/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, { useEffect, useState } from 'react';
import { Element, useEditor, useNode } from '@craftjs/core';
import { NewStepsSettings } from './newStepsSetting';
import { Text } from '../../basic/Text';
import { Parent } from '../../Parent/index.js';
import { Wrapper } from '../../wrapper/index.js';
import { BounceLoader } from 'react-spinners';


export const Steps_1 = ({ selected }) => {

    const {
        actions: { setProp },
        connectors: {
            connect
        }
    } = useNode();


    const {
        enabled,
    } = useEditor((state, query) => ({
        enabled: state.options.enabled,

    }));

    const styles = [
        <Element canvas is={Parent} id='parentId100' className="w-[100%] h-auto" >
            <Element canvas is={Parent} id='parentId200' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-400' is='parent' className='w-[100%] h-full bg-[white] '>
                <Wrapper canvas id='random-id-401' is='container' className='container h-full flex flex-col'>
                    <Wrapper canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', fontFamily: 'Rubik' }} id='random-id-1' className='w-auto h-full mx-auto flex justify-center text-center text-[#100103] mt-4' is='div'>
                        <Text text='Simple Steps for easy start.' />
                    </Wrapper>
                    <Wrapper canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: '400px', fontFamily: 'Rubik' }} className='w-auto h-full mx-auto flex justify-center text-center text-[#100103] my-4 px-6' id='random-id-2' is='div'>
                        <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                    </Wrapper>
                    <Wrapper canvas id='random-id-402' is='wrapper' className='w-full h-full flex flex-row flex-wrap py-20'>
                        <Wrapper canvas id='random-id-403' is='div' className='max-w-[332px] min-w-[300px]  my-4 mx-auto flex flex-col'>
                            <Wrapper canvas id='random-id-404' is='div' className='mx-auto w-[64px] h-[64px] rounded-[4px]  items-center' style={{ background: '#B20312' }}>
                                <Wrapper canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', color: '#fff', fontFamily: 'Rubik' }} className='text-center w-full h-full' id='random-id-3' is='div'>
                                    <Text text='1' />
                                </Wrapper>
                            </Wrapper>
                            <Wrapper canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Rubik', color: '#100103', textAlign: 'center' }} className='flex my-4 justify-center' id='random-id-4' is='div'>
                                <Text text='Step 1' />
                            </Wrapper>
                            <Wrapper canvas style={{ display: 'inline-block', fontSize: '18px', fontFamily: 'Rubik', color: '#100103', fontWeight :{value:null, type:'String'},
        textAlign :{value:'center', type:'String'},
        textDecoration :{value:null, type:'String'}, 
        fontFamily :{value:null, type:'String'},
        textTransform :{value:null, type:'String'},
fontWeight: '400px', lineHeight: '28.8px' }} className='w-[300px] mx-auto' id='random-id-5' is='div'>
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                            </Wrapper>
                        </Wrapper>
                        <Wrapper canvas id='random-id-405' is='div' className='max-w-[332px] min-w-[300px]  my-4 mx-auto flex flex-col'>
                            <Wrapper canvas id='random-id-406' is='div' className='mx-auto w-[64px] h-[64px] flex justify-center items-center rounded-[4px]' style={{ background: '#B20312' }}>
                                <Wrapper canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', color: '#fff', fontFamily: 'Rubik', textAlign: 'center' }} className='text-center w-full h-full' id='random-id-6' is='div'>
                                    <Text text='2' />
                                </Wrapper>
                            </Wrapper>
                            <Wrapper canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Rubik', color: '#100103', textAlign: 'center' }} className='flex my-4 justify-center' id='random-id-7' is='div'>
                                <Text text='Step 2' />
                            </Wrapper>
                            <Wrapper canvas style={{ display: 'inline-block', fontSize: '18px', fontFamily: 'Rubik', color: '#100103', fontWeight :{value:null, type:'String'},
        textAlign :{value:'center', type:'String'},
        textDecoration :{value:null, type:'String'}, 
        fontFamily :{value:null, type:'String'},
        textTransform :{value:null, type:'String'},
fontWeight: '400px', lineHeight: '28.8px' }} className='w-[300px] mx-auto' id='random-id-8' is='div'>
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                            </Wrapper>
                        </Wrapper>
                        <Wrapper canvas id='random-id-407' is='div' className='max-w-[332px] min-w-[300px]  my-4 mx-auto flex flex-col'>
                            <Wrapper canvas id='random-id-408' is='div' className='mx-auto w-[64px] h-[64px] flex justify-center items-center rounded-[4px]' style={{ background: '#B20312' }}>
                                <Wrapper canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', color: '#fff', fontFamily: 'Rubik', textAlign: 'center' }} className='text-center w-full h-full' id='random-id-9' is='div'>
                                    <Text text='3' />
                                </Wrapper>
                            </Wrapper>
                            <Wrapper canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Rubik', color: '#100103', textAlign: 'center' }} className='flex my-4 justify-center' id='random-id-10' is='div'>
                                <Text text='Step 3' />
                            </Wrapper>
                            <Wrapper canvas style={{ display: 'inline-block', fontSize: '18px', fontFamily: 'Rubik', color: '#100103', fontWeight :{value:null, type:'String'},
        textAlign :{value:'center', type:'String'},
        textDecoration :{value:null, type:'String'}, 
        fontFamily :{value:null, type:'String'},
        textTransform :{value:null, type:'String'},
fontWeight: '400px', lineHeight: '28.8px' }} className='w-[300px] mx-auto' id='random-id-11' is='div'>
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                            </Wrapper>
                        </Wrapper>
                    </Wrapper>
                </Wrapper>
            </Wrapper></Element>
        </Element>
        ,

        <Element canvas is={Parent} id='parentId300' className="w-[100%] h-auto" >
            <Element canvas is={Parent} id='parentId400' className="w-[100%] h-auto" row={true} >
                <Wrapper canvas id='random-id-409' is='parent' className='w-[100%] h-full bg-[#121826] '>

                    <Wrapper canvas id='random-id-410' is='container' className='container h-full flex flex-col'>

                        <Wrapper canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', fontFamily: 'Rubik' }} className='w-auto h-full mx-auto flex justify-center text-center IncreaseConversion2 mt-4' id='random-id-12' is='div'>
                            <Text text='Simple Steps for easy start' />
                        </Wrapper>

                        <Wrapper canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: '400px', fontFamily: 'Rubik' }} className='w-auto h-full mx-auto flex justify-center text-center text-[#FFF] my-4 px-6' id='random-id-13' is='div'>
                            <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                        </Wrapper>

                        <Wrapper canvas id='random-id-411' is='wrapper' className='w-full h-full flex flex-row flex-wrap py-20'>
                            <Wrapper canvas id='random-id-412' is='div' className='max-w-[332px] min-w-[300px]  my-4 mx-auto flex justify-center flex flex-col '>
                            <Wrapper canvas id='random-id-413' is='div' className='mx-auto w-[64px] h-[64px] flex justify-center items-center rounded-[4px] ' style={{ background: '#4BDBA2' }}>
                                    <Wrapper canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', fontFamily: 'Rubik', color: '#FFF', textAlign: 'center' }} className='text-center w-full h-full' id='random-id-14' is='div'>
                                        <Text text='1' />
                                    </Wrapper>
                                </Wrapper>
                                <Wrapper canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Rubik', color: '#FFF', textAlign: 'center' }} className='flex my-4 justify-center' id='random-id-15' is='div'>
                                    <Text text='Step 1' />
                                </Wrapper>
                                <Wrapper canvas style={{ display: 'inline-block', fontSize: '18px', fontFamily: 'Rubik', color: '#FFFFFF', fontWeight :{value:null, type:'String'},
        textAlign :{value:'center', type:'String'},
        textDecoration :{value:null, type:'String'}, 
        fontFamily :{value:null, type:'String'},
        textTransform :{value:null, type:'String'},
fontWeight: '400px', lineHeight: '28.8px' }} className='w-[300px] mx-auto flex justify-center' id='random-id-16' is='div'>
                                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                                </Wrapper>
                            </Wrapper>
                            <Wrapper canvas id='random-id-414' is='div' className='max-w-[332px] min-w-[300px]  my-4 mx-auto flex justify-center flex flex-col'>
                                <Wrapper canvas id='random-id-415' is='div' className='mx-auto w-[64px] h-[64px] flex justify-center items-center rounded-[4px] ' style={{ background: '#4BDBA2' }}>
                                    <Wrapper canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', fontFamily: 'Rubik', color: '#FFF', textAlign: 'center' }} className='text-center w-full h-full' id='random-id-17' is='div'>
                                        <Text text='2' />
                                    </Wrapper>
                                </Wrapper>
                                <Wrapper canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Rubik', color: '#FFF', textAlign: 'center' }} className='flex my-4 justify-center' id='random-id-18' is='div'>
                                    <Text text='Step 2' />
                                </Wrapper>
                                <Wrapper canvas style={{ display: 'inline-block', fontSize: '18px', fontFamily: 'Rubik', color: '#FFFFFF', fontWeight :{value:null, type:'String'},
        textAlign :{value:'center', type:'String'},
        textDecoration :{value:null, type:'String'}, 
        fontFamily :{value:null, type:'String'},
        textTransform :{value:null, type:'String'},
fontWeight: '400px', lineHeight: '28.8px' }} className='w-[300px] mx-auto flex justify-center' id='random-id-19' is='div'>
                                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                                </Wrapper>
                            </Wrapper>
                            <Wrapper canvas id='random-id-416' is='div' className='max-w-[332px] min-w-[300px]  my-4 mx-auto flex justify-center flex flex-col'>
                                <Wrapper canvas id='random-id-417' is='div' className='mx-auto w-[64px] h-[64px] flex justify-center items-center rounded-[4px] ' style={{ background: '#4BDBA2' }}>
                                    <Wrapper canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', fontFamily: 'Rubik', color: '#FFF', textAlign: 'center' }} className='text-center w-full h-full' id='random-id-20' is='div'>
                                        <Text text='3' />
                                    </Wrapper>
                                </Wrapper>
                                <Wrapper canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Rubik', color: '#FFF', textAlign: 'center' }} className='flex my-4 justify-center' id='random-id-21' is='div'>
                                    <Text text='Step 3' />
                                </Wrapper>
                                <Wrapper canvas style={{ display: 'inline-block', fontSize: '18px', fontFamily: 'Rubik', color: '#FFFFFF', fontWeight :{value:null, type:'String'},
        textAlign :{value:'center', type:'String'},
        textDecoration :{value:null, type:'String'}, 
        fontFamily :{value:null, type:'String'},
        textTransform :{value:null, type:'String'},
fontWeight: '400px', lineHeight: '28.8px' }} className='w-[300px] mx-auto flex justify-center' id='random-id-22' is='div'>
                                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                                </Wrapper>
                            </Wrapper>
                        </Wrapper>
                    </Wrapper>
                </Wrapper>
            </Element>
        </Element>
        ,

        <Element canvas is={Parent} id='parentId500' className="w-[100%] h-auto" >
            <Element canvas is={Parent} id='parentId600' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-418' is='parent' className='w-[100%] h-full bg-[#213156] '>
                <Wrapper canvas id='random-id-419' is='container' className='container h-full flex flex-col'>
                    <Wrapper canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', fontFamily: 'Times New Roman' }} className='w-auto h-full mx-auto flex justify-center text-center text-[#EA6EF4] mt-4 ' id='random-id-23' is='div'>
                        <Text text='Simple Steps for easy start' />
                    </Wrapper>
                    <Wrapper canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: '400px', fontFamily: 'Times New Roman' }} className='w-auto h-full mx-auto flex justify-center text-center text-[#FFF] my-4 px-6' id='random-id-24' is='div'>
                        <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                    </Wrapper>
                    <Wrapper canvas id='random-id-420' is='wrapper' className='w-full h-full flex flex-row flex-wrap py-20'>
                        <Wrapper canvas id='random-id-421' is='div' className='max-w-[332px] min-w-[300px]  my-4 mx-auto flex justify-center flex flex-col '>
                            <Wrapper canvas id='random-id-422' is='div' className='mx-auto w-[64px] h-[64px] flex justify-center items-center rounded-[10px 2px 20px 2px] justify-center flex' style={{ background: '#EA6EF4', color: '#FFF' }}>
                                <Wrapper canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', fontFamily: 'Open Sans' }} className='text-center w-full h-full' id='random-id-25' is='div'>
                                    <Text text='1' />
                                </Wrapper>
                            </Wrapper>
                            <Wrapper canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Open Sans', color: '#EA6EF4', textAlign: 'center' }} className='flex my-4 justify-center' id='random-id-26' is='div'>
                                <Text text='Step 1' />
                            </Wrapper>
                            <Wrapper canvas style={{ display: 'inline-block', fontSize: '18px', fontFamily: 'Open Sans', color: '#FFF', fontWeight :{value:null, type:'String'},
        textAlign :{value:'center', type:'String'},
        textDecoration :{value:null, type:'String'}, 
        fontFamily :{value:null, type:'String'},
        textTransform :{value:null, type:'String'},
fontWeight: '400px', lineHeight: '28.8px' }} className='w-[300px] mx-auto flex justify-center' id='random-id-27' is='div'>
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                            </Wrapper>
                        </Wrapper>
                        <Wrapper canvas id='random-id-423' is='div' className='max-w-[332px] min-w-[300px]  my-4 mx-auto flex justify-center flex flex-col'>
                            <Wrapper canvas id='random-id-424' is='div' className='mx-auto w-[64px] h-[64px] flex justify-center items-center rounded-[10px 2px 20px 2px] justify-center flex' style={{ background: '#EA6EF4', color: '#FFF' }}>
                                <Wrapper canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', fontFamily: 'Open Sans', color: '#FFF', textAlign: 'center' }} className='text-center w-full h-full' id='random-id-28' is='div'>
                                    <Text text='2' />
                                </Wrapper>
                            </Wrapper>
                            <Wrapper canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Open Sans', color: '#EA6EF4', textAlign: 'center' }} className='flex my-4 justify-center' id='random-id-29' is='div'>
                                <Text text='Step 2' />
                            </Wrapper>
                            <Wrapper canvas style={{ display: 'inline-block', fontSize: '18px', fontFamily: 'Open Sans', color: '#FFF', fontWeight :{value:null, type:'String'},
        textAlign :{value:'center', type:'String'},
        textDecoration :{value:null, type:'String'}, 
        fontFamily :{value:null, type:'String'},
        textTransform :{value:null, type:'String'},
fontWeight: '400px', lineHeight: '28.8px' }} className='w-[300px] mx-auto flex justify-center' id='random-id-30' is='div'>
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                            </Wrapper>
                        </Wrapper>
                        <Wrapper canvas id='random-id-425' is='div' className='max-w-[332px] min-w-[300px]  my-4 mx-auto flex justify-center flex flex-col'>
                            <Wrapper canvas id='random-id-426' is='div' className='mx-auto w-[64px] h-[64px] flex justify-center items-center rounded-[10px 2px 20px 2px] justify-center flex' style={{ background: '#EA6EF4', color: '#FFF' }}>
                                <Wrapper canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', fontFamily: 'Open Sans', color: '#FFF', textAlign: 'center' }} className='text-center w-full h-full' id='random-id-31' is='div'>
                                    <Text text='3' />
                                </Wrapper>
                            </Wrapper>
                            <Wrapper canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Open Sans', color: '#EA6EF4', textAlign: 'center' }} className='flex my-4 justify-center' id='random-id-32' is='div'>
                                <Text text='Step 3' />
                            </Wrapper>
                            <Wrapper canvas style={{ display: 'inline-block', fontSize: '18px', fontFamily: 'Open Sans', color: '#FFF', fontWeight :{value:null, type:'String'},
        textAlign :{value:'center', type:'String'},
        textDecoration :{value:null, type:'String'}, 
        fontFamily :{value:null, type:'String'},
        textTransform :{value:null, type:'String'},
fontWeight: '400px', lineHeight: '28.8px' }} className='w-[300px] mx-auto flex justify-center' id='random-id-33' is='div'>
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                            </Wrapper>
                        </Wrapper>
                    </Wrapper>
                </Wrapper>
            </Wrapper></Element>
        </Element>
        ,


        <Element canvas is={Parent} id='parentId700' className="w-[100%] h-auto" >
            <Element canvas is={Parent} id='parentId800' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-427' is='parent' className='w-[100%] h-full bg-[#DFFFF9] '>
                <Wrapper canvas id='random-id-428' is='container' className='container h-full flex flex-col'>
                    <Wrapper canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', fontFamily: 'Zing Script Rust SemiBold Demo', fontStyle: 'italic' }} className='w-auto h-full mx-auto flex justify-center text-center text-[#31A993] mt-4 ' id='random-id-34' is='div'>
                        <Text text='Simple Steps for easy start' />
                    </Wrapper>
                    <Wrapper canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: '400px', fontFamily: 'Zing Script Rust SemiBold Demo' }} className='w-auto h-full mx-auto flex justify-center text-center text-[#031815] my-4 px-6' id='random-id-35' is='div'>
                        <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                    </Wrapper>
                    <Wrapper canvas id='random-id-429' is='wrapper' className='w-full h-full flex flex-row flex-wrap py-20'>
                        <Wrapper canvas id='random-id-430' is='div' className='max-w-[332px] min-w-[300px]  my-4 mx-auto flex justify-center flex flex-col '>
                            <Wrapper canvas id='random-id-431' is='div' className='mx-auto w-[64px] h-[64px] flex justify-center items-center rounded-[40px] justify-center flex ' style={{ background: '#31A993', color: '#FFF' }}>
                                <Wrapper canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', fontFamily: 'Montserrat' }} className='text-center w-full h-full' id='random-id-36' is='div'>
                                    <Text text='1' />
                                </Wrapper>
                            </Wrapper>
                            <Wrapper canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Montserrat', color: '#31A993', textAlign: 'center' }} className='flex my-4 justify-center' id='random-id-37' is='div'>
                                ,                             <Text text='Step 1' />
                            </Wrapper>
                            <Wrapper canvas style={{ display: 'inline-block', fontSize: '18px', fontFamily: 'Montserrat', color: '#031815', fontWeight :{value:null, type:'String'},
        textAlign :{value:'center', type:'String'},
        textDecoration :{value:null, type:'String'}, 
        fontFamily :{value:null, type:'String'},
        textTransform :{value:null, type:'String'},
fontWeight: '400px', lineHeight: '28.8px' }} className='w-[300px] mx-auto flex justify-center' id='random-id-38' is='div'>
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                            </Wrapper>
                        </Wrapper>
                        <Wrapper canvas id='random-id-432' is='div' className='max-w-[332px] min-w-[300px]  my-4 mx-auto flex justify-center flex flex-col'>
                            <Wrapper canvas id='random-id-433' is='div' className='mx-auto w-[64px] h-[64px] flex justify-center items-center rounded-[40px] justify-center flex ' style={{ background: '#31A993', color: '#FFF' }}>
                                <Wrapper canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', fontFamily: 'Montserrat', textAlign: 'center' }} className='text-center w-full h-full' id='random-id-39' is='div'>
                                    <Text text='2' />
                                </Wrapper>
                            </Wrapper>
                            <Wrapper canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Montserrat', color: '#31A993', textAlign: 'center' }} className='flex my-4 justify-center' id='random-id-40' is='div'>
                                <Text text='Step 2' />
                            </Wrapper>

                            <Wrapper canvas style={{ display: 'inline-block', fontSize: '18px', fontFamily: 'Montserrat', color: '#031815', fontWeight :{value:null, type:'String'},
        textAlign :{value:'center', type:'String'},
        textDecoration :{value:null, type:'String'}, 
        fontFamily :{value:null, type:'String'},
        textTransform :{value:null, type:'String'},
fontWeight: '400px', lineHeight: '28.8px' }} className='w-[300px] mx-auto flex justify-center' id='random-id-41' is='div'>
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                            </Wrapper>
                        </Wrapper>
                        <Wrapper canvas id='random-id-434' is='div' className='max-w-[332px] min-w-[300px]  my-4 mx-auto flex justify-center flex flex-col'>
                            <Wrapper canvas id='random-id-435' is='div' className='mx-auto w-[64px] h-[64px] flex justify-center items-center rounded-[40px] justify-center flex ' style={{ background: '#31A993', color: '#FFF' }}>
                                <Wrapper canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', fontFamily: 'Montserrat', textAlign: 'center' }} className='text-center w-full h-full' id='random-id-42' is='div'>
                                    <Text text='3' />
                                </Wrapper>
                            </Wrapper>
                            <Wrapper canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Montserrat', color: '#31A993', textAlign: 'center' }} className='flex my-4 justify-center' id='random-id-43' is='div'>
                                <Text text='Step 3' />
                            </Wrapper>

                            <Wrapper canvas style={{ display: 'inline-block', fontSize: '18px', fontFamily: 'Montserrat', color: '#031815', fontWeight :{value:null, type:'String'},
        textAlign :{value:'center', type:'String'},
        textDecoration :{value:null, type:'String'}, 
        fontFamily :{value:null, type:'String'},
        textTransform :{value:null, type:'String'},
fontWeight: '400px', lineHeight: '28.8px' }} className='w-[300px] mx-auto flex justify-center' id='random-id-44' is='div'>
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                            </Wrapper>
                        </Wrapper>
                    </Wrapper>
                </Wrapper>
            </Wrapper></Element>
        </Element>
        ,


        <Element canvas is={Parent} id='parentId900' className="w-[100%] h-auto" >
            <Element canvas is={Parent} id='parentId1000' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-436' is='parent' className='w-[100%] h-full bg-[#FFFFFF] '>
                <Wrapper canvas id='random-id-437' is='container' className='container h-full flex flex-col'>
                    <Wrapper canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', fontFamily: 'Inter' }} className='w-auto h-full mx-auto flex justify-center text-center text-[#0078D3] mt-4 ' id='random-id-45' is='div'>
                        <Text text='Simple Steps for easy start' />
                    </Wrapper>
                    <Wrapper canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: '400px', fontFamily: 'Inter' }} className='w-auto h-full mx-auto flex justify-center text-center text-[#031815] my-4 px-6' id='random-id-46' is='div'>
                        <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                    </Wrapper>
                    <Wrapper canvas id='random-id-438' is='wrapper' className='w-full h-full flex flex-row flex-wrap py-20'>
                        <Wrapper canvas id='random-id-439' is='div' className='max-w-[332px] min-w-[300px]  my-4 mx-auto flex justify-center flex flex-col '>
                            <Wrapper canvas id='random-id-440' is='div' className='mx-auto w-[64px] h-[64px] flex justify-center items-center rounded-[2px] flex justify-center' style={{ background: '#0078D3', color: '#FFF' }}>
                                <Wrapper canvas className='text-center w-full h-full' style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', fontFamily: 'Inter' }} id='random-id-47' is='div'>
                                    <Text text='1' />
                                </Wrapper>
                            </Wrapper>
                            <Wrapper canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Inter', color: '#0078D3', textAlign: 'center' }} className='flex my-4 justify-center' id='random-id-48' is='div'>
                                <Text text='Step 1' />
                            </Wrapper>
                            <Wrapper canvas style={{ display: 'inline-block', fontSize: '18px', fontFamily: 'Inter', color: '#031815', fontWeight :{value:null, type:'String'},
        textAlign :{value:'center', type:'String'},
        textDecoration :{value:null, type:'String'}, 
        fontFamily :{value:null, type:'String'},
        textTransform :{value:null, type:'String'},
fontWeight: '400px', lineHeight: '28.8px' }} className='w-[300px] mx-auto flex justify-center' id='random-id-49' is='div'>
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                            </Wrapper>
                        </Wrapper>
                        <Wrapper canvas id='random-id-441' is='div' className='max-w-[332px] min-w-[300px]  my-4 mx-auto flex justify-center flex flex-col'>
                            <Wrapper canvas id='random-id-442' is='div' className='mx-auto w-[64px] h-[64px] flex justify-center items-center rounded-[2px] flex justify-center' style={{ background: '#0078D3', color: '#FFF' }}>
                                <Wrapper canvas className='text-center w-full h-full' style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', fontFamily: 'Inter', textAlign: 'center' }} id='random-id-50' is='div'>
                                    <Text text='2' />
                                </Wrapper>
                            </Wrapper>
                            <Wrapper canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Inter', color: '#0078D3', textAlign: 'center' }} className='flex my-4 justify-center' id='random-id-51' is='div'>
                                <Text text='Step 2' />
                            </Wrapper>
                            <Wrapper canvas style={{ display: 'inline-block', fontSize: '18px', fontFamily: 'Inter', color: '#031815', fontWeight :{value:null, type:'String'},
        textAlign :{value:'center', type:'String'},
        textDecoration :{value:null, type:'String'}, 
        fontFamily :{value:null, type:'String'},
        textTransform :{value:null, type:'String'},
fontWeight: '400px', lineHeight: '28.8px' }} className='w-[300px] mx-auto flex justify-center' id='random-id-52' is='div'>
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                            </Wrapper>
                        </Wrapper>
                        <Wrapper canvas id='random-id-443' is='div' className='max-w-[332px] min-w-[300px]  my-4 mx-auto flex justify-center flex flex-col'>
                            <Wrapper canvas id='random-id-444' is='div' className='mx-auto w-[64px] h-[64px] flex justify-center items-center rounded-[2px] flex justify-center' style={{ background: '#0078D3', color: '#FFF' }}>
                                <Wrapper canvas className='text-center w-full h-full' style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', fontFamily: 'Inter', textAlign: 'center' }} id='random-id-53' is='div'>
                                    <Text text='3' />
                                </Wrapper>
                            </Wrapper>
                            <Wrapper canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Inter', color: '#0078D3', textAlign: 'center' }} className='flex my-4 justify-center' id='random-id-54' is='div'>
                                <Text text='Step 3' />
                            </Wrapper>
                            <Wrapper canvas style={{ display: 'inline-block', fontSize: '18px', fontFamily: 'Inter', color: '#031815', fontWeight :{value:null, type:'String'},
        textAlign :{value:'center', type:'String'},
        textDecoration :{value:null, type:'String'}, 
        fontFamily :{value:null, type:'String'},
        textTransform :{value:null, type:'String'},
fontWeight: '400px', lineHeight: '28.8px' }} className='w-[300px] mx-auto flex justify-center' id='random-id-55' is='div'>
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                            </Wrapper>
                        </Wrapper>
                    </Wrapper>
                </Wrapper>
            </Wrapper></Element>
        </Element>
        ,


        <Element canvas is={Parent} id='parentId1100' className="w-[100%] h-auto" >
            <Element canvas is={Parent} id='parentId1200' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-445' is='parent' className='w-[100%] h-full bg-[#FFFFFF]'>
                <Wrapper canvas id='random-id-446' is='container' className='container h-full flex flex-col'>
                    <Wrapper canvas id='random-id-447' is='div' className='w-[100%] h-full bg-[#F8F8F8]'>
                        <Wrapper canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: '400px', fontFamily: 'Voces' }} className='w-auto h-full mx-auto flex justify-center text-center text-[#020212] mt-4 ' id='random-id-56' is='div'>
                            <Text text='Simple Steps for easy start' />
                        </Wrapper>
                        <Wrapper canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: '400px', fontFamily: 'Voces' }} className='w-auto h-full mx-auto flex justify-center text-center text-[#020212] my-4 px-6' id='random-id-57' is='div'>
                            <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                        </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-448' is='wrapper' className='w-full h-full flex flex-row flex-wrap py-20 '>
                        <Wrapper canvas id='random-id-449' is='div' className='max-w-[332px] min-w-[300px]  my-4 mx-auto flex justify-center flex flex-col '>
                            <Wrapper canvas id='random-id-450' is='div' className='mx-auto w-[64px] h-[64px] flex justify-center items-center rounded-[2px] flex justify-center' style={{ background: '#020212', color: 'white' }}>
                                <Wrapper canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: '400px', fontFamily: 'Voces' }} className='text-center w-full h-full' id='random-id-58' is='div'>
                                    <Text text='1' />
                                </Wrapper>
                            </Wrapper>
                            <Wrapper canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: '400px', fontFamily: 'Voces', color: '#020212', textAlign: 'center' }} className='flex my-4 justify-center' id='random-id-59' is='div'>
                                <Text text='Step 1' />
                            </Wrapper>
                            <Wrapper canvas style={{ display: 'inline-block', fontSize: '18px', fontFamily: 'Voces', color: '#020212', fontWeight :{value:null, type:'String'},
        textAlign :{value:'center', type:'String'},
        textDecoration :{value:null, type:'String'}, 
        fontFamily :{value:null, type:'String'},
        textTransform :{value:null, type:'String'},
fontWeight: '400px', lineHeight: '28.8px' }} className='w-[300px] mx-auto flex justify-center' id='random-id-60' is='div'>
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                            </Wrapper>
                        </Wrapper>
                        <Wrapper canvas id='random-id-451' is='div' className='max-w-[332px] min-w-[300px]  my-4 mx-auto flex justify-center flex flex-col'>
                            <Wrapper canvas id='random-id-452' is='div' className='mx-auto w-[64px] h-[64px] flex justify-center items-center rounded-[2px] flex justify-center' style={{ background: '#020212', color: 'white' }}>
                                <Wrapper canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: '400px', fontFamily: 'Voces', textAlign: 'center' }} className='text-center w-full h-full' id='random-id-61' is='div'>
                                    <Text text='2' />
                                </Wrapper>
                            </Wrapper>
                            <Wrapper canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: '400px', fontFamily: 'Voces', color: '#020212', textAlign: 'center' }} className='flex my-4 justify-center' id='random-id-62' is='div'>
                                <Text text='Step 2' />
                            </Wrapper>
                            <Wrapper canvas style={{ display: 'inline-block', fontSize: '18px', fontFamily: 'Voces', color: '#020212', fontWeight :{value:null, type:'String'},
        textAlign :{value:'center', type:'String'},
        textDecoration :{value:null, type:'String'}, 
        fontFamily :{value:null, type:'String'},
        textTransform :{value:null, type:'String'},
fontWeight: '400px', lineHeight: '28.8px' }} className='w-[300px] mx-auto flex justify-center' id='random-id-63' is='div'>
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                            </Wrapper>
                        </Wrapper>
                        <Wrapper canvas id='random-id-453' is='div' className='max-w-[332px] min-w-[300px]  my-4 mx-auto flex justify-center flex flex-col'>
                            <Wrapper canvas id='random-id-454' is='div' className='mx-auto w-[64px] h-[64px] flex justify-center items-center rounded-[2px] flex justify-center' style={{ background: '#020212', color: 'white' }}>
                                <Wrapper canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: '400px', fontFamily: 'Voces', textAlign: 'center' }} className='text-center w-full h-full' id='random-id-64' is='div'>
                                    <Text text='3' />
                                </Wrapper>
                            </Wrapper>
                            <Wrapper canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: '400px', fontFamily: 'Voces', color: '#020212', textAlign: 'center' }} className='flex my-4 justify-center' id='random-id-65' is='div'>
                                <Text text='Step 3' />
                            </Wrapper>
                            <Wrapper canvas style={{ display: 'inline-block', fontSize: '18px', fontFamily: 'Voces', color: '#020212', fontWeight :{value:null, type:'String'},
        textAlign :{value:'center', type:'String'},
        textDecoration :{value:null, type:'String'}, 
        fontFamily :{value:null, type:'String'},
        textTransform :{value:null, type:'String'},
fontWeight: '400px', lineHeight: '28.8px' }} className='w-[300px] mx-auto flex justify-center' id='random-id-66' is='div'>
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                            </Wrapper>
                        </Wrapper>
                    </Wrapper>
                </Wrapper>
            </Wrapper></Element>
        </Element>
        ,



        <Element canvas is={Parent} id='parentId1300' className="w-[100%] h-auto" >
            <Element canvas is={Parent} id='parentId1400' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-455' is='parent' className='w-[100%] h-full bg-[#FBFAFF] '>
                <Wrapper canvas id='random-id-456' is='container' className='container h-full flex flex-col'>
                    <Wrapper canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', fontFamily: 'Roboto' }} className='w-auto h-full mx-auto flex justify-center text-center text-[#6750A4] mt-4 ' id='random-id-67' is='div'>
                        <Text text='Simple Steps for easy start' />
                    </Wrapper>
                    <Wrapper canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: '400px', fontFamily: 'Roboto' }} className='w-auto h-full mx-auto flex justify-center text-center text-[#020212] my-4 px-6' id='random-id-68' is='div'>
                        <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                    </Wrapper>
                    <Wrapper canvas id='random-id-457' is='wrapper' className='w-full h-full flex flex-row flex-wrap py-20'>
                        <Wrapper canvas id='random-id-458' is='div' className='max-w-[332px] min-w-[300px]  my-4 mx-auto flex justify-center flex flex-col '>
                            <Wrapper canvas id='random-id-459' is='div' className='mx-auto w-[64px] h-[64px] flex justify-center items-center rounded-[40px] justify-center flex' style={{ background: '#6750A4', color: 'white' }}>
                                <Wrapper canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', fontFamily: 'Roboto' }} className='text-center w-full h-full' id='random-id-69' is='div'>
                                    <Text text='1' />
                                </Wrapper>
                            </Wrapper>
                            <Wrapper canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Roboto', color: '#6750A4', textAlign: 'center' }} className='flex my-4 justify-center' id='random-id-70' is='div'>
                                <Text text='Step 1' />
                            </Wrapper>
                            <Wrapper canvas style={{ display: 'inline-block', fontSize: '18px', fontFamily: 'Roboto', color: '#020212', fontWeight :{value:null, type:'String'},
        textAlign :{value:'center', type:'String'},
        textDecoration :{value:null, type:'String'}, 
        fontFamily :{value:null, type:'String'},
        textTransform :{value:null, type:'String'},
fontWeight: '400px', lineHeight: '28.8px' }} className='w-[300px] mx-auto flex justify-center' id='random-id-71' is='div'>
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                            </Wrapper>
                        </Wrapper>
                        <Wrapper canvas id='random-id-460' is='div' className='max-w-[332px] min-w-[300px]  my-4 mx-auto flex justify-center flex flex-col'>
                            <Wrapper canvas id='random-id-461' is='div' className='mx-auto w-[64px] h-[64px] flex justify-center items-center rounded-[40px] justify-center flex' style={{ background: '#6750A4', color: 'white' }}>
                                <Wrapper canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', fontFamily: 'Roboto', textAlign: 'center' }} className='text-center w-full h-full' id='random-id-72' is='div'>
                                    <Text text='2' />
                                </Wrapper>
                            </Wrapper>
                            <Wrapper canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Roboto', color: '#6750A4', textAlign: 'center' }} className='flex my-4 justify-center' id='random-id-73' is='div'>
                                <Text text='Step 2' />
                            </Wrapper>
                            <Wrapper canvas style={{ display: 'inline-block', fontSize: '18px', fontFamily: 'Roboto', color: '#020212', fontWeight :{value:null, type:'String'},
        textAlign :{value:'center', type:'String'},
        textDecoration :{value:null, type:'String'}, 
        fontFamily :{value:null, type:'String'},
        textTransform :{value:null, type:'String'},
fontWeight: '400px', lineHeight: '28.8px' }} className='w-[300px] mx-auto flex justify-center' id='random-id-74' is='div'>
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                            </Wrapper>
                        </Wrapper>
                        <Wrapper canvas id='random-id-462' is='div' className='max-w-[332px] min-w-[300px]  my-4 mx-auto flex justify-center flex flex-col'>
                            <Wrapper canvas id='random-id-463' is='div' className='mx-auto w-[64px] h-[64px] flex justify-center items-center rounded-[40px] justify-center flex' style={{ background: '#6750A4', color: 'white' }}>
                                <Wrapper canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', fontFamily: 'Roboto', textAlign: 'center' }} className='text-center w-full h-full' id='random-id-75' is='div'>
                                    <Text text='3' />
                                </Wrapper>
                            </Wrapper>
                            <Wrapper canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Roboto', color: '#6750A4', textAlign: 'center' }} className='flex my-4 justify-center' id='random-id-76' is='div'>
                                <Text text='Step 3' />
                            </Wrapper>
                            <Wrapper canvas style={{ display: 'inline-block', fontSize: '18px', fontFamily: 'Roboto', color: '#020212', fontWeight :{value:null, type:'String'},
        textAlign :{value:'center', type:'String'},
        textDecoration :{value:null, type:'String'}, 
        fontFamily :{value:null, type:'String'},
        textTransform :{value:null, type:'String'},
fontWeight: '400px', lineHeight: '28.8px' }} className='w-[300px] mx-auto flex justify-center' id='random-id-77' is='div'>
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                            </Wrapper>
                        </Wrapper>
                    </Wrapper>
                </Wrapper>
            </Wrapper></Element>
        </Element>
        ,

        <Element canvas is={Parent} id='parentId1500' className="w-[100%] h-auto" >
            <Element canvas is={Parent} id='parentId1600' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-464' is='parent' className='w-[100%] h-full bg-[#2A2A2A] '>
                <Wrapper canvas id='random-id-465' is='container' className='container h-full flex flex-col'>
                    <Wrapper canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: '400px', fontFamily: 'Rum Raisin' }} className='w-auto h-full mx-auto flex justify-center text-center text-[#FFD600] mt-4 ' id='random-id-78' is='div'>
                        <Text text='Simple Steps for easy start' />
                    </Wrapper>
                    <Wrapper canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: '400px', fontFamily: 'Rubik' }} className='w-auto h-full mx-auto flex justify-center text-center text-[#FFFFFF] my-4 px-6' id='random-id-79' is='div'>
                        <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                    </Wrapper>
                    <Wrapper canvas id='random-id-466' is='wrapper' className='w-full h-full flex flex-row flex-wrap py-20'>
                        <Wrapper canvas id='random-id-467' is='div' className='max-w-[332px] min-w-[300px]  my-4 mx-auto flex justify-center flex flex-col '>
                            <Wrapper canvas id='random-id-468' is='div' className='mx-auto w-[64px] h-[64px] flex justify-center items-center rounded-[2px] flex justify-center' style={{ background: '#FFD600', color: '#2A2A2A' }}>
                                <Wrapper canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', fontFamily: 'Rubik' }} className='text-center w-full h-full' id='random-id-80' is='div'>
                                    <Text text='1' />
                                </Wrapper>
                            </Wrapper>
                            <Wrapper canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Rubik', color: '#FFD600', textAlign: 'center' }} className='flex my-4 justify-center' id='random-id-81' is='div'>
                                <Text text='Step 1' />
                            </Wrapper>
                            <Wrapper canvas style={{ display: 'inline-block', fontSize: '18px', fontFamily: 'Rubik', color: '#FFFFFF', fontWeight :{value:null, type:'String'},
        textAlign :{value:'center', type:'String'},
        textDecoration :{value:null, type:'String'}, 
        fontFamily :{value:null, type:'String'},
        textTransform :{value:null, type:'String'},
fontWeight: '400px', lineHeight: '28.8px' }} className='w-[300px] mx-auto flex justify-center' id='random-id-82' is='div'>
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                            </Wrapper>
                        </Wrapper>
                        <Wrapper canvas id='random-id-469' is='div' className='max-w-[332px] min-w-[300px]  my-4 mx-auto flex justify-center flex flex-col'>
                            <Wrapper canvas id='random-id-470' is='div' className='mx-auto w-[64px] h-[64px] flex justify-center items-center rounded-[2px] flex justify-center' style={{ background: '#FFD600', color: '#2A2A2A' }}>
                                <Wrapper canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', fontFamily: 'Rubik', textAlign: 'center' }} className='text-center w-full h-full' id='random-id-83' is='div'>
                                    <Text text='2' />
                                </Wrapper>
                            </Wrapper>
                            <Wrapper canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Rubik', color: '#FFD600', textAlign: 'center' }} className='flex my-4 justify-center' id='random-id-84' is='div'>
                                <Text text='Step 2' />
                            </Wrapper>
                            <Wrapper canvas style={{ display: 'inline-block', fontSize: '18px', fontFamily: 'Rubik', color: '#FFFFFF', fontWeight :{value:null, type:'String'},
        textAlign :{value:'center', type:'String'},
        textDecoration :{value:null, type:'String'}, 
        fontFamily :{value:null, type:'String'},
        textTransform :{value:null, type:'String'},
fontWeight: '400px', lineHeight: '28.8px' }} className='w-[300px] mx-auto flex justify-center' id='random-id-85' is='div'>
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                            </Wrapper>
                        </Wrapper>
                        <Wrapper canvas id='random-id-472' is='div' className='max-w-[332px] min-w-[300px]  my-4 mx-auto flex justify-center flex flex-col'>
                            <Wrapper canvas id='random-id-473' is='div' className='mx-auto w-[64px] h-[64px] flex justify-center items-center rounded-[2px] flex justify-center' style={{ background: '#FFD600', color: '#2A2A2A' }}>
                                <Wrapper canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', fontFamily: 'Rubik', textAlign: 'center' }} className='text-center w-full h-full' id='random-id-86' is='div'>
                                    <Text text='3' />
                                </Wrapper>
                            </Wrapper>
                            <Wrapper canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Rubik', color: '#FFD600', textAlign: 'center' }} className='flex my-4 justify-center' id='random-id-87' is='div'>
                                <Text text='Step 3' />
                            </Wrapper>
                            <Wrapper canvas style={{ display: 'inline-block', fontSize: '18px', fontFamily: 'Rubik', color: '#FFFFFF', fontWeight :{value:null, type:'String'},
        textAlign :{value:'center', type:'String'},
        textDecoration :{value:null, type:'String'}, 
        fontFamily :{value:null, type:'String'},
        textTransform :{value:null, type:'String'},
fontWeight: '400px', lineHeight: '28.8px' }} className='w-[300px] mx-auto flex justify-center' id='random-id-88' is='div'>
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                            </Wrapper>
                        </Wrapper>
                    </Wrapper>
                </Wrapper>
            </Wrapper></Element>
        </Element>
        ,

        <Element canvas is={Parent} id='parentId1700' className="w-[100%] h-auto" >
            <Element canvas is={Parent} id='parentId1800' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-900' is='parent' className='w-[100%] h-full bg-[#F7FBFE]'>
                <Wrapper canvas id='random-id-901' is='container' className='container h-full flex flex-col'>
                    <Wrapper canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', fontFamily: 'Rubik' }} id='random-id-902' className='w-auto h-full mx-auto flex justify-center text-center text-[#6064D2] mt-4' is='div'>
                        <Text text='Simple Steps for easy start.' />
                    </Wrapper>
                    <Wrapper canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: '400px', fontFamily: 'Rubik' }} className='w-auto h-full mx-auto flex justify-center text-center text-[#100103] my-4 px-6' id='random-id-903' is='div'>
                        <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                    </Wrapper>
                    <Wrapper canvas id='random-id-904' is='wrapper' className='w-full h-full flex flex-row flex-wrap py-20'>
                        <Wrapper canvas id='random-id-905' is='div' className='max-w-[332px] min-w-[300px]  my-4 mx-auto flex flex-col'>
                            <Wrapper canvas id='random-id-906' is='div' className='mx-auto w-[64px] h-[64px] flex justify-center items-center rounded-[4px] ' style={{ background: '#B20312', color: '#FFF' }}>
                                <Wrapper canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', fontFamily: 'Rubik' }} className='text-center w-full h-full' id='random-id-907' is='div'>
                                    <Text text='1' />
                                </Wrapper>
                            </Wrapper>
                            <Wrapper canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Rubik', color: '#6064D2', textAlign: 'center' }} className='flex my-4 justify-center' id='random-id-908' is='div'>
                                <Text text='Step 1' />
                            </Wrapper>
                            <Wrapper canvas style={{ display: 'inline-block', fontSize: '18px', fontFamily: 'Rubik', color: '#100103', fontWeight :{value:null, type:'String'},
        textAlign :{value:'center', type:'String'},
        textDecoration :{value:null, type:'String'}, 
        fontFamily :{value:null, type:'String'},
        textTransform :{value:null, type:'String'},
fontWeight: '400px', lineHeight: '28.8px' }} className='w-[300px] mx-auto' id='random-id-909' is='div'>
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                            </Wrapper>
                        </Wrapper>
                        <Wrapper canvas id='random-id-910' is='div' className='max-w-[332px] min-w-[300px]  my-4 mx-auto flex flex-col'>
                            <Wrapper canvas id='random-id-911' is='div' className='mx-auto w-[64px] h-[64px] flex justify-center items-center rounded-[4px] ' style={{ background: '#B20312', color: '#FFF' }}>
                                <Wrapper canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', fontFamily: 'Rubik', color: '#FFF', textAlign: 'center' }} className='text-center w-full h-full' id='random-id-912' is='div'>
                                    <Text text='2' />
                                </Wrapper>
                            </Wrapper>
                            <Wrapper canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Rubik', color: '#6064D2', textAlign: 'center' }} className='flex my-4 justify-center' id='random-id-913' is='div'>
                                <Text text='Step 2' />
                            </Wrapper>
                            <Wrapper canvas style={{ display: 'inline-block', fontSize: '18px', fontFamily: 'Rubik', color: '#100103', fontWeight :{value:null, type:'String'},
        textAlign :{value:'center', type:'String'},
        textDecoration :{value:null, type:'String'}, 
        fontFamily :{value:null, type:'String'},
        textTransform :{value:null, type:'String'},
fontWeight: '400px', lineHeight: '28.8px' }} className='w-[300px] mx-auto' id='random-id-914' is='div'>
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                            </Wrapper>
                        </Wrapper>
                        <Wrapper canvas id='random-id-915' is='div' className='max-w-[332px] min-w-[300px]  my-4 mx-auto flex flex-col'>
                            <Wrapper canvas id='random-id-916' is='div' className='mx-auto w-[64px] h-[64px] flex justify-center items-center rounded-[4px] ' style={{ background: '#B20312', color: '#FFF' }}>
                                <Wrapper canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', fontFamily: 'Rubik', color: '#FFF', textAlign: 'center' }} className='text-center w-full h-full' id='random-id-917' is='div'>
                                    <Text text='3' />
                                </Wrapper>
                            </Wrapper>
                            <Wrapper canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Rubik', color: '#6064D2', textAlign: 'center' }} className='flex my-4 justify-center' id='random-id-918' is='div'>
                                <Text text='Step 3' />
                            </Wrapper>
                            <Wrapper canvas style={{ display: 'inline-block', fontSize: '18px', fontFamily: 'Rubik', color: '#100103', fontWeight :{value:null, type:'String'},
        textAlign :{value:'center', type:'String'},
        textDecoration :{value:null, type:'String'}, 
        fontFamily :{value:null, type:'String'},
        textTransform :{value:null, type:'String'},
fontWeight: '400px', lineHeight: '28.8px' }} className='w-[300px] mx-auto' id='random-id-919' is='div'>
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                            </Wrapper>
                        </Wrapper>
                    </Wrapper>
                </Wrapper>
            </Wrapper></Element>
        </Element>
        ,


        <Element canvas is={Parent} id='parentId1900' className="w-[100%] h-auto" >
            <Element canvas is={Parent} id='parentId2000' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-920' is='parent' className='w-[100%] h-full bg-[#FEFBF7]'>
                <Wrapper canvas id='random-id-921' is='container' className='container h-full flex flex-col'>
                    <Wrapper canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', fontFamily: 'Ruluko' }} id='random-id-921' className='w-auto h-full mx-auto flex justify-center text-center text-[#A25738] mt-4' is='div'>
                        <Text text='Simple Steps for easy start.' />
                    </Wrapper>
                    <Wrapper canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: '400px', fontFamily: 'Rubik' }} className='w-auto h-full mx-auto flex justify-center text-center text-[#100103] my-4 px-6' id='random-id-922' is='div'>
                        <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                    </Wrapper>
                    <Wrapper canvas id='random-id-923' is='wrapper' className='w-full h-full flex flex-row flex-wrap py-20'>
                        <Wrapper canvas id='random-id-924' is='div' className='max-w-[332px] min-w-[300px]  my-4 mx-auto flex flex-col'>
                            <Wrapper canvas id='random-id-925' is='div' className='mx-auto w-[64px] h-[64px] flex justify-center items-center rounded-[4px] ' style={{ background: '#A25738', color: '#FFF' }}>
                                <Wrapper canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', fontFamily: 'Rubik' }} className='text-center w-full h-full' id='random-id-926' is='div'>
                                    <Text text='1' />
                                </Wrapper>
                            </Wrapper>
                            <Wrapper canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Rubik', color: '#A25738', textAlign: 'center' }} className='flex my-4 justify-center' id='random-id-927' is='div'>
                                <Text text='Step 1' />
                            </Wrapper>
                            <Wrapper canvas style={{ display: 'inline-block', fontSize: '18px', fontFamily: 'Rubik', color: '#100103', fontWeight :{value:null, type:'String'},
        textAlign :{value:'center', type:'String'},
        textDecoration :{value:null, type:'String'}, 
        fontFamily :{value:null, type:'String'},
        textTransform :{value:null, type:'String'},
fontWeight: '400px', lineHeight: '28.8px' }} className='w-[300px] mx-auto' id='random-id-928' is='div'>
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                            </Wrapper>
                        </Wrapper>
                        <Wrapper canvas id='random-id-929' is='div' className='max-w-[332px] min-w-[300px]  my-4 mx-auto flex flex-col'>
                            <Wrapper canvas id='random-id-930' is='div' className='mx-auto w-[64px] h-[64px] flex justify-center items-center rounded-[4px] ' style={{ background: '#A25738', color: '#FFF' }}>
                                <Wrapper canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', fontFamily: 'Rubik', color: '#FFF', textAlign: 'center' }} className='text-center w-full h-full' id='random-id-931' is='div'>
                                    <Text text='2' />
                                </Wrapper>
                            </Wrapper>
                            <Wrapper canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Rubik', color: '#A25738', textAlign: 'center' }} className='flex my-4 justify-center' id='random-id-932' is='div'>
                                <Text text='Step 2' />
                            </Wrapper>
                            <Wrapper canvas style={{ display: 'inline-block', fontSize: '18px', fontFamily: 'Rubik', color: '#100103', fontWeight :{value:null, type:'String'},
        textAlign :{value:'center', type:'String'},
        textDecoration :{value:null, type:'String'}, 
        fontFamily :{value:null, type:'String'},
        textTransform :{value:null, type:'String'},
fontWeight: '400px', lineHeight: '28.8px' }} className='w-[300px] mx-auto' id='random-id-933' is='div'>
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                            </Wrapper>
                        </Wrapper>
                        <Wrapper canvas id='random-id-934' is='div' className='max-w-[332px] min-w-[300px]  my-4 mx-auto flex flex-col'>
                            <Wrapper canvas id='random-id-935' is='div' className='mx-auto w-[64px] h-[64px] flex justify-center items-center rounded-[4px] ' style={{ background: '#A25738', color: '#FFF' }}>
                                <Wrapper canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', fontFamily: 'Rubik', color: '#FFF', textAlign: 'center' }} className='text-center w-full h-full' id='random-id-936' is='div'>
                                    <Text text='3' />
                                </Wrapper>
                            </Wrapper>
                            <Wrapper canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Rubik', color: '#A25738', textAlign: 'center' }} className='flex my-4 justify-center' id='random-id-937' is='div'>
                                <Text text='Step 3' />
                            </Wrapper>
                            <Wrapper canvas style={{ display: 'inline-block', fontSize: '18px', fontFamily: 'Rubik', color: '#100103', fontWeight :{value:null, type:'String'},
        textAlign :{value:'center', type:'String'},
        textDecoration :{value:null, type:'String'}, 
        fontFamily :{value:null, type:'String'},
        textTransform :{value:null, type:'String'},
fontWeight: '400px', lineHeight: '28.8px' }} className='w-[300px] mx-auto' id='random-id-938' is='div'>
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                            </Wrapper>
                        </Wrapper>
                    </Wrapper>
                </Wrapper>
            </Wrapper></Element>
        </Element>
        ,
    ]

    const [sel, setSel] = useState(selected)

    function makeOdd(number) {
        setProp((prop) => {
            setSel(100);
        }, 1000);

        setTimeout(() => {
            setProp((prop) => {
                setSel(number)
            }, 1000);
        }, 100);



    }

    useEffect(() => {
        if (selected || selected >= 0) {
            makeOdd(selected);
        }
    }, [selected])

    return (
        <div style={{ padding: enabled ? "10px" : "0px", display: "flex", justifyContent: "center", alignItems: "center", width: '100%', height: '100%' }} ref={connect}>
            {sel == 100 ? (
                <div style={{ height: "85vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <BounceLoader
                        size={100}
                        color={"white"}
                        loading={true}
                    />
                </div>
            ) : (
                styles[sel]
            )}
        </div>
    );
}

Steps_1.craft = {
    displayName: "Steps 1",
    props: {
        selected: 1, // here is the default value of the selected style
        length: 10,
        isBlock: true
    },
    rules: {
        canDrag: () => true,
        canDrop: () => true,
    },
    related: {
        toolbar: NewStepsSettings,
    },
};