/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, { useContext } from 'react';
import { Parent } from '../../selectors/Parent';
import { Text } from '../../selectors/basic/Text';
import { Wrapper } from '../../selectors/wrapper';
import { Element } from '@craftjs/core';
import { useOptionsContext } from '../../../OptionsContext';
import { useActiveIdContext } from '../../../ActiveIdContext';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import WidthContext from '../../../WidthContext/WidthContext';


const NavbarItems = ({ navbarProps, menuFrom, color }) => {
    const { width } = useContext(WidthContext);

    const { state: idState, dispatch: idDispatch } = useOptionsContext();
    const { options: treeDatas } = idState;
    const { state, dispatch: setActiveId } = useActiveIdContext();
    const { activeId: activeId } = state;

    let countToMap = 0;

    if (width === "100%") {
    countToMap = 5; // Map all items
    } else if (width === "800px") {
    countToMap = 3; // Map only 4 items
    }



    return (
        <div style={{ display: 'flex', margin: menuFrom ? '' : 'auto auto', flexDirection: menuFrom ? 'column' : 'row', width: '100%', justifyContent: menuFrom ? '' : 'space-around', }}>

            {navbarProps?.textVis ? (
                navbarProps?.textCount.length > 0 ? navbarProps.textCount.map((item, i) => (
                    <button key={i} style={{ display: 'flex', height: menuFrom ? '100px' : '', flex: menuFrom && 1, justifyItems: 'start', justifyContent: 'start', margin: menuFrom ? 5 : 0 }} className="k-button k-button-md k-rounded-md k-button-flat k-button-flat-base">
                        <Element none canvas is={'div'} id={`${menuFrom ? `parentId-2${i}` : `parentId-1-${i}`}`} className="w-[100%] h-auto">
                            <Wrapper canvas className='cursor-pointer flex-1 w-[fit-content] text-center' id={`random-id-1-${i}`} is='div'>
                                <Text text={item} />
                            </Wrapper>
                        </Element>
                    </button>
                )) : null
            ) : null 
            //  (treeDatas?.slice(0, countToMap).map(x => (
            //     <div key={x._id} style={{ width: "7vw", }} className='my-auto itemtohover'>
            //         <div style={{ width: "100%" }} className='flex flex-col align-items dropdown'>
            //             <div className={`flex ${menuFrom ? 'flex-column' : 'flex-row'}`} style={{margin:menuFrom? '6px 0' : ''}}>
            //                 <div className='dropbtn k-button k-button-md k-rounded-md k-button-flat k-button-flat-base'
            //                     id='homeItem'
            //                     val={x?.name}
            //                     _id="sidebarItemx"
            //                     style={{ justifyContent: menuFrom ? 'start' : 'center', width: "100%", padding: 0, textDecoration: 'none', cursor: "pointer", fontWeight: activeId?._id === x._id || x.children?.some((y) => y._id == activeId?._id) ? '700' : '400' }}
            //                     onClick={() => {
            //                         x?.children?.some(z => z?._id == activeId?._id) ? setActiveId(x?.children?.find(z => z?._id == activeId?._id)) : setActiveId(x)
            //                     }}>

            //                     <Element none canvas is={Parent} id={`parentId-1-${x._id}`} className="w-[fit-content] h-auto ml-[4px]">
            //                         <Wrapper canvas className='flex-1 w-[fit-content] text-center' id={`random-id-5-${x._id}`} is='div'>
            //                             <Text text={(x?.name?.length > 11 ? x?.name.slice(0, 10) + "..." : x?.name)} />
            //                         </Wrapper>
            //                     </Element>

            //                 </div>

            //                 {menuFrom ? <div className='container' style={{ display: 'flex', flexDirection:'column', justifyContent: 'start',}}>
            //                     {x.children ? x?.children?.map(child => (

            //                         <div className='k-button k-button-md k-rounded-md k-button-flat k-button-flat-base' style={{display:'flex', justifyContent:'start', margin:'4px 0', }} key={child._id} _id={"sidebarItemx"} val={child.name} >
            //                             <div
            //                                 className='text-center'
            //                                 style={{color: color && color === null ? '#FFF' : `rgba(${Object.values(color)})` , textDecoration: 'none', width: 'auto', fontSize: '13px', cursor: "pointer", fontWeight: activeId?._id === child._id ? '600' : '400' }}
            //                                 onClick={() => {
            //                                     activeId?._id == child._id ? setActiveId(x) : setActiveId(child)

            //                                 }}>
            //                                 {
            //                                     activeId?._id === child._id
            //                                         ? (x?.name?.length > 11 ? x?.name.slice(0, 10) + "..." : x?.name)
            //                                         : (child?.name?.length > 11 ? x?.name.slice(0, 10) + "..." : child?.name)
            //                                 }
            //                             </div>
            //                         </div>


            //                     )) : null}
            //                 </div> : null}

            //             </div>
            //         </div>
            //     </div>
            // )))
            }
        </div>
    );
};

export default NavbarItems;
