// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inputt::-webkit-input-placeholder { /* WebKit browsers */
    color:#ffffff9a
}

`, "",{"version":3,"sources":["webpack://./src/components/selectors/blocks/Blog/blogs.css"],"names":[],"mappings":"AAAA,qCAAqC,oBAAoB;IACrD;AACJ","sourcesContent":[".inputt::-webkit-input-placeholder { /* WebKit browsers */\n    color:#ffffff9a\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
