/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/

import { withStyles } from '@mui/styles';
import { useState } from 'react';
import { UndoIcon } from '../../Viewport/EditorSvg';
import { Slider as Small } from '@mui/material';


const SliderStyled = withStyles({
    root: {
        margin: 'auto auto',
        width: '33%',
        color: '#3880ff',
        height: '3vmin',
        padding: '5% 0',
    },
    thumb: {
        height: '2vmin',
        width: '2vmin',
        backgroundColor: '#0867FC',
    },

    active: {},
    valueLabel: {
        left: 'calc(-50% + 1.1%)',
        top: '-22%',
        '& *': {
            background: 'transparent',
            color: '#000',
        },
    },

    rail: {
        height: '2%',
        opselectedIconacity: 0.5,
        backgroundColor: '#bfbfbf',
    },
    mark: {
        backgroundColor: '#bfbfbf',
        height: '8%',
        width: '10%',
        marginTop: '-3%',
    },
    markActive: {
        opacity: 1,
        backgroundColor: 'currentColor',
    },
})(Small);

export const SliderType = ({
    value,
    node,
    setProp,
    isDarkMode,
    propKey,
    unitOptions,
    value2,
    onChange,
    propValue,
    index,
    activeState,
    options,
    hoverV,
    scrollV,
    props,
    max,
}) => {
    const handleReset = (activeState) => {
        if (activeState === 'Hover') {
          setProp(node.id, (props) => {
            props['actionSelect'][propKey] = { hover: null, scroll: null };
            return { ...props };
          });           
        } else if (activeState === 'Default') {
          setProp(node.id, (props) => {
            props[propKey] = null;
            return { ...props };
          });
        } else {
            setProp(node.id, (props) => {
                props[propKey] = null;
                return { ...props };
              });
        }
      }

    return (
        <div
            className='w-[100%] flex flex-column wrapper-slide container justify-between my-[1vmin] rounded-[2vmin]'>
            <div className={` items-center flex justify-start text-left w-[fit-content]`}
                style={{ fontSize: '2.2vmin', fontWeight: '500', fontFamily: 'Inter' }}>{props.label}</div>
            <div className='px-[1.5Vmin] mx-auto flex flex-row justify-between flex-wrap' style={{ width: '100%' }}>

                <div className='flex flex-row justify-around items-center w-full flex-1'>
                <SliderStyled
                    className='mx-auto my-auto justify-center flex flex-1 max-w-[70%] items-center'
                    style={{ border: '.25vmin solid transparent', paddingLeft:'.5vmin' }}
                    min={props.label === 'Transform value' ? -100 : 1}
                    max={max || props.label === 'Transform value' ? 100 : 1000}
                    value={
                        activeState == "Hover" ?
                            parseInt(hoverV) || 0 : activeState == "Scroll" ?
                                parseInt(scrollV) || 0 :
                                parseInt(value) || 0
                    }
                    onChange={
                        ((_, value) => {
                            let numberValue = value || propValue?.split(" ")[0]
                            let unitValue = propValue?.split(" ")[1] || 'px'

                            let finalvalue = [numberValue, unitValue]

                            let valueWithUnit = activeState === 'Hover' ? finalvalue.join("") : finalvalue.join(" ")

                            console.log(valueWithUnit)
                           
                            setProp(node.id, (props) => {
                                if ( node?.data?.props["isParent"] && !props.actionSelect[propKey]) {
                                    props.actionSelect[propKey] = {};
                                  }
                                  
                                  if (activeState == "Default") { 
                                    props[propKey] = onChange ? onChange(valueWithUnit) : valueWithUnit;
                                  } else if (activeState == "Hover") { 
                                    props.actionSelect[propKey].hover = onChange ? onChange(valueWithUnit) : valueWithUnit;
                                  } else if (activeState == "Scroll") { 
                                    props.actionSelect[propKey].scroll = onChange ? onChange(valueWithUnit) : valueWithUnit;
                                  } else if (Array.isArray(propValue)) {
                                    props[propKey][index] = onChange ? onChange(valueWithUnit) : valueWithUnit;
                                  } else {
                                    props[propKey] = onChange ? onChange(valueWithUnit) : valueWithUnit;
                                  }

                            }, 1000)
                        })
                    }

                />

                {activeState === 'Default' ? (
                        <div className=' items-center flex flex-1 cursor-pointer justify-center flex-row' onClick={() => handleReset('Default')}>
                        <UndoIcon width='2.5vmin' height='2.5vmin' fill={`${(value && value !== 0 && value !== null) ? '#0867FC' : '#989191'}`} />
                        <div className={`${(value && value !== 0 && value !== null) ? 'text-[#0867FC]' : 'text-[#989191]'} text-[2vmin] my-auto flex`} style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center' }}>Reset</div>
                    </div>
                    ) : activeState === 'Hover' ? (
                        <div className=' items-center flex flex-1 cursor-pointer justify-center flex-row' onClick={() => handleReset('Hover')}>
                        <UndoIcon width='2.5vmin' height='2.5vmin' fill={`${(hoverV && hoverV !== 0 && hoverV !== null) ? '#0867FC' : '#989191'}`} />
                        <div className={`${(hoverV && hoverV !== 0 && hoverV !== null) ? 'text-[#0867FC]' : 'text-[#989191]'} text-[2vmin] my-auto flex`} style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center' }}>Reset</div>
                    </div>
                    ) : activeState === 'Scroll'? (
                        <div className=' items-center flex flex-1 cursor-pointer justify-center flex-row' onClick={() => handleReset('Scroll')}>
                        <UndoIcon width='2.5vmin' height='2.5vmin' fill={`${(hoverV && hoverV !== 0 && hoverV !== null) ? '#0867FC' : '#989191'}`} />
                        <div className={`${(hoverV && hoverV !== 0 && hoverV !== null) ? 'text-[#0867FC]' : 'text-[#989191]'} text-[2vmin] my-auto flex`} style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center' }}>Reset</div>
                    </div>
                    ) :  <div className=' items-center flex flex-1 cursor-pointer justify-center flex-row' onClick={() => handleReset(propKey)}>
                    <UndoIcon width='2.5vmin' height='2.5vmin' fill={`${options[propKey] !== null ? '#0867FC' : '#989191'}`} />
                    <div className={`${options[propKey] !== null ? 'text-[#0867FC]' : 'text-[#989191]'} text-[2vmin] my-auto flex`} style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center' }}>Reset</div>
                </div>}

                </div>
                 

                <div className='value-props' >
                        <input style={{
                             alignContent: 'center',
                             alignItems: 'center',
                             display: 'flex',
                             padding: '.7vmin 1.4vmin',
                             fontFamily: 'Inter, sans-serif',
                             fontWeight: '400',
                             fontSize: '1.5vmin',
                             color: isDarkMode? '#FFF' : '#333',
                             backgroundColor: 'transparent',
                             border: '.25vmin solid #e9e9e9',
                             borderRadius: '2vmin',
                             outline: 'none',
                            //  transition: 'border-color 0.2s ease, box-shadow 0.2s ease',
                        }}
                            value={
                                activeState == "Hover" ?
                                parseInt(hoverV) || 0 : activeState == "Scroll" ?
                                parseInt(scrollV) || 0 :
                                parseInt(value) || 0
                            }

                            placeholder='0' 
                            onFocus={(e) => {
                                e.target.style.borderColor = '#007bff';
                                e.target.style.boxShadow = '0 0 5px rgba(0, 123, 255, 0.5)';
                            }}
                            onBlur={(e) => {
                                e.target.style.borderColor = '#ccc';
                                e.target.style.boxShadow = 'none';
                            }}
                            onChange={ 
                                ((e, value) => {
                                    value = e.target.value
                                    let numberValue = value || propValue?.split(" ")[0]
                                    let unitValue = propValue?.split(" ")[1] || 'px'
        
                                    let finalvalue = [numberValue, unitValue]
        
                                    let valueWithUnit = activeState === 'Hover' ? finalvalue.join("") : finalvalue.join(" ")
        
                                    console.log(valueWithUnit)
                                    setProp(node.id, (props) => {

                                            if (node?.data?.props['actionSelect'] === undefined) {
                                                props[propKey] = onChange ? onChange(valueWithUnit) : valueWithUnit;
                                            } else {
                                            if (!props.actionSelect[propKey]) {
                                                props.actionSelect[propKey] = {};
                                              }
                                              if (activeState == "Default") { 
                                                props[propKey] = onChange ? onChange(valueWithUnit) : valueWithUnit;
                                              } else if (activeState == "Hover") { 
                                                props.actionSelect[propKey].hover = onChange ? onChange(valueWithUnit) : valueWithUnit;
                                              } else if (activeState == "Scroll") { 
                                                props.actionSelect[propKey].scroll = onChange ? onChange(valueWithUnit) : valueWithUnit;
                                              } else if (Array.isArray(propValue)) {
                                                props[propKey][index] = onChange ? onChange(valueWithUnit) : valueWithUnit;
                                              } else {
                                                props[propKey] = onChange ? onChange(valueWithUnit) : valueWithUnit;
                                              }
                                        }
        
                                    }, 1000)
                                })
                            }
                        />

                 
                    <div className='w-full h-full mx-[1vmin] flex-1 items-center flex'>
                    <select
                        style={{
                            alignContent: 'center',
                            alignItems: 'center',
                            display: 'flex',
                            padding: '.7vmin 1.4vmin',
                            fontFamily: 'Inter, sans-serif',
                            fontWeight: '400',
                            fontSize: '1.5vmin',
                            color: isDarkMode? '#FFF' : '#333',
                            backgroundColor: 'transparent',
                            border: '.25vmin solid #e9e9e9',
                            borderRadius: '2vmin',
                            outline: 'none',
                            // transition: 'border-color 0.2s ease, box-shadow 0.2s ease',
                            cursor: 'pointer',
                        }}
                        value={value2 || value.replace(/\d+/g, '')}
                        onChange={(e) => {
                            const newValue = e.target.value;

                            let numberValue = propValue?.split(" ")[0];
                            let unitValue = newValue;

                            let finalvalue = [numberValue, unitValue];

                            let valueWithUnit = finalvalue.join(" ");

                            setProp(node.id, (props) => {
                                props[propKey] = onChange ? onChange(valueWithUnit) : valueWithUnit;
                            });
                        }}
                        onFocus={(e) => {
                            e.target.style.borderColor = '#007bff';
                            e.target.style.boxShadow = '0 0 5px rgba(0, 123, 255, 0.5)';
                        }}
                        onBlur={(e) => {
                            e.target.style.borderColor = '#ccc';
                            e.target.style.boxShadow = 'none';
                        }}
                    >
                        {unitOptions?.map((option) => (
                            <option
                                style={{
                                    fontFamily: 'Inter, sans-serif',
                                    fontWeight: '400',
                                    fontSize: '1.4rem',
                                    color: '#333',
                                }}
                                key={option} 
                                value={option}
                            >
                                {option}
                            </option>
                        ))}
                    </select>

                    </div>
                </div>
            </div>
        </div>
    )
}