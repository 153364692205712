/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import { useEditor, useNode } from '@craftjs/core';
import {
  Grid,
  Divider,
} from '@mui/material';
import { Transition } from 'react-transition-group';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { useDarkMode } from '../../../DarkModeContext';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';


import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import { ToolbarItem } from '..';
import { LikeIcon } from '../Viewport/Pop-up/svgArray';
const usePanelStyles = makeStyles((_) => ({
  root: {
    overflowY: 'scroll',
    overflowX: "hidden",
    boxShadow: 'none',
    '&:before': {
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
    },
    '&.Mui-expanded': {
      margin: '0 0',
      minHeight: '7%',
      '&:before': {
        opacity: '1',
      },
      '& + .MuiExpansionPanel-root:before ': {
        display: 'block'
      },
    },
  },
}));

const useSummaryStyles = makeStyles((_) => ({
  root: {
    'min-height': 'auto',
    padding: 0,
    display: "flex",
    alignItems: "flex-start"
  },
  content: {
    marginLeft: '0px',

  },
}));

export const ToolbarSection = ({ big, title, children, props, toolbox, expanded, setExpanded, summary, icon }) => {

  const panelClasses = usePanelStyles({});
  const summaryClasses = useSummaryStyles({});
  const { isDarkMode } = useDarkMode();
  const [expandedd, setExpandedd] = useState(false)

  return (
    <div style={{ display: "flex", flexDirection: 'column', justifyContent: "center", alignItems: "center", width: '100%' }}>
      {
        !toolbox && (
          <div
            onClick={() => {
              setExpanded ? setExpanded(!expanded) : setExpandedd(!expandedd)
            }}
            style={{
              height: '7vmin',
              borderBottom: `.25vmin solid ${isDarkMode? '#e9e9e930' : '#e9e9e9'}`
            }}
            className="flex flex-row justify-between w-[100%] items-center cursor-pointer"
            id="ProjectNameRoot"
          >
            <div

              className={` ${isDarkMode ? expanded || expandedd ? 'text-[#FFFFFF]' : 'text-[#FFFFFFA1]' : expanded || expandedd ? 'text-[#161717]' : 'text-[#989191]'}`}
              style={{ fontSize: '2.5Vmin', paddingLeft: "2%", fontWeight: '500', fontFamily: 'Inter', transition:'all .5s ease-in', width:'fit-content', height:'100%', alignItems:'center', alignContent:'center', lineHeight:'100%'}}
              id="ProjectName"
            >
              {title}
            </div>
            <div style={{ width: "2.5Vmin", margin:'auto auto', flex:1}}>
            <svg style={{float:'right', marginRight:'1vmin', transition:'all .3s ease-in', transform: expanded || expandedd ? 'rotate(90deg)' : ''}} aria-hidden="true" focusable="false" role="img" className="octicon octicon-chevron-right" viewBox="0 0 12 12" width="2.5Vmin" height="2.5Vmin" fill={isDarkMode ? expanded || expandedd ? '#FFFFFF' : '#FFFFFFA1' :  expanded || expandedd ? '#161717' : '#989191'}><path d="M4.7 10c-.2 0-.4-.1-.5-.2-.3-.3-.3-.8 0-1.1L6.9 6 4.2 3.3c-.3-.3-.3-.8 0-1.1.3-.3.8-.3 1.1 0l3.3 3.2c.3.3.3.8 0 1.1L5.3 9.7c-.2.2-.4.3-.6.3Z"></path></svg>
            </div>
          </div>
        )
      }


      <Accordion style={{
        minWidth: "0px", width: "95%", overflow: "visible", background: isDarkMode ? "#111F3E" : "", margin: "0px", padding: "0px", paddingRight: "0px", height: 'fit-content',

      }} expanded={expanded || expandedd} classes={panelClasses}>

        <AccordionSummary style={{
          width: "100%", minHeight: "0px", height: toolbox ? "fit-content" : "0vh", marginTop: "0px", margin: "0px", padding: "0px", 

        }} classes={summaryClasses}>
          {!toolbox ? null : (
            <div
              style={{ width: "100%", display: "flex", marginBottom: '1Vmin' }}
              className="flex flex-row justify-start items-center"
              id="BannerRoot"
              onClick={() => {
                setExpanded ? setExpanded(!expanded) : setExpandedd(!expandedd)
              }}
            >


              <div style={{ width: "2.5Vmin" }}>
              <svg style={{float:'right', marginLeft:'1vmin', transition:'all .3s ease-in', transform: expanded || expandedd ? 'rotate(90deg)' : ''}} aria-hidden="true" focusable="false" role="img" className="octicon octicon-chevron-right" viewBox="0 0 12 12" width="2.5Vmin" height="2.5Vmin" fill={isDarkMode ? expanded || expandedd ? '#FFFFFF' : '#FFFFFFA1' :  expanded || expandedd ? '#161717' : '#989191'}><path d="M4.7 10c-.2 0-.4-.1-.5-.2-.3-.3-.3-.8 0-1.1L6.9 6 4.2 3.3c-.3-.3-.3-.8 0-1.1.3-.3.8-.3 1.1 0l3.3 3.2c.3.3.3.8 0 1.1L5.3 9.7c-.2.2-.4.3-.6.3Z"></path></svg>
              </div>


              <div style={{ width: "3.5Vmin" }} className={` ${isDarkMode ? 'text-[#FFFFFF]' : 'text-[#161717]'}`}>
                {icon ? icon : null}
              </div>
              <div style={{ fontSize: '2.2Vmin', }} className={`font-['Inter'] ${isDarkMode ? 'text-[#FFFFFF]' : 'text-[#161717]'}`}> {title}</div>
              
            </div>


          )}
        </AccordionSummary>
          <Transition in={expanded || expandedd} timeout={100} mountOnEnter unmountOnExit>
            {(state) => (
              <AccordionDetails style={{
                padding: '0Vmin',
                height: '100%',
                color: isDarkMode ? '#FFFFFF' : '#161717',
                display: 'flex',
                flexDirection: 'column',
                transition: 'height 300ms ease-in-out',
                height: state === 'entered' ? 'auto' : 0,
              }}>
                <Grid style={{ width: '100%', justifyContent: 'center', alignItems: 'center', padding:!toolbox && '1vmin 0'}} container>
                  {children}
                </Grid>
                {!toolbox && !big && <div className='shadow-2xl' style={{ borderBottom: '0.1Vmin solid #e9e9e9', margin: 'auto auto' }}></div>}
              </AccordionDetails>
            )}
          </Transition>
        </Accordion>

        
    </div>
  );
};
