/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, { useState } from 'react';
import { useDarkMode } from '../../../../DarkModeContext';

export const TabOption = ({activeTab, setActiveTab, noContent, noAdvanced}) => {
    const { isDarkMode } = useDarkMode();

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
      };

  return (
    <div className="relative w-[100%] rounded-[.5vmin]" style={{padding:'0', display:'flex', alignContent:'center', alignItems:'center'}}>
      <div className="flex w-[100%]  justify-between flex-row " style={{ borderBottom:'0.7vmin solid #e9e9e9', padding:".5vmin 0", alignItems:'center', borderBottomColor:isDarkMode? 'rgba(233, 233, 234, 0.4)' : '#E9E9EA'}}>
      <div style={{fontSize:'2.4Vmin', height:'fit-content', flex:1, display:noContent && 'none'}} onClick={() => handleTabClick("Content")} className={` font-['Inter'] cursor-pointer text-center font-bold ${activeTab == "Content" ? "text-[#0867fc]" : `${isDarkMode? 'text-[#E9E9EA]' : "text-[#161717] opacity-[60%]"}`}`}>
        Content
      </div>
      <div style={{fontSize:'2.4Vmin', height:'fit-content', flex:1}} onClick={() => handleTabClick("Style Manager")} className={`font-['Inter']  cursor-pointer text-center font-bold ${activeTab == "Style Manager" ? "text-[#0867fc]" : `${isDarkMode? 'text-[#E9E9EA]' : "text-[#161717] opacity-[60%]"}`}`}>
        Style
      </div>
      <div style={{fontSize:'2.4Vmin', height:'fit-content', flex:1, display: noAdvanced && 'none'}} onClick={() => handleTabClick("Advanced")} className={`cursor-pointer text-center  font-['Inter'] font-bold ${activeTab == "Advanced" ? "text-[#0867fc]" : `${isDarkMode? 'text-[#E9E9EA]' : "text-[#161717] opacity-[60%]"}`}`}>
      Advanced
      </div>
      </div>
      {/* Blue highlight line */}
      <div
        className="absolute bottom-0 left-0"
        style={{
          display: noAdvanced && noContent && 'none',
          width: noContent ? noAdvanced? '100%' : '50%' : "35%",
          height: '16%',
          borderRadius:'3Vmin',
          backgroundColor: isDarkMode? '#3C90F2' : '#0867FC',
          transform: `translateX(${
            activeTab === 'Advanced'
              ? noContent ? '100%' : '185%'
              : activeTab === 'Style Manager'
              ? noContent ? '0%' : '93%'
              : activeTab === 'Content' ? '1%'  : null
          })`,
          // transition: 'transform 0.3s ease'
        }}
      ></div>
    </div>
  );
};