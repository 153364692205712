/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, { useEffect, useRef, useState } from 'react';

import { Element, useEditor, useNode } from '@craftjs/core';
import { NewContentSettings } from './newContentSetting';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import { Text } from '../../basic/Text';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import { ImageC } from '../../basic/Image';
import { Button } from '../../basic/Button';
import { Parent } from '../../../selectors/Parent/index';
import { Wrapper } from '../../../selectors/wrapper/index';
import { BounceLoader } from 'react-spinners';



export const Content_3 = ({ selected }) => {

  const { 
    actions: { setProp },
      connectors: {
        connect
      } 
  } = useNode();

  
  const {
    enabled,
  } = useEditor((state, query) => ({
    enabled: state.options.enabled,
 
  }));


    const styles = [
        <Element canvas is={Parent} id='parentId100' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId200' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-400' is='parent' className='w-[100%] h-full py-10' style={{background:'white'}} >
            <Wrapper canvas id='random-id-401' is='container' className='w-[100%] h-full flex flex-row flex-wrap'>
                
                <Wrapper canvas id='random-id-402' is='div' className='max-h-[550px] min-h-[180px] max-h-[500px] p-4 bg-[#2479FD]' style={{borderRadius: '0px 24px 24px 0px', flex:'45%'}}>
                    <Wrapper  canvas style={{display:'inline-block', width:'100%', height:'100%'}} id='random-id-100' is='div'>
                        <ImageC className='w-[100%] h-[100%] object-cover' style={{borderRadius:'0px 18px 18px 0px'}} src='https://i.ibb.co/df2btHp/content3.jpg'/>
                    </Wrapper>
                </Wrapper>

                <Wrapper canvas id='random-id-403' is='div' className='h-full my-auto px-10 ' style={{flex:'45%'}}>
                   
                   <Wrapper canvas id='random-id-404' is='div' className={`w-full flex flex-col my-4 `}>
                            <Wrapper canvas style={{display:'inline-block', fontWeight:'700', fontFamily:'Poppins', color:'#000000', marginBottom:'15px'}} className={`text-[46px]`} id='random-id-1' is='div'>
                                <Text text='Understand User Flow'/>
                            </Wrapper>
                            <Wrapper canvas style={{display:'inline-block', fontWeight:'400', fontFamily:'Poppins', color:'#100103', lineHeight:'30.8px'}} className={`text-[22px]`} id='random-id-2' is='div'>
                                <Text text='Sed sed risus pretium quam vulputate dignissim. Eget nunc lobortis mattis aliquam faucibus purus in. Potenti nullam ac tortor vitae purus faucibus ornare suspendisse.'/>
                            </Wrapper>
                    </Wrapper>

                    <Wrapper canvas id='random-id-406' is='div' className={`w-full flex flex-col my-4 `}>
                        <Wrapper canvas style={{display:'inline-block', fontFamily:'Poppins', fontWeight:'400'}} className={`text-[22px]`} id='random-id-3' is='div'>
                            <Text text='Commodo ullamcorper a lacus vestibulum sed arcu non odio. Lectus arcu bibendum at varius. Suspendisse sed nisi lacus sed viverra tellus in.'/>
                        </Wrapper>
                        <Wrapper canvas id='random-id-200' is='div' className={`w-[100%] flex items-center `} style={{borderRadius:'14px'}}>
                            <Button  classn='block' class='w-[280px] h-[60px] py-[12px] bg-[#FFC92E] hover:bg-[#FFF5D7] border-1 border-[#FFC92E] hover:border-[#FFC92E]  flex justify-center cursor-pointer mt-4 text-[#000000]' style={{ borderRadius:'14px'}}>
                                <Wrapper canvas style={{display:'inline-block', fontSize:'22px', fontWeight:'500', fontFamily:'Poppins',}} id='random-id-4' is='div'>
                                    <Text text='Get Started'/>
                                </Wrapper>
                                {/* <div className='mx-6'>
                                    <ArrowCircleRightOutlinedIcon sx={{color:'#000000', fontSize:'28px'}}/>
                                </div> */}
                            </Button>
                        </Wrapper>
                   </Wrapper>
                </Wrapper>
            </Wrapper>
        </Wrapper></Element>
        </Element>,
        <Element canvas is={Parent} id='parentId300' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId400' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-401' is='parent' className='w-[100%] h-full py-10' style={{background:'white'}} >
        <Wrapper canvas id='random-id-402' is='container' className='w-[100%] h-full flex flex-row flex-wrap'>
            
            <Wrapper canvas id='random-id-403' is='div' className='max-h-[550px] min-h-[180px] max-h-[500px p-4 bg-[#F6FA70]' style={{borderRadius: '0px 18px 18px 0px', flex:'45%'}}>
                <Wrapper  canvas style={{display:'inline-block', width:'100%', height:'100%'}} id='random-id-101' is='div'>
                    <ImageC className='w-[100%] h-[100%] object-cover' style={{borderRadius:'0px 18px 18px 0px'}} src='https://i.ibb.co/fMbmxjk/content3-4.jpg'/>
                </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-404' is='wrapper' className='h-full my-auto px-10 ' style={{flex:'45%'}}>
               
               <Wrapper canvas id='random-id-405' is='div' className={`w-full flex flex-col my-4 `}>
                        <Wrapper canvas style={{display:'inline-block', fontWeight:'700', fontFamily:'Prompt', color:'#000000', marginBottom:'15px'}} className={`text-[46px]`} id='random-id-5' is='div'>
                            <Text text='Understand User Flow'/>
                        </Wrapper>
                    <Wrapper canvas id='random-id-406' is='div' className={`text-[22px]`} style={{fontWeight:'400', fontFamily:'Prompt', color:'#100103', lineHeight:'30.8px'}}>
                        <Wrapper canvas style={{display:'inline-block'}} id='random-id-6' is='div'>
                            <Text text='Sed sed risus pretium quam vulputate dignissim. Eget nunc lobortis mattis aliquam faucibus purus in. Potenti nullam ac tortor vitae purus faucibus ornare suspendisse.'/>
                        </Wrapper>
                    </Wrapper>
                </Wrapper>

                <Wrapper canvas id='random-id-408' is='div' className={`w-full flex flex-col my-4 `}>
                    <Wrapper canvas style={{display:'inline-block', fontFamily:'Prompt', fontWeight:'400'}} className={`text-[22px]`} id='random-id-7' is='div'>
                        <Text text='Commodo ullamcorper a lacus vestibulum sed arcu non odio. Lectus arcu bibendum at varius. Suspendisse sed nisi lacus sed viverra tellus in.'/>
                    </Wrapper>
                    <Wrapper canvas id='random-id-201' is='div' className={`w-[100%] flex items-center`} style={{borderRadius:'30px'}}>
                        <Button  classn='block' class='w-[280px] h-[60px] py-[12px] bg-[#FF0060] hover:bg-[#C20049] flex justify-center cursor-pointer mt-4 text-[#FFF]' style={{ borderRadius:'30px'}}>
                            <Wrapper canvas style={{display:'inline-block', fontSize:'22px', fontWeight:'500', fontFamily:'Prompt',}} id='random-id-8' is='div'>
                                <Text text='Get Started'/>
                            </Wrapper>
                            {/* <div className='mx-6'>
                                <ArrowCircleRightOutlinedIcon sx={{color:'#FFF', fontSize:'28px'}}/>
                            </div> */}
                        </Button>
                    </Wrapper>
               </Wrapper>
            </Wrapper>
        </Wrapper>
    </Wrapper></Element>
        </Element>,

        
<Element canvas is={Parent} id='parentId500' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId600' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-409' is='parent' className='w-[100%] h-full py-10' style={{background:'#FDF4F4'}} >
<Wrapper canvas id='random-id-410' is='container' className='w-[100%] h-full flex flex-row flex-wrap'>
    
 <Wrapper canvas id='random-id-411' is='div'  className='max-h-[550px] min-h-[180px] max-h-[500px p-4 bg-[#FFB7B7]' style={{borderRadius: '0px 18px 18px 0px', flex:'45%'}}>
        <Wrapper  canvas style={{display:'inline-block', width:'100%', height:'100%'}} id='random-id-103' is='div'>
            <ImageC className='w-[100%] h-[100%] object-cover' style={{borderRadius:'0px 18px 18px 0px'}} src='https://i.ibb.co/2FGKtyP/content3-3.jpg'/>
        </Wrapper>
    </Wrapper>

    <Wrapper canvas id='random-id-412' is='wrapper'  className='h-full my-auto px-10 ' style={{flex:'45%'}}>
       
       <Wrapper canvas id='random-id-413' is='div' className={`w-full flex flex-col my-4 `}>
                <Wrapper canvas style={{display:'inline-block', fontWeight:'700', fontFamily:'Almarai', color:'#000000', marginBottom:'15px'}} className={`text-[46px]`} id='random-id-9' is='div'>
                    <Text text='Understand User Flow'/>
                </Wrapper>
                <Wrapper canvas style={{display:'inline-block', fontWeight:'400', fontFamily:'Almarai', color:'#100103', lineHeight:'30.8px'}} className={`text-[22px]`} id='random-id-10' is='div'>
                    <Text text='Sed sed risus pretium quam vulputate dignissim. Eget nunc lobortis mattis aliquam faucibus purus in. Potenti nullam ac tortor vitae purus faucibus ornare suspendisse.'/>
                </Wrapper>
        </Wrapper>

        <Wrapper canvas id='random-id-415' is='div'  className={`w-full flex flex-col my-4 `}>
            <Wrapper canvas style={{display:'inline-block', fontFamily:'Almarai', fontWeight:'400'}} className={`text-[22px]`} id='random-id-11' is='div'>
                <Text text='Commodo ullamcorper a lacus vestibulum sed arcu non odio. Lectus arcu bibendum at varius. Suspendisse sed nisi lacus sed viverra tellus in.'/>
            </Wrapper>
            <Wrapper canvas id='random-id-202' is='div' className={`w-[100%] flex items-center `} style={{borderRadius:'8px'}}>
                <Button  classn='block' class='w-[280px] h-[60px] py-[12px] bg-[#F31559] hover:bg-[#FF5B8D] flex justify-center cursor-pointer mt-4 text-[#FFFFFF]' style={{ borderRadius:'8px'}}>
                    <Wrapper canvas style={{display:'inline-block', fontSize:'22px', fontWeight:'500', fontFamily:'Almarai',}} id='random-id-12' is='div'>
                        <Text text='Get Started'/>
                    </Wrapper>
                    {/* <div className='mx-6'>
                        <ArrowCircleRightOutlinedIcon sx={{color:'#FFF', fontSize:'28px'}}/>
                    </div> */}
                </Button>
            </Wrapper>
       </Wrapper>
    </Wrapper>
</Wrapper>
</Wrapper></Element>
        </Element>,

 
<Element canvas is={Parent} id='parentId700' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId800' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-416' is='parent' className='w-[100%] h-full py-10' style={{background:'#F6F2FA'}} >
<Wrapper canvas id='random-id-417' is='container' className='w-[100%] h-full flex flex-row flex-wrap'>
  <Wrapper canvas id='random-id-418' is='div' className='max-h-[550px] min-h-[180px] max-h-[500px p-4 bg-[#0C134F]' style={{borderRadius: '0px 6px 6px 0px', flex:'45%'}}>
        <Wrapper  canvas style={{display:'inline-block', width:'100%', height:'100%'}} id='random-id-105' is='div'>
            <ImageC className='w-[100%] h-[100%] object-cover' style={{borderRadius:'0px 6px 6px 0px'}} src='https://i.ibb.co/xqfPWXZ/content3-2.jpg'/>
        </Wrapper>
    </Wrapper>

    <Wrapper canvas id='random-id-419' is='wrapper' className='h-full my-auto px-10' style={{flex:'45%'}}>
       
       <Wrapper canvas id='random-id-420' is='div' className={`w-full flex flex-col my-4 `}>
                <Wrapper canvas style={{display:'inline-block', fontWeight:'700', fontFamily:'Chavi', color:'#000000', marginBottom:'15px'}} className={`text-[46px]`} id='random-id-13' is='div'>
                    <Text text='Understand User Flow'/>
                </Wrapper>
                <Wrapper canvas style={{display:'inline-block', fontWeight:'400', fontFamily:'Chavi', color:'#100103', lineHeight:'30.8px'}} className={`text-[22px]`} id='random-id-14' is='div'>
                    <Text text='Sed sed risus pretium quam vulputate dignissim. Eget nunc lobortis mattis aliquam faucibus purus in. Potenti nullam ac tortor vitae purus faucibus ornare suspendisse.'/>
                </Wrapper>
        </Wrapper>

        <Wrapper canvas id='random-id-422' is='div' className={`w-full flex flex-col my-4 `}>
            <Wrapper canvas style={{display:'inline-block', fontFamily:'Chavi', fontWeight:'400'}} className={`text-[22px]`} id='random-id-15' is='div'>
                <Text text='Commodo ullamcorper a lacus vestibulum sed arcu non odio. Lectus arcu bibendum at varius. Suspendisse sed nisi lacus sed viverra tellus in.'/>
            </Wrapper>
            <Wrapper canvas id='random-id-203' is='div' className={`w-[100%] flex items-center `} style={{borderRadius:'8px'}}>
                <Button  classn='block' class='w-[280px] h-[60px] py-[12px] bg-[#D4ADFC] hover:bg-[#C68EFF] flex justify-center cursor-pointer mt-4 text-[#0C134F]' style={{borderRadius:'8px'}}>
                    <Wrapper canvas style={{display:'inline-block', fontSize:'22px', fontWeight:'500', fontFamily:'Chavi', }} id='random-id-16' is='div'>
                        <Text text='Get Started'/>
                    </Wrapper>
                    {/* <div className='mx-6'>
                        <ArrowCircleRightOutlinedIcon sx={{color:'#0C134F', fontSize:'28px'}}/>
                    </div> */}
                </Button>
            </Wrapper>
       </Wrapper>
    </Wrapper>
</Wrapper>
</Wrapper></Element>
        </Element>,
    ]

    const [sel, setSel] = useState(selected)

    function makeOdd(number) {
      setProp((prop) => {
        setSel(100);
      }, 1000);

      setTimeout(() => {
        setProp((prop) => {
          setSel(number)
        }, 1000);
      }, 100);


      
  }

  useEffect(() => {
    if(selected || selected >= 0) {
      makeOdd(selected);
    }
  },[selected])



    return (

        <div style={{ padding: enabled? '10px' : "0px", display:"flex", justifyContent:"center", alignItems:"center", width:'100%', height:'100%'}} ref={connect}>
        {sel == 100 ? (
          <div style={{height:"85vh", display:"flex", justifyContent:"center", alignItems:"center"}}>
          <BounceLoader
                  size={100}
                  color={"white"}
                  loading={true}
                />
          </div>
         ) : (
          styles[sel]
         )} 
    </div>

    )}

    Content_3.craft = {
    displayName: "Content 3",
    props: {
    selected: 1, // here is the default value of the selected style
    length:3,
isBlock: true
    },
    rules: {
    canDrag: () => true,
    canDrop: () => true,
    },
    related: {
    toolbar: NewContentSettings,
    },
    };