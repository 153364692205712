/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import { Element, useEditor, useNode } from "@craftjs/core";
import React, { useEffect, useRef, useState } from "react";
import { NewContactSettings } from "./newContactSetting";
import EmailIcon from '@mui/icons-material/Email';
import { Text } from "../../basic/Text";
import '../Form/Form.css'
import { ImageC } from "../../basic/Image";
import { Button } from "../../basic/Button";
import { Input } from "../../basic/Input/Index";
import { Parent } from "../../Parent/index.js";
import { Wrapper } from "../../wrapper/index.js";
import { BounceLoader } from "react-spinners";

export const Contact_1 = ({selected}) => {

    const { 
      actions: { setProp },
        connectors: {
          connect
        } 
    } = useNode();
  
    
    const {
      enabled,
    } = useEditor((state, query) => ({
      enabled: state.options.enabled,
   
    }));

    const styles = [
        <Element canvas is={Parent} id='parentId100' className="w-[100%] h-auto" >
      <Element canvas is={Parent} id='parentId200' className="w-[100%] h-auto" row={true}><Wrapper canvas id='random-id-400' is='parent' className="w-[100%] h-full bg-[white] py-10" >
            <Wrapper canvas id='random-id-401' is='container' className="container" >
                <Wrapper canvas id='random-id-402' is='wrapper' className="w-[100%] h-full flex flex-row flex-wrap flex justify-center border" >
                    <Wrapper canvas id='random-id-403' is='div' className="items-center h-full flex justify-center" style={{flex:'48%'}}>
                        <Wrapper canvas id='random-id-404' is='div' className={`w-full max-w-[50%] h-full min-w-[358px]`}>
                            <Wrapper canvas style={{ display: "inline-block", fontWeight:'700', fontFamily:'Rubik' }} className={`w-full my-5 text-[#100103] text-[46px]`} id="random-id-1" is="div">
                              <Text text="Contact Us" />
                            </Wrapper>
                            <Wrapper canvas style={{ display: "inline-block", fontWeight:'400', fontFamily:'Rubik', color:'#100103', lineHeight:'28px'}} className={`w-full flex justify-center text-[18px]`} id="random-id-2" is="div">
                              <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                            </Wrapper>
                        <Wrapper canvas id='random-id-405' is='form'  className="w-full flex flex-col my-5 flex justify-center ">
                            <Input className="input-4 my-2 form-control bg-transparent text-[#100103]" style={{border:'1px solid #B2031233', height:'50px', padding:'20px', fontSize:'18px'}} name='name' type='text' value={'#'} placeholder="Enter your name"/>
                            <Input className="input-4 my-2 form-control bg-transparent text-[#100103]" style={{border:'1px solid #B2031233', height:'50px', padding:'20px',  fontSize:'18px'}} name='email' type='email' value={'#'} placeholder="Enter your email"/>
                            <Input className="input-4 my-2 form-control bg-transparent text-[#100103]" style={{border:'1px solid #B2031233', padding:'20px 20px 130px 20px',  fontSize:'18px'}} name='message' type='text' value={'#'} placeholder="Enter your message"/>
                        </Wrapper>
                        <Wrapper canvas id='random-id-200' is='div' style={{width:'260px', height:'70px'}}>
                            <Button  classn='block' className="flex cursor-pointer my-5 flex bg-[#B20312] hover:bg-[#87000C] font-bold uppercase text-[#FFF] justify-center" style={{ width:'260px', height:'70px', boxShadow: '0px 4px 10px 0px #0000001A'}}>
                              <Wrapper canvas style={{ display: "inline-block", fontSize:'22px', fontFamily:'Rubik', fontWeight:'600', }} className='flex justify-center flex items-center' id="random-id-3" is="div">
                                <Text text="Contact Us" />
                              </Wrapper>
                            </Button>
                        </Wrapper>
                        </Wrapper>
                    </Wrapper>
                      <Wrapper canvas id='random-id-406' is='div' className="w-full px-20 justify-center flex items-center" style={{flex:'48%'}}>
                          <img className="w-[100%] flex justify-center h-[100%] min-h-auto max-h-[600px] items-align object-cover" alt="map" src="https://i.ibb.co/hYZYbp9/image.png"/>
                      </Wrapper>
                </Wrapper>
            </Wrapper>
        </Wrapper>
        </Element>
      </Element>,

        <Element canvas is={Parent} id='parentId100000' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId2002000' className="w-[100%] h-auto" row={true} >
        <Wrapper canvas id='random-id-407' is='parent' className="w-[100%] h-full bg-[#121826] py-10" >
            <Wrapper canvas id='random-id-408' is='container' className="container" >
                <Wrapper canvas id='random-id-409' is='wrapper' className="w-[100%] h-full flex justify-center" style={{flexDirection:'row', flexWrap:'wrap'}}>
                    <Wrapper canvas id='random-id-410' is='div' className="h-full flex items-center w-[100%] flex justify-center" style={{flex:'48%'}}>
                        <Wrapper canvas id='random-id-411' is='div' className={`w-full max-w-[50%] h-full min-w-[358px] `}>
                             <Wrapper canvas style={{ display: "inline-block", fontWeight:'700', fontFamily:'Rubik' }} className={`w-full my-5 IncreaseConversion2 text-[46px]`} id="random-id-4" is="div">
                    <Text text="Contact Us" />
                  </Wrapper>
                             <Wrapper canvas style={{ display: "inline-block", fontWeight:'400', fontFamily:'Rubik', color:'#FFFFFF', lineHeight:'28px' }} className={`w-full flex justify-center text-[18px]`}  id="random-id-5" is="div">
                    <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                  </Wrapper>
                        <Wrapper canvas id='random-id-413' is='form' className="w-full flex flex-col my-5 justify-center ">
                            <Input className="inpute my-2 form-control bg-transparent text-white" style={{border:'1px solid #3265E366', height:'50px', padding:'20px', fontSize:'18px'}} name='name' type='text' value={'#'} placeholder="Enter your name"/>
                            <Input className="inpute my-2 form-control bg-transparent text-white" style={{border:'1px solid #3265E366', height:'50px', padding:'20px',  fontSize:'18px'}} name='email' type='email' value={'#'} placeholder="Enter your email"/>
                            <Input className="inpute my-2 form-control bg-transparent text-white" style={{border:'1px solid #3265E366', padding:'20px 20px 130px 20px',  fontSize:'18px'}} name='message' type='text' value={'#'} placeholder="Enter your message"/>
                        </Wrapper>
                        <Wrapper canvas id='random-id-201' is='div'  style={{ width:'260px', height:'70px'}}>
                            <Button  classn='block' className="flex  font-bold uppercase cursor-pointer my-5 justify-center flex bg-[#3265E3] hover:bg-[#0049FF] text-[#FFF]" style={{width:'260px', height:'70px'}}>
                              <Wrapper canvas style={{ display: "inline-block", fontSize:'22px', fontFamily:'Rubik', fontWeight:'600' }} className='flex items-center flex justify-center align-items-center' id="random-id-6" is="div">
                                <Text text="Contact Us" />
                              </Wrapper>
                            </Button>
                        </Wrapper>
                        </Wrapper>
                    </Wrapper>
                      <Wrapper canvas id='random-id-414' is='div' className="w-full px-20 justify-center  flex items-center" style={{flex:'48%'}}>
                        <img className="w-[100%] flex justify-center h-[100%] object-cover  min-h-auto max-h-[600px] items-align " src="https://i.ibb.co/hYZYbp9/image.png"/>
                      </Wrapper>
                </Wrapper>
            </Wrapper>
            </Wrapper>
            </Element>
            </Element>,

        <Element canvas is={Parent} id='parentId300' className="w-[100%] h-auto" >
      <Element canvas is={Parent} id='parentId400' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-415' is='parent' className="w-[100%] h-full bg-[#213156] py-10" >
        <Wrapper canvas id='random-id-416' is='container' className="container" >
            <Wrapper canvas id='random-id-417' is='div' className="w-[100%] h-full flex flex-row flex-wrap flex justify-center" >
                <Wrapper canvas id='random-id-418' is='div' className="h-full flex items-center w-[100%] flex justify-center" style={{flex:'48%'}}>
                    <Wrapper canvas id='random-id-419' is='div' className={`w-full max-w-[70%] h-full min-w-[358px] `}>
                         <Wrapper canvas style={{ display: "inline-block", fontWeight:'700', fontFamily:'Times New Roman' }} className={`w-full my-5 text-[#EA6EF4] text-[46px]`} id="random-id-7" is="div">
                    <Text text="Contact Us" />
                  </Wrapper>
                         <Wrapper canvas style={{ display: "inline-block", fontWeight:'400', fontFamily:'Rubik', color:'#FFFFFF', lineHeight:'28px' }} className={`w-full flex justify-center text-[18px]`} id="random-id-8" is="div">
                    <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                  </Wrapper>
                    <Wrapper canvas id='random-id-421' is='div'  className="w-full flex flex-col my-5 flex justify-center ">
                        <Input className="inpute my-2 form-control bg-transparent text-white" style={{border:'1px solid #EA6EF466', borderRadius:'10px', height:'50px', padding:'20px', fontSize:'18px'}} name='name' type='text' value={'#'} placeholder="Enter your name"/>
                        <Input className="inpute my-2 form-control bg-transparent text-white" style={{border:'1px solid #EA6EF466', borderRadius:'10px', height:'50px', padding:'20px',  fontSize:'18px'}} name='email' type='email' value={'#'} placeholder="Enter your email"/>
                        <Input className="inpute my-2 form-control bg-transparent text-white" style={{border:'1px solid #EA6EF466', borderRadius:'10px', padding:'20px 20px 130px 20px',  fontSize:'18px'}} name='message' type='text' value={'#'} placeholder="Enter your message"/>
                    </Wrapper>
                    <Wrapper canvas id='random-id-202' is='div'  style={{ width:'260px', height:'70px', borderRadius:'40px'}}>
                        <Button  classn='block' className="flex cursor-pointer my-5 justify-center flex bg-[#EA6EF4] hover:bg-[#E111F2] font-bold uppercase text-[#FFF]" style={{width:'260px', height:'70px',  borderRadius:'40px'}}>
                          <Wrapper canvas style={{ display: "inline-block", fontSize:'22px', fontFamily:'Open Sans', fontWeight:'600', }} className='flex items-center flex justify-center align-items-center' id="random-id-9" is="div">
                            <Text text="Contact Us" />
                          </Wrapper>
                        </Button>
                    </Wrapper>
                    </Wrapper>
                </Wrapper>
                
                  <Wrapper canvas id='random-id-422' is='div' className="w-full px-20 justify-center  flex items-center" style={{flex:'48%'}}>
                      <img className="w-[100%] flex justify-center h-[100%] object-cover min-h-auto max-h-[600px] items-align" style={{borderRadius:'30px 0px 30px 0px'}} alt="map" src="https://i.ibb.co/hYZYbp9/image.png"/>
                  </Wrapper>
            </Wrapper>
        </Wrapper>
    </Wrapper></Element>
      </Element>,
    
        <Element canvas is={Parent} id='parentId500' className="w-[100%] h-auto" >
      <Element canvas is={Parent} id='parentId600' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-423' is='parent' className="w-[100%] h-full bg-[#DFFFF9] py-10" >
        <Wrapper canvas id='random-id-424' is='container' className="container" >
            <Wrapper canvas id='random-id-425' is='wrapper' className="w-[100%] h-full flex flex-row flex-wrap flex justify-center" >
                <Wrapper canvas id='random-id-426' is='div' className="flex items-center h-full w-[100%] flex justify-center" style={{flex:'48%'}}>
                    <Wrapper canvas id='random-id-427' is='div' className={`w-full max-w-[70%] h-full min-w-[358px] `}>
                         <Wrapper canvas style={{ display: "inline-block", fontWeight:'100px', fontFamily:'Zing Script Rust SemiBold Demo', fontStyle:'italic' }} className={`w-full my-5 text-[#31A993] text-[46px]`} id="random-id-10" is="div">
                    <Text text="Contact Us" />
                  </Wrapper>
                         <Wrapper canvas style={{ display: "inline-block", fontWeight:'400', fontFamily:'Montserrat', color:'#000', lineHeight:'28px'}} className={`w-full flex justify-center text-[18px]`} id="random-id-11" is="div">
                    <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                  </Wrapper>
                    <Wrapper canvas id='random-id-429' is='div'  className="w-full flex flex-col my-5 flex justify-center ">
                        <Input className="inputt my-2 form-control bg-transparent text-[#000]" style={{border:'1px solid #31A99366', height:'50px', padding:'20px', fontSize:'18px', borderRadius:'30px'}} name='name' type='text' value={'#'} placeholder="Enter your name"/>
                        <Input className="inputt my-2 form-control bg-transparent text-[#000]" style={{border:'1px solid #31A99366', height:'50px', padding:'20px',  fontSize:'18px', borderRadius:'30px'}} name='email' type='email' value={'#'} placeholder="Enter your email"/>
                        <Input className="inputt my-2 form-control bg-transparent text-[#000]" style={{border:'1px solid #31A99366', padding:'20px 20px 130px 20px',  fontSize:'18px', borderRadius:'30px'}} name='message' type='text' value={'#'} placeholder="Enter your message"/>
                    </Wrapper>
                    <Wrapper canvas id='random-id-203' is='div'  style={{ width:'260px', height:'70px', borderRadius:'40px'}}>
                        <Button  classn='block' className="flex cursor-pointer my-5 justify-center flex bg-[#31A993] hover:bg-[#11816C] font-bold uppercase text-[#FFF]" style={{width:'260px', height:'70px',  borderRadius:'40px'}}>
                          <Wrapper canvas style={{ display: "inline-block", fontSize:'22px', fontFamily:'Open Sans', fontWeight:'600', }} className='flex items-center flex justify-center align-items-center' id="random-id-12" is="div">
                            <Text text="Contact Us" />
                          </Wrapper>
                        </Button>
                    </Wrapper>
                    </Wrapper>
                </Wrapper>
                  <Wrapper canvas id='random-id-430' is='div' className="w-full px-20 justify-center  flex items-center" style={{flex:'48%'}}>
                      <img className="w-[100%] flex justify-center h-[100%] object-cover min-h-auto max-h-[600px] items-align" style={{borderRadius:'30px'}}  src="https://i.ibb.co/hYZYbp9/image.png"/>
                  </Wrapper>
            </Wrapper>
        </Wrapper>
    </Wrapper></Element>
      </Element>,
    
    <Element canvas is={Parent} id='parentId700' className="w-[100%] h-auto" >
      <Element canvas is={Parent} id='parentId800' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-431' is='parent' className="w-[100%] h-full bg-[#FFFFFF] py-10" >
    <Wrapper canvas id='random-id-432' is='container' className="container" >
        <Wrapper canvas id='random-id-433' is='wrapper' className="w-[100%] h-full flex flex-row flex-wrap flex justify-center" >
            <Wrapper canvas id='random-id-434' is='div' className="flex items-center h-full w-[100%] flex justify-center" style={{flex:'48%'}}>
                <Wrapper canvas id='random-id-435' is='div' className={`w-full max-w-[70%] h-full min-w-[358px] `}>
                     <Wrapper canvas style={{ display: "inline-block", fontWeight:'700', fontFamily:'Inter' }} className={`w-full my-5 text-[#0078D3] text-[46px]`} id="random-id-13" is="div">
                    <Text text="Contact Us" />
                  </Wrapper>
                     <Wrapper canvas style={{ display: "inline-block", fontWeight:'400', fontFamily:'Inter', color:'#100103', lineHeight:'28px' }} className={`w-full flex justify-center text-[18px]`} id="random-id-14" is="div">
                    <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                  </Wrapper>
                <Wrapper canvas id='random-id-436' is='div'  className="w-full flex flex-col my-5 flex justify-center ">
                    <Input className="inputt my-2 form-control bg-transparent text-white" style={{border:'1px solid #3265E366', height:'50px', padding:'20px', fontSize:'18px'}} name='name' type='text' value={'#'} placeholder="Enter your name"/>
                    <Input className="inputt my-2 form-control bg-transparent text-white" style={{border:'1px solid #3265E366', height:'50px', padding:'20px',  fontSize:'18px'}} name='email' type='email' value={'#'} placeholder="Enter your email"/>
                    <Input className="inputt my-2 form-control bg-transparent text-white" style={{border:'1px solid #3265E366', padding:'20px 20px 130px 20px',  fontSize:'18px'}} name='message' type='text' value={'#'} placeholder="Enter your message"/>
                </Wrapper>
                <Wrapper id='random-id-204' is='div'  style={{width:'260px', height:'70px', borderRadius:'6px'}}>
                    <Button  classn='block' className="flex cursor-pointer my-5 justify-center flex bg-[#3265E3] hover:bg-[#11578B] font-bold uppercase text-[#FFF]" style={{width:'260px', height:'70px',  borderRadius:'6px', boxShadow: '0px 4px 10px 0px #0000001A'}}>
                      <Wrapper canvas style={{ display: "inline-block", fontSize:'22px', fontFamily:'Inter', fontWeight:'600', }} className='flex items-center flex justify-center align-items-center' id="random-id-15" is="div">
                        <Text text="Contact Us" />
                      </Wrapper>
                    </Button>
                </Wrapper>
                </Wrapper>
            </Wrapper>
              <Wrapper canvas id='random-id-437' is='div' className="w-full px-20 justify-center  flex items-center" style={{flex:'48%'}}>
                  <img className="w-[100%] flex justify-center h-[100%] object-cover min-h-auto max-h-[600px] items-align" src="https://i.ibb.co/hYZYbp9/image.png"/>
              </Wrapper>
            </Wrapper>
        </Wrapper>
    </Wrapper>
    </Element>
      </Element>,
    
    
    <Element canvas is={Parent} id='parentId900' className="w-[100%] h-auto" >
      <Element canvas is={Parent} id='parentId1000' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-438' is='parent' className="w-[100%] h-full bg-[#020212] py-10" >
    <Wrapper canvas id='random-id-439' is='container' className="container" >
        <Wrapper canvas id='random-id-440' is='wrapper' className="w-[100%] h-full flex flex-row flex-wrap flex justify-center" >
            <Wrapper canvas id='random-id-441' is='div' className="flex items-center h-full w-[100%] flex justify-center" style={{flex:'48%'}}>
                <Wrapper canvas id='random-id-442' is='div' className={`w-full max-w-[70%] h-full min-w-[358px] `}>
                     <Wrapper canvas style={{ display: "inline-block", fontWeight:'700', fontFamily:'Voces' }} className={`w-full my-5 uppercase text-[white] text-[46px]`} id="random-id-16" is="div">
                    <Text text="Contact Us" />
                  </Wrapper>
                     <Wrapper canvas style={{ display: "inline-block", fontWeight:'400', color:'white', lineHeight:'28px', fontFamily:'Voces'}} className={`w-full flex justify-center text-[18px]`} id="random-id-17" is="div">
                    <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                  </Wrapper>
                <Wrapper canvas id='random-id-443' is='div'  className="w-full flex flex-col my-5 flex justify-center ">
                    <Input className="inpute my-2 form-control bg-transparent text-white" style={{border:'1px solid #FFFFFF', height:'50px', padding:'20px', fontSize:'18px', fontFamily:'Voces'}} name='name' type='text' value={'#'} placeholder="Enter your name"/>
                    <Input className="inpute my-2 form-control bg-transparent text-white" style={{border:'1px solid #FFFFFF', height:'50px', padding:'20px',  fontSize:'18px', fontFamily:'Voces'}} name='email' type='email' value={'#'} placeholder="Enter your email"/>
                    <Input className="inpute my-2 form-control bg-transparent text-white" style={{border:'1px solid #FFFFFF', padding:'20px 20px 130px 20px',  fontSize:'18px', fontFamily:'Voces'}} name='message' type='text' value={'#'} placeholder="Enter your message"/>
                </Wrapper>
                <Wrapper canvas id='random-id-205' is='div' style={{width:'260px', height:'70px', border:'1px solid #FFFFFF'}} className='my-5'>
                    <Button  classn='block' className="flex cursor-pointer items-center justify-center bg-[#020212] hover:bg-[#FFFFFF] text-[#FFFFFF] hover:text-[#020212] uppercase" style={{width:'260px', height:'70px', border:'1px solid #FFFFFF',  boxShadow: '0px 4px 10px 0px #0000001A'}}>
                      <Wrapper canvas style={{ display: "inline-block", fontSize:'22px', fontWeight:'600', fontFamily:'Voces', }} className='py-[7px]' id="random-id-18" is="div">
                        <Text text="Contact Us" />
                      </Wrapper>
                    </Button>
                </Wrapper>
                </Wrapper>
            </Wrapper>
              <Wrapper canvas id='random-id-444' is='div' className="w-full px-20 justify-center  flex items-center" style={{flex:'48%'}}>
                 <img className="w-[100%] flex justify-center h-[100%] object-cover min-h-auto max-h-[600px] items-align" src="https://i.ibb.co/hYZYbp9/image.png"/>
              </Wrapper>
            </Wrapper>
        </Wrapper>
    </Wrapper></Element>
      </Element>,

    
    <Element canvas is={Parent} id='parentId1100' className="w-[100%] h-auto" >
      <Element canvas is={Parent} id='parentId1200' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-445' is='parent' className="w-[100%] h-full bg-[white] py-10" >
    <Wrapper canvas id='random-id-446' is='container' className="container" >
        <Wrapper canvas id='random-id-447' is='wrapper' className="w-[100%] h-full flex flex-row flex-wrap flex justify-center" >
            <Wrapper canvas id='random-id-448' is='div' className="flex items-center h-full w-[100%] flex justify-center" style={{flex:'48%'}}>
                <Wrapper canvas id='random-id-449' is='div' className={`w-full max-w-[70%] h-full min-w-[358px] `}>
                     <Wrapper canvas style={{ display: "inline-block", fontWeight:'700', fontFamily:'Roboto' }} className={`w-full my-5 text-[#6750A4] text-[46px]`} id="random-id-19" is="div">
                    <Text text="Contact Us" />
                  </Wrapper>
                     <Wrapper canvas style={{ display: "inline-block", fontFamily:'Roboto' ,fontWeight:'400', color:'#100103', lineHeight:'28px'}} className={`w-full flex justify-center text-[18px]`} id="random-id-20" is="div">
                    <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                  </Wrapper>
                <Wrapper canvas id='random-id-451' is='div'  className="w-full flex flex-col my-5 flex justify-center ">
                    <Input className="inputt my-2 form-control bg-transparent text-white" style={{border:'1px solid #D0BCFF', height:'50px', padding:'20px', fontSize:'18px', borderRadius:'30px'}} name='name' type='text' value={'#'} placeholder="Enter your name"/>
                    <Input className="inputt my-2 form-control bg-transparent text-white" style={{border:'1px solid #D0BCFF', height:'50px', padding:'20px',  fontSize:'18px', borderRadius:'30px'}} name='email' type='email' value={'#'} placeholder="Enter your email"/>
                    <Input className="inputt my-2 form-control bg-transparent text-white" style={{border:'1px solid #D0BCFF', padding:'20px 20px 130px 20px',  fontSize:'18px', borderRadius:'30px'}} name='message' type='text' value={'#'} placeholder="Enter your message"/>
                </Wrapper>
                <Wrapper canvas id='random-id-206' is='div' style={{background:'#D0BCFF', width:'260px', height:'70px', borderRadius:'100px'}} className='my-5'>
                    <Button  classn='block' className="flex cursor-pointer justify-center flex border-1 hover:border-[#381E72] font-bold uppercase text-[#6750A4]" style={{width:'260px', height:'70px', background:'#D0BCFF',  borderRadius:'100px', boxShadow: '0px 4px 10px 0px #0000001A'}}>
                      <Wrapper canvas style={{ display: "inline-block", fontSize:'22px', fontFamily:'Roboto' , fontWeight:'600', }} className='py-[7px]' id="random-id-21" is="div">
                        <Text text="Contact Us" />
                      </Wrapper>
                    </Button>
                </Wrapper>
                </Wrapper>
            </Wrapper>
              <Wrapper canvas id='random-id-452' is='div' className="w-full px-20 justify-center  flex items-center" style={{flex:'48%'}}>
                  <img className="w-[100%] flex justify-center h-[100%] object-cover min-h-auto max-h-[600px] items-align" style={{borderRadius:'30px'}} src="https://i.ibb.co/hYZYbp9/image.png"/>
              </Wrapper>
            </Wrapper>
        </Wrapper>
    </Wrapper></Element>
      </Element>,
    
    <Element canvas is={Parent} id='parentId1300' className="w-[100%] h-auto" >
      <Element canvas is={Parent} id='parentId1400' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-453' is='parent' className="w-[100%] h-full bg-[#2A2A2A] py-10" >
    <Wrapper canvas id='random-id-454' is='container' className="container" >
        <Wrapper canvas id='random-id-455' is='wrapper' className="w-[100%] h-full flex flex-row flex-wrap flex justify-center" >
            <Wrapper canvas id='random-id-456' is='div' className="flex items-center h-full w-[100%] flex justify-center" style={{flex:'48%'}}>
                <Wrapper canvas id='random-id-457' is='div'  className={`w-full max-w-[70%] h-full min-w-[358px] `}>
                     <Wrapper canvas style={{ display: "inline-block", fontWeight:'400', fontFamily:'Rum Raisin'}} className={`w-full my-5 text-[#FFD600] text-[46px]`} id="random-id-22" is="div">
                    <Text text="Contact Us" />
                  </Wrapper>
                     <Wrapper canvas style={{ display: "inline-block", fontWeight:'400', fontFamily:'Rubik', color:'#FFFFFF', lineHeight:'28px'}} className={`w-full flex justify-center text-[18px]`} id="random-id-23" is="div">
                    <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                  </Wrapper>
                <Wrapper canvas id='random-id-459' is='div'  className="w-full flex flex-col my-5 flex justify-center ">
                    <Input className="inpute my-2 form-control bg-transparent text-white" style={{border:'1px solid #FFD60099', height:'50px', padding:'20px', fontSize:'18px', borderRadius:'8px'}} name='name' type='text' value={'#'} placeholder="Enter your name"/>
                    <Input className="inpute my-2 form-control bg-transparent text-white" style={{border:'1px solid #FFD60099', height:'50px', padding:'20px',  fontSize:'18px', borderRadius:'8px'}} name='email' type='email' value={'#'} placeholder="Enter your email"/>
                    <Input className="inpute my-2 form-control bg-transparent text-white" style={{border:'1px solid #FFD60099', padding:'20px 20px 130px 20px',  fontSize:'18px', borderRadius:'8px'}} name='message' type='text' value={'#'} placeholder="Enter your message"/>
                </Wrapper>
                <Wrapper canvas id='random-id-207' is='div' style={{width:'260px', height:'70px', borderRadius:'10px'}}>
                    <Button  classn='block' className="flex cursor-pointer my-5 justify-center bg-[#FFD600] hover:bg-[#FFD600A1] font-bold text-[#2A2A2A]" style={{width:'260px', height:'70px',  borderRadius:'10px'}}>
                      <Wrapper canvas style={{ display: "inline-block", fontSize:'22px', fontFamily:'Sansation', fontWeight:'600', }} className='flex justify-center flex items-center align-items-center' id="random-id-24" is="div">
                        <Text text="Contact Us" />
                      </Wrapper>
                    </Button>
                </Wrapper>
                </Wrapper>
            </Wrapper>
              <Wrapper canvas id='random-id-460' is='div' className="w-full px-20 justify-center  flex items-center" style={{borderRadius:'10px', flex:'48%'}}>
                  <img className="w-[100%] flex justify-center h-[100%] object-cover min-h-auto max-h-[600px] items-align" style={{borderRadius:'10px'}} src="https://i.ibb.co/hYZYbp9/image.png"/>
              </Wrapper>
            </Wrapper>
        </Wrapper>
    </Wrapper></Element>
      </Element>,

    
<Element canvas is={Parent} id='parentId1500' className="w-[100%] h-auto" >
      <Element canvas is={Parent} id='parentId1600' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-400' is='parent' className="w-[100%] h-full bg-[white] py-10" >
<Wrapper canvas id='random-id-401' is='container' className="container" >
    <Wrapper canvas id='random-id-402' is='wrapper' className="w-[100%] h-full flex flex-row flex-wrap flex justify-center border" >
        <Wrapper canvas id='random-id-403' is='div' className="items-center h-full flex justify-center" style={{flex:'48%'}}>
            <Wrapper canvas id='random-id-404' is='div' className={`w-full max-w-[50%] h-full min-w-[358px]`}>
                <Wrapper canvas style={{ display: "inline-block", fontWeight:'700', fontFamily:'Rubik' }} className={`w-full my-5 text-[#100103] text-[46px]`} id="random-id-1" is="div">
                  <Text text="Contact Us" />
                </Wrapper>
                <Wrapper canvas style={{ display: "inline-block", fontWeight:'400', fontFamily:'Rubik', color:'#100103', lineHeight:'28px'}} className={`w-full flex justify-center text-[18px]`} id="random-id-2" is="div">
                  <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                </Wrapper>
            <Wrapper canvas id='random-id-405' is='form'  className="w-full flex flex-col my-5 flex justify-center ">
                <Input className="input-4 my-2 form-control bg-transparent text-[#100103]" style={{border:'1px solid #B2031233', height:'50px', padding:'20px', fontSize:'18px'}} name='name' type='text' value={'#'} placeholder="Enter your name"/>
                <Input className="input-4 my-2 form-control bg-transparent text-[#100103]" style={{border:'1px solid #B2031233', height:'50px', padding:'20px',  fontSize:'18px'}} name='email' type='email' value={'#'} placeholder="Enter your email"/>
                <Input className="input-4 my-2 form-control bg-transparent text-[#100103]" style={{border:'1px solid #B2031233', padding:'20px 20px 130px 20px',  fontSize:'18px'}} name='message' type='text' value={'#'} placeholder="Enter your message"/>
            </Wrapper>
            <Wrapper canvas id='random-id-200' is='div' style={{width:'260px', height:'70px'}}>
                <Button  classn='block' className="flex cursor-pointer my-5 flex bg-[#B20312] hover:bg-[#87000C] font-bold uppercase text-[#FFF] justify-center" style={{ width:'260px', height:'70px', boxShadow: '0px 4px 10px 0px #0000001A'}}>
                  <Wrapper canvas style={{ display: "inline-block", fontSize:'22px', fontFamily:'Rubik', fontWeight:'600', }} className='flex justify-center flex items-center' id="random-id-3" is="div">
                    <Text text="Contact Us" />
                  </Wrapper>
                </Button>
            </Wrapper>
            </Wrapper>
        </Wrapper>
          <Wrapper canvas id='random-id-406' is='div' className="w-full px-20 justify-center  flex items-center" style={{flex:'48%'}}>
              <img className="w-[100%] flex justify-center h-[100%] object-cover min-h-auto max-h-[600px] items-align" alt="map" src="https://i.ibb.co/hYZYbp9/image.png"/>
          </Wrapper>
    </Wrapper>
</Wrapper>
</Wrapper></Element>
      </Element>,

<Element canvas is={Parent} id='parentId1700' className="w-[100%] h-auto" >
      <Element canvas is={Parent} id='parentId1800' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-900' is='parent' className="w-[100%] h-full bg-[#F7FBFE] py-10" >
<Wrapper canvas id='random-id-901' is='container' className="container" >
    <Wrapper canvas id='random-id-902' is='wrapper' className="w-[100%] h-full flex flex-row flex-wrap flex justify-center border" >
        <Wrapper canvas id='random-id-903' is='div' className="items-center h-full flex justify-center" style={{flex:'48%'}}>
            <Wrapper canvas id='random-id-904' is='div' className={`w-full max-w-[50%] h-full min-w-[358px]`}>
                <Wrapper canvas style={{ display: "inline-block", fontWeight:'400', fontFamily:'Rubik' }} className={`w-full my-5 text-[#6064D2] text-[46px]`} id="random-id-905" is="div">
                  <Text text="Contact Us" />
                </Wrapper>
                <Wrapper canvas style={{ display: "inline-block", fontWeight:'400', fontFamily:'Rubik', color:'#100103', lineHeight:'28px'}} className={`w-full flex justify-center text-[18px]`} id="random-id-906" is="div">
                  <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                </Wrapper>
            <Wrapper canvas id='random-id-907' is='form'  className="w-full flex flex-col my-5 flex justify-center ">
                <Input className="input-4 my-2 form-control bg-transparent text-[#100103]" style={{border:'1px solid var(--Grey-light, #ABB0B9)', borderRadius:'8px', height:'50px', padding:'20px', fontSize:'18px'}} name='name' type='text' value={'#'} placeholder="Enter your name"/>
                <Input className="input-4 my-2 form-control bg-transparent text-[#100103]" style={{border:'1px solid var(--Grey-light, #ABB0B9)', borderRadius:'8px', height:'50px', padding:'20px',  fontSize:'18px'}} name='email' type='email' value={'#'} placeholder="Enter your email"/>
                <Input className="input-4 my-2 form-control bg-transparent text-[#100103]" style={{border:'1px solid var(--Grey-light, #ABB0B9)', borderRadius:'8px', padding:'20px 20px 130px 20px',  fontSize:'18px'}} name='message' type='text' value={'#'} placeholder="Enter your message"/>
            </Wrapper>
            <Wrapper canvas id='random-id-908' is='div' style={{width:'320px', height:'50px', borderRadius:'10px'}}>
                <Button  classn='block' className="flex cursor-pointer my-5 flex bg-[#B20312] hover:bg-[#87000C] font-bold uppercase text-[#FFF] justify-center" style={{ width:'320px', height:'50px', boxShadow: '0px 4px 10px 0px #0000001A', borderRadius:'10px'}}>
                  <Wrapper canvas style={{ display: "inline-block", fontSize:'22px', fontFamily:'Rubik', fontWeight:'600', }} className='flex justify-center flex items-center' id="random-id-909" is="div">
                    <Text text="Contact Us" />
                  </Wrapper>
                </Button>
            </Wrapper>
            </Wrapper>
        </Wrapper>
          <Wrapper canvas id='random-id-910' is='div' className="w-full px-20 justify-center  flex items-center" style={{flex:'48%', borderRadius:'10px'}}>
              <img className="w-[100%] flex justify-center h-[100%] object-cover min-h-auto max-h-[600px] items-align" style={{borderRadius:'10px'}} alt="map" src="https://i.ibb.co/hYZYbp9/image.png"/>
          </Wrapper>
    </Wrapper>
</Wrapper>
</Wrapper></Element>
      </Element>,

<Element canvas is={Parent} id='parentId1900' className="w-[100%] h-auto" >
      <Element canvas is={Parent} id='parentId2000' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-911' is='parent' className="w-[100%] h-full bg-[#FEFBF7] py-10" >
<Wrapper canvas id='random-id-912' is='container' className="container" >
    <Wrapper canvas id='random-id-913' is='wrapper' className="w-[100%] h-full flex flex-row flex-wrap flex justify-center border" >
        <Wrapper canvas id='random-id-914' is='div' className="items-center h-full flex justify-center" style={{flex:'48%'}}>
            <Wrapper canvas id='random-id-915' is='div' className={`w-full max-w-[50%] h-full min-w-[358px]`}>
                <Wrapper canvas style={{ display: "inline-block", fontWeight:'700', fontFamily:'Ruluko' }} className={`w-full my-5 text-[#A25738] text-[46px]`} id="random-id-916" is="div">
                  <Text text="Contact Us" />
                </Wrapper>
                <Wrapper canvas style={{ display: "inline-block", fontWeight:'400', fontFamily:'Rubik', color:'#100103', lineHeight:'28px'}} className={`w-full flex justify-center text-[18px]`} id="random-id-917" is="div">
                  <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                </Wrapper>
            <Wrapper canvas id='random-id-918' is='form'  className="w-full flex flex-col my-5 flex justify-center ">
                <Input className="input-4 my-2 form-control bg-transparent text-[#100103]" style={{border:'1px solid rgba(162, 87, 56, 0.40)', borderRadius:'8px', height:'50px', padding:'20px', fontSize:'18px'}} name='name' type='text' value={'#'} placeholder="Enter your name"/>
                <Input className="input-4 my-2 form-control bg-transparent text-[#100103]" style={{border:'1px solid rgba(162, 87, 56, 0.40)', borderRadius:'8px', height:'50px', padding:'20px',  fontSize:'18px'}} name='email' type='email' value={'#'} placeholder="Enter your email"/>
                <Input className="input-4 my-2 form-control bg-transparent text-[#100103]" style={{border:'1px solid rgba(162, 87, 56, 0.40)', borderRadius:'8px', padding:'20px 20px 130px 20px',  fontSize:'18px'}} name='message' type='text' value={'#'} placeholder="Enter your message"/>
            </Wrapper>
            <Wrapper canvas id='random-id-919' is='div' style={{width:'280px', height:'60px'}}>
                <Button  classn='block' className="flex cursor-pointer my-5 flex bg-[#A25738] font-bold uppercase text-[#FFF] justify-center" style={{ width:'280px', height:'60px', boxShadow: '0px 4px 10px 0px #0000001A', borderRadius:'10px'}}>
                  <Wrapper canvas style={{ display: "inline-block", fontSize:'22px', fontFamily:'Rubik', fontWeight:'600', }} className='flex justify-center flex items-center' id="random-id-920" is="div">
                    <Text text="Contact Us" />
                  </Wrapper>
                </Button>
            </Wrapper>
            </Wrapper>
        </Wrapper>
          <Wrapper canvas id='random-id-921' is='div' className="w-full px-20 justify-center  flex items-center" style={{flex:'48%', borderRadius:'10px'}}>
              <img className="w-[100%] flex justify-center h-[100%] object-cover min-h-auto max-h-[600px] items-align" style={{borderRadius:'10px'}} alt="map" src="https://i.ibb.co/hYZYbp9/image.png"/>
          </Wrapper>
    </Wrapper>
</Wrapper>
</Wrapper></Element>
      </Element>,
    ]

    
const [sel, setSel] = useState(selected)

function makeOdd(number) {
  setProp((prop) => {
    setSel(100);
  }, 1000);

  setTimeout(() => {
    setProp((prop) => {
      setSel(number)
    }, 1000);
  }, 100);


  
}

useEffect(() => {
if(selected || selected >= 0) {
  makeOdd(selected);
}
},[selected])


    return (
      <div style={{ padding:enabled? "10px" : "0px", display:"flex", justifyContent:"center", alignItems:"center", width:'100%', height:'100%'}} ref={connect}>
      {sel == 100 ? (
          <div style={{height:"85vh", display:"flex", justifyContent:"center", alignItems:"center"}}>
          <BounceLoader
                  size={100}
                  color={"white"}
                  loading={true}
                />
          </div>
         ) : (
          styles[sel]
         )}
  </div>
  );
}


Contact_1.craft = {
    displayName: "Contact 1",
    props: {
       selected: 1, // here is the default value of the selected style
       length:9,
isBlock: true
      },
      rules: {
        canDrag: () => true,
        canDrop: () => true,
      },
      related: {
        toolbar: NewContactSettings,
      },
  };