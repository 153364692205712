/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import { Element, useEditor, useNode } from '@craftjs/core';
import React, {useState, useEffect, useRef} from 'react';
import { NewServicesSettings } from './newServicesSetting';
import { Text } from '../../basic/Text';
import StopIcon from '@mui/icons-material/SquareSharp';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import ModeNightIcon from '@mui/icons-material/ModeNight';
import { Button } from '../../basic/Button';
import { ImageC } from '../../basic/Image';
import { Parent } from '../../Parent/index.js';
import { Wrapper } from '../../wrapper/index.js';
import { BounceLoader } from 'react-spinners';
import { SvgIcon } from '../../basic/Svg';

export const Services_2 = ({selected}) => {
    const { 
        actions: { setProp },
          connectors: {
            connect
          } 
      } = useNode();
    
      
      const {
        enabled,
      } = useEditor((state, query) => ({
        enabled: state.options.enabled,
     
      }));



    const styles = [
        <Element canvas is={Parent} id='parentId100' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId200' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-400' is='parent' className='w-[100%] h-full bg-[#FFFFFF] py-10' >
            <Wrapper canvas id='random-id-401' is='container' className='container'>
                <Wrapper canvas id='random-id-402' is='wrapper' className='w-full h-full flex flex-row flex-wrap justify-center'>
                    <Wrapper canvas id='random-id-403' is='div' className='max-w-[100%] min-w-[330px] flex items-center h-full flex flex-col mx-4 mb-4' style={{flex:'45%'}}>
                            <Wrapper canvas style={{display:'inline-block', fontWeight:'700', fontFamily:'Poppins', color:'#000000', width:'100%'}} className={`my-8 text-[46px]`} id='random-id-1' is='div'>
                                <Text text='Services'/>
                            </Wrapper>
                        <Wrapper canvas style={{display:'inline-block', fontWeight:'500', fontFamily:'Poppins', color:'#000000'}}  className={`mb-4 text-[22px]`} id='random-id-2' is='div'>
                            <Text text='Sed sed risus pretium quam vulputate dignissim. Eget nunc lobortis mattis aliquam faucibus purus in. Potenti nullam ac tortor vitae purus faucibus ornare suspendisse.'/>
                        </Wrapper>
                        <Wrapper canvas style={{display:'inline-block', fontWeight:'500', fontFamily:'Poppins', color:'#000000'}} className={`text-[22px]`} id='random-id-3' is='div'>
                            <Text text='Commodo ullamcorper a lacus vestibulum sed arcu non odio. Lectus arcu bibendum at varius. Suspendisse sed nisi lacus sed viverra tellus in.'/>
                        </Wrapper>
                      
                            <Wrapper canvas id='random-id-200' is='div' className='w-full mt-4' style={{borderRadius:'30px'}}>
                            <Button  classn='block' class='w-[280px] h-[60px] cursor-pointer justify-center items-center bg-[#FFC92E] hover:bg-[#FFF5D7] border-1 border-[#FFC92E] hover:border-[#FFC92E] flex text-[#000000]' style={{ borderRadius:'30px'}}>
                                <Wrapper canvas style={{display:'inline-block', fontSize:'22px', fontWeight:'500', fontFamily:'Poppins',}} className='flex justify-center flex items-center' id='random-id-4' is='div'>
                                    <Text text='Get Started'/>
                                </Wrapper>
                                {/* <div className='mx-2 flex items-center'>
                                    <ArrowCircleRightOutlinedIcon sx={{color:'#000000', fontSize:'28px'}}/>
                                </div> */}
                            </Button>
                            </Wrapper>
                       
                    </Wrapper>
                    <Wrapper canvas id='random-id-404' is='div' className='max-w-[100%] min-w-[350px] flex items-center h-full justify-center flex flex-col mx-4 my-4' style={{flex:'45%'}}>
                        <Wrapper canvas id='random-id-405' is='div' className='w-full flex flex-row my-4'>
                            <Wrapper canvas id='random-id-406' is='div' className='w-[100px] h-[80px] bg-[#FFC92E] flex justify-center flex items-center flex' style={{borderRadius:'14px'}}>
                            <SvgIcon fill='#2479FD' viewBox='0 0 46 46' path='M18.208 3.833c-3.488 0-6.765.959-9.583 2.588 5.73 3.316 9.583 9.487 9.583 16.579s-3.852 13.263-9.583 16.58a19.1 19.1 0 0 0 9.583 2.587c10.58 0 19.167-8.587 19.167-19.167S28.788 3.833 18.208 3.833' />
                            </Wrapper>
                                <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'500', fontFamily:'Poppins', color:'#000000'}} className='w-[100%] flex items-center px-6' id='random-id-5' is='div'>
                                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                                </Wrapper>
                        </Wrapper>
                        <Wrapper canvas id='random-id-407' is='div' className='w-full flex flex-row my-4'>
                            <Wrapper canvas id='random-id-408' is='div' className='w-[100px] h-[80px] bg-[#2479FD] flex justify-center flex items-center flex' style={{borderRadius:'14px'}}>
                            <SvgIcon fill='#FFC92E' viewBox='0 0 46 46' path='M18.208 3.833c-3.488 0-6.765.959-9.583 2.588 5.73 3.316 9.583 9.487 9.583 16.579s-3.852 13.263-9.583 16.58a19.1 19.1 0 0 0 9.583 2.587c10.58 0 19.167-8.587 19.167-19.167S28.788 3.833 18.208 3.833' />
                            </Wrapper>
                                <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'500', fontFamily:'Poppins', color:'#000000'}} className='w-[100%] flex items-center px-6' id='random-id-6' is='div'>
                                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                                </Wrapper>
                        </Wrapper>
                        <Wrapper canvas id='random-id-470' is='div' className='w-full flex flex-row my-4'>
                            <Wrapper canvas id='random-id-409' is='div' className='w-[100px] h-[80px] bg-[#FFC92E] flex justify-center flex items-center flex' style={{borderRadius:'14px'}}>
                            <SvgIcon fill='#2479FD' viewBox='0 0 46 46' path='M18.208 3.833c-3.488 0-6.765.959-9.583 2.588 5.73 3.316 9.583 9.487 9.583 16.579s-3.852 13.263-9.583 16.58a19.1 19.1 0 0 0 9.583 2.587c10.58 0 19.167-8.587 19.167-19.167S28.788 3.833 18.208 3.833' />
                            </Wrapper>
                                <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'500', fontFamily:'Poppins', color:'#000000'}} className='w-[100%] flex items-center px-6' id='random-id-7' is='div'>
                                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                                </Wrapper>
                        </Wrapper>
                        <Wrapper canvas id='random-id-410' is='div' className='w-full flex flex-row my-4'>
                            <Wrapper canvas id='random-id-411' is='div' className='w-[100px] h-[80px] bg-[#2479FD] flex justify-center flex items-center flex' style={{borderRadius:'14px'}}>
                            <SvgIcon fill='#FFC92E' viewBox='0 0 46 46' path='M18.208 3.833c-3.488 0-6.765.959-9.583 2.588 5.73 3.316 9.583 9.487 9.583 16.579s-3.852 13.263-9.583 16.58a19.1 19.1 0 0 0 9.583 2.587c10.58 0 19.167-8.587 19.167-19.167S28.788 3.833 18.208 3.833' />
                            </Wrapper>
                                <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'500', fontFamily:'Poppins', color:'#000000'}} className='w-[100%] flex items-center px-6' id='random-id-8' is='div'>
                                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                                </Wrapper>
                        </Wrapper>
                      
                    </Wrapper>
                    
                </Wrapper>
            </Wrapper>
        </Wrapper></Element>
        </Element>
        ,
        <Element canvas is={Parent} id='parentId300' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId400' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-412' is='parent' className='w-[100%] h-full bg-[#FFFFFF] py-10' >
            <Wrapper canvas id='random-id-413' is='container' className='container'>
                <Wrapper canvas id='random-id-414' is='wrapper' className='w-full h-full flex flex-row flex-wrap justify-center'>
                    <Wrapper canvas id='random-id-415' is='div' className='max-w-[100%] min-w-[330px] flex items-center justify-start text-left h-full flex flex-col mx-4 mb-4' style={{flex:'45%'}}>
                            <Wrapper canvas style={{display:'inline-block', fontWeight:'700', fontFamily:'Prompt', color:'#000000', width:'100%'}} className={`my-8 text-[46px]`} id='random-id-9' is='div'>
                                <Text text='Services'/>
                            </Wrapper>
                        <Wrapper canvas style={{display:'inline-block', fontWeight:'400', fontFamily:'Prompt', color:'#000000'}} className={`mb-4 text-[22px]`} id='random-id-10' is='div'>
                            <Text text='Sed sed risus pretium quam vulputate dignissim. Eget nunc lobortis mattis aliquam faucibus purus in. Potenti nullam ac tortor vitae purus faucibus ornare suspendisse.'/>
                        </Wrapper>
                        <Wrapper canvas style={{display:'inline-block', fontWeight:'400', fontFamily:'Prompt', color:'#000000'}} className={`text-[22px]`} id='random-id-11' is='div'>
                            <Text text='Commodo ullamcorper a lacus vestibulum sed arcu non odio. Lectus arcu bibendum at varius. Suspendisse sed nisi lacus sed viverra tellus in.'/>
                        </Wrapper>
                      
                            <Wrapper canvas id='random-id-201' is='div' className='w-full mt-4' style={{borderRadius:'30px'}}>
                            <Button  classn='block' class='w-[280px] h-[60px] cursor-pointer justify-center items-center bg-[#F6FA70] hover:bg-[#F6FD00] flex text-[#000000]' style={{ borderRadius:'30px'}}>
                                <Wrapper canvas style={{display:'inline-block', fontSize:'22px', fontWeight:'400', fontFamily:'Prompt',}} className='flex justify-center flex items-center' id='random-id-12' is='div'>
                                    <Text text='Get Started'/>
                                </Wrapper>
                                {/* <div className='mx-2 flex items-center'>
                                    <ArrowCircleRightOutlinedIcon sx={{color:'#000000', fontSize:'28px'}}/>
                                </div> */}
                            </Button>
                            </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-416' is='div' className='max-w-[100%] min-w-[350px] h-full justify-center flex flex-col mx-4 my-4' style={{flex:'45%'}}>
                        <Wrapper canvas id='random-id-417' is='div' className='w-full flex flex-row my-4'>
                            <Wrapper canvas id='random-id-418' is='div' className='w-[100px] h-[80px] bg-[#F6F8A2] flex justify-center flex items-center flex' style={{borderRadius:'14px'}}>
                            <SvgIcon fill='#FF0060' viewBox='0 0 46 46' path='M18.208 3.833c-3.488 0-6.765.959-9.583 2.588 5.73 3.316 9.583 9.487 9.583 16.579s-3.852 13.263-9.583 16.58a19.1 19.1 0 0 0 9.583 2.587c10.58 0 19.167-8.587 19.167-19.167S28.788 3.833 18.208 3.833' />
                            </Wrapper>
                                <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Prompt', color:'#000000'}} className='w-[100%] flex items-center px-6' id='random-id-13' is='div'>
                                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                                </Wrapper>
                        </Wrapper>
                        <Wrapper canvas id='random-id-419' is='div' className='w-full flex flex-row my-4'>
                            <Wrapper canvas id='random-id-420' is='div' className='w-[100px] h-[80px] bg-[#FF0060] flex justify-center flex items-center flex' style={{borderRadius:'14px'}}>
                            <SvgIcon fill='#F6F8A2' viewBox='0 0 46 46' path='M18.208 3.833c-3.488 0-6.765.959-9.583 2.588 5.73 3.316 9.583 9.487 9.583 16.579s-3.852 13.263-9.583 16.58a19.1 19.1 0 0 0 9.583 2.587c10.58 0 19.167-8.587 19.167-19.167S28.788 3.833 18.208 3.833' />
                            </Wrapper>
                                <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Prompt', color:'#000000'}} className='w-[100%] flex items-center px-6' id='random-id-14' is='div'>
                                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                                </Wrapper>
                        </Wrapper>
                        <Wrapper canvas id='random-id-421' is='div' className='w-full flex flex-row my-4'>
                            <Wrapper canvas id='random-id-422' is='div' className='w-[100px] h-[80px] bg-[#F6F8A2] flex justify-center flex items-center flex' style={{borderRadius:'14px'}}>
                            <SvgIcon fill='#FF0060' viewBox='0 0 46 46' path='M18.208 3.833c-3.488 0-6.765.959-9.583 2.588 5.73 3.316 9.583 9.487 9.583 16.579s-3.852 13.263-9.583 16.58a19.1 19.1 0 0 0 9.583 2.587c10.58 0 19.167-8.587 19.167-19.167S28.788 3.833 18.208 3.833' />
                            </Wrapper>
                                <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Prompt', color:'#000000'}} className='w-[100%] flex items-center px-6' id='random-id-15' is='div'>
                                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                                </Wrapper>
                        </Wrapper>
                        <Wrapper canvas id='random-id-423' is='div' className='w-full flex flex-row my-4'>
                            <Wrapper canvas id='random-id-424' is='div' className='w-[100px] h-[80px] bg-[#FF0060] flex justify-center flex items-center flex' style={{borderRadius:'14px'}}>
                            <SvgIcon fill='#F6F8A2' viewBox='0 0 46 46' path='M18.208 3.833c-3.488 0-6.765.959-9.583 2.588 5.73 3.316 9.583 9.487 9.583 16.579s-3.852 13.263-9.583 16.58a19.1 19.1 0 0 0 9.583 2.587c10.58 0 19.167-8.587 19.167-19.167S28.788 3.833 18.208 3.833' />
                            </Wrapper>
                                <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Prompt', color:'#000000'}} className='w-[100%] flex items-center px-6' id='random-id-16' is='div'>
                                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                                </Wrapper>
                        </Wrapper>
                    </Wrapper>
                    
                </Wrapper>
            </Wrapper>
        </Wrapper></Element>
        </Element>,

        <Element canvas is={Parent} id='parentId500' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId600' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-425' is='parent' className='w-[100%] h-full bg-[#FFFFFF] py-10' >
            <Wrapper canvas id='random-id-426' is='container' className='container'>
                <Wrapper canvas id='random-id-427' is='div' className='w-full h-full flex flex-row flex-wrap justify-center'>
                    <Wrapper canvas id='random-id-428' is='div' className='max-w-[100%] min-w-[330px] flex items-center h-full flex flex-col mx-4 mb-4' style={{flex:'45%'}}>
                            <Wrapper canvas style={{display:'inline-block', fontWeight:'700', fontFamily:'Almarai', color:'#000000', width:'100%'}} className={`my-8 text-[46px]`} id='random-id-17' is='div'>
                                <Text text='Services'/>
                            </Wrapper>
                        <Wrapper canvas style={{display:'inline-block', fontWeight:'400', fontFamily:'Almarai', color:'#000000'}} className={`mb-4 text-[22px]`} id='random-id-18' is='div'>
                            <Text text='Sed sed risus pretium quam vulputate dignissim. Eget nunc lobortis mattis aliquam faucibus purus in. Potenti nullam ac tortor vitae purus faucibus ornare suspendisse.'/>
                        </Wrapper>
                        <Wrapper canvas style={{display:'inline-block', fontWeight:'400', fontFamily:'Almarai', color:'#000000'}} className={`text-[22px]`} id='random-id-19' is='div'>
                            <Text text='Commodo ullamcorper a lacus vestibulum sed arcu non odio. Lectus arcu bibendum at varius. Suspendisse sed nisi lacus sed viverra tellus in.'/>
                        </Wrapper>
                            <Wrapper canvas id='random-id-202' is='div' className='w-full mt-4' style={{borderRadius:'8px'}}>
                            <Button  classn='block' class='w-[280px] h-[60px] cursor-pointer justify-center items-center bg-[#F31559] hover:bg-[#FF5B8D] flex text-[#FFFFFF]' style={{borderRadius:'8px'}}>
                                <Wrapper canvas style={{display:'inline-block', fontSize:'22px', fontWeight:'400', fontFamily:'Almarai', }} className='flex justify-center flex items-center' id='random-id-20' is='div'>
                                    <Text text='Get Started'/>
                                </Wrapper>
                                {/* <div className='mx-2 flex items-center'>
                                    <ArrowCircleRightOutlinedIcon sx={{color:'#FFFFFF', fontSize:'28px'}}/>
                                </div> */}
                            </Button>
                            </Wrapper> 
                    </Wrapper>
                    <Wrapper canvas id='random-id-429' is='div' className='max-w-[100%] min-w-[350px] h-full justify-center flex flex-col mx-4 my-4' style={{flex:'45%'}}>
                        <Wrapper canvas id='random-id-430' is='div' className='w-full flex flex-row my-4'>
                            <Wrapper canvas id='random-id-431' is='div' className='w-[100px] h-[80px] bg-[#FFB7B7] flex justify-center flex items-center flex' style={{borderRadius:'14px'}}>
                            <SvgIcon fill='#F31559' viewBox='0 0 46 46' path='M18.208 3.833c-3.488 0-6.765.959-9.583 2.588 5.73 3.316 9.583 9.487 9.583 16.579s-3.852 13.263-9.583 16.58a19.1 19.1 0 0 0 9.583 2.587c10.58 0 19.167-8.587 19.167-19.167S28.788 3.833 18.208 3.833' />
                            </Wrapper>
                                <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Almarai', color:'#000000'}} className='w-[100%] flex items-center px-6' id='random-id-21' is='div'>
                                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                                </Wrapper>
                        </Wrapper>
                        <Wrapper canvas id='random-id-432' is='div' className='w-full flex flex-row my-4'>
                            <Wrapper canvas id='random-id-433' is='div' className='w-[100px] h-[80px] bg-[#F31559] flex justify-center flex items-center flex' style={{borderRadius:'14px'}}>
                            <SvgIcon fill='#FFB7B7' viewBox='0 0 46 46' path='M18.208 3.833c-3.488 0-6.765.959-9.583 2.588 5.73 3.316 9.583 9.487 9.583 16.579s-3.852 13.263-9.583 16.58a19.1 19.1 0 0 0 9.583 2.587c10.58 0 19.167-8.587 19.167-19.167S28.788 3.833 18.208 3.833' />
                            </Wrapper>
                                <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Almarai', color:'#000000'}} className='w-[100%] flex items-center px-6' id='random-id-22' is='div'>
                                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                                </Wrapper>
                        </Wrapper>
                        <Wrapper canvas id='random-id-434' is='div' className='w-full flex flex-row my-4'>
                            <Wrapper canvas id='random-id-435' is='div' className='w-[100px] h-[80px] bg-[#FFB7B7] flex justify-center flex items-center flex' style={{borderRadius:'14px'}}>
                            <SvgIcon fill='#F31559' viewBox='0 0 46 46' path='M18.208 3.833c-3.488 0-6.765.959-9.583 2.588 5.73 3.316 9.583 9.487 9.583 16.579s-3.852 13.263-9.583 16.58a19.1 19.1 0 0 0 9.583 2.587c10.58 0 19.167-8.587 19.167-19.167S28.788 3.833 18.208 3.833' />
                            </Wrapper>
                                <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Almarai', color:'#000000'}} className='w-[100%] flex items-center px-6' id='random-id-23' is='div'>
                                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                                </Wrapper>
                        </Wrapper>
                        <Wrapper canvas id='random-id-436' is='div' className='w-full flex flex-row my-4'>
                            <Wrapper canvas id='random-id-437' is='div' className='w-[100px] h-[80px] bg-[#F31559] flex justify-center flex items-center flex' style={{borderRadius:'14px'}}>
                            <SvgIcon fill='#FFB7B7' viewBox='0 0 46 46' path='M18.208 3.833c-3.488 0-6.765.959-9.583 2.588 5.73 3.316 9.583 9.487 9.583 16.579s-3.852 13.263-9.583 16.58a19.1 19.1 0 0 0 9.583 2.587c10.58 0 19.167-8.587 19.167-19.167S28.788 3.833 18.208 3.833' />
                            </Wrapper>
                                <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Almarai', color:'#000000'}} className='w-[100%] flex items-center px-6' id='random-id-24' is='div'>
                                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                                </Wrapper>
                        </Wrapper>
                    </Wrapper>
                    
                </Wrapper>
            </Wrapper>
        </Wrapper></Element>
        </Element>,

        <Element canvas is={Parent} id='parentId700' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId800' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-439' is='parent' className='w-[100%] h-full bg-[#FFFFFF] py-10' >
            <Wrapper canvas id='random-id-440' is='container' className='container'>
                <Wrapper canvas id='random-id-441' is='wrapper' className='w-full h-full flex flex-row flex-wrap justify-center'>
                    <Wrapper canvas id='random-id-442' is='div' className='max-w-[100%] min-w-[330px] flex items-center h-full flex flex-col mx-4 mb-4' style={{flex:'45%'}}>
                            <Wrapper canvas style={{display:'inline-block', fontWeight:'700', fontFamily:'Chivo', color:'#000000', width:'100%'}} className={`my-8 text-[46px]`} id='random-id-17' is='div'>
                                <Text text='Services'/>
                            </Wrapper>
                        <Wrapper canvas style={{display:'inline-block', fontWeight:'400', fontFamily:'Chivo', color:'#000000'}} className={`mb-4 text-[22px]`} id='random-id-18' is='div'>
                            <Text text='Sed sed risus pretium quam vulputate dignissim. Eget nunc lobortis mattis aliquam faucibus purus in. Potenti nullam ac tortor vitae purus faucibus ornare suspendisse.'/>
                        </Wrapper>
                        <Wrapper canvas style={{display:'inline-block', fontWeight:'400', fontFamily:'Chivo', color:'#000000'}} className={`text-[22px]`} id='random-id-19' is='div'>
                            <Text text='Commodo ullamcorper a lacus vestibulum sed arcu non odio. Lectus arcu bibendum at varius. Suspendisse sed nisi lacus sed viverra tellus in.'/>
                        </Wrapper>
                            <Wrapper canvas id='random-id-203' is='div' className='w-full mt-4' style={{borderRadius:'8px'}}>
                            <Button  classn='block' class='w-[280px] h-[60px] cursor-pointer justify-center items-center bg-[#D4ADFC] hover:bg-[#C68EFF] flex text-[#0C134F]' style={{ borderRadius:'8px'}}>
                                <Wrapper canvas style={{display:'inline-block', fontSize:'22px', fontWeight:'bold', fontFamily:'Chivo',}} className='flex justify-center flex items-center' id='random-id-20' is='div'>
                                    <Text text='Get Started'/>
                                </Wrapper>
                                {/* <Wrapper canvas id='random-id-443' is='div' className='mx-2 flex items-center'>
                                    <ArrowCircleRightOutlinedIcon sx={{color:'#0C134F', fontSize:'28px'}}/>
                                </Wrapper> */}
                            </Button>
                            </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-444' is='div' className='max-w-[100%] min-w-[350px] h-full justify-center flex flex-col mx-4 my-4' style={{flex:'45%'}}>
                        <Wrapper canvas id='random-id-445' is='div' className='w-full flex flex-row my-4'>
                            <Wrapper canvas id='random-id-446' is='div' className='w-[100px] h-[80px] bg-[#D4ADFC] flex justify-center flex items-center flex' style={{borderRadius:'14px'}}>
                            <SvgIcon fill='#0C134F' viewBox='0 0 46 46' path='M18.208 3.833c-3.488 0-6.765.959-9.583 2.588 5.73 3.316 9.583 9.487 9.583 16.579s-3.852 13.263-9.583 16.58a19.1 19.1 0 0 0 9.583 2.587c10.58 0 19.167-8.587 19.167-19.167S28.788 3.833 18.208 3.833' />
                            </Wrapper>
                                <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Chivo', color:'#000000'}} className='w-[100%] flex items-center px-6' id='random-id-21' is='div'>
                                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                                </Wrapper>
                        </Wrapper>
                        <Wrapper canvas id='random-id-447' is='div' className='w-full flex flex-row my-4'>
                            <Wrapper canvas id='random-id-448' is='div' className='w-[100px] h-[80px] bg-[#0C134F] flex justify-center flex items-center flex' style={{borderRadius:'14px'}}>
                            <SvgIcon fill='#D4ADFC' viewBox='0 0 46 46' path='M18.208 3.833c-3.488 0-6.765.959-9.583 2.588 5.73 3.316 9.583 9.487 9.583 16.579s-3.852 13.263-9.583 16.58a19.1 19.1 0 0 0 9.583 2.587c10.58 0 19.167-8.587 19.167-19.167S28.788 3.833 18.208 3.833' />
                            </Wrapper>
                                <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Chivo', color:'#000000'}} className='w-[100%] flex items-center px-6' id='random-id-22' is='div'>
                                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                                </Wrapper>
                        </Wrapper>
                        <Wrapper canvas id='random-id-449' is='div' className='w-full flex flex-row my-4'>
                            <Wrapper canvas id='random-id-450' is='div' className='w-[100px] h-[80px] bg-[#D4ADFC] flex justify-center flex items-center flex' style={{borderRadius:'14px'}}>
                            <SvgIcon fill='#0C134F' viewBox='0 0 46 46' path='M18.208 3.833c-3.488 0-6.765.959-9.583 2.588 5.73 3.316 9.583 9.487 9.583 16.579s-3.852 13.263-9.583 16.58a19.1 19.1 0 0 0 9.583 2.587c10.58 0 19.167-8.587 19.167-19.167S28.788 3.833 18.208 3.833' />
                            </Wrapper>
                                <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Chivo', color:'#000000'}} className='w-[100%] flex items-center px-6' id='random-id-23' is='div'>
                                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                                </Wrapper>
                        </Wrapper>
                        <Wrapper canvas id='random-id-451' is='div' className='w-full flex flex-row my-4'>
                            <Wrapper canvas id='random-id-452' is='div' className='w-[100px] h-[80px] bg-[#0C134F] flex justify-center flex items-center flex' style={{borderRadius:'14px'}}>
                            <SvgIcon fill='#D4ADFC' viewBox='0 0 46 46' path='M18.208 3.833c-3.488 0-6.765.959-9.583 2.588 5.73 3.316 9.583 9.487 9.583 16.579s-3.852 13.263-9.583 16.58a19.1 19.1 0 0 0 9.583 2.587c10.58 0 19.167-8.587 19.167-19.167S28.788 3.833 18.208 3.833' />
                            </Wrapper>
                                <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Chivo', color:'#000000'}} className='w-[100%] flex items-center px-6' id='random-id-24' is='div'>
                                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                                </Wrapper>
                        </Wrapper>
                    </Wrapper>
                    
                </Wrapper>
            </Wrapper>
        </Wrapper></Element>
        </Element>
    ]

    
const [sel, setSel] = useState(selected)

function makeOdd(number) {
  setProp((prop) => {
    setSel(100);
  }, 1000);

  setTimeout(() => {
    setProp((prop) => {
      setSel(number)
    }, 1000);
  }, 100);


  
}

useEffect(() => {
if(selected || selected >= 0) {
  makeOdd(selected);
}
},[selected])


    return (
        <div style={{ padding:enabled? "10px" : "0px", display:"flex", justifyContent:"center", alignItems:"center", width:'100%', height:'100%'}} ref={connect}>
            {sel == 100 ? (
            <div style={{height:"85vh", display:"flex", justifyContent:"center", alignItems:"center"}}>
            <BounceLoader
                    size={100}
                    color={"white"}
                    loading={true}
                    />
            </div>
            ) : (
            styles[sel]
            )} 
    </div>
    )
}

Services_2.craft = {
    displayName: "Services 2",
    props: {
       selected: 1, // here is the default value of the selected style
       length:3,
isBlock: true
      },
      rules: {
        canDrag: () => true,
        canDrop: () => true,
      },
      related: {
        toolbar: NewServicesSettings,
      },
  };
