/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import { Element, UserComponent, useEditor, useNode } from '@craftjs/core';
import cx from 'classnames';
import React, { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';
import { ButtonSettings } from './ButtonSettings';
import { Text } from '../Text';
import { useActiveIdContext } from '../../../../ActiveIdContext';
import { useOptionsContext } from '../../../../OptionsContext';
import { useInView } from 'react-hook-inview';

export const Button = (props) => {
  const {
    connectors: { connect },
  } = useNode((node) => ({
    selected: node.events.selected,
  }));

  const {
    nodes,
    enabled,
  } = useEditor((state) => ({
    enabled: state.options.enabled,
    nodes: state.nodes
  }));

    
  const { state, dispatch: setActiveId } = useActiveIdContext();
  const { activeId: activeId } = state;
  const { state: idState, dispatch: idDispatch } = useOptionsContext();
  const { options: treeData } = idState;
  const [hovered, setHovered] = useState(false)
  const [inViewRef, inView] = useInView()

  const mergedRef = useRef(null)

  
  const setRef = useCallback((node) => {
    if (mergedRef.current !== node) {
      if (node) {
        connect(node);
        inViewRef(node);
      }
      mergedRef.current = node;
    }
  }, []);

  
 
  const { 
    background,
    buttonStyle,
    text,
    margin,
    width,
    height,
    href,
    classn,
    color,
    children,
    style,
    shadow,
    isBasic,
    maxWidth,
    marginBottom,
    marginLeft,
    marginRight,
    marginTop,
    minWidth,
    transition,
    unit,
    disabled,
    actionSelect = {
      background: {hover:null, scroll:null}
    },
    actionState = 'Default',
    ...otherProps 
  } = props;


  // const mergedStyle = () => {
  //   let mergedStyle = {}
  //     for (const key in actionSelect) {
  //       if (actionSelect[key] !== null) {
  //         mergedStyle[key] = actionSelect[key];
  //       }
  //       return {...mergedStyle};
  //   }
  // }
  
  return (  
    <div
    val={href} _id="sidebarItemx"
    href={href && !enabled && `/${href}`}
    onClick={(e) => {
      e.preventDefault(); // Prevent default action of anchor tag

      if (!enabled) {
        if (href && href._id && href._id.includes("www")) {
        // If href exists and href._id includes "www", open a new tab with the URL
        window.open("https://" + href.name, "_blank");
      } else if (href && href._id && href.name === 'dom') {
        const element = Object.values(nodes).find((node) => node.id === href._id);

        if (element) {

          if (element.dom) {
            element.dom.scrollIntoView({ behavior: 'smooth', block: 'start' });
          } else {
            console.log("DOM Element not found for ID:", element.id);
          }
        } else {
          console.log("Element with ID not found:", href.id);
        }
        }
        else if (href && href.name !== 'dom') {
          // Find the item in treeData based on href._id
          const parentItem = treeData.find((x) => x._id === href._id);
          if (parentItem) {
            // Set activeId to the parent item if found
            setActiveId(parentItem);
          } else {
            // If parent item not found, find the child item in treeData
            let childItem = null;
            for (const parent of treeData) {
              const child = parent.children.find((child) => child._id === href._id);
              if (child) {
                childItem = child;
                break;
              }
            }
            // Set activeId to the child item if found
            setActiveId(childItem);
          }
        }
      }
    }}
    ref={setRef}
    // onMouseEnter={() => setHovered(true)}
    // onMouseLeave={() => setHovered(false)}
    className={`${classn} ${props.class}`}
    {...otherProps}
    style={{
      ...style,
      cursor: disabled === 'true' ? null : href && !enabled && 'pointer',
      fontSize: isBasic && '20px',
      display: isBasic && 'flex',
      justifyContent: isBasic && 'center',
      alignItems: isBasic && 'center',
      opacity:disabled === 'true' && '40%',
      background: disabled === 'true' ? '#363434' : isBasic && !background ? 'transparent' : buttonStyle === 'outline' ? 'transparent' : background ? background : "" ,
      color: disabled === 'true' ? '#fff' : isBasic ? 'black' : buttonStyle === 'outline' ? color ? color : "black" : color ? color : "" ,
      backgroundImage: color && !color.startsWith('#') ? color : undefined,
      WebkitBackgroundClip: color && !color.startsWith('#') ? 'text' : undefined,
      backgroundClip: color && !color.startsWith('#') ? 'text' : undefined,
      borderWidth: "2px",
      borderStyle: enabled ? "solid" : "solid",
      borderRadius: enabled ? '8px' : style?.borderRadius || '',
      marginTop:marginTop && `${marginTop?.split(" ")?.join("")}`,
      marginBottom:marginBottom && `${marginBottom?.split(" ")?.join("")}`,
      marginLeft:marginLeft && `${marginLeft?.split(" ")?.join("")}`,
      marginRight:marginRight && `${marginRight?.split(" ")?.join("")}`,
      borderColor: enabled ? "#3b97e360" : buttonStyle === 'outline' && 'transparent',
      width: width ? `${width?.split(" ")?.join("")}` : isBasic ? '260px' : style?.width || '',
      minWidth: minWidth ? `${minWidth?.split(" ")?.join("")}` : (isBasic ? '0px' : ''),
      maxWidth: maxWidth ? `${maxWidth?.split(" ")?.join("")}` : (isBasic ? 'none' : ''),
      height: height ? `${height?.split(" ")?.join("")}` : isBasic ? '60px' : style?.height || '',
      // boxShadow: shadow ? `0px 4px 6px rgba(0, 0, 0, 0.1)` : style?.boxShadow || 'none',
      transition: transition? `${transition}` :''
      }}> 
     
      {!isBasic ? children : <Element canvas id='random-id-1' is={Text}>
      </Element>}
    </div>
  );
};

Button.craft = {
  displayName: 'Button',
  props: {
    actionState : 'Default',
    background: null,
    color: null,
    actionSelect: {hover: null, scroll: null},
    buttonStyle: 'full',
    text: 'Get started',
    margin: null,
    width: null,
    minWidth:null,
    maxWidth:null,
    marginTop:null,
    marignBottom:null,
    marignRight:null,
    marignLeft:null,
    height: null,
    shadow: null,
    isBasic: false,
    transition:null,
    disabled:'false',
    isButton:true,
    href:null,
  },
  related: {
    toolbar: ButtonSettings,
  },
};
