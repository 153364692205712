/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import { FormControlLabel, Radio } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import React from 'react';

const useStyles = makeStyles({
  icon: {
    // display: 'none',

    borderRadius: '100%',
    minWidth: '2Vmin',
    minHeight: '2Vmin',
    background: 'transparent',
    position: 'relative', 
    border: '0.6Vmin solid rgb(142, 142, 142)',
    // transition: '0.4s cubic-bezier(0.19, 1, 0.22, 1)',
  }, 
  checkedIcon: {
    background: 'white',
    borderColor: 'rgb(19, 115, 230)',

    '&:before': {
      content: '""',
      width: '100%',
      height: '100%',
      borderRadius: '100%',
      background: '#000000',
    },
  },
});

// Inspired by blueprintjs
function StyledRadio(props) {
  const classes = useStyles({});

  return (
    <Radio
      disableRipple 
      color="default"
      checkedIcon={
        <span className={classnames(classes.icon, classes.checkedIcon)} />
      }
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

const useLabelStyles = makeStyles({
  label: {
    fontSize: '1.9Vmin',
    fontFamily:'Inter',
    fontWeight:'500',
  },
});

export const ToolbarRadio = ({selected, value, label }) => {
  const classes = useLabelStyles({});
  return (
    <FormControlLabel
    className='flex justify-between lg:flex-nowrap md:flex-wrap sm:flex-wrap'
    checked={selected}
      classes={classes}
      value={value}
      control={value === "center" ? <StyledRadio /> : <StyledRadio />}
      label={label}
    />
  );
};
