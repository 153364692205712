/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React from 'react';
import styled from 'styled-components';

const SidebarItemDiv = styled.div`
  height: ${(props) =>
    props.visible && props.height && props.height !== 'full'
      ? `${props.height}`
      : 'auto'};
  flex: ${(props) =>
    props.visible && props.height && props.height === 'full' ? `1` : 'unset'};
  color: #545454;
`;

const Chevron = styled.a`
  transform: rotate(${(props) => (props.visible ? 180 : 0)}deg);
  svg {
    width: 8%;
    height: 8%;
  }
`;

const HeaderDiv = styled.div`
  color: #615c5c;
  height: 20%;
  svg {
    fill: #707070;
  }
`;

export const SidebarItem = ({
  visible,
  icon,
  title,
  children,
  height,
  onChange,
}) => {
  return (
    <SidebarItemDiv visible={visible} height={height} className="flex flex-row">
      <HeaderDiv
        onClick={() => {
          if (onChange) onChange(!visible);
        }}
        className={`cursor-pointer bg-white border-b last:border-b-0 flex items-center px-[1Vmin] ${
          visible ? 'shadow-sm' : ''
        }`}
      >
        <div className="flex-1 flex items-center">
          {React.createElement(icon, { className: 'w-[4%] h-[4%] mr-[1Vmin]' })}
          <h2 style={{fontSize:'2Vmin'}} className="uppercase border">{title}</h2>
        </div>
        <Chevron visible={visible}>
          {/* <Arrow /> */}
        </Chevron>
      </HeaderDiv>
      {visible ? (
        <div className="w-[100%] flex-1 overflow-auto">{children}</div>
      ) : null}
    </SidebarItemDiv>
  );
};
