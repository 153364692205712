/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, { useEffect, useState } from 'react';
import { Element, useEditor, useNode } from '@craftjs/core';
import { NewStepsSettings } from './newStepsSetting';
import { Text } from '../../basic/Text';
import ApartmentIcon from '@mui/icons-material/Apartment';
import LightModeIcon from '@mui/icons-material/LightMode';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import { ImageC } from '../../basic/Image';
import { Parent } from '../../Parent/index.js';
import { Wrapper } from '../../wrapper/index.js';
import { BounceLoader } from 'react-spinners';
import { SvgIcon } from '../../basic/Svg';

export const Steps_2 = ({ selected }) => {

    const {
        actions: { setProp },
        connectors: {
            connect
        }
    } = useNode();


    const {
        enabled,
    } = useEditor((state, query) => ({
        enabled: state.options.enabled,

    }));

    const styles = [
        <Element canvas is={Parent} id='parentId100' className="w-[100%] h-auto" >
            <Element canvas is={Parent} id='parentId200' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-400' is='parent' className='w-[100%] h-full bg-[#FFFFFF] '>
                <Wrapper canvas id='random-id-401' is='container' className='container h-full flex flex-col'>
                    <Wrapper canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: '700px', fontFamily: 'Poppins' }} className='w-auto h-full mx-auto text-center text-[#0000000] mt-4' id='random-id-1' is='div'>
                        <Text text='Simple steps of work' />
                    </Wrapper>
                    <Wrapper canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: '400px', fontFamily: 'Poppins' }} className='w-[90%] h-full mx-auto text-center text-[#0000000] my-4 px-6' id='random-id-2' is='div'>
                        <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                    </Wrapper>
                    <Wrapper canvas id='random-id-402' is='wrapper' className='w-full h-full flex flex-row flex-wrap py-20'>
                        <Wrapper canvas id='random-id-403' is='div' className='max-w-[332px] min-w-[300px]  my-4 mx-auto flex flex-col '>
                            <Wrapper canvas id='random-id-404' is='div' className='flex mx-auto' style={{ background: '#F6FA70', width: '100px', height: '100px', borderRadius: '30px', color: '#000000' }}>
                                <Wrapper id='random-id-500' is='div' className='mx-auto my-auto' style={{ width: '60px', height: '60px' }}>
                                <SvgIcon fill='#2479FD' viewBox='0 0 46 46' path='M21.084 44.084a3.833 3.833 0 0 1-3.834-3.834v-3.833h11.5v3.833a3.833 3.833 0 0 1-3.833 3.834zM23 1.917c1.361 0 2.665.172 3.93.498 2.242 3.01 3.737 8.53 3.737 14.835 0 4.37-.728 8.376-1.917 13.417a3.833 3.833 0 0 1-3.833 3.833h-3.833a3.833 3.833 0 0 1-3.834-3.833c-1.188-5.04-1.916-9.047-1.916-13.417 0-6.305 1.495-11.826 3.737-14.835a15.7 15.7 0 0 1 3.93-.498m15.334 13.417c0 6.095-3.546 15.18-8.702 17.652 1.82-3.488 2.952-10.311 2.952-15.736 0-5.424-1.131-10.33-2.952-13.819 5.156 2.473 8.702 5.808 8.702 11.903m-30.667 0c0-6.095 3.546-9.43 8.702-11.903-1.821 3.488-2.952 8.395-2.952 13.82 0 5.423 1.13 12.247 2.952 15.735-5.156-2.472-8.702-11.557-8.702-17.652' />
                                </Wrapper>
                            </Wrapper>
                            <Wrapper canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Poppins', color: '#000000', textAlign: 'center' }} className='flex my-4 justify-center' id='random-id-3' is='div'>
                                <Text text='Lorem ipsum' />
                            </Wrapper>

                            <Wrapper canvas style={{ display: 'inline-block', fontSize: '18px', fontFamily: 'Poppins', color: '#000000', fontWeight :{value:null, type:'String'},
        textAlign :{value:'center', type:'String'},
        textDecoration :{value:null, type:'String'}, 
        fontFamily :{value:null, type:'String'},
        textTransform :{value:null, type:'String'},
fontWeight: '400px', lineHeight: '28.8px' }} className='w-[340px] mx-auto' id='random-id-4' is='div'>
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                            </Wrapper>
                        </Wrapper>
                        <Wrapper canvas id='random-id-405' is='div' className='max-w-[332px] min-w-[300px]  my-4 mx-auto flex flex-col'>
                            <Wrapper canvas id='random-id-406' is='div' className='flex mx-auto' style={{ background: '#F6FA70', width: '100px', height: '100px', borderRadius: '30px', color: '#000000' }}>
                                <Wrapper id='random-id-501' is='div' className='mx-auto my-auto' style={{ width: '60px', height: '60px' }}>
                                <SvgIcon fill='#2479FD' viewBox='0 0 46 46' path='M36.4167 17.2497V13.4163C36.4167 12.3997 36.0128 11.4247 35.2939 10.7058C34.575 9.98688 33.6 9.58301 32.5833 9.58301H30.6667V3.83301H26.8333V9.58301H24.9167C23.9 9.58301 22.925 9.98688 22.2061 10.7058C21.4872 11.4247 21.0833 12.3997 21.0833 13.4163V17.2497C20.0667 17.2497 19.0916 17.6535 18.3728 18.3724C17.6539 19.0913 17.25 20.0663 17.25 21.083V22.9997H9.58333C8.56667 22.9997 7.59165 23.4035 6.87276 24.1224C6.15387 24.8413 5.75 25.8163 5.75 26.833V42.1663H11.5V38.333H15.3333V42.1663H23V38.333H26.8333V42.1663H30.6667V38.333H34.5V42.1663H40.25V21.083C40.25 20.0663 39.8461 19.0913 39.1272 18.3724C38.4084 17.6535 37.4333 17.2497 36.4167 17.2497ZM15.3333 34.4997H11.5V30.6663H15.3333V34.4997ZM26.8333 34.4997H23V30.6663H26.8333V34.4997ZM26.8333 26.833H23V22.9997H26.8333V26.833ZM24.9167 17.2497V13.4163H32.5833V17.2497H24.9167ZM34.5 34.4997H30.6667V30.6663H34.5V34.4997ZM34.5 26.833H30.6667V22.9997H34.5V26.833Z' />
                                </Wrapper>
                            </Wrapper>
                            <Wrapper canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Poppins', color: '#000000', textAlign: 'center' }} className='flex my-4 justify-center' id='random-id-5' is='div'>
                                <Text text='Lorem ipsum' />
                            </Wrapper>

                            <Wrapper canvas style={{ display: 'inline-block', fontSize: '18px', fontFamily: 'Poppins', color: '#000000', fontWeight :{value:null, type:'String'},
        textAlign :{value:'center', type:'String'},
        textDecoration :{value:null, type:'String'}, 
        fontFamily :{value:null, type:'String'},
        textTransform :{value:null, type:'String'},
fontWeight: '400px', lineHeight: '28.8px' }} className='w-[340px] mx-auto' id='random-id-6' is='div'>
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                            </Wrapper>
                        </Wrapper>
                        <Wrapper canvas id='random-id-407' is='div' className='max-w-[332px] min-w-[300px]  my-4 mx-auto flex flex-col'>
                            <Wrapper canvas id='random-id-408' is='div' className='flex mx-auto' style={{ background: '#F6FA70', width: '100px', height: '100px', borderRadius: '30px', color: '#000000' }}>
                                <Wrapper id='random-id-502' is='div' className='mx-auto my-auto' style={{ width: '60px', height: '60px' }}>
                                <SvgIcon fill='#2479FD' viewBox='0 0 46 46' path='M34.5 23a11.5 11.5 0 1 1-23 0 11.5 11.5 0 0 1 23 0M23 0a1.44 1.44 0 0 1 1.438 1.438v5.75a1.437 1.437 0 1 1-2.875 0v-5.75A1.44 1.44 0 0 1 23 0m0 37.375a1.44 1.44 0 0 1 1.438 1.438v5.75a1.438 1.438 0 1 1-2.875 0v-5.75A1.44 1.44 0 0 1 23 37.375M46 23a1.44 1.44 0 0 1-1.437 1.438h-5.75a1.438 1.438 0 1 1 0-2.875h5.75A1.44 1.44 0 0 1 46 23M8.625 23a1.44 1.44 0 0 1-1.437 1.438h-5.75a1.437 1.437 0 1 1 0-2.875h5.75A1.44 1.44 0 0 1 8.625 23M39.264 6.736a1.437 1.437 0 0 1 0 2.033l-4.065 4.068a1.438 1.438 0 0 1-2.033-2.036l4.065-4.065a1.437 1.437 0 0 1 2.033 0m-26.43 26.43a1.437 1.437 0 0 1 0 2.033l-4.065 4.065a1.438 1.438 0 0 1-2.033-2.033l4.065-4.065a1.437 1.437 0 0 1 2.033 0m26.43 6.098a1.44 1.44 0 0 1-2.033 0l-4.065-4.065a1.438 1.438 0 0 1 2.033-2.033l4.065 4.065a1.437 1.437 0 0 1 0 2.033m-26.43-26.427a1.437 1.437 0 0 1-2.033 0L6.736 8.769A1.438 1.438 0 1 1 8.77 6.736l4.065 4.065a1.435 1.435 0 0 1 0 2.036' />
                                </Wrapper>
                            </Wrapper>
                            <Wrapper canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Poppins', color: '#000000', textAlign: 'center' }} className='flex my-4 justify-center' id='random-id-7' is='div'>
                                <Text text='Lorem ipsum' />
                            </Wrapper>

                            <Wrapper canvas style={{ display: 'inline-block', fontSize: '18px', fontFamily: 'Poppins', color: '#000000', fontWeight :{value:null, type:'String'},
        textAlign :{value:'center', type:'String'},
        textDecoration :{value:null, type:'String'}, 
        fontFamily :{value:null, type:'String'},
        textTransform :{value:null, type:'String'},
fontWeight: '400px', lineHeight: '28.8px' }} className='w-[340px] mx-auto' id='random-id-' is='div'>
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                            </Wrapper>
                        </Wrapper>
                    </Wrapper>
                </Wrapper>
            </Wrapper></Element>
        </Element>
        ,
        <Element canvas is={Parent} id='parentId300' className="w-[100%] h-auto" >
            <Element canvas is={Parent} id='parentId400' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-409' is='parent' className='w-[100%] h-full bg-[#FFFFFF] '>
                <Wrapper canvas id='random-id-410' is='container' className='container h-full flex flex-col'>
                    <Wrapper canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: '700px', fontFamily: 'Prompt' }} className='w-auto h-full mx-auto text-center text-[#0000000] mt-4' id='random-id-8' is='div'>
                        <Text text='Simple steps of work' />
                    </Wrapper>
                    <Wrapper canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: '400px', fontFamily: 'Prompt' }} className='w-[90%] h-full mx-auto text-center text-[#0000000] my-4 px-6' id='random-id-9' is='div'>
                        <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                    </Wrapper>
                    <Wrapper canvas id='random-id-411' is='wrapper' className='w-full h-full flex flex-row flex-wrap py-20'>
                        <Wrapper canvas id='random-id-412' is='div' className='max-w-[332px] min-w-[300px]  my-4 mx-auto flex flex-col '>
                            <Wrapper canvas id='random-id-413' is='div' className='flex mx-auto' style={{ background: '#F6FA70', width: '100px', height: '100px', borderRadius: '20px', color: '#000000' }}>
                                <Wrapper id='random-id-503' is='div' className='mx-auto my-auto' style={{ width: '60px', height: '60px' }}>
                                <SvgIcon fill='#FF0060' viewBox='0 0 46 46' path='M21.084 44.084a3.833 3.833 0 0 1-3.834-3.834v-3.833h11.5v3.833a3.833 3.833 0 0 1-3.833 3.834zM23 1.917c1.361 0 2.665.172 3.93.498 2.242 3.01 3.737 8.53 3.737 14.835 0 4.37-.728 8.376-1.917 13.417a3.833 3.833 0 0 1-3.833 3.833h-3.833a3.833 3.833 0 0 1-3.834-3.833c-1.188-5.04-1.916-9.047-1.916-13.417 0-6.305 1.495-11.826 3.737-14.835a15.7 15.7 0 0 1 3.93-.498m15.334 13.417c0 6.095-3.546 15.18-8.702 17.652 1.82-3.488 2.952-10.311 2.952-15.736 0-5.424-1.131-10.33-2.952-13.819 5.156 2.473 8.702 5.808 8.702 11.903m-30.667 0c0-6.095 3.546-9.43 8.702-11.903-1.821 3.488-2.952 8.395-2.952 13.82 0 5.423 1.13 12.247 2.952 15.735-5.156-2.472-8.702-11.557-8.702-17.652' />
                                </Wrapper>
                            </Wrapper>
                            <Wrapper canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Prompt', color: '#000000', textAlign: 'center' }} className='flex my-4 justify-center' id='random-id-10' is='div'>
                                <Text text='Lorem ipsum' />
                            </Wrapper>


                            <Wrapper canvas style={{ display: 'inline-block', fontSize: '18px', fontFamily: 'Prompt', color: '#000000', fontWeight :{value:null, type:'String'},
        textAlign :{value:'center', type:'String'},
        textDecoration :{value:null, type:'String'}, 
        fontFamily :{value:null, type:'String'},
        textTransform :{value:null, type:'String'},
fontWeight: '400px', lineHeight: '28.8px' }} className='w-[340px] mx-auto' id='random-id-11' is='div'>
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                            </Wrapper>

                        </Wrapper>
                        <Wrapper canvas id='random-id-414' is='div' className='max-w-[332px] min-w-[300px]  my-4 mx-auto flex flex-col'>
                            <Wrapper canvas id='random-id-415' is='div' className='flex mx-auto' style={{ background: '#F6FA70', width: '100px', height: '100px', borderRadius: '20px', color: '#000000' }}>
                                <Wrapper id='random-id-504' is='div' className='mx-auto my-auto' style={{ width: '60px', height: '60px' }}>
                                <SvgIcon fill='#FF0060' viewBox='0 0 46 46' path='M36.4167 17.2497V13.4163C36.4167 12.3997 36.0128 11.4247 35.2939 10.7058C34.575 9.98688 33.6 9.58301 32.5833 9.58301H30.6667V3.83301H26.8333V9.58301H24.9167C23.9 9.58301 22.925 9.98688 22.2061 10.7058C21.4872 11.4247 21.0833 12.3997 21.0833 13.4163V17.2497C20.0667 17.2497 19.0916 17.6535 18.3728 18.3724C17.6539 19.0913 17.25 20.0663 17.25 21.083V22.9997H9.58333C8.56667 22.9997 7.59165 23.4035 6.87276 24.1224C6.15387 24.8413 5.75 25.8163 5.75 26.833V42.1663H11.5V38.333H15.3333V42.1663H23V38.333H26.8333V42.1663H30.6667V38.333H34.5V42.1663H40.25V21.083C40.25 20.0663 39.8461 19.0913 39.1272 18.3724C38.4084 17.6535 37.4333 17.2497 36.4167 17.2497ZM15.3333 34.4997H11.5V30.6663H15.3333V34.4997ZM26.8333 34.4997H23V30.6663H26.8333V34.4997ZM26.8333 26.833H23V22.9997H26.8333V26.833ZM24.9167 17.2497V13.4163H32.5833V17.2497H24.9167ZM34.5 34.4997H30.6667V30.6663H34.5V34.4997ZM34.5 26.833H30.6667V22.9997H34.5V26.833Z' />
                                </Wrapper>
                            </Wrapper>
                            <Wrapper canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Prompt', color: '#000000', textAlign: 'center' }} className='flex my-4 justify-center' id='random-id-12' is='div'>
                                <Text text='Lorem ipsum' />
                            </Wrapper>


                            <Wrapper canvas style={{ display: 'inline-block', fontSize: '18px', fontFamily: 'Prompt', color: '#000000', fontWeight :{value:null, type:'String'},
        textAlign :{value:'center', type:'String'},
        textDecoration :{value:null, type:'String'}, 
        fontFamily :{value:null, type:'String'},
        textTransform :{value:null, type:'String'},
fontWeight: '400px', lineHeight: '28.8px' }} className='w-[340px] mx-auto' id='random-id-13' is='div'>
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                            </Wrapper>

                        </Wrapper>
                        <Wrapper canvas id='random-id-416' is='div' className='max-w-[332px] min-w-[300px]  my-4 mx-auto flex flex-col'>
                            <Wrapper canvas id='random-id-417' is='div' className='flex mx-auto' style={{ background: '#F6FA70', width: '100px', height: '100px', borderRadius: '20px', color: '#000000' }}>
                                <Wrapper id='random-id-505' is='div' className='mx-auto my-auto' style={{ width: '60px', height: '60px' }}>
                                <SvgIcon fill='#FF0060' viewBox='0 0 46 46' path='M34.5 23a11.5 11.5 0 1 1-23 0 11.5 11.5 0 0 1 23 0M23 0a1.44 1.44 0 0 1 1.438 1.438v5.75a1.437 1.437 0 1 1-2.875 0v-5.75A1.44 1.44 0 0 1 23 0m0 37.375a1.44 1.44 0 0 1 1.438 1.438v5.75a1.438 1.438 0 1 1-2.875 0v-5.75A1.44 1.44 0 0 1 23 37.375M46 23a1.44 1.44 0 0 1-1.437 1.438h-5.75a1.438 1.438 0 1 1 0-2.875h5.75A1.44 1.44 0 0 1 46 23M8.625 23a1.44 1.44 0 0 1-1.437 1.438h-5.75a1.437 1.437 0 1 1 0-2.875h5.75A1.44 1.44 0 0 1 8.625 23M39.264 6.736a1.437 1.437 0 0 1 0 2.033l-4.065 4.068a1.438 1.438 0 0 1-2.033-2.036l4.065-4.065a1.437 1.437 0 0 1 2.033 0m-26.43 26.43a1.437 1.437 0 0 1 0 2.033l-4.065 4.065a1.438 1.438 0 0 1-2.033-2.033l4.065-4.065a1.437 1.437 0 0 1 2.033 0m26.43 6.098a1.44 1.44 0 0 1-2.033 0l-4.065-4.065a1.438 1.438 0 0 1 2.033-2.033l4.065 4.065a1.437 1.437 0 0 1 0 2.033m-26.43-26.427a1.437 1.437 0 0 1-2.033 0L6.736 8.769A1.438 1.438 0 1 1 8.77 6.736l4.065 4.065a1.435 1.435 0 0 1 0 2.036' />
                                </Wrapper>
                            </Wrapper>
                            <Wrapper canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Prompt', color: '#000000', textAlign: 'center' }} className='flex my-4 justify-center' id='random-id-14' is='div'>
                                <Text text='Lorem ipsum' />
                            </Wrapper>


                            <Wrapper canvas style={{ display: 'inline-block', fontSize: '18px', fontFamily: 'Prompt', color: '#000000', fontWeight :{value:null, type:'String'},
        textAlign :{value:'center', type:'String'},
        textDecoration :{value:null, type:'String'}, 
        fontFamily :{value:null, type:'String'},
        textTransform :{value:null, type:'String'},
fontWeight: '400px', lineHeight: '28.8px' }} className='w-[340px] mx-auto' id='random-id-15' is='div'>
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                            </Wrapper>

                        </Wrapper>
                    </Wrapper>
                </Wrapper>
            </Wrapper></Element>
        </Element>
        ,


        <Element canvas is={Parent} id='parentId500' className="w-[100%] h-auto" >
            <Element canvas is={Parent} id='parentId600' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-418' is='parent' className='w-[100%] h-full bg-[#FFFFFF] '>
                <Wrapper canvas id='random-id-419' is='container' className='container h-full flex flex-col'>

                    <Wrapper canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: '700px', fontFamily: 'Almarai' }} className='w-auto h-full mx-auto text-center text-[#0000000] mt-4' id='random-id-16' is='div'>
                        <Text text='Simple steps of work' />
                    </Wrapper>
                    <Wrapper canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: '400px', fontFamily: 'Almarai' }} className='w-[90%] h-full mx-auto text-center text-[#0000000] my-4 px-6' id='random-id-17' is='div'>
                        <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                    </Wrapper>
                    <Wrapper canvas id='random-id-420' is='wrapper' className='w-full h-full flex flex-row flex-wrap py-20'>
                        <Wrapper canvas id='random-id-421' is='div' className='max-w-[332px] min-w-[300px]  my-4 mx-auto flex flex-col '>
                            <Wrapper canvas id='random-id-422' is='div' className='flex mx-auto' style={{ background: '#FFB7B7', width: '100px', height: '100px', borderRadius: '8px 20px 8px 20px', color: '#000000' }}>
                                <Wrapper id='random-id-506' is='div' className='mx-auto my-auto' style={{ width: '60px', height: '60px' }}>
                                <SvgIcon fill='#F31559' viewBox='0 0 46 46' path='M21.084 44.084a3.833 3.833 0 0 1-3.834-3.834v-3.833h11.5v3.833a3.833 3.833 0 0 1-3.833 3.834zM23 1.917c1.361 0 2.665.172 3.93.498 2.242 3.01 3.737 8.53 3.737 14.835 0 4.37-.728 8.376-1.917 13.417a3.833 3.833 0 0 1-3.833 3.833h-3.833a3.833 3.833 0 0 1-3.834-3.833c-1.188-5.04-1.916-9.047-1.916-13.417 0-6.305 1.495-11.826 3.737-14.835a15.7 15.7 0 0 1 3.93-.498m15.334 13.417c0 6.095-3.546 15.18-8.702 17.652 1.82-3.488 2.952-10.311 2.952-15.736 0-5.424-1.131-10.33-2.952-13.819 5.156 2.473 8.702 5.808 8.702 11.903m-30.667 0c0-6.095 3.546-9.43 8.702-11.903-1.821 3.488-2.952 8.395-2.952 13.82 0 5.423 1.13 12.247 2.952 15.735-5.156-2.472-8.702-11.557-8.702-17.652' />
                                </Wrapper>
                            </Wrapper>
                            <Wrapper canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Almarai', color: '#000000', textAlign: 'center' }} className='flex my-4 justify-center' id='random-id-18' is='div'>
                                <Text text='Lorem ipsum' />
                            </Wrapper>

                            <Wrapper canvas style={{ display: 'inline-block', fontSize: '18px', fontFamily: 'Almarai', color: '#000000', fontWeight :{value:null, type:'String'},
        textAlign :{value:'center', type:'String'},
        textDecoration :{value:null, type:'String'}, 
        fontFamily :{value:null, type:'String'},
        textTransform :{value:null, type:'String'},
fontWeight: '400px', lineHeight: '28.8px' }} className='w-[340px] mx-auto' id='random-id-19' is='div'>
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                            </Wrapper>

                        </Wrapper>
                        <Wrapper canvas id='random-id-423' is='div' className='max-w-[332px] min-w-[300px]  my-4 mx-auto flex flex-col'>
                            <Wrapper canvas id='random-id-424' is='div' className='flex mx-auto' style={{ background: '#FFB7B7', width: '100px', height: '100px', borderRadius: '20px 8px 20px 8px', color: '#000000' }}>
                                <Wrapper id='random-id-507' is='div' className='mx-auto my-auto' style={{ width: '60px', height: '60px' }}>
                                <SvgIcon fill='#F31559' viewBox='0 0 46 46' path='M36.4167 17.2497V13.4163C36.4167 12.3997 36.0128 11.4247 35.2939 10.7058C34.575 9.98688 33.6 9.58301 32.5833 9.58301H30.6667V3.83301H26.8333V9.58301H24.9167C23.9 9.58301 22.925 9.98688 22.2061 10.7058C21.4872 11.4247 21.0833 12.3997 21.0833 13.4163V17.2497C20.0667 17.2497 19.0916 17.6535 18.3728 18.3724C17.6539 19.0913 17.25 20.0663 17.25 21.083V22.9997H9.58333C8.56667 22.9997 7.59165 23.4035 6.87276 24.1224C6.15387 24.8413 5.75 25.8163 5.75 26.833V42.1663H11.5V38.333H15.3333V42.1663H23V38.333H26.8333V42.1663H30.6667V38.333H34.5V42.1663H40.25V21.083C40.25 20.0663 39.8461 19.0913 39.1272 18.3724C38.4084 17.6535 37.4333 17.2497 36.4167 17.2497ZM15.3333 34.4997H11.5V30.6663H15.3333V34.4997ZM26.8333 34.4997H23V30.6663H26.8333V34.4997ZM26.8333 26.833H23V22.9997H26.8333V26.833ZM24.9167 17.2497V13.4163H32.5833V17.2497H24.9167ZM34.5 34.4997H30.6667V30.6663H34.5V34.4997ZM34.5 26.833H30.6667V22.9997H34.5V26.833Z' />
                                </Wrapper>
                            </Wrapper>
                            <Wrapper canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Almarai', color: '#000000', textAlign: 'center' }} className='flex my-4 justify-center' id='random-id-20' is='div'>
                                <Text text='Lorem ipsum' />
                            </Wrapper>


                            <Wrapper canvas style={{ display: 'inline-block', fontSize: '18px', fontFamily: 'Almarai', color: '#000000', fontWeight :{value:null, type:'String'},
        textAlign :{value:'center', type:'String'},
        textDecoration :{value:null, type:'String'}, 
        fontFamily :{value:null, type:'String'},
        textTransform :{value:null, type:'String'},
fontWeight: '400px', lineHeight: '28.8px' }} className='w-[340px] mx-auto' id='random-id-21' is='div'>
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                            </Wrapper>

                        </Wrapper>
                        <Wrapper canvas id='random-id-425' is='div' className='max-w-[332px] min-w-[300px]  my-4 mx-auto flex flex-col'>
                            <Wrapper canvas id='random-id-426' is='div' className='flex mx-auto' style={{ background: '#FFB7B7', width: '100px', height: '100px', borderRadius: '8px 20px 20px 20px', color: '#000000' }}>
                                <Wrapper id='random-id-508' is='div' className='mx-auto my-auto' style={{ width: '60px', height: '60px' }}>
                                <SvgIcon fill='#F31559' viewBox='0 0 46 46' path='M34.5 23a11.5 11.5 0 1 1-23 0 11.5 11.5 0 0 1 23 0M23 0a1.44 1.44 0 0 1 1.438 1.438v5.75a1.437 1.437 0 1 1-2.875 0v-5.75A1.44 1.44 0 0 1 23 0m0 37.375a1.44 1.44 0 0 1 1.438 1.438v5.75a1.438 1.438 0 1 1-2.875 0v-5.75A1.44 1.44 0 0 1 23 37.375M46 23a1.44 1.44 0 0 1-1.437 1.438h-5.75a1.438 1.438 0 1 1 0-2.875h5.75A1.44 1.44 0 0 1 46 23M8.625 23a1.44 1.44 0 0 1-1.437 1.438h-5.75a1.437 1.437 0 1 1 0-2.875h5.75A1.44 1.44 0 0 1 8.625 23M39.264 6.736a1.437 1.437 0 0 1 0 2.033l-4.065 4.068a1.438 1.438 0 0 1-2.033-2.036l4.065-4.065a1.437 1.437 0 0 1 2.033 0m-26.43 26.43a1.437 1.437 0 0 1 0 2.033l-4.065 4.065a1.438 1.438 0 0 1-2.033-2.033l4.065-4.065a1.437 1.437 0 0 1 2.033 0m26.43 6.098a1.44 1.44 0 0 1-2.033 0l-4.065-4.065a1.438 1.438 0 0 1 2.033-2.033l4.065 4.065a1.437 1.437 0 0 1 0 2.033m-26.43-26.427a1.437 1.437 0 0 1-2.033 0L6.736 8.769A1.438 1.438 0 1 1 8.77 6.736l4.065 4.065a1.435 1.435 0 0 1 0 2.036' />
                                </Wrapper>
                            </Wrapper>
                            <Wrapper canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Almarai', color: '#000000', textAlign: 'center' }} className='flex my-4 justify-center' id='random-id-22' is='div'>
                                <Text text='Lorem ipsum' />
                            </Wrapper>


                            <Wrapper canvas style={{ display: 'inline-block', fontSize: '18px', fontFamily: 'Almarai', color: '#000000', fontWeight :{value:null, type:'String'},
        textAlign :{value:'center', type:'String'},
        textDecoration :{value:null, type:'String'}, 
        fontFamily :{value:null, type:'String'},
        textTransform :{value:null, type:'String'},
fontWeight: '400px', lineHeight: '28.8px' }} className='w-[340px] mx-auto' id='random-id-23' is='div'>
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                            </Wrapper>

                        </Wrapper>
                    </Wrapper>
                </Wrapper>
            </Wrapper></Element>
        </Element>
        ,


        <Wrapper canvas id='random-id-427' is='parent' className='w-[100%] h-full bg-[#FFFFFF] '>
            <Wrapper canvas id='random-id-428' is='container' className='container h-full flex flex-col'>
                <Wrapper canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: '700px', fontFamily: 'Chivo' }} className='w-auto h-full mx-auto text-center text-[#0000000] mt-4' id='random-id-24' is='div'>
                    <Text text='Simple steps of work' />
                </Wrapper>
                <Wrapper canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: '400px', fontFamily: 'Chivo' }} className='w-[90%] h-full mx-auto text-center text-[#0000000] my-4 px-6' id='random-id-25' is='div'>
                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                </Wrapper>
                <Wrapper canvas id='random-id-429' is='wrapper' className='w-full h-full flex flex-row flex-wrap py-20'>
                    <Wrapper canvas id='random-id-431' is='div' className='max-w-[332px] min-w-[300px]  my-4 mx-auto flex flex-col '>
                        <Wrapper canvas id='random-id-432' is='div' className='flex mx-auto' style={{ background: '#D4ADFC', width: '100px', height: '100px', borderRadius: '8px', color: '#000000' }}>
                            <Wrapper id='random-id-509' is='div' className='mx-auto my-auto' style={{ width: '60px', height: '60px' }}>
                            <SvgIcon fill='#0C134F' viewBox='0 0 46 46' path='M21.084 44.084a3.833 3.833 0 0 1-3.834-3.834v-3.833h11.5v3.833a3.833 3.833 0 0 1-3.833 3.834zM23 1.917c1.361 0 2.665.172 3.93.498 2.242 3.01 3.737 8.53 3.737 14.835 0 4.37-.728 8.376-1.917 13.417a3.833 3.833 0 0 1-3.833 3.833h-3.833a3.833 3.833 0 0 1-3.834-3.833c-1.188-5.04-1.916-9.047-1.916-13.417 0-6.305 1.495-11.826 3.737-14.835a15.7 15.7 0 0 1 3.93-.498m15.334 13.417c0 6.095-3.546 15.18-8.702 17.652 1.82-3.488 2.952-10.311 2.952-15.736 0-5.424-1.131-10.33-2.952-13.819 5.156 2.473 8.702 5.808 8.702 11.903m-30.667 0c0-6.095 3.546-9.43 8.702-11.903-1.821 3.488-2.952 8.395-2.952 13.82 0 5.423 1.13 12.247 2.952 15.735-5.156-2.472-8.702-11.557-8.702-17.652' />
                            </Wrapper>
                        </Wrapper>
                        <Wrapper canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Chivo', color: '#000000', textAlign: 'center' }} className='flex my-4 justify-center' id='random-id-26' is='div'>
                            <Text text='Lorem ipsum' />
                        </Wrapper>


                        <Wrapper canvas style={{ display: 'inline-block', fontSize: '18px', fontFamily: 'Chivo', color: '#000000', fontWeight :{value:null, type:'String'},
        textAlign :{value:'center', type:'String'},
        textDecoration :{value:null, type:'String'}, 
        fontFamily :{value:null, type:'String'},
        textTransform :{value:null, type:'String'},
fontWeight: '400px', lineHeight: '28.8px' }} className='w-[340px] mx-auto' id='random-id-27' is='div'>
                            <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                        </Wrapper>

                    </Wrapper>
                    <Wrapper canvas id='random-id-433' is='div' className='max-w-[332px] min-w-[300px]  my-4 mx-auto flex flex-col'>
                        <Wrapper canvas id='random-id-434' is='div' className='flex mx-auto' style={{ background: '#D4ADFC', width: '100px', height: '100px', borderRadius: '8px', color: '#000000' }}>
                            <Wrapper id='random-id-510' is='div' className='mx-auto my-auto' style={{ width: '60px', height: '60px' }}>
                            <SvgIcon fill='#0C134F' viewBox='0 0 46 46' path='M36.4167 17.2497V13.4163C36.4167 12.3997 36.0128 11.4247 35.2939 10.7058C34.575 9.98688 33.6 9.58301 32.5833 9.58301H30.6667V3.83301H26.8333V9.58301H24.9167C23.9 9.58301 22.925 9.98688 22.2061 10.7058C21.4872 11.4247 21.0833 12.3997 21.0833 13.4163V17.2497C20.0667 17.2497 19.0916 17.6535 18.3728 18.3724C17.6539 19.0913 17.25 20.0663 17.25 21.083V22.9997H9.58333C8.56667 22.9997 7.59165 23.4035 6.87276 24.1224C6.15387 24.8413 5.75 25.8163 5.75 26.833V42.1663H11.5V38.333H15.3333V42.1663H23V38.333H26.8333V42.1663H30.6667V38.333H34.5V42.1663H40.25V21.083C40.25 20.0663 39.8461 19.0913 39.1272 18.3724C38.4084 17.6535 37.4333 17.2497 36.4167 17.2497ZM15.3333 34.4997H11.5V30.6663H15.3333V34.4997ZM26.8333 34.4997H23V30.6663H26.8333V34.4997ZM26.8333 26.833H23V22.9997H26.8333V26.833ZM24.9167 17.2497V13.4163H32.5833V17.2497H24.9167ZM34.5 34.4997H30.6667V30.6663H34.5V34.4997ZM34.5 26.833H30.6667V22.9997H34.5V26.833Z' />
                            </Wrapper>
                        </Wrapper>
                        <Wrapper canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Chivo', color: '#000000', textAlign: 'center' }} className='flex my-4 justify-center' id='random-id-28' is='div'>
                            <Text text='Lorem ipsum' />
                        </Wrapper>


                        <Wrapper canvas style={{ display: 'inline-block', fontSize: '18px', fontFamily: 'Chivo', color: '#000000', fontWeight :{value:null, type:'String'},
        textAlign :{value:'center', type:'String'},
        textDecoration :{value:null, type:'String'}, 
        fontFamily :{value:null, type:'String'},
        textTransform :{value:null, type:'String'},
fontWeight: '400px', lineHeight: '28.8px' }} className='w-[340px] mx-auto' id='random-id-29' is='div'>
                            <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                        </Wrapper>

                    </Wrapper>
                    <Wrapper canvas id='random-id-435' is='div' className='max-w-[332px] min-w-[300px]  my-4 mx-auto flex flex-col'>
                        <Wrapper canvas id='random-id-436' is='div' className='flex mx-auto' style={{ background: '#D4ADFC', width: '100px', height: '100px', borderRadius: '8px', color: '#000000' }}>
                            <Wrapper id='random-id-511' is='div' className='mx-auto my-auto' style={{ width: '60px', height: '60px' }}>
                            <SvgIcon fill='#0C134F' viewBox='0 0 46 46' path='M34.5 23a11.5 11.5 0 1 1-23 0 11.5 11.5 0 0 1 23 0M23 0a1.44 1.44 0 0 1 1.438 1.438v5.75a1.437 1.437 0 1 1-2.875 0v-5.75A1.44 1.44 0 0 1 23 0m0 37.375a1.44 1.44 0 0 1 1.438 1.438v5.75a1.438 1.438 0 1 1-2.875 0v-5.75A1.44 1.44 0 0 1 23 37.375M46 23a1.44 1.44 0 0 1-1.437 1.438h-5.75a1.438 1.438 0 1 1 0-2.875h5.75A1.44 1.44 0 0 1 46 23M8.625 23a1.44 1.44 0 0 1-1.437 1.438h-5.75a1.437 1.437 0 1 1 0-2.875h5.75A1.44 1.44 0 0 1 8.625 23M39.264 6.736a1.437 1.437 0 0 1 0 2.033l-4.065 4.068a1.438 1.438 0 0 1-2.033-2.036l4.065-4.065a1.437 1.437 0 0 1 2.033 0m-26.43 26.43a1.437 1.437 0 0 1 0 2.033l-4.065 4.065a1.438 1.438 0 0 1-2.033-2.033l4.065-4.065a1.437 1.437 0 0 1 2.033 0m26.43 6.098a1.44 1.44 0 0 1-2.033 0l-4.065-4.065a1.438 1.438 0 0 1 2.033-2.033l4.065 4.065a1.437 1.437 0 0 1 0 2.033m-26.43-26.427a1.437 1.437 0 0 1-2.033 0L6.736 8.769A1.438 1.438 0 1 1 8.77 6.736l4.065 4.065a1.435 1.435 0 0 1 0 2.036' />
                            </Wrapper>
                        </Wrapper>
                        <Wrapper canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Chivo', color: '#000000', textAlign: 'center' }} className='flex my-4 justify-center' id='random-id-30' is='div'>
                            <Text text='Lorem ipsum' />
                        </Wrapper>


                        <Wrapper canvas style={{ display: 'inline-block', fontSize: '18px', fontFamily: 'Chivo', color: '#000000', fontWeight :{value:null, type:'String'},
        textAlign :{value:'center', type:'String'},
        textDecoration :{value:null, type:'String'}, 
        fontFamily :{value:null, type:'String'},
        textTransform :{value:null, type:'String'},
fontWeight: '400px', lineHeight: '28.8px' }} className='w-[340px] mx-auto' id='random-id-31' is='div'>
                            <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                        </Wrapper>

                    </Wrapper>
                </Wrapper>
            </Wrapper>
        </Wrapper>
    ]


    const [sel, setSel] = useState(selected)

    function makeOdd(number) {
        setProp((prop) => {
            setSel(100);
        }, 1000);

        setTimeout(() => {
            setProp((prop) => {
                setSel(number)
            }, 1000);
        }, 100);



    }

    useEffect(() => {
        if (selected || selected >= 0) {
            makeOdd(selected);
        }
    }, [selected])



    return (
        <div style={{ padding: enabled ? "10px" : "0px", display: "flex", justifyContent: "center", alignItems: "center", width: '100%', height: '100%' }} ref={connect}>
            {sel == 100 ? (
                <div style={{ height: "85vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <BounceLoader
                        size={100}
                        color={"white"}
                        loading={true}
                    />
                </div>
            ) : (
                styles[sel]
            )}
        </div>
    );
}

Steps_2.craft = {
    displayName: "Steps 2",
    props: {
        selected: 1, // here is the default value of the selected style
        length: 4,
        isBlock: true
    },
    rules: {
        canDrag: () => true,
        canDrop: () => true,
    },
    related: {
        toolbar: NewStepsSettings,
    },
};