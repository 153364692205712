/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, { useEffect, useState } from 'react';
import CheckTwoToneIcon from '@mui/icons-material/CheckTwoTone';
import {AyccLogo } from '../EditorSvg.js';
import '../ToolBox.css'
import { Box, Modal,Typography, FormControl, FormControlLabel, FormGroup, Checkbox } from '@mui/material';

import StarRating from '../StarRating/StarRating.js';



export const DownloadModal = (props) => {


    const {
        open,
        onClose,
        downloadingProcess,
        selectAll,
        handleChangeChecked,
        checkedValue,
        rateValue,
        setRateValue,
        positionValue,
        setPositionValue,
        bugsMessage,
        setBugsMessage,
        wishMessage,
        setWishMessage,
        downloading,
        setDownloadModal
    } = props

     const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    height:'80Vmin',
    transform: 'translate(-50%, -50%)',
    width: '60Vmin',
    bgcolor: 'background.paper',
    borderRadius: '2.5Vmin',
    boxShadow: 24,
    padding:'3Vmin',
    transition: 'all .3s ease-in-out',
    overflow:'auto',
    scrollbarWidth: 'none',
    scrollbarColor: 'transparent transparent',
  };



    const [rateValueChange, setRateValueChange] = useState(3)
    const [positionValueChange, setPositionValueChange] = useState('')
    const [bugsMessageChange, setBugsMessageChange] = useState('')
    const [wishMessageChange, setWishMessageChange] = useState('')

    useEffect(() => {
 if(rateValue) {
    setRateValueChange(rateValue)
 }
    },[rateValue])



    useEffect(() => {
        if(positionValue) {
            setPositionValueChange(positionValue)
        }
           },[positionValue])

           


           useEffect(() => {
            if(bugsMessage) {
                setBugsMessageChange(bugsMessage)
            }
               },[bugsMessage])
           



               useEffect(() => {
                if(wishMessage) {
                    setWishMessageChange(wishMessage)
                }
                   },[wishMessage])
               

    return (
        <Modal
        open={open ? true : false}
        onClose={onClose}
        >
        <Box sx={style}>
          {!downloadingProcess ? (<>
            <div>
              <div className='flex flex-col justify-items-center px-[1.5Vmin] mt-[1Vmin]'>
                      <div className='text-center w-full text-[#000]' style={{fontWeight:'700', color:'#000', fontFamily:'Inter', fontSize:'3.6Vmin'}}>Help us to become  better</div>
                      <div className='flex flex-col my-[1.5Vmin]'>
                        <div className='text-center' style={{fontWeight:'500', color:'#000', fontFamily:'Inter', fontSize:'3Vmin'}}>Rate Allyoucancloud</div>
                        <div
                          style={{
                            justifyContent:'center', justifyItems:'center', display:'flex', alignItems:'center', alignContent:'center', margin:'0 auto'
                          }}
                        >
                          <StarRating value={rateValueChange} 
                          
                          onBlur={() => {
                            setRateValue(rateValueChange)
                          }}
                          onChange={(event, newValue) => {
                              setRateValueChange(newValue);
                            }} />
                        </div>

                      </div>
                      <div style={{display:'flex', flexDirection:'column', alignItems:'center', alignContent:'center', marginBottom:'2Vmin'}}>
                      <div style={{fontWeight:'500', color:'#000', fontFamily:'Inter', fontSize:'2.4Vmin', width:'100%', marginBottom:'.5Vmin'}}>Select your position</div>

                      <select value={positionValueChange} 
                          
                          onBlur={() => {
                            setPositionValue(positionValueChange)
                          }}
                          onChange={(e) => setPositionValueChange(e.target.value)} className='p-[2%] border-[.25Vmin] border-[#E9E9EA] custom-select ' style={{borderRadius:'3Vmin', fontSize:'2.4Vmin', margin:'0'}}>
                            <option value="Web Developer">Web Developer</option>
                            <option value="Designers">Designers</option>
                            <option value="Students">Students</option>
                            <option value="School">School</option>
                          </select>
                        </div>
                      <div style={{display:'flex', flexDirection:'column', alignItems:'center', alignContent:'center', marginBottom:'2Vmin'}}>
                        <div style={{fontWeight:'500', color:'#000', fontFamily:'Inter', fontSize:'2.4Vmin', width:'100%', marginBottom:'.5Vmin'}}>Found any bug? Please, let us know</div>
                        <textarea value={bugsMessageChange} 
                        onBlur={() => {
                            setBugsMessage(bugsMessageChange)
                        }}
                        onChange={(e) => setBugsMessageChange(e.target.value)} className='min-h-[11Vmin] w-full border-[.25Vmin] border-[#E9E9EA] p-[2%] mt-[1%]' style={{borderRadius:'1.5Vmin',color:'#000',fontSize:'2.4Vmin' ,}}  fullWidth  placeholder='Your message' />
                      </div>
                      <div style={{display:'flex', flexDirection:'column', alignItems:'center', alignContent:'center', marginBottom:'2Vmin'}}>
                        <div style={{fontWeight:'500', color:'#000', fontFamily:'Inter', fontSize:'2.4Vmin', width:'100%', marginBottom:'.5Vmin'}}>Want any feature? We can make it real!</div>
                        <textarea value={wishMessageChange} 
                        onBlur={() => {
                            setWishMessage(wishMessageChange)
                        }}
                        onChange={(e) => setWishMessageChange(e.target.value)} className='min-h-[11Vmin] w-full border-[.25Vmin] border-[#E9E9EA] p-[2%] my-[1%]' style={{borderRadius:'1.5Vmin',color:'#000',fontSize:'2.4Vmin' ,}} fullWidth placeholder='Your message' />
                      </div>
                  </div>
        </div>
        <div style={{ display: 'flex', flexFlow: 'row', justifyContent: 'center', height:'fit-content', padding:'.8Vmin 0' }}>
          <div className='bg-[transparent] cursor-pointer hover:bg-[#0867FC] text-[#0867FC] hover:text-[#fff]' onClick={() => setDownloadModal(false)}
          style={{ border: '.25Vmin solid #0867FC', width: '17Vmin', height: '6Vmin', margin: '1.5Vmin', display: 'flex', borderRadius: '3Vmin' }}>
            <div className='text-[2Vmin]' style={{ fontWeight: '600', margin: 'auto auto' }}>Cancel</div>
          </div>
          <div className='bg-[#0867FC] cursor-pointer hover:bg-[#0156DC]' style={{ width: '22Vmin', height: '6Vmin', margin: '1.5Vmin', display: 'flex', borderRadius: '3Vmin' }}>
            <div className='text-[2Vmin] text-[#FFF]'
              onClick={() => downloading()}
              style={{ fontWeight: '600', margin: 'auto auto' }}>Download</div>
          </div>
        </div>
      </>) : (
        <div sx={{ flexFlow: 'column', justifyContent: 'center', alignItems: 'center', justifyItems: 'center', alignContent: 'center', display: 'flex' }}>
          <div className='h-full' style={{ margin: '4Vmin auto', fontWeight :{value:null, type:'String'},
        textAlign :{value:'center', type:'String'},
        textDecoration :{value:null, type:'String'}, 
        fontFamily :{value:null, type:'String'},
        textTransform :{value:null, type:'String'},
fontSize:'3.5Vmin', fontWeight:'500', fontFamily:'Inter', color:'#000' }}>
            Thank you for choosing us!
          </div>
          <div className='w-full h-full flex justify-center align-items-center'>
          <AyccLogo width='25Vmin' height='25Vmin' />
          </div>
          <div className='h-full' style={{ margin: '4Vmin auto', fontWeight :{value:null, type:'String'},
        textAlign :{value:'center', type:'String'},
        textDecoration :{value:null, type:'String'}, 
        fontFamily :{value:null, type:'String'},
        textTransform :{value:null, type:'String'},
fontSize:'3.5Vmin', fontWeight:'500', fontFamily:'Inter', color:'#000' }}>
            Your file is in the process of downloading, please do not close the page until the download is complete
          </div>
        </div>
      )}
    </Box>
  </Modal>
)
}