import React, { useMemo, useState } from 'react';
import { useNode, useEditor } from '@craftjs/core';
import { FormSettings } from './FormSettings';

export const BasicForm = ({
    style = {},
    text = 'Click here',
    href,
    color = '#000',
    width,
    height,
    background,
    fontSize = '16px',
    borderColor,
    borderWidth,
    borderRadius,
    textAlign,
    shadowX,
    shadowY,
    shadowBlur,
    shadowColor,
    marginTop,
    marginRight,
    marginBottom,
    marginLeft,
    fontWeight,
    fontStyle,
    textDecoration,
    textTransform,
    fontFamily,
    lineHeight,
    firstName= 'First Name',
    lastName= 'Last Name',
    email= 'Email',


    backgroundColorButton = '#0867FC', 
    colorButton = 'white',        
    borderButton = 'none',            
    paddingButton = '10px 15px',        
    borderRadiusButton = '5px',         
    cursorButton = 'pointer',          
    fontSizeButton = '16px',            
    transitionButton = 'background-color 0.3s', 
    displayButton ='flex',
    justifyContentButton ='center',
    alignContentButton ='center',
widthInputs= '100%',               
    paddingInputs= '10px',            
    borderInputs= '1px solid #ccc',    
    borderRadiusInputs= '5px',        
    marginBottomInputs= '10px',        
    fontSizeInputs= '16px',          
    colorInputs='#000',
colorLabels = 'black',             
    marginBottomLabels = '5px',  
    fontWeightLabels = 'bold',   
    
}) => {
    const {
        connectors: { connect },
        actions: { setProp },
    } = useNode();

    const { enabled } = useEditor((state) => ({
        enabled: state.options.enabled,
    }));

    
  const handleProperty = (value, propertyName, split) => {
    if (value !== null && !split) {
      return { [propertyName]: value?.split(" ").join("") }
    }

    if (value !== null && split === 'splitBg') {
      return { [propertyName]:  value !== null ? value : '' }
    } else {
      return;
    }
  };


    const styleProps = useMemo(() => ({
      ...(color !== null && {
        color: color?.startsWith('#') ? color : 'transparent',
        backgroundImage: !color?.startsWith('#') ? color : undefined,
        WebkitBackgroundClip: !color?.startsWith('#') ? 'text' : undefined,
        backgroundClip: !color?.startsWith('#') ? 'text' : undefined,
      }),

        boxShadow: `${(shadowX?.split(" ").join(""))} ${(shadowY?.split(" ").join(""))} ${(shadowBlur?.split(" ").join(""))} ${shadowColor || `rgba(255, 255, 255)`}`
    ,
    
    ...handleProperty(width, 'width'),
    ...handleProperty(height, 'height'),

    ...handleProperty(background, 'background', 'splitBg'),

    ...handleProperty(fontSize, 'fontSize'),
    ...handleProperty(textTransform, 'textTransform'),
    ...handleProperty(marginTop, 'marginTop'),
    ...handleProperty(marginRight, 'marginRight'),
    ...handleProperty(marginBottom, 'marginBottom'),
    ...handleProperty(marginLeft, 'marginLeft'),
    ...handleProperty(fontFamily, 'fontFamily'),
    ...handleProperty(textDecoration, 'textDecoration'),
    ...handleProperty(fontStyle, 'fontStyle'),
    ...handleProperty(textAlign, 'textAlign'),
    ...handleProperty(fontWeight, 'fontWeight'),
    ...handleProperty(lineHeight, 'lineHeight'),
    ...handleProperty(borderColor, 'borderColor'),
    ...handleProperty(borderWidth, 'borderWidth'),
    ...handleProperty(borderRadius, 'borderRadius'),

    }), [
      color, fontSize, fontWeight, width, height,
      shadowX,
      shadowY,
      shadowColor,
      shadowBlur, fontStyle, fontFamily, background, borderRadius, borderColor, borderWidth, lineHeight, textDecoration, textTransform, textAlign, marginTop, marginRight, marginBottom, marginLeft]);


      const [firstNameValue, setFirstNameValue] = useState('');
      const [lastNameValue, setLastNameValue] = useState('');
      const [emailValue, setEmailValue] = useState('');
      const [data, setData] = useState({})
  
      const handleSubmit = (event) => {
          event.preventDefault();
          setData({ firstName: firstNameValue, lastName: lastNameValue, email: emailValue });
        };

        const styleSubmitButton = {
            ...handleProperty(backgroundColorButton, 'backgroundColor'),
            ...handleProperty(colorButton, 'color'),
            ...handleProperty(borderButton, 'border'),
            ...handleProperty(paddingButton, 'padding'),
            ...handleProperty(borderRadiusButton, 'borderRadius'),
            ...handleProperty(cursorButton, 'cursor'),
            ...handleProperty(fontSizeButton, 'fontSize'),
            ...handleProperty(transitionButton, 'transition'),
            ...handleProperty(displayButton, 'display'),
            ...handleProperty(justifyContentButton, 'justifyContent'),
            ...handleProperty(alignContentButton, 'alignContent'),
        };


        const styleInputsForm = {
            ...handleProperty(widthInputs, 'width'),
            ...handleProperty(paddingInputs, 'padding'),
            ...handleProperty(borderInputs, 'border'),
            ...handleProperty(borderRadiusInputs, 'borderRadius'),
            ...handleProperty(marginBottomInputs, 'marginBottom'),
            ...handleProperty(fontSizeInputs, 'fontSize'),
            ...handleProperty(colorInputs, 'color'),
        };

        const styleLabels = {
            ...handleProperty(colorLabels, 'color'),
            ...handleProperty(marginBottomLabels, 'marginBottom'),
            ...handleProperty(fontWeightLabels, 'fontWeight'),
        };


    return (
        <div ref={connect} style={{ display: 'inline-block' }}>
           <form onSubmit={handleSubmit}>
            <div style={{...styleProps, padding:'10px'}}>
                <div>
                    <div style={{...styleLabels}}>{firstName}</div>
                    <input
                    name='name'
                    style={{...styleInputsForm, border:'.25vmin solid #e9e9e9', borderRadius:'1.5vmin'}}
                        value={firstNameValue} 
                        onChange={(e) => setFirstNameValue(e.target.value)} 
                    />
                </div>
                <div>
                    <div style={{...styleLabels}}>{lastName}</div>
                    <input
                    name='name'
                    style={{...styleInputsForm, border:'.25vmin solid #e9e9e9', borderRadius:'1.5vmin'}}
                        value={lastNameValue} 
                        onChange={(e) => setLastNameValue(e.target.value)} 
                    />
                </div>
                <div>
                    <div style={{...styleLabels}}>{email}</div>
                    <input
                    name='email'
                    style={{...styleInputsForm, border:'.25vmin solid #e9e9e9', borderRadius:'1.5vmin'}}
                        value={emailValue} 
                        onChange={(e) => setEmailValue(e.target.value)} 
                    />
                </div>
                <div style={{...styleSubmitButton, ...style}}>
                    <button type="submit">Submit</button>
                </div>
            </div>
        </form>
        </div>
    );
};

BasicForm.craft = {
    displayName: 'Form',
    props: {
    text: 'Click here',
    href: null,
    color: '#000',
    width:null,
    height:null,
    background:null,
    fontSize: '16px',
    shadowX: null,
    shadowY: null,
    shadowColor:null,
    shadowBlur: null,
    marginTop: null,
    marginRight: null,
    marginBottom: null,
    marginLeft: null,
    borderRadius: null,
    background:null,
    borderColor:null,
    borderWidth:null,
    textAlign:null,
    fontWeight:null,
    textTransform:null,
    fontStyle:null,
    textDecoration:null,
    fontFamily:null,
    textTransform:null,
    lineHeight:null,
    
    backgroundColorButton: '#0867FC', 
    colorButton: 'white',        
    borderButton: 'none',            
    paddingButton: '10px 15px',        
    borderRadiusButton: '5px',         
    cursorButton: 'pointer',          
    fontSizeButton: '16px',            
    transitionButton: 'background-color 0.3s', 
    displayButton:'flex',
    justifyContentButton:'center',
    alignContentButton:'center',
    widthInputs: '100%',               
    paddingInputs: '10px',            
    borderInputs: '1px solid #ccc',    
    borderRadiusInputs: '5px',        
    marginBottomInputs: '10px',        
    fontSizeInputs: '16px',          
    colorInputs:'#000',
    colorLabels: 'black',             
    marginBottomLabels: '5px',  
    fontWeightLabels: 'bold', 

    isForm:true
        
    },
    related: {
        toolbar: FormSettings, // Create a LinkSettings component for custom options
    },
};
