/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import { useNode, useEditor, Element } from '@craftjs/core';
import React, { useEffect, useState } from 'react';
import ContentEditable from 'react-contenteditable';
import { TextSettings } from './TextSettings';
import { useActiveIdContext } from '../../../../ActiveIdContext';
import { useOptionsContext } from '../../../../OptionsContext';
import { SvgIcon } from '../Svg';
import { Wrapper } from '../../wrapper/index.js';
import Draggable from 'react-draggable';
import { Parent } from '../../Parent/index.js';


export const Text = ({
  style = {},
  fontSize,
  textAlign,
  fontWeight,
  color,
  shadowX,
  shadowY,
  shadowBlur,
  shadowColor,
  text,
  href,
  selected,
  fontStyle,
  isBasic,
  textDecoration,
  width,
  fontFamily,
  textTransform,
  lineHeight,
  onclick,
  marginTop,
  marginRight,
  marginBottom,
  marginLeft,
  isSvg = false,
  ableToMove,
  background,
  transition,
  headingNum,
  menu,
}) => {
  const {
    connectors: { connect },
    actions: { setProp },

  } = useNode((node) => ({
    selected: node.events.selected,
  }));


  const {
    nodes,
    enabled,
  } = useEditor((state) => ({
    enabled: state.options.enabled,
    nodes: state.nodes
  }));


  const { state, dispatch: setActiveId } = useActiveIdContext();
  const { activeId: activeId } = state;
  const { state: idState, dispatch: idDispatch } = useOptionsContext();
  const { options: treeData } = idState;

  const handleProperty = (value, propertyName, split) => {
    if (value !== null && !split) {
      return { [propertyName]: value?.split(" ").join("") }
    }

    if (value !== null && split === 'splitBg') {
      return { [propertyName]:  value !== null ? value : '' }
    } else {
      return;
    }
  };


  const styleProps = {

      ...(color !== null && {
        color: color?.startsWith('#') ? color : 'transparent',
        backgroundImage: !color?.startsWith('#') ? color : undefined,
        WebkitBackgroundClip: !color?.startsWith('#') ? 'text' : undefined,
        backgroundClip: !color?.startsWith('#') ? 'text' : undefined,
      }),

      ...((shadowX || shadowY || shadowColor || shadowBlur) !== null ? {
        textShadow: `${(shadowX?.split(" ").join(""))} ${(shadowY?.split(" ").join(""))} ${(shadowBlur?.split(" ").join(""))} ${shadowColor || `rgba(255, 255, 255)`}`
    } : null),
    
    ...handleProperty(width, 'width'),
    ...handleProperty(background, 'background', 'splitBg'),

    // ...handleProperty(shadow, 'boxShadow'),
    ...handleProperty(fontSize, 'fontSize'),
    ...handleProperty(textTransform, 'textTransform'),
    ...handleProperty(marginTop, 'marginTop'),
    ...handleProperty(marginRight, 'marginRight'),
    ...handleProperty(marginBottom, 'marginBottom'),
    ...handleProperty(marginLeft, 'marginLeft'),
    ...handleProperty(fontFamily, 'fontFamily'),
    ...handleProperty(textDecoration, 'textDecoration'),
    ...handleProperty(fontStyle, 'fontStyle'),
    ...handleProperty(textAlign, 'textAlign'),
    ...handleProperty(fontWeight, 'fontWeight'),
    ...handleProperty(lineHeight, 'lineHeight'),
  };

  const headingTags = {
    'Heading one': 'h1',
    'Two': 'h2',
    'Three': 'h3',
    'Four': 'h4',
    'Five': 'h5',
    'Six: Smallest' : 'h6'
};

const tagName = headingNum !== null ? headingTags[headingNum] : 'div';



  return (
    <>
      {isSvg ?
        <Element canvas is={Parent} id='parentId12' style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center' }}>
          <SvgIcon height='30px' width='30px' viewBox='0 0 24 24' fill='#000' path='M16.5 4.5C16.5 5.17031 16.3547 5.80781 16.0875 6.38437L18.5625 10.6594C17.4516 11.8453 16.0219 12.7266 14.4141 13.1719L12 9L8.8125 14.5078C9.82031 14.8266 10.8891 15 12.0047 15C15.3188 15 18.2766 13.4672 20.2031 11.0625C20.7234 10.4156 21.6656 10.3125 22.3125 10.8281C22.9594 11.3438 23.0625 12.2906 22.5469 12.9375C20.0672 16.0219 16.2656 18 12 18C10.3406 18 8.74688 17.7 7.27969 17.1516L4.62656 21.7359C4.40625 22.1156 4.07812 22.425 3.68438 22.6219L1.0875 23.9203C0.853125 24.0375 0.576563 24.0234 0.35625 23.8875C0.135938 23.7516 0 23.5078 0 23.25V20.6531C0 20.2594 0.103125 19.8703 0.304688 19.5234L3.11719 14.6625C2.51719 14.1375 1.95938 13.5609 1.4625 12.9375C0.942188 12.2906 1.05 11.3484 1.69688 10.8281C2.34375 10.3078 3.28594 10.4156 3.80625 11.0625C4.07344 11.3953 4.35938 11.7094 4.65938 12.0047L7.9125 6.38437C7.65 5.8125 7.5 5.175 7.5 4.5C7.5 2.01562 9.51563 0 12 0C14.4844 0 16.5 2.01562 16.5 4.5ZM17.4844 18.4641C19.0125 17.8641 20.4141 17.0203 21.6516 15.9844L23.7 19.5234C23.8969 19.8656 24.0047 20.2547 24.0047 20.6531V23.25C24.0047 23.5078 23.8688 23.7516 23.6484 23.8875C23.4281 24.0234 23.1516 24.0375 22.9172 23.9203L20.3203 22.6219C19.9266 22.425 19.5984 22.1156 19.3781 21.7359L17.4844 18.4641ZM12 6C12.3978 6 12.7794 5.84196 13.0607 5.56066C13.342 5.27936 13.5 4.89782 13.5 4.5C13.5 4.10218 13.342 3.72064 13.0607 3.43934C12.7794 3.15804 12.3978 3 12 3C11.6022 3 11.2206 3.15804 10.9393 3.43934C10.658 3.72064 10.5 4.10218 10.5 4.5C10.5 4.89782 10.658 5.27936 10.9393 5.56066C11.2206 5.84196 11.6022 6 12 6Z' />
        </Element>
        : (
            <ContentEditable
              val={href?.name} _id="sidebarItemx"
              onClick={(e) => {
                e.preventDefault(); // Prevent default action of anchor tag

                if (!enabled) {
                  if (href && href._id && href._id.includes("www")) {
                  // If href exists and href._id includes "www", open a new tab with the URL
                  window.open("https://" + href.name, "_blank");
                } else if (href && href._id && href.name === 'dom') {
                  const element = Object.values(nodes).find((node) => node.id === href._id);

                  if (element) {

                    if (element.dom) {
                      element.dom.scrollIntoView({ behavior: 'smooth', block: 'start' });
                    } else {
                      console.log("DOM Element not found for ID:", element.id);
                    }
                  } else {
                    console.log("Element with ID not found:", href.id);
                  }
                  }
                  else if (href && href.name !== 'dom') {
                    // Find the item in treeData based on href._id
                    const parentItem = treeData.find((x) => x._id === href._id);
                    if (parentItem) {
                      // Set activeId to the parent item if found
                      setActiveId(parentItem);
                    } else {
                      // If parent item not found, find the child item in treeData
                      let childItem = null;
                      for (const parent of treeData) {
                        const child = parent.children.find((child) => child._id === href._id);
                        if (child) {
                          childItem = child;
                          break;
                        }
                      }
                      // Set activeId to the child item if found
                      setActiveId(childItem);
                    }
                  }
                }
              }}
            
            
            innerRef={connect}
            html={text} // innerHTML of the editable div
            disabled={!enabled}
            onChange={(e) => {
              setProp((prop) => (prop.text = e.target.value), 500);
            }}
            tagName={tagName}
            style={{
              ...styleProps, ...style, fontSize:"25px", cursor: href && !enabled ? "pointer" : "",
              borderRadius: enabled ? '8px' : '', 
              // transition: transition ? `${transition}` : '',
            }}
            className={(href && href.name && activeId && activeId?.name && text) && (href.name.toLowerCase() === activeId?.name.toLowerCase() || text.toLowerCase() === activeId?.name.toLowerCase()) ? selected : ""}

          />

        )}

    </>
      );
};

Text.craft = {
  displayName: 'Text',
  props: {
    width: null,
    fontSize: "30",
    textAlign: null,
    fontWeight: null,
    color: null,
    margin: null,
    shadowX: null,
    shadowY: null,
    shadowColor:null,
    shadowBlur: null,
    marginTop: null,
    headingNum: null,
    marginRight: null,
    marginBottom: null,
    marginLeft: null,
    text: 'Lorem ipsum',
    href: null,
    lineHeight: null,
    fontStyle: "normal",
    textDecoration: null,
    fontFamily: null,
    isText: true,
    isBasic: false,
    isSvg: false,
    background:null,
    transition: null,
    textTransform: null,
  },
  rules: {
    canDrag: () => true,
  },
  related: {
    toolbar: TextSettings,
  },
};
