/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, { useEffect, useRef, useState } from 'react';

import { Element, useEditor, useNode } from '@craftjs/core';
import { NewPriceSettings } from './newPriceSetting';

import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import InstagramIcon from '@mui/icons-material/Instagram';
import FitbitIcon from '@mui/icons-material/Fitbit';
import MenuIcon from '@mui/icons-material/Menu'; 
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { Text } from '../../basic/Text';
import { Button } from '../../basic/Button';
import { Parent } from '../../../selectors/Parent/index';
import { Wrapper } from '../../../selectors/wrapper/index';
import { BounceLoader } from 'react-spinners';

export const Price_1 = ({ selected }) => {

  const { 
    actions: { setProp },
      connectors: {
        connect
      } 
  } = useNode();

  
  const {
    enabled,
  } = useEditor((state, query) => ({
    enabled: state.options.enabled,
 
  }));

  const styles = [
     <Element canvas is={Parent} id='parentId100' className="w-[100%] h-auto" >
  <Element canvas is={Parent} id='parentId200' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-401' is='parent' className='w-[100%] h-full'   style={{background:'white'}}>
    <Wrapper canvas id='random-id-402' is='container' className="container">
    <Wrapper canvas id='random-id-403' is='container' className="flex flex-col text-center pt-10 flex flex-col" >            
        <Wrapper canvas style={{ display: "inline-block", fontFamily:'Rubik' }} className={`text-[46px]  text-black`} id="random-id-1" is="div">
            <Text text="Ready to get started?" />
        </Wrapper>
        <Wrapper canvas style={{ display: "inline-block", fontFamily:'Rubik' }} className={`text-[#B20312] text-[46px]  text-[#B20312]`} id="random-id-2" is="div">
            <Text text="Select your plan." />
        </Wrapper>
    </Wrapper>
    <Wrapper canvas id='random-id-404' is='container' className="flex align-content-center flex justify-center py-10 justify-content-center" style={{flexFlow:'row wrap'}}>
    <Wrapper canvas id='random-id-405' is='wrapper' className="h-full flex flex-col p-7 m-4" style={{background:'transparent', border:'1px solid #DBD9D9', flex:'28%', maxWidth:'380px',  minHeight:'408px'}}>
            <Wrapper canvas id='random-id-406' is='div' className="p-10 flex flex-col">
                <Wrapper canvas style={{ display: "inline-block", fontWeight:'700', fontFamily:'Rubik' }} className="w-full h-full text-[36px] text-[#100103]" id="random-id-3" is="div">
                    <Text text="Basic" />
                </Wrapper>
                <Wrapper canvas style={{ display: "inline-block", fontSize:'16px', fontFamily:'Rubik' }} className="content font-normal text-[100103] my-10" id="random-id-4" is="div">
                    <Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
                </Wrapper>
            <Wrapper canvas id='random-id-407' is='div' className="flex flex-row w-[200px] ">
                <Wrapper canvas style={{ display: "inline-block", fontSize:'36px', fontWeight:'400', fontFamily:'Rubik' }} className=" items-center text-[#B20312]"  id="random-id-5" is="div">
                    <Text text="0$" />
                </Wrapper>
                <Wrapper canvas style={{ display: "inline-block", fontFamily:'Rubik'}} className="items-center text-[16px] font-normal text-[#100103]  ml-5 pt-4" id="random-id-6" is="div">
                    <Text text="per month" />
                </Wrapper>
            </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-200' is='div' className="w-[100%] flex justify-center">
                <Button  classn='block' class='w-[260px] h-[70px] flex justify-center items-center text-center py-[15px] my-7 text-[#FFFFFF] bg-[#B20312] hover:bg-[#87000C] cursor-pointer' 
                >
                    <Wrapper canvas style={{ display: "inline-block", fontSize:'22px', fontWeight:'600', textTransform: 'uppercase', fontFamily:'Rubik' }} id="random-id-7" is="div">
                        <Text text="Get Started" />
                    </Wrapper>
                </Button>
            </Wrapper>
        </Wrapper>
        <Wrapper canvas id='random-id-408' is='container' className=" h-full flex flex-col p-7 m-4" style={{background:'transparent',  border:'1px solid #DBD9D9', flex:'28%', maxWidth:'380px',  minHeight:'408px'}}>
            <Wrapper canvas id='random-id-409' is='div' className="p-10 flex flex-col">
            <Wrapper canvas id='random-id-410' is='div' className="w-full text-[36px] text-[#100103]" style={{ fontWeight:'700',fontFamily:'Rubik' }}>
                <Text text='Team'/>
            </Wrapper>
            <Wrapper canvas style={{ display: "inline-block", fontSize:'16px', fontFamily:'Rubik' }} className="content my-10 font-normal text-[#100103]" id="random-id-8" is="div">
                <Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
            </Wrapper>
            <Wrapper canvas id='random-id-411' is='div' className="flex flex-row w-[200px]">
                <Wrapper canvas id='random-id-1000' style={{fontSize:'36px', fontWeight:'400', fontFamily:'Rubik', display:'inline-block'}} is='div'>
                    <Text text='$12' className="items-center text-[#B20312]" />
                </Wrapper>
                <Wrapper canvas style={{ display: "inline-block", fontFamily:'Rubik' }} className="items-center text-[16px] font-normal ml-5 pt-4 text-[#100103]" id="random-id-9" is="div">
                    <Text text="per month" />
                </Wrapper>
            </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-201' is='div' className="w-[100%] flex justify-center">
                <Button  classn='block' className="my-7 text-[#FFFFFF] w-[260px] h-[70px] flex justify-center items-center text-center py-[15px]  bg-[#B20312] hover:bg-[#87000C] cursor-pointer" 
                >
                <Wrapper canvas style={{ display: "inline-block", fontSize:'22px', fontWeight:'600', textTransform: 'uppercase',fontFamily:'Rubik' }} id="random-id-10" is="div">
                    <Text text="Get Started" />
                </Wrapper>
                </Button>
            </Wrapper>
        </Wrapper>
    <Wrapper canvas id='random-id-412' is='container' className=" h-full flex flex-col p-7 m-4" style={{background:'transparent', border:'1px solid #DBD9D9', flex:'28%', maxWidth:'380px',  minHeight:'408px'}}>
        <Wrapper canvas id='random-id-413' is='wrapper' className="p-10 flex flex-col">
            <Wrapper canvas style={{ display: "inline-block", fontWeight:'700', fontFamily:'Rubik' }} className="w-full h-full text-[36px] text-[#100103]" id="random-id-11" is="div">
                <Text text="Pro" />
            </Wrapper>
            <Wrapper canvas style={{ display: "inline-block", fontSize:'16px', fontFamily:'Rubik' }} className="content font-normal text-[100103] my-10" id="random-id-12" is="div">
                <Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
            </Wrapper>
            <Wrapper canvas id='random-id-414' is='div' className="flex flex-row w-[200px] ">
                <Wrapper canvas style={{ display: "inline-block", fontSize:'36px', fontWeight:'400', fontFamily:'Rubik' }} className=" items-center text-[#B20312]" id="random-id-13" is="div">
                    <Text text="22$" />
                </Wrapper>
                <Wrapper canvas style={{ display: "inline-block", fontFamily:'Rubik' }} className="items-center text-[16px] font-normal text-[#100103]  ml-5 pt-4" id="random-id-14" is="div">
                <Text text="per month" />
                </Wrapper>
            </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-203' is='div' className="w-[100%] flex justify-center">
                <Button  classn='block' className="my-7 text-[#FFFFFF] w-[260px] h-[70px] flex justify-center items-center text-center py-[15px]  bg-[#B20312] hover:bg-[#87000C] cursor-pointer"
                 >
                    <Wrapper canvas style={{ display: "inline-block", fontSize:'22px', fontWeight:'600', textTransform: 'uppercase', fontFamily:'Rubik' }} id="random-id-15" is="div">
                    <Text text="Get Started" />
                    </Wrapper>
                </Button>
            </Wrapper>
        </Wrapper>
    </Wrapper>
    </Wrapper>
    </Wrapper></Element>
  </Element>
,

 <Element canvas is={Parent} id='parentId300' className="w-[100%] h-auto" >
  <Element canvas is={Parent} id='parentId400' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-415' is='parent' className='w-[100%] h-full'  style={{background:'#121826'}}>
<Wrapper canvas id='random-id-416' is='container' className="container">
<Wrapper canvas id='random-id-417' is='div' className="flex flex-col text-center pt-10" >        
<Wrapper canvas style={{ display: "inline-block", fontFamily:'Rubik' }} className={`text-[46px]  text-[#FFFFFF]`} id="random-id-16" is="div">
    <Text text="Ready to get started?" />
</Wrapper>
<Wrapper canvas style={{ display: "inline-block", fontFamily:'Rubik' }} className={`IncreaseConversion2 text-[46px]  text-[#FFFFFF]`} id="random-id-17" is="div">
    <Text text="Select your plan." />
</Wrapper>
</Wrapper>

<Wrapper canvas id='random-id-418' is='container' className="align-content-center flex justify-center py-10 justify-content-center" style={{flexFlow:'row wrap',}}>

<Wrapper canvas id='random-id-419' is='wrapper' className=" h-full flex flex-col p-7 m-4" style={{flex:'28%', maxWidth:'380px', minHeight:'408px', background:'transparent', boxShadow: '0px 0px 4px rgba(255, 255, 255, 0.10)'}}>
        <Wrapper canvas id='random-id-420' is='div' className="p-10 flex flex-col">
        <Wrapper canvas style={{ display: "inline-block", fontWeight:'700', fontFamily:'Rubik' }} className="w-full h-full text-[36px] text-[#FFFFFF] " id="random-id-18" is="div">
            <Text text="Team" />
        </Wrapper>
        <Wrapper canvas style={{ display: "inline-block", fontSize:'16px', fontWeight:'400', fontFamily:'Rubik' }} className="content my-10 font-normal text-[#FFFFFF]" id="random-id-19" is="div">
            <Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
        </Wrapper>
        <Wrapper canvas id='random-id-421' is='div' className="flex flex-row w-[200px]">
            <Wrapper canvas style={{ display: "inline-block", fontSize:'36px', fontWeight:'400', fontFamily:'Rubik' }} className="items-center text-[#3265e3]" id="random-id-20" is="div">
                <Text text="22$" />
            </Wrapper>
            <Wrapper canvas style={{ display: "inline-block", fontFamily:'Rubik' }} className="items-center text-[16px] font-normal ml-5 pt-4 text-[#FFFFFF]" id="random-id-21" is="div">
                <Text text="per month" />
            </Wrapper>
        </Wrapper>
        </Wrapper>
        <Wrapper canvas id='random-id-204' is='div' className="w-[100%] flex justify-center">
            <Button  classn='block' className="my-7 py-[15px] w-[260px] h-[70px] flex justify-center items-center text-center bg-[#3265e3] hover:bg-[#0049FF] cursor-pointer leading-[30.80px] text-[#FFFFFF]"
             >
            <Wrapper canvas style={{ fontSize:'22px', fontWeight:'600', textTransform: 'uppercase',fontFamily:'Rubik', display: "inline-block" }} id="random-id-22" is="div">
                <Text text="Get Started" />
            </Wrapper>
            </Button>
        </Wrapper>
</Wrapper>

<Wrapper canvas id='random-id-422' is='wrapper' className=" h-full flex flex-col p-7 m-4" style={{flex:'28%', maxWidth:'380px', minHeight:'408px', background:'transparent', boxShadow: '0px 0px 4px rgba(255, 255, 255, 0.10)'}}>
        <Wrapper canvas id='random-id-423' is='div' className="p-10 flex flex-col">
        <Wrapper canvas style={{ display: "inline-block", fontWeight:'700',fontFamily:'Rubik' }} className="w-full h-full text-[36px] text-[#FFFFFF] " id="random-id-120" is="div">
            <Text text="Team" />
        </Wrapper>
        <Wrapper canvas style={{ display: "inline-block", fontSize:'16px', fontWeight:'400', fontFamily:'Rubik' }} className="content my-10 font-normal text-[#FFFFFF]" id="random-id-23" is="div">
            <Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
        </Wrapper>
        <Wrapper canvas id='random-id-424' is='div' className="flex flex-row w-[200px]">
            <Wrapper canvas style={{ display: "inline-block", fontSize:'36px', fontWeight:'400', fontFamily:'Rubik' }} className="items-center text-[#3265e3]" id="random-id-121" is="div">
                <Text text="$12" />
            </Wrapper>
            <Wrapper canvas style={{ display: "inline-block", fontFamily:'Rubik' }} className="items-center text-[16px] font-normal ml-5 pt-4 text-[#FFFFFF]" id="random-id-24" is="div">
                <Text text="per month" />
            </Wrapper>
        </Wrapper>
        </Wrapper>
        <Wrapper canvas id='random-id-205' is='div' className="w-[100%] flex justify-center">
            <Button  classn='block' className="my-7 py-[15px] w-[260px] h-[70px] flex justify-center items-center text-center bg-[#3265e3] hover:bg-[#0049FF] cursor-pointer leading-[30.80px] text-[#FFFFFF]" 
            >
            <Wrapper canvas style={{fontSize:'22px', fontWeight:'600', textTransform: 'uppercase',fontFamily:'Rubik',  display: "inline-block" }} id="random-id-25" is="div">
                <Text text="Get Started" />
            </Wrapper>
            </Button>
        </Wrapper>
</Wrapper>

<Wrapper canvas id='random-id-425' is='wrapper' className=" h-full flex flex-col p-7 m-4" style={{flex:'28%', maxWidth:'380px', minHeight:'408px', background:'transparent', boxShadow: '0px 0px 4px rgba(255, 255, 255, 0.10)'}}>
        <Wrapper canvas id='random-id-426' is='div' className="p-10 flex flex-col">
        <Wrapper canvas style={{ display: "inline-block", fontWeight:'700', fontFamily:'Rubik' }} className="w-full h-full text-[36px] text-[#FFFFFF]" id="random-id-26" is="div">
            <Text text="Pro" />
        </Wrapper>        
        <Wrapper canvas style={{ display: "inline-block", fontSize:'16px', fontWeight:'400', fontFamily:'Rubik' }} className="content my-10 font-normal text-[#FFFFFF]" id="random-id-27" is="div">
            <Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
        </Wrapper>
        <Wrapper canvas id='random-id-427' is='div' className="flex flex-row w-[200px]">
        <Wrapper canvas style={{ display: "inline-block", fontSize:'36px', fontWeight:'400', fontFamily:'Rubik' }} className=" items-center text-[#3265e3]" id="random-id-28" is="div">
            <Text text="22$" />
        </Wrapper>
        <Wrapper canvas style={{ display: "inline-block", fontFamily:'Rubik'}} className="items-center text-[16px] font-normal ml-5 pt-4 text-[#FFFFFF]" id="random-id-29" is="div">
            <Text text="per month" />
        </Wrapper>
        </Wrapper>
        </Wrapper>
        <Wrapper canvas id='random-id-206' is='div' className="w-[100%] flex justify-center">
            <Button  classn='block' className="my-7 py-[15px] w-[260px] h-[70px] flex justify-center items-center text-center bg-[#3265e3] hover:bg-[#0049FF] cursor-pointer leading-[30.80px] text-[#FFFFFF]" 
            >
            <Wrapper canvas style={{ display: "inline-block" , fontSize:'22px', fontWeight:'600', textTransform: 'uppercase', fontFamily:'Rubik'}} id="random-id-30" is="div">
                <Text text="Get Started" />
            </Wrapper>
            </Button>
        </Wrapper>
</Wrapper>
</Wrapper>
</Wrapper>
</Wrapper></Element>
  </Element>,

 <Element canvas is={Parent} id='parentId500' className="w-[100%] h-auto" >
  <Element canvas is={Parent} id='parentId600' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-428' is='parent' className='w-[100%] h-full'  style={{background:'#213156'}}>
<Wrapper canvas id='random-id-429' is='container' className="container">
<Wrapper canvas id='random-id-430' is='div' className="flex flex-col text-center pt-10" >        
<Wrapper canvas style={{ display: "inline-block", fontFamily:'Yeseva One' }} className={`text-[46px]  leading-[39.20px] text-[#FFFFFF]`} id="random-id-31" is="div">
<Text text="Ready to get started?" />
</Wrapper>
<Wrapper canvas style={{ display: "inline-block", fontFamily:'Yeseva One' }} className={`text-[46px]  leading-[39.20pxb] text-[#ea6ef4]`} id="random-id-32" is="div">
<Text text="Select your plan." />
</Wrapper>
</Wrapper>
<Wrapper canvas id='random-id-431' is='container' className="flex align-content-center  flex justify-center py-10 justify-content-center" style={{flexFlow:'row wrap'}}>

    <Wrapper canvas id='random-id-432' is='wrapper' className=" h-full flex flex-col p-7 m-4 "
    style={{background:'transparent', flex:'28%', minHeight:'408px', maxWidth:'380px', borderRadius: 20, border: '1px rgba(234, 110, 244, 0.04) solid', boxShadow: '0px 0px 4px rgba(255, 255, 255, 0.10)'}}>
        <Wrapper canvas id='random-id-433' is='div' className="p-10 flex flex-col">            
<Wrapper canvas style={{ display: "inline-block", fontWeight:'700', fontFamily:'Open Sans' }} className="w-full h-full text-[36px] text-[#FFFFFF] " id="random-id-33" is="div">
<Text text="Basic" />
</Wrapper>        
<Wrapper canvas style={{ display: "inline-block", fontSize:'16px', fontWeight:'400', fontFamily:'Open Sans' }} className="content  my-10 font-normal text-[#FFFFFF]" id="random-id-34" is="div">
<Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
</Wrapper>
        <Wrapper canvas id='random-id-434' is='div' className="flex flex-row w-[200px]  ">
<Wrapper canvas style={{ display: "inline-block", fontSize:'36px', fontWeight:'400', fontFamily:'Open Sans' }} className=" items-center text-[#ea6ef4]" id="random-id-35" is="div">
<Text text="0$" />
</Wrapper>
<Wrapper canvas style={{ display: "inline-block", fontFamily:'Open Sans' }} className="items-center text-[16px] font-normal ml-5 pt-4 text-[#FFFFFF]" id="random-id-36" is="div">
<Text text="per month" />
</Wrapper>
        </Wrapper>
        </Wrapper>
        <Wrapper canvas id='random-id-207' is='div' className="w-[100%] flex justify-center" style={{borderRadius:'40px'}}>
            <Button  classn='block' className="my-7 py-[15px] w-[260px] h-[70px] flex justify-center items-center text-center bg-[#ea6ef4] hover:bg-[#E111F2] cursor-pointer leading-[30.80px] text-[#FFFFFF]" 
            >
<Wrapper canvas style={{ display: "inline-block" , fontSize:'22px', fontWeight:'600', textTransform: 'uppercase', fontFamily:'Open Sans', borderRadius:'40px'}} id="random-id-37" is="div">
<Text text="Get Started" />
</Wrapper>
            </Button>
        </Wrapper>
    </Wrapper>
    <Wrapper canvas id='random-id-435' is='wrapped' className=" h-full flex flex-col p-7 m-4 " 
    style={{background:'transparent', flex:'28%', minHeight:'408px', maxWidth:'380px', borderRadius: 20, border: '1px rgba(234, 110, 244, 0.04) solid', boxShadow: '0px 0px 4px rgba(255, 255, 255, 0.10)'}}>
        <Wrapper canvas id='random-id-436' is='div' className="p-10 flex flex-col">
            <Wrapper canvas style={{ display: "inline-block", fontWeight:'700',fontFamily:'Open Sans' }} className="w-full h-full  text-[36px] text-[#FFFFFF] " id="random-id-123" is="div">
<Text text="Team" />
</Wrapper>
<Wrapper canvas style={{ display: "inline-block", fontSize:'16px', fontWeight:'400', fontFamily:'Open Sans' }} className="content  my-10 font-normal text-[#FFFFFF]" id="random-id-38" is="div">
<Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
</Wrapper>
        <Wrapper canvas id='random-id-437' is='div' className="flex flex-row w-[200px]  ">
            <Wrapper canvas style={{ display: "inline-block", fontSize:'36px', fontWeight:'400', fontFamily:'Open Sans' }} className="  items-center text-[#ea6ef4]" id="random-id-123" is="div">
<Text text="$12" />
</Wrapper> 
<Wrapper canvas style={{ display: "inline-block", fontFamily:'Open Sans' }} className="items-center text-[16px] font-normal ml-5 pt-4 text-[#FFFFFF]" id="random-id-39" is="div">
<Text text="per month" />
</Wrapper>
        </Wrapper>
        </Wrapper>
        <Wrapper canvas id='random-id-208' is='div' className="w-[100%] flex justify-center" style={{borderRadius:'40px'}}>
            <Button  classn='block' className="my-7 py-[15px] w-[260px] h-[70px] flex justify-center items-center text-center bg-[#ea6ef4] hover:bg-[#E111F2] cursor-pointer leading-[30.80px] text-[#FFFFFF]" 
            >
<Wrapper canvas style={{ display: "inline-block" , fontSize:'22px', fontWeight:'600', textTransform: 'uppercase',fontFamily:'Open Sans', borderRadius:'40px'}} id="random-id-40" is="div">
<Text text="Get Started" />
</Wrapper>
            </Button>
        </Wrapper>
    </Wrapper>
    <Wrapper canvas id='random-id-438' is='wrapped' className=" h-full flex flex-col p-7 m-4 " 
    style={{background:'transparent', flex:'28%', minHeight:'408px', maxWidth:'380px', borderRadius: 20, border: '1px rgba(234, 110, 244, 0.04) solid', boxShadow: '0px 0px 4px rgba(255, 255, 255, 0.10)'}}>
        <Wrapper canvas id='random-id-439' is='div' className="p-10 flex flex-col">
<Wrapper canvas style={{ display: "inline-block", fontWeight:'700', fontFamily:'Open Sans' }} className="w-full h-full text-[36px] text-[#FFFFFF]" id="random-id-41" is="div">
<Text text="Pro" />
</Wrapper>        
<Wrapper canvas style={{ display: "inline-block", fontSize:'16px', fontWeight:'400', fontFamily:'Open Sans' }} className="content  my-10 font-normal  text-[#FFFFFF]" id="random-id-42" is="div">
<Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
</Wrapper>
        <Wrapper canvas id='random-id-440' is='div' className="flex flex-row w-[200px]  ">
<Wrapper canvas style={{ display: "inline-block", fontSize:'36px', fontWeight:'400', fontFamily:'Open Sans' }} className=" items-center text-[#ea6ef4]" id="random-id-43" is="div">
<Text text="22$" />
</Wrapper>
<Wrapper canvas style={{ display: "inline-block", fontFamily:'Open Sans' }} className="items-center text-[16px] font-normal ml-5 pt-4 text-[#FFFFFF]" id="random-id-44" is="div">
<Text text="per month" />
</Wrapper>
        </Wrapper>
        </Wrapper>
        <Wrapper canvas id='random-id-209' is='div' className="w-[100%] flex justify-center" style={{borderRadius:'40px'}}>
            <Button  classn='block' className="my-7 py-[15px] w-[260px] h-[70px] flex justify-center items-center text-center bg-[#ea6ef4] hover:bg-[#E111F2] cursor-pointer leading-[30.80px] text-[#FFFFFF]" 
            >
<Wrapper canvas style={{ display: "inline-block", fontSize:'22px', fontWeight:'600', textTransform: 'uppercase', fontFamily:'Open Sans', borderRadius:'40px' }} id="random-id-45" is="div">
<Text text="Get Started" />
</Wrapper>
            </Button>
        </Wrapper>
    </Wrapper>
</Wrapper>
</Wrapper>
</Wrapper></Element>
  </Element>,


 <Element canvas is={Parent} id='parentId700' className="w-[100%] h-auto" >
  <Element canvas is={Parent} id='parentId800' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-441' is='parent' className='w-[100%] h-full'  style={{background:'#DFFFF9'}}>
<Wrapper canvas id='random-id-442' is='container' className="container">
<Wrapper canvas id='random-id-443' is='div' className="flex flex-col text-center pt-10" >        
<Wrapper canvas style={{ display: "inline-block", fontFamily: 'Zing Script Rust SemiBold Demo' }} className={`text-[46px]  leading-[39.20px] text-[#031815]`} id="random-id-46" is="div">
<Text text="Ready to get started?" />
</Wrapper>
<Wrapper canvas style={{ display: "inline-block", fontFamily: 'Zing Script Rust SemiBold Demo' }} className={`text-[46px]  leading-[39.20pxb] text-[#31A993]`} id="random-id-47" is="div">
<Text text="Select your plan." />
</Wrapper>
</Wrapper>
<Wrapper canvas id='random-id-444' is='container' className="flex align-content-center  flex justify-center py-10 justify-content-center" style={{flexFlow:'row wrap'}}>

    <Wrapper canvas id='random-id-445' is='wrapper' className=" h-full flex flex-col p-7 m-4 " style={{background:'transparent', borderRadius: '20px', flex:'28%', minHeight:'408px', maxWidth:'380px', border: '1px solid #31A99333', boxShadow: '0px 0px 4px 0px #0318101A' }}>
        <Wrapper canvas id='random-id-446' is='div' className="p-10 flex flex-col">            
<Wrapper canvas style={{ display: "inline-block", fontWeight:'700', fontFamily:'Open Sans' }} className="w-full -h-full text-[36px] text-[#031815] " id="random-id-48" is="div">
<Text text="Basic" />
</Wrapper>        
<Wrapper canvas style={{ display: "inline-block", fontSize:'16px', fontWeight:'400', fontFamily:'Montserrat' }} className="content my-10 font-normal text-[#031815]" id="random-id-49" is="div">
<Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
</Wrapper>
        <Wrapper canvas id='random-id-447' is='div' className="flex flex-row w-[200px]">
<Wrapper canvas style={{ display: "inline-block", fontSize:'36px', fontWeight:'400', fontFamily:'Open Sans' }} className=" items-center text-[#31A993]" id="random-id-50" is="div">
<Text text="0$" />
</Wrapper>
<Wrapper canvas style={{ display: "inline-block", fontFamily:'Open Sans' }} className="items-center text-[16px] font-normal ml-5 pt-4 text-[#031815]" id="random-id-51" is="div">
<Text text="per month" />
</Wrapper>
        </Wrapper>
        </Wrapper>
        <Wrapper canvas id='random-id-210' is='div' className="w-[100%] flex justify-center" style={{borderRadius:'40px'}}>
            <Button  classn='block' className="flex justify-center w-[260px] h-[60px] flex flex justify-center items-center text-center bg-[#31A993] hover:bg-[#11816C] cursor-pointer py-[15px] items-center text-[#FFFFFF]" 
            >
<Wrapper canvas style={{ display: "inline-block", fontSize:'22px', fontWeight:'600', textTransform: 'uppercase', fontFamily:'Open Sans', borderRadius:'40px' }} id="random-id-52" is="div">
<Text text="Get Started" />
</Wrapper>
            </Button>
        </Wrapper>
    </Wrapper>
    <Wrapper canvas id='random-id-448' is='wrapper' className=" h-full flex flex-col p-7 m-4  "  style={{background:'transparent', borderRadius: '20px', flex:'28%', minHeight:'408px', maxWidth:'380px', border: '1px solid #31A99333', boxShadow: '0px 0px 4px 0px #0318101A' }}>
        <Wrapper canvas id='random-id-449' is='div' className="p-10 flex flex-col">
        <Wrapper canvas style={{ display: "inline-block", fontWeight:'700',fontFamily:'Open Sans' }} className="w-full h-full text-[36px] text-[#031815] " id="random-id-124" is="div">
<Text text="Team" />
</Wrapper>        
<Wrapper canvas style={{ display: "inline-block", fontSize:'16px', fontWeight:'400', fontFamily:'Montserrat' }} className="content my-10 font-normal text-[#031815]" id="random-id-53" is="div">
<Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
</Wrapper>
        <Wrapper canvas id='random-id-450' is='div' className="flex flex-row w-[200px]">
            <Wrapper canvas style={{ display: "inline-block" , fontSize:'36px', fontWeight:'400', fontFamily:'Open Sans'}} className="  items-center text-[#31A993]" id="random-id-125" is="div">
<Text text="$12" />
</Wrapper>
<Wrapper canvas style={{ display: "inline-block", fontFamily:'Open Sans' }} className="items-center text-[16px] font-normal ml-5 pt-4 text-[#031815]" id="random-id-54" is="div">
<Text text="per month" />
</Wrapper>
        </Wrapper>
        </Wrapper>
        <Wrapper canvas id='random-id-211' is='div' className="w-[100%] flex justify-center" style={{borderRadius:'40px'}}>
            <Button  classn='block' className="flex justify-center items-center w-[260px] h-[60px] flex flex justify-center items-center text-center bg-[#31A993] hover:bg-[#11816C] cursor-pointer py-[15px] text-[#FFFFFF]"
             >
<Wrapper canvas style={{ display: "inline-block", fontSize:'22px', fontWeight:'600', textTransform: 'uppercase',fontFamily:'Open Sans', borderRadius:'40px' }} id="random-id-55" is="div">
<Text text="Get Started" />
</Wrapper>
            </Button>
        </Wrapper>
    </Wrapper>
    <Wrapper canvas id='random-id-451' is='wrapper' className=" h-full flex flex-col p-7 m-4 "  style={{background:'transparent', borderRadius: '20px', flex:'28%', minHeight:'408px', maxWidth:'380px', border: '1px solid #31A99333', boxShadow: '0px 0px 4px 0px #0318101A' }}>
        <Wrapper  canvas id='random-id-452' is='div' className="p-10 flex flex-col">
<Wrapper canvas style={{ display: "inline-block", fontWeight:'700', fontFamily:'Open Sans' }} className="w-full h-full text-[36px] text-[#031815]" id="random-id-56" is="div">
<Text text="Pro" />
</Wrapper>        
<Wrapper canvas style={{ display: "inline-block", fontSize:'16px', fontWeight:'400', fontFamily:'Montserrat' }} className="content my-10 font-normal  text-[#031815]" id="random-id-57" is="div">
<Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
</Wrapper>
        <Wrapper canvas id='random-id-453' is='div' className="flex flex-row w-[200px]">
<Wrapper canvas style={{ display: "inline-block", fontSize:'36px', fontWeight:'400', fontFamily:'Open Sans' }} className=" items-center text-[#31A993]" id="random-id-58" is="div">
<Text text="22$" />
</Wrapper>
<Wrapper canvas style={{ display: "inline-block", fontFamily:'Open Sans' }} className="items-center text-[16px] font-normal ml-5 pt-4 text-[#031815]" id="random-id-59" is="div">
<Text text="per month" />
</Wrapper>
        </Wrapper>
        </Wrapper>
        <Wrapper canvas id='random-id-212' is='div' className="w-[100%] flex justify-center" style={{borderRadius:'40px'}}>
            <Button  classn='block' className="flex justify-center items-center w-[260px] h-[60px] flex flex justify-center items-center text-center bg-[#31A993] hover:bg-[#11816C] py-[15px] text-[#FFFFFF]" 
            >
<Wrapper canvas style={{ display: "inline-block" , fontSize:'22px', fontWeight:'600', textTransform: 'uppercase', fontFamily:'Open Sans', borderRadius:'40px'}} id="random-id-60" is="div">
<Text text="Get Started" />
</Wrapper>
            </Button>
        </Wrapper>
    </Wrapper>
</Wrapper>
</Wrapper>
</Wrapper></Element>
  </Element>,


 <Element canvas is={Parent} id='parentId900' className="w-[100%] h-auto" >
  <Element canvas is={Parent} id='parentId1000' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-454' is='parent' className='w-[100%] h-full'  style={{background:'white'}}>
<Wrapper canvas id='random-id-455' is='container' className="container">
<Wrapper canvas id='random-id-456' is='div' className="flex flex-col text-center pt-10" >
<Wrapper canvas style={{ display: "inline-block", fontFamily:'Rubik' }} className={`text-[46px]  text-[#100103]`} id="random-id-61" is="div">
<Text text="Ready to get started?" />
</Wrapper>
<Wrapper canvas style={{ display: "inline-block", fontFamily:'Rubik' }} className={`text-[46px]  text-[#0078D3]`} id="random-id-62" is="div">
<Text text="Select your plan." />
</Wrapper>
</Wrapper>
<Wrapper canvas id='random-id-457' is='container' className="flex align-content-center  flex justify-center py-10 justify-content-center" style={{flexFlow:'row wrap'}}>

    <Wrapper canvas id='random-id-458' is='wrapper' className=" h-full flex flex-col p-7 m-4 " style={{background:'#FFF', flex:'28%', minHeight:'408px', maxWidth:'380px', borderRadius:'4px',  border:'1px solid #DBD9D9', boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.10)'}}>
        <Wrapper canvas id='random-id-459' is='div' className="p-10 flex flex-col">            
<Wrapper canvas style={{ display: "inline-block", fontWeight:'700', fontFamily:'Rubik' }} className="w-full h-full text-[36px] text-[#100103] " id="random-id-63" is="div">
<Text text="Basic" />
</Wrapper>        
<Wrapper canvas style={{ display: "inline-block",fontSize:'16px', fontWeight:'400', fontFamily:'Rubik' }} className="content  my-10 font-normal text-[#100103]" id="random-id-64" is="div">
<Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
</Wrapper>
        <Wrapper canvas id='random-id-460' is='div' className="flex flex-row w-[200px]  ">
<Wrapper canvas style={{ display: "inline-block", fontSize:'36px', fontWeight:'400' }} className=" font-['Rubik'] items-center text-[#0078D3]" id="random-id-65" is="div">
<Text text="0$" />
</Wrapper>
<Wrapper canvas style={{ display: "inline-block", fontFamily:'Rubik' }} className="items-center text-[16px] font-normal ml-5 pt-4 text-[#100103]" id="random-id-66" is="div">
<Text text="per month" />
</Wrapper>
        </Wrapper>
        </Wrapper>
        <Wrapper canvas id='random-id-213' is='div' className="w-[100%] flex justify-center" style={{borderRadius:'6px'}}>
        <Button  classn='block' className="font-['Rubik'] items-center flex justify-center w-[260px] h-[60px] flex flex justify-center items-center text-center bg-[#0078D3] hover:bg-[#11578B] cursor-pointer text-[#FFFFFF]" 
        >
                
<Wrapper canvas style={{ display: "inline-block" , fontSize:'22px', borderRadius:'6px', fontWeight:'600', textTransform: 'uppercase'}} id="random-id-67" is="div">
<Text text="Get Started" />
</Wrapper>
            </Button>
        </Wrapper>
    </Wrapper>
    <Wrapper canvas id='random-id-461' is='wrapper' className=" h-full flex flex-col p-7 m-4  " style={{background:'#FFF', flex:'28%', minHeight:'408px', maxWidth:'380px', borderRadius:'4px', border:'1px solid #DBD9D9', boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.10)'}}>
        <Wrapper canvas id='random-id-462' is='div' className="p-10 flex flex-col">
        <Wrapper canvas style={{ display: "inline-block", fontWeight:'700',fontFamily:'Rubik'  }} className="w-full h-full  text-[36px] text-[#100103] " id="random-id-126" is="div">
<Text text="Team" />
</Wrapper>        
<Wrapper canvas style={{ display: "inline-block", fontSize:'16px', fontWeight:'400', fontFamily:'Rubik' }} className="content  my-10 font-normal text-[#100103]" id="random-id-68" is="div">
<Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
</Wrapper>
        <Wrapper canvas id='random-id-463' is='div' className="flex flex-row w-[200px]  ">
            <Wrapper canvas style={{ display: "inline-block", fontSize:'36px', fontWeight:'400', fontFamily:'Rubik' }} className="  items-center text-[#0078D3]" id="random-id-127" is="div">
<Text text="$12" />
</Wrapper>
<Wrapper canvas style={{ display: "inline-block", fontFamily:'Rubik' }} className="items-center text-[16px] font-normal ml-5 pt-4 text-[#100103]" id="random-id-69" is="div">
<Text text="per month" />
</Wrapper>
        </Wrapper>
        </Wrapper>
        <Wrapper canvas id='random-id-214' is='div' className="w-[100%] flex justify-center" style={{borderRadius:'6px'}}>
            <Button  classn='block' className="flex justify-center items-center text-[#FFFFFF] w-[260px] h-[60px] flex flex justify-center items-center text-center bg-[#0078D3] hover:bg-[#11578B] cursor-pointer" 
            >
                
<Wrapper canvas style={{ display: "inline-block", fontSize:'22px', fontWeight:'600', textTransform: 'uppercase', fontFamily:'Rubik', borderRadius:'6px' }} id="random-id-70" is="div">
<Text text="Get Started" />
</Wrapper>
            </Button>
        </Wrapper>
    </Wrapper>
    <Wrapper canvas id='random-id-464' is='wrapper' className=" h-full flex flex-col p-7 m-4 " style={{background:'#FFF', flex:'28%', minHeight:'408px', maxWidth:'380px', borderRadius:'4px', border:'1px solid #DBD9D9',boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.10)'}}>
        <Wrapper canvas id='random-id-465' is='div' className="p-10 flex flex-col">
<Wrapper canvas style={{ display: "inline-block", fontWeight:'700', fontFamily:'Rubik' }} className="w-full h-full text-[36px] text-[#100103]" id="random-id-71" is="div">
<Text text="Pro" />
</Wrapper>        
<Wrapper canvas style={{ display: "inline-block", fontSize:'16px', fontWeight:'400', fontFamily:'Rubik' }} className="content  my-10 font-normal  text-[#100103]" id="random-id-72" is="div">
<Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
</Wrapper>
        <Wrapper canvas id='random-id-466' is='div' className="flex flex-row w-[200px]  ">
<Wrapper canvas style={{ display: "inline-block", fontSize:'36px', fontWeight:'400', fontFamily:'Rubik' }} className=" items-center text-[#0078D3]" id="random-id-73" is="div">
<Text text="22$" />
</Wrapper>
<Wrapper canvas style={{ display: "inline-block", fontFamily:'Rubik' }} className="items-center text-[16px] font-normal ml-5 pt-4 text-[#100103]" id="random-id-74" is="div">
<Text text="per month" />
</Wrapper>
        </Wrapper>
        </Wrapper>
        <Wrapper canvas id='random-id-215' is='div' className="w-[100%] flex justify-center" style={{borderRadius:'6px'}}>
            <Button  classn='block' className="flex justify-center items-center w-[260px] h-[60px] flex flex justify-center items-center text-center bg-[#0078D3] hover:bg-[#11578B] cursor-pointer py-[15px] text-[#FFFFFF]"
             >
<Wrapper canvas style={{ display: "inline-block", fontSize:'22px', fontWeight:'600', textTransform: 'uppercase', fontFamily:'Rubik', borderRadius:'6px' }} id="random-id-75" is="div">
<Text text="Get Started" />
</Wrapper>
            </Button>
        </Wrapper>
    </Wrapper>
</Wrapper>
</Wrapper>
</Wrapper></Element>
  </Element>,

 <Element canvas is={Parent} id='parentId1100' className="w-[100%] h-auto" >
  <Element canvas is={Parent} id='parentId1200' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-467' is='parent' className='w-[100%] h-full'  style={{background:'#020212'}}>
<Wrapper canvas id='random-id-468' is='container' className="container">
<Wrapper canvas id='random-id-469' is='div' className="flex flex-col text-center pt-10" >        
<Wrapper canvas style={{ display: "inline-block" }} className={`text-[46px]  text-[#FFF]`} id="random-id-76" is="div">
<Text text="Ready to get started?" />
</Wrapper>
<Wrapper canvas style={{ display: "inline-block", fontFamily:'Voces' }} className={`text-[46px]  text-[#FFF]`} id="random-id-77" is="div">
<Text text="Select your plan." />
</Wrapper>
</Wrapper>
<Wrapper canvas id='random-id-470' is='container' className="flex align-content-center  flex justify-center py-10 justify-content-center" style={{flexFlow:'row wrap'}}>
    <Wrapper canvas id='random-id-471' is='wrapper' className=" h-full flex flex-col p-7 m-4  " style={{background:'#020212',  flex:'28%', minHeight:'408px', maxWidth:'380px', borderRadius:'4px', boxShadow: '0px 0px 10px 0px rgba(255, 255, 255, 0.20)'}}>
        <Wrapper canvas id='random-id-472' is='div' className="p-10 flex flex-col">            
<Wrapper canvas style={{ display: "inline-block", fontWeight:'700', fontFamily:'Voces' }}  className="w-full h-full text-[36px] text-[#FFF] uppercase " id="random-id-78" is="div">
<Text text="Basic" />
</Wrapper>        
<Wrapper canvas style={{ display: "inline-block", fontSize:'16px', fontWeight:'400', fontFamily:'Voces' }} className="content  my-10 font-normal text-[#FFF]" id="random-id-79" is="div">
<Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
</Wrapper>
        <Wrapper canvas id='random-id-473' is='div' className="flex flex-row w-[200px]  ">
<Wrapper canvas style={{ display: "inline-block", fontSize:'36px', fontWeight:'400' }} className=" font-['Voces'] items-center text-[#FFF]" id="random-id-80" is="div">
<Text text="0$" />
</Wrapper>

<Wrapper canvas style={{ display: "inline-block" }} className="items-center text-[16px] font-normal font-['Voces'] ml-5 pt-4 text-[#FFF]" id="random-id-81" is="div">
<Text text="per month" />
</Wrapper>
        </Wrapper>
        </Wrapper>
        <Wrapper canvas id='random-id-216' is='div' className="w-[100%] flex justify-center" style={{borderRadius:'10px', border:'2px solid #FFF'}}>
        <Button  classn='block' className="font-['Voces'] items-center flex justify-center py-[15px] w-[260px] h-[60px] flex justify-center items-center flex bg-[#020212] hover:bg-[white] text-[#FFFFFF] hover:text-[#020212] cursor-pointer" 
        >
                
<Wrapper canvas style={{ display: "inline-block", fontSize:'22px', fontWeight:'600', textTransform: 'uppercase', borderRadius:'10px', border:'2px solid #FFF' }} id="random-id-82" is="div">
<Text text="Get Started" />
</Wrapper>
            </Button>
        </Wrapper>
    </Wrapper>
    <Wrapper canvas id='random-id-474' is='wrapper' className=" h-full flex flex-col p-7 m-4   " style={{background:'#020212',  flex:'28%', minHeight:'408px', maxWidth:'380px', borderRadius:'4px', boxShadow: '0px 0px 10px 0px rgba(255, 255, 255, 0.20)'}}>
        <Wrapper canvas id='random-id-475' is='div' className="p-10 flex flex-col">
        <Wrapper canvas style={{ display: "inline-block", fontWeight:'700',fontFamily:'Voces' }} className="w-full h-full  text-[36px] text-[#FFF] uppercase " id="random-id-128" is="div">
<Text text="Team" />
</Wrapper>        
<Wrapper canvas style={{ display: "inline-block", fontSize:'16px', fontWeight:'400', fontFamily:'Voces' }} className="content  my-10 font-normal text-[#FFF]" id="random-id-83" is="div">
<Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
</Wrapper>
        <Wrapper canvas id='random-id-476' is='div' className="flex flex-row w-[200px]  ">
            <Wrapper canvas style={{ display: "inline-block", fontSize:'36px', fontWeight:'400', fontFamily:'Voces' }} className="items-center text-[#FFF]" id="random-id-128" is="div">
<Text text="$12" />
</Wrapper>
<Wrapper canvas style={{ display: "inline-block", fontFamily:'Voces' }} className="items-center text-[16px] font-normal ml-5 pt-4 text-[#FFF]" id="random-id-84" is="div">
<Text text="per month" />
</Wrapper>
        </Wrapper>
        </Wrapper>
        <Wrapper canvas id='random-id-217' is='div' className="w-[100%] flex justify-center" style={{borderRadius:'10px', border:'2px solid #FFF'}}>
            <Button  classn='block' className="items-center flex justify-center w-[260px] h-[60px] flex justify-center items-center bg-[#020212] hover:bg-[white] text-[#FFFFFF] hover:text-[#020212] cursor-pointer py-[15px]"
             >
<Wrapper canvas style={{ display: "inline-block" , fontSize:'22px', fontWeight:'600', textTransform: 'uppercase',fontFamily:'Voces', borderRadius:'10px', border:'2px solid #FFF'}} id="random-id-85" is="div">
<Text text="Get Started" />
</Wrapper>
            </Button>
        </Wrapper>
    </Wrapper>
    <Wrapper canvas id='random-id-477' is='wrapper' className=" h-full flex flex-col p-7 m-4  " style={{background:'#020212',  flex:'28%', minHeight:'408px', maxWidth:'380px', borderRadius:'4px',boxShadow: '0px 0px 10px 0px rgba(255, 255, 255, 0.20)'}}>
        <Wrapper canvas id='random-id-478' is='div' className="p-10 flex flex-col">
<Wrapper canvas style={{ display: "inline-block", fontWeight:'700', fontFamily:'Voces' }} className="w-full h-full  text-[36px] text-[#FFF] uppercase" id="random-id-86" is="div">
<Text text="Pro" />
</Wrapper>        
<Wrapper canvas style={{ display: "inline-block", fontSize:'16px', fontWeight:'400', fontFamily:'Voces' }} className="content  my-10 font-normal  text-[#FFF]" id="random-id-87" is="div">
<Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
</Wrapper>
        <Wrapper canvas id='random-id-479' is='div' className="flex flex-row w-[200px]  ">
<Wrapper canvas style={{ display: "inline-block", fontSize:'36px', fontWeight:'400', fontFamily:'Voces' }} className=" items-center text-[#FFF]" id="random-id-88" is="div">
<Text text="22$" />
</Wrapper>
<Wrapper canvas style={{ display: "inline-block", fontFamily:'Voces' }} className="items-center text-[16px] font-normal ml-5 pt-4 text-[#FFF]" id="random-id-89" is="div">
<Text text="per month" />
</Wrapper>
        </Wrapper>
        </Wrapper>
        <Wrapper canvas id='random-id-218' is='div' className="w-[100%] flex justify-center" style={{borderRadius:'10px', border:'2px solid #FFF'}}>
            <Button  classn='block' className="flex justify-center items-center w-[260px] h-[60px] flex justify-center items-center flex bg-[#020212] hover:bg-[white] text-[#FFFFFF] hover:text-[#020212] cursor-pointer py-[15px" 
            >
                
<Wrapper canvas style={{ display: "inline-block", fontSize:'22px', fontWeight:'600', textTransform: 'uppercase', fontFamily:'Voces', borderRadius:'10px', border:'2px solid #FFF' }} id="random-id-90" is="div">
<Text text="Get Started" />
</Wrapper>
            </Button>
        </Wrapper>
    </Wrapper>
</Wrapper>
</Wrapper>
</Wrapper></Element>
  </Element>,

 <Element canvas is={Parent} id='parentId1300' className="w-[100%] h-auto" >
  <Element canvas is={Parent} id='parentId1400' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-480' is='parent' className='w-[100%] h-full'  style={{background:'#6750a4'}}>
<Wrapper canvas id='random-id-481' is='container' className="container">
<Wrapper canvas id='random-id-482' is='div' className="flex flex-col text-center pt-10" >
<Wrapper canvas style={{ display: "inline-block", fontFamily:'Roboto' }} className={`text-[46px]  text-[#FFF]`} id="random-id-91" is="div">
<Text text="Ready to get started?" />
</Wrapper>
<Wrapper canvas style={{ display: "inline-block",fontFamily:'Roboto' }} className={`text-[46px]  text-[#D0BCFF]`} id="random-id-92" is="div">
<Text text="Select your plan." />
</Wrapper>
</Wrapper>
<Wrapper canvas id='random-id-483' is='container' className="flex align-content-center  flex justify-center py-10 justify-content-center" style={{flexFlow:'row wrap'}}>

    <Wrapper canvas id='random-id-484' is='wrapper' className=" h-full flex flex-col p-7 m-4 " style={{background:'#FFF', flex:'28%', minHeight:'408px', maxWidth:'380px', borderRadius:'30px'}}>
        <Wrapper canvas id='random-id-485' is='div' className="p-10 flex flex-col">            
<Wrapper canvas style={{ display: "inline-block", fontWeight:'700', fontFamily:'Roboto'  }} className="w-full h-full text-[36px] text-[#6750A4]  " id="random-id-93" is="div">
<Text text="Basic" />
</Wrapper>        
<Wrapper canvas style={{ display: "inline-block", fontSize:'16px', fontWeight:'400' }} className="content  my-10 font-normal text-[#100103]" id="random-id-94" is="div">
<Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
</Wrapper>
        <Wrapper canvas id='random-id-486' is='div' className="flex flex-row w-[200px]  ">
<Wrapper canvas style={{ display: "inline-block", fontSize:'36px', fontWeight:'400' }} className=" font-['Roboto'] items-center text-[#6750A4]" id="random-id-95" is="div">
<Text text="0$" />
</Wrapper>
<Wrapper canvas style={{ display: "inline-block", fontFamily:'Roboto' }} className="items-center text-[16px] font-normal ml-5 pt-4 text-[#100103]" id="random-id-96" is="div">
<Text text="per month" />
</Wrapper>
        </Wrapper>
        </Wrapper>
        <Wrapper canvas id='random-id-219' is='div' className="w-[100%] flex justify-center" style={{borderRadius:'30px'}}>
        <Button  classn='block' className="font-semibold text-[#381E72] w-[260px] h-[60px] flex justify-center items-center text-center bg-[#d0bcff] border-1 hover:border-[#381E72] cursor-pointer py-[15px]" 
        >
            <Wrapper canvas style={{ display: "inline-block" , fontSize:'18px', fontWeight:'600', textTransform: 'uppercase', fontFamily:'Roboto', borderRadius:'30px'}} id="random-id-97" is="div">
            <Text text="Learn more" />
            </Wrapper>
        </Button>
        </Wrapper>
    </Wrapper>
    <Wrapper canvas id='random-id-487' is='wrapper' className=" h-full flex flex-col p-7 m-4  " style={{background:'#FFF', flex:'28%', minHeight:'408px', maxWidth:'380px', borderRadius:'30px'}}>
        <Wrapper canvas id='random-id-488' is='div' className="p-10 flex flex-col">
        <Wrapper canvas style={{ display: "inline-block", fontWeight:'700',fontFamily:'Roboto' }} className="w-full h-full  text-[36px] text-[#6750A4]  " id="random-id-129" is="div">
<Text text="Team" />
</Wrapper>        
<Wrapper canvas style={{ display: "inline-block", fontSize:'16px', fontWeight:'400', fontFamily:'Roboto' }}  className="content  my-10 font-normal text-[#100103]" id="random-id-98" is="div">
<Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
</Wrapper>
        <Wrapper canvas id='random-id-489' is='div' className="flex flex-row w-[200px]  ">
            <Wrapper canvas style={{ display: "inline-block", fontSize:'36px', fontWeight:'400', fontFamily:'Roboto' }} className="  items-center text-[#6750A4]" id="random-id-130" is="div">
<Text text="$12" />
</Wrapper>
<Wrapper canvas style={{ display: "inline-block", fontFamily:'Roboto'}} className="items-center text-[16px] font-normal ml-5 pt-4 text-[#100103]" id="random-id-98" is="div">
<Text text="per month" />
</Wrapper>
        </Wrapper>
        </Wrapper>
        <Wrapper canvas id='random-id-220' is='div' className="w-[100%] flex justify-center" style={{borderRadius:'30px'}}>
            <Button   classn='block' className="font-semibold py-[12px] text-[#381E72]  w-[260px] h-[60px] flex justify-center items-center text-center bg-[#d0bcff] cursor-pointer border-1 hover:border-[#381E72]"
            >
            <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'600', textTransform: 'uppercase',fontFamily:'Roboto', borderRadius:'30px' }} id="random-id-99" is="div">
<Text text="Learn more" />
</Wrapper>
            </Button>
        </Wrapper>
    </Wrapper>
    <Wrapper canvas id='random-id-490' is='wrapper' className=" h-full flex flex-col p-7 m-4 " style={{background:'#FFF', flex:'28%', minHeight:'408px', maxWidth:'380px', borderRadius:'30px'}}>
        <Wrapper canvas id='random-id-491' is='div' className="p-10 flex flex-col">
<Wrapper canvas style={{ display: "inline-block", fontWeight:'700', fontFamily:'Roboto' }}  className="w-full h-full  text-[36px] text-[#6750A4] " id="random-id-100" is="div">
<Text text="Pro" />
</Wrapper>        
<Wrapper canvas style={{ display: "inline-block", fontSize:'16px', fontWeight:'400', fontFamily:'Roboto' }} className="content  my-10 font-normal  text-[#100103]" id="random-id-101" is="div">
<Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
</Wrapper>
        <Wrapper canvas id='random-id-492' is='div' className="flex flex-row w-[200px]  ">
<Wrapper canvas style={{ display: "inline-block" , fontSize:'36px', fontWeight:'400', fontFamily:'Roboto'}} className=" items-center text-[#6750A4]"  id="random-id-102" is="div">
<Text text="22$" />
</Wrapper>
<Wrapper canvas style={{ display: "inline-block", fontFamily:'Roboto'}} className="items-center text-[16px] font-normal ml-5 pt-4 text-[#100103]" id="random-id-103" is="div">
<Text text="per month" />
</Wrapper>
        </Wrapper>
        </Wrapper>
        <Wrapper canvas id='random-id-221' is='div' className="w-[100%] flex justify-center" style={{borderRadius:'30px'}}>
            <Button   classn='block' className="font-semibold py-[12px] text-[#381E72]  w-[260px] h-[60px] flex justify-center items-center text-center bg-[#d0bcff] cursor-pointer border-1 hover:border-[#381E72]"
            >
            <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'600', textTransform: 'uppercase', fontFamily:'Roboto', borderRadius:'30px' }} id="random-id-104" is="div">
<Text text="Learn more" />
</Wrapper>
            </Button>
        </Wrapper>
    </Wrapper>
</Wrapper>
</Wrapper>
</Wrapper></Element>
  </Element>,


 <Element canvas is={Parent} id='parentId1500' className="w-[100%] h-auto" >
  <Element canvas is={Parent} id='parentId1600' className="w-[100%] h-auto" row={true} ><Wrapper  canvas id='random-id-493' is='parent' className='w-[100%] h-full'  style={{background:'#2A2A2A'}}>
<Wrapper  canvas id='random-id-494' is='container' className="container">
<Wrapper  canvas id='random-id-495' is='div' className="flex flex-col text-center pt-10" >        
<Wrapper canvas style={{ display: "inline-block", fontFamily:'Rum Raisin' }} className={`text-[46px]  text-[#FFD600]`} id="random-id-105" is="div">
<Text text="Ready to get started?" />
</Wrapper>
<Wrapper canvas style={{ display: "inline-block", fontFamily:'Rubik' }} className={`text-[46px]  text-[#FFFFFF]`} id="random-id-106" is="div">
<Text text="Select your plan." />
</Wrapper>
</Wrapper>
<Wrapper  canvas id='random-id-496' is='container' className="flex align-content-center  flex justify-center py-10 justify-content-center" style={{flexFlow:'row wrap'}}>

<Wrapper  canvas id='random-id-497' is='wrapper' className=" h-full flex flex-col p-7 m-4  " style={{background:'transparent', flex:'28%', minHeight:'408px', maxWidth:'380px', borderRadius:'10px', border: '1px solid #FFB8001A',  boxShadow: '0px 0px 10px 0px #FFFFFF0F'}}>
        <Wrapper  canvas id='random-id-498' is='div' className="p-10 flex flex-col">
        <Wrapper canvas style={{ display: "inline-block", fontWeight:'700',fontFamily:'Rubik' }} className="w-full h-full text-[36px] text-[#FFD600] " id="random-id-107" is="div">
        <Text text="Team" />
        </Wrapper>        
<Wrapper canvas style={{ display: "inline-block", fontSize:'16px', fontWeight:'400', fontFamily:'Rubik' }} className="content  my-10 font-normal text-[#FFFFFF]"  id="random-id-108" is="div">
<Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
</Wrapper>
        <Wrapper  canvas id='random-id-499' is='div' className="flex flex-row w-[200px]  ">
            <Wrapper canvas style={{ display: "inline-block", fontSize:'36px', fontWeight:'400', fontFamily:'Rubik' }} className="  items-center text-[#FFD600]" id="random-id-131" is="div">
<Text text="$12" />
</Wrapper>
<Wrapper canvas style={{ display: "inline-block", fontFamily:'Rubik' }} className="items-center text-[16px] font-normal ml-5 pt-4 text-[#FFFFFF]" id="random-id-109" is="div">
<Text text="per month" />
</Wrapper>
        </Wrapper>
        </Wrapper>
        <Wrapper canvas id='random-id-222' is='div' className="w-[100%] flex justify-center" style={{borderRadius:'6px'}}>
            <Button  classn='block' className=" justify-center py-[12px] text-[#2A2A2A] w-[260px] h-[60px] flex justify-center text-center bg-[#FFD600] hover:bg-[#FFD600A1] cursor-pointer"
            >
                
<Wrapper canvas style={{fontSize:'22px', fontWeight:'700',fontFamily:'Rubik', borderRadius:'6px',  display: "inline-block" }} id="random-id-110" is="div">
<Text text="Get Started" />
</Wrapper>
            </Button>
        </Wrapper>
    </Wrapper>
    <Wrapper  canvas id='random-id-500' is='wrapper' className=" h-full flex flex-col p-7 m-4  " style={{background:'transparent', flex:'28%', minHeight:'408px', maxWidth:'380px', borderRadius:'10px', border: '1px solid #FFB8001A',  boxShadow: '0px 0px 10px 0px #FFFFFF0F'}}>
        <Wrapper  canvas id='random-id-501' is='div' className="p-10 flex flex-col">
        <Wrapper canvas style={{ display: "inline-block", fontWeight:'700',fontFamily:'Rubik' }} className="w-full h-full text-[36px] text-[#FFD600] " id="random-id-111" is="div">
        <Text text="Team" />
        </Wrapper>        
<Wrapper canvas style={{ display: "inline-block", fontSize:'16px', fontWeight:'400', fontFamily:'Rubik' }} className="content  my-10 font-normal text-[#FFFFFF]" id="random-id-112" is="div">
<Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
</Wrapper>
        <Wrapper  canvas id='random-id-502' is='div' className="flex flex-row w-[200px]  ">
            <h2 className="  items-center text-[#FFD600]" style={{fontSize:'36px', fontWeight:'400', fontFamily:'Rubik'}}>$12</h2>
            <Wrapper canvas style={{ display: "inline-block", fontFamily:'Rubik' }} className="items-center text-[16px] font-normal ml-5 pt-4 text-[#FFFFFF]" id="random-id-113" is="div">
            <Text text="per month" />
            </Wrapper>
        </Wrapper>
        </Wrapper>
        <Wrapper canvas id='random-id-223' is='div' className="w-[100%] flex justify-center" style={{borderRadius:'6px'}}>
            <Button  classn='block' className="justify-center py-[12px] text-[#2A2A2A] w-[260px] h-[60px] flex justify-center text-center bg-[#FFD600] hover:bg-[#FFD600A1] cursor-pointer"
            >
                
<Wrapper canvas style={{fontSize:'22px', fontWeight:'700',fontFamily:'Rubik', borderRadius:'6px',  display: "inline-block" }} id="random-id-114" is="div">
<Text text="Get Started" />
</Wrapper>
            </Button>
        </Wrapper>
    </Wrapper>
    <Wrapper  canvas id='random-id-503' is='wrapper' className=" h-full flex flex-col p-7 m-4 " style={{background:'transparent', flex:'28%', minHeight:'408px', maxWidth:'380px', borderRadius:'10px', border: '1px solid #FFB8001A', boxShadow: '0px 0px 10px 0px #FFFFFF0F'}}>
        <Wrapper  canvas id='random-id-504' is='div' className="p-10 flex flex-col">
<Wrapper canvas style={{ display: "inline-block", fontWeight:'700', fontFamily:'Rubik' }} className="w-full h-full text-[36px] text-[#FFD600]" id="random-id-115" is="div">
<Text text="Pro" />
</Wrapper>        
<Wrapper canvas style={{ display: "inline-block", fontSize:'16px', fontWeight:'400', fontFamily:'Rubik' }} className="content  my-10 font-normal  text-[#FFFFFF]" id="random-id-116" is="div">
<Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
</Wrapper>
        <Wrapper  canvas id='random-id-505' is='div' className="flex flex-row w-[200px]  ">
<Wrapper canvas style={{ display: "inline-block", fontSize:'36px', fontWeight:'400', fontFamily:'Rubik' }} className=" items-center text-[#FFD600]" id="random-id-117" is="div">
<Text text="22$" />
</Wrapper>
<Wrapper canvas style={{ display: "inline-block", fontFamily:'Rubik' }} className="items-center text-[16px] font-normal ml-5 pt-4 text-[#FFFFFF]" id="random-id-118" is="div">
<Text text="per month" />
</Wrapper>
        </Wrapper>
        </Wrapper>
        <Wrapper canvas id='random-id-224' is='div' className="w-[100%] flex justify-center" style={{borderRadius:'6px'}}>
            <Button  classn='block' className="justify-center py-[12px] text-[#2A2A2A] w-[260px] h-[60px] flex justify-center my-6 text-center bg-[#FFD600] hover:bg-[#FFD600A1] cursor-pointer"
            >
                
<Wrapper canvas style={{ display: "inline-block", fontSize:'22px', fontWeight:'700', fontFamily:'Rubik', borderRadius:'6px' }} id="random-id-119" is="div">
<Text text="Get Started" />
</Wrapper>
            </Button>
        </Wrapper>
    </Wrapper>
</Wrapper>
</Wrapper>
</Wrapper></Element>
  </Element>,

 <Element canvas is={Parent} id='parentId1700' className="w-[100%] h-auto" >
  <Element canvas is={Parent} id='parentId1800' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-900' is='parent' className='w-[100%] h-full'   style={{background:'#F7FBFE'}}>
<Wrapper canvas id='random-id-901' is='container' className="container">
<Wrapper canvas id='random-id-902' is='container' className="flex flex-col text-center pt-10 flex flex-col" >            
    <Wrapper canvas style={{ display: "inline-block", fontFamily:'Rubik', fontWeight:'700' }} className={`text-[46px]  text-[#B20312]`} id="random-id-1" is="div">
        <Text text="Ready to get started?" />
    </Wrapper>
    <Wrapper canvas style={{ display: "inline-block", fontFamily:'Rubik', fontWeight:'700' }} className={`text-[#6064D2] text-[46px]`} id="random-id-2" is="div">
        <Text text="Select your plan." />
    </Wrapper>
</Wrapper>
<Wrapper canvas id='random-id-903' is='container' className="flex align-content-center flex justify-center py-10 justify-content-center" style={{flexFlow:'row wrap'}}>
<Wrapper canvas id='random-id-904' is='wrapper' className="h-full flex flex-col p-7 m-4" style={{background:'transparent', border:'1px solid #6064D21A', flex:'28%', maxWidth:'380px',  minHeight:'408px'}}>
        <Wrapper canvas id='random-id-905' is='div' className="p-10 flex flex-col">
            <Wrapper canvas style={{ display: "inline-block", fontWeight:'700', fontFamily:'Rubik' }} className="w-full h-full text-[36px] text-[#B20312]" id="random-id-906" is="div">
                <Text text="Basic" />
            </Wrapper>
            <Wrapper canvas style={{ display: "inline-block", fontSize:'16px', fontFamily:'Rubik' }} className="content font-normal text-[100103] my-10" id="random-id-906" is="div">
                <Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
            </Wrapper>
        <Wrapper canvas id='random-id-407' is='div' className="flex flex-row w-[200px] ">
            <Wrapper canvas style={{ display: "inline-block", fontSize:'36px', fontWeight:'400', fontFamily:'Rubik' }} className=" items-center text-[#6064D2]"  id="random-id-908" is="div">
                <Text text="0$" />
            </Wrapper>
            <Wrapper canvas style={{ display: "inline-block", fontFamily:'Rubik'}} className="items-center text-[16px] font-normal text-[#100103]  ml-5 pt-4" id="random-id-909" is="div">
                <Text text="per month" />
            </Wrapper>
        </Wrapper>
        </Wrapper>
        <Wrapper canvas id='random-id-910' is='div' className="w-[100%] flex justify-center">
            <Button  classn='block' class='w-[320px] h-[50px] flex justify-center items-center text-center py-[15px] my-7 text-[#FFFFFF] bg-[#B20312] hover:bg-[#87000C] cursor-pointer'
            >
                <Wrapper canvas style={{ display: "inline-block", fontSize:'22px', fontWeight:'600', textTransform: 'uppercase', fontFamily:'Rubik' }} id="random-id-911" is="div">
                    <Text text="Get Started" />
                </Wrapper>
            </Button>
        </Wrapper>
    </Wrapper>
    <Wrapper canvas id='random-id-912' is='container' className=" h-full flex flex-col p-7 m-4" style={{background:'transparent',  border:'1px solid #6064D21A', flex:'28%', maxWidth:'380px',  minHeight:'408px'}}>
        <Wrapper canvas id='random-id-913' is='div' className="p-10 flex flex-col">
        <Wrapper canvas id='random-id-914' is='div' className="w-full text-[36px] text-[#B20312]" style={{ fontWeight:'700',fontFamily:'Rubik' }}>
            <Text text='Team'/>
        </Wrapper>
        <Wrapper canvas style={{ display: "inline-block", fontSize:'16px', fontFamily:'Rubik' }} className="content my-10 font-normal text-[#100103]" id="random-id-8" is="div">
            <Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
        </Wrapper>
        <Wrapper canvas id='random-id-915' is='div' className="flex flex-row w-[200px]">
            <Wrapper canvas id='random-id-916' is='div'>
                <Text text='$12' className="items-center text-[#B20312]" style={{fontSize:'36px', fontWeight:'400', fontFamily:'Rubik'}}/>
            </Wrapper>
            <Wrapper canvas style={{ display: "inline-block", fontFamily:'Rubik' }} className="items-center text-[16px] font-normal ml-5 pt-4 text-[#6064D2]" id="random-id-917" is="div">
                <Text text="per month" />
            </Wrapper>
        </Wrapper>
        </Wrapper>
        <Wrapper canvas id='random-id-918' is='div' className="w-[100%] flex justify-center">
            <Button  classn='block' className="my-7 text-[#FFFFFF] w-[320px] h-[50px] flex justify-center items-center text-center py-[15px]  bg-[#B20312] hover:bg-[#87000C] cursor-pointer"
            >
            <Wrapper canvas style={{ display: "inline-block" , fontSize:'22px', fontWeight:'600', textTransform: 'uppercase',fontFamily:'Rubik'}} id="random-id-919" is="div">
                <Text text="Get Started" />
            </Wrapper>
            </Button>
        </Wrapper>
    </Wrapper>
<Wrapper canvas id='random-id-920' is='container' className=" h-full flex flex-col p-7 m-4" style={{background:'transparent', border:'1px solid #6064D21A', flex:'28%', maxWidth:'380px',  minHeight:'408px'}}>
    <Wrapper canvas id='random-id-921' is='wrapper' className="p-10 flex flex-col">
        <Wrapper canvas style={{ display: "inline-block", fontWeight:'700', fontFamily:'Rubik' }} className="w-full h-full text-[36px] text-[#B20312]" id="random-id-921" is="div">
            <Text text="Pro" />
        </Wrapper>
        <Wrapper canvas style={{ display: "inline-block", fontSize:'16px', fontFamily:'Rubik' }} className="content font-normal text-[100103] my-10" id="random-id-922" is="div">
            <Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
        </Wrapper>
        <Wrapper canvas id='random-id-923' is='div' className="flex flex-row w-[200px] ">
            <Wrapper canvas style={{ display: "inline-block", fontSize:'36px', fontWeight:'400', fontFamily:'Rubik' }} className=" items-center text-[#6064D2]" id="random-id-924" is="div">
                <Text text="22$" />
            </Wrapper>
            <Wrapper canvas style={{ display: "inline-block", fontFamily:'Rubik' }} className="items-center text-[16px] font-normal text-[#100103]  ml-5 pt-4" id="random-id-925" is="div">
            <Text text="per month" />
            </Wrapper>
        </Wrapper>
        </Wrapper>
        <Wrapper canvas id='random-id-926' is='div' className="w-[100%] flex justify-center">
            <Button  classn='block' className="my-7 text-[#FFFFFF] w-[320px] h-[50px] flex justify-center items-center text-center py-[15px]  bg-[#B20312] hover:bg-[#87000C] cursor-pointer"
            >
                <Wrapper canvas style={{ display: "inline-block" , fontSize:'22px', fontWeight:'600', textTransform: 'uppercase', fontFamily:'Rubik'}} id="random-id-927" is="div">
                <Text text="Get Started" />
                </Wrapper>
            </Button>
        </Wrapper>
    </Wrapper>
</Wrapper>
</Wrapper>
</Wrapper></Element>
  </Element>,


 <Element canvas is={Parent} id='parentId1900' className="w-[100%] h-auto" >
  <Element canvas is={Parent} id='parentId2000' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-928' is='parent' className='w-[100%] h-full'   style={{background:'#FEFBF7'}}>
<Wrapper canvas id='random-id-929' is='container' className="container">
<Wrapper canvas id='random-id-930' is='container' className="flex flex-col text-center pt-10 flex flex-col" >            
    <Wrapper canvas style={{ display: "inline-block", fontFamily:'Ruluko', fontWeight:'400' }} className={`text-[46px]  text-[#A25738]`} id="random-id-931" is="div">
        <Text text="Ready to get started?" />
    </Wrapper>
    <Wrapper canvas style={{ display: "inline-block", fontFamily:'Sansation', fontWeight:'700' }} className={`text-[#A25738] text-[46px]`} id="random-id-932" is="div">
        <Text text="Select your plan." />
    </Wrapper>
</Wrapper>
<Wrapper canvas id='random-id-933' is='container' className="flex align-content-center flex justify-center py-10 justify-content-center" style={{flexFlow:'row wrap'}}>
<Wrapper canvas id='random-id-934' is='wrapper' className="h-full flex flex-col p-7 m-4" style={{background:'transparent', border:'1px solid #DBD9D9', flex:'28%', maxWidth:'380px',  minHeight:'408px'}}>
        <Wrapper canvas id='random-id-935' is='div' className="p-10 flex flex-col">
            <Wrapper canvas style={{ display: "inline-block", fontWeight:'700', fontFamily:'Rubik' }} className="w-full h-full text-[36px] text-[#A25738]" id="random-id-935" is="div">
                <Text text="Basic" />
            </Wrapper>
            <Wrapper canvas style={{ display: "inline-block", fontSize:'16px', fontFamily:'Sansation' }} className="content font-normal text-[100103] my-10" id="random-id-936" is="div">
                <Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
            </Wrapper>
        <Wrapper canvas id='random-id-937' is='div' className="flex flex-row w-[200px] ">
            <Wrapper canvas style={{ display: "inline-block", fontSize:'36px', fontWeight:'400', fontFamily:'Rubik' }} className=" items-center text-[#A25738]"  id="random-id-938" is="div">
                <Text text="0$" />
            </Wrapper>
            <Wrapper canvas style={{ display: "inline-block", fontFamily:'Rubik'}} className="items-center text-[16px] font-normal text-[#100103]  ml-5 pt-4" id="random-id-939" is="div">
                <Text text="per month" />
            </Wrapper>
        </Wrapper>
        </Wrapper>
        <Wrapper canvas id='random-id-940' is='div' className="w-[100%] flex justify-center">
            <Button  classn='block' class='w-[320px] h-[50px] flex justify-center items-center text-center py-[11px] my-7 text-[#FFFFFF] bg-[#A25738] cursor-pointer'
            >
                <Wrapper canvas style={{ display: "inline-block", fontSize:'22px', fontWeight:'600', textTransform: 'uppercase', fontFamily:'Rubik', borderRadius:'10px' }} id="random-id-941" is="div">
                    <Text text="Get Started" />
                </Wrapper>
            </Button>
        </Wrapper>
    </Wrapper>
    <Wrapper canvas id='random-id-942' is='container' className=" h-full flex flex-col p-7 m-4" style={{background:'transparent',  border:'1px solid #DBD9D9', flex:'28%', maxWidth:'380px',  minHeight:'408px'}}>
        <Wrapper canvas id='random-id-943' is='div' className="p-10 flex flex-col">
        <Wrapper canvas id='random-id-944' is='div' className="w-full text-[36px] text-[#A25738]" style={{ fontWeight:'700',fontFamily:'Rubik' }}>
            <Text text='Team'/>
        </Wrapper>
        <Wrapper canvas style={{ display: "inline-block", fontSize:'16px', fontFamily:'Sansation' }} className="content my-10 font-normal text-[#100103]" id="random-id-945" is="div">
            <Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
        </Wrapper>
        <Wrapper canvas id='random-id-946' is='div' className="flex flex-row w-[200px]">
            <Wrapper canvas id='random-id-947' is='div'>
                <Text text='$12' className="items-center text-[#A25738]" style={{fontSize:'36px', fontWeight:'400', fontFamily:'Rubik'}}/>
            </Wrapper>
            <Wrapper canvas style={{ display: "inline-block", fontFamily:'Rubik' }} className="items-center text-[16px] font-normal ml-5 pt-4 text-[#6064D2]" id="random-id-948" is="div">
                <Text text="per month" />
            </Wrapper>
        </Wrapper>
        </Wrapper>
        <Wrapper canvas id='random-id-949' is='div' className="w-[100%] flex justify-center">
            <Button  classn='block' className="my-7 text-[#FFFFFF] w-[320px] h-[50px] flex justify-center items-center text-center py-[11px]  bg-[#A25738] cursor-pointer"
            >
            <Wrapper canvas style={{ display: "inline-block", fontSize:'22px', fontWeight:'600', textTransform: 'uppercase',fontFamily:'Rubik', borderRadius:'10px' }} id="random-id-950" is="div">
                <Text text="Get Started" />
            </Wrapper>
            </Button>
        </Wrapper>
    </Wrapper>
<Wrapper canvas id='random-id-951' is='container' className=" h-full flex flex-col p-7 m-4" style={{background:'transparent', border:'1px solid #DBD9D9', flex:'28%', maxWidth:'380px',  minHeight:'408px'}}>
    <Wrapper canvas id='random-id-952' is='wrapper' className="p-10 flex flex-col">
        <Wrapper canvas style={{ display: "inline-block", fontWeight:'700', fontFamily:'Rubik' }} className="w-full h-full text-[36px] text-[#A25738]" id="random-id-953" is="div">
            <Text text="Pro" />
        </Wrapper>
        <Wrapper canvas style={{ display: "inline-block", fontSize:'16px', fontFamily:'Rubik' }} className="content font-normal text-[100103] my-10" id="random-id-954" is="div">
            <Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
        </Wrapper>
        <Wrapper canvas id='random-id-955' is='div' className="flex flex-row w-[200px] ">
            <Wrapper canvas style={{ display: "inline-block", fontSize:'36px', fontWeight:'400', fontFamily:'Rubik' }} className=" items-center text-[#A25738]" id="random-id-956" is="div">
                <Text text="22$" />
            </Wrapper>
            <Wrapper canvas style={{ display: "inline-block", fontFamily:'Rubik' }} className="items-center text-[16px] font-normal text-[#100103]  ml-5 pt-4" id="random-id-956" is="div">
            <Text text="per month" />
            </Wrapper>
        </Wrapper>
        </Wrapper>
        <Wrapper canvas id='random-id-957' is='div' className="w-[100%] flex justify-center">
            <Button  classn='block' className="my-7 text-[#FFFFFF] w-[320px] h-[50px] flex justify-center items-center text-center py-[11px]  bg-[#A25738] cursor-pointer"
            >
                <Wrapper canvas style={{ display: "inline-block", fontSize:'22px', fontWeight:'600', textTransform: 'uppercase', fontFamily:'Rubik', borderRadius:'10px'}} id="random-id-958" is="div">
                <Text text="Get Started" />
                </Wrapper>
            </Button>
        </Wrapper>
    </Wrapper>
</Wrapper>
</Wrapper>
</Wrapper></Element>
  </Element>

]


const [sel, setSel] = useState(selected)

function makeOdd(number) {
  setProp((prop) => {
    setSel(100);
  }, 1000);

  setTimeout(() => {
    setProp((prop) => {
      setSel(number)
    }, 1000);
  }, 100);


  
}

useEffect(() => {
if(selected || selected >= 0) {
  makeOdd(selected);
}
},[selected])



  return ( <div style={{ padding: enabled? "10px" :"0px", display:"flex", justifyContent:"center", alignItems:"center", width:'100%', height:'100%'}} ref={connect}>
 {sel == 100 ? (
          <div style={{height:"85vh", display:"flex", justifyContent:"center", alignItems:"center"}}>
          <BounceLoader
                  size={100}
                  color={"white"}
                  loading={true}
                />
          </div>
         ) : (
          styles[sel]
         )} 
</div>
  );
};

Price_1.craft = {
    displayName: "Price 1",
    props: {
       selected: 1, // here is the default value of the selected style
       length:9,
isBlock: true
      },
      rules: {
        canDrag: () => true,
        canDrop: () => true,
      },
      related: {
        toolbar: NewPriceSettings,
      },
  };


