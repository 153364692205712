/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, { useEffect, useRef, useState } from 'react';

import { Element, useEditor, useNode } from '@craftjs/core';
import { NewPriceSettings } from './newPriceSetting';

import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import InstagramIcon from '@mui/icons-material/Instagram';
import FitbitIcon from '@mui/icons-material/Fitbit';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { Text } from '../../basic/Text';
import { Button } from '../../basic/Button';
import { Parent } from '../../../selectors/Parent/index';
import { Wrapper } from '../../../selectors/wrapper/index';
import { BounceLoader } from 'react-spinners';

export const Price_1 = ({ selected }) => {

    const {
        actions: { setProp },
        connectors: {
            connect
        }
    } = useNode();


    const {
        enabled,
    } = useEditor((state, query) => ({
        enabled: state.options.enabled,

    }));

    const styles = [
        <Element canvas is={Parent} id='parentId100' className="w-[100%] h-auto py-10" style={{ background: 'white' }}>
            <Element canvas is={Parent} id='parentId200' className="w-[100%] h-auto flex justify-center items-center" row={true}>
                <Element is={Parent} canvas id='random-id-402' className="max-w-[1300px] ">
                    <Element is={Parent} canvas id='random-id-403' className="w-full flex flex-col text-center pt-10 flex flex-col" >
                        <Element is={Parent} canvas style={{ display: "inline-block", fontFamily: 'Rubik' }} className={`text-[46px]  text-black`} id="random-id-1" >
                            <Text text="Ready to get started?" />
                        </Element>
                        <Element is={Parent} canvas style={{ display: "inline-block", fontFamily: 'Rubik' }} className={`text-[#B20312] text-[46px]  text-[#B20312]`} id="random-id-2" >
                            <Text text="Select your plan." />
                        </Element>
                    </Element>
                    <Element is={Parent} canvas id='random-id-404' className="flex align-content-center flex justify-center py-10 justify-content-center" style={{ flexFlow: 'row wrap' }}>
                        <Element is={Parent} canvas id='random-id-405' className="h-full flex flex-col p-7 m-4" style={{ background: 'transparent', border: '1px solid #DBD9D9', flex: '28%', maxWidth: '380px', minHeight: '408px' }}>
                            <Element is={Parent} canvas id='random-id-406' className="p-10 flex flex-col">
                                <Element is={Parent} canvas style={{ display: "inline-block", fontWeight: '700', fontFamily: 'Rubik' }} className="w-full h-full text-[36px] text-[#100103]" id="random-id-3" >
                                    <Text text="Basic" />
                                </Element>
                                <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '16px', fontFamily: 'Rubik' }} className="content font-normal text-[100103] my-10" id="random-id-4" >
                                    <Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
                                </Element>
                                <Element is={Parent} canvas id='random-id-407' className="flex flex-row w-[200px] ">
                                    <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '36px', fontWeight: '400', fontFamily: 'Rubik' }} className=" items-center text-[#B20312]" id="random-id-5" >
                                        <Text text="0$" />
                                    </Element>
                                    <Element is={Parent} canvas style={{ display: "inline-block", fontFamily: 'Rubik' }} className="items-center text-[16px] font-normal text-[#100103]  ml-5 pt-4" id="random-id-6" >
                                        <Text text="per month" />
                                    </Element>
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-200' className="w-[100%] flex justify-center">
                                <Button classn='block' class='w-[260px] h-[70px] flex justify-center items-center text-center py-[15px] my-7 text-[#FFFFFF] bg-[#B20312] hover:bg-[#87000C] cursor-pointer'
                                >
                                    <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '22px', fontWeight: '600', textTransform: 'uppercase', fontFamily: 'Rubik' }} id="random-id-7" >
                                        <Text text="Get Started" />
                                    </Element>
                                </Button>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-408' className=" h-full flex flex-col p-7 m-4" style={{ background: 'transparent', border: '1px solid #DBD9D9', flex: '28%', maxWidth: '380px', minHeight: '408px' }}>
                            <Element is={Parent} canvas id='random-id-409' className="p-10 flex flex-col">
                                <Element is={Parent} canvas id='random-id-410' className="w-full text-[36px] text-[#100103]" style={{ fontWeight: '700', fontFamily: 'Rubik' }}>
                                    <Text text='Team' />
                                </Element>
                                <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '16px', fontFamily: 'Rubik' }} className="content my-10 font-normal text-[#100103]" id="random-id-8" >
                                    <Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
                                </Element>
                                <Element is={Parent} canvas id='random-id-411' className="flex flex-row w-[200px]">
                                    <Element is={Parent} canvas id='random-id-1000' style={{ fontSize: '36px', fontWeight: '400', fontFamily: 'Rubik', display: 'inline-block' }} >
                                        <Text text='$12' className="items-center text-[#B20312]" />
                                    </Element>
                                    <Element is={Parent} canvas style={{ display: "inline-block", fontFamily: 'Rubik' }} className="items-center text-[16px] font-normal ml-5 pt-4 text-[#100103]" id="random-id-9" >
                                        <Text text="per month" />
                                    </Element>
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-201' className="w-[100%] flex justify-center">
                                <Button classn='block' className="my-7 text-[#FFFFFF] w-[260px] h-[70px] flex justify-center items-center text-center py-[15px]  bg-[#B20312] hover:bg-[#87000C] cursor-pointer"
                                >
                                    <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '22px', fontWeight: '600', textTransform: 'uppercase', fontFamily: 'Rubik' }} id="random-id-10" >
                                        <Text text="Get Started" />
                                    </Element>
                                </Button>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-412' className=" h-full flex flex-col p-7 m-4" style={{ background: 'transparent', border: '1px solid #DBD9D9', flex: '28%', maxWidth: '380px', minHeight: '408px' }}>
                            <Element is={Parent} canvas id='random-id-413' className="p-10 flex flex-col">
                                <Element is={Parent} canvas style={{ display: "inline-block", fontWeight: '700', fontFamily: 'Rubik' }} className="w-full h-full text-[36px] text-[#100103]" id="random-id-11" >
                                    <Text text="Pro" />
                                </Element>
                                <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '16px', fontFamily: 'Rubik' }} className="content font-normal text-[100103] my-10" id="random-id-12" >
                                    <Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
                                </Element>
                                <Element is={Parent} canvas id='random-id-414' className="flex flex-row w-[200px] ">
                                    <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '36px', fontWeight: '400', fontFamily: 'Rubik' }} className=" items-center text-[#B20312]" id="random-id-13" >
                                        <Text text="22$" />
                                    </Element>
                                    <Element is={Parent} canvas style={{ display: "inline-block", fontFamily: 'Rubik' }} className="items-center text-[16px] font-normal text-[#100103]  ml-5 pt-4" id="random-id-14" >
                                        <Text text="per month" />
                                    </Element>
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-203' className="w-[100%] flex justify-center">
                                <Button classn='block' className="my-7 text-[#FFFFFF] w-[260px] h-[70px] flex justify-center items-center text-center py-[15px]  bg-[#B20312] hover:bg-[#87000C] cursor-pointer"
                                >
                                    <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '22px', fontWeight: '600', textTransform: 'uppercase', fontFamily: 'Rubik' }} id="random-id-15" >
                                        <Text text="Get Started" />
                                    </Element>
                                </Button>
                            </Element>
                        </Element>
                    </Element>
                </Element></Element>
        </Element>
        ,

        <Element canvas is={Parent} id='parentId300' className="w-[100%] h-auto py-10" style={{ background: '#121826' }}>
            <Element canvas is={Parent} id='parentId400' className="w-[100%] h-auto flex justify-center items-center" row={true} >
                <Element is={Parent} canvas id='random-id-416' className="max-w-[1300px] ">
                    <Element is={Parent} canvas id='random-id-417' className="w-full flex flex-col text-center pt-10" >
                        <Element is={Parent} canvas style={{ display: "inline-block", fontFamily: 'Rubik' }} className={`text-[46px]  text-[#FFFFFF]`} id="random-id-16" >
                            <Text text="Ready to get started?" />
                        </Element>
                        <Element is={Parent} canvas style={{ display: "inline-block", fontFamily: 'Rubik' }} className={`IncreaseConversion2 text-[46px]  text-[#FFFFFF]`} id="random-id-17" >
                            <Text text="Select your plan." />
                        </Element>
                    </Element>

                    <Element is={Parent} canvas id='random-id-418' className="align-content-center flex justify-center py-10 justify-content-center" style={{ flexFlow: 'row wrap', }}>

                        <Element is={Parent} canvas id='random-id-419' className=" h-full flex flex-col p-7 m-4" style={{ flex: '28%', maxWidth: '380px', minHeight: '408px', background: 'transparent', boxShadow: '0px 0px 4px rgba(255, 255, 255, 0.10)' }}>
                            <Element is={Parent} canvas id='random-id-420' className="p-10 flex flex-col">
                                <Element is={Parent} canvas style={{ display: "inline-block", fontWeight: '700', fontFamily: 'Rubik' }} className="w-full h-full text-[36px] text-[#FFFFFF] " id="random-id-18" >
                                    <Text text="Team" />
                                </Element>
                                <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '16px', fontWeight: '400', fontFamily: 'Rubik' }} className="content my-10 font-normal text-[#FFFFFF]" id="random-id-19" >
                                    <Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
                                </Element>
                                <Element is={Parent} canvas id='random-id-421' className="flex flex-row w-[200px]">
                                    <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '36px', fontWeight: '400', fontFamily: 'Rubik' }} className="items-center text-[#3265e3]" id="random-id-20" >
                                        <Text text="22$" />
                                    </Element>
                                    <Element is={Parent} canvas style={{ display: "inline-block", fontFamily: 'Rubik' }} className="items-center text-[16px] font-normal ml-5 pt-4 text-[#FFFFFF]" id="random-id-21" >
                                        <Text text="per month" />
                                    </Element>
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-204' className="w-[100%] flex justify-center">
                                <Button classn='block' className="my-7 py-[15px] w-[260px] h-[70px] flex justify-center items-center text-center bg-[#3265e3] hover:bg-[#0049FF] cursor-pointer leading-[30.80px] text-[#FFFFFF]"
                                >
                                    <Element is={Parent} canvas style={{ fontSize: '22px', fontWeight: '600', textTransform: 'uppercase', fontFamily: 'Rubik', display: "inline-block" }} id="random-id-22" >
                                        <Text text="Get Started" />
                                    </Element>
                                </Button>
                            </Element>
                        </Element>

                        <Element is={Parent} canvas id='random-id-422' className=" h-full flex flex-col p-7 m-4" style={{ flex: '28%', maxWidth: '380px', minHeight: '408px', background: 'transparent', boxShadow: '0px 0px 4px rgba(255, 255, 255, 0.10)' }}>
                            <Element is={Parent} canvas id='random-id-423' className="p-10 flex flex-col">
                                <Element is={Parent} canvas style={{ display: "inline-block", fontWeight: '700', fontFamily: 'Rubik' }} className="w-full h-full text-[36px] text-[#FFFFFF] " id="random-id-120" >
                                    <Text text="Team" />
                                </Element>
                                <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '16px', fontWeight: '400', fontFamily: 'Rubik' }} className="content my-10 font-normal text-[#FFFFFF]" id="random-id-23" >
                                    <Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
                                </Element>
                                <Element is={Parent} canvas id='random-id-424' className="flex flex-row w-[200px]">
                                    <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '36px', fontWeight: '400', fontFamily: 'Rubik' }} className="items-center text-[#3265e3]" id="random-id-121" >
                                        <Text text="$12" />
                                    </Element>
                                    <Element is={Parent} canvas style={{ display: "inline-block", fontFamily: 'Rubik' }} className="items-center text-[16px] font-normal ml-5 pt-4 text-[#FFFFFF]" id="random-id-24" >
                                        <Text text="per month" />
                                    </Element>
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-205' className="w-[100%] flex justify-center">
                                <Button classn='block' className="my-7 py-[15px] w-[260px] h-[70px] flex justify-center items-center text-center bg-[#3265e3] hover:bg-[#0049FF] cursor-pointer leading-[30.80px] text-[#FFFFFF]"
                                >
                                    <Element is={Parent} canvas style={{ fontSize: '22px', fontWeight: '600', textTransform: 'uppercase', fontFamily: 'Rubik', display: "inline-block" }} id="random-id-25" >
                                        <Text text="Get Started" />
                                    </Element>
                                </Button>
                            </Element>
                        </Element>

                        <Element is={Parent} canvas id='random-id-425' className=" h-full flex flex-col p-7 m-4" style={{ flex: '28%', maxWidth: '380px', minHeight: '408px', background: 'transparent', boxShadow: '0px 0px 4px rgba(255, 255, 255, 0.10)' }}>
                            <Element is={Parent} canvas id='random-id-426' className="p-10 flex flex-col">
                                <Element is={Parent} canvas style={{ display: "inline-block", fontWeight: '700', fontFamily: 'Rubik' }} className="w-full h-full text-[36px] text-[#FFFFFF]" id="random-id-26" >
                                    <Text text="Pro" />
                                </Element>
                                <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '16px', fontWeight: '400', fontFamily: 'Rubik' }} className="content my-10 font-normal text-[#FFFFFF]" id="random-id-27" >
                                    <Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
                                </Element>
                                <Element is={Parent} canvas id='random-id-427' className="flex flex-row w-[200px]">
                                    <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '36px', fontWeight: '400', fontFamily: 'Rubik' }} className=" items-center text-[#3265e3]" id="random-id-28" >
                                        <Text text="22$" />
                                    </Element>
                                    <Element is={Parent} canvas style={{ display: "inline-block", fontFamily: 'Rubik' }} className="items-center text-[16px] font-normal ml-5 pt-4 text-[#FFFFFF]" id="random-id-29" >
                                        <Text text="per month" />
                                    </Element>
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-206' className="w-[100%] flex justify-center">
                                <Button classn='block' className="my-7 py-[15px] w-[260px] h-[70px] flex justify-center items-center text-center bg-[#3265e3] hover:bg-[#0049FF] cursor-pointer leading-[30.80px] text-[#FFFFFF]"
                                >
                                    <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '22px', fontWeight: '600', textTransform: 'uppercase', fontFamily: 'Rubik' }} id="random-id-30" >
                                        <Text text="Get Started" />
                                    </Element>
                                </Button>
                            </Element>
                        </Element>
                    </Element>
                </Element></Element>
        </Element>,

        <Element canvas is={Parent} id='parentId500' className="w-[100%] h-auto py-10" style={{ background: '#213156' }}>
            <Element canvas is={Parent} id='parentId600' className="w-[100%] h-auto flex justify-center items-center" row={true} >
                <Element is={Parent} canvas id='random-id-429' className="max-w-[1300px] ">
                    <Element is={Parent} canvas id='random-id-430' className="w-full flex flex-col text-center pt-10" >
                        <Element is={Parent} canvas style={{ display: "inline-block", fontFamily: 'Yeseva One' }} className={`text-[46px]  leading-[39.20px] text-[#FFFFFF]`} id="random-id-31" >
                            <Text text="Ready to get started?" />
                        </Element>
                        <Element is={Parent} canvas style={{ display: "inline-block", fontFamily: 'Yeseva One' }} className={`text-[46px]  leading-[39.20pxb] text-[#ea6ef4]`} id="random-id-32" >
                            <Text text="Select your plan." />
                        </Element>
                    </Element>
                    <Element is={Parent} canvas id='random-id-431' className="flex align-content-center  flex justify-center py-10 justify-content-center" style={{ flexFlow: 'row wrap' }}>

                        <Element is={Parent} canvas id='random-id-432' className=" h-full flex flex-col p-7 m-4 "
                            style={{ background: 'transparent', flex: '28%', minHeight: '408px', maxWidth: '380px', borderRadius: 20, border: '1px rgba(234, 110, 244, 0.04) solid', boxShadow: '0px 0px 4px rgba(255, 255, 255, 0.10)' }}>
                            <Element is={Parent} canvas id='random-id-433' className="p-10 flex flex-col">
                                <Element is={Parent} canvas style={{ display: "inline-block", fontWeight: '700', fontFamily: 'Open Sans' }} className="w-full h-full text-[36px] text-[#FFFFFF] " id="random-id-33" >
                                    <Text text="Basic" />
                                </Element>
                                <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '16px', fontWeight: '400', fontFamily: 'Open Sans' }} className="content  my-10 font-normal text-[#FFFFFF]" id="random-id-34" >
                                    <Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
                                </Element>
                                <Element is={Parent} canvas id='random-id-434' className="flex flex-row w-[200px]  ">
                                    <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '36px', fontWeight: '400', fontFamily: 'Open Sans' }} className=" items-center text-[#ea6ef4]" id="random-id-35" >
                                        <Text text="0$" />
                                    </Element>
                                    <Element is={Parent} canvas style={{ display: "inline-block", fontFamily: 'Open Sans' }} className="items-center text-[16px] font-normal ml-5 pt-4 text-[#FFFFFF]" id="random-id-36" >
                                        <Text text="per month" />
                                    </Element>
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-207' className="w-[100%] flex justify-center" style={{ borderRadius: '40px' }}>
                                <Button classn='block' className="my-7 py-[15px] w-[260px] h-[70px] flex justify-center items-center text-center bg-[#ea6ef4] hover:bg-[#E111F2] cursor-pointer leading-[30.80px] text-[#FFFFFF]"
                                >
                                    <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '22px', fontWeight: '600', textTransform: 'uppercase', fontFamily: 'Open Sans', borderRadius: '40px' }} id="random-id-37" >
                                        <Text text="Get Started" />
                                    </Element>
                                </Button>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-435' className=" h-full flex flex-col p-7 m-4 "
                            style={{ background: 'transparent', flex: '28%', minHeight: '408px', maxWidth: '380px', borderRadius: 20, border: '1px rgba(234, 110, 244, 0.04) solid', boxShadow: '0px 0px 4px rgba(255, 255, 255, 0.10)' }}>
                            <Element is={Parent} canvas id='random-id-436' className="p-10 flex flex-col">
                                <Element is={Parent} canvas style={{ display: "inline-block", fontWeight: '700', fontFamily: 'Open Sans' }} className="w-full h-full  text-[36px] text-[#FFFFFF] " id="random-id-123" >
                                    <Text text="Team" />
                                </Element>
                                <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '16px', fontWeight: '400', fontFamily: 'Open Sans' }} className="content  my-10 font-normal text-[#FFFFFF]" id="random-id-38" >
                                    <Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
                                </Element>
                                <Element is={Parent} canvas id='random-id-437' className="flex flex-row w-[200px]  ">
                                    <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '36px', fontWeight: '400', fontFamily: 'Open Sans' }} className="  items-center text-[#ea6ef4]" id="random-id-123" >
                                        <Text text="$12" />
                                    </Element>
                                    <Element is={Parent} canvas style={{ display: "inline-block", fontFamily: 'Open Sans' }} className="items-center text-[16px] font-normal ml-5 pt-4 text-[#FFFFFF]" id="random-id-39" >
                                        <Text text="per month" />
                                    </Element>
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-208' className="w-[100%] flex justify-center" style={{ borderRadius: '40px' }}>
                                <Button classn='block' className="my-7 py-[15px] w-[260px] h-[70px] flex justify-center items-center text-center bg-[#ea6ef4] hover:bg-[#E111F2] cursor-pointer leading-[30.80px] text-[#FFFFFF]"
                                >
                                    <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '22px', fontWeight: '600', textTransform: 'uppercase', fontFamily: 'Open Sans', borderRadius: '40px' }} id="random-id-40" >
                                        <Text text="Get Started" />
                                    </Element>
                                </Button>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-438' className=" h-full flex flex-col p-7 m-4 "
                            style={{ background: 'transparent', flex: '28%', minHeight: '408px', maxWidth: '380px', borderRadius: 20, border: '1px rgba(234, 110, 244, 0.04) solid', boxShadow: '0px 0px 4px rgba(255, 255, 255, 0.10)' }}>
                            <Element is={Parent} canvas id='random-id-439' className="p-10 flex flex-col">
                                <Element is={Parent} canvas style={{ display: "inline-block", fontWeight: '700', fontFamily: 'Open Sans' }} className="w-full h-full text-[36px] text-[#FFFFFF]" id="random-id-41" >
                                    <Text text="Pro" />
                                </Element>
                                <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '16px', fontWeight: '400', fontFamily: 'Open Sans' }} className="content  my-10 font-normal  text-[#FFFFFF]" id="random-id-42" >
                                    <Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
                                </Element>
                                <Element is={Parent} canvas id='random-id-440' className="flex flex-row w-[200px]  ">
                                    <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '36px', fontWeight: '400', fontFamily: 'Open Sans' }} className=" items-center text-[#ea6ef4]" id="random-id-43" >
                                        <Text text="22$" />
                                    </Element>
                                    <Element is={Parent} canvas style={{ display: "inline-block", fontFamily: 'Open Sans' }} className="items-center text-[16px] font-normal ml-5 pt-4 text-[#FFFFFF]" id="random-id-44" >
                                        <Text text="per month" />
                                    </Element>
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-209' className="w-[100%] flex justify-center" style={{ borderRadius: '40px' }}>
                                <Button classn='block' className="my-7 py-[15px] w-[260px] h-[70px] flex justify-center items-center text-center bg-[#ea6ef4] hover:bg-[#E111F2] cursor-pointer leading-[30.80px] text-[#FFFFFF]"
                                >
                                    <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '22px', fontWeight: '600', textTransform: 'uppercase', fontFamily: 'Open Sans', borderRadius: '40px' }} id="random-id-45" >
                                        <Text text="Get Started" />
                                    </Element>
                                </Button>
                            </Element>
                        </Element>
                    </Element>
                </Element></Element>
        </Element>,


        <Element canvas is={Parent} id='parentId700' className="w-[100%] h-auto py-10" style={{ background: '#DFFFF9' }}>
            <Element canvas is={Parent} id='parentId800' className="w-[100%] h-auto flex justify-center items-center" row={true} >
                <Element is={Parent} canvas id='random-id-442' className="max-w-[1300px] ">
                    <Element is={Parent} canvas id='random-id-443' className="w-full flex flex-col text-center pt-10" >
                        <Element is={Parent} canvas style={{ display: "inline-block", fontFamily: 'Zing Script Rust SemiBold Demo' }} className={`text-[46px]  leading-[39.20px] text-[#031815]`} id="random-id-46" >
                            <Text text="Ready to get started?" />
                        </Element>
                        <Element is={Parent} canvas style={{ display: "inline-block", fontFamily: 'Zing Script Rust SemiBold Demo' }} className={`text-[46px]  leading-[39.20pxb] text-[#31A993]`} id="random-id-47" >
                            <Text text="Select your plan." />
                        </Element>
                    </Element>
                    <Element is={Parent} canvas id='random-id-444' className="flex align-content-center  flex justify-center py-10 justify-content-center" style={{ flexFlow: 'row wrap' }}>

                        <Element is={Parent} canvas id='random-id-445' className=" h-full flex flex-col p-7 m-4 " style={{ background: 'transparent', borderRadius: '20px', flex: '28%', minHeight: '408px', maxWidth: '380px', border: '1px solid #31A99333', boxShadow: '0px 0px 4px 0px #0318101A' }}>
                            <Element is={Parent} canvas id='random-id-446' className="p-10 flex flex-col">
                                <Element is={Parent} canvas style={{ display: "inline-block", fontWeight: '700', fontFamily: 'Open Sans' }} className="w-full -h-full text-[36px] text-[#031815] " id="random-id-48" >
                                    <Text text="Basic" />
                                </Element>
                                <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '16px', fontWeight: '400', fontFamily: 'Montserrat' }} className="content my-10 font-normal text-[#031815]" id="random-id-49" >
                                    <Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
                                </Element>
                                <Element is={Parent} canvas id='random-id-447' className="flex flex-row w-[200px]">
                                    <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '36px', fontWeight: '400', fontFamily: 'Open Sans' }} className=" items-center text-[#31A993]" id="random-id-50" >
                                        <Text text="0$" />
                                    </Element>
                                    <Element is={Parent} canvas style={{ display: "inline-block", fontFamily: 'Open Sans' }} className="items-center text-[16px] font-normal ml-5 pt-4 text-[#031815]" id="random-id-51" >
                                        <Text text="per month" />
                                    </Element>
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-210' className="w-[100%] flex justify-center" style={{ borderRadius: '40px' }}>
                                <Button classn='block' className="flex justify-center w-[260px] h-[60px] flex flex justify-center items-center text-center bg-[#31A993] hover:bg-[#11816C] cursor-pointer py-[15px] items-center text-[#FFFFFF]"
                                >
                                    <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '22px', fontWeight: '600', textTransform: 'uppercase', fontFamily: 'Open Sans', borderRadius: '40px' }} id="random-id-52" >
                                        <Text text="Get Started" />
                                    </Element>
                                </Button>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-448' className=" h-full flex flex-col p-7 m-4  " style={{ background: 'transparent', borderRadius: '20px', flex: '28%', minHeight: '408px', maxWidth: '380px', border: '1px solid #31A99333', boxShadow: '0px 0px 4px 0px #0318101A' }}>
                            <Element is={Parent} canvas id='random-id-449' className="p-10 flex flex-col">
                                <Element is={Parent} canvas style={{ display: "inline-block", fontWeight: '700', fontFamily: 'Open Sans' }} className="w-full h-full text-[36px] text-[#031815] " id="random-id-124" >
                                    <Text text="Team" />
                                </Element>
                                <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '16px', fontWeight: '400', fontFamily: 'Montserrat' }} className="content my-10 font-normal text-[#031815]" id="random-id-53" >
                                    <Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
                                </Element>
                                <Element is={Parent} canvas id='random-id-450' className="flex flex-row w-[200px]">
                                    <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '36px', fontWeight: '400', fontFamily: 'Open Sans' }} className="  items-center text-[#31A993]" id="random-id-125" >
                                        <Text text="$12" />
                                    </Element>
                                    <Element is={Parent} canvas style={{ display: "inline-block", fontFamily: 'Open Sans' }} className="items-center text-[16px] font-normal ml-5 pt-4 text-[#031815]" id="random-id-54" >
                                        <Text text="per month" />
                                    </Element>
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-211' className="w-[100%] flex justify-center" style={{ borderRadius: '40px' }}>
                                <Button classn='block' className="flex justify-center items-center w-[260px] h-[60px] flex flex justify-center items-center text-center bg-[#31A993] hover:bg-[#11816C] cursor-pointer py-[15px] text-[#FFFFFF]"
                                >
                                    <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '22px', fontWeight: '600', textTransform: 'uppercase', fontFamily: 'Open Sans', borderRadius: '40px' }} id="random-id-55" >
                                        <Text text="Get Started" />
                                    </Element>
                                </Button>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-451' className=" h-full flex flex-col p-7 m-4 " style={{ background: 'transparent', borderRadius: '20px', flex: '28%', minHeight: '408px', maxWidth: '380px', border: '1px solid #31A99333', boxShadow: '0px 0px 4px 0px #0318101A' }}>
                            <Element is={Parent} canvas id='random-id-452' className="p-10 flex flex-col">
                                <Element is={Parent} canvas style={{ display: "inline-block", fontWeight: '700', fontFamily: 'Open Sans' }} className="w-full h-full text-[36px] text-[#031815]" id="random-id-56" >
                                    <Text text="Pro" />
                                </Element>
                                <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '16px', fontWeight: '400', fontFamily: 'Montserrat' }} className="content my-10 font-normal  text-[#031815]" id="random-id-57" >
                                    <Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
                                </Element>
                                <Element is={Parent} canvas id='random-id-453' className="flex flex-row w-[200px]">
                                    <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '36px', fontWeight: '400', fontFamily: 'Open Sans' }} className=" items-center text-[#31A993]" id="random-id-58" >
                                        <Text text="22$" />
                                    </Element>
                                    <Element is={Parent} canvas style={{ display: "inline-block", fontFamily: 'Open Sans' }} className="items-center text-[16px] font-normal ml-5 pt-4 text-[#031815]" id="random-id-59" >
                                        <Text text="per month" />
                                    </Element>
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-212' className="w-[100%] flex justify-center" style={{ borderRadius: '40px' }}>
                                <Button classn='block' className="flex justify-center items-center w-[260px] h-[60px] flex flex justify-center items-center text-center bg-[#31A993] hover:bg-[#11816C] py-[15px] text-[#FFFFFF]"
                                >
                                    <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '22px', fontWeight: '600', textTransform: 'uppercase', fontFamily: 'Open Sans', borderRadius: '40px' }} id="random-id-60" >
                                        <Text text="Get Started" />
                                    </Element>
                                </Button>
                            </Element>
                        </Element>
                    </Element>
                </Element></Element>
        </Element>,


        <Element canvas is={Parent} id='parentId900' className="w-[100%] h-auto py-10" style={{ background: 'white' }}>
            <Element canvas is={Parent} id='parentId1000' className="w-[100%] h-aut flex justify-center items-centero" row={true} >
                <Element is={Parent} canvas id='random-id-455' className="max-w-[1300px] ">
                    <Element is={Parent} canvas id='random-id-456' className="w-full flex flex-col text-center pt-10" >
                        <Element is={Parent} canvas style={{ display: "inline-block", fontFamily: 'Rubik' }} className={`text-[46px]  text-[#100103]`} id="random-id-61" >
                            <Text text="Ready to get started?" />
                        </Element>
                        <Element is={Parent} canvas style={{ display: "inline-block", fontFamily: 'Rubik' }} className={`text-[46px]  text-[#0078D3]`} id="random-id-62" >
                            <Text text="Select your plan." />
                        </Element>
                    </Element>
                    <Element is={Parent} canvas id='random-id-457' className="flex align-content-center  flex justify-center py-10 justify-content-center" style={{ flexFlow: 'row wrap' }}>

                        <Element is={Parent} canvas id='random-id-458' className=" h-full flex flex-col p-7 m-4 " style={{ background: '#FFF', flex: '28%', minHeight: '408px', maxWidth: '380px', borderRadius: '4px', border: '1px solid #DBD9D9', boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.10)' }}>
                            <Element is={Parent} canvas id='random-id-459' className="p-10 flex flex-col">
                                <Element is={Parent} canvas style={{ display: "inline-block", fontWeight: '700', fontFamily: 'Rubik' }} className="w-full h-full text-[36px] text-[#100103] " id="random-id-63" >
                                    <Text text="Basic" />
                                </Element>
                                <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '16px', fontWeight: '400', fontFamily: 'Rubik' }} className="content  my-10 font-normal text-[#100103]" id="random-id-64" >
                                    <Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
                                </Element>
                                <Element is={Parent} canvas id='random-id-460' className="flex flex-row w-[200px]  ">
                                    <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '36px', fontWeight: '400' }} className=" font-['Rubik'] items-center text-[#0078D3]" id="random-id-65" >
                                        <Text text="0$" />
                                    </Element>
                                    <Element is={Parent} canvas style={{ display: "inline-block", fontFamily: 'Rubik' }} className="items-center text-[16px] font-normal ml-5 pt-4 text-[#100103]" id="random-id-66" >
                                        <Text text="per month" />
                                    </Element>
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-213' className="w-[100%] flex justify-center" style={{ borderRadius: '6px' }}>
                                <Button classn='block' className="font-['Rubik'] items-center flex justify-center w-[260px] h-[60px] flex flex justify-center items-center text-center bg-[#0078D3] hover:bg-[#11578B] cursor-pointer text-[#FFFFFF]"
                                >

                                    <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '22px', borderRadius: '6px', fontWeight: '600', textTransform: 'uppercase' }} id="random-id-67" >
                                        <Text text="Get Started" />
                                    </Element>
                                </Button>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-461' className=" h-full flex flex-col p-7 m-4  " style={{ background: '#FFF', flex: '28%', minHeight: '408px', maxWidth: '380px', borderRadius: '4px', border: '1px solid #DBD9D9', boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.10)' }}>
                            <Element is={Parent} canvas id='random-id-462' className="p-10 flex flex-col">
                                <Element is={Parent} canvas style={{ display: "inline-block", fontWeight: '700', fontFamily: 'Rubik' }} className="w-full h-full  text-[36px] text-[#100103] " id="random-id-126" >
                                    <Text text="Team" />
                                </Element>
                                <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '16px', fontWeight: '400', fontFamily: 'Rubik' }} className="content  my-10 font-normal text-[#100103]" id="random-id-68" >
                                    <Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
                                </Element>
                                <Element is={Parent} canvas id='random-id-463' className="flex flex-row w-[200px]  ">
                                    <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '36px', fontWeight: '400', fontFamily: 'Rubik' }} className="  items-center text-[#0078D3]" id="random-id-127" >
                                        <Text text="$12" />
                                    </Element>
                                    <Element is={Parent} canvas style={{ display: "inline-block", fontFamily: 'Rubik' }} className="items-center text-[16px] font-normal ml-5 pt-4 text-[#100103]" id="random-id-69" >
                                        <Text text="per month" />
                                    </Element>
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-214' className="w-[100%] flex justify-center" style={{ borderRadius: '6px' }}>
                                <Button classn='block' className="flex justify-center items-center text-[#FFFFFF] w-[260px] h-[60px] flex flex justify-center items-center text-center bg-[#0078D3] hover:bg-[#11578B] cursor-pointer"
                                >

                                    <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '22px', fontWeight: '600', textTransform: 'uppercase', fontFamily: 'Rubik', borderRadius: '6px' }} id="random-id-70" >
                                        <Text text="Get Started" />
                                    </Element>
                                </Button>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-464' className=" h-full flex flex-col p-7 m-4 " style={{ background: '#FFF', flex: '28%', minHeight: '408px', maxWidth: '380px', borderRadius: '4px', border: '1px solid #DBD9D9', boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.10)' }}>
                            <Element is={Parent} canvas id='random-id-465' className="p-10 flex flex-col">
                                <Element is={Parent} canvas style={{ display: "inline-block", fontWeight: '700', fontFamily: 'Rubik' }} className="w-full h-full text-[36px] text-[#100103]" id="random-id-71" >
                                    <Text text="Pro" />
                                </Element>
                                <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '16px', fontWeight: '400', fontFamily: 'Rubik' }} className="content  my-10 font-normal  text-[#100103]" id="random-id-72" >
                                    <Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
                                </Element>
                                <Element is={Parent} canvas id='random-id-466' className="flex flex-row w-[200px]  ">
                                    <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '36px', fontWeight: '400', fontFamily: 'Rubik' }} className=" items-center text-[#0078D3]" id="random-id-73" >
                                        <Text text="22$" />
                                    </Element>
                                    <Element is={Parent} canvas style={{ display: "inline-block", fontFamily: 'Rubik' }} className="items-center text-[16px] font-normal ml-5 pt-4 text-[#100103]" id="random-id-74" >
                                        <Text text="per month" />
                                    </Element>
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-215' className="w-[100%] flex justify-center" style={{ borderRadius: '6px' }}>
                                <Button classn='block' className="flex justify-center items-center w-[260px] h-[60px] flex flex justify-center items-center text-center bg-[#0078D3] hover:bg-[#11578B] cursor-pointer py-[15px] text-[#FFFFFF]"
                                >
                                    <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '22px', fontWeight: '600', textTransform: 'uppercase', fontFamily: 'Rubik', borderRadius: '6px' }} id="random-id-75" >
                                        <Text text="Get Started" />
                                    </Element>
                                </Button>
                            </Element>
                        </Element>
                    </Element>
                </Element></Element>
        </Element>,

        <Element canvas is={Parent} id='parentId1100' className="w-[100%] h-auto py-10" style={{ background: '#020212' }}>
            <Element canvas is={Parent} id='parentId1200' className="w-[100%] h-aut flex justify-center items-centero" row={true} >
                <Element is={Parent} canvas id='random-id-468' className="max-w-[1300px] ">
                    <Element is={Parent} canvas id='random-id-469' className="w-full flex flex-col text-center pt-10" >
                        <Element is={Parent} canvas style={{ display: "inline-block" }} className={`text-[46px]  text-[#FFF]`} id="random-id-76" >
                            <Text text="Ready to get started?" />
                        </Element>
                        <Element is={Parent} canvas style={{ display: "inline-block", fontFamily: 'Voces' }} className={`text-[46px]  text-[#FFF]`} id="random-id-77" >
                            <Text text="Select your plan." />
                        </Element>
                    </Element>
                    <Element is={Parent} canvas id='random-id-470' className="flex align-content-center  flex justify-center py-10 justify-content-center" style={{ flexFlow: 'row wrap' }}>
                        <Element is={Parent} canvas id='random-id-471' className=" h-full flex flex-col p-7 m-4  " style={{ background: '#020212', flex: '28%', minHeight: '408px', maxWidth: '380px', borderRadius: '4px', boxShadow: '0px 0px 10px 0px rgba(255, 255, 255, 0.20)' }}>
                            <Element is={Parent} canvas id='random-id-472' className="p-10 flex flex-col">
                                <Element is={Parent} canvas style={{ display: "inline-block", fontWeight: '700', fontFamily: 'Voces' }} className="w-full h-full text-[36px] text-[#FFF] uppercase " id="random-id-78" >
                                    <Text text="Basic" />
                                </Element>
                                <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '16px', fontWeight: '400', fontFamily: 'Voces' }} className="content  my-10 font-normal text-[#FFF]" id="random-id-79" >
                                    <Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
                                </Element>
                                <Element is={Parent} canvas id='random-id-473' className="flex flex-row w-[200px]  ">
                                    <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '36px', fontWeight: '400' }} className=" font-['Voces'] items-center text-[#FFF]" id="random-id-80" >
                                        <Text text="0$" />
                                    </Element>

                                    <Element is={Parent} canvas style={{ display: "inline-block" }} className="items-center text-[16px] font-normal font-['Voces'] ml-5 pt-4 text-[#FFF]" id="random-id-81" >
                                        <Text text="per month" />
                                    </Element>
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-216' className="w-[100%] flex justify-center" style={{ borderRadius: '10px', border: '2px solid #FFF' }}>
                                <Button classn='block' className="font-['Voces'] items-center flex justify-center py-[15px] w-[260px] h-[60px] flex justify-center items-center flex bg-[#020212] hover:bg-[white] text-[#FFFFFF] hover:text-[#020212] cursor-pointer"
                                >

                                    <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '22px', fontWeight: '600', textTransform: 'uppercase', borderRadius: '10px', border: '2px solid #FFF' }} id="random-id-82" >
                                        <Text text="Get Started" />
                                    </Element>
                                </Button>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-474' className=" h-full flex flex-col p-7 m-4   " style={{ background: '#020212', flex: '28%', minHeight: '408px', maxWidth: '380px', borderRadius: '4px', boxShadow: '0px 0px 10px 0px rgba(255, 255, 255, 0.20)' }}>
                            <Element is={Parent} canvas id='random-id-475' className="p-10 flex flex-col">
                                <Element is={Parent} canvas style={{ display: "inline-block", fontWeight: '700', fontFamily: 'Voces' }} className="w-full h-full  text-[36px] text-[#FFF] uppercase " id="random-id-128" >
                                    <Text text="Team" />
                                </Element>
                                <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '16px', fontWeight: '400', fontFamily: 'Voces' }} className="content  my-10 font-normal text-[#FFF]" id="random-id-83" >
                                    <Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
                                </Element>
                                <Element is={Parent} canvas id='random-id-476' className="flex flex-row w-[200px]  ">
                                    <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '36px', fontWeight: '400', fontFamily: 'Voces' }} className="items-center text-[#FFF]" id="random-id-128" >
                                        <Text text="$12" />
                                    </Element>
                                    <Element is={Parent} canvas style={{ display: "inline-block", fontFamily: 'Voces' }} className="items-center text-[16px] font-normal ml-5 pt-4 text-[#FFF]" id="random-id-84" >
                                        <Text text="per month" />
                                    </Element>
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-217' className="w-[100%] flex justify-center" style={{ borderRadius: '10px', border: '2px solid #FFF' }}>
                                <Button classn='block' className="items-center flex justify-center w-[260px] h-[60px] flex justify-center items-center bg-[#020212] hover:bg-[white] text-[#FFFFFF] hover:text-[#020212] cursor-pointer py-[15px]"
                                >
                                    <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '22px', fontWeight: '600', textTransform: 'uppercase', fontFamily: 'Voces', borderRadius: '10px', border: '2px solid #FFF' }} id="random-id-85" >
                                        <Text text="Get Started" />
                                    </Element>
                                </Button>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-477' className=" h-full flex flex-col p-7 m-4  " style={{ background: '#020212', flex: '28%', minHeight: '408px', maxWidth: '380px', borderRadius: '4px', boxShadow: '0px 0px 10px 0px rgba(255, 255, 255, 0.20)' }}>
                            <Element is={Parent} canvas id='random-id-478' className="p-10 flex flex-col">
                                <Element is={Parent} canvas style={{ display: "inline-block", fontWeight: '700', fontFamily: 'Voces' }} className="w-full h-full  text-[36px] text-[#FFF] uppercase" id="random-id-86" >
                                    <Text text="Pro" />
                                </Element>
                                <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '16px', fontWeight: '400', fontFamily: 'Voces' }} className="content  my-10 font-normal  text-[#FFF]" id="random-id-87" >
                                    <Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
                                </Element>
                                <Element is={Parent} canvas id='random-id-479' className="flex flex-row w-[200px]  ">
                                    <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '36px', fontWeight: '400', fontFamily: 'Voces' }} className=" items-center text-[#FFF]" id="random-id-88" >
                                        <Text text="22$" />
                                    </Element>
                                    <Element is={Parent} canvas style={{ display: "inline-block", fontFamily: 'Voces' }} className="items-center text-[16px] font-normal ml-5 pt-4 text-[#FFF]" id="random-id-89" >
                                        <Text text="per month" />
                                    </Element>
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-218' className="w-[100%] flex justify-center" style={{ borderRadius: '10px', border: '2px solid #FFF' }}>
                                <Button classn='block' className="flex justify-center items-center w-[260px] h-[60px] flex justify-center items-center flex bg-[#020212] hover:bg-[white] text-[#FFFFFF] hover:text-[#020212] cursor-pointer py-[15px"
                                >

                                    <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '22px', fontWeight: '600', textTransform: 'uppercase', fontFamily: 'Voces', borderRadius: '10px', border: '2px solid #FFF' }} id="random-id-90" >
                                        <Text text="Get Started" />
                                    </Element>
                                </Button>
                            </Element>
                        </Element>
                    </Element>
                </Element></Element>
        </Element>,

        <Element canvas is={Parent} id='parentId1300' className="w-[100%] h-auto py-10" style={{ background: '#6750a4' }}>
            <Element canvas is={Parent} id='parentId1400' className="w-[100%] h-aut flex justify-center items-centero" row={true} >
                <Element is={Parent} canvas id='random-id-481' className="max-w-[1300px] ">
                    <Element is={Parent} canvas id='random-id-482' className="w-full flex flex-col text-center pt-10" >
                        <Element is={Parent} canvas style={{ display: "inline-block", fontFamily: 'Roboto' }} className={`text-[46px]  text-[#FFF]`} id="random-id-91" >
                            <Text text="Ready to get started?" />
                        </Element>
                        <Element is={Parent} canvas style={{ display: "inline-block", fontFamily: 'Roboto' }} className={`text-[46px]  text-[#D0BCFF]`} id="random-id-92" >
                            <Text text="Select your plan." />
                        </Element>
                    </Element>
                    <Element is={Parent} canvas id='random-id-483' className="flex align-content-center  flex justify-center py-10 justify-content-center" style={{ flexFlow: 'row wrap' }}>

                        <Element is={Parent} canvas id='random-id-484' className=" h-full flex flex-col p-7 m-4 " style={{ background: '#FFF', flex: '28%', minHeight: '408px', maxWidth: '380px', borderRadius: '30px' }}>
                            <Element is={Parent} canvas id='random-id-485' className="p-10 flex flex-col">
                                <Element is={Parent} canvas style={{ display: "inline-block", fontWeight: '700', fontFamily: 'Roboto' }} className="w-full h-full text-[36px] text-[#6750A4]  " id="random-id-93" >
                                    <Text text="Basic" />
                                </Element>
                                <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '16px', fontWeight: '400' }} className="content  my-10 font-normal text-[#100103]" id="random-id-94" >
                                    <Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
                                </Element>
                                <Element is={Parent} canvas id='random-id-486' className="flex flex-row w-[200px]  ">
                                    <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '36px', fontWeight: '400' }} className=" font-['Roboto'] items-center text-[#6750A4]" id="random-id-95" >
                                        <Text text="0$" />
                                    </Element>
                                    <Element is={Parent} canvas style={{ display: "inline-block", fontFamily: 'Roboto' }} className="items-center text-[16px] font-normal ml-5 pt-4 text-[#100103]" id="random-id-96" >
                                        <Text text="per month" />
                                    </Element>
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-219' className="w-[100%] flex justify-center" style={{ borderRadius: '30px' }}>
                                <Button classn='block' className="font-semibold text-[#381E72] w-[260px] h-[60px] flex justify-center items-center text-center bg-[#d0bcff] border-1 hover:border-[#381E72] cursor-pointer py-[15px]"
                                >
                                    <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '600', textTransform: 'uppercase', fontFamily: 'Roboto', borderRadius: '30px' }} id="random-id-97" >
                                        <Text text="Learn more" />
                                    </Element>
                                </Button>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-487' className=" h-full flex flex-col p-7 m-4  " style={{ background: '#FFF', flex: '28%', minHeight: '408px', maxWidth: '380px', borderRadius: '30px' }}>
                            <Element is={Parent} canvas id='random-id-488' className="p-10 flex flex-col">
                                <Element is={Parent} canvas style={{ display: "inline-block", fontWeight: '700', fontFamily: 'Roboto' }} className="w-full h-full  text-[36px] text-[#6750A4]  " id="random-id-129" >
                                    <Text text="Team" />
                                </Element>
                                <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '16px', fontWeight: '400', fontFamily: 'Roboto' }} className="content  my-10 font-normal text-[#100103]" id="random-id-98" >
                                    <Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
                                </Element>
                                <Element is={Parent} canvas id='random-id-489' className="flex flex-row w-[200px]  ">
                                    <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '36px', fontWeight: '400', fontFamily: 'Roboto' }} className="  items-center text-[#6750A4]" id="random-id-130" >
                                        <Text text="$12" />
                                    </Element>
                                    <Element is={Parent} canvas style={{ display: "inline-block", fontFamily: 'Roboto' }} className="items-center text-[16px] font-normal ml-5 pt-4 text-[#100103]" id="random-id-98" >
                                        <Text text="per month" />
                                    </Element>
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-220' className="w-[100%] flex justify-center" style={{ borderRadius: '30px' }}>
                                <Button classn='block' className="font-semibold py-[12px] text-[#381E72]  w-[260px] h-[60px] flex justify-center items-center text-center bg-[#d0bcff] cursor-pointer border-1 hover:border-[#381E72]"
                                >
                                    <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '600', textTransform: 'uppercase', fontFamily: 'Roboto', borderRadius: '30px' }} id="random-id-99" >
                                        <Text text="Learn more" />
                                    </Element>
                                </Button>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-490' className=" h-full flex flex-col p-7 m-4 " style={{ background: '#FFF', flex: '28%', minHeight: '408px', maxWidth: '380px', borderRadius: '30px' }}>
                            <Element is={Parent} canvas id='random-id-491' className="p-10 flex flex-col">
                                <Element is={Parent} canvas style={{ display: "inline-block", fontWeight: '700', fontFamily: 'Roboto' }} className="w-full h-full  text-[36px] text-[#6750A4] " id="random-id-100" >
                                    <Text text="Pro" />
                                </Element>
                                <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '16px', fontWeight: '400', fontFamily: 'Roboto' }} className="content  my-10 font-normal  text-[#100103]" id="random-id-101" >
                                    <Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
                                </Element>
                                <Element is={Parent} canvas id='random-id-492' className="flex flex-row w-[200px]  ">
                                    <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '36px', fontWeight: '400', fontFamily: 'Roboto' }} className=" items-center text-[#6750A4]" id="random-id-102" >
                                        <Text text="22$" />
                                    </Element>
                                    <Element is={Parent} canvas style={{ display: "inline-block", fontFamily: 'Roboto' }} className="items-center text-[16px] font-normal ml-5 pt-4 text-[#100103]" id="random-id-103" >
                                        <Text text="per month" />
                                    </Element>
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-221' className="w-[100%] flex justify-center" style={{ borderRadius: '30px' }}>
                                <Button classn='block' className="font-semibold py-[12px] text-[#381E72]  w-[260px] h-[60px] flex justify-center items-center text-center bg-[#d0bcff] cursor-pointer border-1 hover:border-[#381E72]"
                                >
                                    <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '600', textTransform: 'uppercase', fontFamily: 'Roboto', borderRadius: '30px' }} id="random-id-104" >
                                        <Text text="Learn more" />
                                    </Element>
                                </Button>
                            </Element>
                        </Element>
                    </Element>
                </Element></Element>
        </Element>,


        <Element canvas is={Parent} id='parentId1500' className="w-[100%] h-auto py-10" style={{ background: '#2A2A2A' }}>
            <Element canvas is={Parent} id='parentId1600' className="w-[100%] h-aut flex justify-center items-centero" row={true} >
                <Element is={Parent} canvas id='random-id-494' className="max-w-[1300px] ">
                    <Element is={Parent} canvas id='random-id-495' className="w-full flex flex-col text-center pt-10" >
                        <Element is={Parent} canvas style={{ display: "inline-block", fontFamily: 'Rum Raisin' }} className={`text-[46px]  text-[#FFD600]`} id="random-id-105" >
                            <Text text="Ready to get started?" />
                        </Element>
                        <Element is={Parent} canvas style={{ display: "inline-block", fontFamily: 'Rubik' }} className={`text-[46px]  text-[#FFFFFF]`} id="random-id-106" >
                            <Text text="Select your plan." />
                        </Element>
                    </Element>
                    <Element is={Parent} canvas id='random-id-496' className="flex align-content-center  flex justify-center py-10 justify-content-center" style={{ flexFlow: 'row wrap' }}>

                        <Element is={Parent} canvas id='random-id-497' className=" h-full flex flex-col p-7 m-4  " style={{ background: 'transparent', flex: '28%', minHeight: '408px', maxWidth: '380px', borderRadius: '10px', border: '1px solid #FFB8001A', boxShadow: '0px 0px 10px 0px #FFFFFF0F' }}>
                            <Element is={Parent} canvas id='random-id-498' className="p-10 flex flex-col">
                                <Element is={Parent} canvas style={{ display: "inline-block", fontWeight: '700', fontFamily: 'Rubik' }} className="w-full h-full text-[36px] text-[#FFD600] " id="random-id-107" >
                                    <Text text="Team" />
                                </Element>
                                <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '16px', fontWeight: '400', fontFamily: 'Rubik' }} className="content  my-10 font-normal text-[#FFFFFF]" id="random-id-108" >
                                    <Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
                                </Element>
                                <Element is={Parent} canvas id='random-id-499' className="flex flex-row w-[200px]  ">
                                    <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '36px', fontWeight: '400', fontFamily: 'Rubik' }} className="  items-center text-[#FFD600]" id="random-id-131" >
                                        <Text text="$12" />
                                    </Element>
                                    <Element is={Parent} canvas style={{ display: "inline-block", fontFamily: 'Rubik' }} className="items-center text-[16px] font-normal ml-5 pt-4 text-[#FFFFFF]" id="random-id-109" >
                                        <Text text="per month" />
                                    </Element>
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-222' className="w-[100%] flex justify-center" style={{ borderRadius: '6px' }}>
                                <Button classn='block' className=" justify-center py-[12px] text-[#2A2A2A] w-[260px] h-[60px] flex justify-center text-center bg-[#FFD600] hover:bg-[#FFD600A1] cursor-pointer"
                                >

                                    <Element is={Parent} canvas style={{ fontSize: '22px', fontWeight: '700', fontFamily: 'Rubik', borderRadius: '6px', display: "inline-block" }} id="random-id-110" >
                                        <Text text="Get Started" />
                                    </Element>
                                </Button>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-500' className=" h-full flex flex-col p-7 m-4  " style={{ background: 'transparent', flex: '28%', minHeight: '408px', maxWidth: '380px', borderRadius: '10px', border: '1px solid #FFB8001A', boxShadow: '0px 0px 10px 0px #FFFFFF0F' }}>
                            <Element is={Parent} canvas id='random-id-501' className="p-10 flex flex-col">
                                <Element is={Parent} canvas style={{ display: "inline-block", fontWeight: '700', fontFamily: 'Rubik' }} className="w-full h-full text-[36px] text-[#FFD600] " id="random-id-111" >
                                    <Text text="Team" />
                                </Element>
                                <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '16px', fontWeight: '400', fontFamily: 'Rubik' }} className="content  my-10 font-normal text-[#FFFFFF]" id="random-id-112" >
                                    <Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
                                </Element>
                                <Element is={Parent} canvas id='random-id-502' className="flex flex-row w-[200px]  ">
                                    <h2 className="  items-center text-[#FFD600]" style={{ fontSize: '36px', fontWeight: '400', fontFamily: 'Rubik' }}>$12</h2>
                                    <Element is={Parent} canvas style={{ display: "inline-block", fontFamily: 'Rubik' }} className="items-center text-[16px] font-normal ml-5 pt-4 text-[#FFFFFF]" id="random-id-113" >
                                        <Text text="per month" />
                                    </Element>
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-223' className="w-[100%] flex justify-center" style={{ borderRadius: '6px' }}>
                                <Button classn='block' className="justify-center py-[12px] text-[#2A2A2A] w-[260px] h-[60px] flex justify-center text-center bg-[#FFD600] hover:bg-[#FFD600A1] cursor-pointer"
                                >

                                    <Element is={Parent} canvas style={{ fontSize: '22px', fontWeight: '700', fontFamily: 'Rubik', borderRadius: '6px', display: "inline-block" }} id="random-id-114" >
                                        <Text text="Get Started" />
                                    </Element>
                                </Button>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-503' className=" h-full flex flex-col p-7 m-4 " style={{ background: 'transparent', flex: '28%', minHeight: '408px', maxWidth: '380px', borderRadius: '10px', border: '1px solid #FFB8001A', boxShadow: '0px 0px 10px 0px #FFFFFF0F' }}>
                            <Element is={Parent} canvas id='random-id-504' className="p-10 flex flex-col">
                                <Element is={Parent} canvas style={{ display: "inline-block", fontWeight: '700', fontFamily: 'Rubik' }} className="w-full h-full text-[36px] text-[#FFD600]" id="random-id-115" >
                                    <Text text="Pro" />
                                </Element>
                                <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '16px', fontWeight: '400', fontFamily: 'Rubik' }} className="content  my-10 font-normal  text-[#FFFFFF]" id="random-id-116" >
                                    <Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
                                </Element>
                                <Element is={Parent} canvas id='random-id-505' className="flex flex-row w-[200px]  ">
                                    <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '36px', fontWeight: '400', fontFamily: 'Rubik' }} className=" items-center text-[#FFD600]" id="random-id-117" >
                                        <Text text="22$" />
                                    </Element>
                                    <Element is={Parent} canvas style={{ display: "inline-block", fontFamily: 'Rubik' }} className="items-center text-[16px] font-normal ml-5 pt-4 text-[#FFFFFF]" id="random-id-118" >
                                        <Text text="per month" />
                                    </Element>
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-224' className="w-[100%] flex justify-center" style={{ borderRadius: '6px' }}>
                                <Button classn='block' className="justify-center py-[12px] text-[#2A2A2A] w-[260px] h-[60px] flex justify-center my-6 text-center bg-[#FFD600] hover:bg-[#FFD600A1] cursor-pointer"
                                >

                                    <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '22px', fontWeight: '700', fontFamily: 'Rubik', borderRadius: '6px' }} id="random-id-119" >
                                        <Text text="Get Started" />
                                    </Element>
                                </Button>
                            </Element>
                        </Element>
                    </Element>
                </Element></Element>
        </Element>,

        <Element canvas is={Parent} id='parentId1700' className="w-[100%] h-auto py-10" style={{ background: '#F7FBFE' }}>
            <Element canvas is={Parent} id='parentId1800' className="w-[100%] h-aut flex justify-center items-centero" row={true} >
                <Element is={Parent} canvas id='random-id-901' className="max-w-[1300px] ">
                    <Element is={Parent} canvas id='random-id-902' className="w-full flex flex-col text-center pt-10 flex flex-col" >
                        <Element is={Parent} canvas style={{ display: "inline-block", fontFamily: 'Rubik', fontWeight: '700' }} className={`text-[46px]  text-[#B20312]`} id="random-id-1" >
                            <Text text="Ready to get started?" />
                        </Element>
                        <Element is={Parent} canvas style={{ display: "inline-block", fontFamily: 'Rubik', fontWeight: '700' }} className={`text-[#6064D2] text-[46px]`} id="random-id-2" >
                            <Text text="Select your plan." />
                        </Element>
                    </Element>
                    <Element is={Parent} canvas id='random-id-903' className="flex align-content-center flex justify-center py-10 justify-content-center" style={{ flexFlow: 'row wrap' }}>
                        <Element is={Parent} canvas id='random-id-904' className="h-full flex flex-col p-7 m-4" style={{ background: 'transparent', border: '1px solid #6064D21A', flex: '28%', maxWidth: '380px', minHeight: '408px' }}>
                            <Element is={Parent} canvas id='random-id-905' className="p-10 flex flex-col">
                                <Element is={Parent} canvas style={{ display: "inline-block", fontWeight: '700', fontFamily: 'Rubik' }} className="w-full h-full text-[36px] text-[#B20312]" id="random-id-906" >
                                    <Text text="Basic" />
                                </Element>
                                <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '16px', fontFamily: 'Rubik' }} className="content font-normal text-[100103] my-10" id="random-id-906" >
                                    <Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
                                </Element>
                                <Element is={Parent} canvas id='random-id-407' className="flex flex-row w-[200px] ">
                                    <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '36px', fontWeight: '400', fontFamily: 'Rubik' }} className=" items-center text-[#6064D2]" id="random-id-908" >
                                        <Text text="0$" />
                                    </Element>
                                    <Element is={Parent} canvas style={{ display: "inline-block", fontFamily: 'Rubik' }} className="items-center text-[16px] font-normal text-[#100103]  ml-5 pt-4" id="random-id-909" >
                                        <Text text="per month" />
                                    </Element>
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-910' className="w-[100%] flex justify-center">
                                <Button classn='block' class='w-[320px] h-[50px] flex justify-center items-center text-center py-[15px] my-7 text-[#FFFFFF] bg-[#B20312] hover:bg-[#87000C] cursor-pointer'
                                >
                                    <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '22px', fontWeight: '600', textTransform: 'uppercase', fontFamily: 'Rubik' }} id="random-id-911" >
                                        <Text text="Get Started" />
                                    </Element>
                                </Button>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-912' className=" h-full flex flex-col p-7 m-4" style={{ background: 'transparent', border: '1px solid #6064D21A', flex: '28%', maxWidth: '380px', minHeight: '408px' }}>
                            <Element is={Parent} canvas id='random-id-913' className="p-10 flex flex-col">
                                <Element is={Parent} canvas id='random-id-914' className="w-full text-[36px] text-[#B20312]" style={{ fontWeight: '700', fontFamily: 'Rubik' }}>
                                    <Text text='Team' />
                                </Element>
                                <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '16px', fontFamily: 'Rubik' }} className="content my-10 font-normal text-[#100103]" id="random-id-8" >
                                    <Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
                                </Element>
                                <Element is={Parent} canvas id='random-id-915' className="flex flex-row w-[200px]">
                                    <Element is={Parent} canvas id='random-id-916' >
                                        <Text text='$12' className="items-center text-[#B20312]" style={{ fontSize: '36px', fontWeight: '400', fontFamily: 'Rubik' }} />
                                    </Element>
                                    <Element is={Parent} canvas style={{ display: "inline-block", fontFamily: 'Rubik' }} className="items-center text-[16px] font-normal ml-5 pt-4 text-[#6064D2]" id="random-id-917" >
                                        <Text text="per month" />
                                    </Element>
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-918' className="w-[100%] flex justify-center">
                                <Button classn='block' className="my-7 text-[#FFFFFF] w-[320px] h-[50px] flex justify-center items-center text-center py-[15px]  bg-[#B20312] hover:bg-[#87000C] cursor-pointer"
                                >
                                    <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '22px', fontWeight: '600', textTransform: 'uppercase', fontFamily: 'Rubik' }} id="random-id-919" >
                                        <Text text="Get Started" />
                                    </Element>
                                </Button>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-920' className=" h-full flex flex-col p-7 m-4" style={{ background: 'transparent', border: '1px solid #6064D21A', flex: '28%', maxWidth: '380px', minHeight: '408px' }}>
                            <Element is={Parent} canvas id='random-id-921' className="p-10 flex flex-col">
                                <Element is={Parent} canvas style={{ display: "inline-block", fontWeight: '700', fontFamily: 'Rubik' }} className="w-full h-full text-[36px] text-[#B20312]" id="random-id-921" >
                                    <Text text="Pro" />
                                </Element>
                                <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '16px', fontFamily: 'Rubik' }} className="content font-normal text-[100103] my-10" id="random-id-922" >
                                    <Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
                                </Element>
                                <Element is={Parent} canvas id='random-id-923' className="flex flex-row w-[200px] ">
                                    <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '36px', fontWeight: '400', fontFamily: 'Rubik' }} className=" items-center text-[#6064D2]" id="random-id-924" >
                                        <Text text="22$" />
                                    </Element>
                                    <Element is={Parent} canvas style={{ display: "inline-block", fontFamily: 'Rubik' }} className="items-center text-[16px] font-normal text-[#100103]  ml-5 pt-4" id="random-id-925" >
                                        <Text text="per month" />
                                    </Element>
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-926' className="w-[100%] flex justify-center">
                                <Button classn='block' className="my-7 text-[#FFFFFF] w-[320px] h-[50px] flex justify-center items-center text-center py-[15px]  bg-[#B20312] hover:bg-[#87000C] cursor-pointer"
                                >
                                    <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '22px', fontWeight: '600', textTransform: 'uppercase', fontFamily: 'Rubik' }} id="random-id-927" >
                                        <Text text="Get Started" />
                                    </Element>
                                </Button>
                            </Element>
                        </Element>
                    </Element>
                </Element></Element>
        </Element>,


        <Element canvas is={Parent} id='parentId1900' className="w-[100%] h-auto py-10" style={{ background: '#FEFBF7' }}>
            <Element canvas is={Parent} id='parentId2000' className="w-[100%] h-aut flex justify-center items-centero" row={true} >
                <Element is={Parent} canvas id='random-id-929' className="max-w-[1300px] ">
                    <Element is={Parent} canvas id='random-id-930' className="w-full flex flex-col text-center pt-10 flex flex-col" >
                        <Element is={Parent} canvas style={{ display: "inline-block", fontFamily: 'Ruluko', fontWeight: '400' }} className={`text-[46px]  text-[#A25738]`} id="random-id-931" >
                            <Text text="Ready to get started?" />
                        </Element>
                        <Element is={Parent} canvas style={{ display: "inline-block", fontFamily: 'Sansation', fontWeight: '700' }} className={`text-[#A25738] text-[46px]`} id="random-id-932" >
                            <Text text="Select your plan." />
                        </Element>
                    </Element>
                    <Element is={Parent} canvas id='random-id-933' className="flex align-content-center flex justify-center py-10 justify-content-center" style={{ flexFlow: 'row wrap' }}>
                        <Element is={Parent} canvas id='random-id-934' className="h-full flex flex-col p-7 m-4" style={{ background: 'transparent', border: '1px solid #DBD9D9', flex: '28%', maxWidth: '380px', minHeight: '408px' }}>
                            <Element is={Parent} canvas id='random-id-935' className="p-10 flex flex-col">
                                <Element is={Parent} canvas style={{ display: "inline-block", fontWeight: '700', fontFamily: 'Rubik' }} className="w-full h-full text-[36px] text-[#A25738]" id="random-id-935" >
                                    <Text text="Basic" />
                                </Element>
                                <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '16px', fontFamily: 'Sansation' }} className="content font-normal text-[100103] my-10" id="random-id-936" >
                                    <Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
                                </Element>
                                <Element is={Parent} canvas id='random-id-937' className="flex flex-row w-[200px] ">
                                    <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '36px', fontWeight: '400', fontFamily: 'Rubik' }} className=" items-center text-[#A25738]" id="random-id-938" >
                                        <Text text="0$" />
                                    </Element>
                                    <Element is={Parent} canvas style={{ display: "inline-block", fontFamily: 'Rubik' }} className="items-center text-[16px] font-normal text-[#100103]  ml-5 pt-4" id="random-id-939" >
                                        <Text text="per month" />
                                    </Element>
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-940' className="w-[100%] flex justify-center">
                                <Button classn='block' class='w-[320px] h-[50px] flex justify-center items-center text-center py-[11px] my-7 text-[#FFFFFF] bg-[#A25738] cursor-pointer'
                                >
                                    <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '22px', fontWeight: '600', textTransform: 'uppercase', fontFamily: 'Rubik', borderRadius: '10px' }} id="random-id-941" >
                                        <Text text="Get Started" />
                                    </Element>
                                </Button>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-942' className=" h-full flex flex-col p-7 m-4" style={{ background: 'transparent', border: '1px solid #DBD9D9', flex: '28%', maxWidth: '380px', minHeight: '408px' }}>
                            <Element is={Parent} canvas id='random-id-943' className="p-10 flex flex-col">
                                <Element is={Parent} canvas id='random-id-944' className="w-full text-[36px] text-[#A25738]" style={{ fontWeight: '700', fontFamily: 'Rubik' }}>
                                    <Text text='Team' />
                                </Element>
                                <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '16px', fontFamily: 'Sansation' }} className="content my-10 font-normal text-[#100103]" id="random-id-945" >
                                    <Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
                                </Element>
                                <Element is={Parent} canvas id='random-id-946' className="flex flex-row w-[200px]">
                                    <Element is={Parent} canvas id='random-id-947' >
                                        <Text text='$12' className="items-center text-[#A25738]" style={{ fontSize: '36px', fontWeight: '400', fontFamily: 'Rubik' }} />
                                    </Element>
                                    <Element is={Parent} canvas style={{ display: "inline-block", fontFamily: 'Rubik' }} className="items-center text-[16px] font-normal ml-5 pt-4 text-[#6064D2]" id="random-id-948" >
                                        <Text text="per month" />
                                    </Element>
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-949' className="w-[100%] flex justify-center">
                                <Button classn='block' className="my-7 text-[#FFFFFF] w-[320px] h-[50px] flex justify-center items-center text-center py-[11px]  bg-[#A25738] cursor-pointer"
                                >
                                    <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '22px', fontWeight: '600', textTransform: 'uppercase', fontFamily: 'Rubik', borderRadius: '10px' }} id="random-id-950" >
                                        <Text text="Get Started" />
                                    </Element>
                                </Button>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-951' className=" h-full flex flex-col p-7 m-4" style={{ background: 'transparent', border: '1px solid #DBD9D9', flex: '28%', maxWidth: '380px', minHeight: '408px' }}>
                            <Element is={Parent} canvas id='random-id-952' className="p-10 flex flex-col">
                                <Element is={Parent} canvas style={{ display: "inline-block", fontWeight: '700', fontFamily: 'Rubik' }} className="w-full h-full text-[36px] text-[#A25738]" id="random-id-953" >
                                    <Text text="Pro" />
                                </Element>
                                <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '16px', fontFamily: 'Rubik' }} className="content font-normal text-[100103] my-10" id="random-id-954" >
                                    <Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
                                </Element>
                                <Element is={Parent} canvas id='random-id-955' className="flex flex-row w-[200px] ">
                                    <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '36px', fontWeight: '400', fontFamily: 'Rubik' }} className=" items-center text-[#A25738]" id="random-id-956" >
                                        <Text text="22$" />
                                    </Element>
                                    <Element is={Parent} canvas style={{ display: "inline-block", fontFamily: 'Rubik' }} className="items-center text-[16px] font-normal text-[#100103]  ml-5 pt-4" id="random-id-956" >
                                        <Text text="per month" />
                                    </Element>
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-957' className="w-[100%] flex justify-center">
                                <Button classn='block' className="my-7 text-[#FFFFFF] w-[320px] h-[50px] flex justify-center items-center text-center py-[11px]  bg-[#A25738] cursor-pointer"
                                >
                                    <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '22px', fontWeight: '600', textTransform: 'uppercase', fontFamily: 'Rubik', borderRadius: '10px' }} id="random-id-958" >
                                        <Text text="Get Started" />
                                    </Element>
                                </Button>
                            </Element>
                        </Element>
                    </Element>
                </Element>
            </Element>
        </Element>

    ]


    const [sel, setSel] = useState(selected)

    function makeOdd(number) {
        setProp((prop) => {
            setSel(100);
        }, 1000);

        setTimeout(() => {
            setProp((prop) => {
                setSel(number)
            }, 1000);
        }, 100);



    }

    useEffect(() => {
        if (selected || selected >= 0) {
            makeOdd(selected);
        }
    }, [selected])



    return (<div style={{ padding: enabled ? "10px" : "0px", display: "flex", justifyContent: "center", alignItems: "center", width: '100%', height: '100%' }} ref={connect}>
        {sel == 100 ? (
            <div style={{ height: "85vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <BounceLoader
                    size={100}
                    color={"white"}
                    loading={true}
                />
            </div>
        ) : (
            styles[sel]
        )}
    </div>
    );
};

Price_1.craft = {
    displayName: "Price 1",
    props: {
        selected: 1, // here is the default value of the selected style
        length: 9,
        isBlock: true
    },
    rules: {
        canDrag: () => true,
        canDrop: () => true,
    },
    related: {
        toolbar: NewPriceSettings,
    },
};


