
/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/

import React, { useEffect, useState } from 'react';
import { Layers } from '@craftjs/layers';
import { Alert } from '@mui/material';

export const  DraggableComponent = ({isDarkMode}) => {
    const [dragging, setDragging] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 80 }); 
  const [layersHidden, setLayersHidden] = useState(false);
  const [messageHidden, setMessageHidden] = useState(false);

  


  // const handleMouseDown = (e) => {
  //   setDragging(true);
  // };
  

  // const handleMouseUp = (e) => {
  //   setDragging(false);
  // };

  // const handleMouseMove = (e) => {
  //   if (dragging) {
  //     const newY = e.clientY - e.clientY % 20; // Snap to 20px grid
  //     setPosition((prevPosition) => ({
  //       ...prevPosition,
  //       y: newY,
  //     }));
  //   }
  // };

  useEffect(() => {
    if (!layersHidden) {
        setMessageHidden(true);

        const timeoutId = setTimeout(() => {
            setMessageHidden(false);
        }, 4000); 
        
        return () => clearTimeout(timeoutId);
    }
}, [layersHidden, setMessageHidden]);

  return (
    <>  
        <div
          onMouseOver={() => setLayersHidden(true)}
          style={{
            zIndex:9999999,
          }}
          className={`absolute top-0 left-0 h-[100vh] w-[2vmin] ${isDarkMode? 'text-[#FFF]' : 'text-[#161717]'}`}
        >
         
          {
          !layersHidden && messageHidden &&
          <Alert
          sx={{
            zIndex:999,
            width: '40vmin',
            pointerEvents: 'none',
            marginTop: '10.5vmin',
            marginLeft:'-2vmin',
            background: '#e9e9e9',
            borderRadius:'2.5vmin',
            display:'flex',
            justifyContent:'center',
            padding: '2px 0',
          }}
          icon={false}
          severity="success"
        >
          <div style={{ color: '#000', width:'100%', height:'100%', fontSize: '2vmin', textAlign: 'center'}}>
              Moving to the side opens the layers
          </div>
        </Alert>
          }
        </div>
       
        {layersHidden ? (
          <div 
        
          className='pt-[10vmin] bg-[transparent] flex items-center justify-center'
          style={{
          top: 0,
          height:'auto',
          position:'absolute',
          zIndex: 999999,
          left:layersHidden ? '2.2vmin' : '-60vmin',
          transition:'left .5s ease-in-out .2s',
          borderRadius:'2.5vmin',
        }}
          >
            <div className='flex shadow-md bg-[#0866fc99] p-[.25vmin] rounded-[.5vmin] w-[2vmin] align-items-center justify-center cursor-pointer bg-[transparent]' onClick={() => setLayersHidden(false)}>
          <svg
              style={{
                transition: 'transform .3s ease-in',
                transform: layersHidden ? 'rotate(180deg)' : '',
              }}
              aria-hidden="true"
              focusable="false"
              role="img"
              className="octicon octicon-chevron-right"
              viewBox="0 0 12 12"
              width="2.2Vmin"
              height="2.2Vmin"
              fill={ '#fff' }
            >
              <path d="M4.7 10c-.2 0-.4-.1-.5-.2-.3-.3-.3-.8 0-1.1L6.9 6 4.2 3.3c-.3-.3-.3-.8 0-1.1.3-.3.8-.3 1.1 0l3.3 3.2c.3.3.3.8 0 1.1L5.3 9.7c-.2.2-.4.3-.6.3Z"></path>
            </svg>
          </div>
          <div
            style={{
              borderRadius:'2vmin',
              padding:'2vmin',
              height: 'fit-content',
              maxHeight: '90vmin',
              overflow:'scroll',
              background:'#e9e9e9',
              scrollbarWidth:'none',
              zIndex:9999999,
            }}
            className="flex justify-center shadow-xl"
          >
            <Layers expandRootOnLoad={true} />
          
          </div>
          
          </div>
        ) : null}
    </>
  );
}
