import React, { useState } from 'react';
import { useNode, useEditor, Element } from '@craftjs/core';
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { DropdownSettings } from './DropdownSettings';
import { Text } from '../Text';
import { Parent } from '../../Parent';



export const DropDownButtons = ({
        style = {},
        isDropDown,
        fontSize = '16px',
        label = 'Choose From The List',
        width = '20%',
        height,
        className,
        color = '#0867FC',
        background,
        borderRadius,
        borderWidth,
        borderColor,
        fontFamily,
        fontWeight,
        flexDirection = 'row',
        categories = ["Option 1", "Option 2", "Option 3", "Option 4"]
}) => {

        const {
          connectors: { connect },
          actions: {setProp},
        } = useNode();


        const { enabled } = useEditor((state) => ({
          enabled: state.options.enabled,
        }));

        const handleProperty = (value, propertyName, split) => {
          if (value !== null && !split) {
          return { [propertyName]: value?.split(" ").join("") }
          }

          if (value !== null && split === 'splitBg') {
          return { [propertyName]:  value !== null ? value : '' }
          } else {
          return;
          }
      };


      const styleProps = {
      
            ...(color !== null && {
              color: color?.startsWith('#') ? color : 'transparent',
              backgroundImage: !color?.startsWith('#') ? color : undefined,
              WebkitBackgroundClip: !color?.startsWith('#') ? 'text' : undefined,
              backgroundClip: !color?.startsWith('#') ? 'text' : undefined,
            }),

          ...handleProperty(background, 'background', 'splitBg'),
          ...handleProperty(width, 'width'),
          ...handleProperty(height, 'height'),
          ...handleProperty(borderWidth, 'borderWidth'),
          ...handleProperty(fontFamily, 'fontFamily', 'splitBg'),
          ...handleProperty(fontWeight, 'fontWeight', 'splitBg'),

          ...handleProperty(borderColor, 'borderColor'),
          ...handleProperty(borderRadius, 'borderRadius'),
          ...handleProperty(fontSize, 'fontSize'),
         
        };

        // const customLabel = {
        //   labelSize,
        //   labelColor,
        //   labelFont,
        // }

  const randomId = Math.floor(Math.random() * 1000);


        
  return ( 
  <div
  ref={connect}
  className={className}
  style={{padding:'10px 0', alignItems:'center', display:'flex'}}
  >
    <div style={{display:'flex', flexDirection: flexDirection, flexWrap:'wrap', width:'100%'}}>
      <Element style={{width:'fit-content', margin:'auto 8px'}} id={`random-id-${randomId}`} canvas is={Parent}>
      <Text text={label} className='test-[#000] text-[22px]' style={{color:'#FFF', fontSize:'22px'}} />
      </Element>
      <DropDownList
        style={{
          ...styleProps,
          ...style,
          
        }}
        data={categories}
        defaultValue={[categories[0]]}
      />
    </div>
    
    </div>
    );
};

DropDownButtons.craft = {
    displayName: 'Dropdown',
    props: {
      fontSize: '16px',
      labelSize: '20px',
      label: 'Choose From The List',
      color: '#0867FC',
      borderRadius: null,
      borderWidth:null,
      borderColor:null,
      width: '20%',
      height:null,
      fontFamily:null,
      fontWeight:null,
      flexDirection: 'row',
      isDropDown:true,
      categories: ["Option 1", "Option 2", "Option 3", "Option 4"],
    },
    related: {
      toolbar: DropdownSettings,
    },
  };