/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, { useEffect, useRef, useState } from 'react';

import { Element, useEditor, useNode } from '@craftjs/core';
import { NewGallerySettings } from './newGallerySetting';
import './gallery.css'
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import InstagramIcon from '@mui/icons-material/Instagram';
import FitbitIcon from '@mui/icons-material/Fitbit';
import MenuIcon from '@mui/icons-material/Menu'; 
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';


import StarOutlineIcon from '@mui/icons-material/StarOutline';
import '../../../../blocks.css'
import { Text } from '../../basic/Text';
import { ImageC } from '../../basic/Image';
import { Parent } from '../../Parent/index.js';
import { Wrapper } from '../../wrapper/index.js';
import { BounceLoader } from 'react-spinners';


export const Gallery_1 = ({ selected }) => {
    
  const { 
    actions: { setProp },
      connectors: {
        connect
      } 
  } = useNode();

  
  const {
    enabled,
  } = useEditor((state, query) => ({
    enabled: state.options.enabled,
 
  }));


  
    const styles = [
        <Element canvas is={Parent} id='parentId100' className="w-[100%] h-auto" >
  <Element canvas is={Parent} id='parentId200' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-400' is='parent' className="w-[100%] h-auto" style={{background:'white'}} >
        <Wrapper canvas id='random-id-401' is='container' className="w-[93%] h-[100%] flex flex-col mx-auto py-[30px]">
                
        <Wrapper canvas style={{ display: "inline-block", fontWeight:'700', fontFamily:'Rubik' }} className={`mx-auto mb-4  text-[46px] text-[#100103]`} id="random-id-1" is="div">
        <Text text="Our Projects." />
        </Wrapper>
            <Wrapper canvas id='random-id-403' is='wrapper' className="wrapper-images">
                <Wrapper canvas id='random-id-404' is='div' className={`column-one`}>
                    <Wrapper canvas className='img-2' style={{display:'inline-block' }} id='random-id-490' is='div'>
                    <ImageC className='img-img' src="https://i.ibb.co/YdFb2Hc/gallery1.jpg"/>
                    </Wrapper>
                    <Wrapper canvas className='img-1' style={{display:'inline-block', }} id='random-id-491' is='div'>
                    <ImageC className='img-img' src="https://i.ibb.co/DzPmHNF/gallery2.jpg"/>
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-407' is='div' className={`column-two`}>
                <Wrapper canvas className='img-1' style={{display:'inline-block'}} id='random-id-408' is='div'>
                    <ImageC className='img-img' src="https://i.ibb.co/QdfkhvG/gallery3.jpg"/>
                </Wrapper>
                <Wrapper canvas className='img-2' style={{display:'inline-block', }} id='random-id-492' is='div'>
                    <ImageC className='img-img' src="https://i.ibb.co/t2nXd64/gallery4.jpg"/>
                </Wrapper>
                </Wrapper>
            </Wrapper>
        </Wrapper> 
        </Wrapper></Element>
  </Element>,

        <Element canvas is={Parent} id='parentId300' className="w-[100%] h-auto" >
  <Element canvas is={Parent} id='parentId400' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-405' is='parent' className="w-[100%] h-auto py-[20px]" style={{background:'#121826'}}  >
            <Wrapper canvas id='random-id-406' is='container' className="container">
                <Wrapper canvas style={{ display: "inline-block", fontStyle:'normal', fontWeight:'700', fontFamily:'Rubik'}} className={`mx-auto mb-4  text-[46px] IncreaseConversion2`} id="random-id-2" is="div">
                <Text text="Our Projects" />
                </Wrapper>
                <Wrapper canvas id='random-id-409' is='wrapper' className="wrapper-images">
                    <Wrapper canvas id='random-id-493' is='div' className={`column-one`}>
                        <Wrapper canvas className='img-2' style={{display:'inline-block', }} id='random-id-494' is='div'>
                        <ImageC className='img-img' src="https://i.ibb.co/YdFb2Hc/gallery1.jpg"/>
                        </Wrapper>
                        <Wrapper canvas className='img-1' style={{display:'inline-block', }} id='random-id-412' is='div'>
                        <ImageC className='img-img' src="https://i.ibb.co/DzPmHNF/gallery2.jpg"/>
                        </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-413' is='div' className={`column-two`}>
                    <Wrapper canvas className='img-1' style={{display:'inline-block', }} id='random-id-414' is='div'>
                        <ImageC className='img-img' src="https://i.ibb.co/QdfkhvG/gallery3.jpg"/>
                    </Wrapper>
                    <Wrapper canvas className='img-2' style={{display:'inline-block', }} id='random-id-495' is='div'>
                        <ImageC className='img-img' src="https://i.ibb.co/t2nXd64/gallery4.jpg"/>
                    </Wrapper>
                    </Wrapper>
                </Wrapper>
            </Wrapper>
        </Wrapper></Element>
  </Element>,

        <Element canvas is={Parent} id='parentId500' className="w-[100%] h-auto" >
  <Element canvas is={Parent} id='parentId600' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-410' is='parent' className="w-[100%] h-auto" style={{background:'#213156'}}  >
        <Wrapper canvas id='random-id-411' is='container' className="w-[93%] h-[100%] flex flex-col mx-auto py-[30px]">        
        <Wrapper canvas style={{ display: "inline-block", fontStyle:'normal', fontWeight:'700', fontFamily:'Times New Roman' }} className={`mx-auto mb-4  text-[46px] text-[#EA6EF4]`} id="random-id-3" is="div">
        <Text text="Our Projects" />
        </Wrapper>
        <Wrapper canvas id='random-id-496' is='wrapper' className="wrapper-images">
                <Wrapper canvas id='random-id-417' is='div' className={`column-one`}>
                    <Wrapper canvas className='img-2' style={{display:'inline-block', borderRadius:'30px 0px' }} id='random-id-418' is='div'>
                    <ImageC className='img-img' style={{borderRadius:'30px 0px'}} src="https://i.ibb.co/YdFb2Hc/gallery1.jpg"/>
                    </Wrapper>
                    <Wrapper canvas className='img-1' style={{display:'inline-block', borderRadius:'0px 30px' }} id='random-id-419' is='div'>
                    <ImageC className='img-img' style={{borderRadius:'0px 30px'}} src="https://i.ibb.co/DzPmHNF/gallery2.jpg"/>
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-497' is='div' className={`column-two`}>
                <Wrapper canvas className='img-1' style={{display:'inline-block', borderRadius:'0px 30px' }} id='random-id-498' is='div'>
                    <ImageC className='img-img' style={{borderRadius:'0px 30px'}} src="https://i.ibb.co/QdfkhvG/gallery3.jpg"/>
                </Wrapper>
                <Wrapper canvas className='img-2' style={{display:'inline-block', borderRadius:'30px 0px' }} id='random-id-499' is='div'>
                    <ImageC className='img-img' style={{borderRadius:'30px 0px'}} src="https://i.ibb.co/t2nXd64/gallery4.jpg"/>
                </Wrapper>
                </Wrapper>
            </Wrapper>
        </Wrapper>
        </Wrapper></Element>
  </Element>,

        <Element canvas is={Parent} id='parentId700' className="w-[100%] h-auto" >
  <Element canvas is={Parent} id='parentId800' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-415' is='parent' className="w-[100%] h-auto" style={{background:'#DFFFF9'}}  >
        <Wrapper canvas id='random-id-416' is='container' className="w-[93%] h-[100%] flex flex-col mx-auto py-[30px]">
        <Wrapper canvas style={{ display: "inline-block", fontWeight:'700', fontFamily:'Zing Script Rust SemiBold Demo', fontStyle:'italic' }} className={`mx-auto mb-4  text-[46px] text-[#31A993]`} id="random-id-4" is="div">
        <Text text="Our Projects" />
        </Wrapper>
        <Wrapper canvas id='random-id-423' is='wrapper' className="wrapper-images">
                <Wrapper canvas id='random-id-424' is='div' className={`column-one`}>
                    <Wrapper canvas className='img-2' style={{display:'inline-block', borderRadius:'30px' }} id='random-id-500' is='div'>
                    <ImageC className='img-img' style={{borderRadius:'30px'}} src="https://i.ibb.co/YdFb2Hc/gallery1.jpg"/>
                    </Wrapper>
                    <Wrapper canvas className='img-1' style={{display:'inline-block', borderRadius:'30px' }} id='random-id-501' is='div'>
                    <ImageC className='img-img' style={{borderRadius:'30px'}} src="https://i.ibb.co/DzPmHNF/gallery2.jpg"/>
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-427' is='div' className={`column-two`}>
                <Wrapper canvas className='img-1' style={{display:'inline-block', borderRadius:'30px' }} id='random-id-428' is='div'>
                    <ImageC className='img-img' style={{borderRadius:'30px'}} src="https://i.ibb.co/QdfkhvG/gallery3.jpg"/>
                </Wrapper>
                <Wrapper canvas className='img-2' style={{display:'inline-block', borderRadius:'30px' }} id='random-id-429' is='div'>
                    <ImageC className='img-img' style={{borderRadius:'30px'}} src="https://i.ibb.co/t2nXd64/gallery4.jpg"/>
                </Wrapper>
                </Wrapper>
            </Wrapper>
        </Wrapper>
        </Wrapper></Element>
  </Element>,


        <Element canvas is={Parent} id='parentId900' className="w-[100%] h-auto" >
  <Element canvas is={Parent} id='parentId1000' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-420' is='parent' className="w-[100%] h-auto" style={{background:'#FFF'}} >
        <Wrapper canvas id='random-id-421' is='container' className="w-[93%] h-[100%] flex flex-col mx-auto py-[30px]">        
        <Wrapper canvas style={{ display: "inline-block", fontWeight:'700', fontFamily:'Inter'  }} className={`mx-auto mb-4  text-[46px] text-[#0078D3]`} id="random-id-5" is="div">
        <Text text="Our Projects." />
        </Wrapper>
        <Wrapper canvas id='random-id-502' is='wrapper' className="wrapper-images">
                <Wrapper canvas id='random-id-503' is='div' className={`column-one`}>
                    <Wrapper canvas className='img-2' style={{display:'inline-block', borderRadius:'6px' }} id='random-id-432' is='div'>
                    <ImageC className='img-img' style={{borderRadius:'6px'}} src="https://i.ibb.co/YdFb2Hc/gallery1.jpg"/>
                    </Wrapper>
                    <Wrapper canvas className='img-1' style={{display:'inline-block', borderRadius:'6px' }} id='random-id-433' is='div'>
                    <ImageC className='img-img' style={{borderRadius:'6px'}} src="https://i.ibb.co/DzPmHNF/gallery2.jpg"/>
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-434' is='div' className={`column-two`}>
                <Wrapper canvas className='img-1' style={{display:'inline-block', borderRadius:'6px' }} id='random-id-505' is='div'>
                    <ImageC className='img-img' style={{borderRadius:'6px'}} src="https://i.ibb.co/QdfkhvG/gallery3.jpg"/>
                </Wrapper>
                <Wrapper canvas className='img-2' style={{display:'inline-block', borderRadius:'6px' }} id='random-id-507' is='div'>
                    <ImageC className='img-img' style={{borderRadius:'6px'}} src="https://i.ibb.co/t2nXd64/gallery4.jpg"/>
                </Wrapper>
                </Wrapper>
            </Wrapper>
        </Wrapper>
        </Wrapper></Element>
  </Element>,


        <Element canvas is={Parent} id='parentId1100' className="w-[100%] h-auto" >
  <Element canvas is={Parent} id='parentId1200' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-425' is='parent' className="w-[100%] h-auto" style={{background:'#FFF'}} >
        <Wrapper canvas id='random-id-426' is='containerdiv' className="w-[93%] h-[100%] flex flex-col mx-auto py-[30px]">        
        <Wrapper canvas style={{ display: "inline-block", fontWeight:'400', fontFamily:'Vocas' }} className={`mx-auto mb-4  text-[46px] text-[#020212] uppercase`} id="random-id-6" is="div">
        <Text text="Our Projects." />
        </Wrapper>
        <Wrapper canvas id='random-id-508' is='wrapper' className="wrapper-images">
                <Wrapper canvas id='random-id-509' is='div' className={`column-one`}>
                    <Wrapper canvas className='img-2' style={{display:'inline-block', }} id='random-id-510' is='div'>
                    <ImageC className='img-img' src="https://i.ibb.co/YdFb2Hc/gallery1.jpg"/>
                    </Wrapper>
                    <Wrapper canvas className='img-1' style={{display:'inline-block', }} id='random-id-511' is='div'>
                    <ImageC className='img-img' src="https://i.ibb.co/DzPmHNF/gallery2.jpg"/>
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-512' is='div' className={`column-two`}>
                <Wrapper canvas className='img-1' style={{display:'inline-block', }} id='random-id-513' is='div'>
                    <ImageC className='img-img' src="https://i.ibb.co/QdfkhvG/gallery3.jpg"/>
                </Wrapper>
                <Wrapper canvas className='img-2' style={{display:'inline-block', }} id='random-id-443' is='div'>
                    <ImageC className='img-img' src="https://i.ibb.co/t2nXd64/gallery4.jpg"/>
                </Wrapper>
                </Wrapper>
            </Wrapper>
        </Wrapper>
        </Wrapper></Element>
  </Element>,


        <Element canvas is={Parent} id='parentId1300' className="w-[100%] h-auto" >
  <Element canvas is={Parent} id='parentId1400' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-430' is='parent' className="w-[100%] h-auto" style={{background:'#FBFAFF'}}  >
        <Wrapper canvas id='random-id-431' is='container' className="w-[93%] h-[100%] flex flex-col mx-auto py-[30px]">
        <Wrapper canvas style={{ display: "inline-block", fontWeight:'700', fontFamily:'Roboto'  }} className={`mx-auto mb-4  text-[46px] text-[#6750A4]`} id="random-id-7" is="div">
        <Text text="Our Projects." />
        </Wrapper>
        <Wrapper canvas id='random-id-444' is='wrapper' className="wrapper-images">
                <Wrapper canvas id='random-id-445' is='div' className={`column-one`}>
                    <Wrapper canvas className='img-2' style={{display:'inline-block', borderRadius:'30px' }} id='random-id-446' is='div'>
                    <ImageC className='img-img' style={{borderRadius:'30px'}} src="https://i.ibb.co/YdFb2Hc/gallery1.jpg"/>
                    </Wrapper>
                    <Wrapper canvas className='img-1' style={{display:'inline-block', borderRadius:'30px' }} id='random-id-447' is='div'>
                    <ImageC className='img-img' style={{borderRadius:'30px'}} src="https://i.ibb.co/DzPmHNF/gallery2.jpg"/>
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-448' is='div' className={`column-two`}>
                <Wrapper canvas className='img-1' style={{display:'inline-block', borderRadius:'30px' }} id='random-id-449' is='div'>
                    <ImageC className='img-img' style={{borderRadius:'30px'}} src="https://i.ibb.co/QdfkhvG/gallery3.jpg"/>
                </Wrapper>
                <Wrapper canvas className='img-2' style={{display:'inline-block', borderRadius:'30px' }} id='random-id-450' is='div'>
                    <ImageC className='img-img' style={{borderRadius:'30px'}} src="https://i.ibb.co/t2nXd64/gallery4.jpg"/>
                </Wrapper>
                </Wrapper>
            </Wrapper>
        </Wrapper>
        </Wrapper></Element>
  </Element>,


        <Element canvas is={Parent} id='parentId1500' className="w-[100%] h-auto" >
  <Element canvas is={Parent} id='parentId1600' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-435' is='parent' className="w-[100%] h-auto" style={{background:'#2A2A2A'}}  >
        <Wrapper canvas id='random-id-436' is='container' className="w-[93%] h-[100%] flex flex-col mx-auto py-[30px]">        
        <Wrapper canvas style={{ display: "inline-block", fontWeight:'400', fontFamily:'Rum Raisin' }} className={`mx-auto mb-4  text-[46px] text-[#FFD600]`} id="random-id-8" is="div">
        <Text text="Our Projects." />
        </Wrapper>
        <Wrapper canvas id='random-id-451' is='wrapper' className="wrapper-images">
                <Wrapper canvas id='random-id-452' is='div' className={`column-one`}>
                    <Wrapper canvas className='img-2' style={{display:'inline-block', borderRadius:'10px' }} id='random-id-453' is='div'>
                    <ImageC className='img-img' style={{borderRadius:'10px'}} src="https://i.ibb.co/YdFb2Hc/gallery1.jpg"/>
                    </Wrapper>
                    <Wrapper canvas className='img-1' style={{display:'inline-block', borderRadius:'10px' }} id='random-id-454' is='div'>
                    <ImageC className='img-img' style={{borderRadius:'10px'}} src="https://i.ibb.co/DzPmHNF/gallery2.jpg"/>
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-455' is='div' className={`column-two`}>
                <Wrapper canvas className='img-1' style={{display:'inline-block', borderRadius:'10px' }} id='random-id-456' is='div'>
                    <ImageC className='img-img' style={{borderRadius:'10px'}} src="https://i.ibb.co/QdfkhvG/gallery3.jpg"/>
                </Wrapper>
                <Wrapper canvas className='img-2' style={{display:'inline-block', borderRadius:'10px' }} id='random-id-457' is='div'>
                    <ImageC className='img-img' style={{borderRadius:'10px'}} src="https://i.ibb.co/t2nXd64/gallery4.jpg"/>
                </Wrapper>
                </Wrapper>
            </Wrapper>
        </Wrapper>
        </Wrapper></Element>
  </Element>,

        <Element canvas is={Parent} id='parentId1700' className="w-[100%] h-auto" >
  <Element canvas is={Parent} id='parentId1800' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-437' is='parent' className="w-[100%] h-auto" style={{background:'#F7FBFE'}} >
        <Wrapper canvas id='random-id-438' is='container' className="w-[93%] h-[100%] flex flex-col mx-auto py-[30px]">
                
        <Wrapper canvas style={{ display: "inline-block", fontWeight:'700', fontFamily:'Rubik' }} className={`mx-auto mb-4  text-[46px] text-[#6064D2]`} id="random-id-439" is="div">
        <Text text="Our Projects." />
        </Wrapper>
            <Wrapper canvas id='random-id-458' is='wrapper' className="wrapper-images">
                <Wrapper canvas id='random-id-459' is='div' className={`column-one`}>
                    <Wrapper canvas className='img-2' style={{display:'inline-block', borderRadius:'10px'}} id='random-id-460' is='div'>
                    <ImageC className='img-img' style={{borderRadius:'10px'}} src="https://i.ibb.co/YdFb2Hc/gallery1.jpg"/>
                    </Wrapper>
                    <Wrapper canvas className='img-1' style={{display:'inline-block', borderRadius:'10px'}} id='random-id-461' is='div'>
                    <ImageC className='img-img' style={{borderRadius:'10px'}} src="https://i.ibb.co/DzPmHNF/gallery2.jpg"/>
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-462' is='div' className={`column-two`}>
                <Wrapper canvas className='img-1' style={{display:'inline-block', borderRadius:'10px'}} id='random-id-463' is='div'>
                    <ImageC className='img-img' style={{borderRadius:'10px'}} src="https://i.ibb.co/QdfkhvG/gallery3.jpg"/>
                </Wrapper>
                <Wrapper canvas className='img-2' style={{display:'inline-block', borderRadius:'10px'}} id='random-id-464' is='div'>
                    <ImageC className='img-img' style={{borderRadius:'10px'}} src="https://i.ibb.co/t2nXd64/gallery4.jpg"/>
                </Wrapper>
                </Wrapper>
            </Wrapper>
        </Wrapper> 
        </Wrapper></Element>
  </Element>,
        
        <Element canvas is={Parent} id='parentId1900' className="w-[100%] h-auto" >
  <Element canvas is={Parent} id='parentI2000' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-440' is='parent' className="w-[100%] h-auto" style={{background:'#FEFBF7'}} >
        <Wrapper canvas id='random-id-441' is='container' className="w-[93%] h-[100%] flex flex-col mx-auto py-[30px]">
                
        <Wrapper canvas style={{ display: "inline-block", fontWeight:'700', fontFamily:'Ruluko' }} className={`mx-auto mb-4  text-[46px] text-[#A25738]`} id="random-id-442" is="div">
        <Text text="Our Projects." />
        </Wrapper>
            <Wrapper canvas id='random-id-465' is='wrapper' className="wrapper-images">
                <Wrapper canvas id='random-id-466' is='div' className={`column-one`}>
                    <Wrapper canvas className='img-2' style={{display:'inline-block', borderRadius:'0px 30px 0px 0px'}} id='random-id-467' is='div'>
                    <ImageC className='img-img' style={{borderRadius:'0px 30px 0px 0px'}} src="https://i.ibb.co/YdFb2Hc/gallery1.jpg"/>
                    </Wrapper>
                    <Wrapper canvas className='img-1' style={{display:'inline-block', borderRadius:'30px 0px 0px 0px'}} id='random-id-468' is='div'>
                    <ImageC className='img-img' style={{borderRadius:'30px 0px 0px 0px'}} src="https://i.ibb.co/DzPmHNF/gallery2.jpg"/>
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-469' is='div' className={`column-two`}>
                <Wrapper canvas className='img-1' style={{display:'inline-block', borderRadius:'0px 0px 0px 30px'}} id='random-id-470' is='div'>
                    <ImageC className='img-img' style={{borderRadius:'0px 0px 0px 30px'}} src="https://i.ibb.co/QdfkhvG/gallery3.jpg"/>
                </Wrapper>
                <Wrapper canvas className='img-2' style={{display:'inline-block', borderRadius:'0px 0px 30px 0px'}} id='random-id-471' is='div'>
                    <ImageC className='img-img' style={{borderRadius:'0px 0px 30px 0px'}} src="https://i.ibb.co/t2nXd64/gallery4.jpg"/>
                </Wrapper>
                </Wrapper>
            </Wrapper>
        </Wrapper> 
        </Wrapper></Element>
  </Element>,


]

const [sel, setSel] = useState(selected)

function makeOdd(number) {
  setProp((prop) => {
    setSel(100);
  }, 1000);

  setTimeout(() => {
    setProp((prop) => {
      setSel(number)
    }, 1000);
  }, 100);


  
}

useEffect(() => {
if(selected || selected >= 0) {
  makeOdd(selected);
}
},[selected])

  return ( <div style={{ padding:enabled? "10px" : "0px", display:"flex", justifyContent:"center", alignItems:"center", width:'100%', height:'100%'}} ref={connect}>
   {sel == 100 ? (
          <div style={{height:"85vh", display:"flex", justifyContent:"center", alignItems:"center"}}>
          <BounceLoader
                  size={100}
                  color={"white"}
                  loading={true}
                />
          </div>
         ) : (
          styles[sel]
         )} 
</div>
  );
};

Gallery_1.craft = {
    displayName: "Gallery 1",
    props: {
       selected: 1, // here is the default value of the selected style
       length:9,
isBlock: true
      },
      rules: {
        canDrag: () => true,
        canDrop: () => true,
      },
      related: {
        toolbar: NewGallerySettings,
      },
  };


