/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import { Element, useEditor, useNode } from '@craftjs/core';
import React, { useState } from 'react';
import { PopoverSettings } from './PopoverSettings';
import { Text } from '../Text';
import { Popover } from '@mui/material';

export const Popovere = (props) => {
const [anchorEl, setAnchorEl] = useState(null);
const [id] = useState('popover-id'); // Unique ID for the popover
const [open, setOpen] = useState(true);


  const {
    connectors: { connect },
  } = useNode((node) => ({
    selected: node.events.selected,
  }));

  const { enabled } = useEditor((state, query) => ({
    enabled: state.options.enabled,

  }));

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
      setOpen(true);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
      setOpen(false);
    };
  
    const {
        background,
        buttonStyle,
        text,
        margin,
        width,
        height,
        href,
        classn,
        color,
        children,
        style,
        ...otherProps 
    } = props

    const backgroundStyle = {
        background: 'transparent',
        color: 'black',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: '#3b97e360',
        margin: '0',
        width: 'auto',
        height: 'auto',
    }
  
    return (
      <div>
        <div
        val={href} _id="sidebarItemx"
          href={href && enabled == null ? `/${href}` : null}
          ref={connect}
          className={`${classn} ${props.class}`}
          {...otherProps}
          style={{
            ...style,
            background:
            props.buttonStyle === 'outline' ? "transparent" : 
            props.background ? `rgba(${Object.values(props.background)})` : "",
            color: props.buttonStyle === 'outline' ? color ? color : "black" : color ? `rgba(${Object.values(color)})` : "",
            borderWidth: "2px",
            borderStyle:enabled  ?"solid" : "solid",
           borderRadius:enabled ? '8px' : '',
            borderColor:
              enabled ? "#3b97e360" : props.buttonStyle === 'outline'
                ? `rgba(${Object.values(props.background)})`
                : 'transparent',
            margin: margin ? `${margin[0]}px ${margin[1]}px ${margin[2]}px ${margin[3]}px` : 0.,
            width: width? `${width}px` : '',
            height: height ? `${height}px` : '',
          }}
          onClick={handleClick}
        >
          Open Popover
        </div>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          style={{
            ...backgroundStyle,
          }}
        >
          <Text text='The content of the Popover' />
        </Popover>
      </div>
    )}

//     <div>
//     <Element canvas id='random-id-1' is='container'>
//         <Element canvas id='random-id-2' style={{width:'100%'}} is='div'>
//             <Button href={'/'} onClick={() => setIsOpen(!isOpen)} classn='block' class='w-[250px] border-1 border-[#989191] bg-[#989191]'>
//                 <Element canvas id='random-id-3' style={{width:'auto', color:'#FFF', margin:'5px 11px'}} is='div'>
//                     <Text text='Popover'/>
//                 </Element>
//             </Button>
//         </Element>
//     </Element>

//     {/* popover */}
//     <UncontrolledPopover
//     placement="bottom"
//     trigger="legacy"
//     toggle={isOpen}
//     >
//     <PopoverHeader>
//       Legacy Trigger
//     </PopoverHeader>
//     <PopoverBody>
//       Legacy is a reactstrap special trigger value (outside of bootstrap‘s spec/standard). Before reactstrap correctly supported click and focus, it had a hybrid which was very useful and has been brought back as trigger=“legacy“. One advantage of the legacy trigger is that it allows the popover text to be selected while also closing when clicking outside the triggering element and popover itself.
//     </PopoverBody>
//   </UncontrolledPopover>
//     </div>

Popovere.craft = {
  displayName: 'Popover',
  props: {
    background: null,
    color: null,
    buttonStyle: 'full',
    text: 'Popover',
    margin: null,
    width: null,
    height: null,
  },
  related: {
    toolbar: PopoverSettings,
  },
};
