import { kcContextMocks, kcContextCommonMock } from "./kcContextMocks";
import { deepAssign } from "keycloakify/tools/deepAssign";
import { isStorybook } from "keycloakify/lib/isStorybook";
import { getKcContextFromWindow } from "./getKcContextFromWindow";

export function createGetKcContext(params) {
    const { mockData, mockProperties } = params || {};

    function getKcContext(params) {
        const { mockPageId, storyPartialKcContext } = params || {};

        const realKcContext = getKcContextFromWindow();

        if (mockPageId !== undefined && realKcContext === undefined) {
            warn_that_mock_is_enbaled: {
                if (isStorybook) {
                    break warn_that_mock_is_enbaled;
                }

                console.log(`%cKeycloakify: ${mockPageId} set to ${mockPageId}.`, "background: red; color: yellow; font-size: medium");
            }

            const kcContextDefaultMock = kcContextMocks.find(({ pageId }) => pageId === mockPageId);

            const partialKcContextCustomMock = (() => {
                const out = {};

                const mockDataPick = mockData?.find(({ pageId }) => pageId === mockPageId);

                if (mockDataPick !== undefined) {
                    deepAssign({
                        "target": out,
                        "source": mockDataPick
                    });
                }

                if (storyPartialKcContext !== undefined) {
                    deepAssign({
                        "target": out,
                        "source": storyPartialKcContext
                    });
                }

                return Object.keys(out).length === 0 ? undefined : out;
            })();

            if (kcContextDefaultMock === undefined && partialKcContextCustomMock === undefined) {
                console.warn(
                    [
                        `WARNING: You declared the non build in page ${mockPageId} but you didn't `,
                        `provide mock data needed to debug the page outside of Keycloak as you are trying to do now.`,
                        `Please check the documentation of the getKcContext function`
                    ].join("\n")
                );
            }

            const kcContext = {};

            deepAssign({
                "target": kcContext,
                "source": kcContextDefaultMock !== undefined ? kcContextDefaultMock : { "pageId": mockPageId, ...kcContextCommonMock }
            });

            if (partialKcContextCustomMock !== undefined) {
                deepAssign({
                    "target": kcContext,
                    "source": partialKcContextCustomMock
                });

                if (
                    partialKcContextCustomMock.pageId === "register-user-profile.ftl" ||
                    partialKcContextCustomMock.pageId === "update-user-profile.ftl" ||
                    partialKcContextCustomMock.pageId === "idp-review-user-profile.ftl"
                ) {
                 

                    const { attributes } = kcContextDefaultMock.profile;

                    kcContext.profile.attributes = [];
                    kcContext.profile.attributesByName = {};

                    const partialAttributes = [
                        ...(partialKcContextCustomMock.profile?.attributes || [])
                    ].filter((x) => x);

                    attributes.forEach(attribute => {
                        const partialAttribute = partialAttributes.find(({ name }) => name === attribute.name);

                        const augmentedAttribute = {};

                        deepAssign({
                            "target": augmentedAttribute,
                            "source": attribute
                        });

                        if (partialAttribute !== undefined) {
                            partialAttributes.splice(partialAttributes.indexOf(partialAttribute), 1);

                            deepAssign({
                                "target": augmentedAttribute,
                                "source": partialAttribute
                            });
                        }

                        kcContext.profile.attributes.push(augmentedAttribute);
                        kcContext.profile.attributesByName[augmentedAttribute.name] = augmentedAttribute;
                    });

                    partialAttributes
                        .map(partialAttribute => ({ "validators": {}, ...partialAttribute }))
                        .forEach(partialAttribute => {
                            const { name } = partialAttribute;

                            kcContext.profile.attributes.push(partialAttribute);
                            kcContext.profile.attributesByName[name] = partialAttribute;
                        });
                }
            }

            if (mockProperties !== undefined) {
                deepAssign({
                    "target": kcContext.properties,
                    "source": mockProperties
                });
            }

            return { kcContext };
        }

        if (realKcContext === undefined) {
            return { "kcContext": undefined };
        }

        if (realKcContext.themeType !== "login") {
            return { "kcContext": undefined };
        }

        return { "kcContext": realKcContext };
    }

    return { getKcContext };
}
