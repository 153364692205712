/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, { useState } from 'react';
import SelectionBox from '../../../editor/Viewport/Pop-up/PopUp';
import { TabOption } from '../../../editor/Viewport/Sidebar/TabOption';
import { ToolbarSection, ToolbarItem } from '../../../editor';
import { category } from '../Image/ImageSettings';
import { useNode } from '@craftjs/core';
import { useDarkMode } from '../../../../DarkModeContext';
import Toggle from '../../../editor/Toolbar/Toggle';

export const SvgIconSettings = () => {


  const {
    node,
    actions: { setProp }
  } = useNode((node) => ({
    props: node.data.props,
    node: node,
  }));

  const [img, setImg] = useState(false)


  const toggleIsImg = () => {
    setProp((prop) => {
      prop.isImg = !prop.isImg;
    })
    setImg((prevImg) => !prevImg);
  };

  const [iconsSquare, setIconsSquare] = useState(false)
  const [selectedIcon, setSelectedIcon] = useState({});

  const handleIconImageSelect = (selectedUrl) => {

    if (Array.isArray(selectedUrl) && selectedUrl.length) {
      setSelectedIcon(selectedUrl[0]);
    } else {
      setSelectedIcon(selectedUrl)
    }

    setIconsSquare(false);
  };

  const [activeTab, setActiveTab] = useState("Style Manager");

  return (
    <div className='flex flex-col component-settings' style={{ height:'100%'}}>
      <ToolbarItem
            full={true}
             type={null}
          />
      <div className='w-[100%]'>
        <TabOption activeTab={activeTab} setActiveTab={setActiveTab} />
      </div>
    <div style={{overflowY:'scroll', scrollbarWidth:'none', overflowX:'hidden', height:'100%', }}>
      {activeTab === "Navigation Manager" ? (

            <ToolbarSection
              

              title="Navigation"
            >
              <ToolbarItem
                full={true}
                propKey="href"
                type="select"
                label="href"
              />
            </ToolbarSection>
      ) : null}



      {activeTab === "Style Manager" ? (
        <>
          <Toggle toggleIsImg={toggleIsImg} on={img} second={'Svg'} first={'Image'} />

          <ToolbarSection title="Source" >
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }} className='w-[100%] text-center flex-col items-center justify-center mb-[.5vmin]'>
              <div style={{ width: "95%",}}>
                <ToolbarItem
                  selectedIcon={selectedIcon}
                  IconsSquare={iconsSquare}
                  setIconsSquare={setIconsSquare}
                  full={true}
                  propKey="src"
                  type="src"
                  iconSearch
                  noinput
                  placeholder='Put the Svg Path'
                />
                {iconsSquare && (
                  <SelectionBox
                    category={category}
                    setIconsSquare={setIconsSquare}
                    iconsSquare={iconsSquare}
                    onSelect={handleIconImageSelect}
                  />
                )}
              </div>
            </div>
          </ToolbarSection>
            <ToolbarSection
              
              title="Colors"
              props={['fillColor']}
              summary={({ fillColor }) => {
                return (
                  <div className="flex flex-row-reverse">
                    <div
                      style={{
                        background:
                          fillColor && `rgba(${Object.values(fillColor)})`,
                      }}
                      className="shadow-md flex-end w-[6%] h-[6%] text-center flex items-center rounded-[0.25Vmin] bg-black"
                    >
                    </div>
                  </div>
                );
              }}
            >
              <ToolbarItem
                full={true}
                propKey="fillColor"
                type="bg"
                label="Svg's fill color"
              />
            </ToolbarSection>

          <ToolbarSection
            
            title="Size"
            props={['width', 'height']}
            summary={({ width, height, minWidth, maxWidth }) => {
              return `Width: ${width || ''}, Height: ${height || ''}, Min Width: ${minWidth || ''}, Max Width: ${maxWidth || ''}`;
            }}
          >
            <ToolbarItem
              full={true}
              propKey="scale"
              type="slider"
              unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
              label="Scale SVG"
            />
          </ToolbarSection>

          <ToolbarSection
            
            title="margin"
            props={['margin']}
            summary={({ }) => {
              return;
            }}
          >
            <ToolbarItem propKey="marginTop" type="slider"
              unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
              label="Top" />
            <ToolbarItem propKey="marginRight" type="slider"
              unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
              label="Right" />
            <ToolbarItem propKey="marginBottom" type="slider"
              unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
              label="Bottom" />
            <ToolbarItem propKey="marginLeft" type="slider"
              unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
              label="Left" />
          </ToolbarSection>
</>
      ) : null}
      </div>
    </div>
  )
}