/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, { useEffect, useRef, useState } from 'react';
import { Element, useNode } from '@craftjs/core';
import { NewStatisticsSettings } from './newStatisticsSetting';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import { Text } from '../../basic/Text';
import './Sta.css'
import {XYPlot, XAxis, YAxis, HorizontalGridLines, VerticalGridLines, LineMarkSeries, LineSeries } from 'react-vis';
import { Parent } from '../../Parent/index.js';




export const Statistics_1 = ({ selected }) => {

    const data = new Array(19).fill(0).reduce((prev, curr) => [...prev, {
        x: prev.slice(-1)[0].x + 1,
        y: prev.slice(-1)[0].y * (0.9 + Math.random() * 0.2) 
      }], [{x: 100, y: 500}]);

      const xAxisStyle = {
        line: { stroke: '#FFFFFF33' },
        ticks: { stroke: '#FFFFFF33' },
      };
      
    const { 
        connectors: {
          connect
        } 
    } = useNode();

    const styles = [
        <div className='w-[100%] h-full bg-[#213156]'>
            <div className='container'>
            <div className='w-[100%] h-full flex flex-col py-10'>
            <div className='mb-10' style={{fontSize:'46px', fontWeight:'700', fontFamily:'Open Sans', color:'#EA6EF4'}}>Our work in numbers</div>
            <div className='w-full h-full flex flex-row'>
                <div className='w-full h-full flex flex-col mr-8 my-auto' style={{border:'1px solid #EA6EF40A', flex:'49%', boxShadow: '0px 0px 4px 0px #FFFFFF1A'}}>
                    <div className='w-full h-full flex flex-row justify-center mx-auto py-10' style={{borderBottom:'1px solid #EA6EF40A'}}>
                        <div className='w-auto p-10 mx-8 h-full flex flex-col'>
                            <div className='mx-auto my-auto' style={{fontSize:'36px', fontWeight:'700', fontFamily:'Times New Roman', color:'#EA6EF4'}}>1,524</div>
                            <div className='mx-auto my-auto' style={{fontSize:'18px', fontWeight:'400', fontFamily:'Open Sans', color:'#FFFFFF'}}>Sales</div>
                        </div>
                        <div className='w-full h-full mx-10 my-auto'>
                            <img alt='vector' className='w-full h-full' src='https://i.ibb.co/YNVFk06/Vector-1.png'/>
                        </div>
                    </div>
                    <div className='w-full h-full flex flex-row justify-center mx-auto py-10' style={{borderBottom:'1px solid #EA6EF40A'}}>
                        <div className='w-auto p-10 mx-8 h-full flex flex-col'>
                            <div className='mx-auto my-auto' style={{fontSize:'36px', fontWeight:'700', fontFamily:'Times New Roman', color:'#EA6EF4'}}>1,524</div>
                            <div className='mx-auto my-auto' style={{fontSize:'18px', fontWeight:'400', fontFamily:'Open Sans', color:'#FFFFFF'}}>Sales</div>
                        </div>
                        <div className='w-full h-full mx-10 my-auto'>
                            <img alt='vector' className='w-full h-full' src='https://i.ibb.co/rxQBB9h/Vector-2.png'/>
                        </div>
                    </div>
                </div>
                <div style={{border:'1px solid #EA6EF40A', flex:'49%', boxShadow: '0px 0px 4px 0px #FFFFFF1A'}}>
                <div className='p-4' style={{fontSize:'22px', fontWeight:'700', fontFamily:'Open Sans', color:'#FFFFFF', lineHeight:'30.8px'}}>User activity</div>
                <XYPlot width={650} height={300} stroke="#EA6EF4">
                    <VerticalGridLines style={xAxisStyle}/>
                    <HorizontalGridLines style={xAxisStyle}/>
                    <XAxis style={xAxisStyle} hideTicks={true}/>
                    <YAxis style={xAxisStyle}/>
                    <LineSeries
                        className="linemark-series-example"
                        style={{
                        strokeWidth: '7px'
                        }}
                        data={data}
                    />
                </XYPlot>
                </div>
            </div>
            <div className='w-[100%] flex flex-col px-4 mt-8 py-4' style={{border:'1px solid #EA6EF40A', boxShadow: '0px 0px 4px 0px #FFFFFF1A'}}>
        <div className='my-4' style={{fontSize:'22px', fontWeight:'700', fontFamily:'Open Sans', color:'#FFFFFF'}}>Grouth in numbers</div>
        <div className='w-[100%] h-full flex flex-row'>
        <div className='w-[50%] flex flex-row'>
        <div className="pieContainer">
        <div className="pieBackground">
        </div>
        <div id="pieSlice50" className="hold">
            <div className="pie">
            </div>
        </div>
        <div className="stat-circle-cover">  
            <span className="stat-number">
                50%
            </span>
        </div>
        </div>

        <div className='w-[50%] my-auto mx-auto flex flex-col justify-between'>
            <div style={{fontSize:'18px', fontWeight:'700', fontFamily:'Open Sans', color:'#EA6EF4'}}>Lorem ipum</div>
            <div  style={{fontSize:'16px', fontWeight:'400', fontFamily:'Open Sans', color:'#FFFFFF'}}>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...</div>
        </div>
        </div>

        <div className='w-[50%] flex flex-row mx-auto'>
        <div className="pieContainer">
        <div className="pieBackground">
        </div>
        <div id="pieSlice75_1" className="hold">
            <div className="pie slice active">
            </div>
        </div>
        <div id="pieSlice75_2" className="hold">
            <div className="pie">
            </div>
        </div>
        <div className="stat-circle-cover">  
            <span className="stat-number">
                75%
            </span>
        </div>
        </div>
        <div className='w-[50%] my-auto mx-auto flex flex-col justify-between'>
            <div style={{fontSize:'18px', fontWeight:'700', fontFamily:'Open Sans', color:'#EA6EF4'}}>Lorem ipum</div>
            <div  style={{fontSize:'16px', fontWeight:'400', fontFamily:'Open Sans', color:'#FFFFFF'}}>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...</div>
        </div>
        </div>
        </div>
            </div>
            </div>
            </div>
        </div>
    ]

    
  return (
    <div style={{ padding: "0px", display:"flex", justifyContent:"center", alignItems:"center", width:'100%', height:'100%'}} ref={connect}>
    <Element canvas is={Parent} id='parentId1' className="w-[100%] h-auto" >
    <Element canvas is={Parent} id='parentId1' className="w-[100%] h-auto" row={true} >
      {styles[selected]}
    </Element>
    </Element>
</div>
);
};

Statistics_1.craft = {
displayName: "Statistics 1",
props: {
   selected: 1, // here is the default value of the selected style
isBlock: true
  },
  rules: {
    canDrag: () => true,
    canDrop: () => true,
  },
  related: {
    toolbar: NewStatisticsSettings,
  },
};


