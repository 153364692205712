/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, { useState } from 'react';
import { Element, useEditor, useNode } from '@craftjs/core';
import { NewBannerSettings } from './newBannerSetting';
import { Text } from '../../basic/Text';
import { useEffect } from 'react';
import { Button } from '../../basic/Button';
import { Parent } from '../../Parent/index.js';
import { BounceLoader } from 'react-spinners';
import { Wrapper } from '../../wrapper/index.js';
import './Banner.css'

export const Banner_1 = ({ selected }) => {

  const {
    enabled,

  } = useEditor((state, query) => ({
    enabled: state.options.enabled,

  }));

  const {
    actions: { setProp },
    connectors: {
      connect
    }
  } = useNode();



  const styles = [
    <Element canvas is={Parent} id='parentId100' className="w-full h-full" >
      <Element canvas is={Parent} id='parentId16' className="w-full h-full flex justify-center items-center" row={true}>

        <Element is={Parent} canvas id='random-id-438' className="wrapper-banner text-center bg-white py-10" >

            <Element is={Parent} canvas id='random-id-440' className='wrapper-content'>

              <Element is={Parent} canvas id='random-id-441' className='flex flex-col mx-auto pt-20'>
                <Element is={Parent} canvas className={`inline-block mx-auto font-bold my-4 text-stone-950 font-bold text-[46px] `} id="random-id-1" >
                  <Text text="Understand User Flow." />
                </Element>
                <Element is={Parent} canvas className={`inline-block mx-auto my-4 font-bold text-[#B20312] font-bold text-[46px]`} id="random-id-2" >
                  <Text text="Increase Conversion." />
                </Element>
              </Element>

              <Element is={Parent} canvas id='random-id-442' className='p-[15px] mx-auto'>
                <Element is={Parent} canvas className={` inline-block mx-auto text-stone-950 font-normal text-[37px] font-normal text-[26px]`} id="random-id-3" >
                  <Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
                </Element>
              </Element>

              <Element is={Parent} canvas id='random-id-443' className='flex flex-row flex-wrap justify-center mx-auto p-20 '>
                <Element is={Parent} canvas id="random-id-100" className="m-2" >
                  <Button href={'/'} classn='block' text='Get started' className="bg-[#B20312] text-[#fff] button-filled font-['Rubik'] hover:bg-[#87000C] shadow ">
                    <Element is={Parent} canvas className='inline-block' id="random-id-4" >
                      <Text text='Get started' />
                    </Element>
                  </Button>
                </Element>
                <Element is={Parent} canvas id="random-id-101" className="m-2" >
                  <Button href={'/'} classn='block' text='Learn more' className="button-filled hover:bg-[#87000C] hover:text-[#fff] font-['Rubik'] border-1 hover:border-[#87000C] shadow">
                    <Element is={Parent} canvas className='inline-block' id="random-id-5" >
                      <Text text='Learn more' />
                    </Element>
                  </Button>
                </Element>
              </Element>
            </Element>
          </Element>

        </Element>
    </Element>,
    <Element canvas is={Parent} id='parentId200' className="w-full h-auto  bg-gray-900 py-10" >
      <Element canvas is={Parent} id='parentId17' className="w-full h-auto flex justify-center items-center" row={true}>
            <Element is={Parent} nameOf='section' canvas id='random-id-402' className='wrapper-content h-full flex flex-col justify-center text-center' >

              <Element is={Parent} canvas id='random-id-40323' className='flex justify-center flex-col pt-20'>
                <Element is={Parent} canvas className={`inline-block UnderstandUserFlow2 font-['Rubik'] font-bold justify-center my-4 text-[46px]  text-[46px]`} id="random-id-326" >
                  <Text text="Understand User Flow." />
                </Element>
                <Element is={Parent} canvas className={`inline-block IncreaseConversion2 font-['Rubik'] font-bold justify-center my-4 text-[46px]  text-[46px]`} id="random-id-232327" >
                  <Text text="Increase Conversion." />
                </Element>
              </Element>

              <Element is={Parent} canvas className={`w-[100%] flex justify-center font-normal text-[30px] text-white font-normal`} id="random-id-8" >
                <Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
              </Element>

              <Element is={Parent} canvas id='random-id-405' className='flex justify-center justify-center p-[20px] mx-auto my-auto flex-row flex-wrap'>
                <Element is={Parent} canvas id="random-id-102" className="m-2" >
                  <Button classn='block' text='Get started' className="button-filled  bg-blue-600 font-['Rubik'] hover:bg-[#0049FF] border-opacity-10 text-white ">
                    <Element is={Parent} canvas className='inline-block' id="random-id-9" >
                      <Text text='Get started' />
                    </Element>
                  </Button>
                </Element>
                <Element is={Parent} canvas id="random-id-103" className="m-2" >
                  <Button classn='block' text='Learn more' className="button-filled bg-[#121826] font-['Rubik'] hover:bg-[#3265E3] border-opacity-10 border-solid border-[#FFFFFF1A] text-white ">
                    <Element is={Parent} canvas className='inline-block' id="random-id-10" >
                      <Text text='Learn more' />
                    </Element>
                  </Button>
                </Element>
              </Element>
            </Element>
          </Element>
    </Element>,
    <Element canvas is={Parent} id='parentId300' className="w-full h-full  bg-[#213156] py-10" >
      <Element canvas is={Parent} id='parentId18' className="w-full h-full flex justify-center items-center" row={true} >
            <Element is={Parent} canvas id='random-id-407' className='wrapper-content flex flex-col flex justify-center text-center' >
              <Element is={Parent} canvas id='random-id-408' className='flex justify-center pt-20 flex-col'>
                <Element is={Parent} canvas id='random-id-409' >
                  <Element is={Parent} canvas className={`inline-block UnderstandUserFlow3 font-['Times New Roman'] font-bold flex justify-center my-4 text-white text-[46px]`} id="random-id-11" >
                    <Text text="Understand User Flow." />
                  </Element>
                </Element>
                <Element is={Parent} canvas id='random-id-410' className={`IncreaseConversion3 flex justify-center font-bold my-4 text-[46px] font-normal font-['Times New Roman]`}>
                  <Element is={Parent} canvas className='inline-block' id="random-id-12" >
                    <Text text="Increase Conversion." />
                  </Element>
                </Element>
              </Element>
              <Element is={Parent} canvas id='random-id-411' className='p-[15px] flex justify-center'>
                <Element is={Parent} canvas className={`inline-block font-['Open Sans'] justify-center text-white  text-[30px] font-normal`} id="random-id-13" >
                  <Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
                </Element>
              </Element>

              <Element is={Parent} canvas id='random-id-412' className='flex flex-row flex-wrap justify-center flex justify-center p-20 '>
                <Element is={Parent} canvas id="random-id-104" className="m-2" >
                  <Button href={'/'} classn='block' text='Get started' className="button-filled font-['Open Sans'] text-[#DFFFF9] rounded-[40px] bg-[#ea6ef4] hover:bg-[#E111F2]">
                    <Element is={Parent} canvas className='inline-block' id="random-id-14" >
                      <Text text='Get started' />
                    </Element>
                  </Button>
                </Element>
                <Element is={Parent} canvas id="random-id-105" className="m-2" >
                  <Button href={'/'} classn='block'
                    className="button-filled bg-[#213156] hover:bg-[#ea6ef4] text-[#31A993] rounded-[40px] font-['Open Sans'] hover:text-[#FFFFFF] border-1 border-solid border-[#ea6ef4]">
                    <Element is={Parent} canvas className='inline-block' id="random-id-15" >
                      <Text text='Learn more' />
                    </Element>
                  </Button>
                </Element>
              </Element>
              {/* <Element canvas id='random-id-412'  className='flex flex-row flex-wrap justify-center flex justify-center p-20 '>
          <Element canvas id="random-id-104" className="m-2" >
                    <Button href={'/'} classn='block' text='Get started' className="bg-[#ea6ef4] uppercase font-bold font-['Open Sans'] hover:bg-[#E111F2] rounded-[40px] flex text-white text-[22px] py-[15px] m-2 uppercase font-bold">
                      <Element canvas  id="random-id-14" >
                        <Text text='Get started'/>
                      </Element>
                    </Button>
                  </Element>
                  <Element canvas id="random-id-105" className="m-2" >
                    <Button href={'/'} classn='block' text='Learn more' className="w-[260px] h-[70px] cursor-pointer bg-[#213156] uppercase font-['Open Sans'] hover:bg-[#ea6ef4] rounded-[40px] border-1 border-[#ea6ef4] flex text-[#EA6EF4] hover:text-white text-[22px] py-[15px] flex shadow m-2 uppercase font-bold font-['Roboto']">
                      <Element canvas  id="random-id-15" >
                        <Text text='Learn more'/>
                      </Element>
                    </Button>
          </Element>
        </Element> */}

          </Element>
        </Element>
    </Element>,
    <Element canvas is={Parent} id='parentId400' className="w-full h-full bg-[#dffff9] py-10" >
      <Element canvas is={Parent} id='parentId19' className="w-full h-full flex justify-center items-center" row={true} >
            <Element is={Parent} canvas id='random-id-415' className='wrapper-content flex flex-col flex justify-center text-center' >
              <Element is={Parent} canvas id='random-id-416' className='w-full flex justify-center pt-20 flex-col'>
                <Element is={Parent} canvas className={`inline-block IncreaseConversion4 font-['italic'] font-bold font-['Zing Script Rust SemiBold Demo'] flex justify-center my-4 text-[#31A993] text-[56px]`} id="random-id-16" >
                  <Text text="Understand User Flow." />
                </Element>
                <Element is={Parent} canvas className={`inline-block IncreaseConversion4 font-['italic'] font-bold font-['Zing Script Rust SemiBold Demo'] flex justify-center my-4 text-[#31A993] text-[46px]`} id="random-id-17" >
                  <Text text="Increase Conversion." />
                </Element>
              </Element>
              <Element is={Parent} canvas id='random-id-417' className='p-[15px] flex justify-center'>
                <Element is={Parent} canvas className={`inline-block justify-center text-gray-950 text-[26px] font-normal`} id="random-id-18" >
                  <Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
                </Element>
              </Element>
              <Element is={Parent} canvas id='random-id-418' className='flex flex-row flex-wrap justify-center flex justify-center p-20 '>
                <Element is={Parent} canvas id="random-id-106" className="m-2" >
                  <Button href={'/'} classn='block' text='Get started' className="button-filled bg-[#31a993] font-['Open Sans'] hover:bg-[#11816C] rounded-[40px] text-[#DFFFF9] ">
                    <Element is={Parent} canvas className='inline-block' id="random-id-19" >
                      <Text text='Get started' />
                    </Element>
                  </Button>
                </Element>
                <Element is={Parent} canvas id="random-id-107" className="m-2" >
                  <Button href={'/'} classn='block' text='Learn more' className="button-filled bg-[#dffff9] font-['Open Sans'] hover:bg-[#31A993] text-[#31A993] hover:text-[#FFFFFF] rounded-[40px] border-1 border-solid border-[#31A993]">
                    <Element is={Parent} canvas className='inline-block' id="random-id-20" >
                      <Text text='Learn more' />
                    </Element>
                  </Button>
                </Element>
              </Element>
            </Element>
          </Element>
    </Element>,
    <Element canvas is={Parent} id='parentId202020' className="w-full h-full bg-[#FFF] py-10" >
      <Element canvas is={Parent} id='parentId202021' className="w-full h-full flex justify-center items-center" row={true} >
          <Element is={Parent} canvas id='random-id-2020024' className='wrapper-content flex flex-col justify-center text-center' >

            <Element is={Parent} canvas id='random-id-2020204' className='flex justify-center flex-col pt-20'>
              <Element is={Parent} canvas className={`inline-block text-[#100103] font-['Inter'] font-bold justify-center my-4 text-[46px]  text-[46px]`} id="random-id-2020205" >
                <Text text="Understand User Flow." />
              </Element>
              <Element is={Parent} canvas className={` inline-block text-[#0078D3] font-['Inter'] font-bold justify-center my-4 text-[46px]  text-[46px]`} id="random-id-2020206" >
                <Text text="Increase Conversion." />
              </Element>
            </Element>

            <Element is={Parent} canvas id='random-id-2020207' className='p-[15px] flex justify-center'>
              <Element is={Parent} canvas className={`inline-block justify-center  text-[30px] text-[#100103] font-normal`} id="random-id-2020208" >
                <Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
              </Element>
            </Element>

            <Element is={Parent} canvas id='random-id-2020208' className='flex flex-row flex-wrap justify-center p-20 '>
              <Element is={Parent} canvas id="random-id-2020209" className="m-2" >
                <Button href={'/'} classn='block' text='Get started' className="button-filled bg-[#0078D3] font-['Inter'] text-white">
                  <Element is={Parent} canvas className='inline-block' id="random-id-20202010" >
                    <Text text='Get started' />
                  </Element>
                </Button>
              </Element>
              <Element is={Parent} canvas id="random-id-20202011" className="m-2" >
                <Button href={'/'} classn='block' text='Learn more' className="button-filled bg-[transparent] font-['Inter'] hover:bg-[#0078D3] border-1 border-solid border-[#0078D3]  text-[#0078D3] hover:text-[#FFF]">
                  <Element is={Parent} canvas className='inline-block' id="random-id-20202012" >
                    <Text text='Learn more' />
                  </Element>
                </Button>
              </Element>
            </Element>
          </Element>
      </Element>
    </Element>,
    <Element canvas is={Parent} id='parentId1600' className="w-full h-full  bg-[#020212] py-10" >
      <Element canvas is={Parent} id='parentId21' className="w-full h-full flex justify-center items-center" row={true} >
    
            <Element is={Parent} canvas id='random-id-425' className='wrapper-content flex flex-col gap-10 items-center pt-[206px] pb-[243px] flex justify-center text-center' >
              <Element is={Parent} canvas id='random-id-426' className='w-full flex justify-center'>
                <Element is={Parent} canvas className={`inline-block font-['Voces'] justify-center text-[46px]  text-white font-bold `} id="random-id-26" >
                  <Text text="Understand User Flow." />
                </Element>
              </Element>
              <Element is={Parent} canvas id='random-id-427' className='flex justify-center  px-10 my-8'>
                <Element is={Parent} canvas className={`inline-block font-['Voces'] justify-center  text-[26px] text-white font-normal`} id="random-id-27" >
                  <Text text="Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab ill." />
                </Element>
              </Element>
              <Element is={Parent} canvas id="random-id-110" className="m-2" >
                <Button href={'/'} classn='block' text='Learn more' className="button-filled rounded-[10px] border-1 border-white bg-[#020212] font-['Voces'] hover:bg-[#FFFFFF] text-[#FFFFFF] hover:text-[#020212]">
                  <Element is={Parent} canvas className='inline-block' id="random-id-28" >
                    <Text text='Learn more' />
                  </Element>
                </Button> 
              </Element>
            </Element>
          </Element>
    </Element>,
    <Element canvas is={Parent} id='parent121' className="w-full h-full bg-whiter" >
      <Element canvas is={Parent} id='parentId12' className="w-full h-full flex justify-center items-center" row={true} >
            <Element is={Parent} canvas id='random-id-430' className='wrapper-content bg-[#6750a4] flex flex-col items-center pt-[180px] pb-[238px] rounded-[40px] flex justify-center text-center' >
              <Element is={Parent} canvas id='random-id-431' className='flex justify-center pt-20 flex-col'>
                <Element is={Parent} canvas className={`inline-block justify-center font-['Roboto'] text-[56px] font-bold text-white px-[20px]`} id="random-id-29" >
                  <Text text="Understand User Flow." />
                </Element>
              </Element>
              <Element is={Parent} canvas id='random-id-43233' className=' p-[15px]  flex justify-center px-[50px]'>
                <Element is={Parent} canvas className={`inline-block justify-center font-['Roboto'] font-normal text-[26px] text-white px-[30px]`} id="random-id-30" >
                  <Text text="Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab ill." />
                </Element>
              </Element>
              <Element is={Parent} canvas id="random-id-111" className="m-2" >
                <Button href={'/'} classn='block' text='Learn more' className="button-filled bg-purple-300 rounded-[100px] text-[#381E72] border-1 hover:border-[#381E72] font-['Roboto']">
                  <Element is={Parent} canvas className='inline-block' id="random-id-31" >
                    <Text text='Learn more' />
                  </Element>
                </Button>
              </Element>
            </Element>
          </Element>
    </Element>,
    <Element canvas is={Parent} id='parentId1800' className="w-full h-full bg-[#2A2A2A]" >
      <Element canvas is={Parent} id='parentId13' className="w-full h-full flex justify-center items-center" row={true} >
          <Element is={Parent} canvas id='random-id-435' className='wrapper-content flex flex-col items-center pt-[206px] pb-[243px] flex justify-center text-center' >
            <Element is={Parent} canvas id='random-id-436' className='w-full flex justify-center'>
              <Element is={Parent} canvas className={`inline-block justify-center font-['Rum Raisin'] text-[46px]  text-[#FFD600] font-bold`} id="random-id-3222222222" >
                <Text text="Understand User Flow." />
              </Element>
            </Element>
            <Element is={Parent} canvas id='random-id-437' className='flex justify-center  px-10 my-8'>
              <Element is={Parent} canvas className={`inline-block justify-center font-['Rum Raisin'] text-[24px] text-white font-normal`} id="random-id-3444443" >
                <Text text="Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab ill." />
              </Element>
            </Element>
            <Element is={Parent} canvas id="random-id-112" className="m-2" >
              <Button href={'/'} classn='block' text='Learn more' className="button-filled rounded-[10px] text-[#2A2A2A] bg-[#FFD600] font-['Sansation'] hover:bg-[#FFD600A1]">
                <Element is={Parent} canvas className='inline-block' id="random-id-34" >
                  <Text text='Learn more' />
                </Element>
              </Button>
            </Element>
          </Element>
        </Element>
    </Element>,
    <Element canvas is={Parent} id='parentId1900' className="w-full h-full bg-[#F7FBFE]" >
      <Element canvas is={Parent} id='parentId14' className="w-full h-full items-center justify-center" row={true} >
          <Element is={Parent} canvas id='random-id-902' className='wrapper-content flex flex-col items-center pt-[206px] pb-[243px] flex justify-center text-center' >
            <Element is={Parent} canvas id='random-id-903' className='w-full flex justify-center'>
              <Element is={Parent} canvas className={`inline-block justify-center font-['Rubik'] text-[46px]  text-[#6064D2] font-bold`} id="random-id-32222" >
                <Text text="Understand User Flow." />
              </Element>
            </Element>
            <Element is={Parent} canvas id='random-id-904' className='flex justify-center  px-10 my-8'>
              <Element is={Parent} canvas className={`inline-block justify-center font-['Rubik'] text-[24px] text-[#000000] font-normal`} id="random-id-336666" >
                <Text text="Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab ill." />
              </Element>
            </Element>
            <Element is={Parent} canvas id="random-id-905" className="m-2" >
              <Button href={'/'} classn='block' text='Learn more' className="w-[320px] h-[50px] cursor-pointer rounded-[10px] flex items-center justify-center text-[#2A2A2A] text-[22px] bg-[#B20312] hover:bg-[#87000C] uppercase font-bold font-['Sansation'] hover:bg-[#FFD600A1]">
                <Element is={Parent} canvas className='inline-block' id="random-id-3466666" >
                  <Text text='Learn more' />
                </Element>
              </Button>
            </Element>
          </Element>
        </Element>
    </Element>,
    <Element canvas is={Parent} id='parentId1000' className="w-full h-full bg-[#FEFBF7]" >
      <Element canvas is={Parent} id='parentId15' className="w-full h-full flex justify-center items-center" row={true} >
          <Element is={Parent} canvas id='random-id-908' className='w-[100%] h-[100%] flex flex-col items-center pt-[206px] pb-[243px] flex justify-center text-center' >
            <Element is={Parent} canvas id='random-id-909' className='w-full flex justify-center'>
              <Element is={Parent} canvas className={`inline-block justify-center font-['Rubik'] text-[46px]  text-[#A25738] font-bold`} id="random-id-32" >
                <Text text="Understand User Flow." />
              </Element>
            </Element>
            <Element is={Parent} canvas id='random-id-910' className='flex justify-center  px-10 my-8'>
              <Element is={Parent} canvas className={`inline-block justify-center font-['Rubik'] text-[24px] text-[#000000] font-normal`} id="random-id-33" >
                <Text text="Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab ill." />
              </Element>
            </Element>
            <Element is={Parent} canvas id="random-id-911" className="m-2" >
              <Button href={'/'} classn='block' text='Learn more' className="w-[280px] h-[60px] cursor-pointer rounded-[10px] py-[14px] text-[#2A2A2A] text-[22px] bg-[#A25738]  uppercase font-bold font-['Sansation'] hover:bg-[#FFD600A1]">
                <Element is={Parent} canvas className='inline-block' id="random-id-34777777" >
                  <Text text='Learn more' />
                </Element>
              </Button>
            </Element>
          </Element>
        </Element>
    </Element>
  ]

  const [sel, setSel] = useState(selected)

  // function makeOdd(number) {
  //   setProp((prop) => {
  //     setSel(100);
  //   }, 1000);

  //   setTimeout(() => {
  //     setProp((prop) => {
  //       setSel(number)
  //     }, 1000);
  //   }, 100);
  // }

  // useEffect(() => {
  //   if (selected || selected >= 0) {
  //     makeOdd(selected);
  //   }
  // }, [selected])

  return (
    <div style={{ padding: enabled ? "10px" : "0px", display: "flex", justifyContent: "center", alignItems: "center", width: '100%', height: '100%' }} ref={connect}>
      {sel == 100 ? (
        <div style={{ height: "85vh", display: "flex", justifyContent: "center", alignItems: "center"}}>
          <BounceLoader
            size={100}
            color={"white"}
            loading={true}
          />
        </div>
      ) : (
        styles[sel]
      )}
    </div>
  );
};

Banner_1.craft = {
  displayName: "Banner 1",
  props: {
    selected: 1, // here is the default value of the selected style
    isBlock: true,
    length: 9,
  },
  rules: {
    canDrag: () => true,
    canDrop: () => true,
  },
  related: {
    toolbar: NewBannerSettings,
  },
};
