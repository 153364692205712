/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import { Element, useEditor, useNode } from '@craftjs/core';
import React, { useEffect, useRef, useState } from 'react';
import {NewSectionsSettings} from './newSectionsSetting'
import { Text } from '../../basic/Text';
import { ImageC } from '../../basic/Image';
import { Parent } from '../../Parent/index.js';
import { Wrapper } from '../../wrapper/index.js';
import { BounceLoader } from 'react-spinners';
import { SvgIcon } from '../../basic/Svg';


export const Sections_1 = ({selected}) => {
  const { 
    actions: { setProp },
      connectors: {
        connect
      } 
  } = useNode();

  
  const {
    enabled,
  } = useEditor((state, query) => ({
    enabled: state.options.enabled,
 
  }));
    const styles = [

      <Element canvas is={Parent} id='parentId100' className="w-[100%] h-auto" >
  <Element canvas is={Parent} id='parentId200' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-400' is='parent' className='w-[100%] h-full bg-[white] py-20'>
      <Wrapper canvas id='random-id-401' is='container' className='container'>
      <Wrapper canvas id='random-id-402' is='div' className='w-full h-full flex flex-col flex-wrap'>
                <Wrapper canvas style={{display:'inline-block', fontSize:'46px', fontWeight:'700', fontFamily:'Rubik'}} className='text-[#100103] mx-auto text-center' id='random-id-55' is='div'>
                  <Text text='What makes our clients happy.'/>
                </Wrapper>
              <Wrapper canvas style={{display:'inline-block', fontSize:'22px', fontWeight:'400', fontFamily:'Rubik', color:'#000000'}} className='my-10 mx-auto text-center max-w-[500px] flex flex-wrap' id='random-id-56' is='div'>
                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
              </Wrapper>
              <Wrapper canvas id='random-id-403' is='wrapper' className='w-full h-full flex flex-row flex-wrap justify-center'>
                <Wrapper canvas id='random-id-404' is='div' className=' min-h-[117px] max-h-[100%] min-w-[300px] flex flex-row  m-2' style={{flex:'30%'}}>
                  <Wrapper canvas id='random-id-405' is='div' className='h-full my-[7px] items-center flex' style={{border:'1px solid #B20312', borderRadius:'6px', background:'#B20312', width:'auto', height:'fit-content', padding:'6px'}}>
                    <SvgIcon fill='#FFFFFF' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                  </Wrapper>
                  <Wrapper canvas id='random-id-406' is='div' className='flex flex-col px-8'>
                    <Wrapper canvas style={{display:'inline-block', fontSize:'22px', fontWeight:'700', fontFamily:'Rubik', textAlign:'left', color:'#000000'}} id='random-id-57' is='div'>
                      <Text text='Dolor sit'/> 
                    </Wrapper>
                      
                    <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', textAlign:'left', color:'#000000'}} className='my-[7px]' id='random-id-58' is='div'>
                      <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.'/>
                    </Wrapper>
                  </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-407' is='div' className=' min-h-[117px] max-h-[100%] min-w-[300px] flex flex-row  m-2' style={{flex:'30%'}}>
                  <Wrapper canvas id='random-id-408' is='div' className='h-full my-[7px] items-center flex' style={{border:'1px solid #B20312', borderRadius:'6px', background:'#B20312', width:'auto', height:'fit-content', padding:'6px'}}>
                    <SvgIcon fill='#FFFFFF' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                  </Wrapper>
                  <Wrapper canvas id='random-id-409' is='div' className='flex flex-col px-8'>
                    <Wrapper canvas id='random-id-410' is='div' style={{fontSize:'22px', fontWeight:'700', fontFamily:'Rubik', textAlign:'left', color:'#000000'}}>
                      <Text text='Dolor sit'/>
                    </Wrapper>
                      <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', textAlign:'left', color:'#000000'}} className='my-[7px]' id='random-id-59' is='div'>
                        <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.'/>
                      </Wrapper>
                  </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-411' is='div' className=' min-h-[117px] max-h-[100%] min-w-[300px] flex flex-row  m-2' style={{flex:'30%'}}>
                  <Wrapper canvas id='random-id-412' is='div' className='h-full my-[7px] items-center flex' style={{border:'1px solid #B20312', borderRadius:'6px', background:'#B20312', width:'auto', height:'fit-content', padding:'6px'}}>
                    <SvgIcon fill='#FFFFFF' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                  </Wrapper>
                  <Wrapper canvas id='random-id-413' is='div' className='flex flex-col px-8'>
                    <Wrapper canvas id='random-id-414' is='div' style={{fontSize:'22px', fontWeight:'700', fontFamily:'Rubik', textAlign:'left', color:'#000000'}}>
                      <Text text='Dolor sit'/>
                    </Wrapper>
                   
                      <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', textAlign:'left', color:'#000000'}} className='my-[7px]' id='random-id-60' is='div'>
                        <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.'/>
                      </Wrapper>
                   
                  </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-415' is='div' className=' min-h-[117px] max-h-[100%] min-w-[300px] flex flex-row  m-2' style={{flex:'30%'}}>
                  <Wrapper canvas id='random-id-416' is='div' className='h-full my-[7px] items-center flex' style={{border:'1px solid #B20312', borderRadius:'6px', background:'#B20312', width:'auto', height:'fit-content', padding:'6px'}}>
                    <SvgIcon fill='#FFFFFF' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                  </Wrapper>
                  <Wrapper canvas id='random-id-417' is='div' className='flex flex-col px-8'>
                    <Wrapper canvas id='random-id-418' is='div' style={{fontSize:'22px', fontWeight:'700', fontFamily:'Rubik', textAlign:'left', color:'#000000'}}>
                      <Text text='Dolor sit'/>
                    </Wrapper>
                   
                      <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', textAlign:'left', color:'#000000'}} className='my-[7px]' id='random-id-61' is='div'>
                        <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.'/>
                      </Wrapper>
                    </Wrapper>
          
                </Wrapper>
                <Wrapper canvas id='random-id-419' is='div' className=' min-h-[117px] max-h-[100%] min-w-[300px] flex flex-row  m-2' style={{flex:'30%'}}>
                  <Wrapper canvas id='random-id-420' is='div' className='h-full my-[7px] items-center flex' style={{border:'1px solid #B20312', borderRadius:'6px', background:'#B20312', width:'auto', height:'fit-content', padding:'6px'}}>
                    <SvgIcon fill='#FFFFFF' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                  </Wrapper>
                  <Wrapper canvas id='random-id-421' is='div' className='flex flex-col px-8'>
                    <Wrapper canvas id='random-id-422' is='div' style={{fontSize:'22px', fontWeight:'700', fontFamily:'Rubik', textAlign:'left', color:'#000000'}}>
                      <Text text='Dolor sit'/>
                    </Wrapper>
                   
                      <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', textAlign:'left', color:'#000000'}} className='my-[7px]' id='random-id-62' is='div'>
                        <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.'/>
                      </Wrapper>
                
                  </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-423' is='div' className=' min-h-[117px] max-h-[100%] min-w-[300px] flex flex-row  m-2' style={{flex:'30%'}}>
                  <Wrapper canvas id='random-id-424' is='div' className='h-full my-[7px] items-center flex' style={{border:'1px solid #B20312', borderRadius:'6px', background:'#B20312', width:'auto', height:'fit-content', padding:'6px'}}>
                    <SvgIcon fill='#FFFFFF' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                  </Wrapper>
                  <Wrapper canvas id='random-id-425' is='div' className='flex flex-col px-8'>
                    <Wrapper canvas id='random-id-426' is='div' style={{fontSize:'22px', fontWeight:'700', fontFamily:'Rubik', textAlign:'left', color:'#000000'}}>
                      <Text text='Dolor sit'/>
                    </Wrapper>
                      <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', textAlign:'left', color:'#000000'}} className='my-[7px]' id='random-id-63' is='div'>
                        <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.'/>
                      </Wrapper>
                  </Wrapper>
                </Wrapper>
              </Wrapper>
        </Wrapper>
      </Wrapper>
       
    </Wrapper></Element>
  </Element>,
  
        <Element canvas is={Parent} id='parentId300' className="w-[100%] h-auto" >
  <Element canvas is={Parent} id='parentId400' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-427' is='parent' className='w-[100%] h-full bg-[#121826] py-20'>
          <Wrapper canvas id='random-id-428' is='container' className='container'>
          <Wrapper canvas id='random-id-429' is='div' className='w-full h-full flex flex-col flex-wrap'>
                    <Wrapper canvas style={{display:'inline-block', fontSize:'46px', fontWeight:'700', fontFamily:'Rubik'}} className='IncreaseConversion2 mx-auto text-center'  id='random-id-1' is='div'>
                      <Text text='What makes our clients happy.'/>
                    </Wrapper>
                  <Wrapper canvas style={{display:'inline-block', fontSize:'22px', fontWeight:'400', fontFamily:'Rubik', color:'#FFF'}} className='my-10 mx-auto text-center max-w-[500px] flex flex-wrap' id='random-id-2' is='div'>
                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                  </Wrapper>
                  <Wrapper canvas id='random-id-430' is='wrapper' className='w-full h-full flex flex-row flex-wrap justify-center'>
                    <Wrapper canvas id='random-id-431' is='div' className='min-h-[117px] max-h-[100%]  min-w-[300px] flex flex-row  m-2 mx-auto' style={{flex:'30%'}}>
                      <Wrapper canvas id='random-id-432' is='div' className='h-full my-[7px] items-center flex' style={{border:'1px solid #4BDBA2', borderRadius:'6px', background:'#4BDBA2', width:'auto', height:'fit-content', padding:'6px'}}>
                        <SvgIcon fill='#121826' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                      </Wrapper>
                      <Wrapper canvas id='random-id-433' is='div' className='flex flex-col px-8'>
                        <Wrapper canvas style={{display:'inline-block', fontSize:'22px', fontWeight:'700', fontFamily:'Rubik', textAlign:'left',color:'#FFF'}} id='random-id-3' is='div'>
                          <Text text='Dolor sit'/> 
                        </Wrapper>
                          
                        <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', textAlign:'left',color:'#FFF'}} className='my-[7px]'  id='random-id-4' is='div'>
                          <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.'/>
                        </Wrapper>
                      </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-434' is='div' className='min-h-[117px] max-h-[100%]  min-w-[300px] flex flex-row  m-2 mx-auto' style={{flex:'30%'}}>
                      <Wrapper canvas id='random-id-435' is='div' className='h-full my-[7px] items-center flex' style={{border:'1px solid #4BDBA2', borderRadius:'6px', background:'#4BDBA2', width:'auto', height:'fit-content', padding:'6px'}}>
                        <SvgIcon fill='#121826' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                      </Wrapper>
                      <Wrapper canvas id='random-id-436' is='div' className='flex flex-col px-8'>
                        <Wrapper canvas id='random-id-437' is='div' style={{fontSize:'22px', fontWeight:'700', fontFamily:'Rubik', textAlign:'left',color:'#FFF'}}>
                          <Text text='Dolor sit'/>
                        </Wrapper>
                          <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', textAlign:'left',color:'#FFF'}} className='my-[7px]' id='random-id-5' is='div'>
                            <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.'/>
                          </Wrapper>
                      </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-438' is='div' className='min-h-[117px] max-h-[100%]  min-w-[300px] flex flex-row  m-2 mx-auto' style={{flex:'30%'}}>
                      <Wrapper canvas id='random-id-439' is='div' className='h-full my-[7px] items-center flex' style={{border:'1px solid #4BDBA2', borderRadius:'6px', background:'#4BDBA2', width:'auto', height:'fit-content', padding:'6px'}}>
                        <SvgIcon fill='#121826' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                      </Wrapper>
                      <Wrapper canvas id='random-id-440' is='div' className='flex flex-col px-8'>
                        <Wrapper canvas id='random-id-441' is='div' style={{fontSize:'22px', fontWeight:'700', fontFamily:'Rubik', textAlign:'left',color:'#FFF'}}>
                          <Text text='Dolor sit'/>
                        </Wrapper>
                      
                          <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', textAlign:'left',color:'#FFF'}} className='my-[7px]' id='random-id-6' is='div'>
                            <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.'/>
                          </Wrapper>
                        </Wrapper>
                      
                    </Wrapper>
                    <Wrapper canvas id='random-id-442' is='div' className='min-h-[117px] max-h-[100%]  min-w-[300px] flex flex-row  m-2 mx-auto' style={{flex:'30%'}}>
                      <Wrapper canvas id='random-id-443' is='div' className='h-full my-[7px] items-center flex' style={{border:'1px solid #4BDBA2', borderRadius:'6px', background:'#4BDBA2', width:'auto', height:'fit-content', padding:'6px'}}>
                        <SvgIcon fill='#121826' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                      </Wrapper>
                      <Wrapper canvas id='random-id-444' is='div' className='flex flex-col px-8'>
                        <Wrapper canvas id='random-id-445' is='div' style={{fontSize:'22px', fontWeight:'700', fontFamily:'Rubik', textAlign:'left',color:'#FFF'}}>
                          <Text text='Dolor sit'/>
                        </Wrapper>
                      
                          <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', textAlign:'left',color:'#FFF'}} className='my-[7px]' id='random-id-7' is='div'>
                            <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.'/>
                          </Wrapper>
                        </Wrapper>
                      
                    </Wrapper>
                    <Wrapper canvas id='random-id-446' is='div' className='min-h-[117px] max-h-[100%]  min-w-[300px] flex flex-row  m-2 mx-auto' style={{flex:'30%'}}>
                      <Wrapper canvas id='random-id-447' is='div' className='h-full my-[7px] items-center flex' style={{border:'1px solid #4BDBA2', borderRadius:'6px', background:'#4BDBA2', width:'auto', height:'fit-content', padding:'6px'}}>
                        <SvgIcon fill='#121826' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                      </Wrapper>
                      <Wrapper canvas id='random-id-448' is='div' className='flex flex-col px-8'>
                        <Wrapper canvas id='random-id-449' is='div' style={{fontSize:'22px', fontWeight:'700', fontFamily:'Rubik', textAlign:'left',color:'#FFF'}}>
                          <Text text='Dolor sit'/>
                        </Wrapper>
                      
                          <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', textAlign:'left',color:'#FFF'}} className='my-[7px]' id='random-id-8' is='div'>
                            <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.'/>
                          </Wrapper>
                        </Wrapper>
                      
                    </Wrapper>
                    <Wrapper canvas id='random-id-450' is='div' className='min-h-[117px] max-h-[100%]  min-w-[300px] flex flex-row  m-2 mx-auto' style={{flex:'30%'}}>
                      <Wrapper canvas id='random-id-451' is='div' className='h-full my-[7px] items-center flex' style={{border:'1px solid #4BDBA2', borderRadius:'6px', background:'#4BDBA2', width:'auto', height:'fit-content', padding:'6px'}}>
                        <SvgIcon fill='#121826' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                      </Wrapper>
                      <Wrapper canvas id='random-id-452' is='div' className='flex flex-col px-8'>
                        <Wrapper canvas id='random-id-453' is='div' style={{fontSize:'22px', fontWeight:'700', fontFamily:'Rubik', textAlign:'left',color:'#FFF'}}>
                          <Text text='Dolor sit'/>
                        </Wrapper>
                      
                          <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', textAlign:'left',color:'#FFF'}} className='my-[7px]' id='random-id-9' is='div'>
                            <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.'/>
                          </Wrapper>
                        </Wrapper>
                      
                    </Wrapper>
                  </Wrapper>
            </Wrapper>
          </Wrapper>
           
        </Wrapper></Element>
  </Element>,

        <Element canvas is={Parent} id='parentId500' className="w-[100%] h-auto" >
  <Element canvas is={Parent} id='parentId600' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-454' is='parent' className='w-[100%] h-full bg-[#213156] py-20'>
          <Wrapper canvas id='random-id-455' is='container' className='container'>
          <Wrapper canvas id='random-id-456' is='div' className='w-full h-full flex flex-col flex-wrap'>
                    <Wrapper canvas style={{display:'inline-block', fontSize:'46px', fontWeight:'700', fontFamily:'Times New Roman'}} className='text-[#EA6EF4] mx-auto text-center' id='random-id-10' is='div'>
                      <Text text='What makes our clients happy.'/>
                    </Wrapper>
                  <Wrapper canvas style={{display:'inline-block', fontSize:'22px', fontWeight:'400', fontFamily:'Open Sans', color:'#FFFFFF'}} className='my-6 mx-auto text-center max-w-[500px] flex flex-wrap' id='random-id-11' is='div'>
                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                  </Wrapper>
                  <Wrapper canvas id='random-id-458' is='wrapper' className='w-full h-full flex flex-row flex-wrap justify-center'>
                    <Wrapper canvas id='random-id-459' is='div' className='min-h-[117px] max-h-[100%]  min-w-[300px] flex flex-row  m-2 mx-auto' style={{flex:'30%'}}>
                      <Wrapper canvas id='random-id-460' is='div' className='h-full my-[7px] items-center flex' style={{border:'1px solid #EA6EF4', borderRadius:'8px', background:'#EA6EF4', width:'auto', height:'fit-content', padding:'6px'}}>
                        <SvgIcon fill='#213156' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                      </Wrapper>
                      <Wrapper canvas id='random-id-461' is='div' className='flex flex-col px-8'>
                        <Wrapper canvas style={{display:'inline-block', fontSize:'22px', fontWeight:'700', fontFamily:'Open Sans', textAlign:'left', color:'#EA6EF4'}} id='random-id-12' is='div'>
                          <Text text='Dolor sit'/> 
                        </Wrapper>
                          
                        
                        <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Open Sans', textAlign:'left', color:'#FFFFFF'}} className='my-[7px]' id='random-id-13' is='div'>
                          <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.'/>
                        </Wrapper>
                        
                      </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-462' is='div' className='min-h-[117px] max-h-[100%]  min-w-[300px] flex flex-row  m-2 mx-auto' style={{flex:'30%'}}>
                      <Wrapper canvas id='random-id-463' is='div' className='h-full my-[7px] items-center flex' style={{border:'1px solid #EA6EF4', borderRadius:'8px', background:'#EA6EF4', width:'auto', height:'fit-content', padding:'6px'}}>
                        <SvgIcon fill='#213156' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                      </Wrapper>
                      <Wrapper canvas id='random-id-464' is='div' className='flex flex-col px-8'>
                        <Wrapper canvas id='random-id-465' is='div' style={{fontSize:'22px', fontWeight:'700', fontFamily:'Open Sans', textAlign:'left', color:'#EA6EF4'}}>
                          <Text text='Dolor sit'/>
                        </Wrapper>
                        
                          <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Open Sans', textAlign:'left', color:'#FFFFFF'}} className='my-[7px]' id='random-id-14' is='div'>
                            <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.'/>
                          </Wrapper>
                        
                      </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-466' is='div' className='min-h-[117px] max-h-[100%]  min-w-[300px] flex flex-row  m-2 mx-auto' style={{flex:'30%'}}>
                      <Wrapper canvas id='random-id-467' is='div' className='h-full my-[7px] items-center flex' style={{border:'1px solid #EA6EF4', borderRadius:'8px', background:'#EA6EF4', width:'auto', height:'fit-content', padding:'6px'}}>
                        <SvgIcon fill='#213156' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                      </Wrapper>
                      <Wrapper canvas id='random-id-468' is='div' className='flex flex-col px-8'>
                        <Wrapper canvas id='random-id-469' is='div' style={{fontSize:'22px', fontWeight:'700', fontFamily:'Open Sans', textAlign:'left', color:'#EA6EF4'}}>
                          <Text text='Dolor sit'/>
                        </Wrapper>
                        
                          <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Open Sans', textAlign:'left', color:'#FFFFFF'}} className='my-[7px]' id='random-id-15' is='div'>
                            <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.'/>
                          </Wrapper>
                        
                      </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-470' is='div' className='min-h-[117px] max-h-[100%]  min-w-[300px] flex flex-row  m-2 mx-auto' style={{flex:'30%'}}>
                      <Wrapper canvas id='random-id-471' is='div' className='h-full my-[7px] items-center flex' style={{border:'1px solid #EA6EF4', borderRadius:'8px', background:'#EA6EF4', width:'auto', height:'fit-content', padding:'6px'}}>
                        <SvgIcon fill='#213156' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                      </Wrapper>
                      <Wrapper canvas id='random-id-472' is='div' className='flex flex-col px-8'>
                        <Wrapper canvas id='random-id-473' is='div' style={{fontSize:'22px', fontWeight:'700', fontFamily:'Open Sans', textAlign:'left', color:'#EA6EF4'}}>
                          <Text text='Dolor sit'/>
                        </Wrapper>
                        
                          <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Open Sans', textAlign:'left', color:'#FFFFFF'}} className='my-[7px]' id='random-id-16' is='div'>
                            <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.'/>
                          </Wrapper>
                        
                      </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-474' is='div' className='min-h-[117px] max-h-[100%]  min-w-[300px] flex flex-row  m-2 mx-auto' style={{flex:'30%'}}>
                      <Wrapper canvas id='random-id-475' is='div' className='h-full my-[7px] items-center flex' style={{border:'1px solid #EA6EF4', borderRadius:'8px', background:'#EA6EF4', width:'auto', height:'fit-content', padding:'6px'}}>
                        <SvgIcon fill='#213156' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                      </Wrapper>
                      <Wrapper canvas id='random-id-476' is='div' className='flex flex-col px-8'>
                        <Wrapper canvas id='random-id-477' is='div' style={{fontSize:'22px', fontWeight:'700', fontFamily:'Open Sans', textAlign:'left', color:'#EA6EF4'}}>
                          <Text text='Dolor sit'/>
                        </Wrapper>
                        
                          <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Open Sans', textAlign:'left', color:'#FFFFFF'}} className='my-[7px]' id='random-id-17' is='div'>
                            <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.'/>
                          </Wrapper>
                        
                      </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-478' is='div' className='min-h-[117px] max-h-[100%]  min-w-[300px] flex flex-row  m-2 mx-auto' style={{flex:'30%'}}>
                      <Wrapper canvas id='random-id-479' is='div' className='h-full my-[7px] items-center flex' style={{border:'1px solid #EA6EF4', borderRadius:'8px', background:'#EA6EF4', width:'auto', height:'fit-content', padding:'6px'}}>
                        <SvgIcon fill='#213156' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                      </Wrapper>
                      <Wrapper canvas id='random-id-480' is='div' className='flex flex-col px-8'>
                        <Wrapper canvas id='random-id-481' is='div' style={{fontSize:'22px', fontWeight:'700', fontFamily:'Open Sans', textAlign:'left', color:'#EA6EF4'}}>
                          <Text text='Dolor sit'/>
                        </Wrapper>
                        
                          <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Open Sans', textAlign:'left', color:'#FFFFFF'}} className='my-[7px]' id='random-id-18' is='div'>
                            <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.'/>
                          </Wrapper>
                        
                      </Wrapper>
                    </Wrapper>
                  </Wrapper>
            </Wrapper>
          </Wrapper>
           
        </Wrapper></Element>
  </Element>,
        

        <Element canvas is={Parent} id='parentId700' className="w-[100%] h-auto" >
  <Element canvas is={Parent} id='parentId800' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-482' is='parent' className='w-[100%] h-full bg-[#DFFFF9] py-20'>
          <Wrapper canvas id='random-id-483' is='container' className='container'>
          <Wrapper canvas id='random-id-484' is='div' className='w-full h-full flex flex-col flex-wrap'>
                    <Wrapper canvas style={{display:'inline-block', fontSize:'46px', fontWeight:'100px', fontFamily:'Zing Script Rust Semi700 Demo', fontStyle:'italic'}} className='text-[#31A993] mx-auto text-center' id='random-id-19' is='div'>
                      <Text text='What makes our clients happy.'/>
                    </Wrapper>
                  <Wrapper canvas style={{display:'inline-block', fontSize:'22px', fontWeight:'400', fontFamily:'Montserrat', color:'#031815'}} className='my-6 mx-auto text-center max-w-[500px] flex flex-wrap' id='random-id-20' is='div'>
                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                  </Wrapper>
                  <Wrapper canvas id='random-id-484' is='wrapper' className='w-full h-full flex flex-row flex-wrap justify-center'>
                    <Wrapper canvas id='random-id-485' is='div' className='min-h-[117px] max-h-[100%]  min-w-[300px] flex flex-row  m-2 mx-auto' style={{flex:'30%'}}>
                      <Wrapper canvas id='random-id-486' is='div' className='h-full my-[7px] px-[8px] py-[8px]' style={{ borderRadius:'30px', background:'#31A993', width:'auto', height:'fit-content', padding:'6px'}}>
                          <SvgIcon fill='#DFFFF9' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                      </Wrapper>
                      <Wrapper canvas id='random-id-487' is='div' className='flex flex-col px-8'>
                        <Wrapper canvas style={{display:'inline-block', fontSize:'22px', fontWeight:'700', fontFamily:'Montserrat', textAlign:'left', color:'#31A993'}} id='random-id-21' is='div'>
                          <Text text='Dolor sit'/> 
                        </Wrapper>
                          
                       
                        <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Montserrat', textAlign:'left', color:'#031815'}} className='my-[7px]' id='random-id-22' is='div'>
                          <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.'/>
                        </Wrapper>
                       
                      </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-488' is='div' className='min-h-[117px] max-h-[100%]  min-w-[300px] flex flex-row  m-2 mx-auto' style={{flex:'30%'}}>
                      <Wrapper canvas id='random-id-489' is='div' className='h-full my-[7px] px-[8px] py-[8px]' style={{ borderRadius:'30px', background:'#31A993', width:'auto', height:'fit-content', padding:'6px'}}>
                          <SvgIcon fill='#DFFFF9' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                      </Wrapper>
                      <Wrapper canvas id='random-id-490' is='div' className='flex flex-col px-8'>
                        <Wrapper canvas id='random-id-491' is='div' style={{fontSize:'22px', fontWeight:'700', fontFamily:'Montserrat', textAlign:'left', color:'#31A993'}}>
                          <Text text='Dolor sit'/>
                        </Wrapper>
                       
                          <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Montserrat', textAlign:'left', color:'#031815'}} className='my-[7px]' id='random-id-23' is='div'>
                            <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.'/>
                          </Wrapper>
                       
                      </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-492' is='div' className='min-h-[117px] max-h-[100%]  min-w-[300px] flex flex-row  m-2 mx-auto' style={{flex:'30%'}}>
                      <Wrapper canvas id='random-id-493' is='div' className='h-full my-[7px] px-[8px] py-[8px]' style={{ borderRadius:'30px', background:'#31A993', width:'auto', height:'fit-content', padding:'6px'}}>
                          <SvgIcon fill='#DFFFF9' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                      </Wrapper>
                      <Wrapper canvas id='random-id-494' is='div' className='flex flex-col px-8'>
                        <Wrapper canvas id='random-id-495' is='div' style={{fontSize:'22px', fontWeight:'700', fontFamily:'Montserrat', textAlign:'left', color:'#31A993'}}>
                          <Text text='Dolor sit'/>
                        </Wrapper>
                       
                          <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Montserrat', textAlign:'left', color:'#031815'}} className='my-[7px]' id='random-id-24' is='div'>
                            <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.'/>
                          </Wrapper>
                       
                      </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-496' is='div' className='min-h-[117px] max-h-[100%]  min-w-[300px] flex flex-row  m-2 mx-auto' style={{flex:'30%'}}>
                      <Wrapper canvas id='random-id-497' is='div' className='h-full my-[7px] px-[8px] py-[8px]' style={{ borderRadius:'30px', background:'#31A993', width:'auto', height:'fit-content', padding:'6px'}}>
                          <SvgIcon fill='#DFFFF9' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                      </Wrapper>
                      <Wrapper canvas id='random-id-498' is='div' className='flex flex-col px-8'>
                        <Wrapper canvas id='random-id-499' is='div' style={{fontSize:'22px', fontWeight:'700', fontFamily:'Montserrat', textAlign:'left', color:'#31A993'}}>
                          <Text text='Dolor sit'/>
                        </Wrapper>
                       
                          <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Montserrat', textAlign:'left', color:'#031815'}} className='my-[7px]' id='random-id-25' is='div'>
                            <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.'/>
                          </Wrapper>
                       
                      </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-500' is='div' className='min-h-[117px] max-h-[100%]  min-w-[300px] flex flex-row  m-2 mx-auto' style={{flex:'30%'}}>
                      <Wrapper canvas id='random-id-501' is='div' className='h-full my-[7px] px-[8px] py-[8px]' style={{ borderRadius:'30px', background:'#31A993', width:'auto', height:'fit-content', padding:'6px'}}>
                          <SvgIcon fill='#DFFFF9' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                      </Wrapper>
                      <Wrapper canvas id='random-id-502' is='div' className='flex flex-col px-8'>
                        <Wrapper canvas id='random-id-503' is='div' style={{fontSize:'22px', fontWeight:'700', fontFamily:'Montserrat', textAlign:'left', color:'#31A993'}}>
                          <Text text='Dolor sit'/>
                        </Wrapper>
                       
                          <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Montserrat', textAlign:'left', color:'#031815'}} className='my-[7px]' id='random-id-26' is='div'>
                            <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.'/>
                          </Wrapper>
                       
                      </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-504' is='div' className='min-h-[117px] max-h-[100%]  min-w-[300px] flex flex-row  m-2 mx-auto' style={{flex:'30%'}}>
                      <Wrapper canvas id='random-id-505' is='div' className='h-full my-[7px] px-[8px] py-[8px]' style={{ borderRadius:'30px', background:'#31A993', width:'auto', height:'fit-content', padding:'6px'}}>
                          <SvgIcon fill='#DFFFF9' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                      </Wrapper>
                      <Wrapper canvas id='random-id-506' is='div' className='flex flex-col px-8'>
                        <Wrapper canvas id='random-id-507' is='div' style={{fontSize:'22px', fontWeight:'700', fontFamily:'Montserrat', textAlign:'left', color:'#31A993'}}>
                          <Text text='Dolor sit'/>
                        </Wrapper>
                       
                          <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Montserrat', textAlign:'left', color:'#031815'}} className='my-[7px]' id='random-id-27' is='div'>
                            <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.'/>
                          </Wrapper>
                       
                      </Wrapper>
                    </Wrapper>
                  </Wrapper>
            </Wrapper>
          </Wrapper>
           
        </Wrapper></Element>
  </Element>,
        
        <Element canvas is={Parent} id='parentId900' className="w-[100%] h-auto" >
  <Element canvas is={Parent} id='parentId1000' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-508' is='parent' className='w-[100%] h-full bg-[#FFFFFF] py-20'>
          <Wrapper canvas id='random-id-509' is='container' className='container'>
          <Wrapper canvas id='random-id-510' is='div' className='w-full h-full flex flex-col flex-wrap'>
                    <Wrapper canvas style={{display:'inline-block', fontSize:'46px', fontWeight:'700', fontFamily:'Inter'}} className='text-[#0078D3] mx-auto text-center' id='random-id-28' is='div'>
                      <Text text='What makes our clients happy.'/>
                    </Wrapper>
                  <Wrapper canvas style={{display:'inline-block', fontSize:'22px', fontWeight:'400', fontFamily:'Inter', color:'#000000'}} className='my-6 mx-auto text-center max-w-[500px] flex flex-wrap' id='random-id-29' is='div'>
                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                  </Wrapper>
                  <Wrapper canvas id='random-id-511' is='wrapper' className='w-full h-full flex flex-row flex-wrap justify-center'>
                    <Wrapper canvas id='random-id-512' is='div' className='min-h-[117px] max-h-[100%]  min-w-[300px] flex flex-row  m-2 mx-auto' style={{flex:'30%'}}>
                      <Wrapper canvas id='random-id-513' is='div' className='h-full my-[7px] items-center flex' style={{border:'1px solid #0078D3', borderRadius:'6px', background:'#0078D3', width:'auto', height:'fit-content', padding:'6px'}}>
                        <SvgIcon fill='#FFFFFF' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                      </Wrapper>
                      <Wrapper canvas id='random-id-514' is='div' className='flex flex-col px-8'>
                        <Wrapper canvas style={{display:'inline-block', fontSize:'22px', fontWeight:'700', fontFamily:'Inter', textAlign:'left', color:'#000000'}} id='random-id-30' is='div'>
                          <Text text='Dolor sit'/> 
                        </Wrapper>
                          
                      
                        <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Inter', textAlign:'left', color:'#000000'}} className='my-[7px]' id='random-id-31' is='div'>
                          <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.'/>
                        </Wrapper>
                      
                      </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-515' is='div' className='min-h-[117px] max-h-[100%]  min-w-[300px] flex flex-row  m-2 mx-auto' style={{flex:'30%'}}>
                      <Wrapper canvas id='random-id-516' is='div' className='h-full my-[7px] items-center flex' style={{border:'1px solid #0078D3', borderRadius:'6px', background:'#0078D3', width:'auto', height:'fit-content', padding:'6px'}}>
                        <SvgIcon fill='#FFFFFF' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                      </Wrapper>
                      <Wrapper canvas id='random-id-517' is='div' className='flex flex-col px-8'>
                        <Wrapper canvas id='random-id-518' is='div' style={{fontSize:'22px', fontWeight:'700', fontFamily:'Inter', textAlign:'left', color:'#000000'}}>
                          <Text text='Dolor sit'/>
                        </Wrapper>
                      
                          <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Inter', textAlign:'left', color:'#000000'}} className='my-[7px]'  id='random-id-32' is='div'>
                            <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.'/>
                          </Wrapper>
                      
                      </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-519' is='div' className='min-h-[117px] max-h-[100%]  min-w-[300px] flex flex-row  m-2 mx-auto' style={{flex:'30%'}}>
                      <Wrapper canvas id='random-id-520' is='div' className='h-full my-[7px] items-center flex' style={{border:'1px solid #0078D3', borderRadius:'6px', background:'#0078D3', width:'auto', height:'fit-content', padding:'6px'}}>
                        <SvgIcon fill='#FFFFFF' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                      </Wrapper>
                      <Wrapper canvas id='random-id-521' is='div' className='flex flex-col px-8'>
                        <Wrapper canvas id='random-id-522' is='div' style={{fontSize:'22px', fontWeight:'700', fontFamily:'Inter', textAlign:'left', color:'#000000'}}>
                          <Text text='Dolor sit'/>
                        </Wrapper>
                      
                          <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Inter', textAlign:'left', color:'#000000'}} className='my-[7px]'  id='random-id-33' is='div'>
                            <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.'/>
                          </Wrapper>
                      
                      </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-523' is='div' className='min-h-[117px] max-h-[100%]  min-w-[300px] flex flex-row  m-2 mx-auto' style={{flex:'30%'}}>
                      <Wrapper canvas id='random-id-524' is='div' className='h-full my-[7px] items-center flex' style={{border:'1px solid #0078D3', borderRadius:'6px', background:'#0078D3', width:'auto', height:'fit-content', padding:'6px'}}>
                        <SvgIcon fill='#FFFFFF' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                      </Wrapper>
                      <Wrapper canvas id='random-id-525' is='div' className='flex flex-col px-8'>
                        <Wrapper canvas id='random-id-526' is='div' style={{fontSize:'22px', fontWeight:'700', fontFamily:'Inter', textAlign:'left', color:'#000000'}}>
                          <Text text='Dolor sit'/>
                        </Wrapper>
                      
                          <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Inter', textAlign:'left', color:'#000000'}} className='my-[7px]'  id='random-id-34' is='div'>
                            <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.'/>
                          </Wrapper>
                      
                      </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-527' is='div' className='min-h-[117px] max-h-[100%]  min-w-[300px] flex flex-row  m-2 mx-auto' style={{flex:'30%'}}>
                      <Wrapper canvas id='random-id-528' is='div' className='h-full my-[7px] items-center flex' style={{border:'1px solid #0078D3', borderRadius:'6px', background:'#0078D3', width:'auto', height:'fit-content', padding:'6px'}}>
                        <SvgIcon fill='#FFFFFF' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                      </Wrapper>
                      <Wrapper canvas id='random-id-529' is='div' className='flex flex-col px-8'>
                        <Wrapper canvas id='random-id-530' is='div' style={{fontSize:'22px', fontWeight:'700', fontFamily:'Inter', textAlign:'left', color:'#000000'}}>
                          <Text text='Dolor sit'/>
                        </Wrapper>
                      
                          <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Inter', textAlign:'left', color:'#000000'}} className='my-[7px]'  id='random-id-35' is='div'>
                            <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.'/>
                          </Wrapper>
                      
                      </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-531' is='div' className='min-h-[117px] max-h-[100%]  min-w-[300px] flex flex-row  m-2 mx-auto' style={{flex:'30%'}}>
                      <Wrapper canvas id='random-id-532' is='div' className='h-full my-[7px] items-center flex' style={{border:'1px solid #0078D3', borderRadius:'6px', background:'#0078D3', width:'auto', height:'fit-content', padding:'6px'}}>
                        <SvgIcon fill='#FFFFFF' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                      </Wrapper>
                      <Wrapper canvas id='random-id-533' is='div' className='flex flex-col px-8'>
                        <Wrapper canvas id='random-id-534' is='div' style={{fontSize:'22px', fontWeight:'700', fontFamily:'Inter', textAlign:'left', color:'#000000'}}>
                          <Text text='Dolor sit'/>
                        </Wrapper>
                      
                          <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Inter', textAlign:'left', color:'#000000'}} className='my-[7px]'  id='random-id-36' is='div'>
                            <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.'/>
                          </Wrapper>
                      
                      </Wrapper>
                    </Wrapper>
                  </Wrapper>
            </Wrapper>
          </Wrapper>
           
        </Wrapper></Element>
  </Element>,
        
        
        <Element canvas is={Parent} id='parentId1100' className="w-[100%] h-auto" >
  <Element canvas is={Parent} id='parentId1200' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-535' is='parent' className='w-[100%] h-full bg-[#FFFFFF] py-20'>
          <Wrapper canvas id='random-id-536' is='container' className='container'>
          <Wrapper canvas id='random-id-537' is='div' className='w-full h-full flex flex-col flex-wrap'>
                    <Wrapper canvas style={{display:'inline-block', fontSize:'46px', fontWeight:'400', fontFamily:'Voces'}} className='text-[#020212] mx-auto text-center uppercase' id='random-id-37' is='div'>
                      <Text text='What makes our clients happy.'/>
                    </Wrapper>
                  <Wrapper canvas style={{display:'inline-block', fontSize:'22px', fontWeight:'400', fontFamily:'Voces', color:'#020212'}} className='my-6 mx-auto text-center max-w-[500px] flex flex-wrap' id='random-id-38' is='div'>
                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                  </Wrapper>
                  <Wrapper canvas id='random-id-538' is='wrapper' className='w-full h-full flex flex-row flex-wrap justify-center'>
                    <Wrapper canvas id='random-id-539' is='div' className='min-h-[117px] max-h-[100%]  min-w-[300px] flex flex-row  m-2 mx-auto' style={{flex:'30%'}}>
                      <Wrapper canvas id='random-id-540' is='div' className='h-full my-[7px] items-center flex' style={{border:'1px solid #020212', borderRadius:'6px', background:'#020212', width:'auto', height:'fit-content', padding:'6px'}}>
                        <SvgIcon fill='#FFFFFF' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                      </Wrapper>
                      <Wrapper canvas id='random-id-541' is='div' className='flex flex-col px-8'>
                        <Wrapper canvas style={{display:'inline-block', fontSize:'22px', fontWeight:'700', fontFamily:'Voces', textAlign:'left', color:'#020212'}} id='random-id-39' is='div'>
                          <Text text='Dolor sit'/> 
                        </Wrapper>
                          
                        
                        <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Voces', textAlign:'left', color:'#020212'}} className='my-[7px]' id='random-id-40' is='div'>
                          <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.'/>
                        </Wrapper>
                        
                      </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-542' is='div' className='min-h-[117px] max-h-[100%]  min-w-[300px] flex flex-row  m-2 mx-auto' style={{flex:'30%'}}>
                      <Wrapper canvas id='random-id-543' is='div' className='h-full my-[7px] items-center flex' style={{border:'1px solid #020212', borderRadius:'6px', background:'#020212', width:'auto', height:'fit-content', padding:'6px'}}>
                        <SvgIcon fill='#FFFFFF' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                      </Wrapper>
                      <Wrapper canvas id='random-id-544' is='div' className='flex flex-col px-8'>
                        <Wrapper canvas id='random-id-545' is='div' style={{fontSize:'22px', fontWeight:'700', fontFamily:'Voces', textAlign:'left', color:'#020212'}}>
                          <Text text='Dolor sit'/>
                        </Wrapper>
                        
                          <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Voces', textAlign:'left', color:'#020212'}} className='my-[7px]' id='random-id-41' is='div'>
                            <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.'/>
                          </Wrapper>
                        
                      </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-546' is='div' className='min-h-[117px] max-h-[100%]  min-w-[300px] flex flex-row  m-2 mx-auto' style={{flex:'30%'}}>
                      <Wrapper canvas id='random-id-547' is='div' className='h-full my-[7px] items-center flex' style={{border:'1px solid #020212', borderRadius:'6px', background:'#020212', width:'auto', height:'fit-content', padding:'6px'}}>
                        <SvgIcon fill='#FFFFFF' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                      </Wrapper>
                      <Wrapper canvas id='random-id-548' is='div' className='flex flex-col px-8'>
                        <Wrapper canvas id='random-id-549' is='div' style={{fontSize:'22px', fontWeight:'700', fontFamily:'Voces', textAlign:'left', color:'#020212'}}>
                          <Text text='Dolor sit'/>
                        </Wrapper>
                        
                          <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Voces', textAlign:'left', color:'#020212'}} className='my-[7px]' id='random-id-42' is='div'>
                            <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.'/>
                          </Wrapper>
                        
                      </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-550' is='div' className='min-h-[117px] max-h-[100%]  min-w-[300px] flex flex-row  m-2 mx-auto' style={{flex:'30%'}}>
                      <Wrapper canvas id='random-id-551' is='div' className='h-full my-[7px] items-center flex' style={{border:'1px solid #020212', borderRadius:'6px', background:'#020212', width:'auto', height:'fit-content', padding:'6px'}}>
                        <SvgIcon fill='#FFFFFF' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                      </Wrapper>
                      <Wrapper canvas id='random-id-552' is='div' className='flex flex-col px-8'>
                        <Wrapper canvas id='random-id-553' is='div' style={{fontSize:'22px', fontWeight:'700', fontFamily:'Voces', textAlign:'left', color:'#020212'}}>
                          <Text text='Dolor sit'/>
                        </Wrapper>
                        
                          <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Voces', textAlign:'left', color:'#020212'}} className='my-[7px]' id='random-id-43' is='div'>
                            <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.'/>
                          </Wrapper>
                        
                      </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-554' is='div' className='min-h-[117px] max-h-[100%]  min-w-[300px] flex flex-row  m-2 mx-auto' style={{flex:'30%'}}>
                      <Wrapper canvas id='random-id-555' is='div' className='h-full my-[7px] items-center flex' style={{border:'1px solid #020212', borderRadius:'6px', background:'#020212', width:'auto', height:'fit-content', padding:'6px'}}>
                        <SvgIcon fill='#FFFFFF' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                      </Wrapper>
                      <Wrapper canvas id='random-id-556' is='div' className='flex flex-col px-8'>
                        <Wrapper canvas id='random-id-557' is='div' style={{fontSize:'22px', fontWeight:'700', fontFamily:'Voces', textAlign:'left', color:'#020212'}}>
                          <Text text='Dolor sit'/>
                        </Wrapper>
                        
                          <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Voces', textAlign:'left', color:'#020212'}} className='my-[7px]' id='random-id-44' is='div'>
                            <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.'/>
                          </Wrapper>
                        
                      </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-558' is='div' className='min-h-[117px] max-h-[100%]  min-w-[300px] flex flex-row  m-2 mx-auto' style={{flex:'30%'}}>
                      <Wrapper canvas id='random-id-559' is='div' className='h-full my-[7px] items-center flex' style={{border:'1px solid #020212', borderRadius:'6px', background:'#020212', width:'auto', height:'fit-content', padding:'6px'}}>
                        <SvgIcon fill='#FFFFFF' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                      </Wrapper>
                      <Wrapper canvas id='random-id-560' is='div' className='flex flex-col px-8'>
                        <Wrapper canvas id='random-id-561' is='div' style={{fontSize:'22px', fontWeight:'700', fontFamily:'Voces', textAlign:'left', color:'#020212'}}>
                          <Text text='Dolor sit'/>
                        </Wrapper>
                        
                          <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Voces', textAlign:'left', color:'#020212'}} className='my-[7px]' id='random-id-45' is='div'>
                            <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.'/>
                          </Wrapper>
                        
                      </Wrapper>
                    </Wrapper>
                  </Wrapper>
            </Wrapper>
          </Wrapper>
           
        </Wrapper></Element>
  </Element>,
        
        

        <Element canvas is={Parent} id='parentId1300' className="w-[100%] h-auto" >
  <Element canvas is={Parent} id='parentId1400' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-562' is='parent' className='w-[100%] h-full bg-[#FBFAFF] py-20'>
          <Wrapper canvas id='random-id-563' is='container' className='container'>
          <Wrapper canvas id='random-id-564' is='div' className='w-full h-full flex flex-col flex-wrap'>
                    <Wrapper canvas style={{display:'inline-block', fontSize:'46px', fontWeight:'700', fontFamily:'Roboto'}} className='text-[#6750A4] mx-auto text-center' id='random-id-46' is='div'>
                      <Text text='What makes our clients happy.'/>
                    </Wrapper>
                  <Wrapper canvas style={{display:'inline-block', fontSize:'22px', fontWeight:'400', fontFamily:'Roboto', color:'#100103'}} className='my-6 mx-auto text-center max-w-[500px] flex flex-wrap'  id='random-id-47' is='div'>
                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                  </Wrapper>
                  <Wrapper canvas id='random-id-565' is='wrapper' className='w-full h-full flex flex-row flex-wrap justify-center'>
                    <Wrapper canvas id='random-id-566' is='div' className='min-h-[117px] max-h-[100%]  min-w-[300px] flex flex-row  m-2 mx-auto' style={{flex:'30%'}}>
                      <Wrapper canvas id='random-id-567' is='div' className='h-full my-[7px] px-[8px] py-[8px]' style={{ borderRadius:'30px', background:'#6750A4', width:'auto', height:'fit-content', padding:'6px'}}>
                        <SvgIcon fill='#FBFAFF' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                      </Wrapper>
                      <Wrapper canvas id='random-id-568' is='div' className='flex flex-col px-8'>
                        <Wrapper canvas style={{display:'inline-block', fontSize:'22px', fontWeight:'700', fontFamily:'Roboto', textAlign:'left', color:'#6750A4'}} id='random-id-48' is='div'>
                          <Text text='Dolor sit'/> 
                        </Wrapper>
                          
                        
                        <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Roboto', textAlign:'left', color:'#100103'}} className='my-[7px]' id='random-id-49' is='div'>
                          <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.'/>
                        </Wrapper>
                        
                      </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-569' is='div' className='min-h-[117px] max-h-[100%]  min-w-[300px] flex flex-row  m-2 mx-auto' style={{flex:'30%'}}>
                      <Wrapper canvas id='random-id-570' is='div' className='h-full my-[7px] px-[8px] py-[8px]' style={{ borderRadius:'30px', background:'#6750A4', width:'auto', height:'fit-content', padding:'6px'}}>
                        <SvgIcon fill='#FBFAFF' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                      </Wrapper>
                      <Wrapper canvas id='random-id-571' is='div' className='flex flex-col px-8'>
                        <Wrapper canvas id='random-id-572' is='div' style={{fontSize:'22px', fontWeight:'700', fontFamily:'Roboto', textAlign:'left', color:'#6750A4'}}>
                          <Text text='Dolor sit'/>
                        </Wrapper>
                        
                          <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Roboto', textAlign:'left', color:'#100103'}} className='my-[7px]' id='random-id-50' is='div'>
                            <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.'/>
                          </Wrapper>
                        
                      </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-573' is='div' className='min-h-[117px] max-h-[100%]  min-w-[300px] flex flex-row  m-2 mx-auto' style={{flex:'30%'}}>
                      <Wrapper canvas id='random-id-574' is='div' className='h-full my-[7px] px-[8px] py-[8px]' style={{ borderRadius:'30px', background:'#6750A4', width:'auto', height:'fit-content', padding:'6px'}}>
                        <SvgIcon fill='#FBFAFF' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                      </Wrapper>
                      <Wrapper canvas id='random-id-575' is='div' className='flex flex-col px-8'>
                        <Wrapper canvas id='random-id-576' is='div' style={{fontSize:'22px', fontWeight:'700', fontFamily:'Roboto', textAlign:'left', color:'#6750A4'}}>
                          <Text text='Dolor sit'/>
                        </Wrapper>
                        
                          <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Roboto', textAlign:'left', color:'#100103'}} className='my-[7px]' id='random-id-51' is='div'>
                            <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.'/>
                          </Wrapper>
                        
                      </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-577' is='div' className='min-h-[117px] max-h-[100%]  min-w-[300px] flex flex-row  m-2 mx-auto' style={{flex:'30%'}}>
                      <Wrapper canvas id='random-id-578' is='div' className='h-full my-[7px] px-[8px] py-[8px]' style={{ borderRadius:'30px', background:'#6750A4', width:'auto', height:'fit-content', padding:'6px'}}>
                        <SvgIcon fill='#FBFAFF' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                      </Wrapper>
                      <Wrapper canvas id='random-id-579' is='div' className='flex flex-col px-8'>
                        <Wrapper canvas id='random-id-580' is='div' style={{fontSize:'22px', fontWeight:'700', fontFamily:'Roboto', textAlign:'left', color:'#6750A4'}}>
                          <Text text='Dolor sit'/>
                        </Wrapper>
                        
                          <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Roboto', textAlign:'left', color:'#100103'}} className='my-[7px]' id='random-id-52' is='div'>
                            <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.'/>
                          </Wrapper>
                        
                      </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-581' is='div' className='min-h-[117px] max-h-[100%]  min-w-[300px] flex flex-row  m-2 mx-auto' style={{flex:'30%'}}>
                      <Wrapper canvas id='random-id-582' is='div' className='h-full my-[7px] px-[8px] py-[8px]' style={{ borderRadius:'30px', background:'#6750A4', width:'auto', height:'fit-content', padding:'6px'}}>
                        <SvgIcon fill='#FBFAFF' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                      </Wrapper>
                      <Wrapper canvas id='random-id-583' is='div' className='flex flex-col px-8'>
                        <Wrapper canvas id='random-id-584' is='div' style={{fontSize:'22px', fontWeight:'700', fontFamily:'Roboto', textAlign:'left', color:'#6750A4'}}>
                          <Text text='Dolor sit'/>
                        </Wrapper>
                        
                          <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Roboto', textAlign:'left', color:'#100103'}} className='my-[7px]' id='random-id-53' is='div'>
                            <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.'/>
                          </Wrapper>
                        
                      </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-585' is='div' className='min-h-[117px] max-h-[100%]  min-w-[300px] flex flex-row  m-2 mx-auto' style={{flex:'30%'}}>
                      <Wrapper canvas id='random-id-586' is='div' className='h-full my-[7px] px-[8px] py-[8px]' style={{ borderRadius:'30px', background:'#6750A4', width:'auto', height:'fit-content', padding:'6px'}}>
                        <SvgIcon fill='#FBFAFF' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                      </Wrapper>
                      <Wrapper canvas id='random-id-587' is='div' className='flex flex-col px-8'>
                        <Wrapper canvas id='random-id-588' is='div' style={{fontSize:'22px', fontWeight:'700', fontFamily:'Roboto', textAlign:'left', color:'#6750A4'}}>
                          <Text text='Dolor sit'/>
                        </Wrapper>
                        
                          <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Roboto', textAlign:'left', color:'#100103'}} className='my-[7px]' id='random-id-54' is='div'>
                            <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.'/>
                          </Wrapper>
                        
                      </Wrapper>
                    </Wrapper>
                  </Wrapper>
            </Wrapper>
          </Wrapper>
           
        </Wrapper></Element>
  </Element>,

        <Element canvas is={Parent} id='parentId1500' className="w-[100%] h-auto" >
  <Element canvas is={Parent} id='parentId1600' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-589' is='parent' className='w-[100%] h-full bg-[#2A2A2A] py-20'>
          <Wrapper canvas id='random-id-590' is='container' className='container'>
          <Wrapper canvas id='random-id-591' is='div' className='w-full h-full flex flex-col flex-wrap'>
                    <Wrapper canvas style={{display:'inline-block', fontSize:'46px', fontWeight:'400', fontFamily:'Rum Raisin'}} className='text-[#FFD600] mx-auto text-center' id='random-id-28' is='div'>
                      <Text text='What makes our clients happy.'/>
                    </Wrapper>
                  <Wrapper canvas style={{display:'inline-block', fontSize:'22px', fontWeight:'400', fontFamily:'Inter', color:'#FFFFFF'}} className='my-6 mx-auto text-center max-w-[500px] flex flex-wrap' id='random-id-29' is='div'>
                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                  </Wrapper>
                  <Wrapper canvas id='random-id-592' is='wrapper' className='w-full h-full flex flex-row flex-wrap justify-center'>
                    <Wrapper canvas id='random-id-593' is='div' className='min-h-[117px] max-h-[100%]  min-w-[300px] flex flex-row  m-2 mx-auto' style={{flex:'30%'}}>
                      <Wrapper canvas id='random-id-594' is='div' className='h-full my-[7px] items-center flex' style={{ borderRadius:'6px', background:'#FFD600', width:'auto', height:'fit-content', padding:'6px'}}>
                          <SvgIcon fill='#2A2A2A' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                      </Wrapper>
                      <Wrapper canvas id='random-id-595' is='div' className='flex flex-col px-8'>
                        <Wrapper canvas style={{display:'inline-block', fontSize:'22px', fontWeight:'700', fontFamily:'Inter', textAlign:'left', color:'#FFFFFF'}} id='random-id-30' is='div'>
                          <Text text='Dolor sit'/> 
                        </Wrapper>
                          
                        
                        <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Inter', textAlign:'left', color:'#FFFFFF'}} className='my-[7px]' id='random-id-31' is='div'>
                          <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.'/>
                        </Wrapper>
                        
                      </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-596' is='div' className='min-h-[117px] max-h-[100%]  min-w-[300px] flex flex-row  m-2 mx-auto' style={{flex:'30%'}}>
                      <Wrapper canvas id='random-id-597' is='div' className='h-full my-[7px] items-center flex' style={{ borderRadius:'6px', background:'#FFD600', width:'auto', height:'fit-content', padding:'6px'}}>
                          <SvgIcon fill='#2A2A2A' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                      </Wrapper>
                      <Wrapper canvas id='random-id-598' is='div' className='flex flex-col px-8'>
                        <Wrapper canvas id='random-id-599' is='div' style={{fontSize:'22px', fontWeight:'700', fontFamily:'Inter', textAlign:'left', color:'#FFFFFF'}}>
                          <Text text='Dolor sit'/>
                        </Wrapper>
                        
                          <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Inter', textAlign:'left', color:'#FFFFFF'}} className='my-[7px]' id='random-id-32' is='div'>
                            <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.'/>
                          </Wrapper>
                        
                      </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-600' is='div' className='min-h-[117px] max-h-[100%]  min-w-[300px] flex flex-row  m-2 mx-auto' style={{flex:'30%'}}>
                      <Wrapper canvas id='random-id-601' is='div' className='h-full my-[7px] items-center flex' style={{ borderRadius:'6px', background:'#FFD600', width:'auto', height:'fit-content', padding:'6px'}}>
                          <SvgIcon fill='#2A2A2A' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                      </Wrapper>
                      <Wrapper canvas id='random-id-602' is='div' className='flex flex-col px-8'>
                        <Wrapper canvas id='random-id-603' is='div' style={{fontSize:'22px', fontWeight:'700', fontFamily:'Inter', textAlign:'left', color:'#FFFFFF'}}>
                          <Text text='Dolor sit'/>
                        </Wrapper>
                        
                          <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Inter', textAlign:'left', color:'#FFFFFF'}} className='my-[7px]' id='random-id-33' is='div'>
                            <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.'/>
                          </Wrapper>
                        
                      </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-604' is='div' className='min-h-[117px] max-h-[100%]  min-w-[300px] flex flex-row  m-2 mx-auto' style={{flex:'30%'}}>
                      <Wrapper canvas id='random-id-605' is='div' className='h-full my-[7px] items-center flex' style={{ borderRadius:'6px', background:'#FFD600', width:'auto', height:'fit-content', padding:'6px'}}>
                          <SvgIcon fill='#2A2A2A' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                      </Wrapper>
                      <Wrapper canvas id='random-id-606' is='div' className='flex flex-col px-8'>
                        <Wrapper canvas id='random-id-607' is='div' style={{fontSize:'22px', fontWeight:'700', fontFamily:'Inter', textAlign:'left', color:'#FFFFFF'}}>
                          <Text text='Dolor sit'/>
                        </Wrapper>
                        
                          <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Inter', textAlign:'left', color:'#FFFFFF'}} className='my-[7px]' id='random-id-34' is='div'>
                            <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.'/>
                          </Wrapper>
                        
                      </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-608' is='div' className='min-h-[117px] max-h-[100%]  min-w-[300px] flex flex-row  m-2 mx-auto' style={{flex:'30%'}}>
                      <Wrapper canvas id='random-id-609' is='div' className='h-full my-[7px] items-center flex' style={{ borderRadius:'6px', background:'#FFD600', width:'auto', height:'fit-content', padding:'6px'}}>
                          <SvgIcon fill='#2A2A2A' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                      </Wrapper>
                      <Wrapper canvas id='random-id-610' is='div' className='flex flex-col px-8'>
                        <Wrapper canvas id='random-id-611' is='div' style={{fontSize:'22px', fontWeight:'700', fontFamily:'Inter', textAlign:'left', color:'#FFFFFF'}}>
                          <Text text='Dolor sit'/>
                        </Wrapper>
                        
                          <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Inter', textAlign:'left', color:'#FFFFFF'}} className='my-[7px]' id='random-id-35' is='div'>
                            <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.'/>
                          </Wrapper>
                        
                      </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-612' is='div' className='min-h-[117px] max-h-[100%]  min-w-[300px] flex flex-row  m-2 mx-auto' style={{flex:'30%'}}>
                      <Wrapper canvas id='random-id-613' is='div' className='h-full my-[7px] items-center flex' style={{ borderRadius:'6px', background:'#FFD600', width:'auto', height:'fit-content', padding:'6px'}}>
                          <SvgIcon fill='#2A2A2A' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                      </Wrapper>
                      <Wrapper canvas id='random-id-614' is='div' className='flex flex-col px-8'>
                        <Wrapper canvas id='random-id-615' is='div' style={{fontSize:'22px', fontWeight:'700', fontFamily:'Inter', textAlign:'left', color:'#FFFFFF'}}>
                          <Text text='Dolor sit'/>
                        </Wrapper>
                        
                          <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Inter', textAlign:'left', color:'#FFFFFF'}} className='my-[7px]' id='random-id-36' is='div'>
                            <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.'/>
                          </Wrapper>
                        
                      </Wrapper>
                    </Wrapper>
                  </Wrapper>
            </Wrapper>
          </Wrapper>
           
        </Wrapper></Element>
  </Element>,

        
      <Element canvas is={Parent} id='parentId1700' className="w-[100%] h-auto" >
  <Element canvas is={Parent} id='parentId1800' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-900' is='parent' className='w-[100%] h-full bg-[#F7FBFE] py-20'>
      <Wrapper canvas id='random-id-901' is='container' className='container'>
      <Wrapper canvas id='random-id-902' is='div' className='w-full h-full flex flex-col flex-wrap'>
                <Wrapper canvas style={{display:'inline-block', fontSize:'46px', fontWeight:'700', fontFamily:'Rubik'}} className='text-[#6064D2] mx-auto text-center' id='random-id-5500' is='div'>
                  <Text text='What makes our clients happy.'/>
                </Wrapper>
              <Wrapper canvas style={{display:'inline-block', fontSize:'22px', fontWeight:'400', fontFamily:'Inter', color:'#020E17'}} className='my-10 mx-auto text-center max-w-[500px] flex flex-wrap' id='random-id-5600' is='div'>
                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
              </Wrapper>
              <Wrapper canvas id='random-id-903' is='wrapper' className='w-full h-full flex flex-row flex-wrap justify-center'>
                <Wrapper canvas id='random-id-904' is='div' className='min-h-[117px] max-h-[100%]  min-w-[300px] flex flex-row  m-2 mx-auto' style={{flex:'30%'}}>
                  <Wrapper canvas id='random-id-905' is='div' className='h-full my-[7px] items-center flex' style={{border:'1px solid #B20312', borderRadius:'6px', background:'#B20312', width:'auto', height:'fit-content', padding:'6px'}}>
                    <SvgIcon fill='#FFFFFF' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                  </Wrapper>
                  <Wrapper canvas id='random-id-906' is='div' className='flex flex-col px-8'>
                    <Wrapper canvas style={{display:'inline-block', fontSize:'22px', fontWeight:'700', fontFamily:'Rubik', textAlign:'left', color:'#000000'}} id='random-id-5700' is='div'>
                      <Text text='Dolor sit'/> 
                    </Wrapper>
                      
                    <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', textAlign:'left', color:'#000000'}} className='my-[7px]' id='random-id-5800' is='div'>
                      <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.'/>
                    </Wrapper>
                  </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-907' is='div' className='min-h-[117px] max-h-[100%]  min-w-[300px] flex flex-row  m-2 mx-auto' style={{flex:'30%'}}>
                  <Wrapper canvas id='random-id-908' is='div' className='h-full my-[7px] items-center flex' style={{border:'1px solid #B20312', borderRadius:'6px', background:'#B20312', width:'auto', height:'fit-content', padding:'6px'}}>
                    <SvgIcon fill='#FFFFFF' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                  </Wrapper>
                  <Wrapper canvas id='random-id-909' is='div' className='flex flex-col px-8'>
                    <Wrapper canvas id='random-id-910' is='div' style={{fontSize:'22px', fontWeight:'700', fontFamily:'Rubik', textAlign:'left', color:'#000000'}}>
                      <Text text='Dolor sit'/>
                    </Wrapper>
                      <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', textAlign:'left', color:'#000000'}} className='my-[7px]' id='random-id-5900' is='div'>
                        <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.'/>
                      </Wrapper>
                  </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-911' is='div' className='min-h-[117px] max-h-[100%]  min-w-[300px] flex flex-row  m-2 mx-auto' style={{flex:'30%'}}>
                  <Wrapper canvas id='random-id-912' is='div' className='h-full my-[7px] items-center flex' style={{border:'1px solid #B20312', borderRadius:'6px', background:'#B20312', width:'auto', height:'fit-content', padding:'6px'}}>
                    <SvgIcon fill='#FFFFFF' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                  </Wrapper>
                  <Wrapper canvas id='random-id-913' is='div' className='flex flex-col px-8'>
                    <Wrapper canvas id='random-id-914' is='div' style={{fontSize:'22px', fontWeight:'700', fontFamily:'Rubik', textAlign:'left', color:'#000000'}}>
                      <Text text='Dolor sit'/>
                    </Wrapper>
                   
                      <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', textAlign:'left', color:'#000000'}} className='my-[7px]' id='random-id-60100' is='div'>
                        <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.'/>
                      </Wrapper>
                   
                  </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-915' is='div' className='min-h-[117px] max-h-[100%]  min-w-[300px] flex flex-row  m-2 mx-auto' style={{flex:'30%'}}>
                  <Wrapper canvas id='random-id-916' is='div' className='h-full my-[7px] items-center flex' style={{border:'1px solid #B20312', borderRadius:'6px', background:'#B20312', width:'auto', height:'fit-content', padding:'6px'}}>
                    <SvgIcon fill='#FFFFFF' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                  </Wrapper>
                  <Wrapper canvas id='random-id-917' is='div' className='flex flex-col px-8'>
                    <Wrapper canvas id='random-id-918' is='div' style={{fontSize:'22px', fontWeight:'700', fontFamily:'Rubik', textAlign:'left', color:'#000000'}}>
                      <Text text='Dolor sit'/>
                    </Wrapper>
                   
                      <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', textAlign:'left', color:'#000000'}} className='my-[7px]' id='random-id-60120' is='div'>
                        <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.'/>
                      </Wrapper>
                    </Wrapper>
          
                </Wrapper>
                <Wrapper canvas id='random-id-919' is='div' className='min-h-[117px] max-h-[100%]  min-w-[300px] flex flex-row  m-2 mx-auto' style={{flex:'30%'}}>
                  <Wrapper canvas id='random-id-920' is='div' className='h-full my-[7px] items-center flex' style={{border:'1px solid #B20312', borderRadius:'6px', background:'#B20312', width:'auto', height:'fit-content', padding:'6px'}}>
                    <SvgIcon fill='#FFFFFF' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                  </Wrapper>
                  <Wrapper canvas id='random-id-921' is='div' className='flex flex-col px-8'>
                    <Wrapper canvas id='random-id-922' is='div' style={{fontSize:'22px', fontWeight:'700', fontFamily:'Rubik', textAlign:'left', color:'#000000'}}>
                      <Text text='Dolor sit'/>
                    </Wrapper>
                   
                      <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', textAlign:'left', color:'#000000'}} className='my-[7px]' id='random-id-6201' is='div'>
                        <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.'/>
                      </Wrapper>
                
                  </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-923' is='div' className='min-h-[117px] max-h-[100%]  min-w-[300px] flex flex-row  m-2 mx-auto' style={{flex:'30%'}}>
                  <Wrapper canvas id='random-id-924' is='div' className='h-full my-[7px] items-center flex' style={{border:'1px solid #B20312', borderRadius:'6px', background:'#B20312', width:'auto', height:'fit-content', padding:'6px'}}>
                    <SvgIcon fill='#FFFFFF' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                  </Wrapper>
                  <Wrapper canvas id='random-id-925' is='div' className='flex flex-col px-8'>
                    <Wrapper canvas id='random-id-926' is='div' style={{fontSize:'22px', fontWeight:'700', fontFamily:'Rubik', textAlign:'left', color:'#000000'}}>
                      <Text text='Dolor sit'/>
                    </Wrapper>
                      <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', textAlign:'left', color:'#000000'}} className='my-[7px]' id='random-id-63100' is='div'>
                        <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.'/>
                      </Wrapper>
                  </Wrapper>
                </Wrapper>
              </Wrapper>
        </Wrapper>
      </Wrapper>
       
    </Wrapper></Element>
  </Element>,

       
<Element canvas is={Parent} id='parentId1900' className="w-[100%] h-auto" >
  <Element canvas is={Parent} id='parentId2000' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-1000' is='parent' className='w-[100%] h-full bg-[#FEFBF7] py-20'>
<Wrapper canvas id='random-id-1001' is='container' className='container'>
<Wrapper canvas id='random-id-1002' is='div' className='w-full h-full flex flex-col flex-wrap'>
          <Wrapper canvas style={{display:'inline-block', fontSize:'46px', fontWeight:'400', fontFamily:'Ruluko'}} className='text-[#A25738] mx-auto text-center' id='random-id-5015' is='div'>
            <Text text='What makes our clients happy.'/>
          </Wrapper>
        <Wrapper canvas style={{display:'inline-block', fontSize:'22px', fontWeight:'400', fontFamily:'Inter', color:'#020E17'}} className='my-10 mx-auto text-center max-w-[500px] flex flex-wrap' id='random-id-5016' is='div'>
          <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
        </Wrapper>
        <Wrapper canvas id='random-id-1003' is='wrapper' className='w-full h-full flex flex-row flex-wrap justify-center'>
          <Wrapper canvas id='random-id-1004' is='div' className='min-h-[117px] max-h-[100%]  min-w-[300px] flex flex-row  m-2 mx-auto' style={{flex:'30%'}}>
            <Wrapper canvas id='random-id-1005' is='div' className='h-full my-[7px] items-center flex' style={{borderRadius:'8px', background:'#A25738', width:'auto', height:'fit-content', padding:'6px'}}>
              <SvgIcon fill='#FFFFFF' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

            </Wrapper>
            <Wrapper canvas id='random-id-1006' is='div' className='flex flex-col px-8'>
              <Wrapper canvas style={{display:'inline-block', fontSize:'22px', fontWeight:'700', fontFamily:'Rubik', textAlign:'left', color:'#000000'}} id='random-id-5017' is='div'>
                <Text text='Dolor sit'/> 
              </Wrapper>
                
              <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', textAlign:'left', color:'#000000'}} className='my-[7px]' id='random-id-5018' is='div'>
                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.'/>
              </Wrapper>
            </Wrapper>
          </Wrapper>
          <Wrapper canvas id='random-id-1007' is='div' className='min-h-[117px] max-h-[100%]  min-w-[300px] flex flex-row  m-2 mx-auto' style={{flex:'30%'}}>
            <Wrapper canvas id='random-id-1008' is='div' className='h-full my-[7px] items-center flex' style={{borderRadius:'8px', background:'#A25738', width:'auto', height:'fit-content', padding:'6px'}}>
              <SvgIcon fill='#FFFFFF' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

            </Wrapper>
            <Wrapper canvas id='random-id-1009' is='div' className='flex flex-col px-8'>
              <Wrapper canvas id='random-id-1010' is='div' style={{fontSize:'22px', fontWeight:'700', fontFamily:'Rubik', textAlign:'left', color:'#000000'}}>
                <Text text='Dolor sit'/>
              </Wrapper>
                <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', textAlign:'left', color:'#000000'}} className='my-[7px]' id='random-id-5019' is='div'>
                  <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.'/>
                </Wrapper>
            </Wrapper>
          </Wrapper>
          <Wrapper canvas id='random-id-1011' is='div' className='min-h-[117px] max-h-[100%]  min-w-[300px] flex flex-row  m-2 mx-auto' style={{flex:'30%'}}>
            <Wrapper canvas id='random-id-1012' is='div' className='h-full my-[7px] items-center flex' style={{borderRadius:'8px', background:'#A25738', width:'auto', height:'fit-content', padding:'6px'}}>
              <SvgIcon fill='#FFFFFF' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

            </Wrapper>
            <Wrapper canvas id='random-id-1013' is='div' className='flex flex-col px-8'>
              <Wrapper canvas id='random-id-1014' is='div' style={{fontSize:'22px', fontWeight:'700', fontFamily:'Rubik', textAlign:'left', color:'#000000'}}>
                <Text text='Dolor sit'/>
              </Wrapper>
             
                <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', textAlign:'left', color:'#000000'}} className='my-[7px]' id='random-id-5220' is='div'>
                  <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.'/>
                </Wrapper>
             
            </Wrapper>
          </Wrapper>
          <Wrapper canvas id='random-id-1015' is='div' className='min-h-[117px] max-h-[100%]  min-w-[300px] flex flex-row  m-2 mx-auto' style={{flex:'30%'}}>
            <Wrapper canvas id='random-id-1016' is='div' className='h-full my-[7px] items-center flex' style={{borderRadius:'8px', background:'#A25738', width:'auto', height:'fit-content', padding:'6px'}}>
              <SvgIcon fill='#FFFFFF' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

            </Wrapper>
            <Wrapper canvas id='random-id-1017' is='div' className='flex flex-col px-8'>
              <Wrapper canvas id='random-id-1018' is='div' style={{fontSize:'22px', fontWeight:'700', fontFamily:'Rubik', textAlign:'left', color:'#000000'}}>
                <Text text='Dolor sit'/>
              </Wrapper>
             
                <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', textAlign:'left', color:'#000000'}} className='my-[7px]' id='random-id-5221' is='div'>
                  <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.'/>
                </Wrapper>
              </Wrapper>
    
          </Wrapper>
          <Wrapper canvas id='random-id-1019' is='div' className='min-h-[117px] max-h-[100%]  min-w-[300px] flex flex-row  m-2 mx-auto' style={{flex:'30%'}}>
            <Wrapper canvas id='random-id-1020' is='div' className='h-full my-[7px] items-center flex' style={{borderRadius:'8px', background:'#A25738', width:'auto', height:'fit-content', padding:'6px'}}>
              <SvgIcon fill='#FFFFFF' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

            </Wrapper>
            <Wrapper canvas id='random-id-1021' is='div' className='flex flex-col px-8'>
              <Wrapper canvas id='random-id-1022' is='div' style={{fontSize:'22px', fontWeight:'700', fontFamily:'Rubik', textAlign:'left', color:'#000000'}}>
                <Text text='Dolor sit'/>
              </Wrapper>
             
                <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', textAlign:'left', color:'#000000'}} className='my-[7px]' id='random-id-5222' is='div'>
                  <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.'/>
                </Wrapper>
          
            </Wrapper>
          </Wrapper>
          <Wrapper canvas id='random-id-1023' is='div' className='min-h-[117px] max-h-[100%]  min-w-[300px] flex flex-row  m-2 mx-auto' style={{flex:'30%'}}>
            <Wrapper canvas id='random-id-1024' is='div' className='h-full my-[7px] items-center flex' style={{borderRadius:'8px', background:'#A25738', width:'auto', height:'fit-content', padding:'6px'}}>
              <SvgIcon fill='#FFFFFF' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

            </Wrapper>
            <Wrapper canvas id='random-id-1025' is='div' className='flex flex-col px-8'>
              <Wrapper canvas id='random-id-1026' is='div' style={{fontSize:'22px', fontWeight:'700', fontFamily:'Rubik', textAlign:'left', color:'#000000'}}>
                <Text text='Dolor sit'/>
              </Wrapper>
                <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', textAlign:'left', color:'#000000'}} className='my-[7px]' id='random-id-5223' is='div'>
                  <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.'/>
                </Wrapper>
            </Wrapper>
          </Wrapper>
        </Wrapper>
  </Wrapper>
</Wrapper>
 
</Wrapper></Element>
  </Element>,
     
    ]

    
const [sel, setSel] = useState(selected)

function makeOdd(number) {
  setProp((prop) => {
    setSel(100);
  }, 1000);

  setTimeout(() => {
    setProp((prop) => {
      setSel(number)
    }, 1000);
  }, 100);


  
}

useEffect(() => {
if(selected || selected >= 0) {
  makeOdd(selected);
}
},[selected])


return (
  <div style={{ padding:enabled? '10px' : "0px", display:"flex", justifyContent:"center", alignItems:"center", width:'100%', height:'100%'}} ref={connect}>
  {sel == 100 ? (
          <div style={{height:"85vh", display:"flex", justifyContent:"center", alignItems:"center"}}>
          <BounceLoader
                  size={100}
                  color={"white"}
                  loading={true}
                />
          </div>
         ) : (
          styles[sel]
         )} 
</div>
)
}

Sections_1.craft = {
displayName: "Sections 1",
props: {
   selected: 1, // here is the default value of the selected style
   length:9,
isBlock: true
  },
  rules: {
    canDrag: () => true,
    canDrop: () => true,
  },
  related: {
    toolbar: NewSectionsSettings,
  },
};
