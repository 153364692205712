/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, { useEffect, useState } from 'react';
import { Element, useEditor, useNode } from '@craftjs/core';
import { Text } from '../../basic/Text';
import{ NewFeaturesSettings} from './newFeaturesSetting'
import { TextField, Typography } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SettingsIcon from '@mui/icons-material/Settings';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import HomeIcon from '@mui/icons-material/Home';
import { ImageC } from '../../basic/Image';
import { Parent } from '../../Parent/index.js';
import { Wrapper } from '../../wrapper/index.js';
import { BounceLoader } from 'react-spinners';
import { SvgIcon } from '../../basic/Svg';

export const Features_2 = ({selected}) => {
 
    const { 
        actions: { setProp },
          connectors: {
            connect
          } 
      } = useNode();
    
      
      const {
        enabled,
      } = useEditor((state, query) => ({
        enabled: state.options.enabled,
     
      }));

    const styles = [
       <Element canvas is={Parent} id='parentId100' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId200' className="w-[100%] h-auto" row={true} > <Wrapper canvas id='random-id-400' is='parent' className='w-[100%] h-full bg-[#FFFFFF]' style={{padding:'0px 4em 3em 4em'}}>
            <Wrapper canvas id='random-id-401' is='div' classNamew-='w-[100%] h-full' style={{background:'#F7F7F9', borderRadius:'0px 0px 30px 30px'}}>
                <Wrapper canvas id='random-id-402' is='container' className='container flex flex-row flex-wrap justify-center p-7'>
                    <Wrapper canvas id='random-id-403' is='div' className='w-[22%] min-w-[265px] pt-4 h-full my-auto mx-auto'>
                        <Wrapper canvas id='random-id-404' is='div' className='w-full h-full flex flex-col mx-auto pb-2 px-4 mb-2' style={{borderBottom:'3px solid #D2D4D8', borderRight:'3px solid #D2D4D8', borderRadius:'8px'}}>
                            <Wrapper canvas id='random-id-405' is='div' className='flex flex-row flex-nowrap' style={{fontSize:'58px', fontWeight:'bold', fontFamily:'Prompt'}}>
                                    <Wrapper canvas style={{display:'inline-block'}} className='text-[#2479FD]' id='random-id-1' is='div'>
                                        <Text text='24.'/>
                                    </Wrapper>
                                    <Wrapper canvas style={{display:'inline-block'}} className='text-[#01060E] overflow-hidden my-auto' id='random-id-2' is='div'>
                                        <Text text='000'/>
                                    </Wrapper>
                            </Wrapper>
                                    <Wrapper canvas style={{display:'inline-block', fontSize:'28px', fontWeight:'400px', fontFamily:'Prompt', color:'#01060E'}} id='random-id-3' is='div'>
                                        <Text text='Customers trust to our service'/>
                                    </Wrapper>
                        </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-406' is='parent' className='w-[70%] min-w-[550px] pt-4 h-full flex flex-row flex-wrap mx-auto justify-center'>
                        <Wrapper canvas id='random-id-407' is='parent' className='w-[50%] min-w-[295px] h-full flex flex-row flex-wrap justify-center'>
                            <Wrapper canvas id='random-id-408' is='parent' className='w-auto h-auto mx-4 flex align-items-center w-[60px] h-[60px]'>
                            <SvgIcon fill='#FFB800' viewBox='0 0 24 24' path='M21 13v10h-6v-6h-6v6h-6v-10h-3l12-12 12 12h-3zm-1-5.907v-5.093h-3v2.093l3 3z' />
                            </Wrapper>
                                    <Wrapper canvas className='w-[40%] min-w-[300px] flex align-items-center py-4' style={{display:'inline-block', fontSize:'16px', fontWeight:'400px', fontFamily:'Prompt', color:'#000000'}} id='random-id-4' is='div'>
                                        <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore'/>
                                    </Wrapper>
                        </Wrapper>
                        <Wrapper canvas id='random-id-409' is='parent' className='w-[50%] min-w-[295px] h-full flex flex-row flex-wrap justify-center'>
                        <Wrapper canvas id='random-id-410' is='parent' className='w-auto h-auto mx-4 flex align-items-center w-[60px] h-[60px]'>
                        <SvgIcon fill='#FFB800' viewBox='0 0 56 56' path='M32.918 3.675c-1.446-4.9-8.39-4.9-9.835 0l-.35 1.19a5.124 5.124 0 0 1-7.368 3.052l-1.085-.595C9.79 4.879 4.879 9.79 7.326 14.277l.591 1.088c1.561 2.87.08 6.443-3.052 7.368l-1.19.35c-4.9 1.445-4.9 8.389 0 9.834l1.19.35a5.124 5.124 0 0 1 3.052 7.368l-.595 1.085c-2.443 4.49 2.468 9.401 6.955 6.955l1.088-.592a5.123 5.123 0 0 1 7.368 3.052l.35 1.19c1.445 4.9 8.389 4.9 9.834 0l.35-1.19a5.124 5.124 0 0 1 7.368-3.052l1.085.595c4.49 2.443 9.401-2.467 6.955-6.954l-.592-1.089a5.123 5.123 0 0 1 3.052-7.367l1.19-.35c4.9-1.446 4.9-8.39 0-9.835l-1.19-.35a5.124 5.124 0 0 1-3.052-7.368l.595-1.085c2.443-4.49-2.467-9.401-6.954-6.954l-1.089.591a5.124 5.124 0 0 1-7.367-3.052zM28 38.255a10.255 10.255 0 1 1 7.249-17.507A10.252 10.252 0 0 1 28 38.248z' />
                            </Wrapper>
                                    <Wrapper canvas className='w-[40%] min-w-[300px] flex align-items-center py-4' style={{display:'inline-block', fontSize:'16px', fontWeight:'400px', fontFamily:'Prompt', color:'#000000'}} id='random-id-5' is='div'>
                                        <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore'/>
                                    </Wrapper>
                        </Wrapper>
                        <Wrapper canvas id='random-id-411' is='parent' className='w-[50%] min-w-[295px] h-full flex flex-row flex-wrap justify-center'>
                        <Wrapper canvas id='random-id-412' is='parent' className='w-auto h-auto mx-4 flex align-items-center w-[60px] h-[60px]'>
                        <SvgIcon fill='#FFB800' viewBox='0 0 56 56' path='M28 56a7 7 0 0 0 7-7H21a7 7 0 0 0 7 7m3.483-52.153a3.5 3.5 0 1 0-6.966 0A17.51 17.51 0 0 0 10.5 21c0 3.843-1.75 21-7 24.5h49c-5.25-3.5-7-20.657-7-24.5 0-8.47-6.02-15.54-14.017-17.153' />
                            </Wrapper>
                                    <Wrapper canvas className='w-[40%] min-w-[300px] flex align-items-center py-4' style={{display:'inline-block', fontSize:'16px', fontWeight:'400px', fontFamily:'Prompt', color:'#000000'}} id='random-id-6' is='div'>
                                        <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore'/>
                                    </Wrapper>
                        </Wrapper>
                        <Wrapper canvas id='random-id-413' is='parent' className='w-[50%] min-w-[295px] h-full flex flex-row flex-wrap justify-center'>
                        <Wrapper canvas id='random-id-414' is='parent' className='w-auto h-auto mx-4 flex align-items-center w-[60px] h-[60px]'>
                        <SvgIcon fill='#FFB800' viewBox='0 0 56 56' path='M10.5 8.75a8.75 8.75 0 0 1 17.5 0 8.75 8.75 0 0 1 17.5 0v.021c0 .245 0 .945-.133 1.729H52.5A3.5 3.5 0 0 1 56 14v3.5a3.5 3.5 0 0 1-3.5 3.5h-49A3.5 3.5 0 0 1 0 17.5V14a3.5 3.5 0 0 1 3.5-3.5h7.133a10.4 10.4 0 0 1-.133-1.729zm3.738 1.75H24.5V8.75a5.25 5.25 0 0 0-10.5 0c0 .297.007.959.158 1.505q.03.125.08.245m17.262 0h10.262q.049-.12.08-.245c.151-.546.158-1.208.158-1.505a5.25 5.25 0 1 0-10.5 0zm21 14v26.25A5.25 5.25 0 0 1 47.25 56H31.5V24.5zM8.75 56a5.25 5.25 0 0 1-5.25-5.25V24.5h21V56z' />
                            </Wrapper>
                                <Wrapper canvas className='w-[40%] min-w-[300px] flex align-items-center py-4' style={{display:'inline-block', fontSize:'16px', fontWeight:'400px', fontFamily:'Prompt', color:'#000000'}} id='random-id-7' is='div'>
                                        <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore'/>
                                    </Wrapper>
                        </Wrapper>
                    </Wrapper>
                </Wrapper>
            </Wrapper>
        </Wrapper></Element>
        </Element>
        ,
        <Element canvas is={Parent} id='parentId300' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId400' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-415' is='parent' className='w-[100%] h-full bg-[#FFFFFF]' style={{padding:'0px 4em 3em 4em'}}>
            <Wrapper canvas id='random-id-416' is='div' classNamew-='w-[100%] h-full' style={{background:'#FDFDF3', borderRadius:'0px 0px 30px 30px'}}>
                <Wrapper canvas id='random-id-417' is='container' className='container flex flex-row flex-wrap justify-center p-7'>
                    <Wrapper canvas id='random-id-418' is='div' className='w-[22%] min-w-[265px] pt-4 my-auto h-full my-auto mx-auto'>
                        <Wrapper canvas id='random-id-419' is='div' className='w-full h-full flex flex-col mx-auto pb-2 px-4 mb-2' style={{borderBottom:'3px solid #F6FA70', borderRight:'3px solid #F6FA70', borderRadius:'8px'}}>
                            <Wrapper canvas id='random-id-420' is='div' className='flex flex-row flex-nowrap' style={{fontSize:'58px', fontWeight:'bold', fontFamily:'Prompt'}}>
                                    <Wrapper canvas style={{display:'inline-block'}} className='text-[#FF0060]' id='random-id-8' is='div'>
                                        <Text text='24.'/>
                                    </Wrapper>
                                <Wrapper canvas style={{display:'inline-block'}} className='text-[#01060E] overflow-hidden my-auto' id='random-id-9' is='div'>
                                        <Text text='000'/>
                                    </Wrapper>
                            </Wrapper>
                                <Wrapper canvas style={{display:'inline-block', fontSize:'28px', fontWeight:'400px', fontFamily:'Prompt', color:'#01060E'}} id='random-id-10' is='div'>
                                    <Text text='Customers trust to our service'/>
                                </Wrapper>
                        </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-421' is='parent' className='w-[70%] min-w-[550px] mt-4 h-full flex flex-row flex-wrap mx-auto justify-center'>
                        <Wrapper canvas id='random-id-422' is='parent'v className='w-[50%] min-w-[295px] h-full flex flex-row flex-wrap justify-center'>
                        <Wrapper canvas id='random-id-423' is='parent' className='w-auto h-auto mx-4 flex align-items-center w-[60px] h-[60px]'>
                        <SvgIcon fill='#FF0060' viewBox='0 0 24 24' path='M21 13v10h-6v-6h-6v6h-6v-10h-3l12-12 12 12h-3zm-1-5.907v-5.093h-3v2.093l3 3z' />
                            </Wrapper>
                           
                                <Wrapper canvas className='w-[40%] min-w-[300px] flex align-items-center py-4' style={{display:'inline-block', fontSize:'16px', fontWeight:'400px', fontFamily:'Prompt'}} id='random-id-11' is='div'>
                                        <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore'/>
                                    </Wrapper>
                           
                        </Wrapper>
                        <Wrapper canvas id='random-id-424' is='parent' className='w-[50%] min-w-[295px] h-full flex flex-row flex-wrap justify-center'>
                        <Wrapper canvas id='random-id-425' is='parent' className='w-auto h-auto mx-4 flex align-items-center w-[60px] h-[60px]'>
                        <SvgIcon fill='#FF0060' viewBox='0 0 56 56' path='M32.918 3.675c-1.446-4.9-8.39-4.9-9.835 0l-.35 1.19a5.124 5.124 0 0 1-7.368 3.052l-1.085-.595C9.79 4.879 4.879 9.79 7.326 14.277l.591 1.088c1.561 2.87.08 6.443-3.052 7.368l-1.19.35c-4.9 1.445-4.9 8.389 0 9.834l1.19.35a5.124 5.124 0 0 1 3.052 7.368l-.595 1.085c-2.443 4.49 2.468 9.401 6.955 6.955l1.088-.592a5.123 5.123 0 0 1 7.368 3.052l.35 1.19c1.445 4.9 8.389 4.9 9.834 0l.35-1.19a5.124 5.124 0 0 1 7.368-3.052l1.085.595c4.49 2.443 9.401-2.467 6.955-6.954l-.592-1.089a5.123 5.123 0 0 1 3.052-7.367l1.19-.35c4.9-1.446 4.9-8.39 0-9.835l-1.19-.35a5.124 5.124 0 0 1-3.052-7.368l.595-1.085c2.443-4.49-2.467-9.401-6.954-6.954l-1.089.591a5.124 5.124 0 0 1-7.367-3.052zM28 38.255a10.255 10.255 0 1 1 7.249-17.507A10.252 10.252 0 0 1 28 38.248z' />
                            </Wrapper>
                           
                                <Wrapper canvas className='w-[40%] min-w-[300px] flex align-items-center py-4' style={{display:'inline-block', fontSize:'16px', fontWeight:'400px', fontFamily:'Prompt'}} id='random-id-12' is='div'>
                                        <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore'/>
                                    </Wrapper>
                           
                        </Wrapper>
                        <Wrapper canvas id='random-id-426' is='parent' className='w-[50%] min-w-[295px] h-full flex flex-row flex-wrap justify-center'>
                        <Wrapper canvas id='random-id-427' is='parent' className='w-auto h-auto mx-4 flex align-items-center w-[60px] h-[60px]'>
                        <SvgIcon fill='#FF0060' viewBox='0 0 56 56' path='M28 56a7 7 0 0 0 7-7H21a7 7 0 0 0 7 7m3.483-52.153a3.5 3.5 0 1 0-6.966 0A17.51 17.51 0 0 0 10.5 21c0 3.843-1.75 21-7 24.5h49c-5.25-3.5-7-20.657-7-24.5 0-8.47-6.02-15.54-14.017-17.153' />
                            </Wrapper>
                           
                                <Wrapper canvas className='w-[40%] min-w-[300px] flex align-items-center py-4' style={{display:'inline-block', fontSize:'16px', fontWeight:'400px', fontFamily:'Prompt'}} id='random-id-13' is='div'>
                                        <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore'/>
                                    </Wrapper>
                           
                        </Wrapper>
                        <Wrapper canvas id='random-id-428' is='parent' className='w-[50%] min-w-[295px] h-full flex flex-row flex-wrap justify-center'>
                        <Wrapper canvas id='random-id-429' is='parent' className='w-auto h-auto mx-4 flex align-items-center w-[60px] h-[60px]'>
                        <SvgIcon fill='#FF0060' viewBox='0 0 56 56' path='M10.5 8.75a8.75 8.75 0 0 1 17.5 0 8.75 8.75 0 0 1 17.5 0v.021c0 .245 0 .945-.133 1.729H52.5A3.5 3.5 0 0 1 56 14v3.5a3.5 3.5 0 0 1-3.5 3.5h-49A3.5 3.5 0 0 1 0 17.5V14a3.5 3.5 0 0 1 3.5-3.5h7.133a10.4 10.4 0 0 1-.133-1.729zm3.738 1.75H24.5V8.75a5.25 5.25 0 0 0-10.5 0c0 .297.007.959.158 1.505q.03.125.08.245m17.262 0h10.262q.049-.12.08-.245c.151-.546.158-1.208.158-1.505a5.25 5.25 0 1 0-10.5 0zm21 14v26.25A5.25 5.25 0 0 1 47.25 56H31.5V24.5zM8.75 56a5.25 5.25 0 0 1-5.25-5.25V24.5h21V56z' />
                            </Wrapper>
                           
                                <Wrapper canvas className='w-[40%] min-w-[300px] flex align-items-center py-4' style={{display:'inline-block', fontSize:'16px', fontWeight:'400px', fontFamily:'Prompt'}} id='random-id-14' is='div'>
                                        <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore'/>
                                    </Wrapper>
                           
                        </Wrapper>
                    </Wrapper>
                </Wrapper>
            </Wrapper>
        </Wrapper></Element>
        </Element>
        ,
        
        <Element canvas is={Parent} id='parentId500' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId600' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-430' is='parent' className='w-[100%] h-full bg-[#FFFFFF]' style={{padding:'0px 4em 3em 4em'}}>
            <Wrapper canvas id='random-id-431' is='parent' classNamew-='w-[100%] h-full' style={{background:'#FDF4F4', borderRadius:'0px 0px 30px 30px'}}>
                <Wrapper canvas id='random-id-432' is='parent' className='container flex flex-row flex-wrap justify-center p-7'>
                    <Wrapper canvas id='random-id-433' is='parent' className='w-[22%] min-w-[265px] pt-4 my-auto h-full my-auto mx-auto'>
                        <Wrapper canvas id='random-id-434' is='parent' className='w-full h-full flex flex-col mx-auto pb-2 px-4 mb-2' style={{borderBottom:'3px solid #FFB7B7', borderRight:'3px solid #FFB7B7', borderRadius:'8px'}}>
                            <Wrapper canvas id='random-id-435' is='parent' className='flex flex-row flex-nowrap' style={{fontSize:'58px', fontWeight:'bold', fontFamily:'Almarai'}}>
                                    <Wrapper canvas style={{display:'inline-block'}} className='text-[#F31559]' id='random-id-15' is='div'>
                                        <Text text='24.'/>
                                    </Wrapper>
                                <Wrapper canvas style={{display:'inline-block'}} className='text-[#01060E] overflow-hidden my-auto' id='random-id-16' is='div'>
                                        <Text text='000'/>
                                    </Wrapper>
                            </Wrapper>
                                <Wrapper canvas style={{display:'inline-block', fontSize:'28px', fontWeight:'400px', fontFamily:'Almarai', color:'#01060E'}}  id='random-id-17' is='div'>
                                    <Text text='Customers trust to our service'/>
                                </Wrapper>
                        </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-436' is='parent' className='w-[70%] min-w-[550px] mt-4 h-full flex flex-row flex-wrap mx-auto justify-center'>
                        <Wrapper canvas id='random-id-437' is='parent' className='w-[50%] min-w-[295px] h-full flex flex-row flex-wrap justify-center'>
                        <Wrapper canvas id='random-id-438' is='parent' className='w-auto h-auto mx-4 flex align-items-center w-[60px] h-[60px]'>
                        <SvgIcon fill='#F31559' viewBox='0 0 24 24' path='M21 13v10h-6v-6h-6v6h-6v-10h-3l12-12 12 12h-3zm-1-5.907v-5.093h-3v2.093l3 3z' />
                            </Wrapper>
                            
                                <Wrapper canvas className='w-[40%] min-w-[300px] flex align-items-center py-4' style={{display:'inline-block', fontSize:'16px', fontWeight:'400px', fontFamily:'Almarai'}} id='random-id-18' is='div'>
                                        <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore'/>
                                    </Wrapper>
                          
                        </Wrapper>
                        <Wrapper canvas id='random-id-439' is='parent' className='w-[50%] min-w-[295px] h-full flex flex-row flex-wrap justify-center'>
                        <Wrapper canvas id='random-id-440' is='parent' className='w-auto h-auto mx-4 flex align-items-center w-[60px] h-[60px]'>
                        <SvgIcon fill='#F31559' viewBox='0 0 56 56' path='M32.918 3.675c-1.446-4.9-8.39-4.9-9.835 0l-.35 1.19a5.124 5.124 0 0 1-7.368 3.052l-1.085-.595C9.79 4.879 4.879 9.79 7.326 14.277l.591 1.088c1.561 2.87.08 6.443-3.052 7.368l-1.19.35c-4.9 1.445-4.9 8.389 0 9.834l1.19.35a5.124 5.124 0 0 1 3.052 7.368l-.595 1.085c-2.443 4.49 2.468 9.401 6.955 6.955l1.088-.592a5.123 5.123 0 0 1 7.368 3.052l.35 1.19c1.445 4.9 8.389 4.9 9.834 0l.35-1.19a5.124 5.124 0 0 1 7.368-3.052l1.085.595c4.49 2.443 9.401-2.467 6.955-6.954l-.592-1.089a5.123 5.123 0 0 1 3.052-7.367l1.19-.35c4.9-1.446 4.9-8.39 0-9.835l-1.19-.35a5.124 5.124 0 0 1-3.052-7.368l.595-1.085c2.443-4.49-2.467-9.401-6.954-6.954l-1.089.591a5.124 5.124 0 0 1-7.367-3.052zM28 38.255a10.255 10.255 0 1 1 7.249-17.507A10.252 10.252 0 0 1 28 38.248z' />
                            </Wrapper>
                            
                                <Wrapper canvas className='w-[40%] min-w-[300px] flex align-items-center py-4' style={{display:'inline-block', fontSize:'16px', fontWeight:'400px', fontFamily:'Almarai'}} id='random-id-19' is='div'>
                                        <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore'/>
                                    </Wrapper>
                          
                        </Wrapper>
                        <Wrapper canvas id='random-id-441' is='parent' className='w-[50%] min-w-[295px] h-full flex flex-row flex-wrap justify-center'>
                        <Wrapper canvas id='random-id-442' is='parent' className='w-auto h-auto mx-4 flex align-items-center w-[60px] h-[60px]'>
                        <SvgIcon fill='#F31559' viewBox='0 0 56 56' path='M28 56a7 7 0 0 0 7-7H21a7 7 0 0 0 7 7m3.483-52.153a3.5 3.5 0 1 0-6.966 0A17.51 17.51 0 0 0 10.5 21c0 3.843-1.75 21-7 24.5h49c-5.25-3.5-7-20.657-7-24.5 0-8.47-6.02-15.54-14.017-17.153' />
                            </Wrapper>
                            
                                <Wrapper canvas className='w-[40%] min-w-[300px] flex align-items-center py-4' style={{display:'inline-block', fontSize:'16px', fontWeight:'400px', fontFamily:'Almarai'}} id='random-id-20' is='div'>
                                        <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore'/>
                                    </Wrapper>
                          
                        </Wrapper>
                        <Wrapper canvas id='random-id-443' is='parent' className='w-[50%] min-w-[295px] h-full flex flex-row flex-wrap justify-center'>
                        <Wrapper canvas id='random-id-444' is='parent' className='w-auto h-auto mx-4 flex align-items-center w-[60px] h-[60px]'>
                        <SvgIcon fill='#F31559' viewBox='0 0 56 56' path='M10.5 8.75a8.75 8.75 0 0 1 17.5 0 8.75 8.75 0 0 1 17.5 0v.021c0 .245 0 .945-.133 1.729H52.5A3.5 3.5 0 0 1 56 14v3.5a3.5 3.5 0 0 1-3.5 3.5h-49A3.5 3.5 0 0 1 0 17.5V14a3.5 3.5 0 0 1 3.5-3.5h7.133a10.4 10.4 0 0 1-.133-1.729zm3.738 1.75H24.5V8.75a5.25 5.25 0 0 0-10.5 0c0 .297.007.959.158 1.505q.03.125.08.245m17.262 0h10.262q.049-.12.08-.245c.151-.546.158-1.208.158-1.505a5.25 5.25 0 1 0-10.5 0zm21 14v26.25A5.25 5.25 0 0 1 47.25 56H31.5V24.5zM8.75 56a5.25 5.25 0 0 1-5.25-5.25V24.5h21V56z' />
                            </Wrapper>
                                <Wrapper canvas className='w-[40%] min-w-[300px] flex align-items-center py-4' style={{display:'inline-block', fontSize:'16px', fontWeight:'400px', fontFamily:'Almarai'}} id='random-id-21' is='div'>
                                        <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore'/>
                                    </Wrapper>
                          
                        </Wrapper>
                    </Wrapper>
                </Wrapper>
            </Wrapper>
        </Wrapper></Element>
        </Element>,


        <Element canvas is={Parent} id='parentId700' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId800' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-445' is='parent' className='w-[100%] h-full bg-[#FFFFFF]' style={{padding:'0px 4em 3em 4em'}}>
        <Wrapper canvas id='random-id-446' is='parent' classNamew-='w-[100%] h-full' style={{background:'#F6F2FA', borderRadius:'0px 0px 30px 30px'}}>
            <Wrapper canvas id='random-id-447' is='parent' className='container flex flex-row flex-wrap justify-center p-7'>
                <Wrapper canvas id='random-id-448' is='parent' className='w-[22%] min-w-[265px] pt-4 my-auto h-full my-auto mx-auto'>
                    <Wrapper canvas id='random-id-449' is='parent' className='w-full h-full flex flex-col mx-auto pb-2 px-4 mb-2' style={{borderBottom:'3px solid #DAD9DB', borderRight:'3px solid #DAD9DB', borderRadius:'8px'}}>
                        <Wrapper canvas id='random-id-450' is='parent' className='flex flex-row flex-nowrap' style={{fontSize:'58px', fontWeight:'bold', fontFamily:'Chivo'}}>
                                <Wrapper canvas style={{display:'inline-block'}} className='text-[#D4ADFC]' id='random-id-22' is='div'>
                                        <Text text='24.'/>
                                    </Wrapper>
                            <Wrapper canvas style={{display:'inline-block'}} className='text-[#01060E] overflow-hidden my-auto' id='random-id-23' is='div'>
                                        <Text text='000'/>
                                    </Wrapper>
                        </Wrapper>
                            <Wrapper canvas style={{display:'inline-block', fontSize:'28px', fontWeight:'400px', fontFamily:'Chivo', color:'#01060E'}}  id='random-id-24' is='div'>
                                <Text text='Customers trust to our service'/>
                            </Wrapper>
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-451' is='parent' className='w-[70%] min-w-[550px] mt-4 h-full flex flex-row flex-wrap mx-auto justify-center'>
                    <Wrapper canvas id='random-id-452' is='parent' className='w-[50%] min-w-[295px] h-full flex flex-row flex-wrap justify-center'>
                    <Wrapper canvas id='random-id-453' is='parent' className='w-auto h-auto mx-4 flex align-items-center w-[60px] h-[60px]'>
                    <SvgIcon fill='#D4ADFC' viewBox='0 0 24 24' path='M21 13v10h-6v-6h-6v6h-6v-10h-3l12-12 12 12h-3zm-1-5.907v-5.093h-3v2.093l3 3z' />
                            </Wrapper>
                       
                            <Wrapper canvas className='w-[40%] min-w-[300px] flex align-items-center py-4' style={{display:'inline-block', fontSize:'16px', fontWeight:'400px', fontFamily:'Chivo'}} id='random-id-25' is='div'>
                                        <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore'/>
                                    </Wrapper>
                      
                    </Wrapper>
                    <Wrapper canvas id='random-id-454' is='parent' className='w-[50%] min-w-[295px] h-full flex flex-row flex-wrap justify-center'>
                    <Wrapper canvas id='random-id-455' is='parent' className='w-auto h-auto mx-4 flex align-items-center w-[60px] h-[60px]'>
                    <SvgIcon fill='#D4ADFC' viewBox='0 0 56 56' path='M32.918 3.675c-1.446-4.9-8.39-4.9-9.835 0l-.35 1.19a5.124 5.124 0 0 1-7.368 3.052l-1.085-.595C9.79 4.879 4.879 9.79 7.326 14.277l.591 1.088c1.561 2.87.08 6.443-3.052 7.368l-1.19.35c-4.9 1.445-4.9 8.389 0 9.834l1.19.35a5.124 5.124 0 0 1 3.052 7.368l-.595 1.085c-2.443 4.49 2.468 9.401 6.955 6.955l1.088-.592a5.123 5.123 0 0 1 7.368 3.052l.35 1.19c1.445 4.9 8.389 4.9 9.834 0l.35-1.19a5.124 5.124 0 0 1 7.368-3.052l1.085.595c4.49 2.443 9.401-2.467 6.955-6.954l-.592-1.089a5.123 5.123 0 0 1 3.052-7.367l1.19-.35c4.9-1.446 4.9-8.39 0-9.835l-1.19-.35a5.124 5.124 0 0 1-3.052-7.368l.595-1.085c2.443-4.49-2.467-9.401-6.954-6.954l-1.089.591a5.124 5.124 0 0 1-7.367-3.052zM28 38.255a10.255 10.255 0 1 1 7.249-17.507A10.252 10.252 0 0 1 28 38.248z' />
                            </Wrapper>
                       
                            <Wrapper canvas className='w-[40%] min-w-[300px] flex align-items-center py-4' style={{display:'inline-block', fontSize:'16px', fontWeight:'400px', fontFamily:'Chivo'}} id='random-id-26' is='div'>
                                        <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore'/>
                                    </Wrapper>
                      
                    </Wrapper>
                    <Wrapper canvas id='random-id-456' is='parent' className='w-[50%] min-w-[295px] h-full flex flex-row flex-wrap justify-center'>
                    <Wrapper canvas id='random-id-457' is='parent' className='w-auto h-auto mx-4 flex align-items-center w-[60px] h-[60px]'>
                    <SvgIcon fill='#D4ADFC' viewBox='0 0 56 56' path='M28 56a7 7 0 0 0 7-7H21a7 7 0 0 0 7 7m3.483-52.153a3.5 3.5 0 1 0-6.966 0A17.51 17.51 0 0 0 10.5 21c0 3.843-1.75 21-7 24.5h49c-5.25-3.5-7-20.657-7-24.5 0-8.47-6.02-15.54-14.017-17.153' />
                            </Wrapper>
                       
                            <Wrapper canvas className='w-[40%] min-w-[300px] flex align-items-center py-4' style={{display:'inline-block', fontSize:'16px', fontWeight:'400px', fontFamily:'Chivo'}} id='random-id-27' is='div'>
                                        <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore'/>
                                    </Wrapper>
                      
                    </Wrapper>
                    <Wrapper canvas id='random-id-458' is='parent' className='w-[50%] min-w-[295px] h-full flex flex-row flex-wrap justify-center'>
                            <Wrapper canvas id='random-id-459' is='parent' className='w-auto h-auto mx-4 flex align-items-center w-[60px] h-[60px]'>
                            <SvgIcon fill='#D4ADFC' viewBox='0 0 56 56' path='M10.5 8.75a8.75 8.75 0 0 1 17.5 0 8.75 8.75 0 0 1 17.5 0v.021c0 .245 0 .945-.133 1.729H52.5A3.5 3.5 0 0 1 56 14v3.5a3.5 3.5 0 0 1-3.5 3.5h-49A3.5 3.5 0 0 1 0 17.5V14a3.5 3.5 0 0 1 3.5-3.5h7.133a10.4 10.4 0 0 1-.133-1.729zm3.738 1.75H24.5V8.75a5.25 5.25 0 0 0-10.5 0c0 .297.007.959.158 1.505q.03.125.08.245m17.262 0h10.262q.049-.12.08-.245c.151-.546.158-1.208.158-1.505a5.25 5.25 0 1 0-10.5 0zm21 14v26.25A5.25 5.25 0 0 1 47.25 56H31.5V24.5zM8.75 56a5.25 5.25 0 0 1-5.25-5.25V24.5h21V56z' />
                            </Wrapper>
                       
                            <Wrapper canvas className='w-[40%] min-w-[300px] flex align-items-center py-4' style={{display:'inline-block', fontSize:'16px', fontWeight:'400px', fontFamily:'Chivo'}} id='random-id-28' is='div'>
                                        <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore'/>
                                    </Wrapper>
                      
                    </Wrapper>
                </Wrapper>
            </Wrapper>
        </Wrapper>
    </Wrapper></Element>
        </Element>
    ]

    
    
const [sel, setSel] = useState(selected)

function makeOdd(number) {
  setProp((prop) => {
    setSel(100);
  }, 1000);

  setTimeout(() => {
    setProp((prop) => {
      setSel(number)
    }, 1000);
  }, 100);


  
}

useEffect(() => {
if(selected || selected >= 0) {
  makeOdd(selected);
}
},[selected])
    

    return (
        <div style={{ padding: enabled? "10px" : "0px", display:"flex", justifyContent:"center", alignItems:"center", width:'100%', height:'100%'}} ref={connect}>
       {sel == 100 ? (
          <div style={{height:"85vh", display:"flex", justifyContent:"center", alignItems:"center"}}>
          <BounceLoader
                  size={100}
                  color={"white"}
                  loading={true}
                />
          </div>
         ) : (
          styles[sel]
         )} 
    </div>
    );
}

    Features_2.craft = {
        displayName: "Features 2",
        props: {
        selected: 1, // here is the default value of the selected style
        length:3,
        isBlock: true
        },
        rules: {
            canDrag: () => true,
            canDrop: () => true,
        },
        related: {
            toolbar: NewFeaturesSettings,
        },
    }