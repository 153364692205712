// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bgbg {
    background: linear-gradient(90deg, #4BDBA2 0%, #3DA0DF 33.33%, #414FEF 66.66%, #A660FF 100%),
linear-gradient(0deg, #D9D9D9, #D9D9D9);

}

.input-1::placeholder {
    color: #FFFFFF !important;
    opacity: 1;
    }

    .input-box {
        width: 358px;
        height: 70px;
        border: 1px solid #F8ADF4;
        border-radius: 30px;
        padding:20px;
      }
      
      input {
        width: 100%;
        height: 100%;
        border: none;
        background: none;
        outline: none;
        font-family: 'Rubik', sans-serif;
        font-size: 18px;
        line-height: 28.80px;
        color: rgba(255, 255, 255, 0.6);
      }
      
  `, "",{"version":3,"sources":["webpack://./src/components/selectors/blocks/CTA/CTA.css"],"names":[],"mappings":"AAAA;IACI;uCACmC;;AAEvC;;AAEA;IACI,yBAAyB;IACzB,UAAU;IACV;;IAEA;QACI,YAAY;QACZ,YAAY;QACZ,yBAAyB;QACzB,mBAAmB;QACnB,YAAY;MACd;;MAEA;QACE,WAAW;QACX,YAAY;QACZ,YAAY;QACZ,gBAAgB;QAChB,aAAa;QACb,gCAAgC;QAChC,eAAe;QACf,oBAAoB;QACpB,+BAA+B;MACjC","sourcesContent":[".bgbg {\n    background: linear-gradient(90deg, #4BDBA2 0%, #3DA0DF 33.33%, #414FEF 66.66%, #A660FF 100%),\nlinear-gradient(0deg, #D9D9D9, #D9D9D9);\n\n}\n\n.input-1::placeholder {\n    color: #FFFFFF !important;\n    opacity: 1;\n    }\n\n    .input-box {\n        width: 358px;\n        height: 70px;\n        border: 1px solid #F8ADF4;\n        border-radius: 30px;\n        padding:20px;\n      }\n      \n      input {\n        width: 100%;\n        height: 100%;\n        border: none;\n        background: none;\n        outline: none;\n        font-family: 'Rubik', sans-serif;\n        font-size: 18px;\n        line-height: 28.80px;\n        color: rgba(255, 255, 255, 0.6);\n      }\n      \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
