/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import { Element, useEditor, useNode } from "@craftjs/core";
import React, { useState, useEffect, useRef } from "react";
import { NewContactSettings } from "./newContactSetting";
import { Text } from "../../basic/Text";
import { ImageC } from "../../basic/Image";
import { Parent } from "../../Parent/index.js";
import { BounceLoader } from "react-spinners";
import { SvgIcon } from "../../basic/Svg";
import { Wrapper } from "../../wrapper/index.js";

export const Contact_2 = ({ selected }) => {
    const {
        actions: { setProp },
        connectors: {
            connect
        }
    } = useNode();


    const {
        enabled,
    } = useEditor((state, query) => ({
        enabled: state.options.enabled,

    }));

    const styles = [
        <Element canvas is={Parent} id='parentId100' className="w-[100%] h-auto bg-[#FFFFFF] py-10" >
            <Element canvas is={Parent} id='parentId200' className="w-[100%] h-auto flex justify-center items-center" row={true} >
                <Element is={Parent} canvas id='random-id-404' className='max-w-[1300px] h-full flex flex-col'>
                    <Element is={Parent} canvas style={{ display: 'inline-block', fontWeight: '700', fontFamily: 'Poppins' }} className={`w-auto h-full mx-auto text-center text-[#0000000] my-4 text-[46px]`} id='random-id-11'  >
                        <Text text={`Let's chat!`} />
                    </Element>
                    <Element is={Parent} canvas id='random-id-405' className='w-full h-full flex flex-row flex-wrap'>
                        <Element is={Parent} canvas id='random-id-406' className='max-w-[308px] min-w-[277px] my-4 mx-auto flex flex-col '>
                            <Element is={Parent} canvas id='random-id-407' className='flex justify-center items-center'>
                                <SvgIcon width='30px' height='30px' viewBox='0 0 47 46' fill='#2479FD' path='m33.083 1.936-19.167-.02a3.845 3.845 0 0 0-3.833 3.834v34.5a3.845 3.845 0 0 0 3.833 3.833h19.167a3.845 3.845 0 0 0 3.833-3.833V5.75c0-2.108-1.725-3.814-3.833-3.814m0 32.564H13.916v-23h19.167zm-17.25-15.333h15.333v2.875H15.833zm1.917 5.75h11.5v2.875h-11.5z' />
                            </Element>
                            <Element is={Parent} canvas style={{ display: 'inline-block', fontWeight: '700', fontFamily: 'Poppins', color: '#000000', textAlign: 'center' }} className={`flex my-2 justify-center text-[24px]`} id='random-id-12'  >
                                <Text text='Email Us' />
                            </Element>
                            <Element is={Parent} canvas id='random-id-408' className={`w-[100%] mx-auto flex flex-col text-[18px]`} style={{ fontFamily: 'Poppins', color: '#000000', textAlign: 'center', fontWeight: '400', lineHeight: '28.8px' }}>
                                <Element is={Parent} canvas style={{ display: 'inline-block' }} id='random-id-13'  >
                                    <Text text='We would love to start our collaborstion' />
                                </Element>
                                <Element is={Parent} canvas style={{ display: 'inline-block' }} className="pt-4 " id='random-id-14'  >
                                    <Text text='team-email@email.com' />
                                </Element>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-409' className='max-w-[308px] min-w-[277px]  my-4 mx-auto flex flex-col'>
                            <Element is={Parent} canvas id='random-id-410' className='flex justify-center items-center'>
                                <SvgIcon width='30px' height='30px' viewBox='0 0 46 46' fill='#2479FD' path='M23 3.833c-7.418 0-13.417 6-13.417 13.417C9.583 27.312 23 42.167 23 42.167S36.416 27.312 36.416 17.25c0-7.418-5.999-13.417-13.416-13.417m0 18.209a4.791 4.791 0 1 1 0-9.583 4.791 4.791 0 0 1 0 9.583' />
                            </Element>
                            <Element is={Parent} canvas style={{ display: 'inline-block', fontWeight: '700', fontFamily: 'Poppins', color: '#000000', textAlign: 'center' }} className={`flex my-2 justify-center text-[24px]`} id='random-id-15'  >
                                <Text text='Location' />
                            </Element>
                            <Element is={Parent} canvas id='random-id-411' className={`w-[100%] flex flex-col mx-auto text-[18px]`} style={{ fontFamily: 'Poppins', color: '#000000', textAlign: 'center', fontWeight: '400', lineHeight: '28.8px' }}>
                                <Element is={Parent} canvas style={{ display: 'inline-block' }} id='random-id-16'  >
                                    <Text text='Want to meet us in person?' />
                                </Element>
                                <Element is={Parent} canvas style={{ display: 'inline-block' }} className="pt-4 " id='random-id-17'  >
                                    <Text text='Contoso Ltd215, E Tasman DrPo Box, 65502CA, 95134 San Jose' />
                                </Element>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-412' className='max-w-[308px] min-w-[277px] my-4 mx-auto flex flex-col'>
                            <Element is={Parent} canvas id='random-id-413' className='flex justify-center items-center'>
                                <SvgIcon width='30px' height='30px' viewBox='0 0 47 46' fill='#2479FD' path='M38.833 15.333 23.5 24.917 8.166 15.333V11.5L23.5 21.083 38.833 11.5m0-3.833H8.166A3.82 3.82 0 0 0 4.333 11.5v23a3.834 3.834 0 0 0 3.833 3.833h30.667a3.834 3.834 0 0 0 3.833-3.833v-23a3.833 3.833 0 0 0-3.833-3.833' />
                            </Element>
                            <Element is={Parent} canvas style={{ display: 'inline-block', fontWeight: '700', fontFamily: 'Poppins', color: '#000000', textAlign: 'center' }} className={`flex my-2 justify-center text-[24px]`} id='random-id-18'  >
                                <Text text='Call Us' />
                            </Element>
                            <Element is={Parent} canvas id='random-id-414' className={`w-[100%] flex flex-col mx-auto text-[18px]`} style={{ fontFamily: 'Poppins', color: '#000000', textAlign: 'center', fontWeight: '400', lineHeight: '28.8px' }}>
                                <Element is={Parent} canvas style={{ display: 'inline-block' }} id='random-id-19'  >
                                    <Text text='Want to talk about your feature project?' />
                                </Element>
                                <Element is={Parent} canvas style={{ display: 'inline-block' }} className="pt-4 " id='random-id-20'  >
                                    <Text text='+111 111 111 111' />
                                </Element>
                            </Element>
                        </Element>
                    </Element>
                    <Element is={Parent} canvas id='random-id-415' className="max-w-[100%] min-w-[358px] h-[257px] my-4" style={{ borderRadius: '30px' }}>
                        <img alt="map" className='w-[100%] mx-auto h-[100%] object-cover' style={{ borderRadius: '30px' }} src="https://i.ibb.co/1qLn15M/map.png" />
                    </Element>
                </Element>
                </Element>
        </Element>,
        <Element canvas is={Parent} id='parentId300' className="w-[100%] h-auto bg-[#FFFFFF] py-10" >
            <Element canvas is={Parent} id='parentId400' className="w-[100%] h-auto flex justify-center items-center" row={true} >
                <Element is={Parent} canvas id='random-id-417' className='max-w-[1300px] h-full flex flex-col'>
                    <Element is={Parent} canvas style={{ display: 'inline-block', fontWeight: '700', fontFamily: 'Prompt' }} className={`w-auto h-full mx-auto text-center text-[#0000000] my-4 text-[46px]`} id='random-id-1'  >
                        <Text text={`Let's chat!`} />
                    </Element>
                    <Element is={Parent} canvas id='random-id-418' className='w-full h-full flex flex-row flex-wrap'>
                        <Element is={Parent} canvas id='random-id-419' className='max-w-[308px] min-w-[277px]  my-4 mx-auto flex flex-col '>
                            <Element is={Parent} canvas id='random-id-420' className='flex justify-center items-center'>
                                <SvgIcon width='30px' height='30px' viewBox='0 0 47 46' fill='#FF0060' path='m33.083 1.936-19.167-.02a3.845 3.845 0 0 0-3.833 3.834v34.5a3.845 3.845 0 0 0 3.833 3.833h19.167a3.845 3.845 0 0 0 3.833-3.833V5.75c0-2.108-1.725-3.814-3.833-3.814m0 32.564H13.916v-23h19.167zm-17.25-15.333h15.333v2.875H15.833zm1.917 5.75h11.5v2.875h-11.5z' />
                            </Element>
                            <Element is={Parent} canvas style={{ display: 'inline-block', fontWeight: '700', fontFamily: 'Prompt', color: '#000000', textAlign: 'center' }} className={`flex my-2 justify-center text-[24px]`} id='random-id-2'  >
                                <Text text='Email Us' />
                            </Element>
                            <Element is={Parent} canvas id='random-id-421' className={`w-[100%] mx-auto flex flex-col text-[18px]`} style={{ fontFamily: 'Prompt', color: '#000000', textAlign: 'center', fontWeight: '400', lineHeight: '28.8px' }}>
                                <Element is={Parent} canvas style={{ display: 'inline-block' }} id='random-id-3'  >
                                    <Text text='We would love to start our collaborstion' />
                                </Element>
                                <Element is={Parent} canvas style={{ display: 'inline-block' }} className="pt-4" id='random-id-4'  >
                                    <Text text='team-email@email.com' />
                                </Element>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-422' className='max-w-[308px] min-w-[277px]  my-4 mx-auto flex flex-col'>
                            <Element is={Parent} canvas id='random-id-423' className='flex justify-center items-center'>
                                <SvgIcon width='30px' height='30px' viewBox='0 0 46 46' fill='#FF0060' path='M23 3.833c-7.418 0-13.417 6-13.417 13.417C9.583 27.312 23 42.167 23 42.167S36.416 27.312 36.416 17.25c0-7.418-5.999-13.417-13.416-13.417m0 18.209a4.791 4.791 0 1 1 0-9.583 4.791 4.791 0 0 1 0 9.583' />
                            </Element>
                            <Element is={Parent} canvas style={{ display: 'inline-block', fontWeight: '700', fontFamily: 'Prompt', color: '#000000', textAlign: 'center' }} className={`flex my-2 justify-center text-[24px]`} id='random-id-5'  >
                                <Text text='Location' />
                            </Element>
                            <Element is={Parent} canvas id='random-id-424' className={`w-[100%] flex flex-col mx-auto text-[18px]`} style={{ fontFamily: 'Prompt', color: '#000000', textAlign: 'center', fontWeight: '400', lineHeight: '28.8px' }}>
                                <Element is={Parent} canvas style={{ display: 'inline-block' }} id='random-id-6'  >
                                    <Text text='Want to meet us in person?' />
                                </Element>
                                <Element is={Parent} canvas style={{ display: 'inline-block' }} className="pt-4 " id='random-id-7'  >
                                    <Text text='Contoso Ltd215, E Tasman DrPo Box, 65502CA, 95134 San Jose' />
                                </Element>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-425' className='max-w-[308px] min-w-[277px] my-4 mx-auto flex flex-col'>
                            <Element is={Parent} canvas id='random-id-426' className='flex justify-center items-center'>
                                <SvgIcon width='30px' height='30px' viewBox='0 0 47 46' fill='#FF0060' path='M38.833 15.333 23.5 24.917 8.166 15.333V11.5L23.5 21.083 38.833 11.5m0-3.833H8.166A3.82 3.82 0 0 0 4.333 11.5v23a3.834 3.834 0 0 0 3.833 3.833h30.667a3.834 3.834 0 0 0 3.833-3.833v-23a3.833 3.833 0 0 0-3.833-3.833' />
                            </Element>
                            <Element is={Parent} canvas style={{ display: 'inline-block', fontWeight: '700', fontFamily: 'Prompt', color: '#000000', textAlign: 'center' }} className={`flex my-2 justify-center text-[24px]`} id='random-id-8'  >
                                <Text text='Call Us' />
                            </Element>
                            <Element is={Parent} canvas id='random-id-427' className={`w-[100%] flex flex-col mx-auto text-[18px]`} style={{ fontFamily: 'Prompt', color: '#000000', textAlign: 'center', fontWeight: '400', lineHeight: '28.8px' }}>
                                <Element is={Parent} canvas style={{ display: 'inline-block' }} id='random-id-9'  >
                                    <Text text='Want to talk about your feature project?' />
                                </Element>
                                <Element is={Parent} canvas style={{ display: 'inline-block' }} className="pt-4 " id='random-id-10'  >
                                    <Text text='+111 111 111 111' />
                                </Element>
                            </Element>
                        </Element>
                    </Element>
                    <Element is={Parent} canvas id='random-id-428' className="max-w-[100%] min-w-[358px] h-[257px] my-4" style={{ borderRadius: '20px' }}>
                        <img alt='map' className='w-[100%] mx-auto h-[100%] object-cover' style={{ borderRadius: '20px' }} src="https://i.ibb.co/1qLn15M/map.png" />
                    </Element>
                </Element></Element>
        </Element>,

        <Element canvas is={Parent} id='parentId500' className="w-[100%] h-auto bg-[#FFFFFF] py-10" >
            <Element canvas is={Parent} id='parentId600' className="w-[100%] h-auto flex justify-center items-center" row={true} >
                <Element is={Parent} canvas id='random-id-430' className='max-w-[1300px] h-full flex flex-col'>
                    <Element is={Parent} canvas style={{ display: 'inline-block', fontWeight: '700', fontFamily: 'Almarai' }} className={`w-auto h-full mx-auto text-center text-[#0000000] my-4 text-[46px]`} id='random-id-21'  >
                        <Text text={`Let's chat!`} />
                    </Element>
                    <Element is={Parent} canvas id='random-id-431' className='w-full h-full flex flex-row flex-wrap'>
                        <Element is={Parent} canvas id='random-id-432' className='max-w-[308px] min-w-[277px]  my-4 mx-auto flex flex-col '>
                            <Element is={Parent} canvas id='random-id-433' className='flex justify-center items-center'>
                                <SvgIcon width='30px' height='30px' viewBox='0 0 47 46' fill='#F31559' path='m33.083 1.936-19.167-.02a3.845 3.845 0 0 0-3.833 3.834v34.5a3.845 3.845 0 0 0 3.833 3.833h19.167a3.845 3.845 0 0 0 3.833-3.833V5.75c0-2.108-1.725-3.814-3.833-3.814m0 32.564H13.916v-23h19.167zm-17.25-15.333h15.333v2.875H15.833zm1.917 5.75h11.5v2.875h-11.5z' />
                            </Element>
                            <Element is={Parent} canvas style={{ display: 'inline-block', fontWeight: '700', fontFamily: 'Almarai', color: '#000000', textAlign: 'center' }} className={`flex my-2 justify-center text-[24px]`} id='random-id-22'  >
                                <Text text='Email Us' />
                            </Element>
                            <Element is={Parent} canvas id='random-id-434' className={`w-[100%] mx-auto flex flex-col text-[18px]`} style={{ fontFamily: 'Almarai', color: '#000000', textAlign: 'center', fontWeight: '400', lineHeight: '28.8px' }}>
                                <Element is={Parent} canvas style={{ display: 'inline-block' }} id='random-id-23'  >
                                    <Text text='We would love to start our collaborstion' />
                                </Element>
                                <Element is={Parent} canvas style={{ display: 'inline-block' }} className="pt-4 " id='random-id-24'  >
                                    <Text text='team-email@email.com' />
                                </Element>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-435' className='max-w-[308px] min-w-[277px]  my-4 mx-auto flex flex-col'>
                            <Element is={Parent} canvas id='random-id-436' className='flex justify-center items-center'>
                                <SvgIcon width='30px' height='30px' viewBox='0 0 46 46' fill='#F31559' path='M23 3.833c-7.418 0-13.417 6-13.417 13.417C9.583 27.312 23 42.167 23 42.167S36.416 27.312 36.416 17.25c0-7.418-5.999-13.417-13.416-13.417m0 18.209a4.791 4.791 0 1 1 0-9.583 4.791 4.791 0 0 1 0 9.583' />
                            </Element>
                            <Element is={Parent} canvas style={{ display: 'inline-block', fontWeight: '700', fontFamily: 'Almarai', color: '#000000', textAlign: 'center' }} className={`flex my-2 justify-center text-[24px]`} id='random-id-25'  >
                                <Text text='Location' />
                            </Element>
                            <Element is={Parent} canvas id='random-id-437' className={`w-[100%] flex flex-col mx-auto text-[18px]`} style={{ fontFamily: 'Almarai', color: '#000000', textAlign: 'center', fontWeight: '400', lineHeight: '28.8px' }}>
                                <Element is={Parent} canvas style={{ display: 'inline-block' }} id='random-id-26'  >
                                    <Text text='Want to meet us in person?' />
                                </Element>
                                <Element is={Parent} canvas style={{ display: 'inline-block' }} className="pt-4 " id='random-id-27'  >
                                    <Text text='Contoso Ltd215, E Tasman DrPo Box, 65502CA, 95134 San Jose' />
                                </Element>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-438' className='max-w-[308px] min-w-[277px] my-4 mx-auto flex flex-col'>
                            <Element is={Parent} canvas id='random-id-439' className='flex justify-center items-center'>
                                <SvgIcon width='30px' height='30px' viewBox='0 0 47 46' fill='#F31559' path='M38.833 15.333 23.5 24.917 8.166 15.333V11.5L23.5 21.083 38.833 11.5m0-3.833H8.166A3.82 3.82 0 0 0 4.333 11.5v23a3.834 3.834 0 0 0 3.833 3.833h30.667a3.834 3.834 0 0 0 3.833-3.833v-23a3.833 3.833 0 0 0-3.833-3.833' />
                            </Element>
                            <Element is={Parent} canvas style={{ display: 'inline-block', fontWeight: '700', fontFamily: 'Almarai', color: '#000000', textAlign: 'center' }} className={`flex my-2 justify-center text-[24px]`} id='random-id-28'  >
                                <Text text='Call Us' />
                            </Element>
                            <Element is={Parent} canvas id='random-id-440' className={`w-[100%] flex flex-col mx-auto text-[18px]`} style={{ fontFamily: 'Almarai', color: '#000000', textAlign: 'center', fontWeight: '400', lineHeight: '28.8px' }}>
                                <Element is={Parent} canvas style={{ display: 'inline-block' }} id='random-id-29'  >
                                    <Text text='Want to talk about your feature project?' />
                                </Element>
                                <Element is={Parent} canvas style={{ display: 'inline-block' }} className="pt-4 " id='random-id-30'  >
                                    <Text text='+111 111 111 111' />
                                </Element>
                            </Element>
                        </Element>
                    </Element>
                    <Element is={Parent} canvas id='random-id-441' className="max-w-[100%] min-w-[358px] h-[257px] my-4" style={{ borderRadius: '20px 8px 20px 8px' }}>
                        <ImageC className='w-[100%] mx-auto h-[100%] object-cover' style={{ borderRadius: '20px 8px 20px 8px' }} src="https://i.ibb.co/1qLn15M/map.png" />
                    </Element>
                </Element></Element>
        </Element>,


        <Element canvas is={Parent} id='parentId700' className="w-[100%] h-auto bg-[#FFFFFF] py-10" >
            <Element canvas is={Parent} id='parentId800' className="w-[100%] h-auto flex justify-center items-center" row={true} >
                <Element is={Parent} canvas id='random-id-443' className='max-w-[1300px] h-full flex flex-col'>
                    <Element is={Parent} canvas style={{ display: 'inline-block', fontWeight: '700', fontFamily: 'Chivo' }} className={`w-auto h-full mx-auto text-center text-[#0000000] my-4 text-[46px]`} id='random-id-31'  >
                        <Text text={`Let's chat!`} />
                    </Element>
                    <Element is={Parent} canvas id='random-id-444' className='w-full h-full flex flex-row flex-wrap'>
                        <Element is={Parent} canvas id='random-id-445' className='max-w-[308px] min-w-[277px] my-4 mx-auto flex flex-col '>
                            <Element is={Parent} canvas id='random-id-446' className='flex justify-center items-center'>
                                <SvgIcon width='30px' height='30px' viewBox='0 0 47 46' fill='#D4ADFC' path='m33.083 1.936-19.167-.02a3.845 3.845 0 0 0-3.833 3.834v34.5a3.845 3.845 0 0 0 3.833 3.833h19.167a3.845 3.845 0 0 0 3.833-3.833V5.75c0-2.108-1.725-3.814-3.833-3.814m0 32.564H13.916v-23h19.167zm-17.25-15.333h15.333v2.875H15.833zm1.917 5.75h11.5v2.875h-11.5z' />
                            </Element>
                            <Element is={Parent} canvas style={{ display: 'inline-block', fontWeight: '700', fontFamily: 'Chivo', color: '#000000', textAlign: 'center' }} className={`flex my-2 justify-center text-[24px]`} id='random-id-32'  >
                                <Text text='Email Us' />
                            </Element>
                            <Element is={Parent} canvas id='random-id-447' className={`w-[100%] mx-auto flex flex-col text-[18px]`} style={{ fontFamily: 'Chivo', color: '#000000', textAlign: 'center', fontWeight: '400', lineHeight: '28.8px' }}>
                                <Element is={Parent} canvas style={{ display: 'inline-block' }} id='random-id-33'  >
                                    <Text text='We would love to start our collaborstion' />
                                </Element>
                                <Element is={Parent} canvas style={{ display: 'inline-block' }} className="pt-4 " id='random-id-34'  >
                                    <Text text='team-email@email.com' />
                                </Element>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-448' className='max-w-[308px] min-w-[277px]  my-4 mx-auto flex flex-col'>
                            <Element is={Parent} canvas id='random-id-449' className='flex justify-center items-center'>
                                <SvgIcon width='30px' height='30px' viewBox='0 0 46 46' fill='#D4ADFC' path='M23 3.833c-7.418 0-13.417 6-13.417 13.417C9.583 27.312 23 42.167 23 42.167S36.416 27.312 36.416 17.25c0-7.418-5.999-13.417-13.416-13.417m0 18.209a4.791 4.791 0 1 1 0-9.583 4.791 4.791 0 0 1 0 9.583' />
                            </Element>
                            <Element is={Parent} canvas style={{ display: 'inline-block', fontWeight: '700', fontFamily: 'Chivo', color: '#000000', textAlign: 'center' }} className={`flex my-2 justify-center text-[24px]`} id='random-id-35'  >
                                <Text text='Location' />
                            </Element>
                            <Element is={Parent} canvas id='random-id-450' className={`w-[100%] flex flex-col mx-auto text-[18px]`} style={{ fontFamily: 'Chivo', color: '#000000', textAlign: 'center', fontWeight: '400', lineHeight: '28.8px' }}>
                                <Element is={Parent} canvas style={{ display: 'inline-block' }} id='random-id-36'  >
                                    <Text text='Want to meet us in person?' />
                                </Element>
                                <Element is={Parent} canvas style={{ display: 'inline-block' }} className="pt-4 " id='random-id-37'  >
                                    <Text text='Contoso Ltd215, E Tasman DrPo Box, 65502CA, 95134 San Jose' />
                                </Element>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-451' className='max-w-[308px] min-w-[277px] my-4 mx-auto flex flex-col'>
                            <Element is={Parent} canvas id='random-id-452' className='flex justify-center items-center'>
                                <SvgIcon width='30px' height='30px' viewBox='0 0 47 46' fill='#D4ADFC' path='M38.833 15.333 23.5 24.917 8.166 15.333V11.5L23.5 21.083 38.833 11.5m0-3.833H8.166A3.82 3.82 0 0 0 4.333 11.5v23a3.834 3.834 0 0 0 3.833 3.833h30.667a3.834 3.834 0 0 0 3.833-3.833v-23a3.833 3.833 0 0 0-3.833-3.833' />
                            </Element>
                            <Element is={Parent} canvas style={{ display: 'inline-block', fontWeight: '700', fontFamily: 'Chivo', color: '#000000', textAlign: 'center' }} className={`flex my-2 justify-center text-[24px]`} id='random-id-38'  >
                                <Text text='Call Us' />
                            </Element>
                            <Element is={Parent} canvas id='random-id-401' className={`w-[100%] flex flex-col mx-auto text-[18px]`} style={{ fontFamily: 'Chivo', color: '#000000', textAlign: 'center', fontWeight: '400', lineHeight: '28.8px' }}>
                                <Element is={Parent} canvas style={{ display: 'inline-block' }} id='random-id-39'  >
                                    <Text text='Want to talk about your feature project?' />
                                </Element>
                                <Element is={Parent} canvas style={{ display: 'inline-block' }} className="pt-4 " id='random-id-40'  >
                                    <Text text='+111 111 111 111' />
                                </Element>
                            </Element>
                        </Element>
                    </Element>
                    <Element is={Parent} canvas id='random-id-402' className="max-w-[100%] min-w-[358px] h-[257px] my-4" style={{ borderRadius: '8px' }}>
                        <ImageC className='w-[100%] mx-auto h-[100%] object-cover' style={{ borderRadius: '8px' }} src="https://i.ibb.co/1qLn15M/map.png" />
                    </Element>
                </Element></Element>
        </Element>
    ]


    const [sel, setSel] = useState(selected)

    function makeOdd(number) {
        setProp((prop) => {
            setSel(100);
        }, 1000);

        setTimeout(() => {
            setProp((prop) => {
                setSel(number)
            }, 1000);
        }, 100);



    }

    useEffect(() => {
        if (selected || selected >= 0) {
            makeOdd(selected);
        }
    }, [selected])

    return (
        <div style={{ padding: enabled ? "10px" : "0px", display: "flex", justifyContent: "center", alignItems: "center", width: '100%', height: '100%' }} ref={connect}>
            {sel == 100 ? (
                <div style={{ height: "85vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <BounceLoader
                        size={100}
                        color={"white"}
                        loading={true}
                    />
                </div>
            ) : (
                styles[sel]
            )}
        </div>
    );
}


Contact_2.craft = {
    displayName: "Contact 2",
    props: {
        selected: 1, // here is the default value of the selected style
        length: 3,
        isBlock: true
    },
    rules: {
        canDrag: () => true,
        canDrop: () => true,
    },
    related: {
        toolbar: NewContactSettings,
    },
};
