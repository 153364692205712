/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import { useEditor, useNode } from '@craftjs/core';
import { Grid, Slider as Small } from '@mui/material';
import RadioGroup from '@mui/material/RadioGroup';
import { withStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import './toolbarItem.css'
import { ToolbarDropdown } from './ToolbarDropdown';
import { ToolbarTextInput } from './ToolbarTextInput';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { useDarkMode } from '../../../DarkModeContext';
import { CloseIcon2, PressIcon } from '../Viewport/Pop-up/svgArray';
import { SliderType } from './Toolbar-Types/Slider';
import { SelectBox } from './Toolbar-Types/SelectBox';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import { ShapesPositions } from './Toolbar-Types/ShapesPositions';
import { NumberSlider } from './Toolbar-Types/NumberSlider';
import { ToolbarSection } from './ToolbarSection';
import { AuthSettings } from './Toolbar-Types/Auth/AuthSettings';
import { useActiveIdContext } from '../../../ActiveIdContext';
import { Widget_Nav } from './Toolbar-Types/Widget';
import ToolbarSelectDrop from './ToolbarSelectDrop';
import { DeleteNameIcon } from '../Viewport/EditorSvg';

const iOSBoxShadow = '0 3px 1px rgba(0,0,0,0.1),0 2px 2px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';

const SliderStyled = withStyles({
  root: {
    margin: 'auto auto',
    width: '33%',
    color: '#3880ff',
    height: '3vmin',
    padding: '5% 0',
  },
  thumb: {
    height: '2vmin',
    width: '2vmin',
    backgroundColor: '#0867FC',
  },

  active: {},
  valueLabel: {
    left: 'calc(-50% + 1.1%)',
    top: '-22%',
    '& *': {
      background: 'transparent',
      color: '#000',
    },
  },

  rail: {
    height: '2%',
    opselectedIconacity: 0.5,
    backgroundColor: '#bfbfbf',
  },
  mark: {
    backgroundColor: '#bfbfbf',
    height: '8%',
    width: '10%',
    marginTop: '-3%',
  },
  markActive: {
    opacity: 1,
    backgroundColor: 'currentColor',
  },
})(Small);


export const ToolbarItem = ({
  placeholder,
  events,
  noinput,
  iconSearch,
  selectedIcon,
  imageSelect,
  setIconsSquare,
  IconsSquare,
  full = false,
  title,
  loginPropKey,
  LogoutPropKey,
  RegisterPropKey,
  RegisterUserPropKey,
  ErrorMessagePropKey,
  ResetPasswordPropKey,
  VerifyEmailPropKey,
  LoginVerifyUserCodePropKey,
  LoginAuthGrantPropKey,
  LoginOtpPropKey,
  LoginUsernamePropKey,
  LoginPasswordPropKey,
  LoginUpdatePasswordContnetsPropKey,
  LoginLinkConfirmPropKey,
  LoginpageExpiredContentsPropKey,
  LoginConfigTotpContentsPropKey,
  LoginUpdateProfileContentsPropKey,
  UpdateUserProfilePropKey,
  ReviewUserProfilePropKey,
  UpdateEmailContentsPropKey,
  propKey,
  propKey2,
  propKey3,
  propKey4,
  propKey5,
  propKey6,
  propKey7,
  optionsDrop,
  selectBoxes,
  unitOptions,
  type,
  type2,
  transition,
  percent,
  unit,
  onChange,
  index,
  sm,
  all,
  action,
  style,
  number,
  color,
  graValue,
  max,
  pxInput,
  ...props
}) => {

  const { nodes, actions: { setProp } } = useEditor((state, query) => ({ nodes: state.nodes }));

  const { isDarkMode } = useDarkMode();

  const { state, dispatch: setActiveId } = useActiveIdContext();
  
  const { activeId: activeId } = state;

  const {
    options,
    hoverValue,
    scrollValue,
    propValue,
    loginPropValue,
    logoutPropValue,
    registerPropValue,
    registerUserPropValue,
    errorMessagePropValue,
    resetPasswordPropValue,
    verifyEmailPropValue,
    loginVerifyUserCodePropValue,
    loginAuthGrantPropValue,
    loginOtpPropValue,
    loginUsernamePropValue,
    loginPasswordPropValue,
    LoginUpdatePasswordContnetsPropValue,
    LoginLinkConfirmPropValue,
    LoginpageExpiredContentsPropValue,
    LoginConfigTotpContentsPropValue,
    LoginUpdateProfileContentsPropValue,
    UpdateUserProfilePropValue,
    ReviewUserProfilePropValue,
    UpdateEmailContentsPropValue,
    stylesLength,
    shapeBottom,
    shapeLeft,
    shapeRight,
    shapeTop,
    node,
  } = useNode((node) => ({
    hoverValue: node?.data?.props?.actionSelect?.[propKey]?.hover ?? "",
    scrollValue: node?.data?.props?.actionSelect?.[propKey]?.scroll ?? "",
    shapeTop: node?.data?.props?.shapes?.Top?.[propKey] ?? "",
    shapeBottom: node?.data?.props?.shapes?.Bottom?.[propKey] ?? "",
    shapeLeft: node?.data?.props?.shapes?.Left?.[propKey] ?? "",
    shapeRight: node?.data?.props?.shapes?.Right?.[propKey] ?? "",
    propValue: node.data.props[propKey] || "",
    loginPropValue: node.data.props[loginPropKey] || "",
    logoutPropValue: node.data.props[LogoutPropKey] || "",
    registerPropValue: node.data.props[RegisterPropKey] || "",
    registerUserPropValue: node.data.props[RegisterUserPropKey] || "",
    errorMessagePropValue: node.data.props[ErrorMessagePropKey] || "",
    resetPasswordPropValue: node.data.props[ResetPasswordPropKey] || "",
    verifyEmailPropValue: node.data.props[VerifyEmailPropKey] || "",
    loginVerifyUserCodePropValue: node.data.props[LoginVerifyUserCodePropKey] || "",
    loginAuthGrantPropValue: node.data.props[LoginAuthGrantPropKey] || "",
    loginOtpPropValue: node.data.props[LoginOtpPropKey] || "",
    loginUsernamePropValue: node.data.props[LoginUsernamePropKey] || "",
    loginPasswordPropValue: node.data.props[LoginPasswordPropKey] || "",
    LoginUpdatePasswordContnetsPropValue: node.data.props[LoginUpdatePasswordContnetsPropKey] || "",
    LoginLinkConfirmPropValue: node.data.props[LoginLinkConfirmPropKey] || "",
    LoginpageExpiredContentsPropValue: node.data.props[LoginpageExpiredContentsPropKey] || "",
    LoginConfigTotpContentsPropValue: node.data.props[LoginConfigTotpContentsPropKey] || "",
    LoginUpdateProfileContentsPropValue: node.data.props[LoginUpdateProfileContentsPropKey] || "",
    UpdateUserProfilePropValue: node.data.props[UpdateUserProfilePropKey] || "",
    ReviewUserProfilePropValue: node.data.props[ReviewUserProfilePropKey] || "",
    UpdateEmailContentsPropValue: node.data.props[UpdateEmailContentsPropKey] || "",
    stylesLength: node.data.props["length"],
    options: node.data.props,
    node: node
  }));

  const loginValue = type !== 'slider' ? loginPropValue : loginPropValue?.split(" ")[0];

  const logoutValue = type !== 'slider' ? logoutPropValue : logoutPropValue?.split(" ")[0];

  const registerValue = type !== 'slider' ? registerPropValue : registerPropValue?.split(" ")[0];

  const registerUserValue = type !== 'slider' ? registerUserPropValue : registerUserPropValue?.split(" ")[0];

  const errorMessageValue = type !== 'slider' ? errorMessagePropValue : errorMessagePropValue?.split(" ")[0];

  const resetPasswordValue = type !== 'slider' ? resetPasswordPropValue : resetPasswordPropValue?.split(" ")[0];

  const verifyEmailValue = type !== 'slider' ? verifyEmailPropValue : verifyEmailPropValue?.split(" ")[0];

  const verifyUserCodeValue = type !== 'slider' ? loginVerifyUserCodePropValue : loginVerifyUserCodePropValue?.split(" ")[0];

  const loginAuthGrantValue = type !== 'slider' ? loginAuthGrantPropValue : loginAuthGrantPropValue?.split(" ")[0];

  const loginOtpValue = type !== 'slider' ? loginOtpPropValue : loginOtpPropValue?.split(" ")[0];

  const loginUsernameValue = type !== 'slider' ? loginUsernamePropValue : loginUsernamePropValue?.split(" ")[0];

  const loginPasswordValue = type !== 'slider' ? loginPasswordPropValue : loginPasswordPropValue?.split(" ")[0];

  const LoginUpdatePasswordContnetsValue = type !== 'slider' ? LoginUpdatePasswordContnetsPropValue : LoginUpdatePasswordContnetsPropValue?.split(" ")[0];

  const LoginLinkConfirmValue = type !== 'slider' ? LoginLinkConfirmPropValue : LoginLinkConfirmPropValue?.split(" ")[0];

  const LoginpageExpiredContentsValue = type !== 'slider' ? LoginpageExpiredContentsPropValue : LoginpageExpiredContentsPropValue?.split(" ")[0];

  const LoginConfigTotpContentsValue = type !== 'slider' ? LoginConfigTotpContentsPropValue : LoginConfigTotpContentsPropValue?.split(" ")[0];

  const LoginUpdateProfileContentsValue = type !== 'slider' ? LoginUpdateProfileContentsPropValue : LoginUpdateProfileContentsPropValue?.split(" ")[0];

  const UpdateUserProfileValue = type !== 'slider' ? UpdateUserProfilePropValue : UpdateUserProfilePropValue?.split(" ")[0];

  const ReviewUserProfileValue = type !== 'slider' ? ReviewUserProfilePropValue : ReviewUserProfilePropValue?.split(" ")[0];

  const UpdateEmailContentsValue = type !== 'slider' ? UpdateEmailContentsPropValue : UpdateEmailContentsPropValue?.split(" ")[0];

  const value = type !== 'slider' ? propValue : propValue?.split(" ")[0];

  const hoverV = type !== 'slider' ? hoverValue : hoverValue?.split(" ")[0];

  const scrollV = type !== 'slider' ? scrollValue : scrollValue?.split(" ")[0];

  const value2 = type !== 'slider' ? propValue : propValue?.split(" ")[1];

  useEffect(() => {
    if (selectedIcon && Object.keys(selectedIcon).length > 0) {
      setProp(node.id, (props) => {
        props[propKey] = selectedIcon;
        return props;
      });
    } else if (imageSelect && Object.keys(imageSelect).length > 0) {
      setProp(node.id, (props) => {
        props[propKey] = imageSelect;
        return props;
      });
    }
  }, [selectedIcon, imageSelect]);


  const handleInputChange = (e, backgroundName, property) => {
    const { value } = e.target;
    setProp(node.id, (props) => {
      props[propKey] = { ...propValue, [backgroundName]: { ...propValue[backgroundName], [property]: value, } }

    })
  };

  const [newCategory, setNewCategory] = useState('');


  const handleAddCategory = () => {
    if (newCategory && !options['categories'].includes(newCategory)) {
      setProp(node.id, (props) => {
        props[propKey] = [...options['categories'], newCategory]
      });
      setNewCategory('');
    }
  };

  const handleRemoveCategory = (index) => {
    setProp(node.id, (props) => {
      props[propKey] = options['categories'].filter((_, i) => i !== index)
    });
  };

  return (
    <Grid style={{ width: '100%' }} item >
      <div className="my-[.25Vmin]" style={{ width: '100%' }}>
        {['src', 'text', 'color', 'bg', 'number', 'shadow', 'shadowBackground'].includes(type) ? (
          <div className='relative'>
           {noinput ? null : <ToolbarTextInput
            options={options}
              type={type}
              {...props}
              value={
                options['actionState'] == "Hover" ?
                  hoverV || 0 : options['actionState'] == "Scroll" ?
                    scrollV || 0 :
                    value || 0 
                }
              placeholder={placeholder}
              onChange={(value) => {
                console.log(value)
                setProp(node.id, (props) => {

                  if (node?.data?.props['actionSelect'] === undefined) {
                      props[propKey] = onChange ? onChange(value) : value;
                  } else {
                  if (node?.data?.props["isParent"] && !props.actionSelect[propKey]) {
                      props.actionSelect[propKey] = {};
                    }
                    if (options['actionState'] == "Default") { 
                      props[propKey] = onChange ? onChange(value) : value;
                    } else if (options['actionState'] == "Hover") { 
                      props.actionSelect[propKey].hover = onChange ? onChange(value) : value;
                    } else if (options['actionState'] == "Scroll") { 
                      props.actionSelect[propKey].scroll = onChange ? onChange(value) : value;
                    } else if (Array.isArray(propValue)) {
                      props[propKey][index] = onChange ? onChange(value) : value;
                    } else {
                      props[propKey] = onChange ? onChange(value) : value;
                    }
              }

          }, 1000)
              }}
            />}
            {['src'].includes(type) === true && (
              <div className='w-auto h-[auto] justify-center flex items-center cursor-pointer '
                onClick={() => {
                  if (['color', 'bg', 'number', 'shadow'].includes(type) !== true) {
                    setIconsSquare(!IconsSquare)
                    
                  }
                }}
              >
                {iconSearch && <div className='mx-[1vmin]'>Click here to open the icons library</div>}
                <div style={{ padding: iconSearch && '3.6vmin 0' }} className={`${noinput ? '' : 'absolute top-[0] left-[1vmin]'} h-full flex items-center`}>
                  {IconsSquare ? 
                    <div className='my-auto' onClick={() => setIconsSquare(!IconsSquare)}>
                      <CloseIcon2 fill={`${isDarkMode ? '#fff' : '#989191'}`} width={`${!noinput ? '2.4vmin' : '3vmin'}`} height={`${!noinput ? '2.4vmin' : '3vmin'}`} />
                    </div> 
                    : 
                    <div className='my-auto'>
                      <PressIcon fill={`${isDarkMode ? '#fff' : '#989191'}`} width={`${!noinput ? '2.4vmin' : '5vmin'}`} height={`${!noinput ? '2.4vmin' : '5vmin'}`} />
                    </div>
                    }
                </div>
              </div>
            )}

          </div>

        ) : type === 'bgGra' ? (
          <div className='w-full flex flex-row flex-wrap' style={{ height: '100%', justifyContent: 'center' }}>
            {Object.entries(propValue).map(([backgroundName, background]) => (
              <div className='items-center justify-center flex flex-column m-[.5vmin]' key={backgroundName}>
                <div className='h-full min-h-[3vmin] flex justify-center w-full'>
                  <div className='relative border-[.25vmin] border-solid border-[#e9e9e9] w-[20vmin] max-w-[20vmin] min-h-[100%] max-h-[5vmin] flex justify-center align-items-center rounded-[2.5vmin] overflow-hidden'>
                    <input style={{ minHeight: '10vmin', minWidth: '20vmin', cursor: 'pointer' }}
                      type="color"
                      min="0"
                      max="100"
                      value={background.color}
                      onChange={(e) => handleInputChange(e, backgroundName, 'color')} />

                    <div style={{ pointerEvents: 'none' }} className='absolute top-0 left-0 text-[#fff] text-[2.5vmin] w-full h-full flex align-items-center justify-center text-center'>
                      <div>{background?.color || 'Click Here.'}</div>
                    </div>
                  </div>
                </div>
                <div className='items-center justify-center flex flex-column w-full h-full'>
                  <SliderStyled
                    className='mx-auto my-auto justify-center flex items-center'
                    style={{ width: '10vmin', border: '.25vmin solid transparent' }}
                    min={0}
                    max={100}
                    value={background.value}
                    onChange={(e) => handleInputChange(e, backgroundName, 'value')}
                  />
                  <div className='my-[.5vmin]'>
                    {background.value}%
                  </div>
                </div>

              </div>
            ))}

          </div>
        ) : type === 'vslider' ? (
          <div style={{ width: "80%", margin: '.6vmin auto' }}>

            <Slider
              min={0}
              max={stylesLength || 9}
              value={parseInt(value) || 0}
              onChange={
                ((e) => {
                  if (all) {
                    let nodesId = ["ROOT", ...node.data.nodes]
                    nodesId.map(x => {
                      setProp(x, (props) => {
                        props[propKey] = onChange ? onChange(e) : e;

                      }, 1000)
                    })
                  } else {

                    setProp(node.id, (props) => {
                      props[propKey] = onChange ? onChange(e) : e;
                    }, 1000);
                  }

                })
              }
            />
          </div>
        ) : type === 'slider' ? (
          <SliderType
            value={value}
            node={node}
            setProp={setProp}
            isDarkMode={isDarkMode}
            type={type}
            propKey={propKey}
            unitOptions={unitOptions}
            value2={value2}
            onChange={onChange}
            propValue={propValue}
            index={index}
            activeState={options['actionState']}
            hoverV={hoverV}
            scrollV={scrollV}
            props={props}
            options={options}
            max={max}
          />
        ) : type === 'numSlider' ? (
          <NumberSlider
            value={value}
            node={node}
            setProp={setProp}
            isDarkMode={isDarkMode}
            propKey={propKey}
            unitOptions={unitOptions}
            onChange={onChange}
            props={props}
          />
        ) : type === 'radio' ? (
          <div className='w-[100%] flex flex-col justify-center'>
            {props.label ? (
              <div className={`flex items-center ${isDarkMode ? 'text-[#ffffff]' : 'text-[#161717]'}`} style={{ fontSize: '2vmin', fontWeight: '500', fontFamily: 'Inter' }}>{props.label}</div>
            ) : null}
            <div className='w-[100%] flex flex-row' style={{ justifyContent: "center" }}>
              <RadioGroup
                style={{ display: "flex", justifyContent: "space-around", width: "100%" }}
                row={true}
                value={value || 0}
                onChange={(e) => {
                  const value = e.target.value;
                  if (onChange) {
                    onChange(value)
                  } else {
                    setProp(node.id, (props) => {
                      props[propKey] = value;
                    });
                  }

                }}
              >

                {props.children}
              </RadioGroup>
            </div>
          </div>

        ) : type === 'select' ? (
          <ToolbarDropdown
          nodes={nodes}
            placeholder={placeholder}
            isEvents={events}
            {...props}
            options={events || []}
            value={value || ''}
            onChange={(value) => {
              console.log(nodes, 'nodes')
              return setProp(node.id, (props) =>
                  props[propKey] = onChange ? onChange(value) : value
              )
            }
            }
          />
        ) : type === 'src' ? (
          <div style={{ width: "100%" }}>
            {props.label ? (
              <h4 style={{ fontsize: '1Vmin' }} className={`${isDarkMode ? 'text-[#ffffff]' : 'text-[#161717]'}`}>{props.label}</h4>
            ) : null}
            <input className='border-[0.25Vmin] border-[#989191] border-solid text-[#000000]'
              type={type}
              {...props}
              value={value || props.value}
              onChange={(value) => {
                setProp(node.id, (props) => {
                  if (Array.isArray(propValue)) {
                    props[propKey][index] = onChange ? onChange(value) : value;
                  } else {
                    props[propKey] = onChange ? onChange(value) : value;
                  }
                }, 500);
              }}
            />
          </div>
        ) : type === 'select-drop' ? (
          <ToolbarSelectDrop
          isDarkMode={isDarkMode}
          props={props}
          noinput={noinput}
          optionsDrop={optionsDrop}
          value={value}
          onChange={onChange}
          setProp={setProp}
          node={node}
          propKey={propKey}
          />
        ) : type === 'header' ? (
          <div style={{ border: '.25vmin solid #e9e9e930' }}
            className={`w-[100%] py-[.7vmin] shadow-md mb-[1.5Vmin] `}
          >
            <input
              className={`text-[#000] py-[.7vmin] text-[2.1vmin] text-center font-bold font-["Inter"] ${isDarkMode ? 'text-[#fff]' : 'text-[#161717]'}`}
              style={{ height: 'fit-content', background: 'transparent' }}
              value={value}
              // onClick={() => console.log(logoutContents)}
              onChange={
                (e, value) => {
                  value = e.target.value
                  setProp(node.id, (props) => {
                    if (Array.isArray(propValue)) {
                      props[propKey][index] = value;
                    } else {
                      props[propKey] = value;
                    }
                  });
                }
              }
            />
          </div>
        ) : type === 'Toggle' ? (
          <div className='w-full' style={{height:'fit-content'}}>
          <div style={{ width: "100%", height: "6.1vh" }} className="flex flex-row  justify-center mt-[3%] gap-[3%] items-center">
            <div className={`${isDarkMode ? 'text-[#ffffff]' : options[propKey] === true ? 'text-[#000000]' : 'text-[#989191]'} text-[1.8Vmin] item-hidden`} style={{ fontFamily: 'Inter', fontWeight: '500' }}>Sidebar</div>
            <div
              className={`cursor-pointer relative bg-[transparent] mx-[2.5%] ${isDarkMode
                  ? 'text-[#FFFFFFA1] border-[.06vw] border-[#E9E9E930]'
                  : 'text-[#FFFFFF] border-[.06vw] border-[#E9E9EA]'
                } flex`}
              style={{ borderRadius: '2.5Vmin', overflow: "hidden", width: "7Vmin", height: "3.7Vmin" }}
              onClick={() => {
                setProp(node.id, (props) => {
                  props[propKey] = !value
                })
              }}
            >
              <div
                className={`${options[propKey] === true ? 'w-[49%] h-[100%] bg-[#0867FC] motion-left'
                    : 'w-[49%] h-[100%] bg-[#0867FC] motion-right'
                  } items-left text-[.7vmin]`}
                style={{ borderRadius: '2.5Vmin' }}
              ></div>
            </div>
            <div className={`${isDarkMode ? 'text-[#ffffff]' : options[propKey] === false  ? 'text-[#000000]' : 'text-[#989191]'} text-[1.8Vmin] item-hidden`} style={{ fontFamily: 'Inter', fontWeight: '500' }}>Navbar</div>
          </div>
          <div className='text-center toggle-result'>
            {options[propKey] === true ? 'Sidebar' : 'Navbar'}
          </div>
        </div>
        ) : type === 'header Auth' ? (
          <AuthSettings
            props={props}
            setProp={setProp}
            propKey={propKey}
            node={node}
            value={value}
            options={options}
            activeId={activeId}
            propValue={propValue}
            />
          
        ) : type === 'properties' ? (
          <div className={`w-[100%] py-[1vmin] uppercase text-[2.5vmin] text-center font-bold font-["Inter"] ${isDarkMode ? 'text-[#fff]' : 'text-[#161717]'}`}>{props.title ? props.title : 'properties'}</div>

        ) : type === 'itemlist' ? (
          <div>
            <div>
          <h4 className='mb-[1.5vmin]'>Categories</h4>
          {options['categories'].map((category, index) => (
            <div className='container border-l border-[#0867FC] py-[.5vmin]' key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
              <div style={{ color: '#000', flexGrow: 1, fontSize:'1.8vmin' }}>{category}</div>
              <div 
                onClick={() => handleRemoveCategory(index)} 
                className='bg-[#0867FC] hover:bg-[#0867FC95]'
                style={{display:'flex', alignItems:'center', justifyContent:'center', marginLeft: '1vmin', padding:'.5vmin', color: '#fff', border: 'none', fontSize:'1.8vmin', borderRadius: '2vmin', cursor: 'pointer' }}
              >
                <DeleteNameIcon fill='#fff'/>
              </div>
            </div>
          ))}
          <div className='flex justify-between w-full items-center flex-wrap'>
         
          <input
          className='border-[#e9e9e9] border-solid border-1 hover:border-[#0867FC95]'
          style={{color:'#000', borderRadius:'2vmin', maxHeight:'3.5vmin', fontSize:'1.8vmin', maxWidth:'60%', margin:'auto auto'}}
            type="text"
            value={newCategory}
            onChange={(e) => setNewCategory(e.target.value)}
            placeholder="Add new category"
          />
          <span className='mx-auto cursor-pointer rounded-[2vmin] px-[1vmin] hover:text-[#0867FC]' onClick={handleAddCategory}>Add Category</span>
          </div>

        </div>
          </div>
        ) : type === 'Switch' ? (
          <ShapesPositions
            isDarkMode={isDarkMode}
            options={options}
            shapeTop={shapeTop}
            shapeRight={shapeRight}
            shapeLeft={shapeLeft}
            shapeBottom={shapeBottom}
            value={value}
            number={number}
            style={style}
            color={color}
            type={type}
            propKey={propKey}
            setProp={setProp}
            optionsDrop={optionsDrop}
            node={node}
            onChange={onChange}
            props={props}
          />
        ) : type === 'select-box' ? (
          <SelectBox
            propKey={propKey}
            propKey2={propKey2}
            propKey3={propKey3}
            propKey4={propKey4}
            propKey5={propKey5}
            propKey6={propKey6}
            propKey7={propKey7}
            setProp={setProp}
            props={props}
            RotateLeftIcon={RotateLeftIcon}
            OpenWithIcon={OpenWithIcon}
            OpenInFullIcon={OpenInFullIcon}
            options={options}
            type={type}
            noinput={noinput}
            isDarkMode={isDarkMode}
            selectBoxes={selectBoxes}
            node={node}
          />
        ) : type === 'menulist' ? (
          <Widget_Nav
          props={props}
          setProp={setProp}
          propKey={propKey}
          node={node}
          value={value}
          options={options}
          propValue={propValue}
          />
        ) : type === 'auth' ? (
          <AuthSettings
            props={props}
            setProp={setProp}
            propKey={
              activeId.name === 'Logout Confirm' ? LogoutPropKey
                : activeId.name === 'Register' ? RegisterPropKey
                  : activeId.name === 'Register User Profile' ? RegisterUserPropKey
                    : activeId.name === 'Error' ? ErrorMessagePropKey
                      : activeId.name === 'Login Reset Password' ? ResetPasswordPropKey
                        : activeId.name === 'Login Verify Email' ? VerifyEmailPropKey
                          : activeId.name === 'Login Oauth2 Device Verify User Code' ? LoginVerifyUserCodePropKey
                            : activeId.name === 'Login Oauth Grant' ? LoginAuthGrantPropKey
                              : activeId.name === 'Login Otp' ? LoginOtpPropKey
                                : activeId.name === 'Login Username' ? LoginUsernamePropKey
                                  : activeId.name === 'Login Password' ? LoginPasswordPropKey
                                    : activeId.name === 'Login Update Password' ? LoginUpdatePasswordContnetsPropKey
                                      : activeId.name === 'Login Idp Link Confirm' ? LoginLinkConfirmPropKey
                                        : activeId.name === 'Login Page Expired' ? LoginpageExpiredContentsPropKey
                                          : activeId.name === 'Login Config Totp' ? LoginConfigTotpContentsPropKey
                                            : activeId.name === 'Login Update Profile' ? LoginUpdateProfileContentsPropKey
                                              : activeId.name === 'Update User Profile' ? UpdateUserProfilePropKey
                                                : activeId.name === 'Idp Review User Profile' ? ReviewUserProfilePropKey
                                                  : activeId.name === 'Update Email' ? UpdateEmailContentsPropKey
                                                    : loginPropKey
            }
            node={node}
            value={
              activeId.name === 'Logout Confirm' ? logoutValue
                : activeId.name === 'Register' ? registerValue
                  : activeId.name === 'Register User Profile' ? registerUserValue
                    : activeId.name === 'Error' ? errorMessageValue
                      : activeId.name === 'Login Reset Password' ? resetPasswordValue
                        : activeId.name === 'Login Verify Email' ? verifyEmailValue
                          : activeId.name === 'Login Oauth2 Device Verify User Code' ? verifyUserCodeValue
                            : activeId.name === 'Login Oauth Grant' ? loginAuthGrantValue
                              : activeId.name === 'Login Otp' ? loginOtpValue
                                : activeId.name === 'Login Username' ? loginUsernameValue
                                  : activeId.name === 'Login Password' ? loginPasswordValue
                                    : activeId.name === 'Login Update Password' ? LoginUpdatePasswordContnetsValue
                                      : activeId.name === 'Login Idp Link Confirm' ? LoginLinkConfirmValue
                                        : activeId.name === 'Login Page Expired' ? LoginpageExpiredContentsValue
                                          : activeId.name === 'Login Config Totp' ? LoginConfigTotpContentsValue
                                            : activeId.name === 'Login Update Profile' ? LoginUpdateProfileContentsValue
                                              : activeId.name === 'Update User Profile' ? UpdateUserProfileValue
                                                : activeId.name === 'Idp Review User Profile' ? ReviewUserProfileValue
                                                  : activeId.name === 'Update Email' ? UpdateEmailContentsValue
                                                    : loginValue
            }
            propValue={
              activeId.name === 'Logout Confirm' ? logoutPropValue
              : activeId.name === 'Register' ? registerPropValue
                : activeId.name === 'Register User Profile' ? registerUserPropValue
                  : activeId.name === 'Error' ? errorMessagePropValue
                    : activeId.name === 'Login Reset Password' ? resetPasswordPropValue
                      : activeId.name === 'Login Verify Email' ? verifyEmailPropValue
                        : activeId.name === 'Login Oauth2 Device Verify User Code' ? loginVerifyUserCodePropValue
                          : activeId.name === 'Login Oauth Grant' ? loginAuthGrantPropValue
                            : activeId.name === 'Login Otp' ? loginOtpPropValue
                              : activeId.name === 'Login Username' ? loginUsernamePropValue
                                : activeId.name === 'Login Password' ? loginPasswordPropValue
                                  : activeId.name === 'Login Update Password' ? LoginUpdatePasswordContnetsPropValue
                                    : activeId.name === 'Login Idp Link Confirm' ? LoginLinkConfirmPropValue
                                      : activeId.name === 'Login Page Expired' ? LoginpageExpiredContentsPropValue
                                        : activeId.name === 'Login Config Totp' ? LoginConfigTotpContentsPropValue
                                          : activeId.name === 'Login Update Profile' ? LoginUpdateProfileContentsPropValue
                                            : activeId.name === 'Update User Profile' ? UpdateUserProfilePropValue
                                              : activeId.name === 'Idp Review User Profile' ? ReviewUserProfilePropValue
                                                : activeId.name === 'Update Email' ? UpdateEmailContentsPropValue
                                                  : loginPropValue
            }
            options={options}
            activeId={activeId}
          />
        )
          : null}
      </div>
    </Grid>
  );
};