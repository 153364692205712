/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, { useEffect } from 'react';
import { ContainerSettings } from './ContainerSettings.js';
import { Element, useEditor, useNode } from '@craftjs/core';
import { Parent } from '../selectors/Parent/index.js';
import { Banner_1 } from '../selectors/blocks/Banner/Banner1.js';
import { Banner_2 } from '../selectors/blocks/Banner/Banner2.js';

export const Container = (props) => {

  const {
    connectors: {
      connect
    },
    selected,
    parent,
  } = useNode(node => ({
    selected: node.events.selected,
    parent: node.data.parent,
  }));


  const {  actions: { selectNode }, } = useEditor();

  const {
    flexDirection =  'column',
    alignItems =  'flex-start',
    justifyContent =  'flex-start',
    fillSpace =  'no',
    background =  '#e9e9e9',
    startImage =  false,
    color =  '#000',
    shadow =  0,
    radius =  0,
    // width =  '100%',
    // height =  'auto',
    width =  "100%",
    child =  false,
    height = "300px",
    padding,
    margin,
    children,
    border,
    notMain,
  } = props;





 
  let heightt = height || "100px"


  console.log(selected, 109)

  useEffect(() => {
    if(selected) {
      selectNode(parent)
    }
  },[selected])
  return (
    <div className={`${selected ? "selectedNodeIsActive" : "notSelectedNode"}`} style={{...props, border:!notMain ? "0px solid red" : "1px dashed gray", height:"100%", minHeight: !notMain ? "100px" : heightt, width: width || "100%", background: background || "#10101010", display:"flex", flexDirection:"row", flexWrap:"wrap"}} ref={connect}>
      {children}
      {notMain && !children ? <div style={{fontWeight:"bold", color:"darkgray", fontSize:"20px", display:"flex", justifyContent:"center", alignItems:"center", height:"100%"}}>Column</div> : ""}
      {!notMain && !children ? <div style={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}> <img width={"100%"} src='https://i.ibb.co/wp0Snw7/Whats-App-Image-2024-10-03-at-7-42-46-PM.jpg'/></div> : null}      
      </div>
  );
};

Container.craft = {
  displayName: 'Container',
  props: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    fillSpace: 'no',
    background: '#e9e9e9',
    startImage: false,
    color: '#000',
    shadow: 0,
    radius: 0,
    width: "100%",
    child: false,
    height:"300px",
  },
  rules: {
    canDrag: () => true,
  },
  related: {
    toolbar: ContainerSettings,
  },
};

