/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, { useEffect, useState } from 'react';
import { Element, useEditor, useNode } from '@craftjs/core';
import { Text } from '../../basic/Text';
import { NewFormSettings } from './newFormSetting'; 
import { TextField, Typography } from '@mui/material';
import './Form.css'
import { Button } from '../../basic/Button';
import { Parent } from '../../Parent/index.js';
import { Wrapper } from '../../wrapper/index.js';
import { BounceLoader } from 'react-spinners';
import { Input } from '../../basic/Input/Index.js';

export const Form_1 = ({selected}) => {
    const { 
        actions: { setProp },
          connectors: {
            connect
          } 
      } = useNode();
    
      
      const {
        enabled,
      } = useEditor((state, query) => ({
        enabled: state.options.enabled,
     
      }));
    const styles = [
       
    <Element canvas is={Parent} id='parentId100' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId200' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-400' is='parent' className='w-[100%] h-full bg-[#FFFDFB] py-20'>
        <Wrapper canvas id='random-id-401' is='container' className='container bg-[#FFFDFB]'>
                <Wrapper canvas id='random-id-402' is='div' className='w-[90%] h-full flex flex-col mx-auto' style={{border:'1px solid #DBD9D9'}}>
                    <Wrapper className='text-center text-[#100103] w-[300px] mx-auto pl-10 flex mb-10 justify-center' canvas style={{display:'inline-block', fontSize:'46px', fontWeight:'bold', fontFamily:'Rubik'}} id='random-id-1' is='div'>
                        <Text text='Simple form'/>
                    </Wrapper>

                    <Wrapper canvas id='random-id-403' is='form'>
                        <Wrapper canvas id='random-id-404' is='parent' className='w-full flex flex-row flex-wrap justify-center mx-auto my-4'>
                            <Wrapper canvas id='random-id-405' is='div' className='w-[15%] min-w-[100px]'>
                                <Wrapper canvas id='random-id-406' is='div' className='w-full text-center text-[#000000] mx-auto flex flex-row justify-center' style={{fontSize:'18px', fontWeight:'500', fontFamily:'Rubik'}}>
                                    <Wrapper className='text-center' canvas style={{display:'inline-block'}} id='random-id-3' is='div'>
                                        <Text text='Name'/>
                                    </Wrapper>
                                    <Wrapper className='text-center' canvas style={{display:'inline-block', color:'#B20312'}} id='random-id-4' is='div'>
                                        <Text text='*'/>
                                    </Wrapper>
                                </Wrapper>
                            </Wrapper>
                            <Wrapper canvas id='random-id-407' is='div' className='w-[60%] min-w-[358px] my-auto'>
                                <Input style={{height:'50px', border:'1px solid #B2031233', fontSize:'14px', fontFamily:'Rubik'}} type="text" name="name" placeholder="Your name" />
                            </Wrapper>
                        </Wrapper>

                        <Wrapper canvas id='random-id-408' is='div' className='w-full flex flex-row flex-wrap justify-center mx-auto my-4'>
                            <Wrapper canvas id='random-id-409' is='div' className='w-[15%] min-w-[100px]'>
                                <Wrapper canvas id='random-id-410' is='div' className='w-full text-center text-[#000000] mx-auto flex flex-row justify-center' style={{fontSize:'18px', fontWeight:'500', fontFamily:'Rubik'}}>
                                    <Wrapper className='text-center' canvas style={{display:'inline-block'}} id='random-id-5' is='div'>
                                        <Text text='Company'/>
                                    </Wrapper>
                                    <Wrapper className='text-center' canvas style={{display:'inline-block', color:'#B20312'}} id='random-id-6' is='div'>
                                        <Text text='*'/>
                                    </Wrapper>
                                </Wrapper>
                            </Wrapper>
                            <Wrapper canvas id='random-id-411' is='div' className='w-[60%] min-w-[358px] my-auto'>
                                <Input style={{height:'50px', border:'1px solid #B2031233', fontSize:'14px', fontFamily:'Rubik'}} type="text" name="company" placeholder="Company" />
                            </Wrapper>
                        </Wrapper>
                        <Wrapper canvas id='random-id-412' is='div' className='w-full flex flex-row flex-wrap justify-center flex-wrap mx-auto my-4'>
                            <Wrapper canvas id='random-id-413' is='div' className='w-[15%] min-w-[100px]'>
                                <Wrapper canvas id='random-id-414' is='div' className='w-full text-center text-[#000000] mx-auto flex flex-row justify-center' style={{fontSize:'18px', fontWeight:'500', fontFamily:'Rubik'}}>
                                    <Wrapper className='text-center' canvas style={{display:'inline-block'}} id='random-id-7' is='div'>
                                        <Text text='Email'/>
                                    </Wrapper>
                                    <Wrapper className='text-center' canvas style={{display:'inline-block', color:'#B20312'}} id='random-id-8' is='div'>
                                        <Text text='*'/>
                                    </Wrapper>
                                </Wrapper>
                            </Wrapper>
                            <Wrapper canvas id='random-id-415' is='div' className='w-[60%] min-w-[358px] my-auto'>
                                <Input style={{height:'50px', border:'1px solid #B2031233', fontSize:'14px', fontFamily:'Rubik'}} type="text" name="email" placeholder="email@mail.com" />
                            </Wrapper>
                        </Wrapper>
                        <Wrapper canvas id='random-id-416' is='div' className='w-full flex flex-row flex-wrap justify-center flex-wrap mx-auto my-4'>
                            <Wrapper canvas id='random-id-417' is='div' className='w-[15%] min-w-[100px]'>
                            <Wrapper canvas id='random-id-418' is='div' className='w-full text-center text-[#000000] mx-auto flex flex-row justify-center' style={{fontSize:'18px', fontWeight:'500', fontFamily:'Rubik'}}>
                                    <Wrapper className='text-center' canvas style={{display:'inline-block'}} id='random-id-9' is='div'>
                                        <Text text='Name'/>
                                    </Wrapper>
                                    <Wrapper className='text-center' canvas style={{display:'inline-block', color:'#B20312'}} id='random-id-10' is='div'>
                                        <Text text='*'/>
                                    </Wrapper>
                                </Wrapper>                            </Wrapper>
                            <Wrapper canvas id='random-id-419' is='div' className='w-[60%] min-w-[358px] my-auto'>
                                <Input style={{height:'50px', border:'1px solid #B2031233', fontSize:'14px', fontFamily:'Rubik'}} type="text" name="phone" placeholder="(123) 456 78 90" />
                            </Wrapper>
                        </Wrapper>
                        <Wrapper canvas id='random-id-420' is='div' className='w-full flex flex-row flex-wrap justify-center flex-wrap mx-auto my-4'>
                            <Wrapper canvas id='random-id-421' is='div' className='w-[15%] min-w-[100px]'>
                            <Wrapper canvas id='random-id-422' is='div' className='w-full text-center text-[#000000] mx-auto flex flex-row justify-center' style={{fontSize:'18px', fontWeight:'500', fontFamily:'Rubik'}}>
                                    <Wrapper className='text-center' canvas style={{display:'inline-block'}} id='random-id-11' is='div'>
                                        <Text text='Phone'/>
                                    </Wrapper>
                                    <Wrapper className='text-center' canvas style={{display:'inline-block', color:'#B20312'}} id='random-id-12' is='div'>
                                        <Text text='*'/>
                                    </Wrapper>
                                </Wrapper>                            </Wrapper>
                            <Wrapper canvas id='random-id-423' is='div' className='w-[60%] min-w-[358px] my-auto'>
                                <Input style={{height:'50px', paddingBottom:'170px', paddingTop:'20px', border:'1px solid #B2031233', fontSize:'14px', fontFamily:'Rubik'}} type="text" name="Message" placeholder="Message.." />
                            </Wrapper>
                        </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-200' is='div' className='mx-auto mb-4'>
                            <Button  classn='block' class='uppercase w-[260px] flex h-[70px] bg-[#B20312] hover:bg-[#87000C] cursor-pointer text-[#FFFFFF]' >
                                <Wrapper canvas id='random-id-1-1' className='mx-auto my-auto' style={{display:'inlineflex', fontSize:'22px', fontWeight:'600', fontFamily:'Rubik'}} is='div'>
                                    <Text text='Confirm'/>
                                </Wrapper>
                            </Button>
                    </Wrapper>
                </Wrapper>
            </Wrapper>
        </Wrapper> </Element>
        </Element>,


<Element canvas is={Parent} id='parentId300' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId400' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-424' is='parent' className='w-[100%] h-full bg-[#121826] py-20'>
<Wrapper canvas id='random-id-425' is='container' className='container' style={{background:'#121826'}}>
        <Wrapper canvas id='random-id-426' is='div' className='w-[90%] h-full flex flex-col mx-auto' style={{border:'1px solid #FFFFFF1A', boxShadow: '0px 0px 4px 0px #FFFFFF1A'}}>
            <Wrapper canvas style={{display:'inline-block', fontSize:'46px', fontWeight:'bold', fontFamily:'Rubik'}} className='text-center IncreaseConversion2 w-[300px] mx-auto pl-10 flex mb-10' id='random-id-13' is='div'>
                <Text text='Simple form'/>
            </Wrapper>
            <Wrapper canvas id='random-id-427' is='form' >
                <Wrapper canvas id='random-id-428' is='div' className='w-full flex flex-row flex-wrap justify-center flex-wrap mx-auto my-4'>
                    <Wrapper canvas id='random-id-429' is='div' className='w-[15%] min-w-[100px]'>
                        <Wrapper canvas id='random-id-430' is='div' className='w-full text-center text-[#FFFFFF] mx-auto flex flex-row justify-center' style={{fontSize:'18px', fontWeight:'500', fontFamily:'Rubik'}}>
                            <Wrapper className='text-center' canvas style={{display:'inline-block'}} id='random-id-14' is='div'>
                                <Text text='Name'/>
                            </Wrapper>
                            <Wrapper className='text-center' canvas style={{display:'inline-block', color:'#4BDBA2'}} id='random-id-15' is='div'>
                                <Text text='*'/>
                            </Wrapper>
                        </Wrapper> 
                    </Wrapper>
                    <Wrapper canvas id='random-id-431' is='div' className='w-[60%] min-w-[358px] my-auto'>
                        <Input className='inpute' style={{color:'#FFFFFF', height:'50px', border:'1px solid #3265E366', fontSize:'14px', fontFamily:'Rubik', background:'transparent'}} type="text" name="name" placeholder="Your name" />
                    </Wrapper>
                </Wrapper>

                <Wrapper canvas id='random-id-432' is='div' className='w-full flex flex-row flex-wrap justify-center flex-wrap mx-auto my-4'>
                    <Wrapper canvas id='random-id-433' is='div' className='w-[15%] min-w-[100px]'>
                    <Wrapper canvas id='random-id-434' is='div' className='w-full text-center text-[#FFFFFF] mx-auto flex flex-row justify-center' style={{fontSize:'18px', fontWeight:'500', fontFamily:'Rubik'}}>
                            <Wrapper className='text-center' canvas style={{display:'inline-block'}} id='random-id-16' is='div'>
                                <Text text='Company'/>
                            </Wrapper>
                            <Wrapper className='text-center' canvas style={{display:'inline-block', color:'#4BDBA2'}} id='random-id-17' is='div'>
                                <Text text='*'/>
                            </Wrapper>
                        </Wrapper>            
                 </Wrapper>
                    <Wrapper canvas id='random-id-435' is='div' className='w-[60%] min-w-[358px] my-auto'>
                        <Input className='inpute' style={{color:'#FFFFFF', height:'50px', border:'1px solid #3265E366', fontSize:'14px', fontFamily:'Rubik', background:'transparent'}} type="text" name="company" placeholder="Company" />
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-436' is='div' className='w-full flex flex-row flex-wrap justify-center flex-wrap mx-auto my-4'>
                    <Wrapper canvas id='random-id-437' is='div' className='w-[15%] min-w-[100px]'>
                    <Wrapper canvas id='random-id-438' is='div' className='w-full text-center text-[#FFFFFF] mx-auto flex flex-row justify-center' style={{fontSize:'18px', fontWeight:'500', fontFamily:'Rubik'}}>
                            <Wrapper className='text-center' canvas style={{display:'inline-block'}} id='random-id-18' is='div'>
                                <Text text='Email'/>
                            </Wrapper>
                            <Wrapper className='text-center' canvas style={{display:'inline-block', color:'#4BDBA2'}} id='random-id-19' is='div'>
                                <Text text='*'/>
                            </Wrapper>
                        </Wrapper>         
                </Wrapper>
                    <Wrapper canvas id='random-id-439' is='div' className='w-[60%] min-w-[358px] my-auto'>
                        <Wrapper canvas id='random-id-440' is='div' className='inpute' style={{color:'#FFFFFF', height:'50px', border:'1px solid #3265E366', fontSize:'14px', fontFamily:'Rubik', background:'transparent'}} type="text" name="email" placeholder="email@mail.com" />
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-441' is='div' className='w-full flex flex-row flex-wrap justify-center flex-wrap mx-auto my-4'>
                    <Wrapper canvas id='random-id-442' is='div' className='w-[15%] min-w-[100px]'>
                    <Wrapper canvas id='random-id-443' is='div' className='w-full text-center text-[#FFFFFF] mx-auto flex flex-row justify-center' style={{fontSize:'18px', fontWeight:'500', fontFamily:'Rubik'}}>
                            <Wrapper className='text-center' canvas style={{display:'inline-block'}} id='random-id-20' is='div'>
                                <Text text='Phone'/>
                            </Wrapper>
                            <Wrapper className='text-center' canvas style={{display:'inline-block', color:'#4BDBA2'}} id='random-id-21' is='div'>
                                <Text text='*'/>
                            </Wrapper>
                        </Wrapper>     
                    </Wrapper>
                    <Wrapper canvas id='random-id-444' is='div' className='w-[60%] min-w-[358px] my-auto'>
                        <Input className='inpute' style={{color:'#FFFFFF', height:'50px', border:'1px solid #3265E366', fontSize:'14px', fontFamily:'Rubik', background:'transparent'}} type="text" name="phone" placeholder="(123) 456 78 90" />
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-445' is='div' className='w-full flex flex-row flex-wrap justify-center flex-wrap mx-auto my-4'>
                    <Wrapper canvas id='random-id-446' is='div' className='w-[15%] min-w-[100px]'>
                    <Wrapper canvas id='random-id-447' is='div' className='w-full text-center text-[#FFFFFF] mx-auto flex flex-row justify-center' style={{fontSize:'18px', fontWeight:'500', fontFamily:'Rubik'}}>
                            <Wrapper className='text-center' canvas style={{display:'inline-block'}} id='random-id-22' is='div'>
                                <Text text='Message'/>
                            </Wrapper>
                            <Wrapper className='text-center' canvas style={{display:'inline-block', color:'#4BDBA2'}} id='random-id-23' is='div'>
                                <Text text='*'/>
                            </Wrapper>
                        </Wrapper>              
                   </Wrapper>
                    <Wrapper canvas id='random-id-448' is='div' className='w-[60%] min-w-[358px] my-auto'>
                        <Input className='inpute' style={{color:'#FFFFFF', height:'50px', paddingBottom:'170px', paddingTop:'20px', border:'1px solid #3265E366', fontSize:'14px', background:'transparent', fontFamily:'Rubik'}} type="text" name="firstname" placeholder="Message.." />
                    </Wrapper>
                </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-201' is='div' className='mx-auto mb-4'>
                    <Button  classn='block' class='uppercase w-[260px] flex h-[70px] cursor-pointer bg-[#3265E3] hover:bg-[#0049FF] text-[#FFFFFF]' style={{ border: '1px solid #FFFFFF1A', boxShadow: '0px 4px 10px 0px #FFFFFF0D'}}>
<Wrapper canvas id='random-id-1-2' className='mx-auto my-auto' style={{display:'inlineflex', fontSize:'22px', fontWeight:'600', fontFamily:'Rubik',}} is='div'>
                                    <Text text='Confirm'/>
                                </Wrapper>
                        
                    </Button>
            </Wrapper>
        </Wrapper>
    </Wrapper>
</Wrapper> </Element>
        </Element>,




<Element canvas is={Parent} id='parentId500' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId600' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-549' is='parent' className='w-[100%] h-full bg-[#213156] py-20'>
<Wrapper canvas id='random-id-450' is='container' className='container' style={{background:'#213156'}}>
        <Wrapper canvas id='random-id-451' is='div' className='w-[90%] h-full flex flex-col mx-auto' style={{border:'1px solid #EA6EF41A', boxShadow: '0px 0px 4px 0px #FFFFFF1A'}}>
        <Wrapper className='text-center text-[#EA6EF4] w-[300px] mx-auto pl-10 flex mb-10' canvas style={{display:'inline-block', fontSize:'46px', fontWeight:'bold', fontFamily:'Rubik'}} id='random-id-24' is='div'>
                <Text text='Simple form'/>
            </Wrapper>

            <Wrapper canvas id='random-id-452' is='form' >
                <Wrapper canvas id='random-id-453' is='div' className='w-full flex flex-row flex-wrap justify-center mx-auto flex-wrap my-4'>
                    <Wrapper canvas id='random-id-454' is='div' className='w-[15%] min-w-[100px]'>

                        <Wrapper canvas id='random-id-455' is='div' className='w-full text-center text-[#FFFFFF] mx-auto flex flex-row justify-center' style={{fontSize:'18px', fontWeight:'500', fontFamily:'Open Sans'}}>
                            <Wrapper className='text-center' canvas style={{display:'inline-block'}} id='random-id-25' is='div'>
                                <Text text='Name'/>
                            </Wrapper>
                            <Wrapper className='text-center' canvas style={{display:'inline-block', color:'#EA6EF4'}} id='random-id-26' is='div'>
                                <Text text='*'/>
                            </Wrapper>
                        </Wrapper> 
                    </Wrapper>
                    <Wrapper canvas id='random-id-456' is='div' className='w-[60%] min-w-[358px] my-auto'>
                        <Input className='inpute' style={{color:'#FFFFFF', height:'50px', border:'1px solid #EA6EF466', borderRadius:'10px', fontSize:'14px', fontFamily:'Open Sans', background:'transparent'}} type="text" name="name" placeholder="Your name" />
                    </Wrapper>
                </Wrapper>

                <Wrapper canvas id='random-id-457' is='div' className='w-full flex flex-row flex-wrap justify-center mx-auto flex-wrap my-4'>
                    <Wrapper canvas id='random-id-458' is='div' className='w-[15%] min-w-[100px]'>
                    <Wrapper canvas id='random-id-459' is='div' className='w-full text-center text-[#FFFFFF] mx-auto flex flex-row justify-center' style={{fontSize:'18px', fontWeight:'500', fontFamily:'Open Sans'}}>
                            <Wrapper className='text-center' canvas style={{display:'inline-block'}} id='random-id-27' is='div'>
                                <Text text='Company'/>
                            </Wrapper>
                            <Wrapper className='text-center' canvas style={{display:'inline-block', color:'#EA6EF4'}} id='random-id-28' is='div'>
                                <Text text='*'/>
                            </Wrapper>
                        </Wrapper>                     </Wrapper>
                    <Wrapper canvas id='random-id-460' is='div' className='w-[60%] min-w-[358px] my-auto'>
                        <Input className='inpute' style={{color:'#FFFFFF', height:'50px', border:'1px solid #EA6EF466', borderRadius:'10px', fontSize:'14px', fontFamily:'Open Sans', background:'transparent'}} type="text" name="company" placeholder="Company" />
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-461' is='div' className='w-full flex flex-row flex-wrap justify-center mx-auto flex-wrap my-4'>
                    <Wrapper canvas id='random-id-462' is='div' className='w-[15%] min-w-[100px]'>
                    <Wrapper canvas id='random-id-463' is='div' className='w-full text-center text-[#FFFFFF] mx-auto flex flex-row justify-center' style={{fontSize:'18px', fontWeight:'500', fontFamily:'Open Sans'}}>
                            <Wrapper className='text-center' canvas style={{display:'inline-block'}} id='random-id-29' is='div'>
                                <Text text='Email'/>
                            </Wrapper>
                            <Wrapper className='text-center' canvas style={{display:'inline-block', color:'#EA6EF4'}} id='random-id-30' is='div'>
                                <Text text='*'/>
                            </Wrapper>
                    </Wrapper>
                </Wrapper>
                    <Wrapper canvas id='random-id-464' is='div' className='w-[60%] min-w-[358px] my-auto'>
                        <Input className='inpute' style={{color:'#FFFFFF', height:'50px', border:'1px solid #EA6EF466', borderRadius:'10px', fontSize:'14px', fontFamily:'Open Sans', background:'transparent'}} type="text" name="email" placeholder="email@mail.com" />
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-465' is='div' className='w-full flex flex-row flex-wrap justify-center mx-auto flex-wrap my-4'>
                    <Wrapper canvas id='random-id-466' is='div' className='w-[15%] min-w-[100px]'>
                    <Wrapper canvas id='random-id-467' is='div' className='w-full text-center text-[#FFFFFF] mx-auto flex flex-row justify-center' style={{fontSize:'18px', fontWeight:'500', fontFamily:'Open Sans'}}>
                    <Wrapper className='text-center' canvas style={{display:'inline-block'}} id='random-id-31' is='div'>
                                <Text text='Phone'/>
                            </Wrapper>
                            <Wrapper className='text-center' canvas style={{display:'inline-block', color:'#EA6EF4'}} id='random-id-32' is='div'>
                                <Text text='*'/>
                            </Wrapper>
                    </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-468' is='div' className='w-[60%] min-w-[358px] my-auto'>
                        <Input className='inpute' style={{color:'#FFFFFF', height:'50px', border:'1px solid #EA6EF466', borderRadius:'10px', fontSize:'14px', fontFamily:'Open Sans', background:'transparent'}} type="text" name="phone" placeholder="(123) 456 78 90" />
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-469' is='div' className='w-full flex flex-row flex-wrap justify-center mx-auto flex-wrap my-4'>
                    <Wrapper canvas id='random-id-470' is='div' className='w-[15%] min-w-[100px]'>
                    <Wrapper canvas id='random-id-471' is='div' className='w-full text-center text-[#FFFFFF] mx-auto flex flex-row justify-center' style={{fontSize:'18px', fontWeight:'500', fontFamily:'Open Sans'}}>
                            <Wrapper className='text-center' canvas style={{display:'inline-block'}} id='random-id-33' is='div'>
                                <Text text='Message'/>
                            </Wrapper>
                            <Wrapper className='text-center' canvas style={{display:'inline-block', color:'#EA6EF4'}} id='random-id-34' is='div'>
                                <Text text='*'/>
                            </Wrapper>
                    </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-472' is='div' className='w-[60%] min-w-[358px] my-auto'>
                        <Input className='inpute' style={{color:'#FFFFFF', height:'50px', paddingBottom:'170px', paddingTop:'20px', border:'1px solid #EA6EF466', borderRadius:'10px', fontSize:'14px', background:'transparent', fontFamily:'Open Sans'}} type="text" name="firstname" placeholder="Message.." />
                    </Wrapper>
                </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-202' is='div' className='mx-auto mb-4'>
                    <Button  classn='block' class='uppercase cursor-pointer w-[260px] flex h-[70px] bg-[#EA6EF4] hover:bg-[#E111F2] text-[#FFFFFF]' style={{ border: '1px solid #213156', borderRadius:'40px', boxShadow: '0px 4px 10px 0px #FFFFFF0D'}}>
                    <Wrapper canvas id='random-id-1-3' className='mx-auto my-auto' style={{display:'inlineflex', fontSize:'22px', fontWeight:'600', fontFamily:'Open Sans',}} is='div'>
                                    <Text text='Confirm'/>
                                </Wrapper>
                    </Button>
            </Wrapper>
        </Wrapper>
    </Wrapper>
</Wrapper> </Element>
        </Element>,



<Element canvas is={Parent} id='parentId700' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId800' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-473' is='parent' className='w-[100%] h-full bg-[#DFFFF9] py-20'>
<Wrapper canvas id='random-id-474' is='container' className='container' style={{background:'#DFFFF9'}}>
        <Wrapper canvas id='random-id-475' is='div' className='w-[90%] h-full flex flex-col mx-auto' style={{border:'1px solid #31A9931A', borderRadius:'30px'}}>
        <Wrapper className='text-center text-[#31A993] w-[300px] mx-auto pl-10 flex mb-10' canvas style={{display:'inline-block', fontSize:'46px', fontWeight:'100px', fontFamily:'Zing Script Rust SemiBold Demo', fontStyle:'italic'}} id='random-id-35' is='div'>
                <Text text='Simple form'/>
            </Wrapper>

            <Wrapper canvas id='random-id-476' is='form' >
                <Wrapper canvas id='random-id-477' is='div' className='w-full flex flex-row flex-wrap justify-center mx-auto flex-wrap my-4'>
                    <Wrapper canvas id='random-id-478' is='div' className='w-[15%] min-w-[100px]'>

                        <Wrapper canvas id='random-id-479' is='div' className='w-full text-center text-[#000000] mx-auto flex flex-row justify-center' style={{fontSize:'18px', fontWeight:'500', fontFamily:'Montserrat'}}>
                            <Wrapper className='text-center' canvas style={{display:'inline-block'}} id='random-id-36' is='div'>
                                <Text text='Name'/>
                            </Wrapper>
                            <Wrapper className='text-center' canvas style={{display:'inline-block', color:'#31A993'}} id='random-id-37' is='div'>
                                <Text text='*'/>
                            </Wrapper>
                        </Wrapper> 
                    </Wrapper>
                    <Wrapper canvas id='random-id-480' is='div' className='w-[60%] min-w-[358px] my-auto'>
                        <Input className='input-4' style={{color:'#000000', height:'50px', border:'1px solid #31A99366', borderRadius:'30px', fontSize:'14px', fontFamily:'Montserrat', background:'transparent'}} type="text" name="name" placeholder="Your name" />
                    </Wrapper>
                </Wrapper>

                <Wrapper canvas id='random-id-481' is='div' className='w-full flex flex-row flex-wrap justify-center mx-auto flex-wrap my-4'>
                    <Wrapper canvas id='random-id-482' is='div' className='w-[15%] min-w-[100px]'>
                    <Wrapper canvas id='random-id-483' is='div' className='w-full text-center text-[#000000] mx-auto flex flex-row justify-center' style={{fontSize:'18px', fontWeight:'500', fontFamily:'Montserrat'}}>
                            <Wrapper className='text-center' canvas style={{display:'inline-block'}} id='random-id-38' is='div'>
                                <Text text='Company'/>
                            </Wrapper>
                            <Wrapper className='text-center' canvas style={{display:'inline-block', color:'#31A993'}} id='random-id-39' is='div'>
                                <Text text='*'/>
                            </Wrapper>
                        </Wrapper>                     </Wrapper>
                    <Wrapper canvas id='random-id-484' is='div' className='w-[60%] min-w-[358px] my-auto'>
                        <Input className='input-4' style={{color:'#000000', height:'50px', border:'1px solid #31A99366', borderRadius:'30px', fontSize:'14px', fontFamily:'Montserrat', background:'transparent'}} type="text" name="company" placeholder="Company" />
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-485' is='div' className='w-full flex flex-row flex-wrap justify-center mx-auto flex-wrap my-4'>
                    <Wrapper canvas id='random-id-486' is='div' className='w-[15%] min-w-[100px]'>
                    <Wrapper canvas id='random-id-487' is='div' className='w-full text-center text-[#000000] mx-auto flex flex-row justify-center' style={{fontSize:'18px', fontWeight:'500', fontFamily:'Montserrat'}}>
                            <Wrapper className='text-center' canvas style={{display:'inline-block'}} id='random-id-40' is='div'>
                                <Text text='Email'/>
                            </Wrapper>
                            <Wrapper className='text-center' canvas style={{display:'inline-block', color:'#31A993'}} id='random-id-41' is='div'>
                                <Text text='*'/>
                            </Wrapper>
                    </Wrapper>
                </Wrapper>
                    <Wrapper canvas id='random-id-488' is='div' className='w-[60%] min-w-[358px] my-auto'>
                        <Input className='input-4' style={{color:'#000000', height:'50px', border:'1px solid #31A99366', borderRadius:'30px', fontSize:'14px', fontFamily:'Montserrat', background:'transparent'}} type="text" name="email" placeholder="email@mail.com" />
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-489' is='div' className='w-full flex flex-row flex-wrap justify-center mx-auto flex-wrap my-4'>
                    <Wrapper canvas id='random-id-490' is='div' className='w-[15%] min-w-[100px]'>
                    <Wrapper canvas id='random-id-491' is='div' className='w-full text-center text-[#000000] mx-auto flex flex-row justify-center' style={{fontSize:'18px', fontWeight:'500', fontFamily:'Montserrat'}}>
                    <Wrapper className='text-center' canvas style={{display:'inline-block'}} id='random-id-42' is='div'>
                                <Text text='Phone'/>
                            </Wrapper>
                            <Wrapper className='text-center' canvas style={{display:'inline-block', color:'#31A993'}} id='random-id-43' is='div'>
                                <Text text='*'/>
                            </Wrapper>
                    </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-492' is='div' className='w-[60%] min-w-[358px] my-auto'>
                        <Input className='input-4' style={{color:'#000000', height:'50px', border:'1px solid #31A99366', borderRadius:'30px', fontSize:'14px', fontFamily:'Montserrat', background:'transparent'}} type="text" name="phone" placeholder="(123) 456 78 90" />
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-493' is='div' className='w-full flex flex-row flex-wrap justify-center mx-auto flex-wrap my-4'>
                    <Wrapper canvas id='random-id-494' is='div' className='w-[15%] min-w-[100px]'>
                    <Wrapper canvas id='random-id-495' is='div' className='w-full text-center text-[#000000] mx-auto flex flex-row justify-center' style={{fontSize:'18px', fontWeight:'500', fontFamily:'Montserrat'}}>
                            <Wrapper className='text-center' canvas style={{display:'inline-block'}} id='random-id-44' is='div'>
                                <Text text='Message'/>
                            </Wrapper>
                            <Wrapper className='text-center' canvas style={{display:'inline-block', color:'#31A993'}} id='random-id-45' is='div'>
                                <Text text='*'/>
                            </Wrapper>
                    </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-496' is='div' className='w-[60%] min-w-[358px] my-auto'>
                        <Input className='input-4' style={{color:'#000000', height:'50px', paddingBottom:'170px', paddingTop:'20px', border:'1px solid #31A99366', borderRadius:'30px', fontSize:'14px', background:'transparent', fontFamily:'Montserrat'}} type="text" name="firstname" placeholder="Message.." />
                    </Wrapper>
                </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-203' is='div' className='mx-auto mb-4'>
                    <Button  classn='block' class='uppercase cursor-pointer w-[260px] flex h-[70px] bg-[#31a993] hover:bg-[#11816C] text-[#FFFFFF]' style={{border: '1px solid #31A993',borderRadius:'30px'}}>
                    <Wrapper canvas id='random-id-1-4' className='mx-auto my-auto =' style={{display:'inlineflex', fontSize:'22px', fontWeight:'600', fontFamily:'Montserrat', }} is='div'>
                                    <Text text='Confirm'/>
                                </Wrapper>
                    </Button>
            </Wrapper>
        </Wrapper>
    </Wrapper>
</Wrapper> </Element>
        </Element>,




<Element canvas is={Parent} id='parentId900' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId1000' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-497' is='parent' className='w-[100%] h-full bg-[#FFFFFF] py-20'>
<Wrapper canvas id='random-id-498' is='container' className='container' style={{background:'#FFFFFF'}}>
        <Wrapper canvas id='random-id-499' is='div' className='w-[90%] h-full flex flex-col mx-auto' style={{border:'1px solid #FFFFFF1A', boxShadow: '0px 0px 4px 0px #FFFFFF1A'}}>
            <Wrapper className='text-center text-[#0078D3] w-[300px] mx-auto pl-10 flex mb-10' canvas style={{display:'inline-block', fontSize:'46px', fontWeight:'bold', fontFamily:'Inter'}} id='random-id-46' is='div'>
                <Text text='Simple form'/>
            </Wrapper>
            <Wrapper canvas id='random-id-500' is='form' >
                <Wrapper canvas id='random-id-501' is='div' className='w-full flex flex-row flex-wrap justify-center mx-auto flex-wrap my-4'>
                    <Wrapper canvas id='random-id-502' is='div' className='w-[15%] min-w-[100px]'>
                        <Wrapper canvas id='random-id-503' is='div' className='w-full text-center text-[#000000] mx-auto flex flex-row justify-center' style={{fontSize:'18px', fontWeight:'500', fontFamily:'Inter'}}>
                            <Wrapper className='text-center' canvas style={{display:'inline-block'}} id='random-id-47' is='div'>
                                <Text text='Name'/>
                            </Wrapper>
                            <Wrapper className='text-center' canvas style={{display:'inline-block', color:'#0078D3'}} id='random-id-48' is='div'>
                                <Text text='*'/>
                            </Wrapper>
                        </Wrapper> 
                    </Wrapper>
                    <Wrapper canvas id='random-id-504' is='div' className='w-[60%] min-w-[358px] my-auto'>
                        <Input className='input-5' style={{color:'#FFFFFF', height:'50px', border:'1px solid #0078D366', borderRadius:'4px', fontSize:'14px', fontFamily:'Inter', background:'transparent'}} type="text" name="name" placeholder="Your name" />
                    </Wrapper>
                </Wrapper>

                <Wrapper canvas id='random-id-505' is='div' className='w-full flex flex-row flex-wrap justify-center mx-auto flex-wrap my-4'>
                    <Wrapper canvas id='random-id-506' is='div' className='w-[15%] min-w-[100px]'>
                    <Wrapper canvas id='random-id-507' is='div' className='w-full text-center text-[#000000] mx-auto flex flex-row justify-center' style={{fontSize:'18px', fontWeight:'500', fontFamily:'Inter'}}>
                            <Wrapper className='text-center' canvas style={{display:'inline-block'}} id='random-id-49' is='div'>
                                <Text text='Company'/>
                            </Wrapper>
                            <Wrapper className='text-center' canvas style={{display:'inline-block', color:'#0078D3'}} id='random-id-50' is='div'>
                                <Text text='*'/>
                            </Wrapper>
                        </Wrapper>                     </Wrapper>
                    <Wrapper canvas id='random-id-508' is='div' className='w-[60%] min-w-[358px] my-auto'>
                        <Input className='input-5' style={{color:'#FFFFFF', height:'50px', border:'1px solid #0078D366', borderRadius:'4px', fontSize:'14px', fontFamily:'Inter', background:'transparent'}} type="text" name="company" placeholder="Company" />
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-509' is='div' className='w-full flex flex-row flex-wrap justify-center mx-auto flex-wrap my-4'>
                    <Wrapper canvas id='random-id-510' is='div' className='w-[15%] min-w-[100px]'>
                    <Wrapper canvas id='random-id-511' is='div' className='w-full text-center text-[#000000] mx-auto flex flex-row justify-center' style={{fontSize:'18px', fontWeight:'500', fontFamily:'Inter'}}>
                            <Wrapper className='text-center' canvas style={{display:'inline-block'}} id='random-id-51' is='div'>
                                <Text text='Email'/>
                            </Wrapper>
                            <Wrapper className='text-center' canvas style={{display:'inline-block', color:'#0078D3'}} id='random-id-52' is='div'>
                                <Text text='*'/>
                            </Wrapper>
                        </Wrapper>                     </Wrapper>
                    <Wrapper canvas id='random-id-512' is='div' className='w-[60%] min-w-[358px] my-auto'>
                        <Input className='input-5' style={{color:'#FFFFFF', height:'50px', border:'1px solid #0078D366', borderRadius:'4px', fontSize:'14px', fontFamily:'Inter', background:'transparent'}} type="text" name="email" placeholder="email@mail.com" />
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-513' is='div' className='w-full flex flex-row flex-wrap justify-center mx-auto flex-wrap my-4'>
                    <Wrapper canvas id='random-id-514' is='div' className='w-[15%] min-w-[100px]'>
                    <Wrapper canvas id='random-id-515' is='div' className='w-full text-center text-[#000000] mx-auto flex flex-row justify-center' style={{fontSize:'18px', fontWeight:'500', fontFamily:'Inter'}}>
                            <Wrapper className='text-center' canvas style={{display:'inline-block'}} id='random-id-53' is='div'>
                                <Text text='Phone'/>
                            </Wrapper>
                            <Wrapper className='text-center' canvas style={{display:'inline-block', color:'#0078D3'}} id='random-id-54' is='div'>
                                <Text text='*'/>
                            </Wrapper>
                        </Wrapper>                     </Wrapper>
                    <Wrapper canvas id='random-id-516' is='div' className='w-[60%] min-w-[358px] my-auto'>
                        <Input className='input-5' style={{color:'#FFFFFF', height:'50px', border:'1px solid #0078D366', borderRadius:'4px', fontSize:'14px', fontFamily:'Inter', background:'transparent'}} type="text" name="phone" placeholder="(123) 456 78 90" />
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-517' is='div' className='w-full flex flex-row flex-wrap justify-center mx-auto flex-wrap my-4'>
                    <Wrapper canvas id='random-id-518' is='div' className='w-[15%] min-w-[100px]'>
                    <Wrapper canvas id='random-id-519' is='div' className='w-full text-center text-[#000000] mx-auto flex flex-row justify-center' style={{fontSize:'18px', fontWeight:'500', fontFamily:'Inter'}}>
                            <Wrapper className='text-center' canvas style={{display:'inline-block'}} id='random-id-55' is='div'>
                                <Text text='Message'/>
                            </Wrapper>
                            <Wrapper className='text-center' canvas style={{display:'inline-block', color:'#0078D3'}} id='random-id-56' is='div'>
                                <Text text='*'/>
                            </Wrapper>
                        </Wrapper>                     </Wrapper>
                    <Wrapper canvas id='random-id-520' is='div' className='w-[60%] min-w-[358px] my-auto'>
                        <Input className='input-5' style={{color:'#FFFFFF', height:'50px', paddingBottom:'170px', paddingTop:'20px', border:'1px solid #0078D366', borderRadius:'4px', fontSize:'14px', background:'transparent', fontFamily:'Inter'}} type="text" name="firstname" placeholder="Message.." />
                    </Wrapper>
                </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-204' is='div' className='mx-auto mb-4'>
                    <Button  classn='block' class='uppercase cursor-pointer w-[260px] flex h-[70px] bg-[#0078d3] hover:bg-[#11578B] text-[#FFFFFF]' style={{ boxShadow: '0px 4px 10px 0px #0000001A', borderRadius:'4px'}}>
                    <Wrapper canvas id='random-id-1-5' className='mx-auto my-auto' style={{display:'inlineflex', fontSize:'22px', fontWeight:'600', fontFamily:'Inter',}} is='div'>
                                    <Text text='Confirm'/>
                                </Wrapper>
                    </Button>
            </Wrapper>
        </Wrapper>
    </Wrapper>
</Wrapper> </Element>
        </Element>,

<Element canvas is={Parent} id='parentId1100' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId1200' className="w-[100%] h-auto" row={true} >
            <Wrapper canvas id='random-id-521' is='parent' className='w-[100%] h-full bg-[#FFFFFF] py-20'>
<Wrapper canvas id='random-id-522' is='container' className='container' style={{background:'#FFFFFF'}}>
        <Wrapper canvas id='random-id-523' is='div' className='w-[90%] h-full flex flex-col mx-auto bg-[#F8F8F8]' style={{border:'1px solid #0202120A', boxShadow: '0px 0px 4px 0px #FFFFFF1A'}}>
            <Wrapper className='text-center text-[#020212] w-[300px] mx-auto pl-10 flex mb-10' canvas style={{display:'inline-block', fontSize:'46px', fontWeight:'bold', fontFamily:'Voces'}} id='random-id-57' is='div'>
                <Text text='Simple form'/>
            </Wrapper>
            <Wrapper canvas id='random-id-524' is='form' >
                <Wrapper canvas id='random-id-525' is='div' className='w-full flex flex-row flex-wrap justify-center mx-auto flex-wrap my-4'>
                    <Wrapper canvas id='random-id-526' is='div' className='w-[15%] min-w-[100px]'>
                        <Wrapper canvas id='random-id-527' is='div' className='w-full text-center text-[#020212] justify-center' style={{fontSize:'18px', fontWeight:'500', fontFamily:'Voces'}}>
                            <Wrapper className='text-center' canvas style={{display:'inline-block'}} id='random-id-58' is='div'>
                                <Text text='Name*'/>
                            </Wrapper>
                            
                        </Wrapper> 
                    </Wrapper>
                    <Wrapper canvas id='random-id-528' is='div' className='w-[60%] min-w-[358px] my-auto'>
                        <Input className='input-6' style={{color:'#020212', height:'50px', border:'1px solid #02021233',  fontSize:'14px', fontFamily:'Voces', background:'transparent'}} type="text" name="name" placeholder="Your name" />
                    </Wrapper>
                </Wrapper>

                <Wrapper canvas id='random-id-529' is='div' className='w-full flex flex-row flex-wrap justify-center mx-auto flex-wrap my-4'>
                    <Wrapper canvas id='random-id-530' is='div' className='w-[15%] min-w-[100px]'>
                    <Wrapper canvas id='random-id-531' is='div' className='w-full text-center text-[#020212] justify-center' style={{fontSize:'18px', fontWeight:'500', fontFamily:'Voces'}}>
                            <Wrapper className='text-center' canvas style={{display:'inline-block'}} id='random-id-59' is='div'>
                                <Text text='Company*'/>
                            </Wrapper>
                           
                        </Wrapper>                     </Wrapper>
                    <Wrapper canvas id='random-id-532' is='div' className='w-[60%] min-w-[358px] my-auto'>
                        <Input className='input-6' style={{color:'#020212', height:'50px', border:'1px solid #02021233',  fontSize:'14px', fontFamily:'Voces', background:'transparent'}} type="text" name="company" placeholder="Company" />
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-533' is='div' className='w-full flex flex-row flex-wrap justify-center mx-auto flex-wrap my-4'>
                    <Wrapper canvas id='random-id-534' is='div' className='w-[15%] min-w-[100px]'>
                    <Wrapper canvas id='random-id-535' is='div' className='w-full text-center text-[#020212] justify-center' style={{fontSize:'18px', fontWeight:'500', fontFamily:'Voces'}}>
                            <Wrapper className='text-center' canvas style={{display:'inline-block'}} id='random-id-60' is='div'>
                                <Text text='Email*'/>
                            </Wrapper>
                            
                        </Wrapper>                     </Wrapper>
                    <Wrapper canvas id='random-id-536' is='div' className='w-[60%] min-w-[358px] my-auto'>
                        <Input className='input-6' style={{color:'#020212', height:'50px', border:'1px solid #02021233',  fontSize:'14px', fontFamily:'Voces', background:'transparent'}} type="text" name="email" placeholder="email@mail.com" />
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-537' is='div' className='w-full flex flex-row flex-wrap justify-center mx-auto flex-wrap my-4'>
                    <Wrapper canvas id='random-id-538' is='div' className='w-[15%] min-w-[100px]'>
                    <Wrapper canvas id='random-id-539' is='div' className='w-full text-center text-[#020212] justify-center' style={{fontSize:'18px', fontWeight:'500', fontFamily:'Voces'}}>
                            <Wrapper className='text-center' canvas style={{display:'inline-block'}} id='random-id-61' is='div'>
                                <Text text='Phone*'/>
                            </Wrapper>
                        </Wrapper>                     </Wrapper>
                    <Wrapper canvas id='random-id-540' is='div' className='w-[60%] min-w-[358px] my-auto'>
                        <Input className='input-6' style={{color:'#020212', height:'50px', border:'1px solid #02021233',  fontSize:'14px', fontFamily:'Voces', background:'transparent'}} type="text" name="phone" placeholder="(123) 456 78 90" />
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-541' is='div' className='w-full flex flex-row flex-wrap justify-center mx-auto flex-wrap my-4'>
                    <Wrapper canvas id='random-id-542' is='div' className='w-[15%] min-w-[100px]'>
                    <Wrapper canvas id='random-id-543' is='div' className='w-full text-center text-[#020212] justify-center' style={{fontSize:'18px', fontWeight:'500', fontFamily:'Voces'}}>
                            <Wrapper className='text-center' canvas style={{display:'inline-block'}} id='random-id-62' is='div'>
                                <Text text='Message*'/>
                            </Wrapper>
                        </Wrapper>                     </Wrapper>
                    <Wrapper canvas id='random-id-544' is='div' className='w-[60%] min-w-[358px] my-auto'>
                        <Input className='input-6' style={{color:'#020212', height:'50px', paddingBottom:'170px', paddingTop:'20px', border:'1px solid #02021233',  fontSize:'14px', background:'transparent', fontFamily:'Voces'}} type="text" name="firstname" placeholder="Message.." />
                    </Wrapper>
                </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-205' is='div' className='mx-auto mb-4'>
                    <Button  classn='block' class='uppercase cursor-pointer w-[280px] flex h-[60px] bg-[#FFFFFFA1] hover:bg-[#020212] text-[#000000] hover:text-[#FFFFFF]' style={{ borderRadius:'10px', border:'1px solid #020212'}}>
                    <Wrapper canvas id='random-id-1-6' className='mx-auto my-auto' style={{display:'inlineflex', fontSize:'22px', fontWeight:'600', fontFamily:'Voces',}} is='div'>
                                    <Text text='Confirm'/>
                                </Wrapper>
                    </Button>
            </Wrapper>
        </Wrapper>
    </Wrapper>
</Wrapper> </Element>
        </Element>,


<Element canvas is={Parent} id='parentId1300' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId1400' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-545' is='parent' className='w-[100%] h-full bg-[#FBFAFF] py-20'>
<Wrapper canvas id='random-id-546' is='container' className='container' style={{background:'#FBFAFF'}}>
        <Wrapper canvas id='random-id-547' is='div' className='w-[90%] h-full flex flex-col mx-auto bg-[#D0BCFF66]' style={{border:'1px solid #D0BCFF66', borderRadius:'30px'}}>
            <Wrapper className='text-[#6750A4] w-[300px] mx-auto pl-10 flex mb-10 text-center' canvas style={{display:'inline-block', fontSize:'46px', fontWeight:'bold', fontFamily:'Roboto'}} id='random-id-63' is='div'>
                <Text text='Simple form'/>
            </Wrapper>
            <Wrapper canvas id='random-id-548' is='form' >
                <Wrapper canvas id='random-id-549' is='div' className='w-full flex flex-row flex-wrap justify-center mx-auto flex-wrap my-4'>
                    <Wrapper canvas id='random-id-550' is='div' className='w-[15%] min-w-[100px]'>
                        <Wrapper canvas id='random-id-551' is='div' className='w-full text-center text-[#100103] justify-center' style={{fontSize:'18px', fontWeight:'500', fontFamily:'Roboto'}}>
                            <Wrapper className='text-center' canvas style={{display:'inline-block'}} id='random-id-64' is='div'>
                                <Text text='Name'/>
                            </Wrapper>
                            <Wrapper className='text-center' canvas style={{display:'inline-block', color:'#6750A4'}} id='random-id-65' is='div'>
                                <Text text='*'/>
                            </Wrapper>
                        </Wrapper> 
                    </Wrapper>
                    <Wrapper canvas id='random-id-552' is='div' className='w-[60%] min-w-[358px] my-auto'>
                        <Input className='input-7' style={{color:'#100103', height:'50px', border:'1px solid #D0BCFF', borderRadius:'30px',  fontSize:'14px', fontFamily:'Roboto', background:'transparent'}} type="text" name="name" placeholder="Your name" />
                    </Wrapper>
                </Wrapper>

                <Wrapper canvas id='random-id-553' is='div' className='w-full flex flex-row flex-wrap justify-center mx-auto flex-wrap my-4'>
                    <Wrapper canvas id='random-id-554' is='div' className='w-[15%] min-w-[100px]'>
                    <Wrapper canvas id='random-id-555' is='div' className='w-full text-center text-[#100103] justify-center' style={{fontSize:'18px', fontWeight:'500', fontFamily:'Roboto'}}>
                            <Wrapper className='text-center' canvas style={{display:'inline-block'}} id='random-id-66' is='div'>
                                <Text text='Company'/>
                            </Wrapper>
                            <Wrapper className='text-center' canvas style={{display:'inline-block', color:'#6750A4'}} id='random-id-67' is='div'>
                                <Text text='*'/>
                            </Wrapper>
                        </Wrapper>                     </Wrapper>
                    <Wrapper canvas id='random-id-556' is='div' className='w-[60%] min-w-[358px] my-auto'>
                        <Input className='input-7' style={{color:'#100103', height:'50px', border:'1px solid #D0BCFF', borderRadius:'30px',  fontSize:'14px', fontFamily:'Roboto', background:'transparent'}} type="text" name="company" placeholder="Company" />
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-557' is='div' className='w-full flex flex-row flex-wrap justify-center mx-auto flex-wrap my-4'>
                    <Wrapper canvas id='random-id-558' is='div' className='w-[15%] min-w-[100px]'>
                    <Wrapper canvas id='random-id-559' is='div' className='w-full text-center text-[#100103] justify-center' style={{fontSize:'18px', fontWeight:'500', fontFamily:'Roboto'}}>
                            <Wrapper className='text-center' canvas style={{display:'inline-block'}} id='random-id-68' is='div'>
                                <Text text='Email'/>
                            </Wrapper>
                            <Wrapper className='text-center' canvas style={{display:'inline-block', color:'#6750A4'}} id='random-id-69' is='div'>
                                <Text text='*'/>
                            </Wrapper>
                        </Wrapper>                     </Wrapper>
                    <Wrapper canvas id='random-id-560' is='div' className='w-[60%] min-w-[358px] my-auto'>
                        <Input className='input-7' style={{color:'#100103', height:'50px', border:'1px solid #D0BCFF', borderRadius:'30px',  fontSize:'14px', fontFamily:'Roboto', background:'transparent'}} type="text" name="email" placeholder="email@mail.com" />
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-561' is='div' className='w-full flex flex-row flex-wrap justify-center mx-auto flex-wrap my-4'>
                    <Wrapper canvas id='random-id-562' is='div' className='w-[15%] min-w-[100px]'>
                    <Wrapper canvas id='random-id-563' is='div' className='w-full text-center text-[#100103] justify-center' style={{fontSize:'18px', fontWeight:'500', fontFamily:'Roboto'}}>
                            <Wrapper className='text-center' canvas style={{display:'inline-block'}} id='random-id-70' is='div'>
                                <Text text='Phone'/>
                            </Wrapper>
                            <Wrapper className='text-center' canvas style={{display:'inline-block', color:'#6750A4'}} id='random-id-71' is='div'>
                                <Text text='*'/>
                            </Wrapper>
                        </Wrapper>                     </Wrapper>
                    <Wrapper canvas id='random-id-564' is='div' className='w-[60%] min-w-[358px] my-auto'>
                        <Input className='input-7' style={{color:'#100103', height:'50px', border:'1px solid #D0BCFF', borderRadius:'30px',  fontSize:'14px', fontFamily:'Roboto', background:'transparent'}} type="text" name="phone" placeholder="(123) 456 78 90" />
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-565' is='div' className='w-full flex flex-row flex-wrap justify-center mx-auto flex-wrap my-4'>
                    <Wrapper canvas id='random-id-566' is='div' className='w-[15%] min-w-[100px]'>
                    <Wrapper canvas id='random-id-567' is='div' className='w-full text-center text-[#100103] justify-center' style={{fontSize:'18px', fontWeight:'500', fontFamily:'Roboto'}}>
                            <Wrapper className='text-center' canvas style={{display:'inline-block'}} id='random-id-72' is='div'>
                                <Text text='Message'/>
                            </Wrapper>
                            <Wrapper className='text-center' canvas style={{display:'inline-block', color:'#6750A4'}} id='random-id-73' is='div'>
                                <Text text='*'/>
                            </Wrapper>
                        </Wrapper>                     </Wrapper>
                    <Wrapper canvas id='random-id-568' is='div' className='w-[60%] min-w-[358px] my-auto'>
                        <Input className='input-7' style={{color:'#100103', height:'50px', paddingBottom:'170px', paddingTop:'20px', border:'1px solid #D0BCFF', borderRadius:'30px',  fontSize:'14px', background:'transparent', fontFamily:'Roboto'}} type="text" name="firstname" placeholder="Message.." />
                    </Wrapper>
                </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-206' is='div' className='mx-auto mb-4'>
                    <Button  classn='block' class='uppercase cursor-pointer w-[220px] flex mx-auto h-[50px] bg-[#D0BCFF] border-1 hover:border-[#6750A4] text-[#6750A4]' style={{ borderRadius:'100px'}}>
                    <Wrapper canvas id='random-id-1-7' className='mx-auto my-auto' style={{fontSize:'18px', fontWeight:'bold', fontFamily:'Roboto',}} is='div'>
                                    <Text text='Confirm'/>
                                </Wrapper>
                    </Button>
            </Wrapper>
        </Wrapper>
    </Wrapper>
</Wrapper> </Element>
        </Element>,




<Element canvas is={Parent} id='parentId1500' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId1600' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-569' is='parent' className='w-[100%] h-full bg-[#2A2A2A] py-20'>
<Wrapper canvas id='random-id-570' is='container' className='container' style={{background:'#2A2A2A'}}>
        <Wrapper canvas id='random-id-571' is='div' className='w-[90%] h-full flex flex-col mx-auto' style={{border:'1px solid #FFD6001A', boxShadow: '0px 0px 10px 0px #FFFFFF0F'}}>
            <Wrapper className='text-center text-[#FFD600] w-[300px] mx-auto pl-10 flex mb-10' canvas style={{display:'inline-block', fontSize:'46px', fontWeight:'400', fontFamily:'Rum Raisin'}} id='random-id-74' is='div'> 
                <Text text='Simple form'/> 
            </Wrapper>
            <Wrapper canvas id='random-id-572' is='form' >
                <Wrapper canvas id='random-id-573' is='div' className='w-full flex flex-row flex-wrap justify-center mx-auto flex-wrap my-4'>
                    <Wrapper canvas id='random-id-574' is='div' className='w-[15%] min-w-[100px]'>
                        <Wrapper canvas id='random-id-575' is='div' className='w-full text-center text-[#FFFFFF] mx-auto flex flex-row justify-center' style={{fontSize:'18px', fontWeight:'500', fontFamily:'Rubik'}}>
                            <Wrapper className='text-center' canvas style={{display:'inline-block'}} id='random-id-75' is='div'>
                                <Text text='Name'/>
                            </Wrapper>
                            <Wrapper className='text-center' canvas style={{display:'inline-block', color:'#FFD600'}} id='random-id-76' is='div'>
                                <Text text='*'/>
                            </Wrapper>
                        </Wrapper> 
                    </Wrapper>
                    <Wrapper canvas id='random-id-576' is='div' className='w-[60%] min-w-[358px] my-auto'>
                        <Input className='input-8' style={{color:'#FFFFFF', height:'50px', border:'1px solid #FFD60099', borderRadius:'10px', fontSize:'14px', fontFamily:'Rubik', background:'transparent'}} type="text" name="name" placeholder="Your name" />
                    </Wrapper>
                </Wrapper>

                <Wrapper canvas id='random-id-577' is='div' className='w-full flex flex-row flex-wrap justify-center mx-auto flex-wrap my-4'>
                    <Wrapper canvas id='random-id-578' is='div' className='w-[15%] min-w-[100px]'>
                    <Wrapper canvas id='random-id-579' is='div' className='w-full text-center text-[#FFFFFF] mx-auto flex flex-row justify-center' style={{fontSize:'18px', fontWeight:'500', fontFamily:'Rubik'}}>
                            <Wrapper className='text-center' canvas style={{display:'inline-block'}} id='random-id-77' is='div'>
                                <Text text='Company'/>
                            </Wrapper>
                            <Wrapper className='text-center' canvas style={{display:'inline-block', color:'#FFD600'}} id='random-id-78' is='div'>
                                <Text text='*'/>
                            </Wrapper>
                        </Wrapper>                     </Wrapper>
                    <Wrapper canvas id='random-id-580' is='div' className='w-[60%] min-w-[358px] my-auto'>
                        <Input className='input-8' style={{color:'#FFFFFF', height:'50px', border:'1px solid #FFD60099', borderRadius:'10px', fontSize:'14px', fontFamily:'Rubik', background:'transparent'}} type="text" name="company" placeholder="Company" />
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-581' is='div' className='w-full flex flex-row flex-wrap justify-center mx-auto flex-wrap my-4'>
                    <Wrapper canvas id='random-id-582' is='div' className='w-[15%] min-w-[100px]'>
                    <Wrapper canvas id='random-id-583' is='div' className='w-full text-center text-[#FFFFFF] mx-auto flex flex-row justify-center' style={{fontSize:'18px', fontWeight:'500', fontFamily:'Rubik'}}>
                            <Wrapper className='text-center' canvas style={{display:'inline-block'}} id='random-id-79' is='div'>
                                <Text text='Email'/>
                            </Wrapper>
                            <Wrapper className='text-center' canvas style={{display:'inline-block', color:'#FFD600'}} id='random-id-80' is='div'>
                                <Text text='*'/>
                            </Wrapper>
                        </Wrapper>                     </Wrapper>
                    <Wrapper canvas id='random-id-584' is='div' className='w-[60%] min-w-[358px] my-auto'>
                        <Input className='input-8' style={{color:'#FFFFFF', height:'50px', border:'1px solid #FFD60099', borderRadius:'10px', fontSize:'14px', fontFamily:'Rubik', background:'transparent'}} type="text" name="email" placeholder="email@mail.com" />
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-585' is='div' className='w-full flex flex-row flex-wrap justify-center mx-auto flex-wrap my-4'>
                    <Wrapper canvas id='random-id-586' is='div' className='w-[15%] min-w-[100px]'>
                    <Wrapper canvas id='random-id-587' is='div' className='w-full text-center text-[#FFFFFF] mx-auto flex flex-row justify-center' style={{fontSize:'18px', fontWeight:'500', fontFamily:'Rubik'}}>
                            <Wrapper className='text-center' canvas style={{display:'inline-block'}} id='random-id-81' is='div'>
                                <Text text='Phone'/>
                            </Wrapper>
                            <Wrapper className='text-center' canvas style={{display:'inline-block', color:'#FFD600'}} id='random-id-82' is='div'>
                                <Text text='*'/>
                            </Wrapper>
                        </Wrapper>                     </Wrapper>
                    <Wrapper canvas id='random-id-588' is='div' className='w-[60%] min-w-[358px] my-auto'>
                        <Input className='input-8' style={{color:'#FFFFFF', height:'50px', border:'1px solid #FFD60099', borderRadius:'10px', fontSize:'14px', fontFamily:'Rubik', background:'transparent'}} type="text" name="phone" placeholder="(123) 456 78 90" />
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-589' is='div' className='w-full flex flex-row flex-wrap justify-center mx-auto flex-wrap my-4'>
                    <Wrapper canvas id='random-id-590' is='div' className='w-[15%] min-w-[100px]'>
                    <Wrapper canvas id='random-id-591' is='div' className='w-full text-center text-[#FFFFFF] mx-auto flex flex-row justify-center' style={{fontSize:'18px', fontWeight:'500', fontFamily:'Rubik'}}>
                            <Wrapper className='text-center' canvas style={{display:'inline-block'}} id='random-id-83' is='div'>
                                <Text text='Message'/>
                            </Wrapper>
                            <Wrapper className='text-center' canvas style={{display:'inline-block', color:'#FFD600'}} id='random-id-84' is='div'>
                                <Text text='*'/>
                            </Wrapper>
                        </Wrapper>                     </Wrapper>
                    <Wrapper canvas id='random-id-592' is='div' className='w-[60%] min-w-[358px] my-auto'>
                        <Input className='input-8' style={{color:'#FFFFFF', height:'50px', paddingBottom:'170px', paddingTop:'20px', border:'1px solid #FFD60099', borderRadius:'10px', fontSize:'14px', background:'transparent', fontFamily:'Rubik'}} type="text" name="firstname" placeholder="Message.." />
                    </Wrapper>
                </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-207' is='div' className='mx-auto mb-4'>
                    <Button  classn='block' class='uppercase cursor-pointer w-[280px] flex mx-auto h-[60px] bg-[#FFD600] hover:bg-[#FFD600A1] text-[#2A2A2A]' style={{ borderRadius:'10px'}}>
                    <Wrapper canvas id='random-id-1-8' style={{display:'inlineflex', fontSize:'22px', fontWeight:'600', fontFamily:'Sansation',}} className='mx-auto my-auto' is='div'>
                                    <Text text='Confirm'/>
                                </Wrapper>
                    </Button>
            </Wrapper>
        </Wrapper>
    </Wrapper>
</Wrapper> </Element>
        </Element>,


<Element canvas is={Parent} id='parentId1700' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId1800' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-900' is='parent' className='w-[100%] h-full bg-[#F7FBFE] py-20'>
<Wrapper canvas id='random-id-901' is='container' className='container bg-[#FFFDFB]'>
        <Wrapper canvas id='random-id-902' is='div' className='w-[90%] h-full flex flex-col mx-auto' style={{border:'1px solid #DBD9D9', borderRadius:'10px', background:'#FFF'}}>
            <Wrapper className='text-center text-[#6064D2] w-[300px] mx-auto pl-10 flex mb-10 justify-center' canvas style={{display:'inline-block', fontSize:'46px', fontWeight:'bold', fontFamily:'Rubik'}} id='random-id-9030' is='div'>
                <Text text='Simple form'/>
            </Wrapper>

            <Wrapper canvas id='random-id-903' is='form'>
                <Wrapper canvas id='random-id-904' is='parent' className='w-full flex flex-row flex-wrap justify-center mx-auto my-4'>
                    <Wrapper canvas id='random-id-905' is='div' className='w-[15%] min-w-[100px]'>
                        <Wrapper canvas id='random-id-906' is='div' className='w-full text-center text-[#000000] mx-auto flex flex-row justify-center' style={{fontSize:'18px', fontWeight:'500', fontFamily:'Rubik'}}>
                            <Wrapper className='text-center' canvas style={{display:'inline-block'}} id='random-id-9031' is='div'>
                                <Text text='Name'/>
                            </Wrapper>
                            <Wrapper className='text-center' canvas style={{display:'inline-block', color:'#6064D2'}} id='random-id-9032' is='div'>
                                <Text text='*'/>
                            </Wrapper>
                        </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-907' is='div' className='w-[60%] min-w-[358px] my-auto'>
                        <Input style={{height:'50px', border:'1px solid rgba(96, 100, 210, 0.20)',borderRadius:'10px', fontSize:'14px', fontFamily:'Rubik'}} type="text" name="name" placeholder="Your name" />
                    </Wrapper>
                </Wrapper>

                <Wrapper canvas id='random-id-908' is='div' className='w-full flex flex-row flex-wrap justify-center mx-auto my-4'>
                    <Wrapper canvas id='random-id-909' is='div' className='w-[15%] min-w-[100px]'>
                        <Wrapper canvas id='random-id-910' is='div' className='w-full text-center text-[#000000] mx-auto flex flex-row justify-center' style={{fontSize:'18px', fontWeight:'500', fontFamily:'Rubik'}}>
                            <Wrapper className='text-center' canvas style={{display:'inline-block'}} id='random-id-5' is='div'>
                                <Text text='Company'/>
                            </Wrapper>
                            <Wrapper className='text-center' canvas style={{display:'inline-block', color:'#6064D2'}} id='random-id-9033' is='div'>
                                <Text text='*'/>
                            </Wrapper>
                        </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-911' is='div' className='w-[60%] min-w-[358px] my-auto'>
                        <Input style={{height:'50px', border:'1px solid rgba(96, 100, 210, 0.20)',borderRadius:'10px', fontSize:'14px', fontFamily:'Rubik'}} type="text" name="company" placeholder="Company" />
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-912' is='div' className='w-full flex flex-row flex-wrap justify-center flex-wrap mx-auto my-4'>
                    <Wrapper canvas id='random-id-913' is='div' className='w-[15%] min-w-[100px]'>
                        <Wrapper canvas id='random-id-914' is='div' className='w-full text-center text-[#000000] mx-auto flex flex-row justify-center' style={{fontSize:'18px', fontWeight:'500', fontFamily:'Rubik'}}>
                            <Wrapper className='text-center' canvas style={{display:'inline-block'}} id='random-id-9034' is='div'>
                                <Text text='Email'/>
                            </Wrapper>
                            <Wrapper className='text-center' canvas style={{display:'inline-block', color:'#6064D2'}} id='random-id-9035' is='div'>
                                <Text text='*'/>
                            </Wrapper>
                        </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-915' is='div' className='w-[60%] min-w-[358px] my-auto'>
                        <Input style={{height:'50px', border:'1px solid rgba(96, 100, 210, 0.20)',borderRadius:'10px', fontSize:'14px', fontFamily:'Rubik'}} type="text" name="email" placeholder="email@mail.com" />
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-916' is='div' className='w-full flex flex-row flex-wrap justify-center flex-wrap mx-auto my-4'>
                    <Wrapper canvas id='random-id-917' is='div' className='w-[15%] min-w-[100px]'>
                    <Wrapper canvas id='random-id-918' is='div' className='w-full text-center text-[#000000] mx-auto flex flex-row justify-center' style={{fontSize:'18px', fontWeight:'500', fontFamily:'Rubik'}}>
                            <Wrapper className='text-center' canvas style={{display:'inline-block'}} id='random-id-9036' is='div'>
                                <Text text='Name'/>
                            </Wrapper>
                            <Wrapper className='text-center' canvas style={{display:'inline-block', color:'#6064D2'}} id='random-id-9037' is='div'>
                                <Text text='*'/>
                            </Wrapper>
                        </Wrapper>                            </Wrapper>
                    <Wrapper canvas id='random-id-919' is='div' className='w-[60%] min-w-[358px] my-auto'>
                        <Input style={{height:'50px', border:'1px solid rgba(96, 100, 210, 0.20)',borderRadius:'10px', fontSize:'14px', fontFamily:'Rubik'}} type="text" name="phone" placeholder="(123) 456 78 90" />
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-920' is='div' className='w-full flex flex-row flex-wrap justify-center flex-wrap mx-auto my-4'>
                    <Wrapper canvas id='random-id-921' is='div' className='w-[15%] min-w-[100px]'>
                    <Wrapper canvas id='random-id-922' is='div' className='w-full text-center text-[#000000] mx-auto flex flex-row justify-center' style={{fontSize:'18px', fontWeight:'500', fontFamily:'Rubik'}}>
                            <Wrapper className='text-center' canvas style={{display:'inline-block'}} id='random-id-9038' is='div'>
                                <Text text='Phone'/>
                            </Wrapper>
                            <Wrapper className='text-center' canvas style={{display:'inline-block', color:'#6064D2'}} id='random-id-9039' is='div'>
                                <Text text='*'/>
                            </Wrapper>
                        </Wrapper>                            </Wrapper>
                    <Wrapper canvas id='random-id-923' is='div' className='w-[60%] min-w-[358px] my-auto'>
                        <Input style={{height:'50px', paddingBottom:'170px', paddingTop:'20px', border:'1px solid rgba(96, 100, 210, 0.20)',borderRadius:'10px', fontSize:'14px', fontFamily:'Rubik'}} type="text" name="Message" placeholder="Message.." />
                    </Wrapper>
                </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-924' is='div' className='mx-auto mb-4'>
                    <Button  classn='block' class='uppercase w-[320px] flex h-[50px] bg-[#B20312] hover:bg-[#87000C] cursor-pointer text-[#FFFFFF]' style={{borderRadius:'10px'}}>
                        <Wrapper canvas id='random-id-1-9040' style={{display:'inlineflex', fontSize:'22px', fontWeight:'600', fontFamily:'Rubik', }} className='mx-auto my-auto' is='div'>
                            <Text text='Confirm'/>
                        </Wrapper>
                    </Button>
            </Wrapper>
        </Wrapper>
    </Wrapper>
</Wrapper> </Element>
        </Element>,
        

<Element canvas is={Parent} id='parentId1900' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId2000' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-925' is='parent' className='w-[100%] h-full bg-[#FEFBF7] py-20'>
<Wrapper canvas id='random-id-926' is='container' className='container bg-[#FFFDFB]'>
        <Wrapper canvas id='random-id-927' is='div' className='w-[90%] h-full flex flex-col mx-auto' style={{border:'1px solid rgba(162, 87, 56, 0.20)', borderRadius:'10px', background:'#FFF'}}>
            <Wrapper className='text-center text-[#A25738] w-[300px] mx-auto pl-10 flex mb-10 justify-center' canvas style={{display:'inline-block', fontSize:'46px', fontWeight:'bold', fontFamily:'Rubik'}} id='random-id-928' is='div'>
                <Text text='Simple form'/>
            </Wrapper>

            <Wrapper canvas id='random-id-930' is='form'>
                <Wrapper canvas id='random-id-931' is='parent' className='w-full flex flex-row flex-wrap justify-center mx-auto my-4'>
                    <Wrapper canvas id='random-id-932' is='div' className='w-[15%] min-w-[100px]'>
                        <Wrapper canvas id='random-id-933' is='div' className='w-full text-center text-[#000000] mx-auto flex flex-row justify-center' style={{fontSize:'18px', fontWeight:'500', fontFamily:'Inter'}}>
                            <Wrapper className='text-center' canvas style={{display:'inline-block'}} id='random-id-934' is='div'>
                                <Text text='Name'/>
                            </Wrapper>
                            <Wrapper className='text-center' canvas style={{display:'inline-block', color:'#A25738'}} id='random-id-934' is='div'>
                                <Text text='*'/>
                            </Wrapper>
                        </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-935' is='div' className='w-[60%] min-w-[358px] my-auto'>
                        <Input style={{height:'50px', border:'1px solid rgba(162, 87, 56, 0.20)',borderRadius:'10px', fontSize:'14px', fontFamily:'Inter'}} type="text" name="name" placeholder="Your name" />
                    </Wrapper>
                </Wrapper>

                <Wrapper canvas id='random-id-936' is='div' className='w-full flex flex-row flex-wrap justify-center mx-auto my-4'>
                    <Wrapper canvas id='random-id-937' is='div' className='w-[15%] min-w-[100px]'>
                        <Wrapper canvas id='random-id-938' is='div' className='w-full text-center text-[#000000] mx-auto flex flex-row justify-center' style={{fontSize:'18px', fontWeight:'500', fontFamily:'Inter'}}>
                            <Wrapper className='text-center' canvas style={{display:'inline-block'}} id='random-id-939' is='div'>
                                <Text text='Company'/>
                            </Wrapper>
                            <Wrapper className='text-center' canvas style={{display:'inline-block', color:'#A25738'}} id='random-id-940' is='div'>
                                <Text text='*'/>
                            </Wrapper>
                        </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-941' is='div' className='w-[60%] min-w-[358px] my-auto'>
                        <Input style={{height:'50px', border:'1px solid rgba(162, 87, 56, 0.20)',borderRadius:'10px', fontSize:'14px', fontFamily:'Inter'}} type="text" name="company" placeholder="Company" />
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-942' is='div' className='w-full flex flex-row flex-wrap justify-center flex-wrap mx-auto my-4'>
                    <Wrapper canvas id='random-id-943' is='div' className='w-[15%] min-w-[100px]'>
                        <Wrapper canvas id='random-id-944' is='div' className='w-full text-center text-[#000000] mx-auto flex flex-row justify-center' style={{fontSize:'18px', fontWeight:'500', fontFamily:'Inter'}}>
                            <Wrapper className='text-center' canvas style={{display:'inline-block'}} id='random-id-945' is='div'>
                                <Text text='Email'/>
                            </Wrapper>
                            <Wrapper className='text-center' canvas style={{display:'inline-block', color:'#A25738'}} id='random-id-946' is='div'>
                                <Text text='*'/>
                            </Wrapper>
                        </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-947' is='div' className='w-[60%] min-w-[358px] my-auto'>
                        <Input style={{height:'50px', border:'1px solid rgba(162, 87, 56, 0.20)',borderRadius:'10px', fontSize:'14px', fontFamily:'Inter'}} type="text" name="email" placeholder="email@mail.com" />
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-948' is='div' className='w-full flex flex-row flex-wrap justify-center flex-wrap mx-auto my-4'>
                    <Wrapper canvas id='random-id-949' is='div' className='w-[15%] min-w-[100px]'>
                    <Wrapper canvas id='random-id-950' is='div' className='w-full text-center text-[#000000] mx-auto flex flex-row justify-center' style={{fontSize:'18px', fontWeight:'500', fontFamily:'Inter'}}>
                            <Wrapper className='text-center' canvas style={{display:'inline-block'}} id='random-id-951' is='div'>
                                <Text text='Name'/>
                            </Wrapper>
                            <Wrapper className='text-center' canvas style={{display:'inline-block', color:'#A25738'}} id='random-id-952' is='div'>
                                <Text text='*'/>
                            </Wrapper>
                        </Wrapper>                            </Wrapper>
                    <Wrapper canvas id='random-id-953' is='div' className='w-[60%] min-w-[358px] my-auto'>
                        <Input style={{height:'50px', border:'1px solid rgba(162, 87, 56, 0.20)',borderRadius:'10px', fontSize:'14px', fontFamily:'Inter'}} type="text" name="phone" placeholder="(123) 456 78 90" />
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-954' is='div' className='w-full flex flex-row flex-wrap justify-center flex-wrap mx-auto my-4'>
                    <Wrapper canvas id='random-id-956' is='div' className='w-[15%] min-w-[100px]'>
                    <Wrapper canvas id='random-id-957' is='div' className='w-full text-center text-[#000000] mx-auto flex flex-row justify-center' style={{fontSize:'18px', fontWeight:'500', fontFamily:'Inter'}}>
                            <Wrapper className='text-center' canvas style={{display:'inline-block'}} id='random-id-958' is='div'>
                                <Text text='Phone'/>
                            </Wrapper>
                            <Wrapper className='text-center' canvas style={{display:'inline-block', color:'#A25738'}} id='random-id-959' is='div'>
                                <Text text='*'/>
                            </Wrapper>
                        </Wrapper>                           
                    </Wrapper>
                    <Wrapper canvas id='random-id-960' is='div' className='w-[60%] min-w-[358px] my-auto'>
                        <Input style={{height:'50px', paddingBottom:'170px', paddingTop:'20px', border:'1px solid rgba(162, 87, 56, 0.20)',borderRadius:'10px', fontSize:'14px', fontFamily:'Inter'}} type="text" name="Message" placeholder="Message.." />
                    </Wrapper>
                </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-961' is='div' className='mx-auto mb-4'>
                    <Button  classn='block' class='uppercase w-[280px] flex h-[60px] bg-[#A25738] cursor-pointer text-[#FFFFFF]' style={{ borderRadius:'10px'}}>
                        <Wrapper canvas id='random-id-1-962' style={{display:'inlineflex', fontSize:'22px', fontWeight:'600', fontFamily:'Sansation',}} className='mx-auto my-auto' is='div'>
                            <Text text='Confirm'/>
                        </Wrapper>
                    </Button>
            </Wrapper>
        </Wrapper>
    </Wrapper>
</Wrapper> </Element>
        </Element>,
        
    ]

    
const [sel, setSel] = useState(selected)

function makeOdd(number) {
  setProp((prop) => {
    setSel(100);
  }, 1000);

  setTimeout(() => {
    setProp((prop) => {
      setSel(number)
    }, 1000);
  }, 100);


  
}

useEffect(() => {
if(selected || selected >= 0) {
  makeOdd(selected);
}
},[selected])

    return (
        <div style={{ padding:enabled? "10px" : "0px", display:"flex", justifyContent:"center", alignItems:"center", width:'100%', height:'100%'}} ref={connect}>
        {sel == 100 ? (
          <div style={{height:"85vh", display:"flex", justifyContent:"center", alignItems:"center"}}>
          <BounceLoader
                  size={100}
                  color={"white"}
                  loading={true}
                />
          </div>
         ) : (
          styles[sel]
         )} 
    </div>
    );
}

    Form_1.craft = {
        displayName: "Form 1",
        props: {
        selected: 1, // here is the default value of the selected style
        length:9,
isBlock: true
        },
        rules: {
            canDrag: () => true,
            canDrop: () => true,
        },
        related: {
            toolbar: NewFormSettings,
        },
    }