/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import { Element, useEditor, useNode } from '@craftjs/core';
import React, { useMemo } from 'react';
import { CardSettings } from './CardSettings';
import {Button} from '../Button/index'
import { ImageC } from '../Image';
import { Text } from '../../basic/Text/index';
import { Wrapper } from '../../wrapper/index.js';
import { Parent } from '../../Parent/index.js';


export const Card = (props) => {
  const {
    connectors: { connect },
  } = useNode((node) => ({
    selected: node.events.selected,
  }));

  const { enabled } = useEditor((state, query) => ({
    enabled: state.options.enabled,

  }));

  const { 
    style= {},
    text,
    margin,
    width = '350px',
    height,
    href,
    classn,
    color,
    shadow,
    children,
    marginTop,
    marginBottom,
    background,
    marginLeft,
    marginRight,
    minWidth,
    borderColor,
    borderWidth,
    borderRadius,
shadowX,
    shadowY,
    shadowBlur,
    shadowColor,
    isBlog,
    ...otherProps
  } = props;

   
  const handleProperty = (value, propertyName, split) => {
    if (value !== null && !split) {
      return { [propertyName]: value?.split(" ").join("") }
    }

    if (value !== null && split === 'splitBg') {
      return { [propertyName]:  value !== null ? value : '' }
    } else {
      return;
    }
  };


    const styleProps = useMemo(() => ({
      ...(color !== null && {
        color: color?.startsWith('#') ? color : 'transparent',
        backgroundImage: !color?.startsWith('#') ? color : undefined,
        WebkitBackgroundClip: !color?.startsWith('#') ? 'text' : undefined,
        backgroundClip: !color?.startsWith('#') ? 'text' : undefined,
      }),

        boxShadow: `${(shadowX?.split(" ").join(""))} ${(shadowY?.split(" ").join(""))} ${(shadowBlur?.split(" ").join(""))} ${shadowColor || `rgba(255, 255, 255)`}`
    ,
    
    ...handleProperty(width, 'width'),
    ...handleProperty(minWidth, 'minWidth'),

    ...handleProperty(height, 'height'),

    ...handleProperty(background, 'background', 'splitBg'),

    ...handleProperty(marginTop, 'marginTop'),
    ...handleProperty(marginRight, 'marginRight'),
    ...handleProperty(marginBottom, 'marginBottom'),
    ...handleProperty(marginLeft, 'marginLeft'),

    ...handleProperty(borderColor, 'borderColor'),
    ...handleProperty(borderWidth, 'borderWidth'),
    ...handleProperty(borderRadius, 'borderRadius'),

    }), [
      color, width, height,
      shadowX,
      shadowY,
      shadowColor,
      shadowBlur, background, borderRadius, borderColor, borderWidth, marginTop, marginRight, marginBottom, marginLeft]);

  
  return ( 
    <div
    ref={connect}
    style={{...styleProps, ...style,}}
    >

      <Element canvas is={Parent} id='parentId12' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId12' className="w-[100%] h-auto" row={true} >
        <Element canvas id='random-id-1' is={Parent} className='w-[100%] h-full flex flex-col' style={{width:'100%', height:'100%', padding:'4px 8px'}}>
        
        <ImageC className='h-[200px] object-cover' style={{margin:'0 0 4px 0'}} src='https://i.ibb.co/gW0hkRZ/sample3banner.jpg'/>
            <Element canvas id='random-id-30000' is={Parent} className='flex flex-col' style={{ width:'100%', height:'100%', padding:'0 4px', margin:'5px 0'}}>
            <Element canvas id='random-id-2' is={Parent} style={{fontSize:'18px', display:'inline-block', fontWeight:'700', margin:'2px 0'}}>
                <Text text='Card title'/>
            </Element>
            <Element canvas id='random-id-3' is={Parent} style={{fontSize:'16px', display:'inline-block', opacity:'70%', fontWeight:'600', margin:'2px 0'}}>
                <Text text='Sub subtitle'/>
            </Element>
            <Element canvas id='random-id-4' is={Parent} style={{fontSize:'16px', display:'inline-block', fontWeight:'400'}}>
                <Text text='Some quick example text to build on the card title and make up the bulk of the card‘s content.'/>
            </Element>
            <Element canvas id='random-id-4' is={Parent} style={{width:'100%', margin:'10px 0', justifyContent:'center', display:'flex'}}>
            <Button href={'/'} classn='block' class={' justify-center border w-[240px] h-[60px] flex rounded-[10px] py-[11px]'}>
                <Element canvas id='random-id-6' is={Parent} style={{width:'100%', display:'inline-block', color:'#000', textAlign:'center', fontSize:'18px'}}>
                    <Text text='Button'/>
                </Element>
            </Button>   
            </Element>
            </Element>
        </Element>
        </Element>
        </Element>
    </div>
  );
};

Card.craft = {
  displayName: 'Card',
  props: {
    color: null,
    text: 'Card',
    margin: null,
    width: '350px',
    height: null,
    minWidth:null,
    height:null,
    background:null,
    shadowX: null,
    shadowY: null,
    shadowColor:null,
    shadowBlur: null,
    marginTop: null,
    marginRight: null,
    marginBottom: null,
    marginLeft: null,
borderRadius: null,
    borderColor:null,
    borderWidth:null,
  },
  related: {
    toolbar: CardSettings,
  },
};
