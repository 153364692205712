/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, { useEffect, useState } from 'react';
import { Element, useEditor, useNode } from '@craftjs/core';
import { NewFaqSettings } from './newFaqSetting';
import {Box, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { Text } from '../../basic/Text';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Parent } from '../../Parent/index.js';
import { Wrapper } from '../../wrapper/index.js';
import { BounceLoader } from 'react-spinners';

export const FAQ_2 = ({selected}) => {

  const { 
    actions: { setProp },
      connectors: {
        connect
      } 
  } = useNode();

  
  const {
    enabled,
  } = useEditor((state, query) => ({
    enabled: state.options.enabled,
 
  }));
  

    const styles = [
      <Element canvas is={Parent} id='parentId100' className="w-[100%] h-auto" >
      <Element canvas is={Parent} id='parentId200' className="w-[100%] h-auto" row={true} ><div className='w-[100%] h-full' style={{background:'#F7F7F9'}}>
            <Box className='container' sx={{textAlign:'center'}}>
            <Typography className='text-[#100103] my-4' sx={{fontSize:'46px', fontWeight:'bold', fontFamily:'Poppins'}}>
            <Wrapper canvas style={{ display: "inline-block" }} id="random-id-1" is="div">
            <Text text="Questions?" />
            </Wrapper>
            </Typography>
            <Typography className='container mx-auto my-4' sx={{fontSize:'22px', fontWeight:'400px', fontFamily:'Poppins', color:'[#100103]', maxWidth:'full'}}>
            <Wrapper canvas style={{ display: "inline-block" }} id="random-id-2" is="div">
            <Text text="Sed sed risus pretium quam vulputate dignissim. Eget nunc lobortis mattis aliquam faucibus purus in. Potenti nullam ac tortor vitae purus faucibus ornare suspendisse." />
            </Wrapper>
            </Typography>
            </Box>
            <Box className='mx-auto' sx={{padding:'1em', width:'100%', maxWidth:'1192px'}}>
            <Accordion sx={{background:'transparent', borderBottom:'1px solid rgb(255, 201, 46)', color:'#000000', padding:'5px', margin:'10px'}}>
            <AccordionSummary
            aria-controls="panel1a-content"
            id="panel1a-header"
            >
            <Box className='justify-center' sx={{display:'flex', flexFlow:'row'}}>
            <Box className='pr-6'>
            <AddIcon sx={{color:'#2479FD', fontSize:'40px'}} />
            </Box>
            <Box>
            <Typography className='pt-[3px]' sx={{fontSize:'22px', fontWeight:'400px', fontFamily:'Poppins'}}>
            <Wrapper canvas style={{ display: "inline-block" }} id="random-id-3" is="div">
            <Text text="Sed sed risus pretium quam vulputate dignissim?" />
            </Wrapper>
            </Typography>
            </Box>
            </Box>
            </AccordionSummary> 
            <AccordionDetails >
            <Typography className='w-[90%] mx-auto' sx={{fontSize:'16px', fontWeight:'400px', fontFamily:'Poppins', padding:'10px 0px 0px 0px', lineHeight:'25.6px', letterSpacing:'1.5px',}}>
    
            <Wrapper canvas style={{ display: "inline-block" }} id="random-id-4" is="div">
            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />
            </Wrapper>
            </Typography>
            </AccordionDetails>
            </Accordion>
            <Accordion sx={{background:'transparent', borderBottom:'1px solid rgb(255, 201, 46)', color:'#000000', padding:'5px',  margin:'10px'}}>
            <AccordionSummary
            aria-controls="panel2a-content"
            id="panel2a-header"
            >
            <Box className='justify-center' sx={{display:'flex', flexFlow:'row'}}>
            <Box className='pr-6'>
            <AddIcon sx={{color:'#2479FD', fontSize:'40px'}} />
            </Box>
            <Box>
            <Typography className='pt-[3px]' sx={{fontSize:'22px', fontWeight:'400px', fontFamily:'Poppins'}}>
            <Wrapper canvas style={{ display: "inline-block" }} id="random-id-24" is="div">
            <Text text="Sed sed risus pretium quam vulputate dignissim?" />
            </Wrapper>
            </Typography>
            </Box>
            </Box>
            </AccordionSummary>
            <AccordionDetails>
            <Typography className='w-[90%] mx-auto' sx={{fontSize:'16px', lineHeight:'25.6px', letterSpacing:'1.5px', fontWeight:'400px', fontFamily:'Poppins', padding:'10px 0px 0px 0px'}}>
                
            <Wrapper canvas style={{ display: "inline-block" }} id="random-id-5" is="div">
            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />
            </Wrapper>
            </Typography>
            </AccordionDetails>
            </Accordion>
            <Accordion sx={{background:'transparent', borderBottom:'1px solid rgb(255, 201, 46)', color:'#000000', padding:'5px',  margin:'10px'}}>
            <AccordionSummary
            aria-controls="panel2a-content"
            id="panel2a-header"
            >
            <Box className='justify-center' sx={{display:'flex', flexFlow:'row'}}>
            <Box className='pr-6'>
            <AddIcon sx={{color:'#2479FD', fontSize:'40px'}} />
            </Box>
            <Box>
            <Typography className='pt-[3px]' sx={{fontSize:'22px', fontWeight:'400px', fontFamily:'Poppins'}}>
            <Wrapper canvas style={{ display: "inline-block" }} id="random-id-6" is="div">
            <Text text="Sed sed risus pretium quam vulputate dignissim?" />
            </Wrapper>
            </Typography>
            </Box>
            </Box>
            </AccordionSummary>
            <AccordionDetails>
            <Typography className='w-[90%] mx-auto' sx={{fontSize:'16px', lineHeight:'25.6px', letterSpacing:'1.5px', fontWeight:'400px', fontFamily:'Poppins', padding:'10px 0px 0px 0px'}}>
                
            <Wrapper canvas style={{ display: "inline-block" }} id="random-id-7" is="div">
            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />
            </Wrapper>
            </Typography>
            </AccordionDetails>
            </Accordion>
            <Accordion sx={{background:'transparent', borderBottom:'1px solid rgb(255, 201, 46)', color:'#000000', padding:'5px',  margin:'10px'}}>
            <AccordionSummary
            aria-controls="panel2a-content"
            id="panel2a-header"
            >
            <Box className='justify-center' sx={{display:'flex', flexFlow:'row'}}>
            <Box className='pr-6'>
            <AddIcon sx={{color:'#2479FD', fontSize:'40px'}} />
            </Box>
            <Box>
            <Typography className='pt-[3px]' sx={{fontSize:'22px', fontWeight:'400px', fontFamily:'Poppins'}}>
            <Wrapper canvas style={{ display: "inline-block" }} id="random-id-8" is="div">
            <Text text="Sed sed risus pretium quam vulputate dignissim?" />
            </Wrapper>
            </Typography>
            </Box>
            </Box>
            </AccordionSummary>
            <AccordionDetails>
            <Typography className='w-[90%] mx-auto' sx={{fontSize:'16px', lineHeight:'25.6px', letterSpacing:'1.5px', fontWeight:'400px', fontFamily:'Poppins', padding:'10px 0px 0px 0px'}}>
                
            <Wrapper canvas style={{ display: "inline-block" }} id="random-id-9" is="div">
            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />
            </Wrapper>
            </Typography>
            </AccordionDetails>
            </Accordion>
            <Accordion sx={{background:'transparent', borderBottom:'1px solid rgb(255, 201, 46)', color:'#000000', padding:'5px',  margin:'10px'}}>
            <AccordionSummary
            aria-controls="panel2a-content"
            id="panel2a-header"
            >
            <Box className='justify-center' sx={{display:'flex', flexFlow:'row'}}>
            <Box className='pr-6'>
            <AddIcon sx={{color:'#2479FD', fontSize:'40px'}} />
            </Box>
            <Box>
            <Typography className='pt-[3px]' sx={{fontSize:'22px', fontWeight:'400px', fontFamily:'Poppins'}}>
            <Wrapper canvas style={{ display: "inline-block" }} id="random-id-10" is="div">
            <Text text="Sed sed risus pretium quam vulputate dignissim?" />
            </Wrapper>
            </Typography>
            </Box>
            </Box>
            </AccordionSummary>
            <AccordionDetails>
            <Typography className='w-[90%] mx-auto' sx={{fontSize:'16px', lineHeight:'25.6px', letterSpacing:'1.5px', fontWeight:'400px', fontFamily:'Poppins', padding:'10px 0px 0px 0px'}}>
                
            <Wrapper canvas style={{ display: "inline-block" }} id="random-id-11" is="div">
            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />
            </Wrapper>
            </Typography>
            </AccordionDetails>
            </Accordion>
            </Box>
            </div></Element>
      </Element>
            ,
    
    <Element canvas is={Parent} id='parentId300' className="w-[100%] h-auto" >
      <Element canvas is={Parent} id='parentId400' className="w-[100%] h-auto" row={true} ><div className='w-[100%] h-full' style={{background:'#FDFDF3'}}>
            <Box className='container' sx={{textAlign:'center'}}>
            <Typography className='text-[#100103] my-4' sx={{fontSize:'46px', fontWeight:'bold', fontFamily:'Prompt'}}>
            <Wrapper canvas style={{ display: "inline-block" }} id="random-id-12" is="div">
            <Text text="Questions?" />
            </Wrapper>
            </Typography>
            <Typography className='container mx-auto my-4' sx={{fontSize:'22px', fontWeight:'400px', fontFamily:'Prompt', color:'[#100103]', maxWidth:'full'}}>
            <Wrapper canvas style={{ display: "inline-block" }} id="random-id-13" is="div">
            <Text text="Sed sed risus pretium quam vulputate dignissim. Eget nunc lobortis mattis aliquam faucibus purus in. Potenti nullam ac tortor vitae purus faucibus ornare suspendisse." />
            </Wrapper>
            </Typography>
            </Box>
            <Box className='mx-auto' sx={{padding:'1em', width:'100%', maxWidth:'1192px'}}>
            <Accordion sx={{background:'transparent', borderBottom:'1px solid rgb(246, 250, 112)', color:'#000000', padding:'5px', margin:'10px'}}>
            <AccordionSummary
            aria-controls="panel1a-content"
            id="panel1a-header"
            >
            <Box className='justify-center' sx={{display:'flex', flexFlow:'row'}}>
            <Box className='pr-6'>
            <AddIcon sx={{color:'#B20312', fontSize:'40px'}} />
            </Box>
            <Box>
            <Typography className='pt-[3px]' sx={{fontSize:'22px', fontWeight:'400px', fontFamily:'Prompt'}}>
            <Wrapper canvas style={{ display: "inline-block" }} id="random-id-14" is="div">
            <Text text="Sed sed risus pretium quam vulputate dignissim?" />
            </Wrapper>
            </Typography>
            </Box>
            </Box>
            </AccordionSummary> 
            <AccordionDetails >
            <Typography className='w-[90%] mx-auto' sx={{fontSize:'16px', fontWeight:'400px', fontFamily:'Prompt', padding:'10px 0px 0px 0px', lineHeight:'25.6px', letterSpacing:'1.5px',}}>
    
            <Wrapper canvas style={{ display: "inline-block" }} id="random-id-15" is="div">
            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />
            </Wrapper>
            </Typography>
            </AccordionDetails>
            </Accordion>
            <Accordion sx={{background:'transparent', borderBottom:'1px solid rgb(246, 250, 112)', color:'#000000', padding:'5px',  margin:'10px'}}>
            <AccordionSummary
            aria-controls="panel2a-content"
            id="panel2a-header"
            >
            <Box className='justify-center' sx={{display:'flex', flexFlow:'row'}}>
            <Box className='pr-6'>
            <AddIcon sx={{color:'#B20312', fontSize:'40px'}} />
            </Box>
            <Box>
            <Typography className='pt-[3px]' sx={{fontSize:'22px', fontWeight:'400px', fontFamily:'Prompt'}}>
            <Wrapper canvas style={{ display: "inline-block" }} id="random-id-16" is="div">
            <Text text="Sed sed risus pretium quam vulputate dignissim?" />
            </Wrapper>
            </Typography>
            </Box>
            </Box>
            </AccordionSummary>
            <AccordionDetails>
            <Typography className='w-[90%] mx-auto' sx={{fontSize:'16px', lineHeight:'25.6px', letterSpacing:'1.5px', fontWeight:'400px', fontFamily:'Prompt', padding:'10px 0px 0px 0px'}}>
                
            <Wrapper canvas style={{ display: "inline-block" }} id="random-id-17" is="div">
            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />
            </Wrapper>
            </Typography>
            </AccordionDetails>
            </Accordion>
            <Accordion sx={{background:'transparent', borderBottom:'1px solid rgb(246, 250, 112)', color:'#000000', padding:'5px',  margin:'10px'}}>
            <AccordionSummary
            aria-controls="panel2a-content"
            id="panel2a-header"
            >
            <Box className='justify-center' sx={{display:'flex', flexFlow:'row'}}>
            <Box className='pr-6'>
            <AddIcon sx={{color:'#B20312', fontSize:'40px'}} />
            </Box>
            <Box>
            <Typography className='pt-[3px]' sx={{fontSize:'22px', fontWeight:'400px', fontFamily:'Prompt'}}>
            <Wrapper canvas style={{ display: "inline-block" }} id="random-id-18" is="div">
            <Text text="Sed sed risus pretium quam vulputate dignissim?" />
            </Wrapper>
            </Typography>
            </Box>
            </Box>
            </AccordionSummary>
            <AccordionDetails>
            <Typography className='w-[90%] mx-auto' sx={{fontSize:'16px', lineHeight:'25.6px', letterSpacing:'1.5px', fontWeight:'400px', fontFamily:'Prompt', padding:'10px 0px 0px 0px'}}>
                
            <Wrapper canvas style={{ display: "inline-block" }} id="random-id-19" is="div">
            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />
            </Wrapper>
            </Typography>
            </AccordionDetails>
            </Accordion>
            <Accordion sx={{background:'transparent', borderBottom:'1px solid rgb(246, 250, 112)', color:'#000000', padding:'5px',  margin:'10px'}}>
            <AccordionSummary
            aria-controls="panel2a-content"
            id="panel2a-header"
            >
            <Box className='justify-center' sx={{display:'flex', flexFlow:'row'}}>
            <Box className='pr-6'>
            <AddIcon sx={{color:'#B20312', fontSize:'40px'}} />
            </Box>
            <Box>
            <Typography className='pt-[3px]' sx={{fontSize:'22px', fontWeight:'400px', fontFamily:'Prompt'}}>
            <Wrapper canvas style={{ display: "inline-block" }} id="random-id-23" is="div">
            <Text text="Sed sed risus pretium quam vulputate dignissim?" />
            </Wrapper>
            </Typography>
            </Box>
            </Box>
            </AccordionSummary>
            <AccordionDetails>
            <Typography className='w-[90%] mx-auto' sx={{fontSize:'16px', lineHeight:'25.6px', letterSpacing:'1.5px', fontWeight:'400px', fontFamily:'Prompt', padding:'10px 0px 0px 0px'}}>
                
            <Wrapper canvas style={{ display: "inline-block" }} id="random-id-20" is="div">
            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />
            </Wrapper>
            </Typography>
            </AccordionDetails>
            </Accordion>
            <Accordion sx={{background:'transparent', borderBottom:'1px solid rgb(246, 250, 112)', color:'#000000', padding:'5px',  margin:'10px'}}>
            <AccordionSummary
            aria-controls="panel2a-content"
            id="panel2a-header"
            >
            <Box className='justify-center' sx={{display:'flex', flexFlow:'row'}}>
            <Box className='pr-6'>
            <AddIcon sx={{color:'#B20312', fontSize:'40px'}} />
            </Box>
            <Box>
            <Typography className='pt-[3px]' sx={{fontSize:'22px', fontWeight:'400px', fontFamily:'Prompt'}}>
            <Wrapper canvas style={{ display: "inline-block" }} id="random-id-21" is="div">
            <Text text="Sed sed risus pretium quam vulputate dignissim?" />
            </Wrapper>
            </Typography>
            </Box>
            </Box>
            </AccordionSummary>
            <AccordionDetails>
            <Typography className='w-[90%] mx-auto' sx={{fontSize:'16px', lineHeight:'25.6px', letterSpacing:'1.5px', fontWeight:'400px', fontFamily:'Prompt', padding:'10px 0px 0px 0px'}}>
                
            <Wrapper canvas style={{ display: "inline-block" }} id="random-id-22" is="div">
            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />
            </Wrapper>
            </Typography>
            </AccordionDetails>
            </Accordion>
            </Box>
            </div></Element>
      </Element>,
    
            <Element canvas is={Parent} id='parentId500' className="w-[100%] h-auto" >
      <Element canvas is={Parent} id='parentId600' className="w-[100%] h-auto" row={true} ><div className='w-[100%] h-full' style={{background:'#FDF4F4'}}>
            <Box className='container' sx={{textAlign:'center'}}>
            <Typography className='text-[#100103] my-4' sx={{fontSize:'46px', fontWeight:'bold', fontFamily:'Almarai'}}>
            <Wrapper canvas style={{ display: "inline-block" }} id="random-id-25" is="div">
            <Text text="Questions?" />
            </Wrapper>
            </Typography>
            <Typography className='container mx-auto my-4' sx={{fontSize:'22px', fontWeight:'400px', fontFamily:'Almarai', color:'[#100103]', maxWidth:'full'}}>
            <Wrapper canvas style={{ display: "inline-block" }} id="random-id-26" is="div">
            <Text text="Sed sed risus pretium quam vulputate dignissim. Eget nunc lobortis mattis aliquam faucibus purus in. Potenti nullam ac tortor vitae purus faucibus ornare suspendisse." />
            </Wrapper>
            </Typography>
            </Box>
            <Box className='mx-auto' sx={{padding:'1em', width:'100%', maxWidth:'1192px'}}>
            <Accordion sx={{background:'transparent', borderBottom:'1px solid rgb(255, 183, 183)', color:'#000000', padding:'5px', margin:'10px'}}>
            <AccordionSummary
            aria-controls="panel1a-content"
            id="panel1a-header"
            >
            <Box className='justify-center' sx={{display:'flex', flexFlow:'row'}}>
            <Box className='pr-6'>
            <AddIcon sx={{color:'#F31559', fontSize:'40px'}} />
            </Box>
            <Box>
            <Typography className='pt-[3px]' sx={{fontSize:'22px', fontWeight:'400px', fontFamily:'Almarai'}}>
            <Wrapper canvas style={{ display: "inline-block" }} id="random-id-27" is="div">
            <Text text="Sed sed risus pretium quam vulputate dignissim?" />
            </Wrapper>
            </Typography>
            </Box>
            </Box>
            </AccordionSummary> 
            <AccordionDetails >
            <Typography className='w-[90%] mx-auto' sx={{fontSize:'16px', fontWeight:'400px', fontFamily:'Almarai', padding:'10px 0px 0px 0px', lineHeight:'25.6px', letterSpacing:'1.5px',}}>
    
            <Wrapper canvas style={{ display: "inline-block" }} id="random-id-28" is="div">
            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />
            </Wrapper>
            </Typography>
            </AccordionDetails>
            </Accordion>
            <Accordion sx={{background:'transparent', borderBottom:'1px solid rgb(255, 183, 183)', color:'#000000', padding:'5px',  margin:'10px'}}>
            <AccordionSummary
            aria-controls="panel2a-content"
            id="panel2a-header"
            >
            <Box className='justify-center' sx={{display:'flex', flexFlow:'row'}}>
            <Box className='pr-6'>
            <AddIcon sx={{color:'#F31559', fontSize:'40px'}} />
            </Box>
            <Box>
            <Typography className='pt-[3px]' sx={{fontSize:'22px', fontWeight:'400px', fontFamily:'Almarai'}}>
            <Wrapper canvas style={{ display: "inline-block" }} id="random-id-29" is="div">
            <Text text="Sed sed risus pretium quam vulputate dignissim?" />
            </Wrapper>
            </Typography>
            </Box>
            </Box>
            </AccordionSummary>
            <AccordionDetails>
            <Typography className='w-[90%] mx-auto' sx={{fontSize:'16px', lineHeight:'25.6px', letterSpacing:'1.5px', fontWeight:'400px', fontFamily:'Almarai', padding:'10px 0px 0px 0px'}}>
                
            <Wrapper canvas style={{ display: "inline-block" }} id="random-id-30" is="div">
            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />
            </Wrapper>
            </Typography>
            </AccordionDetails>
            </Accordion>
            <Accordion sx={{background:'transparent', borderBottom:'1px solid rgb(255, 183, 183)', color:'#000000', padding:'5px',  margin:'10px'}}>
            <AccordionSummary
            aria-controls="panel2a-content"
            id="panel2a-header"
            >
            <Box className='justify-center' sx={{display:'flex', flexFlow:'row'}}>
            <Box className='pr-6'>
            <AddIcon sx={{color:'#F31559', fontSize:'40px'}} />
            </Box>
            <Box>
            <Typography className='pt-[3px]' sx={{fontSize:'22px', fontWeight:'400px', fontFamily:'Almarai'}}>
            <Wrapper canvas style={{ display: "inline-block" }} id="random-id-31" is="div">
            <Text text="Sed sed risus pretium quam vulputate dignissim?" />
            </Wrapper>
            </Typography>
            </Box>
            </Box>
            </AccordionSummary>
            <AccordionDetails>
            <Typography className='w-[90%] mx-auto' sx={{fontSize:'16px', lineHeight:'25.6px', letterSpacing:'1.5px', fontWeight:'400px', fontFamily:'Almarai', padding:'10px 0px 0px 0px'}}>
                
            <Wrapper canvas style={{ display: "inline-block" }} id="random-id-32" is="div">
            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />
            </Wrapper>
            </Typography>
            </AccordionDetails>
            </Accordion>
            <Accordion sx={{background:'transparent', borderBottom:'1px solid rgb(255, 183, 183)', color:'#000000', padding:'5px',  margin:'10px'}}>
            <AccordionSummary
            aria-controls="panel2a-content"
            id="panel2a-header"
            >
            <Box className='justify-center' sx={{display:'flex', flexFlow:'row'}}>
            <Box className='pr-6'>
            <AddIcon sx={{color:'#F31559', fontSize:'40px'}} />
            </Box>
            <Box>
            <Typography className='pt-[3px]' sx={{fontSize:'22px', fontWeight:'400px', fontFamily:'Almarai'}}>
            <Wrapper canvas style={{ display: "inline-block" }} id="random-id-33" is="div">
            <Text text="Sed sed risus pretium quam vulputate dignissim?" />
            </Wrapper>
            </Typography>
            </Box>
            </Box>
            </AccordionSummary>
            <AccordionDetails>
            <Typography className='w-[90%] mx-auto' sx={{fontSize:'16px', lineHeight:'25.6px', letterSpacing:'1.5px', fontWeight:'400px', fontFamily:'Almarai', padding:'10px 0px 0px 0px'}}>
                
            <Wrapper canvas style={{ display: "inline-block" }} id="random-id-34" is="div">
            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />
            </Wrapper>
            </Typography>
            </AccordionDetails>
            </Accordion>
            <Accordion sx={{background:'transparent', borderBottom:'1px solid rgb(255, 183, 183)', color:'#000000', padding:'5px',  margin:'10px'}}>
            <AccordionSummary
            aria-controls="panel2a-content"
            id="panel2a-header"
            >
            <Box className='justify-center' sx={{display:'flex', flexFlow:'row'}}>
            <Box className='pr-6'>
            <AddIcon sx={{color:'#F31559', fontSize:'40px'}} />
            </Box>
            <Box>
            <Typography className='pt-[3px]' sx={{fontSize:'22px', fontWeight:'400px', fontFamily:'Almarai'}}>
            <Wrapper canvas style={{ display: "inline-block" }} id="random-id-35" is="div">
            <Text text="Sed sed risus pretium quam vulputate dignissim?" />
            </Wrapper>
            </Typography>
            </Box>
            </Box>
            </AccordionSummary>
            <AccordionDetails>
            <Typography className='w-[90%] mx-auto' sx={{fontSize:'16px', lineHeight:'25.6px', letterSpacing:'1.5px', fontWeight:'400px', fontFamily:'Almarai', padding:'10px 0px 0px 0px'}}>
                
            <Wrapper canvas style={{ display: "inline-block" }} id="random-id-36" is="div">
            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />
            </Wrapper>
            </Typography>
            </AccordionDetails>
            </Accordion>
            </Box>
            </div></Element>
      </Element>
            ,
    
            
            <Element canvas is={Parent} id='parentId700' className="w-[100%] h-auto" >
      <Element canvas is={Parent} id='parentId800' className="w-[100%] h-auto" row={true} ><div className='w-[100%] h-full' style={{background:'#F6F2FA'}}>
            <Box className='container' sx={{textAlign:'center'}}>
            <Typography className='text-[#100103] my-4' sx={{fontSize:'46px', fontWeight:'bold', fontFamily:'Chivo'}}>
            <Wrapper canvas style={{ display: "inline-block" }} id="random-id-37" is="div">
            <Text text="Questions?" />
            </Wrapper>
            </Typography>
            <Typography className='container mx-auto my-4' sx={{fontSize:'22px', fontWeight:'400px', fontFamily:'Chivo', color:'[#100103]', maxWidth:'full'}}>
            <Wrapper canvas style={{ display: "inline-block" }} id="random-id-38" is="div">
            <Text text="Sed sed risus pretium quam vulputate dignissim. Eget nunc lobortis mattis aliquam faucibus purus in. Potenti nullam ac tortor vitae purus faucibus ornare suspendisse." />
            </Wrapper>
            </Typography>
            </Box>
            <Box className='mx-auto' sx={{padding:'1em', width:'100%', maxWidth:'1192px'}}>
            <Accordion sx={{background:'transparent', borderBottom:'1px solid rgb(212, 173, 252)', color:'#000000', padding:'5px', margin:'10px'}}>
            <AccordionSummary
            aria-controls="panel1a-content"
            id="panel1a-header"
            >
            <Box className='justify-center' sx={{display:'flex', flexFlow:'row'}}>
            <Box className='pr-6'>
            <AddIcon sx={{color:'#0C134F', fontSize:'40px'}} />
            </Box>
            <Box>
            <Typography className='pt-[3px]' sx={{fontSize:'22px', fontWeight:'400px', fontFamily:'Chivo'}}>
            <Wrapper canvas style={{ display: "inline-block" }} id="random-id-39" is="div">
            <Text text="Sed sed risus pretium quam vulputate dignissim?" />
            </Wrapper>
            </Typography>
            </Box>
            </Box>
            </AccordionSummary> 
            <AccordionDetails >
            <Typography className='w-[90%] mx-auto' sx={{fontSize:'16px', fontWeight:'400px', fontFamily:'Chivo', padding:'10px 0px 0px 0px', lineHeight:'25.6px', letterSpacing:'1.5px',}}>
    
            <Wrapper canvas style={{ display: "inline-block" }} id="random-id-40" is="div">
            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />
            </Wrapper>
            </Typography>
            </AccordionDetails>
            </Accordion>
            <Accordion sx={{background:'transparent', borderBottom:'1px solid rgb(212, 173, 252)', color:'#000000', padding:'5px',  margin:'10px'}}>
            <AccordionSummary
            aria-controls="panel2a-content"
            id="panel2a-header"
            >
            <Box className='justify-center' sx={{display:'flex', flexFlow:'row'}}>
            <Box className='pr-6'>
            <AddIcon sx={{color:'#0C134F', fontSize:'40px'}} />
            </Box>
            <Box>
            <Typography className='pt-[3px]' sx={{fontSize:'22px', fontWeight:'400px', fontFamily:'Chivo'}}>
            <Wrapper canvas style={{ display: "inline-block" }} id="random-id-41" is="div">
            <Text text="Sed sed risus pretium quam vulputate dignissim?" />
            </Wrapper>
            </Typography>
            </Box>
            </Box>
            </AccordionSummary>
            <AccordionDetails>
            <Typography className='w-[90%] mx-auto' sx={{fontSize:'16px', lineHeight:'25.6px', letterSpacing:'1.5px', fontWeight:'400px', fontFamily:'Chivo', padding:'10px 0px 0px 0px'}}>
                
            <Wrapper canvas style={{ display: "inline-block" }} id="random-id-42" is="div">
            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />
            </Wrapper>
            </Typography>
            </AccordionDetails>
            </Accordion>
            <Accordion sx={{background:'transparent', borderBottom:'1px solid rgb(212, 173, 252)', color:'#000000', padding:'5px',  margin:'10px'}}>
            <AccordionSummary
            aria-controls="panel2a-content"
            id="panel2a-header"
            >
            <Box className='justify-center' sx={{display:'flex', flexFlow:'row'}}>
            <Box className='pr-6'>
            <AddIcon sx={{color:'#0C134F', fontSize:'40px'}} />
            </Box>
            <Box>
            <Typography className='pt-[3px]' sx={{fontSize:'22px', fontWeight:'400px', fontFamily:'Chivo'}}>
            <Wrapper canvas style={{ display: "inline-block" }} id="random-id-43" is="div">
            <Text text="Sed sed risus pretium quam vulputate dignissim?" />
            </Wrapper>
            </Typography>
            </Box>
            </Box>
            </AccordionSummary>
            <AccordionDetails>
            <Typography className='w-[90%] mx-auto' sx={{fontSize:'16px', lineHeight:'25.6px', letterSpacing:'1.5px', fontWeight:'400px', fontFamily:'Chivo', padding:'10px 0px 0px 0px'}}>
                
            <Wrapper canvas style={{ display: "inline-block" }} id="random-id-44" is="div">
            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />
            </Wrapper>
            </Typography>
            </AccordionDetails>
            </Accordion>
            <Accordion sx={{background:'transparent', borderBottom:'1px solid rgb(212, 173, 252)', color:'#000000', padding:'5px',  margin:'10px'}}>
            <AccordionSummary
            aria-controls="panel2a-content"
            id="panel2a-header"
            >
            <Box className='justify-center' sx={{display:'flex', flexFlow:'row'}}>
            <Box className='pr-6'>
            <AddIcon sx={{color:'#0C134F', fontSize:'40px'}} />
            </Box>
            <Box>
            <Typography className='pt-[3px]' sx={{fontSize:'22px', fontWeight:'400px', fontFamily:'Chivo'}}>
            <Wrapper canvas style={{ display: "inline-block" }} id="random-id-45" is="div">
            <Text text="Sed sed risus pretium quam vulputate dignissim?" />
            </Wrapper>
            </Typography>
            </Box>
            </Box>
            </AccordionSummary>
            <AccordionDetails>
            <Typography className='w-[90%] mx-auto' sx={{fontSize:'16px', lineHeight:'25.6px', letterSpacing:'1.5px', fontWeight:'400px', fontFamily:'Chivo', padding:'10px 0px 0px 0px'}}>
                
            <Wrapper canvas style={{ display: "inline-block" }} id="random-id-46" is="div">
            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />
            </Wrapper>
            </Typography>
            </AccordionDetails>
            </Accordion>
            <Accordion sx={{background:'transparent', borderBottom:'1px solid rgb(212, 173, 252)', color:'#000000', padding:'5px',  margin:'10px'}}>
            <AccordionSummary
            aria-controls="panel2a-content"
            id="panel2a-header"
            >
            <Box className='justify-center' sx={{display:'flex', flexFlow:'row'}}>
            <Box className='pr-6'>
            <AddIcon sx={{color:'#0C134F', fontSize:'40px'}} />
            </Box>
            <Box>
            <Typography className='pt-[3px]' sx={{fontSize:'22px', fontWeight:'400px', fontFamily:'Chivo'}}>
            <Wrapper canvas style={{ display: "inline-block" }} id="random-id-47" is="div">
            <Text text="Sed sed risus pretium quam vulputate dignissim?" />
            </Wrapper>
            </Typography>
            </Box>
            </Box>
            </AccordionSummary>
            <AccordionDetails>
            <Typography className='w-[90%] mx-auto' sx={{fontSize:'16px', lineHeight:'25.6px', letterSpacing:'1.5px', fontWeight:'400px', fontFamily:'Chivo', padding:'10px 0px 0px 0px'}}>
                
            <Wrapper canvas style={{ display: "inline-block" }} id="random-id-48" is="div">
            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />
            </Wrapper>
            </Typography>
            </AccordionDetails>
            </Accordion>
            </Box>
            </div></Element>
      </Element>
    ]

    
const [sel, setSel] = useState(selected)

function makeOdd(number) {
  setProp((prop) => {
    setSel(100);
  }, 1000);

  setTimeout(() => {
    setProp((prop) => {
      setSel(number)
    }, 1000);
  }, 100);


  
}

useEffect(() => {
if(selected || selected >= 0) {
  makeOdd(selected);
}
},[selected])



return (
  <div style={{ padding: enabled? "10px" : "0px", display:"flex", justifyContent:"center", alignItems:"center", width:'100%', height:'100%'}} ref={connect}>
 {sel == 100 ? (
          <div style={{height:"85vh", display:"flex", justifyContent:"center", alignItems:"center"}}>
          <BounceLoader
                  size={100}
                  color={"white"}
                  loading={true}
                />
          </div>
         ) : (
          styles[sel]
         )} 
</div>
);
}

FAQ_2.craft = {
displayName: "Faq 2",
props: {
   selected: 1, // here is the default value of the selected style
isBlock: true
  },
  rules: {
    canDrag: () => true,
    canDrop: () => true,
  },
  related: {
    toolbar: NewFaqSettings,
  },
};