/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, { useEffect, useState } from 'react';
import { Element, useEditor, useNode } from '@craftjs/core';
import { NewBannerSettings } from './newBannerSetting';
import { Text } from '../../basic/Text';
import './Banner.css'
import './Banner3.css'

import { ImageC } from '../../basic/Image';
import { Button } from '../../basic/Button';
import { ArrowToRight, HelpArrowBanner } from '../../../editor/Viewport/Pop-up/svgArray';
import { Container } from  '../../../Container/index.js';
import { Parent } from '../../Parent/index.js';
import { BounceLoader } from 'react-spinners';
import { Wrapper } from '../../wrapper/index.js';

export const Banner_3 = ({ selected }) => {
 
  const { 
    actions: { setProp },
      connectors: {
        connect
      } 
  } = useNode();

  const {
    enabled,

  } = useEditor((state, query) => ({
    enabled: state.options.enabled,
 
  }));

  const styles = [
    <Element canvas is={Parent} id='parentId100' className="parentWrapper" style={{ background: '#FFF' }}>
  <Element canvas is={Parent} id='parentId200' className="parentWrapper2" row={true} >
    <Element is={Parent} canvas id='random-id-1' className='wrapperContainer' >
        <Element is={Parent} canvas id='random-id-3'  className='columnContainer'>
          <Element is={Parent} canvas id='random-id-4' style={{ display: "inline-block" }}  className='banner-header'>
            <Text text='Understand User Flow' />
          </Element>
          <Element is={Parent} canvas id='random-id-5' style={{ display: "inline-block" }}  className='banner-subheader my-4'>
            <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.' />
          </Element>
          <Element is={Parent} canvas id='random-id-6'  className='button-aria flex flex-row w-full' >

            <Element is={Parent} canvas id='random-id-7' className='rounded-[33px]' >
              <Button  classn='block' class='w-[152px] h-[46px] flex rounded-[33px] cursor-pointer border border-solid border-black bg-transparent"'>
                <Element is={Parent} canvas id='random-id-8'  style={{ display: "inline-block", cursor: 'pointer', color: '#000' }} className='cursor-pointer my-auto mx-auto button-text' >
                  <Text text='Learn more' />
                </Element>
              </Button>
            </Element>

            <Element is={Parent} canvas id='random-id-9'  className='flex-row flex relative' style={{ cursor: 'pointer' }}>
              <Element is={Parent} canvas id='random-id-10'  style={{ margin: '0 6px 0' }} className='rounded-[33px]'>
                <Button  classn='block' class='rounded-[33px] cursor-pointer bg-[#343CE8] w-[152px] h-[46px] flex'>
                  <Element is={Parent} canvas id='random-id-11' style={{ display: "inline-block", color: '#FFF', cursor: 'pointer' }}  className='cursor-pointer my-auto mx-auto button-text'>
                    <Text text='Start for free' />
                  </Element>
                </Button>
              </Element>

              <Element is={Parent} canvas id='random-id-12'  className='my-auto absolute top-6 right-[44px]' style={{ zIndex: 20 }}>
                <HelpArrowBanner fill='#343CE8' />
              </Element>

              <Element is={Parent} canvas id='random-id-13'  style={{ margin: '0 0 0 4px' }}>
                <Button  classn='inline-flex' class='rounded-full cursor-pointer bg-[#343CE8] w-[46px] h-[46px] flex my-auto' >
                  <Element is={Parent} canvas id='random-id-14'  className='my-auto mx-auto -rotate-45 hover:rotate-45 transition duration-700 ease-in-out'>
                    <ArrowToRight fill='#FFF' />
                  </Element>
                </Button>
              </Element>
            </Element>
 
          </Element>
        </Element>
        <Element is={Parent} canvas id='random-id-15'  className='columnContainer'>
          <Element is={Parent} canvas id='random-id-16'  className='relative' style={{ borderRadius: '30px' }}>
            <Element is={Parent} canvas id='random-id-17'  className='absolute top-[20px] right-[20px] flex flex-row'>
              <Element is={Parent} canvas id='random-id-18' style={{ display: "inline-block", margin: 'auto 10px' }}  className='img-text '>
                <Text text='100K' />
              </Element>
              <Element is={Parent} canvas id='random-id-19' style={{ display: "inline-block", maxWidth: '53px', margin: 'auto auto', fontSize: '16px' }}  className='img-text '>
                <Text text='Happy Users' />
              </Element>
            </Element>
            <Element is={Parent} canvas id='random-id-20' style={{ borderRadius: '30px' }} className='banner-img' >
              <ImageC style={{ borderRadius: '30px', width: '100%', height: '100%', maxWidth: '640px', maxHeight: '340px' }} className='object-cover' alt='banner-img' src='https://i.ibb.co/rs0rJfc/711798e37e8a0197bf19cf0701fdef35.jpg' />
            </Element>
            <Element is={Parent} canvas id='random-id-21' >
              <Button  classn='block' class='img-button bg-[#ffffffcc] cursor-pointer rounded-[33px]'>
                <Element is={Parent} canvas id='random-id-22' style={{ display: "inline-block", color: '#000', cursor: 'pointer' }}  className='button-text cursor-pointer'>
                  <Text text='About our team' />
                </Element>
                <ArrowToRight />
              </Button>
            </Element>
          </Element>
          <Element is={Parent} canvas id='random-id-23'  className='flex flex-row w-[100%]' style={{ justifyContent: 'center', borderRadius: '30px', background: '#E5E5E5', margin: '8px 0 0 0', padding: '24px 5px' }}>
            <Element is={Parent} canvas id='random-id-24'  className='flex flex-col mx-auto my-auto'>
              <Element is={Parent} canvas id='random-id-25' style={{ display: "inline-block" }}  className='card-header'>
                <Text text='85%' />
              </Element>
              <Element is={Parent} canvas id='random-id-26' style={{ display: "inline-block" }}  className='card-subheader'>
                <Text text='Lorem Ipsum' />
              </Element>
            </Element>
            <Element is={Parent} canvas id='random-id-27'  className='flex flex-col mx-auto my-auto'>
              <Element is={Parent} canvas id='random-id-28' style={{ display: "inline-block" }}  className='card-header'>
                <Text text='120K' />
              </Element>
              <Element is={Parent} canvas id='random-id-29' style={{ display: "inline-block" }}  className='card-subheader'>
                <Text text='Lorem Ipsum' />
              </Element>
            </Element>
            <Element is={Parent} canvas id='random-id-30'  className='flex flex-col mx-auto my-auto'>
              <Element is={Parent} canvas id='random-id-31' style={{ display: "inline-block" }}  className='card-header'>
                <Text text='5%' />
              </Element>
              <Element is={Parent} canvas id='random-id-32' style={{ display: "inline-block" }}  className='card-subheader'>
                <Text text='Lorem Ipsum' />
              </Element>
            </Element>
          </Element>
        </Element>
    </Element>
    </Element>
  </Element>,

    <Element canvas is={Parent} id='parentId300' className="parentWrapper" style={{ background: '#2D2C32' }}>
  <Element canvas is={Parent} id='parentId400' className="parentWrapper2" row={true} >
    <Element is={Parent} canvas id='random-id-33' className='wrapperContainer' >
        <Element is={Parent} canvas id='random-id-35'  className='columnContainer'>
          <Element is={Parent} canvas id='random-id-36' style={{ display: "inline-block", color: '#FFF', width:'100%' }}  className='banner-header'>
            <Text text='Understand User Flow' />
          </Element>
          <Element is={Parent} canvas id='random-id-37' style={{ display: "inline-block", color: '#FFF' }}  className='banner-subheader my-4'>
            <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.' />
          </Element>
          <Element is={Parent} canvas id='random-id-1'  className='button-aria flex flex-row w-full' >
            <Element is={Parent} canvas id='random-id-1' className='rounded-[33px]' >
              <Button  classn='block' class='w-[152px] h-[46px] flex rounded-[33px] cursor-pointer border border-solid border-white bg-transparent"'>
                <Element is={Parent} canvas id='random-id-1'  style={{ display: "inline-block", cursor: 'pointer', color: '#fff' }} className='cursor-pointer my-auto mx-auto button-text' >
                  <Text text='Learn more' />
                </Element>
              </Button>
            </Element>

            <Element is={Parent} canvas id='random-id-1'  className='flex-row flex relative' style={{ cursor: 'pointer' }}>
              <Element is={Parent} canvas id='random-id-1'  style={{ margin: '0 6px 0' }} className='rounded-[33px]'>
                <Button  classn='block' class='rounded-[33px] cursor-pointer bg-[#9007D2] w-[152px] h-[46px] flex'>
                  <Element is={Parent} canvas id='random-id-1' style={{ display: "inline-block", color: '#FFF', cursor: 'pointer' }}  className='cursor-pointer my-auto mx-auto button-text'>
                    <Text text='Start for free' />
                  </Element>
                </Button>
              </Element>
              <Element is={Parent} canvas id='random-id-1'  className='my-auto absolute top-6 right-[44px]' style={{ zIndex: 20 }}>
                <HelpArrowBanner fill='#9007D2' />
              </Element>
              <Element is={Parent} canvas id='random-id-1'  style={{ margin: '0 0 0 4px' }}>
                <Button  classn='inline-flex' class='rounded-full cursor-pointer bg-[#9007D2] w-[46px] h-[46px] flex my-auto' >
                  <Element is={Parent} canvas id='random-id-1'  className='my-auto mx-auto -rotate-45 hover:rotate-45 transition duration-700 ease-in-out'>
                    <ArrowToRight fill='#FFF' />
                  </Element>
                </Button>
              </Element>
            </Element>
          </Element>
        </Element>
        <Element is={Parent} canvas id='random-id-47'  className='columnContainer'>
        <Element is={Parent} canvas id='random-id-48' className='w-full h-full rounded-[30px]'>
          <Element is={Parent} canvas id='random-id-52' className='banner-img'>
            <Element is={Parent} canvas id='random-id-49' className='text-absolute'>
              <Element is={Parent} canvas id='random-id-50' className='img-text'>
                <Text text='100K' />
              </Element>
              <Element is={Parent} canvas id='random-id-51' className='img-text' style={{ fontSize: '16px' }}>
                <Text text='Happy Users' />
              </Element>
            </Element>
            <ImageC className='banner-img' alt='banner-img' src='https://i.ibb.co/N16Kjxg/5c0df7910ab8f6be7c19479ba7e615b8.jpg' />
          </Element>
          <Element is={Parent} canvas id='random-id-53'>
            <Button className='img-button'>
              <Element is={Parent} canvas id='random-id-54' className='button-text'>
                <Text text='About our team' />
              </Element>
              <ArrowToRight />
            </Button>
          </Element>
        </Element>
          <Element is={Parent} canvas id='random-id-55'  className='flex flex-row w-[100%]' style={{ justifyContent: 'center', borderRadius: '30px', background: 'rgba(255, 255, 255, 0.10)', margin: '8px 0 0 0', padding: '24px 5px' }}>
            <Element is={Parent} canvas id='random-id-55'  className='flex flex-col mx-auto my-auto'>
              <Element is={Parent} canvas id='random-id-56' style={{ display: "inline-block", color: '#FFF' }}  className='card-header'>
                <Text text='85%' />
              </Element>
              <Element is={Parent} canvas id='random-id-57' style={{ display: "inline-block", color: '#FFF' }}  className='card-subheader'>
                <Text text='Lorem Ipsum' />
              </Element>
            </Element>
            <Element is={Parent} canvas id='random-id-58'  className='flex flex-col mx-auto my-auto'>
              <Element is={Parent} canvas id='random-id-59' style={{ display: "inline-block", color: '#FFF' }}  className='card-header'>
                <Text text='120K' />
              </Element>
              <Element is={Parent} canvas id='random-id-60' style={{ display: "inline-block", color: '#FFF' }}  className='card-subheader'>
                <Text text='Lorem Ipsum' />
              </Element>
            </Element>
            <Element is={Parent} canvas id='random-id-61'  className='flex flex-col mx-auto my-auto'>
              <Element is={Parent} canvas id='random-id-62' style={{ display: "inline-block", color: '#FFF' }}  className='card-header'>
                <Text text='5%' />
              </Element>
              <Element is={Parent} canvas id='random-id-63' style={{ display: "inline-block", color: '#FFF' }}  className='card-subheader'>
                <Text text='Lorem Ipsum' />
              </Element>
            </Element>
          </Element>
        </Element>
    </Element>
    </Element>
  </Element>,


    <Element canvas is={Parent} id='parentId500' className="parentWrapper" style={{ background: '#FFF' }}>
  <Element canvas is={Parent} id='parentId600' className="parentWrapper2" row={true} >
    <Element is={Parent} canvas id='random-id-64' className='wrapperContainer' >
        <Element is={Parent} canvas id='random-id-66'  className='columnContainer'>
          <Element is={Parent} canvas id='random-id-67' style={{ display: "inline-block", color: '#000000' }}  className='banner-header'>
            <Text text='Understand User Flow' />
          </Element>
          <Element is={Parent} canvas id='random-id-68' style={{ display: "inline-block", color: '#000000' }}  className='banner-subheader my-4'>
            <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.' />
          </Element>
          <Element is={Parent} canvas id='random-id-69'  className='button-aria flex flex-row w-full' >
            <Element is={Parent} canvas id='random-id-70' className='rounded-[33px]' >
              <Button  classn='block' class='w-[152px] h-[46px] flex rounded-[33px] cursor-pointer border border-solid border-black bg-transparent"'>
                <Element is={Parent} canvas id='random-id-71'  style={{ display: "inline-block", cursor: 'pointer', color: '#000' }} className='cursor-pointer my-auto mx-auto button-text' >
                  <Text text='Learn more' />
                </Element>
              </Button>
            </Element>

            <Element is={Parent} canvas id='random-id-72'  className='flex-row flex relative' style={{ cursor: 'pointer' }}>
              <Element is={Parent} canvas id='random-id-73'  style={{ margin: '0 6px 0' }} className='rounded-[33px]'>
                <Button  classn='block' class='rounded-[33px] cursor-pointer bg-[#343CE8] w-[152px] h-[46px] flex'>
                  <Element is={Parent} canvas id='random-id-74' style={{ display: "inline-block", color: '#FFF', cursor: 'pointer' }}  className='cursor-pointer my-auto mx-auto button-text'>
                    <Text text='Start for free' />
                  </Element>
                </Button>
              </Element>
              <Element is={Parent} canvas id='random-id-75'  className='my-auto absolute top-6 right-[44px]' style={{ zIndex: 20 }}>
                <HelpArrowBanner fill='#343CE8' />
              </Element>
              <Element is={Parent} canvas id='random-id-76'  style={{ margin: '0 0 0 4px' }}>
                <Button  classn='inline-flex' class='rounded-full cursor-pointer bg-[#343CE8] w-[46px] h-[46px] flex my-auto' >
                  <Element is={Parent} canvas id='random-id-77'  className='my-auto mx-auto -rotate-45 hover:rotate-45 transition duration-700 ease-in-out'>
                    <ArrowToRight fill='#FFF' />
                  </Element>
                </Button>
              </Element>
            </Element>
          </Element>
        </Element>
        <Element is={Parent} canvas id='random-id-78'  className='flex flex-col w-[100%]' style={{ flex: '50%' }}>
          <Element is={Parent} canvas id='random-id-79'  className='relative' style={{ borderRadius: '30px' }}>
            <Element is={Parent} canvas id='random-id-80'  className='absolute top-[20px] right-[20px] flex flex-row'>
              <Element is={Parent} canvas id='random-id-81' style={{ display: "inline-block", color: '#000', margin: 'auto 10px' }}  className='img-text '>
                <Text text='100K' />
              </Element>
              <Element is={Parent} canvas id='random-id-82' style={{ display: "inline-block", color: '#000', maxWidth: '53px', margin: 'auto auto', fontSize: '16px' }}  className='img-text '>
                <Text text='Happy Users' />
              </Element>
            </Element>
            <Element is={Parent} canvas id='random-id-83' style={{ borderRadius: '30px' }} className='banner-img' >
              <ImageC style={{ borderRadius: '30px', width: '100%', height: '100%', maxWidth: '640px', maxHeight: '340px' }} className='object-cover' alt='banner-img' src='https://i.ibb.co/NrPnH5b/7d2814b7067494a02c36b82f6a50e11a.jpg' />
            </Element>
            <Element is={Parent} canvas id='random-id-84' >
              <Button  classn='block' class='img-button bg-[#FFFFFFCC] cursor-pointer rounded-[33px]'>
                <Element is={Parent} canvas id='random-id-85' style={{ display: "inline-block", color: '#000000', cursor: 'pointer' }}  className='button-text cursor-pointer'>
                  <Text text='About our team' />
                </Element>
                <ArrowToRight />
              </Button>
            </Element>
          </Element>
          <Element is={Parent} canvas id='random-id-86'  className='flex flex-row w-[100%]' style={{ justifyContent: 'center', borderRadius: '30px', background: '#DDEDFB', margin: '8px 0 0 0', padding: '24px 5px' }}>
            <Element is={Parent} canvas id='random-id-87'  className='flex flex-col mx-auto my-auto'>
              <Element is={Parent} canvas id='random-id-88' style={{ display: "inline-block", color: '#000000' }}  className='card-header'>
                <Text text='85%' />
              </Element>
              <Element is={Parent} canvas id='random-id-89' style={{ display: "inline-block", color: '#000000' }}  className='card-subheader'>
                <Text text='Lorem Ipsum' />
              </Element>
            </Element>
            <Element is={Parent} canvas id='random-id-90'  className='flex flex-col mx-auto my-auto'>
              <Element is={Parent} canvas id='random-id-91' style={{ display: "inline-block", color: '#000000' }}  className='card-header'>
                <Text text='120K' />
              </Element>
              <Element is={Parent} canvas id='random-id-92' style={{ display: "inline-block", color: '#000000' }}  className='card-subheader'>
                <Text text='Lorem Ipsum' />
              </Element>
            </Element>
            <Element is={Parent} canvas id='random-id-93'  className='flex flex-col mx-auto my-auto'>
              <Element is={Parent} canvas id='random-id-94' style={{ display: "inline-block", color: '#000000' }}  className='card-header'>
                <Text text='5%' />
              </Element>
              <Element is={Parent} canvas id='random-id-95' style={{ display: "inline-block", color: '#000000' }}  className='card-subheader'>
                <Text text='Lorem Ipsum' />
              </Element>
            </Element>
          </Element>
        </Element>
    </Element>
    </Element>
  </Element>,


    <Element canvas is={Parent} id='parentId700' className="parentWrapper" style={{ background: '#0C2117' }}>
  <Element canvas is={Parent} id='parentId800' className="parentWrapper2" row={true} >
    <Element is={Parent} canvas id='random-id-96' className='wrapperContainer' >
        <Element is={Parent} canvas id='random-id-98'  className='columnContainer'>
          <Element is={Parent} canvas id='random-id-99' style={{ display: "inline-block", color: '#FFFFFF' }}  className='banner-header'>
            <Text text='Understand User Flow' />
          </Element>
          <Element is={Parent} canvas id='random-id-100' style={{ display: "inline-block", color: '#FFFFFF' }}  className='banner-subheader my-4'>
            <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.' />
          </Element>
          <Element is={Parent} canvas id='random-id-101'  className='button-aria flex flex-row w-full' >
            <Element is={Parent} canvas id='random-id-102' className='rounded-[33px]' >
              <Button  classn='block' class='w-[152px] h-[46px] flex rounded-[33px] cursor-pointer border border-solid border-white bg-transparent"'>
                <Element is={Parent} canvas id='random-id-103'  style={{ display: "inline-block", cursor: 'pointer', color: '#FFFFFF' }} className='cursor-pointer my-auto mx-auto button-text' >
                  <Text text='Learn more' />
                </Element>
              </Button>
            </Element>

            <Element is={Parent} canvas id='random-id-104'  className='flex-row flex relative' style={{ cursor: 'pointer' }}>
              <Element is={Parent} canvas id='random-id-105'  style={{ margin: '0 6px 0' }} className='rounded-[33px]'>
                <Button  classn='block' class='rounded-[33px] cursor-pointer bg-[#3DA169] w-[152px] h-[46px] flex'>
                  <Element is={Parent} canvas id='random-id-106' style={{ display: "inline-block", color: '#FFF', cursor: 'pointer' }}  className='cursor-pointer my-auto mx-auto button-text'>
                    <Text text='Start for free' />
                  </Element>
                </Button>
              </Element>
              <Element is={Parent} canvas id='random-id-107'  className='my-auto absolute top-6 right-[44px]' style={{ zIndex: 20 }}>
                <HelpArrowBanner fill='#3DA169' />
              </Element>
              <Element is={Parent} canvas id='random-id-108'  style={{ margin: '0 0 0 4px' }}>
                <Button  classn='inline-flex' class='rounded-full cursor-pointer bg-[#3DA169] w-[46px] h-[46px] flex my-auto' >
                  <Element is={Parent} canvas id='random-id-109'  className='my-auto mx-auto -rotate-45 hover:rotate-45 transition duration-700 ease-in-out'>
                    <ArrowToRight fill='#FFF' />
                  </Element>
                </Button>
              </Element>
            </Element>
          </Element>
        </Element>
        <Element is={Parent} canvas id='random-id-110'  className='columnContainer'>
          <Element is={Parent} canvas id='random-id-111'  className='relative' style={{ borderRadius: '30px' }}>
            <Element is={Parent} canvas id='random-id-112'  className='absolute top-[20px] right-[20px] flex flex-row'>
              <Element is={Parent} canvas id='random-id-113' style={{ display: "inline-block", color: '#FFF', margin: 'auto 10px' }}  className='img-text '>
                <Text text='100K' />
              </Element>
              <Element is={Parent} canvas id='random-id-114' style={{ display: "inline-block", color: '#FFF', maxWidth: '53px', margin: 'auto auto', fontSize: '16px' }}  className='img-text '>
                <Text text='Happy Users' />
              </Element>
            </Element>
            <Element is={Parent} canvas id='random-id-115' style={{ borderRadius: '30px' }} className='banner-img' >
              <ImageC style={{ borderRadius: '30px', width: '100%', height: '100%', maxWidth: '640px', maxHeight: '340px' }} className='object-cover' alt='banner-img' src='https://i.ibb.co/LgKJW0N/2444bed7e4b1ee88dc213c0f979eb18d.jpg' />
            </Element>
            <Element is={Parent} canvas id='random-id-116' >
              <Button  classn='block' class='img-button bg-[#FFFFFF4D] cursor-pointer rounded-[33px]'>
                <Element is={Parent} canvas id='random-id-117' style={{ display: "inline-block", color: '#FFFFFF', cursor: 'pointer' }}  className='button-text cursor-pointer'>
                  <Text text='About our team' />
                </Element>
                <ArrowToRight />
              </Button>
            </Element>
          </Element>
          <Element is={Parent} canvas id='random-id-118'  className='flex flex-row w-[100%]' style={{ justifyContent: 'center', borderRadius: '30px', background: '#DDEDFB', margin: '8px 0 0 0', padding: '24px 5px' }}>
            <Element is={Parent} canvas id='random-id-119'  className='flex flex-col mx-auto my-auto'>
              <Element is={Parent} canvas id='random-id-120' style={{ display: "inline-block", color: '#000000' }}  className='card-header'>
                <Text text='85%' />
              </Element>
              <Element is={Parent} canvas id='random-id-121' style={{ display: "inline-block", color: '#000000' }}  className='card-subheader'>
                <Text text='Lorem Ipsum' />
              </Element>
            </Element>
            <Element is={Parent} canvas id='random-id-122'  className='flex flex-col mx-auto my-auto'>
              <Element is={Parent} canvas id='random-id-123' style={{ display: "inline-block", color: '#000000' }}  className='card-header'>
                <Text text='120K' />
              </Element>
              <Element is={Parent} canvas id='random-id-124' style={{ display: "inline-block", color: '#000000' }}  className='card-subheader'>
                <Text text='Lorem Ipsum' />
              </Element>
            </Element>
            <Element is={Parent} canvas id='random-id-125'  className='flex flex-col mx-auto my-auto'>
              <Element is={Parent} canvas id='random-id-126' style={{ display: "inline-block", color: '#000000' }}  className='card-header'>
                <Text text='5%' />
              </Element>
              <Element is={Parent} canvas id='random-id-127' style={{ display: "inline-block", color: '#000000' }}  className='card-subheader'>
                <Text text='Lorem Ipsum' />
              </Element>
            </Element>
          </Element>
        </Element>
      </Element>
    </Element>
  </Element>,


    <Element canvas is={Parent} id='parentId900' className="parentWrapper" style={{ background: '#FEEEF4' }}>
  <Element canvas is={Parent} id='parentId1000' className="parentWrapper2" row={true} >
    <Element is={Parent} canvas id='random-id-128' className='wrapperContainer'>
        <Element is={Parent} canvas id='random-id-130'  className='columnContainer'>
          <Element is={Parent} canvas id='random-id-131' style={{ display: "inline-block", color: '#FF0560' }}  className='banner-header'>
            <Text text='Understand User Flow' />
          </Element>
          <Element is={Parent} canvas id='random-id-132' style={{ display: "inline-block", color: '#000' }}  className='banner-subheader my-4'>
            <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.' />
          </Element>
          <Element is={Parent} canvas id='random-id-133'  className='button-aria flex flex-row w-full' >
            <Element is={Parent} canvas id='random-id-134' className='rounded-[33px]' >
              <Button  classn='block' class='w-[152px] h-[46px] flex rounded-[33px] cursor-pointer border border-solid border-black bg-transparent"'>
                <Element is={Parent} canvas id='random-id-135'  style={{ display: "inline-block", cursor: 'pointer', color: '#000' }} className='cursor-pointer my-auto mx-auto button-text' >
                  <Text text='Learn more' />
                </Element>
              </Button>
            </Element>

            <Element is={Parent} canvas id='random-id-136'  className='flex-row flex relative' style={{ cursor: 'pointer' }}>
              <Element is={Parent} canvas id='random-id-137'  style={{ margin: '0 6px 0' }} className='rounded-[33px]'>
                <Button  classn='block' class='rounded-[33px] cursor-pointer bg-[#FF0560] w-[152px] h-[46px] flex'>
                  <Element is={Parent} canvas id='random-id-138' style={{ display: "inline-block", color: '#FFF', cursor: 'pointer' }}  className='cursor-pointer my-auto mx-auto button-text'>
                    <Text text='Start for free' />
                  </Element>
                </Button>
              </Element>
              <Element is={Parent} canvas id='random-id-139'  className='my-auto absolute top-6 right-[44px]' style={{ zIndex: 20 }}>
                <HelpArrowBanner fill='#FF0560' />
              </Element>
              <Element is={Parent} canvas id='random-id-140'  style={{ margin: '0 0 0 4px' }}>
                <Button  classn='inline-flex' class='rounded-full cursor-pointer bg-[#343CE8] w-[46px] h-[46px] flex my-auto' >
                  <Element is={Parent} canvas id='random-id-141'  className='my-auto mx-auto -rotate-45 hover:rotate-45 transition duration-700 ease-in-out'>
                    <ArrowToRight fill='#FFF' />
                  </Element>
                </Button>
              </Element>
            </Element>
          </Element>
        </Element>
        <Element is={Parent} canvas id='random-id-142'  className='columnContainer'>
          <Element is={Parent} canvas id='random-id-143'  className='relative' style={{ borderRadius: '30px' }}>
            <Element is={Parent} canvas id='random-id-144'  className='absolute top-[20px] right-[20px] flex flex-row'>
              <Element is={Parent} canvas id='random-id-145' style={{ display: "inline-block", color: '#FFF', margin: 'auto 10px' }}  className='img-text '>
                <Text text='100K' />
              </Element>
              <Element is={Parent} canvas id='random-id-146' style={{ display: "inline-block", color: '#FFF', maxWidth: '53px', margin: 'auto auto', fontSize: '16px' }}  className='img-text '>
                <Text text='Happy Users' />
              </Element>
            </Element>
            <Element is={Parent} canvas id='random-id-147' style={{ borderRadius: '30px' }} className='banner-img' >
              <ImageC style={{ borderRadius: '30px', width: '100%', height: '100%', maxWidth: '640px', maxHeight: '340px' }} className='object-cover' alt='banner-img' src='https://i.ibb.co/fxnRrsM/d10a7af5bee64a87d1fc3734e25366c2.jpg' />
            </Element>
            <Element is={Parent} canvas id='random-id-148' >
              <Button  classn='block' class='img-button bg-[#FFFFFF4D] cursor-pointer rounded-[33px]'>
                <Element is={Parent} canvas id='random-id-149' style={{ display: "inline-block", color: '#000000', cursor: 'pointer' }}  className='button-text cursor-pointer'>
                  <Text text='About our team' />
                </Element>
                <ArrowToRight fill='#000000' />
              </Button>
            </Element>
          </Element>
          <Element is={Parent} canvas id='random-id-150'  className='flex flex-row w-[100%]' style={{ justifyContent: 'center', borderRadius: '30px', background: '#FFFFFF', margin: '8px 0 0 0', padding: '24px 5px' }}>
            <Element is={Parent} canvas id='random-id-151'  className='flex flex-col mx-auto my-auto'>
              <Element is={Parent} canvas id='random-id-152' style={{ display: "inline-block", color: '#000000' }}  className='card-header'>
                <Text text='85%' />
              </Element>
              <Element is={Parent} canvas id='random-id-153' style={{ display: "inline-block", color: '#000000' }}  className='card-subheader'>
                <Text text='Lorem Ipsum' />
              </Element>
            </Element>
            <Element is={Parent} canvas id='random-id-154'  className='flex flex-col mx-auto my-auto'>
              <Element is={Parent} canvas id='random-id-155' style={{ display: "inline-block", color: '#000000' }}  className='card-header'>
                <Text text='120K' />
              </Element>
              <Element is={Parent} canvas id='random-id-156' style={{ display: "inline-block", color: '#000000' }}  className='card-subheader'>
                <Text text='Lorem Ipsum' />
              </Element>
            </Element>
            <Element is={Parent} canvas id='random-id-157'  className='flex flex-col mx-auto my-auto'>
              <Element is={Parent} canvas id='random-id-158' style={{ display: "inline-block", color: '#000000' }}  className='card-header'>
                <Text text='5%' />
              </Element>
              <Element is={Parent} canvas id='random-id-159' style={{ display: "inline-block", color: '#000000' }}  className='card-subheader'>
                <Text text='Lorem Ipsum' />
              </Element>
            </Element>
          </Element>
        </Element>
      </Element>
    </Element>
  </Element>,


    <Element canvas is={Parent} id='parentId1100' className="parentWrapper" style={{ background: '#1F062C' }}>
  <Element canvas is={Parent} id='parentId1200' className="parentWrapper2" row={true} >
    <Element is={Parent} canvas id='random-id-160' className='wrapperContainer' >
        <Element is={Parent} canvas id='random-id-162'  className='columnContainer'>
          <Element is={Parent} canvas id='random-id-163' style={{ display: "inline-block", color: '#fff' }}  className='banner-header'>
            <Text text='Understand User Flow' />
          </Element>
          <Element is={Parent} canvas id='random-id-164' style={{ display: "inline-block", color: '#fff' }}  className='banner-subheader my-4'>
            <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.' />
          </Element>
          <Element is={Parent} canvas id='random-id-165'  className='button-aria flex flex-row w-full' >
            <Element is={Parent} canvas id='random-id-166' className='rounded-[33px]' >
              <Button  classn='block' class='w-[152px] h-[46px] flex rounded-[33px] cursor-pointer border border-solid border-white bg-transparent"'>
                <Element is={Parent} canvas id='random-id-167'  style={{ display: "inline-block", cursor: 'pointer', color: '#fff' }} className='cursor-pointer my-auto mx-auto button-text' >
                  <Text text='Learn more' />
                </Element>
              </Button>
            </Element>

            <Element is={Parent} canvas id='random-id-168'  className='flex-row flex relative' style={{ cursor: 'pointer' }}>
              <Element is={Parent} canvas id='random-id-169'  style={{ margin: '0 6px 0' }} className='rounded-[33px]'>
                <Button  classn='block' class='rounded-[33px] cursor-pointer bg-[#950B67] w-[152px] h-[46px] flex'>
                  <Element is={Parent} canvas id='random-id-170' style={{ display: "inline-block", color: '#FFF', cursor: 'pointer' }}  className='cursor-pointer my-auto mx-auto button-text'>
                    <Text text='Start for free' />
                  </Element>
                </Button>
              </Element>
              <Element is={Parent} canvas id='random-id-171'  className='my-auto absolute top-6 right-[44px]' style={{ zIndex: 20 }}>
                <HelpArrowBanner fill='#950B67' />
              </Element>
              <Element is={Parent} canvas id='random-id-172'  style={{ margin: '0 0 0 4px' }}>
                <Button  classn='inline-flex' class='rounded-full cursor-pointer bg-[#950B67] w-[46px] h-[46px] flex my-auto' >
                  <Element is={Parent} canvas id='random-id-173'  className='my-auto mx-auto -rotate-45 hover:rotate-45 transition duration-700 ease-in-out'>
                    <ArrowToRight fill='#FFF' />
                  </Element>
                </Button>
              </Element>
            </Element>
          </Element>
        </Element>
        <Element is={Parent} canvas id='random-id-174'  className='columnContainer' >
          <Element is={Parent} canvas id='random-id-175'  className='relative' style={{ borderRadius: '30px' }}>
            <Element is={Parent} canvas id='random-id-176'  className='absolute top-[20px] right-[20px] flex flex-row'>
              <Element is={Parent} canvas id='random-id-177' style={{ display: "inline-block", color: '#FFF', margin: 'auto 10px' }}  className='img-text '>
                <Text text='100K' />
              </Element>
              <Element is={Parent} canvas id='random-id-178' style={{ display: "inline-block", color: '#FFF', maxWidth: '53px', margin: 'auto auto', fontSize: '16px' }}  className='img-text '>
                <Text text='Happy Users' />
              </Element>
            </Element>
            <Element is={Parent} canvas id='random-id-179' style={{ borderRadius: '30px' }} className='banner-img' >
              <ImageC style={{ borderRadius: '30px', width: '100%', height: '100%', maxWidth: '640px', maxHeight: '340px' }} className='object-cover' alt='banner-img' src='https://i.ibb.co/16nKpqD/ca4c3e25bb0a7a02ffe96a55aff07947.jpg' />
            </Element>
            <Element is={Parent} canvas id='random-id-180' >
              <Button  classn='block' class='img-button bg-[#FFFFFFCC] cursor-pointer rounded-[33px]'>
                <Element is={Parent} canvas id='random-id-181' style={{ display: "inline-block", color: '#000000', cursor: 'pointer' }}  className='button-text cursor-pointer'>
                  <Text text='About our team' />
                </Element>
                <ArrowToRight fill='#000000' />
              </Button>
            </Element>
          </Element>
          <Element is={Parent} canvas id='random-id-182'  className='flex flex-row w-[100%]' style={{ justifyContent: 'center', borderRadius: '30px', background: '#FFFFFF33', margin: '8px 0 0 0', padding: '24px 5px' }}>
            <Element is={Parent} canvas id='random-id-183'  className='flex flex-col mx-auto my-auto'>
              <Element is={Parent} canvas id='random-id-184' style={{ display: "inline-block", color: '#fff' }}  className='card-header'>
                <Text text='85%' />
              </Element>
              <Element is={Parent} canvas id='random-id-185' style={{ display: "inline-block", color: '#fff' }}  className='card-subheader'>
                <Text text='Lorem Ipsum' />
              </Element>
            </Element>
            <Element is={Parent} canvas id='random-id-186'  className='flex flex-col mx-auto my-auto'>
              <Element is={Parent} canvas id='random-id-187' style={{ display: "inline-block", color: '#fff' }}  className='card-header'>
                <Text text='120K' />
              </Element>
              <Element is={Parent} canvas id='random-id-188' style={{ display: "inline-block", color: '#fff' }}  className='card-subheader'>
                <Text text='Lorem Ipsum' />
              </Element>
            </Element>
            <Element is={Parent} canvas id='random-id-189'  className='flex flex-col mx-auto my-auto'>
              <Element is={Parent} canvas id='random-id-190' style={{ display: "inline-block", color: '#fff' }}  className='card-header'>
                <Text text='5%' />
              </Element>
              <Element is={Parent} canvas id='random-id-191' style={{ display: "inline-block", color: '#fff' }}  className='card-subheader'>
                <Text text='Lorem Ipsum' />
              </Element>
            </Element>
          </Element>
        </Element>
      </Element>
    </Element>
  </Element>,


    <Element canvas is={Parent} id='parentId1300' className="parentWrapper"style={{ background: '#FFF' }} >
  <Element canvas is={Parent} id='parentId1400' className="parentWrapper2" row={true} >
    <Element is={Parent} canvas id='random-id-192' className='wrapperContainer' >
        <Element is={Parent} canvas id='random-id-194'  className='columnContainer'>
          <Element is={Parent} canvas id='random-id-195' style={{ display: "inline-block", color: '#000' }}  className='banner-header'>
            <Text text='Understand User Flow' />
          </Element>
          <Element is={Parent} canvas id='random-id-196' style={{ display: "inline-block", color: '#000' }}  className='banner-subheader my-4'>
            <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.' />
          </Element>
          <Element is={Parent} canvas id='random-id-197'  className='button-aria flex flex-row w-full' >
            <Element is={Parent} canvas id='random-id-198' className='rounded-[33px]' >
              <Button  classn='block' class='w-[152px] h-[46px] flex rounded-[33px] cursor-pointer border border-solid border-black bg-transparent"'>
                <Element is={Parent} canvas id='random-id-199'  style={{ display: "inline-block", cursor: 'pointer', color: '#000' }} className='cursor-pointer my-auto mx-auto button-text' >
                  <Text text='Learn more' />
                </Element>
              </Button>
            </Element>

            <Element is={Parent} canvas id='random-id-200'  className='flex-row flex relative' style={{ cursor: 'pointer' }}>
              <Element is={Parent} canvas id='random-id-201'  style={{ margin: '0 6px 0' }} className='rounded-[33px]'>
                <Button  classn='block' class='rounded-[33px] cursor-pointer bg-[#FE490B] w-[152px] h-[46px] flex'>
                  <Element is={Parent} canvas id='random-id-202' style={{ display: "inline-block", color: '#FFF', cursor: 'pointer' }}  className='cursor-pointer my-auto mx-auto button-text'>
                    <Text text='Start for free' />
                  </Element>
                </Button>
              </Element>
              <Element is={Parent} canvas id='random-id-203'  className='my-auto absolute top-6 right-[44px]' style={{ zIndex: 20 }}>
                <HelpArrowBanner fill='#FE490B' />
              </Element>
              <Element is={Parent} canvas id='random-id-204'  style={{ margin: '0 0 0 4px' }}>
                <Button  classn='inline-flex' class='rounded-full cursor-pointer bg-[#FE490B] w-[46px] h-[46px] flex my-auto' >
                  <Element is={Parent} canvas id='random-id-205'  className='my-auto mx-auto -rotate-45 hover:rotate-45 transition duration-700 ease-in-out'>
                    <ArrowToRight fill='#FFF' />
                  </Element>
                </Button>
              </Element>
            </Element>
          </Element>
        </Element>
        <Element is={Parent} canvas id='random-id-206'  className='columnContainer'>
          <Element is={Parent} canvas id='random-id-207'  className='relative' style={{ borderRadius: '30px' }}>
            <Element is={Parent} canvas id='random-id-208'  className='absolute top-[20px] right-[20px] flex flex-row'>
              <Element is={Parent} canvas id='random-id-209' style={{ display: "inline-block", color: '#ffffff', margin: 'auto 10px' }}  className='img-text '>
                <Text text='100K' />
              </Element>
              <Element is={Parent} canvas id='random-id-210' style={{ display: "inline-block", color: '#ffffff', maxWidth: '53px', margin: 'auto auto', fontSize: '16px' }}  className='img-text '>
                <Text text='Happy Users' />
              </Element>
            </Element>
            <Element is={Parent} canvas id='random-id-211' style={{ borderRadius: '30px' }} className='banner-img' >
              <ImageC style={{ borderRadius: '30px', width: '100%', height: '100%', maxWidth: '640px', maxHeight: '340px' }} className='object-cover' alt='banner-img' src='https://i.ibb.co/gSm5q41/226acf7e25205a85e2d1c5f7262d9d5b.jpg' />
            </Element>
            <Element is={Parent} canvas id='random-id-212' >
              <Button  classn='block' class='img-button bg-[#00000099] cursor-pointer rounded-[33px]'>
                <Element is={Parent} canvas id='random-id-213' style={{ display: "inline-block", color: '#ffffff', cursor: 'pointer' }}  className='button-text cursor-pointer'>
                  <Text text='About our team' />
                </Element>
                <ArrowToRight fill='#000000' />
              </Button>
            </Element>
          </Element>
          <Element is={Parent} canvas id='random-id-214'  className='flex flex-row w-[100%]' style={{ justifyContent: 'center', borderRadius: '30px', background: '#02BFE833', margin: '8px 0 0 0', padding: '24px 5px' }}>
            <Element is={Parent} canvas id='random-id-215'  className='flex flex-col mx-auto my-auto'>
              <Element is={Parent} canvas id='random-id-216' style={{ display: "inline-block", color: '#000' }}  className='card-header'>
                <Text text='85%' />
              </Element>
              <Element is={Parent} canvas id='random-id-217' style={{ display: "inline-block", color: '#000' }}  className='card-subheader'>
                <Text text='Lorem Ipsum' />
              </Element>
            </Element>
            <Element is={Parent} canvas id='random-id-218'  className='flex flex-col mx-auto my-auto'>
              <Element is={Parent} canvas id='random-id-219' style={{ display: "inline-block", color: '#000' }}  className='card-header'>
                <Text text='120K' />
              </Element>
              <Element is={Parent} canvas id='random-id-220' style={{ display: "inline-block", color: '#000' }}  className='card-subheader'>
                <Text text='Lorem Ipsum' />
              </Element>
            </Element>
            <Element is={Parent} canvas id='random-id-221'  className='flex flex-col mx-auto my-auto'>
              <Element is={Parent} canvas id='random-id-222' style={{ display: "inline-block", color: '#000' }}  className='card-header'>
                <Text text='5%' />
              </Element>
              <Element is={Parent} canvas id='random-id-223' style={{ display: "inline-block", color: '#000' }}  className='card-subheader'>
                <Text text='Lorem Ipsum' />
              </Element>
            </Element>
          </Element>
        </Element>
      </Element>
    </Element>
  </Element>,


    <Element canvas is={Parent} id='parentId1500' className="parentWrapper" style={{ background: '#080B2B' }}>
  <Element canvas is={Parent} id='parentId1600' className="parentWrapper2" row={true} >
    <Element is={Parent} canvas id='random-id-224' className='wrapperContainer' >
        <Element is={Parent} canvas id='random-id-226'  className='columnContainer'>
          <Element is={Parent} canvas id='random-id-227' style={{ display: "inline-block", color: '#fff' }}  className='banner-header'>
            <Text text='Understand User Flow' />
          </Element>
          <Element is={Parent} canvas id='random-id-228' style={{ display: "inline-block", color: '#fff' }}  className='banner-subheader my-4'>
            <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.' />
          </Element>
          <Element is={Parent} canvas id='random-id-229'  className='button-aria flex flex-row w-full' >
            <Element is={Parent} canvas id='random-id-230' className='rounded-[33px]' >
              <Button  classn='block' class='w-[152px] h-[46px] flex rounded-[33px] cursor-pointer border border-solid border-white bg-transparent"'>
                <Element is={Parent} canvas id='random-id-231'  style={{ display: "inline-block", cursor: 'pointer', color: '#fff' }} className='cursor-pointer my-auto mx-auto button-text' >
                  <Text text='Learn more' />
                </Element>
              </Button>
            </Element>

            <Element is={Parent} canvas id='random-id-232'  className='flex-row flex relative' style={{ cursor: 'pointer' }}>
              <Element is={Parent} canvas id='random-id-233'  style={{ margin: '0 6px 0' }} className='rounded-[33px]'>
                <Button  classn='block' class='rounded-[33px] cursor-pointer bg-[#D423A5] w-[152px] h-[46px] flex'>
                  <Element is={Parent} canvas id='random-id-234' style={{ display: "inline-block", color: '#FFF', cursor: 'pointer' }}  className='cursor-pointer my-auto mx-auto button-text'>
                    <Text text='Start for free' />
                  </Element>
                </Button>
              </Element>
              <Element is={Parent} canvas id='random-id-235'  className='my-auto absolute top-6 right-[44px]' style={{ zIndex: 20 }}>
                <HelpArrowBanner fill='#D423A5' />
              </Element>
              <Element is={Parent} canvas id='random-id-236'  style={{ margin: '0 0 0 4px' }}>
                <Button  classn='inline-flex' class='rounded-full cursor-pointer bg-[#D423A5] w-[46px] h-[46px] flex my-auto' >
                  <Element is={Parent} canvas id='random-id-237'  className='my-auto mx-auto -rotate-45 hover:rotate-45 transition duration-700 ease-in-out'>
                    <ArrowToRight fill='#FFF' />
                  </Element>
                </Button>
              </Element>
            </Element>
          </Element>
        </Element>
        <Element is={Parent} canvas id='random-id-238'  className='columnContainer'>
          <Element is={Parent} canvas id='random-id-239'  className='relative' style={{ borderRadius: '30px' }}>
            <Element is={Parent} canvas id='random-id-240'  className='absolute top-[20px] right-[20px] flex flex-row'>
              <Element is={Parent} canvas id='random-id-241' style={{ display: "inline-block", color: '#ffffff', margin: 'auto 10px' }}  className='img-text '>
                <Text text='100K' />
              </Element>
              <Element is={Parent} canvas id='random-id-242' style={{ display: "inline-block", color: '#ffffff', maxWidth: '53px', margin: 'auto auto', fontSize: '16px' }}  className='img-text '>
                <Text text='Happy Users' />
              </Element>
            </Element>
            <Element is={Parent} canvas id='random-id-243' style={{ borderRadius: '30px' }} className='banner-img' >
              <ImageC style={{ borderRadius: '30px', width: '100%', height: '100%', maxWidth: '640px', maxHeight: '340px' }} className='object-cover' alt='banner-img' src='https://i.ibb.co/gSm5q41/226acf7e25205a85e2d1c5f7262d9d5b.jpg' />
            </Element>
            <Element is={Parent} canvas id='random-id-244' >
              <Button  classn='block' class='img-button bg-[#FFFFFF66] cursor-pointer rounded-[33px]'>
                <Element is={Parent} canvas id='random-id-245' style={{ display: "inline-block", color: '#ffffff', cursor: 'pointer' }}  className='button-text cursor-pointer'>
                  <Text text='About our team' />
                </Element>
                <ArrowToRight fill='#fff' />
              </Button>
            </Element>
          </Element>
          <Element is={Parent} canvas id='random-id-246'  className='flex flex-row w-[100%]' style={{ justifyContent: 'center', borderRadius: '30px', background: '#D423A533', margin: '8px 0 0 0', padding: '24px 5px' }}>
            <Element is={Parent} canvas id='random-id-247'  className='flex flex-col mx-auto my-auto'>
              <Element is={Parent} canvas id='random-id-248' style={{ display: "inline-block", color: '#fff' }}  className='card-header'>
                <Text text='85%' />
              </Element>
              <Element is={Parent} canvas id='random-id-249' style={{ display: "inline-block", color: '#fff' }}  className='card-subheader'>
                <Text text='Lorem Ipsum' />
              </Element>
            </Element>
            <Element is={Parent} canvas id='random-id-250'  className='flex flex-col mx-auto my-auto'>
              <Element is={Parent} canvas id='random-id-251' style={{ display: "inline-block", color: '#fff' }}  className='card-header'>
                <Text text='120K' />
              </Element>
              <Element is={Parent} canvas id='random-id-252' style={{ display: "inline-block", color: '#fff' }}  className='card-subheader'>
                <Text text='Lorem Ipsum' />
              </Element>
            </Element>
            <Element is={Parent} canvas id='random-id-253'  className='flex flex-col mx-auto my-auto'>
              <Element is={Parent} canvas id='random-id-254' style={{ display: "inline-block", color: '#fff' }}  className='card-header'>
                <Text text='5%' />
              </Element>
              <Element is={Parent} canvas id='random-id-255' style={{ display: "inline-block", color: '#fff' }}  className='card-subheader'>
                <Text text='Lorem Ipsum' />
              </Element>
            </Element>
          </Element>
        </Element>
      </Element>
    </Element>
  </Element>,


    <Element canvas is={Parent} id='parentId1700' className="parentWrapper" style={{ background: '#EEFAFF' }}>
  <Element canvas is={Parent} id='parentId1800' className="parentWrapper2" row={true} >
    <Element is={Parent} canvas id='random-id-256' className='wrapperContainer' >
        <Element is={Parent} canvas id='random-id-258'  className='columnContainer' style={{ flex: '50%', alignContent: 'center', alignItems: 'center', justifyContent: 'center', margin: '20px 0' }}>
          <Element is={Parent} canvas id='random-id-259' style={{ display: "inline-block", color: '#000' }}  className='banner-header'>
            <Text text='Understand User Flow' />
          </Element>
          <Element is={Parent} canvas id='random-id-260' style={{ display: "inline-block", color: '#000' }}  className='banner-subheader my-4'>
            <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.' />
          </Element>
          <Element is={Parent} canvas id='random-id-261'  className='button-aria flex flex-row w-full' >
            <Element is={Parent} canvas id='random-id-262' className='rounded-[33px]' >
              <Button  classn='block' class='w-[152px] h-[46px] flex rounded-[33px] cursor-pointer border border-solid border-black bg-transparent"'>
                <Element is={Parent} canvas id='random-id-263'  style={{ display: "inline-block", cursor: 'pointer', color: '#000' }} className='cursor-pointer my-auto mx-auto button-text' >
                  <Text text='Learn more' />
                </Element>
              </Button>
            </Element>

            <Element is={Parent} canvas id='random-id-264'  className='flex-row flex relative' style={{ cursor: 'pointer' }}>
              <Element is={Parent} canvas id='random-id-265'  style={{ margin: '0 6px 0' }} className='rounded-[33px]'>
                <Button  classn='block' class='rounded-[33px] cursor-pointer bg-[#02C8B4] w-[152px] h-[46px] flex'>
                  <Element is={Parent} canvas id='random-id-266' style={{ display: "inline-block", color: '#000', cursor: 'pointer' }}  className='cursor-pointer my-auto mx-auto button-text'>
                    <Text text='Start for free' />
                  </Element>
                </Button>
              </Element>
              <Element is={Parent} canvas id='random-id-267'  className='my-auto absolute top-6 right-[44px]' style={{ zIndex: 20 }}>
                <HelpArrowBanner fill='#02C8B4' />
              </Element>
              <Element is={Parent} canvas id='random-id-268'  style={{ margin: '0 0 0 4px' }}>
                <Button  classn='inline-flex' class='rounded-full cursor-pointer bg-[#02C8B4] w-[46px] h-[46px] flex my-auto' >
                  <Element is={Parent} canvas id='random-id-269'  className='my-auto mx-auto -rotate-45 hover:rotate-45 transition duration-700 ease-in-out'>
                    <ArrowToRight fill='#000' />
                  </Element>
                </Button>
              </Element>
            </Element>
          </Element>
        </Element>
        <Element is={Parent} canvas id='random-id-270'  className='columnContainer' >
          <Element is={Parent} canvas id='random-id-271'  className='relative' style={{ borderRadius: '30px' }}>
            <Element is={Parent} canvas id='random-id-272'  className='absolute top-[20px] right-[20px] flex flex-row'>
              <Element is={Parent} canvas id='random-id-273' style={{ display: "inline-block", color: '#ffffff', margin: 'auto 10px' }}  className='img-text '>
                <Text text='100K' />
              </Element>
              <Element is={Parent} canvas id='random-id-274' style={{ display: "inline-block", color: '#ffffff', maxWidth: '53px', margin: 'auto auto', fontSize: '16px' }}  className='img-text '>
                <Text text='Happy Users' />
              </Element>
            </Element>
            <Element is={Parent} canvas id='random-id-275' style={{ borderRadius: '30px' }} className='banner-img' >
              <ImageC style={{ borderRadius: '30px', width: '100%', height: '100%', maxWidth: '640px', maxHeight: '340px' }} className='object-cover' alt='banner-img' src='https://i.ibb.co/c8Fr9gn/f05c65e2de2bd6045a56e4fb3347c759.jpg' />
            </Element>
            <Element is={Parent} canvas id='random-id-276' >
              <Button  classn='block' class='img-button bg-[#FFFFFF4D] cursor-pointer rounded-[33px]'>
                <Element is={Parent} canvas id='random-id-277' style={{ display: "inline-block", color: '#ffffff', cursor: 'pointer' }}  className='button-text cursor-pointer'>
                  <Text text='About our team' />
                </Element>
                <ArrowToRight fill='#fff' />
              </Button>
            </Element>
          </Element>
          <Element is={Parent} canvas id='random-id-278'  className='flex flex-row w-[100%]' style={{ justifyContent: 'center', borderRadius: '30px', background: '#02C8B433', margin: '8px 0 0 0', padding: '24px 5px' }}>
            <Element is={Parent} canvas id='random-id-279'  className='flex flex-col mx-auto my-auto'>
              <Element is={Parent} canvas id='random-id-280' style={{ display: "inline-block", color: '#000' }}  className='card-header'>
                <Text text='85%' />
              </Element>
              <Element is={Parent} canvas id='random-id-281' style={{ display: "inline-block", color: '#000' }}  className='card-subheader'>
                <Text text='Lorem Ipsum' />
              </Element>
            </Element>
            <Element is={Parent} canvas id='random-id-282'  className='flex flex-col mx-auto my-auto'>
              <Element is={Parent} canvas id='random-id-283' style={{ display: "inline-block", color: '#000' }}  className='card-header'>
                <Text text='120K' />
              </Element>
              <Element is={Parent} canvas id='random-id-284' style={{ display: "inline-block", color: '#000' }}  className='card-subheader'>
                <Text text='Lorem Ipsum' />
              </Element>
            </Element>
            <Element is={Parent} canvas id='random-id-285'  className='flex flex-col mx-auto my-auto'>
              <Element is={Parent} canvas id='random-id-286' style={{ display: "inline-block", color: '#000' }}  className='card-header'>
                <Text text='5%' />
              </Element>
              <Element is={Parent} canvas id='random-id-287' style={{ display: "inline-block", color: '#000' }}  className='card-subheader'>
                <Text text='Lorem Ipsum' />
              </Element>
            </Element>
          </Element>
        </Element>
      </Element>
    </Element>
  </Element>,


    <Element canvas is={Parent} id='parentId1900' className="parentWrapper" style={{ background: '#444A50' }}>
  <Element canvas is={Parent} id='parentId1910' className="parentWrapper2" row={true} >
    <Element is={Parent} canvas id='random-id-288' className='wrapperContainer' >
        <Element is={Parent} canvas id='random-id-290'  className='columnContainer'>
          <Element is={Parent} canvas id='random-id-291' style={{ display: "inline-block", color: '#fff' }}  className='banner-header'>
            <Text text='Understand User Flow' />
          </Element>
          <Element is={Parent} canvas id='random-id-292' style={{ display: "inline-block", color: '#fff' }}  className='banner-subheader my-4'>
            <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.' />
          </Element>
          <Element is={Parent} canvas id='random-id-293'  className='button-aria flex flex-row w-full' >
            <Element is={Parent} canvas id='random-id-294' className='rounded-[33px]' >
              <Button  classn='block' class='w-[152px] h-[46px] flex rounded-[33px] cursor-pointer border border-solid border-white bg-transparent"'>
                <Element is={Parent} canvas id='random-id-295'  style={{ display: "inline-block", cursor: 'pointer', color: '#fff' }} className='cursor-pointer my-auto mx-auto button-text' >
                  <Text text='Learn more' />
                </Element>
              </Button>
            </Element>

            <Element is={Parent} canvas id='random-id-296'  className='flex-row flex relative' style={{ cursor: 'pointer' }}>
              <Element is={Parent} canvas id='random-id-297'  style={{ margin: '0 6px 0' }} className='rounded-[33px]'>
                <Button  classn='block' class='rounded-[33px] cursor-pointer bg-[#C7D9E3] w-[152px] h-[46px] flex'>
                  <Element is={Parent} canvas id='random-id-298' style={{ display: "inline-block", color: '#000', cursor: 'pointer' }}  className='cursor-pointer my-auto mx-auto button-text'>
                    <Text text='Start for free' />
                  </Element>
                </Button>
              </Element>
              <Element is={Parent} canvas id='random-id-299'  className='my-auto absolute top-6 right-[44px]' style={{ zIndex: 20 }}>
                <HelpArrowBanner fill='#C7D9E3' />
              </Element>
              <Element is={Parent} canvas id='random-id-300'  style={{ margin: '0 0 0 4px' }}>
                <Button  classn='inline-flex' class='rounded-full cursor-pointer bg-[#C7D9E3] w-[46px] h-[46px] flex my-auto' >
                  <Element is={Parent} canvas id='random-id-301'  className='my-auto mx-auto -rotate-45 hover:rotate-45 transition duration-700 ease-in-out'>
                    <ArrowToRight fill='#000' />
                  </Element>
                </Button>
              </Element>
            </Element>
          </Element>
        </Element>
        <Element is={Parent} canvas id='random-id-302'  className='columnContainer' >
          <Element is={Parent} canvas id='random-id-303'  className='relative' style={{ borderRadius: '30px' }}>
            <Element is={Parent} canvas id='random-id-304'  className='absolute top-[20px] right-[20px] flex flex-row'>
              <Element is={Parent} canvas id='random-id-305' style={{ display: "inline-block", color: '#ffffff', margin: 'auto 10px' }}  className='img-text '>
                <Text text='100K' />
              </Element>
              <Element is={Parent} canvas id='random-id-306' style={{ display: "inline-block", color: '#ffffff', maxWidth: '53px', margin: 'auto auto', fontSize: '16px' }}  className='img-text '>
                <Text text='Happy Users' />
              </Element>
            </Element>
            <Element is={Parent} canvas id='random-id-307' style={{ borderRadius: '30px' }} className='banner-img' >
              <ImageC style={{ borderRadius: '30px', width: '100%', height: '100%', maxWidth: '640px', maxHeight: '340px' }} className='object-cover' alt='banner-img' src='https://i.ibb.co/k6MF2fM/ba2f88a9c55bbd8e8e6ee26d15b50f73.jpg' />
            </Element>
            <Element is={Parent} canvas id='random-id-308' >
              <Button  classn='block' class='img-button bg-[#FFFFFFCC] cursor-pointer rounded-[33px]'>
                <Element is={Parent} canvas id='random-id-309' style={{ display: "inline-block", color: '#000', cursor: 'pointer' }}  className='button-text cursor-pointer'>
                  <Text text='About our team' />
                </Element>
                <ArrowToRight fill='#fff' />
              </Button>
            </Element>
          </Element>
          <Element is={Parent} canvas id='random-id-310'  className='flex flex-row w-[100%]' style={{ justifyContent: 'center', borderRadius: '30px', background: '#C7D9E366', margin: '8px 0 0 0', padding: '24px 5px' }}>
            <Element is={Parent} canvas id='random-id-311'  className='flex flex-col mx-auto my-auto'>
              <Element is={Parent} canvas id='random-id-312' style={{ display: "inline-block", color: '#fff' }}  className='card-header'>
                <Text text='85%' />
              </Element>
              <Element is={Parent} canvas id='random-id-313' style={{ display: "inline-block", color: '#fff' }}  className='card-subheader'>
                <Text text='Lorem Ipsum' />
              </Element>
            </Element>
            <Element is={Parent} canvas id='random-id-314'  className='flex flex-col mx-auto my-auto'>
              <Element is={Parent} canvas id='random-id-315' style={{ display: "inline-block", color: '#fff' }}  className='card-header'>
                <Text text='120K' />
              </Element>
              <Element is={Parent} canvas id='random-id-316' style={{ display: "inline-block", color: '#fff' }}  className='card-subheader'>
                <Text text='Lorem Ipsum' />
              </Element>
            </Element>
            <Element is={Parent} canvas id='random-id-317'  className='flex flex-col mx-auto my-auto'>
              <Element is={Parent} canvas id='random-id-318' style={{ display: "inline-block", color: '#fff' }}  className='card-header'>
                <Text text='5%' />
              </Element>
              <Element is={Parent} canvas id='random-id-319' style={{ display: "inline-block", color: '#fff' }}  className='card-subheader'>
                <Text text='Lorem Ipsum' />
              </Element>
            </Element>
          </Element>
        </Element>
      </Element>
    </Element>
  </Element>,


  ]

  
  const [sel, setSel] = useState(selected)

  function makeOdd(number) {
    setProp((prop) => {
      setSel(100);
    }, 1000);

    setTimeout(() => {
      setProp((prop) => {
        setSel(number)
      }, 1000);
    }, 100);


    
}

useEffect(() => {
  if(selected || selected >= 0) {
    makeOdd(selected);
  }
},[selected])


  return ( <div style={{ padding: enabled? "10px" : "0px", display:"flex", justifyContent:"center", alignItems:"center", width:'100%', height:'100%'}} ref={connect}>
  {sel == 100 ? (
          <div style={{height:"85vh", display:"flex", justifyContent:"center", alignItems:"center"}}>
          <BounceLoader
                  size={100}
                  color={"white"}
                  loading={true}
                />
          </div>
         ) : (
          styles[sel]
         )} 
</div>
  );
};

Banner_3.craft = {
  displayName: "Banner 3",
  props: {
    selected: 1, // here is the default value of the selected style
    isBlock: true
  },
  rules: {
    canDrag: () => true,
    canDrop: () => true,
  },
  related: {
    toolbar: NewBannerSettings,
  },
};