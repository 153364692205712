/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import { Element, useEditor, useNode } from '@craftjs/core';
import React, { useEffect, useState } from 'react';
import { NewReviewSettings } from './newReviewSetting'
import { Rating } from '@mui/material';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import { Text } from '../../basic/Text';
import { ImageC } from '../../basic/Image';
import { Parent } from '../../Parent/index.js';
import { Wrapper } from '../../wrapper/index.js';
import { BounceLoader } from 'react-spinners';

export const Review_1 = ({ selected }) => {

  const { 
    actions: { setProp },
      connectors: {
        connect
      } 
  } = useNode();

  
  const {
    enabled,
  } = useEditor((state, query) => ({
    enabled: state.options.enabled,
 
  }));

    const styles = [
        <Element canvas is={Parent} id='parentId100' className="w-[100%] h-auto" >
      <Element canvas is={Parent} id='parentId200' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-400' is='parent' className='w-[100%] h-full bg-[#FFFDFB]' >
        <Wrapper canvas id='random-id-401' is='container' className='container h-full mx-auto py-10' style={{display:'flex', flexDirection:'column'}}>
        <Wrapper canvas id='random-id-402' is='div' className='text-[#100103] text-[46px] py-[25px] mx-auto' style={{fontWeight:'700', fontFamily:'Rubik'}}>
          <Text text='Review'/>
        </Wrapper>
        <Wrapper canvas id='random-id-403' is='wrapper' className='w-[100%] h-full flex flex-row flex-wrap justify-center my-auto' >
            <Wrapper canvas id='random-id-404' is='div' className='mx-auto my-[15px] w-[30%] min-w-[290px] min-h-[361px] h-full flex flex-col relative' style={{border:'1px solid #DBD9D9'}}>
                <Wrapper canvas id='random-id-405' is='div' className='absolute right-[-14px] top-[-25px] w-[40px] h-[40px]  '>
                <ImageC src='https://i.ibb.co/kSvqCRj/iconmonstr-quote-right-filled-240.png' style={{width:'100%', height:'100%'}}/>                
                </Wrapper>
<Wrapper canvas style={{ display: "inline-block", fontSize:'30px', fontWeight:'700', fontFamily:'Rubik', color:'#B20312' }} className='text-center w-full mt-[20px]' id="random-id-1" is="div">
<Text text="Great service" />
</Wrapper>
                {/* <div className='w-[200px] mx-auto'>
                <Rating sx={{fontSize:'30px'}}
                name="text-feedback"
                defaultValue={5}
                  />
                </div> */}
                    <Wrapper canvas style={{ display: "inline-block", fontSize:'16px', fontWeight:'400', fontFamily:'Rubik', lineHeight:'25.6px', color:'#100103', padding:'10px 20px 0px 20px', textAlign:'center' }} className='flex align-items-center h-full justify-items-center' id="random-id-2" is="div">
                    <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat." />
                    </Wrapper> 
                <Wrapper canvas id='random-id-406' is='div' className='my-auto flex flex-col' style={{margin:'10px', borderTop:'1px solid #DBD9D9', textAlign:'center'}}>
<Wrapper canvas style={{ display: "inline-block", fontSize:'24px', fontWeight:'700', fontFamily:'Rubik', color:'#100103' }} className='my-2 pt-2' id="random-id-3" is="div">
<Text text="Mark Twain" />
</Wrapper>
<Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'300', fontFamily:'Rubik', color:'#100103'}} className='my-2 pb-2' id="random-id-4" is="div">
<Text text="CEO of Company" />
</Wrapper>
                </Wrapper>
            </Wrapper>

            <Wrapper canvas id='random-id-407' is='div' className='mx-auto my-[15px] w-[30%] min-w-[290px] min-h-[361px] h-full flex flex-col relative ' style={{border:'1px solid #DBD9D9'}}>
                <Wrapper canvas id='random-id-408' is='div' className='absolute right-[-14px] top-[-25px] w-[40px] h-[40px] '>
                <ImageC src='https://i.ibb.co/kSvqCRj/iconmonstr-quote-right-filled-240.png' style={{width:'100%', height:'100%'}}/>
                </Wrapper>
<Wrapper canvas style={{ display: "inline-block", fontSize:'30px', fontWeight:'700', fontFamily:'Rubik', color:'#B20312'}} className='w-full text-center mt-[20px]' id="random-id-5" is="div">
<Text text="Great service" />
</Wrapper>
                {/* <div className='w-[200px] mx-auto'>
                <Rating sx={{fontSize:'30px'}}
                name="text-feedback"
                defaultValue={5}
                  />
                </div> */}
<Wrapper canvas style={{ display: "inline-block", fontSize:'16px', fontWeight:'400', fontFamily:'Rubik', lineHeight:'25.6px', color:'#100103', padding:'10px 20px 0px 20px', textAlign:'center'}} className='flex align-items-center h-full justify-items-center '  id="random-id-6" is="div">
<Text text="    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat." />
</Wrapper> 
                <Wrapper canvas id='random-id-409' is='div' className='my-auto flex flex-col' style={{margin:'10px', borderTop:'1px solid #DBD9D9', textAlign:'center'}}>
<Wrapper canvas style={{ display: "inline-block", fontSize:'24px', fontWeight:'700', fontFamily:'Rubik', color:'#100103'}} className='my-2 pt-2' id="random-id-7" is="div">
<Text text="Jane Austen" />
</Wrapper>                        
<Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'300', fontFamily:'Rubik', color:'#100103'}} className='my-2 pb-2' id="random-id-8" is="div">
<Text text="Developement Team Lead" />
</Wrapper>
                </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-410' is='div' className='mx-auto my-[15px] w-[30%] min-w-[290px] min-h-[361px] h-full flex flex-col relative ' style={{border:'1px solid #DBD9D9'}}>
                <Wrapper canvas id='random-id-411' is='div' className='absolute right-[-14px] top-[-25px] w-[40px] h-[40px] '>
                <ImageC src='https://i.ibb.co/kSvqCRj/iconmonstr-quote-right-filled-240.png' style={{width:'100%', height:'100%'}}/>
                </Wrapper>
<Wrapper canvas style={{ display: "inline-block", fontSize:'30px', fontWeight:'700', fontFamily:'Rubik', color:'#B20312'}} className='w-full text-center mt-[20px] ' id="random-id-9" is="div">
<Text text="Great service" />
</Wrapper>
                {/* <div className='w-[200px] mx-auto '>
                <Rating sx={{fontSize:'30px'}}
                name="text-feedback"
                defaultValue={5}
                  />
                </div>                 */}
<Wrapper canvas style={{ display: "inline-block", fontSize:'16px', fontWeight:'400', fontFamily:'Rubik', lineHeight:'25.6px', color:'#100103', padding:'10px 20px 0px 20px', textAlign:'center' }} className='flex align-items-center h-full justify-items-center  ' id="random-id-10" is="div">
<Text text="    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat." />
</Wrapper> 
                <Wrapper canvas id='random-id-412' is='div' className='my-auto flex flex-col' style={{margin:'10px', borderTop:'1px solid #DBD9D9', textAlign:'center'}}>
<Wrapper canvas style={{ display: "inline-block", fontSize:'24px', fontWeight:'700', fontFamily:'Rubik', color:'#100103' }} className='my-2 pt-2' id="random-id-11" is="div">
<Text text="Mark Twain" />
</Wrapper>                        
<Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'300', fontFamily:'Rubik', color:'#100103' }} className='my-2 pb-2' id="random-id-12" is="div">
<Text text="Teach Lead" />
</Wrapper>
                </Wrapper>
            </Wrapper>
            </Wrapper>
        </Wrapper>
      </Wrapper></Element>
      </Element>,


        <Element canvas is={Parent} id='parentId300' className="w-[100%] h-auto" >
      <Element canvas is={Parent} id='parentId400' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-413' is='parent' className='w-[100%] h-full bg-[#121826]' >
        <Wrapper canvas id='random-id-414' is='container' className='container h-full mx-auto py-10' style={{display:'flex', flexDirection:'column'}}>
        <Wrapper canvas id='random-id-415' is='wrapper' className='IncreaseConversion2 text-[46px] py-[25px] mx-auto' style={{fontWeight:'700', fontFamily:'Rubik'}}>

<Wrapper canvas style={{ display: "inline-block" }} id="random-id-13" is="div">
<Text text="Review" />
</Wrapper>
        </Wrapper>
        <Wrapper canvas id='random-id-416' is='div' className='w-[100%] h-full flex flex-row flex-wrap justify-center my-auto' >
            <Wrapper canvas id='random-id-417' is='div' className='mx-auto my-[15px] w-[30%] min-w-[290px] min-h-[361px] h-full flex flex-col relative' style={{border: '1px solid #FFFFFF1A', boxShadow: '0px 0px 4px 0px #FFFFFF1A'}}>
                <Wrapper canvas id='random-id-418' is='div' className='absolute right-[-14px] top-[-25px] w-[40px] h-[40px]  '>
                      <ImageC src='https://i.ibb.co/59Fv4zr/iconmonstr-quote-right-filled-240-8.png' style={{width:'100%', height:'100%'}}/>
                </Wrapper>
<Wrapper canvas style={{ display: "inline-block", fontSize:'30px', fontWeight:'700', fontFamily:'Rubik', color:'#4BDBA2' }} className='text-center w-full mt-[20px]' id="random-id-14" is="div">
<Text text="Great service" />
</Wrapper>
                {/* <div className='w-[200px] mx-auto'>
                <Rating sx={{fontSize:'30px'}}
                name="text-feedback"
                defaultValue={5}
                  />
                </div> */}
<Wrapper canvas style={{ display: "inline-block", fontSize:'16px', fontWeight:'400', fontFamily:'Rubik', lineHeight:'25.6px', color:'#FFFFFF', padding:'10px 20px 0px 20px', textAlign:'center'}} className='flex align-items-center h-full justify-items-center' id="random-id-15" is="div">
<Text text="    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat." />
</Wrapper> 
                <Wrapper canvas id='random-id-419' is='div' className='my-auto flex flex-col' style={{margin:'10px', borderTop:'1px solid #4BDBA233', textAlign:'center'}}>

<Wrapper canvas style={{ display: "inline-block", fontSize:'24px', fontWeight:'700', fontFamily:'Rubik', color:'#FFFFFF' }} className='my-2 pt-2' id="random-id-16" is="div">
<Text text="Mark Twain" />
</Wrapper>

<Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'300', fontFamily:'Rubik', color:'#FFFFFF' }} className='my-2 pb-2' id="random-id-17" is="div">
<Text text="CEO of Company" />
</Wrapper>
                </Wrapper>
            </Wrapper>

            <Wrapper canvas id='random-id-420' is='div' className='mx-auto my-[15px] w-[30%] min-w-[290px] min-h-[361px] h-full flex flex-col relative ' style={{border: '1px solid #FFFFFF1A',  boxShadow: '0px 0px 4px 0px #FFFFFF1A'}}>
                <Wrapper canvas id='random-id-421' is='div' className='absolute right-[-14px] top-[-25px] w-[40px] h-[40px] '>
                      <ImageC src='https://i.ibb.co/59Fv4zr/iconmonstr-quote-right-filled-240-8.png' style={{width:'100%', height:'100%'}}/>
                </Wrapper>
<Wrapper canvas style={{ display: "inline-block", fontSize:'30px', fontWeight:'700', fontFamily:'Rubik', color:'#4BDBA2'}} className='w-full text-center mt-[20px]' id="random-id-18" is="div">
<Text text="Great service" />
</Wrapper>
                {/* <div className='w-[200px] mx-auto'>
                <Rating sx={{fontSize:'30px'}}
                name="text-feedback"
                defaultValue={5}
                  />
                </div> */}
                
<Wrapper canvas style={{ display: "inline-block", fontSize:'16px', fontWeight:'400', fontFamily:'Rubik', lineHeight:'25.6px', color:'#FFFFFF', padding:'10px 20px 0px 20px', textAlign:'center'}} className='flex align-items-center h-full justify-items-center ' id="random-id-19" is="div">
<Text text="    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat." />
</Wrapper> 
                <Wrapper canvas id='random-id-422' is='div' className='my-auto flex flex-col' style={{margin:'10px', borderTop:'1px solid #4BDBA233', textAlign:'center'}}>

<Wrapper canvas style={{ display: "inline-block" , fontSize:'24px', fontWeight:'700', fontFamily:'Rubik', color:'#FFFFFF'}} className='my-2 pt-2' id="random-id-20" is="div">
<Text text="Jane Austen" />
</Wrapper>
                        
<Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'300', fontFamily:'Rubik', color:'#FFFFFF'}} className='my-2 pb-2' id="random-id-21" is="div">
<Text text="Developement Team Lead" />  
</Wrapper>
                </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-423' is='div' className='mx-auto my-[15px] w-[30%] min-w-[290px] min-h-[361px] h-full flex flex-col relative ' style={{border: '1px solid #FFFFFF1A', boxShadow: '0px 0px 4px 0px #FFFFFF1A'}}>
                <Wrapper canvas id='random-id-424' is='div' className='absolute right-[-14px] top-[-25px] w-[40px] h-[40px] '>
                      <ImageC src='https://i.ibb.co/59Fv4zr/iconmonstr-quote-right-filled-240-8.png' style={{width:'100%', height:'100%'}}/>
                </Wrapper>

<Wrapper canvas style={{ display: "inline-block", fontSize:'30px', fontWeight:'700', fontFamily:'Rubik', color:'#4BDBA2'}} className='w-full text-center mt-[20px] ' id="random-id-22" is="div">
<Text text="Great service" />
</Wrapper>
                {/* <div className='w-[200px] mx-auto '>
                <Rating sx={{fontSize:'30px'}}
                name="text-feedback"
                defaultValue={5}
                  />
                </div> */}
                
<Wrapper canvas style={{ display: "inline-block", fontSize:'16px', fontWeight:'400', fontFamily:'Rubik', lineHeight:'25.6px', color:'#FFFFFF', padding:'10px 20px 0px 20px', textAlign:'center' }} className='flex align-items-center h-full justify-items-center  '  id="random-id-23" is="div">
<Text text="    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat." />
</Wrapper> 
                <Wrapper canvas id='random-id-425' is='div' className='my-auto flex flex-col' style={{margin:'10px', borderTop:'1px solid #4BDBA233', textAlign:'center'}}>

<Wrapper canvas style={{ display: "inline-block", fontSize:'24px', fontWeight:'700', fontFamily:'Rubik', color:'#FFFFFF'}} className='my-2 pt-2' id="random-id-24" is="div">
<Text text="Mark Twain" />
</Wrapper>
                        
<Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'300', fontFamily:'Rubik', color:'#FFFFFF' }} className='my-2 pb-2' id="random-id-25" is="div">
<Text text="Teach Lead" />
</Wrapper>
                </Wrapper>
            </Wrapper>
            </Wrapper>
        </Wrapper>
      </Wrapper></Element>
      </Element>,
      
      <Element canvas is={Parent} id='parentId500' className="w-[100%] h-auto" >
      <Element canvas is={Parent} id='parentId600' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-426' is='parent' className='w-[100%] h-full bg-[#213156]' >
      <Wrapper canvas id='random-id-427' is='container' className='container h-full mx-auto py-10' style={{display:'flex', flexDirection:'column'}}>
      <Wrapper canvas id='random-id-428' is='div' className='text-[#EA6EF4] text-[46px] py-[25px] mx-auto' style={{fontWeight:'700', fontFamily:'Times New Roman'}}>
        <Text text='Review'/>
      </Wrapper>
      <Wrapper canvas id='random-id-429' is='wrapper' className='w-[100%] h-full flex flex-row flex-wrap justify-center my-auto' >
          <Wrapper canvas id='random-id-430' is='div' className='mx-auto my-[15px] w-[30%] min-w-[290px] min-h-[361px] h-full flex flex-col relative' style={{border: '1px solid #FFFFFF1A', boxShadow: '0px 0px 4px 0px #FFFFFF1A'}}>
              <Wrapper canvas id='random-id-431' is='div' className='absolute right-[-14px] top-[-25px] w-[40px] h-[40px]  '>
              <ImageC src='https://i.ibb.co/6J9Jt74/iconmonstr-quote-right-filled-240-2.png' sx={{width:'100%', height:'100%'}}/> 
              </Wrapper>

<Wrapper canvas style={{ display: "inline-block", fontSize:'30px', fontWeight:'700', fontFamily:'Open Sans', color:'#EA6EF4'}}className='text-center w-full mt-[20px]' id="random-id-26" is="div">
<Text text="Great service" />
</Wrapper>
              {/* <div className='w-[200px] mx-auto'>
              <Rating sx={{fontSize:'30px'}}
              name="text-feedback"
              defaultValue={5}
              />
              </div> */}
              
<Wrapper canvas style={{ display: "inline-block", fontSize:'16px', fontWeight:'400', fontFamily:'Open Sans', lineHeight:'25.6px', color:'#FFFFFF', padding:'10px 20px 0px 20px', textAlign:'center'}} className='flex align-items-center h-full py-4 justify-items-center' id="random-id-27" is="div">
<Text text="    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat." />
</Wrapper> 
              <Wrapper canvas id='random-id-432' is='div' className='my-auto flex flex-col' style={{margin:'10px', borderTop:'1px solid #4BDBA233', textAlign:'center'}}>

<Wrapper canvas style={{ display: "inline-block", fontSize:'24px', fontWeight:'700', fontFamily:'Rubik', color:'#FFFFFF' }} className='my-2 pt-2' id="random-id-28" is="div">
<Text text="Mark Twain" />
</Wrapper>

<Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'300', fontFamily:'Rubik', color:'#FFFFFF'}} className='my-2 pb-2'  id="random-id-29" is="div">
<Text text="CEO of Company" />
</Wrapper>
              </Wrapper>
          </Wrapper>

          <Wrapper canvas id='random-id-433' is='div' className='mx-auto my-[15px] w-[30%] min-w-[290px] min-h-[361px] h-full flex flex-col relative ' style={{border: '1px solid #FFFFFF1A',  boxShadow: '0px 0px 4px 0px #FFFFFF1A'}}>
              <Wrapper canvas id='random-id-434' is='div' className='absolute right-[-14px] top-[-25px] w-[40px] h-[40px] '>
              <ImageC src='https://i.ibb.co/6J9Jt74/iconmonstr-quote-right-filled-240-2.png' sx={{width:'100%', height:'100%'}}/> 
              </Wrapper>

<Wrapper canvas style={{ display: "inline-block" ,}} className='w-full text-center mt-[20px]' id="random-id-30" is="div">
<Text text="Great service" />
</Wrapper>
              {/* <div className='w-[200px] mx-auto'>
              <Rating sx={{fontSize:'30px'}}
              name="text-feedback"
              defaultValue={5}
              />
              </div> */}
              
<Wrapper canvas style={{ display: "inline-block", fontSize:'16px', fontWeight:'400', fontFamily:'Open Sans', lineHeight:'25.6px', color:'#FFFFFF', padding:'10px 20px 0px 20px', textAlign:'center'}} className='flex align-items-center py-4 h-full justify-items-center'id="random-id-31" is="div">
<Text text="    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat." />
</Wrapper> 
              <Wrapper canvas id='random-id-435' is='div' className='my-auto flex flex-col' style={{margin:'10px', borderTop:'1px solid #4BDBA233', textAlign:'center'}}>
<Wrapper canvas style={{ display: "inline-block", fontSize:'24px', fontWeight:'700', fontFamily:'Rubik', color:'#FFFFFF' }} className='my-2 pt-2' id="random-id-32" is="div">
<Text text="Jane Austen" />
</Wrapper>
                    
<Wrapper canvas style={{ display: "inline-block" , fontSize:'18px', fontWeight:'300', fontFamily:'Rubik', color:'#FFFFFF'}} className='my-2 pb-2' id="random-id-33" is="div">
<Text text="Developement Team Lead" />
</Wrapper>
              </Wrapper>
          </Wrapper>
          <Wrapper canvas id='random-id-436' is='div' className='mx-auto my-[15px] w-[30%] min-w-[290px] min-h-[361px] h-full flex flex-col relative ' style={{border: '1px solid #FFFFFF1A', boxShadow: '0px 0px 4px 0px #FFFFFF1A'}}>
              <Wrapper canvas id='random-id-437' is='div' className='absolute right-[-14px] top-[-25px] w-[40px] h-[40px] '>
              <ImageC src='https://i.ibb.co/6J9Jt74/iconmonstr-quote-right-filled-240-2.png' sx={{width:'100%', height:'100%'}}/> 
              </Wrapper>

<Wrapper canvas style={{ display: "inline-block", fontSize:'30px', fontWeight:'700', fontFamily:'Open Sans', color:'#EA6EF4'}} className='w-full text-center mt-[20px] '  id="random-id-34" is="div">
<Text text="Great service" />
</Wrapper>
              {/* <div className='w-[200px] mx-auto '>
              <Rating sx={{fontSize:'30px'}}
              name="text-feedback"
              defaultValue={5}
              />
              </div> */}
              
<Wrapper canvas style={{ display: "inline-block", fontSize:'16px', fontWeight:'400', fontFamily:'Open Sans', lineHeight:'25.6px', color:'#FFFFFF', padding:'10px 20px 0px 20px', textAlign:'center'}} className='flex align-items-center h-full py-4 justify-items-center  '  id="random-id-35" is="div">
<Text text="    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat." />
</Wrapper> 
              <Wrapper canvas id='random-id-438' is='div' className='my-auto flex flex-col' style={{margin:'10px', borderTop:'1px solid #4BDBA233', textAlign:'center'}}>

<Wrapper canvas style={{ display: "inline-block", fontSize:'24px', fontWeight:'700', fontFamily:'Rubik', color:'#FFFFFF' }} className='my-2 pt-2' id="random-id-36" is="div">
<Text text="Mark Twain" />
</Wrapper>
                    
<Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'300', fontFamily:'Rubik', color:'#FFFFFF'}} className='my-2 pb-2' id="random-id-37" is="div">
<Text text="Teach Lead" />
</Wrapper>
              </Wrapper>
          </Wrapper>
          </Wrapper>
      </Wrapper>
    </Wrapper></Element>
      </Element>,
    
    

    <Element canvas is={Parent} id='parentId700' className="w-[100%] h-auto" >
      <Element canvas is={Parent} id='parentId800' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-439' is='parent' className='w-[100%] h-full bg-[#DFFFF9]' >
    <Wrapper canvas id='random-id-440' is='container' className='container h-full mx-auto py-10' style={{display:'flex', flexDirection:'column'}}>
<Wrapper canvas style={{ display: "inline-block", fontWeight:'100px', fontFamily:'Zing Script Rust SemiBold Demo', fontStyle:'italic' }} className='text-[#31A993] text-[46px] py-[25px] mx-auto' id="random-id-38" is="div">
<Text text="Review" />
</Wrapper>
    <Wrapper canvas id='random-id-441' is='container' className='w-[100%] h-full flex flex-row flex-wrap justify-center my-auto' >
        <Wrapper canvas id='random-id-442' is='div' className='mx-auto my-[15px] w-[30%] min-w-[290px] min-h-[361px] h-full flex flex-col relative' style={{border: '1px solid #31A9931A', borderRadius:'10px', boxShadow: '0px 0px 4px 0px #0825201A'}}>
            <Wrapper canvas id='random-id-443' is='div' className='absolute right-[-14px] top-[-25px] w-[40px] h-[40px]  '>
            <ImageC src='https://i.ibb.co/QPh3GVr/iconmonstr-quote-right-filled-240-7.png' sx={{width:'100%', height:'100%'}}/>
            </Wrapper>
<Wrapper canvas style={{ display: "inline-block", fontSize:'30px', fontWeight:'700', fontFamily:'Montserrat', color:'#31A993'}} className='text-center w-full mt-[20px]' id="random-id-39" is="div">
<Text text="Great service" />
</Wrapper>
            {/* <div className='w-[200px] mx-auto'>
              <Rating sx={{fontSize:'30px'}}
              name="text-feedback"
              defaultValue={5}
              />
              </div> */}
            
<Wrapper canvas style={{ display: "inline-block", fontSize:'16px', fontWeight:'400', fontFamily:'Montserrat', lineHeight:'25.6px', color:'#031815', padding:'10px 20px 0px 20px', textAlign:'center' }} className='flex align-items-center h-full py-4 justify-items-center' id="random-id-40" is="div">
<Text text="    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat." />
</Wrapper> 
            <Wrapper canvas id='random-id-444' is='div' className='my-auto flex flex-col' style={{margin:'10px', borderTop:'1px solid #4BDBA233', textAlign:'center'}}>

<Wrapper canvas style={{ display: "inline-block", fontSize:'24px', fontWeight:'700', fontFamily:'Rubik', color:'#031815' }} className='my-2 pt-2'  id="random-id-41" is="div">
<Text text="Mark Twain" />
</Wrapper>

<Wrapper canvas style={{ display: "inline-block" , fontSize:'18px', fontWeight:'300', fontFamily:'Rubik', color:'#031815'}} className='my-2 pb-2'  id="random-id-42" is="div">
<Text text="CEO of Company" />
</Wrapper>
            </Wrapper>
        </Wrapper>

        <Wrapper canvas id='random-id-445' is='div' className='mx-auto my-[15px] w-[30%] min-w-[290px] min-h-[361px] h-full flex flex-col relative ' style={{border: '1px solid #31A9931A', borderRadius:'10px',  boxShadow: '0px 0px 4px 0px #0825201A'}}>
            <Wrapper canvas id='random-id-446' is='div' className='absolute right-[-14px] top-[-25px] w-[40px] h-[40px] '>
            <ImageC src='https://i.ibb.co/QPh3GVr/iconmonstr-quote-right-filled-240-7.png' sx={{width:'100%', height:'100%'}}/>
            </Wrapper>

<Wrapper canvas style={{ display: "inline-block", fontSize:'30px', fontWeight:'700', fontFamily:'Montserrat', color:'#31A993'}} className='w-full text-center mt-[20px]'  id="random-id-43" is="div">
<Text text="Great service" />
</Wrapper>
            
            {/* <div className='w-[200px] mx-auto'>
              <Rating sx={{fontSize:'30px'}}
              name="text-feedback"
              defaultValue={5}
              />
              </div> */}
            
<Wrapper canvas style={{ display: "inline-block", fontSize:'16px', fontWeight:'400', fontFamily:'Montserrat', lineHeight:'25.6px', color:'#031815', padding:'10px 20px 0px 20px', textAlign:'center' }} className='flex align-items-center py-4 h-full justify-items-center'  id="random-id-44" is="div">
<Text text="    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat." />
</Wrapper> 
            <Wrapper canvas id='random-id-447' is='div' className='my-auto flex flex-col' style={{margin:'10px', borderTop:'1px solid #4BDBA233', textAlign:'center'}}>
<Wrapper canvas style={{ display: "inline-block", fontSize:'24px', fontWeight:'700', fontFamily:'Rubik', color:'#031815' }} className='my-2 pt-2' id="random-id-45" is="div">
<Text text="Jane Austen" />
</Wrapper>                    
<Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'300', fontFamily:'Rubik', color:'#031815' }} className='my-2 pb-2' id="random-id-46" is="div">
<Text text="Developement Team Lead" />
</Wrapper>
            </Wrapper>
        </Wrapper>
        <Wrapper canvas id='random-id-448' is='div' className='mx-auto my-[15px] w-[30%] min-w-[290px] min-h-[361px] h-full flex flex-col relative ' style={{border: '1px solid #31A9931A', borderRadius:'10px', boxShadow: '0px 0px 4px 0px #0825201A'}}>
            <Wrapper canvas id='random-id-449' is='div' className='absolute right-[-14px] top-[-25px] w-[40px] h-[40px] '>
            <ImageC src='https://i.ibb.co/QPh3GVr/iconmonstr-quote-right-filled-240-7.png' sx={{width:'100%', height:'100%'}}/>
            </Wrapper>

<Wrapper canvas style={{ display: "inline-block", fontSize:'30px', fontWeight:'700', fontFamily:'Montserrat', color:'#31A993'}} className='w-full text-center mt-[20px] ' id="random-id-47" is="div">
<Text text="Great service" />
</Wrapper>
            {/* <div className='w-[200px] mx-auto'>
              <Rating sx={{fontSize:'30px'}}
              name="text-feedback"
              defaultValue={5}
              />
              </div> */}
            
<Wrapper canvas style={{ display: "inline-block", fontSize:'16px', fontWeight:'400', fontFamily:'Montserrat', lineHeight:'25.6px', color:'#031815', padding:'10px 20px 0px 20px', textAlign:'center' }} className='flex align-items-center h-full py-4 justify-items-center  ' id="random-id-48" is="div">
<Text text="    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat." />
</Wrapper> 
            <Wrapper canvas id='random-id-450' is='div' className='my-auto flex flex-col' style={{margin:'10px', borderTop:'1px solid #4BDBA233', textAlign:'center'}}>
<Wrapper canvas style={{ display: "inline-block", fontSize:'24px', fontWeight:'700', fontFamily:'Rubik', color:'#031815'}} className='my-2 pt-2' id="random-id-49" is="div">
<Text text="Mark Twain" />
</Wrapper>                    
<Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'300', fontFamily:'Rubik', color:'#031815' }} className='my-2 pb-2' id="random-id-50" is="div">
<Text text="Teach Lead" />
</Wrapper>
            </Wrapper>
        </Wrapper>
        </Wrapper>
    </Wrapper>
  </Wrapper></Element>
      </Element>,

  
        <Element canvas is={Parent} id='parentId900' className="w-[100%] h-auto" >
      <Element canvas is={Parent} id='parentId1000' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-451' is='parent' className='w-[100%] h-full bg-[white]' >
        <Wrapper canvas id='random-id-452' is='container' className='container h-full mx-auto py-10' style={{display:'flex', flexDirection:'column'}}>
<Wrapper canvas style={{ display: "inline-block", fontWeight:'700', fontFamily:'Rubik' }} className='text-[#0078D3] text-[46px] py-[25px] mx-auto' id="random-id-51" is="div">
<Text text="Review" />
</Wrapper>
        <Wrapper canvas id='random-id-453' is='wrapper' className='w-[100%] h-full flex flex-row flex-wrap justify-center my-auto' >
            <Wrapper canvas id='random-id-454' is='div' className='mx-auto my-[15px] w-[30%] min-w-[290px] min-h-[361px] h-full flex flex-col relative' style={{border: '1px solid #DBD9D9', borderRadius:'4px'}}>
                <Wrapper canvas id='random-id-455' is='div' className='absolute right-[-14px] top-[-25px] w-[40px] h-[40px]  '>
                <ImageC src='https://i.ibb.co/DMxCqch/iconmonstr-quote-right-filled-240-6.png' sx={{width:'100%', height:'100%'}}/>
                </Wrapper>
<Wrapper canvas style={{ display: "inline-block", fontSize:'30px', fontWeight:'700', fontFamily:'Inter', color:'#0078D3'}} className='text-center w-full mt-[20px]' id="random-id-52" is="div">
<Text text="Great service" />
</Wrapper>
                {/* <div className='w-[200px] mx-auto'>
                <Rating sx={{fontSize:'30px'}}
                name="text-feedback"
                defaultValue={5}
                  />
                </div> */}
                
<Wrapper canvas style={{ display: "inline-block", fontSize:'16px', fontWeight:'400', fontFamily:'Inter', lineHeight:'25.6px', color:'#000000', padding:'10px 20px 0px 20px', textAlign:'center'}} className='flex align-items-center h-full py-4 justify-items-center' id="random-id-53" is="div">
<Text text="    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat." />
</Wrapper> 
                <Wrapper canvas id='random-id-456' is='div' className='my-auto flex flex-col' style={{margin:'10px', borderTop:'1px solid #DBD9D9', textAlign:'center'}}>
<Wrapper canvas style={{ display: "inline-block", fontSize:'24px', fontWeight:'700', fontFamily:'Inter', color:'#000000'}}className='my-2 pt-2' id="random-id-54" is="div">
<Text text="Mark Twain" />
</Wrapper>
<Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'300', fontFamily:'Inter', color:'#000000'}} className='my-2 pb-2' id="random-id-55" is="div">
<Text text="CEO of Company" />
</Wrapper>
                </Wrapper>
            </Wrapper>

            <Wrapper canvas id='random-id-457' is='div' className='mx-auto my-[15px] w-[30%] min-w-[290px] min-h-[361px] h-full flex flex-col relative ' style={{border: '1px solid #DBD9D9', borderRadius:'4px',}}>
                <Wrapper canvas id='random-id-458' is='div' className='absolute right-[-14px] top-[-25px] w-[40px] h-[40px] '>
                <ImageC src='https://i.ibb.co/DMxCqch/iconmonstr-quote-right-filled-240-6.png' sx={{width:'100%', height:'100%'}}/>
                </Wrapper>
<Wrapper canvas style={{ display: "inline-block", fontSize:'30px', fontWeight:'700', fontFamily:'Inter', color:'#0078D3'}} className='w-full text-center mt-[20px]' id="random-id-56" is="div">
<Text text="Great service" />
</Wrapper>
                {/* <div className='w-[200px] mx-auto'>
                <Rating sx={{fontSize:'30px'}}
                name="text-feedback"
                defaultValue={5}
                  />
                </div> */}
                
<Wrapper canvas style={{ display: "inline-block", fontSize:'16px', fontWeight:'400', fontFamily:'Inter', lineHeight:'25.6px', color:'#000000', padding:'10px 20px 0px 20px', textAlign:'center' }} className='flex align-items-center h-full py-4 justify-items-center '  id="random-id-57" is="div">
<Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat." />
</Wrapper> 
                <Wrapper canvas id='random-id-459' is='div' className='my-auto flex flex-col' style={{margin:'10px', borderTop:'1px solid #DBD9D9', textAlign:'center'}}>

<Wrapper canvas style={{ display: "inline-block", fontSize:'24px', fontWeight:'700', fontFamily:'Inter', color:'#000000'}} className='my-2 pt-2' id="random-id-58" is="div">
<Text text="Jane Austen" />
</Wrapper>
                        
<Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'300', fontFamily:'Inter', color:'#000000' }}  className='my-2 pb-2' id="random-id-59" is="div">
<Text text="Developement Team Lead" />
</Wrapper>
                </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-460' is='div' className='mx-auto my-[15px] w-[30%] min-w-[290px] min-h-[361px] h-full flex flex-col relative ' style={{border: '1px solid #DBD9D9', borderRadius:'4px'}}>
                <Wrapper canvas id='random-id-461' is='div' className='absolute right-[-14px] top-[-25px] w-[40px] h-[40px] '>
                <ImageC src='https://i.ibb.co/DMxCqch/iconmonstr-quote-right-filled-240-6.png' sx={{width:'100%', height:'100%'}}/>
                </Wrapper>
<Wrapper canvas style={{ display: "inline-block", fontSize:'30px', fontWeight:'700', fontFamily:'Inter', color:'#0078D3' }}className='w-full text-center mt-[20px] '  id="random-id-60" is="div">
<Text text="Great service" />
</Wrapper>
                {/* <div className='w-[200px] mx-auto '>
                <Rating sx={{fontSize:'30px'}}
                name="text-feedback"
                defaultValue={5}
                  />
                </div> */}
                
<Wrapper canvas style={{ display: "inline-block", fontSize:'16px', fontWeight:'400', fontFamily:'Inter', lineHeight:'25.6px', color:'#000000', padding:'10px 20px 0px 20px', textAlign:'center'}} className='flex align-items-center h-full py-4 justify-items-center  '  id="random-id-61" is="div">
<Text text="    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat." />
</Wrapper> 
                <Wrapper canvas id='random-id-462' is='div' className='my-auto flex flex-col' style={{margin:'10px', borderTop:'1px solid #DBD9D9', textAlign:'center'}}>
<Wrapper canvas style={{ display: "inline-block", fontSize:'24px', fontWeight:'700', fontFamily:'Inter', color:'#000000' }} className='my-2 pt-2' id="random-id-62" is="div">
<Text text="Mark Twain" />
</Wrapper>                        
<Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'300', fontFamily:'Inter', color:'#000000' }} className='my-2 pb-2' id="random-id-63" is="div">
<Text text="Teach Lead" />
</Wrapper>
                </Wrapper>
            </Wrapper>
            </Wrapper>
        </Wrapper>
      </Wrapper></Element>
      </Element>,
      

      <Element canvas is={Parent} id='parentId1100' className="w-[100%] h-auto" >
      <Element canvas is={Parent} id='parentId1200' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-463' is='parent' className='w-[100%] h-full bg-[#020212]' >
      <Wrapper canvas id='random-id-464' is='container' className='container h-full mx-auto py-10' style={{display:'flex', flexDirection:'column'}}>
<Wrapper canvas style={{ display: "inline-block", fontWeight:'700', fontFamily:'Voces' }} className='text-[#FFFFFF] text-[46px] py-[25px] mx-auto' id="random-id-64" is="div">
<Text text="Review" />
</Wrapper>
      <Wrapper canvas id='random-id-465' is='wrapper' className='w-[100%] h-full flex flex-row flex-wrap justify-center my-auto' >
          <Wrapper canvas id='random-id-466' is='div' className='mx-auto my-[15px] w-[30%] min-w-[290px] min-h-[361px] h-full flex flex-col relative' style={{boxShadow: '0px 0px 10px 0px #FFFFFF33'}}>
              <Wrapper canvas id='random-id-467' is='div' className='absolute right-[-14px] top-[-25px] w-[40px] h-[40px]  '>
              <ImageC src='https://i.ibb.co/Xk7S30V/iconmonstr-quote-right-filled-240-5.png' sx={{width:'100%', height:'100%'}}/>
              </Wrapper>
<Wrapper canvas style={{ display: "inline-block", fontSize:'30px', fontWeight:'700', fontFamily:'Voces', color:'#FFFFFF'}} className='text-center w-full mt-[20px]'  id="random-id-65" is="div">
<Text text="Great service" />
</Wrapper>
              {/* <div className='w-[200px] mx-auto'>
              <Rating sx={{fontSize:'30px'}}
              name="text-feedback"
              defaultValue={5}
              />
              </div> */}
              
<Wrapper canvas style={{ display: "inline-block", fontSize:'16px', fontWeight:'400', fontFamily:'Voces', lineHeight:'25.6px', color:'#FFFFFF', padding:'10px 20px 0px 20px', textAlign:'center' }} className='flex align-items-center h-full py-4 justify-items-center' id="random-id-66" is="div">
<Text text="    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat." />
</Wrapper> 
              <Wrapper canvas id='random-id-468' is='div' className='my-auto flex flex-col' style={{margin:'10px', borderTop:'1px solid #DBD9D966', textAlign:'center'}}>
<Wrapper canvas style={{ display: "inline-block", fontSize:'24px', fontWeight:'700', fontFamily:'Voces', color:'#FFFFFF'}} className='my-2 pt-2' id="random-id-67" is="div">
<Text text="Mark Twain" />
</Wrapper>
<Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'300', fontFamily:'Voces', color:'#FFFFFF' }} className='my-2 pb-2'  id="random-id-68" is="div">
<Text text="CEO of Company" />
</Wrapper>
              </Wrapper>
          </Wrapper>

          <Wrapper canvas id='random-id-469' is='div' className='mx-auto my-[15px] w-[30%] min-w-[290px] min-h-[361px] h-full flex flex-col relative ' style={{boxShadow: '0px 0px 10px 0px #FFFFFF33',}}>
              <Wrapper canvas id='random-id-470' is='div' className='absolute right-[-14px] top-[-25px] w-[40px] h-[40px] '>
              <ImageC src='https://i.ibb.co/Xk7S30V/iconmonstr-quote-right-filled-240-5.png' sx={{width:'100%', height:'100%'}}/>
              </Wrapper>
<Wrapper canvas style={{ display: "inline-block", fontSize:'30px', fontWeight:'700', fontFamily:'Voces', color:'#FFFFFF' }} className='w-full text-center mt-[20px]' id="random-id-69" is="div">
<Text text="Great service" />
</Wrapper>
              {/* <div className='w-[200px] mx-auto'>
              <Rating sx={{fontSize:'30px'}}
              name="text-feedback"
              defaultValue={5}
              
              />
              </div> */}
              
<Wrapper canvas style={{ display: "inline-block", fontSize:'16px', fontWeight:'400', fontFamily:'Voces', lineHeight:'25.6px', color:'#FFFFFF', padding:'10px 20px 0px 20px', textAlign:'center'}} className='flex align-items-center h-full py-4 justify-items-center '  id="random-id-70" is="div">
<Text text="    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat." />
</Wrapper> 
              <Wrapper canvas id='random-id-471' is='div' className='my-auto flex flex-col' style={{margin:'10px', borderTop:'1px solid #DBD9D966', textAlign:'center'}}>

<Wrapper canvas style={{ display: "inline-block", fontSize:'24px', fontWeight:'700', fontFamily:'Voces', color:'#FFFFFF' }} className='my-2 pt-2'  id="random-id-71" is="div">
<Text text="Jane Austen" />
</Wrapper>                    
<Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'300', fontFamily:'Voces', color:'#FFFFFF'}} className='my-2 pb-2' id="random-id-72" is="div">
<Text text="Developement Team Lead" />
</Wrapper>
              </Wrapper>
          </Wrapper>
          <Wrapper canvas id='random-id-472' is='div' className='mx-auto my-[15px] w-[30%] min-w-[290px] min-h-[361px] h-full flex flex-col relative ' style={{boxShadow: '0px 0px 10px 0px #FFFFFF33'}}>
              <Wrapper canvas id='random-id-473' is='div' className='absolute right-[-14px] top-[-25px] w-[40px] h-[40px] '>
              <ImageC src='https://i.ibb.co/Xk7S30V/iconmonstr-quote-right-filled-240-5.png' sx={{width:'100%', height:'100%'}}/>
              </Wrapper>
<Wrapper canvas style={{ display: "inline-block", fontSize:'30px', fontWeight:'700', fontFamily:'Voces', color:'#FFFFFF' }} className='w-full text-center mt-[20px] '  id="random-id-73" is="div">
<Text text="Great service" />
</Wrapper>
              {/* <div className='w-[200px] mx-auto '>
              <Rating sx={{fontSize:'30px'}}
              name="text-feedback"
              defaultValue={5}
              
              />
              </div> */}
              
<Wrapper canvas style={{ display: "inline-block", fontSize:'16px', fontWeight:'400', fontFamily:'Voces', lineHeight:'25.6px', color:'#FFFFFF', padding:'10px 20px 0px 20px', textAlign:'center'}} className='flex align-items-center h-full py-4 justify-items-center  ' id="random-id-74" is="div">
<Text text="    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat." />
</Wrapper> 
              <Wrapper canvas id='random-id-474' is='div' className='my-auto flex flex-col' style={{margin:'10px', borderTop:'1px solid #DBD9D966', textAlign:'center'}}>
<Wrapper canvas style={{ display: "inline-block", fontSize:'24px', fontWeight:'700', fontFamily:'Voces', color:'#FFFFFF' }} className='my-2 pt-2' id="random-id-75" is="div">
<Text text="Mark Twain" />
</Wrapper>
                    
<Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'300', fontFamily:'Voces', color:'#FFFFFF'}} className='my-2 pb-2' id="random-id-76" is="div">
<Text text="Teach Lead" />
</Wrapper>
              </Wrapper>
          </Wrapper>
          </Wrapper>
      </Wrapper>
    </Wrapper></Element>
      </Element>,

      <Element canvas is={Parent} id='parentId1300' className="w-[100%] h-auto" >
      <Element canvas is={Parent} id='parentId1400' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-475' is='parent' className='w-[100%] h-full bg-[#FBFAFF]' >
      <Wrapper canvas id='random-id-476' is='container' className='container h-full mx-auto py-10' style={{display:'flex', flexDirection:'column'}}>

<Wrapper canvas style={{ display: "inline-block", fontWeight:'700', fontFamily:'Roboto' }} className='text-[#6750A4] text-[46px] py-[25px] mx-auto' id="random-id-77" is="div">
<Text text="Review" />
</Wrapper>
      <Wrapper canvas id='random-id-477' is='wrapper' className='w-[100%] h-full flex flex-row flex-wrap justify-center my-auto' >
          <Wrapper canvas id='random-id-478' is='div' className='mx-auto my-[15px] w-[30%] min-w-[290px] min-h-[361px] h-full flex flex-col relative' style={{borderRadius:'20px', background:'#6750A4'}}>
              <Wrapper canvas id='random-id-479' is='div' className='absolute right-[-14px] top-[-25px] w-[40px] h-[40px]  '>
              <ImageC src='https://i.ibb.co/Xk7S30V/iconmonstr-quote-right-filled-240-5.png' sx={{width:'100%', height:'100%'}}/>
              </Wrapper>

<Wrapper canvas style={{ display: "inline-block", fontSize:'30px', fontWeight:'700', fontFamily:'Roboto', color:'#FFFFFF' }} className='text-center w-full mt-[20px]'  id="random-id-78" is="div">
<Text text="Great service" />
</Wrapper>
              {/* <div className='w-[200px] mx-auto'>
              <Rating sx={{fontSize:'30px'}}
              name="text-feedback"
              defaultValue={5}
            
              />
              </div> */}
              
<Wrapper canvas style={{ display: "inline-block", fontSize:'16px', fontWeight:'400', fontFamily:'Roboto', lineHeight:'25.6px', color:'#FFFFFF', padding:'10px 20px 0px 20px', textAlign:'center'}} className='flex align-items-center h-full py-4 justify-items-center'  id="random-id-79" is="div">
<Text text="    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat." />
</Wrapper> 
              <Wrapper canvas id='random-id-480' is='div' className='my-auto flex flex-col' style={{margin:'10px', borderTop:'1px solid #DBD9D966', textAlign:'center'}}>
<Wrapper canvas style={{ display: "inline-block", fontSize:'24px', fontWeight:'700', fontFamily:'Roboto', color:'#FFFFFF'}} className='my-2 pt-2'  id="random-id-80" is="div">
<Text text="Mark Twain" />
</Wrapper>
<Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'300', fontFamily:'Roboto', color:'#FFFFFF' }} className='my-2 pb-2' id="random-id-81" is="div">
<Text text="CEO of Company" />
</Wrapper>
              </Wrapper>
          </Wrapper>

          <Wrapper canvas id='random-id-481' is='div' className='mx-auto my-[15px] w-[30%] min-w-[290px] min-h-[361px] h-full flex flex-col relative ' style={{borderRadius:'20px', background:'#6750A4',}}>
              <Wrapper canvas id='random-id-482' is='div' className='absolute right-[-14px] top-[-25px] w-[40px] h-[40px] '>
              <ImageC src='https://i.ibb.co/Xk7S30V/iconmonstr-quote-right-filled-240-5.png' sx={{width:'100%', height:'100%'}}/>
              </Wrapper>
<Wrapper canvas style={{ display: "inline-block", fontSize:'30px', fontWeight:'700', fontFamily:'Roboto', color:'#FFFFFF'}} className='w-full text-center mt-[20px]'  id="random-id-82" is="div">
<Text text="Great service" />
</Wrapper>
              {/* <div className='w-[200px] mx-auto'>
              <Rating sx={{fontSize:'30px'}}
              name="text-feedback"
              defaultValue={5}
              
              />
              </div> */}
<Wrapper canvas style={{ display: "inline-block", fontSize:'16px', fontWeight:'400', fontFamily:'Roboto', lineHeight:'25.6px', color:'#FFFFFF', padding:'10px 20px 0px 20px', textAlign:'center'}} className='flex align-items-center h-full py-4 justify-items-center '  id="random-id-83" is="div">
<Text text="    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat." />
</Wrapper> 
              <Wrapper canvas id='random-id-483' is='div' className='my-auto flex flex-col' style={{margin:'10px', borderTop:'1px solid #DBD9D966', textAlign:'center'}}>
<Wrapper canvas style={{ display: "inline-block", fontSize:'24px', fontWeight:'700', fontFamily:'Roboto', color:'#FFFFFF'}} className='my-2 pt-2'  id="random-id-84" is="div">
<Text text="Jane Austen" />
</Wrapper>                    
<Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'300', fontFamily:'Roboto', color:'#FFFFFF' }} className='my-2 pb-2' id="random-id-85" is="div">
<Text text="Developement Team Lead" />
</Wrapper>
              </Wrapper>
          </Wrapper>
          <Wrapper canvas id='random-id-484' is='div' className='mx-auto my-[15px] w-[30%] min-w-[290px] min-h-[361px] h-full flex flex-col relative ' style={{borderRadius:'20px', background:'#6750A4'}}>
              <Wrapper canvas id='random-id-485' is='div' className='absolute right-[-14px] top-[-25px] w-[40px] h-[40px] '>
              <ImageC src='https://i.ibb.co/Xk7S30V/iconmonstr-quote-right-filled-240-5.png' sx={{width:'100%', height:'100%'}}/>
              </Wrapper>

<Wrapper canvas style={{ display: "inline-block" , fontSize:'30px', fontWeight:'700', fontFamily:'Roboto', color:'#FFFFFF'}} className='w-full text-center mt-[20px] ' id="random-id-86" is="div">
<Text text="Great service" />
</Wrapper>
              {/* <div className='w-[200px] mx-auto '>
              <Rating sx={{fontSize:'30px'}}
              name="text-feedback"
              defaultValue={5}
              
              />
              </div> */}
              
<Wrapper canvas style={{ display: "inline-block", fontSize:'16px', fontWeight:'400', fontFamily:'Roboto', lineHeight:'25.6px', color:'#FFFFFF', padding:'10px 20px 0px 20px', textAlign:'center'}} className='flex align-items-center h-full py-4 justify-items-center  ' id="random-id-87" is="div">
<Text text="    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat." />
</Wrapper> 
              <Wrapper canvas id='random-id-486' is='div' className='my-auto flex flex-col' style={{margin:'10px', borderTop:'1px solid #DBD9D966', textAlign:'center'}}>

<Wrapper canvas style={{ display: "inline-block", fontSize:'24px', fontWeight:'700', fontFamily:'Roboto', color:'#FFFFFF'}} className='my-2 pt-2'  id="random-id-88" is="div">
<Text text="Mark Twain" />
</Wrapper>
                    
<Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'300', fontFamily:'Roboto', color:'#FFFFFF' }} className='my-2 pb-2' id="random-id-89" is="div">
<Text text="Teach Lead" />
</Wrapper>
              </Wrapper>
          </Wrapper>
          </Wrapper>
      </Wrapper>
    </Wrapper></Element>
      </Element>,

    <Element canvas is={Parent} id='parentId1500' className="w-[100%] h-auto" >
      <Element canvas is={Parent} id='parentId1600' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-487' is='parent' className='w-[100%] h-full bg-[#2A2A2A]' >
        <Wrapper canvas id='random-id-488' is='container' className='container h-full mx-auto py-10' style={{display:'flex', flexDirection:'column'}}>
<Wrapper canvas style={{ display: "inline-block", fontWeight:'400', fontFamily:'Rum Raisin' }} className='text-[#FFD600] text-[46px] py-[25px] mx-auto' id="random-id-90" is="div">
<Text text="Review" />
</Wrapper>
        <Wrapper canvas id='random-id-489' is='wrapper' className='w-[100%] h-full flex flex-row flex-wrap justify-center my-auto' >
            <Wrapper canvas id='random-id-490' is='div' className='mx-auto my-[15px] w-[30%] min-w-[290px] min-h-[361px] h-full flex flex-col relative' style={{border: '1px solid #FFD6001A', boxShadow: '0px 0px 10px 0px #FFFFFF0F', borderRadius:'4px'}}>
                <Wrapper canvas id='random-id-491' is='div' className='absolute right-[-14px] top-[-25px] w-[40px] h-[40px]  '>
                <ImageC src='https://i.ibb.co/dDGcTsY/iconmonstr-quote-right-filled-240-3.png' sx={{width:'100%', height:'100%'}}/>
                </Wrapper>

<Wrapper canvas style={{ display: "inline-block", fontSize:'30px', fontWeight:'700', fontFamily:'Rubik', color:'#FFFFFF' }} className='text-center w-full mt-[20px]'  id="random-id-91" is="div">
<Text text="Great service" />
</Wrapper>
                {/* <div className='w-[200px] mx-auto'>
                <Rating sx={{fontSize:'30px'}}
                name="text-feedback"
                defaultValue={5}
                  />
                </div> */}
                
<Wrapper canvas style={{ display: "inline-block", fontSize:'16px', fontWeight:'400', fontFamily:'Rubik', lineHeight:'25.6px', color:'#FFFFFF', padding:'10px 20px 0px 20px', textAlign:'center'}} className='flex align-items-center h-full py-4 justify-items-center'  id="random-id-92" is="div">
<Text text="    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat." />
</Wrapper> 
                <Wrapper canvas id='random-id-492' is='div' className='my-auto flex flex-col' style={{margin:'10px', borderTop:'1px solid #FFD60033', textAlign:'center'}}>

<Wrapper canvas style={{ display: "inline-block", fontSize:'24px', fontWeight:'700', fontFamily:'Rubik', color:'#FFFFFF'}} className='my-2 pt-2' id="random-id-93" is="div">
<Text text="Mark Twain" />
</Wrapper>

<Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'300', fontFamily:'Rubik', color:'#FFFFFF' }} className='my-2 pb-2' id="random-id-94" is="div">
<Text text="CEO of Company" />
</Wrapper>
                </Wrapper>
            </Wrapper>

            <Wrapper canvas id='random-id-493' is='div' className='mx-auto my-[15px] w-[30%] min-w-[290px] min-h-[361px] h-full flex flex-col relative ' style={{border: '1px solid #FFD6001A', boxShadow: '0px 0px 10px 0px #FFFFFF0F', borderRadius:'4px',}}>
                <Wrapper canvas id='random-id-494' is='div' className='absolute right-[-14px] top-[-25px] w-[40px] h-[40px] '>
                <ImageC src='https://i.ibb.co/dDGcTsY/iconmonstr-quote-right-filled-240-3.png' sx={{width:'100%', height:'100%'}}/>
                </Wrapper>

<Wrapper canvas style={{ display: "inline-block", fontSize:'30px', fontWeight:'700', fontFamily:'Rubik', color:'#FFFFFF'}} className='w-full text-center mt-[20px]'  id="random-id-95" is="div">
<Text text="Great service" />
</Wrapper>
                {/* <div className='w-[200px] mx-auto'>
                <Rating sx={{fontSize:'30px'}}
                name="text-feedback"
                defaultValue={5}
                  />
                </div> */}
                
<Wrapper canvas style={{ display: "inline-block", fontSize:'16px', fontWeight:'400', fontFamily:'Rubik', lineHeight:'25.6px', color:'#FFFFFF', padding:'10px 20px 0px 20px', textAlign:'center' }} className='flex align-items-center h-full py-4 justify-items-center ' id="random-id-96" is="div">
<Text text="    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat." />
</Wrapper> 
                <Wrapper canvas id='random-id-495' is='div' className='my-auto flex flex-col' style={{margin:'10px', borderTop:'1px solid #FFD60033', textAlign:'center'}}>
<Wrapper canvas style={{ display: "inline-block", fontSize:'24px', fontWeight:'700', fontFamily:'Rubik', color:'#FFFFFF' }} className='my-2 pt-2' id="random-id-97" is="div">
<Text text="Jane Austen" />
</Wrapper>
                        
<Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'300', fontFamily:'Rubik', color:'#FFFFFF' }} className='my-2 pb-2' id="random-id-98" is="div">
<Text text="Developement Team Lead" />
</Wrapper>
                </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-496' is='div' className='mx-auto my-[15px] w-[30%] min-w-[290px] min-h-[361px] h-full flex flex-col relative ' style={{border: '1px solid #FFD6001A', boxShadow: '0px 0px 10px 0px #FFFFFF0F', borderRadius:'4px'}}>
                <Wrapper canvas id='random-id-497' is='div' className='absolute right-[-14px] top-[-25px] w-[40px] h-[40px] '>
                <ImageC src='https://i.ibb.co/dDGcTsY/iconmonstr-quote-right-filled-240-3.png' sx={{width:'100%', height:'100%'}}/>
                </Wrapper>

<Wrapper canvas style={{ display: "inline-block", fontSize:'30px', fontWeight:'700', fontFamily:'Rubik', color:'#FFFFFF' }}  className='w-full text-center mt-[20px] ' id="random-id-99" is="div">
<Text text="Great service" />
</Wrapper>
                {/* <div className='w-[200px] mx-auto '>
                <Rating sx={{fontSize:'30px'}}
                name="text-feedback"
                defaultValue={5}
                  />
                </div> */}
                
<Wrapper canvas style={{ display: "inline-block", fontSize:'16px', fontWeight:'400', fontFamily:'Rubik', lineHeight:'25.6px', color:'#FFFFFF', padding:'10px 20px 0px 20px', textAlign:'center' }} className='flex align-items-center h-full py-4 justify-items-center  ' id="random-id-100" is="div">
<Text text="    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat." />
</Wrapper> 
                <Wrapper canvas id='random-id-498' is='div' className='my-auto flex flex-col' style={{margin:'10px', borderTop:'1px solid #FFD60033', textAlign:'center'}}>

<Wrapper canvas style={{ display: "inline-block", fontSize:'24px', fontWeight:'700', fontFamily:'Rubik', color:'#FFFFFF' }} className='my-2 pt-2' id="random-id-101" is="div">
<Text text="Mark Twain" />
</Wrapper>
                        
<Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'300', fontFamily:'Rubik', color:'#FFFFFF'}} className='my-2 pb-2' id="random-id-102" is="div">
<Text text="Teach Lead" />
</Wrapper>
                </Wrapper>
            </Wrapper>
            </Wrapper>
        </Wrapper>
      </Wrapper></Element>
      </Element>,

<Element canvas is={Parent} id='parentId1700' className="w-[100%] h-auto" >
      <Element canvas is={Parent} id='parentId1800' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-900' is='parent' className='w-[100%] h-full bg-[#F7FBFE]' >
<Wrapper canvas id='random-id-901' is='container' className='container h-full mx-auto py-10' style={{display:'flex', flexDirection:'column'}}>
<Wrapper canvas id='random-id-902' is='div' className='text-[#6064D2] text-[46px] py-[25px] mx-auto' style={{fontWeight:'700', fontFamily:'Rubik'}}>
  <Text text='Review'/>
</Wrapper>
<Wrapper canvas id='random-id-903' is='wrapper' className='w-[100%] h-full flex flex-row flex-wrap justify-center my-auto' >
    <Wrapper canvas id='random-id-904' is='div' className='mx-auto my-[15px] w-[30%] min-w-[290px] min-h-[361px] h-full flex flex-col relative' style={{border:'1px solid #6064D21A', borderRadius:'10px'}}>
        <Wrapper canvas id='random-id-905' is='div' className='absolute right-[-14px] top-[-25px] w-[40px] h-[40px]  '>
        <ImageC src='https://i.ibb.co/KxJxkb1/iconmonstr-quote-right-filled-240-10.png' style={{width:'100%', height:'100%'}}/>                
        </Wrapper>
<Wrapper canvas style={{ display: "inline-block", fontSize:'30px', fontWeight:'700', fontFamily:'Rubik', color:'#B20312' }} className='text-center w-full mt-[20px]' id="random-id-920" is="div">
<Text text="Great service" />
</Wrapper>
        {/* <div className='w-[200px] mx-auto'>
        <Rating sx={{fontSize:'30px'}}
        name="text-feedback"
        defaultValue={5}
          />
        </div> */}
            <Wrapper canvas style={{ display: "inline-block", fontSize:'16px', fontWeight:'400', fontFamily:'Rubik', lineHeight:'25.6px', color:'#100103', padding:'10px 20px 0px 20px', textAlign:'center' }} className='flex align-items-center h-full justify-items-center' id="random-id-2" is="div">
            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat." />
            </Wrapper> 
        <Wrapper canvas id='random-id-906' is='div' className='my-auto flex flex-col' style={{margin:'10px', borderTop:'1px solid #6064D21A', textAlign:'center'}}>
<Wrapper canvas style={{ display: "inline-block", fontSize:'24px', fontWeight:'700', fontFamily:'Rubik', color:'#100103' }} className='my-2 pt-2' id="random-id-921" is="div">
<Text text="Mark Twain" />
</Wrapper>
<Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'300', fontFamily:'Rubik', color:'#100103'}} className='my-2 pb-2' id="random-id-922" is="div">
<Text text="CEO of Company" />
</Wrapper>
        </Wrapper>
    </Wrapper>

    <Wrapper canvas id='random-id-907' is='div' className='mx-auto my-[15px] w-[30%] min-w-[290px] min-h-[361px] h-full flex flex-col relative ' style={{border:'1px solid #6064D21A', borderRadius:'10px'}}>
        <Wrapper canvas id='random-id-908' is='div' className='absolute right-[-14px] top-[-25px] w-[40px] h-[40px] '>
        <ImageC src='https://i.ibb.co/KxJxkb1/iconmonstr-quote-right-filled-240-10.png' style={{width:'100%', height:'100%'}}/>
        </Wrapper>
<Wrapper canvas style={{ display: "inline-block", fontSize:'30px', fontWeight:'700', fontFamily:'Rubik', color:'#B20312'}} className='w-full text-center mt-[20px]' id="random-id-923" is="div">
<Text text="Great service" />
</Wrapper>
        {/* <div className='w-[200px] mx-auto'>
        <Rating sx={{fontSize:'30px'}}
        name="text-feedback"
        defaultValue={5}
          />
        </div> */}
<Wrapper canvas style={{ display: "inline-block", fontSize:'16px', fontWeight:'400', fontFamily:'Rubik', lineHeight:'25.6px', color:'#100103', padding:'10px 20px 0px 20px', textAlign:'center'}} className='flex align-items-center h-full justify-items-center '  id="random-id-6" is="div">
<Text text="    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat." />
</Wrapper> 
        <Wrapper canvas id='random-id-909' is='div' className='my-auto flex flex-col' style={{margin:'10px', borderTop:'1px solid #6064D21A', textAlign:'center'}}>
<Wrapper canvas style={{ display: "inline-block", fontSize:'24px', fontWeight:'700', fontFamily:'Rubik', color:'#100103'}} className='my-2 pt-2' id="random-id-924" is="div">
<Text text="Jane Austen" />
</Wrapper>                        
<Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'300', fontFamily:'Rubik', color:'#100103'}} className='my-2 pb-2' id="random-id-925" is="div">
<Text text="Developement Team Lead" />
</Wrapper>
        </Wrapper>
    </Wrapper>
    <Wrapper canvas id='random-id-910' is='div' className='mx-auto my-[15px] w-[30%] min-w-[290px] min-h-[361px] h-full flex flex-col relative ' style={{border:'1px solid #6064D21A', borderRadius:'10px'}}>
        <Wrapper canvas id='random-id-911' is='div' className='absolute right-[-14px] top-[-25px] w-[40px] h-[40px] '>
        <ImageC src='https://i.ibb.co/KxJxkb1/iconmonstr-quote-right-filled-240-10.png' style={{width:'100%', height:'100%'}}/>
        </Wrapper>
<Wrapper canvas style={{ display: "inline-block", fontSize:'30px', fontWeight:'700', fontFamily:'Rubik', color:'#B20312'}} className='w-full text-center mt-[20px] ' id="random-id-926" is="div">
<Text text="Great service" />
</Wrapper>
        {/* <div className='w-[200px] mx-auto '>
        <Rating sx={{fontSize:'30px'}}
        name="text-feedback"
        defaultValue={5}
          />
        </div>                 */}
<Wrapper canvas style={{ display: "inline-block", fontSize:'16px', fontWeight:'400', fontFamily:'Rubik', lineHeight:'25.6px', color:'#100103', padding:'10px 20px 0px 20px', textAlign:'center' }} className='flex align-items-center h-full justify-items-center  ' id="random-id-10" is="div">
<Text text="    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat." />
</Wrapper> 
        <Wrapper canvas id='random-id-912' is='div' className='my-auto flex flex-col' style={{margin:'10px', borderTop:'1px solid #6064D21A', textAlign:'center'}}>
<Wrapper canvas style={{ display: "inline-block", fontSize:'24px', fontWeight:'700', fontFamily:'Rubik', color:'#100103' }} className='my-2 pt-2' id="random-id-927" is="div">
<Text text="Mark Twain" />
</Wrapper>                        
<Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'300', fontFamily:'Rubik', color:'#100103' }} className='my-2 pb-2' id="random-id-928" is="div">
<Text text="Teach Lead" />
</Wrapper>
        </Wrapper>
    </Wrapper>
    </Wrapper>
</Wrapper>
</Wrapper></Element>
      </Element>,


<Element canvas is={Parent} id='parentId1900' className="w-[100%] h-auto" >
      <Element canvas is={Parent} id='parentId2000' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-913' is='parent' className='w-[100%] h-full bg-[#FEFBF7]' >
<Wrapper canvas id='random-id-914' is='container' className='container h-full mx-auto py-10' style={{display:'flex', flexDirection:'column'}}>
<Wrapper canvas id='random-id-915' is='div' className='text-[#A25738] text-[46px] py-[25px] mx-auto' style={{fontWeight:'400', fontFamily:'Ruluko'}}>
<Text text='Review'/>
</Wrapper>
<Wrapper canvas id='random-id-916' is='wrapper' className='w-[100%] h-full flex flex-row flex-wrap justify-center my-auto' >
    <Wrapper canvas id='random-id-917' is='div' className='mx-auto my-[15px] w-[30%] min-w-[290px] min-h-[361px] h-full flex flex-col relative' style={{border:'1px solid #A257381A', borderRadius:'10px'}}>
        <Wrapper canvas id='random-id-918' is='div' className='absolute right-[-14px] top-[-25px] w-[40px] h-[40px]  '>
        <ImageC src='https://i.ibb.co/94Nq5LQ/iconmonstr-quote-right-filled-240-11.png' style={{width:'100%', height:'100%'}}/>                
        </Wrapper>
<Wrapper canvas style={{ display: "inline-block", fontSize:'22px', fontWeight:'700', fontFamily:'Sansation', color:'#000' }} className='text-center w-full mt-[20px]' id="random-id-919" is="div">
<Text text="Great service" />
</Wrapper>
        {/* <div className='w-[200px] mx-auto'>
        <Rating sx={{fontSize:'30px'}}
        name="text-feedback"
        defaultValue={5}
          />
        </div> */}
            <Wrapper canvas style={{ display: "inline-block", fontSize:'16px', fontWeight:'400', fontFamily:'Sansation', lineHeight:'25.6px', color:'#100103', padding:'10px 20px 0px 20px', textAlign:'center' }} className='flex align-items-center h-full justify-items-center' id="random-id-929" is="div">
            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat." />
            </Wrapper> 
        <Wrapper canvas id='random-id-930' is='div' className='my-auto flex flex-col' style={{margin:'10px', borderTop:'1px solid #A257381A', textAlign:'center'}}>
<Wrapper canvas style={{ display: "inline-block", fontSize:'24px', fontWeight:'700', fontFamily:'Sansation', color:'#100103' }} className='my-2 pt-2' id="random-id-931" is="div">
<Text text="Mark Twain" />
</Wrapper>
<Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'300', fontFamily:'Sansation', color:'#100103'}} className='my-2 pb-2' id="random-id-932" is="div">
<Text text="CEO of Company" />
</Wrapper>
        </Wrapper>
    </Wrapper>

    <Wrapper canvas id='random-id-933' is='div' className='mx-auto my-[15px] w-[30%] min-w-[290px] min-h-[361px] h-full flex flex-col relative ' style={{border:'1px solid #A257381A', borderRadius:'10px'}}>
        <Wrapper canvas id='random-id-934' is='div' className='absolute right-[-14px] top-[-25px] w-[40px] h-[40px] '>
        <ImageC src='https://i.ibb.co/94Nq5LQ/iconmonstr-quote-right-filled-240-11.png' style={{width:'100%', height:'100%'}}/>
        </Wrapper>
<Wrapper canvas style={{ display: "inline-block", fontSize:'22px', fontWeight:'700', fontFamily:'Sansation', color:'#000'}} className='w-full text-center mt-[20px]' id="random-id-935" is="div">
<Text text="Great service" />
</Wrapper>
        {/* <div className='w-[200px] mx-auto'>
        <Rating sx={{fontSize:'30px'}}
        name="text-feedback"
        defaultValue={5}
          />
        </div> */}
<Wrapper canvas style={{ display: "inline-block", fontSize:'16px', fontWeight:'400', fontFamily:'Sansation', lineHeight:'25.6px', color:'#100103', padding:'10px 20px 0px 20px', textAlign:'center'}} className='flex align-items-center h-full justify-items-center '  id="random-id-6" is="div">
<Text text="    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat." />
</Wrapper> 
        <Wrapper canvas id='random-id-936' is='div' className='my-auto flex flex-col' style={{margin:'10px', borderTop:'1px solid #A257381A', textAlign:'center'}}>
<Wrapper canvas style={{ display: "inline-block", fontSize:'24px', fontWeight:'700', fontFamily:'Sansation', color:'#100103'}} className='my-2 pt-2' id="random-id-936" is="div">
<Text text="Jane Austen" />
</Wrapper>                        
<Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'300', fontFamily:'Sansation', color:'#100103'}} className='my-2 pb-2' id="random-id-937" is="div">
<Text text="Developement Team Lead" />
</Wrapper>
        </Wrapper>
    </Wrapper>
    <Wrapper canvas id='random-id-938' is='div' className='mx-auto my-[15px] w-[30%] min-w-[290px] min-h-[361px] h-full flex flex-col relative ' style={{border:'1px solid #A257381A', borderRadius:'10px'}}>
        <Wrapper canvas id='random-id-939' is='div' className='absolute right-[-14px] top-[-25px] w-[40px] h-[40px] '>
        <ImageC src='https://i.ibb.co/94Nq5LQ/iconmonstr-quote-right-filled-240-11.png' style={{width:'100%', height:'100%'}}/>
        </Wrapper>
<Wrapper canvas style={{ display: "inline-block", fontSize:'22px', fontWeight:'700', fontFamily:'Sansation', color:'#100103'}} className='w-full text-center mt-[20px] ' id="random-id-940" is="div">
<Text text="Great service" />
</Wrapper>
        {/* <div className='w-[200px] mx-auto '>
        <Rating sx={{fontSize:'30px'}}
        name="text-feedback"
        defaultValue={5}
          />
        </div>                 */}
<Wrapper canvas style={{ display: "inline-block", fontSize:'16px', fontWeight:'400', fontFamily:'Sansation', lineHeight:'25.6px', color:'#100103', padding:'10px 20px 0px 20px', textAlign:'center' }} className='flex align-items-center h-full justify-items-center  ' id="random-id-941" is="div">
<Text text="    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat." />
</Wrapper> 
        <Wrapper canvas id='random-id-942' is='div' className='my-auto flex flex-col' style={{margin:'10px', borderTop:'1px solid #A257381A', textAlign:'center'}}>
<Wrapper canvas style={{ display: "inline-block", fontSize:'24px', fontWeight:'700', fontFamily:'Sansation', color:'#100103' }} className='my-2 pt-2' id="random-id-943" is="div">
<Text text="Mark Twain" />
</Wrapper>                        
<Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'300', fontFamily:'Sansation', color:'#100103' }} className='my-2 pb-2' id="random-id-944" is="div">
<Text text="Teach Lead" />
</Wrapper>
        </Wrapper>
    </Wrapper>
    </Wrapper>
</Wrapper>
</Wrapper></Element>
      </Element>,

    ]

    
const [sel, setSel] = useState(selected)

function makeOdd(number) {
  setProp((prop) => {
    setSel(100);
  }, 1000);

  setTimeout(() => {
    setProp((prop) => {
      setSel(number)
    }, 1000);
  }, 100);


  
}

useEffect(() => {
if(selected || selected >= 0) {
  makeOdd(selected);
}
},[selected])

    return (

      <div style={{ padding:enabled? "10px" : "0px", display:"flex", justifyContent:"center", alignItems:"center", width:'100%', height:'100%'}} ref={connect}>
       {sel == 100 ? (
          <div style={{height:"85vh", display:"flex", justifyContent:"center", alignItems:"center"}}>
          <BounceLoader
                  size={100}
                  color={"white"}
                  loading={true}
                />
          </div>
         ) : (
          styles[sel]
         )} 
  </div>
    
    )}   

Review_1.craft = {
displayName: "Review 1",
props: {
   selected: 1, // here is the default value of the selected style
   length:9,
isBlock: true
  },
  rules: {
    canDrag: () => true,
    canDrop: () => true,
  },
  related: {
    toolbar: NewReviewSettings,
  },
};

