/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, { useContext, useState } from 'react';
import { useEditor } from '@craftjs/core';
import { useEffect } from 'react'
import { useDarkMode } from '../../../DarkModeContext';
import './toolbar.css'
import { AddPageIcon, DeleteNameIcon } from '../Viewport/EditorSvg';
import SelectionBox from '../Viewport/Pop-up/PopUp';
import { category } from '../../selectors/basic/Image/ImageSettings';
import { ArrowToRight, CloseIcon2 } from '../Viewport/Pop-up/svgArray';
import { Slider as Small } from '@mui/material';

import { PenEditIcon } from '../Viewport/Pop-up/svgArray';
import { withStyles } from '@mui/styles';
import SideBarProperties from '../../selectors/blocks/Header/Side bar/SideBarProperties';
import SidebarContext from '../../../SidebarShown/SidebarContext';

export * from './ToolbarItem';
export * from './ToolbarSection';
export * from './ToolbarTextInput';
export * from './ToolbarDropdown';


const SliderStyled = withStyles({
  root: {
    color: '#ABE2FE',
    padding: '5% 0',
  },
  thumb: {
    height: '60%',
    width: '8%',
    backgroundColor: '#ffffff',
    border: '2px solid #ABE2FE',
    marginTop: '-0.5%',
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 1.1%)',
    top: '-22%',
    '& *': {
      background: 'transparent',
      color: '#000',
    },
  },
  track: {
    padding: '1.5%',
    height: '2%',
  },
  rail: {
    padding: '1.5%',
    height: '2%',
    opacity: 0.5,
    backgroundColor: '#ABE2FE',
  },
  mark: {
    backgroundColor: '#bfbfbf',
    height: '8%',
    width: '10%',
    marginTop: '-3%',
  },
  markActive: {
    opacity: 1,
    backgroundColor: 'currentColor',
  },
})(Small);

export const Toolbar = (props) => {
  const { isDarkMode } = useDarkMode();
  const { showSideBar, setShowSideBar } = useContext(SidebarContext)
  const {smartMenu, setSmartMenu} = useDarkMode()


  const { navigationSide,
    setMoving,
    setSettings,
    setStylesOn,
    selectNode,
    currentStyleIndex,
    handleSliderChange,
    handleSliderBlur,
    handleSliderFocus,
    setSocialIcons,
    socialIcons,
    enabled,
    setNavigationSide,
    setLogoText,
    logoText,
    logoIcon,
    setLogoIcon, onClose, } = props;

  const { active, related } = useEditor((state, query) => {
    // TODO: handle multiple selected elements
    const currentlySelectedNodeId = query.getEvent('selected').first();
    return {
      active: currentlySelectedNodeId,
      related:
        currentlySelectedNodeId && state.nodes[currentlySelectedNodeId].related,
    };
  });

  useEffect(() => {
    if (related?.toolbar && React.createElement(related.toolbar)) {
      setNavigationSide(false)

    }
  }, [related])

if(!smartMenu) {
  return null
}

  return (
    <>
   

      <div className={`sidebarApp mr-[2vmin] ${isDarkMode ? 'dark-mode' : 'light-mode'} `} 
      style={{ display: !active && !navigationSide && 'none' }}>
        <div onClick={() => {
          if (!navigationSide) {
            setSmartMenu(false)
            onClose()
          } else {
          setNavigationSide(false)
          setSettings(false)
          setStylesOn(false)
          selectNode(null)
         } 
        }} className='cursor-pointer w-[fit-content] p-[2%]'><CloseIcon2 width='3vmin' height='3vmin' fill='#989191'/></div>
        <div className={`w-[100%] mx-auto pb-[1vmin] uppercase text-[2.8vmin] text-center font-bold font-["Inter"] ${isDarkMode ? 'text-[#fff]' : 'text-[#161717]'}`}>properties</div>
        {active ? (related.toolbar && React.createElement(related.toolbar)) : navigationSide && (
          <>
          <SideBarProperties
            setMoving={setMoving}
            setNavigationSide={setNavigationSide}
            navigationSide={navigationSide}
            setSettings={setSettings}
            setStylesOn={setStylesOn}
            selectNode={selectNode}
            enabled={enabled}
            active={active}
            socialIcons={socialIcons}
            setSocialIcons={setSocialIcons}
            logoText={logoText}
            setLogoIcon={setLogoIcon}
            setLogoText={setLogoText}
            logoIcon={logoIcon}
          />
          <div style={{ background: isDarkMode ? '#111F3E' : '#FFF' }} className='w-[90%] px-[15%] py-[3%] rounded-[2.5vmin] mx-auto my-[1vmin] shadow-md flex align-items-center'>
            <SliderStyled
              title="Navigition System Styles"
              min={0}
              max={23}
              value={currentStyleIndex}
              onChange={handleSliderChange}
              onBlur={handleSliderBlur}
              onFocus={handleSliderFocus}
            />
          </div>
        </>
        )
        
      }
        
        
        {!active && (
            <div
              className="flex flex-col items-center w-[100%] h-[100%] justify-center text-center"
              style={{
                color: 'rgba(0, 0, 0, 0.5607843137254902)',
              }}
            >
              <div className='text-[2.2Vmin]'>Click on a component to start editing.</div>
            </div>
        )}
      </div>
    </>
  );
};