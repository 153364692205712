/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React from 'react';
import { ContainerSettings } from './ContainerSettings.js';
import { Element, useNode } from '@craftjs/core';
import { Parent } from '../selectors/Parent/index.js';
import { Banner_1 } from '../selectors/blocks/Banner/Banner1.js';

const defaultProps = {
  isContainer: true,
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  fillSpace: 'no',
  background: { r: 0, g: 0, b: 0, a: 0 },
  startImage: false,
  color: { r: 0, g: 0, b: 0, a: 1 },
  shadow: 0,
  radius: 0,
  // width: '100%',
  // height: 'auto',
  width: "100%",
  child: false,
  height:"300px"
};

export const Container = (props) => {
  props = {
    ...defaultProps,
    ...props,
  };

  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));

  const {
    flexDirection,
    fillSpace,
    background,
    color,
    padding,
    margin,
    shadow,
    radius,
    children,
    child,
    width,
    height,
    border,
    startImage,
    notMain,
  } = props;



  return (
    <>
      {children}
      {!notMain ? <Banner_1 selected={1} /> : null}
      </>
  );
};

Container.craft = {
  displayName: 'Container',
  props: defaultProps,
  rules: {
    canDrag: () => true,
  },
  related: {
    toolbar: ContainerSettings,
  },
};

