/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, { useState } from 'react';

import { Element, useNode } from '@craftjs/core';
import { NewBannerSettings } from './newBannerSetting';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import InstagramIcon from '@mui/icons-material/Instagram';
import FitbitIcon from '@mui/icons-material/Fitbit';
import MenuIcon from '@mui/icons-material/Menu'; 
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { Text } from '../../basic/Text';
import { ImageC } from '../../basic/Image'
import { Button } from '../../basic/Button';

export const StartFromHere = ({ selected, chidlren }) => {
  const { 
    connectors: {
      connect
    } 
  } = useNode();

  const styles = [
    <div className=" w-[100%] h-auto bg-white px-20 py-20">
        <div className='w-[100%] h-auto flex flex-col mx-auto text-center' >
            <div className='mx-auto pt-20'>
                <div className="w-[100%]">
                  <Element canvas style={{ display: "inline-block" }} id="random-id-100" is="div">
                    
                     <div className='w-[100%] h-auto mx-auto'>
        <ImageC />
      </div>
                  </Element>
                </div>
                <div className=" mx-auto my-4 text-stone-950 text-[56px] font-bold ">
                  <Element canvas style={{ display: "inline-block" }} id="random-id-1" is="div">
                    <Text text="All You Can Cloud" />
                  </Element>
                </div>
                <div className=" mx-auto my-4 text-red-700 text-[56px] font-bold ">
                  <Element canvas style={{ display: "inline-block" }} id="random-id-2" is="div">
                    <Text text="Start From Here" />
                  </Element>
                </div>
            </div>
            
            <div className='flex flex-row flex-wrap justify-center mx-auto p-20 '>
                <Element canvas id='random-id-201' is='div' className="m-2">
                    <Button href={'/'} classn='block' className=" w-[260px] h-[70px] bg-red-700 flex shadow py-[15px] cursor-pointer text-white text-[22px]" style={{fontWeight:'600px'}}>
                      <Element canvas style={{ display: "inline-block" }} className='mx-auto my-auto' id="random-id-4" is="div">
                        <Text text="Get started" />
                      </Element>
                    </Button>
                </Element>
            </div>
        </div>
    </div>,
    <div className=" w-[100%] h-auto bg-gray-900 px-20 py-20">
        <div className='w-[100%] h-auto flex flex-col mx-auto text-center' >
            <div className='mx-auto pt-20'>
                <div className="UnderstandUserFlow2 mx-auto my-4 text-[56px] font-bold " style={{fontFamily:'Rubik', fontWeight:'700px'}}>
                  <Element canvas style={{ display: "inline-block" }} id="random-id-1111112" is="div">
                    
                     <div className='w-[100%] h-auto mx-auto'>
        <img alt='style2' className='object-cover' src='https://i.ibb.co/s1zbVwW/Logo.png' />
      </div>
                  </Element>
                </div>
                <div className="UnderstandUserFlow2 mx-auto my-4 text-[56px] font-bold " style={{fontFamily:'Rubik', fontWeight:'700px'}}>
                  <Element canvas style={{ display: "inline-block" }} id="random-id-6" is="div">
                    <Text text="All You Can Cloud" />
                  </Element>
                </div>
                <div className="IncreaseConversion2 mx-auto my-4 text-[56px] font-bold " style={{fontFamily:'Rubik', fontWeight:'700px'}}>
                  <Element canvas style={{ display: "inline-block" }} id="random-id-7" is="div">
                    <Text text="Start From Here" />
                  </Element>
                </div>
            </div>
            
            <div className='flex flex-row flex-wrap justify-center mx-auto p-20 '>
                <Element canvas id='random-id-200' is='div' className="m-2">
                    <Button href={'/'} classn='block' className="w-[260px] h-[70px] bg-blue-600 cursor-pointer flex border-opacity-10 py-[16px] text-white text-[22px] " style={{fontWeight:'600px', boxShadow: '0px 4px 10px 0px #FFFFFF0D'}}>
                      <Element canvas style={{ display: "inline-block" }} className='mx-auto my-auto' id="random-id-9" is="div">
                        <Text text="Get started" />
                      </Element>
                    </Button>
                </Element>
            </div>
        </div>
    </div>,
    <div className=" w-[100%] h-auto bg-[#213156] px-20 py-20">
        <div className='w-[100%] h-auto flex flex-col mx-auto text-center' >
            <div className='mx-auto pt-20'>
                <div className=" UnderstandUserFlow3 mx-auto my-4 text-white text-[56px] font-bold" style={{fontFamily:'Times New Roman'}}>
                  <Element canvas style={{ display: "inline-block" }} id="random-id-11111131" is="div">
                    
                     <div className='w-[100%] h-auto mx-auto'>
        <img alt='style2' className='object-cover' src='https://i.ibb.co/s1zbVwW/Logo.png' />
      </div>
                  </Element>
                </div>
                <div className=" UnderstandUserFlow3 mx-auto my-4 text-white text-[56px] font-bold" style={{fontFamily:'Times New Roman'}}>
                  <Element canvas style={{ display: "inline-block" }} id="random-id-11" is="div">
                    <Text text="All You Can Cloud" />
                  </Element>
                </div>
                <div className=" IncreaseConversion3 mx-auto my-4 text-[56px] font-bold" style={{fontFamily:'Times New Roman'}}>
                  <Element canvas style={{ display: "inline-block" }} id="random-id-12" is="div">
                    <Text text="Start From Here" />
                  </Element>
                </div>
            </div>
            
            <div className='flex flex-row flex-wrap justify-center mx-auto p-20 '>
                <div className=" w-[260px] h-[70px] cursor-pointer m-2 ">
                    <div className=" w-[260px] h-[70px] bg-red-700 bg-[#ea6ef4] rounded-[40px] py-[15px] text-white text-[22px]" style={{fontWeight:'700px', fontFamily:'Open Sans'}}>
                      <Element canvas style={{ display: "inline-block" }} id="random-id-14" is="div">
                        <Text text="Get started" />
                      </Element>
                    </div>
                </div>
            </div>
        </div> 
    </div>,
    <div className=" w-[100%] h-auto bg-[#dffff9] px-20 py-20 ">
        <div className='w-[100%] h-auto flex flex-col mx-auto text-center' >
            <div className='mx-auto pt-20'>
                <div className="UnderstandUserFlow4 mx-auto my-4 text-stone-950 text-[56px] font-bold " style={{fontFamily:'Zing Script Rust SemiBold Demo', fontStyle:'italic'}}>
                  <Element canvas style={{ display: "inline-block" }} id="random-id-11111146" is="div">
                    
                     <div className='w-[100%] h-auto mx-auto'>
          <img alt='style2' className='object-cover' src='https://i.ibb.co/s1zbVwW/Logo.png' />
        </div>
                  </Element>
                </div>
                <div className="UnderstandUserFlow4 mx-auto my-4 text-stone-950 text-[56px] font-bold " style={{fontFamily:'Zing Script Rust SemiBold Demo', fontStyle:'italic'}}>
                  <Element canvas style={{ display: "inline-block" }} id="random-id-16" is="div">
                    <Text text="All You Can Cloud" />
                  </Element>
                </div>
                <div className="IncreaseConversion4 mx-auto my-4 text-[#31A993] text-[56px] font-bold " style={{fontFamily:'Zing Script Rust SemiBold Demo', fontStyle:'italic'}}>
                  <Element canvas style={{ display: "inline-block" }} id="random-id-17" is="div">
                    <Text text="Start From Here" />
                  </Element>
                </div>
            </div>
            
            <div className='flex flex-row flex-wrap justify-center mx-auto p-20 '>
                <div className=" w-[260px] h-[70px] cursor-pointer m-2">
                    <div className=" w-[260px] h-[70px] bg-[#31a993] rounded-[40px] py-[16px] text-[#DFFFF9] text-[22px]" style={{fontWeight:'700px', fontFamily:'Open Sans'}}>
                      <Element canvas style={{ display: "inline-block" }} id="random-id-19" is="div">
                        <Text text="Get started" />
                      </Element>
                    </div>
                </div>
            </div>
        </div>
    </div>,
    <div className=" w-[100%] h-auto bg-white px-20 py-20 ">
            <div className='w-[100%] h-auto flex flex-col mx-auto text-center' >
                <div className='mx-auto pt-20'>
                    <div className=" mx-auto my-4 text-slate-950 text-[56px] font-bold" style={{fontFamily:'Inter'}}>
                      <Element canvas style={{ display: "inline-block" }} id="random-id-11111151" is="div">
                        
                         <div className='w-[100%] h-auto mx-auto'>
        <img alt='style2' className='object-cover' src='https://i.ibb.co/s1zbVwW/Logo.png' />
      </div>
                      </Element>
                    </div>
                    <div className=" mx-auto my-4 text-slate-950 text-[56px] font-bold" style={{fontFamily:'Inter'}}>
                      <Element canvas style={{ display: "inline-block" }} id="random-id-21" is="div">
                        <Text text="All You Can Cloud" />
                      </Element>
                    </div>
                    <div className=" mx-auto my-4 text-[#0078d3] text-[56px] font-bold" style={{fontFamily:'Inter'}}>
                      <Element canvas style={{ display: "inline-block" }} id="random-id-22" is="div">
                        <Text text="Start From Here" />
                      </Element>
                    </div>
                </div>
                
                <div className='flex flex-row flex-wrap justify-center mx-auto p-20'>
                    <div className="w-[260px] h-[70px] cursor-pointer m-2">
                        <div className="w-[260px] h-[70px] bg-[#0078d3] py-[16px] text-[22px] text-white" style={{borderRadius:'6px', boxShadow: '0px 4px 10px 0px #0000001A', fontFamily:'Inter', fontWeight:'700px'}}>
                          <Element canvas style={{ display: "inline-block" }} id="random-id-24" is="div">
                            <Text text="Get started" />
                          </Element>
                        </div>
                    </div>
                </div>
            </div>
        </div>,
        <div className=" w-[100%] h-auto bg-[#020212] px-20 " id="NewRootRoot">
        <div className='w-[100%] h-auto flex flex-col gap-10 items-center pt-[206px] pb-[243px] mx-auto text-center' >
            <div className='w-full mx-auto'>
                <div className=" mx-auto text-[58px] text-white font-bold " style={{fontFamily:'Voces'}}>
                  <Element canvas style={{ display: "inline-block" }} id="random-id-11111166" is="div">
                    
                     <div className='w-[100%] h-auto mx-auto'>
        <img alt='style2' className='object-cover' src='https://i.ibb.co/s1zbVwW/Logo.png' />
      </div>
                  </Element>
                </div>
                <div className=" mx-auto text-[58px] text-white font-bold " style={{fontFamily:'Voces'}}>
                  <Element canvas style={{ display: "inline-block" }} id="random-id-26" is="div">
                    <Text text="All You Can Cloud" />
                  </Element>
                </div>
            </div>
            <div className='mx-auto px-[50px] my-8'>
                <div className=" mx-auto text-[28px] text-white font-normal" style={{fontFamily:'Voces'}}>
                  <Element canvas style={{ display: "inline-block" }} id="random-id-27" is="div">
                    <Text text="Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab ill." />
                  </Element>
                </div>
            </div>
            </div>
        </div>,
    <div className=" w-[100%] h-auto bg-white px-20  " id="NewRootRoot">
        <div className='w-[100%] bg-[#6750a4] border h-auto flex flex-col gap-10 items-center pt-[180px] pb-[238px] rounded-[40px] mx-auto text-center' >
            <div className='mx-auto pt-20'>
                <div className=" mx-auto text-[64px] text-white px-[20px] " style={{fontWeight:'600px', fontFamily:'Roboto'}}>
                  <Element canvas style={{ display: "inline-block" }} id="random-id-11111179" is="div">
                    
                     <div className='w-[100%] h-auto mx-auto'>
        <img alt='style2' className='object-cover' src='https://i.ibb.co/s1zbVwW/Logo.png' />
      </div>
                  </Element>
                </div>
                <div className=" mx-auto text-[64px] text-white px-[20px] " style={{fontWeight:'600px', fontFamily:'Roboto'}}>
                  <Element canvas style={{ display: "inline-block" }} id="random-id-29" is="div">
                    <Text text="All You Can Cloud" />
                  </Element>
                </div>
            </div>
            <div className='p-[15px]  mx-auto px-[50px]'>
                <div className=" mx-auto text-[32px] text-white px-[30px] " style={{fontWeight:'400px', fontFamily:'Roboto'}}>
                  <Element canvas style={{ display: "inline-block" }} id="random-id-30" is="div">
                    <Text text="Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab ill." />
                  </Element>
                </div>
            </div>
            </div>
        </div>,
        
        <div className=" w-[100%] h-auto bg-[#2A2A2A] px-20 " id="NewRootRoot">
        <div className='w-[100%] h-auto flex flex-col gap-10 items-center pt-[206px] pb-[243px] mx-auto text-center' >
            <div className='w-full mx-auto'>
                <div className=" mx-auto text-[58px] text-[#FFD600] font-bold " style={{fontFamily:'Rum Raisin'}}>
                  <Element canvas style={{ display: "inline-block" }} id="random-id-11111186" is="div">
                    
                     <div className='w-[100%] h-auto mx-auto'>
        <img alt='style2' className='object-cover' src='https://i.ibb.co/s1zbVwW/Logo.png' />
      </div>
                  </Element>
                </div>
                <div className=" mx-auto text-[58px] text-[#FFD600] font-bold " style={{fontFamily:'Rum Raisin'}}>
                  <Element canvas style={{ display: "inline-block" }} id="random-id-26" is="div">
                    <Text text="All You Can Cloud" />
                  </Element>
                </div>
            </div>
            <div className='mx-auto px-[50px] my-8'>
                <div className=" mx-auto text-[28px] text-white font-normal" style={{fontFamily:'Rubik'}}>
                  <Element canvas style={{ display: "inline-block" }} id="random-id-27" is="div">
                    <Text text="Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab ill." />
                  </Element>
                </div>
            </div>
            </div>
        </div>
        
  ]

  return (
    <div style={{display:"flex", color:"black", justifyContent:"center", alignItems:"center", width:'100%', height:'100%'}} ref={connect}>
{chidlren || "+"}
    </div>
  );
};

StartFromHere.craft = {
  displayName: "Start-Block",
  props: {
    selected: 1, // here is the default value of the selected style
isBlock: true 
  },
  rules: {
    canDrag: () => true,
    canDrop: () => true,
  },
  related: {
    toolbar: NewBannerSettings,
  },
};
