/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import { useContext } from "react"
import SidebarContext from "../../../SidebarShown/SidebarContext"
import { DarkModeProvider, useDarkMode } from "../../../DarkModeContext"
import { CloseIcon2 } from "../../editor/Viewport/Pop-up/svgArray"

const AskHeader = () => {
    const {showSideBar, setShowSideBar, setPopUp} = useContext(SidebarContext)
    const {isDarkMode} = useDarkMode()
    return (
            <div className='relative mx-auto w-[fit-content] p-[7vmin] rounded-[1.5vmin] my-[3vmin]' style={{height:'fit-content', background:'#FFF'}}>
              <div onClick={() => setPopUp(false)} className="cursor-pointer absolute top-[3%] right-[3%]">
                <CloseIcon2 fill={isDarkMode? '#fff' : '#000'}/>
              </div>
              <div className="w-full h-full">
              <div className='title w-full text-center' style={{ fontFamily: 'Inter', fontWeight: '500', color: isDarkMode ? '#fff' : '#161717' }}>App navigation</div>
              <div style={{ width: "100%", height: "6.1vh" }} className=" flex flex-row  justify-center mt-[3%] gap-[3%] items-center">
                <div className={`${isDarkMode ? 'text-[#ffffff]' : showSideBar ? 'text-[#000000]' : 'text-[#989191]'} text-[1.8Vmin] item-hidden`} style={{ fontFamily: 'Inter', fontWeight: '500' }}>On</div>
                <div
                  className={`cursor-pointer relative bg-[transparent] mx-[2.5%] ${isDarkMode
                      ? 'text-[#FFFFFFA1] border-[.06vw] border-[#E9E9E930]'
                      : 'text-[#FFFFFF] border-[.06vw] border-[#E9E9EA]'
                    } flex`}
                  style={{ borderRadius: '2.5Vmin', overflow: "hidden", width: "7Vmin", height: "3.7Vmin" }}
                  onClick={() => {
                     if (showSideBar === null ) {
                        setShowSideBar(true)
                    } else if(showSideBar !== null) {
                        setShowSideBar(null)
                    } 
                  }}
                >
                  <div
                    className={`${showSideBar
                        ? 'w-[49%] h-[100%] bg-[#0867FC] motion-left'
                        : 'w-[49%] h-[100%] bg-[#0867FC] motion-right'
                      } items-left text-[.7vmin]`}
                    style={{ borderRadius: '2.5Vmin' }}
                  ></div>
                </div>
                <div className={`${isDarkMode ? 'text-[#ffffff]' : !showSideBar ? 'text-[#000000]' : 'text-[#989191]'} text-[1.8Vmin] item-hidden`} style={{ fontFamily: 'Inter', fontWeight: '500' }}>Off</div>
              </div>
              <div className='text-center toggle-result'>
                {showSideBar ? 'On' : 'Off'}
              </div>
              </div>
            </div>
    )
}

export default AskHeader;