/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import { Element, useEditor, useNode } from '@craftjs/core';
import React, { useCallback, useRef, useState } from 'react';
import { ImageCSettings } from './ImageSettings';
import { useActiveIdContext } from '../../../../ActiveIdContext';
import { useOptionsContext } from '../../../../OptionsContext';
import { Parent } from '../../Parent/index.js';
import { Wrapper } from '../../wrapper/index.js';
import { Text } from '../Text';
import { useInView } from 'react-hook-inview';
import Draggable from 'react-draggable';


export const ImageC = (props) => {
  const {
    connectors: { connect, drag },
    actions: {setProp},
  } = useNode((node) => ({
    selected: node.events.selected,
  }));

  const { enabled } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));

  const { state, dispatch: setActiveId } = useActiveIdContext();
  const { activeId: activeId } = state;

  const { state: idState, dispatch: idDispatch } = useOptionsContext();
  const { options: treeData } = idState;

  const [hovered, setHovered] = useState(false)
  const [inViewRef, inView] = useInView()

  const mergedRef = useRef(null)

 
  const setRef = useCallback((node) => {
    if (mergedRef.current !== node) {
      if (node) {
        connect(node);
        inViewRef(node);
      }
      mergedRef.current = node;
    }
  }, []);

  const { 
    background,
    color,
    margin,
    href,
    src,
    style,
    width,
    height,
    maxWidth,
    minWidth,
    padding,
    fit,
    isBasic,
    marginTop,
    marginBottom,
    marginLeft,
    marginRight,
    paddingTop,
    paddingBottom,
    paddingLeft,
    paddingRight,
    radius,
    border,
    positions,
    unit,
    convertToTxt = false,
    ...otherProps 

  } = props;

  return (
    <>
    {convertToTxt? (
    <Element canvas is={Wrapper} id='parentId12text' className="w-auto h-auto">
      <Wrapper canvas id='random-id-400text' is='div' width={'100%'} height={'100%'}>
        <Text text='Text'/>
      </Wrapper>
    </Element>
    ) : (

    <img
    alt='img'
      val={href?.name} _id="sidebarItemx"
      onClick={() => {
        if(href && !enabled) {
          if(href._id.includes("www")) {
            window.open("https://" + href?.name, "_blank")
          } else {
            const parentItem = treeData.find((x) => x._id === href._id);

            if (parentItem) {
              setActiveId(parentItem);
            } else {
              let childItem = null;
            
              for (const parent of treeData) {
                const child = parent.children.find((child) => child._id === href._id);
            
                if (child) {
                  childItem = child;
                  break;
                }
              }
            
              setActiveId(childItem);
            }

          }
        } 
      }}
      className={props.className}
      ref={setRef}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      src={src || 'https://i.ibb.co/72vs9kd/2.jpg'}
      style={{
        ...style,
        objectFit: fit? `${fit}` : 'cover', 
        cursor: href && !enabled ? "pointer" : "auto",
        marginTop:marginTop && `${marginTop?.split(" ")?.join("")}`,
        marginBottom:marginBottom && `${marginBottom?.split(" ")?.join("")}`,
        marginLeft:marginLeft && `${marginLeft?.split(" ")?.join("")}`,
        marginRight:marginRight && `${marginRight?.split(" ")?.join("")}`,
        paddingTop:paddingTop && `${paddingTop?.split(" ")?.join("")}`,
        paddingBottom:paddingBottom && `${paddingBottom?.split(" ")?.join("")}`,
        paddingLeft:paddingLeft && `${paddingLeft?.split(" ")?.join("")}`,
        paddingRight:paddingRight && `${paddingRight?.split(" ")?.join("")}`,
        color,
        borderRadius: radius ? `${radius?.split(" ")?.join("")}` : style?.borderRadius || '',
        border: border ? `${border?.split(" ")?.join("")}` : enabled ? "2px solid #E9E9EA" : style?.border || '',
        width: width ? `${width?.split(" ")?.join("")}` : style?.width || '',
        height: height ? `${height?.split(" ")?.join("")}` : isBasic ? 'auto' : style?.height || '',
        minWidth: minWidth ? `${minWidth?.split(" ")?.join("")}` :  style?.minWidth || '',
        maxWidth: maxWidth ? `${maxWidth?.split(" ")?.join("")}` : isBasic ? '100%' : style?.maxWidth || '',
        display:isBasic? 'block' : ''
      }}
      {...otherProps}
    />    
    )}
    </>
  );
};

ImageC.craft = {
  displayName: 'Image',
  props: {
    background: { r: 255, g: 255, b: 255, a: 0.5 },
    color: { r: 92, g: 90, b: 90, a: 1 },
    Decoration:'full',
    text: 'ImageC',
    border:null,
    transition:null,
    href:null,
    src:null,
    fit:null,
    width:null,
    maxWidth:null,
    minWidth:null,
    radius:null,
    convertToTxt: false,
    height:null,
    marginTop:null,
    marginBottom:null,
    marginLeft:null,
    marginRight:null,
    paddingTop:null,
    paddingBottom:null,
    paddingLeft:null,
    paddingRight:null,
    isBasic:false,
    isImage:true,
  },
  related: {
    toolbar: ImageCSettings,
  },
};