/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, { useRef } from 'react';
import { useEditor, useNode } from '@craftjs/core';
import { ParentSettings } from './ParentSettings';

export const Parent = (props) => {
  const {
    connectors: { connect },
  } = useNode((node) => ({
    selected: node.events.selected,

  }));

  const { enabled } = useEditor((state, query) => ({
    enabled: state.options.enabled,
  }));

  const {
    style,
    row,
    className,
    children,
    margin,
    shadow,
    transition,
    none,
    absolute,
    nav,
  } = props;



  let padding = row ? "0 20px" : "20px 0"
  // let background = !row ? "url(https://i.ibb.co/47D8yCW/columnicon.png)" : "url(https://i.ibb.co/1KkFdgj/rowicon.png)"
  // let background = row
  // ? "url('https://i.ibb.co/cv0Mgrm/Screenshot-2024-01-25-at-1-07-41-AM.png') repeat"
  // : "url('https://i.ibb.co/cv0Mgrm/Screenshot-2024-01-25-at-1-07-41-AM.png') repeat";
  let background = row ? "lightgray" : "lightblue"
  let flexDirection = row ? "row" : "column"
  let justifyContent = row ? "center" : "flex-start"


  return (
    <div
      ref={connect}
      className={className}
      style={{
        ...style,
        position: !absolute && 'relative',
        boxShadow: `0px 2px 8px ${shadow}px rgba(99, 99, 99, 0.2)`,
        padding: enabled?  "" : '',
        border: enabled && !none ? "1px dashed #0867FC" : "0",
        // background: enabled ? "white" : '',
        backgroundSize:"contain",
        display: "flex",
        flexDirection: flexDirection,
        transition: transition? `${transition}` : '',
        overflow: 'hidden',
        // justifyContent: justifyContent,
      }}
    >
        {children}
    </div> 

  )}


Parent.craft = {
  displayName: 'Parent',
  props: {
      isParent: true,
      transition:null,
    
  },
  rules: {
    canDrag: () => true,
    canDrop: () => true,
  },
  related: {
    toolbar: ParentSettings,
  },
}