/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, { useState } from 'react';
import { useDarkMode } from '../../../DarkModeContext';

const Toggle = ({ first, second, toggleIsImg, on }) => {
  const { isDarkMode } = useDarkMode();


  return (
    <div className='w-full border-b'>
        {/* <div className='title' style={{ fontFamily:'Inter', fontWeight:'500', color:isDarkMode? '#fff' : '#161717'}}>Type of element</div> */}
        <div style={{width:"100%",}} className="flex flex-row  justify-center my-[3%] gap-[3%] items-center">
            <div className={`${isDarkMode? 'text-[#ffffff]' : on? 'text-[#000000]' : 'text-[#989191]'} text-[1.8Vmin] `} style={{fontFamily:'Inter', fontWeight:'500'}}>{first}</div> 
                 <div
                   className={`cursor-pointer relative bg-[transparent] mx-[2.5%] ${
                     isDarkMode
                       ? 'text-[#FFFFFFA1] border-[.06vw] border-[#E9E9E930]'
                       : 'text-[#FFFFFF] border-[.06vw] border-[#E9E9EA]'
                   } flex`}
                   style={{borderRadius:'2.5Vmin', overflow:"hidden", width:"6Vmin", height:"3.3Vmin"}}
                   onClick={() => toggleIsImg()}
                 >
                   <div
                     className={`${
                       on
                         ? 'w-[49%] h-[100%] bg-[#0867FC] motion-left'
                         : 'w-[49%] h-[100%] bg-[#0867FC] motion-right'
                     } items-left text-[11px]`}
                     style={{borderRadius:'2.5Vmin'}}
                   ></div>
                 </div>
                <div className={`${isDarkMode? 'text-[#ffffff]' : !on? 'text-[#000000]' : 'text-[#989191]'} text-[1.8Vmin] `} style={{fontFamily:'Inter', fontWeight:'500'}}>{second}</div>
        </div>
        <div className='text-center toggle-result'>
               {on? first : second}
        </div>
    </div>
  );
};

export default Toggle;
