/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, {useState, useContext, createContext} from 'react';

const DarkModeContext = createContext();

    export function useDarkMode() {
        return useContext(DarkModeContext)
    }

    export function DarkModeProvider({children}) {
        const [isDarkMode, setIsDarkMode] = useState(false)
        const [smartMenu, setSmartMenu] = useState(false)
        


        const toggleDarkMode = () => {
            setIsDarkMode(!isDarkMode);
        };

    return (
        <DarkModeContext.Provider value={{isDarkMode, toggleDarkMode, smartMenu,
            setSmartMenu}}>
            {children}
        </DarkModeContext.Provider>
    )

}