import { useState } from "react";
import { MapIcon } from "../../components/land/Svg";
import { LangTool, LogoSite } from "../../components/editor/Viewport/EditorSvg";
import { ArrowDown, ArrowUpp, SearchIcon } from "../../components/editor/Viewport/Pop-up/svgArray";
import AreaEl from "../AreaEl";
import { useOptionsContext } from "../../OptionsContext";
import { useSidesContext } from "../../SideContext";

const SmallWidgets = ({
    basic,
    checkPos,
    handleStyleChange, open, setOpen,
    setClosePanel, closePanel,
    selectedItem, review, styleWrIcon, styleRMIcon, h3Styles, h4Styles, color, handleClick 
    }) => {

    const { 
    appSettings,
    navbarProps, setNavbarProps,
    styles,
    theOptionRow,
    } = useSidesContext();

    const { state: idState, dispatch: idDispatch } = useOptionsContext();

    const { options: treeData } = idState;

    const [searchInput, setSearchInput] = useState('')
    const [bluring, setBluring] = useState(false)
    const [clickedInput, setClickedInput] = useState(false)
    const [openWidget, setOpenWidget] = useState(null);
    const [clicked, setClicked] = useState(null)
    const [expanded, setExpanded] = useState({})

    let notice =
        `m12.002 2.005c5.518 0 9.998 4.48 9.998 9.997 0 5.518-4.48 9.998-9.998 9.998-5.517 0-9.997-4.48-9.997-9.998 0-5.517 4.48-9.997 9.997-9.997zm0 1.5c-4.69 0-8.497 3.807-8.497 8.497s3.807 8.498 8.497 8.498 8.498-3.808 8.498-8.498-3.808-8.497-8.498-8.497zm0 6.5c-.414 0-.75.336-.75.75v5.5c0 .414.336.75.75.75s.75-.336.75-.75v-5.5c0-.414-.336-.75-.75-.75zm-.002-3c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1z`

    let eyeNotSeen =
        `m11.998 5c-4.078 0-7.742 3.093-9.853 6.483-.096.159-.145.338-.145.517s.048.358.144.517c2.112 3.39 5.776 6.483 9.854 6.483 4.143 0 7.796-3.09 9.864-6.493.092-.156.138-.332.138-.507s-.046-.351-.138-.507c-2.068-3.403-5.721-6.493-9.864-6.493zm8.413 7c-1.837 2.878-4.897 5.5-8.413 5.5-3.465 0-6.532-2.632-8.404-5.5 1.871-2.868 4.939-5.5 8.404-5.5 3.518 0 6.579 2.624 8.413 5.5zm-8.411-4c2.208 0 4 1.792 4 4s-1.792 4-4 4-4-1.792-4-4 1.792-4 4-4zm0 1.5c-1.38 0-2.5 1.12-2.5 2.5s1.12 2.5 2.5 2.5 2.5-1.12 2.5-2.5-1.12-2.5-2.5-2.5z`

    let eyeSeen =
        `m11.998 5c-4.078 0-7.742 3.093-9.853 6.483-.096.159-.145.338-.145.517s.048.358.144.517c2.112 3.39 5.776 6.483 9.854 6.483 4.143 0 7.796-3.09 9.864-6.493.092-.156.138-.332.138-.507s-.046-.351-.138-.507c-2.068-3.403-5.721-6.493-9.864-6.493zm.002 3c2.208 0 4 1.792 4 4s-1.792 4-4 4-4-1.792-4-4 1.792-4 4-4zm0 1.5c1.38 0 2.5 1.12 2.5 2.5s-1.12 2.5-2.5 2.5-2.5-1.12-2.5-2.5 1.12-2.5 2.5-2.5z`

    let userLogo =
        `M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7.753 18.305c-.261-.586-.789-.991-1.871-1.241-2.293-.529-4.428-.993-3.393-2.945 3.145-5.942.833-9.119-2.489-9.119-3.388 0-5.644 3.299-2.489 9.119 1.066 1.964-1.148 2.427-3.393 2.945-1.084.25-1.608.658-1.867 1.246-1.405-1.723-2.251-3.919-2.251-6.31 0-5.514 4.486-10 10-10s10 4.486 10 10c0 2.389-.845 4.583-2.247 6.305z`


    let loginSuccess = false
    let user = 'name'
    let notify = [
        {
            message: `You've a new Message`,
            name: user,
        },
    ]

    const selectedPage = (item) => selectedItem !== item 
    ? `${color}10` 
    : `${color}70`;


    const handleReset = (id, e) => {
        e.stopPropagation();
        setNavbarProps(prev =>
            prev.map(widget =>
                widget.id === id ?
                    { ...widget, selected: false } :
                    widget
            )
        );
    };

    const handleChangeValue = (newValue) => {
        setSearchInput(newValue)
    }

    const handleOpenWidget = (e, widget) => {
        e.stopPropagation()
        setOpenWidget(prev => prev === null || prev.id !== widget.id ? widget : null);
    }

    const widgetComponents = (widget) => {
        console.log(widget.icon.fill)
        return (
            <>
                {widget.id === 'searchVis' ? (
                    <svg aria-hidden="true" focusable="false" role="img" viewBox={widget?.icon?.viewBox} width="4vmin" height="4vmin" fill={widget.widgetdisplay !== 'icon' ? color || widget?.icon?.fill ? bluring ? color || widget?.icon?.fill : '#98919160' : bluring ? '#0867FC60' : "#98919160" : color || widget?.icon?.fill || bluring ? '#0867FC60' : "#98919160"}>
                        <path d={widget?.icon?.path || "M14.516 4.489a9.733 9.733 0 1 1-9.725 9.733 9.78 9.78 0 0 1 9.724-9.733m0-1.822a11.555 11.555 0 1 0 0 23.11 11.555 11.555 0 0 0 0-23.11m16.596 26.924-6.55-6.596-1.263 1.254 6.551 6.595a.888.888 0 0 0 1.454-.964.9.9 0 0 0-.192-.289"} />
                    </svg>
                ) : (
                    <svg aria-hidden="true" focusable="false" role="img" viewBox={widget?.icon?.viewBox} width="4vmin" height="4vmin" fill={openWidget !== null && openWidget?.id === widget?.id && widget.id !== 2 ? color + '90' : widget?.icon?.fill || color}>
                        <path d={widget?.icon?.path} />
                    </svg>
                )}
            </>
        );
    };

    const renderIcon = (widget) => widgetComponents(widget);
    const logoWidget = navbarProps.filter(widget => widget.id === 2)
    const startWidgets = navbarProps.slice(0, 2);
    const endWidgets = navbarProps.slice(2, 6);
    const searchBarWidget = navbarProps.filter(widget => widget.selected && widget.name === 'searchVis');

    const anySelected = endWidgets.some(widget => widget.selected);

    const check = navbarProps.every(widget => theOptionRow !== 'Basic Header' && widget.selected !== true);

    const Msg = () => {
        if (check) {
            return <div className='min-w-[100%] text-center'>
                <div>You must choose even one widget to appear here!</div>
            </div>
        }
        return null;
    };

    return (
        <div style={h3Styles} className="w-full h-full flex items-center">
            <Msg />
            <div className="w-[100%] flex justify-between" style={{ height: 'auto', flexWrap: 'wrap' }}>

                <div style={{ display: 'flex', width: 'fit-content', flexDirection: 'row', alignItems: 'flex-center', height:'fit-content', zIndex: 99999999 }}>
                    {startWidgets.filter(widget => widget.selected).map((widget, index) => (
                        <div
                        onClick={(e) => {
                            setClosePanel(true)
                            setOpen(widget.id)
                            handleOpenWidget(e, widget)
                        }}
                            style={{ position: 'relative', marginRight: widget.id === widget.id ? '2em' : '3em'}} className={styleWrIcon} key={widget.id}>
                            {!review && <div
                                onClick={(e) => handleReset(widget.id, e)}
                                className={styleRMIcon(checkPos)}
                            >
                                ❌
                            </div>}
                            <div style={{ display: widget.widgetdisplay !== 'icon' && widget.widgetdisplay !== 'both' && 'none' }}>
                                {renderIcon(widget)}
                            </div>
                            <div style={{ margin: '0 .7em', display: widget.widgetdisplay !== 'name' && widget.widgetdisplay !== 'both' && 'none' }} className="mx-[.5vmin]">{widget.name.replace('Vis', '')}</div>
                            {widget.id !== 2 && openWidget && openWidget.id === widget.id && 
                            <AreaEl
                                review={review}
                                open={open}
                                setOpen={setOpen}
                                closePanel={closePanel}
                                setClosePanel={setClosePanel}
                                defaultStyle={styles[widget.id]}
                                onStyleChange={(newStyle) => handleStyleChange(widget.id, newStyle)}
                                id={widget.id}
                                style={{ ...styles[widget.id], top: '7vmin', left: 0 }}
                                className="absolute shadow-xl border rounded-[1vmin] p-[1vmin] flex flex-column justify-start items-start w-[35vmin]"
                            >
                                    <>
                                     <div className={`w-[100%] border-b flex justify-center items-center p-[1.5vmin]`} >
                                     <div className="cursor-pointer">
                                        {logoWidget.map((logo) => {
                                            return (
                                                <svg aria-hidden="true" focusable="false" fill={(logo?.icon?.fill || color)} viewBox={logo.icon.viewBox} role="img" width={logo.icon.width || '4vmin'} height={logo.icon.height || '4vmin'} >
                                                <path d={logo?.icon?.path} />
                                            </svg>
                                            )
                                        })}
                                   
                                     </div>
                                     {/* <div className="h-auto ml-[1vmin]">
                                         <div> {appSettings.appTitle.value} </div>
                                     </div> */}
                                 </div>
     
                                 <div className='container py-[6px]' style={{ overflow: 'scroll', scrollbarWidth: 'none', maxHeight: '80vmin', ...h3Styles, display:'flex', justifyContent:'flex-start', alignContent:'flex-start', flexDirection:'column' }}>
     
                                     {treeData.map((node, id) => (
                                        <div className="flex flex-column w-full">
                                         <div key={node._id} onClick={(e) => {
                                             e.stopPropagation()
                                             handleClick(node._id)
     
                                         }} className={`bg-[${selectedPage(node._id)}] w-[100%] flex flex-row items-center shadow-md justify-between rounded-[1vmin] my-[1vmin]`} style={{ height: 'auto', padding: '0 .5vmin' }}>
     
                                             <div className="w-[4vmin] ml-[1vmin] flex justify-start items-center">
                                                 <svg aria-hidden="true" focusable="false" fill={(node?.icon?.fill || color)} viewBox={node.icon.viewBox} role="img" width="2.9vmin" height="2.9vmin" >
                                                     <path d={node.icon.path} />
                                                 </svg>
                                             </div>
                                             <div className={` navbar-item`} style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', textAlign: 'left', width: '100%' }}>
                                                 {node.name.length > 11 ? node.name.slice(0, 10) + "..." : node.name}
                                             </div>
                                            {node.children.length !== 0 && <div className={`${!expanded? 'rotate-90' : 'rotate-180'}`} onClick={() => setExpanded(prev => prev === node._id ? null : node._id)}>
                                             <ArrowUpp  stroke={color} fill={color} />
                                            </div>}
                                         </div>
                                         <div>

                                         </div>
                                         {node.children.length !== 0 && expanded === node._id && <div className="flex flex-column space-y-[1vmin] items-end w-full">
                                         {(
                                            node.children.map(child => {
                                                return (
                                                    <div key={child._id} onClick={(e) => {
                                                        e.stopPropagation()
                                                        handleClick(child._id)
                                                        }} 
                                                        className={`bg-[${selectedPage(child._id)}] w-[80%] flex flex-row items-center shadow-md justify-between rounded-[1vmin] my-[1vmin]`} style={{ height: 'auto', padding: '0 .5vmin' }}>
                
                                                        <div className="w-[4vmin] ml-[1vmin] flex justify-start items-center">
                                                            <svg aria-hidden="true" focusable="false" fill={(child?.icon?.fill || color)} viewBox={child.icon.viewBox} role="img" width="2.9vmin" height="2.9vmin" >
                                                                <path d={child.icon.path} />
                                                            </svg>
                                                        </div>
                                                        <div className={` navbar-item`} style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', textAlign: 'left', width: '100%' }}>
                                                            {child.name.length > 11 ? child.name.slice(0, 10) + "..." : child.name}
                                                        </div>
                                                    </div>
                                                )
                                            })
                                         )}
                                         </div>}
                                         </div>
                                     ))}
                                 </div>
                                 </>
                            </AreaEl>
                            }
                        </div>
                    ))}
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: 'fit-content', padding: 0, zIndex: 999 }}>
                    {searchBarWidget.filter(widget => widget.selected).map((widget, index) => (
                        <div style={{ display: 'flex', justifyContent: 'start', width: 'fit-content', minWidth: 'fit-content', padding: '0' }}
                            className={styleWrIcon} key={widget.id}>
                            {!review && <div
                                onClick={(e) => handleReset(widget.id, e)}
                                className={styleRMIcon(checkPos)}
                            >
                                ❌
                            </div>}

                            <div style={{
                                transition: 'border-color 200ms ease-in-out', borderColor: bluring ? appSettings.color.value : `${appSettings.color.value}60`, padding: '.5vmin 1vmin', zIndex: 99999,
                                borderRadius: '1vmin', borderWidth: widget.widgetdisplay !== 'both' ? (clickedInput ? '.25vmin' : '') : '.25vmin', textTransform: 'capitalize'
                            }}
                                className={`mx-[.5vmin] flex justify-between items-center`}>
                                <input style={{...h4Styles, color: color || '#000', display: widget.widgetdisplay !== 'name' && widget.widgetdisplay !== 'both' && !clickedInput && 'none' }} onFocus={() => setBluring(true)}
                                    onBlur={() => setBluring(false)}
                                    placeholder={widget.placeholder}
                                    value={searchInput}
                                    onChange={(e) => handleChangeValue(e.target.value)}
                                    />
                                <div onClick={() => {
                                    widget.widgetdisplay === 'icon' && setClickedInput(!clickedInput)
                                }} style={{ display: widget.widgetdisplay !== 'icon' && widget.widgetdisplay !== 'both' && 'none' }}>
                                    {renderIcon(widget)}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                <div className={`flex flex-row justify-end ${!anySelected ? 'd-none' : ''}`} style={{ flex: 1, width: '100%', height: 'auto', zIndex: 9999999 }}>

                    {endWidgets.filter(widget => widget.selected).map((widget, index) => (
                        <div style={{ position: 'relative' }} key={widget.id}>
                            <div
                                onClick={(e) => {
                                    setClosePanel(true)
                                    setOpen(widget.id)
                                    handleOpenWidget(e, widget)
                                }}
                                className={styleWrIcon}
                            >
                                {!review && (
                                    <div
                                        onClick={(e) => handleReset(widget.id, e)}
                                        className={styleRMIcon(checkPos)}
                                    >
                                        ❌
                                    </div>
                                )}
                                <div
                                    style={{ display: widget.widgetdisplay !== 'icon' && widget.widgetdisplay !== 'both' ? 'none' : 'block' }}
                                >
                                    {renderIcon(widget)}
                                </div>
                                <div
                                    style={{ margin: '0 .5em', display: widget.widgetdisplay !== 'name' && widget.widgetdisplay !== 'both' ? 'none' : 'block' }}
                                    className="mx-[.5vmin]"
                                >
                                    {widget.name.replace('Vis', '')}
                                </div>
                            </div>
                            {openWidget && openWidget.id === widget.id && <AreaEl
                                review={review}
                                open={open}
                                setOpen={setOpen}
                                closePanel={closePanel}
                                setClosePanel={setClosePanel}
                                defaultStyle={styles[widget.id]}
                                onStyleChange={(newStyle) => handleStyleChange(widget.id, newStyle)}
                                id={widget.id}
                                className="absolute shadow-md border rounded-[1vmin] p-[1vmin] flex justify-center items-center h-[fit-content] w-[max-content]"
                                style={{ ...styles[widget.id], top: '7vmin', right: 0 }}
                            >
                                {openWidget.id === 6 ? (
                                    <div className='w-full h-full justify-center align-items-center flex flex-column text-[11px]'>
                                        <div style={{ alignContent: 'center', alignItems: 'center', height: '100%', padding: '.5vmin 0 1vmin 0' }} className=' w-[100%] flex justify-around my-auto'>
                                            <div className="w-[fit-content] h-auto ">
                                                <div className="cursor-pointer w-[fit-content]">
                                                    <svg aria-hidden="true" viewBox={widget.icon.viewBox} fill={widget.icon.fill || color} focusable="false" role="img" width="2.9vmin" height="2.9vmin" >
                                                        <path d={widget.icon.path} />
                                                    </svg>
                                                </div>
                                            </div>
                                            <span className="k-appbar-separator" />
                                            {!loginSuccess && <div className='mx-[5px]' style={{ textTransform: 'capitalize' }}>{user}</div>}
                                        </div>
                                        <div style={{ textTransform: 'capitalize' }} className='container flex flex-column h-full'>
                                            <div className='cursor-pointer w-[fit-content] my-[4px]'>
                                                <div>Login</div>
                                            </div>
                                            <div className='cursor-pointer w-[fit-content] my-[4px]'>
                                                <div>Sign up</div>
                                            </div>
                                            <div className='cursor-pointer w-[fit-content] my-[4px]'>
                                                <div>Forget Account</div>
                                            </div>
                                            <div className='cursor-pointer w-[fit-content] my-[4px]'>
                                                <div>Forget Password</div>
                                            </div>
                                        </div>
                                    </div>)
                                    : openWidget.id === 5 ? (
                                        <div className='w-full h-[max-content] text-[11px] container py-[5px]'>
                                            {notify.length >= 0 ? (
                                                notify.map((notification, i) => (
                                                    <>
                                                        <div key={i} className='w-full h-[fit-content] my-[4px] flex justify-between'
                                                            style={{
                                                                background: (i === clicked && clicked !== null) ? '#e9e9e930' : '',
                                                                border: '1px solid #e9e9e930',
                                                                padding: '3px',
                                                                borderRadius: '.5vmin',
                                                                fontSize: '8px',
                                                            }}>
                                                            <div>
                                                                <div>
                                                                    <div> {notification.message || "You've a new message"}!! </div>
                                                                </div>
                                                            </div>
                                                            <div className="w-[fit-content] mx-[.5vmin] h-auto ">
                                                                <div onClick={() => setClicked(prev => prev === i ? null : i)}>
                                                                    <svg aria-hidden="true" viewBox="0 0 24 24" focusable="false" role="img" width="2.9vmin" height="2.9vmin" >
                                                                        <path d={i === clicked && clicked !== null ? eyeSeen : eyeNotSeen} />
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div style={{
                                                            fontSize: '6px',
                                                            height: '100%',
                                                            color: styles[widget.id].color ? styles[widget.id]?.color + '80' : ''
                                                        }}>{clicked === null ? `There's ${notify.length} messages in the box` : "There's no message unread"}</div>
                                                    </>
                                                ))
                                            ) : (
                                                <div className='w-full h-full flex-column'>
                                                    <div>
                                                        <div>
                                                            <div> There's no notification yet.</div>
                                                        </div>
                                                    </div>
                                                    <div className="w-[fit-content] h-auto p-[10px] flex justify-center ">
                                                        <div className="cursor-pointer w-[fit-content]">
                                                            <svg aria-hidden="true" focusable="false" viewBox="0 0 24 24" role="img" width="2.9vmin" height="2.9vmin" >
                                                                <path d={notice} />
                                                            </svg>
                                                        </div>
                                                    </div>

                                                </div>
                                            )}
                                        </div>
                            ) : openWidget.id === 3 ? (
                                <svg aria-hidden="true" viewBox={widget.icon.viewBox} fill={widget.icon.fill || color} focusable="false" role="img" width="2.9vmin" height="2.9vmin" >
                                <path d={widget.icon.path} />
                            </svg>
                        ) : openWidget.id === 4 ? (
                            <img style={{borderRadius:'1.5vmin', width:'100%', height:'100%', maxHeight:'300px', objectFit:'cover'}} src="https://images.squarespace-cdn.com/content/v1/5dc6e3dd8f8a511234b04819/975cd81b-2e9d-42d4-985b-bacb22aa24a0/IMG_2892.jpg"/>
                        ) : null
                    }
                    </AreaEl>
                    }
                        </div>
                    ))}
                </div>
                {basic && (
                    <div className={`${!anySelected ? 'w-full flex-1' : 'w-[100%]'} min-w-[50vmin] flex flex-row items-center justify-center`} style={{ height: 'auto', flexWrap: 'wrap' }}>
                        {treeData.slice(0, 5).map((node, id) => (
                            <div className={` navbar-item`} style={{borderBottom: selectedItem === node._id && `1px solid ${color}`, borderRadius:0}} onClick={() => handleClick(node._id)} key={id}>
                                {node.name.length > 11 ? node.name.slice(0, 10) + "..." : node.name}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
}

export default SmallWidgets;