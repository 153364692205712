/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import { Element } from '@craftjs/core';
import { useNode } from '@craftjs/core';
import React from 'react';
import { Container } from  '../../../Container/index.js';

export const Column2 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));

  return (
    <div style={{ display: "flex", width: "100%" }} ref={connect}>
      <Element
        id="Container11111i-2"
        canvas
        is={Container}
        child={true}
        background={{ r: 78, g: 78, b: 78, a: 1 }}
        color={{ r: 0, g: 0, b: 0, a: 1 }}
        height="300px"
        width="50%"
      >
      </Element>
      <Element
        id="Container11111i-3"
        canvas
        is={Container}
        child={true}
        background={{ r: 78, g: 78, b: 78, a: 1 }}
        color={{ r: 0, g: 0, b: 0, a: 1 }}
        height="300px"
        width="50%"
      >
      </Element>
    </div>
  );
};

Column2.craft = {
  displayName: "Column2",
};
