import '@progress/kendo-theme-default/dist/all.css';
import '../Stepper.css';
import React, { useEffect, useState } from 'react';
import { CloseIcon } from '../../components/land/Svg';
import { useOptionsContext } from '../../OptionsContext';
import '../layout.css'
import Footer from '../ViewArea/ViewBottom'
import TopArea from '../ViewArea/ViewTop';
import SidesLR from '../ViewArea/SidesLR'
import { SidesIcon } from '../iconsSvg';
import BackgroundBanner from './BackgroundBanner';
import { useSidesContext } from '../../SideContext';

export const AppSetup = ({
  review,
  enable,
}) => {

  const { 
    walksFor,
    appSettings, 
    pageSettings, setPageSettings,
    widgets,
    } = useSidesContext();

  const [hiddenAlert, setHiddenAlert] = useState(false)
  const [open, setOpen] = useState('')
  const [closePanel, setClosePanel] = useState(false)
  const [menuHover, setMenuHover] = useState(true)
  const [selectedItem, setSelectedItem] = useState(null);

  const handleClick = (id) => {
    setSelectedItem(id);
  };

  const styleWrIcon = 'w-[fit-content] min-w-[4vmin] p-[1vmin] relative rounded-[.5vmin] flex justify-center items-center'
  const styleRMIcon = (check) => {
    const baseClasses = 'absolute top-[-1vmin] right-[-.7vmin] hover:scale-150 transform-gpu';
    let conditionalClasses = '';

    if (check === 'header') {
      conditionalClasses = walksFor.mainApp.value !== 0 ? 'd-none' : 'z-[999999999]';
    } else {
      conditionalClasses = walksFor.mainApp.value !== 2 ? 'd-none' : '';
    }

    return `${baseClasses} ${conditionalClasses} `;
  };

  const [backgroundStyle, setBackgroundStyle] = useState({});

  const isColor = (value) => {
    const colorPattern = /^(#([0-9a-fA-F]{3}){1,2}|rgb(a)?\(\d{1,3}%?,\s*\d{1,3}%?,\s*\d{1,3}%?(,\s*\d?\.?\d+)?\)|hsl(a)?\(\d{1,3}°,\s*\d+%,\s*\d+%(,\s*\d?\.?\d+)?\)|[a-zA-Z]+)$/;
    return typeof value === 'string' || colorPattern.test(value);
  };

  const identifyValue = (value) => {
    if ((typeof value === 'string' && (value.startsWith('blob:') ))) {
      return 'Blob';
    } else if (typeof value === 'string' && (value.startsWith('http://') || value.startsWith('https://'))) {
      return 'URL';
    } else if (isColor(value)) {
      return 'Color';
    } else {
      return 'Unknown';
    }
  };

  useEffect(() => {
    const backgroundValue = appSettings?.background?.value;
    const backgroundSetup = identifyValue(backgroundValue);

    if (backgroundSetup === 'Blob') {
        setBackgroundStyle({
          backgroundImage: `url(${backgroundValue})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
        });
    } else if (backgroundSetup === 'URL') {
      setBackgroundStyle({
        backgroundImage: `url(${backgroundValue})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      });
    } else if (backgroundSetup === 'Color') {
      setBackgroundStyle({ background: backgroundValue });
    } else {
      setBackgroundStyle({});
    }
  }, [appSettings?.background?.value]);

  useEffect(() => {

    if (walksFor.mainApp.value === 1) {
      setMenuHover(true)
      setPageSettings((prevSettings) => ({
        ...prevSettings,
        sidebar: {
          ...prevSettings.sidebar,
          position: 'left',
        },
      }));
    } else if (walksFor.mainApp.value === 3) {
      setMenuHover(true)
      setPageSettings((prevSettings) => ({
        ...prevSettings,
        sidebar: {
          ...prevSettings.sidebar,
          position: 'right',
        },
      }));
    }

  }, [walksFor.mainApp.value])

  const dynamicStyles = {
    border: !review && `.5vmin solid #e9e9e9`, 

  };

  const combinedStyles = { ...dynamicStyles, ...backgroundStyle, };

  return (
    <div className={`realtive shadow-2xl text-[1.5vmin] w-full max-h-[100%] h-[100%] flex flex-col justify-between ${!review && 'rounded-[1.5vmin] p-[.5vmin]'} cursor-pointer`}
      style={{...combinedStyles, zoom: !review && '80%', overflowX:'scroll', overflowX:'hidden'}}>
      {review && <div onMouseOver={() => setHiddenAlert(true)} onMouseLeave={() => setHiddenAlert(false)} className='w-full h-[2vmin] bg-[transparent] absolute top-0 left-0' style={{ zIndex: 99999999999999 }}>
        {hiddenAlert && <div
          style={{ transition: 'all 0.3s ease-in-out' }}
          onClick={enable}
          className='w-full h-full pt-[2vmin]'>
          <div className={`cursor-pointer hover:bg-[#0078D3A1] bg-[#0078d352] border-[.25vmin] border-[#fff] w-[fit-content] mx-auto rounded-full p-[2vmin]`}>
            <CloseIcon fill='#fff' height='3vmin' width='3vmin' />
          </div>
        </div>
        }
      </div> 
      }

      {
        <>
          {walksFor.main.value >= 1 && walksFor.mainApp.value >= 0 && <div style={{ width: '100%', margin: '0 auto' }} onClick={(e) => e.stopPropagation()}>
            <TopArea
            review={review}
            checkHeader={pageSettings.navbarProps.visibility}
            closePanel={closePanel}
            setClosePanel={setClosePanel}
              handleClick={handleClick}
              selectedItem={selectedItem}
              setSelectedItem={setSelectedItem}
              styleWrIcon={styleWrIcon}
              styleRMIcon={styleRMIcon} 
              />
          </div>}
         

          <div className={`${pageSettings.sidebar.position === 'right' ? 'right-0' : 'left-0'}`} style={{ height: '100%', position: "absolute", top: 0, width: 'fit-content' }}>
            <div className="relative flex flex-row justify-between items-center h-full" >

              {walksFor.main.value >= 1 && walksFor.mainApp.value >= 1 && !pageSettings.sidebar.openWay && (pageSettings.sidebar.visibility || widgets) && !menuHover && (
                <div
                style={{zIndex:999999}}
                  className={`absolute text-[#0867FC] ${pageSettings?.sidebar?.position === 'right' ? 'left-[-5vmin]' : 'right-[-5vmin]'} h-full w-[fit-content] flex items-center`}
                  onMouseMove={(e) => {
                    e.stopPropagation();
                    setMenuHover(true);
                  }}
                >
                  {pageSettings.sidebar.position === 'right' ? (
                    <SidesIcon width='fit-content' height='6vmin' fill={appSettings.color.value || '#0867FC'}/>
                  ) : (
                    <SidesIcon width='fit-content' height='6vmin' fill={appSettings.color.value || '#0867FC'}/>
                  )}
                </div>
              )}

              {walksFor.main.value >= 1 && walksFor.mainApp.value >= 1 ? (
                <div style={{ zIndex: 999999999 }} 
                className={`h-[97%] items-center flex 
                ${pageSettings.sidebar.position === 'right' ? 
                `absolute ${!review ? 'right-[.5vmin]' : 'right-0'}`
                : `absolute ${!review ? 'left-[.5vmin]' : 'left-0'}`}`
                }>
                  <SidesLR
                  review={review}
                  selectedItem={selectedItem}
                  menuHover={menuHover}
                  setMenuHover={setMenuHover}
                  handleClick={handleClick}
                  closePanel={closePanel}
                  setClosePanel={setClosePanel}
                  open={open} setOpen={setOpen}
                  checkLeftSide={pageSettings.sidebar.visibility} 
                  />
                </div>
              ) : null}

            </div>
          </div>
          <div className='w-full flex items-center justify-center h-[100%]' style={{ zIndex:9999, alignItems:'center', alignContent:'center'}}>
          <BackgroundBanner appSettings={appSettings}/>
        </div>

          {/* Bottom Area */}
          {walksFor.mainApp.value < 2 ?
            null : walksFor.main.value >= 1 &&
            (<div style={{ width: '100%', margin: '0 auto', zIndex:9999999 }} onClick={(e) => e.stopPropagation()}>
              <Footer
              review={review}
                closePanel={closePanel}
                setClosePanel={setClosePanel}
                open={open}
                setOpen={setOpen}
                handleClick={handleClick}
                selectedItem={selectedItem}
                setSelectedItem={setSelectedItem}
                styleWrIcon={styleWrIcon}
                styleRMIcon={styleRMIcon}
                checkFooter={pageSettings.bottomSide.visibility}
                // setFooter={setPageSettings} 
                />
            </div>)}
        </>
      }

    </div>
  );
}
