/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
    AppBar,
    AppBarSection,
    AppBarSpacer,
    Avatar,
} from "@progress/kendo-react-layout";
import { Badge, BadgeContainer } from "@progress/kendo-react-indicators";
import { bellIcon, menuIcon } from "@progress/kendo-svg-icons";
import { Element, useEditor, useNode } from '@craftjs/core';
import { Parent } from '../../selectors/Parent/index.js'
import { Wrapper } from '../../selectors/wrapper/index.js';
import { Text } from '../../selectors/basic/Text/index.js'

import { New_Header } from './index.js';
import { Widget_Nav } from '../../editor/Toolbar/Toolbar-Types/Widget.js';
import navbarPropsVar from './NavBarListProps.js';
import { SvgIcon } from '../../selectors/basic/Svg/index.js';
import TheMenu from './TheMenu.js';
import SidebarContext from '../../../SidebarShown/SidebarContext.js';
import AskHeader from './PopupAsk.js';

export const NewHeaderNav = (props) => {

    const {
        navbarProps = navbarPropsVar,
        background,
        color,
        shadow,
        children,
        style = {},
        className,
        width,
        height,
        minWidth,
        flex,
        radius,
        maxWidth,
        border,
        flexDirection,
        paddingTop,
        paddingRight,
        paddingBottom,
        paddingLeft,
        marginTop,
        marginRight,
        marginBottom,
        marginLeft,
        onClick,
        justifyContent,
        justifyItems,
        alignItems,
        alignContent,
        isNavBar,
        subMenu,
        id,
        displayName,
        actionState = 'Default',
        ...otherProps
    } = props;



    const handleProperty = (value, propertyName, split) => {
        if (value !== null && !split) {
            return { [propertyName]: value?.split(" ").join("") }
        } else if (value !== null && split) {
            return { [propertyName]: split === 'splitBg' ? value : '' }
        }
    };

    const styleProps = {
        ...handleProperty(background, 'background', 'splitBg'),
        ...handleProperty(color, 'color', 'splitBg'),

        ...handleProperty(width, 'width'),
        ...handleProperty(height, 'height'),
        ...handleProperty(minWidth, 'minWidth'),
        ...handleProperty(shadow, 'boxShadow'),
        ...handleProperty(flex, 'display'),
        ...handleProperty(radius, 'borderRadius'),
        ...handleProperty(maxWidth, 'maxWidth'),
        ...handleProperty(flexDirection, 'flexDirection'),
        ...handleProperty(paddingTop, 'paddingTop'),
        ...handleProperty(paddingRight, 'paddingRight'),
        ...handleProperty(paddingBottom, 'paddingBottom'),
        ...handleProperty(paddingLeft, 'paddingLeft'),

        ...handleProperty(marginTop, 'marginTop'),
        ...handleProperty(marginRight, 'marginRight'),
        ...handleProperty(marginBottom, 'marginBottom'),
        ...handleProperty(marginLeft, 'marginLeft'),

        ...handleProperty(justifyContent, 'justifyContent'),
        ...handleProperty(justifyItems, 'justifyItems'),
        ...handleProperty(alignContent, 'alignContent'),
        ...handleProperty(alignItems, 'alignItems'),
    };


    let kendokaAvatar =
        "https://www.telerik.com/kendo-react-ui-develop/components/images/kendoka-react.png";

    let logoIcon =
        "M16.5 4.5C16.5 5.17031 16.3547 5.80781 16.0875 6.38437L18.5625 10.6594C17.4516 11.8453 16.0219 12.7266 14.4141 13.1719L12 9L8.8125 14.5078C9.82031 14.8266 10.8891 15 12.0047 15C15.3188 15 18.2766 13.4672 20.2031 11.0625C20.7234 10.4156 21.6656 10.3125 22.3125 10.8281C22.9594 11.3438 23.0625 12.2906 22.5469 12.9375C20.0672 16.0219 16.2656 18 12 18C10.3406 18 8.74688 17.7 7.27969 17.1516L4.62656 21.7359C4.40625 22.1156 4.07812 22.425 3.68438 22.6219L1.0875 23.9203C0.853125 24.0375 0.576563 24.0234 0.35625 23.8875C0.135938 23.7516 0 23.5078 0 23.25V20.6531C0 20.2594 0.103125 19.8703 0.304688 19.5234L3.11719 14.6625C2.51719 14.1375 1.95938 13.5609 1.4625 12.9375C0.942188 12.2906 1.05 11.3484 1.69688 10.8281C2.34375 10.3078 3.28594 10.4156 3.80625 11.0625C4.07344 11.3953 4.35938 11.7094 4.65938 12.0047L7.9125 6.38437C7.65 5.8125 7.5 5.175 7.5 4.5C7.5 2.01562 9.51563 0 12 0C14.4844 0 16.5 2.01562 16.5 4.5ZM17.4844 18.4641C19.0125 17.8641 20.4141 17.0203 21.6516 15.9844L23.7 19.5234C23.8969 19.8656 24.0047 20.2547 24.0047 20.6531V23.25C24.0047 23.5078 23.8688 23.7516 23.6484 23.8875C23.4281 24.0234 23.1516 24.0375 22.9172 23.9203L20.3203 22.6219C19.9266 22.425 19.5984 22.1156 19.3781 21.7359L17.4844 18.4641ZM12 6C12.3978 6 12.7794 5.84196 13.0607 5.56066C13.342 5.27936 13.5 4.89782 13.5 4.5C13.5 4.10218 13.342 3.72064 13.0607 3.43934C12.7794 3.15804 12.3978 3 12 3C11.6022 3 11.2206 3.15804 10.9393 3.43934C10.658 3.72064 10.5 4.10218 10.5 4.5C10.5 4.89782 10.658 5.27936 10.9393 5.56066C11.2206 5.84196 11.6022 6 12 6Z'"

    const {
        enabled,
    } = useEditor((state, query) => ({
        enabled: state.options.enabled,

    }));

    const {
        actions: { setProp },
        connectors: {
            connect
        },
        node
    } = useNode((node) => ({
        node: node
    }));

    const [menu, setMenu] = useState(false)
    const [loginAuth, setLoginAuth] = useState(false)
    const [notifyList, setNotifyList] = useState(false)

    useEffect(() => {
        console.log(node)
    }, [node])

    const {setPopUp} = useContext(SidebarContext)

    useEffect(() => {
        if(menu) {
            if(!menu) {
                setMenu(true)
            } else {
                return;
            }
        } 
    }, [menu, setMenu])

    useEffect(() => {
        if(loginAuth) {
            if(!loginAuth) {
                setLoginAuth(true)
            } else {
                return;
            }
        } 
    }, [loginAuth, setLoginAuth])

    useEffect(() => {
        if(notifyList) {
            if(!notifyList) {
                setNotifyList(true)
            } else {
                return;
            }
        } 
    }, [notifyList, setNotifyList])

    useEffect(() => {
        setPopUp(true)
    }, [])

    const flexProps = {
        justifyContent: justifyContent !== null ? justifyContent : subMenu? "start" : "center",
        alignItems: alignItems ? alignItems : subMenu? "start" : "center",
        justifyItems: justifyItems,
        alignContent: alignContent,
    }


    return (
        <div onClick={() => {
            setNotifyList(false)
            setLoginAuth(false)
            setMenu(false)
        }}
            style={{...flexProps, padding: enabled ? subMenu ? '4px' : "10px" : "0px", zIndex: 1, top: enabled ? subMenu ? 0 : 22 : 0, left: 0, right: 0, position: 'absolute', display: "flex", width: '100%' , flexWrap:'nowrap'}} ref={connect}>
            <New_Header 
                subMenu={subMenu}
                menu={menu}
                setMenu={setMenu}
                loginAuth={loginAuth}
                setLoginAuth={setLoginAuth}
                notifyList={notifyList}
                setNotifyList={setNotifyList}
                enabled={enabled}
                kendokaAvatar={kendokaAvatar}
                logoIcon={logoIcon}
                className={className}
                navbarProps={navbarProps}
                background={background}
                color={color}
                radius={radius}
                styleProps={styleProps} />
        </div>
    );
};

NewHeaderNav.craft = {
    displayName: "New Header",
    props: {
        selected: 1, // here is the default value of the selected style
        navbarProps: navbarPropsVar,
        background: null,
        color:'#FFF',
        radius: null,
        shadow: null,
        width: null,
        height: null,
        minWidth: null,
        maxWidth: null,
        paddingTop: null,
        paddingRight: null,
        paddingBottom: null,
        paddingLeft: null,
        border: null,
        flex: null,
        justifyContent: null,
        justifyItems: null,
        alignItems: null,
        alignContent: null,
        flexDirection: null,
        isNavBar: true,
        subMenu : false,
        actionState: 'Default',
    },
    rules: {
        canDrag: () => true,
        canDrop: () => true,
    },
    // related: {
    //   toolbar: WidgetSettings,
    // },
};
