/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, { useEffect, useRef, useState } from 'react';

import { Element, useEditor, useNode } from '@craftjs/core';
import { NewSolutionSettings } from './newSolutionSetting';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import { Text } from '../../basic/Text';
import { ImageC } from '../../basic/Image';
import { Parent } from '../../Parent/index.js';
import { Wrapper } from '../../wrapper/index.js';
import { BounceLoader } from 'react-spinners';
import { SvgIcon } from '../../basic/Svg';
import './Solution.css'


export const Solution_1 = ({ selected }) => {
    const {
        actions: { setProp },
        connectors: {
            connect
        }
    } = useNode();


    const {
        enabled,
    } = useEditor((state, query) => ({
        enabled: state.options.enabled,

    }));

    let starIcon =
        `M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224`

    const styles = [
        <Element canvas is={Parent} id='parentId100' className="w-[100%] h-auto bg-white py-10" >
            <Element canvas is={Parent} id='parentId200' className="w-[100%] h-auto flex justify-center items-center" row={true} >
                <Element is={Parent} canvas id='randomm-id-401' className='max-w-[1300px]'>
                    <Element is={Parent} canvas style={{ display: "inline-block", fontFamily: 'Rubik', color: '#100103', fontWeight: '700', fontStyle: 'normal', textAlign: 'center', width: '100%' }} className={`my-4 text-[46px]`} id="random-id-1"  >
                        <Text text="Solutions." />
                    </Element>
                    <Element is={Parent} canvas id='randomm-id-402' className='h-full' style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', width: '100%' }}>
                        <Element is={Parent} canvas id='randomm-id-403' className='mx-auto solution-card' >
                            <Element is={Parent} canvas id='randomm-id-404' className='h-full justify-center flex flex-1' >
                                <SvgIcon width='60px' height='60px' fill='#000' viewBox='0 0 72 72' path={starIcon} />
                            </Element>
                            <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400px', fontFamily: 'Rubik', color: '#000', fontStyle: 'normal' }} className='h-full flex-1' id="random-id-2"  >
                                <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='randomm-id-405' className='mx-auto solution-card'  >
                            <Element is={Parent} canvas id='randomm-id-406' className='h-full justify-center flex flex-1' >
                                <SvgIcon width='60px' height='60px' fill='#000' viewBox='0 0 72 72' path={starIcon} />
                            </Element>
                            <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400px', fontFamily: 'Rubik', color: '#000', fontStyle: 'normal' }} className='h-full flex-1' id="random-id-3"  >
                                <Text text="Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo." />
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='randomm-id-407' className='mx-auto solution-card'  >
                            <Element is={Parent} canvas id='randomm-id-408' className='h-full justify-center flex flex-1' >
                                <SvgIcon width='60px' height='60px' fill='#000' viewBox='0 0 72 72' path={starIcon} />
                            </Element>
                            <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400px', fontFamily: 'Rubik', color: '#000', fontStyle: 'normal' }} className='h-full flex-1' id="random-id-4"  >
                                <Text text="Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur." />
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='randomm-id-409' className='mx-auto solution-card'  >
                            <Element is={Parent} canvas id='randomm-id-410' className='h-full justify-center flex flex-1' >
                                <SvgIcon width='60px' height='60px' fill='#000' viewBox='0 0 72 72' path={starIcon} />
                            </Element>
                            <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400px', fontFamily: 'Rubik', color: '#000', fontStyle: 'normal' }} className='h-full flex-1' id="random-id-5"  >
                                <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='randomm-id-411' className='mx-auto solution-card'  >
                            <Element is={Parent} canvas id='randomm-id-412' className='h-full justify-center flex flex-1' >
                                <SvgIcon width='60px' height='60px' fill='#000' viewBox='0 0 72 72' path={starIcon} />
                            </Element>
                            <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400px', fontFamily: 'Rubik', color: '#000', fontStyle: 'normal' }} className='h-full flex-1' id="random-id-6"  >
                                <Text text="Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo." />
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='randomm-id-413' className='mx-auto solution-card'  >
                            <Element is={Parent} canvas id='randomm-id-414' className='h-full justify-center flex flex-1' >
                                <SvgIcon width='60px' height='60px' fill='#000' viewBox='0 0 72 72' path={starIcon} />
                            </Element>
                            <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400px', fontFamily: 'Rubik', color: '#000', fontStyle: 'normal' }} className='h-full flex-1' id="random-id-7"  >
                                <Text text="Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur." />
                            </Element>
                        </Element>
                    </Element>
                </Element></Element>
        </Element>,


        <Element canvas is={Parent} id='parentId300' className="w-[100%] h-auto bg-[#121826] py-10" >
            <Element canvas is={Parent} id='parentId400' className="w-[100%] h-auto flex justify-center items-center" row={true} >
                <Element is={Parent} canvas id='randomm-id-416' className='max-w-[1300px]'>
                    <Element is={Parent} canvas style={{ display: "inline-block", fontFamily: 'Rubik', fontWeight: '700', fontStyle: 'normal', textAlign: 'center', width: '100%' }} className={` IncreaseConversion2 my-4 text-[46px]`} id="random-id-8"  >
                        <Text text="Solutions." />
                    </Element>
                    <Element is={Parent} canvas id='randomm-id-417' className='h-full' style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', width: '100%' }}>
                        <Element is={Parent} canvas id='randomm-id-418' className='mx-auto solution-card' >
                            <Element is={Parent} canvas id='randomm-id-419' className='h-full justify-center flex flex-1'>
                                <SvgIcon width='60px' height='60px' fill='#3265E3' viewBox='0 0 72 72' path={starIcon} />
                            </Element>
                            <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400px', fontFamily: 'Rubik', color: '#FFF', fontStyle: 'normal' }} className='h-full flex-1' id="random-id-9"  >
                                <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='randomm-id-420' className='mx-auto solution-card'  >
                            <Element is={Parent} canvas id='randomm-id-421' className='h-full justify-center flex flex-1'>
                                <SvgIcon width='60px' height='60px' fill='#3265E3' viewBox='0 0 72 72' path={starIcon} />
                            </Element>
                            <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400px', fontFamily: 'Rubik', color: '#FFF', fontStyle: 'normal' }} className='h-full flex-1' id="random-id-10"  >
                                <Text text="Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo." />
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='randomm-id-422' className='mx-auto solution-card'  >
                            <Element is={Parent} canvas id='randomm-id-423' className='h-full justify-center flex flex-1'>
                                <SvgIcon width='60px' height='60px' fill='#3265E3' viewBox='0 0 72 72' path={starIcon} />
                            </Element>
                            <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400px', fontFamily: 'Rubik', color: '#FFF', fontStyle: 'normal' }} className='h-full flex-1' id="random-id-11"  >
                                <Text text="Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur." />
                            </Element>

                        </Element>
                        <Element is={Parent} canvas id='randomm-id-424' className='mx-auto solution-card'  >
                            <Element is={Parent} canvas id='randomm-id-425' className='h-full justify-center flex flex-1'>
                                <SvgIcon width='60px' height='60px' fill='#3265E3' viewBox='0 0 72 72' path={starIcon} />
                            </Element>
                            <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400px', fontFamily: 'Rubik', color: '#FFF', fontStyle: 'normal' }} className='h-full flex-1' id="random-id-12"  >
                                <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='randomm-id-426' className='mx-auto solution-card'  >
                            <Element is={Parent} canvas id='randomm-id-427' className='h-full justify-center flex flex-1'>
                                <SvgIcon width='60px' height='60px' fill='#3265E3' viewBox='0 0 72 72' path={starIcon} />
                            </Element>
                            <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400px', fontFamily: 'Rubik', color: '#FFF', fontStyle: 'normal' }} className='h-full flex-1' id="random-id-13"  >
                                <Text text="Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo." />
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='randomm-id-428' className='mx-auto solution-card'  >
                            <Element is={Parent} canvas id='randomm-id-429' className='h-full justify-center flex flex-1'>
                                <SvgIcon width='60px' height='60px' fill='#3265E3' viewBox='0 0 72 72' path={starIcon} />
                            </Element>
                            <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400px', fontFamily: 'Rubik', color: '#FFF', fontStyle: 'normal' }} className='h-full flex-1' id="random-id-14"  >
                                <Text text="Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur." />
                            </Element>
                        </Element>
                    </Element>
                </Element></Element>
        </Element>,



        <Element canvas is={Parent} id='parentId500' className="w-[100%] h-auto bg-[#213156] py-10" >
            <Element canvas is={Parent} id='parentId600' className="w-[100%] h-auto flex justify-center items-center" row={true} >
                <Element is={Parent} canvas id='randomm-id-431' className='max-w-[1300px]'>
                    <Element is={Parent} canvas style={{ display: "inline-block", fontFamily: 'Yeseva One', fontWeight: '700', fontStyle: 'normal', textAlign: 'center', width: '100%', color: '#EA6EF4' }} className={`my-4 text-[46px]`} id="random-id-15"  >
                        <Text text="Solutions" />
                    </Element>
                    <Element is={Parent} canvas id='randomm-id-432' className='h-full ' style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', width: '100%' }}>
                        <Element is={Parent} canvas id='randomm-id-433' className='mx-auto solution-card' >
                            <Element is={Parent} canvas id='randomm-id-434' className='h-full justify-center flex flex-1' >
                                <SvgIcon width='60px' height='60px' fill='#EA6EF4' viewBox='0 0 72 72' path={starIcon} />

                            </Element>
                            <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400px', fontFamily: 'Open Sans', color: '#FFF', fontStyle: 'normal' }} className='h-full flex-1' id="random-id-16"  >
                                <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='randomm-id-435' className='mx-auto solution-card'  >
                            <Element is={Parent} canvas id='randomm-id-436' className='h-full justify-center flex flex-1' >
                                <SvgIcon width='60px' height='60px' fill='#EA6EF4' viewBox='0 0 72 72' path={starIcon} />

                            </Element>
                            <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400px', fontFamily: 'Open Sans', color: '#FFF', fontStyle: 'normal' }} className='h-full flex-1' id="random-id-17"  >
                                <Text text="Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo." />
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='randomm-id-437' className='mx-auto solution-card'  >
                            <Element is={Parent} canvas id='randomm-id-438' className='h-full justify-center flex flex-1' >
                                <SvgIcon width='60px' height='60px' fill='#EA6EF4' viewBox='0 0 72 72' path={starIcon} />

                            </Element>
                            <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400px', fontFamily: 'Open Sans', color: '#FFF', fontStyle: 'normal' }} className='h-full flex-1' id="random-id-18"  >
                                <Text text="Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur." />
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='randomm-id-439' className='mx-auto solution-card'  >
                            <Element is={Parent} canvas id='randomm-id-440' className='h-full justify-center flex flex-1' >
                                <SvgIcon width='60px' height='60px' fill='#EA6EF4' viewBox='0 0 72 72' path={starIcon} />

                            </Element>
                            <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400px', fontFamily: 'Open Sans', color: '#FFF', fontStyle: 'normal' }} className='h-full flex-1' id="random-id-19"  >
                                <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='randomm-id-441' className='mx-auto solution-card'  >
                            <Element is={Parent} canvas id='randomm-id-442' className='h-full justify-center flex flex-1' >
                                <SvgIcon width='60px' height='60px' fill='#EA6EF4' viewBox='0 0 72 72' path={starIcon} />

                            </Element>
                            <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400px', fontFamily: 'Open Sans', color: '#FFF', fontStyle: 'normal' }} className='h-full flex-1' id="random-id-20"  >
                                <Text text="Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo." />
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='randomm-id-443' className='mx-auto solution-card'  >
                            <Element is={Parent} canvas id='randomm-id-444' className='h-full justify-center flex flex-1' >
                                <SvgIcon width='60px' height='60px' fill='#EA6EF4' viewBox='0 0 72 72' path={starIcon} />

                            </Element>
                            <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400px', fontFamily: 'Open Sans', color: '#FFF', fontStyle: 'normal' }} className='h-full flex-1' id="random-id-21"  >
                                <Text text="Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur." />
                            </Element>
                        </Element>
                    </Element>
                </Element></Element>
        </Element>,



        <Element canvas is={Parent} id='parentId700' className="w-[100%] h-auto bg-[#DFFFF9] py-10" >
            <Element canvas is={Parent} id='parentId800' className="w-[100%] h-auto flex justify-center items-center" row={true} >
                <Element is={Parent} canvas id='randomm-id-446' className='max-w-[1300px]'>
                    <Element is={Parent} canvas style={{ display: "inline-block", fontFamily: 'Zing Script Rust SemiBold Demo', fontWeight: '700', fontStyle: 'normal', textAlign: 'center', width: '100%', color: '#31A993' }} className={`my-4 text-[46px]`} id="random-id-22"  >
                        <Text text="Solutions" />
                    </Element>
                    <Element is={Parent} canvas id='randomm-id-447' className='h-full ' style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', width: '100%' }}>
                        <Element is={Parent} canvas id='randomm-id-448' className='mx-auto solution-card' >
                            <Element is={Parent} canvas id='randomm-id-449' className='h-full justify-center flex flex-1' >
                                <SvgIcon width='60px' height='60px' fill='#31A993' viewBox='0 0 72 72' path={starIcon} />

                            </Element>
                            <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400px', fontFamily: 'Montserrat', color: '#031815', fontStyle: 'normal' }} className='h-full flex-1' id="random-id-23"  >
                                <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='randomm-id-450' className='mx-auto solution-card'  >
                            <Element is={Parent} canvas id='randomm-id-451' className='h-full justify-center flex flex-1' >
                                <SvgIcon width='60px' height='60px' fill='#31A993' viewBox='0 0 72 72' path={starIcon} />

                            </Element>
                            <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400px', fontFamily: 'Montserrat', color: '#031815', fontStyle: 'normal' }} className='h-full flex-1' id="random-id-24"  >
                                <Text text="Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo." />
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='randomm-id-452' className='mx-auto solution-card'  >
                            <Element is={Parent} canvas id='randomm-id-453' className='h-full justify-center flex flex-1' >
                                <SvgIcon width='60px' height='60px' fill='#31A993' viewBox='0 0 72 72' path={starIcon} />

                            </Element>
                            <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400px', fontFamily: 'Montserrat', color: '#031815', fontStyle: 'normal' }} className='h-full flex-1' id="random-id-25"  >
                                <Text text="Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur." />
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='randomm-id-454' className='mx-auto solution-card'  >
                            <Element is={Parent} canvas id='randomm-id-455' className='h-full justify-center flex flex-1'   >
                                <SvgIcon width='60px' height='60px' fill='#31A993' viewBox='0 0 72 72' path={starIcon} />

                            </Element>
                            <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400px', fontFamily: 'Montserrat', color: '#031815', fontStyle: 'normal' }} className='h-full flex-1' id="random-id-26"  >
                                <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='randomm-id-456' className='mx-auto solution-card'  >
                            <Element is={Parent} canvas id='randomm-id-457' className='h-full justify-center flex flex-1'   >
                                <SvgIcon width='60px' height='60px' fill='#31A993' viewBox='0 0 72 72' path={starIcon} />

                            </Element>
                            <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400px', fontFamily: 'Montserrat', color: '#031815', fontStyle: 'normal' }} className='h-full flex-1' id="random-id-27"  >
                                <Text text="Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo." />
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='randomm-id-458' className='mx-auto solution-card'  >
                            <Element is={Parent} canvas id='randomm-id-459' className='h-full justify-center flex flex-1'   >
                                <SvgIcon width='60px' height='60px' fill='#31A993' viewBox='0 0 72 72' path={starIcon} />

                            </Element>
                            <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400px', fontFamily: 'Montserrat', color: '#031815', fontStyle: 'normal' }} className='h-full flex-1' id="random-id-28"  >
                                <Text text="Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur." />
                            </Element>
                        </Element>
                    </Element>
                </Element></Element>
        </Element>,



        <Element canvas is={Parent} id='parentId900' className="w-[100%] h-auto py-10 bg-[#FFF]" >
            <Element canvas is={Parent} id='parentId1000' className="w-[100%] h-auto flex justify-center items-center" row={true} >
                <Element is={Parent} canvas id='randomm-id-461' className='max-w-[1300px]'>
                    <Element is={Parent} canvas style={{ display: "inline-block", fontFamily: 'Inter', fontWeight: '700', fontStyle: 'normal', textAlign: 'center', width: '100%', color: '#0078D3' }} className={`my-4 text-[46px]`} id="random-id-29"  >
                        <Text text="Solutions" />
                    </Element>
                    <Element is={Parent} canvas id='randomm-id-462' className='h-full ' style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', width: '100%' }}>
                        <Element is={Parent} canvas id='randomm-id-463' className='mx-auto solution-card' >
                            <Element is={Parent} canvas id='randomm-id-464' className='h-full justify-center flex flex-1'>
                                <SvgIcon width='60px' height='60px' fill='#0078D3' viewBox='0 0 72 72' path={starIcon} />

                            </Element>
                            <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400px', fontFamily: 'Inter', color: '#031815', fontStyle: 'normal' }} className='h-full flex-1' id="random-id-30"  >
                                <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-400' className='mx-auto solution-card'  >
                            <Element is={Parent} canvas id='random-id-400' className='h-full justify-center flex flex-1'>
                                <SvgIcon width='60px' height='60px' fill='#0078D3' viewBox='0 0 72 72' path={starIcon} />

                            </Element>
                            <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400px', fontFamily: 'Inter', color: '#031815', fontStyle: 'normal' }} className='h-full flex-1' id="random-id-31"  >
                                <Text text="Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo." />
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='randomm-id-465' v className='mx-auto solution-card'  >
                            <Element is={Parent} canvas id='randomm-id-466' className='h-full justify-center flex flex-1'>
                                <SvgIcon width='60px' height='60px' fill='#0078D3' viewBox='0 0 72 72' path={starIcon} />

                            </Element>
                            <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400px', fontFamily: 'Inter', color: '#031815', fontStyle: 'normal' }} className='h-full flex-1' id="random-id-32"  >
                                <Text text="Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur." />
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='randomm-id-467' className='mx-auto solution-card'  >
                            <Element is={Parent} canvas id='randomm-id-468' className='h-full justify-center flex flex-1'>
                                <SvgIcon width='60px' height='60px' fill='#0078D3' viewBox='0 0 72 72' path={starIcon} />

                            </Element>
                            <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400px', fontFamily: 'Inter', color: '#031815', fontStyle: 'normal' }} className='h-full flex-1' id="random-id-33"  >
                                <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='randomm-id-469' className='mx-auto solution-card'  >
                            <Element is={Parent} canvas id='randomm-id-470' className='h-full justify-center flex flex-1'>
                                <SvgIcon width='60px' height='60px' fill='#0078D3' viewBox='0 0 72 72' path={starIcon} />

                            </Element>
                            <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400px', fontFamily: 'Inter', color: '#031815', fontStyle: 'normal' }} className='h-full flex-1' id="random-id-34"  >
                                <Text text="Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo." />
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='randomm-id-471' className='mx-auto solution-card'  >
                            <Element is={Parent} canvas id='randomm-id-472' className='h-full justify-center flex flex-1'>
                                <SvgIcon width='60px' height='60px' fill='#0078D3' viewBox='0 0 72 72' path={starIcon} />

                            </Element>
                            <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400px', fontFamily: 'Inter', color: '#031815', fontStyle: 'normal' }} className='h-full flex-1' id="random-id-35"  >
                                <Text text="Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur." />
                            </Element>
                        </Element>
                    </Element>
                </Element></Element>
        </Element>,

        <Element canvas is={Parent} id='parentId1100' className="w-[100%] h-auto px-10 py-20" >
            <Element canvas is={Parent} id='parentId1200' className="w-[100%] h-auto flex justify-center items-center" row={true} >
                <Element is={Parent} canvas id='randomm-id-474' className='max-w-[1300px]' style={{ background: '#F8F8F8' }}>
                    <Element is={Parent} canvas style={{ display: "inline-block", fontFamily: 'Voces', fontWeight: '700', fontStyle: 'normal', textAlign: 'center', width: '100%', color: '#020212' }} className={` my-4 text-[46px] uppercase`} id="random-id-36"  >
                        <Text text="Solutions" />
                    </Element>
                    <Element is={Parent} canvas id='randomm-id-475' className='h-full ' style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', width: '100%' }}>
                        <Element is={Parent} canvas id='randomm-id-476' className='mx-auto solution-card' >
                            <Element is={Parent} canvas id='randomm-id-477' className='h-full justify-center flex flex-1'>
                                <SvgIcon width='60px' height='60px' fill='#020212' viewBox='0 0 72 72' path={starIcon} />

                            </Element>
                            <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400px', fontFamily: 'Voces', color: '#020212', fontStyle: 'normal' }} className='h-full flex-1' id="random-id-37"  >
                                <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='randomm-id-478' className='mx-auto solution-card'  >
                            <Element is={Parent} canvas id='randomm-id-479' className='h-full justify-center flex flex-1'>
                                <SvgIcon width='60px' height='60px' fill='#020212' viewBox='0 0 72 72' path={starIcon} />

                            </Element>
                            <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400px', fontFamily: 'Voces', color: '#020212', fontStyle: 'normal' }} className='h-full flex-1' id="random-id-38"  >
                                <Text text="Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo." />
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='randomm-id-480' className='mx-auto solution-card'  >
                            <Element is={Parent} canvas id='randomm-id-481' className='h-full justify-center flex flex-1'>
                                <SvgIcon width='60px' height='60px' fill='#020212' viewBox='0 0 72 72' path={starIcon} />

                            </Element>
                            <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400px', fontFamily: 'Voces', color: '#020212', fontStyle: 'normal' }} className='h-full flex-1' id="random-id-39"  >
                                <Text text="Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur." />
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='randomm-id-482' className='mx-auto solution-card'  >
                            <Element is={Parent} canvas id='randomm-id-483' className='h-full justify-center flex flex-1'>
                                <SvgIcon width='60px' height='60px' fill='#020212' viewBox='0 0 72 72' path={starIcon} />

                            </Element>
                            <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400px', fontFamily: 'Voces', color: '#020212', fontStyle: 'normal' }} className='h-full flex-1' id="random-id-40"  >
                                <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='randomm-id-484' className='mx-auto solution-card'  >
                            <Element is={Parent} canvas id='randomm-id-485' className='h-full justify-center flex flex-1'>
                                <SvgIcon width='60px' height='60px' fill='#020212' viewBox='0 0 72 72' path={starIcon} />

                            </Element>
                            <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400px', fontFamily: 'Voces', color: '#020212', fontStyle: 'normal' }} className='h-full flex-1' id="random-id-41"  >
                                <Text text="Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo." />
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='randomm-id-486' className='mx-auto solution-card'  >
                            <Element is={Parent} canvas id='randomm-id-487' className='h-full justify-center flex flex-1'>
                                <SvgIcon width='60px' height='60px' fill='#020212' viewBox='0 0 72 72' path={starIcon} />

                            </Element>
                            <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400px', fontFamily: 'Voces', color: '#020212', fontStyle: 'normal' }} className='h-full flex-1' id="random-id-42"  >
                                <Text text="Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur." />
                            </Element>
                        </Element>
                    </Element>
                </Element></Element>
        </Element>,



        <Element canvas is={Parent} id='parentId1300' className="w-[100%] h-auto py-10 bg-[#FFF]" >
            <Element canvas is={Parent} id='parentId1400' className="w-[100%] h-auto flex justify-center items-center" row={true} >
                <Element is={Parent} canvas id='randomm-id-489' className='max-w-[1300px]'>
                    <Element is={Parent} canvas style={{ display: "inline-block", fontFamily: 'Roboto', fontWeight: '700', fontStyle: 'normal', textAlign: 'center', width: '100%', color: '#6750A4' }} className={`my-4 text-[46px]`} id="random-id-29"  >
                        <Text text="Solutions" />
                    </Element>
                    <Element is={Parent} canvas id='randomm-id-490' className='h-full ' style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', width: '100%' }}>
                        <Element is={Parent} canvas id='randomm-id-491' className='mx-auto solution-card' >
                            <Element is={Parent} canvas id='randomm-id-492' className='h-full justify-center flex flex-1' >
                                <SvgIcon width='60px' height='60px' fill='#6750A4' viewBox='0 0 72 72' path={starIcon} />

                            </Element>
                            <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400px', fontFamily: 'Roboto', color: '#031815', fontStyle: 'normal' }} className='h-full flex-1' id="random-id-30"  >
                                <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='randomm-id-493' className='mx-auto solution-card'  >
                            <Element is={Parent} canvas id='randomm-id-494' className='h-full justify-center flex flex-1' >
                                <SvgIcon width='60px' height='60px' fill='#6750A4' viewBox='0 0 72 72' path={starIcon} />

                            </Element>
                            <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400px', fontFamily: 'Roboto', color: '#031815', fontStyle: 'normal' }} className='h-full flex-1' id="random-id-31"  >
                                <Text text="Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo." />
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='randomm-id-495' className='mx-auto solution-card'  >
                            <Element is={Parent} canvas id='randomm-id-496' className='h-full justify-center flex flex-1' >
                                <SvgIcon width='60px' height='60px' fill='#6750A4' viewBox='0 0 72 72' path={starIcon} />

                            </Element>
                            <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400px', fontFamily: 'Roboto', color: '#031815', fontStyle: 'normal' }} className='h-full flex-1' id="random-id-32"  >
                                <Text text="Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur." />
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='randomm-id-497' className='mx-auto solution-card'  >
                            <Element is={Parent} canvas id='randomm-id-498' className='h-full justify-center flex flex-1' >
                                <SvgIcon width='60px' height='60px' fill='#6750A4' viewBox='0 0 72 72' path={starIcon} />

                            </Element>
                            <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400px', fontFamily: 'Roboto', color: '#031815', fontStyle: 'normal' }} className='h-full flex-1' id="random-id-35"  >
                                <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='randomm-id-499' className='mx-auto solution-card'  >
                            <Element is={Parent} canvas id='randomm-id-500' className='h-full justify-center flex flex-1' >
                                <SvgIcon width='60px' height='60px' fill='#6750A4' viewBox='0 0 72 72' path={starIcon} />

                            </Element>
                            <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400px', fontFamily: 'Roboto', color: '#031815', fontStyle: 'normal' }} className='h-full flex-1' id="random-id-35"  >
                                <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='randomm-id-501' className='mx-auto solution-card'  >
                            <Element is={Parent} canvas id='randomm-id-502' className='h-full justify-center flex flex-1' >
                                <SvgIcon width='60px' height='60px' fill='#6750A4' viewBox='0 0 72 72' path={starIcon} />

                            </Element>
                            <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400px', fontFamily: 'Roboto', color: '#031815', fontStyle: 'normal' }} className='h-full flex-1' id="random-id-35"  >
                                <Text text="Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. " />
                            </Element>
                        </Element>
                    </Element>
                </Element></Element>
        </Element>,



        <Element canvas is={Parent} id='parentId1500' className="w-[100%] h-auto py-10 bg-[#2A2A2A]" >
            <Element canvas is={Parent} id='parentId1600' className="w-[100%] h-auto flex justify-center items-center" row={true} >
                <Element is={Parent} canvas id='randomm-id-504' className='max-w-[1300px]'>
                    <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '46px', fontFamily: 'Rum Raisin', fontWeight: '400px', fontStyle: 'normal', textAlign: 'center', width: '100%', color: '#FFD600' }} className={`my-4 text-[46px] mt-4 mb-6`} id="random-id-36"  >
                        <Text text="Solutions" />
                    </Element>
                    <Element is={Parent} canvas id='randomm-id-505' className='h-full ' style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', width: '100%' }}>
                        <Element is={Parent} canvas id='randomm-id-506' className='mx-auto solution-card' >
                            <Element is={Parent} canvas id='randomm-id-507' className='h-full justify-center flex flex-1' >
                                <SvgIcon width='60px' height='60px' fill='#FFD600' viewBox='0 0 72 72' path={starIcon} />

                            </Element>
                            <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400px', fontFamily: 'Rubik', color: '#FFFFFF', fontStyle: 'normal' }} className='h-full flex-1' id="random-id-37"  >
                                <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='randomm-id-508' className='mx-auto solution-card'  >
                            <Element is={Parent} canvas id='randomm-id-509' className='h-full justify-center flex flex-1' >
                                <SvgIcon width='60px' height='60px' fill='#FFD600' viewBox='0 0 72 72' path={starIcon} />

                            </Element>
                            <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400px', fontFamily: 'Rubik', color: '#FFFFFF', fontStyle: 'normal' }} className='h-full flex-1' id="random-id-38"  >
                                <Text text="Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo." />
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='randomm-id-510' className='mx-auto solution-card'  >
                            <Element is={Parent} canvas id='randomm-id-511' className='h-full justify-center flex flex-1' >
                                <SvgIcon width='60px' height='60px' fill='#FFD600' viewBox='0 0 72 72' path={starIcon} />

                            </Element>
                            <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400px', fontFamily: 'Rubik', color: '#FFFFFF', fontStyle: 'normal' }} className='h-full flex-1' id="random-id-39"  >
                                <Text text="Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur." />
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='randomm-id-512' className='mx-auto solution-card'  >
                            <Element is={Parent} canvas id='randomm-id-513' className='h-full justify-center flex flex-1' >
                                <SvgIcon width='60px' height='60px' fill='#FFD600' viewBox='0 0 72 72' path={starIcon} />

                            </Element>
                            <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400px', fontFamily: 'Rubik', color: '#FFFFFF', fontStyle: 'normal' }} className='h-full flex-1' id="random-id-40"  >
                                <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='randomm-id-514' className='mx-auto solution-card'  >
                            <Element is={Parent} canvas id='randomm-id-515' className='h-full justify-center flex flex-1' >
                                <SvgIcon width='60px' height='60px' fill='#FFD600' viewBox='0 0 72 72' path={starIcon} />

                            </Element>
                            <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400px', fontFamily: 'Rubik', color: '#FFFFFF', fontStyle: 'normal' }} className='h-full flex-1' id="random-id-41"  >
                                <Text text="Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo." />
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='randomm-id-516' className='mx-auto solution-card'  >
                            <Element is={Parent} canvas id='randomm-id-517' className='h-full justify-center flex flex-1' >
                                <SvgIcon width='60px' height='60px' fill='#FFD600' viewBox='0 0 72 72' path={starIcon} />

                            </Element>
                            <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400px', fontFamily: 'Rubik', color: '#FFFFFF', fontStyle: 'normal' }} className='h-full flex-1' id="random-id-42"  >
                                <Text text="Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur." />
                            </Element>
                        </Element>
                    </Element>
                </Element></Element>
        </Element>,


        <Element canvas is={Parent} id='parentId1700' className="w-[100%] h-auto bg-[#F7FBFE] py-10" >
            <Element canvas is={Parent} id='parentId1800' className="w-[100%] h-auto flex justify-center items-center" row={true} >
                <Element is={Parent} canvas id='randomm-id-901' className='max-w-[1300px]'>
                    <Element is={Parent} canvas style={{ display: "inline-block", fontFamily: 'Rubik', color: '#6064D2', fontWeight: '700', fontStyle: 'normal', textAlign: 'center', width: '100%' }} className={`my-4 text-[46px]`} id="random-id-1"  >
                        <Text text="Solutions." />
                    </Element>
                    <Element is={Parent} canvas id='randomm-id-902' className='h-full' style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', width: '100%' }}>
                        <Element is={Parent} canvas id='randomm-id-903' className='mx-auto solution-card' >
                            <Element is={Parent} canvas id='randomm-id-904' className='h-full justify-center flex flex-1' >
                                <SvgIcon width='60px' height='60px' fill='#B20312' viewBox='0 0 72 72' path={starIcon} />

                            </Element>
                            <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400px', fontFamily: 'Rubik', color: '#000', fontStyle: 'normal' }} className='h-full flex-1' id="random-id-2"  >
                                <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='randomm-id-905' className='mx-auto solution-card'  >
                            <Element is={Parent} canvas id='randomm-id-906' className='h-full justify-center flex flex-1' >
                                <SvgIcon width='60px' height='60px' fill='#B20312' viewBox='0 0 72 72' path={starIcon} />

                            </Element>
                            <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400px', fontFamily: 'Rubik', color: '#000', fontStyle: 'normal' }} className='h-full flex-1' id="random-id-3"  >
                                <Text text="Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo." />
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='randomm-id-907' className='mx-auto solution-card'  >
                            <Element is={Parent} canvas id='randomm-id-908' className='h-full justify-center flex flex-1' >
                                <SvgIcon width='60px' height='60px' fill='#B20312' viewBox='0 0 72 72' path={starIcon} />

                            </Element>
                            <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400px', fontFamily: 'Rubik', color: '#000', fontStyle: 'normal' }} className='h-full flex-1' id="random-id-4"  >
                                <Text text="Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur." />
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='randomm-id-909' className='mx-auto solution-card'  >
                            <Element is={Parent} canvas id='randomm-id-910' className='h-full justify-center flex flex-1' >
                                <SvgIcon width='60px' height='60px' fill='#B20312' viewBox='0 0 72 72' path={starIcon} />

                            </Element>
                            <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400px', fontFamily: 'Rubik', color: '#000', fontStyle: 'normal' }} className='h-full flex-1' id="random-id-5"  >
                                <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='randomm-id-911' className='mx-auto solution-card'  >
                            <Element is={Parent} canvas id='randomm-id-912' className='h-full justify-center flex flex-1' >
                                <SvgIcon width='60px' height='60px' fill='#B20312' viewBox='0 0 72 72' path={starIcon} />

                            </Element>
                            <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400px', fontFamily: 'Rubik', color: '#000', fontStyle: 'normal' }} className='h-full flex-1' id="random-id-6"  >
                                <Text text="Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo." />
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='randomm-id-913' className='mx-auto solution-card'  >
                            <Element is={Parent} canvas id='randomm-id-914' className='h-full justify-center flex flex-1' >
                                <SvgIcon width='60px' height='60px' fill='#B20312' viewBox='0 0 72 72' path={starIcon} />

                            </Element>
                            <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400px', fontFamily: 'Rubik', color: '#000', fontStyle: 'normal' }} className='h-full flex-1' id="random-id-7"  >
                                <Text text="Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur." />
                            </Element>
                        </Element>
                    </Element>
                </Element></Element>
        </Element>,


        <Element canvas is={Parent} id='parentId1900' className="w-[100%] h-auto  bg-[#FEFBF7] py-10" >
            <Element canvas is={Parent} id='parentId2000' className="w-[100%] h-auto flex justify-center items-center" row={true} >
                <Element is={Parent} canvas id='randomm-id-1001' className='max-w-[1300px]'>
                    <Element is={Parent} canvas style={{ display: "inline-block", fontFamily: 'Ruluko', color: '#A25738', fontWeight: '400', fontStyle: 'normal', textAlign: 'center', width: '100%' }} className={`my-4 text-[46px]`} id="random-id-1"  >
                        <Text text="Solutions." />
                    </Element>
                    <Element is={Parent} canvas id='randomm-id-1002' className='h-full' style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', width: '100%' }}>
                        <Element is={Parent} canvas id='randomm-id-1003' className='mx-auto solution-card' >
                            <Element is={Parent} canvas id='randomm-id-1004' className='h-full justify-center flex flex-1' >
                                <SvgIcon width='60px' height='60px' fill='#A25738' viewBox='0 0 72 72' path={starIcon} />

                            </Element>
                            <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400px', fontFamily: 'Sansation', color: '#000', fontStyle: 'normal' }} className='h-full flex-1' id="random-id-2"  >
                                <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='randomm-id-1005' className='mx-auto solution-card'  >
                            <Element is={Parent} canvas id='randomm-id-1006' className='h-full justify-center flex flex-1' >
                                <SvgIcon width='60px' height='60px' fill='#A25738' viewBox='0 0 72 72' path={starIcon} />

                            </Element>
                            <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400px', fontFamily: 'Sansation', color: '#000', fontStyle: 'normal' }} className='h-full flex-1' id="random-id-3"  >
                                <Text text="Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo." />
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='randomm-id-1007' className='mx-auto solution-card'  >
                            <Element is={Parent} canvas id='randomm-id-1008' className='h-full justify-center flex flex-1' >
                                <SvgIcon width='60px' height='60px' fill='#A25738' viewBox='0 0 72 72' path={starIcon} />

                            </Element>
                            <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400px', fontFamily: 'Sansation', color: '#000', fontStyle: 'normal' }} className='h-full flex-1' id="random-id-4"  >
                                <Text text="Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur." />
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='randomm-id-1009' className='mx-auto solution-card'  >
                            <Element is={Parent} canvas id='randomm-id-1010' className='h-full justify-center flex flex-1' >
                                <SvgIcon width='60px' height='60px' fill='#A25738' viewBox='0 0 72 72' path={starIcon} />

                            </Element>
                            <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400px', fontFamily: 'Sansation', color: '#000', fontStyle: 'normal' }} className='h-full flex-1' id="random-id-5"  >
                                <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='randomm-id-1011' className='mx-auto solution-card'  >
                            <Element is={Parent} canvas id='randomm-id-1012' className='h-full justify-center flex flex-1' >
                                <SvgIcon width='60px' height='60px' fill='#A25738' viewBox='0 0 72 72' path={starIcon} />

                            </Element>
                            <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400px', fontFamily: 'Sansation', color: '#000', fontStyle: 'normal' }} className='h-full flex-1' id="random-id-6"  >
                                <Text text="Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo." />
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='randomm-id-1013' className='mx-auto solution-card'  >
                            <Element is={Parent} canvas id='randomm-id-1014' className='h-full justify-center flex flex-1' >
                                <SvgIcon width='60px' height='60px' fill='#A65738' viewBox='0 0 72 72' path={starIcon} />

                            </Element>
                            <Element is={Parent} canvas style={{ display: "inline-block", fontSize: '18px', fontWeight: '400px', fontFamily: 'Sansation', color: '#000', fontStyle: 'normal' }} className='h-full flex-1' id="random-id-7"  >
                                <Text text="Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur." />
                            </Element>
                        </Element>
                    </Element>
                </Element></Element>
        </Element>,

    ]


    const [sel, setSel] = useState(selected)

    function makeOdd(number) {
        setProp((prop) => {
            setSel(100);
        }, 1000);

        setTimeout(() => {
            setProp((prop) => {
                setSel(number)
            }, 1000);
        }, 100);



    }

    useEffect(() => {
        if (selected || selected >= 0) {
            makeOdd(selected);
        }
    }, [selected])


    return (
        <div style={{ padding: enabled ? "10px" : "0px", display: "flex", justifyContent: "center", alignItems: "center", width: '100%', height: '100%' }} ref={connect}>
            {sel == 100 ? (
                <div style={{ height: "85vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <BounceLoader
                        size={100}
                        color={"white"}
                        loading={true}
                    />
                </div>
            ) : (
                styles[sel]
            )}
        </div>
    );
};

Solution_1.craft = {
    displayName: "Solution 1",
    props: {
        selected: 1, // here is the default value of the selected style
        length: 9,
        isBlock: true
    },
    rules: {
        canDrag: () => true,
        canDrop: () => true,
    },
    related: {
        toolbar: NewSolutionSettings,
    },
};


