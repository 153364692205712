/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, { useState } from 'react';
import { ToolbarSection, ToolbarItem } from '../../../editor';
import { ToolbarRadio } from '../../../editor/Toolbar/ToolbarRadio';
import AlignHorizontalLeftIcon from '@mui/icons-material/AlignHorizontalLeft';
import AlignHorizontalCenterIcon from '@mui/icons-material/AlignHorizontalCenter';
import AlignHorizontalRightIcon from '@mui/icons-material/AlignHorizontalRight';
import { TabOption } from '../../../editor/Viewport/Sidebar/TabOption';
import { useNode } from '@craftjs/core';
import Toggle from '../../../editor/Toolbar/Toggle';

export const TextAreaSettings = () => {
  const [activeTab, setActiveTab] = useState("Style Manager");


  return (
    <div className='flex flex-col component-settings' style={{ height:'100%'}}>
      <ToolbarItem
            full={true}
             type={null}
          />
      <div className='w-full'>
        <TabOption activeTab={activeTab} setActiveTab={setActiveTab} />
      </div>

<div style={{overflowY:'scroll', scrollbarWidth:'none', overflowX:'hidden',}}>
      {activeTab === "Advanced" ? (
        <ToolbarSection
          expanded={true}
          title="Actions"
        > 
           <ToolbarItem
            full={true}
            propKey="defaultValue"
            type="text"
            label="Default Value"
          />

        <ToolbarItem
            full={true}
            propKey="placeHolder"
            type="text"
            label="Place Holder"
          />

        <ToolbarItem
            full={true}
            propKey="readOnly"
            optionsDrop={['True', 'False']}

            type="select-drop"
            label="Read Only"
          />

        </ToolbarSection>
      ) : null}

      {activeTab === "Style Manager" ? (
        <div style={{ width: "100%" }}>

        <ToolbarSection
            expanded={true ? true : false}
            title="Size"
            props={['fontSize', 'fontWeight', 'textAlign']}
           
          >
            <ToolbarItem
              full={true}
              propKey="width"
              type="slider"
              unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
              label="Width"
            />

          <ToolbarItem
              full={true}
              propKey="height"
              type="slider"
              unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
              label="Height"
            />

           
          </ToolbarSection>
          <ToolbarSection
            expanded={true ? true : false}
            title="Appearance"
            props={['fontSize', 'fontWeight', 'textAlign']}
           
          >
            <ToolbarItem
              full={true}
              propKey="background"
              type="bg"
              label="Background"
            />
           
          </ToolbarSection>
            <ToolbarSection
            expanded={true ? true : false}
            title="Shadow"
            props={['fontSize', 'fontWeight', 'textAlign']}
           
          >
            <ToolbarItem
              full={true}
              propKey="shadowY"
              type="slider"
              unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
              label="Y offset"
            />

          <ToolbarItem
              full={true}
              propKey="shadowX"
              type="slider"
              unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
              label="X offset"
            />

            <ToolbarItem
              full={true}
              propKey="shadowBlur"
              type="slider"
              unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
              label="Blur"
            />

            <ToolbarItem
              full={true}
              propKey="shadowColor"
              type="color"
              label="Shadow Color"
            />


           
          </ToolbarSection>
         <ToolbarSection
            expanded={true ? true : false}
            title="Margin"
            props={['margin']}
            summary={({ }) => {
              return;
            }}
          >
            <ToolbarItem propKey="marginTop" 
            unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
            type="slider" label="Top" />
            <ToolbarItem propKey="marginRight"
            unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
            type="slider" label="Right" />
            <ToolbarItem propKey="marginBottom"
            unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
            type="slider" label="Bottom" />
            <ToolbarItem propKey="marginLeft"
            unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
            type="slider" label="Left" />
          </ToolbarSection>

          <ToolbarSection
          expanded={true}
          title="Border"
          props={['width', 'height', 'minWidth', 'maxWidth']}
          summary={({ width, height, minWidth, maxWidth }) => {
            return `Width: ${width || 'Auto'}, Height: ${height || 'Auto'}, Min Width: ${minWidth || 'None'}, Max Width: ${maxWidth || 'None'}`;
          }}
        >
          <ToolbarItem
            full={true}
            propKey="borderWidth"
            type="slider"
            max
            unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
            label="Border Width"
          />

            <ToolbarItem
            full={true}
            propKey="borderColor"
            type="color"
            label="Border Color"
          />
        </ToolbarSection>

        </div>
      ) : activeTab === "Content" ? (
        <div style={{ width: "100%" }}>
<ToolbarSection
          expanded={true ? true : false}

          title="Content"
        >

<ToolbarItem
              full={true}
              propKey="fontSize"
              type="slider"
              unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}

              label="Font Size"
            />
            <ToolbarItem
              full={true}
              propKey="lineHeight"
              type="slider"
            unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}

              label="Line Height"
            />
            <ToolbarItem
              full={true}
              propKey="color"
              type="bg"
              label="Color"
            />
            <ToolbarItem
              full={true}
              propKey="fontFamily"
              optionsDrop={['Rubik', 'Open Sans', 'Inter', 'Roboto', 'Voces', 'Poppins', 'Sansation', 'Prompt', 'Almarai', 'Chivo',]}
              type="select-drop"
              label="Font Family"
            />
            <ToolbarItem
              full={true}
              propKey="fontWeight"
              optionsDrop={['normal', 'bold', 'bolder', 'lighter']}
              type="select-drop"
              label="Font Weight"
            />
            <ToolbarItem
              full={true}
              propKey="textTransform"
              optionsDrop={['uppercase', 'lowercase', 'capitalize']}
              type="select-drop"
              label="Text Transform"
            />
            <ToolbarItem
              full={true}
              propKey="textDecoration"
              optionsDrop={['underline', 'overline', 'line-through', 'blink']}
              type="select-drop"
              label="Text Decoration"
            />
            <ToolbarItem
              full={true}
              propKey="textAlign"
              optionsDrop={['left', 'center', 'right']}
              type="select-drop"
              label="Text Align"
            />
          
        </ToolbarSection>

      </div>
      ): null}
      </div>
    </div>
  );
};
