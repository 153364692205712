/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, { useState } from 'react';
import { ToolbarItem, ToolbarSection } from '../components/editor'
import { TabOption } from '../components/editor/Viewport/Sidebar/TabOption';
import { weightDescription } from '../components/selectors/basic/Text/text';
import { capitalize } from '@mui/material';
import { imagesArr } from '../components/editor/Viewport/Pop-up/ImageArray';
import { category, imgsCate } from '../components/selectors/basic/Image/ImageSettings';
import SelectionBox from '../components/editor/Viewport/Pop-up/PopUp';
import { useActiveIdContext } from '../ActiveIdContext';
import { useDarkMode } from '../DarkModeContext';

export const LoginSettings = () => {
  const {isDarkMode} = useDarkMode()
  const { state, dispatch: setActiveId } = useActiveIdContext();
  const { activeId: activeId } = state;

  const [activeTab, setActiveTab] = useState("Style Manager");
  const [iconsSquare, setIconsSquare] = useState(false)
    const [images, setImages] = useState(imagesArr)
    const [selectedIcon, setSelectedIcon] = useState(null);
    const [selectImage, setSelectImage] = useState(null);

    const [imgsCategory, setImgsCategory] = useState(imgsCate)
    const [imageSelect, setImageSelect] = useState(false)
    const [iconSelect, setIconSelect] = useState(false)

    const handleSelect = (selected) => {
      if (selected === 'image') { 
        setIconSelect(false)
        setImageSelect(true)
      } else if (selected === 'icon') {
        setIconSelect(true)
        setImageSelect(false)
      } else {
        setIconSelect(false)
        setImageSelect(false)
      }
    }



    const handleIconIconSelect = (selectedUrl) => {
        if(Array.isArray(selectedUrl) && selectedUrl.length) {
          setSelectedIcon(selectedUrl[0]);
          console.log(selectedUrl)

        } else {
          setSelectedIcon(selectedUrl)
          console.log(selectedUrl)
        }
        setIconsSquare(false);
      };

      const handleIconImageSelect = (selectedUrl) => {
        if(Array.isArray(selectedUrl) && selectedUrl.length) {
          setImageSelect(selectedUrl[0]);
          console.log(selectedUrl)

        } else {
          setImageSelect(selectedUrl)
          console.log(selectedUrl)
        }
        setIconsSquare(false);
      };




  return (
    <div className='flex flex-col component-settings' style={{ height: '100%'}}>
      <ToolbarItem
        full={true}
        type="properties"
      />

      <div className='w-full'>
        <TabOption activeTab={activeTab} setActiveTab={setActiveTab} />
      </div>
      <div style={{ overflowY: 'scroll', scrollbarWidth: 'none', overflowX: 'hidden', height: '100%' }}>
        {activeTab === "Content" ? (
          <>
            <ToolbarItem
              full={true}
              loginPropKey="loginContents"
              LogoutPropKey="logoutContents"
              RegisterPropKey="registerContents"
              RegisterUserPropKey="registerUserContents"
              ErrorMessagePropKey="errorMessageContents"
              ResetPasswordPropKey="resetPasswordContents"
              VerifyEmailPropKey="verifyEmailContents"
              LoginVerifyUserCodePropKey="loginVerifyUserCodeContents"
              LoginAuthGrantPropKey="loginAuthGrantContents"
              LoginOtpPropKey="loginOtpContents"
              LoginUsernamePropKey="loginUsernamecontents"
              LoginPasswordPropKey="loginPasswordContents"

              LoginUpdatePasswordContnetsPropKey="loginUpdatePasswordContnets"
              LoginLinkConfirmPropKey="loginLinkConfirm"
              LoginpageExpiredContentsPropKey="loginpageExpiredContents"
              LoginConfigTotpContentsPropKey="loginConfigTotpContents"
              LoginUpdateProfileContentsPropKey="loginUpdateProfileContents"

              UpdateUserProfilePropKey="updateUserProfile"
              ReviewUserProfilePropKey="reviewUserProfile"
              UpdateEmailContentsPropKey="updateEmailContents"

              type="auth"
              label="Text"
            />
          </>
        ) : null}

        {activeTab === "Advanced" ? (
          <>
            <ToolbarSection
              expanded={false}
              title="Flex layout"
              props={['background', 'radius']}
              summary={({ background, radius }) => {

                return;
              }}
            >
              <ToolbarItem
                full={true}
                propKey="flex"
                optionsDrop={['flex', 'block', 'none', 'inline-block', 'inline-flex']}
                type="select-drop"
                label="Display"
              />
              <ToolbarItem
                full={true}
                propKey="flexDirection"
                optionsDrop={['row', 'column']}
                type="select-drop"
                label="Direction"
              />

              <ToolbarItem
                full={true}
                propKey="justifyContent"
                optionsDrop={['start', 'center', 'end', 'space-between', 'space-around', 'flex-start', 'flex-end']}
                type="select-drop"
                label="Justify content"
              />

              <ToolbarItem
                full={true}
                propKey="justifyItems"
                optionsDrop={['start', 'center', 'end', 'stretch', 'flex-start', 'flex-end', 'self-start', 'self-end']}
                type="select-drop"
                label="Justify Items"
              />

              <ToolbarItem
                full={true}
                propKey="alignContent"
                optionsDrop={['start', 'center', 'end', 'space-between', 'space-around', 'flex-start', 'flex-end']}
                type="select-drop"
                label="Align Content"
              />

              <ToolbarItem
                full={true}
                propKey="alignItems"
                optionsDrop={['start', 'center', 'end', 'stretch', 'flex-start', 'flex-end', 'self-start', 'self-end']}
                type="select-drop"
                label="Align Items"
              />
            </ToolbarSection>
            <ToolbarSection title="Transform" expanded={false} >
              {/* <ToolbarItem
                 full={true}
                 optionsDrop={['No Action','scroll', 'hover']}
                 propKey="actionSelect"
                 type="select-drop"
                 label="Action"
               /> */}
              <ToolbarItem
                full={true}
                selectBoxes={['rotateXYZ', 'translate', 'scale', 'skew']}
                propKey="transformStyle"
                propKey2="positionsRotate"
                propKey3="positionsTranslate"
                propKey4="positionsScale"
                propKey5="positionsRotateXYZ"
                propKey6="positionsSkew"
                propKey7="positionsOrigin"
                type="select-box"
                label="Transform Style"
              />
            </ToolbarSection>
            <ToolbarSection
              expanded={false}
              title="Shapes"
              props={[]}
              summary={() => {

                return (
                  <div className="flex flex-row-reverse">

                  </div>
                );
              }}
            >

              {/* <ToolbarItem
        full={true}
        propKey="top"
        type="Switch"
        /> */}

              <ToolbarItem
                full={true}
                optionsDrop={['Curve', 'Triangle', 'Wave', 'Waves Opacity', 'Triangle Asymmetrical', 'Tilt', 'Arrow', 'Split', 'Book']}
                propKey="shapeStyle"
                type="select-drop"
                label="Shape Style"
              />

              <ToolbarItem
                full={true}
                propKey="shapeHeight"
                type="numSlider"
                label="Shape Height"
              />
              <ToolbarItem
                full={true}
                propKey="shapeWidth"
                type="numSlider"
                label="Shape Width"
              />

              <ToolbarItem
                full={true}
                optionsDrop={['Top', 'Bottom']}
                propKey="shapePos"
                type="select-drop"
                label="Shape Position"
              />

              <ToolbarItem
                full={true}
                optionsDrop={['Underneath Section', 'On Top The Section']}
                propKey="shapeArrangement"
                type="select-drop"
                label="Shape Arrangement"
              />

              <ToolbarItem
                full={true}
                propKey="shapeColor"
                type="bg"
                label="Shape Color"
              />

            </ToolbarSection>
            <ToolbarSection
              expanded={false}
              title="Transition"
            >
              {/* <ToolbarItem
          full={true}
          optionsDrop={['all', 'transform', 'box-shadow', 'color', 'background-color', 'border-color', 'fill', 'filter', '']}
          propKey="transitionFor"
          type="select-drop"
          label="Transition Property"
        /> */}
              <ToolbarItem
                full={true}
                propKey="transitionDuration"
                type="numSlider"
                label="Transition Duration"
              />

              <ToolbarItem
                full={true}
                optionsDrop={['ease-linear', 'ease-in', 'ease-out', 'ease-in-out']}
                propKey="transitionTime"
                type="select-drop"
                label="Transition Timing"
              />

              <ToolbarItem
                full={true}
                propKey="transitionDelay"
                type="numSlider"
                label="Transition Delay"
              />


            </ToolbarSection>

          </>
        ) : null}


        {activeTab === "Style Manager" ? (
          <>
          {imageSelect && <SelectionBox
                isImages={true}
                pozition='absolute top-0 right-0'    
                images={images}
                setImgsCategory={setImgsCategory}
                imgsCategory={imgsCategory}
                onSelect={handleIconImageSelect}
                iconsSquare={iconsSquare}
                setIconsSquare={setIconsSquare}
                setImages={setImages}
                />}

          {iconSelect && (
            <SelectionBox
              pozition='absolute top-0 right-0'
              category={category}
              setIconsSquare={setIconsSquare}
              iconsSquare={iconsSquare}
              onSelect={handleIconIconSelect}
            />
          )}

          
        {activeId.name !== 'Login' ? null : <ToolbarSection
          expanded={false}
          title="Image & Icon Template"
         >
         <div className='w-full flex justify-around text-[#000] my-[1vmin]'>
          <div className='p-[1.2vmin] h-[fit-content] flex justify-center' onClick={() => handleSelect('image')} style={{background: imageSelect ? isDarkMode? '#e9e9e9' : '#e9e9e990' : '' , borderRadius:'1vmin', fontFamily:'Inter', fontWeight:'600', cursor:'pointer', fontSize:'2.2vmin', alignContent:'center', alignItems:'center', color: isDarkMode ? imageSelect ? '#0867FC' : '#FFF' : imageSelect ? '#0867FC' : '#000000A1'}}>Image</div>
          <div className='p-[1.2vmin] h-[fit-content] flex justify-center' onClick={() => handleSelect('icon')} style={{background: iconSelect ? isDarkMode? '#e9e9e9' : '#e9e9e990' : '' , borderRadius:'1vmin', fontFamily:'Inter', fontWeight:'600', cursor:'pointer', fontSize:'2.2vmin', alignContent:'center', alignItems:'center', color: isDarkMode ? iconSelect ? '#0867FC' : '#FFF' : iconSelect ? '#0867FC' : '#000000A1'}}>Icon</div>
          </div>
          { imageSelect && <div className='w-full my-[1vmin]'>
          <ToolbarItem
              imageSelect={imageSelect}
              IconsSquare={iconsSquare}
              setIconsSquare={setIconsSquare}
              full={true}
              propKey="src"
              type="src"
              placeholder='Select Another Image / Add Image Url'
            />
          </div>}
          
          { iconSelect && <div className='w-full'>
          <ToolbarItem
              noinput
              iconSearch
              selectedIcon={selectedIcon}
              IconsSquare={iconsSquare}
              setIconsSquare={setIconsSquare}
              full={true}
              propKey="icon"
              type="src"
              placeholder='Select Another Logo Icon'
            />
          </div>}
         </ToolbarSection>
}
         
            <ToolbarItem
              full={true}
              propKey="welcomeHeader"
              type="header Auth"
              placeholder='Change the Welcome Message'
            />
            
            <ToolbarSection
              expanded={false}
              title="Background"
              props={['background', 'radius']}
              summary={({ background, radius }) => {
                let bg = background || { r: 255, g: 255, b: 255, a: 1 }
                let br = radius
                return (
                  <div className="flex flex-row-reverse">
                    <div
                      style={{
                        background:
                          bg && `rgba(${Object.values(bg)})`,
                      }}
                      className="shadow-md flex-end w-6 h-6 text-center flex items-center rounded-full bg-black"
                    >
                    </div>
                    <div>{br}</div>
                  </div>
                );
              }}
            >
              <ToolbarItem
                full={true}
                propKey="background"
                action={true}
                type="bg"
              />
             
            </ToolbarSection>
            <ToolbarSection
              expanded={false}
              title="Size"
              props={['width', 'height']}
              summary={({ width, height }) => {
                return `${width} ${height}`;
              }}
            >
              <ToolbarItem
                full={true}
                propKey="width"
                type="slider"
                unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                label="Width"
                action={true}
              />
              <ToolbarItem
                propKey="minWidth"
                type="slider"
                unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                label="Min-Width"
                action={true}
              />

              <ToolbarItem
                propKey="maxWidth"
                type="slider"
                unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                label="Max-Width" />

              <ToolbarItem
                full={true}
                propKey="height"
                type="slider"
                unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                label="Height"
              />
            </ToolbarSection>

            <ToolbarSection
              expanded={false}
              title="Margin"
              props={['margin']}
              summary={({ }) => {
                return;
              }}
            >
              <ToolbarItem
                propKey="marginTop"
                type="slider"
                unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                label="Top" />
              <ToolbarItem
                propKey="marginRight"
                type="slider"
                unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                label="Right" />
              <ToolbarItem
                propKey="marginBottom"
                type="slider"
                unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                label="Bottom" />
              <ToolbarItem
                propKey="marginLeft"
                type="slider"
                unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                label="Left" />
            </ToolbarSection>
            <ToolbarSection
              expanded={false}
              title="Padding"
              props={['padding']}
              summary={({ }) => {
                return;
              }}
            >
              <ToolbarItem
                propKey="paddingTop"
                type="slider"
                unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                label="Top" />
              <ToolbarItem
                propKey="paddingRight"
                type="slider"
                unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                label="Right" />
              <ToolbarItem
                propKey="paddingBottom"
                type="slider"
                unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                label="Bottom" />
              <ToolbarItem
                propKey="paddingLeft"
                type="slider"
                unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                label="Left" />
            </ToolbarSection>
            <ToolbarSection
              expanded={false}
              title="Extra"
              props={['raduis', 'shadow', 'shadowBackground', 'transition']}
              summary={({ filterOptions }) => {
                return `${filterOptions}`;
              }}
            >
              <ToolbarItem
                full={true}
                propKey="cursor"
                optionsDrop={['help', 'wait', 'crosshair', 'not-allowed', 'zoom-in', 'grab', 'pointer']}
                type="select-drop"
                label="Cursor"
              />
              <ToolbarItem
                full={true}
                propKey="filterOptions"
                optionsDrop={['Grayscale', 'Blur', 'Brightness', 'Contrast', 'Saturate', 'Invert', 'Opacity', 'Sepia']}
                type="select-drop"
                label="Filter option"
              />
              <ToolbarItem
                full={true}
                propKey="filterValue"
                type="slider"
                unitOptions={['px', '%', 'deg', 'em', 'in', 'rad']}
                label="Filter Value"
              />


              <ToolbarItem
                full={true}
                propKey="radius"
                type="slider"
                unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                label="Radius"
              />
              <ToolbarItem
                full={true}
                propKey="shadow"
                type="slider"
                unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                label="shadow"
              />
            </ToolbarSection>
          </>
        ) : null}
      </div>
{/* 
      <div className='w-full h-auto flex flex-row relative mt-[1vmin]'>
        <ToolbarItem
          full={true}
          propKey='state'
          type='state'
          label='state'
        />
      </div> */}
    </div>

  )
}