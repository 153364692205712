/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, { useState, useRef, useEffect } from 'react';

export const PopupModal = ({ onClick,onButtonclicked, children, custom }) => {
  const [isVisible, setIsVisible] = useState(false);
  const modalRef = useRef(null);

  const handleClick = () => {
    setIsVisible(true);
    // onButtonclicked()
  };

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const modalStyle = {
    position: 'absolute',
    bottom: 'calc(100% + 30px)',
    right: '-80px',
    transform: 'translateX(-50%)',
    height:"fit-content",
    width:"150px",
    background:"rgb(22, 23, 28)",
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
    borderRadius: '5px',
    padding: '10px',
  };

  return (
    <div style={{ position: 'relative', }} onClick={onClick}>
      <div style={{width: custom ? "100%" :"1.5rem", height:"1.2rem",  background:"#959dad14", borderRadius:"3px"}} onClick={custom ? null : handleClick}>
                {custom ? children : (
                  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24" className="eva eva-plus-outline" fill="#959dad"><g data-name="Layer 2"><g data-name="plus"><rect width="24" height="24" transform="rotate(180 12 12)" opacity="0"></rect><path d="M19 11h-6V5a1 1 0 0 0-2 0v6H5a1 1 0 0 0 0 2h6v6a1 1 0 0 0 2 0v-6h6a1 1 0 0 0 0-2z"></path></g></g></svg>
                )}
                  </div>
      {isVisible && (
        <div ref={modalRef} style={modalStyle}>
          
        {custom ? <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24" className="eva eva-plus-outline" fill="#959dad"><g data-name="Layer 2"><g data-name="plus"><rect width="24" height="24" transform="rotate(180 12 12)" opacity="0"></rect><path d="M19 11h-6V5a1 1 0 0 0-2 0v6H5a1 1 0 0 0 0 2h6v6a1 1 0 0 0 2 0v-6h6a1 1 0 0 0 0-2z"></path></g></g></svg> : children}
                  
        </div>
      )}
    </div>
  );
};
