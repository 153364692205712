import { useNode, Element, useEditor } from '@craftjs/core';
import React, { useEffect, useState } from 'react';
import { NewWhyUsSettings } from './newWhyusSetting';
import { Text } from '../../basic/Text';
import { ImageC } from '../../basic/Image';
import { Parent } from '../../Parent/index.js';
import { Wrapper } from '../../wrapper/index.js';
import { BounceLoader } from 'react-spinners';


export const WhyUs_2 = ({selected}) => {
    
  const { 
    actions: { setProp },
      connectors: {
        connect
      } 
  } = useNode();

  
  const {
    enabled,
  } = useEditor((state, query) => ({
    enabled: state.options.enabled,
 
  }));

    const styles = [
      <Element canvas is={Parent} id='parentId100' className="w-[100%] h-auto py-10 bg-[#FFFFFF]" >
      <Element canvas is={Parent} id='parentId200' className="w-[100%] h-auto flex justify-center items-center" row={true} >
        <Element is={Parent} canvas id='random-id-401'  className='max-w-[1300px]'>
          <Element is={Parent} canvas id='random-id-402'  className='w-full h-full flex flex-row flex-wrap justify-center '>
            <Element is={Parent} canvas id='random-id-403'   className=' bg-[#FFFFFF] flex justify-center justify-center h-full flex flex-col p-4' style={{flex:'48%', margin:'0 auto', minWidth:'277px'}} >

              <Element is={Parent} canvas id='random-id-404'   className='w-[100%] h-auto min-h-[300px] flex flex-col bg-[#2479FD] px-10 py-4 flex justify-center' style={{borderRadius:'30px', marginBottom:'20px'}}>
                  <Element is={Parent} canvas style={{display:'inline-block', fontSize:'46px', fontWeight:'bold', fontFamily:'Poppins', color:'#FFFFFF'}} className='mb-4' id='random-id-5'  >
                    <Text text='Why Us'/>
                  </Element>
                  <Element is={Parent}  canvas style={{display:'inline-block', fontSize:'22px', fontWeight:'400px', fontFamily:'Poppins', color:'#FFFFFF'}} id='random-id-6'  >
                    <Text text='Sed sed risus pretium quam vulputate dignissim. Eget nunc lobortis mattis aliquam faucibus purus in. Potenti nullam ac tortor vitae purus faucibus ornare suspendisse. Commodo ullamcorper a lacus vestibulum sed arcu non odio.'/>
                  </Element>
              </Element>

              <Element is={Parent} canvas id='random-id-405'   className='w-[100%] h-[300px]' style={{borderRadius:'30px', marginBottom:'20px'}}>
                  <ImageC className='w-[100%] h-full object-cover' style={{borderRadius:'30px', marginBottom:'20px'}} src='https://i.ibb.co/JRYPKC6/why1-1.jpg'/>
              </Element> 
            </Element>

            <Element is={Parent} canvas id='random-id-406'   className=' bg-[#FFFFFF] flex justify-center justify-center h-[100%] flex flex-col p-4' style={{flex:'48%', margin:'0 auto', minWidth:'277px'}}>
            <Element is={Parent}  canvas id='random-id-407'   className='w-[100%] h-[300px]' style={{borderRadius:'30px', marginBottom:'20px'}}>
                <ImageC className='w-[100%] h-full object-cover' style={{borderRadius:'30px', marginBottom:'20px'}} src='https://i.ibb.co/k1pHH2D/why1-2.jpg'/>
              </Element>

              <Element is={Parent} canvas id='random-id-408'   className='w-[100%] h-auto min-h-[300px] bg-[#F7F7F9] px-10 py-4 ' style={{borderRadius:'30px', marginBottom:'20px'}}>
                    <Element is={Parent} canvas style={{display:'inline-block', fontSize:'46px', fontWeight:'bold', fontFamily:'Poppins', color:'#000000'}} className='mb-4' id='random-id-7'  >
                      <Text text='Why Us'/>
                    </Element>
                    <Element is={Parent}  canvas style={{display:'inline-block', fontSize:'22px', fontWeight:'400px', fontFamily:'Poppins', color:'#000000'}} id='random-id-8'  >
                      <Text text='Sed sed risus pretium quam vulputate dignissim. Eget nunc lobortis mattis aliquam faucibus purus in. Potenti nullam ac tortor vitae purus faucibus ornare suspendisse. Commodo ullamcorper a lacus vestibulum sed arcu non odio.'/>
                    </Element>
              </Element>
            </Element>
          </Element>
      </Element></Element>
      </Element>
      ,
      <Element canvas is={Parent} id='parentId300' className="w-[100%] h-auto py-10 bg-[#FFFFFF]" >
      <Element canvas is={Parent} id='parentId400' className="w-[100%] h-auto flex justify-center items-center" row={true} >
        <Element is={Parent} canvas id='random-id-410'  className='max-w-[1300px]'>
          <Element is={Parent} canvas id='random-id-411'  className='w-full h-full flex flex-row flex-wrap justify-center'>
            <Element is={Parent} canvas id='random-id-412'   className=' bg-[#FFFFFF] flex justify-center justify-center h-full flex flex-col p-4' style={{flex:'48%', margin:'0 auto', minWidth:'277px'}} >

              <Element is={Parent} canvas id='random-id-413'   className='w-[100%] h-auto min-h-[300px] flex flex-col bg-[#FF0060] px-10 py-4 flex justify-center' style={{borderRadius:'14px', marginBottom:'20px'}}>
                  <Element is={Parent} canvas style={{display:'inline-block', fontSize:'46px', fontWeight:'bold', fontFamily:'Prompt', color:'#FFFFFF'}} className='mb-4' id='random-id-1'  >
                    <Text text='Why Us'/>
                  </Element>
                  <Element is={Parent}  canvas style={{display:'inline-block', fontSize:'22px', fontWeight:'400px', fontFamily:'Prompt', color:'#FFFFFF'}} id='random-id-2'  >
                    <Text text='Sed sed risus pretium quam vulputate dignissim. Eget nunc lobortis mattis aliquam faucibus purus in. Potenti nullam ac tortor vitae purus faucibus ornare suspendisse. Commodo ullamcorper a lacus vestibulum sed arcu non odio.'/>
                  </Element>
              </Element>

              <Element is={Parent} canvas id='random-id-414'   className='w-[100%] h-[300px]' style={{borderRadius:'14px', marginBottom:'20px'}}>
                  <ImageC className='w-[100%] h-full object-cover' style={{borderRadius:'14px', marginBottom:'20px'}} src='https://i.ibb.co/5vdKmrt/why2-2.jpg'/>
              </Element>
            </Element>

            <Element is={Parent} canvas id='random-id-415'   className=' bg-[#FFFFFF] flex justify-center justify-center h-[100%] flex flex-col p-4' style={{flex:'48%', margin:'0 auto', minWidth:'277px'}}>
              <Element is={Parent}  canvas id='random-id-416'   className='w-[100%] h-[300px]' style={{borderRadius:'14px', marginBottom:'20px'}}>
                <ImageC className='w-[100%] h-full object-cover' style={{borderRadius:'14px', marginBottom:'20px'}} src='https://i.ibb.co/d7xKqXf/why2-1.jpg'/>
              </Element>

              <Element is={Parent} canvas id='random-id-417'   className='w-[100%] h-auto min-h-[300px] bg-[#FDFDF3] px-10 py-4' style={{borderRadius:'14px', marginBottom:'20px'}}>
                    <Element is={Parent} canvas style={{display:'inline-block', fontSize:'46px', fontWeight:'bold', fontFamily:'Prompt', color:'#000000'}} className='mb-4' id='random-id-3'  >
                      <Text text='Why Us'/>
                    </Element>
                    <Element is={Parent}  canvas style={{display:'inline-block', fontSize:'22px', fontWeight:'400px', fontFamily:'Prompt', color:'#000000'}} id='random-id-4'  >
                      <Text text='Sed sed risus pretium quam vulputate dignissim. Eget nunc lobortis mattis aliquam faucibus purus in. Potenti nullam ac tortor vitae purus faucibus ornare suspendisse. Commodo ullamcorper a lacus vestibulum sed arcu non odio.'/>
                    </Element>
              </Element>
            </Element>
          </Element>
      </Element></Element>
      </Element>
      ,
      <Element canvas is={Parent} id='parentId500' className="w-[100%] h-auto py-10 bg-[#FFFFFF]" >
      <Element canvas is={Parent} id='parentId600' className="w-[100%] h-auto flex justify-center items-center" row={true} >
      <Element is={Parent} canvas id='random-id-419'  className='max-w-[1300px]'>
        <Element is={Parent} canvas id='random-id-420'  className='w-full h-full flex flex-row flex-wrap justify-center'>
          <Element is={Parent} canvas id='random-id-421'   className=' bg-[#FFFFFF] flex justify-center justify-center h-full flex flex-col p-4' style={{flex:'48%', margin:'0 auto', minWidth:'277px'}} >

            <Element is={Parent} canvas id='random-id-422'   className='w-[100%] h-auto min-h-[300px] flex flex-col bg-[#F31559] px-10 py-4 flex justify-center' style={{borderRadius:'20px 20px 0px 20px', marginBottom:'20px'}}>
                <Element is={Parent} canvas style={{display:'inline-block', fontSize:'46px', fontWeight:'bold', fontFamily:'Almarai', color:'#FFFFFF'}} className='mb-4' id='random-id-5'  >
                  <Text text='Why Us'/>
                </Element>
                <Element is={Parent}  canvas style={{display:'inline-block', fontSize:'22px', fontWeight:'400px', fontFamily:'Almarai', color:'#FFFFFF'}} id='random-id-6'  >
                  <Text text='Sed sed risus pretium quam vulputate dignissim. Eget nunc lobortis mattis aliquam faucibus purus in. Potenti nullam ac tortor vitae purus faucibus ornare suspendisse. Commodo ullamcorper a lacus vestibulum sed arcu non odio.'/>
                </Element>
            </Element>

              <Element is={Parent}  canvas  className='w-[100%] h-[300px]' style={{borderRadius:'20px 0px 20px 20px', marginBottom:'20px'}} id='random-id-104'  >
                <ImageC className='w-[100%] h-full object-cover' style={{borderRadius:'20px 0px 20px 20px', marginBottom:'20px'}} src='https://i.ibb.co/NW7gRWJ/why3-1.jpg'/>
              </Element>

          </Element>

          <Element is={Parent} canvas id='random-id-423'   className=' bg-[#FFFFFF] flex justify-center justify-center h-[100%] flex flex-col p-4' style={{flex:'48%', margin:'0 auto', minWidth:'277px'}}>

            <Element is={Parent}  canvas className='w-[100%] h-[300px]' style={{borderRadius:'20px 20px 20px 0px', marginBottom:'20px'}} id='random-id-105'  >
              <ImageC className='w-[100%] h-full object-cover' style={{borderRadius:'20px 20px 20px 0px', marginBottom:'20px'}} src='https://i.ibb.co/m8ddRr2/why3-2.jpg'/>
            </Element>

            <Element is={Parent} canvas id='random-id-424'   className='w-[100%] h-auto min-h-[300px] bg-[#FDF4F4] px-10 py-4' style={{borderRadius:'0px 20px 20px 20px', marginBottom:'20px'}}>
                  <Element is={Parent} canvas style={{display:'inline-block', fontSize:'46px', fontWeight:'bold', fontFamily:'Almarai', color:'#000000'}} className='mb-4' id='random-id-7'  >
                    <Text text='Why Us'/>
                  </Element>
                  <Element is={Parent}  canvas style={{display:'inline-block', fontSize:'22px', fontWeight:'400px', fontFamily:'Almarai', color:'#000000'}} id='random-id-8'  >
                    <Text text='Sed sed risus pretium quam vulputate dignissim. Eget nunc lobortis mattis aliquam faucibus purus in. Potenti nullam ac tortor vitae purus faucibus ornare suspendisse. Commodo ullamcorper a lacus vestibulum sed arcu non odio.'/>
                  </Element>
            </Element>
          </Element>
      </Element>
    </Element></Element>
      </Element>
    ,
    <Element canvas is={Parent} id='parentId700' className="w-[100%] h-auto py-10 bg-[#FFFFFF]" >
      <Element canvas is={Parent} id='parentId800' className="w-[100%] h-auto flex justify-center items-center" row={true} >
    <Element is={Parent} canvas id='random-id-426'  className='max-w-[1300px]'>
      <Element is={Parent} canvas id='random-id-427'  className='w-full h-full flex flex-row flex-wrap justify-center'>
        <Element is={Parent} canvas id='random-id-428'   className=' bg-[#FFFFFF] flex justify-center justify-center h-full flex flex-col p-4' style={{flex:'48%', margin:'0 auto', minWidth:'277px'}} >

          <Element is={Parent} canvas id='random-id-429'   className='w-[100%] h-auto min-h-[300px] flex flex-col bg-[#0C134F] px-10 py-4 flex justify-center' style={{borderRadius:'14px', marginBottom:'20px'}}>
              <Element is={Parent} canvas style={{display:'inline-block', fontSize:'46px', fontWeight:'bold', fontFamily:'Chivo', color:'#FFFFFF'}} className='mb-4' id='random-id-9'  >
                <Text text='Why Us'/>
              </Element>
              <Element is={Parent}  canvas style={{display:'inline-block', fontSize:'22px', fontWeight:'400px', fontFamily:'Chivo', color:'#FFFFFF'}} id='random-id-10'  >
                <Text text='Sed sed risus pretium quam vulputate dignissim. Eget nunc lobortis mattis aliquam faucibus purus in. Potenti nullam ac tortor vitae purus faucibus ornare suspendisse. Commodo ullamcorper a lacus vestibulum sed arcu non odio.'/>
              </Element>
          </Element>

            <Element is={Parent}  canvas className='w-[100%] h-[300px]' style={{borderRadius:'14px', marginBottom:'20px'}} id='random-id-106'  >
              <ImageC className='w-[100%] h-full object-cover' style={{borderRadius:'14px', marginBottom:'20px'}} src='https://i.ibb.co/wpD9Tk4/why4-2.jpg'/>
            </Element>
        </Element>

        <Element is={Parent} canvas id='random-id-430'   className=' bg-[#FFFFFF] flex justify-center justify-center h-[100%] flex flex-col p-4' style={{flex:'48%', margin:'0 auto', minWidth:'277px'}}>
          <Element is={Parent}  canvas className='w-[100%] h-[300px]' style={{borderRadius:'14px', marginBottom:'20px'}} id='random-id-106'  >
            <ImageC className='w-[100%] h-full object-cover' style={{borderRadius:'14px', marginBottom:'20px'}} src='https://i.ibb.co/T2FbfL4/why4-1.jpgs'/>
          </Element>

          <Element is={Parent} canvas id='random-id-431'   className='w-[100%] h-auto min-h-[300px] bg-[#D4ADFC] px-10 py-4 ' style={{borderRadius:'14px', marginBottom:'20px'}}>
                <Element is={Parent} canvas style={{display:'inline-block', fontSize:'46px', fontWeight:'bold', fontFamily:'Chivo', color:'#000000'}} className='mb-4' id='random-id-11'  >
                  <Text text='Why Us'/>
                </Element>
                <Element is={Parent}  canvas style={{display:'inline-block', fontSize:'22px', fontWeight:'400px', fontFamily:'Chivo', color:'#000000'}} id='random-id-12'  >
                  <Text text='Sed sed risus pretium quam vulputate dignissim. Eget nunc lobortis mattis aliquam faucibus purus in. Potenti nullam ac tortor vitae purus faucibus ornare suspendisse. Commodo ullamcorper a lacus vestibulum sed arcu non odio.'/>
                </Element>
          </Element>
        </Element>
    </Element>
  </Element></Element>
      </Element>
  ,
    
    ]

      
    const [sel, setSel] = useState(selected)

function makeOdd(number) {
  setProp((prop) => {
    setSel(100);
  }, 1000);

  setTimeout(() => {
    setProp((prop) => {
      setSel(number)
    }, 1000);
  }, 100);


  
}

useEffect(() => {
if(selected || selected >= 0) {
  makeOdd(selected);
}
},[selected])

    return (
      <div style={{ padding:enabled? "10px" : "0px", display:"flex", justifyContent:"center", alignItems:"center", width:'100%', height:'100%'}} ref={connect}>
       {sel == 100 ? (
          <div style={{height:"85vh", display:"flex", justifyContent:"center", alignItems:"center"}}>
          <BounceLoader
                  size={100}
                  color={"white"}
                  loading={true}
                />
          </div>
         ) : (
          styles[sel]
         )} 
  </div>
    )
}

WhyUs_2.craft = {
    displayName: "WhyUs 2",
    props: {
       selected: 1, // here is the default value of the selected style
       length:3,
isBlock: true
      },
      rules: {
        canDrag: () => true,
        canDrop: () => true,
      },
      related: {
        toolbar: NewWhyUsSettings,
      },
  };
