import React, { useState } from 'react';
import { Checkbox } from '@progress/kendo-react-inputs';
import { useNode, useEditor } from '@craftjs/core';
import { TextArea } from "@progress/kendo-react-inputs";
import { TextAreaSettings } from './TextAreaSettings';


export const BasicTextArea = ({
        style = {},
        fontSize = '16px',
        width,
        className,
        height,
        color,
        background,
        borderColor,
        borderWidth,
        borderRadius,
        textAlign,
        shadowX,
        shadowY,
        shadowBlur,
        shadowColor,
        marginTop,
        marginRight,
        marginBottom,
        marginLeft,
fontWeight,
fontStyle,
textDecoration,
fontFamily,
textTransform,
readOnly,
lineHeight,
        defaultValue,
        placeHolder,
        isTextArea,
}) => {

        const {
          connectors: { connect },
          actions: {setProp},
        } = useNode();
      
        const { enabled } = useEditor((state) => ({
          enabled: state.options.enabled,
        }));

        
        const handleProperty = (value, propertyName, split) => {
            if (value !== null && !split) {
            return { [propertyName]: value?.split(" ").join("") }
            }

            if (value !== null && split === 'splitBg') {
            return { [propertyName]:  value !== null ? value : '' }
            } else {
            return;
            }
        };


        const styleProps = {
        
              ...(color !== null && {
                color: color?.startsWith('#') ? color : 'transparent',
                backgroundImage: !color?.startsWith('#') ? color : undefined,
                WebkitBackgroundClip: !color?.startsWith('#') ? 'text' : undefined,
                backgroundClip: !color?.startsWith('#') ? 'text' : undefined,
              }),

              ...((shadowX || shadowY || shadowColor || shadowBlur) !== null ? {
                boxShadow: `${(shadowX?.split(" ").join(""))} ${(shadowY?.split(" ").join(""))} ${(shadowBlur?.split(" ").join(""))} ${shadowColor || `rgba(255, 255, 255)`}`
            } : null),
            ...handleProperty(marginTop, 'marginTop'),
            ...handleProperty(marginRight, 'marginRight'),
            ...handleProperty(marginBottom, 'marginBottom'),
            ...handleProperty(marginLeft, 'marginLeft'),
            ...handleProperty(fontSize, 'fontSize'),
            ...handleProperty(fontFamily, 'fontFamily'),
            ...handleProperty(textDecoration, 'textDecoration'),
            ...handleProperty(fontStyle, 'fontStyle'),
            ...handleProperty(textAlign, 'textAlign'),
            ...handleProperty(fontWeight, 'fontWeight'),
            ...handleProperty(lineHeight, 'lineHeight'),
           
          };

    const CustomStyle = `
    background-color: ${background} !important;
    border-color: ${borderColor} !important;
    width: ${width?.split(" ").join("")};
    height: ${height?.split(" ").join("")};
    border-radius: ${borderRadius?.split(" ").join("")};
    border: ${borderWidth?.split(" ").join("")} solid;
  `;

        
  return ( 
  <div
  ref={connect}
  className={className}
  style={{padding:'10px 0', alignItems:'center', display:'flex'}}
  >
    <TextArea style={{
        ...styleProps,
        ...style,
        cssText:CustomStyle,
    }} 
    defaultValue={defaultValue || 'Tell us a little bit about yourself...'} 
    readOnly={readOnly === 'True'? true : false} 
    placeholder={placeHolder} />
    </div>
    );
};

BasicTextArea.craft = {
    displayName: 'CheckBox',
    props: {
        fontSize: '16px',
        color: '#0867FC',
        width: null,
        height:null,
        shadowX: null,
        shadowY: null,
        shadowColor:null,
        shadowBlur: null,
        marginTop: null,
        marginRight: null,
        marginBottom: null,
        marginLeft: null,
        isTextArea:true,
        defaultValue: null,
        readOnly:null,
        placeHolder:null,
        borderRadius: null,
        background:null,
        borderColor:null,
        borderWidth:null,
        textAlign:null,
        fontWeight:null,
        fontStyle:null,
        textDecoration:null,
        fontFamily:null,
        textTransform:null,
        lineHeight:null,
    },
    related: {
      toolbar: TextAreaSettings,
    },
  };