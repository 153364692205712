/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import { useNode, useEditor } from '@craftjs/core';
import { ROOT_NODE } from '@craftjs/utils';
import React, { useEffect, useRef, useCallback, useState, useContext } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { useDarkMode } from '../../DarkModeContext';
import { ToolbarItem } from './Toolbar';
import SelectionBox from './Viewport/Pop-up/PopUp';
import { ImageCSettings, category, imgsCate } from '../selectors/basic/Image/ImageSettings';
import { DisabledIcon, ScaleIcon, UndisabledIcon } from './Viewport/Pop-up/svgArray';
import { ButtonSettings } from '../selectors/basic/Button/ButtonSettings';
import Draggable from "react-draggable";
import { Resizable } from 're-resizable';
import { TextSettings } from '../selectors/basic/Text/TextSettings';
import { SvgIconSettings } from '../selectors/basic/Svg/SvgIconSettings';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import { WrapperSettings } from '../selectors/wrapper/WrapperSettings';
import { InputSettings } from '../selectors/basic/Input/InputSettings';
import { LoginSettings } from '../../login/LoginSettings';
import { NavBarSettings } from '../widgets/newHeader/NavbarSettings';
import { PluginSettings } from '../widgets/Plugin/PluginSettings';
import SidebarContext from '../../SidebarShown/SidebarContext';
import { WidgetFooter } from './Toolbar/widgetFooter';

const IndicatorDiv = styled.div`
  height: 30px;
  margin-top: -29px;
  font-size: 12px;
  line-height: 12px;

  svg {
    width: 15px;
    height: 15px;
  } 
`;

const Btn = styled.a`
  padding: 0 0px;
  opacity: 0.9;
  display: flex;
  align-items: center;
  > div {
    position: relative;
    top: -50%;
    left: -50%;
  }
`;

export const RenderNode = ({ render }, settings, setSettings, setWorking) => {
  const { isDarkMode } = useDarkMode();
  const { id } = useNode();
  const { actions, query, isActive, selected, nodeObj, connectors } = useEditor((state, query) => ({
    isActive: query.getEvent('selected').contains(id),
    nodeObj: state,
    selected: state.events.selected,
  }));

  const {
    isHover,
    dom,
    name,
    moveable,
    deletable,
    connectors: { drag },
    parent,
    node,
    children,
    props,
    actions: { setProp }
  } = useNode((node) => ({
    isHover: node.events.hovered,
    dom: node.dom,
    name: node.data.custom.displayName || node.data.displayName,
    moveable: query.node(node.id).isDraggable(),
    deletable: query.node(node.id).isDeletable(),
    parent: node.data.parent,
    props: node.data.props,
    node: node,
    children: node.data.linkedNodes,

  }));

  const currentRef = useRef();
  const [openAria, setOpen] = useState('')
  const [iconsSquare, setIconsSquare] = useState(false)
  const anchorRef = useRef(null);
  const [moving, setMoving] = useState(true)
  const [helberScreen, setHelberScreen] = useState(false)
  const disabledAria = useRef(null)
  const {setPopUp} = useContext(SidebarContext)

  const handleIconImageSelect = (selectedUrl) => {

    if (Array.isArray(selectedUrl) && selectedUrl.length) {
      setProp((prop) => {
        prop.src = selectedUrl[0]
      }, 500)
    } else {
      setProp((prop) => {
        prop.src = selectedUrl
      }, 500)
    }

    setIconsSquare(false);
  };

  const value = props ? props?.fontSize : null

  useEffect(() => {

    if (children) {
      let newChildren = Object.entries(children)
      newChildren.map(([key, x]) => {
      })
    }
  }, [children])

  useEffect(() => {
    if (dom) {
      if (dom) {
        if (isActive) {
          dom.classList.add('component-selected');
        } else if (!isActive && isHover) {
          dom.classList.remove('component-selected');
          dom.classList.add('component-hovered');

        } else {
          dom.classList.remove('component-selected', 'component-hovered');
        }
      }
    }
  }, [dom, isActive, isHover]);


  const getPos = useCallback((dom) => {
    const { top, left, bottom, right } = dom
      ? dom?.getBoundingClientRect()
      : { top: 0, left: 0, bottom: 0, right: 0 };

    return {
      top: `${top > 0 ? top - 35 : bottom}px`,
      right: `${right - 320}px`,
      left: `${left - 5}px`,
      bottom: `${bottom}px`,
    };
  });


  const scroll = useCallback(() => {
    const { current: currentDOM } = currentRef;

    if (!currentDOM) return;
    const { right, top, left, bottom } = getPos(dom);
    currentDOM.style.top = top;
    currentDOM.style.right = right;
    currentDOM.style.left = left;
    currentDOM.style.bottom = bottom;
  },);

  useEffect(() => {
    document
      ?.querySelector('.craftjs-renderer')
      ?.addEventListener('scroll', scroll);

    return () => {
      document
        ?.querySelector('.craftjs-renderer')
        ?.removeEventListener('scroll', scroll);
    };
  },);




  const insertNodeOnParent = useCallback(
    (
      nodeId,
      parentId,
      indexToInsert,
      selectNodeAfterCreated = false,
    ) => {
      const node = query.node(nodeId).get();

      const freshNode = {
        data: {
          ...node.data,
          nodes: [],
        },
      };

      const nodeToAdd = query.parseFreshNode(freshNode).toNode();

      actions.add(nodeToAdd, parentId, indexToInsert);

      if (node.data.nodes.length === 0) {
        return;
      }

      node.data.nodes.forEach((childNode, index) => {
        insertNodeOnParent(childNode, nodeToAdd.id, index);
      });

      if (selectNodeAfterCreated) actions.selectNode(nodeToAdd.id);
    },
    [actions, query],
  );

  const duplicateNode = useCallback(() => {
    const parentNode = query.node(parent).get();
    // const indexToAdd = parentNode.data.nodes.indexOf(id) + 1;

    // insertNodeOnParent(id, parent, indexToAdd, true);
  }, [id, insertNodeOnParent, parent, query]);

  const changeProp = (prop) => {
    setOpen('')


    if (prop == "Bold") {
      setProp((prop) => {
        prop.fontWeight = prop.fontWeight == "bold" ? "normal" : "bold"
      }, 500)
    }

    if (prop == "Italic") {
      setProp((prop) => {
        prop.fontStyle = prop.fontStyle == "italic" ? "normal" : "italic"
      }, 500)

    }

    if (prop == "Underline") {
      setProp((prop) => {
        prop.textDecoration = prop.textDecoration == "underline line-through"
          ? "line-through" : prop.textDecoration == "line-through"
            ? "underline line-through" : prop.textDecoration == "none"
              ? "underline" : prop.textDecoration == "underline" ? "none" : "none"
      }, 500)
    }
  }




  const propActive = (prop, value) => {

    return props[prop] == value

  }

  // const [initPostions, setInitPostions] = useState(null)

  let initPostions = getPos(dom)


  return (
    <>
      {helberScreen && <div className='w-[100vw] h-[100vh] absolute top-0 left-0' style={{ zIndex: 99999, background: 'transparent' }}></div>}

      {isActive
        ? ReactDOM.createPortal(
          <div>
            {!node?.data?.props["isButton"] &&
              !node?.data?.props["isText"] &&
              !node?.data?.props["isBlock"] &&
              !node?.data?.props["isPlugin"] &&
              !node?.data?.props["isSvg"] &&
              !node?.data?.props["isWrapper"] &&
              !node?.data?.props["isNavBar"] &&
              !node?.data?.props["isImage"] &&
              !node?.data?.props["isInput"] &&
              !node?.data?.props["isLogin"] ? null :
                <Draggable bounds={{ left: '100%', top: 0, right: '100%', bottom: 500 }} onStop={() => setHelberScreen(false)} onStart={() => setHelberScreen(true)} disabled={moving ? true : false}>
              <IndicatorDiv
                ref={currentRef}
                className="px-2 py-2 text-white fixed flex items-center itemBox"
                style={{
                  right: "1%",
                  top: "15%",
                  zIndex: 999999,
                  height: "fit-content",
                }}
              >
                <div className='dragdiv shadow-xl rounded-[2.5vmin]'>
                  <Resizable
                    onResizeStart={() => setHelberScreen(true)} onResizeStop={() => setHelberScreen(false)}
                    style={{ borderRadius: '2.5vmin', background: isDarkMode ? '#111F3E' : '#FFF', overflow:'hidden' }}
                    minWidth={320}
                    lockAspectRatioExtraWidth={true}
                    lockAspectRatioExtraHeight={true}
                    defaultSize={{
                      width: 320,
                      height:node?.data?.props["iswidget"] || node?.data?.props["isParent"] || node?.data?.props["isBlock"] ? 'fit-content' : 400
                    }}
                    minHeight={node?.data?.props["iswidget"] || node?.data?.props["isParent"] || node?.data?.props["isBlock"] ? 'fit-content' : 350}
                  >
                    <div
                      style={{ cursor: "move" }}
                      ref={disabledAria}
                      onTouchStart={() => setMoving(false)}
                      onTouchMove={() => setMoving(true)}
                      onMouseLeave={() => setMoving(true)}
                      onMouseOver={() => setMoving(false)}
                      className='w-full flex flex-row py-[1vmin] mx-auto justify-center align-items-center'>
                      <OpenWithIcon sx={{ color: isDarkMode ? '#989191' : '#000', fontSize: '4vmin', }} />
                    </div>

                    {isActive && node?.data?.props["isBlock"] ? <div style={{ position: "relative", width: "100%" }}>
                      {<div
                        style={{
                          border: "1px solid var(--Gray, #E9E9EA)",
                          boxShadow: " 2px 0px 10px 0px rgba(164, 164, 164, 0.10)",
                          borderRadius: "0 0 10px 10px",

                          padding: ".5vmin", background: isDarkMode ? "#111F3E" : "#fff", width: "100%", height: "fit-content"
                        }}
                        onKeyDown={(event) => {
                          event.stopPropagation();
                        }}
                        id="dropdown-menu"
                        anchorEl={anchorRef.current}
                      >
                        <ToolbarItem
                          full={true}
                          propKey="selected"
                          type="vslider"
                          label="Styling"
                        />
                      </div>}


                    </div> : null}

                    {isActive && node?.data?.props["isSvg"] ? (
                      <div style={{ position: "relative", width: "100%" }}>
                        <div style={{ justifyContent: 'space-between', padding: '.40vmin 2vmin', display: 'flex', color: isDarkMode ? '#fff' : '#000' }}>
                          <div className={`${false ? "gjs-rte-action-active gjs-rte-action" : "gjs-rte-action"}`}
                            onClick={() => {
                              if (openAria !== "") {
                                setOpen('')
                              } else {
                                setOpen("scale")
                              }
                            }}
                            style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "fit-content", }}
                          >
                            <ScaleIcon fill={`${isDarkMode ? '#fff' : '#000'}`} width='1.3vmin' height='1.3vmin' />
                          </div>
                          <div className={`${false ? "gjs-rte-action-active gjs-rte-action" : "gjs-rte-action"}`}
                            title='Library Icons'
                            onClick={() => setOpen('')}
                            style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "fit-content", }}
                          >
                            <ToolbarItem
                              IconsSquare={iconsSquare}
                              setIconsSquare={setIconsSquare}
                              full={true}
                              propKey="src"
                              type="src"
                              noinput
                            />
                          </div>
                          <div className={`${false ? "gjs-rte-action-active gjs-rte-action" : "gjs-rte-action"}`}
                            style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "fit-content", }}
                            onClick={() => {
                              if (openAria !== "") {
                                setOpen('')
                              } else {
                                setOpen("hrefSvg")
                              }
                            }}
                          >
                            <svg viewBox="0 0 24 24" fill={isDarkMode ? "#FFF" : "#000"} width='2vmin' height='2vmin'>
                              <path d="M3.9,12C3.9,10.29 5.29,8.9 7,8.9H11V7H7A5,5 0 0,0 2,12A5,5 0 0,0 7,17H11V15.1H7C5.29,15.1 3.9,13.71 3.9,12M8,13H16V11H8V13M17,7H13V8.9H17C18.71,8.9 20.1,10.29 20.1,12C20.1,13.71 18.71,15.1 17,15.1H13V17H17A5,5 0 0,0 22,12A5,5 0 0,0 17,7Z"></path>
                            </svg>
                          </div>
                        </div>
                        {
                          openAria === 'scale' ? (
                            <div
                              style={{ background: isDarkMode ? '#111F3E' : '#fff', right: 0, top: '9vmin', width: "100%", height: "fit-content", borderRadius: '2.5vmin' }}
                            >
                              <ToolbarItem
                                full={true}
                                propKey="scale"
                                type="slider"
                                unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                              />
                            </div>
                          ) : openAria === 'hrefSvg' ? (
                            <div
                              style={{ background: 'transparent', right: 0, top: '9vmin', width: "100%", height: "fit-content", borderRadius: '2.5vmin' }}
                            >
                              <ToolbarItem
                                full={true}
                                propKey="href"
                                sm
                                type="select"
                              />
                            </div>
                          ) : null
                        }
                        <div>
                          {iconsSquare && (
                            <SelectionBox
                              images={false}
                              category={category}
                              setIconsSquare={setIconsSquare}
                              iconsSquare={iconsSquare}
                              onSelect={handleIconImageSelect}
                            />
                          )}
                        </div>
                      </div>
                    ) : null}
                    {isActive && node?.data?.props["isImage"] ? <div style={{ position: "relative", width: "100%" }}>
                      <div style={{ justifyContent: 'space-between', padding: '.40vmin 2vmin', display: 'flex', color: isDarkMode ? '#fff' : '#000', alignContent:'center', alignItems:'center' }}>
                        <div className={`${false ? "gjs-rte-action-active gjs-rte-action" : "gjs-rte-action"} py-[.5vmin]`}
                          onClick={() => setOpen('')}
                          title='Library Images'
                          style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "fit-content", height:'fit-content'}}
                        >
                          <ToolbarItem
                            IconsSquare={iconsSquare}
                            setIconsSquare={setIconsSquare}
                            full={true}
                            propKey="src"
                            noinput
                            type="src"
                          />
                        </div>
                        <div
                          style={{ display: "flex", justifyContent: "center", alignItems: "center", flex: 1, cursor: 'pointer', borderRight: 'none', margin: '.5vmin', borderRadius: '4px' }}
                          onClick={() => {
                            setOpen('')

                          }}
                        >
                          <ToolbarItem
                            full={true}
                            optionsDrop={['fill', 'contain', 'cover', 'none']}
                            propKey="fit"
                            noinput
                            type="select-drop"
                          />
                        </div>
                      </div>

                      <div>
                        {iconsSquare && (
                          <SelectionBox
                            isImages={true}
                            pozition='absolute top-0 right-0'
                            imgsCategory={imgsCate}
                            images
                            onSelect={handleIconImageSelect}
                            iconsSquare={iconsSquare}
                            setIconsSquare={setIconsSquare}
                          />
                        )}
                      </div>
                    </div> : null}
                    {isActive && node?.data?.props["isButton"] ? <div style={{ position: "relative", width: "100%", color: isDarkMode ? '#fff' : '#000' }}>
                      <div style={{ justifyContent: 'space-between', padding: '.40vmin 2vmin', display: 'flex' }}>
                        <div className={`${false ? "gjs-rte-action-active gjs-rte-action" : "gjs-rte-action"}`}
                          style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "fit-content", }}
                          onClick={() => {
                            setOpen('')
                            setProp((prop) => {
                              prop.disabled = prop.disabled == "false" ? "true" : "false"
                            }, 500)
                          }}>{node?.data?.props['disabled'] === 'false' ? (
                            <DisabledIcon width='1.3vmin' height='1.3vmin' fill={`${isDarkMode ? '#FFF' : '#000'}`} />
                          ) : (
                            <UndisabledIcon width='1.3vmin' height='1.3vmin' fill={`${isDarkMode ? '#FFF' : '#000'}`} />
                          )}</div>


                        <div className={`${false ? "gjs-rte-action-active gjs-rte-action" : "gjs-rte-action"}`}
                          style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "fit-content", }}
                          onClick={() => {
                            setOpen('')
                            setProp((prop) => {
                              prop.buttonStyle = prop.buttonStyle == "full" ? "outline" : "full"
                            }, 500)
                          }}>{node?.data?.props['buttonStyle'] === 'full' ? 'filled' : 'outline'}</div>


                        <div className={`${false ? "gjs-rte-action-active gjs-rte-action" : "gjs-rte-action"}`}
                          style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "fit-content", }}
                          onClick={() => {
                            if (openAria !== "") {
                              setOpen('')
                            } else {
                              setOpen("hrefButton")
                            }
                          }}
                        >
                          <svg viewBox="0 0 24 24" fill={isDarkMode ? "#FFF" : "#000"} width='2vmin' height='2vmin'>
                            <path d="M3.9,12C3.9,10.29 5.29,8.9 7,8.9H11V7H7A5,5 0 0,0 2,12A5,5 0 0,0 7,17H11V15.1H7C5.29,15.1 3.9,13.71 3.9,12M8,13H16V11H8V13M17,7H13V8.9H17C18.71,8.9 20.1,10.29 20.1,12C20.1,13.71 18.71,15.1 17,15.1H13V17H17A5,5 0 0,0 22,12A5,5 0 0,0 17,7Z"></path>
                          </svg>
                        </div>


                      </div>
                      {openAria === 'hrefButton' ? (
                        <div
                          style={{ background: 'transparent', right: 0, top: '9vmin', width: "100%", height: "fit-content", borderRadius: '2.5vmin' }}
                        >
                          <ToolbarItem
                            full={true}
                            propKey="href"
                            sm
                            type="select"
                          />
                        </div>
                      ) : null}
                    </div> : null}
                    {isActive && node?.data?.props["isText"] ? <div style={{ position: "relative", width: "100%" }}>
                      <div style={{ justifyContent: 'space-between', padding: '.40vmin 2vmin', display: 'flex', color: isDarkMode ? '#fff' : '#000' }} className="gjs-rte-actionbar">
                        <div className={`${propActive("fontWeight", "bold") ? "gjs-rte-action-active gjs-rte-action" : "gjs-rte-action"}`} onClick={() => changeProp("Bold")} style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "fint-content", color: isDarkMode ? '#fff' : '#000' }} title="" data-tooltip="Bold"><b>B</b></div>
                        <div className={`${propActive("fontStyle", "italic") ? "gjs-rte-action-active gjs-rte-action" : "gjs-rte-action"}`} onClick={() => changeProp("Italic")} style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "fint-content", color: isDarkMode ? '#fff' : '#000' }} title="" data-tooltip="Italic"><i>I</i></div>
                        <div className={`${propActive("textDecoration", "underline") ? "gjs-rte-action-active gjs-rte-action" : "gjs-rte-action"}`}
                          onClick={() => changeProp("Underline")} style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "fint-content", color: isDarkMode ? '#fff' : '#000' }} title="" data-tooltip="Underline"><u>U</u></div>
                        <span className={`${false ? "gjs-rte-action-active gjs-rte-action" : "gjs-rte-action"}`}
                          style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "fit-content", }}
                          onClick={() => {
                            if (openAria !== "") {
                              setOpen('')
                            } else {
                              setOpen("hrefText")
                            }
                          }}
                        >
                          <svg viewBox="0 0 24 24" fill={isDarkMode ? "#FFF" : "#000"} >
                            <path d="M3.9,12C3.9,10.29 5.29,8.9 7,8.9H11V7H7A5,5 0 0,0 2,12A5,5 0 0,0 7,17H11V15.1H7C5.29,15.1 3.9,13.71 3.9,12M8,13H16V11H8V13M17,7H13V8.9H17C18.71,8.9 20.1,10.29 20.1,12C20.1,13.71 18.71,15.1 17,15.1H13V17H17A5,5 0 0,0 22,12A5,5 0 0,0 17,7Z"></path>
                          </svg>
                        </span>

                        <span className="gjs-rte-action gjs-rte-disabled" title="" data-tooltip="Wrap for style">
                          <svg viewBox="0 0 24 24">
                            <path fill={isDarkMode ? "white" : "black"} d="M20.71,4.63L19.37,3.29C19,2.9 18.35,2.9 17.96,3.29L9,12.25L11.75,15L20.71,6.04C21.1,5.65 21.1,5 20.71,4.63M7,14A3,3 0 0,0 4,17C4,18.31 2.84,19 2,19C2.92,20.22 4.5,21 6,21A4,4 0 0,0 10,17A3,3 0 0,0 7,14Z"></path>
                          </svg>
                        </span>

                      </div>

                      {openAria === 'hrefText' ? (
                        <div
                          style={{ background: 'transparent', width: "fit-content", height: "fit-content", borderRadius: '2.5vmin' , margin:'0 auto'}}
                        >
                          <ToolbarItem
                            full={true}
                            propKey="href"
                            sm
                            type="select"
                          />
                        </div>
                      ) : null}

                    </div> : null}
                    {node?.data?.props["isButton"] ?
                      <ButtonSettings /> :
                      isActive ? node?.data?.props["isText"]
                        ? <TextSettings /> : node?.data?.props["isPlugin"]
                        ? <WidgetFooter /> : node?.data?.props["isSvg"]
                          ? <SvgIconSettings /> : node?.data?.props["isWrapper"]
                            ? <WrapperSettings /> : node?.data?.props["isImage"]
                              ? <ImageCSettings /> : node?.data?.props["isInput"]
                                ? <InputSettings /> : node?.data?.props["isLogin"]
                                  ? <LoginSettings /> : node?.data.props["isNavBar"] ? 
                                  <NavBarSettings/>
                                  : null : null}
                  </Resizable>
                </div>
              </IndicatorDiv>

            </Draggable>}

            {!node?.data?.props["isButton"] &&
              !node?.data?.props["isText"] &&
              !node?.data?.props["isBlock"] &&
              !node?.data?.props["isSvg"] &&
              !node?.data?.props["isWrapper"] &&
              !node?.data?.props["isNavBar"] &&
              !node?.data?.props["isImage"] &&
              !node?.data?.props["isInput"] &&
              !node?.data?.props["isParent"] &&
              !node?.data?.props["iswidget"] &&
              !node?.data?.props["isLogin"] ? null : <IndicatorDiv
              ref={currentRef}
              className="px-2 py-2 text-white fixed flex items-center itemBox"
              style={{
                left: getPos(dom).left,
                top: getPos(dom).top,
                zIndex: 888,
                height: 'fit-content'
              }}
            >
              <div
                style={{
                  position: "relative", maxWidth: '35vmin', borderRadius: "10px", overflow: "hidden",
                }}
                className={`border shadow-[2px_0px_10px_0px_rgba(164,_164,_164,_0.1)] flex flex-col w-full ${isDarkMode ? 'bg-[#111F3E]' : 'bg-white'}`}
                id="EditRoot">

                <div
                  style={{ borderBottom: '.5vmin solid #e9e9e9', padding: '0 1vmin' }}
                  className={`border-solid border-[#e9e9ea] shadow-sm ${isDarkMode ? 'bg-[#111F3E]' : 'bg-white'} flex flex-row justify-around gap-2 h-20 shrink-0 items-center `}>
                  <div
                    className="flex flex-col h-10 justify-center items-center w-[40%]"
                    id="Button1">

                    <div
                      title="Block Name"
                      style={{ width: 'fit-content' }}
                      className={` py-[5%] rounded-[2.8Vmin] flex mx-auto`}
                      id="Label">

                      <div
                        className={`item-center justify-center text-center font-['Inter'] leading-[1.5vmin] ${isDarkMode ? 'text-[#FFFFFF]' : 'text-[#161717]'}`}
                      >{name}</div>
                    </div>
                  </div>
                  <div className='flex flex-row justify-between w-[60%]'>
                    {name == "Row" && <div
                      onClick={() => {
                        setProp((prop) => {
                          prop.columns = prop.columns + 1
                        }, 500)
                      }}

                      style={{ cursor: "pointer", margin: '0 .30vmin', background: isDarkMode ? "#111F3E" : "white", border: isDarkMode ? "1px solid #E9E9EA" : "1px solid rgba(8, 103, 252, 0.1)" }}
                      className={` ${isDarkMode ? 'bg-[#0867FC4D]' : 'bg-[#e6f0ff]'} flex flex-col justify-center w-12 shrink-0 h-12 items-center rounded-[30px]`}
                      id="IconButton"
                      title="Add New Column"
                    >
                      <img
                        alt='icons'
                        src="https://i.ibb.co/CnyxZWx/Screenshot-2023-10-31-at-12-43-19-PM.png"
                        className="w-8"
                        id="Icons"
                      />
                    </div>}

                    {name == "Column" && <div
                      onClick={() => {
                        setProp((prop) => {
                          prop.rows = prop.rows + 1
                        }, 500)
                      }}

                      style={{ cursor: "pointer", margin: '0 .30vmin', background: isDarkMode ? "#111F3E" : "white", border: isDarkMode ? "1px solid #E9E9EA" : "1px solid rgba(8, 103, 252, 0.1)" }}
                      className={` border ${isDarkMode ? 'bg-[#0867FC4D]' : 'bg-[#e6f0ff]'} flex flex-col justify-center w-12 shrink-0 h-12 items-center rounded-[30px]`}
                      id="IconButton"
                      title="Add New Row"

                    >
                      <img
                        alt='icons'
                        src="https://i.ibb.co/cC7pZxQ/Screenshot-2023-10-31-at-12-43-41-PM.png  "
                        className="w-8"
                        id="Icons"
                      />
                    </div>}


                    {id !== ROOT_NODE && <div
                      onClick={() => {
                        actions.selectNode(parent);
                        setWorking(true)
                      }}

                      style={{ cursor: "pointer", margin: '0 .30vmin' }}
                      className={` ${isDarkMode ? 'bg-[#0867FC4D]' : 'bg-[#e6f0ff]'} flex flex-col justify-center w-12 shrink-0 h-12 items-center rounded-[30px]`}
                      id="IconButton"
                      title="Parent"

                    >
                      <img
                        alt='icons'
                        src="https://file.rendit.io/n/mdW1kcmagl4wSDj50KGA.svg"
                        className="w-6"
                        id="Icons"
                      />
                    </div>}
                    {moveable && <div
                      style={{ cursor: "pointer", margin: '0 .30vmin' }}
                      ref={drag}
                      className={` ${isDarkMode ? 'bg-[#0867FC4D]' : 'bg-[#e6f0ff]'} flex flex-col justify-center w-12 shrink-0 h-12 items-center rounded-[30px]`}
                      id="IconButton2"
                    >
                      <img
                        alt='icons'
                        src="https://file.rendit.io/n/fakTTDLEhANgdwzPj8EN.svg"
                        className="w-6"
                        id="Icons2"
                        title="Move Item"

                      />
                    </div>}

                    {deletable && <div
                      style={{ cursor: "pointer", margin: '0 .30vmin' }}
                      onMouseDown={(e) => {
                        e.stopPropagation();
                        actions.delete(id);
                      }}
                      className={` ${isDarkMode ? 'bg-[#0867FC4D]' : 'bg-[#e6f0ff]'} flex flex-col justify-center w-12 shrink-0 h-12 items-center rounded-[30px]`}
                      id="IconButton3"
                      title="Delete"

                    >
                      <img
                        alt='icons'
                        src="https://file.rendit.io/n/XM9ibrjLzeBNTJtQgfyk.svg"
                        className="w-6"
                        id="Icons3"
                      />
                    </div>}
                    {node?.data?.props?.['isNavBar'] && <div
                      style={{ cursor: "pointer", margin: '0 .30vmin' }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setPopUp(true)
                      }}
                      className={` ${isDarkMode ? 'bg-[#0867FC4D]' : 'bg-[#e6f0ff]'} flex flex-col justify-center w-12 shrink-0 h-12 items-center rounded-[30px]`}
                      id="IconButton3"
                      title="Info"

                    >
                      <img
                        alt='icons'
                        src="https://i.ibb.co/7v5sPmm/icons8-info-50.png"
                        className="w-6"
                        id="Icons3"
                      />
                    </div>}
                  </div>

                  {/* {node?.id == "ROOT" ? <div
                style={{cursor:"pointer"}}
                  className="bg-[rgba(8,_103,_252,_0.1)] flex flex-col justify-center w-12 shrink-0 h-12 items-center rounded-[30px]"
                  id="IconButton3"
                  
                >
                 <FontAwesomeIcon
                className="activeSideBarX"
                icon={faShare}
                style={{cursor:"pointer", color:"rgba(8,103,252"}}        
                onClick={() =>  {
                  dispatch(ACTION_FORMBUILDER(query.serialize()))
                }}
              />
                </div> 
                : null}  */}
                </div>

              </div>


            </IndicatorDiv>}
          </div>,
          document.querySelector('.page-container')
        )
        : null}
      {render}
    </>
  );
};
