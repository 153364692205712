/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, { useEffect, useState, useRef } from 'react';
import { Element, useEditor, useNode } from '@craftjs/core';
import { NewContentSettings } from './newContentSetting';
import { Link } from '@mui/material';
import { Text } from '../../basic/Text';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import { ImageC } from '../../basic/Image';
import { Parent } from '../../../selectors/Parent/index';
import { Wrapper } from '../../../selectors/wrapper/index';
import { BounceLoader } from 'react-spinners';

export const Content_1 = ({ selected }) => {

  const { 
    actions: { setProp },
      connectors: {
        connect
      } 
  } = useNode();

  
  const {
    enabled,
  } = useEditor((state, query) => ({
    enabled: state.options.enabled,
 
  }));



      const styles = [
        <Element canvas is={Parent} id='parentId100' className="w-[100%] h-auto" >
    <Element canvas is={Parent} id='parentId200' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-400' is='parent' style={{width:'100%', background:'white'}}>
            <Wrapper canvas id='random-id-401' is='container' className="flex flex-col container h-[100%] mx-auto" >
                <Wrapper canvas id='random-id-402' is='div' className="flex my-5" style={{justifyContent:'left'}} >
                  <Wrapper canvas style={{ display: "inline-block", fontFamily:'Rubik', fontWeight:'700px' }} className="text-[56px] text-[#100103] font-normal" id="random-id-1" is="div">
                    <Text text="Increase Conversion"/>
                  </Wrapper>
                </Wrapper>
            <Wrapper canvas id='random-id-403' is='div' className="flex w-[auto] h-[320px] justify-center " >
              <Wrapper  canvas style={{display:'inline-block', width:'100%', height:'100%'}} id='random-id-100' is='div'>
                <ImageC className="w-[100%] h-[100%] object-cover"  src="https://i.ibb.co/bPby2DB/imm.jpg"/>
              </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-404' is='container' className="flex my-5 text-center" style={{flexDirection:'row', width:'100%', flexWrap:'wrap'}}>
            <Wrapper canvas id='random-id-405' is='div' className="flex justify-end w-[100%] h-[50px] m-2 pr-6">
            <ImageC src='https://i.ibb.co/kSvqCRj/iconmonstr-quote-right-filled-240.png' style={{width:'40px', height:'40px',paddingBottom:'10px', }}/>                
            </Wrapper>
                <Wrapper canvas id='random-id-406' is='div' className='mx-auto mb-4 ' style={{display:'flex', flexDirection:'column', boxShadow: '0px 4px 10px 0px rgba(255, 255, 255, 0.05)'}}>
                    <Wrapper canvas id='random-id-407' is='div' className="w-[auto] min-w-[100px] min-h-[100px] mb-2">
                      <Wrapper  canvas style={{display:'inline-block', width:'100%', height:'100%'}} id='random-id-101' is='div'>
                        <ImageC  src="https://i.ibb.co/3ycHRvY/Rectangle-1519.png"/>
                      </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-408' is='div' className="w-[200px] text-center mx-auto">                            
                      <Wrapper canvas style={{display: "inline-block", fontFamily:'Rubik', fontWeight:'600px'}} className="font-bold text-[24px] text-[#000]" id="random-id-2" is="div">
                        <Text text="Mark Twain" />
                      </Wrapper>
                      <Wrapper canvas style={{ display: "inline-block", fontFamily:'Rubik', fontWeight:'300px'}} className="font-normal text-[18px] text-[#000]"  id="random-id-3" is="div">
                        <Text text="CEO of Company" />
                      </Wrapper>
                    </Wrapper> 
                    </Wrapper>                    
                    <Wrapper canvas style={{ display: "inline-block", fontWeight:'400px', fontFamily:'Rubik', textAlign:'center' }} className="w-[70%] min-w-[400px] mx-auto text-[#000] text-[20px]" id="random-id-4" is="div">
                      <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />
                    </Wrapper>
            </Wrapper>
        </Wrapper>
        </Wrapper></Element>
    </Element>,

    <Element canvas is={Parent} id='parentId300' className="w-[100%] h-auto" >
    <Element canvas is={Parent} id='parentId400' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-409' is='parent' style={{width:'100%', background:'#121826'}}>
    <Wrapper canvas id='random-id-410' is='container' className="container flex flex-col h-[100%] mx-auto" >
    <Wrapper canvas id='random-id-411' is='div' className="flex my-5" style={{justifyContent:'left'}} >
          <Wrapper canvas style={{ display: "inline-block", fontFamily:'Rubik', fontWeight:'700px' }} className="IncreaseConversion2 text-[56px] text-[white] font-normal" id="random-id-5" is="div">
            <Text text="Increase Conversion." />
          </Wrapper>
    </Wrapper>
    <Wrapper canvas id='random-id-412' is='div' className="flex w-[auto] h-[320px] justify-center " >
      <Wrapper canvas style={{display:'inline-block', width:'100%', height:'100%'}} id='random-id-102' is='div'>
        <ImageC className="w-[100%] h-[100%] object-cover"  src="https://i.ibb.co/bPby2DB/imm.jpg"/>
      </Wrapper>
    </Wrapper>
    <Wrapper canvas id='random-id-413' is='container' className="flex my-5 text-center" style={{flexDirection:'row', width:'100%', flexWrap:'wrap'}}>
    <Wrapper canvas id='random-id-414' is='div' className="flex justify-end w-[100%] h-[50px] m-2 pr-6">
      <ImageC src='https://i.ibb.co/59Fv4zr/iconmonstr-quote-right-filled-240-8.png' style={{width:'40px', height:'40px',paddingBottom:'10px'}}/>
    </Wrapper>
        <Wrapper canvas id='random-id-415' is='div' className='mx-auto mb-4 ' style={{display:'flex', flexDirection:'column', boxShadow: '0px 4px 10px 0px rgba(255, 255, 255, 0.05)'}}>
            <Wrapper canvas id='random-id-416' is='div' className="w-[auto] min-w-[100px] min-h-[100px] mb-2">
              <Wrapper  canvas style={{display:'inline-block', width:'100%', height:'100%'}} id='random-id-103' is='div'>
                <ImageC  src="https://i.ibb.co/3ycHRvY/Rectangle-1519.png"/>
              </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-417' is='div' className="w-[200px] text-center mx-auto">                
                 <Wrapper canvas style={{ display: "inline-block", fontFamily:'Rubik', fontWeight:'600px'}} className="font-bold text-[24px] text-[#FFF]" id="random-id-6" is="div">
                    <Text text="Mark Twain" />
                  </Wrapper>
                 <Wrapper canvas style={{ display: "inline-block", fontFamily:'Rubik', fontWeight:'300px' }} className="font-normal text-[18px] text-[#FFF]" id="random-id-7" is="div">
                    <Text text="CEO of Company" />
                  </Wrapper>
            </Wrapper> 
        </Wrapper>
        <Wrapper canvas style={{ display: "inline-block", fontWeight:'400px', fontFamily:'Rubik', textAlign:'center' }} className="w-[70%] min-w-[400px] mx-auto text-[#FFF] text-[20px] " id="random-id-8" is="div">
          <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />
        </Wrapper>
    </Wrapper>
    </Wrapper>
    </Wrapper></Element>
    </Element>,



<Element canvas is={Parent} id='parentId500' className="w-[100%] h-auto" >
    <Element canvas is={Parent} id='parentId600' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-418' is='parent' style={{width:'100%', background:'#213156'}}>
    <Wrapper canvas id='random-id-419' is='container' className="flex flex-col container h-[100%] mx-auto">
    <Wrapper className="flex my-5" style={{justifyContent:'left'}} >
        <Wrapper canvas style={{ display: "inline-block",  fontFamily:'Yeseva One', fontWeight:'700px' }} className="text-[56px] text-[#EA6EF4] font-normal" id="random-id-9" is="div">
          <Text text="Increase Conversion" />
        </Wrapper></Wrapper>
    <Wrapper canvas id='random-id-420' is='div' className="flex w-[auto] h-[320px] justify-center">
      <Wrapper canvas style={{display:'inline-block', width:'100%', height:'100%'}} id='random-id-104' is='div'>
        <ImageC className="w-[100%] h-[100%] object-cover" style={{borderRadius: '40px 0px'}}   src="https://i.ibb.co/bPby2DB/imm.jpg"/>
      </Wrapper>
    </Wrapper>
    <Wrapper canvas id='random-id-421' is='container' className="flex my-5 text-center" style={{flexDirection:'row', width:'100%', flexWrap:'wrap'}}>
    <Wrapper className="flex justify-end w-[100%] h-[50px] m-2 pr-6 ">
      <ImageC src='https://i.ibb.co/6J9Jt74/iconmonstr-quote-right-filled-240-2.png' sx={{width:'50px', height:'50px', paddingBottom:'10px'}}/>    
    </Wrapper>
    <Wrapper canvas id='random-id-422' is='div' className='mx-auto mb-4 ' style={{display:'flex', flexDirection:'column', boxShadow: '0px 4px 10px 0px rgba(255, 255, 255, 0.05)'}}>
            <Wrapper canvas id='random-id-423' is='div' className="w-[auto] min-w-[100px] min-h-[100px] mb-2">
              <Wrapper  canvas style={{display:'inline-block', width:'100%', height:'100%'}} id='random-id-105' is='div'>
                <ImageC  src="https://i.ibb.co/3ycHRvY/Rectangle-1519.png"/>
              </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-424' is='div' className="w-[200px] text-center mx-auto">                
                 <Wrapper canvas style={{ display: "inline-block", fontFamily:'Open Sans', fontWeight:'600px'}}  className="font-bold text-[24px] text-[#FFF]" id="random-id-10" is="div">
                    <Text text="Mark Twain" />
                  </Wrapper>
                 <Wrapper canvas style={{ display: "inline-block", fontFamily:'Open Sans', fontWeight:'300px' }} className="font-normal text-[18px] text-[#FFF]" id="random-id-11" is="div">
                    <Text text="CEO of Company" />
                  </Wrapper>
            </Wrapper> 
        </Wrapper>        
         <Wrapper canvas style={{ display: "inline-block", fontWeight:'400px', fontFamily:'Open Sans', textAlign:'center' }} className="w-[70%] min-w-[400px] mx-auto text-[#FFF] text-[20px] " id="random-id-12" is="div">
                    <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />
                  </Wrapper>
    </Wrapper>
    </Wrapper>
    </Wrapper></Element>
    </Element>,



    <Element canvas is={Parent} id='parentId700' className="w-[100%] h-auto" >
    <Element canvas is={Parent} id='parentId800' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-425' is='parent' style={{width:'100%', background:'#DFFFF9'}}>
        <Wrapper canvas id='random-id-426' is='container' className="flex flex-col container h-[100%] mx-auto">
        <Wrapper canvas id='random-id-427' is='div' className="flex my-5" style={{justifyContent:'left'}} >
            <Wrapper canvas style={{ display: "inline-block", fontFamily:'Zing Script Rust SemiBold Demo', fontWeight:'700px' }} className="text-[56px] text-[#31A993] font-normal" id="random-id-13" is="div">
                    <Text text="Increase Conversion" />
                  </Wrapper>
        </Wrapper>
        <Wrapper canvas id='random-id-428' is='div' className="flex w-[auto] h-[320px] justify-center">
          <Wrapper  canvas style={{display:'inline-block', width:'100%', height:'100%'}} id='random-id-106' is='div'>
            <ImageC className="w-[100%] h-[100%] object-cover" style={{borderRadius: '10px'}}   src="https://i.ibb.co/bPby2DB/imm.jpg"/>
          </Wrapper>
        </Wrapper>
        <Wrapper canvas id='random-id-429' is='container' className="flex my-5 text-center" style={{flexDirection:'row', width:'100%', flexWrap:'wrap'}}>
        <Wrapper canvas id='random-id-430' is='div' className="flex justify-end w-[100%] h-[50px] m-2 pr-6 ">
          <ImageC src='https://i.ibb.co/QPh3GVr/iconmonstr-quote-right-filled-240-7.png' sx={{width:'50px', height:'50px', paddingBottom:'10px'}}/>
        </Wrapper>
        <Wrapper canvas id='random-id-431' is='div' className='mx-auto mb-4 ' style={{display:'flex', flexDirection:'column', boxShadow: '0px 4px 10px 0px rgba(255, 255, 255, 0.05)'}}>
                <Wrapper canvas id='random-id-432' is='div' className="w-[auto] min-w-[100px] min-h-[100px] mb-2">
                  <Wrapper  canvas style={{display:'inline-block', width:'100%', height:'100%'}} id='random-id-107' is='div'>
                    <ImageC  style={{borderRadius:'10px'}} src="https://i.ibb.co/3ycHRvY/Rectangle-1519.png"/>
                  </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-433' is='div' className="w-[200px] text-center mx-auto">                    
                     <Wrapper canvas style={{ display: "inline-block", fontFamily:'Montserrat', fontWeight:'600px'}} className="font-bold text-[24px] text-[#031815]" id="random-id-14" is="div">
                    <Text text="Mark Twain" />
                  </Wrapper>
                     <Wrapper canvas style={{ display: "inline-block", fontFamily:'Montserrat', fontWeight:'300px'}} className="font-normal text-[18px] text-[#031815]" id="random-id-15" is="div">
                    <Text text="CEO of Company" />
                  </Wrapper>
                </Wrapper> 
            </Wrapper>            
             <Wrapper canvas style={{ display: "inline-block", fontWeight:'400px', fontFamily:'Montserrat', textAlign:'center'}} className="w-[70%] min-w-[400px] mx-auto text-[#031815] text-[20px] " id="random-id-16" is="div">
                    <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />
                  </Wrapper>
        </Wrapper>
        </Wrapper>
    </Wrapper></Element>
    </Element>,



    <Element canvas is={Parent} id='parentId900' className="w-[100%] h-auto" >
    <Element canvas is={Parent} id='parentId1000' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-434' is='parent' style={{width:'100%', background:'#FFF'}}>
    <Wrapper canvas id='random-id-435' is='container' className="flex flex-col container h-[100%] mx-auto" >
    <Wrapper canvas id='random-id-436' is='div' className="flex my-5" style={{justifyContent:'left'}} >
          <Wrapper canvas style={{ display: "inline-block", fontFamily:'Rubik', fontWeight:'700px' }} className=" text-[56px] text-[#0078D3] font-normal" id="random-id-17" is="div">
                    <Text text="Increase Conversion" />
                  </Wrapper>
    </Wrapper>
    <Wrapper canvas id='random-id-437' is='div' className="flex w-[auto] h-[320px] justify-center " >
      <Wrapper  canvas style={{display:'inline-block', width:'100%', height:'100%'}} id='random-id-108' is='div'>
        <ImageC className="w-[100%] h-[100%] object-cover"  src="https://i.ibb.co/bPby2DB/imm.jpg"/>
      </Wrapper>
    </Wrapper>
    <Wrapper canvas id='random-id-438' is='container' className="flex my-5 text-center" style={{flexDirection:'row', width:'100%', flexWrap:'wrap'}}>
    <Wrapper canvas id='random-id-439' is='div' className="flex justify-end w-[100%] h-[50px] m-2 pr-6 ">
      <ImageC src='https://i.ibb.co/DMxCqch/iconmonstr-quote-right-filled-240-6.png' sx={{width:'40px', height:'50px', paddingBottom:'10px'}}/>
    </Wrapper>
    <Wrapper canvas id='random-id-440' is='div' className='mx-auto mb-4 ' style={{display:'flex', flexDirection:'column', boxShadow: '0px 4px 10px 0px rgba(255, 255, 255, 0.05)'}}>
            <Wrapper canvas id='random-id-441' is='div' className="w-[auto] min-w-[100px] min-h-[100px] mb-2">
              <Wrapper  canvas style={{display:'inline-block', width:'100%', height:'100%'}} id='random-id-109' is='div'>
                <ImageC  src="https://i.ibb.co/3ycHRvY/Rectangle-1519.png"/>
              </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-442' is='div' className="w-[200px] text-center mx-auto">                
                 <Wrapper canvas style={{ display: "inline-block", fontFamily:'Rubik', fontWeight:'600px' }} className="font-bold text-[24px] text-[#020E17]" id="random-id-18" is="div">
                    <Text text="Mark Twain" />
                  </Wrapper>
                 <Wrapper canvas style={{ display: "inline-block", fontFamily:'Rubik', fontWeight:'300px' }} className="font-normal text-[18px] text-[#020E17]" id="random-id-19" is="div">
                    <Text text="CEO of Company" />
                  </Wrapper>
            </Wrapper> 
        </Wrapper>        
         <Wrapper canvas style={{ display: "inline-block", fontWeight:'400px', fontFamily:'Rubik', textAlign:'center' }} className="w-[70%] min-w-[400px] mx-auto text-[#020E17] text-[20px] " id="random-id-20" is="div">
                    <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />
                  </Wrapper>
    </Wrapper>
    </Wrapper>
    </Wrapper></Element>
    </Element>,
        


    <Element canvas is={Parent} id='parentId1100' className="w-[100%] h-auto" >
    <Element canvas is={Parent} id='parentId1200' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-443' is='parent' style={{width:'100%', background:'#020212'}}>
    <Wrapper canvas id='random-id-444' is='container' className="flex flex-col w-100 h-[100%] mx-auto" >
    <Wrapper canvas id='random-id-445' is='div' className="flex w-[90%] mx-auto my-5" style={{justifyContent:'left'}} >
          <Wrapper canvas style={{ display: "inline-block", fontFamily:'Rubik', fontWeight:'700px' }} className="text-[56px] text-[white] font-normal"  id="random-id-21" is="div">
                    <Text text="Increase Conversion" />
                  </Wrapper>
    </Wrapper>
    <Wrapper canvas id='random-id-446' is='div' className="flex w-[auto] h-[320px] justify-center border" style={{padding:'10px 0', background:'white'}} >
      <Wrapper  canvas style={{display:'inline-block', width:'100%', height:'100%'}} id='random-id-110' is='div'>
        <ImageC className="w-[98%] object-cover"  src="https://i.ibb.co/bPby2DB/imm.jpg"/>
      </Wrapper>
    </Wrapper>
    <Wrapper canvas id='random-id-447' is='container' className="flex my-5 text-center" style={{flexDirection:'row', width:'100%', flexWrap:'wrap'}}>
        <Wrapper canvas id='random-id-448' is='div' className="flex justify-end w-[100%] h-[50px] m-2 pr-6 ">
          <ImageC src='https://i.ibb.co/Xk7S30V/iconmonstr-quote-right-filled-240-5.png' sx={{width:'50px', height:'50px', paddingBottom:'10px'}}/>
        </Wrapper>
            <Wrapper canvas id='random-id-449' is='div' className="w-[auto] min-w-[100px] min-h-[100px] mb-2">
              <Wrapper  canvas style={{display:'inline-block', width:'100%', height:'100%'}} id='random-id-111' is='div'>
                <ImageC  src="https://i.ibb.co/3ycHRvY/Rectangle-1519.png"/>
              </Wrapper>
            </Wrapper>
            <Wrapper id='random-id-450' is='div' className="w-[200px] text-center mx-auto">                
                 <Wrapper canvas style={{ display: "inline-block", fontFamily:'Rubik', fontWeight:'600px' }} className="font-bold text-[24px] text-[white]" id="random-id-22" is="div">
                    <Text text="Mark Twain" />
                  </Wrapper>
                 <Wrapper canvas style={{ display: "inline-block", fontFamily:'Rubik', fontWeight:'300px' }} className="font-normal text-[18px] text-[white]"  id="random-id-23" is="div">
                    <Text text="CEO of Company" />
                  </Wrapper>
            </Wrapper>         
          <Wrapper canvas style={{ display: "inline-block", fontWeight:'400px', fontFamily:'Rubik', textAlign:'center' }} className="w-[70%] min-w-[400px] mx-auto text-[white] text-[20px] "  id="random-id-24" is="div">
            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />
          </Wrapper>
    </Wrapper>
    </Wrapper>
    </Wrapper></Element>
    </Element>,




   <Element canvas is={Parent} id='parentId1300' className="w-[100%] h-auto" >
    <Element canvas is={Parent} id='parentId1400' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-451' is='parent' style={{width:'100%', background:'#FFF', padding:'5px' }}> 
   <Wrapper canvas id='random-id-452' is='container'  className="flex flex-col w-100 h-[100%] mx-auto" >
   <Wrapper canvas id='random-id-453' is='div' className="flex" style={{justifyContent:'left', marginLeft:'8px'}} >
       <Wrapper canvas style={{ display: "inline-block", fontFamily:'Zing Script Rust SemiBold Demo', fontWeight:'bold' }} className="text-[46px] text-[#6750A4] font-normal" id="random-id-25" is="div">
          <Text text="Increase Conversion" />
        </Wrapper>
   </Wrapper>
   <Wrapper canvas id='random-id-454' is='wrapper' className="flex flex-col w-[100%] h-[100%] mx-auto " style={{padding:'10px', borderRadius:'40px'}}>   
   <Wrapper canvas id='random-id-455' is='div' className="flex w-[auto] h-[320px] justify-center">
    <Wrapper  canvas style={{display:'inline-block', width:'100%', height:'100%'}} id='random-id-112' is='div'>
      <ImageC className="w-full object-cover" style={{borderRadius: '30px'}}   src="https://i.ibb.co/bPby2DB/imm.jpg"/>
    </Wrapper>
   </Wrapper> 
   <Wrapper canvas id='random-id-456' is='container' className="flex my-5 text-center " style={{flexDirection:'row', width:'100%', flexWrap:'wrap'}}>
   <Wrapper canvas id='random-id-457' is='div' className="flex justify-end w-[100%] h-[50px] pr-6">
           <ImageC src='https://i.ibb.co/WGPbbK4/iconmonstr-quote-right-filled-240-4.png' sx={{width:'50px', height:'50px', paddingBottom:'10px'}}/>
       </Wrapper>
   <Wrapper canvas id='random-id-458' is='div' className='mx-auto mb-4 ' style={{display:'flex', flexDirection:'column'}}>
           <Wrapper canvas id='random-id-459' is='div' className="w-auto min-w-[100px] min-h-[100px] mb-2" > 
           <Wrapper  canvas style={{display:'inline-block', width:'100%', height:'100%'}} id='random-id-113' is='div'>
              <ImageC  style={{borderRadius:'20px'}} src="https://i.ibb.co/3ycHRvY/Rectangle-1519.png"/>
           </Wrapper>
           </Wrapper>
           <Wrapper canvas id='random-id-460' is='div' className="w-[200px] text-center mx-auto">             
             <Wrapper canvas style={{ display: "inline-block", fontFamily:'Roboto', fontWeight:'600px' }} className="font-bold text-[24px] text-[#6750A4]" id="random-id-26" is="div">
                    <Text text="Mark Twain" />
                  </Wrapper>
             <Wrapper canvas style={{ display: "inline-block", fontFamily:'Roboto', fontWeight:'300px' }}  className="font-normal text-[18px] text-[#100103]" id="random-id-27" is="div">
                    <Text text="CEO of Company" />
                  </Wrapper>
           </Wrapper> 
       </Wrapper>       
        <Wrapper canvas style={{ display: "inline-block", fontWeight:'400px', fontFamily:'Roboto', textAlign:'left', paddingLeft:'20px' }} className="w-[70%] min-w-[400px] mx-auto text-[#100103] text-[22px] " id="random-id-28" is="div">
                    <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />
                  </Wrapper>
   </Wrapper> 
   </Wrapper>
   </Wrapper> 
</Wrapper></Element>
    </Element>,

<Element canvas is={Parent} id='parentId1500' className="w-[100%] h-auto" >
    <Element canvas is={Parent} id='parentId1600' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-900' is='parent' style={{width:'100%', background:'#2A2A2A'}}>
    <Wrapper canvas id='random-id-901' is='container' className=" container flex flex-col w-[100%] h-[100%] mx-auto" >
    <Wrapper canvas id='random-id-902' is='div' className="flex my-5">
        <Wrapper canvas style={{ display: "inline-block", fontFamily:'Rum Raisin', fontWeight:'400px' }} className=" text-[46px] text-[#FFD600] text-center mx-auto font-normal" id="random-id-903" is="div">
        <Text text="Increase Conversion" />
      </Wrapper>
    </Wrapper>
    <Wrapper canvas id='random-id-904' is='div' className="flex w-[full] h-[320px] justify-center" style={{borderRadius:'10px'}} >
      <Wrapper  canvas style={{display:'inline-block', width:'100%', height:'100%'}} id='random-id-905' is='div'>
        <ImageC className="w-[100%] h-[100%] object-cover"  src="https://i.ibb.co/bPby2DB/imm.jpg"/>
      </Wrapper>
    </Wrapper>
    <Wrapper canvas id='random-id-906' is='container' className="flex my-5 text-center" style={{flexDirection:'row', width:'100%', flexWrap:'wrap'}}>
    <Wrapper canvas id='random-id-907' is='div' className="flex justify-end w-[100%] h-[50px] m-2 pr-6 ">
      <ImageC src='https://i.ibb.co/dDGcTsY/iconmonstr-quote-right-filled-240-3.png' sx={{width:'50px', height:'50px', paddingBottom:'10px'}}/>
    </Wrapper>
    <Wrapper canvas id='random-id-908' is='div' className='mx-auto' style={{display:'flex', flexDirection:'column', boxShadow: '0px 4px 10px 0px rgba(255, 255, 255, 0.05)'}}>
            <Wrapper canvas id='random-id-909' is='div' className="w-[auto] min-w-[100px] min-h-[100px] mb-2">
              <Wrapper  canvas style={{display:'inline-block', width:'100%', height:'100%'}} id='random-id-910' is='div'>
                <ImageC  src="https://i.ibb.co/3ycHRvY/Rectangle-1519.png"/>
              </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-911' is='div' className="w-[200px] text-center mx-auto">                
                 <Wrapper canvas style={{ display: "inline-block", fontFamily:'Rubik', fontWeight:'600px' }} className="font-bold text-[24px] text-[#FFD600]" id="random-id-912" is="div">
                    <Text text="Mark Twain" />
                  </Wrapper>
                 <Wrapper canvas style={{ display: "inline-block", fontFamily:'Rubik', fontWeight:'300px' }} className="font-normal text-[18px] text-[#FFFFFF]" id="random-id-913" is="div">
                    <Text text="CEO of Company" />
                  </Wrapper>
            </Wrapper> 
        </Wrapper>
        
         <Wrapper canvas style={{ display: "inline-block", fontWeight:'400px', fontFamily:'Rubik', textAlign:'center' }} className="w-[70%] min-w-[400px] mx-auto text-[#FFFFFF] text-[22px] " id="random-id-914" is="div">
                    <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />
                  </Wrapper>
    </Wrapper>
    </Wrapper>
    </Wrapper></Element>
    </Element>,

    <Element canvas is={Parent} id='parentId1700' className="w-[100%] h-auto" >
    <Element canvas is={Parent} id='parentId1800' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-915' is='parent' style={{width:'100%', background:'#F7FBFE', padding:'5px' }}> 
   <Wrapper canvas id='random-id-916' is='container'  className="flex flex-col w-100 h-[100%] mx-auto" >
   <Wrapper canvas id='random-id-917' is='div' className="flex" style={{justifyContent:'left', marginLeft:'8px'}} >
       <Wrapper canvas style={{ display: "inline-block", fontFamily:'Roboto', fontWeight:'700' }} className="text-[46px] text-[#6064D2] font-normal" id="random-id-918" is="div">
          <Text text="Increase Conversion" />
        </Wrapper>
   </Wrapper>
   <Wrapper canvas id='random-id-919' is='wrapper' className="flex flex-col w-[100%] h-[100%] mx-auto " style={{padding:'10px', borderRadius:'40px'}}>   
   <Wrapper canvas id='random-id-920' is='div' className="flex w-[auto] h-[320px] justify-center">
    <Wrapper  canvas style={{display:'inline-block', width:'100%', height:'100%', borderRadius:'10px'}} id='random-id-921' is='div'>
      <ImageC className="w-full object-cover" style={{borderRadius: '10px'}}   src="https://i.ibb.co/bPby2DB/imm.jpg"/>
    </Wrapper>
   </Wrapper> 
   <Wrapper canvas id='random-id-922' is='container' className="flex my-5 text-center " style={{flexDirection:'row', width:'100%', flexWrap:'wrap'}}>
   <Wrapper canvas id='random-id-923' is='div' className="flex justify-end w-[100%] h-[50px] pr-6" style={{borderRadius:'10px'}}>
           <ImageC src='https://i.ibb.co/WGPbbK4/iconmonstr-quote-right-filled-240-4.png' sx={{width:'50px', height:'50px', paddingBottom:'10px'}}/>
       </Wrapper>
   <Wrapper canvas id='random-id-923' is='div' className='mx-auto mb-4 ' style={{display:'flex', flexDirection:'column'}}>
           <Wrapper canvas id='random-id-925' is='div' className="w-auto min-w-[100px] min-h-[100px] mb-2" > 
           <Wrapper  canvas style={{display:'inline-block', width:'100%', height:'100%'}} id='random-id-926' is='div'>
              <ImageC  style={{borderRadius:'20px'}} src="https://i.ibb.co/3ycHRvY/Rectangle-1519.png"/>
           </Wrapper>
           </Wrapper>
           <Wrapper canvas id='random-id-927' is='div' className="w-[200px] text-center mx-auto">             
             <Wrapper canvas style={{ display: "inline-block", fontFamily:'Roboto', fontWeight:'600px' }} className="font-bold text-[24px] text-[#6064D2]" id="random-id-928" is="div">
                    <Text text="Mark Twain" />
                  </Wrapper>
             <Wrapper canvas style={{ display: "inline-block", fontFamily:'Roboto', fontWeight:'300px' }}  className="font-normal text-[18px] text-[#100103]" id="random-id-929" is="div">
                    <Text text="CEO of Company" />
                  </Wrapper>
           </Wrapper> 
       </Wrapper>       
        <Wrapper canvas style={{ display: "inline-block", fontWeight:'400px', fontFamily:'Roboto', textAlign:'left', paddingLeft:'20px' }} className="w-[70%] min-w-[400px] mx-auto text-[#100103] text-[22px] " id="random-id-28" is="div">
                    <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />
                  </Wrapper>
   </Wrapper> 
   </Wrapper>
   </Wrapper> 
</Wrapper></Element>
    </Element>,


<Element canvas is={Parent} id='parentId1900' className="w-[100%] h-auto" >
    <Element canvas is={Parent} id='parentId2000' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-930' is='parent' style={{width:'100%', background:'#FEFBF7', padding:'5px' }}> 
<Wrapper canvas id='random-id-931' is='container'  className="flex flex-col w-100 h-[100%] mx-auto" >
<Wrapper canvas id='random-id-932' is='div' className="flex" style={{justifyContent:'left', marginLeft:'8px'}} >
    <Wrapper canvas style={{ display: "inline-block", fontFamily:'Ruluko', fontWeight:'700' }} className="text-[46px] text-[#A25738] font-normal" id="random-id-933" is="div">
       <Text text="Increase Conversion" />
     </Wrapper>
</Wrapper>
<Wrapper canvas id='random-id-934' is='wrapper' className="flex flex-col w-[100%] h-[100%] mx-auto " style={{padding:'10px', borderRadius:'40px'}}>   
<Wrapper canvas id='random-id-935' is='div' className="flex w-[auto] h-[320px] justify-center">
 <Wrapper  canvas style={{display:'inline-block', width:'100%', height:'100%', borderRadius:'30px 0px'}} id='random-id-936' is='div'>
   <ImageC className="w-full object-cover" style={{borderRadius: '30px 0px'}}   src="https://i.ibb.co/bPby2DB/imm.jpg"/>
 </Wrapper>
</Wrapper> 
<Wrapper canvas id='random-id-936' is='container' className="flex my-5 text-center " style={{flexDirection:'row', width:'100%', flexWrap:'wrap'}}>
<Wrapper canvas id='random-id-937' is='div' className="flex justify-end w-[100%] h-[50px] pr-6">
        <ImageC src='https://i.ibb.co/thgcjfF/iconmonstr-quote-right-filled-240-9.png' sx={{width:'50px', height:'50px'}}/>
    </Wrapper>
<Wrapper canvas id='random-id-938' is='div' className='mx-auto mb-4 ' style={{display:'flex', flexDirection:'column'}}>
        <Wrapper canvas id='random-id-939' is='div' className="w-auto min-w-[100px] min-h-[100px] mb-2" > 
        <Wrapper  canvas style={{display:'inline-block', width:'100%', height:'100%'}} id='random-id-940' is='div'>
           <ImageC  style={{borderRadius:'20px'}} src="https://i.ibb.co/3ycHRvY/Rectangle-1519.png"/>
        </Wrapper>
        </Wrapper>
        <Wrapper canvas id='random-id-941' is='div' className="w-[200px] text-center mx-auto">             
          <Wrapper canvas style={{ display: "inline-block", fontFamily:'Roboto', fontWeight:'600px' }} className="font-bold text-[24px] text-[#A25738]" id="random-id-942" is="div">
                 <Text text="Mark Twain" />
               </Wrapper>
          <Wrapper canvas style={{ display: "inline-block", fontFamily:'Roboto', fontWeight:'300px' }}  className="font-normal text-[18px] text-[#100103]" id="random-id-943" is="div">
                 <Text text="CEO of Company" />
               </Wrapper>
        </Wrapper> 
    </Wrapper>       
     <Wrapper canvas style={{ display: "inline-block", fontWeight:'400px', fontFamily:'Roboto', textAlign:'left', paddingLeft:'20px' }} className="w-[70%] min-w-[400px] mx-auto text-[#100103] text-[22px] " id="random-id-28" is="div">
                 <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />
               </Wrapper>
</Wrapper> 
</Wrapper>
</Wrapper> 
</Wrapper></Element>
    </Element>,
    
    ]


    
    const [sel, setSel] = useState(selected)

    function makeOdd(number) {
      setProp((prop) => {
        setSel(100);
      }, 1000);

      setTimeout(() => {
        setProp((prop) => {
          setSel(number)
        }, 1000);
      }, 100);


      
  }

  useEffect(() => {
    if(selected || selected >= 0) {
      makeOdd(selected);
    }
  },[selected])


  return (
    <div style={{display:"flex", padding: enabled? "10px" : "0px", justifyContent:"center", alignItems:"center", width:'100%', height:'100%'}} ref={connect}>
    {sel == 100 ? (
          <div style={{height:"85vh", display:"flex", justifyContent:"center", alignItems:"center"}}>
          <BounceLoader
                  size={100}
                  color={"white"}
                  loading={true}
                />
          </div>
         ) : (
          styles[sel]
         )} 
</div>
  );
};

Content_1.craft = {
    displayName: "Content 1",
    props: {
       selected: 1, // here is the default value of the selected style
       length:9,
isBlock: true
      },
      rules: {
        canDrag: () => true,
        canDrop: () => true,
      },
      related: {
        toolbar: NewContentSettings,
      },
  };