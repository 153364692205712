/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import { useNode, useEditor, Element } from '@craftjs/core';
import React, { useEffect, useState } from 'react';
import ContentEditable from 'react-contenteditable';
import { useActiveIdContext } from '../../../../ActiveIdContext';
import { useOptionsContext } from '../../../../OptionsContext';
import { SvgIcon } from '../Svg';
import { Wrapper } from '../../wrapper/index.js';
import Draggable from 'react-draggable';
import { TextSettings } from '../Text/TextSettings.js';


export const Heading = ({
  fontSize,
  textAlign,
  fontWeight,
  color = '#FFFFFF',
  text,
  margin,
  href,
  selected,
  fontStyle,
  isBasic,
  textDecoration,
  width,
  fontFamily,
  textTransform,
  lineHeight,
  onclick,
  marginTop,
  marginRight,
  marginBottom,
  marginLeft,
  isSvg = false,
  ableToMove,
  transition,
  shadowX = '0px',
  shadowY = '2px',
  shadowBlur = '4px',
  shadowColor,
  background,
  headingNum = 'h1',
  menu,
}) => {
  const {
    connectors: { connect },
    actions: { setProp },

  } = useNode((node) => ({
    selected: node.events.selected,
  }));


  const {
    nodes,
    enabled,
  } = useEditor((state) => ({
    enabled: state.options.enabled,
    nodes: state.nodes
  }));


  const { state, dispatch: setActiveId } = useActiveIdContext();
  const { activeId: activeId } = state;
  const { state: idState, dispatch: idDispatch } = useOptionsContext();
  const { options: treeData } = idState;

  const handleProperty = (value, propertyName, split) => {
    if (value !== null && !split) {
      return { [propertyName]: value?.split(" ").join("") }
    }

    if (value !== null && split === 'splitBg') {
      return { [propertyName]:  value !== null ? value : '' }
    } else {
      return;
    }
  };


  const styleProps = {

      ...(color !== null && {
        color: color?.startsWith('#') ? color : 'transparent',
        backgroundImage: !color?.startsWith('#') ? color : undefined,
        WebkitBackgroundClip: !color?.startsWith('#') ? 'text' : undefined,
        backgroundClip: !color?.startsWith('#') ? 'text' : undefined,
      }),


      ...((shadowX || shadowY || shadowColor || shadowBlur) !== null ? {
        textShadow: `${(shadowX?.split(" ").join(""))} ${(shadowY?.split(" ").join(""))} ${(shadowBlur?.split(" ").join(""))} ${shadowColor || `rgba(255, 255, 255)`}`
    } : null),
  
    ...handleProperty(width, 'width'),
    ...handleProperty(background, 'background', 'splitBg'),

    ...handleProperty(fontSize, 'fontSize'),
    ...handleProperty(textTransform, 'textTransform'),
    ...handleProperty(marginTop, 'marginTop'),
    ...handleProperty(marginRight, 'marginRight'),
    ...handleProperty(marginBottom, 'marginBottom'),
    ...handleProperty(marginLeft, 'marginLeft'),
    ...handleProperty(fontFamily, 'fontFamily'),
    ...handleProperty(textDecoration, 'textDecoration'),
    ...handleProperty(fontStyle, 'fontStyle'),
    ...handleProperty(textAlign, 'textAlign'),
    ...handleProperty(fontWeight, 'fontWeight'),
    ...handleProperty(lineHeight, 'lineHeight'),
  };

  
  const headingTags = {
    'Heading one': 'h1',
    'Two': 'h2',
    'Three': 'h3',
    'Four': 'h4',
    'Five': 'h5',
    'Six: Smallest' : 'h6'
};

const tagName = headingNum !== null ? headingTags[headingNum] : 'div';


  return (
    <>
      
            <ContentEditable
              val={href?.name} _id="sidebarItemx"
              onClick={(e) => {
                e.preventDefault(); // Prevent default action of anchor tag

                if (!enabled) {
                  if (href && href._id && href._id.includes("www")) {
                  // If href exists and href._id includes "www", open a new tab with the URL
                  window.open("https://" + href.name, "_blank");
                } else if (href && href._id && href.name === 'dom') {
                  const element = Object.values(nodes).find((node) => node.id === href._id);

                  if (element) {

                    if (element.dom) {
                      element.dom.scrollIntoView({ behavior: 'smooth', block: 'start' });
                    } else {
                      console.log("DOM Element not found for ID:", element.id);
                    }
                  } else {
                    console.log("Element with ID not found:", href.id);
                  }
                  }
                  else if (href && href.name !== 'dom') {
                    // Find the item in treeData based on href._id
                    const parentItem = treeData.find((x) => x._id === href._id);
                    if (parentItem) {
                      // Set activeId to the parent item if found
                      setActiveId(parentItem);
                    } else {
                      // If parent item not found, find the child item in treeData
                      let childItem = null;
                      for (const parent of treeData) {
                        const child = parent.children.find((child) => child._id === href._id);
                        if (child) {
                          childItem = child;
                          break;
                        }
                      }
                      // Set activeId to the child item if found
                      setActiveId(childItem);
                    }
                  }
                }
              }}
            
            
            innerRef={connect}
            html={text} // innerHTML of the editable div
            disabled={!enabled}
            onChange={(e) => {
              setProp((prop) => (prop.text = e.target.value), 500);
            }}
            tagName={tagName}
            style={{
              ...styleProps, cursor: href && !enabled ? "pointer" : "",
              borderRadius: enabled ? '8px' : '', 
              // transition: transition ? `${transition}` : '',
            }}
            className={(href && href.name && activeId && activeId?.name && text) && (href.name.toLowerCase() === activeId?.name.toLowerCase() || text.toLowerCase() === activeId?.name.toLowerCase()) ? selected : ""}

          />

    </>
      );
};

Heading.craft = {
  displayName: 'Heading',
  props: {
    width: null,
    fontSize: null,
    textAlign: null,
    fontWeight: null,
    color: '#FFFFFF',
    margin: null,
    shadow: null,
    marginTop: null,
    marginRight: null,
    marginBottom: null,
    marginLeft: null,
    text: 'Type Here',
    headingNum: 'h1',
    href: null,
    lineHeight: null,
    fontStyle: "normal",
    textDecoration: null,
    fontFamily: null,
    isHeading: true,
    isBasic: false,
    isSvg: false,
    shadowX: null,
    shadowY: null,
    shadowColor:null,
    shadowBlur: null,
    transition: null,
    background:null,
    textTransform: null,
  },
  rules: {
    canDrag: () => true,
  },
  related: {
    toolbar: TextSettings,
  },
};
