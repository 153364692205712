/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React from "react";
import '../../bot.css'

// const StyledButton = styled.button`
//   padding: 0.5rem 1rem;
//   border: 1px solid rgb(var(--primary-color)); 
//   color: rgb(var(--primary-color));
//   border-radius: 5rem;
//   transition: 0.15s;
//   &:active {
//     transform: scale(0.95);
//   }
// `;

const GotIt = (props) => {
  const options = [
    {
      name: "FeedBack",
      handler: () => props.actionProvider.handleGotIt('FeedBack'),
      id: 1,
    },
    {
      name: "Bug",
      handler: () => props.actionProvider.handleGotIt('Bug'),
      id: 2,
    },
    {
      name: "Complain",
      handler: () => props.actionProvider.handleGotIt('Complain'),
      id: 3,
    },
  ];
  return (
    <div className="options-container">
      {options.map((option) => {
        return (
          <div
            key={option.id}
            className="option-button"
            onClick={option.handler}
          >
            {option.name}
          </div>
        );
      })}
    </div>
  );
};

export default GotIt;
