// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-img {
    display: inline-block;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-size: 32px 35px;
    background-position: center center;
    vertical-align: middle;
    line-height: 32px;
    box-shadow: inset 0 0 1px #999, inset 0 0 10px rgba(0, 0, 0, 0.2);
    margin-left: 5px;
}

.k-rating-item.k-selected  {
    color: #ffa600;
}

.no-border-sparkline svg>g>path:first-child{
   fill: none!important;
 }

 .no-border-sparkline {
    width: 100%;
 }

 .no-border-sparkline .k-sparkline{
    width: 100%!important;
 }

 .k-badge-container {
     margin-left: 30px;
     margin-bottom: 5px;
 }`, "",{"version":3,"sources":["webpack://./src/components/widgets/datagrid/style.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,0BAA0B;IAC1B,kCAAkC;IAClC,sBAAsB;IACtB,iBAAiB;IACjB,iEAAiE;IACjE,gBAAgB;AACpB;;AAEA;IACI,cAAc;AAClB;;AAEA;GACG,oBAAoB;CACtB;;CAEA;IACG,WAAW;CACd;;CAEA;IACG,qBAAqB;CACxB;;CAEA;KACI,iBAAiB;KACjB,kBAAkB;CACtB","sourcesContent":[".contact-img {\n    display: inline-block;\n    width: 32px;\n    height: 32px;\n    border-radius: 50%;\n    background-size: 32px 35px;\n    background-position: center center;\n    vertical-align: middle;\n    line-height: 32px;\n    box-shadow: inset 0 0 1px #999, inset 0 0 10px rgba(0, 0, 0, 0.2);\n    margin-left: 5px;\n}\n\n.k-rating-item.k-selected  {\n    color: #ffa600;\n}\n\n.no-border-sparkline svg>g>path:first-child{\n   fill: none!important;\n }\n\n .no-border-sparkline {\n    width: 100%;\n }\n\n .no-border-sparkline .k-sparkline{\n    width: 100%!important;\n }\n\n .k-badge-container {\n     margin-left: 30px;\n     margin-bottom: 5px;\n }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
