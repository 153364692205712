/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React from 'react';
import { Element, useNode } from '@craftjs/core';
import { NewBlogSettings2 } from './newBlogSetting2';
import { Text } from '../../basic/Text';
import { ImageC } from '../../basic/Image';
import { Input, InputLabel, MenuItem, Select } from '@mui/material';
import { FormControl } from 'react-bootstrap';
import { useState } from 'react';
import './blogs.css'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Button } from '../../basic/Button';
import { Parent } from '../../Parent/index.js';



export const Blog_2 = ({selected}) => {
    const [selectBlogs, setSelectBlogs] = useState(0)
 

    const { 
        connectors: {
          connect
        } 
    } = useNode();

    // const cards = []

    const blogs = [
        <Element canvas id='random-400' is='parent' className='w-[100%] h-full bg-[transparent]' >
            <Element canvas id='random-401' is='div' className='w-[100%] h-full flex flex-row flex-wrap justify-center align-items-center' >
            <Element canvas id='random-402' is='div' className='max-w-[386px] min-w-[290px] h-[full] mx-auto my-auto flex flex-col' style={{boxShadow: '0px 0px 4px 0px #FFFFFF1A', border:'1px solid #FFFFFF1A', flex:'28%'}}>
                  <Element canvas style={{width:'100%', display:'inline-block', height:'100%'}} id='random-id-100' is='div'>
                  <ImageC className='max-h-[280px] min-h-[213px] w-[100%] object-cover' src='https://i.ibb.co/RyNZCkT/blog2-1.jpg'/>
                  </Element>
                <Element canvas id='random-403' is='div' className='mx-[10px] flex flex-col align-items-start my-auto'>
                  <Element canvas id='random-404' is='div' className='my-2'>
                  <Element canvas style={{display:'inline-block', fontSize:'24px', fontWeight:'bold', fontFamily:'Rubik', color:'#FFF'}} id='random-id-1' is='div'>
                    <Text text='Lorem ipsum'/>
                    </Element>
                    <Element canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'300px', fontFamily:'Rubik', color:'#FFFFFF9A'}} id='random-id-2' is='div'>
                    <Text text='12/09/2023'/>
                    </Element>
                  </Element>
                  <Element canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', color:'#FFFFFF9A', lineHeight:'25.2px'}} className='my-2' id='random-id-3' is='div'>
                    <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...'/>
                    </Element>
                  <Element canvas id='random-id-200' is='div' className='my-4 mx-auto'>
                    <Button  classn='block' class='flex cursor-pointer text-[#FFF] bg-[#3265E3] hover:bg-[#0049FF]'
                    style={{fontWeight:'bold', fontFamily:'Rubik', fontSize:'22px', border:'1px solid #FFFFFF1A', width:'260px', height:'70px', boxShadow: '0px 4px 10px 0px #FFFFFF0D'}}>
                    <Element canvas style={{display:'inline-block'}} className='mx-auto my-auto flex' id='random-id-4' is='div'>
                    <Text text='Learn more'/>
                    </Element>
                    </Button>
                  </Element>
                </Element>
                </Element>
                <Element canvas id='random-405' is='div' className='max-w-[386px] min-w-[290px] h-[full] mx-4 my-4  flex flex-col' style={{boxShadow: '0px 0px 4px 0px #FFFFFF1A', border:'1px solid #FFFFFF1A', flex:'28%'}}>
                  <Element canvas id='random-406' is='div' className='max-h-[280px] min-h-[213px]'>
                  <Element canvas style={{width:'100%', display:'inline-block', height:'100%'}} id='random-id-101' is='div'>
                  <ImageC className='max-h-[280px] min-h-[213px] w-[100%] object-cover' src='https://i.ibb.co/WVTftrs/blog2-2.jpg'/>
                  </Element>
                  </Element>
                <Element canvas id='random-407' is='div' className='mx-[10px] flex flex-col align-items-start my-auto'>
                  <Element canvas id='random-408' is='div' className='my-2'>
                    <Element canvas style={{display:'inline-block', fontSize:'24px', fontWeight:'bold', fontFamily:'Rubik', color:'#FFF'}} id='random-id-5' is='div'>
                    <Text text='Lorem ipsum'/>
                    </Element>
                    <Element canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'300px', fontFamily:'Rubik', color:'#FFFFFF9A'}} id='random-id-6' is='div'>
                    <Text text='12/09/2023'/>
                    </Element>
                  </Element>
                  <Element canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', color:'#FFFFFF9A', lineHeight:'25.2px'}} className='my-2' id='random-id-7' is='div'>
                    <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...'/>
                    </Element>
                    <Element canvas id='random-id-201' is='div' className='my-4 mx-auto'>
                    <Button  classn='block' class='flex cursor-pointer text-[#FFF] bg-[#3265E3] hover:bg-[#0049FF]'
                    style={{ fontWeight:'bold', fontFamily:'Rubik', fontSize:'22px', border:'1px solid #FFFFFF1A', width:'260px', height:'70px', boxShadow: '0px 4px 10px 0px #FFFFFF0D'}}>
                    <Element canvas style={{display:'inline-block'}} className='mx-auto my-auto flex' id='random-id-8' is='div'>
                    <Text text='Learn more'/>
                    </Element>
                    </Button>
                  </Element>
                </Element>
                </Element>
                <Element canvas id='random-409' is='div' className='max-w-[386px] min-w-[290px] h-[full] mx-auto my-auto  flex flex-col' style={{boxShadow: '0px 0px 4px 0px #FFFFFF1A', border:'1px solid #FFFFFF1A', flex:'28%'}}>
                  <Element canvas id='random-410' is='div' className='max-h-[280px] min-h-[213px]'>
                  <Element canvas style={{width:'100%', display:'inline-block', height:'100%'}} id='random-id-102' is='div'>
                  <ImageC className='max-h-[280px] min-h-[213px] w-[100%] object-cover' src='https://i.ibb.co/3SpZz4L/blog2-3.jpg'/>
                  </Element>
                  </Element>
                <Element canvas id='random-411' is='div' className='mx-[10px] flex flex-col align-items-start my-auto'>
                  <Element canvas id='random-412' is='div' className='my-2'>
                    <Element canvas style={{display:'inline-block', fontSize:'24px', fontWeight:'bold', fontFamily:'Rubik', color:'#FFF'}} id='random-id-9' is='div'>
                    <Text text='Lorem ipsum'/>
                    </Element>
                    <Element canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'300px', fontFamily:'Rubik', color:'#FFFFFF9A'}} id='random-id-10' is='div'>
                    <Text text='12/09/2023'/>
                    </Element>
                  </Element>
                  <Element canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', color:'#FFFFFF9A', lineHeight:'25.2px'}} className='my-2' id='random-id-11' is='div'>
                    <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...'/>
                    </Element>
                  <Element canvas id='random-id-202' is='div' className='my-4 mx-auto'>
                    <Button  classn='block' class='flex cursor-pointer text-[#FFF] bg-[#3265E3] hover:bg-[#0049FF]'
                    style={{ fontWeight:'bold', fontFamily:'Rubik', fontSize:'22px', border:'1px solid #FFFFFF1A', width:'260px', height:'70px', boxShadow: '0px 4px 10px 0px #FFFFFF0D'}}>
                    <Element canvas style={{display:'inline-block'}} className='mx-auto my-auto flex' id='random-id-12' is='div'>
                    <Text text='Learn more'/>
                    </Element>
                    </Button>
                  </Element>
                </Element>
                </Element>
            </Element>
      </Element>,
      <Element canvas id='random-400' is='parent' className='w-[100%] h-full bg-[transparent]' >
      <Element canvas id='random-401' is='div' className='w-[100%] h-full flex flex-row flex-wrap justify-center align-items-center' >
      <Element canvas id='random-402' is='div' className='max-w-[386px] min-w-[290px] h-[full] mx-auto my-auto flex flex-col' style={{boxShadow: '0px 0px 4px 0px #FFFFFF1A', border:'1px solid #FFFFFF1A', flex:'28%'}}>
            <Element canvas style={{width:'100%', display:'inline-block', height:'100%'}} id='random-id-100' is='div'>
            <ImageC className='max-h-[280px] min-h-[213px] w-[100%] object-cover' src='https://i.ibb.co/RyNZCkT/blog2-1.jpg'/>
            </Element>
          <Element canvas id='random-403' is='div' className='mx-[10px] flex flex-col align-items-start my-auto'>
            <Element canvas id='random-404' is='div' className='my-2'>
            <Element canvas style={{display:'inline-block', fontSize:'24px', fontWeight:'bold', fontFamily:'Rubik', color:'#FFF'}} id='random-id-1' is='div'>
              <Text text='Lorem ipsum'/>
              </Element>
              <Element canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'300px', fontFamily:'Rubik', color:'#FFFFFF9A'}} id='random-id-2' is='div'>
              <Text text='12/09/2023'/>
              </Element>
            </Element>
            <Element canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', color:'#FFFFFF9A', lineHeight:'25.2px'}} className='my-2' id='random-id-3' is='div'>
              <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...'/>
              </Element>
            <Element canvas id='random-id-200' is='div' className='my-4 mx-auto'>
              <Button  classn='block' class='flex cursor-pointer text-[#FFF] bg-[#3265E3] hover:bg-[#0049FF]'
              style={{fontWeight:'bold', fontFamily:'Rubik', fontSize:'22px', border:'1px solid #FFFFFF1A', width:'260px', height:'70px', boxShadow: '0px 4px 10px 0px #FFFFFF0D'}}>
              <Element canvas style={{display:'inline-block'}} className='mx-auto my-auto flex' id='random-id-4' is='div'>
              <Text text='Learn more'/>
              </Element>
              </Button>
            </Element>
          </Element>
          </Element>
          <Element canvas id='random-405' is='div' className='max-w-[386px] min-w-[290px] h-[full] mx-4 my-4  flex flex-col' style={{boxShadow: '0px 0px 4px 0px #FFFFFF1A', border:'1px solid #FFFFFF1A', flex:'28%'}}>
            <Element canvas id='random-406' is='div' className='max-h-[280px] min-h-[213px]'>
            <Element canvas style={{width:'100%', display:'inline-block', height:'100%'}} id='random-id-101' is='div'>
            <ImageC className='max-h-[280px] min-h-[213px] w-[100%] object-cover' src='https://i.ibb.co/WVTftrs/blog2-2.jpg'/>
            </Element>
            </Element>
          <Element canvas id='random-407' is='div' className='mx-[10px] flex flex-col align-items-start my-auto'>
            <Element canvas id='random-408' is='div' className='my-2'>
              <Element canvas style={{display:'inline-block', fontSize:'24px', fontWeight:'bold', fontFamily:'Rubik', color:'#FFF'}} id='random-id-5' is='div'>
              <Text text='Lorem ipsum'/>
              </Element>
              <Element canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'300px', fontFamily:'Rubik', color:'#FFFFFF9A'}} id='random-id-6' is='div'>
              <Text text='12/09/2023'/>
              </Element>
            </Element>
            <Element canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', color:'#FFFFFF9A', lineHeight:'25.2px'}} className='my-2' id='random-id-7' is='div'>
              <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...'/>
              </Element>
              <Element canvas id='random-id-201' is='div' className='my-4 mx-auto'>
              <Button  classn='block' class='flex cursor-pointer text-[#FFF] bg-[#3265E3] hover:bg-[#0049FF]'
              style={{ fontWeight:'bold', fontFamily:'Rubik', fontSize:'22px', border:'1px solid #FFFFFF1A', width:'260px', height:'70px', boxShadow: '0px 4px 10px 0px #FFFFFF0D'}}>
              <Element canvas style={{display:'inline-block'}} className='mx-auto my-auto flex' id='random-id-8' is='div'>
              <Text text='Learn more'/>
              </Element>
              </Button>
            </Element>
          </Element>
          </Element>
          <Element canvas id='random-409' is='div' className='max-w-[386px] min-w-[290px] h-[full] mx-auto my-auto  flex flex-col' style={{boxShadow: '0px 0px 4px 0px #FFFFFF1A', border:'1px solid #FFFFFF1A', flex:'28%'}}>
            <Element canvas id='random-410' is='div' className='max-h-[280px] min-h-[213px]'>
            <Element canvas style={{width:'100%', display:'inline-block', height:'100%'}} id='random-id-102' is='div'>
            <ImageC className='max-h-[280px] min-h-[213px] w-[100%] object-cover' src='https://i.ibb.co/3SpZz4L/blog2-3.jpg'/>
            </Element>
            </Element>
          <Element canvas id='random-411' is='div' className='mx-[10px] flex flex-col align-items-start my-auto'>
            <Element canvas id='random-412' is='div' className='my-2'>
              <Element canvas style={{display:'inline-block', fontSize:'24px', fontWeight:'bold', fontFamily:'Rubik', color:'#FFF'}} id='random-id-9' is='div'>
              <Text text='Lorem ipsum'/>
              </Element>
              <Element canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'300px', fontFamily:'Rubik', color:'#FFFFFF9A'}} id='random-id-10' is='div'>
              <Text text='12/09/2023'/>
              </Element>
            </Element>
            <Element canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', color:'#FFFFFF9A', lineHeight:'25.2px'}} className='my-2' id='random-id-11' is='div'>
              <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...'/>
              </Element>
            <Element canvas id='random-id-202' is='div' className='my-4 mx-auto'>
              <Button  classn='block' class='flex cursor-pointer text-[#FFF] bg-[#3265E3] hover:bg-[#0049FF]'
              style={{ fontWeight:'bold', fontFamily:'Rubik', fontSize:'22px', border:'1px solid #FFFFFF1A', width:'260px', height:'70px', boxShadow: '0px 4px 10px 0px #FFFFFF0D'}}>
              <Element canvas style={{display:'inline-block'}} className='mx-auto my-auto flex' id='random-id-12' is='div'>
              <Text text='Learn more'/>
              </Element>
              </Button>
            </Element>
          </Element>
          </Element>
      </Element>
</Element>,
<Element canvas id='random-400' is='parent' className='w-[100%] h-full bg-[transparent]' >
            <Element canvas id='random-401' is='div' className='w-[100%] h-full flex flex-row flex-wrap justify-center align-items-center' >
            <Element canvas id='random-402' is='div' className='max-w-[386px] min-w-[290px] h-[full] mx-auto my-auto flex flex-col' style={{boxShadow: '0px 0px 4px 0px #FFFFFF1A', border:'1px solid #FFFFFF1A', flex:'28%'}}>
                  <Element canvas style={{width:'100%', display:'inline-block', height:'100%'}} id='random-id-100' is='div'>
                  <ImageC className='max-h-[280px] min-h-[213px] w-[100%] object-cover' src='https://i.ibb.co/RyNZCkT/blog2-1.jpg'/>
                  </Element>
                <Element canvas id='random-403' is='div' className='mx-[10px] flex flex-col align-items-start my-auto'>
                  <Element canvas id='random-404' is='div' className='my-2'>
                  <Element canvas style={{display:'inline-block', fontSize:'24px', fontWeight:'bold', fontFamily:'Rubik', color:'#FFF'}} id='random-id-1' is='div'>
                    <Text text='Lorem ipsum'/>
                    </Element>
                    <Element canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'300px', fontFamily:'Rubik', color:'#FFFFFF9A'}} id='random-id-2' is='div'>
                    <Text text='12/09/2023'/>
                    </Element>
                  </Element>
                  <Element canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', color:'#FFFFFF9A', lineHeight:'25.2px'}} className='my-2' id='random-id-3' is='div'>
                    <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...'/>
                    </Element>
                  <Element canvas id='random-id-200' is='div' className='my-4 mx-auto'>
                    <Button  classn='block' class='flex cursor-pointer text-[#FFF] bg-[#3265E3] hover:bg-[#0049FF]'
                    style={{fontWeight:'bold', fontFamily:'Rubik', fontSize:'22px', border:'1px solid #FFFFFF1A', width:'260px', height:'70px', boxShadow: '0px 4px 10px 0px #FFFFFF0D'}}>
                    <Element canvas style={{display:'inline-block'}} className='mx-auto my-auto flex' id='random-id-4' is='div'>
                    <Text text='Learn more'/>
                    </Element>
                    </Button>
                  </Element>
                </Element>
                </Element>
                <Element canvas id='random-405' is='div' className='max-w-[386px] min-w-[290px] h-[full] mx-4 my-4  flex flex-col' style={{boxShadow: '0px 0px 4px 0px #FFFFFF1A', border:'1px solid #FFFFFF1A', flex:'28%'}}>
                  <Element canvas id='random-406' is='div' className='max-h-[280px] min-h-[213px]'>
                  <Element canvas style={{width:'100%', display:'inline-block', height:'100%'}} id='random-id-101' is='div'>
                  <ImageC className='max-h-[280px] min-h-[213px] w-[100%] object-cover' src='https://i.ibb.co/WVTftrs/blog2-2.jpg'/>
                  </Element>
                  </Element>
                <Element canvas id='random-407' is='div' className='mx-[10px] flex flex-col align-items-start my-auto'>
                  <Element canvas id='random-408' is='div' className='my-2'>
                    <Element canvas style={{display:'inline-block', fontSize:'24px', fontWeight:'bold', fontFamily:'Rubik', color:'#FFF'}} id='random-id-5' is='div'>
                    <Text text='Lorem ipsum'/>
                    </Element>
                    <Element canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'300px', fontFamily:'Rubik', color:'#FFFFFF9A'}} id='random-id-6' is='div'>
                    <Text text='12/09/2023'/>
                    </Element>
                  </Element>
                  <Element canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', color:'#FFFFFF9A', lineHeight:'25.2px'}} className='my-2' id='random-id-7' is='div'>
                    <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...'/>
                    </Element>
                    <Element canvas id='random-id-201' is='div' className='my-4 mx-auto'>
                    <Button  classn='block' class='flex cursor-pointer text-[#FFF] bg-[#3265E3] hover:bg-[#0049FF]'
                    style={{ fontWeight:'bold', fontFamily:'Rubik', fontSize:'22px', border:'1px solid #FFFFFF1A', width:'260px', height:'70px', boxShadow: '0px 4px 10px 0px #FFFFFF0D'}}>
                    <Element canvas style={{display:'inline-block'}} className='mx-auto my-auto flex' id='random-id-8' is='div'>
                    <Text text='Learn more'/>
                    </Element>
                    </Button>
                  </Element>
                </Element>
                </Element>
                <Element canvas id='random-409' is='div' className='max-w-[386px] min-w-[290px] h-[full] mx-auto my-auto  flex flex-col' style={{boxShadow: '0px 0px 4px 0px #FFFFFF1A', border:'1px solid #FFFFFF1A', flex:'28%'}}>
                  <Element canvas id='random-410' is='div' className='max-h-[280px] min-h-[213px]'>
                  <Element canvas style={{width:'100%', display:'inline-block', height:'100%'}} id='random-id-102' is='div'>
                  <ImageC className='max-h-[280px] min-h-[213px] w-[100%] object-cover' src='https://i.ibb.co/3SpZz4L/blog2-3.jpg'/>
                  </Element>
                  </Element>
                <Element canvas id='random-411' is='div' className='mx-[10px] flex flex-col align-items-start my-auto'>
                  <Element canvas id='random-412' is='div' className='my-2'>
                    <Element canvas style={{display:'inline-block', fontSize:'24px', fontWeight:'bold', fontFamily:'Rubik', color:'#FFF'}} id='random-id-9' is='div'>
                    <Text text='Lorem ipsum'/>
                    </Element>
                    <Element canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'300px', fontFamily:'Rubik', color:'#FFFFFF9A'}} id='random-id-10' is='div'>
                    <Text text='12/09/2023'/>
                    </Element>
                  </Element>
                  <Element canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', color:'#FFFFFF9A', lineHeight:'25.2px'}} className='my-2' id='random-id-11' is='div'>
                    <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...'/>
                    </Element>
                  <Element canvas id='random-id-202' is='div' className='my-4 mx-auto'>
                    <Button  classn='block' class='flex cursor-pointer text-[#FFF] bg-[#3265E3] hover:bg-[#0049FF]'
                    style={{ fontWeight:'bold', fontFamily:'Rubik', fontSize:'22px', border:'1px solid #FFFFFF1A', width:'260px', height:'70px', boxShadow: '0px 4px 10px 0px #FFFFFF0D'}}>
                    <Element canvas style={{display:'inline-block'}} className='mx-auto my-auto flex' id='random-id-12' is='div'>
                    <Text text='Learn more'/>
                    </Element>
                    </Button>
                  </Element>
                </Element>
                </Element>
            </Element>
      </Element>,
      <Element canvas id='random-400' is='parent' className='w-[100%] h-full bg-[transparent]' >
      <Element canvas id='random-401' is='div' className='w-[100%] h-full flex flex-row flex-wrap justify-center align-items-center' >
      <Element canvas id='random-402' is='div' className='max-w-[386px] min-w-[290px] h-[full] mx-auto my-auto flex flex-col' style={{boxShadow: '0px 0px 4px 0px #FFFFFF1A', border:'1px solid #FFFFFF1A', flex:'28%'}}>
            <Element canvas style={{width:'100%', display:'inline-block', height:'100%'}} id='random-id-100' is='div'>
            <ImageC className='max-h-[280px] min-h-[213px] w-[100%] object-cover' src='https://i.ibb.co/RyNZCkT/blog2-1.jpg'/>
            </Element>
          <Element canvas id='random-403' is='div' className='mx-[10px] flex flex-col align-items-start my-auto'>
            <Element canvas id='random-404' is='div' className='my-2'>
            <Element canvas style={{display:'inline-block', fontSize:'24px', fontWeight:'bold', fontFamily:'Rubik', color:'#FFF'}} id='random-id-1' is='div'>
              <Text text='Lorem ipsum'/>
              </Element>
              <Element canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'300px', fontFamily:'Rubik', color:'#FFFFFF9A'}} id='random-id-2' is='div'>
              <Text text='12/09/2023'/>
              </Element>
            </Element>
            <Element canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', color:'#FFFFFF9A', lineHeight:'25.2px'}} className='my-2' id='random-id-3' is='div'>
              <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...'/>
              </Element>
            <Element canvas id='random-id-200' is='div' className='my-4 mx-auto'>
              <Button  classn='block' class='flex cursor-pointer text-[#FFF] bg-[#3265E3] hover:bg-[#0049FF]'
              style={{fontWeight:'bold', fontFamily:'Rubik', fontSize:'22px', border:'1px solid #FFFFFF1A', width:'260px', height:'70px', boxShadow: '0px 4px 10px 0px #FFFFFF0D'}}>
              <Element canvas style={{display:'inline-block'}} className='mx-auto my-auto flex' id='random-id-4' is='div'>
              <Text text='Learn more'/>
              </Element>
              </Button>
            </Element>
          </Element>
          </Element>
          <Element canvas id='random-405' is='div' className='max-w-[386px] min-w-[290px] h-[full] mx-4 my-4  flex flex-col' style={{boxShadow: '0px 0px 4px 0px #FFFFFF1A', border:'1px solid #FFFFFF1A', flex:'28%'}}>
            <Element canvas id='random-406' is='div' className='max-h-[280px] min-h-[213px]'>
            <Element canvas style={{width:'100%', display:'inline-block', height:'100%'}} id='random-id-101' is='div'>
            <ImageC className='max-h-[280px] min-h-[213px] w-[100%] object-cover' src='https://i.ibb.co/WVTftrs/blog2-2.jpg'/>
            </Element>
            </Element>
          <Element canvas id='random-407' is='div' className='mx-[10px] flex flex-col align-items-start my-auto'>
            <Element canvas id='random-408' is='div' className='my-2'>
              <Element canvas style={{display:'inline-block', fontSize:'24px', fontWeight:'bold', fontFamily:'Rubik', color:'#FFF'}} id='random-id-5' is='div'>
              <Text text='Lorem ipsum'/>
              </Element>
              <Element canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'300px', fontFamily:'Rubik', color:'#FFFFFF9A'}} id='random-id-6' is='div'>
              <Text text='12/09/2023'/>
              </Element>
            </Element>
            <Element canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', color:'#FFFFFF9A', lineHeight:'25.2px'}} className='my-2' id='random-id-7' is='div'>
              <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...'/>
              </Element>
              <Element canvas id='random-id-201' is='div' className='my-4 mx-auto'>
              <Button  classn='block' class='flex cursor-pointer text-[#FFF] bg-[#3265E3] hover:bg-[#0049FF]'
              style={{ fontWeight:'bold', fontFamily:'Rubik', fontSize:'22px', border:'1px solid #FFFFFF1A', width:'260px', height:'70px', boxShadow: '0px 4px 10px 0px #FFFFFF0D'}}>
              <Element canvas style={{display:'inline-block'}} className='mx-auto my-auto flex' id='random-id-8' is='div'>
              <Text text='Learn more'/>
              </Element>
              </Button>
            </Element>
          </Element>
          </Element>
          <Element canvas id='random-409' is='div' className='max-w-[386px] min-w-[290px] h-[full] mx-auto my-auto  flex flex-col' style={{boxShadow: '0px 0px 4px 0px #FFFFFF1A', border:'1px solid #FFFFFF1A', flex:'28%'}}>
            <Element canvas id='random-410' is='div' className='max-h-[280px] min-h-[213px]'>
            <Element canvas style={{width:'100%', display:'inline-block', height:'100%'}} id='random-id-102' is='div'>
            <ImageC className='max-h-[280px] min-h-[213px] w-[100%] object-cover' src='https://i.ibb.co/3SpZz4L/blog2-3.jpg'/>
            </Element>
            </Element>
          <Element canvas id='random-411' is='div' className='mx-[10px] flex flex-col align-items-start my-auto'>
            <Element canvas id='random-412' is='div' className='my-2'>
              <Element canvas style={{display:'inline-block', fontSize:'24px', fontWeight:'bold', fontFamily:'Rubik', color:'#FFF'}} id='random-id-9' is='div'>
              <Text text='Lorem ipsum'/>
              </Element>
              <Element canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'300px', fontFamily:'Rubik', color:'#FFFFFF9A'}} id='random-id-10' is='div'>
              <Text text='12/09/2023'/>
              </Element>
            </Element>
            <Element canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', color:'#FFFFFF9A', lineHeight:'25.2px'}} className='my-2' id='random-id-11' is='div'>
              <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...'/>
              </Element>
            <Element canvas id='random-id-202' is='div' className='my-4 mx-auto'>
              <Button  classn='block' class='flex cursor-pointer text-[#FFF] bg-[#3265E3] hover:bg-[#0049FF]'
              style={{ fontWeight:'bold', fontFamily:'Rubik', fontSize:'22px', border:'1px solid #FFFFFF1A', width:'260px', height:'70px', boxShadow: '0px 4px 10px 0px #FFFFFF0D'}}>
              <Element canvas style={{display:'inline-block'}} className='mx-auto my-auto flex' id='random-id-12' is='div'>
              <Text text='Learn more'/>
              </Element>
              </Button>
            </Element>
          </Element>
          </Element>
      </Element>
</Element>,
<Element canvas id='random-400' is='parent' className='w-[100%] h-full bg-[transparent]' >
            <Element canvas id='random-401' is='div' className='w-[100%] h-full flex flex-row flex-wrap justify-center align-items-center' >
            <Element canvas id='random-402' is='div' className='max-w-[386px] min-w-[290px] h-[full] mx-auto my-auto flex flex-col' style={{boxShadow: '0px 0px 4px 0px #FFFFFF1A', border:'1px solid #FFFFFF1A', flex:'28%'}}>
                  <Element canvas style={{width:'100%', display:'inline-block', height:'100%'}} id='random-id-100' is='div'>
                  <ImageC className='max-h-[280px] min-h-[213px] w-[100%] object-cover' src='https://i.ibb.co/RyNZCkT/blog2-1.jpg'/>
                  </Element>
                <Element canvas id='random-403' is='div' className='mx-[10px] flex flex-col align-items-start my-auto'>
                  <Element canvas id='random-404' is='div' className='my-2'>
                  <Element canvas style={{display:'inline-block', fontSize:'24px', fontWeight:'bold', fontFamily:'Rubik', color:'#FFF'}} id='random-id-1' is='div'>
                    <Text text='Lorem ipsum'/>
                    </Element>
                    <Element canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'300px', fontFamily:'Rubik', color:'#FFFFFF9A'}} id='random-id-2' is='div'>
                    <Text text='12/09/2023'/>
                    </Element>
                  </Element>
                  <Element canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', color:'#FFFFFF9A', lineHeight:'25.2px'}} className='my-2' id='random-id-3' is='div'>
                    <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...'/>
                    </Element>
                  <Element canvas id='random-id-200' is='div' className='my-4 mx-auto'>
                    <Button  classn='block' class='flex cursor-pointer text-[#FFF] bg-[#3265E3] hover:bg-[#0049FF]'
                    style={{fontWeight:'bold', fontFamily:'Rubik', fontSize:'22px', border:'1px solid #FFFFFF1A', width:'260px', height:'70px', boxShadow: '0px 4px 10px 0px #FFFFFF0D'}}>
                    <Element canvas style={{display:'inline-block'}} className='mx-auto my-auto flex' id='random-id-4' is='div'>
                    <Text text='Learn more'/>
                    </Element>
                    </Button>
                  </Element>
                </Element>
                </Element>
                <Element canvas id='random-405' is='div' className='max-w-[386px] min-w-[290px] h-[full] mx-4 my-4  flex flex-col' style={{boxShadow: '0px 0px 4px 0px #FFFFFF1A', border:'1px solid #FFFFFF1A', flex:'28%'}}>
                  <Element canvas id='random-406' is='div' className='max-h-[280px] min-h-[213px]'>
                  <Element canvas style={{width:'100%', display:'inline-block', height:'100%'}} id='random-id-101' is='div'>
                  <ImageC className='max-h-[280px] min-h-[213px] w-[100%] object-cover' src='https://i.ibb.co/WVTftrs/blog2-2.jpg'/>
                  </Element>
                  </Element>
                <Element canvas id='random-407' is='div' className='mx-[10px] flex flex-col align-items-start my-auto'>
                  <Element canvas id='random-408' is='div' className='my-2'>
                    <Element canvas style={{display:'inline-block', fontSize:'24px', fontWeight:'bold', fontFamily:'Rubik', color:'#FFF'}} id='random-id-5' is='div'>
                    <Text text='Lorem ipsum'/>
                    </Element>
                    <Element canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'300px', fontFamily:'Rubik', color:'#FFFFFF9A'}} id='random-id-6' is='div'>
                    <Text text='12/09/2023'/>
                    </Element>
                  </Element>
                  <Element canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', color:'#FFFFFF9A', lineHeight:'25.2px'}} className='my-2' id='random-id-7' is='div'>
                    <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...'/>
                    </Element>
                    <Element canvas id='random-id-201' is='div' className='my-4 mx-auto'>
                    <Button  classn='block' class='flex cursor-pointer text-[#FFF] bg-[#3265E3] hover:bg-[#0049FF]'
                    style={{ fontWeight:'bold', fontFamily:'Rubik', fontSize:'22px', border:'1px solid #FFFFFF1A', width:'260px', height:'70px', boxShadow: '0px 4px 10px 0px #FFFFFF0D'}}>
                    <Element canvas style={{display:'inline-block'}} className='mx-auto my-auto flex' id='random-id-8' is='div'>
                    <Text text='Learn more'/>
                    </Element>
                    </Button>
                  </Element>
                </Element>
                </Element>
                <Element canvas id='random-409' is='div' className='max-w-[386px] min-w-[290px] h-[full] mx-auto my-auto  flex flex-col' style={{boxShadow: '0px 0px 4px 0px #FFFFFF1A', border:'1px solid #FFFFFF1A', flex:'28%'}}>
                  <Element canvas id='random-410' is='div' className='max-h-[280px] min-h-[213px]'>
                  <Element canvas style={{width:'100%', display:'inline-block', height:'100%'}} id='random-id-102' is='div'>
                  <ImageC className='max-h-[280px] min-h-[213px] w-[100%] object-cover' src='https://i.ibb.co/3SpZz4L/blog2-3.jpg'/>
                  </Element>
                  </Element>
                <Element canvas id='random-411' is='div' className='mx-[10px] flex flex-col align-items-start my-auto'>
                  <Element canvas id='random-412' is='div' className='my-2'>
                    <Element canvas style={{display:'inline-block', fontSize:'24px', fontWeight:'bold', fontFamily:'Rubik', color:'#FFF'}} id='random-id-9' is='div'>
                    <Text text='Lorem ipsum'/>
                    </Element>
                    <Element canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'300px', fontFamily:'Rubik', color:'#FFFFFF9A'}} id='random-id-10' is='div'>
                    <Text text='12/09/2023'/>
                    </Element>
                  </Element>
                  <Element canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', color:'#FFFFFF9A', lineHeight:'25.2px'}} className='my-2' id='random-id-11' is='div'>
                    <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...'/>
                    </Element>
                  <Element canvas id='random-id-202' is='div' className='my-4 mx-auto'>
                    <Button  classn='block' class='flex cursor-pointer text-[#FFF] bg-[#3265E3] hover:bg-[#0049FF]'
                    style={{ fontWeight:'bold', fontFamily:'Rubik', fontSize:'22px', border:'1px solid #FFFFFF1A', width:'260px', height:'70px', boxShadow: '0px 4px 10px 0px #FFFFFF0D'}}>
                    <Element canvas style={{display:'inline-block'}} className='mx-auto my-auto flex' id='random-id-12' is='div'>
                    <Text text='Learn more'/>
                    </Element>
                    </Button>
                  </Element>
                </Element>
                </Element>
            </Element>
      </Element>,
      <Element canvas id='random-400' is='parent' className='w-[100%] h-full bg-[transparent]' >
      <Element canvas id='random-401' is='div' className='w-[100%] h-full flex flex-row flex-wrap justify-center align-items-center' >
      <Element canvas id='random-402' is='div' className='max-w-[386px] min-w-[290px] h-[full] mx-auto my-auto flex flex-col' style={{boxShadow: '0px 0px 4px 0px #FFFFFF1A', border:'1px solid #FFFFFF1A', flex:'28%'}}>
            <Element canvas style={{width:'100%', display:'inline-block', height:'100%'}} id='random-id-100' is='div'>
            <ImageC className='max-h-[280px] min-h-[213px] w-[100%] object-cover' src='https://i.ibb.co/RyNZCkT/blog2-1.jpg'/>
            </Element>
          <Element canvas id='random-403' is='div' className='mx-[10px] flex flex-col align-items-start my-auto'>
            <Element canvas id='random-404' is='div' className='my-2'>
            <Element canvas style={{display:'inline-block', fontSize:'24px', fontWeight:'bold', fontFamily:'Rubik', color:'#FFF'}} id='random-id-1' is='div'>
              <Text text='Lorem ipsum'/>
              </Element>
              <Element canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'300px', fontFamily:'Rubik', color:'#FFFFFF9A'}} id='random-id-2' is='div'>
              <Text text='12/09/2023'/>
              </Element>
            </Element>
            <Element canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', color:'#FFFFFF9A', lineHeight:'25.2px'}} className='my-2' id='random-id-3' is='div'>
              <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...'/>
              </Element>
            <Element canvas id='random-id-200' is='div' className='my-4 mx-auto'>
              <Button  classn='block' class='flex cursor-pointer text-[#FFF] bg-[#3265E3] hover:bg-[#0049FF]'
              style={{fontWeight:'bold', fontFamily:'Rubik', fontSize:'22px', border:'1px solid #FFFFFF1A', width:'260px', height:'70px', boxShadow: '0px 4px 10px 0px #FFFFFF0D'}}>
              <Element canvas style={{display:'inline-block'}} className='mx-auto my-auto flex' id='random-id-4' is='div'>
              <Text text='Learn more'/>
              </Element>
              </Button>
            </Element>
          </Element>
          </Element>
          <Element canvas id='random-405' is='div' className='max-w-[386px] min-w-[290px] h-[full] mx-4 my-4  flex flex-col' style={{boxShadow: '0px 0px 4px 0px #FFFFFF1A', border:'1px solid #FFFFFF1A', flex:'28%'}}>
            <Element canvas id='random-406' is='div' className='max-h-[280px] min-h-[213px]'>
            <Element canvas style={{width:'100%', display:'inline-block', height:'100%'}} id='random-id-101' is='div'>
            <ImageC className='max-h-[280px] min-h-[213px] w-[100%] object-cover' src='https://i.ibb.co/WVTftrs/blog2-2.jpg'/>
            </Element>
            </Element>
          <Element canvas id='random-407' is='div' className='mx-[10px] flex flex-col align-items-start my-auto'>
            <Element canvas id='random-408' is='div' className='my-2'>
              <Element canvas style={{display:'inline-block', fontSize:'24px', fontWeight:'bold', fontFamily:'Rubik', color:'#FFF'}} id='random-id-5' is='div'>
              <Text text='Lorem ipsum'/>
              </Element>
              <Element canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'300px', fontFamily:'Rubik', color:'#FFFFFF9A'}} id='random-id-6' is='div'>
              <Text text='12/09/2023'/>
              </Element>
            </Element>
            <Element canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', color:'#FFFFFF9A', lineHeight:'25.2px'}} className='my-2' id='random-id-7' is='div'>
              <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...'/>
              </Element>
              <Element canvas id='random-id-201' is='div' className='my-4 mx-auto'>
              <Button  classn='block' class='flex cursor-pointer text-[#FFF] bg-[#3265E3] hover:bg-[#0049FF]'
              style={{ fontWeight:'bold', fontFamily:'Rubik', fontSize:'22px', border:'1px solid #FFFFFF1A', width:'260px', height:'70px', boxShadow: '0px 4px 10px 0px #FFFFFF0D'}}>
              <Element canvas style={{display:'inline-block'}} className='mx-auto my-auto flex' id='random-id-8' is='div'>
              <Text text='Learn more'/>
              </Element>
              </Button>
            </Element>
          </Element>
          </Element>
          <Element canvas id='random-409' is='div' className='max-w-[386px] min-w-[290px] h-[full] mx-auto my-auto  flex flex-col' style={{boxShadow: '0px 0px 4px 0px #FFFFFF1A', border:'1px solid #FFFFFF1A', flex:'28%'}}>
            <Element canvas id='random-410' is='div' className='max-h-[280px] min-h-[213px]'>
            <Element canvas style={{width:'100%', display:'inline-block', height:'100%'}} id='random-id-102' is='div'>
            <ImageC className='max-h-[280px] min-h-[213px] w-[100%] object-cover' src='https://i.ibb.co/3SpZz4L/blog2-3.jpg'/>
            </Element>
            </Element>
          <Element canvas id='random-411' is='div' className='mx-[10px] flex flex-col align-items-start my-auto'>
            <Element canvas id='random-412' is='div' className='my-2'>
              <Element canvas style={{display:'inline-block', fontSize:'24px', fontWeight:'bold', fontFamily:'Rubik', color:'#FFF'}} id='random-id-9' is='div'>
              <Text text='Lorem ipsum'/>
              </Element>
              <Element canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'300px', fontFamily:'Rubik', color:'#FFFFFF9A'}} id='random-id-10' is='div'>
              <Text text='12/09/2023'/>
              </Element>
            </Element>
            <Element canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', color:'#FFFFFF9A', lineHeight:'25.2px'}} className='my-2' id='random-id-11' is='div'>
              <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...'/>
              </Element>
            <Element canvas id='random-id-202' is='div' className='my-4 mx-auto'>
              <Button  classn='block' class='flex cursor-pointer text-[#FFF] bg-[#3265E3] hover:bg-[#0049FF]'
              style={{ fontWeight:'bold', fontFamily:'Rubik', fontSize:'22px', border:'1px solid #FFFFFF1A', width:'260px', height:'70px', boxShadow: '0px 4px 10px 0px #FFFFFF0D'}}>
              <Element canvas style={{display:'inline-block'}} className='mx-auto my-auto flex' id='random-id-12' is='div'>
              <Text text='Learn more'/>
              </Element>
              </Button>
            </Element>
          </Element>
          </Element>
      </Element>
</Element>,
      <Element canvas id='random-413' is='div' className='w-[100%] h-full bg-[transparent]' >
      <Element canvas id='random-414' is='div' className='w-[100%] h-full flex flex-row flex-wrap justify-center align-items-center' >
      <Element canvas id='random-415' is='div' className='max-w-[386px] min-w-[290px] h-[full] mx-auto my-auto flex flex-col' style={{boxShadow: '0px 0px 4px 0px #FFFFFF1A', border:'1px solid #FFFFFF1A', flex:'28%'}}>
            <Element canvas id='random-416' is='div' className='max-h-[280px] min-h-[213px] min-w-[290px]'>
            <Element canvas style={{width:'100%', display:'inline-block', height:'100%'}} id='random-id-103' is='div'>
            <ImageC className='max-h-[280px] min-h-[213px] w-[100%] object-cover' src='https://i.ibb.co/RyNZCkT/blog2-1.jpg'/>
            </Element>
            </Element>
          <Element canvas id='random-417' is='div' className='mx-[10px] flex flex-col align-items-start my-auto'>
            <Element canvas id='random-418' is='div' className='my-2'>
              <Element canvas style={{display:'inline-block', fontSize:'24px', fontWeight:'bold', fontFamily:'Rubik', color:'#FFF'}} id='random-id-13' is='div'>
                    <Text text='Lorem ipsum'/>
                    </Element>
              <Element canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'300px', fontFamily:'Rubik', color:'#FFFFFF9A'}} id='random-id-14' is='div'>
                    <Text text='12/09/2023'/>
                    </Element>
            </Element>
            <Element canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', color:'#FFFFFF9A', lineHeight:'25.2px'}} className='my-2' id='random-id-15' is='div'>
                    <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...'/>
                    </Element>
            <Element canvas id='random-id-203' is='div' className='my-4 mx-auto'>
                    <Button  classn='block' class='flex cursor-pointer text-[#FFF] bg-[#3265E3] hover:bg-[#0049FF]'
                    style={{ fontWeight:'bold', fontFamily:'Rubik', fontSize:'22px', border:'1px solid #FFFFFF1A', width:'260px', height:'70px', boxShadow: '0px 4px 10px 0px #FFFFFF0D'}}>
                    <Element canvas style={{display:'inline-block'}} className='mx-auto my-auto flex' id='random-id-16' is='div'>
                    <Text text='Learn more'/>
                    </Element>
                    </Button>
            </Element>
          </Element>
          </Element>
          <Element canvas id='random-419' is='div' className='max-w-[386px] min-w-[290px] h-[full] mx-4 my-4 flex flex-col' style={{boxShadow: '0px 0px 4px 0px #FFFFFF1A', border:'1px solid #FFFFFF1A', flex:'28%'}}>
            <Element canvas id='random-420' is='div' className='max-h-[280px] min-h-[213px]'>
            <Element canvas style={{width:'100%', display:'inline-block', height:'100%'}} id='random-id-104' is='div'>
            <ImageC className='max-h-[280px] min-h-[213px] w-[100%] object-cover' src='https://i.ibb.co/WVTftrs/blog2-2.jpg'/>
            </Element>
            </Element>
          <Element canvas id='random-421' is='div' className='mx-[10px] flex flex-col align-items-start my-auto'>
            <Element canvas id='random-422' is='div' className='my-2'>
              <Element canvas style={{display:'inline-block', fontSize:'24px', fontWeight:'bold', fontFamily:'Rubik', color:'#FFF'}} id='random-id-17' is='div'>
                    <Text text='Lorem ipsum'/>
                    </Element>
              <Element canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'300px', fontFamily:'Rubik', color:'#FFFFFF9A'}} id='random-id-18' is='div'>
                    <Text text='12/09/2023'/>
                    </Element>
            </Element>
            <Element canvas className='my-2' style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', color:'#FFFFFF9A', lineHeight:'25.2px'}} id='random-id-19' is='div'>
                    <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...'/>
                    </Element>
              <Element canvas id='random-id-204' is='div' className='my-4 mx-auto'>
                    <Button  classn='block' class='flex cursor-pointer text-[#FFF] bg-[#3265E3] hover:bg-[#0049FF]'
                    style={{ fontWeight:'bold', fontFamily:'Rubik', fontSize:'22px', border:'1px solid #FFFFFF1A', width:'260px', height:'70px', boxShadow: '0px 4px 10px 0px #FFFFFF0D'}}>
                    <Element canvas style={{display:'inline-block'}} className='mx-auto my-auto flex' id='random-id-20' is='div'>
                    <Text text='Learn more'/>
                    </Element>
                    </Button>
              </Element>
          </Element>
          </Element>
          <Element canvas id='random-423' is='div' className='max-w-[386px] min-w-[290px] h-[full] mx-auto my-auto flex flex-col' style={{boxShadow: '0px 0px 4px 0px #FFFFFF1A', border:'1px solid #FFFFFF1A', flex:'28%'}}>
            <Element canvas id='random-424' is='div' className='max-h-[280px] min-h-[213px]'>
            <Element canvas style={{width:'100%', display:'inline-block', height:'100%'}} id='random-id-105' is='div'>
            <ImageC className='max-h-[280px] min-h-[213px] w-[100%] object-cover' src='https://i.ibb.co/3SpZz4L/blog2-3.jpg'/>
            </Element>
            </Element>
          <Element canvas id='random-425' is='div' className='mx-[10px] flex flex-col align-items-start my-auto'>
            <Element canvas id='random-426' is='div' className='my-2'>
              <Element canvas style={{display:'inline-block', fontSize:'24px', fontWeight:'bold', fontFamily:'Rubik', color:'#FFF'}} id='random-id-21' is='div'>
                    <Text text='Lorem ipsum'/>
                    </Element>
              <Element canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'300px', fontFamily:'Rubik', color:'#FFFFFF9A'}} id='random-id-22' is='div'>
                    <Text text='12/09/2023'/>
                    </Element>
            </Element>
              <Element canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', color:'#FFFFFF9A', lineHeight:'25.2px'}} className='my-2'  id='random-id-23' is='div'>
                    <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...'/>
                    </Element>
            <Element canvas id='random-id-205' is='div' className='my-4 mx-auto'>
                    <Button  classn='block' class='flex cursor-pointer text-[#FFF] bg-[#3265E3] hover:bg-[#0049FF]'
                    style={{ fontWeight:'bold', fontFamily:'Rubik', fontSize:'22px', border:'1px solid #FFFFFF1A', width:'260px', height:'70px', boxShadow: '0px 4px 10px 0px #FFFFFF0D'}}>
                    <Element canvas style={{display:'inline-block'}} className='mx-auto my-auto flex' id='random-id-24' is='div'>
                    <Text text='Learn more'/>
                    </Element>
                    </Button>
            </Element>
          </Element>
          </Element>
      </Element>
</Element>,
<Element canvas id='random-427' is='parent' className='w-[100%] h-full bg-[transparent]' >
            <Element canvas id='random-428' is='div' className='w-[100%] h-full flex flex-row flex-wrap justify-center align-items-center' >
            <Element canvas id='random-429' is='div' className='max-w-[386px] min-w-[290px] h-[full] mx-auto my-auto flex flex-col' style={{boxShadow: '0px 0px 4px 0px #FFFFFF1A', border:'1px solid #FFFFFF1A', flex:'28%'}}>
                  <Element canvas id='random-430' is='div' className='max-h-[280px] min-h-[213px]'>
                  <Element canvas style={{width:'100%', display:'inline-block', height:'100%'}} id='random-id-106' is='div'>
                  <ImageC className='max-h-[280px] min-h-[213px] w-[100%] object-cover' src='https://i.ibb.co/RyNZCkT/blog2-1.jpg'/>
                  </Element>
                  </Element>
                <Element canvas id='random-431' is='div' className='mx-[10px] flex flex-col align-items-start my-auto'>
                  <Element canvas id='random-432' is='div' className='my-2'>
                    <Element canvas style={{display:'inline-block', fontSize:'24px', fontWeight:'bold', fontFamily:'Rubik', color:'#FFF'}} id='random-id-25' is='div'>
                    <Text text='Lorem ipsum'/>
                    </Element>
                    <Element canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'300px', fontFamily:'Rubik', color:'#FFFFFF9A'}} id='random-id-26' is='div'>
                    <Text text='12/09/2023'/>
                    </Element>
                  </Element>
                    <Element canvas className='my-2' style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', color:'#FFFFFF9A', lineHeight:'25.2px'}} id='random-id-27' is='div'>
                    <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...'/>
                    </Element>
                  <Element canvas id='random-id-206' is='div' className='my-4 mx-auto'>
                    <Button  classn='block' class='flex cursor-pointer text-[#FFF] bg-[#3265E3] hover:bg-[#0049FF]'
                    style={{ fontWeight:'bold', fontFamily:'Rubik', fontSize:'22px', border:'1px solid #FFFFFF1A', width:'260px', height:'70px', boxShadow: '0px 4px 10px 0px #FFFFFF0D'}}>
                    <Element canvas style={{display:'inline-block'}} className='mx-auto my-auto flex' id='random-id-28' is='div'>
                    <Text text='Learn more'/>
                    </Element>
                    </Button>
                  </Element>
                </Element>
                </Element>
                <Element canvas id='random-433' is='div' className='max-w-[386px] min-w-[290px] h-[full] mx-4 my-4 flex flex-col' style={{boxShadow: '0px 0px 4px 0px #FFFFFF1A', border:'1px solid #FFFFFF1A', flex:'28%'}}>
                  <Element canvas id='random-434' is='div' className='max-h-[280px] min-h-[213px]'>
                  <Element canvas style={{width:'100%', display:'inline-block', height:'100%'}} id='random-id-107' is='div'>
                  <ImageC className='max-h-[280px] min-h-[213px] w-[100%] object-cover' src='https://i.ibb.co/WVTftrs/blog2-2.jpg'/>
                  </Element>
                  </Element>
                <Element canvas id='random-435' is='div' className='mx-[10px] flex flex-col align-items-start my-auto'>
                  <Element canvas id='random-436' is='div' className='my-2'>
                    <Element canvas style={{display:'inline-block', fontSize:'24px', fontWeight:'bold', fontFamily:'Rubik', color:'#FFF'}} id='random-id-29' is='div'>
                    <Text text='Lorem ipsum'/>
                    </Element>
                    <Element canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'300px', fontFamily:'Rubik', color:'#FFFFFF9A'}} id='random-id-30' is='div'>
                    <Text text='12/09/2023'/>
                    </Element>
                  </Element>
                    <Element canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', color:'#FFFFFF9A', lineHeight:'25.2px'}} className='my-2' id='random-id-31' is='div'>
                    <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...'/>
                    </Element>
                  <Element canvas id='random-id-207' is='div' className='my-4 mx-auto'>
                    <Button  classn='block' class='flex cursor-pointer text-[#FFF] bg-[#3265E3] hover:bg-[#0049FF]'
                    style={{ fontWeight:'bold', fontFamily:'Rubik', fontSize:'22px', border:'1px solid #FFFFFF1A', width:'260px', height:'70px', boxShadow: '0px 4px 10px 0px #FFFFFF0D'}}>
                    <Element canvas style={{display:'inline-block'}} className='mx-auto my-auto flex' id='random-id-32' is='div'>
                    <Text text='Learn more'/>
                    </Element>
                    </Button>
                  </Element>
                </Element>
                </Element>
                <Element canvas id='random-437' is='div' className='max-w-[386px] min-w-[290px] h-[full] mx-auto my-auto flex flex-col' style={{boxShadow: '0px 0px 4px 0px #FFFFFF1A', border:'1px solid #FFFFFF1A', flex:'28%'}}>
                  <Element canvas id='random-438' is='div' className='max-h-[280px] min-h-[213px]'>
                  <Element canvas style={{width:'100%', display:'inline-block', height:'100%'}} id='random-id-108' is='div'>
                  <ImageC className='max-h-[280px] min-h-[213px] w-[100%] object-cover' src='https://i.ibb.co/3SpZz4L/blog2-3.jpg'/>
                  </Element>
                  </Element>
                <Element canvas id='random-439' is='div' className='mx-[10px] flex flex-col align-items-start my-auto'>
                  <Element canvas id='random-440' is='div' className='my-2'>
                    <Element canvas style={{display:'inline-block', fontSize:'24px', fontWeight:'bold', fontFamily:'Rubik', color:'#FFF'}} id='random-id-33' is='div'>
                    <Text text='Lorem ipsum'/>
                    </Element>
                  <Element canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'300px', fontFamily:'Rubik', color:'#FFFFFF9A'}} id='random-id-34' is='div'>
                    <Text text='12/09/2023'/>
                    </Element>
                  </Element>
                    <Element canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', color:'#FFFFFF9A', lineHeight:'25.2px'}} className='my-2'  id='random-id-35' is='div'>
                    <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...'/>
                    </Element>
                  <Element canvas id='random-id-208' is='div' className='my-4 mx-auto'>
                    <Button  classn='block' class='flex cursor-pointer text-[#FFF] bg-[#3265E3] hover:bg-[#0049FF]'
                    style={{ fontWeight:'bold', fontFamily:'Rubik', fontSize:'22px', border:'1px solid #FFFFFF1A', width:'260px', height:'70px', boxShadow: '0px 4px 10px 0px #FFFFFF0D'}}>
                    <Element canvas style={{display:'inline-block'}} className='mx-auto my-auto flex' id='random-id-36' is='div'>
                    <Text text='Learn more'/>
                    </Element>
                    </Button>
                  </Element>
                </Element>
                </Element>
            </Element>
      </Element>
        
    ]

    const styles = [
        
        <div className='w-[100%] h-full bg-[#121826] py-10'>
            <div>
              <div>
              <Element canvas style={{display:'inline-block', fontSize:'46px', fontWeight:'bold', fontFamily:'Rubik'}} className='w-[105px] IncreaseConversion2 mb-10 ' id='random-id-37' is='div'>
                    <Text text='Blog.'/>
                    </Element>
              <div className='w-[100%] flex flex-row flex-wrap justify-between'>
                <div className='w-[380px]'>
                <input className="inpute my-2 form-control bg-transparent text-white" style={{border:'1px solid #3265E366', height:'50px', fontSize:'18px'}} placeholder="Search request"/>
                </div>
                <div className='w-[auto] h-[50px] flex flex-row justify-between my-auto border-none'>
                    <label className='flex my-auto text-[#FFFFFF] text-[12px] mx-4' style={{fontWeight:'600', fontFamily:'Rubik'}}>
                    <Element canvas style={{display:'inline-block'}} id='random-id-38' is='div'>
                    <Text text='Sort by:'/>
                    </Element>
                    </label>
                    <select className='w-[150px] flex align-items-center border-none text-[14px] font-semibold text-[#4BDBA2] bg-transparent' aria-label="Default select example"> 
                        <option className=' bg-[#121826]' style={{fontSize:'20px', fontWeight:'400', fontFamily:'Rubik'}} value="Recent date">
                        <Element canvas style={{display:'inline-block'}} id='random-id-39' is='div'>
                    <Text text='Recent date'/>
                    </Element>
                          </option>
                        <option className=' bg-[#121826]' style={{fontSize:'20px', fontWeight:'400', fontFamily:'Rubik'}} value="a-z">A - Z</option>
                        <option className=' bg-[#121826]' style={{fontSize:'20px', fontWeight:'400', fontFamily:'Rubik'}} value="z-a">Z - A</option>
                    </select>
                </div>
                <div className='w-[100%] h-full my-6'>
                    {blogs[selectBlogs]}
                </div>
                <div style={{height:"7%", width:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                    <ArrowBackIosNewIcon style={{color:'#4BDBA2', fontSize:'25px', dropShadow:'(0px 0px 4px rgba(255, 255, 255, 0.10)'}}></ArrowBackIosNewIcon>
                    {blogs.map((x,i) => (
                        <div style={{cursor:"pointer", width:"25px", display:"flex", justifyContent:"center", alignItems:"center",  borderRadius:"4px", background:"#4BDBA2", color:'#FFF', fontSize:'14px', height:"25px", margin:"10px", fontWeight:'bolder'}} onClick={() => {
                            setSelectBlogs(i)
                        }}> {i+1} </div>
                    ))}
                    <ArrowForwardIosIcon style={{color:'#4BDBA2', fontSize:'25px', dropShadow:'(0px 0px 4px rgba(255, 255, 255, 0.10)'}}></ArrowForwardIosIcon>
                </div>
            </div>
              </div>
            </div>
        </div>
    ]


    return (
      <div style={{ padding: "0px", display:"flex", justifyContent:"center", alignItems:"center", width:'100%', height:'100%'}} ref={connect}>
      <Element canvas is={Parent} id='parentId1' className="w-[100%] h-auto" >
      <Element canvas is={Parent} id='parentId1' className="w-[100%] h-auto" row={true} >
        {styles[selected]}
      </Element>
      </Element>
  </div>
  );
}

    Blog_2.craft = {
        displayName: "Blog 2",
        props: {
        selected: 1, // here is the default value of the selected style
isBlock: true
        },
        rules: {
            canDrag: () => true,
            canDrop: () => true,
        },
        related: {
            toolbar: NewBlogSettings2,
        },
    };