import React, { useContext, useEffect, useState } from "react";
import { useDarkMode } from "../../../../../DarkModeContext";
import { AddPageIcon, DeleteNameIcon } from "../../../../editor/Viewport/EditorSvg";
import SelectionBox from "../../../../editor/Viewport/Pop-up/PopUp";
import { CloseIcon2, PenEditIcon } from "../../../../editor/Viewport/Pop-up/svgArray";
import { category } from "../../../basic/Image/ImageSettings";
import SidebarContext from "../../../../../SidebarShown/SidebarContext";

import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';

export default function SideBarProperties(props) {
  const { isDarkMode } = useDarkMode();


  const {
      navigationSide,
      enabled,
      active,
      socialIcons,
      setSocialIcons,
      logoText,
      setLogoText,
      setLogoIcon,
      logoIcon,
  } = props

  const [selectedIcon, setSelectedIcon] = useState(null);
  const [href, setHref] = useState('')
  const {showSideBar, setShowSideBar} = useContext(SidebarContext)
  const [iconsSquare, setIconsSquare] = useState(false)
  const [iconsSquareTwo, setIconsSquareTwo] = useState(false)

  const [selectedIcons, setSelectedIcons] = useState(null);


  useEffect(() => {
    if(logoIcon) {
      setSelectedIcon(logoIcon)
    }
  }, [logoIcon])

  useEffect(() => {
    if(selectedIcon) {
      setLogoIcon(selectedIcon)
    } else {
      return;
    }
  },[selectedIcon, setLogoIcon])


  const handleIconImageSelect = (selectedUrl) => {

    if (Array.isArray(selectedUrl) && selectedUrl.length) {
      setSelectedIcon(selectedUrl[0]);
    } else {
      setSelectedIcon(selectedUrl)
    }

    setLogoIcon(selectedIcon)

    setIconsSquare(false);

    setIconsSquareTwo(false);

  };

  
  const updateHref = (index, newHref) => {
    const newSocial = socialIcons.map((icon, i) => {
      if (i === index) {
        return {...icon, href: newHref};
      }
      return icon;
      
    })
    setSocialIcons(newSocial);

  };

  const handleSocialIconsUpdates = (selectedUrl) => {

    if (Array.isArray(selectedUrl) && selectedUrl.length) {
      setSelectedIcons(selectedUrl[0]);
    } else {
      setSelectedIcons(selectedUrl)
    }

    setSocialIcons([ 
    ...socialIcons,
    { name:selectedUrl.name,
      path:selectedUrl.path,
      width:selectedUrl.width,
      height:selectedUrl.height,
      viewBox: selectedUrl.viewBox,
      show:true,
      href:href
    } 
  ])

    setIconsSquare(false);
  }

  const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} /> 
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      color:'#0867FC',
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#0867FC',
        '& + .MuiSwitch-track': {
          backgroundColor: '#E9E9EA',
          opacity: 1,
          border: 0,
          ...theme.applyStyles('dark', {
            backgroundColor: '#2ECA45',
          }),
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#0867FC',
        border: '6px solid #fff',
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        color:'#0867FC',
        opacity: 0.7,
        ...theme.applyStyles('dark', {
          opacity: 0.3,
        }),
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: '#E9E9EA',
      opacity: 1,
      // transition: theme.transitions.create(['background-color'], {
      //   duration: 500,
      // }),
      ...theme.applyStyles('dark', {
        backgroundColor: '#39393D',
      }),
    },
  }));

  const handleToggle = () => {
    setShowSideBar(!showSideBar);
  };

   

    return (
      <div className='flex flex-column rounded-[3.5vmin] overflow-hidden' style={{height:'100%'}}>
      <div className="flex flex-column h-full px-[1vmin]" style={{ overflow:'scroll', scrollbarWidth:'none'}}>
        {/* {navigationSide && enabled && !active ? (
          <div className='w-full' style={{height:'fit-content'}}>
            <div className='title w-full text-center' style={{ fontFamily: 'Inter', fontWeight: '500', color: isDarkMode ? '#fff' : '#161717', fontSize:'1.8vmin' }}>Type of app navigation</div>
            <div style={{ width: "100%"}} className="flex flex-row my-[1.2vmin] justify-center gap-[3%] items-center">
              <div className={`${isDarkMode ? 'text-[#ffffff]' : showSideBar ? 'text-[#000000]' : 'text-[#989191]'} text-[1.8Vmin] item-hidden`} style={{ fontFamily: 'Inter', fontWeight: '500' }}>Sidebar</div>
              <div>
                <IOSSwitch
                  checked={!showSideBar}
                  onChange={handleToggle}
                />
            </div>
              <div className={`${isDarkMode ? 'text-[#ffffff]' : !showSideBar ? 'text-[#000000]' : 'text-[#989191]'} text-[1.8Vmin] item-hidden`} style={{ fontFamily: 'Inter', fontWeight: '500' }}>Header</div>
            </div>
            <div className='text-center toggle-result'>
              {showSideBar ? 'Sidebar' : 'Header'}
            </div>
          </div>
        ) : null} */}
        {
          navigationSide && enabled && !active && (
            <div className='w-full h-full'>
              <div className="settings-panel pt-[2%] pb-[2Vmin] border-b my-[2%] w-[100%] px-[2%] flex flex-col justify-center align-items-center">
                <div className='max-w-[95%] min-w-[20vmin] my-2% h-[fit-content] px-[1vmin] mx-auto align-items-center justify-center flex flex-row border-[.25vmin] border-solid border-[#E9E9EA] hover:border-[#0867FC]' style={{ borderRadius: '3.5Vmin' }}>
                      <div className='cursor-pointer w-[6vmin] h-[6vmin] flex justify-center align-items-center' onClick={() => setIconsSquareTwo(!iconsSquareTwo)}>
                          <svg aria-hidden="true" focusable="false" role="img" viewBox={selectedIcon?.viewBox} width='100%' height='50%' fill={!isDarkMode ? selectedIcon?.fill ? selectedIcon?.fill : '#0867FC' : selectedIcon?.fill ? selectedIcon?.fill : '#0867FC'}><path d={selectedIcon?.path}></path></svg>
                      </div>
                      <div>
                      <input
                        placeholder='ex. AYCC'
                        className='w-full my-[3vmin] py-[3.5%] px-[5%] flex justify-center items-center my-auto'
                        style={{ margin: '3% 0 0 0', width: '100%', borderRadius: '2Vmin', border:'none', background: 'transparent', color: isDarkMode ? '#fff' : '#161717', fontSize: '1.9Vmin', fontWeight: '400', fontFamily: 'Inter' }}
                        value={logoText}
                        onChange={(e) => setLogoText(e.target.value)}
                      />
                      </div>
                      <div className='cursor-pointer mx-[1Vmin] flex items-center' onClick={() => setIconsSquareTwo(!iconsSquareTwo)}>
                        <PenEditIcon fill='none' stroke='#989191' width='3Vmin' height='3Vmin' />
                      </div>
                    </div>
                    <SelectionBox
                      category={category}
                      setIconsSquare={setIconsSquareTwo}
                      iconsSquare={iconsSquareTwo}
                      onSelect={handleIconImageSelect}
                    />
  
                  </div>
                  
                  {socialIcons.map((icon, index) => (
                    <>
                    <div key={index} className="settings-panel w-full my-[1vmin] align-items-center flex  justify-center" >
                      <div className='cursor-pointer w-[4vmin] h-[4vmin] my-auto flex justify-center align-items-center'>
                        <svg aria-hidden="true" focusable="false" role="img" viewBox={icon.viewBox} width={'100%'} height='80%' fill={!isDarkMode ? icon?.fill ? icon?.fill : '#0867FC' : icon?.fill ? icon?.fill : '#0867FC'}><path d={icon?.path}></path></svg>
                      </div>
                 
                    <div className="w-[70%] flex justify-center ">
                      <input
                        placeholder={`https://www.anyURl`}
                        className='py-[3.5%] px-[5%] flex justify-center items-center my-auto'
                        style={{ margin: '3% 0 0 0', width: '100%', borderRadius: '2Vmin', background: 'transparent', color: isDarkMode ? '#fff' : '#161717', fontSize: '1.4Vmin', fontWeight: '400', fontFamily: 'Inter' }}
                        value={icon.href}
                        onChange={(e) => {
                          let hrefValue = e.target.value
                          updateHref(index, hrefValue)
                        }}
                      />
                    </div>
                  <div 
                  className='cursor-pointer'
                  onClick={() => {
                    let updateSocial = socialIcons.filter(x => x.name !== icon.name);
                   setSocialIcons(updateSocial)
                  }}>
                    <DeleteNameIcon fill='#919191' width='2.3vmin' height='2.3vmin'/>
                  </div>
              </div>
              </>
              ))}
  
              {socialIcons.length !== 4 ?
              <div className='flex mx-auto cursor-pointer' 
              onClick={() => setIconsSquare(true) } 
              style={{width:'fit-content', marginTop:'1.5vmin'}}>
                <div 
                className='flex justify-center'>
                  <AddPageIcon width='2.5vmin' height='2.5vmin'/>
                </div>
                <div className={`text-[${isDarkMode? '#fff' : '000'}] text-[1.5vmin] my-auto mx-[1.5vmin]`} style={{opacity:'50%'}}>Click to add more</div>
              </div> : null}
  
              <SelectionBox
                  category={category}
                  setIconsSquare={setIconsSquare}
                  iconsSquare={iconsSquare}
                  onSelect={handleSocialIconsUpdates}
                />
             
            </div>
          )
        }
  
  {/* (
            <div>{active && related.toolbar && React.createElement(related.toolbar)}
              {!active && (
                <div
                  className="flex flex-col items-center h-[82vmin] border-2 justify-center text-center"
                  style={{
                    color: 'rgba(0, 0, 0, 0.5607843137254902)',
                  }}
                >
                  <div className='text-[2.2Vmin]'>Click on a component to start editing.</div>
                </div>
              )}
            </div>
  
          ) */}
  
      </div>
      </div>
      );
    };