/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, { useEffect, useState } from 'react';
import { Element, useEditor, useNode } from '@craftjs/core';
import { Text } from '../../basic/Text';
import { NewFormSettings } from './newFormSetting'; 
import { Box, TextField, Typography } from '@mui/material';
import './Form.css'
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AodIcon from '@mui/icons-material/Aod';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import { Button } from '../../basic/Button';
import { ImageC } from '../../basic/Image';
import { Parent } from '../../Parent/index.js';
import { Wrapper } from '../../wrapper/index.js';
import { BounceLoader } from 'react-spinners';
import { SvgIcon } from '../../basic/Svg';
import { Input } from '../../basic/Input/Index.js';


export const Form_2 = ({selected}) => {
 
    const { 
        actions: { setProp },
          connectors: {
            connect
          } 
      } = useNode();
    
      
      const {
        enabled,
      } = useEditor((state, query) => ({
        enabled: state.options.enabled,
     
      }));

    const styles = [
        <Element canvas is={Parent} id='parentId100' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId200' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-400' is='parent' className='w-[100%] h-full bg-[#FFFFFF] py-8'>
            <Wrapper canvas id='random-id-401' is='container' className='container'>
                <Wrapper canvas id='random-id-402' is='wrapper' className='w-full h-full flex flex-row flex-wrap justify-center'>
                    <Wrapper canvas id='random-id-403' is='div' className='   h-full bg-[#2479FD] mx-auto py-20 pl-20 pr-40' style={{borderRadius:'0px 30px 30px 30px', flex:'50%'}}>
                    <Wrapper canvas id='random-id-404' is='parent' className='w-full h-full flex flex-col'>
                            <Wrapper canvas style={{display:'inline-block', fontSize:'46px', fontWeight:'bold', fontFamily:'Poppins', color:'#FFFFFF'}} className='my-auto pb-10' id='random-id-6' is='div'>
                                <Text text='Ready to talk?'/>
                            </Wrapper>
                        <Wrapper canvas id='random-id-405' is='div' className='w-full h-full flex flex-row my-8'>
                            <Wrapper canvas id='random-id-406' is='div' className='mr-6 my-auto' style={{width:'auto', height:'auto'}}>
                            <SvgIcon width='30' height='30' viewBox='0 0 47 46' fill='#FFC92E' path='m33.083 1.936-19.167-.02a3.845 3.845 0 0 0-3.833 3.834v34.5a3.845 3.845 0 0 0 3.833 3.833h19.167a3.845 3.845 0 0 0 3.833-3.833V5.75c0-2.108-1.725-3.814-3.833-3.814m0 32.564H13.916v-23h19.167zm-17.25-15.333h15.333v2.875H15.833zm1.917 5.75h11.5v2.875h-11.5z' />
                            </Wrapper>
                            
                                <Wrapper canvas className='my-auto' style={{display:'inline-block', fontSize:'18px', fontWeight:'400px', fontFamily:'Poppins', color:'#FFFFFF'}} id='random-id-7' is='div'>
                                    <Text text='team-email@email.com'/>
                                </Wrapper>
                            
                        </Wrapper>
                        <Wrapper canvas id='random-id-407' is='div' className='w-full h-full flex flex-row my-8'>
                            <Wrapper canvas id='random-id-408' is='div' className='mr-6 my-auto' style={{width:'auto', height:'auto'}}>
                            <SvgIcon width='30' height='30' viewBox='0 0 46 46' fill='#FFC92E' path='M23 3.833c-7.418 0-13.417 6-13.417 13.417C9.583 27.312 23 42.167 23 42.167S36.416 27.312 36.416 17.25c0-7.418-5.999-13.417-13.416-13.417m0 18.209a4.791 4.791 0 1 1 0-9.583 4.791 4.791 0 0 1 0 9.583' />
                            </Wrapper>
                                <Wrapper canvas className='my-auto' style={{display:'inline-block', fontSize:'18px', fontWeight:'400px', fontFamily:'Poppins', color:'#FFFFFF'}} id='random-id-8' is='div'>
                                    <Text text='Contoso Ltd215, E Tasman DrPo Box, 65502CA, 95134 San Jose'/>
                                </Wrapper>
                        </Wrapper>
                        <Wrapper canvas id='random-id-409' is='div' className='w-full h-full flex flex-row my-8'>
                            <Wrapper canvas id='random-id-410' is='div'  className='mr-6 my-auto' style={{width:'auto', height:'auto'}}>
                            <SvgIcon width='30' height='30' viewBox='0 0 47 46' fill='#FFC92E' path='M38.833 15.333 23.5 24.917 8.166 15.333V11.5L23.5 21.083 38.833 11.5m0-3.833H8.166A3.82 3.82 0 0 0 4.333 11.5v23a3.834 3.834 0 0 0 3.833 3.833h30.667a3.834 3.834 0 0 0 3.833-3.833v-23a3.833 3.833 0 0 0-3.833-3.833' />

                            </Wrapper>
                            
                                <Wrapper canvas className='my-auto' style={{display:'inline-block', fontSize:'18px', fontWeight:'400px', fontFamily:'Poppins', color:'#FFFFFF'}} id='random-id-9' is='div'>
                                    <Text text='+111 111 111 111'/>
                                </Wrapper>
                            
                        </Wrapper>
                        <Wrapper canvas id='random-id-411' is='div' className='w-full h-full flex flex-row my-10'>
                            <Wrapper canvas id='random-id-412' is='div' className='mx-4 cursor-pointer'>
                                <SvgIcon fill='#fff' viewBox='0 0 44 44' path='M35.75 0q3.41 0 5.83 2.42T44 8.25v27.5q0 3.41-2.42 5.83T35.75 44h-5.385V26.956h5.7l.86-6.646h-6.56v-4.24q0-1.604.673-2.406.672-.802 2.62-.802l3.496-.029v-5.93q-1.805-.257-5.1-.257-3.895 0-6.23 2.292-2.334 2.29-2.334 6.474v4.898h-5.73v6.646h5.73V44H8.25q-3.41 0-5.83-2.42T0 35.75V8.25q0-3.41 2.42-5.83T8.25 0z' />
                            </Wrapper>
                            <Wrapper canvas id='random-id-413' is='div' className='mx-4 cursor-pointer'>
                            <SvgIcon fill='#fff' viewBox='0 0 42 42' path='M37.1.3H4.9C2.37.3.3 2.37.3 4.9v32.2c0 2.532 2.07 4.6 4.6 4.6h32.2c2.53 0 4.6-2.068 4.6-4.6V4.9c0-2.53-2.07-4.6-4.6-4.6M20.963 33.703A12.74 12.74 0 0 0 33.7 20.963c0-.777-.099-1.527-.236-2.263H37.1v16.597a1.587 1.587 0 0 1-1.594 1.587H6.494A1.59 1.59 0 0 1 4.9 35.297V18.7h3.563c-.14.736-.24 1.486-.24 2.263a12.74 12.74 0 0 0 12.74 12.74m-7.96-12.74a7.961 7.961 0 1 1 15.922 0 7.961 7.961 0 0 1-15.922 0M35.506 11.8H31.79a1.596 1.596 0 0 1-1.591-1.592V6.49c0-.878.713-1.59 1.59-1.59h3.714c.883 0 1.596.712 1.596 1.59v3.717c0 .876-.713 1.594-1.594 1.594' />
                                
                            </Wrapper>
                            <Wrapper canvas id='random-id-414' is='div' className='mx-4 cursor-pointer'>
                                <SvgIcon fill='#fff' viewBox='0 0 44 44' path='M39.875 0H4.125A4.137 4.137 0 0 0 0 4.125v35.75A4.137 4.137 0 0 0 4.125 44h35.75A4.137 4.137 0 0 0 44 39.875V4.125A4.137 4.137 0 0 0 39.875 0M16.5 35.75H11V16.5h5.5zm-2.75-22a2.75 2.75 0 1 1 0-5.5 2.75 2.75 0 0 1 0 5.5m22 22h-5.5v-11a2.75 2.75 0 1 0-5.5 0v11h-5.5V16.5h5.5v3.413c1.133-1.557 2.871-3.413 4.813-3.413 3.42 0 6.187 3.077 6.187 6.875z'/>
                            </Wrapper>
                        </Wrapper>
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-415' is='div' className='   h-full mx-auto my-auto px-10' style={{flex:'50%'}}>
                    <Wrapper canvas id='random-id-416' is='form'>
                        <Input className='my-4 p-[20px] text-[16px] focus:ring focus:ring-[#2479FD4D]' style={{border:'1px solid #2479FD4D', borderRadius:'25px',  fontFamily:'Poppins'}} type="text" placeholder='Enter Your Name' />
                        <Input className='my-4 p-[20px] text-[16px] focus:ring focus:ring-[#2479FD4D]' style={{border:'1px solid #2479FD4D', borderRadius:'25px',  fontFamily:'Poppins'}} type="text" placeholder='Phone Number' />
                        <Input className='my-4 p-[20px] text-[16px] focus:ring focus:ring-[#2479FD4D]' style={{border:'1px solid #2479FD4D', borderRadius:'25px',  fontFamily:'Poppins'}} type="text" placeholder='Email' />
                        <Input className='my-4 p-[20px] text-[16px] focus:ring focus:ring-[#2479FD4D]' style={{border:'1px solid #2479FD4D', borderRadius:'25px',  fontFamily:'Poppins'}} type="text" placeholder='Enter your meessage' />
                        <Wrapper canvas id='random-id-200' is='div' className='w-full h-full flex justify-end mt-4 mx-auto'>
                            <Button href={'/'} classn='block' class='w-[280px] h-[60px] bg-[#FFC92E] hover:bg-[#FFF5D7] border-1 border-[#FFC92E] hover:border-[#FFC92E] flex cursor-pointer justify-center text-[#000000]' style={{borderRadius:'8px'}}>
                                <Wrapper canvas style={{display:'inline-block', fontSize:'24px', fontWeight:'500', fontFamily:'Poppins', }} className='mx-auto my-auto' id='random-id-10' is='div'>
                                    <Text text='Send'/>
                                </Wrapper>
                                <Wrapper canvas style={{display:'inline-block'}} className='mx-auto my-auto' id='random-id-10' is='div'>
                                    <ImageC src='https://i.ibb.co/429rB9T/iconmonstr-arrow-right-circle-thin-240.png' style={{width:'30px', height:'auto'}}/>
                                </Wrapper>
                            </Button>
                        </Wrapper>
                    </Wrapper>
                </Wrapper>
                </Wrapper>
            </Wrapper>
        </Wrapper></Element>
        </Element>
        ,
        <Element canvas is={Parent} id='parentId300' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId400' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-417' is='parent' className='w-[100%] h-full bg-[#FFFFFF] py-8'>
            <Wrapper canvas id='random-id-418' is='container' className='container'>
                <Wrapper canvas id='random-id-419' is='wrapper' className='w-full h-full flex flex-row flex-wrap justify-center'>
                    <Wrapper canvas id='random-id-420' is='div' className='   h-full bg-[#FF0060] mx-auto py-20 pl-20 pr-40' style={{borderRadius:'0px 30px 30px 30px', flex:'50%'}}>
                    <Wrapper canvas id='random-id-421' is='div' className='w-full h-full flex flex-col'>
                            <Wrapper canvas style={{display:'inline-block', fontSize:'46px', fontWeight:'bold', fontFamily:'Prompt', color:'#FFFFFF'}} className='my-auto pb-10' id='random-id-1' is='div'>
                                <Text text='Ready to talk?'/>
                            </Wrapper>
                        <Wrapper canvas id='random-id-422' is='div' className='w-full h-full flex flex-row my-8'>
                            <Wrapper canvas id='random-id-423' is='div' className='mr-6 my-auto' style={{width:'auto', height:'auto'}}>
                            <SvgIcon width='30' height='30' viewBox='0 0 47 46' fill='#F6FA70' path='m33.083 1.936-19.167-.02a3.845 3.845 0 0 0-3.833 3.834v34.5a3.845 3.845 0 0 0 3.833 3.833h19.167a3.845 3.845 0 0 0 3.833-3.833V5.75c0-2.108-1.725-3.814-3.833-3.814m0 32.564H13.916v-23h19.167zm-17.25-15.333h15.333v2.875H15.833zm1.917 5.75h11.5v2.875h-11.5z' />

                            </Wrapper>
                            
                                <Wrapper canvas className='my-auto' style={{display:'inline-block', fontSize:'18px', fontWeight:'400px', fontFamily:'Prompt', color:'#FFFFFF'}} id='random-id-2' is='div'>
                                    <Text text='team-email@email.com'/>
                                </Wrapper>
                           
                        </Wrapper>
                        <Wrapper canvas id='random-id-424' is='div' className='w-full h-full flex flex-row my-8'>
                            <Wrapper canvas id='random-id-425' is='div' className='mr-6 my-auto' style={{width:'auto', height:'auto'}}>
                            <SvgIcon width='30' height='30' viewBox='0 0 46 46' fill='#F6FA70' path='M23 3.833c-7.418 0-13.417 6-13.417 13.417C9.583 27.312 23 42.167 23 42.167S36.416 27.312 36.416 17.25c0-7.418-5.999-13.417-13.416-13.417m0 18.209a4.791 4.791 0 1 1 0-9.583 4.791 4.791 0 0 1 0 9.583' />

                            </Wrapper>
                            
                                <Wrapper canvas className='my-auto' style={{display:'inline-block', fontSize:'18px', fontWeight:'400px', fontFamily:'Prompt', color:'#FFFFFF'}} id='random-id-3' is='div'>
                                    <Text text='Contoso Ltd215, E Tasman DrPo Box, 65502CA, 95134 San Jose'/>
                                </Wrapper>
                           
                        </Wrapper>
                        <Wrapper canvas id='random-id-426' is='div' className='w-full h-full flex flex-row my-8'>
                            <Wrapper canvas id='random-id-427' is='div' className='mr-6 my-auto' style={{width:'auto', height:'auto'}}>
                            <SvgIcon width='30' height='30' viewBox='0 0 47 46' fill='#F6FA70' path='M38.833 15.333 23.5 24.917 8.166 15.333V11.5L23.5 21.083 38.833 11.5m0-3.833H8.166A3.82 3.82 0 0 0 4.333 11.5v23a3.834 3.834 0 0 0 3.833 3.833h30.667a3.834 3.834 0 0 0 3.833-3.833v-23a3.833 3.833 0 0 0-3.833-3.833' />

                            </Wrapper>
                            
                                <Wrapper canvas className='my-auto' style={{display:'inline-block', fontSize:'18px', fontWeight:'400px', fontFamily:'Prompt', color:'#FFFFFF'}} id='random-id-4' is='div'>
                                    <Text text='+111 111 111 111'/>
                                </Wrapper>
                           
                        </Wrapper>
                        <Wrapper canvas id='random-id-428' is='div' className='w-full h-full flex flex-row my-10'>
                            <Wrapper canvas id='random-id-429' is='div' className='mx-4 cursor-pointer'>
                                <SvgIcon fill='#fff' viewBox='0 0 44 44' path='M35.75 0q3.41 0 5.83 2.42T44 8.25v27.5q0 3.41-2.42 5.83T35.75 44h-5.385V26.956h5.7l.86-6.646h-6.56v-4.24q0-1.604.673-2.406.672-.802 2.62-.802l3.496-.029v-5.93q-1.805-.257-5.1-.257-3.895 0-6.23 2.292-2.334 2.29-2.334 6.474v4.898h-5.73v6.646h5.73V44H8.25q-3.41 0-5.83-2.42T0 35.75V8.25q0-3.41 2.42-5.83T8.25 0z' />
                            </Wrapper>
                            <Wrapper canvas id='random-id-430' is='div' className='mx-4 cursor-pointer'>
                            <SvgIcon fill='#fff' viewBox='0 0 42 42' path='M37.1.3H4.9C2.37.3.3 2.37.3 4.9v32.2c0 2.532 2.07 4.6 4.6 4.6h32.2c2.53 0 4.6-2.068 4.6-4.6V4.9c0-2.53-2.07-4.6-4.6-4.6M20.963 33.703A12.74 12.74 0 0 0 33.7 20.963c0-.777-.099-1.527-.236-2.263H37.1v16.597a1.587 1.587 0 0 1-1.594 1.587H6.494A1.59 1.59 0 0 1 4.9 35.297V18.7h3.563c-.14.736-.24 1.486-.24 2.263a12.74 12.74 0 0 0 12.74 12.74m-7.96-12.74a7.961 7.961 0 1 1 15.922 0 7.961 7.961 0 0 1-15.922 0M35.506 11.8H31.79a1.596 1.596 0 0 1-1.591-1.592V6.49c0-.878.713-1.59 1.59-1.59h3.714c.883 0 1.596.712 1.596 1.59v3.717c0 .876-.713 1.594-1.594 1.594' />
                                
                            </Wrapper>
                            <Wrapper canvas id='random-id-431' is='div' className='mx-4 cursor-pointer'>
                                <SvgIcon fill='#fff' viewBox='0 0 44 44' path='M39.875 0H4.125A4.137 4.137 0 0 0 0 4.125v35.75A4.137 4.137 0 0 0 4.125 44h35.75A4.137 4.137 0 0 0 44 39.875V4.125A4.137 4.137 0 0 0 39.875 0M16.5 35.75H11V16.5h5.5zm-2.75-22a2.75 2.75 0 1 1 0-5.5 2.75 2.75 0 0 1 0 5.5m22 22h-5.5v-11a2.75 2.75 0 1 0-5.5 0v11h-5.5V16.5h5.5v3.413c1.133-1.557 2.871-3.413 4.813-3.413 3.42 0 6.187 3.077 6.187 6.875z'/>
                            </Wrapper>
                        </Wrapper>
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-432' is='div' className='   h-full mx-auto my-auto px-10' style={{flex:'50%'}}>
                    <Wrapper canvas id='random-id-433' is='form'>
                        <Input className='my-4 p-[20px] text-[16px] focus:ring focus:ring-[#FF006033]' style={{border:'1px solid #FF006033', borderRadius:'25px',  fontFamily:'Propmt'}} type="text" placeholder='Enter Your Name' />
                        <Input className='my-4 p-[20px] text-[16px] focus:ring focus:ring-[#FF006033]' style={{border:'1px solid #FF006033', borderRadius:'25px',  fontFamily:'Propmt'}} type="text" placeholder='Phone Number' />
                        <Input className='my-4 p-[20px] text-[16px] focus:ring focus:ring-[#FF006033]' style={{border:'1px solid #FF006033', borderRadius:'25px',  fontFamily:'Propmt'}} type="text" placeholder='Email' />
                        <Input className='my-4 p-[20px] text-[16px] focus:ring focus:ring-[#FF006033]' style={{border:'1px solid #FF006033', borderRadius:'25px',  fontFamily:'Propmt'}} type="text" placeholder='Enter your meessage' />
                        <Wrapper canvas id='random-id-201' is='div' className='mx-auto mt-4 w-full h-full flex justify-end'>
                            <Button href={'/'} classn='block' class='w-[280px] h-[60px] bg-[#FF0060] hover:bg-[#C20049] flex cursor-pointer justify-center text-[#FFFFFF]' style={{ borderRadius:'30px'}}>
                                <Wrapper canvas style={{display:'inline-block', fontSize:'24px', fontWeight:'500', fontFamily:'Prompt',}} className='mx-auto my-auto' id='random-id-5' is='div'>
                                    <Text text='send'/>
                                </Wrapper>
                                <Wrapper canvas style={{display:'inline-block'}} className='mx-auto my-auto' id='random-id-10' is='div'>
                                    <ImageC src='https://i.ibb.co/c35zrY4/iconmonstr-arrow-right-circle-thin-240-1.png' style={{width:'30px', height:'auto'}}/>
                                </Wrapper>
                            </Button>
                        </Wrapper>
                    </Wrapper>
                </Wrapper>
                </Wrapper>
            </Wrapper>
        </Wrapper></Element>
        </Element>,

        <Element canvas is={Parent} id='parentId500' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId600' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-434' is='parent' className='w-[100%] h-full bg-[#FFFFFF] py-8'>
            <Wrapper canvas id='random-id-435' is='container' className='container'>
                <Wrapper canvas id='random-id-436' is='wrapper' className='w-full h-full flex flex-row flex-wrap justify-center'>
                    <Wrapper canvas id='random-id-437' is='div' className='   h-full bg-[#FFB7B7] mx-auto py-20 pl-20 pr-40' style={{borderRadius:'20px 8px 8px 8px', flex:'50%'}}>
                    <Wrapper canvas id='random-id-438' is='div' className='w-full h-full flex flex-col'>
                            <Wrapper canvas style={{display:'inline-block', fontSize:'46px', fontWeight:'bold', fontFamily:'Almarai', color:'#FFFFFF'}} className='my-auto pb-10' id='random-id-11' is='div'>
                                <Text text='Ready to talk?'/>
                            </Wrapper>
                        <Wrapper canvas id='random-id-439' is='div' className='w-full h-full flex flex-row my-8'>
                            <Wrapper canvas id='random-id-440' is='div' className='mr-6 my-auto' style={{width:'auto', height:'auto'}}>
                            <SvgIcon width='30' height='30' viewBox='0 0 47 46' fill='#F31559' path='m33.083 1.936-19.167-.02a3.845 3.845 0 0 0-3.833 3.834v34.5a3.845 3.845 0 0 0 3.833 3.833h19.167a3.845 3.845 0 0 0 3.833-3.833V5.75c0-2.108-1.725-3.814-3.833-3.814m0 32.564H13.916v-23h19.167zm-17.25-15.333h15.333v2.875H15.833zm1.917 5.75h11.5v2.875h-11.5z' />

                            </Wrapper>
                           
                                <Wrapper canvas className='my-auto' style={{display:'inline-block', fontSize:'18px', fontWeight:'400px', fontFamily:'Almarai', color:'#000000'}} id='random-id-12' is='div'>
                                    <Text text='team-email@email.com'/>
                                </Wrapper>
                          
                        </Wrapper>
                        <Wrapper canvas id='random-id-441' is='div' className='w-full h-full flex flex-row my-8'>
                            <Wrapper canvas id='random-id-442' is='div'  className='mr-6 my-auto' style={{width:'auto', height:'auto'}}>
                                
                            <SvgIcon width='30' height='30' viewBox='0 0 46 46' fill='#F31559' path='M23 3.833c-7.418 0-13.417 6-13.417 13.417C9.583 27.312 23 42.167 23 42.167S36.416 27.312 36.416 17.25c0-7.418-5.999-13.417-13.416-13.417m0 18.209a4.791 4.791 0 1 1 0-9.583 4.791 4.791 0 0 1 0 9.583' />
                            </Wrapper>
                           
                                <Wrapper canvas className='my-auto' style={{display:'inline-block', fontSize:'18px', fontWeight:'400px', fontFamily:'Almarai', color:'#000000'}} id='random-id-13' is='div'>
                                    <Text text='Contoso Ltd215, E Tasman DrPo Box, 65502CA, 95134 San Jose'/>
                                </Wrapper>
                          
                        </Wrapper>
                        <Wrapper canvas id='random-id-443' is='div' className='w-full h-full flex flex-row my-8'>
                            <Wrapper canvas id='random-id-444' is='div'  className='mr-6 my-auto' style={{width:'auto', height:'auto'}}>
                            <SvgIcon width='30' height='30' viewBox='0 0 47 46' fill='#F31559' path='M38.833 15.333 23.5 24.917 8.166 15.333V11.5L23.5 21.083 38.833 11.5m0-3.833H8.166A3.82 3.82 0 0 0 4.333 11.5v23a3.834 3.834 0 0 0 3.833 3.833h30.667a3.834 3.834 0 0 0 3.833-3.833v-23a3.833 3.833 0 0 0-3.833-3.833' />
                                
                            </Wrapper>
                           
                                <Wrapper canvas className='my-auto' style={{display:'inline-block', fontSize:'18px', fontWeight:'400px', fontFamily:'Almarai', color:'#000000'}} id='random-id-14' is='div'>
                                    <Text text='+111 111 111 111'/>
                                </Wrapper>
                          
                        </Wrapper>
                        <Wrapper canvas id='random-id-445' is='div' className='w-full h-full flex flex-row my-10'>
                            <Wrapper canvas id='random-id-446' is='div' className='mx-4 cursor-pointer'>
                                <SvgIcon fill='#fff' viewBox='0 0 44 44' path='M35.75 0q3.41 0 5.83 2.42T44 8.25v27.5q0 3.41-2.42 5.83T35.75 44h-5.385V26.956h5.7l.86-6.646h-6.56v-4.24q0-1.604.673-2.406.672-.802 2.62-.802l3.496-.029v-5.93q-1.805-.257-5.1-.257-3.895 0-6.23 2.292-2.334 2.29-2.334 6.474v4.898h-5.73v6.646h5.73V44H8.25q-3.41 0-5.83-2.42T0 35.75V8.25q0-3.41 2.42-5.83T8.25 0z' />
                            </Wrapper>
                            <Wrapper canvas id='random-id-447' is='div' className='mx-4 cursor-pointer'>
                            <SvgIcon fill='#fff' viewBox='0 0 42 42' path='M37.1.3H4.9C2.37.3.3 2.37.3 4.9v32.2c0 2.532 2.07 4.6 4.6 4.6h32.2c2.53 0 4.6-2.068 4.6-4.6V4.9c0-2.53-2.07-4.6-4.6-4.6M20.963 33.703A12.74 12.74 0 0 0 33.7 20.963c0-.777-.099-1.527-.236-2.263H37.1v16.597a1.587 1.587 0 0 1-1.594 1.587H6.494A1.59 1.59 0 0 1 4.9 35.297V18.7h3.563c-.14.736-.24 1.486-.24 2.263a12.74 12.74 0 0 0 12.74 12.74m-7.96-12.74a7.961 7.961 0 1 1 15.922 0 7.961 7.961 0 0 1-15.922 0M35.506 11.8H31.79a1.596 1.596 0 0 1-1.591-1.592V6.49c0-.878.713-1.59 1.59-1.59h3.714c.883 0 1.596.712 1.596 1.59v3.717c0 .876-.713 1.594-1.594 1.594' />
                                
                            </Wrapper>
                            <Wrapper canvas id='random-id-448' is='div' className='mx-4 cursor-pointer'>
                                <SvgIcon fill='#fff' viewBox='0 0 44 44' path='M39.875 0H4.125A4.137 4.137 0 0 0 0 4.125v35.75A4.137 4.137 0 0 0 4.125 44h35.75A4.137 4.137 0 0 0 44 39.875V4.125A4.137 4.137 0 0 0 39.875 0M16.5 35.75H11V16.5h5.5zm-2.75-22a2.75 2.75 0 1 1 0-5.5 2.75 2.75 0 0 1 0 5.5m22 22h-5.5v-11a2.75 2.75 0 1 0-5.5 0v11h-5.5V16.5h5.5v3.413c1.133-1.557 2.871-3.413 4.813-3.413 3.42 0 6.187 3.077 6.187 6.875z'/>
                            </Wrapper>
                        </Wrapper>
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-449' is='div' className='   h-full mx-auto my-auto px-10' style={{flex:'50%'}}>
                    <Wrapper canvas id='random-id-450' is='form'>
                        <Input className='my-4 p-[20px] text-[16px] focus:ring focus:ring-[#F3155933]' style={{border:'1px solid #F3155933', borderRadius:'8px',  fontFamily:'Propmt'}} type="text" placeholder='Enter Your Name' />
                        <Input className='my-4 p-[20px] text-[16px] focus:ring focus:ring-[#F3155933]' style={{border:'1px solid #F3155933', borderRadius:'8px',  fontFamily:'Propmt'}} type="text" placeholder='Phone Number' />
                        <Input className='my-4 p-[20px] text-[16px] focus:ring focus:ring-[#F3155933]' style={{border:'1px solid #F3155933', borderRadius:'8px',  fontFamily:'Propmt'}} type="text" placeholder='Email' />
                        <Input className='my-4 p-[20px] text-[16px] focus:ring focus:ring-[#F3155933]' style={{border:'1px solid #F3155933', borderRadius:'8px',  fontFamily:'Propmt'}} type="text" placeholder='Enter your meessage' />
                        <Wrapper canvas id='random-id-202' is='div' className='mx-auto mt-4 w-full h-full flex justify-end'>
                            <Button href={'/'} classn='block' class='w-[280px] h-[60px] bg-[#F31559] hover:bg-[#FF5B8D] flex cursor-pointer justify-center text-[#FFFFFF]' style={{ borderRadius:'8px'}}>
                                <Wrapper canvas style={{display:'inline-block', fontSize:'24px', fontWeight:'500', fontFamily:'Almarai',}} className='mx-auto my-auto' id='random-id-15' is='div'>
                                    <Text text='send'/>
                                </Wrapper>
                                <Wrapper canvas style={{display:'inline-block'}} className='mx-auto my-auto' id='random-id-10' is='div'>
                                    <ImageC src='https://i.ibb.co/c35zrY4/iconmonstr-arrow-right-circle-thin-240-1.png' style={{width:'30px', height:'30px'}}/>
                                </Wrapper>
                            </Button>
                        </Wrapper>
                    </Wrapper>
                </Wrapper>
                </Wrapper>
            </Wrapper>
        </Wrapper></Element>
        </Element>,

        <Element canvas is={Parent} id='parentId700' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId800' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-451' is='parent' className='w-[100%] h-full bg-[#FFFFFF] py-8'>
            <Wrapper canvas id='random-id-452' is='container' className='container'>
                <Wrapper canvas id='random-id-453' is='wrapper' className='w-full h-full flex flex-row flex-wrap justify-center'>
                    <Wrapper canvas id='random-id-454' is='div' className='   h-full bg-[#0C134F] mx-auto py-20 pl-20 pr-40' style={{borderRadius:'8px', flex:'50%'}}>
                    <Wrapper canvas id='random-id-455' is='div' className='w-full h-full flex flex-col'>
                            <Wrapper canvas style={{display:'inline-block', fontSize:'46px', fontWeight:'bold', fontFamily:'Chivo', color:'#FFFFFF'}} className='my-auto pb-10' id='random-id-11' is='div'>
                                <Text text='Ready to talk?'/>
                            </Wrapper>
                        <Wrapper canvas id='random-id-456' is='div' className='w-full h-full flex flex-row my-8'>
                            <Wrapper canvas id='random-id-457' is='div' className='mr-6 my-auto' style={{width:'auto', height:'auto'}}>
                                
                            <SvgIcon width='30' height='30' viewBox='0 0 47 46' fill='#D4ADFC' path='m33.083 1.936-19.167-.02a3.845 3.845 0 0 0-3.833 3.834v34.5a3.845 3.845 0 0 0 3.833 3.833h19.167a3.845 3.845 0 0 0 3.833-3.833V5.75c0-2.108-1.725-3.814-3.833-3.814m0 32.564H13.916v-23h19.167zm-17.25-15.333h15.333v2.875H15.833zm1.917 5.75h11.5v2.875h-11.5z' />
                            </Wrapper>
                          
                                <Wrapper canvas className='my-auto' style={{display:'inline-block', fontSize:'18px', fontWeight:'400px', fontFamily:'Chivo', color:'#FFFFFF'}} id='random-id-12' is='div'>
                                    <Text text='team-email@email.com'/>
                                </Wrapper>
                           
                        </Wrapper> 
                        <Wrapper canvas id='random-id-458' is='div' className='w-full h-full flex flex-row my-8'>
                            <Wrapper canvas id='random-id-459' is='div'  className='mr-6 my-auto' style={{width:'auto', height:'auto'}}>
                            <SvgIcon width='30' height='30' viewBox='0 0 46 46' fill='#D4ADFC' path='M23 3.833c-7.418 0-13.417 6-13.417 13.417C9.583 27.312 23 42.167 23 42.167S36.416 27.312 36.416 17.25c0-7.418-5.999-13.417-13.416-13.417m0 18.209a4.791 4.791 0 1 1 0-9.583 4.791 4.791 0 0 1 0 9.583' />
                                
                            </Wrapper>
                          
                                <Wrapper canvas className='my-auto' style={{display:'inline-block', fontSize:'18px', fontWeight:'400px', fontFamily:'Chivo', color:'#FFFFFF'}} id='random-id-13' is='div'>
                                    <Text text='Contoso Ltd215, E Tasman DrPo Box, 65502CA, 95134 San Jose'/>
                                </Wrapper>
                          
                        </Wrapper>
                        <Wrapper canvas id='random-id-460' is='div' className='w-full h-full flex flex-row my-8'>
                            <Wrapper canvas id='random-id-461' is='div'  className='mr-6 my-auto' style={{width:'auto', height:'auto'}}>
                            <SvgIcon width='30' height='30' viewBox='0 0 47 46' fill='#D4ADFC' path='M38.833 15.333 23.5 24.917 8.166 15.333V11.5L23.5 21.083 38.833 11.5m0-3.833H8.166A3.82 3.82 0 0 0 4.333 11.5v23a3.834 3.834 0 0 0 3.833 3.833h30.667a3.834 3.834 0 0 0 3.833-3.833v-23a3.833 3.833 0 0 0-3.833-3.833' />
                                
                            </Wrapper>
                          
                                <Wrapper canvas className='my-auto' style={{display:'inline-block', fontSize:'18px', fontWeight:'400px', fontFamily:'Chivo', color:'#FFFFFF'}} id='random-id-14' is='div'>
                                    <Text text='+111 111 111 111'/>
                                </Wrapper>
                          
                        </Wrapper>
                        <Wrapper canvas id='random-id-462' is='div' className='w-full h-full flex flex-row my-10'>
                            <Wrapper canvas id='random-id-463' is='div' className='mx-4 cursor-pointer'>
                                <SvgIcon fill='#fff' viewBox='0 0 44 44' path='M35.75 0q3.41 0 5.83 2.42T44 8.25v27.5q0 3.41-2.42 5.83T35.75 44h-5.385V26.956h5.7l.86-6.646h-6.56v-4.24q0-1.604.673-2.406.672-.802 2.62-.802l3.496-.029v-5.93q-1.805-.257-5.1-.257-3.895 0-6.23 2.292-2.334 2.29-2.334 6.474v4.898h-5.73v6.646h5.73V44H8.25q-3.41 0-5.83-2.42T0 35.75V8.25q0-3.41 2.42-5.83T8.25 0z' />
                            </Wrapper>
                            <Wrapper canvas id='random-id-464' is='div' className='mx-4 cursor-pointer'>
                            <SvgIcon fill='#fff' viewBox='0 0 42 42' path='M37.1.3H4.9C2.37.3.3 2.37.3 4.9v32.2c0 2.532 2.07 4.6 4.6 4.6h32.2c2.53 0 4.6-2.068 4.6-4.6V4.9c0-2.53-2.07-4.6-4.6-4.6M20.963 33.703A12.74 12.74 0 0 0 33.7 20.963c0-.777-.099-1.527-.236-2.263H37.1v16.597a1.587 1.587 0 0 1-1.594 1.587H6.494A1.59 1.59 0 0 1 4.9 35.297V18.7h3.563c-.14.736-.24 1.486-.24 2.263a12.74 12.74 0 0 0 12.74 12.74m-7.96-12.74a7.961 7.961 0 1 1 15.922 0 7.961 7.961 0 0 1-15.922 0M35.506 11.8H31.79a1.596 1.596 0 0 1-1.591-1.592V6.49c0-.878.713-1.59 1.59-1.59h3.714c.883 0 1.596.712 1.596 1.59v3.717c0 .876-.713 1.594-1.594 1.594' />
                                
                            </Wrapper>
                            <Wrapper canvas id='random-id-465' is='div' className='mx-4 cursor-pointer'>
                                <SvgIcon fill='#fff' viewBox='0 0 44 44' path='M39.875 0H4.125A4.137 4.137 0 0 0 0 4.125v35.75A4.137 4.137 0 0 0 4.125 44h35.75A4.137 4.137 0 0 0 44 39.875V4.125A4.137 4.137 0 0 0 39.875 0M16.5 35.75H11V16.5h5.5zm-2.75-22a2.75 2.75 0 1 1 0-5.5 2.75 2.75 0 0 1 0 5.5m22 22h-5.5v-11a2.75 2.75 0 1 0-5.5 0v11h-5.5V16.5h5.5v3.413c1.133-1.557 2.871-3.413 4.813-3.413 3.42 0 6.187 3.077 6.187 6.875z'/>
                            </Wrapper>
                        </Wrapper>
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-466' is='div' className='   h-full mx-auto my-auto px-10' style={{flex:'50%'}}>
                    <Wrapper canvas id='random-id-467' is='form'>
                        <Input className='my-4 p-[20px] text-[16px] focus:ring focus:ring-[#0C134F33]' style={{border:'1px solid #0C134F33', borderRadius:'8px',  fontFamily:'Propmt'}} type="text" placeholder='Enter Your Name' />
                        <Input className='my-4 p-[20px] text-[16px] focus:ring focus:ring-[#0C134F33]' style={{border:'1px solid #0C134F33', borderRadius:'8px',  fontFamily:'Propmt'}} type="text" placeholder='Phone Number' />
                        <Input className='my-4 p-[20px] text-[16px] focus:ring focus:ring-[#0C134F33]' style={{border:'1px solid #0C134F33', borderRadius:'8px',  fontFamily:'Propmt'}} type="text" placeholder='Email' />
                        <Input className='my-4 p-[20px] text-[16px] focus:ring focus:ring-[#0C134F33]' style={{border:'1px solid #0C134F33', borderRadius:'8px',  fontFamily:'Propmt'}} type="text" placeholder='Enter your meessage' />
                        <Wrapper canvas id='random-id-203' is='div' className='mx-auto mt-4 w-full h-full flex justify-end'>
                            <Button href={'/'} classn='block' class='w-[280px] h-[60px] bg-[#D4ADFC] hover:bg-[#C68EFF] flex cursor-pointer justify-center text-[#0C134F]' style={{ borderRadius:'8px'}}>
                                <Wrapper canvas style={{display:'inline-block', fontSize:'24px', fontWeight:'500', fontFamily:'Chivo',}} className='mx-auto my-auto' id='random-id-15' is='div'>
                                    <Text text='send'/>
                                </Wrapper>
                                <Wrapper canvas style={{display:'inline-block'}} className='mx-auto my-auto' id='random-id-10' is='div'>
                                    <ImageC src='https://i.ibb.co/5GM5w2g/iconmonstr-arrow-right-circle-thin-240-2.png' style={{width:'30px', height:'auto'}}/>
                                </Wrapper>
                            </Button>
                        </Wrapper>
                    </Wrapper>
                </Wrapper>
                </Wrapper>
            </Wrapper>
        </Wrapper></Element>
        </Element>
    ]

     
const [sel, setSel] = useState(selected)

function makeOdd(number) {
  setProp((prop) => {
    setSel(100);
  }, 1000);

  setTimeout(() => {
    setProp((prop) => {
      setSel(number)
    }, 1000);
  }, 100);


  
}

useEffect(() => {
if(selected || selected >= 0) {
  makeOdd(selected);
}
},[selected])


    
    return (
        <div style={{ padding: enabled? '10px' : "0px", display:"flex", justifyContent:"center", alignItems:"center", width:'100%', height:'100%'}} ref={connect}>
       {sel == 100 ? (
          <div style={{height:"85vh", display:"flex", justifyContent:"center", alignItems:"center"}}>
          <BounceLoader
                  size={100}
                  color={"white"}
                  loading={true}
                />
          </div>
         ) : (
          styles[sel]
         )} 
    </div>
    );
}

    Form_2.craft = {
        displayName: "Form 2",
        props: {
        selected: 1, // here is the default value of the selected style
        length:3,
isBlock: true
        },
        rules: {
            canDrag: () => true,
            canDrop: () => true,
        },
        related: {
            toolbar: NewFormSettings,
        },
    }