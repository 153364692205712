/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import { Element, useEditor, useNode } from '@craftjs/core';
import React, { useEffect, useState } from 'react';
import { NewOurTeamSettings } from './newOurTeamSetting'
import { Text } from '../../basic/Text';
import { ImageC } from '../../basic/Image';
import { Parent } from '../../Parent/index.js';
import { Wrapper } from '../../wrapper/index.js';
import { BounceLoader } from 'react-spinners';

export const Team_1 = ({ selected }) => {

  const { 
    actions: { setProp },
      connectors: {
        connect
      } 
  } = useNode();

  
  const {
    enabled,
  } = useEditor((state, query) => ({
    enabled: state.options.enabled,
 
  }));

    const styles = [
      <Element canvas is={Parent} id='parentId100' className="w-[100%] h-auto" >
      <Element canvas is={Parent} id='parentId200' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-400' is='parent' className='w-[100%] h-full bg-[#FFFDFB] py-10' >
        <Wrapper canvas id='random-id-401' is='container' className='container h-full flex justify-center ' style={{display:'flex', flexDirection:'column'}}>
            <Wrapper canvas style={{ display: "inline-block", fontWeight:'bold', fontFamily:'Rubik' }} className='text-[#100103] text-[46px] py-[25px] flex justify-center' id="random-id-1" is="div">
            <Text text="Our Team" />
            </Wrapper>
            <Wrapper canvas id='random-id-402' is='wrapper' className='w-[100%] h-full flex flex-row flex-wrap justify-around items-center' >
              <Wrapper canvas id='random-id-403' is='div' className='flex justify-center my-[10px] mx-[10px] flex flex-col' style={{border:'1px solid #DBD9D9', width:'30%', minWidth:'290px', height:'100%'}}>
                <Wrapper  canvas style={{display:'inline-block', height:'100%', width:'100%'}} id='random-id-100' is='div'>
                <ImageC className='max-h-[280px] min-h-[214px] w-full object-cover' src='https://i.ibb.co/py3SMt4/image.png'/>
                </Wrapper>
                <Wrapper canvas id='random-id-404' is='div' className='mx-[10px] my-4 flex flex-col align-items-start '>
                    <Wrapper canvas style={{ display: "inline-block", fontSize:'24px', fontWeight:'bold', fontFamily:'Rubik' }} id="random-id-2" is="div">
                    <Text text="Mark Twin" />
                    </Wrapper>
                    <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'300px', fontFamily:'Rubik' }} id="random-id-3" is="div">
                    <Text text="CEO of Company" />
                    </Wrapper>
                </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-405' is='div' className='flex justify-center my-[10px] mx-[10px] flex flex-col' style={{border:'1px solid #DBD9D9', width:'30%', minWidth:'290px', height:'100%'}}>
                  <Wrapper  canvas style={{display:'inline-block', height:'100%', width:'100%'}} id='random-id-101' is='div'>
                  <ImageC className='max-h-[280px] min-h-[214px] w-full object-cover' src='https://i.ibb.co/QQQ2ZSz/image.png'/>
                  </Wrapper>
                <Wrapper canvas id='random-id-406' is='div' className='mx-[10px] my-4 flex flex-col align-items-start '>
                      <Wrapper canvas style={{ display: "inline-block", fontSize:'24px', fontWeight:'bold', fontFamily:'Rubik' }} id="random-id-4" is="div">
                      <Text text="Jane Austen" />
                      </Wrapper>
                      <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'300px', fontFamily:'Rubik' }} id="random-id-5" is="div">
                      <Text text="Developement Team Lead" />
                      </Wrapper>
                </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-407' is='div' className='flex justify-center my-[10px] mx-[10px] flex flex-col' style={{border:'1px solid #DBD9D9', width:'30%', minWidth:'290px', height:'100%'}}>
                  <Wrapper  canvas style={{display:'inline-block', height:'100%', width:'100%'}} id='random-id-102' is='div'>
                  <ImageC className='max-h-[280px] min-h-[214px] w-full object-cover' src='https://i.ibb.co/rpqJtLk/image.png'/>
                  </Wrapper>
                <Wrapper canvas id='random-id-408' is='div' className='mx-[10px] my-4 flex flex-col align-items-start '>
                    <Wrapper canvas style={{ display: "inline-block", fontSize:'24px', fontWeight:'bold', fontFamily:'Rubik' }} id="random-id-6" is="div">
                    <Text text="Mark Twin" />
                    </Wrapper>
                      <Wrapper canvas style={{ display: "inline-block",  fontSize:'18px', fontWeight:'300px', fontFamily:'Rubik'}} id="random-id-7" is="div">
                      <Text text="Teach Lead" />
                      </Wrapper>
                </Wrapper>
              </Wrapper>  
            </Wrapper>
        </Wrapper>
      </Wrapper> </Element>
      </Element>,


          <Element canvas is={Parent} id='parentId300' className="w-[100%] h-auto" >
      <Element canvas is={Parent} id='parentId400' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-409' is='parent' className='w-[100%] h-full bg-[#121826] py-10' >
          <Wrapper canvas id='random-id-410' is='container' className='container ' style={{display:'flex', flexDirection:'column'}}>
              <Wrapper canvas style={{ display: "inline-block", fontWeight:'bold', fontFamily:'Rubik' }} className='IncreaseConversion2 text-[#100103] text-[46px] py-[25px] flex justify-center' id="random-id-8" is="div">
              <Text text="Our Team" />
              </Wrapper>
          <Wrapper canvas id='random-id-411' is='wrapper' className='w-[100%] h-full flex flex-row flex-wrap justify-around items-center' >
            <Wrapper canvas id='random-id-412' is='div' className='flex justify-center my-[10px] mx-[10px] flex flex-col' style={{ width:'30%', minWidth:'290px', height:'100%', boxShadow: '0px 0px 4px 0px #FFFFFF1A'}}>
                <Wrapper canvas style={{width:'100%', height:'100%', display:'inline-block'}} id='random-id-103' is='div'>
                  <ImageC className='max-h-[280px] min-h-[214px] w-full object-cover' src='https://i.ibb.co/py3SMt4/image.png'/>
                </Wrapper>
              <Wrapper canvas id='random-id-413' is='div' className='mx-[10px] my-4 flex flex-col align-items-start h-full'>
                  <Wrapper canvas style={{ display: "inline-block", fontSize:'24px', fontWeight:'bold', fontFamily:'Rubik', color:'white' }} id="random-id-9" is="div">
                  <Text text="Mark Twin" />
                  </Wrapper>
                  <Wrapper canvas style={{ display: "inline-block" , fontSize:'18px', fontWeight:'300px', fontFamily:'Rubik', color:'white'}} id="random-id-10" is="div">
                  <Text text="CEO of Company" />
                  </Wrapper>
              </Wrapper>
              </Wrapper>
              <Wrapper canvas id='random-id-414' is='div' className='flex justify-center my-[10px] mx-[10px] flex flex-col' style={{boxShadow: '0px 0px 4px 0px #FFFFFF1A', width:'30%', minWidth:'290px', height:'100%'}}>
                <Wrapper  canvas style={{display:'inline-block', height:'100%', width:'100%'}} id='random-id-104' is='div'>
                <ImageC className='max-h-[280px] min-h-[214px] w-full object-cover' src='https://i.ibb.co/QQQ2ZSz/image.png'/>
                </Wrapper>
              <Wrapper canvas id='random-id-415' is='div' className='mx-[10px] my-4 flex flex-col align-items-start '>
                  <Wrapper canvas style={{ display: "inline-block", fontSize:'24px', fontWeight:'bold', fontFamily:'Rubik', color:'white' }} id="random-id-11" is="div">
                  <Text text="Jane Austen" />
                  </Wrapper>
                  <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'300px', fontFamily:'Rubik', color:'white' }} id="random-id-12" is="div">
                  <Text text="Developement Team Lead" />
                  </Wrapper>
              </Wrapper>
              </Wrapper>
              <Wrapper canvas id='random-id-416' is='div' className='flex justify-center my-[10px] mx-[10px] flex flex-col' style={{boxShadow: '0px 0px 4px 0px #FFFFFF1A', width:'30%', minWidth:'290px', height:'100%'}}>
                <Wrapper  canvas style={{display:'inline-block', height:'100%', width:'100%'}} id='random-id-105' is='div'>
                <ImageC className='max-h-[280px] min-h-[214px] w-full object-cover' src='https://i.ibb.co/rpqJtLk/image.png'/>
                </Wrapper>
              <Wrapper canvas id='random-id-417' is='div' className='mx-[10px] my-4 flex flex-col align-items-start '>
                  <Wrapper canvas style={{ display: "inline-block", fontSize:'24px', fontWeight:'bold', fontFamily:'Rubik', color:'white' }} id="random-id-13" is="div">
                  <Text text="Mark Twin" />
                  </Wrapper>
                  <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'300px', fontFamily:'Rubik', color:'white' }} id="random-id-14" is="div">
                  <Text text="Teach Lead" />
                  </Wrapper>
              </Wrapper>
            </Wrapper>
          </Wrapper>
      </Wrapper>
    </Wrapper> </Element>
      </Element>,
    

          <Element canvas is={Parent} id='parentId500' className="w-[100%] h-auto" >
      <Element canvas is={Parent} id='parentId600' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-418' is='parent' className='w-[100%] h-full bg-[#213156] py-10' >
          <Wrapper canvas id='random-id-419' is='container' className='container h-full flex justify-center ' style={{display:'flex', flexDirection:'column'}}>
            <Wrapper canvas style={{ display: "inline-block", fontWeight:'bold', fontFamily:'Times New Roman' }} className=' text-[#EA6EF4] text-[46px] py-[25px] flex justify-center' id="random-id-15" is="div">
            <Text text="Our Team" />
            </Wrapper>
        <Wrapper canvas id='random-id-420' is='wrapper' className='w-[100%] h-full flex flex-row flex-wrap justify-around items-center' >
          <Wrapper canvas id='random-id-421' is='div' className='flex justify-center my-[10px] mx-[10px] flex flex-col' style={{ width:'30%', minWidth:'290px', height:'100%', boxShadow: '0px 0px 4px 0px #FFFFFF1A', borderRadius:'10px'}}>
            <Wrapper  canvas style={{display:'inline-block', height:'100%', width:'100%'}} id='random-id-106' is='div'>
            <ImageC style={{borderRadius:'10px 10px 0px 0px'}} src='https://i.ibb.co/py3SMt4/image.png'/>
            </Wrapper>
            <Wrapper canvas id='random-id-422' is='div' className='mx-[10px] my-4 flex flex-col align-items-start '>
                <Wrapper canvas style={{ display: "inline-block", fontSize:'24px', fontWeight:'bold', fontFamily:'Rubik', color:'white' }} id="random-id-16" is="div">
                <Text text="Mark Twin" />
                </Wrapper>
                <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'300px', fontFamily:'Rubik', color:'white' }} id="random-id-17" is="div">
                <Text text="CEO of Company" />
                </Wrapper>
            </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-423' is='div' className='flex justify-center my-[10px] mx-[10px] flex flex-col' style={{boxShadow: '0px 0px 4px 0px #FFFFFF1A', width:'30%', minWidth:'290px', height:'100%', borderRadius:'10px'}}>
              <Wrapper  canvas style={{display:'inline-block', height:'100%', width:'100%'}} id='random-id-107' is='div'>
              <ImageC style={{borderRadius:'10px 10px 0px 0px'}} src='https://i.ibb.co/QQQ2ZSz/image.png'/>
              </Wrapper>
            <Wrapper canvas id='random-id-424' is='div' className='mx-[10px] my-4 flex flex-col align-items-start '>
                <Wrapper canvas style={{ display: "inline-block", fontSize:'24px', fontWeight:'bold', fontFamily:'Rubik', color:'white' }} id="random-id-18" is="div">
                <Text text="Jane Austen" />
                </Wrapper>
                <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'300px', fontFamily:'Rubik', color:'white' }} id="random-id-19" is="div">
                <Text text="Developement Team Lead" />
                </Wrapper>
            </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-425' is='div' className='flex justify-center my-[10px] mx-[10px] flex flex-col' style={{boxShadow: '0px 0px 4px 0px #FFFFFF1A', width:'30%', minWidth:'290px', height:'100%', borderRadius:'10px'}}>
              <Wrapper  canvas style={{display:'inline-block', height:'100%', width:'100%'}} id='random-id-108' is='div'>
              <ImageC style={{borderRadius:'10px 10px 0px 0px'}} src='https://i.ibb.co/rpqJtLk/image.png'/>
              </Wrapper>
            <Wrapper canvas id='random-id-426' is='div' className='mx-[10px] my-4 flex flex-col align-items-start '>
                <Wrapper canvas style={{ display: "inline-block", fontSize:'24px', fontWeight:'bold', fontFamily:'Rubik', color:'white'}} id="random-id-20" is="div">
                <Text text="Mark Twin" />
                </Wrapper>
                <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'300px', fontFamily:'Rubik', color:'white'}} id="random-id-21" is="div">
                <Text text="Teach Lead" />
                </Wrapper>
            </Wrapper>
          </Wrapper>
        </Wrapper>
    </Wrapper>
  </Wrapper> </Element>
      </Element>,


        <Element canvas is={Parent} id='parentId700' className="w-[100%] h-auto" >
      <Element canvas is={Parent} id='parentId800' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-427' is='parent' className='w-[100%] h-full bg-[#DFFFF9] py-10' >
        <Wrapper canvas id='random-id-428' is='container' className='container h-full flex justify-center ' style={{display:'flex', flexDirection:'column'}}>
            <Wrapper canvas style={{ display: "inline-block", fontWeight:'100px', fontFamily:'Zing Script Rust SemiBold Demo', fontStyle:'italic' }} className=' text-[#31A993] text-[46px] py-[25px] flex justify-center' id="random-id-22" is="div">
            <Text text="Our Team" />
            </Wrapper>
          <Wrapper canvas id='random-id-429' is='wrapper' className='w-[100%] h-full flex flex-row flex-wrap justify-around items-center' >
            <Wrapper canvas id='random-id-430' is='div' className='flex justify-center my-[10px] mx-[10px] flex flex-col' style={{ width:'30%', minWidth:'290px', height:'100%', boxShadow: '0px 0px 4px 0px #0825201A', borderRadius:'10px'}}>
              <Wrapper  canvas style={{display:'inline-block', height:'100%', width:'100%'}} id='random-id-109' is='div'>
              <ImageC style={{borderRadius:'10px 10px 0px 0px'}} src='https://i.ibb.co/py3SMt4/image.png'/>
              </Wrapper>
              <Wrapper canvas id='random-id-431' is='div' className='mx-[10px] my-4 flex flex-col align-items-center items-center'>
                  <Wrapper canvas style={{ display: "inline-block", fontSize:'24px', fontWeight:'bold', fontFamily:'Montserrat', color:'#031815' }} id="random-id-23" is="div">
                  <Text text="Mark Twin" />
                  </Wrapper>
                  <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'300px', fontFamily:'Montserrat', color:'#031815' }} id="random-id-24" is="div">
                  <Text text="CEO of Company" />
                  </Wrapper>
              </Wrapper>
              </Wrapper>
              <Wrapper canvas id='random-id-432' is='div' className='flex justify-center my-[10px] mx-[10px] flex flex-col' style={{boxShadow: '0px 0px 4px 0px #0825201A', width:'30%', minWidth:'290px', height:'100%', borderRadius:'10px'}}>
                <Wrapper  canvas style={{display:'inline-block', height:'100%', width:'100%'}} id='random-id-110' is='div'>
                <ImageC style={{borderRadius:'10px 10px 0px 0px'}} src='https://i.ibb.co/QQQ2ZSz/image.png'/>
                </Wrapper>
              <Wrapper canvas id='random-id-433' is='div' className='mx-[10px] my-4 flex flex-col align-items-center items-center'>
                  <Wrapper canvas style={{ display: "inline-block", fontSize:'24px', fontWeight:'bold', fontFamily:'Montserrat', color:'#031815' }} id="random-id-25" is="div">
                  <Text text="Jane Austen" />
                  </Wrapper>
                  <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'300px', fontFamily:'Montserrat', color:'#031815' }} id="random-id-26" is="div">
                  <Text text="Developement Team Lead" />
                  </Wrapper>
              </Wrapper>
              </Wrapper>
              <Wrapper canvas id='random-id-434' is='div' className='flex justify-center my-[10px] mx-[10px] flex flex-col' style={{boxShadow: '0px 0px 4px 0px #0825201A', width:'30%', minWidth:'290px', height:'100%', borderRadius:'10px'}}>
                <Wrapper  canvas style={{display:'inline-block', height:'100%', width:'100%'}} id='random-id-111' is='div'>
                <ImageC style={{borderRadius:'10px 10px 0px 0px'}} src='https://i.ibb.co/rpqJtLk/image.png'/>
                </Wrapper>
              <Wrapper canvas id='random-id-435' is='div' className='mx-[10px] my-4 flex flex-col align-items-center items-center'>
                  <Wrapper canvas style={{ display: "inline-block", fontSize:'24px', fontWeight:'bold', fontFamily:'Montserrat', color:'#031815' }} id="random-id-27" is="div">
                  <Text text="Mark Twin" />
                  </Wrapper>
                  <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'300px', fontFamily:'Montserrat', color:'#031815'}} id="random-id-28" is="div">
                  <Text text="Teach Lead" />
                  </Wrapper>
              </Wrapper>
            </Wrapper>
          </Wrapper>
      </Wrapper>
    </Wrapper> </Element>
      </Element>,


          <Element canvas is={Parent} id='parentId900' className="w-[100%] h-auto" >
      <Element canvas is={Parent} id='parentId1000' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-436' is='parent' className='w-[100%] h-full bg-[#FFFFFF] py-10' >
          <Wrapper canvas id='random-id-437' is='container' className='container h-full flex justify-center ' style={{display:'flex', flexDirection:'column'}}>
            <Wrapper canvas style={{ display: "inline-block", fontWeight:'bold', fontFamily:'Rubik' }} className='text-[#0078D3] text-[46px] py-[25px] flex justify-center'  id="random-id-29" is="div">
            <Text text="Our Team" />
            </Wrapper>
            <Wrapper canvas id='random-id-438' is='wrapper' className='w-[100%] h-full flex flex-row flex-wrap justify-around items-center' >
            <Wrapper canvas id='random-id-439' is='div' className='flex justify-center my-[10px] mx-[10px] flex flex-col' style={{ width:'30%', minWidth:'290px', height:'100%', boxShadow: '0px 0px 4px 0px #FFFFFF1A'}}>
              <Wrapper  canvas style={{display:'inline-block', height:'100%', width:'100%'}} id='random-id-112' is='div'>
              <ImageC className='max-h-[280px] min-h-[214px] w-full object-cover' src='https://i.ibb.co/py3SMt4/image.png'/>
              </Wrapper>
              <Wrapper canvas id='random-id-440' is='div' className='mx-[10px] my-4 flex flex-col align-items-start '>
                  <Wrapper canvas style={{ display: "inline-block", fontSize:'24px', fontWeight:'bold', fontFamily:'Rubik', color:'#000000' }} id="random-id-30" is="div">
                  <Text text="Mark Twin" />
                  </Wrapper>
                  <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'300px', fontFamily:'Rubik', color:'#000000' }} id="random-id-31" is="div">
                  <Text text="CEO of Company" />
                  </Wrapper>
              </Wrapper>
              </Wrapper>
              <Wrapper canvas id='random-id-441' is='div' className='flex justify-center my-[10px] mx-[10px] flex flex-col' style={{boxShadow: '0px 0px 4px 0px #FFFFFF1A', width:'30%', minWidth:'290px', height:'100%'}}>
                <Wrapper  canvas style={{display:'inline-block', height:'100%', width:'100%'}} id='random-id-113' is='div'>
                <ImageC className='max-h-[280px] min-h-[214px] w-full object-cover' src='https://i.ibb.co/QQQ2ZSz/image.png'/>
                </Wrapper>
              <Wrapper canvas id='random-id-442' is='div' className='mx-[10px] my-4 flex flex-col align-items-start '>
                    <Wrapper canvas style={{ display: "inline-block", fontSize:'24px', fontWeight:'bold', fontFamily:'Rubik', color:'#000000'}} id="random-id-32" is="div">
                    <Text text="Jane Austen" />
                    </Wrapper>
                  <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'300px', fontFamily:'Rubik', color:'#000000'}} id="random-id-33" is="div">
                  <Text text="Developement Team Lead" />
                  </Wrapper>
              </Wrapper>
              </Wrapper>
              <Wrapper canvas id='random-id-443' is='div' className='flex justify-center my-[10px] mx-[10px] flex flex-col' style={{boxShadow: '0px 0px 4px 0px #FFFFFF1A', width:'30%', minWidth:'290px', height:'100%'}}>
                <Wrapper canvas style={{display:'inline-block', height:'100%', width:'100%'}} id='random-id-114' is='div'>
                <ImageC className='max-h-[280px] min-h-[214px] w-full object-cover' src='https://i.ibb.co/rpqJtLk/image.png'/>
                </Wrapper>
              <Wrapper canvas id='random-id-444' is='div' className='mx-[10px] my-4 flex flex-col align-items-start '>
                  <Wrapper canvas style={{ display: "inline-block", fontSize:'24px', fontWeight:'bold', fontFamily:'Rubik', color:'#000000'}} id="random-id-34" is="div">
                  <Text text="Mark Twin" />
                  </Wrapper>
                  <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'300px', fontFamily:'Rubik', color:'#000000'}} id="random-id-35" is="div">
                  <Text text="Teach Lead" />
                  </Wrapper>
              </Wrapper>
            </Wrapper>
          </Wrapper>
      </Wrapper>
    </Wrapper> </Element>
      </Element>,

          <Element canvas is={Parent} id='parentId1100' className="w-[100%] h-auto" >
      <Element canvas is={Parent} id='parentId1200' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-445' is='parent' className='w-[100%] h-full bg-[#020212] py-10' >
          <Wrapper canvas id='random-id-446' is='container' className='container h-full flex justify-center ' style={{display:'flex', flexDirection:'column'}}>
              <Wrapper canvas style={{ display: "inline-block", fontWeight:'bold', fontFamily:'Voces' }} className='text-[#FFFFFF] text-[46px] py-[25px] flex justify-center'  id="random-id-36" is="div">
              <Text text="Our Team" />
              </Wrapper>
          <Wrapper canvas id='random-id-447' is='wrapper' className='w-[100%] h-full flex flex-row flex-wrap justify-around items-center' >
            <Wrapper canvas id='random-id-448' is='div' className='flex justify-center my-[10px] mx-[10px] flex flex-col' style={{ width:'30%', minWidth:'290px', height:'100%', boxShadow: '0px 0px 10px 0px #FFFFFF33'}}>
              <Wrapper  canvas style={{display:'inline-block', height:'100%', width:'100%'}} id='random-id-115' is='div'>
              <ImageC className='max-h-[280px] min-h-[214px] w-full object-cover' src='https://i.ibb.co/py3SMt4/image.png'/>
              </Wrapper>
              <Wrapper canvas id='random-id-449' is='div' className='mx-[10px] my-4 flex flex-col align-items-start '>
                  <Wrapper canvas style={{ display: "inline-block", fontSize:'24px', fontWeight:'bold', fontFamily:'Voces', color:'#FFFFFF'}} id="random-id-37" is="div">
                  <Text text="Mark Twin" />
                  </Wrapper>
                  <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'300px', fontFamily:'Voces', color:'#FFFFFF'}} id="random-id-38" is="div">
                  <Text text="CEO of Company" />
                  </Wrapper>
              </Wrapper>
              </Wrapper>
              <Wrapper canvas id='random-id-450' is='div' className='flex justify-center my-[10px] mx-[10px] flex flex-col' style={{boxShadow: '0px 0px 10px 0px #FFFFFF33', width:'30%', minWidth:'290px', height:'100%'}}>
                <Wrapper  canvas style={{display:'inline-block', height:'100%', width:'100%'}} id='random-id-116' is='div'>
                <ImageC className='max-h-[280px] min-h-[214px] w-full object-cover' src='https://i.ibb.co/QQQ2ZSz/image.png'/>
                </Wrapper>
              <Wrapper canvas id='random-id-451' is='div' className='mx-[10px] my-4 flex flex-col align-items-start '>
                  <Wrapper canvas style={{ display: "inline-block", fontSize:'24px', fontWeight:'bold', fontFamily:'Voces', color:'#FFFFFF'}} id="random-id-39" is="div">
                  <Text text="Jane Austen" />
                  </Wrapper>
                  <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'300px', fontFamily:'Voces', color:'#FFFFFF'}} id="random-id-40" is="div">
                  <Text text="Developement Team Lead" />
                  </Wrapper>
              </Wrapper>
              </Wrapper>
              <Wrapper canvas id='random-id-452' is='div' className='flex justify-center my-[10px] mx-[10px] flex flex-col' style={{boxShadow: '0px 0px 10px 0px #FFFFFF33', width:'30%', minWidth:'290px', height:'100%'}}>
                <Wrapper  canvas style={{display:'inline-block', height:'100%', width:'100%'}} id='random-id-117' is='div'>
                <ImageC className='max-h-[280px] min-h-[214px] w-full object-cover' src='https://i.ibb.co/rpqJtLk/image.png'/>
                </Wrapper>
              <Wrapper canvas id='random-id-453' is='div' className='mx-[10px] my-4 flex flex-col align-items-start '>
                  <Wrapper canvas style={{ display: "inline-block", fontSize:'24px', fontWeight:'bold', fontFamily:'Voces', color:'#FFFFFF'}} id="random-id-41" is="div">
                  <Text text="Mark Twin" />
                  </Wrapper>
                  <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'300px', fontFamily:'Voces', color:'#FFFFFF'}} id="random-id-42" is="div">
                  <Text text="Teach Lead" />
                  </Wrapper>
              </Wrapper>
            </Wrapper>
          </Wrapper>
      </Wrapper>
    </Wrapper> </Element>
      </Element>,

        <Element canvas is={Parent} id='parentId1300' className="w-[100%] h-auto" >
      <Element canvas is={Parent} id='parentId1400' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-454' is='parent' className='w-[100%] h-full bg-[#FBFAFF] py-10' >
        <Wrapper canvas id='random-id-455' is='container' className='container h-full flex justify-center ' style={{display:'flex', flexDirection:'column'}}>
          <Wrapper canvas style={{ display: "inline-block", fontWeight:'bold', fontFamily:'Roboto' }} className=' text-[#6750A4] text-[46px] py-[25px] flex justify-center' id="random-id-43" is="div">
          <Text text="Our Team" />
          </Wrapper>
        <Wrapper canvas id='random-id-456' is='wrapper' className='w-[100%] h-full flex flex-row flex-wrap justify-center items-center' >
          <Wrapper canvas id='random-id-457' is='div' className='flex justify-center my-[10px] mx-[10px] flex flex-col bg-[#6750A4]' style={{ width:'380px', height:'380px', boxShadow: '0px 0px 10px 0px #FFFFFF33', borderRadius:'20px'}}>
            <Wrapper  canvas style={{display:'inline-block', height:'100%', width:'100%'}} id='random-id-118' is='div'>
            <ImageC className='p-4' style={{borderRadius:'20px'}} src='https://i.ibb.co/py3SMt4/image.png'/>
            </Wrapper>
            <Wrapper canvas id='random-id-458' is='div' className='mx-[10px] my-4 flex flex-col align-items-center items-center'>
                <Wrapper canvas style={{ display: "inline-block", fontSize:'24px', fontWeight:'bold', fontFamily:'Roboto', color:'#FFFFFF' }} id="random-id-44" is="div">
                <Text text="Mark Twin" />
                </Wrapper>
                <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'lighter', fontFamily:'Roboto', color:'#FFFFFF'}} id="random-id-45" is="div">
                <Text text="CEO of Company" />
                </Wrapper>
            </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-459' is='div' className='flex justify-center my-[10px] mx-[10px] flex flex-col bg-[#6750A4]' style={{boxShadow: '0px 0px 10px 0px #FFFFFF33', width:'380px', height:'380px', borderRadius:'20px'}}>
              <Wrapper  canvas style={{display:'inline-block', height:'100%', width:'100%'}} id='random-id-119' is='div'>
              <ImageC className='p-4' style={{borderRadius:'20px'}} src='https://i.ibb.co/QQQ2ZSz/image.png'/>
              </Wrapper>
            <Wrapper canvas id='random-id-460' is='div' className='mx-[10px] my-4 flex flex-col align-items-center items-center'>
                <Wrapper canvas style={{ display: "inline-block", fontSize:'24px', fontWeight:'bold', fontFamily:'Roboto', color:'#FFFFFF' }} id="random-id-46" is="div">
                <Text text="Jane Austen" />
                </Wrapper>
                <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'lighter', fontFamily:'Roboto', color:'#FFFFFF'}} id="random-id-47" is="div">
                <Text text="Developement Team Lead" />
                </Wrapper>
            </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-461' is='div' className='flex justify-center my-[10px] mx-[10px] flex flex-col bg-[#6750A4]' style={{boxShadow: '0px 0px 10px 0px #FFFFFF33', width:'380px', height:'380px', borderRadius:'20px'}}>
              <Wrapper canvas style={{display:'inline-block', height:'100%', width:'100%'}} id='random-id-120' is='div'>
              <ImageC className='p-4' style={{borderRadius:'20px'}} src='https://i.ibb.co/rpqJtLk/image.png'/>
              </Wrapper>
            <Wrapper canvas id='random-id-462' className='mx-[10px] my-4 flex flex-col align-items-center items-center'  is='div'>
                <Wrapper canvas style={{ display: "inline-block", fontSize:'24px', fontWeight:'bold', fontFamily:'Roboto', color:'#FFFFFF'}} id="random-id-48" is="div">
                <Text text="Mark Twin" />
                </Wrapper>
                <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'lighter', fontFamily:'Roboto', color:'#FFFFFF'}} id="random-id-49" is="div">
                <Text text="Teach Lead" />
                </Wrapper>
            </Wrapper>
          </Wrapper>
        </Wrapper>
    </Wrapper>
  </Wrapper> </Element>
      </Element>,

    <Element canvas is={Parent} id='parentId1500' className="w-[100%] h-auto" >
      <Element canvas is={Parent} id='parentId1600' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-463' is='parent' className='w-[100%] h-full bg-[#2A2A2A] py-10' >
            <Wrapper canvas id='random-id-464' is='parent' className='container h-full flex justify-center ' style={{display:'flex', flexDirection:'column'}}>
              <Wrapper canvas style={{ display: "inline-block", fontWeight:'400px', fontFamily:'Rum Raisin' }} className=' text-[#FFD600] text-[46px] py-[25px] flex justify-center' id="random-id-50" is="div">
              <Text text="Our Team" />
              </Wrapper>
              <Wrapper canvas id='random-id-465' is='wrapper' className='w-[100%] h-full flex flex-row flex-wrap justify-center items-center' >
              <Wrapper canvas id='random-id-466' is='div' className='flex justify-center my-[10px] mx-[10px] flex flex-col' style={{ width:'30%', minWidth:'290px', height:'100%', boxShadow: '0px 0px 10px 0px #FFFFFF0F', border: '1px solid #FFD6001A', borderRadius:'10px 10px 0px 0px'}}>
                <Wrapper  canvas style={{display:'inline-block', height:'100%', width:'100%'}} id='random-id-121' is='div'>
                <ImageC style={{borderRadius:'10px 10px 0px 0px'}} src='https://i.ibb.co/py3SMt4/image.png'/>
                </Wrapper>
                <Wrapper canvas id='random-id-467' is='div' className='mx-[10px] my-4 flex flex-col align-items-start '>
                    <Wrapper canvas style={{ display: "inline-block", fontSize:'24px', fontWeight:'bold', fontFamily:'Rubik', color:'#FFFFFF' }} id="random-id-51" is="div">
                    <Text text="Mark Twin" />
                    </Wrapper>
                    <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'300px', fontFamily:'Rubik', color:'#FFFFFF' }} id="random-id-52" is="div">
                    <Text text="CEO of Company" />
                    </Wrapper>
                </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-468' is='div' className='flex justify-center my-[10px] mx-[10px] flex flex-col' style={{boxShadow: '0px 0px 10px 0px #FFFFFF0F', border: '1px solid #FFD6001A', borderRadius:'10px 10px 0px 0px', width:'30%', minWidth:'290px', height:'100%'}}>
                  <Wrapper  canvas style={{display:'inline-block', height:'100%', width:'100%'}} id='random-id-122' is='div'>
                  <ImageC style={{borderRadius:'10px 10px 0px 0px'}}  src='https://i.ibb.co/QQQ2ZSz/image.png'/>
                  </Wrapper>
                <Wrapper canvas id='random-id-469' is='div' className='mx-[10px] my-4 flex flex-col align-items-start '>
                      <Wrapper canvas style={{ display: "inline-block", fontSize:'24px', fontWeight:'bold', fontFamily:'Rubik', color:'#FFFFFF' }} id="random-id-53" is="div">
                      <Text text="Jane Austen" />
                      </Wrapper>
                    <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'300px', fontFamily:'Rubik', color:'#FFFFFF'}} id="random-id-54" is="div">
                    <Text text="Developement Team Lead" />
                    </Wrapper>
                </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-460' is='div' className='flex justify-center my-[10px] mx-[10px] flex flex-col' style={{boxShadow: '0px 0px 10px 0px #FFFFFF0F', border: '1px solid #FFD6001A', borderRadius:'10px 10px 0px 0px', width:'30%', minWidth:'290px', height:'100%'}}>
                  <Wrapper canvas style={{display:'inline-block', height:'100%', width:'100%'}} id='random-id-123' is='div'>
                  <ImageC style={{borderRadius:'10px 10px 0px 0px'}} src='https://i.ibb.co/rpqJtLk/image.png'/>
                  </Wrapper>
                <Wrapper canvas id='random-id-461' is='div' className='mx-[10px] my-4 flex flex-col align-items-start '>
                    <Wrapper canvas style={{ display: "inline-block", fontSize:'24px', fontWeight:'bold', fontFamily:'Rubik', color:'#FFFFFF'}} id="random-id-55" is="div">
                    <Text text="Mark Twin" />
                    </Wrapper>
                    <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'300px', fontFamily:'Rubik', color:'#FFFFFF'}} id="random-id-56" is="div">
                    <Text text="Teach Lead" />
                    </Wrapper>
                </Wrapper>
              </Wrapper>
            </Wrapper>
        </Wrapper>
      </Wrapper> </Element>
      </Element>,

  <Element canvas is={Parent} id='parentId1700' className="w-[100%] h-auto" >
      <Element canvas is={Parent} id='parentId1800' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-900' is='parent' className='w-[100%] h-full bg-[#F7FBFE] py-10' >
  <Wrapper canvas id='random-id-901' is='container' className='container h-full flex justify-center ' style={{display:'flex', flexDirection:'column'}}>
      <Wrapper canvas style={{ display: "inline-block", fontWeight:'bold', fontFamily:'Rubik' }} className='text-[#6064D2] text-[46px] py-[25px] flex justify-center' id="random-id-902" is="div">
      <Text text="Our Team" />
      </Wrapper>
      <Wrapper canvas id='random-id-903' is='wrapper' className='w-[100%] h-full flex flex-row flex-wrap justify-around items-center' >
        <Wrapper canvas id='random-id-904' is='div' className='flex justify-center my-[10px] mx-[10px] flex flex-col' style={{border:'1px solid rgba(96, 100, 210, 0.10)', borderRadius:'10px', background:'#fff', width:'30%', minWidth:'290px', height:'100%'}}>
          <Wrapper  canvas style={{display:'inline-block', height:'100%', width:'100%', borderRadius:'10px 10px 0px 0px'}} id='random-id-905' is='div'>
          <ImageC className='max-h-[280px] min-h-[214px] w-full object-cover' style={{borderRadius:'10px 10px 0px 0px'}} src='https://i.ibb.co/py3SMt4/image.png'/>
          </Wrapper>
          <Wrapper canvas id='random-id-906' is='div' className='mx-[10px] my-4 flex flex-col align-items-start '>
              <Wrapper canvas style={{ display: "inline-block", fontSize:'24px', fontWeight:'bold', fontFamily:'Rubik' }} id="random-id-907" is="div">
              <Text text="Mark Twin" />
              </Wrapper>
              <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'300px', fontFamily:'Rubik' }} id="random-id-908" is="div">
              <Text text="CEO of Company" />
              </Wrapper>
          </Wrapper>
          </Wrapper>
          <Wrapper canvas id='random-id-909' is='div' className='flex justify-center my-[10px] mx-[10px] flex flex-col' style={{border:'1px solid rgba(96, 100, 210, 0.10)', borderRadius:'10px', background:'#fff', width:'30%', minWidth:'290px', height:'100%'}}>
            <Wrapper  canvas style={{display:'inline-block', height:'100%', width:'100%', borderRadius:'10px 10px 0px 0px'}} id='random-id-910' is='div'>
            <ImageC className='max-h-[280px] min-h-[214px] w-full object-cover' style={{borderRadius:'10px 10px 0px 0px'}} src='https://i.ibb.co/QQQ2ZSz/image.png'/>
            </Wrapper>
          <Wrapper canvas id='random-id-911' is='div' className='mx-[10px] my-4 flex flex-col align-items-start '>
                <Wrapper canvas style={{ display: "inline-block", fontSize:'24px', fontWeight:'bold', fontFamily:'Rubik' }} id="random-id-912" is="div">
                <Text text="Jane Austen" />
                </Wrapper>
                <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'300px', fontFamily:'Rubik' }} id="random-id-5" is="div">
                <Text text="Developement Team Lead" />
                </Wrapper>
          </Wrapper>
          </Wrapper>
          <Wrapper canvas id='random-id-913' is='div' className='flex justify-center my-[10px] mx-[10px] flex flex-col' style={{border:'1px solid rgba(96, 100, 210, 0.10)', borderRadius:'10px', background:'#fff', width:'30%', minWidth:'290px', height:'100%'}}>
            <Wrapper  canvas style={{display:'inline-block', height:'100%', width:'100%', borderRadius:'10px 10px 0px 0px'}} id='random-id-914' is='div'>
            <ImageC className='max-h-[280px] min-h-[214px] w-full object-cover' style={{borderRadius:'10px 10px 0px 0px'}} src='https://i.ibb.co/rpqJtLk/image.png'/>
            </Wrapper>
          <Wrapper canvas id='random-id-915' is='div' className='mx-[10px] my-4 flex flex-col align-items-start '>
              <Wrapper canvas style={{ display: "inline-block", fontSize:'24px', fontWeight:'bold', fontFamily:'Rubik' }} id="random-id-916" is="div">
              <Text text="Mark Twin" />
              </Wrapper>
                <Wrapper canvas style={{ display: "inline-block",  fontSize:'18px', fontWeight:'300px', fontFamily:'Rubik'}} id="random-id-917" is="div">
                <Text text="Teach Lead" />
                </Wrapper>
          </Wrapper>
        </Wrapper>  
      </Wrapper>
  </Wrapper>
  </Wrapper> </Element>
      </Element>,


  <Element canvas is={Parent} id='parentId1900' className="w-[100%] h-auto" >
      <Element canvas is={Parent} id='parentId2000' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-918' is='parent' className='w-[100%] h-full bg-[#FFFFFF] py-10' >
  <Wrapper canvas id='random-id-919' is='container' className='container h-full flex justify-center ' style={{display:'flex', flexDirection:'column'}}>
      <Wrapper canvas style={{ display: "inline-block", fontWeight:'400', fontFamily:'Ruluko' }} className='text-[#A25738] text-[46px] py-[25px] flex justify-center' id="random-id-920" is="div">
      <Text text="Our Team" />
      </Wrapper>
      <Wrapper canvas id='random-id-921' is='wrapper' className='w-[100%] h-full flex flex-row flex-wrap justify-around items-center' >
        <Wrapper canvas id='random-id-922' is='div' className='flex justify-center my-[10px] mx-[10px] flex flex-col' style={{border:'1px solid rgba(162, 87, 56, 0.10)', borderRadius:'30px 0', background:'#FEFBF7', width:'30%', minWidth:'290px', height:'100%'}}>
          <Wrapper  canvas style={{display:'inline-block', height:'100%', width:'100%', borderRadius:'0px 30px 0px 0px'}} id='random-id-923' is='div'>
          <ImageC className='max-h-[280px] min-h-[214px] w-full object-cover' style={{borderRadius:'0px 30px 0px 0px'}} src='https://i.ibb.co/py3SMt4/image.png'/>
          </Wrapper>
          <Wrapper canvas id='random-id-924' is='div' className='mx-[10px] my-4 flex flex-col align-items-start '>
              <Wrapper canvas style={{ display: "inline-block", fontSize:'24px', fontWeight:'bold', fontFamily:'Sansation' }} id="random-id-925" is="div">
              <Text text="Mark Twin" />
              </Wrapper>
              <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'300px', fontFamily:'Sansation' }} id="random-id-926" is="div">
              <Text text="CEO of Company" />
              </Wrapper>
          </Wrapper>
          </Wrapper>
          <Wrapper canvas id='random-id-927' is='div' className='flex justify-center my-[10px] mx-[10px] flex flex-col' style={{border:'1px solid rgba(162, 87, 56, 0.10)', borderRadius:'30px 0', background:'#FEFBF7', width:'30%', minWidth:'290px', height:'100%'}}>
            <Wrapper  canvas style={{display:'inline-block', height:'100%', width:'100%', borderRadius:'0px 30px 0px 0px'}} id='random-id-928' is='div'>
            <ImageC className='max-h-[280px] min-h-[214px] w-full object-cover' style={{borderRadius:'0px 30px 0px 0px'}} src='https://i.ibb.co/QQQ2ZSz/image.png'/>
            </Wrapper>
          <Wrapper canvas id='random-id-929' is='div' className='mx-[10px] my-4 flex flex-col align-items-start '>
                <Wrapper canvas style={{ display: "inline-block", fontSize:'24px', fontWeight:'bold', fontFamily:'Sansation' }} id="random-id-930" is="div">
                <Text text="Jane Austen" />
                </Wrapper>
                <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'300px', fontFamily:'Sansation' }} id="random-id-931" is="div">
                <Text text="Developement Team Lead" />
                </Wrapper>
          </Wrapper>
          </Wrapper>
          <Wrapper canvas id='random-id-932' is='div' className='flex justify-center my-[10px] mx-[10px] flex flex-col' style={{border:'1px solid rgba(162, 87, 56, 0.10)', borderRadius:'30px 0', background:'#FEFBF7', width:'30%', minWidth:'290px', height:'100%'}}>
            <Wrapper  canvas style={{display:'inline-block', height:'100%', width:'100%', borderRadius:'0px 30px 0px 0px'}} id='random-id-933' is='div'>
            <ImageC className='max-h-[280px] min-h-[214px] w-full object-cover' style={{borderRadius:'0px 30px 0px 0px'}} src='https://i.ibb.co/rpqJtLk/image.png'/>
            </Wrapper>
          <Wrapper canvas id='random-id-934' is='div' className='mx-[10px] my-4 flex flex-col align-items-start '>
              <Wrapper canvas style={{ display: "inline-block", fontSize:'24px', fontWeight:'bold', fontFamily:'Sansation' }} id="random-id-935" is="div">
              <Text text="Mark Twin" />
              </Wrapper>
                <Wrapper canvas style={{ display: "inline-block",  fontSize:'18px', fontWeight:'300px', fontFamily:'Sansation'}} id="random-id-936" is="div">
                <Text text="Teach Lead" />
                </Wrapper>
          </Wrapper>
        </Wrapper>  
      </Wrapper>
  </Wrapper>
  </Wrapper> </Element>
      </Element>,
    ]

    
const [sel, setSel] = useState(selected)

function makeOdd(number) {
  setProp((prop) => {
    setSel(100);
  }, 1000);

  setTimeout(() => {
    setProp((prop) => {
      setSel(number)
    }, 1000);
  }, 100);


  
}

useEffect(() => {
if(selected || selected >= 0) {
  makeOdd(selected);
}
},[selected])


    return (
      <div style={{ padding:enabled? "10px" : "0px", display:"flex", justifyContent:"center", alignItems:"center", width:'100%', height:'100%'}} ref={connect}>
      {sel == 100 ? (
          <div style={{height:"85vh", display:"flex", justifyContent:"center", alignItems:"center"}}>
          <BounceLoader
                  size={100}
                  color={"white"}
                  loading={true}
                />
          </div>
         ) : (
          styles[sel]
         )} 
  </div>
  );
}   

Team_1.craft = {
    displayName: "Team 1",
    props: {
       selected: 1, // here is the default value of the selected style
       length:9,
isBlock: true
      },
      rules: {
        canDrag: () => true,
        canDrop: () => true,
      },
      related: {
        toolbar: NewOurTeamSettings,
      },
  };

