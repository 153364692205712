import React, { useEffect, useRef, useState } from 'react';
import SettingsPanel from './ViewArea/SettingsPanel';

const AreaEl = ({ style = {},
    className,
    children,
    id,
    currentRow,
    setStylesRow,
    defaultStyle, // Default styles provided to the component
    onStyleChange, // Callback for notifying style changes
    onClick,
    open,
    setOpen,
    closePanel,
    setClosePanel,
    review,
    ...restProps
}) => {

    const [newStyle, setNewStyle] = useState({ ...defaultStyle, ...style });
    const divRef = useRef(null);
    const panelRef = useRef(null);



    useEffect(() => {
        const handleClickOutside = (event) => {
            if (divRef.current && panelRef.current && !panelRef.current.contains(event.target) && !divRef.current.contains(event.target)) {
                setOpen(null);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [setOpen]);
  
    const handleChangeStyles = (property, value) => {
      const updatedStyle = { ...newStyle, [property]: value };
      setNewStyle(updatedStyle);
  
      // Notify parent component or context about style change
      if (onStyleChange) {
        onStyleChange(updatedStyle);
      }
    };
  
    // Combine styles
    const combinedStyle = { ...newStyle, ...style };
    
    const handlersSetting = (id) => (
        <SettingsPanel
        refs={panelRef}
            setOpen={setOpen}
            currentRow={newStyle} 
            setStylesRow={handleChangeStyles}
            id={id}
            closePanel={closePanel} setClosePanel={setClosePanel} 
        />
    );

    console.log(id, open)
    return (
        <div
            ref={divRef}
            onClick={onClick}
            id={id}
            style={{...combinedStyle, border: !review && open === id && '.25vmin solid #989191', borderRadius:!review && open === id && '1.5vmin'}}
            className={className}
            {...restProps}
        >
            {children}
            {!review &&  open === id  && closePanel && (
                <div onClick={(e) => e.stopPropagation()} className='text-[#000] absolute top-[5vmin] right-0' style={{zIndex:99999}}>
                    {handlersSetting(id)} 
                </div>
            )}
        </div>
    );
};

export default AreaEl;
