// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input[type=text], select, textarea {
    width: 100%;
    padding: 12px;
    resize: vertical;
  }
  
  label {
    padding: 2% 0px 2% 0;
    display: inline-block;
  }

  .MuiRadio-root {
    padding: 10% !important;
  }
  
  .inpute::placeholder {
    color: #FFFFFF99 !important;
    opacity: 1;
    }

.input-4::placeholder {
    color: #00000099!important;
    opacity: 1;
    }

.input-5::placeholder {
    color: #00000099!important;
    opacity: 1;
    }

.input-6::placeholder {
    color: #02021299 !important;
    opacity: 1;
}

.input-7::placeholder {
    color: #FFFFFF99 !important;
    opacity: 1;
    }

.input-8::placeholder {
    color: #10010399 !important;
    opacity: 1;
    }
  

  `, "",{"version":3,"sources":["webpack://./src/components/selectors/blocks/Form/Form.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,gBAAgB;EAClB;;EAEA;IACE,oBAAoB;IACpB,qBAAqB;EACvB;;EAEA;IACE,uBAAuB;EACzB;;EAEA;IACE,2BAA2B;IAC3B,UAAU;IACV;;AAEJ;IACI,0BAA0B;IAC1B,UAAU;IACV;;AAEJ;IACI,0BAA0B;IAC1B,UAAU;IACV;;AAEJ;IACI,2BAA2B;IAC3B,UAAU;AACd;;AAEA;IACI,2BAA2B;IAC3B,UAAU;IACV;;AAEJ;IACI,2BAA2B;IAC3B,UAAU;IACV","sourcesContent":["input[type=text], select, textarea {\n    width: 100%;\n    padding: 12px;\n    resize: vertical;\n  }\n  \n  label {\n    padding: 2% 0px 2% 0;\n    display: inline-block;\n  }\n\n  .MuiRadio-root {\n    padding: 10% !important;\n  }\n  \n  .inpute::placeholder {\n    color: #FFFFFF99 !important;\n    opacity: 1;\n    }\n\n.input-4::placeholder {\n    color: #00000099!important;\n    opacity: 1;\n    }\n\n.input-5::placeholder {\n    color: #00000099!important;\n    opacity: 1;\n    }\n\n.input-6::placeholder {\n    color: #02021299 !important;\n    opacity: 1;\n}\n\n.input-7::placeholder {\n    color: #FFFFFF99 !important;\n    opacity: 1;\n    }\n\n.input-8::placeholder {\n    color: #10010399 !important;\n    opacity: 1;\n    }\n  \n\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
