/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import { Element, useEditor, useNode } from '@craftjs/core';
import React from 'react';
import { CardSettings } from './CardSettings';
import {Button} from '../Button/index'
import { ImageC } from '../Image';
import { Text } from '../../basic/Text/index';
import { Wrapper } from '../../wrapper/index.js';
import { Parent } from '../../Parent/index.js';


export const Card = (props) => {
  const {
    connectors: { connect },
  } = useNode((node) => ({
    selected: node.events.selected,
  }));

  const { enabled } = useEditor((state, query) => ({
    enabled: state.options.enabled,

  }));

  const { 
   
    text,
    margin,
    width,
    height,
    href,
    classn,
    color,
    shadow,
    children,
    marginTop,
    marginBottom,
    marginLeft,
    marginRight,
    minWidth,
    isBlog,
    ...otherProps
  } = props;
  
  return ( 
    // <Element canvas id='random-id-1' is='container' className='w-[280px] h-full p-2 flex flex-col'>
    <Wrapper
    isBasic={true}
    canvas id='random-id-10' is='div'
    ref={connect}
    style={ {
      shadow:'1px solid rgba(255, 214, 0, 0.10)',
      borderWidth: "1px",
      borderStyle:enabled  ?"solid" : "solid",
      minWidth:minWidth ?`${minWidth?.split(" ")?.join("")}` :'380px',
      marginTop:marginTop && `${marginTop?.split(" ")?.join("")}`,
      marginBottom:marginBottom && `${marginBottom?.split(" ")?.join("")}`,
      marginLeft:marginLeft && `${marginLeft?.split(" ")?.join("")}`,
      marginRight:marginRight && `${marginRight?.split(" ")?.join("")}`,
      borderColor:
        enabled ? "#E9E9EA" : props.buttonStyle === 'outline'
          ? `rgba(${Object.values(props.background)})`
          : 'gray',
      width: width? `${width?.split(" ")?.join("")}` : '280px',
      height: height ? `${height?.split(" ")?.join("")}` : ''}} 
    >

      <Element canvas is={Parent} id='parentId12' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId12' className="w-[100%] h-auto" row={true} >
        <Wrapper canvas id='random-id-1' is='container' className='w-[100%] h-full flex flex-col' style={{width:'100%', height:'100%', padding:'4px 8px'}}>
        
        <ImageC className='h-[200px] object-cover' style={{margin:'0 0 4px 0'}} src='https://i.ibb.co/gW0hkRZ/sample3banner.jpg'/>
            <Wrapper canvas id='random-id-30000' is='div' className='flex flex-col' style={{ width:'100%', height:'100%', padding:'0 4px', margin:'5px 0'}}>
            <Wrapper canvas id='random-id-2' is='div' style={{fontSize:'18px', display:'inline-block', fontWeight:'700', margin:'2px 0'}}>
                <Text text='Card title'/>
            </Wrapper>
            <Wrapper canvas id='random-id-3' is='div' style={{fontSize:'16px', display:'inline-block', opacity:'70%', fontWeight:'600', margin:'2px 0'}}>
                <Text text='Sub subtitle'/>
            </Wrapper>
            <Wrapper canvas id='random-id-4' is='div' style={{fontSize:'16px', display:'inline-block', fontWeight:'400'}}>
                <Text text='Some quick example text to build on the card title and make up the bulk of the card‘s content.'/>
            </Wrapper>
            <Wrapper canvas id='random-id-4' is='div' style={{width:'100%', margin:'10px 0', justifyContent:'center', display:'flex'}}>
            <Button href={'/'} classn='block' class={' justify-center border w-[240px] h-[60px] flex rounded-[10px] py-[11px]'}>
                <Wrapper canvas id='random-id-6' is='div' style={{width:'100%', display:'inline-block', color:'#000', textAlign:'center', fontSize:'18px'}}>
                    <Text text='Button'/>
                </Wrapper>
            </Button>   
            </Wrapper>
            </Wrapper>
        </Wrapper>
        </Element>
        </Element>
    </Wrapper>
    // </Element>
  );
};

Card.craft = {
  displayName: 'Card',
  props: {
    background: null,
    color: null,
    text: 'Card',
    margin: null,
    width: null,
    height: null,
     marginTop:null,
     marginBottom:null,
     marginLeft:null,
     marginRight:null,
    minWidth:null,
    shadow:null,
  },
  related: {
    toolbar: CardSettings,
  },
};
