/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, { useEffect, useState } from 'react';
import { Element, useEditor, useNode } from '@craftjs/core';
import { NewBannerSettings } from './newBannerSetting';
import { Text } from '../../basic/Text';
import './Banner.css'
import { ImageC } from '../../basic/Image';
import { Button } from '../../basic/Button';
import { ArrowToRight, HelpArrowBanner } from '../../../editor/Viewport/Pop-up/svgArray';
import { Container } from  '../../../Container/index.js';
import { Parent } from '../../Parent/index.js';
import { BounceLoader } from 'react-spinners';
import { Wrapper } from '../../wrapper/index.js';

export const Banner_3 = ({ selected }) => {
 
  const { 
    actions: { setProp },
      connectors: {
        connect
      } 
  } = useNode();

  const {
    enabled,

  } = useEditor((state, query) => ({
    enabled: state.options.enabled,
 
  }));

  const styles = [
    <Element canvas is={Parent} id='parentId100' className="w-[100%] h-auto" >
  <Element canvas is={Parent} id='parentId200' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-1' is='parent' className='w-full h-full py-10' style={{ background: '#FFF' }}>
      <Wrapper canvas id='random-id-2' is='container' className='container flex flex-row' style={{ flexWrap: 'wrap' }}>
        <Wrapper canvas id='random-id-3' is='div' className='flex flex-col w-[100%]' style={{ flex: '50%', alignContent: 'center', alignItems: 'center', justifyContent: 'center', margin: '20px 0' }}>
          <Wrapper canvas id='random-id-4' style={{ display: "inline-block" }} is='div' className='banner-header'>
            <Text text='Understand User Flow' />
          </Wrapper>
          <Wrapper canvas id='random-id-5' style={{ display: "inline-block" }} is='div' className='banner-subheader my-4'>
            <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.' />
          </Wrapper>
          <Wrapper canvas id='random-id-6' is='div' className='button-aria flex flex-row w-full' >

            <Wrapper canvas id='random-id-7' className='rounded-[33px]' is='div'>
              <Button  classn='block' class='w-[152px] h-[46px] flex rounded-[33px] cursor-pointer border border-solid border-black bg-transparent"'>
                <Wrapper canvas id='random-id-8' is='div' style={{ display: "inline-block", cursor: 'pointer', color: '#000' }} className='cursor-pointer my-auto mx-auto button-text' >
                  <Text text='Learn more' />
                </Wrapper>
              </Button>
            </Wrapper>

            <Wrapper canvas id='random-id-9' is='div' className='flex-row flex relative' style={{ cursor: 'pointer' }}>
              <Wrapper canvas id='random-id-10' is='div' style={{ margin: '0 6px 0' }} className='rounded-[33px]'>
                <Button  classn='block' class='rounded-[33px] cursor-pointer bg-[#343CE8] w-[152px] h-[46px] flex'>
                  <Wrapper canvas id='random-id-11' style={{ display: "inline-block", color: '#FFF', cursor: 'pointer' }} is='div' className='cursor-pointer my-auto mx-auto button-text'>
                    <Text text='Start for free' />
                  </Wrapper>
                </Button>
              </Wrapper>

              <Wrapper canvas id='random-id-12' is='div' className='my-auto absolute top-6 right-[44px]' style={{ zIndex: 20 }}>
                <HelpArrowBanner fill='#343CE8' />
              </Wrapper>

              <Wrapper canvas id='random-id-13' is='div' style={{ margin: '0 0 0 4px' }}>
                <Button  classn='inline-flex' class='rounded-full cursor-pointer bg-[#343CE8] w-[46px] h-[46px] flex my-auto' >
                  <Wrapper canvas id='random-id-14' is='div' className='my-auto mx-auto -rotate-45 hover:rotate-45 transition duration-700 ease-in-out'>
                    <ArrowToRight fill='#FFF' />
                  </Wrapper>
                </Button>
              </Wrapper>
            </Wrapper>
 
          </Wrapper>
        </Wrapper>
        <Wrapper canvas id='random-id-15' is='div' className='flex flex-col w-[100%]' style={{ flex: '50%' }}>
          <Wrapper canvas id='random-id-16' is='div' className='relative' style={{ borderRadius: '30px' }}>
            <Wrapper canvas id='random-id-17' is='div' className='absolute top-[20px] right-[20px] flex flex-row'>
              <Wrapper canvas id='random-id-18' style={{ display: "inline-block", margin: 'auto 10px' }} is='div' className='img-text '>
                <Text text='100K' />
              </Wrapper>
              <Wrapper canvas id='random-id-19' style={{ display: "inline-block", maxWidth: '53px', margin: 'auto auto', fontSize: '16px' }} is='div' className='img-text '>
                <Text text='Happy Users' />
              </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-20' style={{ borderRadius: '30px' }} className='banner-img' is='div'>
              <ImageC style={{ borderRadius: '30px', width: '100%', height: '100%', maxWidth: '640px', maxHeight: '340px' }} className='object-cover' alt='banner-img' src='https://i.ibb.co/rs0rJfc/711798e37e8a0197bf19cf0701fdef35.jpg' />
            </Wrapper>
            <Wrapper canvas id='random-id-21' is='div'>
              <Button  classn='block' class='img-button bg-[#ffffffcc] cursor-pointer rounded-[33px]'>
                <Wrapper canvas id='random-id-22' style={{ display: "inline-block", color: '#000', cursor: 'pointer' }} is='div' className='button-text cursor-pointer'>
                  <Text text='About our team' />
                </Wrapper>
                <ArrowToRight />
              </Button>
            </Wrapper>
          </Wrapper>
          <Wrapper canvas id='random-id-23' is='div' className='flex flex-row w-[100%]' style={{ justifyContent: 'center', borderRadius: '30px', background: '#E5E5E5', margin: '8px 0 0 0', padding: '24px 5px' }}>
            <Wrapper canvas id='random-id-24' is='div' className='flex flex-col mx-auto my-auto'>
              <Wrapper canvas id='random-id-25' style={{ display: "inline-block" }} is='div' className='card-header'>
                <Text text='85%' />
              </Wrapper>
              <Wrapper canvas id='random-id-26' style={{ display: "inline-block" }} is='div' className='card-subheader'>
                <Text text='Lorem Ipsum' />
              </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-27' is='div' className='flex flex-col mx-auto my-auto'>
              <Wrapper canvas id='random-id-28' style={{ display: "inline-block" }} is='div' className='card-header'>
                <Text text='120K' />
              </Wrapper>
              <Wrapper canvas id='random-id-29' style={{ display: "inline-block" }} is='div' className='card-subheader'>
                <Text text='Lorem Ipsum' />
              </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-30' is='div' className='flex flex-col mx-auto my-auto'>
              <Wrapper canvas id='random-id-31' style={{ display: "inline-block" }} is='div' className='card-header'>
                <Text text='5%' />
              </Wrapper>
              <Wrapper canvas id='random-id-32' style={{ display: "inline-block" }} is='div' className='card-subheader'>
                <Text text='Lorem Ipsum' />
              </Wrapper>
            </Wrapper>
          </Wrapper>
        </Wrapper>
      </Wrapper>
    </Wrapper></Element>
  </Element>,

    <Element canvas is={Parent} id='parentId300' className="w-[100%] h-auto" >
  <Element canvas is={Parent} id='parentId400' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-33' is='parent' className='w-full h-full py-10' style={{ background: '#2D2C32' }}>
      <Wrapper canvas id='random-id-34' is='container' className='container flex flex-row' style={{ flexWrap: 'wrap' }}>
        <Wrapper canvas id='random-id-35' is='div' className='flex flex-col w-[100%]' style={{ flex: '50%', alignContent: 'center', alignItems: 'center', justifyContent: 'center', margin: '20px 0' }}>
          <Wrapper canvas id='random-id-36' style={{ display: "inline-block", color: '#FFF', width:'100%' }} is='div' className='banner-header'>
            <Text text='Understand User Flow' />
          </Wrapper>
          <Wrapper canvas id='random-id-37' style={{ display: "inline-block", color: '#FFF' }} is='div' className='banner-subheader my-4'>
            <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.' />
          </Wrapper>
          <Wrapper canvas id='random-id-1' is='div' className='button-aria flex flex-row w-full' >
            <Wrapper canvas id='random-id-1' className='rounded-[33px]' is='div'>
              <Button  classn='block' class='w-[152px] h-[46px] flex rounded-[33px] cursor-pointer border border-solid border-white bg-transparent"'>
                <Wrapper canvas id='random-id-1' is='div' style={{ display: "inline-block", cursor: 'pointer', color: '#fff' }} className='cursor-pointer my-auto mx-auto button-text' >
                  <Text text='Learn more' />
                </Wrapper>
              </Button>
            </Wrapper>

            <Wrapper canvas id='random-id-1' is='div' className='flex-row flex relative' style={{ cursor: 'pointer' }}>
              <Wrapper canvas id='random-id-1' is='div' style={{ margin: '0 6px 0' }} className='rounded-[33px]'>
                <Button  classn='block' class='rounded-[33px] cursor-pointer bg-[#9007D2] w-[152px] h-[46px] flex'>
                  <Wrapper canvas id='random-id-1' style={{ display: "inline-block", color: '#FFF', cursor: 'pointer' }} is='div' className='cursor-pointer my-auto mx-auto button-text'>
                    <Text text='Start for free' />
                  </Wrapper>
                </Button>
              </Wrapper>
              <Wrapper canvas id='random-id-1' is='div' className='my-auto absolute top-6 right-[44px]' style={{ zIndex: 20 }}>
                <HelpArrowBanner fill='#9007D2' />
              </Wrapper>
              <Wrapper canvas id='random-id-1' is='div' style={{ margin: '0 0 0 4px' }}>
                <Button  classn='inline-flex' class='rounded-full cursor-pointer bg-[#9007D2] w-[46px] h-[46px] flex my-auto' >
                  <Wrapper canvas id='random-id-1' is='div' className='my-auto mx-auto -rotate-45 hover:rotate-45 transition duration-700 ease-in-out'>
                    <ArrowToRight fill='#FFF' />
                  </Wrapper>
                </Button>
              </Wrapper>
            </Wrapper>
          </Wrapper>
        </Wrapper>
        <Wrapper canvas id='random-id-47' is='div' className='flex flex-col w-[100%]' style={{ flex: '50%' }}>
          <Wrapper canvas id='random-id-48' is='div' className='relative' style={{ borderRadius: '30px' }}>
            <Wrapper canvas id='random-id-49' is='div' className='absolute top-[20px] right-[20px] flex flex-row'>
              <Wrapper canvas id='random-id-50' style={{ display: "inline-block", margin: 'auto 10px' }} is='div' className='img-text '>
                <Text text='100K' />
              </Wrapper>
              <Wrapper canvas id='random-id-51' style={{ display: "inline-block", maxWidth: '53px', margin: 'auto auto', fontSize: '16px' }} is='div' className='img-text '>
                <Text text='Happy Users' />
              </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-52' style={{ borderRadius: '30px' }} className='banner-img' is='div'>
              <ImageC style={{ borderRadius: '30px', width: '100%', height: '100%', maxWidth: '640px', maxHeight: '340px' }} className='object-cover' alt='banner-img' src='https://i.ibb.co/N16Kjxg/5c0df7910ab8f6be7c19479ba7e615b8.jpg' />
            </Wrapper>
            <Wrapper canvas id='random-id-53' is='div'>
              <Button  classn='block' class='img-button bg-[#00000099] cursor-pointer rounded-[33px]'>
                <Wrapper canvas id='random-id-54' style={{ display: "inline-block", color: '#fff', cursor: 'pointer' }} is='div' className='button-text cursor-pointer'>
                  <Text text='About our team' />
                </Wrapper>
                <ArrowToRight />
              </Button>
            </Wrapper>
          </Wrapper>
          <Wrapper canvas id='random-id-55' is='div' className='flex flex-row w-[100%]' style={{ justifyContent: 'center', borderRadius: '30px', background: 'rgba(255, 255, 255, 0.10)', margin: '8px 0 0 0', padding: '24px 5px' }}>
            <Wrapper canvas id='random-id-55' is='div' className='flex flex-col mx-auto my-auto'>
              <Wrapper canvas id='random-id-56' style={{ display: "inline-block", color: '#FFF' }} is='div' className='card-header'>
                <Text text='85%' />
              </Wrapper>
              <Wrapper canvas id='random-id-57' style={{ display: "inline-block", color: '#FFF' }} is='div' className='card-subheader'>
                <Text text='Lorem Ipsum' />
              </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-58' is='div' className='flex flex-col mx-auto my-auto'>
              <Wrapper canvas id='random-id-59' style={{ display: "inline-block", color: '#FFF' }} is='div' className='card-header'>
                <Text text='120K' />
              </Wrapper>
              <Wrapper canvas id='random-id-60' style={{ display: "inline-block", color: '#FFF' }} is='div' className='card-subheader'>
                <Text text='Lorem Ipsum' />
              </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-61' is='div' className='flex flex-col mx-auto my-auto'>
              <Wrapper canvas id='random-id-62' style={{ display: "inline-block", color: '#FFF' }} is='div' className='card-header'>
                <Text text='5%' />
              </Wrapper>
              <Wrapper canvas id='random-id-63' style={{ display: "inline-block", color: '#FFF' }} is='div' className='card-subheader'>
                <Text text='Lorem Ipsum' />
              </Wrapper>
            </Wrapper>
          </Wrapper>
        </Wrapper>
      </Wrapper>
    </Wrapper></Element>
  </Element>,


    <Element canvas is={Parent} id='parentId500' className="w-[100%] h-auto" >
  <Element canvas is={Parent} id='parentId600' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-64' is='parent' className='w-full h-full py-10' style={{ background: '#FFF' }}>
      <Wrapper canvas id='random-id-65' is='container' className='container flex flex-row' style={{ flexWrap: 'wrap' }}>
        <Wrapper canvas id='random-id-66' is='div' className='flex flex-col w-[100%]' style={{ flex: '50%', alignContent: 'center', alignItems: 'center', justifyContent: 'center', margin: '20px 0' }}>
          <Wrapper canvas id='random-id-67' style={{ display: "inline-block", color: '#000000' }} is='div' className='banner-header'>
            <Text text='Understand User Flow' />
          </Wrapper>
          <Wrapper canvas id='random-id-68' style={{ display: "inline-block", color: '#000000' }} is='div' className='banner-subheader my-4'>
            <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.' />
          </Wrapper>
          <Wrapper canvas id='random-id-69' is='div' className='button-aria flex flex-row w-full' >
            <Wrapper canvas id='random-id-70' className='rounded-[33px]' is='div'>
              <Button  classn='block' class='w-[152px] h-[46px] flex rounded-[33px] cursor-pointer border border-solid border-black bg-transparent"'>
                <Wrapper canvas id='random-id-71' is='div' style={{ display: "inline-block", cursor: 'pointer', color: '#000' }} className='cursor-pointer my-auto mx-auto button-text' >
                  <Text text='Learn more' />
                </Wrapper>
              </Button>
            </Wrapper>

            <Wrapper canvas id='random-id-72' is='div' className='flex-row flex relative' style={{ cursor: 'pointer' }}>
              <Wrapper canvas id='random-id-73' is='div' style={{ margin: '0 6px 0' }} className='rounded-[33px]'>
                <Button  classn='block' class='rounded-[33px] cursor-pointer bg-[#343CE8] w-[152px] h-[46px] flex'>
                  <Wrapper canvas id='random-id-74' style={{ display: "inline-block", color: '#FFF', cursor: 'pointer' }} is='div' className='cursor-pointer my-auto mx-auto button-text'>
                    <Text text='Start for free' />
                  </Wrapper>
                </Button>
              </Wrapper>
              <Wrapper canvas id='random-id-75' is='div' className='my-auto absolute top-6 right-[44px]' style={{ zIndex: 20 }}>
                <HelpArrowBanner fill='#343CE8' />
              </Wrapper>
              <Wrapper canvas id='random-id-76' is='div' style={{ margin: '0 0 0 4px' }}>
                <Button  classn='inline-flex' class='rounded-full cursor-pointer bg-[#343CE8] w-[46px] h-[46px] flex my-auto' >
                  <Wrapper canvas id='random-id-77' is='div' className='my-auto mx-auto -rotate-45 hover:rotate-45 transition duration-700 ease-in-out'>
                    <ArrowToRight fill='#FFF' />
                  </Wrapper>
                </Button>
              </Wrapper>
            </Wrapper>
          </Wrapper>
        </Wrapper>
        <Wrapper canvas id='random-id-78' is='div' className='flex flex-col w-[100%]' style={{ flex: '50%' }}>
          <Wrapper canvas id='random-id-79' is='div' className='relative' style={{ borderRadius: '30px' }}>
            <Wrapper canvas id='random-id-80' is='div' className='absolute top-[20px] right-[20px] flex flex-row'>
              <Wrapper canvas id='random-id-81' style={{ display: "inline-block", color: '#000', margin: 'auto 10px' }} is='div' className='img-text '>
                <Text text='100K' />
              </Wrapper>
              <Wrapper canvas id='random-id-82' style={{ display: "inline-block", color: '#000', maxWidth: '53px', margin: 'auto auto', fontSize: '16px' }} is='div' className='img-text '>
                <Text text='Happy Users' />
              </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-83' style={{ borderRadius: '30px' }} className='banner-img' is='div'>
              <ImageC style={{ borderRadius: '30px', width: '100%', height: '100%', maxWidth: '640px', maxHeight: '340px' }} className='object-cover' alt='banner-img' src='https://i.ibb.co/NrPnH5b/7d2814b7067494a02c36b82f6a50e11a.jpg' />
            </Wrapper>
            <Wrapper canvas id='random-id-84' is='div'>
              <Button  classn='block' class='img-button bg-[#FFFFFFCC] cursor-pointer rounded-[33px]'>
                <Wrapper canvas id='random-id-85' style={{ display: "inline-block", color: '#000000', cursor: 'pointer' }} is='div' className='button-text cursor-pointer'>
                  <Text text='About our team' />
                </Wrapper>
                <ArrowToRight />
              </Button>
            </Wrapper>
          </Wrapper>
          <Wrapper canvas id='random-id-86' is='div' className='flex flex-row w-[100%]' style={{ justifyContent: 'center', borderRadius: '30px', background: '#DDEDFB', margin: '8px 0 0 0', padding: '24px 5px' }}>
            <Wrapper canvas id='random-id-87' is='div' className='flex flex-col mx-auto my-auto'>
              <Wrapper canvas id='random-id-88' style={{ display: "inline-block", color: '#000000' }} is='div' className='card-header'>
                <Text text='85%' />
              </Wrapper>
              <Wrapper canvas id='random-id-89' style={{ display: "inline-block", color: '#000000' }} is='div' className='card-subheader'>
                <Text text='Lorem Ipsum' />
              </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-90' is='div' className='flex flex-col mx-auto my-auto'>
              <Wrapper canvas id='random-id-91' style={{ display: "inline-block", color: '#000000' }} is='div' className='card-header'>
                <Text text='120K' />
              </Wrapper>
              <Wrapper canvas id='random-id-92' style={{ display: "inline-block", color: '#000000' }} is='div' className='card-subheader'>
                <Text text='Lorem Ipsum' />
              </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-93' is='div' className='flex flex-col mx-auto my-auto'>
              <Wrapper canvas id='random-id-94' style={{ display: "inline-block", color: '#000000' }} is='div' className='card-header'>
                <Text text='5%' />
              </Wrapper>
              <Wrapper canvas id='random-id-95' style={{ display: "inline-block", color: '#000000' }} is='div' className='card-subheader'>
                <Text text='Lorem Ipsum' />
              </Wrapper>
            </Wrapper>
          </Wrapper>
        </Wrapper>
      </Wrapper>
    </Wrapper></Element>
  </Element>,


    <Element canvas is={Parent} id='parentId700' className="w-[100%] h-auto" >
  <Element canvas is={Parent} id='parentId800' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-96' is='parent' className='w-full h-full py-10' style={{ background: '#0C2117' }}>
      <Wrapper canvas id='random-id-97' is='container' className='container flex flex-row' style={{ flexWrap: 'wrap' }}>
        <Wrapper canvas id='random-id-98' is='div' className='flex flex-col w-[100%]' style={{ flex: '50%', alignContent: 'center', alignItems: 'center', justifyContent: 'center', margin: '20px 0' }}>
          <Wrapper canvas id='random-id-99' style={{ display: "inline-block", color: '#FFFFFF' }} is='div' className='banner-header'>
            <Text text='Understand User Flow' />
          </Wrapper>
          <Wrapper canvas id='random-id-100' style={{ display: "inline-block", color: '#FFFFFF' }} is='div' className='banner-subheader my-4'>
            <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.' />
          </Wrapper>
          <Wrapper canvas id='random-id-101' is='div' className='button-aria flex flex-row w-full' >
            <Wrapper canvas id='random-id-102' className='rounded-[33px]' is='div'>
              <Button  classn='block' class='w-[152px] h-[46px] flex rounded-[33px] cursor-pointer border border-solid border-white bg-transparent"'>
                <Wrapper canvas id='random-id-103' is='div' style={{ display: "inline-block", cursor: 'pointer', color: '#FFFFFF' }} className='cursor-pointer my-auto mx-auto button-text' >
                  <Text text='Learn more' />
                </Wrapper>
              </Button>
            </Wrapper>

            <Wrapper canvas id='random-id-104' is='div' className='flex-row flex relative' style={{ cursor: 'pointer' }}>
              <Wrapper canvas id='random-id-105' is='div' style={{ margin: '0 6px 0' }} className='rounded-[33px]'>
                <Button  classn='block' class='rounded-[33px] cursor-pointer bg-[#3DA169] w-[152px] h-[46px] flex'>
                  <Wrapper canvas id='random-id-106' style={{ display: "inline-block", color: '#FFF', cursor: 'pointer' }} is='div' className='cursor-pointer my-auto mx-auto button-text'>
                    <Text text='Start for free' />
                  </Wrapper>
                </Button>
              </Wrapper>
              <Wrapper canvas id='random-id-107' is='div' className='my-auto absolute top-6 right-[44px]' style={{ zIndex: 20 }}>
                <HelpArrowBanner fill='#3DA169' />
              </Wrapper>
              <Wrapper canvas id='random-id-108' is='div' style={{ margin: '0 0 0 4px' }}>
                <Button  classn='inline-flex' class='rounded-full cursor-pointer bg-[#3DA169] w-[46px] h-[46px] flex my-auto' >
                  <Wrapper canvas id='random-id-109' is='div' className='my-auto mx-auto -rotate-45 hover:rotate-45 transition duration-700 ease-in-out'>
                    <ArrowToRight fill='#FFF' />
                  </Wrapper>
                </Button>
              </Wrapper>
            </Wrapper>
          </Wrapper>
        </Wrapper>
        <Wrapper canvas id='random-id-110' is='div' className='flex flex-col w-[100%]' style={{ flex: '50%' }}>
          <Wrapper canvas id='random-id-111' is='div' className='relative' style={{ borderRadius: '30px' }}>
            <Wrapper canvas id='random-id-112' is='div' className='absolute top-[20px] right-[20px] flex flex-row'>
              <Wrapper canvas id='random-id-113' style={{ display: "inline-block", color: '#FFF', margin: 'auto 10px' }} is='div' className='img-text '>
                <Text text='100K' />
              </Wrapper>
              <Wrapper canvas id='random-id-114' style={{ display: "inline-block", color: '#FFF', maxWidth: '53px', margin: 'auto auto', fontSize: '16px' }} is='div' className='img-text '>
                <Text text='Happy Users' />
              </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-115' style={{ borderRadius: '30px' }} className='banner-img' is='div'>
              <ImageC style={{ borderRadius: '30px', width: '100%', height: '100%', maxWidth: '640px', maxHeight: '340px' }} className='object-cover' alt='banner-img' src='https://i.ibb.co/LgKJW0N/2444bed7e4b1ee88dc213c0f979eb18d.jpg' />
            </Wrapper>
            <Wrapper canvas id='random-id-116' is='div'>
              <Button  classn='block' class='img-button bg-[#FFFFFF4D] cursor-pointer rounded-[33px]'>
                <Wrapper canvas id='random-id-117' style={{ display: "inline-block", color: '#FFFFFF', cursor: 'pointer' }} is='div' className='button-text cursor-pointer'>
                  <Text text='About our team' />
                </Wrapper>
                <ArrowToRight />
              </Button>
            </Wrapper>
          </Wrapper>
          <Wrapper canvas id='random-id-118' is='div' className='flex flex-row w-[100%]' style={{ justifyContent: 'center', borderRadius: '30px', background: '#DDEDFB', margin: '8px 0 0 0', padding: '24px 5px' }}>
            <Wrapper canvas id='random-id-119' is='div' className='flex flex-col mx-auto my-auto'>
              <Wrapper canvas id='random-id-120' style={{ display: "inline-block", color: '#000000' }} is='div' className='card-header'>
                <Text text='85%' />
              </Wrapper>
              <Wrapper canvas id='random-id-121' style={{ display: "inline-block", color: '#000000' }} is='div' className='card-subheader'>
                <Text text='Lorem Ipsum' />
              </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-122' is='div' className='flex flex-col mx-auto my-auto'>
              <Wrapper canvas id='random-id-123' style={{ display: "inline-block", color: '#000000' }} is='div' className='card-header'>
                <Text text='120K' />
              </Wrapper>
              <Wrapper canvas id='random-id-124' style={{ display: "inline-block", color: '#000000' }} is='div' className='card-subheader'>
                <Text text='Lorem Ipsum' />
              </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-125' is='div' className='flex flex-col mx-auto my-auto'>
              <Wrapper canvas id='random-id-126' style={{ display: "inline-block", color: '#000000' }} is='div' className='card-header'>
                <Text text='5%' />
              </Wrapper>
              <Wrapper canvas id='random-id-127' style={{ display: "inline-block", color: '#000000' }} is='div' className='card-subheader'>
                <Text text='Lorem Ipsum' />
              </Wrapper>
            </Wrapper>
          </Wrapper>
        </Wrapper>
      </Wrapper>
    </Wrapper></Element>
  </Element>,


    <Element canvas is={Parent} id='parentId900' className="w-[100%] h-auto" >
  <Element canvas is={Parent} id='parentId1000' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-128' is='parent' className='w-full h-full py-10' style={{ background: '#FEEEF4' }}>
      <Wrapper canvas id='random-id-129' is='container' className='container flex flex-row' style={{ flexWrap: 'wrap' }}>
        <Wrapper canvas id='random-id-130' is='div' className='flex flex-col w-[100%]' style={{ flex: '50%', alignContent: 'center', alignItems: 'center', justifyContent: 'center', margin: '20px 0' }}>
          <Wrapper canvas id='random-id-131' style={{ display: "inline-block", color: '#FF0560' }} is='div' className='banner-header'>
            <Text text='Understand User Flow' />
          </Wrapper>
          <Wrapper canvas id='random-id-132' style={{ display: "inline-block", color: '#000' }} is='div' className='banner-subheader my-4'>
            <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.' />
          </Wrapper>
          <Wrapper canvas id='random-id-133' is='div' className='button-aria flex flex-row w-full' >
            <Wrapper canvas id='random-id-134' className='rounded-[33px]' is='div'>
              <Button  classn='block' class='w-[152px] h-[46px] flex rounded-[33px] cursor-pointer border border-solid border-black bg-transparent"'>
                <Wrapper canvas id='random-id-135' is='div' style={{ display: "inline-block", cursor: 'pointer', color: '#000' }} className='cursor-pointer my-auto mx-auto button-text' >
                  <Text text='Learn more' />
                </Wrapper>
              </Button>
            </Wrapper>

            <Wrapper canvas id='random-id-136' is='div' className='flex-row flex relative' style={{ cursor: 'pointer' }}>
              <Wrapper canvas id='random-id-137' is='div' style={{ margin: '0 6px 0' }} className='rounded-[33px]'>
                <Button  classn='block' class='rounded-[33px] cursor-pointer bg-[#FF0560] w-[152px] h-[46px] flex'>
                  <Wrapper canvas id='random-id-138' style={{ display: "inline-block", color: '#FFF', cursor: 'pointer' }} is='div' className='cursor-pointer my-auto mx-auto button-text'>
                    <Text text='Start for free' />
                  </Wrapper>
                </Button>
              </Wrapper>
              <Wrapper canvas id='random-id-139' is='div' className='my-auto absolute top-6 right-[44px]' style={{ zIndex: 20 }}>
                <HelpArrowBanner fill='#FF0560' />
              </Wrapper>
              <Wrapper canvas id='random-id-140' is='div' style={{ margin: '0 0 0 4px' }}>
                <Button  classn='inline-flex' class='rounded-full cursor-pointer bg-[#343CE8] w-[46px] h-[46px] flex my-auto' >
                  <Wrapper canvas id='random-id-141' is='div' className='my-auto mx-auto -rotate-45 hover:rotate-45 transition duration-700 ease-in-out'>
                    <ArrowToRight fill='#FFF' />
                  </Wrapper>
                </Button>
              </Wrapper>
            </Wrapper>
          </Wrapper>
        </Wrapper>
        <Wrapper canvas id='random-id-142' is='div' className='flex flex-col w-[100%]' style={{ flex: '50%' }}>
          <Wrapper canvas id='random-id-143' is='div' className='relative' style={{ borderRadius: '30px' }}>
            <Wrapper canvas id='random-id-144' is='div' className='absolute top-[20px] right-[20px] flex flex-row'>
              <Wrapper canvas id='random-id-145' style={{ display: "inline-block", color: '#FFF', margin: 'auto 10px' }} is='div' className='img-text '>
                <Text text='100K' />
              </Wrapper>
              <Wrapper canvas id='random-id-146' style={{ display: "inline-block", color: '#FFF', maxWidth: '53px', margin: 'auto auto', fontSize: '16px' }} is='div' className='img-text '>
                <Text text='Happy Users' />
              </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-147' style={{ borderRadius: '30px' }} className='banner-img' is='div'>
              <ImageC style={{ borderRadius: '30px', width: '100%', height: '100%', maxWidth: '640px', maxHeight: '340px' }} className='object-cover' alt='banner-img' src='https://i.ibb.co/fxnRrsM/d10a7af5bee64a87d1fc3734e25366c2.jpg' />
            </Wrapper>
            <Wrapper canvas id='random-id-148' is='div'>
              <Button  classn='block' class='img-button bg-[#FFFFFF4D] cursor-pointer rounded-[33px]'>
                <Wrapper canvas id='random-id-149' style={{ display: "inline-block", color: '#000000', cursor: 'pointer' }} is='div' className='button-text cursor-pointer'>
                  <Text text='About our team' />
                </Wrapper>
                <ArrowToRight fill='#000000' />
              </Button>
            </Wrapper>
          </Wrapper>
          <Wrapper canvas id='random-id-150' is='div' className='flex flex-row w-[100%]' style={{ justifyContent: 'center', borderRadius: '30px', background: '#FFFFFF', margin: '8px 0 0 0', padding: '24px 5px' }}>
            <Wrapper canvas id='random-id-151' is='div' className='flex flex-col mx-auto my-auto'>
              <Wrapper canvas id='random-id-152' style={{ display: "inline-block", color: '#000000' }} is='div' className='card-header'>
                <Text text='85%' />
              </Wrapper>
              <Wrapper canvas id='random-id-153' style={{ display: "inline-block", color: '#000000' }} is='div' className='card-subheader'>
                <Text text='Lorem Ipsum' />
              </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-154' is='div' className='flex flex-col mx-auto my-auto'>
              <Wrapper canvas id='random-id-155' style={{ display: "inline-block", color: '#000000' }} is='div' className='card-header'>
                <Text text='120K' />
              </Wrapper>
              <Wrapper canvas id='random-id-156' style={{ display: "inline-block", color: '#000000' }} is='div' className='card-subheader'>
                <Text text='Lorem Ipsum' />
              </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-157' is='div' className='flex flex-col mx-auto my-auto'>
              <Wrapper canvas id='random-id-158' style={{ display: "inline-block", color: '#000000' }} is='div' className='card-header'>
                <Text text='5%' />
              </Wrapper>
              <Wrapper canvas id='random-id-159' style={{ display: "inline-block", color: '#000000' }} is='div' className='card-subheader'>
                <Text text='Lorem Ipsum' />
              </Wrapper>
            </Wrapper>
          </Wrapper>
        </Wrapper>
      </Wrapper>
    </Wrapper></Element>
  </Element>,


    <Element canvas is={Parent} id='parentId1100' className="w-[100%] h-auto" >
  <Element canvas is={Parent} id='parentId1200' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-160' is='parent' className='w-full h-full py-10' style={{ background: '#1F062C' }}>
      <Wrapper canvas id='random-id-161' is='container' className='container flex flex-row' style={{ flexWrap: 'wrap' }}>
        <Wrapper canvas id='random-id-162' is='div' className='flex flex-col w-[100%]' style={{ flex: '50%', alignContent: 'center', alignItems: 'center', justifyContent: 'center', margin: '20px 0' }}>
          <Wrapper canvas id='random-id-163' style={{ display: "inline-block", color: '#fff' }} is='div' className='banner-header'>
            <Text text='Understand User Flow' />
          </Wrapper>
          <Wrapper canvas id='random-id-164' style={{ display: "inline-block", color: '#fff' }} is='div' className='banner-subheader my-4'>
            <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.' />
          </Wrapper>
          <Wrapper canvas id='random-id-165' is='div' className='button-aria flex flex-row w-full' >
            <Wrapper canvas id='random-id-166' className='rounded-[33px]' is='div'>
              <Button  classn='block' class='w-[152px] h-[46px] flex rounded-[33px] cursor-pointer border border-solid border-white bg-transparent"'>
                <Wrapper canvas id='random-id-167' is='div' style={{ display: "inline-block", cursor: 'pointer', color: '#fff' }} className='cursor-pointer my-auto mx-auto button-text' >
                  <Text text='Learn more' />
                </Wrapper>
              </Button>
            </Wrapper>

            <Wrapper canvas id='random-id-168' is='div' className='flex-row flex relative' style={{ cursor: 'pointer' }}>
              <Wrapper canvas id='random-id-169' is='div' style={{ margin: '0 6px 0' }} className='rounded-[33px]'>
                <Button  classn='block' class='rounded-[33px] cursor-pointer bg-[#950B67] w-[152px] h-[46px] flex'>
                  <Wrapper canvas id='random-id-170' style={{ display: "inline-block", color: '#FFF', cursor: 'pointer' }} is='div' className='cursor-pointer my-auto mx-auto button-text'>
                    <Text text='Start for free' />
                  </Wrapper>
                </Button>
              </Wrapper>
              <Wrapper canvas id='random-id-171' is='div' className='my-auto absolute top-6 right-[44px]' style={{ zIndex: 20 }}>
                <HelpArrowBanner fill='#950B67' />
              </Wrapper>
              <Wrapper canvas id='random-id-172' is='div' style={{ margin: '0 0 0 4px' }}>
                <Button  classn='inline-flex' class='rounded-full cursor-pointer bg-[#950B67] w-[46px] h-[46px] flex my-auto' >
                  <Wrapper canvas id='random-id-173' is='div' className='my-auto mx-auto -rotate-45 hover:rotate-45 transition duration-700 ease-in-out'>
                    <ArrowToRight fill='#FFF' />
                  </Wrapper>
                </Button>
              </Wrapper>
            </Wrapper>
          </Wrapper>
        </Wrapper>
        <Wrapper canvas id='random-id-174' is='div' className='flex flex-col w-[100%]' style={{ flex: '50%' }}>
          <Wrapper canvas id='random-id-175' is='div' className='relative' style={{ borderRadius: '30px' }}>
            <Wrapper canvas id='random-id-176' is='div' className='absolute top-[20px] right-[20px] flex flex-row'>
              <Wrapper canvas id='random-id-177' style={{ display: "inline-block", color: '#FFF', margin: 'auto 10px' }} is='div' className='img-text '>
                <Text text='100K' />
              </Wrapper>
              <Wrapper canvas id='random-id-178' style={{ display: "inline-block", color: '#FFF', maxWidth: '53px', margin: 'auto auto', fontSize: '16px' }} is='div' className='img-text '>
                <Text text='Happy Users' />
              </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-179' style={{ borderRadius: '30px' }} className='banner-img' is='div'>
              <ImageC style={{ borderRadius: '30px', width: '100%', height: '100%', maxWidth: '640px', maxHeight: '340px' }} className='object-cover' alt='banner-img' src='https://i.ibb.co/16nKpqD/ca4c3e25bb0a7a02ffe96a55aff07947.jpg' />
            </Wrapper>
            <Wrapper canvas id='random-id-180' is='div'>
              <Button  classn='block' class='img-button bg-[#FFFFFFCC] cursor-pointer rounded-[33px]'>
                <Wrapper canvas id='random-id-181' style={{ display: "inline-block", color: '#000000', cursor: 'pointer' }} is='div' className='button-text cursor-pointer'>
                  <Text text='About our team' />
                </Wrapper>
                <ArrowToRight fill='#000000' />
              </Button>
            </Wrapper>
          </Wrapper>
          <Wrapper canvas id='random-id-182' is='div' className='flex flex-row w-[100%]' style={{ justifyContent: 'center', borderRadius: '30px', background: '#FFFFFF33', margin: '8px 0 0 0', padding: '24px 5px' }}>
            <Wrapper canvas id='random-id-183' is='div' className='flex flex-col mx-auto my-auto'>
              <Wrapper canvas id='random-id-184' style={{ display: "inline-block", color: '#fff' }} is='div' className='card-header'>
                <Text text='85%' />
              </Wrapper>
              <Wrapper canvas id='random-id-185' style={{ display: "inline-block", color: '#fff' }} is='div' className='card-subheader'>
                <Text text='Lorem Ipsum' />
              </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-186' is='div' className='flex flex-col mx-auto my-auto'>
              <Wrapper canvas id='random-id-187' style={{ display: "inline-block", color: '#fff' }} is='div' className='card-header'>
                <Text text='120K' />
              </Wrapper>
              <Wrapper canvas id='random-id-188' style={{ display: "inline-block", color: '#fff' }} is='div' className='card-subheader'>
                <Text text='Lorem Ipsum' />
              </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-189' is='div' className='flex flex-col mx-auto my-auto'>
              <Wrapper canvas id='random-id-190' style={{ display: "inline-block", color: '#fff' }} is='div' className='card-header'>
                <Text text='5%' />
              </Wrapper>
              <Wrapper canvas id='random-id-191' style={{ display: "inline-block", color: '#fff' }} is='div' className='card-subheader'>
                <Text text='Lorem Ipsum' />
              </Wrapper>
            </Wrapper>
          </Wrapper>
        </Wrapper>
      </Wrapper>
    </Wrapper></Element>
  </Element>,


    <Element canvas is={Parent} id='parentId1300' className="w-[100%] h-auto" >
  <Element canvas is={Parent} id='parentId1400' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-192' is='parent' className='w-full h-full py-10' style={{ background: '#FFF' }}>
      <Wrapper canvas id='random-id-193' is='container' className='container flex flex-row' style={{ flexWrap: 'wrap' }}>
        <Wrapper canvas id='random-id-194' is='div' className='flex flex-col w-[100%]' style={{ flex: '50%', alignContent: 'center', alignItems: 'center', justifyContent: 'center', margin: '20px 0' }}>
          <Wrapper canvas id='random-id-195' style={{ display: "inline-block", color: '#000' }} is='div' className='banner-header'>
            <Text text='Understand User Flow' />
          </Wrapper>
          <Wrapper canvas id='random-id-196' style={{ display: "inline-block", color: '#000' }} is='div' className='banner-subheader my-4'>
            <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.' />
          </Wrapper>
          <Wrapper canvas id='random-id-197' is='div' className='button-aria flex flex-row w-full' >
            <Wrapper canvas id='random-id-198' className='rounded-[33px]' is='div'>
              <Button  classn='block' class='w-[152px] h-[46px] flex rounded-[33px] cursor-pointer border border-solid border-black bg-transparent"'>
                <Wrapper canvas id='random-id-199' is='div' style={{ display: "inline-block", cursor: 'pointer', color: '#000' }} className='cursor-pointer my-auto mx-auto button-text' >
                  <Text text='Learn more' />
                </Wrapper>
              </Button>
            </Wrapper>

            <Wrapper canvas id='random-id-200' is='div' className='flex-row flex relative' style={{ cursor: 'pointer' }}>
              <Wrapper canvas id='random-id-201' is='div' style={{ margin: '0 6px 0' }} className='rounded-[33px]'>
                <Button  classn='block' class='rounded-[33px] cursor-pointer bg-[#FE490B] w-[152px] h-[46px] flex'>
                  <Wrapper canvas id='random-id-202' style={{ display: "inline-block", color: '#FFF', cursor: 'pointer' }} is='div' className='cursor-pointer my-auto mx-auto button-text'>
                    <Text text='Start for free' />
                  </Wrapper>
                </Button>
              </Wrapper>
              <Wrapper canvas id='random-id-203' is='div' className='my-auto absolute top-6 right-[44px]' style={{ zIndex: 20 }}>
                <HelpArrowBanner fill='#FE490B' />
              </Wrapper>
              <Wrapper canvas id='random-id-204' is='div' style={{ margin: '0 0 0 4px' }}>
                <Button  classn='inline-flex' class='rounded-full cursor-pointer bg-[#FE490B] w-[46px] h-[46px] flex my-auto' >
                  <Wrapper canvas id='random-id-205' is='div' className='my-auto mx-auto -rotate-45 hover:rotate-45 transition duration-700 ease-in-out'>
                    <ArrowToRight fill='#FFF' />
                  </Wrapper>
                </Button>
              </Wrapper>
            </Wrapper>
          </Wrapper>
        </Wrapper>
        <Wrapper canvas id='random-id-206' is='div' className='flex flex-col w-[100%]' style={{ flex: '50%' }}>
          <Wrapper canvas id='random-id-207' is='div' className='relative' style={{ borderRadius: '30px' }}>
            <Wrapper canvas id='random-id-208' is='div' className='absolute top-[20px] right-[20px] flex flex-row'>
              <Wrapper canvas id='random-id-209' style={{ display: "inline-block", color: '#ffffff', margin: 'auto 10px' }} is='div' className='img-text '>
                <Text text='100K' />
              </Wrapper>
              <Wrapper canvas id='random-id-210' style={{ display: "inline-block", color: '#ffffff', maxWidth: '53px', margin: 'auto auto', fontSize: '16px' }} is='div' className='img-text '>
                <Text text='Happy Users' />
              </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-211' style={{ borderRadius: '30px' }} className='banner-img' is='div'>
              <ImageC style={{ borderRadius: '30px', width: '100%', height: '100%', maxWidth: '640px', maxHeight: '340px' }} className='object-cover' alt='banner-img' src='https://i.ibb.co/gSm5q41/226acf7e25205a85e2d1c5f7262d9d5b.jpg' />
            </Wrapper>
            <Wrapper canvas id='random-id-212' is='div'>
              <Button  classn='block' class='img-button bg-[#00000099] cursor-pointer rounded-[33px]'>
                <Wrapper canvas id='random-id-213' style={{ display: "inline-block", color: '#ffffff', cursor: 'pointer' }} is='div' className='button-text cursor-pointer'>
                  <Text text='About our team' />
                </Wrapper>
                <ArrowToRight fill='#000000' />
              </Button>
            </Wrapper>
          </Wrapper>
          <Wrapper canvas id='random-id-214' is='div' className='flex flex-row w-[100%]' style={{ justifyContent: 'center', borderRadius: '30px', background: '#02BFE833', margin: '8px 0 0 0', padding: '24px 5px' }}>
            <Wrapper canvas id='random-id-215' is='div' className='flex flex-col mx-auto my-auto'>
              <Wrapper canvas id='random-id-216' style={{ display: "inline-block", color: '#000' }} is='div' className='card-header'>
                <Text text='85%' />
              </Wrapper>
              <Wrapper canvas id='random-id-217' style={{ display: "inline-block", color: '#000' }} is='div' className='card-subheader'>
                <Text text='Lorem Ipsum' />
              </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-218' is='div' className='flex flex-col mx-auto my-auto'>
              <Wrapper canvas id='random-id-219' style={{ display: "inline-block", color: '#000' }} is='div' className='card-header'>
                <Text text='120K' />
              </Wrapper>
              <Wrapper canvas id='random-id-220' style={{ display: "inline-block", color: '#000' }} is='div' className='card-subheader'>
                <Text text='Lorem Ipsum' />
              </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-221' is='div' className='flex flex-col mx-auto my-auto'>
              <Wrapper canvas id='random-id-222' style={{ display: "inline-block", color: '#000' }} is='div' className='card-header'>
                <Text text='5%' />
              </Wrapper>
              <Wrapper canvas id='random-id-223' style={{ display: "inline-block", color: '#000' }} is='div' className='card-subheader'>
                <Text text='Lorem Ipsum' />
              </Wrapper>
            </Wrapper>
          </Wrapper>
        </Wrapper>
      </Wrapper>
    </Wrapper></Element>
  </Element>,


    <Element canvas is={Parent} id='parentId1500' className="w-[100%] h-auto" >
  <Element canvas is={Parent} id='parentId1600' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-224' is='parent' className='w-full h-full py-10' style={{ background: '#080B2B' }}>
      <Wrapper canvas id='random-id-225' is='container' className='container flex flex-row' style={{ flexWrap: 'wrap' }}>
        <Wrapper canvas id='random-id-226' is='div' className='flex flex-col w-[100%]' style={{ flex: '50%', alignContent: 'center', alignItems: 'center', justifyContent: 'center', margin: '20px 0' }}>
          <Wrapper canvas id='random-id-227' style={{ display: "inline-block", color: '#fff' }} is='div' className='banner-header'>
            <Text text='Understand User Flow' />
          </Wrapper>
          <Wrapper canvas id='random-id-228' style={{ display: "inline-block", color: '#fff' }} is='div' className='banner-subheader my-4'>
            <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.' />
          </Wrapper>
          <Wrapper canvas id='random-id-229' is='div' className='button-aria flex flex-row w-full' >
            <Wrapper canvas id='random-id-230' className='rounded-[33px]' is='div'>
              <Button  classn='block' class='w-[152px] h-[46px] flex rounded-[33px] cursor-pointer border border-solid border-white bg-transparent"'>
                <Wrapper canvas id='random-id-231' is='div' style={{ display: "inline-block", cursor: 'pointer', color: '#fff' }} className='cursor-pointer my-auto mx-auto button-text' >
                  <Text text='Learn more' />
                </Wrapper>
              </Button>
            </Wrapper>

            <Wrapper canvas id='random-id-232' is='div' className='flex-row flex relative' style={{ cursor: 'pointer' }}>
              <Wrapper canvas id='random-id-233' is='div' style={{ margin: '0 6px 0' }} className='rounded-[33px]'>
                <Button  classn='block' class='rounded-[33px] cursor-pointer bg-[#D423A5] w-[152px] h-[46px] flex'>
                  <Wrapper canvas id='random-id-234' style={{ display: "inline-block", color: '#FFF', cursor: 'pointer' }} is='div' className='cursor-pointer my-auto mx-auto button-text'>
                    <Text text='Start for free' />
                  </Wrapper>
                </Button>
              </Wrapper>
              <Wrapper canvas id='random-id-235' is='div' className='my-auto absolute top-6 right-[44px]' style={{ zIndex: 20 }}>
                <HelpArrowBanner fill='#D423A5' />
              </Wrapper>
              <Wrapper canvas id='random-id-236' is='div' style={{ margin: '0 0 0 4px' }}>
                <Button  classn='inline-flex' class='rounded-full cursor-pointer bg-[#D423A5] w-[46px] h-[46px] flex my-auto' >
                  <Wrapper canvas id='random-id-237' is='div' className='my-auto mx-auto -rotate-45 hover:rotate-45 transition duration-700 ease-in-out'>
                    <ArrowToRight fill='#FFF' />
                  </Wrapper>
                </Button>
              </Wrapper>
            </Wrapper>
          </Wrapper>
        </Wrapper>
        <Wrapper canvas id='random-id-238' is='div' className='flex flex-col w-[100%]' style={{ flex: '50%' }}>
          <Wrapper canvas id='random-id-239' is='div' className='relative' style={{ borderRadius: '30px' }}>
            <Wrapper canvas id='random-id-240' is='div' className='absolute top-[20px] right-[20px] flex flex-row'>
              <Wrapper canvas id='random-id-241' style={{ display: "inline-block", color: '#ffffff', margin: 'auto 10px' }} is='div' className='img-text '>
                <Text text='100K' />
              </Wrapper>
              <Wrapper canvas id='random-id-242' style={{ display: "inline-block", color: '#ffffff', maxWidth: '53px', margin: 'auto auto', fontSize: '16px' }} is='div' className='img-text '>
                <Text text='Happy Users' />
              </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-243' style={{ borderRadius: '30px' }} className='banner-img' is='div'>
              <ImageC style={{ borderRadius: '30px', width: '100%', height: '100%', maxWidth: '640px', maxHeight: '340px' }} className='object-cover' alt='banner-img' src='https://i.ibb.co/gSm5q41/226acf7e25205a85e2d1c5f7262d9d5b.jpg' />
            </Wrapper>
            <Wrapper canvas id='random-id-244' is='div'>
              <Button  classn='block' class='img-button bg-[#FFFFFF66] cursor-pointer rounded-[33px]'>
                <Wrapper canvas id='random-id-245' style={{ display: "inline-block", color: '#ffffff', cursor: 'pointer' }} is='div' className='button-text cursor-pointer'>
                  <Text text='About our team' />
                </Wrapper>
                <ArrowToRight fill='#fff' />
              </Button>
            </Wrapper>
          </Wrapper>
          <Wrapper canvas id='random-id-246' is='div' className='flex flex-row w-[100%]' style={{ justifyContent: 'center', borderRadius: '30px', background: '#D423A533', margin: '8px 0 0 0', padding: '24px 5px' }}>
            <Wrapper canvas id='random-id-247' is='div' className='flex flex-col mx-auto my-auto'>
              <Wrapper canvas id='random-id-248' style={{ display: "inline-block", color: '#fff' }} is='div' className='card-header'>
                <Text text='85%' />
              </Wrapper>
              <Wrapper canvas id='random-id-249' style={{ display: "inline-block", color: '#fff' }} is='div' className='card-subheader'>
                <Text text='Lorem Ipsum' />
              </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-250' is='div' className='flex flex-col mx-auto my-auto'>
              <Wrapper canvas id='random-id-251' style={{ display: "inline-block", color: '#fff' }} is='div' className='card-header'>
                <Text text='120K' />
              </Wrapper>
              <Wrapper canvas id='random-id-252' style={{ display: "inline-block", color: '#fff' }} is='div' className='card-subheader'>
                <Text text='Lorem Ipsum' />
              </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-253' is='div' className='flex flex-col mx-auto my-auto'>
              <Wrapper canvas id='random-id-254' style={{ display: "inline-block", color: '#fff' }} is='div' className='card-header'>
                <Text text='5%' />
              </Wrapper>
              <Wrapper canvas id='random-id-255' style={{ display: "inline-block", color: '#fff' }} is='div' className='card-subheader'>
                <Text text='Lorem Ipsum' />
              </Wrapper>
            </Wrapper>
          </Wrapper>
        </Wrapper>
      </Wrapper>
    </Wrapper></Element>
  </Element>,


    <Element canvas is={Parent} id='parentId1700' className="w-[100%] h-auto" >
  <Element canvas is={Parent} id='parentId1800' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-256' is='parent' className='w-full h-full py-10' style={{ background: '#EEFAFF' }}>
      <Wrapper canvas id='random-id-257' is='container' className='container flex flex-row' style={{ flexWrap: 'wrap' }}>
        <Wrapper canvas id='random-id-258' is='div' className='flex flex-col w-[100%]' style={{ flex: '50%', alignContent: 'center', alignItems: 'center', justifyContent: 'center', margin: '20px 0' }}>
          <Wrapper canvas id='random-id-259' style={{ display: "inline-block", color: '#000' }} is='div' className='banner-header'>
            <Text text='Understand User Flow' />
          </Wrapper>
          <Wrapper canvas id='random-id-260' style={{ display: "inline-block", color: '#000' }} is='div' className='banner-subheader my-4'>
            <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.' />
          </Wrapper>
          <Wrapper canvas id='random-id-261' is='div' className='button-aria flex flex-row w-full' >
            <Wrapper canvas id='random-id-262' className='rounded-[33px]' is='div'>
              <Button  classn='block' class='w-[152px] h-[46px] flex rounded-[33px] cursor-pointer border border-solid border-black bg-transparent"'>
                <Wrapper canvas id='random-id-263' is='div' style={{ display: "inline-block", cursor: 'pointer', color: '#000' }} className='cursor-pointer my-auto mx-auto button-text' >
                  <Text text='Learn more' />
                </Wrapper>
              </Button>
            </Wrapper>

            <Wrapper canvas id='random-id-264' is='div' className='flex-row flex relative' style={{ cursor: 'pointer' }}>
              <Wrapper canvas id='random-id-265' is='div' style={{ margin: '0 6px 0' }} className='rounded-[33px]'>
                <Button  classn='block' class='rounded-[33px] cursor-pointer bg-[#02C8B4] w-[152px] h-[46px] flex'>
                  <Wrapper canvas id='random-id-266' style={{ display: "inline-block", color: '#000', cursor: 'pointer' }} is='div' className='cursor-pointer my-auto mx-auto button-text'>
                    <Text text='Start for free' />
                  </Wrapper>
                </Button>
              </Wrapper>
              <Wrapper canvas id='random-id-267' is='div' className='my-auto absolute top-6 right-[44px]' style={{ zIndex: 20 }}>
                <HelpArrowBanner fill='#02C8B4' />
              </Wrapper>
              <Wrapper canvas id='random-id-268' is='div' style={{ margin: '0 0 0 4px' }}>
                <Button  classn='inline-flex' class='rounded-full cursor-pointer bg-[#02C8B4] w-[46px] h-[46px] flex my-auto' >
                  <Wrapper canvas id='random-id-269' is='div' className='my-auto mx-auto -rotate-45 hover:rotate-45 transition duration-700 ease-in-out'>
                    <ArrowToRight fill='#000' />
                  </Wrapper>
                </Button>
              </Wrapper>
            </Wrapper>
          </Wrapper>
        </Wrapper>
        <Wrapper canvas id='random-id-270' is='div' className='flex flex-col w-[100%]' style={{ flex: '50%' }}>
          <Wrapper canvas id='random-id-271' is='div' className='relative' style={{ borderRadius: '30px' }}>
            <Wrapper canvas id='random-id-272' is='div' className='absolute top-[20px] right-[20px] flex flex-row'>
              <Wrapper canvas id='random-id-273' style={{ display: "inline-block", color: '#ffffff', margin: 'auto 10px' }} is='div' className='img-text '>
                <Text text='100K' />
              </Wrapper>
              <Wrapper canvas id='random-id-274' style={{ display: "inline-block", color: '#ffffff', maxWidth: '53px', margin: 'auto auto', fontSize: '16px' }} is='div' className='img-text '>
                <Text text='Happy Users' />
              </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-275' style={{ borderRadius: '30px' }} className='banner-img' is='div'>
              <ImageC style={{ borderRadius: '30px', width: '100%', height: '100%', maxWidth: '640px', maxHeight: '340px' }} className='object-cover' alt='banner-img' src='https://i.ibb.co/c8Fr9gn/f05c65e2de2bd6045a56e4fb3347c759.jpg' />
            </Wrapper>
            <Wrapper canvas id='random-id-276' is='div'>
              <Button  classn='block' class='img-button bg-[#FFFFFF4D] cursor-pointer rounded-[33px]'>
                <Wrapper canvas id='random-id-277' style={{ display: "inline-block", color: '#ffffff', cursor: 'pointer' }} is='div' className='button-text cursor-pointer'>
                  <Text text='About our team' />
                </Wrapper>
                <ArrowToRight fill='#fff' />
              </Button>
            </Wrapper>
          </Wrapper>
          <Wrapper canvas id='random-id-278' is='div' className='flex flex-row w-[100%]' style={{ justifyContent: 'center', borderRadius: '30px', background: '#02C8B433', margin: '8px 0 0 0', padding: '24px 5px' }}>
            <Wrapper canvas id='random-id-279' is='div' className='flex flex-col mx-auto my-auto'>
              <Wrapper canvas id='random-id-280' style={{ display: "inline-block", color: '#000' }} is='div' className='card-header'>
                <Text text='85%' />
              </Wrapper>
              <Wrapper canvas id='random-id-281' style={{ display: "inline-block", color: '#000' }} is='div' className='card-subheader'>
                <Text text='Lorem Ipsum' />
              </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-282' is='div' className='flex flex-col mx-auto my-auto'>
              <Wrapper canvas id='random-id-283' style={{ display: "inline-block", color: '#000' }} is='div' className='card-header'>
                <Text text='120K' />
              </Wrapper>
              <Wrapper canvas id='random-id-284' style={{ display: "inline-block", color: '#000' }} is='div' className='card-subheader'>
                <Text text='Lorem Ipsum' />
              </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-285' is='div' className='flex flex-col mx-auto my-auto'>
              <Wrapper canvas id='random-id-286' style={{ display: "inline-block", color: '#000' }} is='div' className='card-header'>
                <Text text='5%' />
              </Wrapper>
              <Wrapper canvas id='random-id-287' style={{ display: "inline-block", color: '#000' }} is='div' className='card-subheader'>
                <Text text='Lorem Ipsum' />
              </Wrapper>
            </Wrapper>
          </Wrapper>
        </Wrapper>
      </Wrapper>
    </Wrapper></Element>
  </Element>,


    <Element canvas is={Parent} id='parentId1900' className="w-[100%] h-auto" >
  <Element canvas is={Parent} id='parentId1910' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-288' is='parent' className='w-full h-full py-10' style={{ background: '#444A50' }}>
      <Wrapper canvas id='random-id-289' is='container' className='container flex flex-row' style={{ flexWrap: 'wrap' }}>
        <Wrapper canvas id='random-id-290' is='div' className='flex flex-col w-[100%]' style={{ flex: '50%', alignContent: 'center', alignItems: 'center', justifyContent: 'center', margin: '20px 0' }}>
          <Wrapper canvas id='random-id-291' style={{ display: "inline-block", color: '#fff' }} is='div' className='banner-header'>
            <Text text='Understand User Flow' />
          </Wrapper>
          <Wrapper canvas id='random-id-292' style={{ display: "inline-block", color: '#fff' }} is='div' className='banner-subheader my-4'>
            <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.' />
          </Wrapper>
          <Wrapper canvas id='random-id-293' is='div' className='button-aria flex flex-row w-full' >
            <Wrapper canvas id='random-id-294' className='rounded-[33px]' is='div'>
              <Button  classn='block' class='w-[152px] h-[46px] flex rounded-[33px] cursor-pointer border border-solid border-white bg-transparent"'>
                <Wrapper canvas id='random-id-295' is='div' style={{ display: "inline-block", cursor: 'pointer', color: '#fff' }} className='cursor-pointer my-auto mx-auto button-text' >
                  <Text text='Learn more' />
                </Wrapper>
              </Button>
            </Wrapper>

            <Wrapper canvas id='random-id-296' is='div' className='flex-row flex relative' style={{ cursor: 'pointer' }}>
              <Wrapper canvas id='random-id-297' is='div' style={{ margin: '0 6px 0' }} className='rounded-[33px]'>
                <Button  classn='block' class='rounded-[33px] cursor-pointer bg-[#C7D9E3] w-[152px] h-[46px] flex'>
                  <Wrapper canvas id='random-id-298' style={{ display: "inline-block", color: '#000', cursor: 'pointer' }} is='div' className='cursor-pointer my-auto mx-auto button-text'>
                    <Text text='Start for free' />
                  </Wrapper>
                </Button>
              </Wrapper>
              <Wrapper canvas id='random-id-299' is='div' className='my-auto absolute top-6 right-[44px]' style={{ zIndex: 20 }}>
                <HelpArrowBanner fill='#C7D9E3' />
              </Wrapper>
              <Wrapper canvas id='random-id-300' is='div' style={{ margin: '0 0 0 4px' }}>
                <Button  classn='inline-flex' class='rounded-full cursor-pointer bg-[#C7D9E3] w-[46px] h-[46px] flex my-auto' >
                  <Wrapper canvas id='random-id-301' is='div' className='my-auto mx-auto -rotate-45 hover:rotate-45 transition duration-700 ease-in-out'>
                    <ArrowToRight fill='#000' />
                  </Wrapper>
                </Button>
              </Wrapper>
            </Wrapper>
          </Wrapper>
        </Wrapper>
        <Wrapper canvas id='random-id-302' is='div' className='flex flex-col w-[100%]' style={{ flex: '50%' }}>
          <Wrapper canvas id='random-id-303' is='div' className='relative' style={{ borderRadius: '30px' }}>
            <Wrapper canvas id='random-id-304' is='div' className='absolute top-[20px] right-[20px] flex flex-row'>
              <Wrapper canvas id='random-id-305' style={{ display: "inline-block", color: '#ffffff', margin: 'auto 10px' }} is='div' className='img-text '>
                <Text text='100K' />
              </Wrapper>
              <Wrapper canvas id='random-id-306' style={{ display: "inline-block", color: '#ffffff', maxWidth: '53px', margin: 'auto auto', fontSize: '16px' }} is='div' className='img-text '>
                <Text text='Happy Users' />
              </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-307' style={{ borderRadius: '30px' }} className='banner-img' is='div'>
              <ImageC style={{ borderRadius: '30px', width: '100%', height: '100%', maxWidth: '640px', maxHeight: '340px' }} className='object-cover' alt='banner-img' src='https://i.ibb.co/k6MF2fM/ba2f88a9c55bbd8e8e6ee26d15b50f73.jpg' />
            </Wrapper>
            <Wrapper canvas id='random-id-308' is='div'>
              <Button  classn='block' class='img-button bg-[#FFFFFFCC] cursor-pointer rounded-[33px]'>
                <Wrapper canvas id='random-id-309' style={{ display: "inline-block", color: '#000', cursor: 'pointer' }} is='div' className='button-text cursor-pointer'>
                  <Text text='About our team' />
                </Wrapper>
                <ArrowToRight fill='#fff' />
              </Button>
            </Wrapper>
          </Wrapper>
          <Wrapper canvas id='random-id-310' is='div' className='flex flex-row w-[100%]' style={{ justifyContent: 'center', borderRadius: '30px', background: '#C7D9E366', margin: '8px 0 0 0', padding: '24px 5px' }}>
            <Wrapper canvas id='random-id-311' is='div' className='flex flex-col mx-auto my-auto'>
              <Wrapper canvas id='random-id-312' style={{ display: "inline-block", color: '#fff' }} is='div' className='card-header'>
                <Text text='85%' />
              </Wrapper>
              <Wrapper canvas id='random-id-313' style={{ display: "inline-block", color: '#fff' }} is='div' className='card-subheader'>
                <Text text='Lorem Ipsum' />
              </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-314' is='div' className='flex flex-col mx-auto my-auto'>
              <Wrapper canvas id='random-id-315' style={{ display: "inline-block", color: '#fff' }} is='div' className='card-header'>
                <Text text='120K' />
              </Wrapper>
              <Wrapper canvas id='random-id-316' style={{ display: "inline-block", color: '#fff' }} is='div' className='card-subheader'>
                <Text text='Lorem Ipsum' />
              </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-317' is='div' className='flex flex-col mx-auto my-auto'>
              <Wrapper canvas id='random-id-318' style={{ display: "inline-block", color: '#fff' }} is='div' className='card-header'>
                <Text text='5%' />
              </Wrapper>
              <Wrapper canvas id='random-id-319' style={{ display: "inline-block", color: '#fff' }} is='div' className='card-subheader'>
                <Text text='Lorem Ipsum' />
              </Wrapper>
            </Wrapper>
          </Wrapper>
        </Wrapper>
      </Wrapper>
    </Wrapper></Element>
  </Element>,


  ]

  
  const [sel, setSel] = useState(selected)

  function makeOdd(number) {
    setProp((prop) => {
      setSel(100);
    }, 1000);

    setTimeout(() => {
      setProp((prop) => {
        setSel(number)
      }, 1000);
    }, 100);


    
}

useEffect(() => {
  if(selected || selected >= 0) {
    makeOdd(selected);
  }
},[selected])


  return ( <div style={{ padding: enabled? "10px" : "0px", display:"flex", justifyContent:"center", alignItems:"center", width:'100%', height:'100%'}} ref={connect}>
  {sel == 100 ? (
          <div style={{height:"85vh", display:"flex", justifyContent:"center", alignItems:"center"}}>
          <BounceLoader
                  size={100}
                  color={"white"}
                  loading={true}
                />
          </div>
         ) : (
          styles[sel]
         )} 
</div>
  );
};

Banner_3.craft = {
  displayName: "Banner 3",
  props: {
    selected: 1, // here is the default value of the selected style
    isBlock: true
  },
  rules: {
    canDrag: () => true,
    canDrop: () => true,
  },
  related: {
    toolbar: NewBannerSettings,
  },
};