/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, { useState } from 'react';
import { ToolbarSection, ToolbarItem } from '../../../editor';
import { TabOption } from '../../../editor/Viewport/Sidebar/TabOption';
import SelectionBox from '../../../editor/Viewport/Pop-up/PopUp';
import { useNode } from '@craftjs/core';
import Toggle from '../../../editor/Toolbar/Toggle';
import { imagesArr } from '../../../editor/Viewport/Pop-up/ImageArray'

export const category = [
  // { tap: 'All Available'},
  { icon: 'https://i.ibb.co/59HkdbY/iconmonstr-accessibility-lined-48.png', tap: "Gallery" },
  { icon: 'https://i.ibb.co/6m7cfkZ/iconmonstr-cat-7-48.png', tap: "Our team" },
  { icon: 'https://i.ibb.co/hLMZ2HB/iconmonstr-language-4-48.png', tap: "Statistics" },
  { icon: 'https://i.ibb.co/59HkdbY/iconmonstr-accessibility-lined-48.png', tap: "Price" },
  { icon: 'https://i.ibb.co/k89sJ1t/iconmonstr-car-21-48.png', tap: "Contact Us/Help/Support " },
  { icon: 'https://i.ibb.co/RpwQ6MK/iconmonstr-marketing-14-48-1.png', tap: "Services" },
  { icon: 'https://i.ibb.co/nQk7FhC/iconmonstr-error-lined-48.png', tap: "Email/Pin/Note" },
  { icon: 'https://i.ibb.co/XbY1pX2/iconmonstr-user-circle-thin-48.png', tap: "Stars" },
  { icon: 'https://i.ibb.co/27rbZGy/iconmonstr-warning-lined-48.png', tap: "Clock/Time" },
  { icon: 'https://i.ibb.co/yyDnGFk/iconmonstr-generation-5-48.png', tap: "Animals" },
  { icon: 'https://i.ibb.co/jRcs2K0/iconmonstr-school-22-48.png', tap: "Education" },
  { icon: 'https://i.ibb.co/rvrR5Fm/iconmonstr-clothing-5-48.png', tap: "Warning" },
  { icon: 'https://i.ibb.co/jkgdn0S/iconmonstr-arrow-right-alt-filled-48.png', tap: "Transport" },
  { icon: 'https://i.ibb.co/Yy4v3HJ/iconmonstr-bar-chart-thin-48.png', tap: "Art" },
  { icon: 'https://i.ibb.co/YTWbxqN/iconmonstr-code-thin-48.png', tap: "Nature" },
  { icon: 'https://i.ibb.co/7QXx90t/iconmonstr-connection-8-48.png', tap: "Shopping" },
  { icon: 'https://i.ibb.co/f9D935b/iconmonstr-flash-thin-48.png', tap: "Home" },
  { icon: 'https://i.ibb.co/bWyTW3z/iconmonstr-customer-9-48.png', tap: "Entertainment" },
  { icon: 'https://i.ibb.co/KKfBhQr/iconmonstr-layout-lined-48.png', tap: "Chat" },
  { icon: 'https://i.ibb.co/KKfBhQr/iconmonstr-layout-lined-48.png', tap: "Message" },
  { icon: 'https://i.ibb.co/KKfBhQr/iconmonstr-layout-lined-48.png', tap: "Arrow" },
  { icon: 'https://i.ibb.co/KKfBhQr/iconmonstr-layout-lined-48.png', tap: "Health" },
  { icon: 'https://i.ibb.co/WWNXhjL/iconmonstr-tablet-6-48.png', tap: "Rating and Feedback" },
  { icon: 'https://i.ibb.co/Y7hnxhT/iconmonstr-cricket-1-48.png', tap: "Calendar" },
  { icon: 'https://i.ibb.co/Y7hnxhT/iconmonstr-cricket-1-48.png', tap: "Gift" },
  { icon: 'https://i.ibb.co/Y7hnxhT/iconmonstr-cricket-1-48.png', tap: "Presentation" },
  { icon: 'https://i.ibb.co/vPyt1Xv/iconmonstr-building-45-48-2.png', tap: "Location" },

];

export const imgsCate = [
  { name: "IT/Marketing teams" },
  { name: "construction" },
  { name: "Restaurant" },
  { name: "Real estate agency" },
  { name: "Beauty saloon" },
  { name: "coffee house" },
  { name: "Trucking" },
  { name: "Grocery" },
  { name: "Auto repair shop" },
  { name: "Shipping" },
  { name: "Workshop" },
  { name: "Healthcare" },
  { name: "Education" },
  { name: "Portraits" },
  { name: "Travel" },
  { name: "Sport" },
  { name: "Management" },
  { name: "Consulting" },
  { name: "Entertainment" },
  { name: "Interior Design" },
  { name: "Bakery" },
  { name: "Event planning/Wedding" },
  { name: "Home services" },
]


export const ImageCSettings = () => {
  const [iconsSquare, setIconsSquare] = useState(false)
  const [selectedIcon, setSelectedIcon] = useState(null);
  const [images, setImages] = useState(imagesArr)
  const [imgsCategory, setImgsCategory] = useState(imgsCate)

  const {
    node,
    actions: { setProp }
  } = useNode((node) => ({
    props: node.data.props,
    node: node,
  }));

  const [text, setText] = useState(false)

  const toggleIsText = () => {
    setProp((prop) => {
      prop.convertToTxt = !prop.convertToTxt;
    })
    setText((prevText) => !prevText);
  };



  const [activeTab, setActiveTab] = useState("Style Manager");

  const handleIconImageSelect = (selectedUrl) => {
    if (Array.isArray(selectedUrl) && selectedUrl.length) {
      setSelectedIcon(selectedUrl[0]);
    } else {
      setSelectedIcon(selectedUrl)
    }
    setIconsSquare(false);
  };

  return (
    <div className='flex flex-col component-settings' style={{height:'100%'}}>
      <ToolbarItem
        full={true}
        type={null}
      />
      <div className='w-[100%]'>
        <TabOption activeTab={activeTab} setActiveTab={setActiveTab} />
      </div>

      <div style={{ overflowY: 'scroll', scrollbarWidth: 'none', overflowX: 'hidden', }}>
        {activeTab === "Advanced" ? (
          <>

            <ToolbarSection


              title="Navigation"
            >
              <ToolbarItem
                full={true}
                propKey="href"
                type="select"
                label="href"
              />
            </ToolbarSection>
          </>
        ) : null}

        {activeTab === "Content" ? (
          <>

            <div className='pt-[2vmin]'>
              <ToolbarItem
                full={true}
                optionsDrop={['fill', 'contain', 'cover', 'none']}
                propKey="fit"
                type="select-drop"
                label="Object Fit"
              />
            </div>

            <ToolbarSection title="Source" >
              <ToolbarItem
                selectedIcon={selectedIcon}
                IconsSquare={iconsSquare}
                setIconsSquare={setIconsSquare}
                full={true}
                propKey="src"
                type="src"
                placeholder='Enter image URL'
              />

            </ToolbarSection>



          </>

        ) : null}

        {activeTab === "Style Manager" ? (
          <div className='w-[100%] h-[100%] flex flex-col'>
            {iconsSquare && (
              <SelectionBox
                isImages={true}
                pozition='absolute top-0 right-0'
                images={images}
                setImgsCategory={setImgsCategory}
                imgsCategory={imgsCategory}
                category={category}
                onSelect={handleIconImageSelect}
                iconsSquare={iconsSquare}
                setIconsSquare={setIconsSquare}
                setImages={setImages}
              />
            )}
            <Toggle toggleIsImg={toggleIsText} on={text} first={'Text'} second={'Image'} />



            <ToolbarSection

              title="Appearance"
              props={['background', 'color']}
              summary={({ background, color }) => {
                return (
                  <div className="flex flex-row-reverse">
                    <div
                      style={{
                        background:
                          background && `rgba(${Object.values(background)})`,
                      }}
                      className="shadow-md flex-end w-[6%] h-[6%] text-center flex items-center rounded-[0.25Vmin] bg-black"
                    >
                      <p
                        style={{
                          color: color && `rgba(${Object.values(color)})`,
                        }}
                        className="text-white w-[100%] text-center"
                      >
                        T
                      </p>
                    </div>
                  </div>
                );
              }}
            >
              <ToolbarItem
                full={true}
                propKey="background"
                type="bg"
                label="Background"
              />
            </ToolbarSection>

            <ToolbarSection

              title="Size"
              props={['width', 'height', 'minWidth', 'maxWidth']}
              summary={({ width, height, minWidth, maxWidth }) => {
                return `Width: ${width || ''}, Height: ${height || ''}, Min Width: ${minWidth || ''}, Max Width: ${maxWidth || ''}`;
              }}
            >
              <ToolbarItem
                full={true}
                propKey="width"
                type="slider"
                unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                label="Width"
              />
              <ToolbarItem
                full={true}
                propKey="height"
                type="slider"
                unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                label="Height"
              />
              <ToolbarItem
                full={true}
                propKey="minWidth"
                type="slider"
                unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                label="Min-Width"
              />
              <ToolbarItem
                full={true}
                propKey="maxWidth"
                type="slider"
                unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                label="Max-Width"
              />
            </ToolbarSection>


            <ToolbarSection

              title="Border"
              props={['border', 'fit']}
              summary={({ border, fit }) => {
                return `${border}px` `${fit}`;
              }}
            >

              <ToolbarItem
                full={true}
                propKey="borderWidth"
                type="slider"
                unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                label="Border Width"
              />

              <ToolbarItem
                full={true}
                propKey="borderColor"
                type="bg"
                label="Border Color"
              />

              <ToolbarItem
                full={true}
                propKey="borderRadius"
                type="slider"
                unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                label="Rounded"
              />
            </ToolbarSection>


            <ToolbarSection

              title="margin"
              props={['margin']}
              summary={({ }) => {
                return;
              }}
            >
              <ToolbarItem propKey="marginTop" type="slider"
                unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                label="Top" />
              <ToolbarItem propKey="marginRight" type="slider"
                unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                label="Right" />
              <ToolbarItem propKey="marginBottom" type="slider"
                unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                label="Bottom" />
              <ToolbarItem propKey="marginLeft" type="slider"
                unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                label="Left" />
            </ToolbarSection>


            <ToolbarSection

              title="Padding"
              props={['padding']}
              summary={({ }) => {
                return;
              }}
            >
              <ToolbarItem propKey="paddingTop" type="slider"
                unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                label="Top" />
              <ToolbarItem propKey="paddingRight" type="slider"
                unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                label="Right" />
              <ToolbarItem propKey="paddingBottom" type="slider"
                unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                label="Bottom" />
              <ToolbarItem propKey="paddingLeft" type="slider"
                unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                label="Left" />
            </ToolbarSection>
          </div>

        ) : null}
      </div>
    </div>
  );
};
