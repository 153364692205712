/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, { useState } from 'react';
import { ToolbarItem, ToolbarSection } from '../../editor/Toolbar'
import { TabOption } from '../../editor/Viewport/Sidebar/TabOption';
import { useEditor, useNode } from '@craftjs/core';

export const ParentSettings = () => {
  const { nodes, actions: { setProp } } = useEditor((state, query) => ({ nodes: state.nodes }));
  const {
    options,
    node
  } = useNode((node) => ({
    options: node.data.props,
    node: node
  }));

  const [activeState, setActiveState] = useState("Default")
  const [stateClicked, setStateClicked] = useState(false);

  const handleStateChange = (newState) => {
    setActiveState(newState)
    setProp(node.id, (props) => {
      props['actionState'] = newState
    })
    console.log(options['actionState'])
    setStateClicked(false);
  };


  const [activeTab, setActiveTab] = useState("Style Manager");



  return (
    <div className='flex flex-col component-settings relative' style={{ height: '100%' }}>
      <ToolbarItem full={true} type={null} />

      <div className='w-full'>
        <TabOption activeTab={activeTab} setActiveTab={setActiveTab} />
      </div>
      <div style={{ overflowY: 'scroll', scrollbarWidth: 'none', overflowX: 'hidden', height: '100%' }}>
        <div className='py-[1vmin]'>
          <div className='h-full w-full '>
            {stateClicked && (
              <div
                className={`w-[fit-content] h-[fit-content] bg-[transparent] mx-auto text-[#989191] flex flex-row justify-between`}
                style={{ opacity: stateClicked ? '1' : '0' }}
              >
                <div onClick={() => handleStateChange('Default')} className={`rounded-[.5vmin] border-[#e9e9e930] cursor-pointer shadow-sm p-[1vmin] text-[2.2vmin] ${options['actionState'] === 'Default' ? 'bg-[#0867fc30]' : 'bg-[#fff]'}`}>Default</div>
                <div onClick={() => handleStateChange('Hover')} className={`rounded-[.5vmin] border-[#e9e9e930] mx-[1vmin] cursor-pointer shadow-sm p-[1vmin] text-[2.2vmin] ${options['actionState'] === 'Hover' ? 'bg-[#0867fc30]' : 'bg-[#fff]'}`}>Hover</div>
                <div onClick={() => handleStateChange('Scroll')} className={`rounded-[.5vmin] border-[#e9e9e930] cursor-pointer shadow-sm p-[1vmin] text-[2.2vmin] ${options['actionState'] === 'Scroll' ? 'bg-[#0867fc30]' : 'bg-[#fff]'}`}>Scroll</div>
              </div>
            )}

            {!stateClicked &&
              <div
                style={{ justifyContent: 'center', alignContent: 'center' }}
                className={`rounded-[.5vmin] flex border-[#e9e9e930] text-[#0867FC] font-bold cursor-pointer shadow-sm p-[1vmin] text-[2.2vmin] ${options['actionState'] === activeState ? 'bg-[#0867fc30]' : 'bg-[#fff]'}`}
                onClick={() => setStateClicked(!stateClicked)}>
                Current State : {options['actionState']}
              </div>
            }
          </div>
        </div>

        {activeTab === "Content" ? (
          <>
            <ToolbarSection expanded={true ? true : false} title="Flex layout">
              <ToolbarItem full={true} propKey="flex" optionsDrop={['flex', 'block', 'none', 'inline-block', 'inline-flex']} type="select-drop" label="Display" />
              <ToolbarItem full={true} propKey="flexDirection" optionsDrop={['row', 'column']} type="select-drop" label="Direction" />
              <ToolbarItem full={true} propKey="flexWrap" optionsDrop={['wrap', 'noWrap']} type="select-drop" label="Flex Wrap" />
              <ToolbarItem full={true} propKey="justifyContent" optionsDrop={['start', 'center', 'end', 'space-between', 'space-around', 'flex-start', 'flex-end']} type="select-drop" label="Justify content" />
              <ToolbarItem full={true} propKey="alignItems" optionsDrop={['start', 'center', 'end', 'stretch', 'flex-start', 'flex-end', 'self-start', 'self-end']} type="select-drop" label="Align Items" />
              <ToolbarItem full={true} propKey="alignContent" optionsDrop={['start', 'center', 'end', 'space-between', 'space-around', 'flex-start', 'flex-end']} type="select-drop" label="Align Content" />
            </ToolbarSection>
          </>
        ) : null}

        {activeTab === "Advanced" ? (
          <>
            <ToolbarSection title="Transform" expanded={true ? true : false}>
              <ToolbarItem full={true} selectBoxes={['rotateXYZ', 'translate', 'scale', 'skew']} propKey="transformStyle" propKey2="positionsRotate" propKey3="positionsTranslate" propKey4="positionsScale" propKey5="positionsRotateXYZ" propKey6="positionsSkew" propKey7="positionsOrigin" type="select-box" label="Transform Style" action={true} />
            </ToolbarSection>

            <ToolbarSection expanded={true ? true : false} title="Shapes">
              <ToolbarItem full={true} optionsDrop={['Curve', 'Triangle', 'Wave', 'Waves Opacity', 'Triangle Asymmetrical', 'Tilt', 'Arrow', 'Split', 'Book']} propKey="shapeStyle" type="Switch" label="Shape Style" />
              <ToolbarItem full={true} optionsDrop={['Top', 'Bottom']} propKey="shapes" type="Switch" label="Shapes Position" />
              <ToolbarItem full={true} propKey="shapeHeight" type="Switch" number label="Shape Height" />
              <ToolbarItem full={true} propKey="shapeWidth" type="Switch" number label="Shape Width" />
              <ToolbarItem full={true} optionsDrop={['Underneath Section', 'On Top The Section']} propKey="shapeArrangement" type="Switch" label="Shape Arrangement" />
              <ToolbarItem full={true} propKey="shapeColor" type="Switch" color label="Shape Color" />
            </ToolbarSection>

            <ToolbarSection expanded={true ? true : false} title="Transition">
              <ToolbarItem full={true} optionsDrop={['all', 'transform', 'box-shadow', 'color', 'background-color', 'border-color', 'fill', 'filter', '']} propKey="transitionFor" type="select-drop" label="Transition Property" />
              <ToolbarItem full={true} propKey="transitionDuration" type="numSlider" label="Transition Duration" />
              <ToolbarItem full={true} optionsDrop={['ease-linear', 'ease-in', 'ease-out', 'ease-in-out']} propKey="transitionTime" type="select-drop" label="Transition Timing" />
              <ToolbarItem full={true} propKey="transitionDelay" type="numSlider" label="Transition Delay" />
            </ToolbarSection>
          </>
        ) : null}

        {activeTab === "Style Manager" ? (
          <>
            <ToolbarSection expanded={true ? true : false} title="Background">
              <ToolbarItem full={true} propKey="background" type="bg" action={true}   />
            </ToolbarSection>

            <ToolbarSection expanded={true ? true : false} title="Color">
              <ToolbarItem full={true} propKey="color" type="color" action={true} />
            </ToolbarSection>

            <ToolbarSection
              expanded={true ? true : false}
              title="Shadow"
              props={['fontSize', 'fontWeight', 'textAlign']}
            >
              <ToolbarItem
                full={true}
                propKey="shadowY"
                type="slider"
                unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                label="Y offset"
              />

              <ToolbarItem
                full={true}
                propKey="shadowX"
                type="slider"
                unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                label="X offset"
              />

              <ToolbarItem
                full={true}
                propKey="shadowBlur"
                type="slider"
                unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                label="Blur"
              />

              <ToolbarItem full={true} label='Shadow Color' propKey="shadowColor" type="color" />


            </ToolbarSection>

            <ToolbarSection expanded={true ? true : false} title="Size">
              <ToolbarItem full={true} propKey="width" type="slider" unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']} label="Width" action={true} />
              <ToolbarItem propKey="height" type="slider" unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']} label="Height" action={true} />
              <ToolbarItem propKey="minWidth" type="slider" unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']} label="Min Width" action={true} />
              <ToolbarItem propKey="maxWidth" type="slider" unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']} label="Max Width" action={true} />
            </ToolbarSection>

            <ToolbarSection expanded={true ? true : false} title="Margin">
              <ToolbarItem propKey="marginTop" type="slider" unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']} label="Top" />
              <ToolbarItem propKey="marginRight" type="slider" unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']} label="Right" />
              <ToolbarItem propKey="marginBottom" type="slider" unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']} label="Bottom" />
              <ToolbarItem propKey="marginLeft" type="slider" unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']} label="Left" />
            </ToolbarSection>

            <ToolbarSection expanded={true ? true : false} title="Padding">
              <ToolbarItem propKey="paddingTop" type="slider" unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']} label="Top" />
              <ToolbarItem propKey="paddingRight" type="slider" unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']} label="Right" />
              <ToolbarItem propKey="paddingBottom" type="slider" unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']} label="Bottom" />
              <ToolbarItem propKey="paddingLeft" type="slider" unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']} label="Left" />
            </ToolbarSection>

            <ToolbarSection expanded={true ? true : false} title="Extra">
              <ToolbarItem full={true} propKey="cursor" optionsDrop={['help', 'wait', 'crosshair', 'not-allowed', 'zoom-in', 'grab', 'pointer']} type="select-drop" label="Cursor" />
              <ToolbarItem full={true} propKey="filterOptions" optionsDrop={['Grayscale', 'Blur', 'Brightness', 'Contrast', 'Saturate', 'Invert', 'Opacity', 'Sepia']} type="select-drop" label="Filter option" />
              <ToolbarItem full={true} propKey="filterValue" type="slider" action={true} unitOptions={['px', '%', 'deg', 'em', 'in', 'rad']} label="Filter Value" />
              <ToolbarItem full={true} propKey="radius" type="slider" action={true} unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']} label="Radius" />
            </ToolbarSection>
          </>
        ) : null}
      </div>
    </div>
  )

}