 /****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/

import { useRef, useState } from 'react';
import { withStyles } from '@mui/styles';
import { Slider as Small } from '@mui/material';
import { ToolbarTextInput } from '../..';
import { ChromePicker } from 'react-color';


const SliderStyled = withStyles({
    root: {
        margin: 'auto auto',
        width: '33%',
        color: '#3880ff',
        height: '3vmin',
        padding: '5% 0',
    },
    thumb: {
        height: '2vmin',
        width: '2vmin',
        backgroundColor: '#0867FC',
    },

    active: {},
    valueLabel: {
        left: 'calc(-50% + 1.1%)',
        top: '-22%',
        '& *': {
            background: 'transparent',
            color: '#000',
        },
    },

    rail: {
        height: '2%',
        opselectedIconacity: 0.5,
        backgroundColor: '#bfbfbf',
    },
    mark: {
        backgroundColor: '#bfbfbf',
        height: '8%',
        width: '10%',
        marginTop: '-3%',
    },
    markActive: {
        opacity: 1,
        backgroundColor: 'currentColor',
    },
})(Small);

export const ShapesPositions = ({
    options,
    shapeTop,
    shapeRight,
    shapeLeft,
    shapeBottom,
    value,
    number,
    style,
    propKey,
    setProp,
    optionsDrop,
    node,
    isDarkMode,
    onChange,
    props,
    type,
    color,
}) => {

    const [selected, setSelected] = useState(value);


    const handleChangeColor = (color) => {
            if (shapePos === 'Top') {
                setProp(node.id, (props) => {
                    props['shapes']['Top'][propKey] = color;
                    return { ...props };
                });
            } else if (shapePos === 'Bottom') {
              setProp(node.id, (props) => {
                  props['shapes']['Bottom'][propKey] = color;
                  return { ...props };
              });
          } else if (shapePos === 'Left') {
            setProp(node.id, (props) => {
                props['shapes']['Left'][propKey] = color;
                return { ...props };
            });
        } else if (shapePos === 'Right') {
          setProp(node.id, (props) => {
              props['shapes']['Right'][propKey] = color;
              return { ...props };
          });
        }
            console.log(shapeTop)

    }

    let shapePos = options['shapePos']
    return (
        <div className={`${isDarkMode ? 'text-[#fff]' : 'text-[#000]'} w-full flex flex-column mb-[2vmin]`}>
              <div style={{ fontSize: '2vmin', fontWeight: '500', fontFamily: 'Inter', paddingBottom: '.5Vmin', marginBottom:'.5vmin' }}>{props?.label}</div>
              {color && <div style={{width:"100%", display:"flex", justifyContent:"center", alignItems:"center", margin:"2Vmin 0"}}>
                    <ChromePicker
                    style={{color:"black"}}
                    header={true}
                    color={selected}
                    onChangeComplete={(color) => {
                        setSelected(color.rgb)
                        handleChangeColor(color.rgb)
                    }}
                    />
                    </div>
                }
              {number && <div className='w-auto flex flex-row justify-between align-items-center'>
                <div className='text-[2vmin] text-center flex-1 mx-[1vmin]'>
                {
                shapePos === 'Top' ? parseInt(shapeTop || 0) : shapePos === 'Bottom' ? parseInt(shapeBottom || 0) : shapePos === 'Right' ? parseInt(shapeRight || 0) : shapePos === 'Left' ? parseInt(shapeLeft || 0) : value
                }
                </div>
              <div className='mx-auto flex-1 flex justify-center'>
              <SliderStyled
              style={{minWidth:'33vmin', width:'100%'}}
              className='w-full'
                  min={0}
                  max={500}
                  value={
                    shapePos === 'Top' ? parseInt(shapeTop) : shapePos === 'Bottom' ? parseInt(shapeBottom) : shapePos === 'Right' ? parseInt(shapeRight) : shapePos === 'Left' ? parseInt(shapeLeft) : 0
                  }
                  onChange={(e) => {
                    let newValue = e.target.value;
                    if (shapePos === 'Top') {
                        setProp(node.id, (props) => {
                            props['shapes']['Top'][propKey] = newValue;
                            return { ...props };
                        });
                    } else if (shapePos === 'Bottom') {
                      setProp(node.id, (props) => {
                          props['shapes']['Bottom'][propKey] = newValue;
                          return { ...props };
                      });
                  } else if (shapePos === 'Left') {
                    setProp(node.id, (props) => {
                        props['shapes']['Left'][propKey] = newValue;
                        return { ...props };
                    });
                } else if (shapePos === 'Right') {
                  setProp(node.id, (props) => {
                      props['shapes']['Right'][propKey] = newValue;
                      return { ...props };
                  });
              }
                    console.log(shapeTop)

                }}
                />
              </div>
          </div>}
              {style && <div>
                <select
                style={{
                  color: isDarkMode ? '#fff' : '#000',
                  fontFamily: 'Inter',
                  fontWeight: '500',
                  padding: '.5Vmin 1Vmin',
                  fontSize: '2vmin',
                  border: isDarkMode ? '.25vmin solid #e9e9e930' : '.25Vmin solid #E9E9EA',
                  borderRadius: '2.5Vmin',
                  backgroundColor: 'transparent',
                  margin: '.5vmin 0',
                  lineHeight:'100%',
                  transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
                }}

                value={
                    shapePos === 'Top'
                      ? options['shapes']['Top'][propKey]
                      : shapePos === 'Bottom'
                      ? options['shapes']['Bottom'][propKey]
                      : shapePos === 'Left'
                      ? options['shapes']['Left'][propKey]
                      : shapePos === 'Right'
                      ? options['shapes']['Right'][propKey]
                      : ''
                  }

                onChange={(e) => {
                    let newValue = e.target.value;
                    if (shapePos === 'Top') {
                        setProp(node.id, (props) => {
                            props['shapes']['Top'][propKey] = newValue;
                            return { ...props };
                        });
                    } else if (shapePos === 'Bottom') {
                      setProp(node.id, (props) => {
                          props['shapes']['Bottom'][propKey] = newValue;
                          return { ...props };
                      });
                  } else if (shapePos === 'Left') {
                    setProp(node.id, (props) => {
                        props['shapes']['Left'][propKey] = newValue;
                        return { ...props };
                    });
                } else if (shapePos === 'Right') {
                  setProp(node.id, (props) => {
                      props['shapes']['Right'][propKey] = newValue;
                      return { ...props };
                  });
              }
                    console.log(shapeTop)

                }}
              >
                <option value="">default</option>
                {optionsDrop.map((option) => (
                  <option
                    style={{
                      fontFamily: 'Inter',
                      fontWeight: '400',
                      fontSize: '2.2Vmin',
                      color: 'black',
                    }}
                    key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
                </div>}
            <div className='flex flex-row w-full'>
              {!style && !number && !color && optionsDrop.map((position) => (
                <div key={position} className={`flex-1 text-center p-[1vmin] text-[2vmin] ${shapePos === position? 'bg-[#0867FC4D]' : ''} hover:bg-[#0867FC4D] cursor-pointer rounded-[.5vmin] shadow-sm mx-[1vmin]`} 
                onClick={() => setProp(node.id, (props) => {
                   props['shapePos'] = position
                }
                )}
                >{position}</div>
              ))}
            </div>
        </div>
    )
}