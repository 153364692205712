/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, { useEffect, useRef, useState } from 'react';
import { Element, useEditor, useNode } from '@craftjs/core';
import { NewEcommerceSettings } from './newEcommerceSetting'
import { Text } from '../../basic/Text';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import { ImageC } from '../../basic/Image';
import { Button } from '../../basic/Button';
import { Parent } from '../../Parent/index.js';
import { Wrapper } from '../../wrapper/index.js';
import { BounceLoader } from 'react-spinners';



export const Ecommerce_2 = ({ selected }) => {

    const {
        actions: { setProp },
        connectors: {
            connect
        }
    } = useNode();


    const {
        enabled,
    } = useEditor((state, query) => ({
        enabled: state.options.enabled,

    }));


    const styles = [
        <Element canvas is={Parent} id='parentId100' className="w-[100%] h-auto bg-[#F7F7F9] py-10" >
            <Element canvas is={Parent} id='parentId200' className="w-[100%] h-auto flex justify-center items-center" row={true} >
                <Element is={Parent} canvas id='random-id-401' className='max-w-[1300px]'>
                    <Element is={Parent} canvas style={{ display: 'inline-block', fontWeight: 'bold', fontFamily: 'Poppins', color: '#000000' }} className={`w-full h-full my-10 px-4 text-[46px]`} id='random-id-1'  >
                        <Text text='Products' />
                    </Element>
                    <Element is={Parent} canvas id='random-id-402' className='w-[100%] h-full flex flex-row flex-wrap justify-center px-4'>
                        <Element is={Parent} canvas id='random-id-403' className='max-w-[386px] min-w-[277px] h-full mx-auto my-auto' style={{ background: '#FFFFFF', borderRadius: '30px', padding: '20px', flex: '28%' }}>
                            <Element is={Parent} canvas id='random-id-404' className='w-[100%] h-full flex flex-col'>
                                <Element is={Parent} canvas id='random-id-405' className='w-full h-full flex flex-row justify-between '>
                                    <Element is={Parent} canvas className='w-[50%] ' style={{ display: 'inline-block', height: '100%', width: '100%' }} id='random-id-100'  >
                                        <ImageC className='w-[100%] h-[100%] object-cover' src='https://i.ibb.co/x6dD5Bm/Rectangle-2217.png' />
                                    </Element>
                                    <div className='w-[50%] mx-auto my-auto text-center' style={{ fontSize: '46px', fontWeight: 'bold', fontFamily: 'Poppins', color: '#2479FD' }}>$566</div>
                                </Element>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '24px', fontWeight: 'bold', fontFamily: 'Poppins', color: '#000000' }} className='my-4' id='random-id-2'  >
                                    <Text text='Lorem Ipsum' />
                                </Element>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '16px', fontWeight: '400px', fontFamily: 'Poppins', color: '#000000' }} id='random-id-3'  >
                                    <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.' />
                                </Element>
                                <Element is={Parent} canvas id='random-id-200' className='mx-auto mt-4'>
                                    <Button classn='block' class='flex w-[280px] h-[60px] cursor-pointer bg-[#FFC92E] hover:bg-[#FFF5D7] border-1 border-[#FFC92E] hover:border-[#FFC92E] text-[#000000]' style={{ borderRadius: '14px' }}>
                                        <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Poppins', }} className='mx-auto my-auto' id='random-id-4'  >
                                            <Text text='Get Started' />
                                        </Element>
                                    </Button>
                                    {/* <div className='my-auto mx-2'>
                            <ArrowCircleRightOutlinedIcon sx={{color:'#000000', fontSize:'28px'}}/>
                            </div> */}
                                </Element>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-407' className='max-w-[386px] min-w-[277px] h-full mx-4 my-4' style={{ background: '#FFFFFF', borderRadius: '30px', padding: '20px', flex: '28%' }}>
                            <Element is={Parent} canvas id='random-id-408' className='w-[100%] h-full flex flex-col'>
                                <Element is={Parent} canvas id='random-id-409' className='w-full h-full flex flex-row justify-between '>
                                    <Element is={Parent} canvas className='w-[50%] ' style={{ display: 'inline-block', height: '100%', width: '100%' }} id='random-id-101'  >
                                        <ImageC className='w-[100%] h-[100%] object-cover' src='https://i.ibb.co/x6dD5Bm/Rectangle-2217.png' />
                                    </Element>
                                    <div className='w-[50%] mx-auto my-auto text-center' style={{ fontSize: '46px', fontWeight: 'bold', fontFamily: 'Poppins', color: '#2479FD' }}>$566</div>
                                </Element>
                                <Element is={Parent} canvas className='my-4' style={{ display: 'inline-block', fontSize: '24px', fontWeight: 'bold', fontFamily: 'Poppins', color: '#000000' }} id='random-id-5'  >
                                    <Text text='Lorem Ipsum' />
                                </Element>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '16px', fontWeight: '400px', fontFamily: 'Poppins', color: '#000000' }} id='random-id-6'  >
                                    <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.' />
                                </Element>
                                <Element is={Parent} canvas id='random-id-201' className='mx-auto mt-4'>
                                    <Button classn='block' class='flex w-[280px] h-[60px] cursor-pointer bg-[#FFC92E] hover:bg-[#FFF5D7] border-1 border-[#FFC92E] hover:border-[#FFC92E] text-[#000000]' style={{ borderRadius: '14px' }}>
                                        <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Poppins', }} className='mx-auto my-auto' id='random-id-7'  >
                                            <Text text='Get Started' />
                                        </Element>
                                    </Button>
                                    {/* <div className='my-auto mx-2'>
                            <ArrowCircleRightOutlinedIcon sx={{color:'#000000', fontSize:'28px'}}/>
                            </div> */}
                                </Element>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-411' className='max-w-[386px] min-w-[277px] h-full mx-auto my-auto' style={{ background: '#FFFFFF', borderRadius: '30px', padding: '20px', flex: '28%' }}>
                            <Element is={Parent} canvas id='random-id-412' className='w-[100%] h-full flex flex-col'>
                                <Element is={Parent} canvas id='random-id-413' className='w-full h-full flex flex-row justify-between '>
                                    <Element is={Parent} canvas className='w-[50%] ' style={{ display: 'inline-block', height: '100%', width: '100%' }} id='random-id-102'  >
                                        <ImageC className='w-[100%] h-[100%] object-cover' src='https://i.ibb.co/x6dD5Bm/Rectangle-2217.png' />
                                    </Element>
                                    <div className='w-[50%] mx-auto my-auto text-center' style={{ fontSize: '46px', fontWeight: 'bold', fontFamily: 'Poppins', color: '#2479FD' }}>$566</div>
                                </Element>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '24px', fontWeight: 'bold', fontFamily: 'Poppins', color: '#000000' }} className='my-4' id='random-id-8'  >
                                    <Text text='Lorem Ipsum' />
                                </Element>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '16px', fontWeight: '400px', fontFamily: 'Poppins', color: '#000000' }} id='random-id-9'  >
                                    <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.' />
                                </Element>
                                <Element is={Parent} canvas id='random-id-202' className='mx-auto mt-4'>
                                    <Button classn='block' class='flex w-[280px] h-[60px] cursor-pointer bg-[#FFC92E] hover:bg-[#FFF5D7] border-1 border-[#FFC92E] hover:border-[#FFC92E] text-[#000000]' style={{ borderRadius: '14px' }}>
                                        <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Poppins', }} className='mx-auto my-auto' id='random-id-10'  >
                                            <Text text='Get Started' />
                                        </Element>
                                    </Button>
                                    {/* <div className='my-auto mx-2'>
                            <ArrowCircleRightOutlinedIcon sx={{color:'#000000', fontSize:'28px'}}/>
                            </div> */}
                                </Element>
                            </Element>
                        </Element>
                    </Element>
                </Element></Element>
        </Element>
        ,
        <Element canvas is={Parent} id='parentId300' className="w-[100%] h-auto bg-[#FDFDF3] py-10" >
            <Element canvas is={Parent} id='parentId400' className="w-[100%] h-auto flex justify-center items-center" row={true} >
                <Element is={Parent} canvas id='random-id-416' className='max-w-[1300px]'>
                    <Element is={Parent} canvas style={{ display: 'inline-block', fontWeight: 'bold', fontFamily: 'Prompt', color: '#000000' }} className={`w-full h-full my-10 px-4 text-[46px]`} id='random-id-11'  >
                        <Text text='Products' />
                    </Element>
                    <Element is={Parent} canvas id='random-id-417' className='w-[100%] h-full flex flex-row flex-wrap justify-center px-4'>
                        <Element is={Parent} canvas id='random-id-418' className=' min-w-[277px] max-w-[386px] h-full mx-auto my-auto' style={{ background: '#FFFFFF', borderRadius: '30px', padding: '20px', flex: '28%' }}>
                            <Element is={Parent} canvas id='random-id-419' className='w-[100%] h-full flex flex-col'>
                                <Element is={Parent} canvas id='random-id-420' className='w-full h-full flex flex-row justify-between '>
                                    <Element is={Parent} canvas className='w-[50%] ' style={{ display: 'inline-block', height: '100%', width: '100%' }} id='random-id-103'  >
                                        <ImageC className='w-[100%] h-[100%] object-cover' src='https://i.ibb.co/FhRG8tT/Rectangle-2217.png' />
                                    </Element>
                                    <div className='w-[50%] mx-auto my-auto text-center' style={{ fontSize: '46px', fontWeight: 'bold', fontFamily: 'Prompt', color: '#FF0060' }}>$566</div>
                                </Element>

                                <Element is={Parent} canvas className='my-4' style={{ display: 'inline-block', fontSize: '24px', fontWeight: 'bold', fontFamily: 'Prompt', color: '#000000' }} id='random-id-12'  >
                                    <Text text='Lorem Ipsum' />
                                </Element>

                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '16px', fontWeight: '400px', fontFamily: 'Prompt', color: '#000000' }} id='random-id-13'  >
                                    <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.' />
                                </Element>
                                <Element is={Parent} canvas id='random-id-203' className='mx-auto mt-4'>
                                    <Button classn='block' class='w-[280px] h-[60px] cursor-pointer bg-[#FF0060] hover:bg-[#C20049] flex text-[#FFF]' style={{ borderRadius: '30px' }}>
                                        <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Prompt', }} className='my-auto mx-auto' id='random-id-14'  >
                                            <Text text='Get Started' />
                                        </Element>
                                        {/* <div className='mx-2 my-auto'>
                                    <ArrowCircleRightOutlinedIcon sx={{color:'#FFFFFF', fontSize:'28px'}}/>
                                </div> */}
                                    </Button>
                                </Element>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-422' className=' min-w-[277px] max-w-[386px] h-full mx-4 my-4' style={{ background: '#FFFFFF', borderRadius: '30px', padding: '20px', flex: '28%' }}>
                            <Element is={Parent} canvas id='random-id-423' className='w-[100%] h-full flex flex-col'>
                                <Element is={Parent} canvas id='random-id-424' className='w-full h-full flex flex-row justify-between '>
                                    <Element is={Parent} canvas className='w-[50%] ' style={{ display: 'inline-block', height: '100%', width: '100%' }} id='random-id-104'  >
                                        <ImageC className='w-[100%] h-[100%] object-cover' src='https://i.ibb.co/FhRG8tT/Rectangle-2217.png' />
                                    </Element>
                                    <div className='w-[50%] mx-auto my-auto text-center' style={{ fontSize: '46px', fontWeight: 'bold', fontFamily: 'Prompt', color: '#FF0060' }}>$566</div>
                                </Element>

                                <Element is={Parent} canvas className='my-4' style={{ display: 'inline-block', fontSize: '24px', fontWeight: 'bold', fontFamily: 'Prompt', color: '#000000' }} id='random-id-15'  >
                                    <Text text='Lorem Ipsum' />
                                </Element>

                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '16px', fontWeight: '400px', fontFamily: 'Prompt', color: '#000000' }} id='random-id-16'  >
                                    <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.' />
                                </Element>
                                <Element is={Parent} canvas id='random-id-204' className='mx-auto mt-4'>
                                    <Button classn='block' class='w-[280px] h-[60px] cursor-pointer bg-[#FF0060] hover:bg-[#C20049] flex text-[#FFF]' style={{ borderRadius: '30px' }}>
                                        <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Prompt', }} className='my-auto mx-auto' id='random-id-17'  >
                                            <Text text='Get Started' />
                                        </Element>
                                        {/* <div className='mx-2 my-auto'>
                                    <ArrowCircleRightOutlinedIcon sx={{color:'#FFFFFF', fontSize:'28px'}}/>
                                </div> */}
                                    </Button>
                                </Element>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-425' className=' min-w-[277px] max-w-[386px] h-full mx-auto my-auto' style={{ background: '#FFFFFF', borderRadius: '30px', padding: '20px', flex: '28%' }}>
                            <Element is={Parent} canvas id='random-id-426' className='w-[100%] h-full flex flex-col'>
                                <Element is={Parent} canvas id='random-id-427' className='w-full h-full flex flex-row justify-between '>
                                    <Element is={Parent} canvas className='w-[50%] ' style={{ display: 'inline-block', height: '100%', width: '100%' }} id='random-id-105'  >
                                        <ImageC className='w-[100%] h-[100%] object-cover' src='https://i.ibb.co/FhRG8tT/Rectangle-2217.png' />
                                    </Element>
                                    <div className='w-[50%] mx-auto my-auto text-center' style={{ fontSize: '46px', fontWeight: 'bold', fontFamily: 'Prompt', color: '#FF0060' }}>$566</div>
                                </Element>
                                <Element is={Parent} canvas id='random-id-428' className='my-4' style={{ fontSize: '24px', fontWeight: 'bold', fontFamily: 'Prompt', color: '#000000' }}>
                                    <Element is={Parent} canvas style={{ display: 'inline-block' }} id='random-id-18'  >
                                        <Text text='Lorem Ipsum' />
                                    </Element>
                                </Element>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '16px', fontWeight: '400px', fontFamily: 'Prompt', color: '#000000' }} id='random-id-19'  >
                                    <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.' />
                                </Element>
                                <Element is={Parent} canvas id='random-id-205' className='mx-auto mt-4'>
                                    <Button classn='block' class='w-[280px] h-[60px] cursor-pointer bg-[#FF0060] hover:bg-[#C20049] flex text-[#FFF]' style={{ borderRadius: '30px' }}>
                                        <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Prompt', }} className='my-auto mx-auto' id='random-id-20'  >
                                            <Text text='Get Started' />
                                        </Element>
                                        {/* <div className='mx-2 my-auto'>
                                    <ArrowCircleRightOutlinedIcon sx={{color:'#FFFFFF', fontSize:'28px'}}/>
                                </div> */}
                                    </Button>
                                </Element>
                            </Element>
                        </Element>
                    </Element>
                </Element></Element>
        </Element>,
        <Element canvas is={Parent} id='parentId500' className="w-[100%] h-auto bg-[#FDF4F4] py-10" >
            <Element canvas is={Parent} id='parentId600' className="w-[100%] h-auto flex justify-center items-center" row={true} >
                <Element is={Parent} canvas id='random-id-430' className='max-w-[1300px]'>
                    <Element is={Parent} canvas style={{ display: 'inline-block', fontWeight: 'bold', fontFamily: 'Almarai', color: '#000000' }} className={`w-full h-full my-10 px-4 text-[46px]`} id='random-id-21'  >
                        <Text text='Products' />
                    </Element>
                    <Element is={Parent} canvas id='random-id-431' className='w-[100%] h-full flex flex-row flex-wrap justify-center'>
                        <Element is={Parent} canvas id='random-id-432' className='max-w-[386px] min-w-[277px] h-full mx-auto my-auto' style={{ background: '#FFFFFF', borderRadius: '6px 20px 6px 20px', padding: '20px', flex: '28%' }}>
                            <Element is={Parent} canvas id='random-id-433' className='w-[100%] h-full flex flex-col'>
                                <Element is={Parent} canvas id='random-id-434' className='w-full h-full flex flex-row justify-between'>

                                    <Element is={Parent} canvas className='w-[50%] ' style={{ display: 'inline-block', height: '100%', width: '100%' }} id='random-id-106'  >
                                        <ImageC className='w-[100%] h-[100%] object-cover' src='https://i.ibb.co/v16vGJm/Rectangle-2217.png' />
                                    </Element>

                                    <div className='w-[50%] mx-auto my-auto text-center' style={{ fontSize: '46px', fontWeight: 'bold', fontFamily: 'Almarai', color: '#F31559' }}>$566</div>
                                </Element>

                                <Element is={Parent} canvas className='my-4' style={{ display: 'inline-block', fontSize: '24px', fontWeight: 'bold', fontFamily: 'Almarai', color: '#000000' }} id='random-id-22'  >
                                    <Text text='Lorem Ipsum' />
                                </Element>

                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '16px', fontWeight: '400px', fontFamily: 'Almarai', color: '#000000', lineHeight: '25.6px' }} id='random-id-23'  >
                                    <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.' />
                                </Element>
                                <Element is={Parent} canvas id='random-id-206' className='mx-auto mt-4'>
                                    <Button classn='block' class='w-[280px] h-[60px] cursor-pointer bg-[#F31559] hover:bg-[#FF5B8D] flex text-[#FFF]' style={{ borderRadius: '14px' }}>
                                        <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Almarai', }} className='mx-auto my-auto' id='random-id-24'  >
                                            <Text text='Get Started' />
                                        </Element>
                                        {/* <div className='mx-2 my-auto'>
                                <ArrowCircleRightOutlinedIcon sx={{color:'#FFFFFF', fontSize:'28px'}}/>
                            </div> */}
                                    </Button>
                                </Element>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-436' className='max-w-[386px] min-w-[277px] h-full mx-4 my-4' style={{ background: '#FFFFFF', borderRadius: '20px 6px 20px 6px', padding: '20px', flex: '28%' }}>
                            <Element is={Parent} canvas id='random-id-437' className='w-[100%] h-full flex flex-col'>
                                <Element is={Parent} canvas id='random-id-438' className='w-full h-full flex flex-row justify-between'>

                                    <Element is={Parent} canvas className='w-[50%] ' style={{ display: 'inline-block', height: '100%', width: '100%' }} id='random-id-107'  >
                                        <ImageC className='w-[100%] h-[100%] object-cover' src='https://i.ibb.co/v16vGJm/Rectangle-2217.png' />
                                    </Element>

                                    <div className='w-[50%] mx-auto my-auto text-center' style={{ fontSize: '46px', fontWeight: 'bold', fontFamily: 'Almarai', color: '#F31559' }}>$566</div>
                                </Element>

                                <Element is={Parent} canvas className='my-4' style={{ display: 'inline-block', fontSize: '24px', fontWeight: 'bold', fontFamily: 'Almarai', color: '#000000' }} id='random-id-25'  >
                                    <Text text='Lorem Ipsum' />
                                </Element>

                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '16px', fontWeight: '400px', fontFamily: 'Almarai', color: '#000000', lineHeight: '25.6px' }} id='random-id-26'  >
                                    <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.' />
                                </Element>
                                <Element is={Parent} canvas id='random-id-207' className='mx-auto mt-4'>
                                    <Button classn='block' class='w-[280px] h-[60px] cursor-pointer bg-[#F31559] hover:bg-[#FF5B8D] flex text-[#FFF]' style={{ borderRadius: '14px' }}>
                                        <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Almarai', }} className='mx-auto my-auto' id='random-id-27'  >
                                            <Text text='Get Started' />
                                        </Element>
                                        {/* <div className='mx-2 my-auto'>
                                <ArrowCircleRightOutlinedIcon sx={{color:'#FFFFFF', fontSize:'28px'}}/>
                            </div> */}
                                    </Button>
                                </Element>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-440' className='max-w-[386px] min-w-[277px] h-full mx-auto my-auto' style={{ background: '#FFFFFF', borderRadius: '6px 20px 6px 20px', padding: '20px', flex: '28%' }}>
                            <Element is={Parent} canvas id='random-id-441' className='w-[100%] h-full flex flex-col'>
                                <Element is={Parent} canvas id='random-id-442' className='w-full h-full flex flex-row justify-between'>

                                    <Element is={Parent} canvas className='w-[50%] ' style={{ display: 'inline-block', height: '100%', width: '100%' }} id='random-id-108'  >
                                        <ImageC className='w-[100%] h-[100%] object-cover' src='https://i.ibb.co/v16vGJm/Rectangle-2217.png' />
                                    </Element>

                                    <div className='w-[50%] mx-auto my-auto text-center' style={{ fontSize: '46px', fontWeight: 'bold', fontFamily: 'Almarai', color: '#F31559' }}>$566</div>
                                </Element>

                                <Element is={Parent} canvas className='my-4' style={{ display: 'inline-block', fontSize: '24px', fontWeight: 'bold', fontFamily: 'Almarai', color: '#000000' }} id='random-id-28'  >
                                    <Text text='Lorem Ipsum' />
                                </Element>

                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '16px', fontWeight: '400px', fontFamily: 'Almarai', color: '#000000', lineHeight: '25.6px' }} id='random-id-29'  >
                                    <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.' />
                                </Element>
                                <Element is={Parent} canvas id='random-id-208' className='mx-auto mt-4'>
                                    <Button classn='block' class='w-[280px] h-[60px] cursor-pointer bg-[#F31559] hover:bg-[#FF5B8D] flex text-[#FFF]' style={{ borderRadius: '14px' }}>
                                        <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Almarai', }} className='mx-auto my-auto' id='random-id-30'  >
                                            <Text text='Get Started' />
                                        </Element>
                                        {/* <div className='mx-2 my-auto'>
                                <ArrowCircleRightOutlinedIcon sx={{color:'#FFFFFF', fontSize:'28px'}}/>
                            </div> */}
                                    </Button>
                                </Element>
                            </Element>
                        </Element>
                    </Element>
                </Element></Element>
        </Element>,
        <Element canvas is={Parent} id='parentId700' className="w-[100%] h-auto bg-[#F6F2FA] py-10" >
            <Element canvas is={Parent} id='parentId800' className="w-[100%] h-auto flex justify-center items-center" row={true} >
                <Element is={Parent} canvas id='random-id-445' className='max-w-[1300px]'>
                    <Element is={Parent} canvas style={{ display: 'inline-block', fontWeight: 'bold', fontFamily: 'Chivo', color: '#000000' }} className={`w-full h-full my-10 px-4 text-[46px]`} id='random-id-31'  >
                        <Text text='Products' />
                    </Element>
                    <Element is={Parent} canvas id='random-id-446' className='w-[100%] h-full flex flex-row flex-wrap justify-center'>
                        <Element is={Parent} canvas id='random-id-447' className='max-w-[386px] min-w-[277px] h-full mx-auto my-auto' style={{ background: '#FFFFFF', borderRadius: '30px', padding: '20px', flex: '28%' }}>
                            <Element is={Parent} canvas id='random-id-448' className='w-[100%] h-full flex flex-col'>
                                <Element is={Parent} canvas id='random-id-449' className='w-full h-full flex flex-row justify-between'>

                                    <Element is={Parent} canvas className='w-[50%] ' style={{ display: 'inline-block', height: '100%', width: '100%' }} id='random-id-109'  >
                                        <ImageC className='w-[100%] h-[100%] object-cover' src='https://i.ibb.co/FhLMq9b/Rectangle-2217.png' />
                                    </Element>
                                    <div className='w-[50%] mx-auto my-auto text-center' style={{ fontSize: '46px', fontWeight: 'bold', fontFamily: 'Chivo', color: '#0C134F' }}>$566</div>
                                </Element>
                                <Element is={Parent} canvas className='my-4' style={{ display: 'inline-block', fontSize: '24px', fontWeight: 'bold', fontFamily: 'Chivo', color: '#000000' }} id='random-id-32'  >
                                    <Text text='Lorem Ipsum' />
                                </Element>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '16px', fontWeight: '400px', fontFamily: 'Chivo', color: '#000000', lineHeight: '25.6px' }} id='random-id-33'  >
                                    <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.' />
                                </Element>
                                <Element is={Parent} canvas id='random-id-209' className='mx-auto mt-4'>
                                    <Button classn='block' class='w-[280px] h-[60px] cursor-pointer bg-[#D4ADFC] hover:bg-[#C68EFF] flex text-[#0C134F]' style={{ borderRadius: '14px' }}>
                                        <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Chivo', }} className='mx-auto my-auto' id='random-id-34'  >
                                            <Text text='Get Started' />
                                        </Element>
                                        <Element is={Parent} canvas id='random-id-451' className='mx-2 my-auto'>
                                            <ArrowCircleRightOutlinedIcon sx={{ color: '#0C134F', fontSize: '28px' }} />
                                        </Element>
                                    </Button>
                                </Element>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-452' className='max-w-[386px] min-w-[277px] h-full mx-4 my-4' style={{ background: '#FFFFFF', borderRadius: '30px', padding: '20px', flex: '28%' }}>
                            <Element is={Parent} canvas id='random-id-453' className='w-[100%] h-full flex flex-col'>
                                <Element is={Parent} canvas id='random-id-454' className='w-full h-full flex flex-row justify-between'>

                                    <Element is={Parent} canvas className='w-[50%] ' style={{ display: 'inline-block', height: '100%', width: '100%' }} id='random-id-110'  >
                                        <ImageC className='w-[100%] h-[100%] object-cover' src='https://i.ibb.co/FhLMq9b/Rectangle-2217.png' />
                                    </Element>
                                    <div className='w-[50%] mx-auto my-auto text-center' style={{ fontSize: '46px', fontWeight: 'bold', fontFamily: 'Chivo', color: '#0C134F' }}>$566</div>
                                </Element>
                                <Element is={Parent} canvas className='my-4' style={{ display: 'inline-block', fontSize: '24px', fontWeight: 'bold', fontFamily: 'Chivo', color: '#000000' }} id='random-id-35'  >
                                    <Text text='Lorem Ipsum' />
                                </Element>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '16px', fontWeight: '400px', fontFamily: 'Chivo', color: '#000000', lineHeight: '25.6px' }} id='random-id-36'  >
                                    <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.' />
                                </Element>
                                <Element is={Parent} canvas id='random-id-210' className='mx-auto mt-4'>
                                    <Button classn='block' class='w-[280px] h-[60px] cursor-pointer bg-[#D4ADFC] hover:bg-[#C68EFF] flex text-[#0C134F]' style={{ borderRadius: '14px' }}>
                                        <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Chivo', }} className='mx-auto my-auto' id='random-id-37'  >
                                            <Text text='Get Started' />
                                        </Element>
                                        {/* <Element is={Parent}canvas id='random-id-456'   className='mx-2 my-auto'>
                                <ArrowCircleRightOutlinedIcon sx={{color:'#0C134F', fontSize:'28px'}}/>
                             </Element> */}
                                    </Button>
                                </Element>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-457' className='max-w-[386px] min-w-[277px] h-full mx-auto my-auto' style={{ background: '#FFFFFF', borderRadius: '30px', padding: '20px', flex: '28%' }}>
                            <Element is={Parent} canvas id='random-id-458' className='w-[100%] h-full flex flex-col'>
                                <Element is={Parent} canvas id='random-id-459' className='w-full h-full flex flex-row justify-between'>

                                    <Element is={Parent} canvas className='w-[50%] ' style={{ display: 'inline-block', height: '100%', width: '100%' }} id='random-id-111'  >
                                        <ImageC className='w-[100%] h-[100%] object-cover' src='https://i.ibb.co/FhLMq9b/Rectangle-2217.png' />
                                    </Element>
                                    <Element is={Parent} canvas id='random-id-460' className='w-[50%] mx-auto my-auto text-center' style={{ fontSize: '46px', fontWeight: 'bold', fontFamily: 'Chivo', color: '#0C134F' }}>$566 </Element>
                                </Element>
                                <Element is={Parent} canvas className='my-4' style={{ display: 'inline-block', fontSize: '24px', fontWeight: 'bold', fontFamily: 'Chivo', color: '#000000' }} id='random-id-38'  >
                                    <Text text='Lorem Ipsum' />
                                </Element>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '16px', fontWeight: '400px', fontFamily: 'Chivo', color: '#000000', lineHeight: '25.6px' }} id='random-id-39'  >
                                    <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.' />
                                </Element>
                                <Element is={Parent} canvas id='random-id-211' className='mx-auto mt-4'>
                                    <Button classn='block' class='w-[280px] h-[60px] cursor-pointer bg-[#D4ADFC] hover:bg-[#C68EFF] flex text-[#0C134F]' style={{ borderRadius: '14px' }}>
                                        <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Chivo', }} className='mx-auto my-auto' id='random-id-40'  >
                                            <Text text='Get Started' />
                                        </Element>
                                        {/* <Element is={Parent}canvas id='random-id-461'   className='mx-2 my-auto'>
                                <ArrowCircleRightOutlinedIcon sx={{color:'#0C134F', fontSize:'28px'}}/>
                             </Element> */}
                                    </Button>
                                </Element>
                            </Element>
                        </Element>
                    </Element>
                </Element></Element>
        </Element>
    ]


    const [sel, setSel] = useState(selected)

    function makeOdd(number) {
        setProp((prop) => {
            setSel(100);
        }, 1000);

        setTimeout(() => {
            setProp((prop) => {
                setSel(number)
            }, 1000);
        }, 100);



    }

    useEffect(() => {
        if (selected || selected >= 0) {
            makeOdd(selected);
        }
    }, [selected])


    return (
        <div style={{ padding: enabled ? "10px" : "0px", display: "flex", justifyContent: "center", alignItems: "center", width: '100%', height: '100%' }} ref={connect}>
            {sel == 100 ? (
                <div style={{ height: "85vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <BounceLoader
                        size={100}
                        color={"white"}
                        loading={true}
                    />
                </div>
            ) : (
                styles[sel]
            )}
        </div>
    );
}

Ecommerce_2.craft = {
    displayName: "Ecommerce 2",
    props: {
        selected: 1, // here is the default value of the selected style
        length: 3,
        isBlock: true
    },
    rules: {
        canDrag: () => true,
        canDrop: () => true,
    },
    related: {
        toolbar: NewEcommerceSettings,
    },
};
