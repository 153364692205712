/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import { Element, useEditor, useNode } from '@craftjs/core';
import React, { useEffect, useRef, useState } from 'react';
import { NewServicesSettings } from './newServicesSetting';
import { Text } from '../../basic/Text';
import StopIcon from '@mui/icons-material/SquareSharp';
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';
import { Icon } from '@mui/material';
import { ImageC } from '../../basic/Image';
import { Parent } from '../../Parent/index.js';
import { Wrapper } from '../../wrapper/index.js';
import { BounceLoader } from 'react-spinners';

export const Services_1 = ({selected}) => {
    const { 
        actions: { setProp },
          connectors: {
            connect
          } 
      } = useNode();
    
      
      const {
        enabled,
      } = useEditor((state, query) => ({
        enabled: state.options.enabled,
     
      }));


    const styles = [
        <Element canvas is={Parent} id='parentId100' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId200' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-400' is='parent' className='w-[100%] h-full bg-[white] py-10'  style={{border:'1px solid transparent'}}>
            <Wrapper canvas id='random-id-401' is='container' className='container'>
                <Wrapper canvas id='random-id-402' is='wrapper' className={`w-[100%] h-full flex justify-center flex-row flex-wrap `}>
                <Wrapper canvas id='random-id-403' is='div' className={` w-[30%] h-full flex flex-col flex my-auto flex justify-center px-10 text-left min-w-[400px] `} style={{flex:'10%'}}>
                        <Wrapper canvas style={{ display: "inline-block", width:'100%', fontWeight:'700', fontFamily:'Rubik' }} className={`my-5 text-[#100103] text-[46px]`} id="random-id-1" is="div">
                        <Text text="Our top Service" />
                        </Wrapper>
                        <Wrapper canvas style={{ display: "inline-block", fontWeight:'400', fontFamily:'Rubik', lineHeight:'35.2px' }} className={`mb-5 text-[#000000] text-[22px]`} id="random-id-2" is="div">
                        <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                        </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-404' is='div' className='h-full justify-center flex justify-center flex flex-col flex-wrap align-items-center ' style={{flex:'60%'}}>
                    <Wrapper canvas id='random-id-405' is='div' className='w-full flex flex-row flex-wrap justify-center flex justify-center' >
                    
                 <Wrapper canvas id='random-id-406' is='div' className='flex flex-row min-w-[300px] my-4 flex justify-center min-h-[150px] pr-8' style={{flex:'20%'}}>
                    <Wrapper canvas id='random-id-700' style={{marginTop:'5px'}} is='div'>
                        <Wrapper canvas id='random-id-200100' is='div' style={{minWidth:'24px', maxWidth:'24px', minHeight:'24px', maxHeight:'24px'}} className='bg-[#B20312]'/>
                    </Wrapper>
                        <Wrapper canvas id='random-id-407' is='div' className='h-full flex flex-col flex items-center pl-2'>
                            <Wrapper canvas style={{ display: "inline-block", fontSize:'22px', fontWeight:'700', fontFamily:'Rubik' }} className='justify-start flex text-[#100103] w-full py-2'  id="random-id-3" is="div">
                                <Text text="Service 1" />
                            </Wrapper>
                            <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', lineHeight:'28.8px' }} className='text-[#100103]' id="random-id-4" is="div">
                            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                            </Wrapper>
                        </Wrapper>
                    </Wrapper>
                    
                    <Wrapper canvas id='random-id-409' is='div' className='flex flex-row min-w-[300px] my-4 flex justify-center min-h-[150px] pr-8' style={{flex:'20%'}}>
                 <Wrapper canvas id='random-id-701' style={{marginTop:'5px'}} is='div'>
                        <Wrapper canvas id='random-id-200101' is='div' style={{minWidth:'24px', maxWidth:'24px', minHeight:'24px', maxHeight:'24px'}} className='bg-[#B20312]'/>
                    </Wrapper>
                        <Wrapper canvas id='random-id-410' is='div' className='h-full flex flex-col flex items-center pl-2'>
                            <Wrapper canvas style={{ display: "inline-block", fontSize:'22px', fontWeight:'700', fontFamily:'Rubik' }} className='text-[#100103] w-full py-2' id="random-id-5" is="div">
                                <Text text="Service 2" />
                            </Wrapper> 
                            <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', lineHeight:'28.8px' }} className='text-[#100103]' id="random-id-6" is="div">
                            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                            </Wrapper>
                        </Wrapper>
                    </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-411' is='div' className='w-full flex flex-row flex-wrap justify-center flex justify-center' >
                    <Wrapper canvas id='random-id-412' is='div' className='flex flex-row min-w-[300px] my-4 flex justify-center min-h-[150px] pr-8' style={{flex:'20%'}}>
                    <Wrapper canvas id='random-id-702' style={{marginTop:'5px'}} is='div'>
                        <Wrapper canvas id='random-id-200102' is='div' style={{minWidth:'24px', maxWidth:'24px', minHeight:'24px', maxHeight:'24px'}} className='bg-[#B20312]'/>
                    </Wrapper>
                        <Wrapper canvas id='random-id-413' is='div' className='h-full flex flex-col flex items-center pl-2'>
                            <Wrapper canvas style={{ display: "inline-block", fontSize:'22px', fontWeight:'700', fontFamily:'Rubik'}} className='text-[#100103] w-full py-2' id="random-id-7" is="div">
                                <Text text="Service 3" />
                            </Wrapper> 
                            <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', lineHeight:'28.8px' }} className='text-[#100103]' id="random-id-8" is="div">
                            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                            </Wrapper>
                        </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-414' is='div' className='flex flex-row min-w-[300px] my-4 flex justify-center min-h-[150px] pr-8' style={{flex:'20%'}}>
                    <Wrapper canvas id='random-id-703' style={{marginTop:'5px'}} is='div'>
                        <Wrapper canvas id='random-id-200103' is='div' style={{minWidth:'24px', maxWidth:'24px', minHeight:'24px', maxHeight:'24px'}} className='bg-[#B20312]'/>
                    </Wrapper>
                        <Wrapper canvas id='random-id-415' is='div' className='h-full flex flex-col flex items-center pl-2'>
                            <Wrapper canvas style={{ display: "inline-block", fontSize:'22px', fontWeight:'700', fontFamily:'Rubik' }} className='text-[#100103] w-full py-2' id="random-id-9" is="div">
                                <Text text="Service 4" />
                            </Wrapper> 
                            <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', lineHeight:'28.8px' }} className='text-[#100103]' id="random-id-10" is="div">
                            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                            </Wrapper>
                        </Wrapper>
                    </Wrapper>
                    </Wrapper>
                </Wrapper>
                </Wrapper>
            </Wrapper>
        </Wrapper></Element>
        </Element>
        ,
        <Element canvas is={Parent} id='parentId300' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId400' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-416' is='parent' className='w-[100%] h-full bg-[#121826] py-10'  style={{border:'1px solid transparent'}}>
            <Wrapper canvas id='random-id-417' is='container' className='container' >
                <Wrapper canvas id='random-id-418' is='wrapper' className={`w-full h-full flex justify-center flex flex-row flex-wrap`}>
                <Wrapper canvas id='random-id-419' is='div' className={`w-[30%] min-w-[400px]  h-full flex flex-col flex items-center flex justify-center px-10 text-left`} style={{maxWidth:'600px', flex:'10%'}}>
                        <Wrapper canvas style={{ display: "inline-block" , fontWeight:'700', fontFamily:'Rubik'}} className={`my-5 IncreaseConversion2 text-[46px]`} id="random-id-11" is="div">
                        <Text text="Our top Services." />
                        </Wrapper>
                        <Wrapper canvas style={{ display: "inline-block", fontWeight:'400', fontFamily:'Rubik', lineHeight:'35.2px' }} className={`mb-5 text-[#FFFFFF] text-[22px]`} id="random-id-12" is="div">
                        <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                        </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-420' is='div' className='w-full h-full justify-center flex justify-center flex flex-col flex-wrap align-items-center' style={{flex:'60%'}}>
                    <Wrapper canvas id='random-id-421' is='div' className='w-full flex flex-row flex-wrap justify-center flex justify-center' >
                    
                    <Wrapper canvas id='random-id-422' is='div' className='flex flex-row min-w-[300px] my-4 flex justify-center min-h-[150px] pr-8' style={{flex:'28%'}}>
                    <Wrapper canvas id='random-id-704' style={{marginTop:'5px'}} is='div'>
                        <Wrapper canvas id='random-id-200104' is='div' style={{minWidth:'24px', maxWidth:'24px', minHeight:'24px', maxHeight:'24px'}} className='bg-[#4BDBA2]'/>
                    </Wrapper>
                        <Wrapper canvas id='random-id-423' is='div' className='h-full flex flex-col flex items-center pl-2'>
                            <Wrapper canvas style={{ display: "inline-block", fontSize:'22px', fontWeight:'700', fontFamily:'Rubik' }} className='text-[#4BDBA2] w-full py-2' id="random-id-13" is="div">
                                <Text text="Service 1" />
                            </Wrapper> 
                            <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', lineHeight:'28.8px' }} className='text-[#FFFFFF]' id="random-id-14" is="div">
                            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                            </Wrapper>
                        </Wrapper>
                    </Wrapper>
                    
                    <Wrapper canvas id='random-id-424' is='div' className='flex flex-row min-w-[300px] my-4 flex justify-center min-h-[150px] pr-8' style={{flex:'28%'}}>
                    <Wrapper canvas id='random-id-705' style={{marginTop:'5px'}} is='div'>
                        <Wrapper canvas id='random-id-200105' is='div' style={{minWidth:'24px', maxWidth:'24px', minHeight:'24px', maxHeight:'24px'}} className='bg-[#4BDBA2]'/>
                    </Wrapper>
                        <Wrapper canvas id='random-id-425' is='div' className='h-full flex flex-col flex items-center pl-2'>
                            <Wrapper canvas style={{ display: "inline-block", fontSize:'22px', fontWeight:'700', fontFamily:'Rubik'}} className='text-[#4BDBA2] w-full py-2' id="random-id-15" is="div">
                                <Text text="Service 2" />
                            </Wrapper> 
                            <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', lineHeight:'28.8px'}} className='text-[#FFFFFF]' id="random-id-16" is="div">
                            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                            </Wrapper>
                        </Wrapper>
                    </Wrapper>

                    </Wrapper>
                    <Wrapper canvas id='random-id-426' is='div' className='w-full flex flex-row flex-wrap justify-center flex justify-center' >
                    <Wrapper canvas id='random-id-427' is='div' className='flex flex-row min-w-[300px] my-4 flex justify-center min-h-[150px] pr-8' style={{flex:'28%'}}>
                    <Wrapper canvas id='random-id-706' style={{marginTop:'5px'}} is='div'>
                        <Wrapper canvas id='random-id-200106' is='div' style={{minWidth:'24px', maxWidth:'24px', minHeight:'24px', maxHeight:'24px'}} className='bg-[#4BDBA2]'/>
                    </Wrapper>
                        <Wrapper canvas id='random-id-428' is='div' className='h-full flex flex-col flex items-center pl-2'>
                            <Wrapper canvas style={{ display: "inline-block", fontSize:'22px', fontWeight:'700', fontFamily:'Rubik'}} className='text-[#4BDBA2] w-full py-2' id="random-id-17" is="div">
                                <Text text="Service 3" />
                            </Wrapper> 
                            <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', lineHeight:'28.8px'}} className='text-[#FFFFFF]' id="random-id-18" is="div">
                            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                            </Wrapper>
                        </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-429' is='div' className='flex flex-row min-w-[300px] my-4 flex justify-center min-h-[150px] pr-8' style={{flex:'28%'}}>
                    <Wrapper canvas id='random-id-707' style={{marginTop:'5px'}} is='div'>
                        <Wrapper canvas id='random-id-200107' is='div' style={{minWidth:'24px', maxWidth:'24px', minHeight:'24px', maxHeight:'24px'}} className='bg-[#4BDBA2]'/>
                    </Wrapper>
                        <Wrapper canvas id='random-id-430' is='div' className='h-full flex flex-col flex items-center pl-2'>
                            <Wrapper canvas style={{ display: "inline-block", fontSize:'22px', fontWeight:'700', fontFamily:'Rubik'}} className='text-[#4BDBA2] w-full py-2' id="random-id-19" is="div">
                                <Text text="Service 4" />
                            </Wrapper> 
                            <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', lineHeight:'28.8px'}} className='text-[#FFFFFF]' id="random-id-20" is="div">
                            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                            </Wrapper>
                        </Wrapper>
                    </Wrapper>
                    </Wrapper>
                </Wrapper>
                    </Wrapper>
                </Wrapper>
        </Wrapper></Element>
        </Element>,
        
        <Element canvas is={Parent} id='parentId500' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId600' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-431' is='parent' className='w-[100%] h-full bg-[#213156] py-10'  style={{border:'1px solid transparent'}}>
            <Wrapper canvas id='random-id-432' is='container' className='container' >
                <Wrapper canvas id='random-id-433' is='wrapper' className={`w-full h-full flex justify-center flex flex-row flex-wrap`}>
                <Wrapper canvas id='random-id-434' is='div' className={`w-[30%] min-w-[400px]  h-full flex flex-col flex items-center flex justify-center px-10  text-left`} style={{maxWidth:'600px', flex:'10%'}}>
                        <Wrapper canvas style={{ display: "inline-block", fontWeight:'700', fontFamily:'Times New Roman'}} className={`my-5 text-[#EA6EF4] text-[46px]`} id="random-id-21" is="div">
                        <Text text="Our top Services." />
                        </Wrapper>                        
                        <Wrapper canvas style={{ display: "inline-block", fontWeight:'400', fontFamily:'Times New Roman', lineHeight:'35.2px'}} className={`mb-5 text-[#FFFFFF] text-[22px]`} id="random-id-22" is="div">
                        <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                        </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-435' is='div' className='w-full h-full justify-center flex justify-center flex flex-col flex-wrap align-items-center' style={{flex:'60%'}}>
                    <Wrapper canvas id='random-id-436' is='div' className='w-full flex flex-row flex-wrap justify-center flex justify-center' >
                    
                    <Wrapper canvas id='random-id-437' is='div' className='flex flex-row min-w-[300px] my-4 flex justify-center min-h-[150px] pr-8' style={{flex:'28%'}}>
                    <Wrapper canvas id='random-id-708' style={{marginTop:'5px'}} is='div'>
                        <Wrapper canvas id='random-id-200108' is='div' style={{minWidth:'24px', maxWidth:'24px', minHeight:'24px', maxHeight:'24px'}} className='bg-[#EA6EF4]'/>
                    </Wrapper>
                        <Wrapper canvas id='random-id-438' is='div' className='h-full flex flex-col flex items-center pl-2'>
                            <Wrapper canvas style={{ display: "inline-block", fontSize:'22px', fontWeight:'700', fontFamily:'Open Sans'}} className='text-[#EA6EF4] w-full py-2' id="random-id-23" is="div">
                                <Text text="Service 1" />
                            </Wrapper> 
                            <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400', fontFamily:'Open Sans', lineHeight:'28.8px'}} className='text-[#FFF]' id="random-id-24" is="div">
                            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                            </Wrapper>
                        </Wrapper>
                    </Wrapper>
                    
                    <Wrapper canvas id='random-id-439' is='div' className='flex flex-row min-w-[300px] my-4 flex justify-center min-h-[150px] pr-8' style={{flex:'28%'}}>
                    <Wrapper canvas id='random-id-709' style={{marginTop:'5px'}} is='div'>
                        <Wrapper canvas id='random-id-200109' is='div' style={{minWidth:'24px', maxWidth:'24px', minHeight:'24px', maxHeight:'24px'}} className='bg-[#EA6EF4]'/>
                    </Wrapper>
                        <Wrapper canvas id='random-id-440' is='div' className='h-full flex flex-col flex items-center pl-2'>
                            <Wrapper canvas style={{ display: "inline-block", fontSize:'22px', fontWeight:'700', fontFamily:'Open Sans'}} className='text-[#EA6EF4] w-full py-2' id="random-id-25" is="div">
                                <Text text="Service 2" />
                            </Wrapper> 
                            <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400', fontFamily:'Open Sans', lineHeight:'28.8px'}} className='text-[#FFF]' id="random-id-26" is="div">
                            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                            </Wrapper>
                        </Wrapper>
                    </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-441' is='div' className='w-full flex flex-row flex-wrap justify-center flex justify-center' >
                    <Wrapper canvas id='random-id-442' is='div' className='flex flex-row min-w-[300px] my-4 flex justify-center min-h-[150px] pr-8' style={{flex:'28%'}}>
                    <Wrapper canvas id='random-id-710' style={{marginTop:'5px'}} is='div'>
                        <Wrapper canvas id='random-id-200110' is='div' style={{minWidth:'24px', maxWidth:'24px', minHeight:'24px', maxHeight:'24px'}} className='bg-[#EA6EF4]'/>
                    </Wrapper>
                        <Wrapper canvas id='random-id-443' is='div' className='h-full flex flex-col flex items-center pl-2'>
                            <Wrapper canvas style={{ display: "inline-block", fontSize:'22px', fontWeight:'700', fontFamily:'Open Sans' }} className='text-[#EA6EF4] w-full py-2' id="random-id-27" is="div">
                                <Text text="Service 3" />
                            </Wrapper> 
                            <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400', fontFamily:'Open Sans', lineHeight:'28.8px'}} className='text-[#FFF]' id="random-id-28" is="div">
                            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                            </Wrapper>
                        </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-444' is='div' className='flex flex-row min-w-[300px] my-4 flex justify-center min-h-[150px] pr-8' style={{flex:'28%'}}>
                    <Wrapper canvas id='random-id-711' style={{marginTop:'5px'}} is='div'>
                        <Wrapper canvas id='random-id-200111' is='div' style={{minWidth:'24px', maxWidth:'24px', minHeight:'24px', maxHeight:'24px'}} className='bg-[#EA6EF4]'/>
                    </Wrapper>
                        <Wrapper canvas id='random-id-445' is='div' className='h-full flex flex-col flex items-center pl-2'>
                            <Wrapper canvas style={{ display: "inline-block", fontSize:'22px', fontWeight:'700', fontFamily:'Open Sans' }} className='text-[#EA6EF4] w-full py-2' id="random-id-29" is="div">
                                <Text text="Service 4" />
                            </Wrapper> 
                            <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400', fontFamily:'Open Sans', lineHeight:'28.8px'}} className='text-[#FFF]' id="random-id-30" is="div">
                            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                            </Wrapper>
                        </Wrapper>
                    </Wrapper>
                    </Wrapper>
                </Wrapper>
                    </Wrapper>
                </Wrapper>
        </Wrapper></Element>
        </Element>,
        
        <Element canvas is={Parent} id='parentId700' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId800' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-446' is='parent' className='w-[100%] h-full bg-[#DFFFF9] py-10'  style={{border:'1px solid transparent'}}>
            <Wrapper canvas id='random-id-447' is='container' className='container' >
                <Wrapper canvas id='random-id-448' is='wrapper' className={`w-full h-full flex justify-center flex flex-row flex-wrap`}>
                <Wrapper canvas id='random-id-449' is='div' className={`w-[30%] min-w-[400px]  h-full flex flex-col flex items-center flex justify-center px-10 text-left`} style={{maxWidth:'600px', flex:'10%'}}>
                        <Wrapper canvas style={{ display: "inline-block", fontWeight:'700', fontStyle:'italic', fontFamily:'Zing Script Rust SemiBold Demo'}} className={`my-5 text-[#31A993] text-[46px] `} id="random-id-31" is="div">
                        <Text text="Our top Services." />
                        </Wrapper>                        
                        <Wrapper canvas style={{ display: "inline-block", fontWeight:'400', fontFamily:'Zing Script Rust SemiBold Demo', lineHeight:'35.2px' }} className={`mb-5 text-[#031815] text-[22px]`} id="random-id-32" is="div">
                        <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                        </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-450' is='div' className='w-full h-full justify-center flex justify-center flex flex-col flex-wrap align-items-center' style={{flex:'60%'}}>
                    <Wrapper canvas id='random-id-451' is='div' className='w-full flex flex-row flex-wrap justify-center flex justify-center' >
                    
                    <Wrapper canvas id='random-id-452' is='div' className='flex flex-row min-w-[300px] my-4 flex justify-center min-h-[150px] pr-8' style={{flex:'28%'}}>
                    <Wrapper canvas id='random-id-712' style={{marginTop:'5px'}} is='div'>
                        <Wrapper canvas id='random-id-200112' is='div' style={{minWidth:'24px', maxWidth:'24px', minHeight:'24px', maxHeight:'24px'}} classsName='bg-[#31A993]'/>
                    </Wrapper>
                        <Wrapper canvas id='random-id-453' is='div' className='h-full flex flex-col flex items-center pl-2'>
                            <Wrapper canvas style={{ display: "inline-block", fontSize:'22px', fontWeight:'700', fontFamily:'Open Sans'}} className='text-[#31A993] w-full py-2' id="random-id-33" is="div">
                                <Text text="Service 1" />
                            </Wrapper> 
                            <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400', fontFamily:'Open Sans', lineHeight:'28.8px'}} className='text-[#031815]' id="random-id-34" is="div">
                            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                            </Wrapper>
                        </Wrapper>
                    </Wrapper>
                    
                    <Wrapper canvas id='random-id-454' is='div' className='flex flex-row min-w-[300px] my-4 flex justify-center min-h-[150px] pr-8' style={{flex:'28%'}}>
                    <Wrapper canvas id='random-id-713' style={{marginTop:'5px'}} is='div'>
                        <Wrapper canvas id='random-id-200113' is='div' style={{minWidth:'24px', maxWidth:'24px', minHeight:'24px', maxHeight:'24px'}} classsName='bg-[#31A993]'/>
                    </Wrapper>
                        <Wrapper canvas id='random-id-455' is='div' className='h-full flex flex-col flex items-center pl-2'>
                            <Wrapper canvas style={{ display: "inline-block", fontSize:'22px', fontWeight:'700', fontFamily:'Open Sans'}} className='text-[#31A993] w-full py-2' id="random-id-35" is="div">
                                <Text text="Service 2" />
                            </Wrapper> 
                            <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400', fontFamily:'Open Sans', lineHeight:'28.8px'}} className='text-[#031815]' id="random-id-36" is="div">
                            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                            </Wrapper>
                        </Wrapper>
                    </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-456' is='div' className='w-full flex flex-row flex-wrap justify-center flex justify-center' >
                    <Wrapper canvas id='random-id-457' is='div' className='flex flex-row min-w-[300px] my-4 flex justify-center min-h-[150px] pr-8' style={{flex:'28%'}}>
                    <Wrapper canvas id='random-id-714' style={{marginTop:'5px'}} is='div'>
                        <Wrapper canvas id='random-id-200114' is='div' style={{minWidth:'24px', maxWidth:'24px', minHeight:'24px', maxHeight:'24px'}} classsName='bg-[#31A993]'/>
                    </Wrapper>
                        <Wrapper canvas id='random-id-458' is='div' className='h-full flex flex-col flex items-center pl-2'>
                            <Wrapper canvas style={{ display: "inline-block", fontSize:'22px', fontWeight:'700', fontFamily:'Open Sans'}} className='text-[#31A993] w-full py-2' id="random-id-37" is="div">
                                <Text text="Service 3" />
                            </Wrapper> 
                            <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400', fontFamily:'Open Sans', lineHeight:'28.8px'}} className='text-[#031815]' id="random-id-38" is="div">
                            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                            </Wrapper>
                        </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-459' is='div' className='flex flex-row min-w-[300px] my-4 flex justify-center min-h-[150px] pr-8' style={{flex:'28%'}}>
                    <Wrapper canvas id='random-id-715' style={{marginTop:'5px'}} is='div'>
                        <Wrapper canvas id='random-id-200115' is='div' style={{minWidth:'24px', maxWidth:'24px', minHeight:'24px', maxHeight:'24px'}} classsName='bg-[#31A993]'/>
                    </Wrapper>
                        <Wrapper canvas id='random-id-460' is='div' className='h-full flex flex-col flex items-center pl-2'>
                            <Wrapper canvas style={{ display: "inline-block", fontSize:'22px', fontWeight:'700', fontFamily:'Open Sans'}} className='text-[#31A993] w-full py-2' id="random-id-39" is="div">
                                <Text text="Service 4" />
                            </Wrapper> 
                            <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400', fontFamily:'Open Sans', lineHeight:'28.8px'}} className='text-[#031815]' id="random-id-40" is="div">
                            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                            </Wrapper>
                        </Wrapper>
                    </Wrapper>
                    </Wrapper>
                </Wrapper>
            </Wrapper>
            </Wrapper>
        </Wrapper></Element>
        </Element>,
  
        <Element canvas is={Parent} id='parentId900' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId1000' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-461' is='parent' className='w-[100%] h-full bg-[#FFFFFF] py-10'  style={{border:'1px solid transparent'}}>
        <Wrapper canvas id='random-id-462' is='container' className='container' >
            <Wrapper canvas id='random-id-463' is='wrapper' className={`w-full h-full flex justify-center flex flex-row flex-wrap`}>
            <Wrapper canvas id='random-id-464' is='div' className={`w-[30%] min-w-[400px] text-left h-full flex flex-col flex items-center flex justify-center px-10 `} style={{maxWidth:'600px', flex:'10%'}}>
                    <Wrapper canvas style={{ display: "inline-block", fontWeight:'700', fontFamily:'Times New Roman'}} className={`my-5 text-[#0078D3] text-[46px]`} id="random-id-41" is="div">
                    <Text text="Our top Services." />
                    </Wrapper>                    
                    <Wrapper canvas style={{ display: "inline-block", fontWeight:'400', fontFamily:'Times New Roman', lineHeight:'35.2px' }} className={`mb-5 text-[#020E17] text-[22px]`} id="random-id-42" is="div">
                    <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                    </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-465' is='div' className='w-full h-full justify-center flex justify-center flex flex-col flex-wrap align-items-center' style={{flex:'60%'}}>
                <Wrapper canvas id='random-id-466' is='div' className='w-full flex flex-row flex-wrap justify-center flex justify-center' >
                
                <Wrapper canvas id='random-id-467' is='div' className='flex flex-row min-w-[300px] my-4 flex justify-center min-h-[150px] pr-8' style={{flex:'28%'}}>
                <Wrapper canvas id='random-id-716' style={{marginTop:'5px'}} is='div'>
                        <Wrapper canvas id='random-id-200116' is='div' style={{minWidth:'24px', maxWidth:'24px', minHeight:'24px', maxHeight:'24px'}} className='bg-[#0078D3]'/>
                    </Wrapper>
                        <Wrapper canvas id='random-id-468' is='div' className='h-full flex flex-col flex items-center pl-2'>
                            <Wrapper canvas style={{ display: "inline-block", fontSize:'22px', fontWeight:'700', fontFamily:'Inter'}} className='text-[#0078D3] w-full py-2' id="random-id-43" is="div">
                                <Text text="Service 1" />
                            </Wrapper> 
                            <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400', fontFamily:'Inter', lineHeight:'28.8px' }} className='text-[#020E17]' id="random-id-44" is="div">
                            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                            </Wrapper>
                        </Wrapper>
                    </Wrapper>
                
                    <Wrapper canvas id='random-id-469' is='div' className='flex flex-row min-w-[300px] my-4 flex justify-center min-h-[150px] pr-8' style={{flex:'28%'}}>
                    <Wrapper canvas id='random-id-717' style={{marginTop:'5px'}} is='div'>
                        <Wrapper canvas id='random-id-200117' is='div' style={{minWidth:'24px', maxWidth:'24px', minHeight:'24px', maxHeight:'24px'}} className='bg-[#0078D3]'/>
                    </Wrapper>
                        <Wrapper canvas id='random-id-470' is='div' className='h-full flex flex-col flex items-center pl-2'>
                            <Wrapper canvas style={{ display: "inline-block", fontSize:'22px', fontWeight:'700', fontFamily:'Inter' }} className='text-[#0078D3] w-full py-2'  id="random-id-45" is="div">
                                <Text text="Service 2" />
                            </Wrapper> 
                            <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400', fontFamily:'Inter', lineHeight:'28.8px'}} className='text-[#020E17]' id="random-id-46" is="div">
                            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                            </Wrapper>
                        </Wrapper>
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-471' is='div' className='w-full flex flex-row flex-wrap justify-center flex justify-center' >
                <Wrapper canvas id='random-id-472' is='div' className='flex flex-row min-w-[300px] my-4 flex justify-center min-h-[150px] pr-8' style={{flex:'28%'}}>
                <Wrapper canvas id='random-id-718' style={{marginTop:'5px'}} is='div'>
                        <Wrapper canvas id='random-id-200118' is='div' style={{minWidth:'24px', maxWidth:'24px', minHeight:'24px', maxHeight:'24px'}} className='bg-[#0078D3]'/>
                    </Wrapper>
                        <Wrapper canvas id='random-id-473' is='div' className='h-full flex flex-col flex items-center pl-2'>
                            <Wrapper canvas style={{ display: "inline-block", fontSize:'22px', fontWeight:'700', fontFamily:'Inter' }} className='text-[#0078D3] w-full py-2' id="random-id-47" is="div">
                                <Text text="Service 3" />
                            </Wrapper> 
                            <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400', fontFamily:'Inter', lineHeight:'28.8px'}} className='text-[#020E17]' id="random-id-48" is="div">
                            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                            </Wrapper>
                        </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-474' is='div' className='flex flex-row min-w-[300px] my-4 flex justify-center min-h-[150px] pr-8' style={{flex:'28%'}}>
                    <Wrapper canvas id='random-id-719' style={{marginTop:'5px'}} is='div'>
                        <Wrapper canvas id='random-id-200119' is='div' style={{minWidth:'24px', maxWidth:'24px', minHeight:'24px', maxHeight:'24px'}} className='bg-[#0078D3]'/>
                    </Wrapper>
                        <Wrapper canvas id='random-id-475' is='div' className='h-full flex flex-col flex items-center pl-2'>
                            <Wrapper canvas style={{ display: "inline-block", fontSize:'22px', fontWeight:'700', fontFamily:'Inter'}} className='text-[#0078D3] w-full py-2' id="random-id-49" is="div">
                                <Text text="Service 4" />
                            </Wrapper> 
                            <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400', fontFamily:'Inter', lineHeight:'28.8px'}} className='text-[#020E17]' id="random-id-50" is="div">
                            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                            </Wrapper>
                        </Wrapper>
                    </Wrapper>
                </Wrapper>
            </Wrapper>
                </Wrapper>
            </Wrapper>
    </Wrapper></Element>
        </Element>,

        <Element canvas is={Parent} id='parentId1100' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId1200' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-476' is='parent' className='w-[100%] h-[100%] bg-[white] py-10'  style={{border:'1px solid transparent'}}>
        <Wrapper canvas id='random-id-477' is='container' className='container'>
            <Wrapper canvas id='random-id-478' is='wrapper' className={`w-[100%] h-full flex justify-center flex-row flex-wrap`}>
            <Wrapper canvas id='random-id-479' is='div' className={`w-[30%] min-w-[400px] flex flex-col justify-center px-10 flex justify-center bg-[#f5f5f6] text-left`} style={{ flex:'38%'}}>
                <Wrapper canvas style={{ display: "inline-block", fontWeight:'700', fontFamily:'#020212' }}  className={`my-5 text-[#020212] uppercase text-[46px]`} id="random-id-51" is="div">
                <Text text="Our top Services." />
                </Wrapper>
                <Wrapper canvas style={{ display: "inline-block", fontWeight:'400', fontFamily:'#020212', lineHeight:'35.2px' }} className={`mb-5 text-[#020212] text-[22px]`} id="random-id-52" is="div">
                <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-480' is='div' className='w-full h-full justify-center flex justify-center pl-4 flex flex-row flex-wrap align-items-center' style={{flex:'60%'}}>
                <Wrapper canvas id='random-id-481' is='div' className='w-full flex flex-row flex-wrap justify-center flex justify-center' >
                
                <Wrapper canvas id='random-id-482' is='div' className='flex flex-row min-w-[300px] my-4 flex justify-center min-h-[150px] pr-8' style={{flex:'28%'}}>
                <Wrapper canvas id='random-id-720' style={{marginTop:'5px'}} is='div'>
                        <Wrapper canvas id='random-id-200120' is='div' style={{minWidth:'24px', maxWidth:'24px', minHeight:'24px', maxHeight:'24px'}} className='bg-[#020212]'/>
                    </Wrapper>
                        <Wrapper canvas id='random-id-483' is='div' className='h-full flex flex-col flex items-center pl-2'>
                            <Wrapper canvas style={{ display: "inline-block", fontSize:'22px', fontWeight:'700', fontFamily:'Rubik' }} className='text-[#020212] w-full py-2' id="random-id-53" is="div">
                                <Text text="Service 1" />
                            </Wrapper> 
                            <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', lineHeight:'28.8px' }} className='text-[#100103]' id="random-id-54" is="div">
                            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                            </Wrapper>
                        </Wrapper>
                    </Wrapper>
                
                    <Wrapper canvas id='random-id-484' is='div' className='flex flex-row min-w-[300px] my-4 flex justify-center min-h-[150px] pr-8' style={{flex:'28%'}}>
                    <Wrapper canvas id='random-id-721' style={{marginTop:'5px'}} is='div'>
                        <Wrapper canvas id='random-id-200121' is='div' style={{minWidth:'24px', maxWidth:'24px', minHeight:'24px', maxHeight:'24px'}} className='bg-[#020212]'/>
                    </Wrapper>
                        <Wrapper canvas id='random-id-485' is='div' className='h-full flex flex-col flex items-center pl-2'>
                            <Wrapper canvas style={{ display: "inline-block", fontSize:'22px', fontWeight:'700', fontFamily:'Rubik' }} className='text-[#020212] w-full py-2' id="random-id-55" is="div">
                                <Text text="Service 2" />
                            </Wrapper> 
                            <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', lineHeight:'28.8px' }} className='text-[#100103]' id="random-id-56" is="div">
                            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                            </Wrapper>
                        </Wrapper>
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-486' is='div' className='w-full flex flex-row flex-wrap justify-center flex justify-center' >
                <Wrapper canvas id='random-id-487' is='div' className='flex flex-row min-w-[300px] my-4 flex justify-center min-h-[150px] pr-8' style={{flex:'28%'}}>
                <Wrapper canvas id='random-id-722' style={{marginTop:'5px'}} is='div'>
                        <Wrapper canvas id='random-id-200122' is='div' style={{minWidth:'24px', maxWidth:'24px', minHeight:'24px', maxHeight:'24px'}} className='bg-[#020212]'/>
                    </Wrapper>
                        <Wrapper canvas id='random-id-488' is='div' className='h-full flex flex-col flex items-center pl-2'>
                            <Wrapper canvas style={{ display: "inline-block", fontSize:'22px', fontWeight:'700', fontFamily:'Rubik' }} className='text-[#020212] w-full py-2' id="random-id-57" is="div">
                                <Text text="Service 3" />
                            </Wrapper> 
                            <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', lineHeight:'28.8px' }} className='text-[#100103]' id="random-id-58" is="div">
                            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                            </Wrapper>
                        </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-489' is='div' className='flex flex-row min-w-[300px] my-4 flex justify-center min-h-[150px] pr-8' style={{flex:'28%'}}>
                    <Wrapper canvas id='random-id-723' style={{marginTop:'5px'}} is='div'>
                        <Wrapper canvas id='random-id-200123' is='div' style={{minWidth:'24px', maxWidth:'24px', minHeight:'24px', maxHeight:'24px'}} className='bg-[#020212]'/>
                    </Wrapper>
                        <Wrapper canvas id='random-id-490' is='div' className='h-full flex flex-col flex items-center pl-2'>
                            <Wrapper canvas style={{ display: "inline-block" , fontSize:'22px', fontWeight:'700', fontFamily:'Rubik'}} className='text-[#020212] w-full py-2' id="random-id-59" is="div">
                                <Text text="Service 4" />
                            </Wrapper> 
                            <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', lineHeight:'28.8px'}} className='text-[#100103]' id="random-id-60" is="div">
                            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                            </Wrapper>
                        </Wrapper>
                    </Wrapper>
                </Wrapper>
            </Wrapper>
            </Wrapper>
        </Wrapper>
    </Wrapper></Element>
        </Element>,


        <Element canvas is={Parent} id='parentId1300' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId1400' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-491' is='parent' className='w-[100%] h-full bg-[white] py-10 border'  style={{border:'1px solid transparent'}}>
        <Wrapper canvas id='random-id-492' is='container' className='container border' >
            <Wrapper canvas id='random-id-493' is='wrapper' className={`w-full h-full flex justify-center bg-[#6750A4] flex flex-row flex-wrap`} style={{borderRadius:'30px'}}>
            <Wrapper canvas id='random-id-494' is='div' className={`w-[30%] min-w-[400px]  h-full flex flex-col flex items-center flex justify-center px-10 text-left`} style={{maxWidth:'600px', flex:'10%'}}>
                    <Wrapper canvas style={{ display: "inline-block", fontWeight:'700', fontFamily:'Roboto'}} className={`my-5 text-[#D0BCFF] text-[46px]`} id="random-id-61" is="div">
                    <Text text="Our top Services." />
                    </Wrapper>                    
                    <Wrapper canvas style={{ display: "inline-block", fontWeight:'400', fontFamily:'Roboto', lineHeight:'35.2px' }} className={`mb-5 text-[#FFFFFF] text-[22px]`}  id="random-id-62" is="div">
                    <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                    </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-495' is='div' className='w-full h-full justify-center flex justify-center flex flex-col flex-wrap align-items-center' style={{flex:'60%'}}>
                <Wrapper canvas id='random-id-496' is='div' className='w-full flex flex-row flex-wrap justify-center flex justify-center pl-4' >
                
                <Wrapper canvas id='random-id-497' is='div' className='flex flex-row min-w-[300px] my-4 flex justify-center min-h-[150px] pr-8' style={{flex:'28%'}}>
                <Wrapper canvas id='random-id-724' style={{marginTop:'5px'}} is='div'>
                        <Wrapper canvas id='random-id-200124' is='div' style={{minWidth:'24px', maxWidth:'24px', minHeight:'24px', maxHeight:'24px'}} className='bg-[#D0BCFF]'/>
                    </Wrapper>
                        <Wrapper canvas id='random-id-498' is='div' className='h-full flex flex-col flex items-center pl-2'>
                            <Wrapper canvas style={{ display: "inline-block", fontSize:'22px', fontWeight:'700', fontFamily:'Roboto' }} className='text-[#D0BCFF] w-full py-2'  id="random-id-63" is="div">
                                <Text text="Service 1" />
                            </Wrapper> 
                            <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400', fontFamily:'Roboto', lineHeight:'28.8px' }} className='text-[#FFFFFF]'  id="random-id-64" is="div">
                            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                            </Wrapper>
                        </Wrapper>
                    </Wrapper>
                
                    <Wrapper canvas id='random-id-499' is='div' className='flex flex-row min-w-[300px] my-4 flex justify-center min-h-[150px] pr-8' style={{flex:'28%'}}>
                    <Wrapper canvas id='random-id-725' style={{marginTop:'5px'}} is='div'>
                        <Wrapper canvas id='random-id-200125' is='div' style={{minWidth:'24px', maxWidth:'24px', minHeight:'24px', maxHeight:'24px'}} className='bg-[#D0BCFF]'/>
                    </Wrapper>
                        <Wrapper canvas id='random-id-500' is='div' className='h-full flex flex-col flex items-center pl-2'>
                            <Wrapper canvas style={{ display: "inline-block", fontSize:'22px', fontWeight:'700', fontFamily:'Roboto' }} className='text-[#D0BCFF] w-full py-2' id="random-id-65" is="div">
                                <Text text="Service 2" />
                            </Wrapper> 
                            <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400', fontFamily:'Roboto', lineHeight:'28.8px' }} className='text-[#FFFFFF]' id="random-id-66" is="div">
                            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                            </Wrapper>
                        </Wrapper>
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-501' is='div' className='w-full flex flex-row flex-wrap justify-center flex justify-center pl-4' >
                <Wrapper canvas id='random-id-502' is='div' className='flex flex-row min-w-[300px] my-4 flex justify-center min-h-[150px] pr-8' style={{flex:'28%'}}>
                <Wrapper canvas id='random-id-726' style={{marginTop:'5px'}} is='div'>
                        <Wrapper canvas id='random-id-200126' is='div' style={{minWidth:'24px', maxWidth:'24px', minHeight:'24px', maxHeight:'24px'}} className='bg-[#D0BCFF]'/>
                    </Wrapper>
                        <Wrapper canvas id='random-id-503' is='div' className='h-full flex flex-col flex items-center pl-2'>
                            <Wrapper canvas style={{ display: "inline-block", fontSize:'22px', fontWeight:'700', fontFamily:'Roboto' }} className='text-[#D0BCFF] w-full py-2' id="random-id-57" is="div">
                                <Text text="Service 3" />
                            </Wrapper> 
                            <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400', fontFamily:'Roboto', lineHeight:'28.8px' }} className='text-[#FFFFFF]' id="random-id-68" is="div">
                            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                            </Wrapper>
                        </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-504' is='div' className='flex flex-row min-w-[300px] my-4 flex justify-center min-h-[150px] pr-8' style={{flex:'28%'}}>
                    <Wrapper canvas id='random-id-727' style={{marginTop:'5px'}} is='div'>
                        <Wrapper canvas id='random-id-200127' is='div' style={{minWidth:'24px', maxWidth:'24px', minHeight:'24px', maxHeight:'24px'}} className='bg-[#D0BCFF]'/>
                    </Wrapper>
                        <Wrapper canvas id='random-id-505' is='div' className='h-full flex flex-col flex items-center pl-2'>
                            <Wrapper canvas style={{ display: "inline-block", fontSize:'22px', fontWeight:'700', fontFamily:'Roboto'}} className='text-[#D0BCFF] w-full py-2' id="random-id-69" is="div">
                                <Text text="Service 4" />
                            </Wrapper> 
                            <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400', fontFamily:'Roboto', lineHeight:'28.8px' }} className='text-[#FFFFFF]' id="random-id-70" is="div">
                            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                            </Wrapper>
                        </Wrapper>
                    </Wrapper>
                </Wrapper>
            </Wrapper>
                </Wrapper>
            </Wrapper>
    </Wrapper></Element>
        </Element>,

    <Element canvas is={Parent} id='parentId1500' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId1600' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-506' is='parent' className='w-[100%] h-full bg-[#2A2A2A] py-10'  style={{border:'1px solid transparent'}}>
        <Wrapper canvas id='random-id-507' is='container' className='container' >
            <Wrapper canvas id='random-id-508' is='wrapper' className={`w-full h-full flex justify-center flex flex-row flex-wrap`}>
            <Wrapper canvas id='random-id-509' is='div' className={`w-[30%] min-w-[400px]  h-full flex flex-col flex items-center flex justify-center px-10  text-left`} style={{maxWidth:'600px', flex:'10%'}}>
                    <Wrapper canvas style={{ display: "inline-block", fontWeight:'400', fontFamily:'Rum Raisin' }} className={`my-5 text-[#FFD600] text-[46px]`}  id="random-id-71" is="div">
                    <Text text="Our top Services." />
                    </Wrapper>                    
                    <Wrapper canvas style={{ display: "inline-block", fontWeight:'400', fontFamily:'Rubik', lineHeight:'35.2px' }} className={`mb-5 text-[#FFFFFF] text-[22px]`} id="random-id-72" is="div">
                    <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                    </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-510' is='div' className='w-full h-full justify-center flex justify-center flex flex-col flex-wrap align-items-center' style={{flex:'60%'}}>
                <Wrapper canvas id='random-id-511' is='div' className='w-full flex flex-row flex-wrap justify-center flex justify-center' >
                
                <Wrapper canvas id='random-id-512' is='div' className='flex flex-row min-w-[300px] my-4 flex justify-center min-h-[150px] pr-8' style={{flex:'28%'}}>
                <Wrapper canvas id='random-id-728' style={{marginTop:'5px'}} is='div'>
                        <Wrapper canvas id='random-id-200128' is='div' style={{minWidth:'24px', maxWidth:'24px', minHeight:'24px', maxHeight:'24px'}} className='bg-[#FFD600]'/>
                    </Wrapper>
                        <Wrapper canvas id='random-id-513' is='div' className='h-full flex flex-col flex items-center pl-2'>
                            <Wrapper canvas style={{ display: "inline-block", fontSize:'22px', fontWeight:'700', fontFamily:'Rubik' }} className='text-[#FFD600] w-full py-2' id="random-id-73" is="div">
                                <Text text="Service 1" />
                            </Wrapper> 
                            <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', lineHeight:'28.8px' }} className='text-[#FFFFFF]' id="random-id-74" is="div">
                            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                            </Wrapper>
                        </Wrapper>
                    </Wrapper>
                
                    <Wrapper canvas id='random-id-514' is='div' className='flex flex-row min-w-[300px] my-4 flex justify-center min-h-[150px] pr-8' style={{flex:'28%'}}>
                    <Wrapper canvas id='random-id-729' style={{marginTop:'5px'}} is='div'>
                        <Wrapper canvas id='random-id-200129' is='div' style={{minWidth:'24px', maxWidth:'24px', minHeight:'24px', maxHeight:'24px'}} className='bg-[#FFD600]'/>
                    </Wrapper>
                        <Wrapper canvas id='random-id-515' is='div' className='h-full flex flex-col flex items-center pl-2'>
                            <Wrapper canvas style={{ display: "inline-block", fontSize:'22px', fontWeight:'700', fontFamily:'Rubik' }} className='text-[#FFD600] w-full py-2' id="random-id-75" is="div">
                                <Text text="Service 2" />
                            </Wrapper> 
                            <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', lineHeight:'28.8px' }} className='text-[#FFFFFF]' id="random-id-76" is="div">
                            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                            </Wrapper>
                        </Wrapper>
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-516' is='div' className='w-full flex flex-row flex-wrap justify-center flex justify-center' >
                <Wrapper canvas id='random-id-517' is='div' className='flex flex-row min-w-[300px] my-4 flex justify-center min-h-[150px] pr-8' style={{flex:'28%'}}>
                <Wrapper canvas id='random-id-730' style={{marginTop:'5px'}} is='div'>
                        <Wrapper canvas id='random-id-200130' is='div' style={{minWidth:'24px', maxWidth:'24px', minHeight:'24px', maxHeight:'24px'}} className='bg-[#FFD600]'/>
                    </Wrapper>
                        <Wrapper canvas id='random-id-518' is='div' className='h-full flex flex-col flex items-center pl-2'>
                            <Wrapper canvas style={{ display: "inline-block", fontSize:'22px', fontWeight:'700', fontFamily:'Rubik' }} className='text-[#FFD600] w-full py-2' id="random-id-77" is="div">
                                <Text text="Service 3" />
                            </Wrapper> 
                            <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', lineHeight:'28.8px' }} className='text-[#FFFFFF]' id="random-id-78" is="div">
                            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                            </Wrapper>
                        </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-519' is='div' className='flex flex-row min-w-[300px] my-4 flex justify-center min-h-[150px] pr-8' style={{flex:'28%'}}>
                    <Wrapper canvas id='random-id-731' style={{marginTop:'5px'}} is='div'>
                        <Wrapper canvas id='random-id-200131' is='div' style={{minWidth:'24px', maxWidth:'24px', minHeight:'24px', maxHeight:'24px'}} className='bg-[#FFD600]'/>
                    </Wrapper>
                        <Wrapper canvas id='random-id-520' is='div' className='h-full flex flex-col flex items-center pl-2'>
                            <Wrapper canvas style={{ display: "inline-block", fontSize:'22px', fontWeight:'700', fontFamily:'Rubik' }} className='text-[#FFD600] w-full py-2' id="random-id-79" is="div">
                                <Text text="Service 4" />
                            </Wrapper> 
                            <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', lineHeight:'28.8px' }} className='text-[#FFFFFF]' id="random-id-80" is="div">
                            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                            </Wrapper>
                        </Wrapper>
                    </Wrapper>
                </Wrapper>
            </Wrapper>
            </Wrapper>
        </Wrapper>
    </Wrapper></Element>
        </Element>,

<Element canvas is={Parent} id='parentId1900' className="w-[100%] h-auto" >
<Element canvas is={Parent} id='parentId2000' className="w-[100%] h-auto" row={true} >
    <Wrapper canvas id='random-id-10000' is='parent' className='w-[100%] h-full bg-[#F7FBFE] py-10' >
<Wrapper canvas id='random-id-100002' is='container' className='container'>
<Wrapper canvas id='random-id-100003' is='wrapper' className={`w-[100%] h-full flex justify-center flex-row flex-wrap `}>
<Wrapper canvas id='random-id-100004' is='div' className={`w-[100%] h-full flex flex-col flex items-center flex justify-center px-10  text-left `} style={{flex:'38%'}}>
        <Wrapper canvas style={{ display: "inline-block", fontWeight:'700', fontFamily:'Rubik' }} className={`my-5 text-[#6064D2] text-[46px]`} id="random-id-100006" is="div">
        <Text text="Our top Service" />
        </Wrapper>
        <Wrapper canvas style={{ display: "inline-block", fontWeight:'400', fontFamily:'Rubik', lineHeight:'35.2px' }} className={`mb-5 text-[#000000] text-[22px]`} id="random-id-1000007" is="div">
        <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
        </Wrapper>
</Wrapper>
<Wrapper canvas id='random-id-1000008' is='div' className='w-full h-full justify-center flex justify-center flex flex-col flex-wrap align-items-center ' style={{flex:'60%'}}>
    <Wrapper canvas id='random-id-100009' is='div' className='w-full flex flex-row flex-wrap justify-center flex justify-center' >
    
<Wrapper canvas id='random-id-202020' is='div' className='flex flex-row min-w-[300px] my-4 flex justify-center min-h-[150px] pr-8' style={{flex:'28%'}}>
    <Wrapper canvas id='random-id-303030' style={{marginTop:'5px'}} is='div'>
        <Wrapper canvas id='random-id-200132' is='div' style={{minWidth:'24px', maxWidth:'24px', minHeight:'24px', maxHeight:'24px'}} className='bg-[#B20312]'/>
    </Wrapper>
        <Wrapper canvas id='random-id-404040' is='div' className='h-full flex flex-col flex items-center pl-2'>
            <Wrapper canvas style={{ display: "inline-block", fontSize:'22px', fontWeight:'700', fontFamily:'Rubik' }} className='justify-start flex text-[#B20312] w-full py-2'  id="random-id-2020212" is="div">
                <Text text="Service 1" />
            </Wrapper>
            <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', lineHeight:'28.8px' }} className='text-[#100103]' id="random-id-20293" is="div">
            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
            </Wrapper>
        </Wrapper>
    </Wrapper>
    
<Wrapper canvas id='random-id-505050' is='div' className='flex flex-col flex justify-center relative' style={{flex:'28%'}}>
<Wrapper canvas id='random-id-606060' is='div' className='flex flex-row min-w-[300px] my-4 flex justify-center min-h-[150px] pr-8' style={{flex:'28%'}}>
<Wrapper canvas id='random-id-707070' style={{marginTop:'5px'}} is='div'>
        <Wrapper canvas id='random-id-200133' is='div' style={{minWidth:'24px', maxWidth:'24px', minHeight:'24px', maxHeight:'24px'}} className='bg-[#B20312]'/>
    </Wrapper>
        <Wrapper canvas id='random-id-808080' is='div' className='h-full flex flex-col flex items-center pl-2'>
            <Wrapper canvas style={{ display: "inline-block", fontSize:'22px', fontWeight:'700', fontFamily:'Rubik' }} className='text-[#B20312] w-full py-2' id="random-id-3048" is="div">
                <Text text="Service 2" />
            </Wrapper> 
            <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', lineHeight:'28.8px' }} className='text-[#100103]' id="random-id-29383" is="div">
            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
            </Wrapper>
        </Wrapper>
    </Wrapper>
    </Wrapper>
    </Wrapper>
    <Wrapper canvas id='random-id-897837' is='div' className='w-full flex flex-row flex-wrap justify-center flex justify-center' >
    <Wrapper canvas id='random-id-397483' is='div' className='flex flex-row min-w-[300px] my-4 flex justify-center min-h-[150px] pr-8' style={{flex:'28%'}}>
    <Wrapper canvas id='random-id-139819' style={{marginTop:'5px'}} is='div'>
        <Wrapper canvas id='random-id-200134' is='div' style={{minWidth:'24px', maxWidth:'24px', minHeight:'24px', maxHeight:'24px'}} className='bg-[#B20312]'/>
    </Wrapper>
        <Wrapper canvas id='random-id-329348' is='div' className='h-full flex flex-col flex items-center pl-2'>
            <Wrapper canvas style={{ display: "inline-block", fontSize:'22px', fontWeight:'700', fontFamily:'Rubik'}} className='text-[#B20312] w-full py-2' id="random-id-3298" is="div">
                <Text text="Service 3" />
            </Wrapper> 
            <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', lineHeight:'28.8px' }} className='text-[#100103]' id="random-id-1209" is="div">
            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
            </Wrapper>
        </Wrapper>
    </Wrapper>
    <Wrapper canvas id='random-id-121029' is='div' className='flex flex-row min-w-[300px] my-4 flex justify-center min-h-[150px] pr-8' style={{flex:'28%'}}>
    <Wrapper canvas id='random-id-12127' style={{marginTop:'5px'}} is='div'>
        <Wrapper canvas id='random-id-200135' is='div' style={{minWidth:'24px', maxWidth:'24px', minHeight:'24px', maxHeight:'24px'}} className='bg-[#B20312]'/>
    </Wrapper>
        <Wrapper canvas id='random-id-13514' is='div' className='h-full flex flex-col flex items-center pl-2'>
            <Wrapper canvas style={{ display: "inline-block", fontSize:'22px', fontWeight:'700', fontFamily:'Rubik' }} className='text-[#B20312] w-full py-2' id="random-id-323232" is="div">
                <Text text="Service 4" />
            </Wrapper> 
            <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', lineHeight:'28.8px' }} className='text-[#100103]' id="random-id-238278" is="div">
            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
            </Wrapper>
        </Wrapper>
    </Wrapper>
    </Wrapper>
</Wrapper>
</Wrapper>
</Wrapper>
</Wrapper></Element>
</Element>,


    <Element canvas is={Parent} id='parentId1900' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId2000' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-930' is='parent' className='w-[100%] h-full bg-[#FEFBF7] py-10' >
    <Wrapper canvas id='random-id-931' is='container' className='container'>
        <Wrapper canvas id='random-id-932' is='wrapper' className={`w-[100%] h-full flex justify-center flex-row flex-wrap `}>
        <Wrapper canvas id='random-id-933' is='div' className={`w-[100%] h-full flex flex-col flex items-center flex justify-center px-10  text-left `} style={{flex:'38%'}}>
                <Wrapper canvas style={{ display: "inline-block", fontWeight:'700', fontFamily:'Rubik' }} className={`my-5 text-[#A25738] text-[46px]`} id="random-id-934" is="div">
                <Text text="Our top Service" />
                </Wrapper>
                <Wrapper canvas style={{ display: "inline-block", fontWeight:'400', fontFamily:'Rubik', lineHeight:'35.2px' }} className={`mb-5 text-[#000000] text-[22px]`} id="random-id-935" is="div">
                <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                </Wrapper>
        </Wrapper>
        <Wrapper canvas id='random-id-936' is='div' className='w-full h-full justify-center flex justify-center flex flex-col flex-wrap align-items-center ' style={{flex:'60%'}}>
            <Wrapper canvas id='random-id-937' is='div' className='w-full flex flex-row flex-wrap justify-center flex justify-center' >
            
        <Wrapper canvas id='random-id-938' is='div' className='flex flex-row min-w-[300px] my-4 flex justify-center min-h-[150px] pr-8' style={{flex:'28%'}}>
            <Wrapper canvas id='random-id-939' style={{marginTop:'5px'}} is='div'>
                <Wrapper id='random-id-200010060' is='div' style={{minWidth:'24px', maxWidth:'24px', minHeight:'24px', maxHeight:'24px'}} className='bg-[#A25738]'/>
            </Wrapper>
                <Wrapper canvas id='random-id-940' is='div' className='h-full flex flex-col flex items-center pl-2'>
                    <Wrapper canvas style={{ display: "inline-block", fontSize:'22px', fontWeight:'700', fontFamily:'Rubik' }} className='justify-start flex text-[#A25738] w-full py-2'  id="random-id-941" is="div">
                        <Text text="Service 1" />
                    </Wrapper>
                    <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', lineHeight:'28.8px' }} className='text-[#100103]' id="random-id-942" is="div">
                    <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                    </Wrapper>
                </Wrapper>
            </Wrapper>
            
        <Wrapper canvas id='random-id-943' is='div' className='flex flex-col flex justify-center relative' style={{flex:'28%'}}>
        <Wrapper canvas id='random-id-944' is='div' className='flex flex-row min-w-[300px] my-4 flex justify-center min-h-[150px] pr-8' style={{flex:'28%'}}>
        <Wrapper canvas id='random-id-945' style={{marginTop:'5px'}} is='div'>
                <Wrapper id='random-id-200010061' is='div' style={{minWidth:'24px', maxWidth:'24px', minHeight:'24px', maxHeight:'24px'}} className='bg-[#A25738]'/>
            </Wrapper>
                <Wrapper canvas id='random-id-946' is='div' className='h-full flex flex-col flex items-center pl-2'>
                    <Wrapper canvas style={{ display: "inline-block", fontSize:'22px', fontWeight:'700', fontFamily:'Rubik' }} className='text-[#A25738] w-full py-2' id="random-id-946" is="div">
                        <Text text="Service 2" />
                    </Wrapper> 
                    <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', lineHeight:'28.8px' }} className='text-[#100103]' id="random-id-947" is="div">
                    <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                    </Wrapper>
                </Wrapper>
            </Wrapper>
            </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-948' is='div' className='w-full flex flex-row flex-wrap justify-center flex justify-center' >
            <Wrapper canvas id='random-id-949' is='div' className='flex flex-row min-w-[300px] my-4 flex justify-center min-h-[150px] pr-8' style={{flex:'28%'}}>
            <Wrapper canvas id='random-id-950' style={{marginTop:'5px'}} is='div'>
                <Wrapper id='random-id-200010062' is='div' style={{minWidth:'24px', maxWidth:'24px', minHeight:'24px', maxHeight:'24px'}} className='bg-[#A25738]'/>
            </Wrapper>
                <Wrapper canvas id='random-id-951' is='div' className='h-full flex flex-col flex items-center pl-2'>
                    <Wrapper canvas style={{ display: "inline-block", fontSize:'22px', fontWeight:'700', fontFamily:'Rubik'}} className='text-[#A25738] w-full py-2' id="random-id-952" is="div">
                        <Text text="Service 3" />
                    </Wrapper> 
                    <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', lineHeight:'28.8px' }} className='text-[#100103]' id="random-id-953" is="div">
                    <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                    </Wrapper>
                </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-954' is='div' className='flex flex-row min-w-[300px] my-4 flex justify-center min-h-[150px] pr-8' style={{flex:'28%'}}>
            <Wrapper canvas id='random-id-955' style={{marginTop:'5px'}} is='div'>
                <Wrapper id='random-id-200010063' is='div' style={{minWidth:'24px', maxWidth:'24px', minHeight:'24px', maxHeight:'24px'}} className='bg-[#A25738]'/>
            </Wrapper>
                <Wrapper canvas id='random-id-956' is='div' className='h-full flex flex-col flex items-center pl-2'>
                    <Wrapper canvas style={{ display: "inline-block", fontSize:'22px', fontWeight:'700', fontFamily:'Rubik' }} className='text-[#A25738] w-full py-2' id="random-id-957" is="div">
                        <Text text="Service 4" />
                    </Wrapper> 
                    <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', lineHeight:'28.8px' }} className='text-[#100103]' id="random-id-958" is="div">
                    <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                    </Wrapper>
                </Wrapper>
            </Wrapper>
            </Wrapper>
        </Wrapper>
        </Wrapper>
    </Wrapper>
    </Wrapper></Element>
        </Element>

    ]

    
const [sel, setSel] = useState(selected)

function makeOdd(number) {
  setProp((prop) => {
    setSel(100);
  }, 1000);

  setTimeout(() => {
    setProp((prop) => {
      setSel(number)
    }, 1000);
  }, 100);


  
}

useEffect(() => {
if(selected || selected >= 0) {
  makeOdd(selected);
}
},[selected])


    return (
        <div style={{ padding:enabled? "10px" : "0px", display:"flex", justifyContent:"center", alignItems:"center", width:'100%', height:'100%'}} ref={connect}>
         {sel == 100 ? (
          <div style={{height:"85vh", display:"flex", justifyContent:"center", alignItems:"center"}}>
          <BounceLoader
                  size={100}
                  color={"white"}
                  loading={true}
                />
          </div>
         ) : (
          styles[sel]
         )} 
    </div>
    )
}

Services_1.craft = {
    displayName: "Services 1",
    props: {
       selected: 1, // here is the default value of the selected style
       length:9,
isBlock: true
      },
      rules: {
        canDrag: () => true,
        canDrop: () => true,
      },
      related: {
        toolbar: NewServicesSettings,
      },
  };
