    import { useNode, Element, useEditor } from '@craftjs/core';
import React, {useState, useEffect, useRef} from 'react';
import { NewWhyUsSettings } from './newWhyusSetting';
import { Text } from '../../basic/Text';
import { Parent } from '../../Parent/index.js';
import { Wrapper } from '../../wrapper/index.js';
import { BounceLoader } from 'react-spinners';


export const WhyUs_1 = ({selected}) => {

    const { 
        actions: { setProp },
          connectors: {
            connect
          } 
      } = useNode();
    
      
      const {
        enabled,
      } = useEditor((state, query) => ({
        enabled: state.options.enabled,
     
      }));

    const styles = [ 

        <Element canvas is={Parent} id='parentId100' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId200' className="w-[100%] h-auto" row={true} >
            <Wrapper canvas id='random-id-400' is='parent' className='w-[100%] h-full bg-[white] py-10' style={{border:'1px solid transparent'}}>
            <Wrapper canvas id='random-id-401' is='container' className='container' >
                <Wrapper canvas id='random-id-402' is='wrapper' className='w-full h-full flex  flex-wrap justify-center' style={{maxWidth:'1300px', margin:'auto auto'}}>
                
                <Wrapper canvas id='random-id-403' is='div' className='w-full h-full flex mx-auto my-auto flex-col' style={{ flex:'49%', minWidth:'300px'}}>


                    <Wrapper canvas id='random-id-404' is='div' className={`mb-5 text-[#100103] text-[38px]`} style={{ fontWeight:'700', fontFamily:'Rubik'}}>
                    <Wrapper canvas style={{display:'inline-block'}} id='random-id-1' is='div'>
                            <Text text='Why Us'/>
                    </Wrapper>
                    <Wrapper canvas style={{display:'inline-block', color:'#B20312'}} id='random-id-2' is='div'>
                            <Text text='.'/>
                    </Wrapper>
                    </Wrapper>
                    <Wrapper canvas style={{display:'inline-block', fontWeight:'400', fontFamily:'Rubik', lineHeight:'35.2px'}} className={`text-[#000000] text-[16px]`} id='random-id-3' is='div'>
                        <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.
                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'/>
                    </Wrapper>


                </Wrapper>

                <Wrapper canvas id='random-id-405' is='div' className='w-[100%] h-full justify-center mx-auto my-auto align-items-center flex flex-col flex-wrap' style={{flex:'49%', minWidth:'300px'}} >
                    <Wrapper canvas id='random-id-406' is='div' className='w-[100%] flex  flex-wrap justify-center mx-auto m-2' >
                    
                    <Wrapper canvas id='random-id-407' is='div' className='m-2 flex  min-h-[100px]' style={{flex:'30%', minWidth:'200px', border:'1px solid #0000000A'}}>
                        <Wrapper canvas style={{display:'inline-block', fontSize:'26px', fontWeight:'700', fontFamily:'Rubik'}} className='my-auto mx-4  text-[#B20312]' id='random-id-4' is='div'>
                            <Text text='+22'/>
                        </Wrapper>
                        <Wrapper canvas id='random-id-408' is='div' className='mx-auto my-auto flex flex-col' style={{fontSize:'14px', fontWeight:'700', fontFamily:'Rubik', lineHeight:'22.4px', color:'#B20312'}}>
                        <Wrapper canvas style={{display:'inline-block'}} id='random-id-5' is='div'>
                            <Text text='Lorem ipsum'/>
                        </Wrapper> 
                        <Wrapper canvas style={{display:'inline-block', fontSize:'12px', fontWeight:'400', fontFamily:'Rubik', lineHeight:'19.2px', color:'#100103'}} className='mx-auto my-auto' id='random-id-6' is='div'>
                            <Text text='Dolor sit amet, consectetur adipiscing elit'/>
                        </Wrapper>
                        </Wrapper>
                    </Wrapper>
                    
                    <Wrapper canvas id='random-id-409' is='div' className='m-2 flex  min-h-[100px]' style={{flex:'30%', minWidth:'200px', border:'1px solid #0000000A'}}>
                            <Wrapper canvas style={{display:'inline-block', fontSize:'26px', fontWeight:'700', fontFamily:'Rubik'}} className='my-auto mx-4  text-[#B20312]' id='random-id-7' is='div'>
                            <Text text='+22'/>
                    </Wrapper>
                        <Wrapper canvas id='random-id-410' is='div' className='mx-auto my-auto flex flex-col' style={{fontSize:'14px', fontWeight:'700', fontFamily:'Rubik', lineHeight:'22.4px', color:'#B20312'}}>
                        <Wrapper canvas style={{display:'inline-block'}} id='random-id-8' is='div'>
                            <Text text='Lorem ipsum'/>
                        </Wrapper> 
                        <Wrapper canvas style={{display:'inline-block', fontSize:'12px', fontWeight:'400', fontFamily:'Rubik', lineHeight:'19.2px', color:'#100103'}} className='mx-auto my-auto' id='random-id-9' is='div'>
                            <Text text='Dolor sit amet, consectetur adipiscing elit'/>
                        </Wrapper>
                        </Wrapper>
                    </Wrapper>
                    </Wrapper>


                    <Wrapper canvas id='random-id-411' is='div' className='w-[100%] flex  flex-wrap justify-center mx-auto m-2' >
                    <Wrapper canvas id='random-id-412' is='div' className='m-2 flex  min-h-[100px]' style={{flex:'30%', minWidth:'200px', border:'1px solid #0000000A'}}>
                            <Wrapper canvas style={{display:'inline-block', fontSize:'26px', fontWeight:'700', fontFamily:'Rubik'}} className='my-auto mx-4  text-[#B20312]' id='random-id-10' is='div'>
                            <Text text='+22'/>
                    </Wrapper>
                        <Wrapper canvas id='random-id-413' is='div' className='mx-auto my-auto flex flex-col' style={{fontSize:'14px', fontWeight:'700', fontFamily:'Rubik', lineHeight:'22.4px', color:'#B20312'}}>
                        <Wrapper canvas style={{display:'inline-block'}} id='random-id-11' is='div'>
                            <Text text='Lorem ipsum'/>
                        </Wrapper> 
                            <Wrapper canvas style={{display:'inline-block', fontSize:'12px', fontWeight:'400', fontFamily:'Rubik', lineHeight:'19.2px', color:'#100103'}} className='mx-auto my-auto' id='random-id-12' is='div'>
                            <Text text='Dolor sit amet, consectetur adipiscing elit'/>
                        </Wrapper>
                        </Wrapper>
                    </Wrapper>


                    <Wrapper canvas id='random-id-414' is='div' className='m-2 flex  min-h-[100px]' style={{flex:'30%', minWidth:'200px', border:'1px solid #0000000A'}}>
                            <Wrapper canvas style={{display:'inline-block', fontSize:'26px', fontWeight:'700', fontFamily:'Rubik'}} className='my-auto mx-4  text-[#B20312]' id='random-id-13' is='div'>
                            <Text text='+22'/>
                    </Wrapper>
                        <Wrapper canvas id='random-id-415' is='div' className='mx-auto my-auto flex flex-col' style={{fontSize:'14px', fontWeight:'700', fontFamily:'Rubik', lineHeight:'22.4px', color:'#B20312'}}>
                        <Wrapper canvas style={{display:'inline-block'}} id='random-id-14' is='div'>
                            <Text text='Lorem ipsum'/>
                        </Wrapper> 
                            <Wrapper canvas style={{display:'inline-block', fontSize:'12px', fontWeight:'400', fontFamily:'Rubik', lineHeight:'19.2px', color:'#100103'}} className='mx-auto my-auto' id='random-id-15' is='div'>
                            <Text text='Dolor sit amet, consectetur adipiscing elit'/>
                        </Wrapper>
                        </Wrapper>
                    </Wrapper>
                    </Wrapper>
                </Wrapper>


                </Wrapper>
            </Wrapper>
        </Wrapper></Element>
        </Element>
        ,

        <Element canvas is={Parent} id='parentId300' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId400' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-416' is='parent' className='w-[100%] h-full  py-10' style={{border:'1px solid transparent', background:'#121826'}}>
            <Wrapper canvas id='random-id-417' is='container' className='container' >
                <Wrapper canvas id='random-id-418' is='wrapper' className='w-full h-full flex  flex-wrap justify-center' style={{maxWidth:'1300px', margin:'auto auto'}}>
                
                
                <Wrapper canvas id='random-id-419' is='div' className='w-full h-full flex flex-col mx-auto my-auto flex flex-col sm:text-center md:text-center lg:text-left' style={{ flex:'49%', minWidth:'300px'}}>
                    <Wrapper canvas style={{display:'inline-block', fontWeight:'700', fontFamily:'Rubik'}} className={`mb-5 IncreaseConversion2 text-[38px]`} id='random-id-16' is='div'>
                            <Text text='Why Us'/>
                    </Wrapper>
                    <Wrapper canvas style={{display:'inline-block', fontWeight:'400', fontFamily:'Rubik', lineHeight:'35.2px'}} className={`text-[#FFFFFF] text-[16px]`} id='random-id-17' is='div'>
                        <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.
                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'/>
                    </Wrapper>
                </Wrapper>


                <Wrapper canvas id='random-id-420' is='div' className='w-[100%] h-full justify-center mx-auto my-auto align-items-center flex flex-col flex-wrap' style={{flex:'49%', minWidth:'300px'}} >
                    <Wrapper canvas id='random-id-421' is='div' className='w-[100%] flex  flex-wrap justify-center mx-auto m-2' >
                    <Wrapper canvas id='random-id-422' is='div' className='m-2 flex  min-h-[100px]' style={{border: '1px solid #FFFFFF1A', flex:'30%', minWidth:'200px'}}>
                            <Wrapper canvas className='text-[#4BDBA2] mx-4 my-auto ' style={{display:'inline-block', fontSize:'26px', fontWeight:'700', fontFamily:'Rubik'}} id='random-id-18' is='div'>
                            <Text text='+22'/>
                    </Wrapper>
                        <Wrapper canvas id='random-id-423' is='div' className='mx-auto my-auto flex flex-col ' style={{fontSize:'14px', fontWeight:'700', fontFamily:'Rubik', lineHeight:'22.4px', color:'#4BDBA2'}}>
                        <Wrapper canvas style={{display:'inline-block'}} id='random-id-19' is='div'>
                            <Text text='Lorem ipsum'/>
                        </Wrapper> 
                            <Wrapper canvas style={{display:'inline-block', fontSize:'12px', fontWeight:'400', fontFamily:'Rubik', lineHeight:'19.2px', color:'#FFF'}} className='mx-auto my-auto' id='random-id-20' is='div'>
                            <Text text='Dolor sit amet, consectetur adipiscing elit'/>
                        </Wrapper>
                        </Wrapper>
                    </Wrapper>
                    
                    <Wrapper canvas id='random-id-424' is='div' className='m-2 flex  min-h-[100px]' style={{border: '1px solid #FFFFFF1A', flex:'30%', minWidth:'200px'}}>
                            <Wrapper canvas style={{display:'inline-block', fontSize:'26px', fontWeight:'700', fontFamily:'Rubik'}} className='text-[#4BDBA2] my-auto mx-4' id='random-id-21' is='div'>
                            <Text text='+22'/>
                    </Wrapper>
                        <Wrapper canvas id='random-id-425' is='div' className='mx-auto my-auto flex flex-col' style={{fontSize:'14px', fontWeight:'700', fontFamily:'Rubik', lineHeight:'22.4px', color:'#4BDBA2'}}>
                        <Wrapper canvas style={{display:'inline-block'}} id='random-id-22' is='div'>
                            <Text text='Lorem ipsum'/>
                        </Wrapper> 
                            <Wrapper canvas style={{display:'inline-block', fontSize:'12px', fontWeight:'400', fontFamily:'Rubik', lineHeight:'19.2px', color:'#FFF'}} className='mx-auto my-auto' id='random-id-23' is='div'>
                            <Text text='Dolor sit amet, consectetur adipiscing elit'/>
                        </Wrapper>
                        </Wrapper>
                    </Wrapper>
                    </Wrapper>


                    <Wrapper canvas id='random-id-426' is='div' className='w-[100%] flex  flex-wrap justify-center mx-auto m-2' >
                    <Wrapper canvas id='random-id-427' is='div' className='m-2 flex  min-h-[100px]' style={{border: '1px solid #FFFFFF1A', flex:'30%', minWidth:'200px'}}>
                            <Wrapper canvas style={{display:'inline-block', fontSize:'26px', fontWeight:'700', fontFamily:'Rubik'}} className='text-[#4BDBA2] my-auto mx-4' id='random-id-24' is='div'>
                            <Text text='+22'/>
                    </Wrapper>
                        <Wrapper canvas id='random-id-428' is='div' className='mx-auto my-auto flex flex-col' style={{fontSize:'14px', fontWeight:'700', fontFamily:'Rubik', lineHeight:'22.4px', color:'#4BDBA2'}}>
                        <Wrapper canvas style={{display:'inline-block'}} id='random-id-25' is='div'>
                            <Text text='Lorem ipsum'/>
                        </Wrapper> 
                            <Wrapper canvas style={{display:'inline-block', fontSize:'12px', fontWeight:'400', fontFamily:'Rubik', lineHeight:'19.2px', color:'#FFF'}} className='mx-auto my-auto' id='random-id-26' is='div'>
                            <Text text='Dolor sit amet, consectetur adipiscing elit'/>
                        </Wrapper>
                        </Wrapper>
                    </Wrapper>

                    
                    <Wrapper canvas id='random-id-429' is='div' className='m-2 flex  min-h-[100px]' style={{border: '1px solid #FFFFFF1A', flex:'30%', minWidth:'200px'}}>
                            <Wrapper canvas style={{display:'inline-block', fontSize:'26px', fontWeight:'700', fontFamily:'Rubik'}} className='text-[#4BDBA2] my-auto mx-4' id='random-id-27' is='div'>
                            <Text text='+22'/>
                    </Wrapper>
                        <Wrapper canvas id='random-id-430' is='div' className='mx-auto my-auto flex flex-col' style={{fontSize:'14px', fontWeight:'700', fontFamily:'Rubik', lineHeight:'22.4px', color:'#4BDBA2'}}>
                        <Wrapper canvas style={{display:'inline-block'}} id='random-id-28' is='div'>
                            <Text text='Lorem ipsum'/>
                        </Wrapper> 
                            <Wrapper canvas style={{display:'inline-block', fontSize:'12px', fontWeight:'400', fontFamily:'Rubik', lineHeight:'19.2px', color:'#FFF'}} className='mx-auto my-auto' id='random-id-29' is='div'>
                            <Text text='Dolor sit amet, consectetur adipiscing elit'/>
                        </Wrapper>
                        </Wrapper>
                    </Wrapper>
                    </Wrapper>
                </Wrapper>


                </Wrapper>
            </Wrapper>
        </Wrapper></Element>
        </Element>,

         <Element canvas is={Parent} id='parentId500' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId600' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-431' is='parent' className='w-[100%] h-full bg-[#213156] py-10' style={{border:'1px solid transparent'}}>
            <Wrapper canvas id='random-id-432' is='container' className='container' >
                <Wrapper canvas id='random-id-433' is='wrapper' className='w-full h-full flex  flex-wrap justify-center' style={{maxWidth:'1300px', margin:'auto auto'}}>
                <Wrapper canvas id='random-id-434' is='div' className='w-full h-full flex flex-col my-auto  mx-auto sm:text-center md:text-cenetr lg:text-left' style={{ flex:'49%', minWidth:'300px'}}>
                    <Wrapper canvas style={{display:'inline-block', fontWeight:'700', fontFamily:'Open Sans'}} className={`mb-5 text-[#EA6EF4] text-[38px]`} id='random-id-30' is='div'>
                            <Text text='Why Us'/>
                    </Wrapper>
                    <Wrapper canvas style={{display:'inline-block', fontWeight:'400', fontFamily:'Open Sans', lineHeight:'35.2px'}} className={`text-[#FFFFFF] text-[16px]`} id='random-id-31' is='div'>
                        <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.
                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'/>
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-435' is='div' className='w-[100%] h-full justify-center mx-auto my-auto align-items-center  flex flex-col flex-wrap' style={{flex:'49%', minWidth:'300px'}}>
                    <Wrapper canvas id='random-id-436' is='div' className='w-[100%] flex  flex-wrap justify-center mx-auto m-2' >
                    
                    <Wrapper canvas id='random-id-437' is='div' className='m-2 flex  min-h-[100px]' style={{flex:'30%', minWidth:'200px', border: '1px solid #FFFFFF1A'}}>
                            <Wrapper canvas className='my-auto mx-4  text-[#EA6EF4]' style={{display:'inline-block', fontSize:'26px', fontWeight:'700', fontFamily:'Open Sans'}} id='random-id-32' is='div'>
                            <Text text='+22'/>
                    </Wrapper>
                        <Wrapper canvas id='random-id-438' is='div' className='mx-auto my-auto flex flex-col flex-col flex' style={{fontSize:'14px', fontWeight:'700', fontFamily:'Open Sans', lineHeight:'22.4px', color:'#EA6EF4'}}>
                        <Wrapper canvas style={{display:'inline-block'}} id='random-id-33' is='div'>
                            <Text text='Lorem ipsum'/>
                        </Wrapper> 
                            <Wrapper canvas className='mx-auto my-auto' style={{display:'inline-block', fontSize:'12px', fontWeight:'400', fontFamily:'Open Sans', lineHeight:'19.2px', color:'#FFFFFF'}} id='random-id-34' is='div'>
                            <Text text='Dolor sit amet, consectetur adipiscing elit'/>
                        </Wrapper>
                        </Wrapper>
                    </Wrapper>
                    
                    <Wrapper canvas id='random-id-439' is='div' className='m-2 flex  min-h-[100px]' style={{flex:'30%', minWidth:'200px', border: '1px solid #FFFFFF1A'}}>
                            <Wrapper canvas className='my-auto mx-4  text-[#EA6EF4]' style={{display:'inline-block', fontSize:'26px', fontWeight:'700', fontFamily:'Open Sans'}} id='random-id-35' is='div'>
                            <Text text='+22'/>
                    </Wrapper>
                        <Wrapper canvas id='random-id-440' is='div' className='mx-auto my-auto flex flex-col flex-col flex' style={{fontSize:'14px', fontWeight:'700', fontFamily:'Open Sans', lineHeight:'22.4px', color:'#EA6EF4'}}>
                        <Wrapper canvas style={{display:'inline-block'}} id='random-id-36' is='div'>
                            <Text text='Lorem ipsum'/>
                        </Wrapper> 
                            <Wrapper canvas className='mx-auto my-auto' style={{display:'inline-block', fontSize:'12px', fontWeight:'400', fontFamily:'Open Sans', lineHeight:'19.2px', color:'#FFFFFF'}} id='random-id-37' is='div'>
                            <Text text='Dolor sit amet, consectetur adipiscing elit'/>
                        </Wrapper>
                        </Wrapper>
                    </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-441' is='div' className='w-[100%] flex  flex-wrap justify-center mx-auto m-2' >
                    <Wrapper canvas id='random-id-442' is='div' className='m-2 flex  min-h-[100px]' style={{flex:'30%', minWidth:'200px', border: '1px solid #FFFFFF1A'}}>
                            <Wrapper canvas className='my-auto mx-4  text-[#EA6EF4]' style={{display:'inline-block', fontSize:'26px', fontWeight:'700', fontFamily:'Open Sans'}} id='random-id-38' is='div'>
                            <Text text='+22'/>
                    </Wrapper>
                        <Wrapper canvas id='random-id-443' is='div' className='mx-auto my-auto flex flex-col flex-col flex' style={{fontSize:'14px', fontWeight:'700', fontFamily:'Open Sans', lineHeight:'22.4px', color:'#EA6EF4'}}>
                        <Wrapper canvas style={{display:'inline-block'}} id='random-id-39' is='div'>
                            <Text text='Lorem ipsum'/>
                        </Wrapper> 
                            <Wrapper canvas className='mx-auto my-auto' style={{display:'inline-block', fontSize:'12px', fontWeight:'400', fontFamily:'Open Sans', lineHeight:'19.2px', color:'#FFFFFF'}} id='random-id-40' is='div'>
                            <Text text='Dolor sit amet, consectetur adipiscing elit'/>
                        </Wrapper>
                        </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-444' is='div' className='m-2 flex  min-h-[100px]' style={{flex:'30%', minWidth:'200px', border: '1px solid #FFFFFF1A'}}>
                            <Wrapper canvas className='my-auto mx-4  text-[#EA6EF4]' style={{display:'inline-block', fontSize:'26px', fontWeight:'700', fontFamily:'Open Sans'}} id='random-id-41' is='div'>
                            <Text text='+22'/>
                    </Wrapper>
                        <Wrapper canvas id='random-id-445' is='div' className='mx-auto my-auto flex flex-col flex-col flex' style={{fontSize:'14px', fontWeight:'700', fontFamily:'Open Sans', lineHeight:'22.4px', color:'#EA6EF4'}}>
                        <Wrapper canvas style={{display:'inline-block'}} id='random-id-42' is='div'>
                            <Text text='Lorem ipsum'/>
                        </Wrapper> 
                            <Wrapper canvas className='mx-auto my-auto' style={{display:'inline-block', fontSize:'12px', fontWeight:'400', fontFamily:'Open Sans', lineHeight:'19.2px', color:'#FFFFFF'}} id='random-id-43' is='div'>
                            <Text text='Dolor sit amet, consectetur adipiscing elit'/>
                        </Wrapper>
                        </Wrapper>
                    </Wrapper>
                    </Wrapper>
                </Wrapper>
                </Wrapper>
            </Wrapper>
        </Wrapper></Element>
        </Element>,
        
        

        <Element canvas is={Parent} id='parentId700' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId800' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-446' is='parent' className='w-[100%] h-full bg-[#DFFFF9] py-10' style={{border:'1px solid transparent'}}>
        <Wrapper canvas id='random-id-447' is='container' className='container' >
            <Wrapper canvas id='random-id-448' is='wrapper' className='w-full h-full flex  flex-wrap justify-center' style={{maxWidth:'1300px', margin:'auto auto'}}>
            <Wrapper canvas id='random-id-449' is='div' className='w-full h-full flex flex-col my-auto  mx-auto sm:text-center md:text-cenetr lg:text-left' style={{ flex:'49%', minWidth:'300px'}}>
                <Wrapper canvas style={{display:'inline-block', fontWeight:'700', fontFamily:'Zing Script Rust SemiBold Demo', fontStyle:'italic'}} className={`mb-5 text-[#31A993] text-[38px]`} id='random-id-44' is='div'>
                            <Text text='Why Us'/>
                    </Wrapper>
                <Wrapper canvas style={{display:'inline-block', fontWeight:'400', fontFamily:'Montserrat', lineHeight:'35.2px'}} className={`text-[#031815] text-[16px]`} id='random-id-45' is='div'>
                        <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.
                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'/>
                    </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-450' is='div' className='w-[100%] h-full justify-center mx-auto my-auto align-items-center  flex flex-col flex-wrap' style={{flex:'49%', minWidth:'300px'}}>
                <Wrapper canvas id='random-id-451' is='div' className='w-[100%] flex  flex-wrap justify-center mx-auto m-2' >
                
                <Wrapper canvas id='random-id-452' is='div' className='m-2 flex  min-h-[100px]' style={{flex:'30%', minWidth:'200px', borderRadius:'30px', border:'1px solid #31A99333'}}>
                        <Wrapper canvas className='my-auto mx-4  text-[#31A993]' style={{fontSize:'26px', fontWeight:'700', fontFamily:'Montserrat' ,display:'inline-block'}} id='random-id-46' is='div'>
                            <Text text='+22'/>
                    </Wrapper>
                    <Wrapper canvas id='random-id-453' is='div' className='mx-auto my-auto flex flex-col flex-col flex' style={{fontSize:'14px', fontWeight:'700', fontFamily:'Montserrat', lineHeight:'22.4px', color:'#31A993'}}>
                    <Wrapper canvas style={{display:'inline-block'}} id='random-id-47' is='div'>
                            <Text text='Lorem ipsum'/>
                        </Wrapper> 
                        <Wrapper canvas className='mx-auto my-auto' style={{display:'inline-block', fontSize:'12px', fontWeight:'400', fontFamily:'Montserrat', lineHeight:'19.2px', color:'#031815'}} id='random-id-48' is='div'>
                            <Text text='Dolor sit amet, consectetur adipiscing elit'/>
                        </Wrapper>
                    </Wrapper>
                </Wrapper>
                
                <Wrapper canvas id='random-id-454' is='div' className='m-2 flex  min-h-[100px]' style={{flex:'30%', minWidth:'200px', borderRadius:'30px', border:'1px solid #31A99333'}}>
                        <Wrapper canvas className='my-auto mx-4  text-[#31A993]' style={{fontSize:'26px', fontWeight:'700', fontFamily:'Montserrat' ,display:'inline-block'}} id='random-id-49' is='div'>
                            <Text text='+22'/>
                    </Wrapper>
                    <Wrapper canvas id='random-id-455' is='div' className='mx-auto my-auto flex flex-col flex-col flex' style={{fontSize:'14px', fontWeight:'700', fontFamily:'Montserrat', lineHeight:'22.4px', color:'#31A993'}}>
                    <Wrapper canvas style={{display:'inline-block'}} id='random-id-50' is='div'>
                            <Text text='Lorem ipsum'/>
                        </Wrapper> 
                        <Wrapper canvas className='mx-auto my-auto' style={{display:'inline-block', fontSize:'12px', fontWeight:'400', fontFamily:'Montserrat', lineHeight:'19.2px', color:'#031815'}} id='random-id-51' is='div'>
                            <Text text='Dolor sit amet, consectetur adipiscing elit'/>
                        </Wrapper>
                    </Wrapper>
                </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-456' is='div' className='w-[100%] flex  flex-wrap justify-center mx-auto m-2' >
                <Wrapper canvas id='random-id-457' is='div' className='m-2 flex  min-h-[100px]' style={{flex:'30%', minWidth:'200px', borderRadius:'30px', border:'1px solid #31A99333'}}>
                        <Wrapper canvas className='my-auto mx-4  text-[#31A993]' style={{fontSize:'26px', fontWeight:'700', fontFamily:'Montserrat' ,display:'inline-block'}} id='random-id-52' is='div'>
                            <Text text='+22'/>
                    </Wrapper>
                    <Wrapper canvas id='random-id-458' is='div' className='mx-auto my-auto flex flex-col flex-col flex' style={{fontSize:'14px', fontWeight:'700', fontFamily:'Montserrat', lineHeight:'22.4px', color:'#31A993'}}>
                    <Wrapper canvas style={{display:'inline-block'}} id='random-id-53' is='div'>
                            <Text text='Lorem ipsum'/>
                        </Wrapper> 
                        <Wrapper canvas className='mx-auto my-auto' style={{display:'inline-block', fontSize:'12px', fontWeight:'400', fontFamily:'Montserrat', lineHeight:'19.2px', color:'#031815'}} id='random-id-54' is='div'>
                            <Text text='Dolor sit amet, consectetur adipiscing elit'/>
                        </Wrapper>
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-459' is='div' className='m-2 flex  min-h-[100px]' style={{flex:'30%', minWidth:'200px', borderRadius:'30px', border:'1px solid #31A99333'}}>
                        <Wrapper canvas className='my-auto mx-4  text-[#31A993]' style={{fontSize:'26px', fontWeight:'700', fontFamily:'Montserrat' ,display:'inline-block'}} id='random-id-55' is='div'>
                            <Text text='+22'/>
                    </Wrapper>
                    <Wrapper canvas id='random-id-460' is='div' className='mx-auto my-auto flex flex-col flex-col flex' style={{fontSize:'14px', fontWeight:'700', fontFamily:'Montserrat', lineHeight:'22.4px', color:'#31A993'}}>
                    <Wrapper canvas style={{display:'inline-block'}} id='random-id-56' is='div'>
                            <Text text='Lorem ipsum'/>
                        </Wrapper> 
                        <Wrapper canvas className='mx-auto my-auto' style={{display:'inline-block', fontSize:'12px', fontWeight:'400', fontFamily:'Montserrat', lineHeight:'19.2px', color:'#031815'}} id='random-id-57' is='div'>
                            <Text text='Dolor sit amet, consectetur adipiscing elit'/>
                        </Wrapper>
                    </Wrapper>
                </Wrapper>
                </Wrapper>
            </Wrapper>
            </Wrapper>
        </Wrapper>
    </Wrapper></Element>
        </Element>,

    <Element canvas is={Parent} id='parentId900' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId1000' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-461' is='parent' className='w-[100%] h-full bg-[white] py-10' style={{border:'1px solid transparent'}}>
            <Wrapper canvas id='random-id-462' is='container' className='container' >
                <Wrapper canvas id='random-id-463' is='wrapper' className='w-full h-full flex  flex-wrap justify-center' style={{maxWidth:'1300px', margin:'auto auto'}}>
                <Wrapper canvas id='random-id-464' is='div' className='w-full h-full flex flex-col mx-auto my-auto flex flex-col flex-col flexsm:text-center md:text-cenetr lg:text-left' style={{ flex:'49%', minWidth:'300px'}}>
                    <Wrapper canvas style={{display:'inline-block', fontWeight:'700', fontFamily:'Inter'}} className={`mb-5 text-[#0078D3] text-[38px]`} id='random-id-58' is='div'>
                            <Text text='Why Us.'/>
                    </Wrapper>
                    <Wrapper canvas style={{display:'inline-block', fontWeight:'400', fontFamily:'Inter', lineHeight:'35.2px'}} className={`text-[#000000] text-[16px]`} id='random-id-59' is='div'>
                        <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.
                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'/>
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-465' is='div' className='w-[100%] h-full justify-center mx-auto my-auto align-items-center flex flex-col flex-wrap' style={{flex:'49%', minWidth:'300px'}} >
                    <Wrapper canvas id='random-id-466' is='div' className='w-[100%] flex  flex-wrap justify-center mx-auto m-2' >
                    
                    <Wrapper canvas id='random-id-467' is='div' className='m-2 flex  min-h-[100px]' style={{flex:'30%', minWidth:'200px', border:'1px solid #0000000A'}}>
                        
                            <Wrapper canvas className='my-auto mx-4  text-[#0078D3]' style={{display:'inline-block', fontSize:'26px', fontWeight:'700', fontFamily:'Inter'}} id='random-id-60' is='div'>
                            <Text text='+22'/>
                    </Wrapper>
                        
                        <Wrapper canvas id='random-id-468' is='div' className='mx-auto my-auto flex flex-col flex-col flex' style={{fontSize:'14px', fontWeight:'700', fontFamily:'Inter', lineHeight:'22.4px', color:'#0078D3'}}>
                        <Wrapper canvas style={{display:'inline-block'}} id='random-id-61' is='div'>
                            <Text text='Lorem ipsum'/>
                        </Wrapper> 
                        
                            <Wrapper canvas className='mx-auto my-auto' style={{display:'inline-block', fontSize:'12px', fontWeight:'400', fontFamily:'Inter', lineHeight:'19.2px', color:'#000000'}} id='random-id-62' is='div'>
                            <Text text='Dolor sit amet, consectetur adipiscing elit'/>
                        </Wrapper>
                        
                        </Wrapper>
                    </Wrapper>
                    
                    <Wrapper canvas id='random-id-469' is='div' className='m-2 flex  min-h-[100px]' style={{flex:'30%', minWidth:'200px', border:'1px solid #0000000A'}}>
                        
                            <Wrapper canvas className='my-auto mx-4  text-[#0078D3]' style={{display:'inline-block', fontSize:'26px', fontWeight:'700', fontFamily:'Inter'}} id='random-id-63' is='div'>
                            <Text text='+22'/>
                    </Wrapper>
                        
                        <Wrapper canvas id='random-id-470' is='div' className='mx-auto my-auto flex flex-col flex-col flex' style={{fontSize:'14px', fontWeight:'700', fontFamily:'Inter', lineHeight:'22.4px', color:'#0078D3'}}>
                        <Wrapper canvas style={{display:'inline-block'}} id='random-id-64' is='div'>
                            <Text text='Lorem ipsum'/>
                        </Wrapper> 
                        
                            <Wrapper canvas className='mx-auto my-auto' style={{display:'inline-block', fontSize:'12px', fontWeight:'400', fontFamily:'Inter', lineHeight:'19.2px', color:'#000000'}} id='random-id-65' is='div'>
                            <Text text='Dolor sit amet, consectetur adipiscing elit'/>
                        </Wrapper>
                        
                        </Wrapper>
                    </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-471' is='div' className='flex  flex-wrap w-full justify-center mx-auto' >
                    <Wrapper canvas id='random-id-472' is='div' className='m-2 flex  min-h-[100px]' style={{flex:'30%', minWidth:'200px', border:'1px solid #0000000A'}}>
                        
                            <Wrapper canvas className='my-auto mx-4  text-[#0078D3]' style={{display:'inline-block', fontSize:'26px', fontWeight:'700', fontFamily:'Inter'}} id='random-id-66' is='div'>
                            <Text text='+22'/>
                    </Wrapper>
                        
                        <Wrapper canvas id='random-id-473' is='div' className='mx-auto my-auto flex flex-col flex-col flex' style={{fontSize:'14px', fontWeight:'700', fontFamily:'Inter', lineHeight:'22.4px', color:'#0078D3'}}>
                        <Wrapper canvas style={{display:'inline-block'}} id='random-id-67' is='div'>
                            <Text text='Lorem ipsum'/>
                        </Wrapper> 
                        
                            <Wrapper canvas className='mx-auto my-auto' style={{display:'inline-block', fontSize:'12px', fontWeight:'400', fontFamily:'Inter', lineHeight:'19.2px', color:'#000000'}} id='random-id-68' is='div'>
                            <Text text='Dolor sit amet, consectetur adipiscing elit'/>
                        </Wrapper>
                        
                        </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-474' is='div' className='m-2 flex  min-h-[100px]' style={{flex:'30%', minWidth:'200px', border:'1px solid #0000000A'}}>
                        
                            <Wrapper canvas className='my-auto mx-4  text-[#0078D3]' style={{display:'inline-block', fontSize:'26px', fontWeight:'700', fontFamily:'Inter'}} id='random-id-69' is='div'>
                            <Text text='+22'/>
                    </Wrapper>
                        
                        <Wrapper canvas id='random-id-475' is='div' className='mx-auto my-auto flex flex-col flex-col flex' style={{fontSize:'14px', fontWeight:'700', fontFamily:'Inter', lineHeight:'22.4px', color:'#0078D3'}}>
                        <Wrapper canvas style={{display:'inline-block'}} id='random-id-70' is='div'>
                            <Text text='Lorem ipsum'/>
                        </Wrapper> 
                        
                            <Wrapper canvas className='mx-auto my-auto' style={{display:'inline-block', fontSize:'12px', fontWeight:'400', fontFamily:'Inter', lineHeight:'19.2px', color:'#000000'}} id='random-id-71' is='div'>
                            <Text text='Dolor sit amet, consectetur adipiscing elit'/>
                        </Wrapper>
                        
                        </Wrapper>
                    </Wrapper>
                    </Wrapper>
                </Wrapper>
                </Wrapper>
            </Wrapper>
        </Wrapper></Element>
        </Element>
        ,
    
        
    <Element canvas is={Parent} id='parentId1100' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId1200' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-476' is='parent' className='w-[100%] h-full bg-[white] py-10' style={{border:'1px solid transparent'}}>
            <Wrapper canvas id='random-id-477' is='container' className='container' >
                <Wrapper canvas id='random-id-478' is='div' className='w-full h-full flex  flex-wrap justify-center' style={{maxWidth:'1300px', margin:'auto auto'}}>
                <Wrapper canvas id='random-id-479' is='div' className='w-full h-full flex flex-col mx-auto my-auto flex flex-col flex-col flexsm:text-center md:text-cenetr lg:text-left' style={{ flex:'49%', minWidth:'300px'}}>
                    <Wrapper canvas style={{display:'inline-block', fontWeight:'700', fontFamily:'Voces'}} className={`mb-5 text-[#020212] uppercase text-[38px]`} id='random-id-72' is='div'>
                            <Text text='Why Us'/>
                    </Wrapper>
                    <Wrapper canvas style={{display:'inline-block', fontWeight:'400', fontFamily:'Voces', lineHeight:'35.2px'}} className={`text-[#020212] text-[16px]`} id='random-id-73' is='div'>
                        <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.
                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'/>
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-480' is='div' className='w-[100%] h-full justify-center mx-auto my-auto align-items-center flex flex-col flex-wrap' style={{flex:'49%', minWidth:'300px'}} >
                    <Wrapper canvas id='random-id-481' is='div' className='w-[100%] flex  flex-wrap justify-center mx-auto m-2' >
                    
                    <Wrapper canvas id='random-id-482' is='div' className='m-2 flex  min-h-[100px]' style={{flex:'30%', minWidth:'200px', border:'1px solid #0000000A', background:'#F8F8F8'}}>
                        
                            <Wrapper canvas className='my-auto mx-4  text-[#020212]' style={{display:'inline-block', fontSize:'26px', fontWeight:'700', fontFamily:'Voces'}} id='random-id-74' is='div'>
                            <Text text='+22'/>
                    </Wrapper>
                        
                        <Wrapper canvas id='random-id-483' is='div' className='mx-auto my-auto flex flex-col flex-col flex' style={{fontSize:'14px', fontWeight:'700', fontFamily:'Voces', lineHeight:'22.4px', color:'#020212'}}>
                        <Wrapper canvas style={{display:'inline-block'}} id='random-id-75' is='div'>
                            <Text text='Lorem ipsum'/>
                        </Wrapper> 
                        
                            <Wrapper canvas className='mx-auto my-auto' style={{display:'inline-block', fontSize:'12px', fontWeight:'400', fontFamily:'Voces', lineHeight:'19.2px', color:'#020212'}} id='random-id-76' is='div'>
                            <Text text='Dolor sit amet, consectetur adipiscing elit'/>
                        </Wrapper>
                        
                        </Wrapper>
                    </Wrapper>
                    
                    <Wrapper canvas id='random-id-484' is='div' className='m-2 flex  min-h-[100px]' style={{flex:'30%', minWidth:'200px', border:'1px solid #0000000A', background:'#F8F8F8'}}>
                        
                            <Wrapper canvas className='my-auto mx-4  text-[#020212]' style={{display:'inline-block', fontSize:'26px', fontWeight:'700', fontFamily:'Voces'}} id='random-id-77' is='div'>
                            <Text text='+22'/>
                    </Wrapper>
                        
                        <Wrapper canvas id='random-id-485' is='div' className='mx-auto my-auto flex flex-col flex-col flex' style={{fontSize:'14px', fontWeight:'700', fontFamily:'Voces', lineHeight:'22.4px', color:'#020212'}}>
                        <Wrapper canvas style={{display:'inline-block'}} id='random-id-78' is='div'>
                            <Text text='Lorem ipsum'/>
                        </Wrapper> 
                        
                            <Wrapper canvas className='mx-auto my-auto' style={{display:'inline-block', fontSize:'12px', fontWeight:'400', fontFamily:'Voces', lineHeight:'19.2px', color:'#020212'}} id='random-id-79' is='div'>
                            <Text text='Dolor sit amet, consectetur adipiscing elit'/>
                        </Wrapper>
                        
                        </Wrapper>
                    </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-486' is='div' className='flex  flex-wrap w-full justify-center mx-auto' >
                    <Wrapper canvas id='random-id-487' is='div' className='m-2 flex  min-h-[100px]' style={{flex:'30%', minWidth:'200px', border:'1px solid #0000000A', background:'#F8F8F8'}}>
                        
                            <Wrapper canvas className='my-auto mx-4  text-[#020212]' style={{display:'inline-block', fontSize:'26px', fontWeight:'700', fontFamily:'Voces'}} id='random-id-80' is='div'>
                            <Text text='+22'/>
                    </Wrapper>
                        
                        <Wrapper canvas id='random-id-488' is='div' className='mx-auto my-auto flex flex-col flex-col flex' style={{fontSize:'14px', fontWeight:'700', fontFamily:'Voces', lineHeight:'22.4px', color:'#020212'}}>
                        <Wrapper canvas style={{display:'inline-block'}} id='random-id-81' is='div'>
                            <Text text='Lorem ipsum'/>
                        </Wrapper> 
                        
                            <Wrapper canvas className='mx-auto my-auto' style={{display:'inline-block', fontSize:'12px', fontWeight:'400', fontFamily:'Voces', lineHeight:'19.2px', color:'#020212'}} id='random-id-82' is='div'>
                            <Text text='Dolor sit amet, consectetur adipiscing elit'/>
                        </Wrapper>
                        
                        </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-489' is='div' className='m-2 flex  min-h-[100px]' style={{flex:'30%', minWidth:'200px', border:'1px solid #0000000A', background:'#F8F8F8'}}>
                        
                            <Wrapper canvas className='my-auto mx-4  text-[#020212]' style={{display:'inline-block', fontSize:'26px', fontWeight:'700', fontFamily:'Voces'}} id='random-id-83' is='div'>
                            <Text text='+22'/>
                    </Wrapper>
                        
                        <Wrapper canvas id='random-id-490' is='div' className='mx-auto my-auto flex flex-col flex-col flex' style={{fontSize:'14px', fontWeight:'700', fontFamily:'Voces', lineHeight:'22.4px', color:'#020212'}}>
                        <Wrapper canvas style={{display:'inline-block'}} id='random-id-84' is='div'>
                            <Text text='Lorem ipsum'/>
                        </Wrapper> 
                        
                            <Wrapper canvas className='mx-auto my-auto' style={{display:'inline-block', fontSize:'12px', fontWeight:'400', fontFamily:'Voces', lineHeight:'19.2px', color:'#020212'}} id='random-id-85' is='div'>
                            <Text text='Dolor sit amet, consectetur adipiscing elit'/>
                        </Wrapper>
                        
                        </Wrapper>
                    </Wrapper>
                    </Wrapper>
                </Wrapper>
                </Wrapper>
            </Wrapper>
        </Wrapper></Element>
        </Element>
        ,
        
    
    <Element canvas is={Parent} id='parentId1300' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId1400' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-491' is='parent' className='w-[100%] h-full bg-[#FBFAFF] py-10' style={{border:'1px solid transparent'}}>
    <Wrapper canvas id='random-id-492' is='container' className='container'>
        <Wrapper canvas id='random-id-493' is='wrapper' className='w-full h-full flex  flex-wrap justify-center' style={{maxWidth:'1300px', margin:'auto auto'}}>
        <Wrapper canvas id='random-id-494' is='div' className='w-full h-full flex flex-col mx-auto my-auto flex flex-col flex-col flexsm:text-center md:text-cenetr lg:text-left' style={{ flex:'49%', minWidth:'300px'}}>
            <Wrapper canvas style={{display:'inline-block', fontWeight:'700', fontFamily:'Voces'}} className={`mb-5 text-[#6750A4] text-[38px]`} id='random-id-86' is='div'>
                            <Text text='Why Us'/>
            </Wrapper>
            <Wrapper canvas style={{display:'inline-block', fontWeight:'400', fontFamily:'Voces', lineHeight:'35.2px'}} className={`text-[#020009] text-[16px]`} id='random-id-87' is='div'>
                        <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.
                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'/>
                    </Wrapper>
        </Wrapper>
        <Wrapper canvas id='random-id-495' is='div' className='w-[100%] h-full justify-center mx-auto my-auto align-items-center flex flex-col flex-wrap' style={{flex:'49%', minWidth:'300px'}} >
            <Wrapper canvas id='random-id-496' is='div' className='w-[100%] flex  flex-wrap justify-center mx-auto m-2' >
            
            <Wrapper canvas id='random-id-497' is='div' className='m-2 flex  min-h-[100px]' style={{flex:'30%', minWidth:'200px', borderRadius:'40px', background:'#6750A4'}}>
                
                    <Wrapper canvas className='my-auto mx-4  text-[#D0BCFF]' style={{display:'inline-block', fontSize:'26px', fontWeight:'700', fontFamily:'Voces'}} id='random-id-88' is='div'>
                            <Text text='+22'/>
                    </Wrapper>
                
                <Wrapper canvas id='random-id-498' is='div' className='mx-auto my-auto flex flex-col' style={{fontSize:'14px', fontWeight:'700', fontFamily:'Voces', lineHeight:'22.4px', color:'#D0BCFF'}}>
                <Wrapper canvas style={{display:'inline-block'}} id='random-id-89' is='div'>
                            <Text text='Lorem ipsum'/>
                        </Wrapper> 
                
                    <Wrapper canvas style={{display:'inline-block', fontSize:'12px', fontWeight:'400', fontFamily:'Voces', lineHeight:'19.2px', color:'#D0BCFF'}} className='mx-auto my-auto' id='random-id-90' is='div'>
                            <Text text='Dolor sit amet, consectetur adipiscing elit'/>
                        </Wrapper>
                
                </Wrapper>
            </Wrapper>
            
            <Wrapper canvas id='random-id-499' is='div' className='m-2 flex  min-h-[100px]' style={{flex:'30%', minWidth:'200px', borderRadius:'40px', background:'#6750A4'}}>
                
                    <Wrapper canvas className='my-auto mx-4  text-[#D0BCFF]' style={{display:'inline-block', fontSize:'26px', fontWeight:'700', fontFamily:'Voces'}} id='random-id-91' is='div'>
                            <Text text='+22'/>
                    </Wrapper>
                
                <Wrapper canvas id='random-id-500' is='div' className='mx-auto my-auto flex flex-col' style={{fontSize:'14px', fontWeight:'700', fontFamily:'Voces', lineHeight:'22.4px', color:'#D0BCFF'}}>
                <Wrapper canvas style={{display:'inline-block'}} id='random-id-92' is='div'>
                            <Text text='Lorem ipsum'/>
                        </Wrapper> 
                
                    <Wrapper canvas style={{display:'inline-block', fontSize:'12px', fontWeight:'400', fontFamily:'Voces', lineHeight:'19.2px', color:'#D0BCFF'}} className='mx-auto my-auto' id='random-id-93' is='div'>
                            <Text text='Dolor sit amet, consectetur adipiscing elit'/>
                        </Wrapper>
                
                </Wrapper>
            </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-501' is='div' className='flex  flex-wrap w-full justify-center mx-auto' >
            <Wrapper canvas id='random-id-502' is='div' className='m-2 flex  min-h-[100px]' style={{flex:'30%', minWidth:'200px', borderRadius:'40px', background:'#6750A4'}}>
                
                    <Wrapper canvas className='my-auto mx-4  text-[#D0BCFF]' style={{display:'inline-block', fontSize:'26px', fontWeight:'700', fontFamily:'Voces'}} id='random-id-94' is='div'>
                            <Text text='+22'/>
                    </Wrapper>
                
                <Wrapper canvas id='random-id-503' is='div' className='mx-auto my-auto flex flex-col' style={{fontSize:'14px', fontWeight:'700', fontFamily:'Voces', lineHeight:'22.4px', color:'#D0BCFF'}}>
                <Wrapper canvas style={{display:'inline-block'}} id='random-id-95' is='div'>
                            <Text text='Lorem ipsum'/>
                        </Wrapper> 
                
                    <Wrapper canvas style={{display:'inline-block', fontSize:'12px', fontWeight:'400', fontFamily:'Voces', lineHeight:'19.2px', color:'#D0BCFF'}} className='mx-auto my-auto' id='random-id-96' is='div'>
                            <Text text='Dolor sit amet, consectetur adipiscing elit'/>
                        </Wrapper>
                
                </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-504' is='div' className='m-2 flex  min-h-[100px]' style={{flex:'30%', minWidth:'200px', borderRadius:'40px', background:'#6750A4'}}>
                
                    <Wrapper canvas className='my-auto mx-4  text-[#D0BCFF]' style={{display:'inline-block', fontSize:'26px', fontWeight:'700', fontFamily:'Voces'}} id='random-id-97' is='div'>
                            <Text text='+22'/>
                    </Wrapper>
                
                <Wrapper canvas id='random-id-505' is='div' className='mx-auto my-auto flex flex-col' style={{fontSize:'14px', fontWeight:'700', fontFamily:'Voces', lineHeight:'22.4px', color:'#D0BCFF'}}>
                <Wrapper canvas style={{display:'inline-block'}} id='random-id-98' is='div'>
                            <Text text='Lorem ipsum'/>
                        </Wrapper> 
                
                    <Wrapper canvas style={{display:'inline-block', fontSize:'12px', fontWeight:'400', fontFamily:'Voces', lineHeight:'19.2px', color:'#D0BCFF'}} className='mx-auto my-auto' id='random-id-99' is='div'>
                            <Text text='Dolor sit amet, consectetur adipiscing elit'/>
                        </Wrapper>
                
                </Wrapper>
            </Wrapper>
            </Wrapper>
        </Wrapper>
        </Wrapper>
    </Wrapper>
</Wrapper></Element>
        </Element>,

<Element canvas is={Parent} id='parentId1500' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId1600' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-506' is='parent' className='w-[100%] h-full bg-[#2A2A2A] py-10' style={{border:'1px solid transparent'}}>
            <Wrapper canvas id='random-id-507' is='container' className='container' >
                <Wrapper canvas id='random-id-508' is='wrapper' className='w-full h-full flex  flex-wrap justify-center' style={{maxWidth:'1300px', margin:'auto auto'}}>
                <Wrapper canvas id='random-id-509' is='div' className='w-full h-full flex flex-col mx-auto my-auto flex flex-col flex-col flexsm:text-center md:text-cenetr lg:text-left' style={{ flex:'49%', minWidth:'300px'}}>
                    <Wrapper canvas style={{display:'inline-block', fontWeight:'400', fontFamily:'Rum Raisin'}} className={`mb-5 text-[#FFD600] text-[38px]`} id='random-id-100' is='div'>
                            <Text text='Why Us.'/>
                    </Wrapper>
                    <Wrapper canvas style={{display:'inline-block', fontWeight:'400', fontFamily:'Rubik', lineHeight:'35.2px'}} className={`text-[#FFFFFF] text-[16px]`} id='random-id-101' is='div'>
                        <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.
                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'/>
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-510' is='div' className='w-[100%] h-full justify-center mx-auto my-auto align-items-center flex flex-col flex-wrap' style={{flex:'49%', minWidth:'300px'}} >
                    <Wrapper canvas id='random-id-511' is='div' className='w-[100%] flex  flex-wrap justify-center mx-auto m-2' >
                    
                    <Wrapper canvas id='random-id-512' is='div' className='m-2 flex  min-h-[100px]' style={{flex:'30%', minWidth:'200px', border:'1px solid #FFD6001A', background:'#FFD600', borderRadius:'10px'}}>
                        
                            <Wrapper canvas className='my-auto mx-4  text-[#2A2A2A]' style={{display:'inline-block', fontSize:'26px', fontWeight:'700', fontFamily:'Rubik'}} id='random-id-102' is='div'>
                            <Text text='+22'/>
                    </Wrapper>
                        
                        <Wrapper canvas id='random-id-513' is='div' className='mx-auto my-auto flex flex-col flex-col flex' style={{fontSize:'14px', fontWeight:'700', fontFamily:'Rubik', lineHeight:'22.4px', color:'#2A2A2A'}}>
                        <Wrapper canvas style={{display:'inline-block'}} id='random-id-103' is='div'>
                            <Text text='Lorem ipsum'/>
                        </Wrapper> 
                        
                            <Wrapper canvas style={{display:'inline-block', fontSize:'12px', fontWeight:'400', fontFamily:'Rubik', lineHeight:'19.2px', color:'#2A2A2A'}} className='mx-auto my-auto' id='random-id-104' is='div'>
                            <Text text='Dolor sit amet, consectetur adipiscing elit'/>
                        </Wrapper>
                        
                        </Wrapper>
                    </Wrapper>
                    
                    <Wrapper canvas id='random-id-514' is='div' className='m-2 flex  min-h-[100px]' style={{flex:'30%', minWidth:'200px', border:'1px solid #FFD6001A', background:'#FFD600', borderRadius:'10px'}}>
                        
                            <Wrapper canvas className='my-auto mx-4  text-[#2A2A2A]' style={{display:'inline-block', fontSize:'26px', fontWeight:'700', fontFamily:'Rubik'}} id='random-id-105' is='div'>
                            <Text text='+22'/>
                    </Wrapper>
                        
                        <Wrapper canvas id='random-id-515' is='div'iv className='mx-auto my-auto flex flex-col flex-col flex' style={{fontSize:'14px', fontWeight:'700', fontFamily:'Rubik', lineHeight:'22.4px', color:'#2A2A2A'}}>
                        <Wrapper canvas style={{display:'inline-block'}} id='random-id-106' is='div'>
                            <Text text='Lorem ipsum'/>
                        </Wrapper> 
                        
                            <Wrapper canvas style={{display:'inline-block', fontSize:'12px', fontWeight:'400', fontFamily:'Rubik', lineHeight:'19.2px', color:'#2A2A2A'}} className='mx-auto my-auto' id='random-id-107' is='div'>
                            <Text text='Dolor sit amet, consectetur adipiscing elit'/>
                        </Wrapper>
                        
                        </Wrapper>
                    </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-516' is='div' className='w-[100%] flex  flex-wrap justify-center mx-auto m-2' >
                    <Wrapper canvas id='random-id-517' is='div' className='m-2 flex  min-h-[100px]' style={{flex:'30%', minWidth:'200px', border:'1px solid #FFD6001A', background:'#FFD600', borderRadius:'10px'}}>
                        
                            <Wrapper canvas className='my-auto mx-4  text-[#2A2A2A]' style={{display:'inline-block', fontSize:'26px', fontWeight:'700', fontFamily:'Rubik'}} id='random-id-108' is='div'>
                            <Text text='+22'/>
                    </Wrapper>
                        
                        <Wrapper canvas id='random-id-518' is='div' className='mx-auto my-auto flex flex-col flex-col flex' style={{fontSize:'14px', fontWeight:'700', fontFamily:'Rubik', lineHeight:'22.4px', color:'#2A2A2A'}}>
                        <Wrapper canvas style={{display:'inline-block'}} id='random-id-109' is='div'>
                            <Text text='Lorem ipsum'/>
                        </Wrapper> 
                        
                            <Wrapper canvas style={{display:'inline-block', fontSize:'12px', fontWeight:'400', fontFamily:'Rubik', lineHeight:'19.2px', color:'#2A2A2A'}} className='mx-auto my-auto' id='random-id-110' is='div'>
                            <Text text='Dolor sit amet, consectetur adipiscing elit'/>
                        </Wrapper>
                        
                        </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-519' is='div' className='m-2 flex  min-h-[100px]' style={{flex:'30%', minWidth:'200px', border:'1px solid #FFD6001A', background:'#FFD600', borderRadius:'10px'}}>
                        
                            <Wrapper canvas className='my-auto mx-4  text-[#2A2A2A]' style={{display:'inline-block', fontSize:'26px', fontWeight:'700', fontFamily:'Rubik'}} id='random-id-111' is='div'>
                            <Text text='+22'/>
                    </Wrapper>
                        
                        <Wrapper canvas id='random-id-520' is='div' className='mx-auto my-auto flex flex-col flex-col flex' style={{fontSize:'14px', fontWeight:'700', fontFamily:'Rubik', lineHeight:'22.4px', color:'#2A2A2A'}}>
                        <Wrapper canvas style={{display:'inline-block'}} id='random-id-112' is='div'>
                            <Text text='Lorem ipsum'/>
                        </Wrapper> 
                        
                            <Wrapper canvas style={{display:'inline-block', fontSize:'12px', fontWeight:'400', fontFamily:'Rubik', lineHeight:'19.2px', color:'#2A2A2A'}} className='mx-auto my-auto' id='random-id-113' is='div'>
                            <Text text='Dolor sit amet, consectetur adipiscing elit'/>
                        </Wrapper>
                        
                        </Wrapper>
                    </Wrapper>
                    </Wrapper>
                </Wrapper>
                </Wrapper>
            </Wrapper>
        </Wrapper></Element>
        </Element>
        ,
    
        <Element canvas is={Parent} id='parentId1700' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId1800' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-40910' is='parent' className='w-[100%] h-full bg-[#F7FBFE] py-10'>
        <Wrapper canvas id='random-id-40001' is='container' className='container' >
            <Wrapper canvas id='random-id-40012' is='wrapper' className='w-full h-full flex  flex-wrap justify-center' style={{maxWidth:'1300px', margin:'auto auto'}}>
            <Wrapper canvas id='random-id-40023' is='div' className='w-full h-full flex flex-col mx-auto my-auto flex flex-col flex-col flexsm:text-center md:text-cenetr lg:text-left' style={{ flex:'49%', minWidth:'300px'}}>
                <Wrapper canvas id='random-id-40034' is='div' className={`mb-5 text-[#6064D2] text-[38px]`} style={{ fontWeight:'700', fontFamily:'Rubik'}}>
                <Wrapper canvas style={{display:'inline-block'}} id='random-id-1' is='div'>
                        <Text text='Why Us'/>
                </Wrapper>
                <Wrapper canvas style={{display:'inline-block', color:'#B20312'}} id='random-id-2' is='div'>
                        <Text text='.'/>
                </Wrapper>
                </Wrapper>
                <Wrapper canvas style={{display:'inline-block', fontWeight:'400', fontFamily:'Rubik', lineHeight:'35.2px', color:'#000'}} className={` text-[16px]`} id='random-id-3329' is='div'>
                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.
                Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'/>
                </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-40045' is='div' className='w-[100%] h-full justify-center mx-auto my-auto align-items-center flex flex-col flex-wrap' style={{flex:'49%', minWidth:'300px'}} >
                <Wrapper canvas id='random-id-40056' is='div' className='w-[100%] flex  flex-wrap justify-center mx-auto m-2' >
                
                <Wrapper canvas id='random-id-40077' is='div' className='m-2 flex  min-h-[100px]' style={{flex:'30%', minWidth:'200px', border:'1px solid #6064D21A'}}>
                    <Wrapper canvas style={{display:'inline-block', fontSize:'26px', fontWeight:'700', fontFamily:'Rubik'}} className='my-auto mx-4  text-[#6064D2]' id='random-id-429' is='div'>
                        <Text text='+22'/>
                    </Wrapper>
                    <Wrapper canvas id='random-id-40088' is='div' className='mx-auto my-auto flex flex-col' style={{fontSize:'14px', fontWeight:'700', fontFamily:'Rubik', lineHeight:'22.4px', color:'#B20312'}}>
                    <Wrapper canvas style={{display:'inline-block'}} id='random-id-52387' is='div'>
                        <Text text='Lorem ipsum'/>
                    </Wrapper> 
                    <Wrapper canvas style={{display:'inline-block', fontSize:'12px', fontWeight:'400', fontFamily:'Rubik', lineHeight:'19.2px', color:'#100103'}} className='mx-auto my-auto' id='random-id-632938' is='div'>
                        <Text text='Dolor sit amet, consectetur adipiscing elit'/>
                    </Wrapper>
                    </Wrapper>
                </Wrapper>
                
                <Wrapper canvas id='random-id-40099' is='div' className='m-2 flex  min-h-[100px]' style={{flex:'30%', minWidth:'200px', border:'1px solid #6064D21A'}}>
                        <Wrapper canvas style={{display:'inline-block', fontSize:'26px', fontWeight:'700', fontFamily:'Rubik'}} className='my-auto mx-4  text-[#B20312]' id='random-id-73283' is='div'>
                        <Text text='+22'/>
                </Wrapper>
                    <Wrapper canvas id='random-id-4010' is='div' className='mx-auto my-auto flex flex-col' style={{fontSize:'14px', fontWeight:'700', fontFamily:'Rubik', lineHeight:'22.4px', color:'#B20312'}}>
                    <Wrapper canvas style={{display:'inline-block'}} id='random-id-8230' is='div'>
                        <Text text='Lorem ipsum'/>
                    </Wrapper> 
                    <Wrapper canvas style={{display:'inline-block', fontSize:'12px', fontWeight:'400', fontFamily:'Rubik', lineHeight:'19.2px', color:'#100103'}} className='mx-auto my-auto' id='random-id-92387' is='div'>
                        <Text text='Dolor sit amet, consectetur adipiscing elit'/>
                    </Wrapper>
                    </Wrapper>
                </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-40111' is='div' className='w-[100%] flex  flex-wrap justify-center mx-auto m-2' >
                <Wrapper canvas id='random--id-40212' is='div' className='m-2 flex  min-h-[100px]' style={{flex:'30%', minWidth:'200px', border:'1px solid #6064D21A'}}>
                        <Wrapper canvas style={{display:'inline-block', fontSize:'26px', fontWeight:'700', fontFamily:'Rubik'}} className='my-auto mx-4  text-[#B20312]' id='random-id-13230' is='div'>
                        <Text text='+22'/>
                </Wrapper>
                    <Wrapper canvas id='random-id-40313' is='div' className='mx-auto my-auto flex flex-col' style={{fontSize:'14px', fontWeight:'700', fontFamily:'Rubik', lineHeight:'22.4px', color:'#B20312'}}>
                    <Wrapper canvas style={{display:'inline-block'}} id='random-id-12381' is='div'>
                        <Text text='Lorem ipsum'/>
                    </Wrapper> 
                        <Wrapper canvas style={{display:'inline-block', fontSize:'12px', fontWeight:'400', fontFamily:'Rubik', lineHeight:'19.2px', color:'#100103'}} className='mx-auto my-auto' id='random-id-12322' is='div'>
                        <Text text='Dolor sit amet, consectetur adipiscing elit'/>
                    </Wrapper>
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-40414' is='div' className='m-2 flex  min-h-[100px]' style={{flex:'30%', minWidth:'200px', border:'1px solid #6064D21A'}}>
                        <Wrapper canvas style={{display:'inline-block', fontSize:'26px', fontWeight:'700', fontFamily:'Rubik'}} className='my-auto mx-4  text-[#B20312]' id='random-id-1433' is='div'>
                        <Text text='+22'/>
                </Wrapper>
                    <Wrapper canvas id='random-id-40515' is='div' className='mx-auto my-auto flex flex-col' style={{fontSize:'14px', fontWeight:'700', fontFamily:'Rubik', lineHeight:'22.4px', color:'#B20312'}}>
                    <Wrapper canvas style={{display:'inline-block'}} id='random-id-1324' is='div'>
                        <Text text='Lorem ipsum'/>
                    </Wrapper> 
                        <Wrapper canvas style={{display:'inline-block', fontSize:'12px', fontWeight:'400', fontFamily:'Rubik', lineHeight:'19.2px', color:'#100103'}} className='mx-auto my-auto' id='random-id-1325' is='div'>
                        <Text text='Dolor sit amet, consectetur adipiscing elit'/>
                    </Wrapper>
                    </Wrapper>
                </Wrapper>
                </Wrapper>
            </Wrapper>
            </Wrapper>
        </Wrapper>
    </Wrapper></Element>
        </Element>
    ,

    
<Element canvas is={Parent} id='parentId1900' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId2000' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-506' is='parent' className='w-[100%] h-full bg-[#FEFBF7] py-10' style={{border:'1px solid transparent'}}>
            <Wrapper canvas id='random-id-507' is='container' className='container' >
                <Wrapper canvas id='random-id-508' is='wrapper' className='w-full h-full flex  flex-wrap justify-center' style={{maxWidth:'1300px', margin:'auto auto'}}>
                <Wrapper canvas id='random-id-509' is='div' className='w-full h-full flex flex-col mx-auto my-auto flex flex-col flex-col flexsm:text-center md:text-cenetr lg:text-left' style={{ flex:'49%', minWidth:'300px'}}>
                    <Wrapper canvas style={{display:'inline-block', fontWeight:'400', fontFamily:'Ruluko'}} className={`mb-5 text-[#A25738] text-[38px]`} id='random-id-100' is='div'>
                            <Text text='Why Us.'/>
                    </Wrapper>
                    <Wrapper canvas style={{display:'inline-block', fontWeight:'400', fontFamily:'Sansation', lineHeight:'35.2px'}} className={`text-[#FFFFFF] text-[16px]`} id='random-id-101' is='div'>
                        <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.
                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'/>
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-510' is='div' className='w-[100%] h-full justify-center mx-auto my-auto align-items-center flex flex-col flex-wrap' style={{flex:'49%', minWidth:'300px'}} >
                    <Wrapper canvas id='random-id-511' is='div' className='w-[100%] flex  flex-wrap justify-center mx-auto m-2' >
                    
                    <Wrapper canvas id='random-id-512' is='div' className='m-2 flex  min-h-[100px]' style={{flex:'30%', minWidth:'200px', background:'#A25738', borderRadius:'10px'}}>
                        
                            <Wrapper canvas className='my-auto mx-4  text-[#2A2A2A]' style={{display:'inline-block', fontSize:'26px', fontWeight:'700', fontFamily:'Sansation'}} id='random-id-102' is='div'>
                            <Text text='+22'/>
                    </Wrapper>
                        
                        <Wrapper canvas id='random-id-513' is='div' className='mx-auto my-auto flex flex-col flex-col flex' style={{fontSize:'14px', fontWeight:'700', fontFamily:'Sansation', lineHeight:'22.4px', color:'#ffffff'}}>
                        <Wrapper canvas style={{display:'inline-block'}} id='random-id-103' is='div'>
                            <Text text='Lorem ipsum'/>
                        </Wrapper> 
                        
                            <Wrapper canvas style={{display:'inline-block', fontSize:'12px', fontWeight:'400', fontFamily:'Sansation', lineHeight:'19.2px', color:'#ffffff'}} className='mx-auto my-auto' id='random-id-104' is='div'>
                            <Text text='Dolor sit amet, consectetur adipiscing elit'/>
                        </Wrapper>
                        
                        </Wrapper>
                    </Wrapper>
                    
                    <Wrapper canvas id='random-id-514' is='div' className='m-2 flex  min-h-[100px]' style={{flex:'30%', minWidth:'200px', background:'#A25738', borderRadius:'10px'}}>
                        
                            <Wrapper canvas className='my-auto mx-4  text-[#2A2A2A]' style={{display:'inline-block', fontSize:'26px', fontWeight:'700', fontFamily:'Sansation'}} id='random-id-105' is='div'>
                            <Text text='+22'/>
                    </Wrapper>
                        
                        <Wrapper canvas id='random-id-515' is='div'iv className='mx-auto my-auto flex flex-col flex-col flex' style={{fontSize:'14px', fontWeight:'700', fontFamily:'Sansation', lineHeight:'22.4px', color:'#ffffff'}}>
                        <Wrapper canvas style={{display:'inline-block'}} id='random-id-106' is='div'>
                            <Text text='Lorem ipsum'/>
                        </Wrapper> 
                        
                            <Wrapper canvas style={{display:'inline-block', fontSize:'12px', fontWeight:'400', fontFamily:'Sansation', lineHeight:'19.2px', color:'#ffffff'}} className='mx-auto my-auto' id='random-id-107' is='div'>
                            <Text text='Dolor sit amet, consectetur adipiscing elit'/>
                        </Wrapper>
                        
                        </Wrapper>
                    </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-516' is='div' className='w-[100%] flex  flex-wrap justify-center mx-auto m-2' >
                    <Wrapper canvas id='random-id-517' is='div' className='m-2 flex  min-h-[100px]' style={{flex:'30%', minWidth:'200px', background:'#A25738', borderRadius:'10px'}}>
                        
                            <Wrapper canvas className='my-auto mx-4  text-[#2A2A2A]' style={{display:'inline-block', fontSize:'26px', fontWeight:'700', fontFamily:'Sansation'}} id='random-id-108' is='div'>
                            <Text text='+22'/>
                    </Wrapper>
                        
                        <Wrapper canvas id='random-id-518' is='div' className='mx-auto my-auto flex flex-col flex-col flex' style={{fontSize:'14px', fontWeight:'700', fontFamily:'Sansation', lineHeight:'22.4px', color:'#ffffff'}}>
                        <Wrapper canvas style={{display:'inline-block'}} id='random-id-109' is='div'>
                            <Text text='Lorem ipsum'/>
                        </Wrapper> 
                        
                            <Wrapper canvas style={{display:'inline-block', fontSize:'12px', fontWeight:'400', fontFamily:'Sansation', lineHeight:'19.2px', color:'#ffffff'}} className='mx-auto my-auto' id='random-id-110' is='div'>
                            <Text text='Dolor sit amet, consectetur adipiscing elit'/>
                        </Wrapper>
                        
                        </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-519' is='div' className='m-2 flex  min-h-[100px]' style={{flex:'30%', minWidth:'200px', background:'#A25738', borderRadius:'10px'}}>
                        
                            <Wrapper canvas className='my-auto mx-4  text-[#2A2A2A]' style={{display:'inline-block', fontSize:'26px', fontWeight:'700', fontFamily:'Sansation'}} id='random-id-111' is='div'>
                            <Text text='+22'/>
                    </Wrapper>
                        
                        <Wrapper canvas id='random-id-520' is='div' className='mx-auto my-auto flex flex-col flex-col flex' style={{fontSize:'14px', fontWeight:'700', fontFamily:'Sansation', lineHeight:'22.4px', color:'#ffffff'}}>
                        <Wrapper canvas style={{display:'inline-block'}} id='random-id-112' is='div'>
                            <Text text='Lorem ipsum'/>
                        </Wrapper> 
                        
                            <Wrapper canvas style={{display:'inline-block', fontSize:'12px', fontWeight:'700', fontFamily:'Sansation', lineHeight:'19.2px', color:'#ffffff'}} className='mx-auto my-auto' id='random-id-113' is='div'>
                            <Text text='Dolor sit amet, consectetur adipiscing elit'/>
                        </Wrapper>
                        
                        </Wrapper>
                    </Wrapper>
                    </Wrapper>
                </Wrapper>
                </Wrapper>
            </Wrapper>
        </Wrapper></Element>
        </Element>
        ,


    ]

      
    const [sel, setSel] = useState(selected)

function makeOdd(number) {
  setProp((prop) => {
    setSel(100);
  }, 1000);

  setTimeout(() => {
    setProp((prop) => {
      setSel(number)
    }, 1000);
  }, 100);


  
}

useEffect(() => {
if(selected || selected >= 0) {
  makeOdd(selected);
}
},[selected])

    return (
        <div style={{ padding:enabled? "10px" : "0px", display:"flex", justifyContent:"center", alignItems:"center", width:'100%', height:'100%'}} ref={connect}>
        {sel == 100 ? (
          <div style={{height:"85vh", display:"flex", justifyContent:"center", alignItems:"center"}}>
          <BounceLoader
                  size={100}
                  color={"white"}
                  loading={true}
                />
          </div>
         ) : (
          styles[sel]
         )} 
    </div>
    )
}

WhyUs_1.craft = {
    displayName: "WhyUs 1",
    props: {
       selected: 1, // here is the default value of the selected style
       length:9,
isBlock: true
      },
      rules: {
        canDrag: () => true,
        canDrop: () => true,
      },
      related: {
        toolbar: NewWhyUsSettings,
      },
  };
