/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, { useEffect, useRef, useState } from 'react';

import { Element, useEditor, useNode } from '@craftjs/core';
import { NewSolutionSettings } from './newSolutionSetting';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import { Text } from '../../basic/Text';
import { ImageC } from '../../basic/Image';
import { Parent } from '../../Parent/index.js';
import { Wrapper } from '../../wrapper/index.js';
import { BounceLoader } from 'react-spinners';
import { SvgIcon } from '../../basic/Svg';


export const Solution_1 = ({ selected }) => {
    const { 
        actions: { setProp },
          connectors: {
            connect
          } 
      } = useNode();
    
      
      const {
        enabled,
      } = useEditor((state, query) => ({
        enabled: state.options.enabled,
     
      }));
    const styles = [   
        <Element canvas is={Parent} id='parentId100' className="w-[100%] h-auto" >
    <Element canvas is={Parent} id='parentId200' className="w-[100%] h-auto" row={true} >
        <Wrapper canvas id='randomm-id-400' is='parent' className='h-full bg-white py-10'  style={{ width:'100%'}}>
            <Wrapper canvas id='randomm-id-401' is='container' className='container'>
                    <Wrapper canvas style={{ display: "inline-block", fontFamily:'Rubik', color:'#100103', fontWeight:'700', fontStyle:'normal', textAlign:'center', width:'100%'}} className={`my-4 text-[46px]`} id="random-id-1" is="div">
                    <Text text="Solutions." />
                    </Wrapper>
                <Wrapper canvas id='randomm-id-402' is='wrapper' className='h-full' style={{display:'flex', flexDirection:'row', flexWrap:'wrap', justifyContent:'center', width:'100%'}}>
                    <Wrapper canvas id='randomm-id-403' is='div' className='mx-auto' style={{display:'flex', flexDirection:'column', maxWidth:"380px", minWidth:'324px', flex:'29%', minHeight:"260px",   textAlign:'center', justifyContent:'space-between', padding:'20px 0'}}>
                        <Wrapper canvas id='randomm-id-404' is='div' className='h-full justify-center flex' >
                        <SvgIcon fill='#000' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
                        </Wrapper>
                            <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400px', fontFamily:'Rubik', color:'#000', fontStyle:'normal'}} className='h-full'  id="random-id-2" is="div">
                            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                            </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='randomm-id-405' is='div' className='mx-auto'  style={{display:'flex', flexDirection:'column', maxWidth:"380px", minWidth:'324px', flex:'29%', minHeight:"260px",  textAlign:'center', justifyContent:'space-between', padding:'20px 0'}}>
                        <Wrapper canvas id='randomm-id-406' is='div' className='h-full justify-center flex' >
                        <SvgIcon fill='#000' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
                        </Wrapper>
                            <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400px', fontFamily:'Rubik', color:'#000', fontStyle:'normal'}} className='h-full' id="random-id-3" is="div">
                            <Text text="Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo." />
                            </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='randomm-id-407' is='div' className='mx-auto'  style={{display:'flex', flexDirection:'column', maxWidth:"380px", minWidth:'324px', flex:'29%', minHeight:"260px",  textAlign:'center', justifyContent:'space-between', padding:'20px 0'}}>
                        <Wrapper canvas id='randomm-id-408' is='div' className='h-full justify-center flex' >
                        <SvgIcon fill='#000' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
                        </Wrapper>
                            <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400px', fontFamily:'Rubik', color:'#000', fontStyle:'normal' }} className='h-full' id="random-id-4" is="div">
                            <Text text="Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur." />
                            </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='randomm-id-409' is='div' className='mx-auto'  style={{display:'flex', flexDirection:'column', maxWidth:"380px", minWidth:'324px', flex:'29%', minHeight:"260px",  textAlign:'center', justifyContent:'space-between', padding:'20px 0'}}>
                        <Wrapper canvas id='randomm-id-410' is='div' className='h-full justify-center flex' >
                        <SvgIcon fill='#000' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
                        </Wrapper>
                            <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400px', fontFamily:'Rubik', color:'#000', fontStyle:'normal' }} className='h-full' id="random-id-5" is="div">
                            <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                            </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='randomm-id-411' is='div' className='mx-auto'  style={{display:'flex', flexDirection:'column', maxWidth:"380px", minWidth:'324px', flex:'29%', minHeight:"260px",  textAlign:'center', justifyContent:'space-between', padding:'20px 0'}}>
                    <Wrapper canvas id='randomm-id-412' is='div' className='h-full justify-center flex' >
                    <SvgIcon fill='#000' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
                        </Wrapper>
                            <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400px', fontFamily:'Rubik', color:'#000', fontStyle:'normal'}} className='h-full' id="random-id-6" is="div">
                            <Text text="Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo." />
                            </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='randomm-id-413' is='div' className='mx-auto'  style={{display:'flex', flexDirection:'column', maxWidth:"380px", minWidth:'324px', flex:'29%', minHeight:"260px",  textAlign:'center', justifyContent:'space-between', padding:'20px 0'}}>
                    <Wrapper canvas id='randomm-id-414' is='div' className='h-full justify-center flex' >
                    <SvgIcon fill='#000' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
                        </Wrapper>
                            <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400px', fontFamily:'Rubik', color:'#000', fontStyle:'normal'}} className='h-full' id="random-id-7" is="div">
                            <Text text="Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur." />
                            </Wrapper>
                    </Wrapper>
                </Wrapper>
            </Wrapper>
        </Wrapper></Element>
    </Element>,


        <Element canvas is={Parent} id='parentId300' className="w-[100%] h-auto" >
    <Element canvas is={Parent} id='parentId400' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='randomm-id-415' is='parent' className='h-full py-10'   style={{ width:'100%', background:'#121826'}}>
            <Wrapper canvas id='randomm-id-416' is='container' className='container'>
<Wrapper canvas style={{ display: "inline-block", fontFamily:'Rubik', fontWeight:'700', fontStyle:'normal', textAlign:'center', width:'100%'}} className={` IncreaseConversion2 my-4 text-[46px]`} id="random-id-8" is="div">
<Text text="Solutions." />
</Wrapper>
                <Wrapper canvas id='randomm-id-417' is='wrapper' className='h-full' style={{display:'flex', flexDirection:'row', flexWrap:'wrap', justifyContent:'center', width:'100%'}}>
                    <Wrapper canvas id='randomm-id-418' is='div' className='mx-auto' style={{display:'flex', flexDirection:'column', maxWidth:"380px", minWidth:'324px', flex:'29%', minHeight:"260px",  textAlign:'center', justifyContent:'space-between', padding:'20px 0'}}>
                        <Wrapper canvas id='randomm-id-419' is='div' className='h-full justify-center flex'>
                        <SvgIcon fill='#3265E3' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
                        </Wrapper>
<Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400px', fontFamily:'Rubik', color:'#FFF', fontStyle:'normal'}} className='h-full'  id="random-id-9" is="div">
<Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
</Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='randomm-id-420' is='div' className='mx-auto'  style={{display:'flex', flexDirection:'column', maxWidth:"380px", minWidth:'324px', flex:'29%', minHeight:"260px",  textAlign:'center', justifyContent:'space-between', padding:'20px 0'}}>
                        <Wrapper canvas id='randomm-id-421' is='div' className='h-full justify-center flex'>
                        <SvgIcon fill='#3265E3' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
                        </Wrapper>
<Wrapper canvas style={{ display: "inline-block" , fontSize:'18px', fontWeight:'400px', fontFamily:'Rubik', color:'#FFF', fontStyle:'normal'}} className='h-full'  id="random-id-10" is="div">
<Text text="Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo." />
</Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='randomm-id-422' is='div' className='mx-auto'  style={{display:'flex', flexDirection:'column', maxWidth:"380px", minWidth:'324px', flex:'29%', minHeight:"260px",  textAlign:'center', justifyContent:'space-between', padding:'20px 0'}}>
                        <Wrapper canvas id='randomm-id-423' is='div' className='h-full justify-center flex'>
                        <SvgIcon fill='#3265E3' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
                        </Wrapper>                            
<Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400px', fontFamily:'Rubik', color:'#FFF', fontStyle:'normal'}} className='h-full' id="random-id-11" is="div">
<Text text="Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur." />
</Wrapper>

                    </Wrapper>
                    <Wrapper canvas id='randomm-id-424' is='div' className='mx-auto'  style={{display:'flex', flexDirection:'column', maxWidth:"380px", minWidth:'324px', flex:'29%', minHeight:"260px",  textAlign:'center', justifyContent:'space-between', padding:'20px 0'}}>
                        <Wrapper canvas id='randomm-id-425' is='div' className='h-full justify-center flex'>
                        <SvgIcon fill='#3265E3' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
                        </Wrapper>
<Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400px', fontFamily:'Rubik', color:'#FFF', fontStyle:'normal'}} className='h-full' id="random-id-12" is="div">
<Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
</Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='randomm-id-426' is='div' className='mx-auto'  style={{display:'flex', flexDirection:'column', maxWidth:"380px", minWidth:'324px', flex:'29%', minHeight:"260px",  textAlign:'center', justifyContent:'space-between', padding:'20px 0'}}>
                        <Wrapper canvas id='randomm-id-427' is='div' className='h-full justify-center flex'>
                        <SvgIcon fill='#3265E3' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
                        </Wrapper>
<Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400px', fontFamily:'Rubik', color:'#FFF', fontStyle:'normal' }} className='h-full'  id="random-id-13" is="div">
<Text text="Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo." />
</Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='randomm-id-428' is='div' className='mx-auto'  style={{display:'flex', flexDirection:'column', maxWidth:"380px", minWidth:'324px', flex:'29%', minHeight:"260px",  textAlign:'center', justifyContent:'space-between', padding:'20px 0'}}>
                        <Wrapper canvas id='randomm-id-429' is='div' className='h-full justify-center flex'>
                        <SvgIcon fill='#3265E3' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
                        </Wrapper>                            
<Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400px', fontFamily:'Rubik', color:'#FFF', fontStyle:'normal' }} className='h-full'  id="random-id-14" is="div">
<Text text="Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur." />
</Wrapper>
                    </Wrapper>
                </Wrapper>
            </Wrapper>
        </Wrapper></Element>
    </Element>,



        <Element canvas is={Parent} id='parentId500' className="w-[100%] h-auto" >
    <Element canvas is={Parent} id='parentId600' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='randomm-id-430' is='parent' className='h-full py-10'   style={{ width:'100%', background:'#213156'}}>
        <Wrapper canvas id='randomm-id-431' is='container' className='container'>
<Wrapper canvas style={{ display: "inline-block", fontFamily:'Yeseva One', fontWeight:'700', fontStyle:'normal', textAlign:'center', width:'100%', color:'#EA6EF4' }} className={`my-4 text-[46px]`} id="random-id-15" is="div">
<Text text="Solutions" />
</Wrapper>
            <Wrapper canvas id='randomm-id-432' is='divwrapper' className='h-full ' style={{display:'flex', flexDirection:'row', flexWrap:'wrap', justifyContent:'center', width:'100%'}}>
                <Wrapper canvas id='randomm-id-433' is='div' className='mx-auto' style={{display:'flex', flexDirection:'column', maxWidth:"380px", minWidth:'324px', flex:'29%', minHeight:"260px",  textAlign:'center', justifyContent:'space-between', padding:'20px 0'}}>
                    <Wrapper canvas id='randomm-id-434' is='div' className='h-full justify-center flex' >
                    <SvgIcon fill='#EA6EF4' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                    </Wrapper>
<Wrapper canvas style={{ display: "inline-block" , fontSize:'18px', fontWeight:'400px', fontFamily:'Open Sans', color:'#FFF', fontStyle:'normal'}} className='h-full' id="random-id-16" is="div">
<Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
</Wrapper>
                </Wrapper>
                <Wrapper canvas id='randomm-id-435' is='div' className='mx-auto'  style={{display:'flex', flexDirection:'column', maxWidth:"380px", minWidth:'324px', flex:'29%', minHeight:"260px",  textAlign:'center', justifyContent:'space-between', padding:'20px 0'}}>
                    <Wrapper canvas id='randomm-id-436' is='div' className='h-full justify-center flex' >
                    <SvgIcon fill='#EA6EF4' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                    </Wrapper>
<Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400px', fontFamily:'Open Sans', color:'#FFF', fontStyle:'normal'}} className='h-full'  id="random-id-17" is="div">
<Text text="Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo." />
</Wrapper>
                </Wrapper>
                <Wrapper canvas id='randomm-id-437' is='div' className='mx-auto'  style={{display:'flex', flexDirection:'column', maxWidth:"380px", minWidth:'324px', flex:'29%', minHeight:"260px",  textAlign:'center', justifyContent:'space-between', padding:'20px 0'}}>
                    <Wrapper canvas id='randomm-id-438' is='div' className='h-full justify-center flex' >
                    <SvgIcon fill='#EA6EF4' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                    </Wrapper>                        
<Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400px', fontFamily:'Open Sans', color:'#FFF', fontStyle:'normal'}} className='h-full'  id="random-id-18" is="div">
<Text text="Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur." />
</Wrapper>
                </Wrapper>
                <Wrapper canvas id='randomm-id-439' is='div' className='mx-auto'  style={{display:'flex', flexDirection:'column', maxWidth:"380px", minWidth:'324px', flex:'29%', minHeight:"260px",  textAlign:'center', justifyContent:'space-between', padding:'20px 0'}}>
                    <Wrapper canvas id='randomm-id-440' is='div' className='h-full justify-center flex' >
                    <SvgIcon fill='#EA6EF4' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                    </Wrapper>
<Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400px', fontFamily:'Open Sans', color:'#FFF', fontStyle:'normal'}} className='h-full' id="random-id-19" is="div">
<Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
</Wrapper>
                </Wrapper>
                <Wrapper canvas id='randomm-id-441' is='div' className='mx-auto'  style={{display:'flex', flexDirection:'column', maxWidth:"380px", minWidth:'324px', flex:'29%', minHeight:"260px",  textAlign:'center', justifyContent:'space-between', padding:'20px 0'}}>
                    <Wrapper canvas id='randomm-id-442' is='div' className='h-full justify-center flex' >
                    <SvgIcon fill='#EA6EF4' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                    </Wrapper>
<Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400px', fontFamily:'Open Sans', color:'#FFF', fontStyle:'normal'}} className='h-full'  id="random-id-20" is="div">
<Text text="Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo." />
</Wrapper>
                </Wrapper>
                <Wrapper canvas id='randomm-id-443' is='div' className='mx-auto'  style={{display:'flex', flexDirection:'column', maxWidth:"380px", minWidth:'324px', flex:'29%', minHeight:"260px",  textAlign:'center', justifyContent:'space-between', padding:'20px 0'}}>
                    <Wrapper canvas id='randomm-id-444' is='div' className='h-full justify-center flex' >
                    <SvgIcon fill='#EA6EF4' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                    </Wrapper>                        
<Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400px', fontFamily:'Open Sans', color:'#FFF', fontStyle:'normal'}} className='h-full' id="random-id-21" is="div">
<Text text="Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur." />
</Wrapper>
                </Wrapper>
            </Wrapper>
        </Wrapper>
        </Wrapper></Element>
    </Element>,
        


        <Element canvas is={Parent} id='parentId700' className="w-[100%] h-auto" >
    <Element canvas is={Parent} id='parentId800' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='randomm-id-445' is='parent' className='h-full py-10'   style={{ width:'100%', background:'#DFFFF9'}}>
        <Wrapper canvas id='randomm-id-446' is='container' className='container'>
<Wrapper canvas style={{ display: "inline-block", fontFamily:'Zing Script Rust SemiBold Demo', fontWeight:'700', fontStyle:'normal', textAlign:'center', width:'100%', color:'#31A993' }} className={`my-4 text-[46px]`} id="random-id-22" is="div">
<Text text="Solutions" />
</Wrapper>
            <Wrapper canvas id='randomm-id-447' is='wrapper' className='h-full ' style={{display:'flex', flexDirection:'row', flexWrap:'wrap', justifyContent:'center', width:'100%'}}>
                <Wrapper canvas id='randomm-id-448' is='div' className='mx-auto' style={{display:'flex', flexDirection:'column', maxWidth:"380px", minWidth:'324px', flex:'29%', minHeight:"260px",  textAlign:'center', justifyContent:'space-between', padding:'20px 0'}}>
                    <Wrapper canvas id='randomm-id-449' is='div' className='h-full justify-center flex' >
                    <SvgIcon fill='#31A993' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                    </Wrapper>
<Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400px', fontFamily:'Montserrat', color:'#031815', fontStyle:'normal'}} className='h-full' id="random-id-23" is="div">
<Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
</Wrapper>
                </Wrapper>
                <Wrapper canvas id='randomm-id-450' is='div' className='mx-auto'  style={{display:'flex', flexDirection:'column', maxWidth:"380px", minWidth:'324px', flex:'29%', minHeight:"260px",  textAlign:'center', justifyContent:'space-between', padding:'20px 0'}}>
                    <Wrapper canvas id='randomm-id-451' is='div' className='h-full justify-center flex' >
                    <SvgIcon fill='#31A993' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                    </Wrapper>
<Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400px', fontFamily:'Montserrat', color:'#031815', fontStyle:'normal'}} className='h-full'  id="random-id-24" is="div">
<Text text="Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo." />
</Wrapper>
                </Wrapper>
                <Wrapper canvas id='randomm-id-452' is='div' className='mx-auto'  style={{display:'flex', flexDirection:'column', maxWidth:"380px", minWidth:'324px', flex:'29%', minHeight:"260px",  textAlign:'center', justifyContent:'space-between', padding:'20px 0'}}>
                    <Wrapper canvas id='randomm-id-453' is='div' className='h-full justify-center flex' >
                    <SvgIcon fill='#31A993' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                    </Wrapper>                        
<Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400px', fontFamily:'Montserrat', color:'#031815', fontStyle:'normal'}} className='h-full'  id="random-id-25" is="div">
<Text text="Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur." />
</Wrapper>
                </Wrapper>
                <Wrapper canvas id='randomm-id-454' is='div' className='mx-auto'  style={{display:'flex', flexDirection:'column', maxWidth:"380px", minWidth:'324px', flex:'29%', minHeight:"260px",  textAlign:'center', justifyContent:'space-between', padding:'20px 0'}}>
                    <Wrapper canvas id='randomm-id-455' className='h-full justify-center flex' is='div' >
                    <SvgIcon fill='#31A993' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                    </Wrapper>
<Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400px', fontFamily:'Montserrat', color:'#031815', fontStyle:'normal'}} className='h-full'  id="random-id-26" is="div">
<Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
</Wrapper>
                </Wrapper>
                <Wrapper canvas id='randomm-id-456' is='div' className='mx-auto'  style={{display:'flex', flexDirection:'column', maxWidth:"380px", minWidth:'324px', flex:'29%', minHeight:"260px",  textAlign:'center', justifyContent:'space-between', padding:'20px 0'}}>
                    <Wrapper canvas id='randomm-id-457' className='h-full justify-center flex' is='div' >
                    <SvgIcon fill='#31A993' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                    </Wrapper>
<Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400px', fontFamily:'Montserrat', color:'#031815', fontStyle:'normal'}} className='h-full'  id="random-id-27" is="div">
<Text text="Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo." />
</Wrapper>
                </Wrapper>
                <Wrapper canvas id='randomm-id-458' is='div' className='mx-auto'  style={{display:'flex', flexDirection:'column', maxWidth:"380px", minWidth:'324px', flex:'29%', minHeight:"260px",  textAlign:'center', justifyContent:'space-between', padding:'20px 0'}}>
                    <Wrapper canvas id='randomm-id-459' className='h-full justify-center flex' is='div' >
                    <SvgIcon fill='#31A993' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                    </Wrapper>                        
<Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400px', fontFamily:'Montserrat', color:'#031815', fontStyle:'normal'}}  className='h-full' id="random-id-28" is="div">
<Text text="Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur." />
</Wrapper>
                </Wrapper>
            </Wrapper>
        </Wrapper>
        </Wrapper></Element>
    </Element>,



        <Element canvas is={Parent} id='parentId900' className="w-[100%] h-auto" >
    <Element canvas is={Parent} id='parentId1000' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='randomm-id-460' is='parent' className='h-full py-10'   style={{ width:'100%', background:'#FFF'}}>
        <Wrapper canvas id='randomm-id-461' is='container' className='container'>
<Wrapper canvas style={{ display: "inline-block", fontFamily:'Inter', fontWeight:'700', fontStyle:'normal', textAlign:'center', width:'100%', color:'#0078D3' }} className={`my-4 text-[46px]`} id="random-id-29" is="div">
<Text text="Solutions" />
</Wrapper>
            <Wrapper canvas id='randomm-id-462' is='wrapper' className='h-full ' style={{display:'flex', flexDirection:'row', flexWrap:'wrap', justifyContent:'center', width:'100%'}}>
                <Wrapper canvas id='randomm-id-463' is='div' className='mx-auto' style={{display:'flex', flexDirection:'column', maxWidth:"380px", minWidth:'324px', flex:'29%', minHeight:"260px",  textAlign:'center', justifyContent:'space-between', padding:'20px 0'}}>
                    <Wrapper canvas id='randomm-id-464' is='div' className='h-full justify-center flex'>
                    <SvgIcon fill='#0078D3' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                    </Wrapper>
<Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400px', fontFamily:'Inter', color:'#031815', fontStyle:'normal' }} className='h-full' id="random-id-30" is="div">
<Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
</Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-400' is='div' className='mx-auto'  style={{display:'flex', flexDirection:'column', maxWidth:"380px", minWidth:'324px', flex:'29%', minHeight:"260px",  textAlign:'center', justifyContent:'space-between', padding:'20px 0'}}>
                    <Wrapper canvas id='random-id-400' is='div' className='h-full justify-center flex'>
                    <SvgIcon fill='#0078D3' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                    </Wrapper>
<Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400px', fontFamily:'Inter', color:'#031815', fontStyle:'normal'}} className='h-full' id="random-id-31" is="div">
<Text text="Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo." />
</Wrapper>
                </Wrapper>
                <Wrapper canvas id='randomm-id-465' is='div'v className='mx-auto'  style={{display:'flex', flexDirection:'column', maxWidth:"380px", minWidth:'324px', flex:'29%', minHeight:"260px",  textAlign:'center', justifyContent:'space-between', padding:'20px 0'}}>
                    <Wrapper canvas id='randomm-id-466' is='div' className='h-full justify-center flex'>
                    <SvgIcon fill='#0078D3' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                    </Wrapper>                        
<Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400px', fontFamily:'Inter', color:'#031815', fontStyle:'normal'}} className='h-full' id="random-id-32" is="div">
<Text text="Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur." />
</Wrapper>
                </Wrapper>
                <Wrapper canvas id='randomm-id-467' is='div' className='mx-auto'  style={{display:'flex', flexDirection:'column', maxWidth:"380px", minWidth:'324px', flex:'29%', minHeight:"260px",  textAlign:'center', justifyContent:'space-between', padding:'20px 0'}}>
                    <Wrapper canvas id='randomm-id-468' is='div' className='h-full justify-center flex'>
                    <SvgIcon fill='#0078D3' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                    </Wrapper>
<Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400px', fontFamily:'Inter', color:'#031815', fontStyle:'normal'}} className='h-full' id="random-id-33" is="div">
<Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
</Wrapper>
                </Wrapper>
                <Wrapper canvas id='randomm-id-469' is='div' className='mx-auto'  style={{display:'flex', flexDirection:'column', maxWidth:"380px", minWidth:'324px', flex:'29%', minHeight:"260px",  textAlign:'center', justifyContent:'space-between', padding:'20px 0'}}>
                    <Wrapper canvas id='randomm-id-470' is='div' className='h-full justify-center flex'>
                    <SvgIcon fill='#0078D3' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                    </Wrapper>
<Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400px', fontFamily:'Inter', color:'#031815', fontStyle:'normal'}} className='h-full'  id="random-id-34" is="div">
<Text text="Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo." />
</Wrapper>
                </Wrapper>
                <Wrapper canvas id='randomm-id-471' is='div' className='mx-auto'  style={{display:'flex', flexDirection:'column', maxWidth:"380px", minWidth:'324px', flex:'29%', minHeight:"260px",  textAlign:'center', justifyContent:'space-between', padding:'20px 0'}}>
                    <Wrapper canvas id='randomm-id-472' is='div' className='h-full justify-center flex'>
                    <SvgIcon fill='#0078D3' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                    </Wrapper>                        
<Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400px', fontFamily:'Inter', color:'#031815', fontStyle:'normal'}} className='h-full' id="random-id-35" is="div">
<Text text="Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur." />
</Wrapper>
                </Wrapper>
            </Wrapper>
        </Wrapper>
        </Wrapper></Element>
    </Element>,

        <Element canvas is={Parent} id='parentId1100' className="w-[100%] h-auto" >
    <Element canvas is={Parent} id='parentId1200' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='randomm-id-473' is='parent' className='h-full bg-white px-10 py-20'  style={{ width:'100%'}}>
        <Wrapper canvas id='randomm-id-474' is='container' className='container' style={{background:'#F8F8F8'}}>
        <Wrapper canvas style={{ display: "inline-block", fontFamily:'Voces', fontWeight:'700', fontStyle:'normal', textAlign:'center', width:'100%', color:'#020212'}} className={` my-4 text-[46px] uppercase`} id="random-id-36" is="div">
        <Text text="Solutions" />
        </Wrapper>
            <Wrapper canvas id='randomm-id-475' is='wrapper' className='h-full ' style={{display:'flex', flexDirection:'row', flexWrap:'wrap', justifyContent:'center', width:'100%'}}>
                <Wrapper canvas id='randomm-id-476' is='div' className='mx-auto' style={{display:'flex', flexDirection:'column', maxWidth:"380px", minWidth:'324px', flex:'29%', minHeight:"260px",  textAlign:'center', justifyContent:'space-between', padding:'20px 0'}}>
                    <Wrapper canvas id='randomm-id-477' is='div' className='h-full justify-center flex'>
                    <SvgIcon fill='#020212' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                    </Wrapper>
        <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400px', fontFamily:'Voces', color:'#020212', fontStyle:'normal'}} className='h-full'  id="random-id-37" is="div">
        <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
        </Wrapper>
                </Wrapper>
                <Wrapper canvas id='randomm-id-478' is='div' className='mx-auto'  style={{display:'flex', flexDirection:'column', maxWidth:"380px", minWidth:'324px', flex:'29%', minHeight:"260px",  textAlign:'center', justifyContent:'space-between', padding:'20px 0'}}>
                    <Wrapper canvas id='randomm-id-479' is='div' className='h-full justify-center flex'>
                    <SvgIcon fill='#020212' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                    </Wrapper>
        <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400px', fontFamily:'Voces', color:'#020212', fontStyle:'normal'}} className='h-full'  id="random-id-38" is="div">
        <Text text="Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo." />
        </Wrapper>
                </Wrapper>
                <Wrapper canvas id='randomm-id-480' is='div' className='mx-auto'  style={{display:'flex', flexDirection:'column', maxWidth:"380px", minWidth:'324px', flex:'29%', minHeight:"260px",  textAlign:'center', justifyContent:'space-between', padding:'20px 0'}}>
                    <Wrapper canvas id='randomm-id-481' is='div' className='h-full justify-center flex'>
                    <SvgIcon fill='#020212' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                    </Wrapper>                
        <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400px', fontFamily:'Voces', color:'#020212', fontStyle:'normal'}} className='h-full' id="random-id-39" is="div">
        <Text text="Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur." />
        </Wrapper>
                </Wrapper>
                <Wrapper canvas id='randomm-id-482' is='div' className='mx-auto'  style={{display:'flex', flexDirection:'column', maxWidth:"380px", minWidth:'324px', flex:'29%', minHeight:"260px",  textAlign:'center', justifyContent:'space-between', padding:'20px 0'}}>
                    <Wrapper canvas id='randomm-id-483' is='div' className='h-full justify-center flex'>
                    <SvgIcon fill='#020212' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                    </Wrapper>
        <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400px', fontFamily:'Voces', color:'#020212', fontStyle:'normal' }} className='h-full' id="random-id-40" is="div">
        <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
        </Wrapper>
                </Wrapper>
                <Wrapper canvas id='randomm-id-484' is='div' className='mx-auto'  style={{display:'flex', flexDirection:'column', maxWidth:"380px", minWidth:'324px', flex:'29%', minHeight:"260px",  textAlign:'center', justifyContent:'space-between', padding:'20px 0'}}>
                    <Wrapper canvas id='randomm-id-485' is='div' className='h-full justify-center flex'>
                    <SvgIcon fill='#020212' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                    </Wrapper>
        <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400px', fontFamily:'Voces', color:'#020212', fontStyle:'normal'}} className='h-full' id="random-id-41" is="div">
        <Text text="Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo." />
        </Wrapper>
                </Wrapper>
                <Wrapper canvas id='randomm-id-486' is='div' className='mx-auto'  style={{display:'flex', flexDirection:'column', maxWidth:"380px", minWidth:'324px', flex:'29%', minHeight:"260px",  textAlign:'center', justifyContent:'space-between', padding:'20px 0'}}>
                    <Wrapper canvas id='randomm-id-487' is='div' className='h-full justify-center flex'>
                    <SvgIcon fill='#020212' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                    </Wrapper>                
        <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400px', fontFamily:'Voces', color:'#020212', fontStyle:'normal' }} className='h-full' id="random-id-42" is="div">
        <Text text="Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur." />
        </Wrapper>
                </Wrapper>
            </Wrapper>
        </Wrapper>
        </Wrapper></Element>
    </Element>,



        <Element canvas is={Parent} id='parentId1300' className="w-[100%] h-auto" >
    <Element canvas is={Parent} id='parentId1400' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='randomm-id-488' is='parent' className='h-full py-10'   style={{ width:'100%', background:'#FFFFFF'}}>
        <Wrapper canvas id='randomm-id-489' is='container' className='container'>
        <Wrapper canvas style={{ display: "inline-block", fontFamily:'Roboto', fontWeight:'700', fontStyle:'normal', textAlign:'center', width:'100%', color:'#6750A4' }} className={`my-4 text-[46px]`} id="random-id-29" is="div">
        <Text text="Solutions" />
        </Wrapper>
            <Wrapper canvas id='randomm-id-490' is='wrapper' className='h-full ' style={{display:'flex', flexDirection:'row', flexWrap:'wrap', justifyContent:'center', width:'100%'}}>
                <Wrapper canvas id='randomm-id-491' is='div' className='mx-auto' style={{display:'flex', flexDirection:'column', maxWidth:"380px", minWidth:'324px', flex:'29%', minHeight:"260px",  textAlign:'center', justifyContent:'space-between', padding:'20px 0'}}>
                    <Wrapper canvas id='randomm-id-492' is='div' className='h-full justify-center flex' >
                    <SvgIcon fill='#6750A4' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                    </Wrapper>
        <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400px', fontFamily:'Roboto', color:'#031815', fontStyle:'normal' }} className='h-full' id="random-id-30" is="div">
        <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
        </Wrapper>
                </Wrapper>
                <Wrapper canvas id='randomm-id-493' is='div' className='mx-auto'  style={{display:'flex', flexDirection:'column', maxWidth:"380px", minWidth:'324px', flex:'29%', minHeight:"260px",  textAlign:'center', justifyContent:'space-between', padding:'20px 0'}}>
                    <Wrapper canvas id='randomm-id-494' is='div' className='h-full justify-center flex' >
                    <SvgIcon fill='#6750A4' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                    </Wrapper>
        <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400px', fontFamily:'Roboto', color:'#031815', fontStyle:'normal'}} className='h-full' id="random-id-31" is="div">
        <Text text="Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo." />
        </Wrapper>
                </Wrapper>
                <Wrapper canvas id='randomm-id-495' is='div' className='mx-auto'  style={{display:'flex', flexDirection:'column', maxWidth:"380px", minWidth:'324px', flex:'29%', minHeight:"260px",  textAlign:'center', justifyContent:'space-between', padding:'20px 0'}}>
                    <Wrapper canvas id='randomm-id-496' is='div' className='h-full justify-center flex' >
                    <SvgIcon fill='#6750A4' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                    </Wrapper>                
                        <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400px', fontFamily:'Roboto', color:'#031815', fontStyle:'normal' }} className='h-full' id="random-id-32" is="div">
                        <Text text="Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur." />
                        </Wrapper>
                </Wrapper>
                <Wrapper canvas id='randomm-id-497' is='div' className='mx-auto'  style={{display:'flex', flexDirection:'column', maxWidth:"380px", minWidth:'324px', flex:'29%', minHeight:"260px",  textAlign:'center', justifyContent:'space-between', padding:'20px 0'}}>
                    <Wrapper canvas id='randomm-id-498' is='div' className='h-full justify-center flex' >
                    <SvgIcon fill='#6750A4' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                    </Wrapper>
                        <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400px', fontFamily:'Roboto', color:'#031815', fontStyle:'normal'}} className='h-full' id="random-id-35" is="div">
                        <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                        </Wrapper>
                </Wrapper>
                <Wrapper canvas id='randomm-id-499' is='div' className='mx-auto'  style={{display:'flex', flexDirection:'column', maxWidth:"380px", minWidth:'324px', flex:'29%', minHeight:"260px",  textAlign:'center', justifyContent:'space-between', padding:'20px 0'}}>
                    <Wrapper canvas id='randomm-id-500' is='div' className='h-full justify-center flex' >
                    <SvgIcon fill='#6750A4' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                    </Wrapper>
                        <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400px', fontFamily:'Roboto', color:'#031815', fontStyle:'normal'}} className='h-full'  id="random-id-35" is="div">
                        <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                        </Wrapper>
                </Wrapper>
                <Wrapper canvas id='randomm-id-501' is='div' className='mx-auto'  style={{display:'flex', flexDirection:'column', maxWidth:"380px", minWidth:'324px', flex:'29%', minHeight:"260px",  textAlign:'center', justifyContent:'space-between', padding:'20px 0'}}>
                    <Wrapper canvas id='randomm-id-502' is='div' className='h-full justify-center flex' >
                    <SvgIcon fill='#6750A4' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                    </Wrapper>
                        <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400px', fontFamily:'Roboto', color:'#031815', fontStyle:'normal' }} className='h-full' id="random-id-35" is="div">
                        <Text text="Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. " />
                        </Wrapper>
                </Wrapper>
            </Wrapper>
        </Wrapper>
        </Wrapper></Element>
    </Element>,



        <Element canvas is={Parent} id='parentId1500' className="w-[100%] h-auto" >
    <Element canvas is={Parent} id='parentId1600' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='randomm-id-503' is='div' className='h-full py-10'   style={{ width:'100%', background:'#2A2A2A'}}>
                <Wrapper canvas id='randomm-id-504' is='div' className='container'>
        <Wrapper canvas style={{ display: "inline-block", fontSize:'46px', fontFamily:'Rum Raisin', fontWeight:'400px', fontStyle:'normal', textAlign:'center', width:'100%', color:'#FFD600' }} className={`my-4 text-[46px] mt-4 mb-6`} id="random-id-36" is="div">
        <Text text="Solutions" />
        </Wrapper>
                    <Wrapper canvas id='randomm-id-505' is='div' className='h-full ' style={{display:'flex', flexDirection:'row', flexWrap:'wrap', justifyContent:'center', width:'100%'}}>
                        <Wrapper canvas id='randomm-id-506' is='div' className='mx-auto' style={{display:'flex', flexDirection:'column', maxWidth:"380px", minWidth:'324px', flex:'29%', minHeight:"260px",  textAlign:'center', justifyContent:'space-between', padding:'20px 0'}}>
                            <Wrapper canvas id='randomm-id-507' is='div' className='h-full justify-center flex' >
                            <SvgIcon fill='#FFD600' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                            </Wrapper>
        <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400px', fontFamily:'Rubik', color:'#FFFFFF', fontStyle:'normal' }} className='h-full' id="random-id-37" is="div">
        <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
        </Wrapper>
                        </Wrapper>
                        <Wrapper canvas id='randomm-id-508' is='div' className='mx-auto'  style={{display:'flex', flexDirection:'column', maxWidth:"380px", minWidth:'324px', flex:'29%', minHeight:"260px",  textAlign:'center', justifyContent:'space-between', padding:'20px 0'}}>
                            <Wrapper canvas id='randomm-id-509' is='div' className='h-full justify-center flex' >
                            <SvgIcon fill='#FFD600' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                            </Wrapper>
        <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400px', fontFamily:'Rubik', color:'#FFFFFF', fontStyle:'normal'}} className='h-full' id="random-id-38" is="div">
        <Text text="Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo." />
        </Wrapper>
                        </Wrapper>
                        <Wrapper canvas id='randomm-id-510' is='div' className='mx-auto'  style={{display:'flex', flexDirection:'column', maxWidth:"380px", minWidth:'324px', flex:'29%', minHeight:"260px",  textAlign:'center', justifyContent:'space-between', padding:'20px 0'}}>
                            <Wrapper canvas id='randomm-id-511' is='div' className='h-full justify-center flex' >
                            <SvgIcon fill='#FFD600' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                            </Wrapper>                        
        <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400px', fontFamily:'Rubik', color:'#FFFFFF', fontStyle:'normal'}} className='h-full'  id="random-id-39" is="div">
        <Text text="Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur." />
        </Wrapper>
                        </Wrapper>
                        <Wrapper canvas id='randomm-id-512' is='div' className='mx-auto'  style={{display:'flex', flexDirection:'column', maxWidth:"380px", minWidth:'324px', flex:'29%', minHeight:"260px",  textAlign:'center', justifyContent:'space-between', padding:'20px 0'}}>
                            <Wrapper canvas id='randomm-id-513' is='div' className='h-full justify-center flex' >
                            <SvgIcon fill='#FFD600' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                            </Wrapper>
        <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400px', fontFamily:'Rubik', color:'#FFFFFF', fontStyle:'normal'}}  className='h-full' id="random-id-40" is="div">
        <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
        </Wrapper>
                        </Wrapper>
                        <Wrapper canvas id='randomm-id-514' is='div' className='mx-auto'  style={{display:'flex', flexDirection:'column', maxWidth:"380px", minWidth:'324px', flex:'29%', minHeight:"260px",  textAlign:'center', justifyContent:'space-between', padding:'20px 0'}}>
                            <Wrapper canvas id='randomm-id-515' is='div' className='h-full justify-center flex' >
                            <SvgIcon fill='#FFD600' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                            </Wrapper>
        <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400px', fontFamily:'Rubik', color:'#FFFFFF', fontStyle:'normal' }} className='h-full' id="random-id-41" is="div">
        <Text text="Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo." />
        </Wrapper>
                        </Wrapper>
                        <Wrapper canvas id='randomm-id-516' is='div' className='mx-auto'  style={{display:'flex', flexDirection:'column', maxWidth:"380px", minWidth:'324px', flex:'29%', minHeight:"260px",  textAlign:'center', justifyContent:'space-between', padding:'20px 0'}}>
                            <Wrapper canvas id='randomm-id-517' is='div' className='h-full justify-center flex' >
                            <SvgIcon fill='#FFD600' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                            </Wrapper>                        
        <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400px', fontFamily:'Rubik', color:'#FFFFFF', fontStyle:'normal' }} className='h-full' id="random-id-42" is="div">
        <Text text="Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur." />
        </Wrapper>
                        </Wrapper>
                    </Wrapper>
                </Wrapper>
                </Wrapper></Element>
    </Element>,

                
        <Element canvas is={Parent} id='parentId1700' className="w-[100%] h-auto" >
    <Element canvas is={Parent} id='parentId1800' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='randomm-id-900' is='parent' className='h-full bg-[#F7FBFE] py-10'  style={{ width:'100%'}}>
        <Wrapper canvas id='randomm-id-901' is='container' className='container'>
                <Wrapper canvas style={{ display: "inline-block", fontFamily:'Rubik', color:'#6064D2', fontWeight:'700', fontStyle:'normal', textAlign:'center', width:'100%'}} className={`my-4 text-[46px]`} id="random-id-1" is="div">
                <Text text="Solutions." />
                </Wrapper>
            <Wrapper canvas id='randomm-id-902' is='wrapper' className='h-full' style={{display:'flex', flexDirection:'row', flexWrap:'wrap', justifyContent:'center', width:'100%'}}>
                <Wrapper canvas id='randomm-id-903' is='div' className='mx-auto' style={{display:'flex', flexDirection:'column', maxWidth:"380px", minWidth:'324px', flex:'29%', minHeight:"260px",   textAlign:'center', justifyContent:'space-between', padding:'20px 0'}}>
                    <Wrapper canvas id='randomm-id-904' is='div' className='h-full justify-center flex' >
                    <SvgIcon fill='#B20312' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                    </Wrapper>
                        <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400px', fontFamily:'Rubik', color:'#000', fontStyle:'normal'}} className='h-full'  id="random-id-2" is="div">
                        <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                        </Wrapper>
                </Wrapper>
                <Wrapper canvas id='randomm-id-905' is='div' className='mx-auto'  style={{display:'flex', flexDirection:'column', maxWidth:"380px", minWidth:'324px', flex:'29%', minHeight:"260px",  textAlign:'center', justifyContent:'space-between', padding:'20px 0'}}>
                    <Wrapper canvas id='randomm-id-906' is='div' className='h-full justify-center flex' >
                <SvgIcon fill='#B20312' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                    </Wrapper>
                        <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400px', fontFamily:'Rubik', color:'#000', fontStyle:'normal'}} className='h-full' id="random-id-3" is="div">
                        <Text text="Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo." />
                        </Wrapper>
                </Wrapper>
                <Wrapper canvas id='randomm-id-907' is='div' className='mx-auto'  style={{display:'flex', flexDirection:'column', maxWidth:"380px", minWidth:'324px', flex:'29%', minHeight:"260px",  textAlign:'center', justifyContent:'space-between', padding:'20px 0'}}>
                    <Wrapper canvas id='randomm-id-908' is='div' className='h-full justify-center flex' >
                <SvgIcon fill='#B20312' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                    </Wrapper>
                        <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400px', fontFamily:'Rubik', color:'#000', fontStyle:'normal' }} className='h-full' id="random-id-4" is="div">
                        <Text text="Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur." />
                        </Wrapper>
                </Wrapper>
                <Wrapper canvas id='randomm-id-909' is='div' className='mx-auto'  style={{display:'flex', flexDirection:'column', maxWidth:"380px", minWidth:'324px', flex:'29%', minHeight:"260px",  textAlign:'center', justifyContent:'space-between', padding:'20px 0'}}>
                    <Wrapper canvas id='randomm-id-910' is='div' className='h-full justify-center flex' >
                <SvgIcon fill='#B20312' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                    </Wrapper>
                        <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400px', fontFamily:'Rubik', color:'#000', fontStyle:'normal' }} className='h-full' id="random-id-5" is="div">
                        <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                        </Wrapper>
                </Wrapper>
                <Wrapper canvas id='randomm-id-911' is='div' className='mx-auto'  style={{display:'flex', flexDirection:'column', maxWidth:"380px", minWidth:'324px', flex:'29%', minHeight:"260px",  textAlign:'center', justifyContent:'space-between', padding:'20px 0'}}>
                <Wrapper canvas id='randomm-id-912' is='div' className='h-full justify-center flex' >
                <SvgIcon fill='#B20312' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                    </Wrapper>
                        <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400px', fontFamily:'Rubik', color:'#000', fontStyle:'normal'}} className='h-full' id="random-id-6" is="div">
                        <Text text="Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo." />
                        </Wrapper>
                </Wrapper>
                <Wrapper canvas id='randomm-id-913' is='div' className='mx-auto'  style={{display:'flex', flexDirection:'column', maxWidth:"380px", minWidth:'324px', flex:'29%', minHeight:"260px",  textAlign:'center', justifyContent:'space-between', padding:'20px 0'}}>
                <Wrapper canvas id='randomm-id-914' is='div' className='h-full justify-center flex' >
                <SvgIcon fill='#B20312' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                    </Wrapper>
                        <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400px', fontFamily:'Rubik', color:'#000', fontStyle:'normal'}} className='h-full' id="random-id-7" is="div">
                        <Text text="Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur." />
                        </Wrapper>
                </Wrapper>
            </Wrapper>
        </Wrapper>
        </Wrapper></Element>
    </Element>,


        <Element canvas is={Parent} id='parentId1900' className="w-[100%] h-auto" >
    <Element canvas is={Parent} id='parentId2000' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='randomm-id-1000' is='parent' className='h-full bg-[#FEFBF7] py-10'  style={{ width:'100%'}}>
        <Wrapper canvas id='randomm-id-1001' is='container' className='container'>
                <Wrapper canvas style={{ display: "inline-block", fontFamily:'Ruluko', color:'#A25738', fontWeight:'400', fontStyle:'normal', textAlign:'center', width:'100%'}} className={`my-4 text-[46px]`} id="random-id-1" is="div">
                <Text text="Solutions." />
                </Wrapper>
            <Wrapper canvas id='randomm-id-1002' is='wrapper' className='h-full' style={{display:'flex', flexDirection:'row', flexWrap:'wrap', justifyContent:'center', width:'100%'}}>
                <Wrapper canvas id='randomm-id-1003' is='div' className='mx-auto' style={{display:'flex', flexDirection:'column', maxWidth:"380px", minWidth:'324px', flex:'29%', minHeight:"260px",   textAlign:'center', justifyContent:'space-between', padding:'20px 0'}}>
                    <Wrapper canvas id='randomm-id-1004' is='div' className='h-full justify-center flex' >
                        <SvgIcon fill='#A25738' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                    </Wrapper>
                        <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400px', fontFamily:'Sansation', color:'#000', fontStyle:'normal'}} className='h-full'  id="random-id-2" is="div">
                        <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                        </Wrapper>
                </Wrapper>
                <Wrapper canvas id='randomm-id-1005' is='div' className='mx-auto'  style={{display:'flex', flexDirection:'column', maxWidth:"380px", minWidth:'324px', flex:'29%', minHeight:"260px",  textAlign:'center', justifyContent:'space-between', padding:'20px 0'}}>
                    <Wrapper canvas id='randomm-id-1006' is='div' className='h-full justify-center flex' >
                    <SvgIcon fill='#A25738' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                    </Wrapper>
                        <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400px', fontFamily:'Sansation', color:'#000', fontStyle:'normal'}} className='h-full' id="random-id-3" is="div">
                        <Text text="Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo." />
                        </Wrapper>
                </Wrapper>
                <Wrapper canvas id='randomm-id-1007' is='div' className='mx-auto'  style={{display:'flex', flexDirection:'column', maxWidth:"380px", minWidth:'324px', flex:'29%', minHeight:"260px",  textAlign:'center', justifyContent:'space-between', padding:'20px 0'}}>
                    <Wrapper canvas id='randomm-id-1008' is='div' className='h-full justify-center flex' >
                    <SvgIcon fill='#A25738' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                    </Wrapper>
                        <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400px', fontFamily:'Sansation', color:'#000', fontStyle:'normal' }} className='h-full' id="random-id-4" is="div">
                        <Text text="Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur." />
                        </Wrapper>
                </Wrapper>
                <Wrapper canvas id='randomm-id-1009' is='div' className='mx-auto'  style={{display:'flex', flexDirection:'column', maxWidth:"380px", minWidth:'324px', flex:'29%', minHeight:"260px",  textAlign:'center', justifyContent:'space-between', padding:'20px 0'}}>
                    <Wrapper canvas id='randomm-id-1010' is='div' className='h-full justify-center flex' >
                    <SvgIcon fill='#A25738' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                    </Wrapper>
                        <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400px', fontFamily:'Sansation', color:'#000', fontStyle:'normal' }} className='h-full' id="random-id-5" is="div">
                        <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
                        </Wrapper>
                </Wrapper>
                <Wrapper canvas id='randomm-id-1011' is='div' className='mx-auto'  style={{display:'flex', flexDirection:'column', maxWidth:"380px", minWidth:'324px', flex:'29%', minHeight:"260px",  textAlign:'center', justifyContent:'space-between', padding:'20px 0'}}>
                <Wrapper canvas id='randomm-id-1012' is='div' className='h-full justify-center flex' >
                    <SvgIcon fill='#A25738' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                    </Wrapper>
                        <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400px', fontFamily:'Sansation', color:'#000', fontStyle:'normal'}} className='h-full' id="random-id-6" is="div">
                        <Text text="Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo." />
                        </Wrapper>
                </Wrapper>
                <Wrapper canvas id='randomm-id-1013' is='div' className='mx-auto'  style={{display:'flex', flexDirection:'column', maxWidth:"380px", minWidth:'324px', flex:'29%', minHeight:"260px",  textAlign:'center', justifyContent:'space-between', padding:'20px 0'}}>
                <Wrapper canvas id='randomm-id-1014' is='div' className='h-full justify-center flex' >
                    <SvgIcon fill='#A25738' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                    </Wrapper>
                        <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'400px', fontFamily:'Sansation', color:'#000', fontStyle:'normal'}} className='h-full' id="random-id-7" is="div">
                        <Text text="Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur." />
                        </Wrapper>
                </Wrapper>
            </Wrapper>
        </Wrapper>
        </Wrapper></Element>
    </Element>,

    ]

       
const [sel, setSel] = useState(selected)

function makeOdd(number) {
  setProp((prop) => {
    setSel(100);
  }, 1000);

  setTimeout(() => {
    setProp((prop) => {
      setSel(number)
    }, 1000);
  }, 100);


  
}

useEffect(() => {
if(selected || selected >= 0) {
  makeOdd(selected);
}
},[selected])


  return (
    <div style={{ padding:enabled? "10px" : "0px", display:"flex", justifyContent:"center", alignItems:"center", width:'100%', height:'100%'}} ref={connect}>
    {sel == 100 ? (
          <div style={{height:"85vh", display:"flex", justifyContent:"center", alignItems:"center"}}>
          <BounceLoader
                  size={100}
                  color={"white"}
                  loading={true}
                />
          </div>
         ) : (
          styles[sel]
         )} 
</div>
  );
};

Solution_1.craft = {
    displayName: "Solution 1",
    props: {
       selected: 1, // here is the default value of the selected style
       length:9,
isBlock: true
      },
      rules: {
        canDrag: () => true,
        canDrop: () => true,
      },
      related: {
        toolbar: NewSolutionSettings,
      },
  };


