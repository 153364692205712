/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import { Element, useEditor, useNode } from '@craftjs/core';
import React, { useEffect, useRef, useState } from 'react';
import {NewSectionsSettings} from './newSectionsSetting'
import { Text } from '../../basic/Text';
import ModeNightIcon from '@mui/icons-material/ModeNight';
import NightsStayIcon from '@mui/icons-material/NightsStay';
import StarIcon from '@mui/icons-material/Star';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { ImageC } from '../../basic/Image';
import { Parent } from '../../Parent/index.js';
import { Wrapper } from '../../wrapper/index.js';
import { BounceLoader } from 'react-spinners';
import { SvgIcon } from '../../basic/Svg';


export const Sections_2 = ({selected}) => {
    const { 
        actions: { setProp },
          connectors: {
            connect
          } 
      } = useNode();
    
      
      const {
        enabled,
      } = useEditor((state, query) => ({
        enabled: state.options.enabled,
     
      }));

    const styles = [
        <Element canvas is={Parent} id='parentId100' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId200' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-400' is='parent' className='w-[100%] h-full bg-[#FFFFFF]'>
            <Wrapper canvas id='random-id-401' is='container' className='container'>
                    <Wrapper canvas style={{display:'inline-block', fontSize:'46px', fontFamily:'Poppins', fontWeight:'bold', color:'#000000'}} className='my-4' id='random-id-1' is='div'>
                        <Text text='What makes our clients happy'/>
                    </Wrapper>
                <Wrapper canvas id='random-id-402' is='wrapper' className='w-[100%] h-full flex flex-row flex-wrap justify-between mb-4'>
                    <Wrapper canvas id='random-id-403' is='div' className='  min-w-[300px] m-2 h-full flex flex-row' style={{flex:'47%'}}>
                        <Wrapper canvas id='random-id-404' is='div' className='bg-[#FFC92E] my-auto flex justify-center items-center ' style={{width:'100px', height:'100px', borderRadius:'14px'}}>
                        <SvgIcon fill='#2479FD' viewBox='0 0 46 46' path='M18.208 3.833c-3.488 0-6.765.959-9.583 2.588 5.73 3.316 9.583 9.487 9.583 16.579s-3.852 13.263-9.583 16.58a19.1 19.1 0 0 0 9.583 2.587c10.58 0 19.167-8.587 19.167-19.167S28.788 3.833 18.208 3.833' />
                        </Wrapper>
                        <Wrapper canvas id='random-id-405' is='div' className='w-[90%] h-full flex flex-col pl-4 my-auto justify-between'>
                            <Wrapper className='mb-2' canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'bold', fontFamily:'Poppins', color:'#000000'}} id='random-id-2' is='div'>
                                <Text text='Lorem ipsum dolor'/>
                            </Wrapper>
                            <Wrapper canvas style={{display:'inline-block', fontSize:'16px', fontWeight:'400px', fontFamily:'Poppins', color:'#000000'}} id='random-id-3' is='div'>
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                            </Wrapper>
                        </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-406' is='div' className='  min-w-[300px] m-2 h-full flex flex-row' style={{flex:'47%'}}>
                        <Wrapper canvas id='random-id-407' is='div' className='bg-[#FFC92E] my-auto d- justify-center items-center flex' style={{width:'100px', height:'100px', borderRadius:'14px'}}>
                        <SvgIcon fill='#2479FD' viewBox='0 0 46 46' path='m23 40.92-2.78-2.53C10.35 29.44 3.834 23.538 3.834 16.293c0-5.904 4.638-10.542 10.542-10.542 3.335 0 6.535 1.553 8.625 4.006 2.089-2.453 5.29-4.006 8.625-4.006 5.903 0 10.541 4.638 10.541 10.542 0 7.245-6.516 13.148-16.387 22.118z' />
                        </Wrapper>
                        <Wrapper canvas id='random-id-408' is='div' className='w-[90%] h-full flex flex-col pl-4 my-auto justify-between'>
                                <Wrapper className='mb-2' canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'bold', fontFamily:'Poppins', color:'#000000'}} id='random-id-4' is='div'>
                                    <Text text='Lorem ipsum dolor'/>
                                </Wrapper>
                          
                                <Wrapper canvas style={{display:'inline-block', fontSize:'16px', fontWeight:'400px', fontFamily:'Poppins', color:'#000000'}} id='random-id-5' is='div'>
                                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                                </Wrapper>
                        </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-409' is='div' className='  min-w-[300px] m-2 h-full flex flex-row' style={{flex:'47%'}}>
                        <Wrapper canvas id='random-id-410' is='div' className='bg-[#FFC92E] my-auto d- justify-center items-center flex' style={{width:'100px', height:'100px', borderRadius:'14px'}}>
                        <SvgIcon fill='#2479FD' viewBox='0 0 46 46' path='M27.658 19.167 24.84 9.89c-.556-1.82-3.124-1.82-3.66 0l-2.837 9.277h-8.53c-1.859 0-2.625 2.395-1.111 3.469l6.976 4.983-2.74 8.836c-.556 1.782 1.514 3.22 2.99 2.09L23 33.177l7.073 5.385c1.475 1.131 3.545-.306 2.99-2.089l-2.741-8.836 6.976-4.983c1.514-1.093.748-3.47-1.111-3.47h-8.53z' />
                        </Wrapper>
                        <Wrapper canvas id='random-id-411' is='div' className='w-[90%] h-full flex flex-col pl-4 my-auto justify-between'>
                            
                                <Wrapper className='mb-2' canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'bold', fontFamily:'Poppins', color:'#000000'}} id='random-id-6' is='div'>
                                    <Text text='Lorem ipsum dolor'/>
                                </Wrapper>
                          
                                <Wrapper canvas style={{display:'inline-block', fontSize:'16px', fontWeight:'400px', fontFamily:'Poppins', color:'#000000'}} id='random-id-7' is='div'>
                                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                                </Wrapper>
                        </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-412' is='div' className='  min-w-[300px] m-2 h-full flex flex-row' style={{flex:'47%'}}>
                        <Wrapper canvas id='random-id-413' is='div' className='bg-[#FFC92E] my-auto d- justify-center items-center flex' style={{width:'100px', height:'100px', borderRadius:'14px'}}>
                        <SvgIcon fill='#2479FD' viewBox='0 0 46 46' path='M21.275 23.153c-4.466-8.644-.958-16.253 1.016-19.3C12.017 4.217 3.795 12.63 3.795 23c0 .268.038.537.038.805A9.5 9.5 0 0 1 7.667 23a9.6 9.6 0 0 1 7.858 4.12 7.686 7.686 0 0 1 5.558 7.38c0 2.913-1.667 5.424-4.063 6.727a19.2 19.2 0 0 0 5.96.959c6.71 0 12.612-3.45 16.043-8.663-4.523.44-13.378-1.86-17.748-10.37M13.417 30.667h-.345c-.805-2.224-2.913-3.834-5.405-3.834a5.74 5.74 0 0 0-5.75 5.75 5.74 5.74 0 0 0 5.75 5.75h5.75A3.845 3.845 0 0 0 17.25 34.5a3.845 3.845 0 0 0-3.833-3.833' />
                        </Wrapper>
                        <Wrapper canvas id='random-id-414' is='div' className='w-[90%] h-full flex flex-col pl-4 my-auto justify-between'>
                            
                                <Wrapper className='mb-2' canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'bold', fontFamily:'Poppins', color:'#000000'}} id='random-id-8' is='div'>
                                    <Text text='Lorem ipsum dolor'/>
                                </Wrapper>
                          
                                <Wrapper canvas style={{display:'inline-block', fontSize:'16px', fontWeight:'400px', fontFamily:'Poppins', color:'#000000'}} id='random-id-9' is='div'>
                                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                                </Wrapper>
                        </Wrapper>
                    </Wrapper>
                </Wrapper>
            </Wrapper>
        </Wrapper></Element>
        </Element>
        ,
        <Element canvas is={Parent} id='parentId300' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId400' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-415' is='parent' className='w-[100%] h-full bg-[#FFFFFF]'>
            <Wrapper canvas id='random-id-416' is='container' className='container'>
                    <Wrapper canvas style={{display:'inline-block', fontSize:'46px', fontFamily:'Prompt', fontWeight:'bold', color:'#000000'}} className='my-4' id='random-id-10' is='div'>
                        <Text text='What makes our clients happy'/>
                    </Wrapper>
                <Wrapper canvas id='random-id-417' is='wrapper' className='w-[100%] h-full flex flex-row flex-wrap justify-between mb-4'>
                    <Wrapper canvas id='random-id-418' is='div' className='min-w-[300px] m-2 h-full flex flex-row' style={{flex:'47%'}}>
                        <Wrapper canvas id='random-id-419' is='div' className='bg-[#FF0060] my-auto d- justify-center items-center flex' style={{width:'100px', height:'100px', borderRadius:'20px'}}>
                        <SvgIcon fill='#F6F8A2' viewBox='0 0 46 46' path='M18.208 3.833c-3.488 0-6.765.959-9.583 2.588 5.73 3.316 9.583 9.487 9.583 16.579s-3.852 13.263-9.583 16.58a19.1 19.1 0 0 0 9.583 2.587c10.58 0 19.167-8.587 19.167-19.167S28.788 3.833 18.208 3.833' />
                        </Wrapper>
                        <Wrapper canvas id='random-id-420' is='div' className='w-[90%] h-full flex flex-col pl-4 my-auto justify-between'>
                            
                                <Wrapper canvas className='mb-2' style={{display:'inline-block', fontSize:'18px', fontWeight:'bold', fontFamily:'Prompt', color:'#000000'}} id='random-id-11' is='div'>
                                    <Text text='Lorem ipsum dolor'/>
                                </Wrapper>
                           
                                <Wrapper canvas style={{display:'inline-block', fontSize:'16px', fontWeight:'400px', fontFamily:'Prompt', color:'#000000'}} id='random-id-12' is='div'>
                                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                                </Wrapper>
                        </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-421' is='div' className='min-w-[300px] m-2 h-full flex flex-row' style={{flex:'47%'}}>
                        <Wrapper canvas id='random-id-422' is='div' className='bg-[#FF0060] my-auto d- justify-center items-center flex' style={{width:'100px', height:'100px', borderRadius:'20px'}}>
                        <SvgIcon fill='#F6F8A2' viewBox='0 0 46 46' path='m23 40.92-2.78-2.53C10.35 29.44 3.834 23.538 3.834 16.293c0-5.904 4.638-10.542 10.542-10.542 3.335 0 6.535 1.553 8.625 4.006 2.089-2.453 5.29-4.006 8.625-4.006 5.903 0 10.541 4.638 10.541 10.542 0 7.245-6.516 13.148-16.387 22.118z' />
                        </Wrapper>
                        <Wrapper canvas id='random-id-423' is='div' className='w-[90%] h-full flex flex-col pl-4 my-auto justify-between'>
                            
                                <Wrapper canvas className='mb-2' style={{display:'inline-block', fontSize:'18px', fontWeight:'bold', fontFamily:'Prompt', color:'#000000'}} id='random-id-13' is='div'>
                                    <Text text='Lorem ipsum dolor'/>
                                </Wrapper>
                           
                                <Wrapper canvas style={{display:'inline-block', fontSize:'16px', fontWeight:'400px', fontFamily:'Prompt', color:'#000000'}} id='random-id-14' is='div'>
                                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                                </Wrapper>
                        </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-424' is='div' className='min-w-[300px] m-2 h-full flex flex-row' style={{flex:'47%'}}>
                        <Wrapper canvas id='random-id-425' is='div' className='bg-[#FF0060] my-auto d- justify-center items-center flex' style={{width:'100px', height:'100px', borderRadius:'20px'}}>
                        <SvgIcon fill='#F6F8A2' viewBox='0 0 46 46' path='M27.658 19.167 24.84 9.89c-.556-1.82-3.124-1.82-3.66 0l-2.837 9.277h-8.53c-1.859 0-2.625 2.395-1.111 3.469l6.976 4.983-2.74 8.836c-.556 1.782 1.514 3.22 2.99 2.09L23 33.177l7.073 5.385c1.475 1.131 3.545-.306 2.99-2.089l-2.741-8.836 6.976-4.983c1.514-1.093.748-3.47-1.111-3.47h-8.53z' />
                        </Wrapper>
                        <Wrapper canvas id='random-id-426' is='div' className='w-[90%] h-full flex flex-col pl-4 my-auto justify-between'>
                            
                                <Wrapper canvas className='mb-2' style={{display:'inline-block', fontSize:'18px', fontWeight:'bold', fontFamily:'Prompt', color:'#000000'}} id='random-id-15' is='div'>
                                    <Text text='Lorem ipsum dolor'/>
                                </Wrapper>
                           
                                <Wrapper canvas style={{display:'inline-block', fontSize:'16px', fontWeight:'400px', fontFamily:'Prompt', color:'#000000'}} id='random-id-16' is='div'>
                                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                                </Wrapper>
                        </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-427' is='div' className='min-w-[300px] m-2 h-full flex flex-row' style={{flex:'47%'}}>
                        <Wrapper canvas id='random-id-428' is='div' className='bg-[#FF0060] my-auto d- justify-center items-center flex' style={{width:'100px', height:'100px', borderRadius:'20px'}}>
                        <SvgIcon fill='#F6F8A2' viewBox='0 0 46 46' path='M21.275 23.153c-4.466-8.644-.958-16.253 1.016-19.3C12.017 4.217 3.795 12.63 3.795 23c0 .268.038.537.038.805A9.5 9.5 0 0 1 7.667 23a9.6 9.6 0 0 1 7.858 4.12 7.686 7.686 0 0 1 5.558 7.38c0 2.913-1.667 5.424-4.063 6.727a19.2 19.2 0 0 0 5.96.959c6.71 0 12.612-3.45 16.043-8.663-4.523.44-13.378-1.86-17.748-10.37M13.417 30.667h-.345c-.805-2.224-2.913-3.834-5.405-3.834a5.74 5.74 0 0 0-5.75 5.75 5.74 5.74 0 0 0 5.75 5.75h5.75A3.845 3.845 0 0 0 17.25 34.5a3.845 3.845 0 0 0-3.833-3.833' />
                        </Wrapper>
                        <Wrapper canvas id='random-id-429' is='div' className='w-[90%] h-full flex flex-col pl-4 my-auto justify-between'>
                            
                                <Wrapper canvas className='mb-2' style={{display:'inline-block', fontSize:'18px', fontWeight:'bold', fontFamily:'Prompt', color:'#000000'}} id='random-id-17' is='div'>
                                    <Text text='Lorem ipsum dolor'/>
                                </Wrapper>
                           
                                <Wrapper canvas style={{display:'inline-block', fontSize:'16px', fontWeight:'400px', fontFamily:'Prompt', color:'#000000'}} id='random-id-18' is='div'>
                                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                                </Wrapper>
                        </Wrapper>
                    </Wrapper>
                </Wrapper>
            </Wrapper>
        </Wrapper></Element>
        </Element>,
        <Element canvas is={Parent} id='parentId500' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId600' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-430' is='parent' className='w-[100%] h-full bg-[#FFFFFF]'>
        <Wrapper canvas id='random-id-431' is='container' className='container'>
                <Wrapper canvas style={{display:'inline-block', fontSize:'46px', fontFamily:'Almarai', fontWeight:'bold', color:'#000000'}} className='my-4' id='random-id-19' is='div'>
                    <Text text='What makes our clients happy'/>
                </Wrapper>
            <Wrapper canvas id='random-id-432' is='wrapper' className='w-[100%] h-full flex flex-row flex-wrap justify-between mb-4'>
                <Wrapper canvas id='random-id-433' is='div' className='min-w-[300px] m-2 h-full flex flex-row' style={{flex:'47%'}}>
                    <Wrapper canvas id='random-id-434' is='div' className='bg-[#FFB7B7] my-auto d- justify-center items-center flex' style={{width:'100px', height:'100px', borderRadius:'20px'}}>
                    <SvgIcon fill='#F31559' viewBox='0 0 46 46' path='M18.208 3.833c-3.488 0-6.765.959-9.583 2.588 5.73 3.316 9.583 9.487 9.583 16.579s-3.852 13.263-9.583 16.58a19.1 19.1 0 0 0 9.583 2.587c10.58 0 19.167-8.587 19.167-19.167S28.788 3.833 18.208 3.833' />
                    </Wrapper>
                    <Wrapper canvas id='random-id-435' is='div' className='w-[90%] h-full flex flex-col pl-4 my-auto justify-between'>
                        
                            <Wrapper canvas className='mb-2' style={{display:'inline-block', fontSize:'18px', fontWeight:'bold', fontFamily:'Almarai', color:'#000000'}} id='random-id-20' is='div'>
                                <Text text='Lorem ipsum dolor'/>
                            </Wrapper>
                      
                            <Wrapper canvas style={{display:'inline-block', fontSize:'16px', fontWeight:'400px', fontFamily:'Almarai', color:'#000000'}} id='random-id-21' is='div'>
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                            </Wrapper>
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-436' is='div' className='min-w-[300px] m-2 h-full flex flex-row' style={{flex:'47%'}}>
                    <Wrapper canvas id='random-id-437' is='div' className='bg-[#FFB7B7] my-auto d- justify-center items-center flex' style={{width:'100px', height:'100px', borderRadius:'20px'}}>
                    <SvgIcon fill='#F31559' viewBox='0 0 46 46' path='m23 40.92-2.78-2.53C10.35 29.44 3.834 23.538 3.834 16.293c0-5.904 4.638-10.542 10.542-10.542 3.335 0 6.535 1.553 8.625 4.006 2.089-2.453 5.29-4.006 8.625-4.006 5.903 0 10.541 4.638 10.541 10.542 0 7.245-6.516 13.148-16.387 22.118z' />
                    </Wrapper>
                    <Wrapper canvas id='random-id-438' is='div' className='w-[90%] h-full flex flex-col pl-4 my-auto justify-between'>
                        
                            <Wrapper canvas className='mb-2' style={{display:'inline-block', fontSize:'18px', fontWeight:'bold', fontFamily:'Almarai', color:'#000000'}} id='random-id-22' is='div'>
                                <Text text='Lorem ipsum dolor'/>
                            </Wrapper>
                      
                            <Wrapper canvas style={{display:'inline-block', fontSize:'16px', fontWeight:'400px', fontFamily:'Almarai', color:'#000000'}} id='random-id-23' is='div'>
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                            </Wrapper>
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-439' is='div' className='min-w-[300px] m-2 h-full flex flex-row' style={{flex:'47%'}}>
                    <Wrapper canvas id='random-id-440' is='div' className='bg-[#FFB7B7] my-auto d- justify-center items-center flex' style={{width:'100px', height:'100px', borderRadius:'20px'}}>
                    <SvgIcon fill='#F31559' viewBox='0 0 46 46' path='M27.658 19.167 24.84 9.89c-.556-1.82-3.124-1.82-3.66 0l-2.837 9.277h-8.53c-1.859 0-2.625 2.395-1.111 3.469l6.976 4.983-2.74 8.836c-.556 1.782 1.514 3.22 2.99 2.09L23 33.177l7.073 5.385c1.475 1.131 3.545-.306 2.99-2.089l-2.741-8.836 6.976-4.983c1.514-1.093.748-3.47-1.111-3.47h-8.53z' />
                    </Wrapper>
                    <Wrapper canvas id='random-id-441' is='div' className='w-[90%] h-full flex flex-col pl-4 my-auto justify-between'>
                        
                            <Wrapper canvas className='mb-2' style={{display:'inline-block', fontSize:'18px', fontWeight:'bold', fontFamily:'Almarai', color:'#000000'}} id='random-id-24' is='div'>
                                <Text text='Lorem ipsum dolor'/>
                            </Wrapper>
                      
                            <Wrapper canvas style={{display:'inline-block', fontSize:'16px', fontWeight:'400px', fontFamily:'Almarai', color:'#000000'}} id='random-id-25' is='div'>
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                            </Wrapper>
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-442' is='div' className='min-w-[300px] m-2 h-full flex flex-row' style={{flex:'47%'}}>
                    <Wrapper canvas id='random-id-443' is='div' className='bg-[#FFB7B7] my-auto d- justify-center items-center flex' style={{width:'100px', height:'100px', borderRadius:'20px'}}>
                    <SvgIcon fill='#F31559' viewBox='0 0 46 46' path='M21.275 23.153c-4.466-8.644-.958-16.253 1.016-19.3C12.017 4.217 3.795 12.63 3.795 23c0 .268.038.537.038.805A9.5 9.5 0 0 1 7.667 23a9.6 9.6 0 0 1 7.858 4.12 7.686 7.686 0 0 1 5.558 7.38c0 2.913-1.667 5.424-4.063 6.727a19.2 19.2 0 0 0 5.96.959c6.71 0 12.612-3.45 16.043-8.663-4.523.44-13.378-1.86-17.748-10.37M13.417 30.667h-.345c-.805-2.224-2.913-3.834-5.405-3.834a5.74 5.74 0 0 0-5.75 5.75 5.74 5.74 0 0 0 5.75 5.75h5.75A3.845 3.845 0 0 0 17.25 34.5a3.845 3.845 0 0 0-3.833-3.833' />
                    </Wrapper>
                    <Wrapper canvas id='random-id-444' is='div' className='w-[90%] h-full flex flex-col pl-4 my-auto justify-between'>
                        
                            <Wrapper canvas className='mb-2' style={{display:'inline-block', fontSize:'18px', fontWeight:'bold', fontFamily:'Almarai', color:'#000000'}} id='random-id-26' is='div'>
                                <Text text='Lorem ipsum dolor'/>
                            </Wrapper>
                      
                            <Wrapper canvas style={{display:'inline-block', fontSize:'16px', fontWeight:'400px', fontFamily:'Almarai', color:'#000000'}} id='random-id-27' is='div'>
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                            </Wrapper>
                    </Wrapper>
                </Wrapper>
            </Wrapper>
        </Wrapper>
    </Wrapper></Element>
        </Element>,
    <Element canvas is={Parent} id='parentId700' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId800' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-445' is='parent' className='w-[100%] h-full bg-[#FFFFFF]'>
        <Wrapper canvas id='random-id-446' is='container' className='container'>
                <Wrapper canvas style={{display:'inline-block', fontSize:'46px', fontFamily:'Chivo', fontWeight:'bold', color:'#000000'}} className='my-4'  id='random-id-28' is='div'>
                    <Text text='What makes our clients happy'/>
                </Wrapper>
            <Wrapper canvas id='random-id-447' is='wrapper' className='w-[100%] h-full flex flex-row flex-wrap justify-between mb-4'>
                <Wrapper canvas id='random-id-448' is='div' className='min-w-[300px] m-2 h-full flex flex-row' style={{flex:'47%'}}>
                    <Wrapper canvas id='random-id-449' is='div' className='bg-[#0C134F]  my-auto justify-center items-center flex' style={{width:'100px', height:'100px', borderRadius:'20px'}}>
                    <SvgIcon fill='#D4ADFC' viewBox='0 0 46 46' path='M18.208 3.833c-3.488 0-6.765.959-9.583 2.588 5.73 3.316 9.583 9.487 9.583 16.579s-3.852 13.263-9.583 16.58a19.1 19.1 0 0 0 9.583 2.587c10.58 0 19.167-8.587 19.167-19.167S28.788 3.833 18.208 3.833' />
                    </Wrapper>
                    <Wrapper canvas id='random-id-450' is='div' className='w-[90%] h-full flex flex-col pl-4 my-auto justify-between'>
                        
                            <Wrapper canvas className='mb-2' style={{display:'inline-block', fontSize:'18px', fontWeight:'bold', fontFamily:'Chivo', color:'#000000'}} id='random-id-29' is='div'>
                                <Text text='Lorem ipsum dolor'/>
                            </Wrapper>
                     
                            <Wrapper canvas style={{display:'inline-block', fontSize:'16px', fontWeight:'400px', fontFamily:'Chivo', color:'#000000'}} id='random-id-30' is='div'>
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                            </Wrapper>
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-451' is='div' className='min-w-[300px] m-2 h-full flex flex-row' style={{flex:'47%'}}>
                    <Wrapper canvas id='random-id-452' is='div' className='bg-[#0C134F]  my-auto justify-center items-center flex' style={{width:'100px', height:'100px', borderRadius:'20px'}}>
                    <SvgIcon fill='#D4ADFC' viewBox='0 0 46 46' path='m23 40.92-2.78-2.53C10.35 29.44 3.834 23.538 3.834 16.293c0-5.904 4.638-10.542 10.542-10.542 3.335 0 6.535 1.553 8.625 4.006 2.089-2.453 5.29-4.006 8.625-4.006 5.903 0 10.541 4.638 10.541 10.542 0 7.245-6.516 13.148-16.387 22.118z' />
                    </Wrapper>
                    <Wrapper canvas id='random-id-453' is='div' className='w-[90%] h-full flex flex-col pl-4 my-auto justify-between'>
                        
                            <Wrapper canvas className='mb-2' style={{display:'inline-block', fontSize:'18px', fontWeight:'bold', fontFamily:'Chivo', color:'#000000'}} id='random-id-31' is='div'>
                                <Text text='Lorem ipsum dolor'/>
                            </Wrapper>
                     
                            <Wrapper canvas style={{display:'inline-block', fontSize:'16px', fontWeight:'400px', fontFamily:'Chivo', color:'#000000'}} id='random-id-32' is='div'>
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                            </Wrapper>
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-454' is='div' className='min-w-[300px] m-2 h-full flex flex-row' style={{flex:'47%'}}>
                    <Wrapper canvas id='random-id-455' is='div' className='bg-[#0C134F]  my-auto justify-center items-center flex' style={{width:'100px', height:'100px', borderRadius:'20px'}}>
                    <SvgIcon fill='#D4ADFC' viewBox='0 0 46 46' path='M27.658 19.167 24.84 9.89c-.556-1.82-3.124-1.82-3.66 0l-2.837 9.277h-8.53c-1.859 0-2.625 2.395-1.111 3.469l6.976 4.983-2.74 8.836c-.556 1.782 1.514 3.22 2.99 2.09L23 33.177l7.073 5.385c1.475 1.131 3.545-.306 2.99-2.089l-2.741-8.836 6.976-4.983c1.514-1.093.748-3.47-1.111-3.47h-8.53z' />
                    </Wrapper>
                    <Wrapper canvas id='random-id-456' is='div' className='w-[90%] h-full flex flex-col pl-4 my-auto justify-between'>
                        
                            <Wrapper canvas className='mb-2' style={{display:'inline-block', fontSize:'18px', fontWeight:'bold', fontFamily:'Chivo', color:'#000000'}} id='random-id-33' is='div'>
                                <Text text='Lorem ipsum dolor'/>
                            </Wrapper>
                     
                            <Wrapper canvas style={{display:'inline-block', fontSize:'16px', fontWeight:'400px', fontFamily:'Chivo', color:'#000000'}} id='random-id-34' is='div'>
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                            </Wrapper>
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-457' is='div' className='min-w-[300px] m-2 h-full flex flex-row' style={{flex:'47%'}}>
                    <Wrapper canvas id='random-id-458' is='div' className='bg-[#0C134F]  my-auto justify-center items-center flex' style={{width:'100px', height:'100px', borderRadius:'20px'}}>
                    <SvgIcon fill='#D4ADFC' viewBox='0 0 46 46' path='M21.275 23.153c-4.466-8.644-.958-16.253 1.016-19.3C12.017 4.217 3.795 12.63 3.795 23c0 .268.038.537.038.805A9.5 9.5 0 0 1 7.667 23a9.6 9.6 0 0 1 7.858 4.12 7.686 7.686 0 0 1 5.558 7.38c0 2.913-1.667 5.424-4.063 6.727a19.2 19.2 0 0 0 5.96.959c6.71 0 12.612-3.45 16.043-8.663-4.523.44-13.378-1.86-17.748-10.37M13.417 30.667h-.345c-.805-2.224-2.913-3.834-5.405-3.834a5.74 5.74 0 0 0-5.75 5.75 5.74 5.74 0 0 0 5.75 5.75h5.75A3.845 3.845 0 0 0 17.25 34.5a3.845 3.845 0 0 0-3.833-3.833' />
                    </Wrapper>
                    <Wrapper canvas id='random-id-459' is='div' className='w-[90%] h-full flex flex-col pl-4 my-auto justify-between'>
                        
                            <Wrapper canvas className='mb-2' style={{display:'inline-block', fontSize:'18px', fontWeight:'bold', fontFamily:'Chivo', color:'#000000'}} id='random-id-35' is='div'>
                                <Text text='Lorem ipsum dolor'/>
                            </Wrapper>
                     
                            <Wrapper canvas style={{display:'inline-block', fontSize:'16px', fontWeight:'400px', fontFamily:'Chivo', color:'#000000'}} id='random-id-36' is='div'>
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                            </Wrapper>
                    </Wrapper>
                </Wrapper>
            </Wrapper>
        </Wrapper>
    </Wrapper></Element>
        </Element>,

    ]

    
const [sel, setSel] = useState(selected)

function makeOdd(number) {
  setProp((prop) => {
    setSel(100);
  }, 1000);

  setTimeout(() => {
    setProp((prop) => {
      setSel(number)
    }, 1000);
  }, 100);


  
}

useEffect(() => {
if(selected || selected >= 0) {
  makeOdd(selected);
}
},[selected])

    return (
        <div style={{ padding: enabled? '10px' : "0px", display:"flex", justifyContent:"center", alignItems:"center", width:'100%', height:'100%'}} ref={connect}>
       {sel == 100 ? (
          <div style={{height:"85vh", display:"flex", justifyContent:"center", alignItems:"center"}}>
          <BounceLoader
                  size={100}
                  color={"white"}
                  loading={true}
                />
          </div>
         ) : (
          styles[sel]
         )} 
    </div>
    )
    }
    
    Sections_2.craft = {
    displayName: "Sections 2",
    props: {
       selected: 1, // here is the default value of the selected style
       length: 3, 
isBlock: true
      },
      rules: {
        canDrag: () => true,
        canDrop: () => true,
      },
      related: {
        toolbar: NewSectionsSettings,
      },
    };