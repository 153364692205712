/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, { useState } from 'react';

import { ToolbarSection, ToolbarItem } from '../../../editor';
import { ToolbarRadio } from '../../../editor/Toolbar/ToolbarRadio';
import { TabOption } from '../../../editor/Viewport/Sidebar/TabOption';
import { Layers } from '@craftjs/layers';

export const ButtonSettings = () => {
  const [activeTab, setActiveTab] = useState("Style Manager");


  return (
    <div className='flex flex-col component-settings' style={{ height:'100%'}}>
        <ToolbarItem
            full={true}
            type="properties"
          />
          
          <TabOption activeTab={activeTab} setActiveTab={setActiveTab}/>
      
      <div style={{overflowY:'scroll', scrollbarWidth:'none', overflowX:'hidden', height:'100%'}}>
        {activeTab === "Advanced" ? ( 
          <>
        <ToolbarSection title="Navigation" expanded={false} >
          <ToolbarItem
            full={true}
            propKey="href"
            type="select"
            label="href"
          />
        </ToolbarSection>
        </>
        ) : null }

        {activeTab === "Content" ? (
          <>
        <ToolbarSection title="Content" expanded={false} >
        <ToolbarItem
            full={true}
            propKey="text"
            type="header"
            label="Text"
          />
         </ToolbarSection>
        </>
        ) : null }

        {activeTab === "Style Manager" ? (
          <div className='w-[100%]'>
          <ToolbarSection
            expanded={false}
            title="Appearance"
            props={['background', 'color', 'margin', 'width', 'height', 'shadow']}
            summary={({ background, color, margin, width, height, shadow }) => {
              let bg = background || { r: 255, g: 255, b: 255, a:1 }
              let clr = color || { r: 92, g: 90, b: 90, a: 1 }
              let btnWidth = width || '0';
              let btnHeight = height || '0';
              let shado = shadow || { r: 92, g: 90, b: 90, a: 1 };

              return (
                <div className="flex flex-row-reverse">
                  <div
                    style={{
                      background:
                        bg && `rgba(${Object.values(bg)})`,
                    }}
                    className="shadow-md flex-end w-6 h-6 text-center flex items-center rounded-full bg-black"
                  >
                    <p
                      style={{
                        color: clr && `rgba(${Object.values(clr)})`,
                      }}
                      className="text-white w-full text-center"
                    >
                      T
                    </p>
                    <p>{btnWidth}</p>
                    <p>{btnHeight}</p>
                    <p>{shado}</p>

                  </div> 
                </div> 
              );
            }}  
          >
            <ToolbarItem propKey="buttonStyle" type="radio" label="Style">
              <ToolbarRadio value="full" label="Filled" />
              <ToolbarRadio value="outline" label="Outline" />
            </ToolbarItem>
            <ToolbarItem 
              full={true}
              propKey="background"
              type="bg"
              value={{ r: 255, g: 255, b: 255, a:1 }}
            />
            <ToolbarItem 
              full={true}
              propKey="shadow"
              type="slider"
              unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
              label="shadow"
            />
          </ToolbarSection>

         
<ToolbarSection
        expanded={false}
        title="margin"
        props={['margin']}
        summary={({ }) => {
          return;
        }}
      >
        <ToolbarItem propKey="marginTop" type="slider"
        unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
        label="Top" />
        <ToolbarItem propKey="marginRight" type="slider"
        unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
        label="Right" />
        <ToolbarItem propKey="marginBottom" type="slider"
        unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
        label="Bottom" />
        <ToolbarItem propKey="marginLeft" type="slider"
        unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
        label="Left" />
      </ToolbarSection>
      <ToolbarSection
expanded={false}
title="Size"
props={['width', 'height']}
summary={({ width, height }) => {
  return `${width} ${height}`;
}}
>
<ToolbarItem
  full={true}
  propKey="width"
  type="slider"
  unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
  label="Width"
/>
  <ToolbarItem propKey="minWidth"
  type="slider"
  unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
  label="Min-Width" />
  <ToolbarItem propKey="maxWidth"
  type="slider"
  unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
  label="Max-Width" />
<ToolbarItem
  full={true}
  propKey="height"
  type="slider"
  unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
  label="Height"
/>
</ToolbarSection>
          </div>
        ) : null}
        </div>
    </div>
  );
};
