/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import { Element, useEditor, useNode } from '@craftjs/core';
import React, { useEffect, useState } from 'react';
import { NewOurTeamSettings } from './newOurTeamSetting'
import { Text } from '../../basic/Text';
import { ImageC } from '../../basic/Image';
import { Parent } from '../../Parent/index.js';
import { Wrapper } from '../../wrapper/index.js';
import { BounceLoader } from 'react-spinners';

export const Team_2 = ({ selected }) => {

  const { 
    actions: { setProp },
      connectors: {
        connect
      } 
  } = useNode();

  
  const {
    enabled,
  } = useEditor((state, query) => ({
    enabled: state.options.enabled,
 
  }));
    const styles = [

        <Element canvas is={Parent} id='parentId100' className="w-[100%] h-auto" >
      <Element canvas is={Parent} id='parentId200' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-400' is='parent' className='w-[100%] h-full bg-[#F7F7F9] py-10' >
        <Wrapper canvas id='random-id-401' is='container' className='container h-full mx-auto ' style={{display:'flex', flexDirection:'column'}}>
            <Wrapper canvas style={{ display: "inline-block", fontWeight:'bold', fontFamily:'Poppins' }} className=' text-[#000000] text-[46px] py-[25px] mx-auto' id="random-id-1" is="div">
            <Text text="Team" />
            </Wrapper>
        <Wrapper canvas id='random-id-402' is='wrapper' className='w-[100%] h-full flex flex-row flex-wrap justify-center my-auto' >
          <Wrapper canvas id='random-id-403' is='div' className='mx-[5px] my-[10px] flex flex-col' style={{ width:'30%', minWidth:'290px', height:'100%', borderRadius:'30px', background:'#FFFFFF'}}>
              <Wrapper  canvas style={{display:'inline-block', height:'100%', width:'100%', borderRadius:'100%'}} className='max-h-[160px] min-h-[120px] max-w-[160px] min-w-[120px] mx-auto' id='random-id-100' is='div'>
                <ImageC className='w-[150px] h-[150px] object-cover ' style={{borderRadius:'100%'}} src='https://i.ibb.co/py3SMt4/image.png'/>
              </Wrapper>
            <Wrapper canvas id='random-id-404' is='div' className='mx-[10px] my-4 flex mx-auto text-center flex-col align-items-start '>
                <Wrapper canvas style={{ display: "inline-block", fontSize:'24px', fontWeight:'bold', fontFamily:'Poppins', color:'#000000' }} className='mx-auto' id="random-id-2" is="div">
                <Text text="Mark Twin" />
                </Wrapper>
                <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'300px', fontFamily:'Poppins', color:'#000000' }} className='mx-auto my-4' id="random-id-3" is="div">
                <Text text="CEO of Company" />
                </Wrapper>
                <Wrapper canvas style={{display:'inline-block', fontSize:'16px', fontWeight:'400px', fontFamily:'Poppins', color:'#000000', lineHeight:'25.6px'}} className='mx-auto px-10 ' id='random-id-4' is='div'>
                    <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.'/>
                </Wrapper>
            </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-405' is='div' className='mx-[5px] my-[10px] flex flex-col' style={{borderRadius:'30px', background:'#FFFFFF', width:'30%', minWidth:'290px', height:'100%'}}>
                <Wrapper  canvas style={{display:'inline-block', height:'100%', width:'100%', borderRadius:'100%'}} className='max-h-[160px] min-h-[120px] max-w-[160px] min-w-[120px] mx-auto' id='random-id-101' is='div'>
                  <ImageC className='w-[150px] h-[150px] object-cover my-4' style={{borderRadius:'100%'}} src='https://i.ibb.co/QQQ2ZSz/image.png'/>
                </Wrapper>
            <Wrapper canvas id='random-id-406' is='div' className='mx-[10px] my-4 flex text-center flex-col align-items-start '>
                <Wrapper canvas style={{ display: "inline-block", fontSize:'24px', fontWeight:'bold', fontFamily:'Poppins', color:'#000000' }} className='mx-auto' id="random-id-5" is="div">
                <Text text="Jane Austen" />
                </Wrapper>
                <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'300px', fontFamily:'Poppins', color:'#000000' }} className='mx-auto my-4' id="random-id-6" is="div">
                <Text text="Developement Team Lead" />
                </Wrapper>
                <Wrapper canvas style={{display:'inline-block', fontSize:'16px', fontWeight:'400px', fontFamily:'Poppins', color:'#000000', lineHeight:'25.6px'}} className='mx-auto px-10' id='random-id-7' is='div'>
                    <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.'/>
                </Wrapper>
            </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-407' is='div' className='mx-[5px] my-[10px] flex flex-col' style={{borderRadius:'30px', background:'#FFFFFF', width:'30%', minWidth:'290px', height:'100%'}}>
                <Wrapper  canvas style={{display:'inline-block', height:'100%', width:'100%', borderRadius:'100%'}} className='max-h-[160px] min-h-[120px] max-w-[160px] min-w-[120px] mx-auto' id='random-id-102' is='div'>
                  <ImageC className='w-[150px] h-[150px] object-cover my-4' style={{borderRadius:'100%'}} src='https://i.ibb.co/rpqJtLk/image.png'/>
                </Wrapper>
            <Wrapper canvas id='random-id-408' is='div' className='mx-[10px] my-4 flex text-center flex-col align-items-start '>
                <Wrapper canvas style={{ display: "inline-block", fontSize:'24px', fontWeight:'bold', fontFamily:'Poppins', color:'#000000' }} className='mx-auto' id="random-id-8" is="div">
                <Text text="Mark Twin" />
                </Wrapper>
                <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'300px', fontFamily:'Poppins', color:'#000000' }} className='mx-auto my-4' id="random-id-9" is="div">
                <Text text="Teach Lead" />
                </Wrapper>
                <Wrapper canvas style={{display:'inline-block', fontSize:'16px', fontWeight:'400px', fontFamily:'Poppins', color:'#000000', lineHeight:'25.6px'}} className='mx-auto px-10' id='random-id-10' is='div'>
                    <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.'/>
                </Wrapper>
            </Wrapper>
          </Wrapper>
        </Wrapper>
    </Wrapper>
  </Wrapper></Element>
      </Element>,

  <Element canvas is={Parent} id='parentId300' className="w-[100%] h-auto" >
      <Element canvas is={Parent} id='parentId400' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-409' is='parent' className='w-[100%] h-full bg-[#FDFDF3] py-10' >
  <Wrapper canvas id='random-id-410' is='container' className='container h-full mx-auto ' style={{display:'flex', flexDirection:'column'}}>
  <Wrapper canvas id='random-id-411' is='div' className=' text-[#000000] text-[46px] py-[25px] mx-auto' style={{fontWeight:'bold', fontFamily:'Prompt'}}>
      <Wrapper canvas style={{ display: "inline-block" }} id="random-id-11" is="div">
      <Text text="Team" />
      </Wrapper>
  </Wrapper>
  <Wrapper canvas id='random-id-412' is='wrapper' className='w-[100%] h-full flex flex-row flex-wrap justify-center my-auto' >
    <Wrapper canvas id='random-id-413' is='div' className='mx-[5px] my-[10px] flex flex-col' style={{ width:'30%', minWidth:'290px', height:'100%', borderRadius:'20px', background:'#FFFFFF'}}>
        <Wrapper  canvas style={{display:'inline-block', height:'100%', width:'100%', borderRadius:'100%'}} className='max-h-[160px] min-h-[120px] max-w-[160px] min-w-[120px] mx-auto' id='random-id-103' is='div'>
          <ImageC className='w-[150px] h-[150px] object-cover my-4' style={{borderRadius:'100%'}} src='https://i.ibb.co/py3SMt4/image.png'/>
        </Wrapper>
      <Wrapper canvas id='random-id-414' is='div' className='mx-[10px] my-4 flex mx-auto text-center flex-col align-items-start '>
          <Wrapper canvas style={{ display: "inline-block", fontSize:'24px', fontWeight:'bold', fontFamily:'Prompt', color:'#000000' }} className='mx-auto' id="random-id-12" is="div">
          <Text text="Mark Twin" />
          </Wrapper>
          <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'300px', fontFamily:'Prompt', color:'#000000' }} className='mx-auto my-4' id="random-id-13" is="div">
          <Text text="CEO of Company" />
          </Wrapper>
          <Wrapper canvas style={{display:'inline-block', fontSize:'16px', fontWeight:'400px', fontFamily:'Prompt', color:'#000000', lineHeight:'25.6px'}} className='mx-auto px-10 ' id='random-id-14' is='div'>
              <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.'/>
          </Wrapper>
      </Wrapper>
      </Wrapper>
      <Wrapper canvas id='random-id-415' is='div' className='mx-[5px] my-[10px] flex flex-col' style={{borderRadius:'20px', background:'#FFFFFF', width:'30%', minWidth:'290px', height:'100%'}}>
          <Wrapper  canvas style={{display:'inline-block', height:'100%', width:'100%', borderRadius:'100%'}} className='max-h-[160px] min-h-[120px] max-w-[160px] min-w-[120px] mx-auto' id='random-id-104' is='div'>
            <ImageC className='w-[150px] h-[150px] object-cover my-4' style={{borderRadius:'100%'}} src='https://i.ibb.co/QQQ2ZSz/image.png'/>
          </Wrapper>
      <Wrapper canvas id='random-id-416' is='div' className='mx-[10px] my-4 flex text-center flex-col align-items-start '>
          <Wrapper canvas style={{ display: "inline-block", fontSize:'24px', fontWeight:'bold', fontFamily:'Prompt', color:'#000000' }} className='mx-auto'  id="random-id-15" is="div">
          <Text text="Jane Austen" />
          </Wrapper>
          <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'300px', fontFamily:'Prompt', color:'#000000'}} className='mx-auto my-4' id="random-id-16" is="div">
          <Text text="Developement Team Lead" />
          </Wrapper>
          <Wrapper canvas style={{display:'inline-block', fontSize:'16px', fontWeight:'400px', fontFamily:'Prompt', color:'#000000', lineHeight:'25.6px'}} className='mx-auto px-10' id='random-id-17' is='div'>
              <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.'/>
          </Wrapper>
      </Wrapper>
      </Wrapper>
      <Wrapper canvas id='random-id-417' is='div' className='mx-[5px] my-[10px] flex flex-col' style={{borderRadius:'20px', background:'#FFFFFF', width:'30%', minWidth:'290px', height:'100%'}}>
          <Wrapper  canvas style={{display:'inline-block', height:'100%', width:'100%', borderRadius:'100%'}} className='max-h-[160px] min-h-[120px] max-w-[160px] min-w-[120px] mx-auto' id='random-id-105' is='div'>
            <ImageC className='w-[150px] h-[150px] object-cover my-4' style={{borderRadius:'100%'}} src='https://i.ibb.co/rpqJtLk/image.png'/>
          </Wrapper>
      <Wrapper canvas id='random-id-418' is='div' className='mx-[10px] my-4 flex text-center flex-col align-items-start '>
          <Wrapper canvas style={{ display: "inline-block", fontSize:'24px', fontWeight:'bold', fontFamily:'Prompt', color:'#000000'}} className='mx-auto' id="random-id-18" is="div">
          <Text text="Mark Twin" />
          </Wrapper>
          <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'300px', fontFamily:'Prompt', color:'#000000' }} className='mx-auto my-4' id="random-id-19" is="div">
          <Text text="Teach Lead" />
          </Wrapper>
          <Wrapper canvas style={{display:'inline-block', fontSize:'16px', fontWeight:'400px', fontFamily:'Prompt', color:'#000000', lineHeight:'25.6px'}} className='mx-auto px-10' id='random-id-20' is='div'>
              <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.'/>
          </Wrapper>
      </Wrapper>
    </Wrapper>
  </Wrapper>
  </Wrapper>
  </Wrapper></Element>
      </Element>,

  <Element canvas is={Parent} id='parentId500' className="w-[100%] h-auto" >
      <Element canvas is={Parent} id='parentId600' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-419' is='parent' className='w-[100%] h-full bg-[#FDF4F4] py-10' >
          <Wrapper canvas id='random-id-420' is='container' className='container h-full mx-auto ' style={{display:'flex', flexDirection:'column'}}>
              <Wrapper canvas style={{ display: "inline-block", fontWeight:'bold', fontFamily:'Poppins'}} className=' text-[#000000] text-[46px] py-[25px] mx-auto'id="random-id-21" is="div">
              <Text text="Team" />
              </Wrapper>
          <Wrapper canvas id='random-id-421' is='wrapper' className='w-[100%] h-full flex flex-row flex-wrap justify-center my-auto' >
            <Wrapper canvas id='random-id-422' is='div' className='mx-[5px] my-[10px] flex flex-col' style={{ width:'30%', minWidth:'290px', height:'100%', borderRadius:'20px 8px 8px 20px', background:'#FFFFFF'}}>
                <Wrapper  canvas style={{display:'inline-block', height:'100%', width:'100%', borderRadius:'100%'}} className='max-h-[160px] min-h-[120px] max-w-[160px] min-w-[120px] mx-auto' id='random-id-106' is='div'>
                  <ImageC className='w-[150px] h-[150px] object-cover my-4' style={{borderRadius:'100%'}} src='https://i.ibb.co/py3SMt4/image.png'/>
                </Wrapper>
              <Wrapper canvas id='random-id-423' is='div' className='mx-[10px] my-4 flex mx-auto text-center flex-col align-items-start '>
                  <Wrapper canvas style={{ display: "inline-block", fontSize:'24px', fontWeight:'bold', fontFamily:'Rubik', color:'#000000'}} className='mx-auto' id="random-id-22" is="div">
                  <Text text="Mark Twin" />
                  </Wrapper>
                  <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'300px', fontFamily:'Poppins', color:'#000000'}} className='mx-auto my-4' id="random-id-23" is="div">
                  <Text text="CEO of Company" />
                  </Wrapper>
                  <Wrapper canvas style={{display:'inline-block', fontSize:'16px', fontWeight:'400px', fontFamily:'Poppins', color:'#000000', lineHeight:'25.6px'}} className='mx-auto px-10 ' id='random-id-24' is='div'>
                      <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.'/>
                  </Wrapper>
              </Wrapper>
              </Wrapper>
              <Wrapper canvas id='random-id-424' is='div' className='mx-[5px] my-[10px] flex flex-col' style={{borderRadius:'8px 20px 8px 20px', background:'#FFFFFF', width:'30%', minWidth:'290px', height:'100%'}}>
                  <Wrapper  canvas style={{display:'inline-block', height:'100%', width:'100%', borderRadius:'100%'}} className='max-h-[160px] min-h-[120px] max-w-[160px] min-w-[120px] mx-auto' id='random-id-107' is='div'>
                    <ImageC className='w-[150px] h-[150px] object-cover my-4' style={{borderRadius:'100%'}} src='https://i.ibb.co/QQQ2ZSz/image.png'/>
                  </Wrapper>
              <Wrapper canvas id='random-id-425' is='div' className='mx-[10px] my-4 flex text-center flex-col align-items-start '>
                  <Wrapper canvas style={{ display: "inline-block", fontSize:'24px', fontWeight:'bold', fontFamily:'Rubik', color:'#000000'}} className='mx-auto' id="random-id-25" is="div">
                  <Text text="Jane Austen" />
                  </Wrapper>
                  <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'300px', fontFamily:'Poppins', color:'#000000' }} className='mx-auto my-4'  id="random-id-26" is="div">
                  <Text text="Developement Team Lead" />
                  </Wrapper>
                  <Wrapper canvas style={{display:'inline-block', fontSize:'16px', fontWeight:'400px', fontFamily:'Poppins', color:'#000000', lineHeight:'25.6px'}} className='mx-auto px-10' id='random-id-27' is='div'>
                      <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.'/>
                  </Wrapper>
              </Wrapper>
              </Wrapper>
              <Wrapper canvas id='random-id-426' is='div' className='mx-[5px] my-[10px] flex flex-col' style={{borderRadius:'8px 20px 20px 8px', background:'#FFFFFF', width:'30%', minWidth:'290px', height:'100%'}}>
                  <Wrapper  canvas style={{display:'inline-block', height:'100%', width:'100%', borderRadius:'100%'}}  className='max-h-[160px] min-h-[120px] max-w-[160px] min-w-[120px] mx-auto' id='random-id-108' is='div'>
                    <ImageC className='w-[150px] h-[150px] object-cover my-4' style={{borderRadius:'100%'}} src='https://i.ibb.co/rpqJtLk/image.png'/>
                  </Wrapper>
              <Wrapper canvas id='random-id-427' is='div' className='mx-[10px] my-4 flex text-center flex-col align-items-start '>
                  <Wrapper canvas style={{ display: "inline-block", fontSize:'24px', fontWeight:'bold', fontFamily:'Rubik', color:'#000000' }}  className='mx-auto' id="random-id-28" is="div">
                  <Text text="Mark Twin" />
                  </Wrapper>
                  <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'300px', fontFamily:'Poppins', color:'#000000' }} className='mx-auto my-4' id="random-id-29" is="div">
                  <Text text="Teach Lead" />
                  </Wrapper>
                  <Wrapper canvas style={{display:'inline-block', fontSize:'16px', fontWeight:'400px', fontFamily:'Poppins', color:'#000000', lineHeight:'25.6px'}} className='mx-auto px-10' id='random-id-30' is='div'>
                      <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.'/>
                  </Wrapper>
              </Wrapper>
            </Wrapper>
          </Wrapper>
      </Wrapper>
    </Wrapper></Element>
      </Element>,

    
  <Element canvas is={Parent} id='parentId700' className="w-[100%] h-auto" >
      <Element canvas is={Parent} id='parentId800' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-428' is='parent' className='w-[100%] h-full bg-[#F6F2FA] py-10' >
          <Wrapper canvas id='random-id-429' is='container' className='container h-full mx-auto ' style={{display:'flex', flexDirection:'column'}}>
              <Wrapper canvas style={{ display: "inline-block", fontWeight:'bold', fontFamily:'Poppins' }} className=' text-[#000000] text-[46px] py-[25px] mx-auto' id="random-id-31" is="div">
              <Text text="Team" />
              </Wrapper>
          <Wrapper canvas id='random-id-430' is='wrapper' className='w-[100%] h-full flex flex-row flex-wrap justify-center my-auto' >
            <Wrapper canvas id='random-id-431' is='div' className='mx-[5px] my-[10px] flex flex-col' style={{ width:'30%', minWidth:'290px', height:'100%', borderRadius:'8px', background:'#FFFFFF'}}>
                <Wrapper  canvas style={{display:'inline-block', height:'100%', width:'100%', borderRadius:'100%'}} className='max-h-[160px] min-h-[120px] max-w-[160px] min-w-[120px] mx-auto' id='random-id-109' is='div'>
                  <ImageC className='w-[150px] h-[150px] object-cover my-4' style={{borderRadius:'100%'}} src='https://i.ibb.co/py3SMt4/image.png'/>
                </Wrapper>
              <Wrapper canvas id='random-id-432' is='div'  className='mx-[10px] my-4 flex mx-auto text-center flex-col align-items-start '>
                  <Wrapper canvas style={{ display: "inline-block", fontSize:'24px', fontWeight:'bold', fontFamily:'Rubik', color:'#000000' }} className='mx-auto' id="random-id-32" is="div">
                  <Text text="Mark Twin" />
                  </Wrapper>
                  <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'300px', fontFamily:'Poppins', color:'#000000' }} className='mx-auto my-4' id="random-id-33" is="div">
                  <Text text="CEO of Company" />
                  </Wrapper>
                  <Wrapper canvas style={{display:'inline-block', fontSize:'16px', fontWeight:'400px', fontFamily:'Poppins', color:'#000000', lineHeight:'25.6px'}} className='mx-auto px-10 '  id='random-id-34' is='div'>
                      <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.'/>
                  </Wrapper>
              </Wrapper>
              </Wrapper>
              <Wrapper canvas id='random-id-433' is='div' className='mx-[5px] my-[10px] flex flex-col' style={{borderRadius:'8px', background:'#FFFFFF', width:'30%', minWidth:'290px', height:'100%'}}>
                  <Wrapper  canvas style={{display:'inline-block', height:'100%', width:'100%', borderRadius:'100%'}} className='max-h-[160px] min-h-[120px] max-w-[160px] min-w-[120px] mx-auto'  id='random-id-110' is='div'>
                    <ImageC className='w-[150px] h-[150px] object-cover my-4' style={{borderRadius:'100%'}} src='https://i.ibb.co/QQQ2ZSz/image.png'/>
                  </Wrapper>
              <Wrapper canvas id='random-id-434' is='div' className='mx-[10px] my-4 flex text-center flex-col align-items-start '>
                  <Wrapper canvas style={{ display: "inline-block", fontSize:'24px', fontWeight:'bold', fontFamily:'Rubik', color:'#000000' }} className='mx-auto' id="random-id-35" is="div">
                  <Text text="Jane Austen" />
                  </Wrapper>
                  <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'300px', fontFamily:'Poppins', color:'#000000' }} className='mx-auto my-4' id="random-id-36" is="div">
                  <Text text="Developement Team Lead" />
                  </Wrapper>
                  <Wrapper canvas style={{display:'inline-block', fontSize:'16px', fontWeight:'400px', fontFamily:'Poppins', color:'#000000', lineHeight:'25.6px'}} className='mx-auto px-10' id='random-id-37' is='div'>
                      <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.'/>
                  </Wrapper>
              </Wrapper>
              </Wrapper>
              <Wrapper canvas id='random-id-435' className='mx-[5px] my-[10px] flex flex-col' style={{borderRadius:'8px', background:'#FFFFFF', width:'30%', minWidth:'290px', height:'100%'}}>
                  <Wrapper canvas style={{display:'inline-block', height:'100%', width:'100%', borderRadius:'100%'}} className='max-h-[160px] min-h-[120px] max-w-[160px] min-w-[120px] mx-auto' id='random-id-111' is='div'>
                    <ImageC className='w-[150px] h-[150px] object-cover my-4' style={{borderRadius:'100%'}} src='https://i.ibb.co/rpqJtLk/image.png'/>
                  </Wrapper>
              <Wrapper canvas id='random-id-436' className='mx-[10px] my-4 flex text-center flex-col align-items-start '>
                  <Wrapper canvas style={{ display: "inline-block", fontSize:'24px', fontWeight:'bold', fontFamily:'Rubik', color:'#000000' }} className='mx-auto' id="random-id-38" is="div">
                  <Text text="Mark Twin" />
                  </Wrapper>
                  <Wrapper canvas style={{ display: "inline-block", fontSize:'18px', fontWeight:'300px', fontFamily:'Poppins', color:'#000000' }} className='mx-auto my-4' id="random-id-39" is="div">
                  <Text text="Teach Lead" />
                  </Wrapper>
                  <Wrapper canvas style={{display:'inline-block', fontSize:'16px', fontWeight:'400px', fontFamily:'Poppins', color:'#000000', lineHeight:'25.6px'}} className='mx-auto px-10' id='random-id-40' is='div'>
                      <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.'/>
                  </Wrapper>
              </Wrapper>
            </Wrapper>
          </Wrapper>
      </Wrapper>
      </Wrapper></Element>
      </Element>,
    ]

    
    
const [sel, setSel] = useState(selected)

function makeOdd(number) {
  setProp((prop) => {
    setSel(100);
  }, 1000);

  setTimeout(() => {
    setProp((prop) => {
      setSel(number)
    }, 1000);
  }, 100);


  
}

useEffect(() => {
if(selected || selected >= 0) {
  makeOdd(selected);
}
},[selected])

    
    return (
      <div style={{ padding:enabled? "10px" : "0px", display:"flex", justifyContent:"center", alignItems:"center", width:'100%', height:'100%'}} ref={connect}>
       {sel == 100 ? (
          <div style={{height:"85vh", display:"flex", justifyContent:"center", alignItems:"center"}}>
          <BounceLoader
                  size={100}
                  color={"white"}
                  loading={true}
                />
          </div>
         ) : (
          styles[sel]
         )} 
  </div>
  );
}   

Team_2.craft = {
    displayName: "Team 2",
    props: {
       selected: 1, // here is the default value of the selected style
       length:3,
isBlock: true
      },
      rules: {
        canDrag: () => true,
        canDrop: () => true,
      },
      related: {
        toolbar: NewOurTeamSettings,
      },
  };