import { useState } from "react";
import { EditNameIcon, EyeIcon, LangTool, LogoSite } from "../../components/editor/Viewport/EditorSvg";
import { useOptionsContext } from "../../OptionsContext";
import AreaEl from "../AreaEl";
import { CloseIcon, MapIcon } from "../../components/land/Svg";
import { IconPerson, MenuIcon3, SearchIcon } from "../../components/editor/Viewport/Pop-up/svgArray";
import { SvgIcon } from "../../components/selectors/basic/Svg";
import SmallWidgets from "./TopWidgets";
import { useSidesContext } from "../../SideContext";

// const widgets = [
//     { 
//         selected: true,
//         event: '',
//         name:'menuVis',
//         icon:{
//           path: 'M24 6h-24v-4h24v4zm0 4h-24v4h24v-4zm0 8h-24v4h24v-4z',
//           viewBox:'0 0 24 24',
//           name:'menu'
//         },
//         widgetdisplay: 'icon'
//       },
//       {
//         selected: true,
//         event:'',
//         name:'logoVis',
//         icon:{
//           path : "M16.5 4.5C16.5 5.17031 16.3547 5.80781 16.0875 6.38437L18.5625 10.6594C17.4516 11.8453 16.0219 12.7266 14.4141 13.1719L12 9L8.8125 14.5078C9.82031 14.8266 10.8891 15 12.0047 15C15.3188 15 18.2766 13.4672 20.2031 11.0625C20.7234 10.4156 21.6656 10.3125 22.3125 10.8281C22.9594 11.3438 23.0625 12.2906 22.5469 12.9375C20.0672 16.0219 16.2656 18 12 18C10.3406 18 8.74688 17.7 7.27969 17.1516L4.62656 21.7359C4.40625 22.1156 4.07812 22.425 3.68438 22.6219L1.0875 23.9203C0.853125 24.0375 0.576563 24.0234 0.35625 23.8875C0.135938 23.7516 0 23.5078 0 23.25V20.6531C0 20.2594 0.103125 19.8703 0.304688 19.5234L3.11719 14.6625C2.51719 14.1375 1.95938 13.5609 1.4625 12.9375C0.942188 12.2906 1.05 11.3484 1.69688 10.8281C2.34375 10.3078 3.28594 10.4156 3.80625 11.0625C4.07344 11.3953 4.35938 11.7094 4.65938 12.0047L7.9125 6.38437C7.65 5.8125 7.5 5.175 7.5 4.5C7.5 2.01562 9.51563 0 12 0C14.4844 0 16.5 2.01562 16.5 4.5ZM17.4844 18.4641C19.0125 17.8641 20.4141 17.0203 21.6516 15.9844L23.7 19.5234C23.8969 19.8656 24.0047 20.2547 24.0047 20.6531V23.25C24.0047 23.5078 23.8688 23.7516 23.6484 23.8875C23.4281 24.0234 23.1516 24.0375 22.9172 23.9203L20.3203 22.6219C19.9266 22.425 19.5984 22.1156 19.3781 21.7359L17.4844 18.4641ZM12 6C12.3978 6 12.7794 5.84196 13.0607 5.56066C13.342 5.27936 13.5 4.89782 13.5 4.5C13.5 4.10218 13.342 3.72064 13.0607 3.43934C12.7794 3.15804 12.3978 3 12 3C11.6022 3 11.2206 3.15804 10.9393 3.43934C10.658 3.72064 10.5 4.10218 10.5 4.5C10.5 4.89782 10.658 5.27936 10.9393 5.56066C11.2206 5.84196 11.6022 6 12 6Z",
//           viewBox:'0 0 24 24',
//           name: 'logo'
//         },
//         widgetdisplay: 'both'
//       },
//       {
//         selected: true,
//         event:'',
//         name:'langVis',
//         icon: {
//           path: "M28.2998 0H12.2998V5H16.2998V7H3.29981C1.59981 7 0.299805 8.3 0.299805 10V21C0.299805 22.7 1.59981 24 3.29981 24H4.29981V29.1L10.5998 24H17.2998V17H28.2998C29.9998 17 31.2998 15.7 31.2998 14V3C31.2998 1.3 29.9998 0 28.2998 0ZM11.0998 19.9L10.5998 18.3H7.49981L6.8998 19.9H4.4998L7.79981 11H10.1998L13.4998 19.9H11.0998ZM26.2998 12V14C24.9998 14 23.5998 13.6 22.3998 13C21.1998 13.6 19.7998 13.9 18.3998 14L18.2998 12C18.9998 12 19.6998 11.9 20.3998 11.7C19.4998 10.8 18.8998 9.7 18.5998 8.5H20.6998C20.9998 9.4 21.5998 10.1 22.2998 10.7C23.3998 9.8 24.0998 8.5 24.1998 7H18.1998V5H21.1998V3H23.1998V5H26.4998L26.5998 6C26.6998 8.1 25.8998 10.2 24.3998 11.7C25.0998 11.9 25.6998 12 26.2998 12Z",
//           viewBox:'0 0 32 30',
//           name:'lang'
//         },
//         widgetdisplay: 'icon'
//       },
//       {
//         selected: true,
//         event:'',
//         name:'mapVis',
//         icon: {
//           path: "M12 0c-3.148 0-6 2.553-6 5.702 0 4.682 4.783 5.177 6 12.298 1.217-7.121 6-7.616 6-12.298 0-3.149-2.851-5.702-6-5.702zm0 8c-1.105 0-2-.895-2-2s.895-2 2-2 2 .895 2 2-.895 2-2 2zm12 16l-6.707-2.427-5.293 2.427-5.581-2.427-6.419 2.427 4-9 3.96-1.584c.38.516.741 1.08 1.061 1.729l-3.523 1.41-1.725 3.88 2.672-1.01 1.506-2.687-.635 3.044 4.189 1.789.495-2.021.465 2.024 4.15-1.89-.618-3.033 1.572 2.896 2.732.989-1.739-3.978-3.581-1.415c.319-.65.681-1.215 1.062-1.731l4.021 1.588 3.936 9z",
//           viewBox:'0 0 24 24',
//           name:'map'
//         },
//         widgetdisplay: 'icon'
//       },
//       {
//         selected: true,
//         event:'',
//         name:'alertVis',
//         icon: {
//           path: "M28.75 36.417a3.833 3.833 0 0 1-3.546 3.823l-.287.01h-3.834a3.833 3.833 0 0 1-3.823-3.546l-.01-.287zM23 3.833A13.417 13.417 0 0 1 36.409 16.79l.008.46v7.214l3.492 6.985a2.108 2.108 0 0 1-1.666 3.04l-.22.011H7.977a2.108 2.108 0 0 1-1.974-2.85l.088-.201 3.492-6.985V17.25A13.417 13.417 0 0 1 23 3.833"        ,
//           viewBox:'0 0 46 46',
//           name:'alert'
//         },
//         widgetdisplay: 'icon'
//       },
     
//       {
//         selected: true,
//         event:'',
//         name:'userVis',
//         icon: {
//           path: "M9.00018 1.875C9.88226 1.87473 10.7412 2.15722 11.4509 2.681C12.1606 3.20479 12.6838 3.94228 12.9435 4.78524C13.2033 5.6282 13.186 6.53223 12.8942 7.36464C12.6024 8.19704 12.0514 8.91399 11.3222 9.41025C12.5879 9.8745 13.6858 10.7069 14.4744 11.8004C15.263 12.8939 15.7063 14.1984 15.7472 15.546C15.751 15.6208 15.7399 15.6956 15.7144 15.766C15.6889 15.8365 15.6496 15.9011 15.5988 15.9562C15.548 16.0112 15.4868 16.0556 15.4186 16.0866C15.3505 16.1177 15.2768 16.1348 15.2019 16.137C15.1271 16.1392 15.0525 16.1264 14.9826 16.0994C14.9128 16.0724 14.849 16.0317 14.7951 15.9797C14.7412 15.9278 14.6981 15.8656 14.6686 15.7967C14.639 15.7279 14.6235 15.6539 14.6229 15.579C14.5783 14.1176 13.9663 12.731 12.9168 11.7131C11.8673 10.6951 10.4626 10.1258 9.00056 10.1258C7.53847 10.1258 6.13383 10.6951 5.0843 11.7131C4.03478 12.731 3.42285 14.1176 3.37818 15.579C3.37371 15.7282 3.31015 15.8695 3.2015 15.9718C3.09284 16.0741 2.94799 16.1291 2.79881 16.1246C2.64962 16.1201 2.50833 16.0566 2.406 15.9479C2.30368 15.8393 2.24871 15.6944 2.25318 15.5452C2.29424 14.1978 2.7376 12.8935 3.5262 11.8001C4.3148 10.7068 5.41256 9.87445 6.67818 9.41025C5.94895 8.91399 5.39799 8.19704 5.10618 7.36464C4.81438 6.53223 4.79708 5.6282 5.05684 4.78524C5.3166 3.94228 5.83973 3.20479 6.54945 2.681C7.25918 2.15722 8.11811 1.87473 9.00018 1.875ZM6.00018 6C6.00018 6.79565 6.31625 7.55871 6.87886 8.12132C7.44147 8.68393 8.20453 9 9.00018 9C9.79583 9 10.5589 8.68393 11.1215 8.12132C11.6841 7.55871 12.0002 6.79565 12.0002 6C12.0002 5.20435 11.6841 4.44129 11.1215 3.87868C10.5589 3.31607 9.79583 3 9.00018 3C8.20453 3 7.44147 3.31607 6.87886 3.87868C6.31625 4.44129 6.00018 5.20435 6.00018 6Z",
//           viewBox:'0 0 18 18',
//           name:'user'
//         },
//         widgetdisplay: 'both'
//       },
//       {
//         selected: true,
//         event:'',
//         name:'searchVis',
//         icon: {
//           path:"M14.516 4.489a9.733 9.733 0 1 1-9.725 9.733 9.78 9.78 0 0 1 9.724-9.733m0-1.822a11.555 11.555 0 1 0 0 23.11 11.555 11.555 0 0 0 0-23.11m16.596 26.924-6.55-6.596-1.263 1.254 6.551 6.595a.888.888 0 0 0 1.454-.964.9.9 0 0 0-.192-.289",
//           viewBox: "0 0 32 32",
//           name:'search'
//         },
//         widgetdisplay: 'both',
//         value: '',
//         placeholder: 'Search Bar'
//       },
// ]

// const defaultRowSettings = {
//     visibility: false,
//     style: { background: '#FFFFFF' },
//     widgets: { visibility: false, widgets: widgets },
//     content: { text: '', href: '' },
//     pages: {},
//   };
  
const TopArea = ({
    styleWrIcon,
    styleRMIcon,
    review,
    selectedItem,
    handleClick,
    closePanel, setClosePanel,
    checkHeader,
    }) => {
    const { 
        appSettings,
        TopProps,
        rows,
        styles,
        setStyles,
        } = useSidesContext();

    const [open, setOpen] = useState('')

    const {
        theOptionRow,
        theOptionRow2,
        theOptionRow3,
        contents,
    } = TopProps

    const { state: idState, dispatch: idDispatch } = useOptionsContext();
    const { options: treeData } = idState;

    const color = appSettings?.color?.value
    const h1Styles = appSettings?.fonts?.h1
    const h2Styles = appSettings?.fonts?.h2
    const h3Styles = appSettings?.fonts?.h3
    const h4Styles = appSettings?.fonts?.h4
   
      const handleStyleChange = (id, updatedStyle) => {
        setStyles(prevStyles => ({
          ...prevStyles,
          [id]: updatedStyle,
        }));
      };

    if (checkHeader) {
        return (
            <div style={{height:'fit-content'}}>
               { theOptionRow !== '' && (
                <AreaEl review={review}
                open={open}
                setOpen={setOpen}
                closePanel={closePanel}
                setClosePanel={setClosePanel}   
                onClick={() => {
                setClosePanel(true)
                if (!review) {
                    setOpen('row1')
                }}}
                defaultStyle={styles.row1}
                onStyleChange={(newStyle) => handleStyleChange('row1', newStyle)}
                id='row1'
                style={{...styles.row1, display: theOptionRow === '' && 'none', position: 'relative', paddingTop:'1.5vmin', paddingBottom: '1.5vmin', borderBottom:`.25vmin solid ${color + '10'}`, zIndex:99999999 }}
                className='shadow-sm w-full  mx-auto text-center'
                >
                { theOptionRow === 'Small widgets' ? (
                    <div 
                        style={{
                            ...h4Styles, alignContent: 'center', height: '100%', alignItems: 'center', zIndex: 9999, 
                        }} className={`flex items-center`}>
                        <SmallWidgets 
                        h3Styles={h3Styles}
                        h4Styles={h4Styles}
                        color={color}
                        selectedItem={selectedItem}
                        review={review}
                        styleWrIcon={styleWrIcon}
                        styleRMIcon={styleRMIcon}
                        handleClick={handleClick} 
                        open={open} setOpen={setOpen} 
                        closePanel={closePanel} setClosePanel={setClosePanel}
                        styles={styles}
                        handleStyleChange={handleStyleChange} 
                        checkPos="header" 
                        />
                    </div>
                ) : theOptionRow === 'Basic Header' ? (
                     <div
                        style={{
                            alignContent: 'center', height: '100%', alignItems: 'center', zIndex: 9999,
                        }} className={`flex items-center`}>
                        <SmallWidgets 
                        h3Styles={h3Styles}
                        h4Styles={h4Styles}
                        color={color}
                        appSettings={appSettings}
                        selectedItem={selectedItem}
                        review={review}
                        styleWrIcon={styleWrIcon}
                        styleRMIcon={styleRMIcon}
                        handleClick={handleClick} 
                        open={open} setOpen={setOpen} 
                        closePanel={closePanel} setClosePanel={setClosePanel}
                        styles={styles}
                        checkPos="header" 
                        handleStyleChange={handleStyleChange} 
                        basic
                        />
                    </div>
                ) : theOptionRow === 'Navigation Pages' ? (
                    <div className="sitemap-container">
                            {treeData.map((node, id) => (
                                <div className="flex flex-column h-full w-[fit-content] min-w-[5vmin] max-w-[15vmin]">
                                <div style={{...h3Styles, color: selectedItem === node._id && color, borderBottom: selectedItem === node._id && `.25vmin solid ${color}` }} className={`sitemap-item`} onClick={() => handleClick(node._id)} key={id}>
                                    {node.name.length > 11 ? node.name.slice(0, 10) + "..." : node.name} 
                                </div>
                                {node?.children !== 0 && <div>
                                {node?.children?.map(child => {
                                    return (
                                        <div key={child._id} onClick={(e) => {
                                            e.stopPropagation()
                                            handleClick(child._id)
                                            }} 
                                            className={`sitemap-item text-center my-[1vmin]`}>
                                            <div style={{...h3Styles, color: selectedItem === child._id && color, borderBottom: selectedItem === child._id && `.25vmin solid ${color}` }}>
                                                {child.name.length > 11 ? child.name.slice(0, 10) + "..." : child.name} 
                                            </div>
                                        </div>
                                        )
                                    })}
                                </div>}
                                </div>
                            ))}
                        </div>
                ) : (
                <>
                <div style={h3Styles} className="link-container">
                    <div className="content-text">
                        {contents.content1.text || 'Content With a link'}
                    </div>
                    {contents.content1.href !== '' && (
                        <a className="content-link" style={{color: color}} target="_blank" rel="noopener noreferrer" href={contents.content1.href}>
                            Click
                        </a>
                    )}
                </div>
                </>
                )}
            </AreaEl>
               )}
                { rows > '1' && theOptionRow2 !== '' && (
                    <AreaEl review={review} open={open} onClick={() => {
                        setClosePanel(true)
                        if (!review) {
                            setOpen('row2')
                        }}} setOpen={setOpen} closePanel={closePanel} setClosePanel={setClosePanel} id='row2' 
                        defaultStyle={styles.row2}
                        onStyleChange={(newStyle) => handleStyleChange('row2', newStyle)}
                        style={{...styles.row2, position: 'relative', paddingTop:'1.5vmin', paddingBottom: '1.5vmin', borderBottom:`.25vmin solid ${color + '10'}`, display: (rows < 2 || theOptionRow2 === '') && 'none', width: '100%' }}
                        className='shadow-sm w-full mx-auto  text-center'
                    >
                    {theOptionRow2 === 'Small widgets' ? (
                        <div onClick={(e) => e.stopPropagation()}
                            style={{ alignContent: 'center', height: '100%', alignItems: 'center', zIndex: 9999, }} className='flex justify-between items-center'>
                            <SmallWidgets check="header" />
                        </div>
                    ) : theOptionRow2 === 'Navigation Pages' ? (
                        <div className="sitemap-container">
                            {treeData.map((node, id) => (
                                <div className="flex flex-column h-full w-[fit-content] min-w-[5vmin] max-w-[15vmin]">
                                <div style={{...h3Styles, color: selectedItem === node._id && color, borderBottom: selectedItem === node._id && `.25vmin solid ${color}` }} className={`sitemap-item`} onClick={() => handleClick(node._id)} key={id}>
                                    {node.name.length > 11 ? node.name.slice(0, 10) + "..." : node.name} 
                                </div>
                                {node?.children !== 0 && <div>
                                {node?.children?.map(child => {
                                    return (
                                        <div key={child._id} onClick={(e) => {
                                            e.stopPropagation()
                                            handleClick(child._id)
                                            }} 
                                            className={`sitemap-item text-center my-[1vmin]`}>
                                            <div style={{...h3Styles, color: selectedItem === child._id && color, borderBottom: selectedItem === child._id && `.25vmin solid ${color}` }}>
                                                {child.name.length > 11 ? child.name.slice(0, 10) + "..." : child.name} 
                                            </div>
                                        </div>
                                        )
                                    })}
                                </div>}
                                </div>
                            ))}
                        </div>
                    ) : (
                        <>
                            <div style={h3Styles} className="link-container">
                                <div className="content-text">
                                    {contents.content2.text || 'Content With a link'}
                                </div>
                                {contents.content2.href !== '' && (
                                    <a className="content-link" style={{color: color}} target="_blank" rel="noopener noreferrer" href={contents.content2.href}>
                                        Click
                                    </a>
                                )}
                            </div>
                        </>
                    )}
                </AreaEl>
                )}
               { rows === '3' && theOptionRow3 !== '' && (
                <AreaEl review={review} open={open} onClick={() => {
                    setClosePanel(true)
                    if (!review) {
                        setOpen('row3')
                    }}} setOpen={setOpen} closePanel={closePanel} setClosePanel={setClosePanel} id='row3' 
                    defaultStyle={styles.row3}
                    onStyleChange={(newStyle) => handleStyleChange('row3', newStyle)}
                    style={{...styles.row3, position: 'relative', paddingTop:'1.5vmin', paddingBottom: '1.5vmin', borderBottom:`.25vmin solid ${color + '10'}`, display: (rows < 3 || theOptionRow3 === '') && 'none', width: '100%', }}
                    className='shadow-sm w-full  text-center mx-auto'
            >
                { theOptionRow3 === 'Small widgets' ? (
                    <div onClick={(e) => e.stopPropagation()}
                        style={{ alignContent: 'center', height: 'auto', alignItems: 'center', zIndex: 9999, }} className='flex justify-between items-center'>
                        <SmallWidgets check="header" />
                    </div>
                ) : theOptionRow3 === 'Navigation Pages' ? (
                    <div className="sitemap-container">
                            {treeData.map((node, id) => (
                                <div className="flex flex-column h-full w-[fit-content] min-w-[5vmin] max-w-[15vmin]">
                                <div style={{...h3Styles, color: selectedItem === node._id && color, borderBottom: selectedItem === node._id && `.25vmin solid ${color}` }} className={`sitemap-item`} onClick={() => handleClick(node._id)} key={id}>
                                    {node.name.length > 11 ? node.name.slice(0, 10) + "..." : node.name} 
                                </div>
                                {node?.children !== 0 && <div>
                                {node?.children?.map(child => {
                                    return (
                                        <div key={child._id} onClick={(e) => {
                                            e.stopPropagation()
                                            handleClick(child._id)
                                            }} 
                                            className={`sitemap-item text-center my-[1vmin]`}>
                                            <div style={{...h3Styles, color: selectedItem === child._id && color, borderBottom: selectedItem === child._id && `.25vmin solid ${color}` }}>
                                                {child.name.length > 11 ? child.name.slice(0, 10) + "..." : child.name} 
                                            </div>
                                        </div>
                                        )
                                    })}
                                </div>}
                                </div>
                            ))}
                        </div>
                ) : (
                    <>
                        <div style={h3Styles} className="link-container">
                            <div className="content-text">
                                {contents.content3.text || 'Content With a link'}
                            </div>
                            {contents.content3.href !== '' && (
                                <a className="content-link" style={{color: color}} target="_blank" rel="noopener noreferrer" href={contents.content3.href}>
                                    Click
                                </a>
                            )}
                        </div>
                    </>
                )}
            </AreaEl>
               ) }
            </div>
        )
    }
    return null
}

export default TopArea