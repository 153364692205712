/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, { useState } from 'react';

import { Element, useEditor, useNode } from '@craftjs/core';
import { NewBannerSettings } from './newBannerSetting';

import { Text } from '../../basic/Text';
import { useRef } from 'react';
import { useEffect } from 'react';
import './Banner.css'
import { ImageC } from '../../basic/Image';
import { Button } from '../../basic/Button';
import { Parent } from '../../Parent/index.js';
import { Wrapper } from '../../wrapper/index.js';
import BounceLoader from 'react-spinners/BounceLoader';

export const Banner_2 = ({ selected }) => {
 

  const {
    enabled,
  } = useEditor((state, query) => ({
    enabled: state.options.enabled,
 
  }));

  const { 
    actions: { setProp },
      connectors: {
        connect
      } 
  } = useNode();

    const styles = [
      <Element canvas is={Parent} id='parentId11' className="w-[100%] h-auto" >
      <Element canvas is={Parent} id='parentId2' className="w-[100%] h-auto" row={true} >
      <Wrapper canvas id='random-id-400' className='w-[100%] h-[100%] bg-[#FFFFFF] py-10' >
        <Wrapper canvas id='random-id-401' className='container'>
          <Wrapper canvas id='random-id-402' className='w-[100%] h-[100%] flex flex-row flex-wrap align-items-center justify-between'>
          <Wrapper canvas id='random-id-403' className='w-full h-full m-4 justify-center flex flex-col' style={{flex:'40%'}}>
            <Wrapper canvas id='random-id-404' className='w-full h-full justify-center mb-4 flex flex-col'>
                <Wrapper canvas style={{ display: "flex", fontWeight: '700', fontFamily: 'Rubik' }} className={`text-[#100103] text-[52px]`} id="random-id-1" >
                  <Text id='random-id-4041'  text="Understand User Flow." />
                </Wrapper>
                <Wrapper canvas style={{ display: "flex", fontWeight: '700', fontFamily: 'Rubik' }} className={`text-[#B20312] text-[50px]`}  id="random-id-2" >
                  <Text id='random-id-4044'  text="Increase Conversion." />
                </Wrapper>
            </Wrapper>
                <Wrapper canvas style={{ display: "flex", fontFamily: 'Rubik', fontWeight: '400', lineHeight:'42px' }} className={` h-full my-8 text-[#100103]  text-[25px]`} id="random-id-3" is="div">
                  <Text id='random-id-4046' text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
                </Wrapper>
            <Wrapper canvas id='random-id-406' className={`w-full h-full flex flex-row flex-wrap  `}>
                <Wrapper canvas id="random-id-200" className="w-[260px] h-[70px] cursor-pointer m-2" style={{ boxShadow: "0px 10px 10px 0px rgba(255, 255, 255, 0.05)" }}>
                          <Button id='random-id-40472'  classn='block' className="bg-[#B20312] w-[260px] h-[70px] py-[15px] hover:bg-[#87000C] text-center text-[#FFFFFF] text-[22px] justify-center flex items-ceter font-semibold uppercase font-['Rubik']">
                            <Wrapper canvas style={{display:'inline-block'}} id='random-id-4'>
                              <Text id='random-id-4047' text='Get Started'/>
                            </Wrapper>
                          </Button>
                </Wrapper>
                <Wrapper canvas id="random-id-201" className="w-[260px] h-[70px] cursor-pointer m-2" style={{ boxShadow: '0px 4px 10px 0px #0000001A' }}>
                    <Button id='random-id-40471'  classn='block' className="w-[260px] h-[70px] bg-[#FFFFFF] border-1 h-[70px] py-[15px] hover:border-[#B20312] text-center text-[#100103] text-[22px] font-semibold uppercase flex items-ceter justify-center font-['Rubik']">
                    <Wrapper canvas style={{display:'inline-block'}} id='random-id-5'>
                        <Text id='random-id-4048' text='Learn more'/>
                      </Wrapper>
                    </Button>
                </Wrapper>
            </Wrapper>
          </Wrapper>
              <Wrapper canvas id="random-id-407" className='h-[780px] max-h-[100%] min-h-[220px] flex justify-center' style={{ flex:'40%' }}>
                <Wrapper canvas className='w-[100%] h-[100%]' id="random-id-100" is="div">
                    <ImageC id={"random-id-4049"} src='https://i.ibb.co/TT289pG/banner2-style1.jpg' className='w-full h-full' />
                </Wrapper>
              </Wrapper> 
          </Wrapper>
        </Wrapper>
      </Wrapper>
          </Element>
        </Element>
      ,
      // Wrapper 2 
      <Element canvas is={Parent} id='parentId121' className="w-[100%] h-auto" >
      <Element canvas is={Parent} id='parentId22' className="w-[100%] h-auto" row={true} >
      <Wrapper canvas id='random-id-408' is='parent' className='w-[100%] h-[100%] bg-gray-900 py-10' >
        <Wrapper canvas id='random-id-409' is='container' className='container'>
          <Wrapper canvas id='random-id-410' is='wrapper' className='w-[100%] h-[100%] flex flex-row flex-wrap align-items-center justify-between'>
          <Wrapper canvas id='random-id-411' is='container' className='h-full m-4 justify-center flex flex-col' style={{flex:'40%'}}>
            <Wrapper canvas id='random-id-412' is='container' className='w-full h-full flex justify-center mb-4 flex flex-col'>
                <Wrapper canvas style={{ display: "inline-block", fontWeight: '700', fontFamily: 'Rubik', fontStretch: 'expanded' }} className={`UnderstandUserFlow2 text-[50px]`} id="random-id-6" is="div">
                <Element id='random-id-4042' is={Text} text="Understand User Flow." />
                </Wrapper>
                <Wrapper canvas style={{ display: "inline-block", fontWeight: '700', fontFamily: 'Rubik', fontStretch: 'expanded' }} className={`UnderstandUserFlow2 text-[50px]`} id="random-id-7" is="div">
                  <Text text="Increase Conversion." />
                </Wrapper>
            </Wrapper>
                <Wrapper canvas style={{ display: "inline-block", fontFamily: 'Rubik', fontWeight: '400', lineHeight:'42px' }} className={`w-full h-full my-8 text-[#FFFFFF]  text-[25px]`} id="random-id-8" is="div">
                  <Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
                </Wrapper>
            <Wrapper canvas id='random-id-414' is='container' className={`w-full h-full flex flex-row flex-wrap  `}>
                <Wrapper canvas id="random-id-202" is='div' className="w-[260px] h-[70px] cursor-pointer m-2" style={{ boxShadow: "0px 10px 10px 0px rgba(255, 255, 255, 0.05)" }}>
                    <Button  classn='block' className="GetStarted bg-[#3265E3] w-[260px] h-[70px] py-[15px] hover:bg-[#0049FF] text-center text-[#FFFFFF] text-[22px] font-semibold flex justify-center items-center uppercase cursor-pointer" style={{fontFamily:'Rubik'}}>
                    <Wrapper canvas style={{display:'inline-block'}} id='random-id-9' is='div'>
                        <Text text='Get Started'/>
                      </Wrapper>
                    </Button>
                </Wrapper>
                <Wrapper canvas id="random-id-203" is='div' className="w-[260px] h-[70px] cursor-pointer m-2" style={{ boxShadow: "0px 10px 10px 0px rgba(255, 255, 255, 0.05)", border:'1px solid #FFFFFF1A' }}>
                    <Button  classn='block' className="bg-gray-900 w-[260px] h-[70px] py-[15px] hover:bg-[#3265E3] text-center text-[#FFFFFF] text-[22px] font-semibold uppercase flex justify-center items-center cursor-pointer" style={{fontFamily:'Rubik'}}>
                    <Wrapper canvas style={{display:'inline-block'}} id='random-id-10' is='div'>
                        <Text text='Learn more'/>
                      </Wrapper>
                    </Button>
                </Wrapper>
            </Wrapper>
          </Wrapper>
          
              <Wrapper canvas id='random-id-415' is='wrapper' className=' h-[780px] max-h-[100%] min-h-[220px] flex justify-center' style={{flex:'40%'}}>
                  <Wrapper canvas className='w-[100%] h-[100%]' id='random-id-101' is='div'>
                    <ImageC className='w-[100%] h-[100%] object-cover' src='https://i.ibb.co/3ScDwnR/2.jpg' />
                  </Wrapper>        
              </Wrapper>
          
          </Wrapper>
        </Wrapper>
      </Wrapper>
      </Element>
        </Element>,
      
      // Wrapper 3
      <Element canvas is={Parent} id='parentId31' className="w-[100%] h-auto" >
      <Element canvas is={Parent} id='parentId32' className="w-[100%] h-auto" row={true} >
      <Wrapper canvas id='random-id-416' is='parent' className='w-[100%] h-[100%] bg-[#213156] py-10' >
        <Wrapper canvas id='random-id-417' is='container' className='container'>
          <Wrapper canvas id='random-id-418' is='wrapper' className='w-[100%] h-[100%] flex flex-row flex-wrap align-items-center justify-between'>
          <Wrapper canvas id='random-id-419' is='container' className='h-full m-4 justify-center flex flex-col' style={{flex:'40%'}}>
            <Wrapper canvas id='random-id-420' is='container' className='w-full h-full flex justify-center mb-4 flex flex-col'>
                <Wrapper canvas style={{ display: "inline-block", fontWeight: '700', fontFamily: 'Open Sans', fontStretch: 'expanded' }} className={`text-[#FFFFFF] text-[50px]`} id="random-id-11" is="div">
                  <Text text="Understand User Flow." />
                </Wrapper>
                <Wrapper canvas style={{ display: "inline-block", fontWeight: '700', fontFamily: 'Open Sans', fontStretch: 'expanded' }} className={`text-[#EA6EF4] text-[50px]`} id="random-id-12" is="div">
                  <Text text="Increase Conversion." />
                </Wrapper>
            </Wrapper>
           
                <Wrapper canvas style={{ display: "inline-block", fontFamily: 'Open Sans', fontWeight: '400', lineHeight:'42px' }} className={`w-full h-full my-8 text-[#FFFFFF]  text-[25px]`} id="random-id-13" is="div">
                  <Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
                </Wrapper>
            <Wrapper canvas id='random-id-422' is='container' className={`w-full h-full flex flex-row flex-wrap  `}>
                  <Wrapper canvas id="random-id-204" is='div' className="w-[260px] h-[70px] m-2" style={{borderRadius:'40px'}}>
                    <Button  classn='block' className="GetStarted bg-[#EA6EF4] w-[260px] h-[70px] py-[15px] hover:bg-[#E111F2] text-center text-[#FFFFFF] text-[22px] flex justify-center items-center font-semibold uppercase cursor-pointer" style={{fontFamily:'Open Sans'}}>
                    <Wrapper canvas style={{display:'inline-block'}} id='random-id-14' is='div'>
                        <Text text='Get Started'/>
                      </Wrapper>
                    </Button>
                  </Wrapper>
                  <Wrapper canvas id="random-id-205" is='div' className="w-[260px] h-[70px] cursor-pointer m-2" style={{borderRadius:'40px'}}>
                    <Button  classn='block' className="text-center bg-[#213156] w-[260px] h-[70px] py-[15px] hover:bg-[#EA6EF4] border-1 border-[#EA6EF4] text-[#EA6EF4] w-[260px] h-[70px] py-[15px] hover:text-[#FFFFFF] text-[22px] font-semibold uppercase cursor-pointer flex justify-center items-center" style={{fontFamily:'Open Sans'}}>
                    <Wrapper canvas style={{display:'inline-block'}} id='random-id-15' is='div'>
                        <Text text='Learn more'/>
                      </Wrapper>
                    </Button>
                  </Wrapper>
            </Wrapper>
          </Wrapper>
        
              <Wrapper canvas id='random-id-423' is='wrapper' className=' h-[780px] max-h-[100%] min-h-[220px] flex justify-center' style={{flex:'40%', borderRadius:'180px 0px 0px 0px'}}>
                  <Wrapper canvas className='w-[100%] h-[100%]' id='random-id-102' is='div'>
                    <ImageC className='w-[100%] h-[100%] object-cover' style={{borderRadius:'20px'}} src='https://i.ibb.co/x6CPpPq/banner2-style3.jpg' />
                  </Wrapper>
              </Wrapper>
          
          </Wrapper>
        </Wrapper>
      </Wrapper>
      </Element>
        </Element>
      ,
      // Wrapper 4
      <Element canvas is={Parent} id='parentId41' className="w-[100%] h-auto" >
      <Element canvas is={Parent} id='parentId42' className="w-[100%] h-auto" row={true} >
      <Wrapper canvas id='random-id-424' is='parent' className='w-[100%] h-[100%] bg-[#DFFFF9] py-10' >
        <Wrapper canvas id='random-id-425' is='container' className='container'>
          <Wrapper canvas id='random-id-426' is='wrapper' className='w-[100%] h-[100%] flex flex-row flex-wrap align-items-center justify-between'>
          <Wrapper canvas id='random-id-427' is='container' className='h-full m-4 justify-center flex flex-col' style={{flex:'40%'}}>
            <Wrapper canvas id='random-id-428' is='container' className='w-full h-full flex justify-center mb-4 flex flex-col'>
                <Wrapper canvas style={{ display: "inline-block", fontWeight: '700', fontFamily: 'Zing Script Rust SemiBold Demo', fontStyle:'italic', fontStretch: 'expanded' }} className={`text-[#031815] text-[50px]`} id="random-id-16" is="div">
                  <Text text="Understand User Flow." />
                </Wrapper>
                <Wrapper canvas style={{ display: "inline-block", fontWeight: '700', fontFamily: 'Zing Script Rust SemiBold Demo', fontStyle:'italic', fontStretch: 'expanded' }} className={`text-[#31A993] text-[50px]`} id="random-id-17" is="div">
                  <Text text="Increase Conversion." />
                </Wrapper>
            </Wrapper>
            
                <Wrapper canvas style={{ display: "inline-block", fontFamily: 'Montserrat', fontWeight: '400', lineHeight:'42px' }} className={`w-full h-full my-8 text-[#031815]  text-[25px]`} id="random-id-18" is="div">
                  <Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
                </Wrapper>
            <Wrapper canvas id='random-id-430' is='wrapper' className={`w-full h-full flex flex-row flex-wrap  `}>
                  <Wrapper canvas id="random-id-206" is='div' className="w-[260px] h-[70px] cursor-pointer m-2" style={{borderRadius:'40px'}}>
                    <Button  classn='block' className="GetStarted bg-[#31A993] w-[260px] h-[70px] py-[15px] hover:bg-[#11816C] text-center text-[#FFFFFF] text-[22px] flex justify-center items-center font-semibold uppercase cursor-pointer" style={{fontFamily:'Montserrat'}}>
                    <Wrapper canvas style={{display:'inline-block'}} id='random-id-19' is='div'>
                        <Text text='Get Started'/>
                      </Wrapper>
                    </Button>
                </Wrapper>
                  <Wrapper canvas id="random-id-207" is='div' className="w-[260px] h-[70px] cursor-pointer m-2" style={{borderRadius:'40px'}}>
                    <Button  classn='block' className="text-center bg-[#DFFFF9] w-[260px] h-[70px] py-[15px] hover:bg-[#31A993] border-1 border-[#31A993] text-[#31A993] w-[260px] h-[70px] py-[15px] hover:text-[#DFFFF9] text-[22px] font-semibold uppercase cursor-pointer flex justify-center items-center" style={{fontFamily:'Montserrat'}}>
                    <Wrapper canvas style={{display:'inline-block'}} id='random-id-20' is='div'>
                        <Text text='Learn more'/>
                      </Wrapper>
                    </Button>
                  </Wrapper>
            </Wrapper>
          </Wrapper>

              <Wrapper canvas id='random-id-431' is='wrapper' className=' h-[780px] max-h-[100%] min-h-[220px] flex justify-center img4' style={{flex:'28%'}}>
                  <Wrapper canvas className='w-[100%] h-[100%]' id='random-id-103' is='div'>
                    <ImageC className='w-[100%] h-[100%] object-cover' src='https://i.ibb.co/mbG84yX/banner2-4.jpg' />
                  </Wrapper>       
              </Wrapper>
          
          </Wrapper>
        </Wrapper>
      </Wrapper>

      </Element>
        </Element>
      ,
      // Wrapper 6
      <Element canvas is={Parent} id='parentId51' className="w-[100%] h-auto" >
      <Element canvas is={Parent} id='parentId52' className="w-[100%] h-auto" row={true} >
      <Wrapper canvas id='random-id-432' is='parent' className='w-[100%] h-[100%] bg-[#FFFFFF] py-10' >
        <Wrapper canvas id='random-id-432' is='container' className='container'>
          <Wrapper canvas id='random-id-432' is='wrapper' className='w-[100%] h-[100%] flex flex-row flex-wrap align-items-center justify-between'>
          <Wrapper canvas id='random-id-432' is='container' className='h-full m-4 justify-center flex flex-col' style={{flex:'40%'}}>
            <Wrapper canvas id='random-id-432' is='container' className='w-full h-full flex justify-center mb-4 flex flex-col'>
                <Wrapper canvas style={{ display: "inline-block", fontWeight: '700', fontFamily: 'Inter', fontStretch: 'expanded' }} className={`text-[#020E17] text-[50px]`} id="random-id-21" is="div">
                  <Text text="Understand User Flow." />
                </Wrapper>
                <Wrapper canvas style={{ display: "inline-block", fontWeight: '700', fontFamily: 'Inter', fontStretch: 'expanded' }} className={`text-[#0078D3] text-[50px]`} id="random-id-22" is="div">
                  <Text text="Increase Conversion." />
                </Wrapper>
            </Wrapper>
           
                <Wrapper canvas style={{ display: "inline-block", fontFamily: 'Inter', fontWeight: '400', lineHeight:'42px' }} className={`w-full h-full my-8 text-[#020E17]  text-[25px]`} id="random-id-23" is="div">
                  <Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
                </Wrapper>
            <Wrapper canvas id='random-id-432' is='container' className={`w-full h-full flex flex-row flex-wrap  `}>
                  <Wrapper canvas id="random-id-208" is='div' className="w-[260px] h-[70px] cursor-pointer m-2" style={{borderRadius:'8px', boxShadow: '0px 4px 10px 0px #0000001A'}}>
                    <Button  classn='block' className="GetStarted bg-[#0078D3] w-[260px] h-[70px] py-[15px] hover:bg-[#11578B] text-center text-[#FFFFFF] text-[22px] flex justify-center items-center font-semibold uppercase cursor-pointer" style={{fontFamily:'Inter'}}>
                    <Wrapper canvas style={{display:'inline-block'}} id='random-id-24' is='div'>
                        <Text text='Get Started'/>
                      </Wrapper>
                    </Button>
                  </Wrapper> 
                  <Wrapper canvas id="random-id-209" is='div' className="w-[260px] h-[70px] cursor-pointer m-2" style={{borderRadius:'8px', boxShadow: '0px 4px 10px 0px #0000001A'}}>
                    <Button  classn='block' className="text-center bg-[#FFFFFF] w-[260px] h-[70px] py-[15px] hover:bg-[#0078D3] border-1 border-[#0078D3] text-[#0078D3] hover:text-[#DFFFF9] text-[22px] font-semibold uppercase cursor-pointer flex justify-center items-center" style={{fontFamily:'Inter'}}>
                    <Wrapper canvas style={{display:'inline-block'}} id='random-id-25' is='div'>
                        <Text text='Learn more'/>
                      </Wrapper>
                    </Button>
                </Wrapper>
            </Wrapper>
          </Wrapper>
          
              <Wrapper canvas id='random-id-432' is='wrapper' className=' h-[780px] max-h-[100%] min-h-[220px] flex justify-center p-14' style={{flex:'40%', borderRadius:'8px'}}>
                <Wrapper canvas className='w-[100%] h-[100%]' id='random-id-104' is='div'>
                  <ImageC className='w-[100%] h-[100%] object-cover' style={{borderRadius:'8px'}} src='https://i.ibb.co/v1JTGmj/banner2-5.jpg' />
                </Wrapper>
              </Wrapper>
          
          </Wrapper>
        </Wrapper>
      </Wrapper>
      </Element>
        </Element>,
        <Element canvas is={Parent} id='parentId1122' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId0022' className="w-[100%] h-auto" row={true} >
        <Wrapper canvas id='random-id-40190' className='w-[100%] h-[100%] bg-[#020212]' >
          <Wrapper canvas id='random-id-4001' className=''>
            <Wrapper canvas id='random-id-4102' className='w-[100%] h-[100%] flex flex-row flex-wrap align-items-center justify-between'>
            <Wrapper canvas id='random-id-4203' className='w-full px-[8%] h-full m-4 justify-center flex flex-col' style={{flex:'40%'}}>
              <Wrapper canvas id='random-id-4304' className='w-full h-full justify-center mb-4 flex flex-col'>
                  <Wrapper canvas style={{ display: "flex", fontWeight: '700', fontFamily: 'Voces' }} className={`text-[#FFF] text-[50px]`}  id="random-id-2222" >
                    <Text  text="Increase Conversion." />
                  </Wrapper>
              </Wrapper>
                  <Wrapper canvas style={{ display: "flex", fontFamily: 'Voces', fontWeight: '400', lineHeight:'42px' }} className={` h-full my-8 text-[#FFF]  text-[25px]`} id="random-id-3333" is="div">
                    <Text  text="Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab ill." />
                  </Wrapper>
              <Wrapper canvas id='random-id-4606' className={`w-full h-full flex flex-row flex-wrap  `}>

                  <Wrapper canvas id="random-id-20122" className="w-[260px] h-[70px] cursor-pointer m-2" style={{ boxShadow: '0px 4px 10px 0px #0000001A' }}>
                      <Button id='random-id-470471'  classn='block' className="w-[260px] h-[70px] bg-[transparent] hover:bg-[#FFFFFF] border-1 h-[70px] py-[15px] text-center hover:text-[#100103] text-[#FFF] text-[22px] font-semibold uppercase flex items-ceter justify-center font-['Voces']">
                      <Wrapper canvas style={{display:'inline-block'}} id='random-id-5555'>
                          <Text text='Learn more'/>
                        </Wrapper>
                      </Button>
                  </Wrapper>
              </Wrapper>
            </Wrapper>
                <Wrapper canvas id="random-id-4907" className='p-4 bg-[#FFF] h-[780px] max-h-[100%] min-h-[220px] flex justify-center' style={{ flex:'40%' }}>
                  <Wrapper canvas className='w-[100%] h-[100%]' id="random-id-1005211" is="div">
                      <ImageC src='https://i.ibb.co/Y2LD38Y/57fc61b521b635f5f0149b6290c976d8.jpg' className='w-[100%] h-[100%] object-cover' />
                  </Wrapper>
                </Wrapper> 
            </Wrapper>
          </Wrapper>
        </Wrapper>
            </Element>
          </Element>
        ,
        <Element canvas is={Parent} id='parentId321' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId123' className="w-[100%] h-auto" row={true} >
        <Wrapper canvas id='random-id-1' className='w-[100%] h-[100%] bg-[#FBFAFF] py-10' >
          <Wrapper canvas id='random-id-2' className='container'>
            <Wrapper canvas id='random-id-3' className='w-[100%] h-[100%] flex flex-row flex-wrap align-items-center justify-between'>
            <Wrapper canvas id='random-id-4' className='w-full h-full m-4 justify-center flex flex-col' style={{flex:'40%'}}>
              <Wrapper canvas id='random-id-5' className='w-full h-full justify-center mb-4 flex flex-col'>
                  <Wrapper canvas style={{ display: "flex", fontWeight: '700', fontFamily: 'Roboto' }} className={`text-[#6750A4] text-[50px]`}  id="random-id-6" >
                    <Text  text="Increase Conversion." />
                  </Wrapper>
              </Wrapper>
                  <Wrapper canvas style={{ display: "flex", fontFamily: 'Roboto', fontWeight: '400', lineHeight:'42px' }} className={` h-full my-8 text-[#100103]  text-[25px]`} id="random-id-7" is="div">
                    <Text text="Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab ill." />
                  </Wrapper>
              <Wrapper canvas id='random-id-8' className={`w-full h-full flex flex-row flex-wrap  `}>

                  <Wrapper canvas id="random-id-9" className="w-[260px] h-[70px] cursor-pointer m-2" style={{ boxShadow: '0px 4px 10px 0px #0000001A', borderRadius:'100px' }}>
                      <Button  classn='block' className="w-[260px] rounded-[100px] h-[70px] bg-[#D0BCFF] border-1 h-[70px] py-[15px] text-center text-[#381E72] text-[22px] font-semibold uppercase flex items-ceter justify-center font-['Roboto']">
                      <Wrapper canvas style={{display:'inline-block'}} id='random-id-10'>
                          <Text text='Learn more'/>
                        </Wrapper>
                      </Button>
                  </Wrapper>
              </Wrapper>
            </Wrapper>
                <Wrapper canvas id="random-id-11" className='h-[780px] max-h-[100%] min-h-[220px] flex justify-center' style={{ flex:'40%', borderRadius:'40px' }}>
                      <ImageC src='https://i.ibb.co/vQQzMc6/60b9286dfb602d87e301e30eb4f1b36b.jpg' className='w-[100%] h-[100%] object-cover' />
                </Wrapper> 
            </Wrapper>
          </Wrapper>
        </Wrapper>
            </Element>
          </Element>
        ,

        <Element canvas is={Parent} id='parentId3001' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId1003' className="w-[100%] h-auto" row={true} >
        <Wrapper canvas id='random-id-12' className='w-[100%] h-[100%] bg-[#2A2A2A]' >
          <Wrapper canvas id='random-id-13' className=''>
            <Wrapper canvas id='random-id-14' className='w-[100%] h-[100%] flex flex-row flex-wrap align-items-center justify-between'>
            <Wrapper canvas id='random-id-15' className='px-[8%] w-full h-full m-4 justify-center flex flex-col' style={{flex:'40%'}}>
              <Wrapper canvas id='random-id-16' className='w-full h-full justify-center mb-4 flex flex-col'>
                  <Wrapper canvas style={{ display: "flex", fontWeight: '700', fontFamily: 'Rum Raisin' }} className={`text-[#FFD600] text-[50px]`}  id="random-id-17" >
                    <Text  text="Increase Conversion." />
                  </Wrapper>
              </Wrapper>
                  <Wrapper canvas style={{ display: "flex", fontFamily: 'Sansation', fontWeight: '400', lineHeight:'42px' }} className={` h-full my-8 text-[#FFF]  text-[25px]`} id="random-id-18" is="div">
                    <Text text="Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab ill." />
                  </Wrapper>
              <Wrapper canvas id='random-id-19' className={`w-full h-full flex flex-row flex-wrap  `}>

                  <Wrapper canvas id="random-id-20" className="w-[260px] h-[70px] cursor-pointer m-2" style={{borderRadius:'10px' }}>
                      <Button  classn='block' className="w-[260px] rounded-[100px] h-[70px] bg-[#FFD600] border-1 h-[70px] py-[15px] text-center text-[#2A2A2A] text-[22px] font-semibold uppercase flex items-ceter justify-center font-['Sansation']">
                      <Wrapper canvas style={{display:'inline-block'}} id='random-id-21'>
                          <Text text='Learn more'/>
                        </Wrapper>
                      </Button>
                  </Wrapper>
              </Wrapper>
            </Wrapper>
                <Wrapper canvas id="random-id-22" className='h-[780px] max-h-[100%] min-h-[220px] flex justify-center' style={{ flex:'40%' }}>
                <Wrapper canvas id='random-id-20001111' is='div' className='w-[100%] h-[100%]' style={{ borderRadius:'0px 0px 0px 300px'}}>
                  <ImageC src='https://i.ibb.co/wWfNWTm/029e9321816539ce1f6b868fe8be395a-Expires-1707696000-Key-Pair-Id-APKAQ4-GOSFWCVNEHN3-O4-Signature-LZs.jpg' style={{borderRadius:'0px 0px 0px 300px', border:'15px solid #FFD600',}} className='w-[100%] h-[100%] object-cover' />

                </Wrapper>
                </Wrapper> 
            </Wrapper>
          </Wrapper>
        </Wrapper>
            </Element>
          </Element>,

          
        <Element canvas is={Parent} id='parentId32221' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId12333' className="w-[100%] h-auto" row={true} >
        <Wrapper canvas id='random-id-911' className='w-[100%] h-[100%] bg-[#FFFFFF] py-10' >
          <Wrapper canvas id='random-id-922' className='container'>
            <Wrapper canvas id='random-id-933' className='w-[100%] h-[100%] flex flex-row flex-wrap align-items-center justify-between'>
            <Wrapper canvas id='random-id-944' className='w-full h-full m-4 justify-center flex flex-col' style={{flex:'40%'}}>
              <Wrapper canvas id='random-id-955' className='w-full h-full justify-center mb-4 flex flex-col'>
                  <Wrapper canvas style={{ display: "flex", fontWeight: '700', fontFamily: 'Rubik' }} className={`text-[#6064D2] text-[50px]`}  id="random-id-966" >
                    <Text  text="Increase Conversion" />
                  </Wrapper>
              </Wrapper>
                  <Wrapper canvas style={{ display: "flex", fontFamily: 'Rubik', fontWeight: '400', lineHeight:'42px' }} className={` h-full my-8 text-[#100103]  text-[25px]`} id="random-id-977" is="div">
                    <Text text="Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab ill." />
                  </Wrapper>
              <Wrapper canvas id='random-id-988' className={`w-full h-full flex flex-row flex-wrap  `}>

                  <Wrapper canvas id="random-id-999" className="w-[320px] h-[50px] cursor-pointer m-2" style={{ borderRadius:'10px' }}>
                      <Button  classn='block' className="w-[320px] rounded-[100px] h-[50px] bg-[#B20312] border-1 py-[5px] text-center text-[#FFF] text-[22px] font-semibold uppercase flex items-ceter justify-center font-['Rubik']">
                      <Wrapper canvas style={{display:'inline-block'}} id='random-id-91010'>
                          <Text text='Learn more'/>
                        </Wrapper>
                      </Button>
                  </Wrapper>
              </Wrapper>
            </Wrapper>
                <Wrapper canvas id="random-id-91111" className='h-[780px] max-h-[100%] min-h-[220px] flex justify-center' style={{ flex:'40%', borderRadius:'10px' }}>
                      <ImageC src='https://i.ibb.co/Hp7wsnz/1ece263745535005c59462ceeadbd9bd.jpg' className='w-[100%] h-[100%] object-cover' />
                </Wrapper> 
            </Wrapper>
          </Wrapper>
        </Wrapper>
            </Element>
          </Element>
        ,

        <Element canvas is={Parent} id='parentId30010' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId10030' className="w-[100%] h-auto" row={true} >
        <Wrapper canvas id='random-id-1112' className='w-[100%] h-[100%] bg-[#FEFBF7] py-10' >
          <Wrapper canvas id='random-id-1213' className='container'>
            <Wrapper canvas id='random-id-1314' className='w-[100%] h-[100%] flex flex-row flex-wrap align-items-center justify-between'>
            <Wrapper canvas id='random-id-1415' className=' w-full h-full m-4 justify-center flex flex-col' style={{flex:'40%'}}>
              <Wrapper canvas id='random-id-1516' className='w-full h-full justify-center mb-4 flex flex-col'>
                  <Wrapper canvas style={{ display: "flex", fontWeight: '700', fontFamily: 'Ruluko' }} className={`text-[#A25738] text-[50px]`}  id="random-id-1617" >
                    <Text  text="Increase Conversion" />
                  </Wrapper>
              </Wrapper>
                  <Wrapper canvas style={{ display: "flex", fontFamily: 'Sansation', fontWeight: '400', lineHeight:'42px' }} className={` h-full my-8 text-[#100103]  text-[25px]`} id="random-id-1718" is="div">
                    <Text text="Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab ill." />
                  </Wrapper>
              <Wrapper canvas id='random-id-1819' className={`w-full h-full flex flex-row flex-wrap  `}>

                  <Wrapper canvas id="random-id-1920" className="w-[280px] h-[60px] cursor-pointer m-2" style={{borderRadius:'10px' }}>
                      <Button  classn='block' className="w-[280px] rounded-[100px] h-[60px] bg-[#A25738] border-1 py-[11px] text-center text-[#2A2A2A] text-[22px] font-semibold uppercase flex items-ceter justify-center font-['Sansation']">
                      <Wrapper canvas style={{display:'inline-block'}} id='random-id-12021'>
                          <Text text='Learn more'/>
                        </Wrapper>
                      </Button>
                  </Wrapper>
              </Wrapper>
            </Wrapper>
                <Wrapper canvas id="random-id-12122" className='h-[780px] max-h-[100%] min-h-[220px] flex justify-center' style={{ flex:'40%' }}>
                <Wrapper canvas id='random-id-122200' is='div' className='w-[100%] h-[100%]' style={{ borderRadius:'0px 200px 0px 200px'}}>
                  <ImageC src='https://i.ibb.co/pJDdsD0/a4df04beaec45f9d7a94e1e85eec4259.jpg' style={{borderRadius:'0px 200px 0px 200px'}} className='w-[100%] h-[100%] object-cover' />

                </Wrapper>
                </Wrapper> 
            </Wrapper>
          </Wrapper>
        </Wrapper>
            </Element>
          </Element>,
      
    ];    
  

    const [sel, setSel] = useState(selected)

    function makeOdd(number) {
      setProp((prop) => {
        setSel(100);
      }, 1000);

      setTimeout(() => {
        setProp((prop) => {
          setSel(number)
        }, 1000);
      }, 100);


      
  }

  useEffect(() => {
    if(selected || selected >= 0) {
      makeOdd(selected);
    }
  },[selected])

  return (
    <div style={{ padding: enabled? "10px" : "0px", display:"flex", justifyContent:"center", alignItems:"center", width:'100%', height:'100%'}} ref={connect}>
         {sel == 100 ? (
          <div style={{height:"85vh", display:"flex", justifyContent:"center", alignItems:"center"}}>
          <BounceLoader
                  size={100}
                  color={"white"}
                  loading={true}
                />
          </div>
         ) : (
          styles[sel]
         )} 
    </div>
  );
};

Banner_2.craft = {
    displayName: "Banner 2",
    props: {
       selected: 1, // here is the default value of the selected style
       length: 10,
       isBlock: true
      },
      rules: {
        canDrag: () => true,
        canDrop: () => true,
      },
      related: {
        toolbar: NewBannerSettings,
      },
  };