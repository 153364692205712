/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import { Element, useEditor, useNode } from '@craftjs/core';
import React, { useEffect, useRef, useState } from 'react';
import { NewSectionsSettings } from './newSectionsSetting'
import { Text } from '../../basic/Text';
import { ImageC } from '../../basic/Image';
import { Parent } from '../../Parent/index.js';
import { Wrapper } from '../../wrapper/index.js';
import { BounceLoader } from 'react-spinners';
import { SvgIcon } from '../../basic/Svg';


export const Sections_1 = ({ selected }) => {
  const {
    actions: { setProp },
    connectors: {
      connect
    }
  } = useNode();


  const {
    enabled,
  } = useEditor((state, query) => ({
    enabled: state.options.enabled,

  }));
  const styles = [

    <Element canvas is={Parent} id='parentId100' className="w-[100%] h-auto  bg-[#white] py-20" >
      <Element canvas is={Parent} id='parentId200' className="w-[100%] h-auto justify-center items-center flex" row={true} >
        <Element is={Parent} canvas id='random-id-401' className='max-w-[1300px]'>
          <Element is={Parent} canvas id='random-id-402' className='w-full h-full flex flex-col flex-wrap'>
            <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: '700', fontFamily: 'Rubik' }} className='text-[#100103] mx-auto text-center' id='random-id-55'  >
              <Text text='What makes our clients happy.' />
            </Element>
            <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: '400', fontFamily: 'Rubik', color: '#000000' }} className='my-10 mx-auto text-center max-w-[500px] flex flex-wrap' id='random-id-56'  >
              <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
            </Element>
            <Element is={Parent} canvas id='random-id-403' className='w-full h-full flex flex-row flex-wrap justify-center'>
              <Element is={Parent} canvas id='random-id-404' className=' min-h-[117px] max-h-[100%] min-w-[277px] flex flex-row  m-2' style={{ flex: '30%' }}>
                <Element is={Parent} canvas id='random-id-405' className='h-full my-[7px] items-center flex' style={{ border: '1px solid #B20312', borderRadius: '6px', background: '#B20312', width: 'auto', height: 'fit-content', padding: '6px' }}>
                  <SvgIcon width='50px' height='50px' fill='#FFFFFF' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                </Element>
                <Element is={Parent} canvas id='random-id-406' className='flex flex-col px-8 max-w-[80%]'>
                  <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: '700', fontFamily: 'Rubik', textAlign: 'left', color: '#000000' }} id='random-id-57'  >
                    <Text text='Dolor sit' />
                  </Element>

                  <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Rubik', textAlign: 'left', color: '#000000' }} className='my-[7px]' id='random-id-58'  >
                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.' />
                  </Element>
                </Element>
              </Element>
              <Element is={Parent} canvas id='random-id-407' className=' min-h-[117px] max-h-[100%] min-w-[277px] flex flex-row  m-2' style={{ flex: '30%' }}>
                <Element is={Parent} canvas id='random-id-408' className='h-full my-[7px] items-center flex' style={{ border: '1px solid #B20312', borderRadius: '6px', background: '#B20312', width: 'auto', height: 'fit-content', padding: '6px' }}>
                  <SvgIcon width='50px' height='50px' fill='#FFFFFF' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                </Element>
                <Element is={Parent} canvas id='random-id-409' className='flex flex-col px-8 max-w-[80%]'>
                  <Element is={Parent} canvas id='random-id-410' style={{ fontSize: '22px', fontWeight: '700', fontFamily: 'Rubik', textAlign: 'left', color: '#000000' }}>
                    <Text text='Dolor sit' />
                  </Element>
                  <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Rubik', textAlign: 'left', color: '#000000' }} className='my-[7px]' id='random-id-59'  >
                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.' />
                  </Element>
                </Element>
              </Element>
              <Element is={Parent} canvas id='random-id-411' className=' min-h-[117px] max-h-[100%] min-w-[277px] flex flex-row  m-2' style={{ flex: '30%' }}>
                <Element is={Parent} canvas id='random-id-412' className='h-full my-[7px] items-center flex' style={{ border: '1px solid #B20312', borderRadius: '6px', background: '#B20312', width: 'auto', height: 'fit-content', padding: '6px' }}>
                  <SvgIcon width='50px' height='50px' fill='#FFFFFF' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                </Element>
                <Element is={Parent} canvas id='random-id-413' className='flex flex-col px-8 max-w-[80%]'>
                  <Element is={Parent} canvas id='random-id-414' style={{ fontSize: '22px', fontWeight: '700', fontFamily: 'Rubik', textAlign: 'left', color: '#000000' }}>
                    <Text text='Dolor sit' />
                  </Element>

                  <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Rubik', textAlign: 'left', color: '#000000' }} className='my-[7px]' id='random-id-60'  >
                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.' />
                  </Element>

                </Element>
              </Element>
              <Element is={Parent} canvas id='random-id-415' className=' min-h-[117px] max-h-[100%] min-w-[277px] flex flex-row  m-2' style={{ flex: '30%' }}>
                <Element is={Parent} canvas id='random-id-416' className='h-full my-[7px] items-center flex' style={{ border: '1px solid #B20312', borderRadius: '6px', background: '#B20312', width: 'auto', height: 'fit-content', padding: '6px' }}>
                  <SvgIcon width='50px' height='50px' fill='#FFFFFF' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                </Element>
                <Element is={Parent} canvas id='random-id-417' className='flex flex-col px-8 max-w-[80%]'>
                  <Element is={Parent} canvas id='random-id-418' style={{ fontSize: '22px', fontWeight: '700', fontFamily: 'Rubik', textAlign: 'left', color: '#000000' }}>
                    <Text text='Dolor sit' />
                  </Element>

                  <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Rubik', textAlign: 'left', color: '#000000' }} className='my-[7px]' id='random-id-61'  >
                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.' />
                  </Element>
                </Element>

              </Element>
              <Element is={Parent} canvas id='random-id-419' className=' min-h-[117px] max-h-[100%] min-w-[277px] flex flex-row  m-2' style={{ flex: '30%' }}>
                <Element is={Parent} canvas id='random-id-420' className='h-full my-[7px] items-center flex' style={{ border: '1px solid #B20312', borderRadius: '6px', background: '#B20312', width: 'auto', height: 'fit-content', padding: '6px' }}>
                  <SvgIcon width='50px' height='50px' fill='#FFFFFF' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                </Element>
                <Element is={Parent} canvas id='random-id-421' className='flex flex-col px-8 max-w-[80%]'>
                  <Element is={Parent} canvas id='random-id-422' style={{ fontSize: '22px', fontWeight: '700', fontFamily: 'Rubik', textAlign: 'left', color: '#000000' }}>
                    <Text text='Dolor sit' />
                  </Element>

                  <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Rubik', textAlign: 'left', color: '#000000' }} className='my-[7px]' id='random-id-62'  >
                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.' />
                  </Element>

                </Element>
              </Element>
              <Element is={Parent} canvas id='random-id-423' className=' min-h-[117px] max-h-[100%] min-w-[277px] flex flex-row  m-2' style={{ flex: '30%' }}>
                <Element is={Parent} canvas id='random-id-424' className='h-full my-[7px] items-center flex' style={{ border: '1px solid #B20312', borderRadius: '6px', background: '#B20312', width: 'auto', height: 'fit-content', padding: '6px' }}>
                  <SvgIcon width='50px' height='50px' fill='#FFFFFF' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                </Element>
                <Element is={Parent} canvas id='random-id-425' className='flex flex-col px-8 max-w-[80%]'>
                  <Element is={Parent} canvas id='random-id-426' style={{ fontSize: '22px', fontWeight: '700', fontFamily: 'Rubik', textAlign: 'left', color: '#000000' }}>
                    <Text text='Dolor sit' />
                  </Element>
                  <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Rubik', textAlign: 'left', color: '#000000' }} className='my-[7px]' id='random-id-63'  >
                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.' />
                  </Element>
                </Element>
              </Element>
            </Element>
          </Element>

        </Element></Element>
    </Element>,

    <Element canvas is={Parent} id='parentId300' className="w-[100%] h-auto  bg-[#121826] py-20" >
      <Element canvas is={Parent} id='parentId400' className="w-[100%] h-auto justify-center items-center flex" row={true} >
        <Element is={Parent} canvas id='random-id-428' className='max-w-[1300px]'>
          <Element is={Parent} canvas id='random-id-429' className='w-full h-full flex flex-col flex-wrap'>
            <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: '700', fontFamily: 'Rubik' }} className='IncreaseConversion2 mx-auto text-center' id='random-id-1'  >
              <Text text='What makes our clients happy.' />
            </Element>
            <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: '400', fontFamily: 'Rubik', color: '#FFF' }} className='my-10 mx-auto text-center max-w-[500px] flex flex-wrap' id='random-id-2'  >
              <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
            </Element>
            <Element is={Parent} canvas id='random-id-430' className='w-full h-full flex flex-row flex-wrap justify-center'>
              <Element is={Parent} canvas id='random-id-431' className='min-h-[117px] max-h-[100%]  min-w-[277px] flex flex-row  m-2 mx-auto' style={{ flex: '30%' }}>
                <Element is={Parent} canvas id='random-id-432' className='h-full my-[7px] items-center flex' style={{ border: '1px solid #4BDBA2', borderRadius: '6px', background: '#4BDBA2', width: 'auto', height: 'fit-content', padding: '6px' }}>
                  <SvgIcon width='50px' height='50px' fill='#121826' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                </Element>
                <Element is={Parent} canvas id='random-id-433' className='flex flex-col px-8 max-w-[80%]'>
                  <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: '700', fontFamily: 'Rubik', textAlign: 'left', color: '#FFF' }} id='random-id-3'  >
                    <Text text='Dolor sit' />
                  </Element>

                  <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Rubik', textAlign: 'left', color: '#FFF' }} className='my-[7px]' id='random-id-4'  >
                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.' />
                  </Element>
                </Element>
              </Element>
              <Element is={Parent} canvas id='random-id-434' className='min-h-[117px] max-h-[100%]  min-w-[277px] flex flex-row  m-2 mx-auto' style={{ flex: '30%' }}>
                <Element is={Parent} canvas id='random-id-435' className='h-full my-[7px] items-center flex' style={{ border: '1px solid #4BDBA2', borderRadius: '6px', background: '#4BDBA2', width: 'auto', height: 'fit-content', padding: '6px' }}>
                  <SvgIcon width='50px' height='50px' fill='#121826' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                </Element>
                <Element is={Parent} canvas id='random-id-436' className='flex flex-col px-8 max-w-[80%]'>
                  <Element is={Parent} canvas id='random-id-437' style={{ fontSize: '22px', fontWeight: '700', fontFamily: 'Rubik', textAlign: 'left', color: '#FFF' }}>
                    <Text text='Dolor sit' />
                  </Element>
                  <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Rubik', textAlign: 'left', color: '#FFF' }} className='my-[7px]' id='random-id-5'  >
                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.' />
                  </Element>
                </Element>
              </Element>
              <Element is={Parent} canvas id='random-id-438' className='min-h-[117px] max-h-[100%]  min-w-[277px] flex flex-row  m-2 mx-auto' style={{ flex: '30%' }}>
                <Element is={Parent} canvas id='random-id-439' className='h-full my-[7px] items-center flex' style={{ border: '1px solid #4BDBA2', borderRadius: '6px', background: '#4BDBA2', width: 'auto', height: 'fit-content', padding: '6px' }}>
                  <SvgIcon width='50px' height='50px' fill='#121826' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                </Element>
                <Element is={Parent} canvas id='random-id-440' className='flex flex-col px-8 max-w-[80%]'>
                  <Element is={Parent} canvas id='random-id-441' style={{ fontSize: '22px', fontWeight: '700', fontFamily: 'Rubik', textAlign: 'left', color: '#FFF' }}>
                    <Text text='Dolor sit' />
                  </Element>

                  <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Rubik', textAlign: 'left', color: '#FFF' }} className='my-[7px]' id='random-id-6'  >
                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.' />
                  </Element>
                </Element>

              </Element>
              <Element is={Parent} canvas id='random-id-442' className='min-h-[117px] max-h-[100%]  min-w-[277px] flex flex-row  m-2 mx-auto' style={{ flex: '30%' }}>
                <Element is={Parent} canvas id='random-id-443' className='h-full my-[7px] items-center flex' style={{ border: '1px solid #4BDBA2', borderRadius: '6px', background: '#4BDBA2', width: 'auto', height: 'fit-content', padding: '6px' }}>
                  <SvgIcon width='50px' height='50px' fill='#121826' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                </Element>
                <Element is={Parent} canvas id='random-id-444' className='flex flex-col px-8 max-w-[80%]'>
                  <Element is={Parent} canvas id='random-id-445' style={{ fontSize: '22px', fontWeight: '700', fontFamily: 'Rubik', textAlign: 'left', color: '#FFF' }}>
                    <Text text='Dolor sit' />
                  </Element>

                  <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Rubik', textAlign: 'left', color: '#FFF' }} className='my-[7px]' id='random-id-7'  >
                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.' />
                  </Element>
                </Element>

              </Element>
              <Element is={Parent} canvas id='random-id-446' className='min-h-[117px] max-h-[100%]  min-w-[277px] flex flex-row  m-2 mx-auto' style={{ flex: '30%' }}>
                <Element is={Parent} canvas id='random-id-447' className='h-full my-[7px] items-center flex' style={{ border: '1px solid #4BDBA2', borderRadius: '6px', background: '#4BDBA2', width: 'auto', height: 'fit-content', padding: '6px' }}>
                  <SvgIcon width='50px' height='50px' fill='#121826' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                </Element>
                <Element is={Parent} canvas id='random-id-448' className='flex flex-col px-8 max-w-[80%]'>
                  <Element is={Parent} canvas id='random-id-449' style={{ fontSize: '22px', fontWeight: '700', fontFamily: 'Rubik', textAlign: 'left', color: '#FFF' }}>
                    <Text text='Dolor sit' />
                  </Element>

                  <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Rubik', textAlign: 'left', color: '#FFF' }} className='my-[7px]' id='random-id-8'  >
                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.' />
                  </Element>
                </Element>

              </Element>
              <Element is={Parent} canvas id='random-id-450' className='min-h-[117px] max-h-[100%]  min-w-[277px] flex flex-row  m-2 mx-auto' style={{ flex: '30%' }}>
                <Element is={Parent} canvas id='random-id-451' className='h-full my-[7px] items-center flex' style={{ border: '1px solid #4BDBA2', borderRadius: '6px', background: '#4BDBA2', width: 'auto', height: 'fit-content', padding: '6px' }}>
                  <SvgIcon width='50px' height='50px' fill='#121826' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                </Element>
                <Element is={Parent} canvas id='random-id-452' className='flex flex-col px-8 max-w-[80%]'>
                  <Element is={Parent} canvas id='random-id-453' style={{ fontSize: '22px', fontWeight: '700', fontFamily: 'Rubik', textAlign: 'left', color: '#FFF' }}>
                    <Text text='Dolor sit' />
                  </Element>

                  <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Rubik', textAlign: 'left', color: '#FFF' }} className='my-[7px]' id='random-id-9'  >
                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.' />
                  </Element>
                </Element>

              </Element>
            </Element>
          </Element>

        </Element></Element>
    </Element>,

    <Element canvas is={Parent} id='parentId500' className="w-[100%] h-auto  bg-[#213156] py-20" >
      <Element canvas is={Parent} id='parentId600' className="w-[100%] h-auto justify-center items-center flex" row={true} >
        <Element is={Parent} canvas id='random-id-455' className='max-w-[1300px]'>
          <Element is={Parent} canvas id='random-id-456' className='w-full h-full flex flex-col flex-wrap'>
            <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: '700', fontFamily: 'Times New Roman' }} className='text-[#EA6EF4] mx-auto text-center' id='random-id-10'  >
              <Text text='What makes our clients happy.' />
            </Element>
            <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: '400', fontFamily: 'Open Sans', color: '#FFFFFF' }} className='my-6 mx-auto text-center max-w-[500px] flex flex-wrap' id='random-id-11'  >
              <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
            </Element>
            <Element is={Parent} canvas id='random-id-458' className='w-full h-full flex flex-row flex-wrap justify-center'>
              <Element is={Parent} canvas id='random-id-459' className='min-h-[117px] max-h-[100%]  min-w-[277px] flex flex-row  m-2 mx-auto' style={{ flex: '30%' }}>
                <Element is={Parent} canvas id='random-id-460' className='h-full my-[7px] items-center flex' style={{ border: '1px solid #EA6EF4', borderRadius: '8px', background: '#EA6EF4', width: 'auto', height: 'fit-content', padding: '6px' }}>
                  <SvgIcon width='50px' height='50px' fill='#213156' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                </Element>
                <Element is={Parent} canvas id='random-id-461' className='flex flex-col px-8 max-w-[80%]'>
                  <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: '700', fontFamily: 'Open Sans', textAlign: 'left', color: '#EA6EF4' }} id='random-id-12'  >
                    <Text text='Dolor sit' />
                  </Element>


                  <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Open Sans', textAlign: 'left', color: '#FFFFFF' }} className='my-[7px]' id='random-id-13'  >
                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.' />
                  </Element>

                </Element>
              </Element>
              <Element is={Parent} canvas id='random-id-462' className='min-h-[117px] max-h-[100%]  min-w-[277px] flex flex-row  m-2 mx-auto' style={{ flex: '30%' }}>
                <Element is={Parent} canvas id='random-id-463' className='h-full my-[7px] items-center flex' style={{ border: '1px solid #EA6EF4', borderRadius: '8px', background: '#EA6EF4', width: 'auto', height: 'fit-content', padding: '6px' }}>
                  <SvgIcon width='50px' height='50px' fill='#213156' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                </Element>
                <Element is={Parent} canvas id='random-id-464' className='flex flex-col px-8 max-w-[80%]'>
                  <Element is={Parent} canvas id='random-id-465' style={{ fontSize: '22px', fontWeight: '700', fontFamily: 'Open Sans', textAlign: 'left', color: '#EA6EF4' }}>
                    <Text text='Dolor sit' />
                  </Element>

                  <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Open Sans', textAlign: 'left', color: '#FFFFFF' }} className='my-[7px]' id='random-id-14'  >
                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.' />
                  </Element>

                </Element>
              </Element>
              <Element is={Parent} canvas id='random-id-466' className='min-h-[117px] max-h-[100%]  min-w-[277px] flex flex-row  m-2 mx-auto' style={{ flex: '30%' }}>
                <Element is={Parent} canvas id='random-id-467' className='h-full my-[7px] items-center flex' style={{ border: '1px solid #EA6EF4', borderRadius: '8px', background: '#EA6EF4', width: 'auto', height: 'fit-content', padding: '6px' }}>
                  <SvgIcon width='50px' height='50px' fill='#213156' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                </Element>
                <Element is={Parent} canvas id='random-id-468' className='flex flex-col px-8 max-w-[80%]'>
                  <Element is={Parent} canvas id='random-id-469' style={{ fontSize: '22px', fontWeight: '700', fontFamily: 'Open Sans', textAlign: 'left', color: '#EA6EF4' }}>
                    <Text text='Dolor sit' />
                  </Element>

                  <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Open Sans', textAlign: 'left', color: '#FFFFFF' }} className='my-[7px]' id='random-id-15'  >
                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.' />
                  </Element>

                </Element>
              </Element>
              <Element is={Parent} canvas id='random-id-470' className='min-h-[117px] max-h-[100%]  min-w-[277px] flex flex-row  m-2 mx-auto' style={{ flex: '30%' }}>
                <Element is={Parent} canvas id='random-id-471' className='h-full my-[7px] items-center flex' style={{ border: '1px solid #EA6EF4', borderRadius: '8px', background: '#EA6EF4', width: 'auto', height: 'fit-content', padding: '6px' }}>
                  <SvgIcon width='50px' height='50px' fill='#213156' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                </Element>
                <Element is={Parent} canvas id='random-id-472' className='flex flex-col px-8 max-w-[80%]'>
                  <Element is={Parent} canvas id='random-id-473' style={{ fontSize: '22px', fontWeight: '700', fontFamily: 'Open Sans', textAlign: 'left', color: '#EA6EF4' }}>
                    <Text text='Dolor sit' />
                  </Element>

                  <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Open Sans', textAlign: 'left', color: '#FFFFFF' }} className='my-[7px]' id='random-id-16'  >
                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.' />
                  </Element>

                </Element>
              </Element>
              <Element is={Parent} canvas id='random-id-474' className='min-h-[117px] max-h-[100%]  min-w-[277px] flex flex-row  m-2 mx-auto' style={{ flex: '30%' }}>
                <Element is={Parent} canvas id='random-id-475' className='h-full my-[7px] items-center flex' style={{ border: '1px solid #EA6EF4', borderRadius: '8px', background: '#EA6EF4', width: 'auto', height: 'fit-content', padding: '6px' }}>
                  <SvgIcon width='50px' height='50px' fill='#213156' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                </Element>
                <Element is={Parent} canvas id='random-id-476' className='flex flex-col px-8 max-w-[80%]'>
                  <Element is={Parent} canvas id='random-id-477' style={{ fontSize: '22px', fontWeight: '700', fontFamily: 'Open Sans', textAlign: 'left', color: '#EA6EF4' }}>
                    <Text text='Dolor sit' />
                  </Element>

                  <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Open Sans', textAlign: 'left', color: '#FFFFFF' }} className='my-[7px]' id='random-id-17'  >
                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.' />
                  </Element>

                </Element>
              </Element>
              <Element is={Parent} canvas id='random-id-478' className='min-h-[117px] max-h-[100%]  min-w-[277px] flex flex-row  m-2 mx-auto' style={{ flex: '30%' }}>
                <Element is={Parent} canvas id='random-id-479' className='h-full my-[7px] items-center flex' style={{ border: '1px solid #EA6EF4', borderRadius: '8px', background: '#EA6EF4', width: 'auto', height: 'fit-content', padding: '6px' }}>
                  <SvgIcon width='50px' height='50px' fill='#213156' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                </Element>
                <Element is={Parent} canvas id='random-id-480' className='flex flex-col px-8 max-w-[80%]'>
                  <Element is={Parent} canvas id='random-id-481' style={{ fontSize: '22px', fontWeight: '700', fontFamily: 'Open Sans', textAlign: 'left', color: '#EA6EF4' }}>
                    <Text text='Dolor sit' />
                  </Element>

                  <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Open Sans', textAlign: 'left', color: '#FFFFFF' }} className='my-[7px]' id='random-id-18'  >
                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.' />
                  </Element>

                </Element>
              </Element>
            </Element>
          </Element>

        </Element></Element>
    </Element>,


    <Element canvas is={Parent} id='parentId700' className="w-[100%] h-auto  bg-[#DFFFF9] py-20" >
      <Element canvas is={Parent} id='parentId800' className="w-[100%] h-auto justify-center items-center flex" row={true} >
        <Element is={Parent} canvas id='random-id-483' className='max-w-[1300px]'>
          <Element is={Parent} canvas id='random-id-484' className='w-full h-full flex flex-col flex-wrap'>
            <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: '100px', fontFamily: 'Zing Script Rust Semi700 Demo', fontStyle: 'italic' }} className='text-[#31A993] mx-auto text-center' id='random-id-19'  >
              <Text text='What makes our clients happy.' />
            </Element>
            <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: '400', fontFamily: 'Montserrat', color: '#031815' }} className='my-6 mx-auto text-center max-w-[500px] flex flex-wrap' id='random-id-20'  >
              <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
            </Element>
            <Element is={Parent} canvas id='random-id-484' className='w-full h-full flex flex-row flex-wrap justify-center'>
              <Element is={Parent} canvas id='random-id-485' className='min-h-[117px] max-h-[100%]  min-w-[277px] flex flex-row  m-2 mx-auto' style={{ flex: '30%' }}>
                <Element is={Parent} canvas id='random-id-486' className='h-full my-[7px] px-[8px] py-[8px]' style={{ borderRadius: '30px', background: '#31A993', width: 'auto', height: 'fit-content', padding: '6px' }}>
                  <SvgIcon width='50px' height='50px' fill='#DFFFF9' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                </Element>
                <Element is={Parent} canvas id='random-id-487' className='flex flex-col px-8 max-w-[80%]'>
                  <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: '700', fontFamily: 'Montserrat', textAlign: 'left', color: '#31A993' }} id='random-id-21'  >
                    <Text text='Dolor sit' />
                  </Element>


                  <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Montserrat', textAlign: 'left', color: '#031815' }} className='my-[7px]' id='random-id-22'  >
                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.' />
                  </Element>

                </Element>
              </Element>
              <Element is={Parent} canvas id='random-id-488' className='min-h-[117px] max-h-[100%]  min-w-[277px] flex flex-row  m-2 mx-auto' style={{ flex: '30%' }}>
                <Element is={Parent} canvas id='random-id-489' className='h-full my-[7px] px-[8px] py-[8px]' style={{ borderRadius: '30px', background: '#31A993', width: 'auto', height: 'fit-content', padding: '6px' }}>
                  <SvgIcon width='50px' height='50px' fill='#DFFFF9' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                </Element>
                <Element is={Parent} canvas id='random-id-490' className='flex flex-col px-8 max-w-[80%]'>
                  <Element is={Parent} canvas id='random-id-491' style={{ fontSize: '22px', fontWeight: '700', fontFamily: 'Montserrat', textAlign: 'left', color: '#31A993' }}>
                    <Text text='Dolor sit' />
                  </Element>

                  <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Montserrat', textAlign: 'left', color: '#031815' }} className='my-[7px]' id='random-id-23'  >
                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.' />
                  </Element>

                </Element>
              </Element>
              <Element is={Parent} canvas id='random-id-492' className='min-h-[117px] max-h-[100%]  min-w-[277px] flex flex-row  m-2 mx-auto' style={{ flex: '30%' }}>
                <Element is={Parent} canvas id='random-id-493' className='h-full my-[7px] px-[8px] py-[8px]' style={{ borderRadius: '30px', background: '#31A993', width: 'auto', height: 'fit-content', padding: '6px' }}>
                  <SvgIcon width='50px' height='50px' fill='#DFFFF9' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                </Element>
                <Element is={Parent} canvas id='random-id-494' className='flex flex-col px-8 max-w-[80%]'>
                  <Element is={Parent} canvas id='random-id-495' style={{ fontSize: '22px', fontWeight: '700', fontFamily: 'Montserrat', textAlign: 'left', color: '#31A993' }}>
                    <Text text='Dolor sit' />
                  </Element>

                  <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Montserrat', textAlign: 'left', color: '#031815' }} className='my-[7px]' id='random-id-24'  >
                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.' />
                  </Element>

                </Element>
              </Element>
              <Element is={Parent} canvas id='random-id-496' className='min-h-[117px] max-h-[100%]  min-w-[277px] flex flex-row  m-2 mx-auto' style={{ flex: '30%' }}>
                <Element is={Parent} canvas id='random-id-497' className='h-full my-[7px] px-[8px] py-[8px]' style={{ borderRadius: '30px', background: '#31A993', width: 'auto', height: 'fit-content', padding: '6px' }}>
                  <SvgIcon width='50px' height='50px' fill='#DFFFF9' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                </Element>
                <Element is={Parent} canvas id='random-id-498' className='flex flex-col px-8 max-w-[80%]'>
                  <Element is={Parent} canvas id='random-id-499' style={{ fontSize: '22px', fontWeight: '700', fontFamily: 'Montserrat', textAlign: 'left', color: '#31A993' }}>
                    <Text text='Dolor sit' />
                  </Element>

                  <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Montserrat', textAlign: 'left', color: '#031815' }} className='my-[7px]' id='random-id-25'  >
                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.' />
                  </Element>

                </Element>
              </Element>
              <Element is={Parent} canvas id='random-id-500' className='min-h-[117px] max-h-[100%]  min-w-[277px] flex flex-row  m-2 mx-auto' style={{ flex: '30%' }}>
                <Element is={Parent} canvas id='random-id-501' className='h-full my-[7px] px-[8px] py-[8px]' style={{ borderRadius: '30px', background: '#31A993', width: 'auto', height: 'fit-content', padding: '6px' }}>
                  <SvgIcon width='50px' height='50px' fill='#DFFFF9' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                </Element>
                <Element is={Parent} canvas id='random-id-502' className='flex flex-col px-8 max-w-[80%]'>
                  <Element is={Parent} canvas id='random-id-503' style={{ fontSize: '22px', fontWeight: '700', fontFamily: 'Montserrat', textAlign: 'left', color: '#31A993' }}>
                    <Text text='Dolor sit' />
                  </Element>

                  <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Montserrat', textAlign: 'left', color: '#031815' }} className='my-[7px]' id='random-id-26'  >
                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.' />
                  </Element>

                </Element>
              </Element>
              <Element is={Parent} canvas id='random-id-504' className='min-h-[117px] max-h-[100%]  min-w-[277px] flex flex-row  m-2 mx-auto' style={{ flex: '30%' }}>
                <Element is={Parent} canvas id='random-id-505' className='h-full my-[7px] px-[8px] py-[8px]' style={{ borderRadius: '30px', background: '#31A993', width: 'auto', height: 'fit-content', padding: '6px' }}>
                  <SvgIcon width='50px' height='50px' fill='#DFFFF9' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                </Element>
                <Element is={Parent} canvas id='random-id-506' className='flex flex-col px-8 max-w-[80%]'>
                  <Element is={Parent} canvas id='random-id-507' style={{ fontSize: '22px', fontWeight: '700', fontFamily: 'Montserrat', textAlign: 'left', color: '#31A993' }}>
                    <Text text='Dolor sit' />
                  </Element>

                  <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Montserrat', textAlign: 'left', color: '#031815' }} className='my-[7px]' id='random-id-27'  >
                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.' />
                  </Element>

                </Element>
              </Element>
            </Element>
          </Element>

        </Element></Element>
    </Element>,

    <Element canvas is={Parent} id='parentId900' className="w-[100%] h-auto  bg-[#white] py-20" >
      <Element canvas is={Parent} id='parentId1000' className="w-[100%] h-auto justify-center items-center flex" row={true} >
        <Element is={Parent} canvas id='random-id-509' className='max-w-[1300px]'>
          <Element is={Parent} canvas id='random-id-510' className='w-full h-full flex flex-col flex-wrap'>
            <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: '700', fontFamily: 'Inter' }} className='text-[#0078D3] mx-auto text-center' id='random-id-28'  >
              <Text text='What makes our clients happy.' />
            </Element>
            <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: '400', fontFamily: 'Inter', color: '#000000' }} className='my-6 mx-auto text-center max-w-[500px] flex flex-wrap' id='random-id-29'  >
              <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
            </Element>
            <Element is={Parent} canvas id='random-id-511' className='w-full h-full flex flex-row flex-wrap justify-center'>
              <Element is={Parent} canvas id='random-id-512' className='min-h-[117px] max-h-[100%]  min-w-[277px] flex flex-row  m-2 mx-auto' style={{ flex: '30%' }}>
                <Element is={Parent} canvas id='random-id-513' className='h-full my-[7px] items-center flex' style={{ border: '1px solid #0078D3', borderRadius: '6px', background: '#0078D3', width: 'auto', height: 'fit-content', padding: '6px' }}>
                  <SvgIcon width='50px' height='50px' fill='#FFFFFF' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                </Element>
                <Element is={Parent} canvas id='random-id-514' className='flex flex-col px-8 max-w-[80%]'>
                  <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: '700', fontFamily: 'Inter', textAlign: 'left', color: '#000000' }} id='random-id-30'  >
                    <Text text='Dolor sit' />
                  </Element>


                  <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Inter', textAlign: 'left', color: '#000000' }} className='my-[7px]' id='random-id-31'  >
                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.' />
                  </Element>

                </Element>
              </Element>
              <Element is={Parent} canvas id='random-id-515' className='min-h-[117px] max-h-[100%]  min-w-[277px] flex flex-row  m-2 mx-auto' style={{ flex: '30%' }}>
                <Element is={Parent} canvas id='random-id-516' className='h-full my-[7px] items-center flex' style={{ border: '1px solid #0078D3', borderRadius: '6px', background: '#0078D3', width: 'auto', height: 'fit-content', padding: '6px' }}>
                  <SvgIcon width='50px' height='50px' fill='#FFFFFF' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                </Element>
                <Element is={Parent} canvas id='random-id-517' className='flex flex-col px-8 max-w-[80%]'>
                  <Element is={Parent} canvas id='random-id-518' style={{ fontSize: '22px', fontWeight: '700', fontFamily: 'Inter', textAlign: 'left', color: '#000000' }}>
                    <Text text='Dolor sit' />
                  </Element>

                  <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Inter', textAlign: 'left', color: '#000000' }} className='my-[7px]' id='random-id-32'  >
                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.' />
                  </Element>

                </Element>
              </Element>
              <Element is={Parent} canvas id='random-id-519' className='min-h-[117px] max-h-[100%]  min-w-[277px] flex flex-row  m-2 mx-auto' style={{ flex: '30%' }}>
                <Element is={Parent} canvas id='random-id-520' className='h-full my-[7px] items-center flex' style={{ border: '1px solid #0078D3', borderRadius: '6px', background: '#0078D3', width: 'auto', height: 'fit-content', padding: '6px' }}>
                  <SvgIcon width='50px' height='50px' fill='#FFFFFF' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                </Element>
                <Element is={Parent} canvas id='random-id-521' className='flex flex-col px-8 max-w-[80%]'>
                  <Element is={Parent} canvas id='random-id-522' style={{ fontSize: '22px', fontWeight: '700', fontFamily: 'Inter', textAlign: 'left', color: '#000000' }}>
                    <Text text='Dolor sit' />
                  </Element>

                  <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Inter', textAlign: 'left', color: '#000000' }} className='my-[7px]' id='random-id-33'  >
                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.' />
                  </Element>

                </Element>
              </Element>
              <Element is={Parent} canvas id='random-id-523' className='min-h-[117px] max-h-[100%]  min-w-[277px] flex flex-row  m-2 mx-auto' style={{ flex: '30%' }}>
                <Element is={Parent} canvas id='random-id-524' className='h-full my-[7px] items-center flex' style={{ border: '1px solid #0078D3', borderRadius: '6px', background: '#0078D3', width: 'auto', height: 'fit-content', padding: '6px' }}>
                  <SvgIcon width='50px' height='50px' fill='#FFFFFF' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                </Element>
                <Element is={Parent} canvas id='random-id-525' className='flex flex-col px-8 max-w-[80%]'>
                  <Element is={Parent} canvas id='random-id-526' style={{ fontSize: '22px', fontWeight: '700', fontFamily: 'Inter', textAlign: 'left', color: '#000000' }}>
                    <Text text='Dolor sit' />
                  </Element>

                  <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Inter', textAlign: 'left', color: '#000000' }} className='my-[7px]' id='random-id-34'  >
                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.' />
                  </Element>

                </Element>
              </Element>
              <Element is={Parent} canvas id='random-id-527' className='min-h-[117px] max-h-[100%]  min-w-[277px] flex flex-row  m-2 mx-auto' style={{ flex: '30%' }}>
                <Element is={Parent} canvas id='random-id-528' className='h-full my-[7px] items-center flex' style={{ border: '1px solid #0078D3', borderRadius: '6px', background: '#0078D3', width: 'auto', height: 'fit-content', padding: '6px' }}>
                  <SvgIcon width='50px' height='50px' fill='#FFFFFF' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                </Element>
                <Element is={Parent} canvas id='random-id-529' className='flex flex-col px-8 max-w-[80%]'>
                  <Element is={Parent} canvas id='random-id-530' style={{ fontSize: '22px', fontWeight: '700', fontFamily: 'Inter', textAlign: 'left', color: '#000000' }}>
                    <Text text='Dolor sit' />
                  </Element>

                  <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Inter', textAlign: 'left', color: '#000000' }} className='my-[7px]' id='random-id-35'  >
                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.' />
                  </Element>

                </Element>
              </Element>
              <Element is={Parent} canvas id='random-id-531' className='min-h-[117px] max-h-[100%]  min-w-[277px] flex flex-row  m-2 mx-auto' style={{ flex: '30%' }}>
                <Element is={Parent} canvas id='random-id-532' className='h-full my-[7px] items-center flex' style={{ border: '1px solid #0078D3', borderRadius: '6px', background: '#0078D3', width: 'auto', height: 'fit-content', padding: '6px' }}>
                  <SvgIcon width='50px' height='50px' fill='#FFFFFF' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                </Element>
                <Element is={Parent} canvas id='random-id-533' className='flex flex-col px-8 max-w-[80%]'>
                  <Element is={Parent} canvas id='random-id-534' style={{ fontSize: '22px', fontWeight: '700', fontFamily: 'Inter', textAlign: 'left', color: '#000000' }}>
                    <Text text='Dolor sit' />
                  </Element>

                  <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Inter', textAlign: 'left', color: '#000000' }} className='my-[7px]' id='random-id-36'  >
                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.' />
                  </Element>

                </Element>
              </Element>
            </Element>
          </Element>

        </Element></Element>
    </Element>,


    <Element canvas is={Parent} id='parentId1100' className="w-[100%] h-auto  bg-[#white] py-20" >
      <Element canvas is={Parent} id='parentId1200' className="w-[100%] h-auto justify-center items-center flex" row={true} >
        <Element is={Parent} canvas id='random-id-536' className='max-w-[1300px]'>
          <Element is={Parent} canvas id='random-id-537' className='w-full h-full flex flex-col flex-wrap'>
            <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: '400', fontFamily: 'Voces' }} className='text-[#020212] mx-auto text-center uppercase' id='random-id-37'  >
              <Text text='What makes our clients happy.' />
            </Element>
            <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: '400', fontFamily: 'Voces', color: '#020212' }} className='my-6 mx-auto text-center max-w-[500px] flex flex-wrap' id='random-id-38'  >
              <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
            </Element>
            <Element is={Parent} canvas id='random-id-538' className='w-full h-full flex flex-row flex-wrap justify-center'>
              <Element is={Parent} canvas id='random-id-539' className='min-h-[117px] max-h-[100%]  min-w-[277px] flex flex-row  m-2 mx-auto' style={{ flex: '30%' }}>
                <Element is={Parent} canvas id='random-id-540' className='h-full my-[7px] items-center flex' style={{ border: '1px solid #020212', borderRadius: '6px', background: '#020212', width: 'auto', height: 'fit-content', padding: '6px' }}>
                  <SvgIcon width='50px' height='50px' fill='#FFFFFF' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                </Element>
                <Element is={Parent} canvas id='random-id-541' className='flex flex-col px-8 max-w-[80%]'>
                  <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: '700', fontFamily: 'Voces', textAlign: 'left', color: '#020212' }} id='random-id-39'  >
                    <Text text='Dolor sit' />
                  </Element>


                  <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Voces', textAlign: 'left', color: '#020212' }} className='my-[7px]' id='random-id-40'  >
                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.' />
                  </Element>

                </Element>
              </Element>
              <Element is={Parent} canvas id='random-id-542' className='min-h-[117px] max-h-[100%]  min-w-[277px] flex flex-row  m-2 mx-auto' style={{ flex: '30%' }}>
                <Element is={Parent} canvas id='random-id-543' className='h-full my-[7px] items-center flex' style={{ border: '1px solid #020212', borderRadius: '6px', background: '#020212', width: 'auto', height: 'fit-content', padding: '6px' }}>
                  <SvgIcon width='50px' height='50px' fill='#FFFFFF' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                </Element>
                <Element is={Parent} canvas id='random-id-544' className='flex flex-col px-8 max-w-[80%]'>
                  <Element is={Parent} canvas id='random-id-545' style={{ fontSize: '22px', fontWeight: '700', fontFamily: 'Voces', textAlign: 'left', color: '#020212' }}>
                    <Text text='Dolor sit' />
                  </Element>

                  <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Voces', textAlign: 'left', color: '#020212' }} className='my-[7px]' id='random-id-41'  >
                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.' />
                  </Element>

                </Element>
              </Element>
              <Element is={Parent} canvas id='random-id-546' className='min-h-[117px] max-h-[100%]  min-w-[277px] flex flex-row  m-2 mx-auto' style={{ flex: '30%' }}>
                <Element is={Parent} canvas id='random-id-547' className='h-full my-[7px] items-center flex' style={{ border: '1px solid #020212', borderRadius: '6px', background: '#020212', width: 'auto', height: 'fit-content', padding: '6px' }}>
                  <SvgIcon width='50px' height='50px' fill='#FFFFFF' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                </Element>
                <Element is={Parent} canvas id='random-id-548' className='flex flex-col px-8 max-w-[80%]'>
                  <Element is={Parent} canvas id='random-id-549' style={{ fontSize: '22px', fontWeight: '700', fontFamily: 'Voces', textAlign: 'left', color: '#020212' }}>
                    <Text text='Dolor sit' />
                  </Element>

                  <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Voces', textAlign: 'left', color: '#020212' }} className='my-[7px]' id='random-id-42'  >
                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.' />
                  </Element>

                </Element>
              </Element>
              <Element is={Parent} canvas id='random-id-550' className='min-h-[117px] max-h-[100%]  min-w-[277px] flex flex-row  m-2 mx-auto' style={{ flex: '30%' }}>
                <Element is={Parent} canvas id='random-id-551' className='h-full my-[7px] items-center flex' style={{ border: '1px solid #020212', borderRadius: '6px', background: '#020212', width: 'auto', height: 'fit-content', padding: '6px' }}>
                  <SvgIcon width='50px' height='50px' fill='#FFFFFF' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                </Element>
                <Element is={Parent} canvas id='random-id-552' className='flex flex-col px-8 max-w-[80%]'>
                  <Element is={Parent} canvas id='random-id-553' style={{ fontSize: '22px', fontWeight: '700', fontFamily: 'Voces', textAlign: 'left', color: '#020212' }}>
                    <Text text='Dolor sit' />
                  </Element>

                  <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Voces', textAlign: 'left', color: '#020212' }} className='my-[7px]' id='random-id-43'  >
                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.' />
                  </Element>

                </Element>
              </Element>
              <Element is={Parent} canvas id='random-id-554' className='min-h-[117px] max-h-[100%]  min-w-[277px] flex flex-row  m-2 mx-auto' style={{ flex: '30%' }}>
                <Element is={Parent} canvas id='random-id-555' className='h-full my-[7px] items-center flex' style={{ border: '1px solid #020212', borderRadius: '6px', background: '#020212', width: 'auto', height: 'fit-content', padding: '6px' }}>
                  <SvgIcon width='50px' height='50px' fill='#FFFFFF' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                </Element>
                <Element is={Parent} canvas id='random-id-556' className='flex flex-col px-8 max-w-[80%]'>
                  <Element is={Parent} canvas id='random-id-557' style={{ fontSize: '22px', fontWeight: '700', fontFamily: 'Voces', textAlign: 'left', color: '#020212' }}>
                    <Text text='Dolor sit' />
                  </Element>

                  <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Voces', textAlign: 'left', color: '#020212' }} className='my-[7px]' id='random-id-44'  >
                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.' />
                  </Element>

                </Element>
              </Element>
              <Element is={Parent} canvas id='random-id-558' className='min-h-[117px] max-h-[100%]  min-w-[277px] flex flex-row  m-2 mx-auto' style={{ flex: '30%' }}>
                <Element is={Parent} canvas id='random-id-559' className='h-full my-[7px] items-center flex' style={{ border: '1px solid #020212', borderRadius: '6px', background: '#020212', width: 'auto', height: 'fit-content', padding: '6px' }}>
                  <SvgIcon width='50px' height='50px' fill='#FFFFFF' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                </Element>
                <Element is={Parent} canvas id='random-id-560' className='flex flex-col px-8 max-w-[80%]'>
                  <Element is={Parent} canvas id='random-id-561' style={{ fontSize: '22px', fontWeight: '700', fontFamily: 'Voces', textAlign: 'left', color: '#020212' }}>
                    <Text text='Dolor sit' />
                  </Element>

                  <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Voces', textAlign: 'left', color: '#020212' }} className='my-[7px]' id='random-id-45'  >
                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.' />
                  </Element>

                </Element>
              </Element>
            </Element>
          </Element>

        </Element></Element>
    </Element>,



    <Element canvas is={Parent} id='parentId1300' className="w-[100%] h-auto  bg-[#FBFAFF] py-20" >
      <Element canvas is={Parent} id='parentId1400' className="w-[100%] h-auto justify-center items-center flex" row={true} >
        <Element is={Parent} canvas id='random-id-563' className='max-w-[1300px]'>
          <Element is={Parent} canvas id='random-id-564' className='w-full h-full flex flex-col flex-wrap'>
            <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: '700', fontFamily: 'Roboto' }} className='text-[#6750A4] mx-auto text-center' id='random-id-46'  >
              <Text text='What makes our clients happy.' />
            </Element>
            <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: '400', fontFamily: 'Roboto', color: '#100103' }} className='my-6 mx-auto text-center max-w-[500px] flex flex-wrap' id='random-id-47'  >
              <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
            </Element>
            <Element is={Parent} canvas id='random-id-565' className='w-full h-full flex flex-row flex-wrap justify-center'>
              <Element is={Parent} canvas id='random-id-566' className='min-h-[117px] max-h-[100%]  min-w-[277px] flex flex-row  m-2 mx-auto' style={{ flex: '30%' }}>
                <Element is={Parent} canvas id='random-id-567' className='h-full my-[7px] px-[8px] py-[8px]' style={{ borderRadius: '30px', background: '#6750A4', width: 'auto', height: 'fit-content', padding: '6px' }}>
                  <SvgIcon width='50px' height='50px' fill='#FBFAFF' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                </Element>
                <Element is={Parent} canvas id='random-id-568' className='flex flex-col px-8 max-w-[80%]'>
                  <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: '700', fontFamily: 'Roboto', textAlign: 'left', color: '#6750A4' }} id='random-id-48'  >
                    <Text text='Dolor sit' />
                  </Element>


                  <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Roboto', textAlign: 'left', color: '#100103' }} className='my-[7px]' id='random-id-49'  >
                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.' />
                  </Element>

                </Element>
              </Element>
              <Element is={Parent} canvas id='random-id-569' className='min-h-[117px] max-h-[100%]  min-w-[277px] flex flex-row  m-2 mx-auto' style={{ flex: '30%' }}>
                <Element is={Parent} canvas id='random-id-570' className='h-full my-[7px] px-[8px] py-[8px]' style={{ borderRadius: '30px', background: '#6750A4', width: 'auto', height: 'fit-content', padding: '6px' }}>
                  <SvgIcon width='50px' height='50px' fill='#FBFAFF' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                </Element>
                <Element is={Parent} canvas id='random-id-571' className='flex flex-col px-8 max-w-[80%]'>
                  <Element is={Parent} canvas id='random-id-572' style={{ fontSize: '22px', fontWeight: '700', fontFamily: 'Roboto', textAlign: 'left', color: '#6750A4' }}>
                    <Text text='Dolor sit' />
                  </Element>

                  <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Roboto', textAlign: 'left', color: '#100103' }} className='my-[7px]' id='random-id-50'  >
                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.' />
                  </Element>

                </Element>
              </Element>
              <Element is={Parent} canvas id='random-id-573' className='min-h-[117px] max-h-[100%]  min-w-[277px] flex flex-row  m-2 mx-auto' style={{ flex: '30%' }}>
                <Element is={Parent} canvas id='random-id-574' className='h-full my-[7px] px-[8px] py-[8px]' style={{ borderRadius: '30px', background: '#6750A4', width: 'auto', height: 'fit-content', padding: '6px' }}>
                  <SvgIcon width='50px' height='50px' fill='#FBFAFF' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                </Element>
                <Element is={Parent} canvas id='random-id-575' className='flex flex-col px-8 max-w-[80%]'>
                  <Element is={Parent} canvas id='random-id-576' style={{ fontSize: '22px', fontWeight: '700', fontFamily: 'Roboto', textAlign: 'left', color: '#6750A4' }}>
                    <Text text='Dolor sit' />
                  </Element>

                  <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Roboto', textAlign: 'left', color: '#100103' }} className='my-[7px]' id='random-id-51'  >
                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.' />
                  </Element>

                </Element>
              </Element>
              <Element is={Parent} canvas id='random-id-577' className='min-h-[117px] max-h-[100%]  min-w-[277px] flex flex-row  m-2 mx-auto' style={{ flex: '30%' }}>
                <Element is={Parent} canvas id='random-id-578' className='h-full my-[7px] px-[8px] py-[8px]' style={{ borderRadius: '30px', background: '#6750A4', width: 'auto', height: 'fit-content', padding: '6px' }}>
                  <SvgIcon width='50px' height='50px' fill='#FBFAFF' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                </Element>
                <Element is={Parent} canvas id='random-id-579' className='flex flex-col px-8 max-w-[80%]'>
                  <Element is={Parent} canvas id='random-id-580' style={{ fontSize: '22px', fontWeight: '700', fontFamily: 'Roboto', textAlign: 'left', color: '#6750A4' }}>
                    <Text text='Dolor sit' />
                  </Element>

                  <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Roboto', textAlign: 'left', color: '#100103' }} className='my-[7px]' id='random-id-52'  >
                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.' />
                  </Element>

                </Element>
              </Element>
              <Element is={Parent} canvas id='random-id-581' className='min-h-[117px] max-h-[100%]  min-w-[277px] flex flex-row  m-2 mx-auto' style={{ flex: '30%' }}>
                <Element is={Parent} canvas id='random-id-582' className='h-full my-[7px] px-[8px] py-[8px]' style={{ borderRadius: '30px', background: '#6750A4', width: 'auto', height: 'fit-content', padding: '6px' }}>
                  <SvgIcon width='50px' height='50px' fill='#FBFAFF' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                </Element>
                <Element is={Parent} canvas id='random-id-583' className='flex flex-col px-8 max-w-[80%]'>
                  <Element is={Parent} canvas id='random-id-584' style={{ fontSize: '22px', fontWeight: '700', fontFamily: 'Roboto', textAlign: 'left', color: '#6750A4' }}>
                    <Text text='Dolor sit' />
                  </Element>

                  <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Roboto', textAlign: 'left', color: '#100103' }} className='my-[7px]' id='random-id-53'  >
                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.' />
                  </Element>

                </Element>
              </Element>
              <Element is={Parent} canvas id='random-id-585' className='min-h-[117px] max-h-[100%]  min-w-[277px] flex flex-row  m-2 mx-auto' style={{ flex: '30%' }}>
                <Element is={Parent} canvas id='random-id-586' className='h-full my-[7px] px-[8px] py-[8px]' style={{ borderRadius: '30px', background: '#6750A4', width: 'auto', height: 'fit-content', padding: '6px' }}>
                  <SvgIcon width='50px' height='50px' fill='#FBFAFF' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                </Element>
                <Element is={Parent} canvas id='random-id-587' className='flex flex-col px-8 max-w-[80%]'>
                  <Element is={Parent} canvas id='random-id-588' style={{ fontSize: '22px', fontWeight: '700', fontFamily: 'Roboto', textAlign: 'left', color: '#6750A4' }}>
                    <Text text='Dolor sit' />
                  </Element>

                  <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Roboto', textAlign: 'left', color: '#100103' }} className='my-[7px]' id='random-id-54'  >
                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.' />
                  </Element>

                </Element>
              </Element>
            </Element>
          </Element>

        </Element></Element>
    </Element>,

    <Element canvas is={Parent} id='parentId1500' className="w-[100%] h-auto  bg-[#2A2A2A] py-20" >
      <Element canvas is={Parent} id='parentId1600' className="w-[100%] h-auto justify-center items-center flex" row={true} >
        <Element is={Parent} canvas id='random-id-590' className='max-w-[1300px]'>
          <Element is={Parent} canvas id='random-id-591' className='w-full h-full flex flex-col flex-wrap'>
            <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: '400', fontFamily: 'Rum Raisin' }} className='text-[#FFD600] mx-auto text-center' id='random-id-28'  >
              <Text text='What makes our clients happy.' />
            </Element>
            <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: '400', fontFamily: 'Inter', color: '#FFFFFF' }} className='my-6 mx-auto text-center max-w-[500px] flex flex-wrap' id='random-id-29'  >
              <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
            </Element>
            <Element is={Parent} canvas id='random-id-592' className='w-full h-full flex flex-row flex-wrap justify-center'>
              <Element is={Parent} canvas id='random-id-593' className='min-h-[117px] max-h-[100%]  min-w-[277px] flex flex-row  m-2 mx-auto' style={{ flex: '30%' }}>
                <Element is={Parent} canvas id='random-id-594' className='h-full my-[7px] items-center flex' style={{ borderRadius: '6px', background: '#FFD600', width: 'auto', height: 'fit-content', padding: '6px' }}>
                  <SvgIcon width='50px' height='50px' fill='#2A2A2A' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                </Element>
                <Element is={Parent} canvas id='random-id-595' className='flex flex-col px-8 max-w-[80%]'>
                  <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: '700', fontFamily: 'Inter', textAlign: 'left', color: '#FFFFFF' }} id='random-id-30'  >
                    <Text text='Dolor sit' />
                  </Element>


                  <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Inter', textAlign: 'left', color: '#FFFFFF' }} className='my-[7px]' id='random-id-31'  >
                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.' />
                  </Element>

                </Element>
              </Element>
              <Element is={Parent} canvas id='random-id-596' className='min-h-[117px] max-h-[100%]  min-w-[277px] flex flex-row  m-2 mx-auto' style={{ flex: '30%' }}>
                <Element is={Parent} canvas id='random-id-597' className='h-full my-[7px] items-center flex' style={{ borderRadius: '6px', background: '#FFD600', width: 'auto', height: 'fit-content', padding: '6px' }}>
                  <SvgIcon width='50px' height='50px' fill='#2A2A2A' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                </Element>
                <Element is={Parent} canvas id='random-id-598' className='flex flex-col px-8 max-w-[80%]'>
                  <Element is={Parent} canvas id='random-id-599' style={{ fontSize: '22px', fontWeight: '700', fontFamily: 'Inter', textAlign: 'left', color: '#FFFFFF' }}>
                    <Text text='Dolor sit' />
                  </Element>

                  <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Inter', textAlign: 'left', color: '#FFFFFF' }} className='my-[7px]' id='random-id-32'  >
                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.' />
                  </Element>

                </Element>
              </Element>
              <Element is={Parent} canvas id='random-id-600' className='min-h-[117px] max-h-[100%]  min-w-[277px] flex flex-row  m-2 mx-auto' style={{ flex: '30%' }}>
                <Element is={Parent} canvas id='random-id-601' className='h-full my-[7px] items-center flex' style={{ borderRadius: '6px', background: '#FFD600', width: 'auto', height: 'fit-content', padding: '6px' }}>
                  <SvgIcon width='50px' height='50px' fill='#2A2A2A' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                </Element>
                <Element is={Parent} canvas id='random-id-602' className='flex flex-col px-8 max-w-[80%]'>
                  <Element is={Parent} canvas id='random-id-603' style={{ fontSize: '22px', fontWeight: '700', fontFamily: 'Inter', textAlign: 'left', color: '#FFFFFF' }}>
                    <Text text='Dolor sit' />
                  </Element>

                  <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Inter', textAlign: 'left', color: '#FFFFFF' }} className='my-[7px]' id='random-id-33'  >
                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.' />
                  </Element>

                </Element>
              </Element>
              <Element is={Parent} canvas id='random-id-604' className='min-h-[117px] max-h-[100%]  min-w-[277px] flex flex-row  m-2 mx-auto' style={{ flex: '30%' }}>
                <Element is={Parent} canvas id='random-id-605' className='h-full my-[7px] items-center flex' style={{ borderRadius: '6px', background: '#FFD600', width: 'auto', height: 'fit-content', padding: '6px' }}>
                  <SvgIcon width='50px' height='50px' fill='#2A2A2A' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                </Element>
                <Element is={Parent} canvas id='random-id-606' className='flex flex-col px-8 max-w-[80%]'>
                  <Element is={Parent} canvas id='random-id-607' style={{ fontSize: '22px', fontWeight: '700', fontFamily: 'Inter', textAlign: 'left', color: '#FFFFFF' }}>
                    <Text text='Dolor sit' />
                  </Element>

                  <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Inter', textAlign: 'left', color: '#FFFFFF' }} className='my-[7px]' id='random-id-34'  >
                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.' />
                  </Element>

                </Element>
              </Element>
              <Element is={Parent} canvas id='random-id-608' className='min-h-[117px] max-h-[100%]  min-w-[277px] flex flex-row  m-2 mx-auto' style={{ flex: '30%' }}>
                <Element is={Parent} canvas id='random-id-609' className='h-full my-[7px] items-center flex' style={{ borderRadius: '6px', background: '#FFD600', width: 'auto', height: 'fit-content', padding: '6px' }}>
                  <SvgIcon width='50px' height='50px' fill='#2A2A2A' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                </Element>
                <Element is={Parent} canvas id='random-id-610' className='flex flex-col px-8 max-w-[80%]'>
                  <Element is={Parent} canvas id='random-id-611' style={{ fontSize: '22px', fontWeight: '700', fontFamily: 'Inter', textAlign: 'left', color: '#FFFFFF' }}>
                    <Text text='Dolor sit' />
                  </Element>

                  <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Inter', textAlign: 'left', color: '#FFFFFF' }} className='my-[7px]' id='random-id-35'  >
                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.' />
                  </Element>

                </Element>
              </Element>
              <Element is={Parent} canvas id='random-id-612' className='min-h-[117px] max-h-[100%]  min-w-[277px] flex flex-row  m-2 mx-auto' style={{ flex: '30%' }}>
                <Element is={Parent} canvas id='random-id-613' className='h-full my-[7px] items-center flex' style={{ borderRadius: '6px', background: '#FFD600', width: 'auto', height: 'fit-content', padding: '6px' }}>
                  <SvgIcon width='50px' height='50px' fill='#2A2A2A' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                </Element>
                <Element is={Parent} canvas id='random-id-614' className='flex flex-col px-8 max-w-[80%]'>
                  <Element is={Parent} canvas id='random-id-615' style={{ fontSize: '22px', fontWeight: '700', fontFamily: 'Inter', textAlign: 'left', color: '#FFFFFF' }}>
                    <Text text='Dolor sit' />
                  </Element>

                  <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Inter', textAlign: 'left', color: '#FFFFFF' }} className='my-[7px]' id='random-id-36'  >
                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.' />
                  </Element>

                </Element>
              </Element>
            </Element>
          </Element>

        </Element></Element>
    </Element>,


    <Element canvas is={Parent} id='parentId1700' className="w-[100%] h-auto  bg-[#F7FBFE] py-20" >
      <Element canvas is={Parent} id='parentId1800' className="w-[100%] h-auto justify-center items-center flex" row={true} >
        <Element is={Parent} canvas id='random-id-901' className='max-w-[1300px]'>
          <Element is={Parent} canvas id='random-id-902' className='w-full h-full flex flex-col flex-wrap'>
            <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: '700', fontFamily: 'Rubik' }} className='text-[#6064D2] mx-auto text-center' id='random-id-5500'  >
              <Text text='What makes our clients happy.' />
            </Element>
            <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: '400', fontFamily: 'Inter', color: '#020E17' }} className='my-10 mx-auto text-center max-w-[500px] flex flex-wrap' id='random-id-5600'  >
              <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
            </Element>
            <Element is={Parent} canvas id='random-id-903' className='w-full h-full flex flex-row flex-wrap justify-center'>
              <Element is={Parent} canvas id='random-id-904' className='min-h-[117px] max-h-[100%]  min-w-[277px] flex flex-row  m-2 mx-auto' style={{ flex: '30%' }}>
                <Element is={Parent} canvas id='random-id-905' className='h-full my-[7px] items-center flex' style={{ border: '1px solid #B20312', borderRadius: '6px', background: '#B20312', width: 'auto', height: 'fit-content', padding: '6px' }}>
                  <SvgIcon width='50px' height='50px' fill='#FFFFFF' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                </Element>
                <Element is={Parent} canvas id='random-id-906' className='flex flex-col px-8 max-w-[80%]'>
                  <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: '700', fontFamily: 'Rubik', textAlign: 'left', color: '#000000' }} id='random-id-5700'  >
                    <Text text='Dolor sit' />
                  </Element>

                  <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Rubik', textAlign: 'left', color: '#000000' }} className='my-[7px]' id='random-id-5800'  >
                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.' />
                  </Element>
                </Element>
              </Element>
              <Element is={Parent} canvas id='random-id-907' className='min-h-[117px] max-h-[100%]  min-w-[277px] flex flex-row  m-2 mx-auto' style={{ flex: '30%' }}>
                <Element is={Parent} canvas id='random-id-908' className='h-full my-[7px] items-center flex' style={{ border: '1px solid #B20312', borderRadius: '6px', background: '#B20312', width: 'auto', height: 'fit-content', padding: '6px' }}>
                  <SvgIcon width='50px' height='50px' fill='#FFFFFF' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                </Element>
                <Element is={Parent} canvas id='random-id-909' className='flex flex-col px-8 max-w-[80%]'>
                  <Element is={Parent} canvas id='random-id-910' style={{ fontSize: '22px', fontWeight: '700', fontFamily: 'Rubik', textAlign: 'left', color: '#000000' }}>
                    <Text text='Dolor sit' />
                  </Element>
                  <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Rubik', textAlign: 'left', color: '#000000' }} className='my-[7px]' id='random-id-5900'  >
                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.' />
                  </Element>
                </Element>
              </Element>
              <Element is={Parent} canvas id='random-id-911' className='min-h-[117px] max-h-[100%]  min-w-[277px] flex flex-row  m-2 mx-auto' style={{ flex: '30%' }}>
                <Element is={Parent} canvas id='random-id-912' className='h-full my-[7px] items-center flex' style={{ border: '1px solid #B20312', borderRadius: '6px', background: '#B20312', width: 'auto', height: 'fit-content', padding: '6px' }}>
                  <SvgIcon width='50px' height='50px' fill='#FFFFFF' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                </Element>
                <Element is={Parent} canvas id='random-id-913' className='flex flex-col px-8 max-w-[80%]'>
                  <Element is={Parent} canvas id='random-id-914' style={{ fontSize: '22px', fontWeight: '700', fontFamily: 'Rubik', textAlign: 'left', color: '#000000' }}>
                    <Text text='Dolor sit' />
                  </Element>

                  <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Rubik', textAlign: 'left', color: '#000000' }} className='my-[7px]' id='random-id-60100'  >
                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.' />
                  </Element>

                </Element>
              </Element>
              <Element is={Parent} canvas id='random-id-915' className='min-h-[117px] max-h-[100%]  min-w-[277px] flex flex-row  m-2 mx-auto' style={{ flex: '30%' }}>
                <Element is={Parent} canvas id='random-id-916' className='h-full my-[7px] items-center flex' style={{ border: '1px solid #B20312', borderRadius: '6px', background: '#B20312', width: 'auto', height: 'fit-content', padding: '6px' }}>
                  <SvgIcon width='50px' height='50px' fill='#FFFFFF' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                </Element>
                <Element is={Parent} canvas id='random-id-917' className='flex flex-col px-8 max-w-[80%]'>
                  <Element is={Parent} canvas id='random-id-918' style={{ fontSize: '22px', fontWeight: '700', fontFamily: 'Rubik', textAlign: 'left', color: '#000000' }}>
                    <Text text='Dolor sit' />
                  </Element>

                  <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Rubik', textAlign: 'left', color: '#000000' }} className='my-[7px]' id='random-id-60120'  >
                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.' />
                  </Element>
                </Element>

              </Element>
              <Element is={Parent} canvas id='random-id-919' className='min-h-[117px] max-h-[100%]  min-w-[277px] flex flex-row  m-2 mx-auto' style={{ flex: '30%' }}>
                <Element is={Parent} canvas id='random-id-920' className='h-full my-[7px] items-center flex' style={{ border: '1px solid #B20312', borderRadius: '6px', background: '#B20312', width: 'auto', height: 'fit-content', padding: '6px' }}>
                  <SvgIcon width='50px' height='50px' fill='#FFFFFF' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                </Element>
                <Element is={Parent} canvas id='random-id-921' className='flex flex-col px-8 max-w-[80%]'>
                  <Element is={Parent} canvas id='random-id-922' style={{ fontSize: '22px', fontWeight: '700', fontFamily: 'Rubik', textAlign: 'left', color: '#000000' }}>
                    <Text text='Dolor sit' />
                  </Element>

                  <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Rubik', textAlign: 'left', color: '#000000' }} className='my-[7px]' id='random-id-6201'  >
                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.' />
                  </Element>

                </Element>
              </Element>
              <Element is={Parent} canvas id='random-id-923' className='min-h-[117px] max-h-[100%]  min-w-[277px] flex flex-row  m-2 mx-auto' style={{ flex: '30%' }}>
                <Element is={Parent} canvas id='random-id-924' className='h-full my-[7px] items-center flex' style={{ border: '1px solid #B20312', borderRadius: '6px', background: '#B20312', width: 'auto', height: 'fit-content', padding: '6px' }}>
                  <SvgIcon width='50px' height='50px' fill='#FFFFFF' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                </Element>
                <Element is={Parent} canvas id='random-id-925' className='flex flex-col px-8 max-w-[80%]'>
                  <Element is={Parent} canvas id='random-id-926' style={{ fontSize: '22px', fontWeight: '700', fontFamily: 'Rubik', textAlign: 'left', color: '#000000' }}>
                    <Text text='Dolor sit' />
                  </Element>
                  <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Rubik', textAlign: 'left', color: '#000000' }} className='my-[7px]' id='random-id-63100'  >
                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.' />
                  </Element>
                </Element>
              </Element>
            </Element>
          </Element>

        </Element></Element>
    </Element>,


    <Element canvas is={Parent} id='parentId1900' className="w-[100%] h-auto  bg-[#FEFBF7] py-20" >
      <Element canvas is={Parent} id='parentId2000' className="w-[100%] h-auto justify-center items-center flex" row={true} >
        <Element is={Parent} canvas id='random-id-1001' className='max-w-[1300px]'>
          <Element is={Parent} canvas id='random-id-1002' className='w-full h-full flex flex-col flex-wrap'>
            <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: '400', fontFamily: 'Ruluko' }} className='text-[#A25738] mx-auto text-center' id='random-id-5015'  >
              <Text text='What makes our clients happy.' />
            </Element>
            <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: '400', fontFamily: 'Inter', color: '#020E17' }} className='my-10 mx-auto text-center max-w-[500px] flex flex-wrap' id='random-id-5016'  >
              <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
            </Element>
            <Element is={Parent} canvas id='random-id-1003' className='w-full h-full flex flex-row flex-wrap justify-center'>
              <Element is={Parent} canvas id='random-id-1004' className='min-h-[117px] max-h-[100%]  min-w-[277px] flex flex-row  m-2 mx-auto' style={{ flex: '30%' }}>
                <Element is={Parent} canvas id='random-id-1005' className='h-full my-[7px] items-center flex' style={{ borderRadius: '8px', background: '#A25738', width: 'auto', height: 'fit-content', padding: '6px' }}>
                  <SvgIcon width='50px' height='50px' fill='#FFFFFF' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                </Element>
                <Element is={Parent} canvas id='random-id-1006' className='flex flex-col px-8 max-w-[80%]'>
                  <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: '700', fontFamily: 'Rubik', textAlign: 'left', color: '#000000' }} id='random-id-5017'  >
                    <Text text='Dolor sit' />
                  </Element>

                  <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Rubik', textAlign: 'left', color: '#000000' }} className='my-[7px]' id='random-id-5018'  >
                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.' />
                  </Element>
                </Element>
              </Element>
              <Element is={Parent} canvas id='random-id-1007' className='min-h-[117px] max-h-[100%]  min-w-[277px] flex flex-row  m-2 mx-auto' style={{ flex: '30%' }}>
                <Element is={Parent} canvas id='random-id-1008' className='h-full my-[7px] items-center flex' style={{ borderRadius: '8px', background: '#A25738', width: 'auto', height: 'fit-content', padding: '6px' }}>
                  <SvgIcon width='50px' height='50px' fill='#FFFFFF' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                </Element>
                <Element is={Parent} canvas id='random-id-1009' className='flex flex-col px-8 max-w-[80%]'>
                  <Element is={Parent} canvas id='random-id-1010' style={{ fontSize: '22px', fontWeight: '700', fontFamily: 'Rubik', textAlign: 'left', color: '#000000' }}>
                    <Text text='Dolor sit' />
                  </Element>
                  <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Rubik', textAlign: 'left', color: '#000000' }} className='my-[7px]' id='random-id-5019'  >
                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.' />
                  </Element>
                </Element>
              </Element>
              <Element is={Parent} canvas id='random-id-1011' className='min-h-[117px] max-h-[100%]  min-w-[277px] flex flex-row  m-2 mx-auto' style={{ flex: '30%' }}>
                <Element is={Parent} canvas id='random-id-1012' className='h-full my-[7px] items-center flex' style={{ borderRadius: '8px', background: '#A25738', width: 'auto', height: 'fit-content', padding: '6px' }}>
                  <SvgIcon width='50px' height='50px' fill='#FFFFFF' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                </Element>
                <Element is={Parent} canvas id='random-id-1013' className='flex flex-col px-8 max-w-[80%]'>
                  <Element is={Parent} canvas id='random-id-1014' style={{ fontSize: '22px', fontWeight: '700', fontFamily: 'Rubik', textAlign: 'left', color: '#000000' }}>
                    <Text text='Dolor sit' />
                  </Element>

                  <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Rubik', textAlign: 'left', color: '#000000' }} className='my-[7px]' id='random-id-5220'  >
                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.' />
                  </Element>

                </Element>
              </Element>
              <Element is={Parent} canvas id='random-id-1015' className='min-h-[117px] max-h-[100%]  min-w-[277px] flex flex-row  m-2 mx-auto' style={{ flex: '30%' }}>
                <Element is={Parent} canvas id='random-id-1016' className='h-full my-[7px] items-center flex' style={{ borderRadius: '8px', background: '#A25738', width: 'auto', height: 'fit-content', padding: '6px' }}>
                  <SvgIcon width='50px' height='50px' fill='#FFFFFF' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                </Element>
                <Element is={Parent} canvas id='random-id-1017' className='flex flex-col px-8 max-w-[80%]'>
                  <Element is={Parent} canvas id='random-id-1018' style={{ fontSize: '22px', fontWeight: '700', fontFamily: 'Rubik', textAlign: 'left', color: '#000000' }}>
                    <Text text='Dolor sit' />
                  </Element>

                  <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Rubik', textAlign: 'left', color: '#000000' }} className='my-[7px]' id='random-id-5221'  >
                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.' />
                  </Element>
                </Element>

              </Element>
              <Element is={Parent} canvas id='random-id-1019' className='min-h-[117px] max-h-[100%]  min-w-[277px] flex flex-row  m-2 mx-auto' style={{ flex: '30%' }}>
                <Element is={Parent} canvas id='random-id-1020' className='h-full my-[7px] items-center flex' style={{ borderRadius: '8px', background: '#A25738', width: 'auto', height: 'fit-content', padding: '6px' }}>
                  <SvgIcon width='50px' height='50px' fill='#FFFFFF' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                </Element>
                <Element is={Parent} canvas id='random-id-1021' className='flex flex-col px-8 max-w-[80%]'>
                  <Element is={Parent} canvas id='random-id-1022' style={{ fontSize: '22px', fontWeight: '700', fontFamily: 'Rubik', textAlign: 'left', color: '#000000' }}>
                    <Text text='Dolor sit' />
                  </Element>

                  <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Rubik', textAlign: 'left', color: '#000000' }} className='my-[7px]' id='random-id-5222'  >
                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.' />
                  </Element>

                </Element>
              </Element>
              <Element is={Parent} canvas id='random-id-1023' className='min-h-[117px] max-h-[100%]  min-w-[277px] flex flex-row  m-2 mx-auto' style={{ flex: '30%' }}>
                <Element is={Parent} canvas id='random-id-1024' className='h-full my-[7px] items-center flex' style={{ borderRadius: '8px', background: '#A25738', width: 'auto', height: 'fit-content', padding: '6px' }}>
                  <SvgIcon width='50px' height='50px' fill='#FFFFFF' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />

                </Element>
                <Element is={Parent} canvas id='random-id-1025' className='flex flex-col px-8 max-w-[80%]'>
                  <Element is={Parent} canvas id='random-id-1026' style={{ fontSize: '22px', fontWeight: '700', fontFamily: 'Rubik', textAlign: 'left', color: '#000000' }}>
                    <Text text='Dolor sit' />
                  </Element>
                  <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Rubik', textAlign: 'left', color: '#000000' }} className='my-[7px]' id='random-id-5223'  >
                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.' />
                  </Element>
                </Element>
              </Element>
            </Element>
          </Element>

        </Element></Element>
    </Element>,

  ]


  const [sel, setSel] = useState(selected)

  function makeOdd(number) {
    setProp((prop) => {
      setSel(100);
    }, 1000);

    setTimeout(() => {
      setProp((prop) => {
        setSel(number)
      }, 1000);
    }, 100);



  }

  useEffect(() => {
    if (selected || selected >= 0) {
      makeOdd(selected);
    }
  }, [selected])


  return (
    <div style={{ padding: enabled ? '10px' : "0px", display: "flex", justifyContent: "center", alignItems: "center", width: '100%', height: '100%' }} ref={connect}>
      {sel == 100 ? (
        <div style={{ height: "85vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <BounceLoader
            size={100}
            color={"white"}
            loading={true}
          />
        </div>
      ) : (
        styles[sel]
      )}
    </div>
  )
}

Sections_1.craft = {
  displayName: "Sections 1",
  props: {
    selected: 1, // here is the default value of the selected style
    length: 9,
    isBlock: true
  },
  rules: {
    canDrag: () => true,
    canDrop: () => true,
  },
  related: {
    toolbar: NewSectionsSettings,
  },
};
