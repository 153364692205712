/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import { useEditor, useNode } from '@craftjs/core';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDarkMode } from '../../../../DarkModeContext';
import { SidebarItem } from './SidebarItem';
import { Resizable } from 're-resizable';
import { Toolbar } from '../../Toolbar';
import { TabOption } from './TabOption';
import { CloseIcon2, Settings } from '../Pop-up/svgArray';
import { WidgetFooter } from '../../Toolbar/widgetFooter';
import { Layers } from '@craftjs/layers';
import Draggable from 'react-draggable'
import SidebarContext from '../../../../SidebarShown/SidebarContext';



const CarbonAdsContainer = styled.div`
  width: 100%;
  margin-top: auto;

  #carbonads * {
    margin: initial;
    padding: initial;
  }

  #carbonads {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
      Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', Helvetica, Arial,
      sans-serif;

    padding: 10px 0.5rem;
    border-top: 1px solid rgb(229 231 235);
  }

  #carbonads {
    display: flex;
    width: 100%;
    background-color: transparent;
    z-index: 100;
  }

  #carbonads a {
    color: inherit;
    text-decoration: none;
  }

  #carbonads a:hover {
    color: inherit;
  }

  #carbonads span {
    position: relative;
    display: block;
    overflow: hidden;
  }

  #carbonads .carbon-wrap {
    display: flex;
  }

  #carbonads .carbon-img {
    display: block;
    margin: 0;
    line-height: 1;
    max-width: 30%;
  }

  #carbonads .carbon-img img {
    display: block;
    max-width: 100% !important;
  }

  #carbonads .carbon-text {
    font-size: 8px;
    padding: 10px;
    margin-bottom: 16px;
    line-height: 1.5;
    text-align: right;
    color: #333333;
    font-weight: 400;
    flex: 1;
  }

  #carbonads .carbon-poweredby {
    display: block;
    padding: 6px 8px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-weight: 600;
    font-size: 6px;
    line-height: 1;
    position: absolute;
    bottom: 0;
    right: 0;
    color: #8f8f8f;
  }
`;

export const Sidebar = ({working, setStylesOn, currentStyleIndex,  handleSliderChange, handleSliderBlur, handleSliderFocus, selectNode, setSettings, socialIcons, setSocialIcons, navigationSide, setNavigationSide, setLogoText, logoText, logoIcon, setLogoIcon, }) => {
  const { isDarkMode } = useDarkMode();
  const [moving, setMoving] = useState(true)
  const [helberScreen, setHelberScreen] = useState(false)

  const { enabled, } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));


  const { active, related } = useEditor((state, query) => {
    // TODO: handle multiple selected elements
    const currentlySelectedNodeId = query.getEvent('selected').first();
    return {
      active: currentlySelectedNodeId,
      related:
        currentlySelectedNodeId && state.nodes[currentlySelectedNodeId],
    };
  });
  
    if(related?.data?.name?.includes("Widget")) {
      return <div style={{position:"fixed", right:0, bottom:0, zIndex:199900}}>
       <Toolbar working={working} selectNode={selectNode} enabled={enabled} logoIcon={logoIcon} setLogoIcon={setLogoIcon} setNavigationSide={setNavigationSide} logoText={logoText} setLogoText={setLogoText} navigationSide={navigationSide} />
      </div> 
    }


  return (
<>
    {/* <div style={{position:"fixed", right:0, bottom:0, zIndex:100}}>
      <WidgetFooter
      expanded={expanded}
      setExpanded={setExpanded}
      >
      
      </WidgetFooter>
    </div> */}
    {helberScreen && <div className='w-[100vw] h-[100vh] bg-[transparent] absolute top-0 left-0' style={{zIndex:999}}></div>}

   
      <Draggable bounds={{ left: '100%', top: 0, right: '100%', bottom: 500 }} onStart={() => setHelberScreen(true)} onStop={() => setHelberScreen(false)} disabled={moving? true : false}>
      {navigationSide && enabled ? <div className='shadow-xl' style={{position:'absolute', zIndex:9999, top:'11vh', borderRadius:'3vmin', right:'1%'}}>

       <Resizable
       
       onResizeStart={(e) => {
        e.stopPropagation();
        setMoving(true)
        setHelberScreen(true)}
      }
      onResizeStop={(e) => {
        e.stopPropagation();
        setMoving(false)
        setHelberScreen(false)}
      } 
      style={{ borderRadius:'3vmin', background:isDarkMode? '#111F3E' : '#fff'}}
      lockAspectRatioExtraWidth={true} 
      lockAspectRatioExtraHeight={true} 
      defaultSize={{width:250, height:400}} 
      minWidth={250}
      minHeight={400}
      enable={{top:'true', bottom:'true'}}
      >
    <Toolbar
    setMoving={setMoving}
    currentStyleIndex={currentStyleIndex}
    handleSliderChange={handleSliderChange}
    handleSliderBlur={handleSliderBlur}
    handleSliderFocus={handleSliderFocus}
    socialIcons={socialIcons} setStylesOn={setStylesOn} setSettings={setSettings} setSocialIcons={setSocialIcons} working={working} selectNode={selectNode} enabled={enabled}  logoIcon={logoIcon} setLogoIcon={setLogoIcon} setNavigationSide={setNavigationSide} logoText={logoText} setLogoText={setLogoText} navigationSide={navigationSide} />
      </Resizable>
      </div> 
       : <div className='d-none'>undefined</div>}
      </Draggable>
  </>
  );
};
