/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, { useState } from 'react';
import { ToolbarSection, ToolbarItem } from '../../../editor';
import { ToolbarRadio } from '../../../editor/Toolbar/ToolbarRadio';
import AlignHorizontalLeftIcon from '@mui/icons-material/AlignHorizontalLeft';
import AlignHorizontalCenterIcon from '@mui/icons-material/AlignHorizontalCenter';
import AlignHorizontalRightIcon from '@mui/icons-material/AlignHorizontalRight';
import { TabOption } from '../../../editor/Viewport/Sidebar/TabOption';
import { useNode } from '@craftjs/core';
import Toggle from '../../../editor/Toolbar/Toggle';

export const InputSettings = () => {
  const [activeTab, setActiveTab] = useState("Style Manager");

  // const [readonly, setReadOnly] = useState(false)

  // const {
  //   node,
  //   props,
  //   actions: { setProp }
  // } = useNode((node) => ({
  //   props: node.data.props,
  //   node: node,
  // }));

  // const toggleIsText = () => {
  //   setProp((prop) => {
  //     prop.readonly = prop.readonly === 'false' ? 'true' : 'false';
  //   })
  //   setReadOnly((readonly) => !readonly);

  // }

  // console.log(props)

  return (
    <div className='flex flex-col component-settings' style={{ height:'100%'}}>
      <ToolbarItem
            full={true}
            type="properties"
          />
      <div className='w-full'>
        <TabOption activeTab={activeTab} setActiveTab={setActiveTab} />
      </div>

<div style={{overflowY:'scroll', scrollbarWidth:'none', overflowX:'hidden',}}>
      {activeTab === "Advanced" ? (
        <ToolbarSection
          expanded={true}
          title="Actions"
        > 
         {/* <ToolbarItem
            full={true}
            type="properties"
            title='Read-Only'
          />
          <Toggle toggleIsImg={toggleIsText} on={readonly} first={'true'} second={'false'}/> */}

          <ToolbarItem
            full={true}
            propKey="type"
            optionsDrop={[ 'string' , 'number', 'email', 'password', 'checkbox', 'file', 'hidden']}
            type="select-drop"
            label="Type"
          />

          <ToolbarItem
            full={true}
            propKey="name"
            type="text"
            label="Name"
          />

           <ToolbarItem
            full={true}
            propKey="inputValue"
            type="text"
            label="Value"
          />

        </ToolbarSection>
      ) : null}

      {activeTab === "Style Manager" ? (
        <div style={{ width: "100%" }}>
          <ToolbarSection
            expanded={true}
            title="Typography"
            props={['fontSize', 'fontWeight', 'textAlign', 'placeholder']}
            summary={({ fontSize, fontWeight, textAlign, placeholder, }) => {
              return `${fontSize || ''}, ${fontWeight || ''}, ${textAlign || ''}, ${placeholder || ''}`;
            }}
          >
            <ToolbarItem
              full={true}
              propKey="fontSize"
              type="slider"
              unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
              label="Size"
            />

            <ToolbarItem
              full={true}
              propKey="textAlign"
              optionsDrop={['left', 'center', 'right']}
              type="select-drop"
              label="Text Align"
            />
            <ToolbarItem
              full={true}
              propKey="fontWeight"
              optionsDrop={['normal', 'bold', 'bolder', 'lighter']}
              type="select-drop"
              label="Font Weight"
            />
            <ToolbarItem
              full={true}
              propKey="placeholder"
              type="text"
              label="Placeholder"
            />
           
          </ToolbarSection>
          <ToolbarSection
            expanded={true}
            title="Size"
            props={['width', 'height', 'minWidth', 'maxWidth']}
            summary={({ width, height, minWidth, maxWidth }) => {
              return `Width: ${width || 'Auto'}, Height: ${height || 'Auto'}, Min Width: ${minWidth || 'None'}, Max Width: ${maxWidth || 'None'}`;
            }}
          >
            <ToolbarItem
              full={true}
              propKey="width"
              type="slider"
              unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
              label="Width"
            />
            <ToolbarItem
              full={true}
              propKey="height"
              type="slider"
              unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
              label="Height"
            />
            <ToolbarItem
              full={true}
              propKey="minWidth"
              type="slider"
              unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
              label="Min-Width"
            />
            <ToolbarItem
              full={true}
              propKey="maxWidth"
              type="slider"
              unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
              label="Max-Width"
            />
          </ToolbarSection>

          <ToolbarSection
            expanded={true}
            title="padding"
            props={['padding']}
            summary={({ }) => {
              return;
            }}
          >
            <ToolbarItem propKey="paddingTop" type="slider"
              unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}

              label="Top" />
            <ToolbarItem propKey="paddingRight" type="slider"
              unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}

              label="Right" />
            <ToolbarItem propKey="paddingBottom" type="slider"
              unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}

              label="Bottom" />
            <ToolbarItem propKey="paddingLeft" type="slider"
              unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}

              label="Left" />
          </ToolbarSection>


          <ToolbarSection
            expanded={true}
            title="margin"
            props={['margin']}
            summary={({ }) => {
              return;
            }}
          >
            <ToolbarItem propKey="marginTop" type="slider"
              unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}

              label="Top" />
            <ToolbarItem propKey="marginRight" type="slider"
              unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}

              label="Right" />
            <ToolbarItem propKey="marginBottom" type="slider"
              unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}

              label="Bottom" />
            <ToolbarItem propKey="marginLeft" type="slider"
              unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}

              label="Left" />
          </ToolbarSection>
          <ToolbarSection
            expanded={true}
            title="Extra"
            props={['background', 'shadow']}
            summary={({ background, shadow }) => {
              return (
                <div className="fletext-right">
                 
                </div>
              );
            }}
          >
            {/* <ToolbarItem full={true} propKey="background" type="bg" label="background" /> */}
            <ToolbarItem
            full={true}
            propKey="radius"
            type="slider"
            unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
            label="Radius"
          />

          </ToolbarSection>
        </div>
      ) : null}
      </div>
    </div>
  );
};
