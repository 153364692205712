/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import { useNode, useEditor } from '@craftjs/core';
import { ROOT_NODE } from '@craftjs/utils';
import React, { useEffect, useRef, useCallback, useState, useContext } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { useDarkMode } from '../../DarkModeContext';
import { ToolbarItem } from './Toolbar';
import SelectionBox from './Viewport/Pop-up/PopUp';
import { ImageCSettings, category, imgsCate } from '../selectors/basic/Image/ImageSettings';
import { DisabledIcon, ScaleIcon, UndisabledIcon } from './Viewport/Pop-up/svgArray';
import { ButtonSettings } from '../selectors/basic/Button/ButtonSettings';
import Draggable from "react-draggable";
import { Resizable } from 're-resizable';
import { TextSettings } from '../selectors/basic/Text/TextSettings';
import { SvgIconSettings } from '../selectors/basic/Svg/SvgIconSettings';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import { WrapperSettings } from '../selectors/wrapper/WrapperSettings';
import { InputSettings } from '../selectors/basic/Input/InputSettings';
import { LoginSettings } from '../../login/LoginSettings';
import { NavBarSettings } from '../widgets/newHeader/NavbarSettings';
import { PluginSettings } from '../widgets/Plugin/PluginSettings';
import SidebarContext from '../../SidebarShown/SidebarContext';
import { WidgetFooter } from './Toolbar/widgetFooter';
import { CheckBoxSettings } from '../selectors/basic/Checkbox/CheckBoxSettings';

const IndicatorDiv = styled.div`
  height: 30px;
  margin-top: -29px;
  font-size: 12px;
  line-height: 12px;

  svg {
    width: 15px;
    height: 15px;
  } 
`;

const Btn = styled.a`
  padding: 0 0px;
  opacity: 0.9;
  display: flex;
  align-items: center;
  > div {
    position: relative;
    top: -50%;
    left: -50%;
  }
`;

export const RenderNode = ({ render }, settings, setSettings, setWorking) => {
  const { isDarkMode, smartMenu, setSmartMenu } = useDarkMode();
  const { id } = useNode();
  const { actions, query, isActive, selected, nodeObj, connectors, actions: { setOptions, selectNode }, } = useEditor((state, query) => ({
    isActive: query.getEvent('selected').contains(id),
    nodeObj: state,
    selected: state.events.selected,
  }));

  const {
    isHover,
    dom,
    name,
    moveable,
    deletable,
    connectors: { drag },
    parent,
    node,
    children,
    props,
    actions: { setProp }
  } = useNode((node) => ({
    isHover: node.events.hovered,
    dom: node.dom,
    name: node.data.props.nameOf || node.data.displayName,
    moveable: query.node(node.id).isDraggable(),
    deletable: query.node(node.id).isDeletable(),
    parent: node.data.parent,
    props: node.data.props,
    node: node,
    children: node.data.linkedNodes,

  }));

  const currentRef = useRef();
  const [openAria, setOpen] = useState('')
  const [iconsSquare, setIconsSquare] = useState(false)
  const anchorRef = useRef(null);
  const [moving, setMoving] = useState(true)
  const [helberScreen, setHelberScreen] = useState(false)
  const disabledAria = useRef(null)
  const {setPopUp} = useContext(SidebarContext)

  const handleIconImageSelect = (selectedUrl) => {

    if (Array.isArray(selectedUrl) && selectedUrl.length) {
      setProp((prop) => {
        prop.src = selectedUrl[0]
      }, 500)
    } else {
      setProp((prop) => {
        prop.src = selectedUrl
      }, 500)
    }

    setIconsSquare(false);
  };

  const value = props ? props?.fontSize : null

  useEffect(() => {

    if (children) {
      let newChildren = Object.entries(children)
      newChildren.map(([key, x]) => {
      })
    }
  }, [children])

  useEffect(() => {
    if (dom) {
        if (isActive) {
            // If this element is active, apply selected class and remove hovered class
            dom.classList.add('component-selected');
            dom.classList.remove('component-hovered');
        } else if (isHover && !isActive && selected.size === 0) { // Allow hover if no elements are selected
            // If this element is hovered and no elements are selected, apply hovered class
            // dom.classList.remove('component-selected');
            // dom.classList.add('component-hovered');
        } else {
            // If neither active nor hovered, remove both classes
            dom.classList.remove('component-selected', 'component-hovered');
        }
    }
}, [dom, isActive, isHover, selected]);


useEffect(() => {
  if(isActive) {
    setSmartMenu(true)
  }
},[isActive])

  const getPos = useCallback((dom) => {
    const { top, left, bottom, right } = dom
      ? dom?.getBoundingClientRect()
      : { top: 0, left: 0, bottom: 0, right: 0 };

    return {
      top: `${top > 0 ? top - 29 : bottom - 27}px`,
      right: `${right - 320}px`,
      left: `${left}px`,
      bottom: `${bottom}px`,
    };
  });


  // const scroll = useCallback(() => {
  //   const { current: currentDOM } = currentRef;

  //   if (!currentDOM) return;
  //   const { right, top, left, bottom } = getPos(dom);
  //   currentDOM.style.top = top;
  //   currentDOM.style.right = right;
  //   currentDOM.style.left = left;
  //   currentDOM.style.bottom = bottom;
  // },);

  // useEffect(() => {
  //   document
  //     ?.querySelector('.craftjs-renderer')
  //     ?.addEventListener('scroll', scroll);

  //   return () => {
  //     document
  //       ?.querySelector('.craftjs-renderer')
  //       ?.removeEventListener('scroll', scroll);
  //   };
  // },);




  const insertNodeOnParent = useCallback(
    (
      nodeId,
      parentId,
      indexToInsert,
      selectNodeAfterCreated = false,
    ) => {
      const node = query.node(nodeId).get();

      const freshNode = {
        data: {
          ...node.data,
          nodes: [],
        },
      };

      const nodeToAdd = query.parseFreshNode(freshNode).toNode();

      actions.add(nodeToAdd, parentId, indexToInsert);

      if (node.data.nodes.length === 0) {
        return;
      }

      node.data.nodes.forEach((childNode, index) => {
        insertNodeOnParent(childNode, nodeToAdd.id, index);
      });

      if (selectNodeAfterCreated) actions.selectNode(nodeToAdd.id);
    },
    [actions, query],
  );

  // const duplicateNode = useCallback(() => {
  //   const parentNode = query.node(parent).get();
  //   // const indexToAdd = parentNode.data.nodes.indexOf(id) + 1;

  //   // insertNodeOnParent(id, parent, indexToAdd, true);
  // }, [id, insertNodeOnParent, parent, query]);

  // const changeProp = (prop) => {
  //   setOpen('')


  //   if (prop == "Bold") {
  //     setProp((prop) => {
  //       prop.fontWeight = prop.fontWeight == "bold" ? "normal" : "bold"
  //     }, 500)
  //   }

  //   if (prop == "Italic") {
  //     setProp((prop) => {
  //       prop.fontStyle = prop.fontStyle == "italic" ? "normal" : "italic"
  //     }, 500)

  //   }

  //   if (prop == "Underline") {
  //     setProp((prop) => {
  //       prop.textDecoration = prop.textDecoration == "underline line-through"
  //         ? "line-through" : prop.textDecoration == "line-through"
  //           ? "underline line-through" : prop.textDecoration == "none"
  //             ? "underline" : prop.textDecoration == "underline" ? "none" : "none"
  //     }, 500)
  //   }
  // }




  // const propActive = (prop, value) => {

  //   return props[prop] == value

  // }

  // const [initPostions, setInitPostions] = useState(null)

  // let initPostions = getPos(dom)


  return (
    <>
      {isActive
        ? ReactDOM.createPortal(
          !node?.data?.props["isButton"] &&
              !node?.data?.props["isText"] &&
              !node?.data?.props["isBlock"] &&
              !node?.data?.props["isSvg"] &&
              !node?.data?.props["isParent"] &&
              !node?.data?.props["isNavBar"] &&
              !node?.data?.props["isImage"] &&
              !node?.data?.props["isInput"] &&
              !node?.data?.props["iswidget"] &&
              !node?.data?.props["isCheckBox"] &&
              !node?.data?.props["isDropDown"] &&
              !node?.data?.props["isRadioButton"] &&
              !node?.data?.props["isHeading"] &&
              !node?.data?.props["isLogin"] &&
              !node?.data?.props["isTextArea"] &&
              !node?.data?.props["isLink"] &&
              !node?.data?.props["isDatePicker"] &&
              !node?.data?.props["isSwitch"] &&
              !node?.data?.props["isSlider"] &&
              !node?.data?.props["isNotify"] &&
              // !node?.data?.props["isForm"] &&
              // !node?.data?.props["isMenu"] &&
              // !node?.data?.props["isAcoordion"] &&
              // !node?.data?.props["isSpacer"] &&
              // !node?.data?.props["isNavbar"] &&
              // !node?.data?.props["isAvatar"] &&
              // !node?.data?.props["isCollapse"] &&
              // !node?.data?.props["isSidebar"] &&
              // !node?.data?.props["isSearchBar"] &&
              // !node?.data?.props["isBadge"] &&
              // !node?.data?.props["isUpload"] &&
              // !node?.data?.props["isAlert"] &&
              // !node?.data?.props["isAudioPlayer"] &&
              // !node?.data?.props["isCalendar"] &&
              // !node?.data?.props["isChart"] &&
              // !node?.data?.props["isModal"] &&
              // !node?.data?.props["isRating"] &&
              // !node?.data?.props["isTabs"] &&
              // !node?.data?.props["isTooltip"] &&
              // !node?.data?.props["isProgressBar"] &&



              !node?.data?.props["isLayout"] ? null : <IndicatorDiv
              ref={currentRef}
              className="px-2 py-2 text-white fixed flex items-center itemBox"
              style={{
                left: getPos(dom).left,
                top: getPos(dom).top,
                zIndex: 9999999,
                height: 'fit-content'
              }}
            >
              
              <div
                style={{
                  position: "relative", maxWidth: '35vmin', borderRadius: "10px", overflow: "hidden",
                }}
                className={`border shadow-[2px_0px_10px_0px_rgba(164,_164,_164,_0.1)] flex flex-col w-full ${isDarkMode ? 'bg-[#111F3E]' : 'bg-white'}`}
                id="EditRoot">

                <div
                  style={{padding: '0 1vmin' }}
                  className={`border-solid border-[#e9e9ea] shadow-sm ${isDarkMode ? 'bg-[#111F3E]' : 'bg-white'} flex flex-row justify-around gap-2 h-20 shrink-0 items-center `}>
                  <div
                    className="flex flex-row h-10 justify-center items-center w-[40%]"
                    id="Button1">

{name == "Column" && <div
                      onClick={() => {
                        setProp((prop) => {
                          if(prop.rows > 1) {
                          prop.rows = prop.rows - 1
                          } else {
                            prop.rows = 1
                          }
                        }, 500)
                      }}

                      style={{ padding:"3px",color:"black", width:"fit-content", display:"flex", textAlign:"center", justifyContent:"center", alignItems:"center", borderRadius:"8px", backgroundColor:"lightgray", cursor: "pointer", margin: '0 .30vmin', background: isDarkMode ? "#111F3E" : "lightgray", border: isDarkMode ? "1px solid #E9E9EA" : "1px solid rgba(8, 103, 252, 0.1)" }}
                      id="IconButton"
                      title="Add New column"

                    >
                      -
                    </div>}

                    <div
                      title="Block Name"
                      style={{ width: 'fit-content' }}
                      className={` py-[5%] rounded-[2.8Vmin] flex mx-auto`}
                      id="Label">

                      <div
                        className={`item-center justify-center text-center font-['Inter'] leading-[1.5vmin] ${isDarkMode ? 'text-[#FFFFFF]' : 'text-[#161717]'}`}
                      >{name}</div>
                    </div>
                    {name == "Column" && <div
                      onClick={() => {
                        setProp((prop) => {
                          if(prop.rows < 6) {
                            prop.rows = prop.rows + 1
                            } else {
                              prop.rows = 6
                            }
                        }, 500)
                      }}

                      style={{ padding:"3px",color:"black", width:"fit-content", display:"flex", textAlign:"center", justifyContent:"center", alignItems:"center", borderRadius:"8px", backgroundColor:"lightgray", cursor: "pointer", margin: '0 .30vmin', background: isDarkMode ? "#111F3E" : "lightgray", border: isDarkMode ? "1px solid #E9E9EA" : "1px solid rgba(8, 103, 252, 0.1)" }}
                      id="IconButton"
                      title="Add New column"

                    >
                      +
                    </div>}
                  </div>
                  <div className='flex flex-row justify-between w-[60%]'>
                    {name == "Row" && <div
                      onClick={() => {
                        setProp((prop) => {
                          prop.columns = prop.columns + 1
                        }, 500)
                      }}

                      style={{ cursor: "pointer", margin: '0 .30vmin', background: isDarkMode ? "#111F3E" : "white", border: isDarkMode ? "1px solid #E9E9EA" : "1px solid rgba(8, 103, 252, 0.1)" }}
                      className={` ${isDarkMode ? 'bg-[#0867FC4D]' : 'bg-[#e6f0ff]'} flex flex-col justify-center w-12 shrink-0 h-12 items-center rounded-[30px]`}
                      id="IconButton"
                      title="Add New Column"
                    >
                      <img
                        alt='icons'
                        src="https://i.ibb.co/CnyxZWx/Screenshot-2023-10-31-at-12-43-19-PM.png"
                        className="w-8"
                        id="Icons"
                      />
                    </div>}

                    {/* {name == "Column" && <div
                      onClick={() => {
                        setProp((prop) => {
                          prop.rows = prop.rows + 1
                        }, 500)
                      }}

                      style={{ padding:"3px",color:"black", width:"fit-content", display:"flex", textAlign:"center", justifyContent:"center", alignItems:"center", borderRadius:"8px", backgroundColor:"lightgray", cursor: "pointer", margin: '0 .30vmin', background: isDarkMode ? "#111F3E" : "lightgray", border: isDarkMode ? "1px solid #E9E9EA" : "1px solid rgba(8, 103, 252, 0.1)" }}
                      id="IconButton"
                      title="Add New column"

                    >
                      column +
                    </div>} */}


                    {id !== ROOT_NODE && <div
                      onClick={() => {
                        actions.selectNode(parent);
                        setWorking(true)
                      }}

                      style={{ cursor: "pointer", margin: '0 .30vmin' }}
                      className={` ${isDarkMode ? 'bg-[#0867FC4D]' : 'bg-[#e6f0ff]'} flex flex-col justify-center w-12 shrink-0 h-12 items-center rounded-[30px]`}
                      id="IconButton"
                      title="Parent"

                    >
                      <img
                        alt='icons'
                        src="https://file.rendit.io/n/mdW1kcmagl4wSDj50KGA.svg"
                        className="w-6"
                        id="Icons"
                      />
                    </div>}
                    {moveable && <div
                      onMouseEnter={() => smartMenu ? setSmartMenu(false) : null}
                      style={{ cursor: "pointer", margin: '0 .30vmin' }}
                      ref={drag}
                      className={` ${isDarkMode ? 'bg-[#0867FC4D]' : 'bg-[#e6f0ff]'} flex flex-col justify-center w-12 shrink-0 h-12 items-center rounded-[30px]`}
                      id="IconButton2"
                    >
                      <img
                        alt='icons'
                        src="https://file.rendit.io/n/fakTTDLEhANgdwzPj8EN.svg"
                        className="w-6"
                        id="Icons2"
                        title="Move Item"

                      />
                    </div>}

                    {deletable && <div
                      style={{ cursor: "pointer", margin: '0 .30vmin' }}
                      onMouseDown={(e) => {
                        e.stopPropagation();
                        actions.delete(id);
                      }}
                      className={` ${isDarkMode ? 'bg-[#0867FC4D]' : 'bg-[#e6f0ff]'} flex flex-col justify-center w-12 shrink-0 h-12 items-center rounded-[30px]`}
                      id="IconButton3"
                      title="Delete"

                    >
                      <img
                        alt='icons'
                        src="https://file.rendit.io/n/XM9ibrjLzeBNTJtQgfyk.svg"
                        className="w-6"
                        id="Icons3"
                      />
                    </div>}
                    {node?.data?.props?.['isNavBar'] && <div
                      style={{ cursor: "pointer", margin: '0 .30vmin' }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setPopUp(true)
                      }}
                      className={` ${isDarkMode ? 'bg-[#0867FC4D]' : 'bg-[#e6f0ff]'} flex flex-col justify-center w-12 shrink-0 h-12 items-center rounded-[30px]`}
                      id="IconButton3"
                      title="Info"

                    >
                      <img
                        alt='icons'
                        src="https://i.ibb.co/7v5sPmm/icons8-info-50.png"
                        className="w-6"
                        id="Icons3"
                      />
                    </div>}
                  </div>

                  {/* {node?.id == "ROOT" ? <div
                style={{cursor:"pointer"}}
                  className="bg-[rgba(8,_103,_252,_0.1)] flex flex-col justify-center w-12 shrink-0 h-12 items-center rounded-[30px]"
                  id="IconButton3"
                  
                >
                 <FontAwesomeIcon
                className="activeSideBarX"
                icon={faShare}
                style={{cursor:"pointer", color:"rgba(8,103,252"}}        
                onClick={() =>  {
                  dispatch(ACTION_FORMBUILDER(query.serialize()))
                }}
              />
                </div> 
                : null}  */}
                </div>

              </div>


              </IndicatorDiv>,
          document.querySelector('.page-container')
        )
        : null}
      {render}
    </>
  );
};
