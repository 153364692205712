/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR falseS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React from 'react';
import { useNode, useEditor } from '@craftjs/core';
import { InputSettings } from './InputSettings';
import { useActiveIdContext } from '../../../../ActiveIdContext';

export const Input = ({
  style = {},
  fontSize,
  textAlign,
  fontWeight,
  shadow,
  inputValue,
  radius,
  placeholder,
  width,
  paddingTop,
  paddingRight,
  paddingBottom,
  paddingLeft,
  marginTop,
  marginRight,
  marginBottom,
  marginLeft,
  height,
  minWidth,
  maxWidth,
  type,
  selected,
  readonly,
  name,
  className,
}) => {
  const {
    connectors: { connect },
    actions: {setProp},
  } = useNode();

  const { enabled } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));

  

  const styleProps = {
    ...(marginTop !== null && { marginTop: `${marginTop?.split(" ")?.join("")}` }),
    ...(marginRight !== null && { marginRight: `${marginRight?.split(" ")?.join("")}` }),
    ...(marginBottom !== null && { marginBottom: `${marginBottom?.split(" ")?.join("")}` }),
    ...(marginLeft !== null && { marginLeft: `${marginLeft?.split(" ")?.join("")}` }),
    ...(paddingTop !== null && { paddingTop: `${paddingTop?.split(" ")?.join("")}` }),
    ...(paddingRight !== null && { paddingRight: `${paddingRight?.split(" ")?.join("")}` }),
    ...(paddingBottom !== null && { paddingBottom: `${paddingBottom?.split(" ")?.join("")}` }),
    ...(paddingLeft !== null && { paddingLeft: `${paddingLeft?.split(" ")?.join("")}` }),
    ...(radius !== null && { borderRadius: `${radius?.split(" ")?.join("")}` }),
    ...(fontSize !== null && { fontSize: `${fontSize?.split(" ")?.join("")}` }),
    ...(shadow !== null && {
      textShadow: `0px 0px 2px rgba(0,0,0,${(shadow || 0) / 100})`,
    }),
    ...(fontWeight !== null && { fontWeight: fontWeight }),
    ...(textAlign !== null && { textAlign: textAlign }),
    ...(width !== null && { width: `${width?.split(" ")?.join("")}` }),
    ...(height !== null && { height: `${height?.split(" ")?.join("")}` }),
    ...(minWidth !== null && { minWidth: `${minWidth?.split(" ")?.join("")}` }),
    ...(maxWidth !== null && { maxWidth: `${maxWidth?.split(" ")?.join("")}` }),
  
  };

  const { state, dispatch: setActiveId } = useActiveIdContext();
  const { activeId: activeId } = state;

  return (
    <input
      ref={connect}
      value={inputValue}
      className={className}
      placeholder={placeholder}
      name={name}
      type={type}
      // readOnly={readonly === 'true' ? true : false}
      onChange={(e) => {
        setProp((prop) => (prop.inputValue = e.target.value), 500);
      }}
      style={{
        ...styleProps,
        ...style,
        cursor: 'auto',
      }}
    />
  );
};

Input.craft = {
  displayName: 'Input',
  props: {
    fontSize: null,
    textAlign: null,
    fontWeight: null,
    radius: null,
    placeholder: null,
    readonly:'false',
    inputValue: null,
    paddingTop: null,
    paddingRight: null,
    paddingBottom: null,
    paddingLeft: null,
    marginTop: null,
    marginRight: null,
    marginBottom: null,
    marginLeft: null,
    width: null,
    height: null,
    minWidth: null,
    maxWidth: null,
    isInput:true,
    name:null,
    type: null,
  },
  related: {
    toolbar: InputSettings,
  },
};