/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, { useEffect, useState } from 'react';
import { Element, useEditor, useNode } from '@craftjs/core';
import { Text } from '../../basic/Text';
import { NewFormSettings } from './newFormSetting';
import { TextField, Typography } from '@mui/material';
import './Form.css'
import { Button } from '../../basic/Button';
import { Parent } from '../../Parent/index.js';
import { Wrapper } from '../../wrapper/index.js';
import { BounceLoader } from 'react-spinners';
import { Input } from '../../basic/Input/Index.js';

export const Form_1 = ({ selected }) => {
    const {
        actions: { setProp },
        connectors: {
            connect
        }
    } = useNode();


    const {
        enabled,
    } = useEditor((state, query) => ({
        enabled: state.options.enabled,

    }));
    const styles = [

        <Element canvas is={Parent} id='parentId100' className="w-[100%] h-auto bg-[#FFFDFB] py-20" >
            <Element canvas is={Parent} id='parentId200' className="w-[100%] h-auto flex justify-center items-center" row={true} >
                <Element is={Parent} canvas id='random-id-401' className='max-w-[1300px]'>
                    <Element is={Parent} canvas id='random-id-402' className='w-[90%] h-full flex flex-col mx-auto' style={{ border: '1px solid #DBD9D9' }}>
                        <Element is={Parent} className='text-center text-[#100103] w-[300px] mx-auto pl-10 flex mb-10 justify-center' canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', fontFamily: 'Rubik' }} id='random-id-1'  >
                            <Text text='Simple form' />
                        </Element>

                        <Element is={Parent} canvas id='random-id-403'  >
                            <Element is={Parent} canvas id='random-id-404' className='w-full flex flex-row flex-wrap justify-center mx-auto my-4'>
                                <Element is={Parent} canvas id='random-id-405' className='w-[15%] min-w-[100px]'>
                                    <Element is={Parent} canvas id='random-id-406' className='w-full text-center text-[#000000] mx-auto flex flex-row justify-center' style={{ fontSize: '18px', fontWeight: '500', fontFamily: 'Rubik' }}>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block' }} id='random-id-3'  >
                                            <Text text='Name' />
                                        </Element>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block', color: '#B20312' }} id='random-id-4'  >
                                            <Text text='*' />
                                        </Element>
                                    </Element>
                                </Element>
                                <Element is={Parent} canvas id='random-id-407' className='w-[60%] min-w-[277px] my-auto'>
                                    <Input style={{ height: '50px', border: '1px solid #B2031233', fontSize: '14px', fontFamily: 'Rubik' }} type="text" name="name" placeholder="Your name" />
                                </Element>
                            </Element>

                            <Element is={Parent} canvas id='random-id-408' className='w-full flex flex-row flex-wrap justify-center mx-auto my-4'>
                                <Element is={Parent} canvas id='random-id-409' className='w-[15%] min-w-[100px]'>
                                    <Element is={Parent} canvas id='random-id-410' className='w-full text-center text-[#000000] mx-auto flex flex-row justify-center' style={{ fontSize: '18px', fontWeight: '500', fontFamily: 'Rubik' }}>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block' }} id='random-id-5'  >
                                            <Text text='Company' />
                                        </Element>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block', color: '#B20312' }} id='random-id-6'  >
                                            <Text text='*' />
                                        </Element>
                                    </Element>
                                </Element>
                                <Element is={Parent} canvas id='random-id-411' className='w-[60%] min-w-[277px] my-auto'>
                                    <Input style={{ height: '50px', border: '1px solid #B2031233', fontSize: '14px', fontFamily: 'Rubik' }} type="text" name="company" placeholder="Company" />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-412' className='w-full flex flex-row flex-wrap justify-center flex-wrap mx-auto my-4'>
                                <Element is={Parent} canvas id='random-id-413' className='w-[15%] min-w-[100px]'>
                                    <Element is={Parent} canvas id='random-id-414' className='w-full text-center text-[#000000] mx-auto flex flex-row justify-center' style={{ fontSize: '18px', fontWeight: '500', fontFamily: 'Rubik' }}>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block' }} id='random-id-7'  >
                                            <Text text='Email' />
                                        </Element>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block', color: '#B20312' }} id='random-id-8'  >
                                            <Text text='*' />
                                        </Element>
                                    </Element>
                                </Element>
                                <Element is={Parent} canvas id='random-id-415' className='w-[60%] min-w-[277px] my-auto'>
                                    <Input style={{ height: '50px', border: '1px solid #B2031233', fontSize: '14px', fontFamily: 'Rubik' }} type="text" name="email" placeholder="email@mail.com" />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-416' className='w-full flex flex-row flex-wrap justify-center flex-wrap mx-auto my-4'>
                                <Element is={Parent} canvas id='random-id-417' className='w-[15%] min-w-[100px]'>
                                    <Element is={Parent} canvas id='random-id-418' className='w-full text-center text-[#000000] mx-auto flex flex-row justify-center' style={{ fontSize: '18px', fontWeight: '500', fontFamily: 'Rubik' }}>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block' }} id='random-id-9'  >
                                            <Text text='Name' />
                                        </Element>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block', color: '#B20312' }} id='random-id-10'  >
                                            <Text text='*' />
                                        </Element>
                                    </Element>                             </Element>
                                <Element is={Parent} canvas id='random-id-419' className='w-[60%] min-w-[277px] my-auto'>
                                    <Input style={{ height: '50px', border: '1px solid #B2031233', fontSize: '14px', fontFamily: 'Rubik' }} type="text" name="phone" placeholder="(123) 456 78 90" />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-420' className='w-full flex flex-row flex-wrap justify-center flex-wrap mx-auto my-4'>
                                <Element is={Parent} canvas id='random-id-421' className='w-[15%] min-w-[100px]'>
                                    <Element is={Parent} canvas id='random-id-422' className='w-full text-center text-[#000000] mx-auto flex flex-row justify-center' style={{ fontSize: '18px', fontWeight: '500', fontFamily: 'Rubik' }}>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block' }} id='random-id-11'  >
                                            <Text text='Phone' />
                                        </Element>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block', color: '#B20312' }} id='random-id-12'  >
                                            <Text text='*' />
                                        </Element>
                                    </Element>                             </Element>
                                <Element is={Parent} canvas id='random-id-423' className='w-[60%] min-w-[277px] my-auto'>
                                    <Input style={{ height: '50px', paddingBottom: '170px', paddingTop: '20px', border: '1px solid #B2031233', fontSize: '14px', fontFamily: 'Rubik' }} type="text" name="Message" placeholder="Message.." />
                                </Element>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-200' className='mx-auto mb-4'>
                            <Button classn='block' class='uppercase w-[260px] flex h-[70px] bg-[#B20312] hover:bg-[#87000C] cursor-pointer text-[#FFFFFF]' >
                                <Element is={Parent} canvas id='random-id-1-1' className='mx-auto my-auto' style={{ display: 'inlineflex', fontSize: '22px', fontWeight: '600', fontFamily: 'Rubik' }}  >
                                    <Text text='Confirm' />
                                </Element>
                            </Button>
                        </Element>
                    </Element>
                </Element> </Element>
        </Element>,


        <Element canvas is={Parent} id='parentId300' className="w-[100%] h-auto bg-[#121826] py-20" >
            <Element canvas is={Parent} id='parentId400' className="w-[100%] h-auto flex justify-center items-center" row={true} >
                <Element is={Parent} canvas id='random-id-425' className='max-w-[1300px]'>
                    <Element is={Parent} canvas id='random-id-426' className='w-[90%] h-full flex flex-col mx-auto' style={{ border: '1px solid #FFFFFF1A', boxShadow: '0px 0px 4px 0px #FFFFFF1A' }}>
                        <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', fontFamily: 'Rubik' }} className='text-center IncreaseConversion2 w-[300px] mx-auto pl-10 flex mb-10' id='random-id-13'  >
                            <Text text='Simple form' />
                        </Element>
                        <Element is={Parent} canvas id='random-id-427' className='w-full'  >
                            <Element is={Parent} canvas id='random-id-428' className='w-full flex flex-row flex-wrap justify-center flex-wrap mx-auto my-4'>
                                <Element is={Parent} canvas id='random-id-429' className='w-[15%] min-w-[100px]'>
                                    <Element is={Parent} canvas id='random-id-430' className='w-full text-center text-[#FFFFFF] mx-auto flex flex-row justify-center' style={{ fontSize: '18px', fontWeight: '500', fontFamily: 'Rubik' }}>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block' }} id='random-id-14'  >
                                            <Text text='Name' />
                                        </Element>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block', color: '#4BDBA2' }} id='random-id-15'  >
                                            <Text text='*' />
                                        </Element>
                                    </Element>
                                </Element>
                                <Element is={Parent} canvas id='random-id-431' className='w-[60%] min-w-[277px] my-auto'>
                                    <Input className='inpute' style={{ color: '#FFFFFF', height: '50px', border: '1px solid #3265E366', fontSize: '14px', fontFamily: 'Rubik', background: 'transparent' }} type="text" name="name" placeholder="Your name" />
                                </Element>
                            </Element>

                            <Element is={Parent} canvas id='random-id-432' className='w-full flex flex-row flex-wrap justify-center flex-wrap mx-auto my-4'>
                                <Element is={Parent} canvas id='random-id-433' className='w-[15%] min-w-[100px]'>
                                    <Element is={Parent} canvas id='random-id-434' className='w-full text-center text-[#FFFFFF] mx-auto flex flex-row justify-center' style={{ fontSize: '18px', fontWeight: '500', fontFamily: 'Rubik' }}>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block' }} id='random-id-16'  >
                                            <Text text='Company' />
                                        </Element>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block', color: '#4BDBA2' }} id='random-id-17'  >
                                            <Text text='*' />
                                        </Element>
                                    </Element>
                                </Element>
                                <Element is={Parent} canvas id='random-id-435' className='w-[60%] min-w-[277px] my-auto'>
                                    <Input className='inpute' style={{ color: '#FFFFFF', height: '50px', border: '1px solid #3265E366', fontSize: '14px', fontFamily: 'Rubik', background: 'transparent' }} type="text" name="company" placeholder="Company" />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-436' className='w-full flex flex-row flex-wrap justify-center flex-wrap mx-auto my-4'>
                                <Element is={Parent} canvas id='random-id-437' className='w-[15%] min-w-[100px]'>
                                    <Element is={Parent} canvas id='random-id-438' className='w-full text-center text-[#FFFFFF] mx-auto flex flex-row justify-center' style={{ fontSize: '18px', fontWeight: '500', fontFamily: 'Rubik' }}>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block' }} id='random-id-18'  >
                                            <Text text='Email' />
                                        </Element>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block', color: '#4BDBA2' }} id='random-id-19'  >
                                            <Text text='*' />
                                        </Element>
                                    </Element>
                                </Element>
                                <Element is={Parent} canvas id='random-id-439' className='w-[60%] min-w-[277px] my-auto'>
                                    <Element is={Parent} canvas id='random-id-440' className='inpute' style={{ color: '#FFFFFF', height: '50px', border: '1px solid #3265E366', fontSize: '14px', fontFamily: 'Rubik', background: 'transparent' }} type="text" name="email" placeholder="email@mail.com" />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-441' className='w-full flex flex-row flex-wrap justify-center flex-wrap mx-auto my-4'>
                                <Element is={Parent} canvas id='random-id-442' className='w-[15%] min-w-[100px]'>
                                    <Element is={Parent} canvas id='random-id-443' className='w-full text-center text-[#FFFFFF] mx-auto flex flex-row justify-center' style={{ fontSize: '18px', fontWeight: '500', fontFamily: 'Rubik' }}>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block' }} id='random-id-20'  >
                                            <Text text='Phone' />
                                        </Element>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block', color: '#4BDBA2' }} id='random-id-21'  >
                                            <Text text='*' />
                                        </Element>
                                    </Element>
                                </Element>
                                <Element is={Parent} canvas id='random-id-444' className='w-[60%] min-w-[277px] my-auto'>
                                    <Input className='inpute' style={{ color: '#FFFFFF', height: '50px', border: '1px solid #3265E366', fontSize: '14px', fontFamily: 'Rubik', background: 'transparent' }} type="text" name="phone" placeholder="(123) 456 78 90" />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-445' className='w-full flex flex-row flex-wrap justify-center flex-wrap mx-auto my-4'>
                                <Element is={Parent} canvas id='random-id-446' className='w-[15%] min-w-[100px]'>
                                    <Element is={Parent} canvas id='random-id-447' className='w-full text-center text-[#FFFFFF] mx-auto flex flex-row justify-center' style={{ fontSize: '18px', fontWeight: '500', fontFamily: 'Rubik' }}>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block' }} id='random-id-22'  >
                                            <Text text='Message' />
                                        </Element>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block', color: '#4BDBA2' }} id='random-id-23'  >
                                            <Text text='*' />
                                        </Element>
                                    </Element>
                                </Element>
                                <Element is={Parent} canvas id='random-id-448' className='w-[60%] min-w-[277px] my-auto'>
                                    <Input className='inpute' style={{ color: '#FFFFFF', height: '50px', paddingBottom: '170px', paddingTop: '20px', border: '1px solid #3265E366', fontSize: '14px', background: 'transparent', fontFamily: 'Rubik' }} type="text" name="firstname" placeholder="Message.." />
                                </Element>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-201' className='mx-auto mb-4'>
                            <Button classn='block' class='uppercase w-[260px] flex h-[70px] cursor-pointer bg-[#3265E3] hover:bg-[#0049FF] text-[#FFFFFF]' style={{ border: '1px solid #FFFFFF1A', boxShadow: '0px 4px 10px 0px #FFFFFF0D' }}>
                                <Element is={Parent} canvas id='random-id-1-2' className='mx-auto my-auto' style={{ display: 'inlineflex', fontSize: '22px', fontWeight: '600', fontFamily: 'Rubik', }}  >
                                    <Text text='Confirm' />
                                </Element>

                            </Button>
                        </Element>
                    </Element>
                </Element> </Element>
        </Element>,




        <Element canvas is={Parent} id='parentId500' className="w-[100%] h-auto bg-[#213156] py-20" >
            <Element canvas is={Parent} id='parentId600' className="w-[100%] h-auto flex justify-center items-center" row={true} >
                <Element is={Parent} canvas id='random-id-450' className='max-w-[1300px]'>
                    <Element is={Parent} canvas id='random-id-451' className='w-[90%] h-full flex flex-col mx-auto' style={{ border: '1px solid #EA6EF41A', boxShadow: '0px 0px 4px 0px #FFFFFF1A' }}>
                        <Element is={Parent} className='text-center text-[#EA6EF4] w-[300px] mx-auto pl-10 flex mb-10' canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', fontFamily: 'Rubik' }} id='random-id-24'  >
                            <Text text='Simple form' />
                        </Element>

                        <Element is={Parent} canvas id='random-id-452' className='w-full'  >
                            <Element is={Parent} canvas id='random-id-453' className='w-full flex flex-row flex-wrap justify-center mx-auto flex-wrap my-4'>
                                <Element is={Parent} canvas id='random-id-454' className='w-[15%] min-w-[100px]'>

                                    <Element is={Parent} canvas id='random-id-455' className='w-full text-center text-[#FFFFFF] mx-auto flex flex-row justify-center' style={{ fontSize: '18px', fontWeight: '500', fontFamily: 'Open Sans' }}>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block' }} id='random-id-25'  >
                                            <Text text='Name' />
                                        </Element>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block', color: '#EA6EF4' }} id='random-id-26'  >
                                            <Text text='*' />
                                        </Element>
                                    </Element>
                                </Element>
                                <Element is={Parent} canvas id='random-id-456' className='w-[60%] min-w-[277px] my-auto'>
                                    <Input className='inpute' style={{ color: '#FFFFFF', height: '50px', border: '1px solid #EA6EF466', borderRadius: '10px', fontSize: '14px', fontFamily: 'Open Sans', background: 'transparent' }} type="text" name="name" placeholder="Your name" />
                                </Element>
                            </Element>

                            <Element is={Parent} canvas id='random-id-457' className='w-full flex flex-row flex-wrap justify-center mx-auto flex-wrap my-4'>
                                <Element is={Parent} canvas id='random-id-458' className='w-[15%] min-w-[100px]'>
                                    <Element is={Parent} canvas id='random-id-459' className='w-full text-center text-[#FFFFFF] mx-auto flex flex-row justify-center' style={{ fontSize: '18px', fontWeight: '500', fontFamily: 'Open Sans' }}>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block' }} id='random-id-27'  >
                                            <Text text='Company' />
                                        </Element>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block', color: '#EA6EF4' }} id='random-id-28'  >
                                            <Text text='*' />
                                        </Element>
                                    </Element>                      </Element>
                                <Element is={Parent} canvas id='random-id-460' className='w-[60%] min-w-[277px] my-auto'>
                                    <Input className='inpute' style={{ color: '#FFFFFF', height: '50px', border: '1px solid #EA6EF466', borderRadius: '10px', fontSize: '14px', fontFamily: 'Open Sans', background: 'transparent' }} type="text" name="company" placeholder="Company" />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-461' className='w-full flex flex-row flex-wrap justify-center mx-auto flex-wrap my-4'>
                                <Element is={Parent} canvas id='random-id-462' className='w-[15%] min-w-[100px]'>
                                    <Element is={Parent} canvas id='random-id-463' className='w-full text-center text-[#FFFFFF] mx-auto flex flex-row justify-center' style={{ fontSize: '18px', fontWeight: '500', fontFamily: 'Open Sans' }}>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block' }} id='random-id-29'  >
                                            <Text text='Email' />
                                        </Element>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block', color: '#EA6EF4' }} id='random-id-30'  >
                                            <Text text='*' />
                                        </Element>
                                    </Element>
                                </Element>
                                <Element is={Parent} canvas id='random-id-464' className='w-[60%] min-w-[277px] my-auto'>
                                    <Input className='inpute' style={{ color: '#FFFFFF', height: '50px', border: '1px solid #EA6EF466', borderRadius: '10px', fontSize: '14px', fontFamily: 'Open Sans', background: 'transparent' }} type="text" name="email" placeholder="email@mail.com" />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-465' className='w-full flex flex-row flex-wrap justify-center mx-auto flex-wrap my-4'>
                                <Element is={Parent} canvas id='random-id-466' className='w-[15%] min-w-[100px]'>
                                    <Element is={Parent} canvas id='random-id-467' className='w-full text-center text-[#FFFFFF] mx-auto flex flex-row justify-center' style={{ fontSize: '18px', fontWeight: '500', fontFamily: 'Open Sans' }}>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block' }} id='random-id-31'  >
                                            <Text text='Phone' />
                                        </Element>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block', color: '#EA6EF4' }} id='random-id-32'  >
                                            <Text text='*' />
                                        </Element>
                                    </Element>
                                </Element>
                                <Element is={Parent} canvas id='random-id-468' className='w-[60%] min-w-[277px] my-auto'>
                                    <Input className='inpute' style={{ color: '#FFFFFF', height: '50px', border: '1px solid #EA6EF466', borderRadius: '10px', fontSize: '14px', fontFamily: 'Open Sans', background: 'transparent' }} type="text" name="phone" placeholder="(123) 456 78 90" />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-469' className='w-full flex flex-row flex-wrap justify-center mx-auto flex-wrap my-4'>
                                <Element is={Parent} canvas id='random-id-470' className='w-[15%] min-w-[100px]'>
                                    <Element is={Parent} canvas id='random-id-471' className='w-full text-center text-[#FFFFFF] mx-auto flex flex-row justify-center' style={{ fontSize: '18px', fontWeight: '500', fontFamily: 'Open Sans' }}>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block' }} id='random-id-33'  >
                                            <Text text='Message' />
                                        </Element>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block', color: '#EA6EF4' }} id='random-id-34'  >
                                            <Text text='*' />
                                        </Element>
                                    </Element>
                                </Element>
                                <Element is={Parent} canvas id='random-id-472' className='w-[60%] min-w-[277px] my-auto'>
                                    <Input className='inpute' style={{ color: '#FFFFFF', height: '50px', paddingBottom: '170px', paddingTop: '20px', border: '1px solid #EA6EF466', borderRadius: '10px', fontSize: '14px', background: 'transparent', fontFamily: 'Open Sans' }} type="text" name="firstname" placeholder="Message.." />
                                </Element>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-202' className='mx-auto mb-4'>
                            <Button classn='block' class='uppercase cursor-pointer w-[260px] flex h-[70px] bg-[#EA6EF4] hover:bg-[#E111F2] text-[#FFFFFF]' style={{ border: '1px solid #213156', borderRadius: '40px', boxShadow: '0px 4px 10px 0px #FFFFFF0D' }}>
                                <Element is={Parent} canvas id='random-id-1-3' className='mx-auto my-auto' style={{ display: 'inlineflex', fontSize: '22px', fontWeight: '600', fontFamily: 'Open Sans', }}  >
                                    <Text text='Confirm' />
                                </Element>
                            </Button>
                        </Element>
                    </Element>
                </Element> </Element>
        </Element>,



        <Element canvas is={Parent} id='parentId700' className="w-[100%] h-auto bg-[#DFFFF9] py-20" >
            <Element canvas is={Parent} id='parentId800' className="w-[100%] h-auto flex justify-center items-center" row={true} >
                <Element is={Parent} canvas id='random-id-474' className='max-w-[1300px]' >
                    <Element is={Parent} canvas id='random-id-475' className='w-[90%] h-full flex flex-col mx-auto' style={{ border: '1px solid #31A9931A', borderRadius: '30px' }}>
                        <Element is={Parent} className='text-center text-[#31A993] w-[300px] mx-auto pl-10 flex mb-10' canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: '100px', fontFamily: 'Zing Script Rust SemiBold Demo', fontStyle: 'italic' }} id='random-id-35'  >
                            <Text text='Simple form' />
                        </Element>

                        <Element is={Parent} canvas id='random-id-476' className='w-full'  >
                            <Element is={Parent} canvas id='random-id-477' className='w-full flex flex-row flex-wrap justify-center mx-auto flex-wrap my-4'>
                                <Element is={Parent} canvas id='random-id-478' className='w-[15%] min-w-[100px]'>

                                    <Element is={Parent} canvas id='random-id-479' className='w-full text-center text-[#000000] mx-auto flex flex-row justify-center' style={{ fontSize: '18px', fontWeight: '500', fontFamily: 'Montserrat' }}>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block' }} id='random-id-36'  >
                                            <Text text='Name' />
                                        </Element>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block', color: '#31A993' }} id='random-id-37'  >
                                            <Text text='*' />
                                        </Element>
                                    </Element>
                                </Element>
                                <Element is={Parent} canvas id='random-id-480' className='w-[60%] min-w-[277px] my-auto'>
                                    <Input className='input-4' style={{ color: '#000000', height: '50px', border: '1px solid #31A99366', borderRadius: '30px', fontSize: '14px', fontFamily: 'Montserrat', background: 'transparent' }} type="text" name="name" placeholder="Your name" />
                                </Element>
                            </Element>

                            <Element is={Parent} canvas id='random-id-481' className='w-full flex flex-row flex-wrap justify-center mx-auto flex-wrap my-4'>
                                <Element is={Parent} canvas id='random-id-482' className='w-[15%] min-w-[100px]'>
                                    <Element is={Parent} canvas id='random-id-483' className='w-full text-center text-[#000000] mx-auto flex flex-row justify-center' style={{ fontSize: '18px', fontWeight: '500', fontFamily: 'Montserrat' }}>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block' }} id='random-id-38'  >
                                            <Text text='Company' />
                                        </Element>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block', color: '#31A993' }} id='random-id-39'  >
                                            <Text text='*' />
                                        </Element>
                                    </Element>                      </Element>
                                <Element is={Parent} canvas id='random-id-484' className='w-[60%] min-w-[277px] my-auto'>
                                    <Input className='input-4' style={{ color: '#000000', height: '50px', border: '1px solid #31A99366', borderRadius: '30px', fontSize: '14px', fontFamily: 'Montserrat', background: 'transparent' }} type="text" name="company" placeholder="Company" />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-485' className='w-full flex flex-row flex-wrap justify-center mx-auto flex-wrap my-4'>
                                <Element is={Parent} canvas id='random-id-486' className='w-[15%] min-w-[100px]'>
                                    <Element is={Parent} canvas id='random-id-487' className='w-full text-center text-[#000000] mx-auto flex flex-row justify-center' style={{ fontSize: '18px', fontWeight: '500', fontFamily: 'Montserrat' }}>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block' }} id='random-id-40'  >
                                            <Text text='Email' />
                                        </Element>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block', color: '#31A993' }} id='random-id-41'  >
                                            <Text text='*' />
                                        </Element>
                                    </Element>
                                </Element>
                                <Element is={Parent} canvas id='random-id-488' className='w-[60%] min-w-[277px] my-auto'>
                                    <Input className='input-4' style={{ color: '#000000', height: '50px', border: '1px solid #31A99366', borderRadius: '30px', fontSize: '14px', fontFamily: 'Montserrat', background: 'transparent' }} type="text" name="email" placeholder="email@mail.com" />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-489' className='w-full flex flex-row flex-wrap justify-center mx-auto flex-wrap my-4'>
                                <Element is={Parent} canvas id='random-id-490' className='w-[15%] min-w-[100px]'>
                                    <Element is={Parent} canvas id='random-id-491' className='w-full text-center text-[#000000] mx-auto flex flex-row justify-center' style={{ fontSize: '18px', fontWeight: '500', fontFamily: 'Montserrat' }}>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block' }} id='random-id-42'  >
                                            <Text text='Phone' />
                                        </Element>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block', color: '#31A993' }} id='random-id-43'  >
                                            <Text text='*' />
                                        </Element>
                                    </Element>
                                </Element>
                                <Element is={Parent} canvas id='random-id-492' className='w-[60%] min-w-[277px] my-auto'>
                                    <Input className='input-4' style={{ color: '#000000', height: '50px', border: '1px solid #31A99366', borderRadius: '30px', fontSize: '14px', fontFamily: 'Montserrat', background: 'transparent' }} type="text" name="phone" placeholder="(123) 456 78 90" />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-493' className='w-full flex flex-row flex-wrap justify-center mx-auto flex-wrap my-4'>
                                <Element is={Parent} canvas id='random-id-494' className='w-[15%] min-w-[100px]'>
                                    <Element is={Parent} canvas id='random-id-495' className='w-full text-center text-[#000000] mx-auto flex flex-row justify-center' style={{ fontSize: '18px', fontWeight: '500', fontFamily: 'Montserrat' }}>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block' }} id='random-id-44'  >
                                            <Text text='Message' />
                                        </Element>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block', color: '#31A993' }} id='random-id-45'  >
                                            <Text text='*' />
                                        </Element>
                                    </Element>
                                </Element>
                                <Element is={Parent} canvas id='random-id-496' className='w-[60%] min-w-[277px] my-auto'>
                                    <Input className='input-4' style={{ color: '#000000', height: '50px', paddingBottom: '170px', paddingTop: '20px', border: '1px solid #31A99366', borderRadius: '30px', fontSize: '14px', background: 'transparent', fontFamily: 'Montserrat' }} type="text" name="firstname" placeholder="Message.." />
                                </Element>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-203' className='mx-auto mb-4'>
                            <Button classn='block' class='uppercase cursor-pointer w-[260px] flex h-[70px] bg-[#31a993] hover:bg-[#11816C] text-[#FFFFFF]' style={{ border: '1px solid #31A993', borderRadius: '30px' }}>
                                <Element is={Parent} canvas id='random-id-1-4' className='mx-auto my-auto =' style={{ display: 'inlineflex', fontSize: '22px', fontWeight: '600', fontFamily: 'Montserrat', }}  >
                                    <Text text='Confirm' />
                                </Element>
                            </Button>
                        </Element>
                    </Element>
                </Element> </Element>
        </Element>,




        <Element canvas is={Parent} id='parentId900' className="w-[100%] h-auto bg-[#FFFFFF] py-20" >
            <Element canvas is={Parent} id='parentId1000' className="w-[100%] h-auto flex justify-center items-center" row={true} >
                <Element is={Parent} canvas id='random-id-498' className='max-w-[1300px]' >
                    <Element is={Parent} canvas id='random-id-499' className='w-[90%] h-full flex flex-col mx-auto' style={{ border: '1px solid #FFFFFF1A', boxShadow: '0px 0px 4px 0px #FFFFFF1A' }}>
                        <Element is={Parent} className='text-center text-[#0078D3] w-[300px] mx-auto pl-10 flex mb-10' canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', fontFamily: 'Inter' }} id='random-id-46'  >
                            <Text text='Simple form' />
                        </Element>
                        <Element is={Parent} canvas id='random-id-500' className='w-full'  >
                            <Element is={Parent} canvas id='random-id-501' className='w-full flex flex-row flex-wrap justify-center mx-auto flex-wrap my-4'>
                                <Element is={Parent} canvas id='random-id-502' className='w-[15%] min-w-[100px]'>
                                    <Element is={Parent} canvas id='random-id-503' className='w-full text-center text-[#000000] mx-auto flex flex-row justify-center' style={{ fontSize: '18px', fontWeight: '500', fontFamily: 'Inter' }}>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block' }} id='random-id-47'  >
                                            <Text text='Name' />
                                        </Element>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block', color: '#0078D3' }} id='random-id-48'  >
                                            <Text text='*' />
                                        </Element>
                                    </Element>
                                </Element>
                                <Element is={Parent} canvas id='random-id-504' className='w-[60%] min-w-[277px] my-auto'>
                                    <Input className='input-5' style={{ color: '#FFFFFF', height: '50px', border: '1px solid #0078D366', borderRadius: '4px', fontSize: '14px', fontFamily: 'Inter', background: 'transparent' }} type="text" name="name" placeholder="Your name" />
                                </Element>
                            </Element>

                            <Element is={Parent} canvas id='random-id-505' className='w-full flex flex-row flex-wrap justify-center mx-auto flex-wrap my-4'>
                                <Element is={Parent} canvas id='random-id-506' className='w-[15%] min-w-[100px]'>
                                    <Element is={Parent} canvas id='random-id-507' className='w-full text-center text-[#000000] mx-auto flex flex-row justify-center' style={{ fontSize: '18px', fontWeight: '500', fontFamily: 'Inter' }}>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block' }} id='random-id-49'  >
                                            <Text text='Company' />
                                        </Element>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block', color: '#0078D3' }} id='random-id-50'  >
                                            <Text text='*' />
                                        </Element>
                                    </Element>                      </Element>
                                <Element is={Parent} canvas id='random-id-508' className='w-[60%] min-w-[277px] my-auto'>
                                    <Input className='input-5' style={{ color: '#FFFFFF', height: '50px', border: '1px solid #0078D366', borderRadius: '4px', fontSize: '14px', fontFamily: 'Inter', background: 'transparent' }} type="text" name="company" placeholder="Company" />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-509' className='w-full flex flex-row flex-wrap justify-center mx-auto flex-wrap my-4'>
                                <Element is={Parent} canvas id='random-id-510' className='w-[15%] min-w-[100px]'>
                                    <Element is={Parent} canvas id='random-id-511' className='w-full text-center text-[#000000] mx-auto flex flex-row justify-center' style={{ fontSize: '18px', fontWeight: '500', fontFamily: 'Inter' }}>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block' }} id='random-id-51'  >
                                            <Text text='Email' />
                                        </Element>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block', color: '#0078D3' }} id='random-id-52'  >
                                            <Text text='*' />
                                        </Element>
                                    </Element>                      </Element>
                                <Element is={Parent} canvas id='random-id-512' className='w-[60%] min-w-[277px] my-auto'>
                                    <Input className='input-5' style={{ color: '#FFFFFF', height: '50px', border: '1px solid #0078D366', borderRadius: '4px', fontSize: '14px', fontFamily: 'Inter', background: 'transparent' }} type="text" name="email" placeholder="email@mail.com" />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-513' className='w-full flex flex-row flex-wrap justify-center mx-auto flex-wrap my-4'>
                                <Element is={Parent} canvas id='random-id-514' className='w-[15%] min-w-[100px]'>
                                    <Element is={Parent} canvas id='random-id-515' className='w-full text-center text-[#000000] mx-auto flex flex-row justify-center' style={{ fontSize: '18px', fontWeight: '500', fontFamily: 'Inter' }}>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block' }} id='random-id-53'  >
                                            <Text text='Phone' />
                                        </Element>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block', color: '#0078D3' }} id='random-id-54'  >
                                            <Text text='*' />
                                        </Element>
                                    </Element>                      </Element>
                                <Element is={Parent} canvas id='random-id-516' className='w-[60%] min-w-[277px] my-auto'>
                                    <Input className='input-5' style={{ color: '#FFFFFF', height: '50px', border: '1px solid #0078D366', borderRadius: '4px', fontSize: '14px', fontFamily: 'Inter', background: 'transparent' }} type="text" name="phone" placeholder="(123) 456 78 90" />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-517' className='w-full flex flex-row flex-wrap justify-center mx-auto flex-wrap my-4'>
                                <Element is={Parent} canvas id='random-id-518' className='w-[15%] min-w-[100px]'>
                                    <Element is={Parent} canvas id='random-id-519' className='w-full text-center text-[#000000] mx-auto flex flex-row justify-center' style={{ fontSize: '18px', fontWeight: '500', fontFamily: 'Inter' }}>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block' }} id='random-id-55'  >
                                            <Text text='Message' />
                                        </Element>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block', color: '#0078D3' }} id='random-id-56'  >
                                            <Text text='*' />
                                        </Element>
                                    </Element>                      </Element>
                                <Element is={Parent} canvas id='random-id-520' className='w-[60%] min-w-[277px] my-auto'>
                                    <Input className='input-5' style={{ color: '#FFFFFF', height: '50px', paddingBottom: '170px', paddingTop: '20px', border: '1px solid #0078D366', borderRadius: '4px', fontSize: '14px', background: 'transparent', fontFamily: 'Inter' }} type="text" name="firstname" placeholder="Message.." />
                                </Element>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-204' className='mx-auto mb-4'>
                            <Button classn='block' class='uppercase cursor-pointer w-[260px] flex h-[70px] bg-[#0078d3] hover:bg-[#11578B] text-[#FFFFFF]' style={{ boxShadow: '0px 4px 10px 0px #0000001A', borderRadius: '4px' }}>
                                <Element is={Parent} canvas id='random-id-1-5' className='mx-auto my-auto' style={{ display: 'inlineflex', fontSize: '22px', fontWeight: '600', fontFamily: 'Inter', }}  >
                                    <Text text='Confirm' />
                                </Element>
                            </Button>
                        </Element>
                    </Element>
                </Element> </Element>
        </Element>,

        <Element canvas is={Parent} id='parentId1100' className="w-[100%] h-auto bg-[#FFFDFB] py-20" >
            <Element canvas is={Parent} id='parentId1200' className="w-[100%] h-auto flex justify-center items-center" row={true} >
                <Element is={Parent} canvas id='random-id-522' className='max-w-[1300px]'>
                    <Element is={Parent} canvas id='random-id-523' className='w-[90%] h-full flex flex-col mx-auto bg-[#F8F8F8]' style={{ border: '1px solid #0202120A', boxShadow: '0px 0px 4px 0px #FFFFFF1A' }}>
                        <Element is={Parent} className='text-center text-[#020212] w-[300px] mx-auto pl-10 flex mb-10' canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', fontFamily: 'Voces' }} id='random-id-57'  >
                            <Text text='Simple form' />
                        </Element>
                        <Element is={Parent} canvas id='random-id-524' className='w-full'  >
                            <Element is={Parent} canvas id='random-id-525' className='w-full flex flex-row flex-wrap justify-center mx-auto flex-wrap my-4'>
                                <Element is={Parent} canvas id='random-id-526' className='w-[15%] min-w-[100px]'>
                                    <Element is={Parent} canvas id='random-id-527' className='w-full text-center text-[#020212] justify-center' style={{ fontSize: '18px', fontWeight: '500', fontFamily: 'Voces' }}>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block' }} id='random-id-58'  >
                                            <Text text='Name*' />
                                        </Element>

                                    </Element>
                                </Element>
                                <Element is={Parent} canvas id='random-id-528' className='w-[60%] min-w-[277px] my-auto'>
                                    <Input className='input-6' style={{ color: '#020212', height: '50px', border: '1px solid #02021233', fontSize: '14px', fontFamily: 'Voces', background: 'transparent' }} type="text" name="name" placeholder="Your name" />
                                </Element>
                            </Element>

                            <Element is={Parent} canvas id='random-id-529' className='w-full flex flex-row flex-wrap justify-center mx-auto flex-wrap my-4'>
                                <Element is={Parent} canvas id='random-id-530' className='w-[15%] min-w-[100px]'>
                                    <Element is={Parent} canvas id='random-id-531' className='w-full text-center text-[#020212] justify-center' style={{ fontSize: '18px', fontWeight: '500', fontFamily: 'Voces' }}>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block' }} id='random-id-59'  >
                                            <Text text='Company*' />
                                        </Element>

                                    </Element>                      </Element>
                                <Element is={Parent} canvas id='random-id-532' className='w-[60%] min-w-[277px] my-auto'>
                                    <Input className='input-6' style={{ color: '#020212', height: '50px', border: '1px solid #02021233', fontSize: '14px', fontFamily: 'Voces', background: 'transparent' }} type="text" name="company" placeholder="Company" />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-533' className='w-full flex flex-row flex-wrap justify-center mx-auto flex-wrap my-4'>
                                <Element is={Parent} canvas id='random-id-534' className='w-[15%] min-w-[100px]'>
                                    <Element is={Parent} canvas id='random-id-535' className='w-full text-center text-[#020212] justify-center' style={{ fontSize: '18px', fontWeight: '500', fontFamily: 'Voces' }}>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block' }} id='random-id-60'  >
                                            <Text text='Email*' />
                                        </Element>

                                    </Element>                      </Element>
                                <Element is={Parent} canvas id='random-id-536' className='w-[60%] min-w-[277px] my-auto'>
                                    <Input className='input-6' style={{ color: '#020212', height: '50px', border: '1px solid #02021233', fontSize: '14px', fontFamily: 'Voces', background: 'transparent' }} type="text" name="email" placeholder="email@mail.com" />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-537' className='w-full flex flex-row flex-wrap justify-center mx-auto flex-wrap my-4'>
                                <Element is={Parent} canvas id='random-id-538' className='w-[15%] min-w-[100px]'>
                                    <Element is={Parent} canvas id='random-id-539' className='w-full text-center text-[#020212] justify-center' style={{ fontSize: '18px', fontWeight: '500', fontFamily: 'Voces' }}>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block' }} id='random-id-61'  >
                                            <Text text='Phone*' />
                                        </Element>
                                    </Element>                      </Element>
                                <Element is={Parent} canvas id='random-id-540' className='w-[60%] min-w-[277px] my-auto'>
                                    <Input className='input-6' style={{ color: '#020212', height: '50px', border: '1px solid #02021233', fontSize: '14px', fontFamily: 'Voces', background: 'transparent' }} type="text" name="phone" placeholder="(123) 456 78 90" />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-541' className='w-full flex flex-row flex-wrap justify-center mx-auto flex-wrap my-4'>
                                <Element is={Parent} canvas id='random-id-542' className='w-[15%] min-w-[100px]'>
                                    <Element is={Parent} canvas id='random-id-543' className='w-full text-center text-[#020212] justify-center' style={{ fontSize: '18px', fontWeight: '500', fontFamily: 'Voces' }}>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block' }} id='random-id-62'  >
                                            <Text text='Message*' />
                                        </Element>
                                    </Element>                      </Element>
                                <Element is={Parent} canvas id='random-id-544' className='w-[60%] min-w-[277px] my-auto'>
                                    <Input className='input-6' style={{ color: '#020212', height: '50px', paddingBottom: '170px', paddingTop: '20px', border: '1px solid #02021233', fontSize: '14px', background: 'transparent', fontFamily: 'Voces' }} type="text" name="firstname" placeholder="Message.." />
                                </Element>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-205' className='mx-auto mb-4'>
                            <Button classn='block' class='uppercase cursor-pointer w-[280px] flex h-[60px] bg-[#FFFFFFA1] hover:bg-[#020212] text-[#000000] hover:text-[#FFFFFF]' style={{ borderRadius: '10px', border: '1px solid #020212' }}>
                                <Element is={Parent} canvas id='random-id-1-6' className='mx-auto my-auto' style={{ display: 'inlineflex', fontSize: '22px', fontWeight: '600', fontFamily: 'Voces', }}  >
                                    <Text text='Confirm' />
                                </Element>
                            </Button>
                        </Element>
                    </Element>
                </Element> </Element>
        </Element>,


        <Element canvas is={Parent} id='parentId1300' className="w-[100%] h-auto bg-[#FBFAFF] py-20" >
            <Element canvas is={Parent} id='parentId1400' className="w-[100%] h-auto flex justify-center items-center" row={true} >
                <Element is={Parent} canvas id='random-id-546' className='max-w-[1300px]'>
                    <Element is={Parent} canvas id='random-id-547' className='w-[90%] h-full flex flex-col mx-auto bg-[#D0BCFF66]' style={{ border: '1px solid #D0BCFF66', borderRadius: '30px' }}>
                        <Element is={Parent} className='text-[#6750A4] w-[300px] mx-auto pl-10 flex mb-10 text-center' canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', fontFamily: 'Roboto' }} id='random-id-63'  >
                            <Text text='Simple form' />
                        </Element>
                        <Element is={Parent} canvas id='random-id-548' className='w-full'  >
                            <Element is={Parent} canvas id='random-id-549' className='w-full flex flex-row flex-wrap justify-center mx-auto flex-wrap my-4'>
                                <Element is={Parent} canvas id='random-id-550' className='w-[15%] min-w-[100px]'>
                                    <Element is={Parent} canvas id='random-id-551' className='w-full text-center text-[#100103] justify-center' style={{ fontSize: '18px', fontWeight: '500', fontFamily: 'Roboto' }}>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block' }} id='random-id-64'  >
                                            <Text text='Name' />
                                        </Element>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block', color: '#6750A4' }} id='random-id-65'  >
                                            <Text text='*' />
                                        </Element>
                                    </Element>
                                </Element>
                                <Element is={Parent} canvas id='random-id-552' className='w-[60%] min-w-[277px] my-auto'>
                                    <Input className='input-7' style={{ color: '#100103', height: '50px', border: '1px solid #D0BCFF', borderRadius: '30px', fontSize: '14px', fontFamily: 'Roboto', background: 'transparent' }} type="text" name="name" placeholder="Your name" />
                                </Element>
                            </Element>

                            <Element is={Parent} canvas id='random-id-553' className='w-full flex flex-row flex-wrap justify-center mx-auto flex-wrap my-4'>
                                <Element is={Parent} canvas id='random-id-554' className='w-[15%] min-w-[100px]'>
                                    <Element is={Parent} canvas id='random-id-555' className='w-full text-center text-[#100103] justify-center' style={{ fontSize: '18px', fontWeight: '500', fontFamily: 'Roboto' }}>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block' }} id='random-id-66'  >
                                            <Text text='Company' />
                                        </Element>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block', color: '#6750A4' }} id='random-id-67'  >
                                            <Text text='*' />
                                        </Element>
                                    </Element>                      </Element>
                                <Element is={Parent} canvas id='random-id-556' className='w-[60%] min-w-[277px] my-auto'>
                                    <Input className='input-7' style={{ color: '#100103', height: '50px', border: '1px solid #D0BCFF', borderRadius: '30px', fontSize: '14px', fontFamily: 'Roboto', background: 'transparent' }} type="text" name="company" placeholder="Company" />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-557' className='w-full flex flex-row flex-wrap justify-center mx-auto flex-wrap my-4'>
                                <Element is={Parent} canvas id='random-id-558' className='w-[15%] min-w-[100px]'>
                                    <Element is={Parent} canvas id='random-id-559' className='w-full text-center text-[#100103] justify-center' style={{ fontSize: '18px', fontWeight: '500', fontFamily: 'Roboto' }}>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block' }} id='random-id-68'  >
                                            <Text text='Email' />
                                        </Element>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block', color: '#6750A4' }} id='random-id-69'  >
                                            <Text text='*' />
                                        </Element>
                                    </Element>                      </Element>
                                <Element is={Parent} canvas id='random-id-560' className='w-[60%] min-w-[277px] my-auto'>
                                    <Input className='input-7' style={{ color: '#100103', height: '50px', border: '1px solid #D0BCFF', borderRadius: '30px', fontSize: '14px', fontFamily: 'Roboto', background: 'transparent' }} type="text" name="email" placeholder="email@mail.com" />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-561' className='w-full flex flex-row flex-wrap justify-center mx-auto flex-wrap my-4'>
                                <Element is={Parent} canvas id='random-id-562' className='w-[15%] min-w-[100px]'>
                                    <Element is={Parent} canvas id='random-id-563' className='w-full text-center text-[#100103] justify-center' style={{ fontSize: '18px', fontWeight: '500', fontFamily: 'Roboto' }}>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block' }} id='random-id-70'  >
                                            <Text text='Phone' />
                                        </Element>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block', color: '#6750A4' }} id='random-id-71'  >
                                            <Text text='*' />
                                        </Element>
                                    </Element>                      </Element>
                                <Element is={Parent} canvas id='random-id-564' className='w-[60%] min-w-[277px] my-auto'>
                                    <Input className='input-7' style={{ color: '#100103', height: '50px', border: '1px solid #D0BCFF', borderRadius: '30px', fontSize: '14px', fontFamily: 'Roboto', background: 'transparent' }} type="text" name="phone" placeholder="(123) 456 78 90" />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-565' className='w-full flex flex-row flex-wrap justify-center mx-auto flex-wrap my-4'>
                                <Element is={Parent} canvas id='random-id-566' className='w-[15%] min-w-[100px]'>
                                    <Element is={Parent} canvas id='random-id-567' className='w-full text-center text-[#100103] justify-center' style={{ fontSize: '18px', fontWeight: '500', fontFamily: 'Roboto' }}>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block' }} id='random-id-72'  >
                                            <Text text='Message' />
                                        </Element>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block', color: '#6750A4' }} id='random-id-73'  >
                                            <Text text='*' />
                                        </Element>
                                    </Element>                      </Element>
                                <Element is={Parent} canvas id='random-id-568' className='w-[60%] min-w-[277px] my-auto'>
                                    <Input className='input-7' style={{ color: '#100103', height: '50px', paddingBottom: '170px', paddingTop: '20px', border: '1px solid #D0BCFF', borderRadius: '30px', fontSize: '14px', background: 'transparent', fontFamily: 'Roboto' }} type="text" name="firstname" placeholder="Message.." />
                                </Element>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-206' className='mx-auto mb-4'>
                            <Button classn='block' class='uppercase cursor-pointer w-[220px] flex mx-auto h-[50px] bg-[#D0BCFF] border-1 hover:border-[#6750A4] text-[#6750A4]' style={{ borderRadius: '100px' }}>
                                <Element is={Parent} canvas id='random-id-1-7' className='mx-auto my-auto' style={{ fontSize: '18px', fontWeight: 'bold', fontFamily: 'Roboto', }}  >
                                    <Text text='Confirm' />
                                </Element>
                            </Button>
                        </Element>
                    </Element>
                </Element> </Element>
        </Element>,




        <Element canvas is={Parent} id='parentId1500' className="w-[100%] h-auto bg-[#2A2A2A] py-20" >
            <Element canvas is={Parent} id='parentId1600' className="w-[100%] h-auto flex justify-center items-center" row={true} >
                <Element is={Parent} canvas id='random-id-570' className='max-w-[1300px]'>
                    <Element is={Parent} canvas id='random-id-571' className='w-[90%] h-full flex flex-col mx-auto' style={{ border: '1px solid #FFD6001A', boxShadow: '0px 0px 10px 0px #FFFFFF0F' }}>
                        <Element is={Parent} className='text-center text-[#FFD600] w-[300px] mx-auto pl-10 flex mb-10' canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: '400', fontFamily: 'Rum Raisin' }} id='random-id-74'  >
                            <Text text='Simple form' />
                        </Element>
                        <Element is={Parent} canvas id='random-id-572' className='w-full'  >
                            <Element is={Parent} canvas id='random-id-573' className='w-full flex flex-row flex-wrap justify-center mx-auto flex-wrap my-4'>
                                <Element is={Parent} canvas id='random-id-574' className='w-[15%] min-w-[100px]'>
                                    <Element is={Parent} canvas id='random-id-575' className='w-full text-center text-[#FFFFFF] mx-auto flex flex-row justify-center' style={{ fontSize: '18px', fontWeight: '500', fontFamily: 'Rubik' }}>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block' }} id='random-id-75'  >
                                            <Text text='Name' />
                                        </Element>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block', color: '#FFD600' }} id='random-id-76'  >
                                            <Text text='*' />
                                        </Element>
                                    </Element>
                                </Element>
                                <Element is={Parent} canvas id='random-id-576' className='w-[60%] min-w-[277px] my-auto'>
                                    <Input className='input-8' style={{ color: '#FFFFFF', height: '50px', border: '1px solid #FFD60099', borderRadius: '10px', fontSize: '14px', fontFamily: 'Rubik', background: 'transparent' }} type="text" name="name" placeholder="Your name" />
                                </Element>
                            </Element>

                            <Element is={Parent} canvas id='random-id-577' className='w-full flex flex-row flex-wrap justify-center mx-auto flex-wrap my-4'>
                                <Element is={Parent} canvas id='random-id-578' className='w-[15%] min-w-[100px]'>
                                    <Element is={Parent} canvas id='random-id-579' className='w-full text-center text-[#FFFFFF] mx-auto flex flex-row justify-center' style={{ fontSize: '18px', fontWeight: '500', fontFamily: 'Rubik' }}>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block' }} id='random-id-77'  >
                                            <Text text='Company' />
                                        </Element>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block', color: '#FFD600' }} id='random-id-78'  >
                                            <Text text='*' />
                                        </Element>
                                    </Element>                      </Element>
                                <Element is={Parent} canvas id='random-id-580' className='w-[60%] min-w-[277px] my-auto'>
                                    <Input className='input-8' style={{ color: '#FFFFFF', height: '50px', border: '1px solid #FFD60099', borderRadius: '10px', fontSize: '14px', fontFamily: 'Rubik', background: 'transparent' }} type="text" name="company" placeholder="Company" />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-581' className='w-full flex flex-row flex-wrap justify-center mx-auto flex-wrap my-4'>
                                <Element is={Parent} canvas id='random-id-582' className='w-[15%] min-w-[100px]'>
                                    <Element is={Parent} canvas id='random-id-583' className='w-full text-center text-[#FFFFFF] mx-auto flex flex-row justify-center' style={{ fontSize: '18px', fontWeight: '500', fontFamily: 'Rubik' }}>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block' }} id='random-id-79'  >
                                            <Text text='Email' />
                                        </Element>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block', color: '#FFD600' }} id='random-id-80'  >
                                            <Text text='*' />
                                        </Element>
                                    </Element>                      </Element>
                                <Element is={Parent} canvas id='random-id-584' className='w-[60%] min-w-[277px] my-auto'>
                                    <Input className='input-8' style={{ color: '#FFFFFF', height: '50px', border: '1px solid #FFD60099', borderRadius: '10px', fontSize: '14px', fontFamily: 'Rubik', background: 'transparent' }} type="text" name="email" placeholder="email@mail.com" />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-585' className='w-full flex flex-row flex-wrap justify-center mx-auto flex-wrap my-4'>
                                <Element is={Parent} canvas id='random-id-586' className='w-[15%] min-w-[100px]'>
                                    <Element is={Parent} canvas id='random-id-587' className='w-full text-center text-[#FFFFFF] mx-auto flex flex-row justify-center' style={{ fontSize: '18px', fontWeight: '500', fontFamily: 'Rubik' }}>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block' }} id='random-id-81'  >
                                            <Text text='Phone' />
                                        </Element>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block', color: '#FFD600' }} id='random-id-82'  >
                                            <Text text='*' />
                                        </Element>
                                    </Element>                      </Element>
                                <Element is={Parent} canvas id='random-id-588' className='w-[60%] min-w-[277px] my-auto'>
                                    <Input className='input-8' style={{ color: '#FFFFFF', height: '50px', border: '1px solid #FFD60099', borderRadius: '10px', fontSize: '14px', fontFamily: 'Rubik', background: 'transparent' }} type="text" name="phone" placeholder="(123) 456 78 90" />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-589' className='w-full flex flex-row flex-wrap justify-center mx-auto flex-wrap my-4'>
                                <Element is={Parent} canvas id='random-id-590' className='w-[15%] min-w-[100px]'>
                                    <Element is={Parent} canvas id='random-id-591' className='w-full text-center text-[#FFFFFF] mx-auto flex flex-row justify-center' style={{ fontSize: '18px', fontWeight: '500', fontFamily: 'Rubik' }}>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block' }} id='random-id-83'  >
                                            <Text text='Message' />
                                        </Element>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block', color: '#FFD600' }} id='random-id-84'  >
                                            <Text text='*' />
                                        </Element>
                                    </Element>                      </Element>
                                <Element is={Parent} canvas id='random-id-592' className='w-[60%] min-w-[277px] my-auto'>
                                    <Input className='input-8' style={{ color: '#FFFFFF', height: '50px', paddingBottom: '170px', paddingTop: '20px', border: '1px solid #FFD60099', borderRadius: '10px', fontSize: '14px', background: 'transparent', fontFamily: 'Rubik' }} type="text" name="firstname" placeholder="Message.." />
                                </Element>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-207' className='mx-auto mb-4'>
                            <Button classn='block' class='uppercase cursor-pointer w-[280px] flex mx-auto h-[60px] bg-[#FFD600] hover:bg-[#FFD600A1] text-[#2A2A2A]' style={{ borderRadius: '10px' }}>
                                <Element is={Parent} canvas id='random-id-1-8' style={{ display: 'inlineflex', fontSize: '22px', fontWeight: '600', fontFamily: 'Sansation', }} className='mx-auto my-auto'  >
                                    <Text text='Confirm' />
                                </Element>
                            </Button>
                        </Element>
                    </Element>
                </Element> </Element>
        </Element>,


        <Element canvas is={Parent} id='parentId1700' className="w-[100%] h-auto bg-[#F7FBFE] py-20" >
            <Element canvas is={Parent} id='parentId1800' className="w-[100%] h-auto flex justify-center items-center" row={true} >
                <Element is={Parent} canvas id='random-id-901' className='max-w-[1300px]'>
                    <Element is={Parent} canvas id='random-id-902' className='w-[90%] h-full flex flex-col mx-auto' style={{ border: '1px solid #DBD9D9', borderRadius: '10px', background: '#FFF' }}>
                        <Element is={Parent} className='text-center text-[#6064D2] w-[300px] mx-auto pl-10 flex mb-10 justify-center' canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', fontFamily: 'Rubik' }} id='random-id-9030'  >
                            <Text text='Simple form' />
                        </Element>

                        <Element is={Parent} canvas id='random-id-903'  >
                            <Element is={Parent} canvas id='random-id-904' className='w-full flex flex-row flex-wrap justify-center mx-auto my-4'>
                                <Element is={Parent} canvas id='random-id-905' className='w-[15%] min-w-[100px]'>
                                    <Element is={Parent} canvas id='random-id-906' className='w-full text-center text-[#000000] mx-auto flex flex-row justify-center' style={{ fontSize: '18px', fontWeight: '500', fontFamily: 'Rubik' }}>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block' }} id='random-id-9031'  >
                                            <Text text='Name' />
                                        </Element>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block', color: '#6064D2' }} id='random-id-9032'  >
                                            <Text text='*' />
                                        </Element>
                                    </Element>
                                </Element>
                                <Element is={Parent} canvas id='random-id-907' className='w-[60%] min-w-[277px] my-auto'>
                                    <Input style={{ height: '50px', border: '1px solid rgba(96, 100, 210, 0.20)', borderRadius: '10px', fontSize: '14px', fontFamily: 'Rubik' }} type="text" name="name" placeholder="Your name" />
                                </Element>
                            </Element>

                            <Element is={Parent} canvas id='random-id-908' className='w-full flex flex-row flex-wrap justify-center mx-auto my-4'>
                                <Element is={Parent} canvas id='random-id-909' className='w-[15%] min-w-[100px]'>
                                    <Element is={Parent} canvas id='random-id-910' className='w-full text-center text-[#000000] mx-auto flex flex-row justify-center' style={{ fontSize: '18px', fontWeight: '500', fontFamily: 'Rubik' }}>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block' }} id='random-id-5'  >
                                            <Text text='Company' />
                                        </Element>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block', color: '#6064D2' }} id='random-id-9033'  >
                                            <Text text='*' />
                                        </Element>
                                    </Element>
                                </Element>
                                <Element is={Parent} canvas id='random-id-911' className='w-[60%] min-w-[277px] my-auto'>
                                    <Input style={{ height: '50px', border: '1px solid rgba(96, 100, 210, 0.20)', borderRadius: '10px', fontSize: '14px', fontFamily: 'Rubik' }} type="text" name="company" placeholder="Company" />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-912' className='w-full flex flex-row flex-wrap justify-center flex-wrap mx-auto my-4'>
                                <Element is={Parent} canvas id='random-id-913' className='w-[15%] min-w-[100px]'>
                                    <Element is={Parent} canvas id='random-id-914' className='w-full text-center text-[#000000] mx-auto flex flex-row justify-center' style={{ fontSize: '18px', fontWeight: '500', fontFamily: 'Rubik' }}>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block' }} id='random-id-9034'  >
                                            <Text text='Email' />
                                        </Element>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block', color: '#6064D2' }} id='random-id-9035'  >
                                            <Text text='*' />
                                        </Element>
                                    </Element>
                                </Element>
                                <Element is={Parent} canvas id='random-id-915' className='w-[60%] min-w-[277px] my-auto'>
                                    <Input style={{ height: '50px', border: '1px solid rgba(96, 100, 210, 0.20)', borderRadius: '10px', fontSize: '14px', fontFamily: 'Rubik' }} type="text" name="email" placeholder="email@mail.com" />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-916' className='w-full flex flex-row flex-wrap justify-center flex-wrap mx-auto my-4'>
                                <Element is={Parent} canvas id='random-id-917' className='w-[15%] min-w-[100px]'>
                                    <Element is={Parent} canvas id='random-id-918' className='w-full text-center text-[#000000] mx-auto flex flex-row justify-center' style={{ fontSize: '18px', fontWeight: '500', fontFamily: 'Rubik' }}>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block' }} id='random-id-9036'  >
                                            <Text text='Name' />
                                        </Element>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block', color: '#6064D2' }} id='random-id-9037'  >
                                            <Text text='*' />
                                        </Element>
                                    </Element>                             </Element>
                                <Element is={Parent} canvas id='random-id-919' className='w-[60%] min-w-[277px] my-auto'>
                                    <Input style={{ height: '50px', border: '1px solid rgba(96, 100, 210, 0.20)', borderRadius: '10px', fontSize: '14px', fontFamily: 'Rubik' }} type="text" name="phone" placeholder="(123) 456 78 90" />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-920' className='w-full flex flex-row flex-wrap justify-center flex-wrap mx-auto my-4'>
                                <Element is={Parent} canvas id='random-id-921' className='w-[15%] min-w-[100px]'>
                                    <Element is={Parent} canvas id='random-id-922' className='w-full text-center text-[#000000] mx-auto flex flex-row justify-center' style={{ fontSize: '18px', fontWeight: '500', fontFamily: 'Rubik' }}>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block' }} id='random-id-9038'  >
                                            <Text text='Phone' />
                                        </Element>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block', color: '#6064D2' }} id='random-id-9039'  >
                                            <Text text='*' />
                                        </Element>
                                    </Element>                             </Element>
                                <Element is={Parent} canvas id='random-id-923' className='w-[60%] min-w-[277px] my-auto'>
                                    <Input style={{ height: '50px', paddingBottom: '170px', paddingTop: '20px', border: '1px solid rgba(96, 100, 210, 0.20)', borderRadius: '10px', fontSize: '14px', fontFamily: 'Rubik' }} type="text" name="Message" placeholder="Message.." />
                                </Element>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-924' className='mx-auto mb-4'>
                            <Button classn='block' class='uppercase w-[320px] flex h-[50px] bg-[#B20312] hover:bg-[#87000C] cursor-pointer text-[#FFFFFF]' style={{ borderRadius: '10px' }}>
                                <Element is={Parent} canvas id='random-id-1-9040' style={{ display: 'inlineflex', fontSize: '22px', fontWeight: '600', fontFamily: 'Rubik', }} className='mx-auto my-auto'  >
                                    <Text text='Confirm' />
                                </Element>
                            </Button>
                        </Element>
                    </Element>
                </Element> </Element>
        </Element>,


        <Element canvas is={Parent} id='parentId1900' className="w-[100%] h-auto bg-[#FEFBF7] py-20" >
            <Element canvas is={Parent} id='parentId2000' className="w-[100%] h-auto flex justify-center items-center" row={true} >
                <Element is={Parent} canvas id='random-id-926' className='max-w-[1300px] '>
                    <Element is={Parent} canvas id='random-id-927' className='w-[90%] h-full flex flex-col mx-auto' style={{ border: '1px solid rgba(162, 87, 56, 0.20)', borderRadius: '10px', background: '#FFF' }}>
                        <Element is={Parent} className='text-center text-[#A25738] w-[300px] mx-auto pl-10 flex mb-10 justify-center' canvas style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', fontFamily: 'Rubik' }} id='random-id-928'  >
                            <Text text='Simple form' />
                        </Element>

                        <Element is={Parent} canvas id='random-id-930'  >
                            <Element is={Parent} canvas id='random-id-931' className='w-full flex flex-row flex-wrap justify-center mx-auto my-4'>
                                <Element is={Parent} canvas id='random-id-932' className='w-[15%] min-w-[100px]'>
                                    <Element is={Parent} canvas id='random-id-933' className='w-full text-center text-[#000000] mx-auto flex flex-row justify-center' style={{ fontSize: '18px', fontWeight: '500', fontFamily: 'Inter' }}>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block' }} id='random-id-934'  >
                                            <Text text='Name' />
                                        </Element>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block', color: '#A25738' }} id='random-id-934'  >
                                            <Text text='*' />
                                        </Element>
                                    </Element>
                                </Element>
                                <Element is={Parent} canvas id='random-id-935' className='w-[60%] min-w-[277px] my-auto'>
                                    <Input style={{ height: '50px', border: '1px solid rgba(162, 87, 56, 0.20)', borderRadius: '10px', fontSize: '14px', fontFamily: 'Inter' }} type="text" name="name" placeholder="Your name" />
                                </Element>
                            </Element>

                            <Element is={Parent} canvas id='random-id-936' className='w-full flex flex-row flex-wrap justify-center mx-auto my-4'>
                                <Element is={Parent} canvas id='random-id-937' className='w-[15%] min-w-[100px]'>
                                    <Element is={Parent} canvas id='random-id-938' className='w-full text-center text-[#000000] mx-auto flex flex-row justify-center' style={{ fontSize: '18px', fontWeight: '500', fontFamily: 'Inter' }}>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block' }} id='random-id-939'  >
                                            <Text text='Company' />
                                        </Element>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block', color: '#A25738' }} id='random-id-940'  >
                                            <Text text='*' />
                                        </Element>
                                    </Element>
                                </Element>
                                <Element is={Parent} canvas id='random-id-941' className='w-[60%] min-w-[277px] my-auto'>
                                    <Input style={{ height: '50px', border: '1px solid rgba(162, 87, 56, 0.20)', borderRadius: '10px', fontSize: '14px', fontFamily: 'Inter' }} type="text" name="company" placeholder="Company" />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-942' className='w-full flex flex-row flex-wrap justify-center flex-wrap mx-auto my-4'>
                                <Element is={Parent} canvas id='random-id-943' className='w-[15%] min-w-[100px]'>
                                    <Element is={Parent} canvas id='random-id-944' className='w-full text-center text-[#000000] mx-auto flex flex-row justify-center' style={{ fontSize: '18px', fontWeight: '500', fontFamily: 'Inter' }}>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block' }} id='random-id-945'  >
                                            <Text text='Email' />
                                        </Element>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block', color: '#A25738' }} id='random-id-946'  >
                                            <Text text='*' />
                                        </Element>
                                    </Element>
                                </Element>
                                <Element is={Parent} canvas id='random-id-947' className='w-[60%] min-w-[277px] my-auto'>
                                    <Input style={{ height: '50px', border: '1px solid rgba(162, 87, 56, 0.20)', borderRadius: '10px', fontSize: '14px', fontFamily: 'Inter' }} type="text" name="email" placeholder="email@mail.com" />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-948' className='w-full flex flex-row flex-wrap justify-center flex-wrap mx-auto my-4'>
                                <Element is={Parent} canvas id='random-id-949' className='w-[15%] min-w-[100px]'>
                                    <Element is={Parent} canvas id='random-id-950' className='w-full text-center text-[#000000] mx-auto flex flex-row justify-center' style={{ fontSize: '18px', fontWeight: '500', fontFamily: 'Inter' }}>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block' }} id='random-id-951'  >
                                            <Text text='Name' />
                                        </Element>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block', color: '#A25738' }} id='random-id-952'  >
                                            <Text text='*' />
                                        </Element>
                                    </Element>                             </Element>
                                <Element is={Parent} canvas id='random-id-953' className='w-[60%] min-w-[277px] my-auto'>
                                    <Input style={{ height: '50px', border: '1px solid rgba(162, 87, 56, 0.20)', borderRadius: '10px', fontSize: '14px', fontFamily: 'Inter' }} type="text" name="phone" placeholder="(123) 456 78 90" />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-954' className='w-full flex flex-row flex-wrap justify-center flex-wrap mx-auto my-4'>
                                <Element is={Parent} canvas id='random-id-956' className='w-[15%] min-w-[100px]'>
                                    <Element is={Parent} canvas id='random-id-957' className='w-full text-center text-[#000000] mx-auto flex flex-row justify-center' style={{ fontSize: '18px', fontWeight: '500', fontFamily: 'Inter' }}>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block' }} id='random-id-958'  >
                                            <Text text='Phone' />
                                        </Element>
                                        <Element is={Parent} className='text-center' canvas style={{ display: 'inline-block', color: '#A25738' }} id='random-id-959'  >
                                            <Text text='*' />
                                        </Element>
                                    </Element>
                                </Element>
                                <Element is={Parent} canvas id='random-id-960' className='w-[60%] min-w-[277px] my-auto'>
                                    <Input style={{ height: '50px', paddingBottom: '170px', paddingTop: '20px', border: '1px solid rgba(162, 87, 56, 0.20)', borderRadius: '10px', fontSize: '14px', fontFamily: 'Inter' }} type="text" name="Message" placeholder="Message.." />
                                </Element>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-961' className='mx-auto mb-4'>
                            <Button classn='block' class='uppercase w-[280px] flex h-[60px] bg-[#A25738] cursor-pointer text-[#FFFFFF]' style={{ borderRadius: '10px' }}>
                                <Element is={Parent} canvas id='random-id-1-962' style={{ display: 'inlineflex', fontSize: '22px', fontWeight: '600', fontFamily: 'Sansation', }} className='mx-auto my-auto'  >
                                    <Text text='Confirm' />
                                </Element>
                            </Button>
                        </Element>
                    </Element>
                </Element> </Element>
        </Element>,

    ]


    const [sel, setSel] = useState(selected)

    function makeOdd(number) {
        setProp((prop) => {
            setSel(100);
        }, 1000);

        setTimeout(() => {
            setProp((prop) => {
                setSel(number)
            }, 1000);
        }, 100);



    }

    useEffect(() => {
        if (selected || selected >= 0) {
            makeOdd(selected);
        }
    }, [selected])

    return (
        <div style={{ padding: enabled ? "10px" : "0px", display: "flex", justifyContent: "center", alignItems: "center", width: '100%', height: '100%' }} ref={connect}>
            {sel == 100 ? (
                <div style={{ height: "85vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <BounceLoader
                        size={100}
                        color={"white"}
                        loading={true}
                    />
                </div>
            ) : (
                styles[sel]
            )}
        </div>
    );
}

Form_1.craft = {
    displayName: "Form 1",
    props: {
        selected: 1, // here is the default value of the selected style
        length: 9,
        isBlock: true
    },
    rules: {
        canDrag: () => true,
        canDrop: () => true,
    },
    related: {
        toolbar: NewFormSettings,
    },
}