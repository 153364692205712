/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import { useEditor, useNode } from "@craftjs/core";
import { WrapperSettings } from "./WrapperSettings";
import { useInView } from "react-hook-inview";
import React, { useCallback, useEffect, useRef, useState } from "react";
import '../blocks/Banner/Banner.css'
import './Wrapper.css'
import { ArrowDivider, BookDivider, CurveDivider, IconPerson, SplitDivider, Tilt, TiltDivider, TriangleAsymmetrical, TriangleAsymmetricalDivider, TriangleDivider, WaveDivider, WavesDivider } from "../../editor/Viewport/Pop-up/svgArray";

export const Wrapper = (props = {
  background: null,
}) => {
  const {
    connectors: { connect },
  } = useNode((node) => ({
    selected: node.events.selected,
  }));

  const { enabled } = useEditor((state, query) => ({
    enabled: state.options.enabled,

  }));

  const [hovered, setHovered] = useState(false)
  const [inViewRef, inView] = useInView()

  const mergedRef = useRef(null)


  const setRef = useCallback((node) => {
    if (mergedRef.current !== node) {
      if (node) {
        connect(node);
        inViewRef(node);
      }
      mergedRef.current = node;
    }
  }, []);

  const {
    background,
    color,
    shadow,
    children,
    style = {},
    isIcon,
    className,
    width,
    height,
    minWidth,
    flex,
    radius,
    maxWidth,
    border,
    flexDirection,
    paddingTop,
    paddingRight,
    paddingBottom,
    paddingLeft,
    marginTop,
    marginRight,
    marginBottom,
    marginLeft,
    transitionDuration,
    transitionTime,
    transitionDelay,
    onClick,
    isBasic,
    actionSelect = {
      background: { hover: null, scroll: null },
      color: { hover: null, scroll: null },
      shadow: { hover: null, scroll: null },
      width: { hover: null, scroll: null },
      height: { hover: null, scroll: null },
      minWidth: { hover: null, scroll: null },
      flex: { hover: null, scroll: null },
      radius: { hover: null, scroll: null },
      maxWidth: { hover: null, scroll: null },
      flexDirection: { hover: null, scroll: null },
      paddingTop: { hover: null, scroll: null },
      paddingRight: { hover: null, scroll: null },
      paddingBottom: { hover: null, scroll: null },
      paddingLeft: { hover: null, scroll: null },
      marginTop: { hover: null, scroll: null },
      marginRight: { hover: null, scroll: null },
      marginBottom: { hover: null, scroll: null },
      marginLeft: { hover: null, scroll: null },
      transformStyle: { hover: null, scroll: null },
      cursor: { hover: null, scroll: null },
      justifyContent: { hover: null, scroll: null },
      justifyItems: { hover: null, scroll: null },
      alignItems: { hover: null, scroll: null },
      alignContent: { hover: null, scroll: null },
      shapeStyle: { hover: null, scroll: null },
      shapePos: { hover: null, scroll: null },
      filterValue: { hover: null, scroll: null },
    },
    transitionFor,
    transformStyle,
    events,
    cursor,
    filterOptions,
    filterValue,
    justifyContent,
    justifyItems,
    alignItems,
    alignContent,
    positionsTranslate,
    positionsRotate,
    positionsRotateXYZ,
    positionsScale,
    positionsSkew,
    isRotateXYZ,
    isSkew,
    isTranslate,
    isScale,
    shapePos,
    absolute,
    shapes = {
      Right: {shapeStyle:null ,shapeWidth: null, shapeHeight: null, shapeColor: '#B20312', shapeArrangement: 'Underneath Section', position:'Right'},
      Left: {shapeStyle:null ,shapeWidth: null, shapeHeight: null, shapeColor: '#000', shapeArrangement: 'Underneath Section', position:'Left'},
      Bottom: {shapeStyle:null ,shapeWidth: null, shapeHeight: null, shapeColor: '#fff', shapeArrangement: 'Underneath Section', position:'Bottom'},
      Top: {shapeStyle:null ,shapeWidth: null, shapeHeight: null, shapeColor: 'blue', shapeArrangement: 'Underneath Section', position:'Top' },
    },
    id,
    displayName,
    actionState = 'Default',
    ...otherProps
  } = props;


  function applyTransformations(positionsTranslate, positionsScale, positionsSkew, positionsRotateXYZ) {
    return `${positionsTranslate && isTranslate ?
      `translate(${positionsTranslate?.x && positionsTranslate?.x !== 0 ? positionsTranslate?.x?.split(" ").join("") : '0'}${positionsTranslate?.y && positionsTranslate?.y !== 0 ? `, ${positionsTranslate?.y?.split(" ").join("")}` : "0"})` : ''}
    ${positionsScale && isScale ? `scale(${positionsScale.x && positionsScale.x !== 0 ? positionsScale.x?.split(" ").join("") : '0'})` : ''}
    ${positionsRotateXYZ && isRotateXYZ ? `rotateX(${positionsRotateXYZ.x && positionsRotateXYZ.x !== 0 ? `${positionsRotateXYZ.x?.split(" ").join("")}` : '0'}) rotateY(${positionsRotateXYZ.y && positionsRotateXYZ.y !== 0 ? `${positionsRotateXYZ.y?.split(" ").join("")}` : '0'}) rotateZ(${positionsRotateXYZ.z && positionsRotateXYZ.z !== 0 ? `${positionsRotateXYZ.z?.split(" ").join("")}` : '0'})` : ''}
    ${positionsSkew && isSkew ? `skew(${positionsSkew?.x && positionsSkew?.x !== 0 ? positionsSkew?.x.split(" ").join("") : '0'}${positionsSkew?.y !== 0 ? `, ${positionsSkew?.y && positionsSkew?.y !== 0 ? positionsSkew?.y?.split(" ").join("") : '0'}` : ""})` : ''}
  `;
  }

  const transform = applyTransformations(positionsTranslate, positionsScale, positionsSkew, positionsRotateXYZ);

  const handleProperty = (value, propertyName, split) => {
    if (value !== null && !split) {
      return { [propertyName]: value?.split(" ").join("") }
    }

    if (value !== null && split === 'splitBg' && actionState === 'Default') {
      return { [propertyName]:  value !== null ? value : '' }
    } else {
      return;
    }
  };


  const styleProps = {
    filter: filterValue !== null ? `${filterOptions}(${filterValue?.split(" ").join("")})` : '',
    ...handleProperty(background, 'background', 'splitBg'),

      ...(color !== null && {
        color: color.startsWith('#') ? color : 'transparent',
        backgroundImage: !color.startsWith('#') ? color : undefined,
        WebkitBackgroundClip: !color.startsWith('#') ? 'text' : undefined,
        backgroundClip: !color.startsWith('#') ? 'text' : undefined,
      }),
  
    ...handleProperty(width, 'width'),
    ...handleProperty(height, 'height'),
    ...handleProperty(minWidth, 'minWidth'),
    ...handleProperty(shadow, 'boxShadow'),
    ...handleProperty(flex, 'display'),
    ...handleProperty(radius, 'borderRadius'),
    ...handleProperty(maxWidth, 'maxWidth'),
    ...handleProperty(flexDirection, 'flexDirection'),
    ...handleProperty(paddingTop, 'paddingTop'),
    ...handleProperty(paddingRight, 'paddingRight'),
    ...handleProperty(paddingBottom, 'paddingBottom'),
    ...handleProperty(paddingLeft, 'paddingLeft'),
    ...handleProperty(marginTop, 'marginTop'),
    ...handleProperty(marginRight, 'marginRight'),
    ...handleProperty(marginBottom, 'marginBottom'),
    ...handleProperty(marginLeft, 'marginLeft'),
    ...handleProperty(cursor, 'cursor'),
    ...handleProperty(justifyContent, 'justifyContent'),
    ...handleProperty(justifyItems, 'justifyItems'),
    ...handleProperty(alignContent, 'alignContent'),
    ...handleProperty(alignItems, 'alignItems'),
    ...handleProperty(shapePos, 'shapePos'),
  };




  function ShapesDisplay() {
    const shapePositions = {
      Right: { top: 0, right: 0, transform: 'rotate(-90deg)' },
      Left: { top: 0, left: 0, transform: 'rotate(90deg)' },
      Bottom: { bottom: 0, left: 0, transform: 'rotate(180deg)' },
      Top: { top: 0, left: 0, transform: '' },
    };

    const shapeElements = Object.keys(shapes).map((key, index) => {
        const shape = shapes[key];
        const style = {
          width: '100%',
          position: 'absolute',
          zIndex:shape.shapeArrangement === 'Underneath Section' ? 0 : 1,
          display: key === 'Left' || key === 'Right' && 'none',
          ...shapePositions[key],
        };

        let shapeComponent;
        switch (shape.shapeStyle) {
            case 'Triangle':
                shapeComponent = <TriangleDivider width={`${shape.shapeWidth || 100}%`} height={shape?.shapeHeight} fill={getColor(shape.shapeColor)} />;
                break;
            case 'Curve':
                shapeComponent = <CurveDivider width={`${shape.shapeWidth || 100}%`} height={shape?.shapeHeight} fill={getColor(shape.shapeColor)} />;
                break;
            case 'Wave':
                shapeComponent = <WaveDivider width={`${shape.shapeWidth || 100}%`} height={shape?.shapeHeight} fill={getColor(shape.shapeColor)} />;
                break;
            case 'Waves Opacity':
                shapeComponent = <WavesDivider width={`${shape.shapeWidth || 100}%`} height={shape?.shapeHeight} fill={getColor(shape.shapeColor)} />;
                break;
            case 'Triangle Asymmetrical':
                shapeComponent = <TriangleAsymmetricalDivider width={`${shape.shapeWidth || 100}%`} height={shape?.shapeHeight} fill={getColor(shape.shapeColor)} />;
                break;
            case 'Tilt':
                shapeComponent = <TiltDivider width={`${shape.shapeWidth || 100}%`} height={shape?.shapeHeight} fill={getColor(shape.shapeColor)} />;
                break;
            case 'Arrow':
                shapeComponent = <ArrowDivider width={`${shape.shapeWidth || 100}%`} height={shape?.shapeHeight} fill={getColor(shape.shapeColor)} />;
                break;
            case 'Split':
                shapeComponent = <SplitDivider width={`${shape.shapeWidth || 100}%`} height={shape?.shapeHeight} fill={getColor(shape.shapeColor)} />;
                break;
            case 'Book':
                shapeComponent = <BookDivider width={`${shape.shapeWidth || 100}%`} height={shape?.shapeHeight} fill={getColor(shape.shapeColor)} />;
                break;
            default:
                shapeComponent = null;
        }

        return (
            <div key={key} style={style}>
                {shapeComponent}
            </div>
        );
    });

    return shapeElements
}


function getColor(shapeColor) {
    return shapeColor !== null ? shapeColor : '#000';
}


  const merged = (currentID) => {

    // Check if the element has hover or scroll values
    if (id === currentID && Object.keys(actionSelect).some(prop => (actionSelect[prop]['hover'] || actionSelect[prop]['scroll']) !== null)) {
      // if (!enabled && !hovered && inView) {
      //   return { ...styleProps }
      // }
      // check scroll
      if (!enabled && !hovered && !inView) {
        let mergedStyles = {};
        for (const prop in actionSelect) {
          if (prop === 'filterValue' && actionSelect[prop] && actionSelect[prop]['scroll']) {
            mergedStyles[prop] = `${filterOptions}(${actionSelect[prop]['scroll']})`;
          } else {
            mergedStyles[prop] = actionSelect[prop]['scroll'];
          }
        }
        const activeKeys = {};
        for (const key in mergedStyles) {
          if (mergedStyles[key] !== null) {
            activeKeys[key] = mergedStyles[key];
          }
        }

        return { ...styleProps, ...activeKeys };
      }
      // Then check the mouse hover state and not enabled
      else if (hovered && id && !enabled) {
        let mergedStyles = {};
        for (const prop in actionSelect) {
          if (prop === 'filterValue' && actionSelect[prop] && actionSelect[prop]['hover']) {
            mergedStyles[prop] = `${filterOptions}(${actionSelect[prop]['hover']})`;
          } else {
            mergedStyles[prop] = actionSelect[prop]['hover'];
          }
        }
        const activeKeys = {};
        for (const key in mergedStyles) {
          if (mergedStyles[key] !== null) {
            activeKeys[key] = mergedStyles[key];
          }
        }

        return { ...styleProps, ...activeKeys };
      }
      // only when hover effect  
      else if (actionState === 'Hover' && enabled) {
        let mergedStyles = {};
        for (const prop in actionSelect) {
           if (prop === 'filterValue' && actionSelect[prop] && actionSelect[prop]['hover']) {
            mergedStyles[prop] = `${filterOptions}(${actionSelect[prop]['hover']})`;
          } else {
            mergedStyles[prop] = actionSelect[prop]['hover'];
          }
        }
        const activeKeys = {};
        for (const key in mergedStyles) {
          if (mergedStyles[key] !== null) {
            activeKeys[key] = mergedStyles[key];
          }
        }

        return { ...activeKeys };

        // only when scroll effect
      } else if (actionState === 'Scroll' && !inView && enabled) {
        let mergedStyles = {};
        for (const prop in actionSelect) {
          mergedStyles[prop] = actionSelect[prop]['scroll'];
        }
        const activeKeys = {};
        for (const key in mergedStyles) {
          if (mergedStyles[key] !== null) {
            activeKeys[key] = mergedStyles[key];
          }
        }

        return { ...activeKeys };
      }
      return { ...styleProps };
    }
    return { ...styleProps };

  };
  

  return (
    <div
      ref={setRef}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      className={`${className}`}
      id={id}
      onClick={onClick}
      style={{
        ...style,
        ...(enabled && actionState === 'Default' ? styleProps : merged(id)),
        zIndex: !absolute && 0,
        position: !absolute  && 'relative',
        transitionProperty: !enabled && transitionFor === null? 'all' : transitionFor,
        transitionDuration: !enabled && transitionDuration === null? '150ms' : transitionDuration !== null? `${transitionDuration}ms` : '',
        transitionTimingFunction: !enabled && transitionTime === null?  'ease-in-out' : transitionTime,
        transitionDelay: !enabled && transitionDelay === null? '150ms' : transitionDelay !== null? `${transitionDelay}ms` : '',
        transform:
          actionState === 'Default' ||
            (actionState === 'Scroll' && !inView) ||
            (actionState === 'Hover' && hovered) ? transform : ''
      }}
    >

    {actionState === 'Default' ||
      (actionState === 'Scroll' && !inView) ||
      (actionState === 'Hover' && hovered) ? ShapesDisplay() : null}       

      {children}

    </div>

  )
}


Wrapper.craft = {
  displayName: 'Wrapper',
  custom: { displayName: null },
  props: {
    actionState: 'Default',
    actionSelect: {
      background: { hover: null, scroll: null },
      color: { hover: null, scroll: null },
      shadow: { hover: null, scroll: null },
      width: { hover: null, scroll: null },
      height: { hover: null, scroll: null },
      minWidth: { hover: null, scroll: null },
      flex: { hover: null, scroll: null },
      radius: { hover: null, scroll: null },
      maxWidth: { hover: null, scroll: null },
      border: { hover: null, scroll: null },
      flexDirection: { hover: null, scroll: null },
      paddingTop: { hover: null, scroll: null },
      paddingRight: { hover: null, scroll: null },
      paddingBottom: { hover: null, scroll: null },
      paddingLeft: { hover: null, scroll: null },
      marginTop: { hover: null, scroll: null },
      marginRight: { hover: null, scroll: null },
      marginBottom: { hover: null, scroll: null },
      marginLeft: { hover: null, scroll: null },
      transformStyle: { hover: null, scroll: null },
      cursor: { hover: null, scroll: null },
      filterOptions: { hover: null, scroll: null },
      filterValue: { hover: null, scroll: null },
      justifyContent: { hover: null, scroll: null },
      justifyItems: { hover: null, scroll: null },
      alignItems: { hover: null, scroll: null },
      alignContent: { hover: null, scroll: null },
      shapeStyle: { hover: null, scroll: null },
      shapePos: { hover: null, scroll: null },
      
    },
    background: null,
    color: null,

    radius: null,
    cursor: null,
    shadow: null,

    width: null,
    height: null,
    minWidth: null,
    maxWidth: null,

    paddingTop: null,
    paddingRight: null,
    paddingBottom: null,
    paddingLeft: null,
    marginTop: null,
    marginRight: null,
    marginBottom: null,
    marginLeft: null,

    flex: null,
    justifyContent: null,
    justifyItems: null,
    alignItems: null,
    alignContent: null,
    flexDirection: null,
    positionsSkew: { x: 0, y: 0 },
    positionsRotate: { x: 0, y: 0 },
    positionsRotateXYZ: { x: 0, y: 0, z: 0 },
    positionsTranslate: { x: 0, y: 0 },
    positionsScale: { x: 0, y: 0 },
    isScale: false,
    isTranslate: false,
    isSkew: false,
    isRotateXYZ: false,

    transitionFor: null,
    transitionDelay: null,
    transitionTime:null ,
    transitionDuration: null,
    transformStyle: 'translate',
    filterOptions: null,
    filterValue: null,
    isBasic: false,
    isWrapper: true,
    shapePos: 'Top',
    shapes : {
        Right: {shapeStyle:null ,shapeWidth: null, shapeHeight: null, shapeColor: '#B20312', shapeArrangement: 'Underneath Section'},
        Left: {shapeStyle:null ,shapeWidth: null, shapeHeight: null, shapeColor: '#000', shapeArrangement: 'Underneath Section'},
        Bottom: {shapeStyle:null ,shapeWidth: null, shapeHeight: null, shapeColor: '#fff', shapeArrangement: 'Underneath Section' },
        Top: {shapeStyle:null ,shapeWidth: null, shapeHeight: null, shapeColor: 'blue', shapeArrangement: 'Underneath Section' },
      },
    },
  // related: {
  //   toolbar: WrapperSettings,
  // },
  rules: {
    canDrag: () => true,
    canDrop: () => true,
  }
};
