import React, { useEffect, useState } from "react"
import { lazy } from "react";
import { useActiveIdContext } from "../ActiveIdContext";
import { getKcContext } from "./kcContext/getKcContext";

// import { getKcContext } from "./kcContext";


const KcLoginThemeApp = lazy(() => import("./KcApp")); 

export const Login = (props) => { 

  const { state, dispatch: setActiveId } = useActiveIdContext();
  const { activeId: activeId } = state;

    const { templateProps, extraProps } = props

    let [contextState, setContextState]  = useState(undefined)
    
    const kcLoginThemeFunc = (pageId="login.ftl") => {
        let {kcContext} = getKcContext({
        // Uncomment to test the login page for development.
        mockPageId: pageId,
    });

    setContextState(kcContext)
    }

    useEffect(() => {
        if(activeId) {
            kcLoginThemeFunc(activeId?._id)
            //  kcLoginThemeFunc("login.ftl")
        }
        
    },[activeId])


    useEffect(() => {
        if(process.env.REACT_APP_AUTH == "true") {
            kcLoginThemeFunc("login.ftl")
            //  kcLoginThemeFunc("login.ftl")
        } 
        
    },[process.env.REACT_APP_AUTH])

    return (


        (()=>{

            if( contextState !== undefined ){
                return <KcLoginThemeApp 
                templateProps={templateProps}
                kcContext={{...contextState, 
                templateProps: templateProps,
                extraProps: extraProps
                }} />;
            } else {
                return <div>"from app"</div>
            }

            throw new Error(
              "This app is a Keycloak theme" +
              "It isn't meant to be deployed outside of Keycloak"
            );

        })()
        
    )
}