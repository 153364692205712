/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import { InputAdornment } from '@mui/material';
import { makeStyles } from "@mui/styles"
import React, { useEffect } from 'react';
import { useState } from 'react';
import { ChromePicker } from 'react-color';
import { useDarkMode } from '../../../DarkModeContext';
import './toolbar.css'
import { TransparentIcon } from '../Viewport/Pop-up/svgArray';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import GradientSettings from './GradientColor';

const useStyles = makeStyles({
  root: {
    padding: 0,
    width: '100%',
    // background:"#efeff1",
    borderRadius: '100px',
    border: 'none',
    margin: '0%',
    marginTop: '7%',
    position: 'relative',
  },
  input: {
    background: '#efeff1',
    borderRadius: '100%',
    fontSize: '0.75Vmin',
    paddingLeft: '18%',
    paddingBottom: '4%',
    paddingTop: '4%',
    margin: '0%',
  }, // a style rule
  // notchedOutline: {
  //   borderColor:'transparent',
  //   borderRadius: "100px"
  // }
});

const useLabelStyles = makeStyles({
  root: {
    color: 'rgb(128,128,128)',
  },
  formControl: {
    fontSize: '2vmin',
    borderRadius: '100%',
    paddingLeft: '0%',
    paddingTop: '1.5%',
    marginBottom: '1.5%',
    position: 'relative',
    left: '-8%',
  }, // a style rule
});

export const ToolbarTextInput = ({
  pxInput,
  placeholder,
  onChange,
  onClick,
  value,
  ref,
  prefix,
  label,
  type,
  initialValue,
  options,
  ...props
}) => {

  
  const [selected, setSelected] = useState(value);
  const [active, setActive] = useState(false);
  const classes = useStyles({});
  const labelClasses = useLabelStyles({});
  const {isDarkMode} = useDarkMode()
  const [focused, setFocused] = useState(false);
  const [gradSetting, setGradSetting] = useState(false)

  const prevColors = [
    '#e83030',
    '#5f2b2b',
    '#c31313',
    '#0867fc',
    '#f84173',
    '#ebebeb',
    '#111f3e',
    '#5558b1', 
  ];



  const checkSelected = () => {
    
   if (selected) {
    if (typeof selected !== 'string') {
      return selected;
    }

    if (selected.startsWith('#')) {
      return selected; 
    }

    if (selected.startsWith('tr')) {
      return 'Transparent'; 
    }

    return 'Gradient'; 
   }
  };


  return (
    <div
      style={{ width: '100%', position: 'relative' }}
      className='flex flex-col'
    >
      <div className='flex flex-col w-[100%] justify-center align-items-center'>
        <div className='flex items-center pr-[1Vmin]' style={{fontSize:'2Vmin', fontWeight:'500', fontFamily:'Inter'}}>
          {pxInput? '' : label}
        </div>
        {['color', 'bg'].includes(type) ? null : <input
          onBlur={() => setFocused(false)}
          onFocus={() => setFocused(true)}
        id='input'
        color='success'
        ref={ref}
        placeholder={placeholder}
        className={` ${!pxInput ? 'py-[3%] px-[10%]' : 'text-center'} flex justify-center items-center`}
        style={{ margin: '0%', width:'100%', alignContent:'center', borderRadius: !pxInput && '2Vmin', background:'transparent', color:isDarkMode? '#fff' : '#161717' , border: focused? !pxInput && '.25Vmin solid #0867FC' : isDarkMode? !pxInput && '0.25Vmin solid #E9E9EA30' : !pxInput && '0.25Vmin solid #E9E9EA', fontSize:'1.7Vmin', fontWeight:'400', fontFamily:'Inter'}}
        value={value || ''} 
       
        // onKeyDown={(e) => {
        //   if (e.key === 'Enter') {
        //     onChange((e.target).value);
        //   }
        // }}
        
        onChange={(e) => {
          const valueInput = e.target.value;
          onChange(valueInput);
          value = valueInput;
        }}

        margin="dense"
        InputProps={{
          classes,
          disableUnderline: true,
          startAdornment: ['color', 'bg'].includes(type) ? (
            <InputAdornment>
            </InputAdornment>
          ) : null,
        }}
        InputLabelProps={{
          classes: {
            ...labelClasses,
          },
          shrink: true,
        }}
        {...props}
      />}
        
      
      {['color', 'bg'].includes(type) ? (
        <label className='text-[#989191] absolute top-[-3%] right-0'
        style={{fontWeight:'400', fontFamily:'Inter', fontSize:'1.25Vmin'}} for="input"></label>
      ) : null}
  
      </div>
      {['color', 'bg'].includes(type) ? (
         <div className='h-full min-h-[3vmin] my-[1vmin] flex flex-column justify-center w-full'>
         <div style={{ display: 'block', width: '100%', fontWeight: '500', fontSize: '2.2vmin' }}>{label}</div>
         <div style={{ display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'center', alignContent: 'center' }}>

           <div className='relative my-[1vmin] min-w-[100%] mx-[.5vmin] h-[7vmin] flex justify-center align-items-center rounded-[1vmin] overflow-hidden'>
             <input style={{ cursor: 'pointer', minWidth: '150%', height: '130%' }} type='color' value={selected}

               onChange={(e) => {
                onChange(e.target.value)
                setSelected(e.target.value)}}
             />
             <div style={{ pointerEvents: 'none' }} className='absolute top-0 left-0 text-[#fff] text-[2.5vmin] w-full h-full flex align-items-center justify-center text-center'>
               <div>{checkSelected() || 'Click Here.'}</div>
             </div>
           </div>

           <ul style={{ width: 'fit-content', padding: '0', margin: '0 .5vmin', display: 'flex', justifyContent: 'center', flexWrap: 'wrap', margin: 'auto auto' }}>
             <li className='cursor-pointer' style={{ background: "url(https://i.pinimg.com/originals/40/8e/28/408e28da9e2773e4b65c9bba40307167.png) center", margin: '.35vmin', display:'flex', justifyContent:'center', alignItems:'center',  width: '3vmin', height: '3vmin', borderRadius: '50%', border: '1px solid #0867FC30',}} onClick={() => {
              onChange('transparent')
              setSelected('transparent')
             }}>
             </li>
             {prevColors.map((color, index) => (
              <li onClick={(e) => {
                onChange(color)
                setSelected(color)
              }}
               style={{ cursor: 'pointer', margin: '.35vmin', width: '3vmin', height: '3vmin', borderRadius: '50%', border: '1px solid #e9e9e9', background: color }} key={index}></li>
             ))}
            {options['actionState'] && options['actionState'] === "Default" ? <li className='cursor-pointer' style={{ backgroundImage: 'linear-gradient(to right top, #051937, #004d7a, #008793, #00bf72, #a8eb12)', margin: '.35vmin', display:'flex', justifyContent:'center', alignItems:'center',  width: '3vmin', height: '3vmin', borderRadius: '50%', padding:'1px'}} onClick={() => {
              setGradSetting(true)
             }}>
             </li> : !options['actionState'] ? (
              <li className='cursor-pointer' style={{ backgroundImage: 'linear-gradient(to right top, #051937, #004d7a, #008793, #00bf72, #a8eb12)', margin: '.35vmin', display:'flex', justifyContent:'center', alignItems:'center',  width: '3vmin', height: '3vmin', borderRadius: '50%', padding:'1px'}} onClick={() => {
                setGradSetting(true)
               }}>
               </li>
             ) : null}
           </ul>
           {gradSetting && (
            <div className='relative pt-[1.5vmin]'>
              <GradientSettings type={type} onChange={onChange} setSelected={setSelected}/>
            </div>
           )}

         </div>

       </div>
      ) : null}
          
    </div>
  );
};
