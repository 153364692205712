/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import { useNode, useEditor } from '@craftjs/core';
import React from 'react';
import ContentEditable from 'react-contenteditable';
import { LinkSettings } from './LinkSettings';
import { useActiveIdContext } from '../../../../ActiveIdContext';
import { useOptionsContext } from '../../../../OptionsContext';


export const Link = ({
  fontSize,
  textAlign,
  fontWeight,
  color,
  shadow,
  text,
  margin,
  href,
  marginTop,
  marginRight,
  marginBottom,
  marginLeft,
  selected,
  unit,
  hrefTitle
}) => {

    const {
        connectors: { connect },
        actions: {setProp},
      } = useNode();

      const { enabled } = useEditor((state) => ({
        enabled: state.options.enabled,
      }));

      const { state, dispatch: setActiveId } = useActiveIdContext();
      const { activeId: activeId } = state;

      const { state: idState, dispatch: idDispatch } = useOptionsContext();
      const { options: treeData } = idState;

      const styleProps = {
        // ...({ width: '100%' }),
        ...(marginTop !== null && { marginTop: `${marginTop?.split(" ")?.join("")}` }),
        ...(marginRight !== null && { marginRight: `${marginRight?.split(" ")?.join("")}` }),
        ...(marginBottom !== null && { marginBottom: `${marginBottom?.split(" ")?.join("")}` }),
        ...(marginLeft !== null && { marginLeft: `${marginLeft?.split(" ")?.join("")}` }),
        ...(color !== null? { color: `rgba(${Object.values(color)})` } : {color:'blue'}),
        ...(fontSize !== null ? { fontSize: `${fontSize?.split(" ")?.join("")}`} : {fontSize:'18px'}),
        ...(shadow !== null && {
          textShadow: `0px 0px 2px rgba(0,0,0,${(shadow || 0) / 100})`,
        }),
        ...(fontWeight !== null && { fontWeight: fontWeight }),
        ...(textAlign !== null && { textAlign: textAlign }),
      };


    return (
    <ContentEditable
    title={hrefTitle}
    onClick={() => {
      if(href && !enabled) {
        if(href._id.includes("www")) {
          window.open("https://" + href?.name, "_blank")
        } else {
          const parentItem = treeData.find((x) => x._id === href._id);

          if (parentItem) {
            setActiveId(parentItem);
          } else {
            let childItem = null;
          
            for (const parent of treeData) {
              const child = parent.children.find((child) => child._id === href._id);
          
              if (child) {
                childItem = child;
                break;
              }
            }
          
            setActiveId(childItem);
          }

        }
      } 
    }}
    innerRef={connect}
    html={text} // innerHTML of the editable div
    disabled={!enabled}
    onChange={(e) => {
    setProp((prop) => (prop.text = e.target.value), 500);
    }} // use true to disable editing
    tagName="div" // Use a custom HTML tag (uses a div by default)
    style={{...styleProps, cursor:"pointer" , borderRadius:enabled ? '8px' : '', textDecoration: 'underline'}}
    />
        
    )
  }

Link.craft = {
    displayName: 'Link',
    props: {
      fontSize: null,
      textAlign: null,
      fontWeight: null,
      color: null,
      margin: null,
      shadow: null,
      href: null,
      marginTop: null,
      marginRight: null,
      marginBottom: null,
      marginLeft: null,
      text: 'Text',
      hrefTitle:"eg. Text Here"
    },
    rules: {
      canDrop: () => true,
      canDrag: () => true,
      canMoveIn: () => true,
      canMoveOut: () => true
    },
    related: {
      toolbar: LinkSettings,
    },
}