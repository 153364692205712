/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, {useState, useRef, useEffect} from 'react';
import { Element, useEditor, useNode } from '@craftjs/core';
import { Text } from '../../basic/Text';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import { NewCtaSettings } from './newCtaSetting';
import './CTA.css'
import { Button } from '../../basic/Button';
import { ImageC } from '../../basic/Image';
import { Parent } from '../../Parent/index.js';
import { Wrapper } from '../../wrapper/index.js';
import { BounceLoader } from 'react-spinners';

export const CTA_2 = ({selected}) => {
 
    
    const { 
        actions: { setProp },
          connectors: {
            connect
          } 
      } = useNode();
    
      
      const {
        enabled,
      } = useEditor((state, query) => ({
        enabled: state.options.enabled,
     
      }));
    

    const styles = [
        <Element canvas is={Parent} id='parentId100' className="w-[100%] h-auto" >
    <Element canvas is={Parent} id='parentId200' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-400' is='parent' className='w-[100%] h-full bg-[#2479FD] m-6 py-10' style={{borderRadius:'30px'}} >
        <Wrapper canvas id='random-id-401' is='container' className='container'>
            <Wrapper canvas id='random-id-402' is='wrapper' className='w-full h-full flex flex-col text-center justify-center'>
                    <Wrapper canvas style={{display:'inline-block', fontFamily:'Poppins', fontWeight:'700', color:'#FFFFFF'}} className={`w-full h-full  text-[46px]`} id='random-id-1' is='div'>
                        <Text text='Understand User Flow'/>
                    </Wrapper>
                    <Wrapper canvas style={{display:'inline-block', fontFamily:'Poppins', fontWeight:'400', color:'#FFFFFF', lineHeight:'33.6px'}} className={`w-full h-full mx-auto m-8 px-6 text-[24px]`} id='random-id-2' is='div'>
                        <Text text='Sed sed risus pretium quam vulputate dignissim. Eget nunc lobortis mattis aliquam faucibus purus in. Potenti nullam ac tortor vitae purus faucibus ornare suspendisse.'/>
                    </Wrapper>
                <Wrapper canvas id='random-id-200' is='div' className='mx-auto' style={{borderRadius:'14px'}}>
                        <Button  classn='block' class='w-[280px] h-[60px] bg-[#FFC92E] justify-center hover:bg-[#FFF5D7] border-1 border-[#FFC92E] hover:border-[#FFC92E] flex cursor-pointer text-[#000000]' style={{ borderRadius:'14px'}}>
                            <Wrapper canvas style={{display:'inline-block', fontSize:'22px', fontWeight:'400', fontFamily:'Poppins',}} className=' my-auto' id='random-id-3' is='div'>
                                <Text text='Get Started'/>
                            </Wrapper>
                            <Wrapper  canvas style={{display:'inline-block'}} className=' my-auto' id='random-id-403' is='div'>
                                <ImageC src='https://i.ibb.co/429rB9T/iconmonstr-arrow-right-circle-thin-240.png' style={{width:'30px', height:'30px'}}/>
                            </Wrapper>
                        </Button>
                    </Wrapper>
            </Wrapper>
        </Wrapper>
    </Wrapper></Element>
    </Element>
        ,
        <Element canvas is={Parent} id='parentId300' className="w-[100%] h-auto" >
    <Element canvas is={Parent} id='parentId400' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-404' is='parent' className='w-[100%] h-full bg-[#FF0060] m-6 py-10' style={{borderRadius:'30px'}} >
        <Wrapper canvas id='random-id-405' is='container' className='container'>
            <Wrapper canvas id='random-id-406' is='wrapper' className='w-full h-full flex flex-col text-center justify-center'>
                    <Wrapper canvas style={{display:'inline-block', fontFamily:'Prompt', fontWeight:'700', color:'#FFFFFF'}} className={`w-full h-full  text-[46px]`} id='random-id-4' is='div'>
                        <Text text='Understand User Flow'/>
                    </Wrapper>
                    <Wrapper canvas style={{display:'inline-block', fontFamily:'Prompt', fontWeight:'400', color:'#FFFFFF', lineHeight:'33.6px'}} className={`w-full h-full mx-auto m-8 px-6 text-[24px]`} id='random-id-5' is='div'>
                        <Text text='Sed sed risus pretium quam vulputate dignissim. Eget nunc lobortis mattis aliquam faucibus purus in. Potenti nullam ac tortor vitae purus faucibus ornare suspendisse.'/>
                    </Wrapper>
                <Wrapper canvas id='random-id-201' is='div' className='mx-auto'>
                        <Button  classn='block' class='w-[280px] h-[60px] bg-[#F6FA70] hover:bg-[#F6FD00] flex justify-center cursor-pointer text-[#000000]' style={{ borderRadius:'30px'}}>
                            <Wrapper canvas style={{display:'inline-block', fontSize:'22px', fontWeight:'400', fontFamily:'Prompt',}} className=' my-auto' id='random-id-6' is='div'>
                                <Text text='Get Started'/>
                            </Wrapper>
                            <Wrapper  canvas style={{display:'inline-block'}} className=' my-auto' id='random-id-407' is='div'>
                                <ImageC src='https://i.ibb.co/429rB9T/iconmonstr-arrow-right-circle-thin-240.png' style={{width:'30px', height:'30px'}}/>
                            </Wrapper>
                        </Button>
                    </Wrapper>
            </Wrapper>
        </Wrapper>
    </Wrapper></Element>
    </Element>,

<Element canvas is={Parent} id='parentId500' className="w-[100%] h-auto" >
    <Element canvas is={Parent} id='parentId600' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-408' is='parent' className='w-[100%] h-full bg-[#FFB7B7] m-6 py-10' style={{borderRadius:'6px 20px 6px 20px'}} >
<Wrapper canvas id='random-id-409' is='container' className='container'>
    <Wrapper canvas id='random-id-410' is='wrapper' className='w-full h-full flex flex-col text-center justify-center'>
            <Wrapper canvas style={{display:'inline-block', fontFamily:'Almarai', fontWeight:'700', color:'#FFFFFF'}} className={`w-full h-full  text-[46px]`} id='random-id-7' is='div'>
                <Text text='Understand User Flow'/>
            </Wrapper>
            <Wrapper canvas style={{display:'inline-block', fontFamily:'Almarai', fontWeight:'400', color:'#000000', lineHeight:'33.6px'}} className={`w-full h-full mx-auto m-8 px-6 text-[24px]`} id='random-id-8' is='div'>
                <Text text='Sed sed risus pretium quam vulputate dignissim. Eget nunc lobortis mattis aliquam faucibus purus in. Potenti nullam ac tortor vitae purus faucibus ornare suspendisse.'/>
            </Wrapper>
        <Wrapper canvas id='random-id-202' is='div' className='mx-auto'>
                <Button  classn='block' class='w-[280px] h-[60px] bg-[#F31559] hover:bg-[#FF5B8D] justify-center flex cursor-pointer text-[#FFFFFF]' style={{ borderRadius:'8px'}}>
                    <Wrapper canvas style={{display:'inline-block', fontSize:'22px', fontWeight:'400', fontFamily:'Almarai',}} className=' my-auto' id='random-id-9' is='div'>
                        <Text text='Get Started'/>
                    </Wrapper>
                    <Wrapper  canvas style={{display:'inline-block'}} className=' my-auto' id='random-id-411' is='div'>
                                <ImageC src='https://i.ibb.co/c35zrY4/iconmonstr-arrow-right-circle-thin-240-1.png' style={{width:'30px', height:'30px'}}/>
                            </Wrapper>
                </Button>
            </Wrapper>
    </Wrapper>
</Wrapper>
</Wrapper></Element>
    </Element>,


<Element canvas is={Parent} id='parentId700' className="w-[100%] h-auto" >
    <Element canvas is={Parent} id='parentId800' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-412' is='parent' className='w-[100%] h-full bg-[#0C134F] m-6 py-10' style={{borderRadius:'30px'}} >
<Wrapper canvas id='random-id-413' is='container' className='container'>
    <Wrapper canvas id='random-id-414' is='wrapper' className='w-[full] h-full flex flex-col text-center justify-center'>
            <Wrapper canvas style={{display:'inline-block', fontFamily:'Chivo', fontWeight:'700', color:'#FFFFFF'}} className={`w-full h-full  text-[46px]`} id='random-id-10' is='div'>
                <Text text='Understand User Flow'/>
            </Wrapper>
            <Wrapper canvas style={{display:'inline-block', fontFamily:'Chivo', fontWeight:'400', color:'#FFFFFF', lineHeight:'33.6px'}} className={`w-full h-full mx-auto m-8 px-6 text-[24px]`} id='random-id-11' is='div'>
                <Text text='Sed sed risus pretium quam vulputate dignissim. Eget nunc lobortis mattis aliquam faucibus purus in. Potenti nullam ac tortor vitae purus faucibus ornare suspendisse.'/>
            </Wrapper>
        <Wrapper canvas id='random-id-203' is='div' className='mx-auto'>
                <Button  classn='block' class='w-[280px] h-[60px] bg-[#D4ADFC] hover:bg-[#C68EFF] justify-center flex cursor-pointer text-[#0C134F]' style={{borderRadius:'8px'}}>
                    <Wrapper canvas style={{display:'inline-block', fontSize:'22px', fontWeight:'400', fontFamily:'Chivo', }} className=' my-auto' id='random-id-12' is='div'>
                        <Text text='Get Started'/>
                    </Wrapper>
                    <Wrapper  canvas style={{display:'inline-block'}} className=' my-auto' id='random-id-415' is='div'>
                                <ImageC src='https://i.ibb.co/5GM5w2g/iconmonstr-arrow-right-circle-thin-240-2.png' style={{width:'30px', height:'30px'}}/>
                            </Wrapper>
                </Button>
            </Wrapper>
    </Wrapper>
</Wrapper>
</Wrapper></Element>
    </Element>
    ]

      
const [sel, setSel] = useState(selected)

function makeOdd(number) {
  setProp((prop) => {
    setSel(100);
  }, 1000);

  setTimeout(() => {
    setProp((prop) => {
      setSel(number)
    }, 1000);
  }, 100);


  
}

useEffect(() => {
if(selected || selected >= 0) {
  makeOdd(selected);
}
},[selected])

 

    

    
    
return (
    <div style={{ padding:enabled? "10px" : "0px", display:"flex", justifyContent:"center", alignItems:"center", width:'100%', height:'100%'}} ref={connect}>
    {sel == 100 ? (
          <div style={{height:"85vh", display:"flex", justifyContent:"center", alignItems:"center"}}>
          <BounceLoader
                  size={100}
                  color={"white"}
                  loading={true}
                />
          </div>
         ) : (
          styles[sel]
         )} 
</div>
);
}

CTA_2.craft = {
    displayName: "CTA 2",
    props: {
    selected: 1, // here is the default value of the selected style
    length:3,
    isBlock: true
    },
    rules: {
        canDrag: () => true,
        canDrop: () => true,
    },
    related: {
        toolbar: NewCtaSettings,
    },
};