/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/



import { Element } from '@craftjs/core';

import { useNode } from '@craftjs/core';
import React, { useEffect, useState } from 'react';
import { Container } from  '../../../Container/index.js';
import { ColumnSettings } from './ColumnSettings';
import { Row1 } from '../Row/Row1';
export const Column3 = ({rows}) => {
  const {
    connectors: {
      connect
    },
    active
  } = useNode(node => ({
    selected: node.events.selected,
    active: node.events.selected,
  }));

  return (
    <div style={{display:"flex", flexDirection:"column", border: active ? "5px solid #3b97e3" : "", width:"100%"}} ref={connect}>
   { [...Array(rows).keys()].map((x,i)=> (
    
       
         <Element
         key={i}
            id={`Container11111i-411${i}`}
            canvas
            is={Row1}
          />
               
     )
     
    )}

    </div>


  )
}

Column3.craft = {
  displayName: "Column",
  props: {
    rows: 2,
  },

};