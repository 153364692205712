/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, { useState } from 'react';
import { capitalize, weightDescription } from '../Text/text';
import { ToolbarSection, ToolbarItem } from '../../../editor';
import { ToolbarRadio } from '../../../editor/Toolbar/ToolbarRadio';
import AlignHorizontalLeftIcon from '@mui/icons-material/AlignHorizontalLeft';
import AlignHorizontalCenterIcon from '@mui/icons-material/AlignHorizontalCenter';
import AlignHorizontalRightIcon from '@mui/icons-material/AlignHorizontalRight';
import { TabOption } from '../../../editor/Viewport/Sidebar/TabOption';

export const LinkSettings = () => {
  const [activeTab, setActiveTab] = useState("Style Manager");

  return (
    <div className='flex flex-col' style={{ display: "flex", height: "100%" }}>
      <div className='w-full'>
        <TabOption activeTab={activeTab} setActiveTab={setActiveTab} />
        {activeTab === "Navigation Manager" ? (
          <ToolbarSection
            expanded={true}

            title="Navigation"
          >

            <ToolbarItem
              full={true}
              propKey="hrefTitle"
              type="text"
              label="Title"
            />

            <ToolbarItem
              full={true}
              propKey="href"
              type="text"
              label="Href"
            />

          </ToolbarSection>
        ) : null}

        {activeTab === "Style Manager" ? (
          <div style={{ width: "100%" }}>
            <ToolbarSection
              expanded={true}
              title="Typography"
              props={['fontSize', 'fontWeight', 'textAlign']}
              summary={({ fontSize, fontWeight, textAlign }) => {
                return `${fontSize || ''}, ${fontWeight ? weightDescription(
                  fontWeight
                ) : ""}, ${textAlign ? capitalize(textAlign) : ""}`;
              }}
            >
              <ToolbarItem
                full={true}
                propKey="fontSize"
                type="slider"
                unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                label="Size"
              />

              <ToolbarItem propKey="textAlign" type="radio" label="Align">
                <ToolbarRadio value="left" label={<AlignHorizontalLeftIcon sx={{ fontSize: '20px', color: '#989191' }} />} />
                <ToolbarRadio value="center" label={<AlignHorizontalCenterIcon sx={{ fontSize: '20px', color: '#989191' }} />} />
                <ToolbarRadio value="right" label={<AlignHorizontalRightIcon sx={{ fontSize: '20px', color: '#989191' }} />} />
              </ToolbarItem>
              <ToolbarItem propKey="fontWeight" type="radio" label="Weight">
                <ToolbarRadio value="400" label={<div style={{ fontWeight: '400', width: 'auto' }}>Regular</div>} />
                <ToolbarRadio value="500" label={<div style={{ fontWeight: '500', width: 'auto' }}>Medium</div>} />
                <ToolbarRadio value="700" label={<div style={{ fontWeight: '700', width: 'auto' }}>Bold</div>} />
              </ToolbarItem>
            </ToolbarSection>

            <ToolbarSection
              expanded={true}
              title="margin"
              props={['margin']}
              summary={({ }) => {
                return;
              }}
            >
              <ToolbarItem propKey="marginTop" type="slider"
                unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}

                label="Top" />
              <ToolbarItem propKey="marginRight" type="slider"
                unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}

                label="Right" />
              <ToolbarItem propKey="marginBottom" type="slider"
                unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}

                label="Bottom" />
              <ToolbarItem propKey="marginLeft" type="slider"
                unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}

                label="Left" />
            </ToolbarSection>
            <ToolbarSection
              expanded={true}
              title="Appearance"
              props={['color', 'shadow']}
              summary={({ color, shadow }) => {
                return (
                  <div className="fletext-right">
                    <p
                      style={{
                        border: '1px solid red',
                        color: color && `rgba(${Object.values(color)})`,
                        textShadow: shadow && `0px 0px 2px rgba(0, 0, 0, ${shadow / 100})`,
                      }}
                      className="text-white text-right"
                    >
                      T
                    </p>
                  </div>
                );
              }}
            >
              <ToolbarItem full={true} propKey="color" type="bg" label="Text" />

            </ToolbarSection>
          </div>
        ) : null}
      </div>
    </div>

  )
}