/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import { Element, useEditor, useNode } from '@craftjs/core';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { ImageCSettings } from './ImageSettings';
import { useActiveIdContext } from '../../../../ActiveIdContext';
import { useOptionsContext } from '../../../../OptionsContext';
import { Parent } from '../../Parent/index.js';
import { Wrapper } from '../../wrapper/index.js';
import { Text } from '../Text';
import { useInView } from 'react-hook-inview';


export const ImageC = (props) => {
  const {
    connectors: { connect, drag },
    actions: {setProp},
  } = useNode((node) => ({
    selected: node.events.selected,
  }));

  const { enabled } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));

  const { state, dispatch: setActiveId } = useActiveIdContext();
  const { activeId: activeId } = state;

  const { state: idState, dispatch: idDispatch } = useOptionsContext();
  const { options: treeData } = idState;

  const [hovered, setHovered] = useState(false)
  const [inViewRef, inView] = useInView()

  const mergedRef = useRef(null)

 
  const setRef = useCallback((node) => {
    if (mergedRef.current !== node) {
      if (node) {
        connect(node);
        inViewRef(node);
      }
      mergedRef.current = node;
    }
  }, []);

  const { 
    className,
    background,
    color,
    margin,
    href,
    src,
    style,
    width,
    height,
    maxWidth,
    minWidth,
    padding,
    fit,
    isBasic,
    marginTop,
    marginBottom,
    marginLeft,
    marginRight,
    paddingTop,
    paddingBottom,
    paddingLeft,
    paddingRight,
    borderColor,
    borderWidth,
    borderRadius,
    shadowX,
    shadowY,
    shadowBlur,
    shadowColor,
    positions,
    unit,
    convertToTxt = false,
    ...otherProps 

  } = props;

  
  const handleProperty = (value, propertyName, split) => {
    if (value !== null && !split) {
      return { [propertyName]: value?.split(" ").join("") }
    }

    if (value !== null && split === 'splitBg') {
      return { [propertyName]:  value !== null ? value : '' }
    } else {
      return;
    }
  };


    const styleProps = useMemo(() => ({
      ...(color !== null && {
        color: color?.startsWith('#') ? color : 'transparent',
        backgroundImage: !color?.startsWith('#') ? color : undefined,
        WebkitBackgroundClip: !color?.startsWith('#') ? 'text' : undefined,
        backgroundClip: !color?.startsWith('#') ? 'text' : undefined,
      }),

        boxShadow: `${(shadowX?.split(" ").join(""))} ${(shadowY?.split(" ").join(""))} ${(shadowBlur?.split(" ").join(""))} ${shadowColor || `rgba(255, 255, 255)`}`
    ,
    
    ...handleProperty(width, 'width'),
    ...handleProperty(minWidth, 'minWidth'),

    ...handleProperty(height, 'height'),

    ...handleProperty(background, 'background', 'splitBg'),

    ...handleProperty(marginTop, 'marginTop'),
    ...handleProperty(marginRight, 'marginRight'),
    ...handleProperty(marginBottom, 'marginBottom'),
    ...handleProperty(marginLeft, 'marginLeft'),

    ...handleProperty(paddingTop, 'paddingTop'),
    ...handleProperty(paddingRight, 'paddingRight'),
    ...handleProperty(paddingBottom, 'paddingBottom'),
    ...handleProperty(paddingLeft, 'paddingLeft'),

    ...handleProperty(borderColor, 'borderColor'),
    ...handleProperty(borderWidth, 'borderWidth'),
    ...handleProperty(borderRadius, 'borderRadius'),

    }), [
      color, width, height,
      shadowX,
      shadowY,
      shadowColor,
      shadowBlur, background, borderRadius, paddingBottom, paddingLeft, paddingRight, paddingTop, borderColor, borderWidth, marginTop, marginRight, marginBottom, marginLeft]);


  return (
    <>
    {convertToTxt? (
    <Element canvas is={Parent} id='parentId12text' className="w-auto h-auto">
      <Element canvas id='random-id-400text' is={Parent} width={'100%'} height={'100%'}>
        <Text text='Text'/>
      </Element>
    </Element>
    ) : (

    <img
    alt='img'
      val={href?.name} _id="sidebarItemx"
      onClick={() => {
        if(href && !enabled) {
          if(href._id.includes("www")) {
            window.open("https://" + href?.name, "_blank")
          } else {
            const parentItem = treeData.find((x) => x._id === href._id);

            if (parentItem) {
              setActiveId(parentItem);
            } else {
              let childItem = null;
            
              for (const parent of treeData) {
                const child = parent.children.find((child) => child._id === href._id);
            
                if (child) {
                  childItem = child;
                  break;
                }
              }
            
              setActiveId(childItem);
            }

          }
        } 
      }}
      className={className}
      ref={setRef}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      src={src || 'https://i.ibb.co/72vs9kd/2.jpg'}
      style={{
        ...styleProps,
        ...style,
        objectFit: fit !== null ? fit : 'cover', 
        cursor: href && !enabled ? "pointer" : "auto",
        display: isBasic? 'block' : ''
      }}
      // {...otherProps}
    />    
    )}
    </>
  );
};

ImageC.craft = {
  displayName: 'Image',
  props: {
    background: null,
    color: null,
    Decoration:'full',
    text: 'ImageC',
    shadowX: null,
    shadowY: null,
    shadowColor:null,
    shadowBlur: null,
borderRadius: null,
    borderColor:null,
    borderWidth:null,
    transition:null,
    href:null,
    src:null,
    fit:null,
    width:null,
    maxWidth:null,
    minWidth:null,
    convertToTxt: false,
    height:null,
    marginTop:null,
    marginBottom:null,
    marginLeft:null,
    marginRight:null,
    paddingTop:null,
    paddingBottom:null,
    paddingLeft:null,
    paddingRight:null,
    paddingTop:null,
    paddingBottom:null,
    paddingLeft:null,
    paddingRight:null,
    isBasic:false,
    isImage:true,
  },
  related: {
    toolbar: ImageCSettings,
  },
};