/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, { useEffect, useRef, useState } from 'react';
import { Element, useEditor, useNode } from '@craftjs/core';
import { NewPriceSettings } from './newPriceSetting';
import { Text } from '../../basic/Text';
import { Button } from '../../basic/Button';
import { Parent } from '../../../selectors/Parent/index';
import { Wrapper } from '../../../selectors/wrapper/index';
import { BounceLoader } from 'react-spinners';

export const Price_2 = ({ selected }) => {

  const { 
    actions: { setProp },
      connectors: {
        connect
      } 
  } = useNode();

  
  const {
    enabled,
  } = useEditor((state, query) => ({
    enabled: state.options.enabled,
 
  }));

  const styles = [

<Element canvas is={Parent} id='parentId100' className="w-[100%] h-auto" >
  <Element canvas is={Parent} id='parentId200' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-400' is='parent' className='w-[100%] h-full py-10'  style={{background:'#FFFFFF'}}>
<Wrapper canvas id='random-id-401' is='container' className="container">
<Wrapper canvas id='random-id-402' is='div' className="text-center pt-10  flex flex-col text-center" > 
<Wrapper canvas style={{ display: "inline-block", fontFamily:'Poppins' }} className={`text-[46px] font-bold mb-2 text-[#000000]`} id="random-id-1" is="div">
<Text text="Pricing Plan" />
</Wrapper>
    <Wrapper canvas style={{ display: "inline-block", fontFamily:'Poppins', fontWeight:'400' }} className={`px-[2%] flex justify-center text-[24px] items-center flex my-4  text-[#000000]`}  id="random-id-2" is="div">
    <Text text="Sed sed risus pretium quam vulputate dignissim. Eget nunc lobortis mattis aliquam faucibus purus in. Potenti nullam ac tortor vitae purus faucibus ornare suspendisse." />
    </Wrapper>
</Wrapper>

<Wrapper canvas id='random-id-403' is='wrapper' className="w-[100%] flex flex-row flex-wrap align-content-center flex justify-center py-4 justify-content-center">

    <Wrapper canvas id='random-id-404' is='div' className=" mx-4 items-center flex my-4 flex px-7 self-align-bottom flex-col min-w-[300px] max-w-[358px] h-full hover:scale-105 duration-300" style={{borderRadius:'30px', border:'1px solid #FFC92E', flex:'28%'}}>
        <Wrapper canvas id='random-id-405' is='div' className='flex flex-col'>
        <Wrapper canvas style={{ display: "inline-block", fontWeight:'700', fontFamily:'Poppins'}} className={`w-[full] h-[50px] my-4 text-center text-[36px] text-[#000000]`} id="random-id-3" is="div">
        <Text text="Free" />
        </Wrapper>
        <Wrapper canvas style={{ display: "inline-block", fontSize:'16px', fontWeight:'400' }} className="content mb-10 font-normal text-center text-[#100103]" id="random-id-4" is="div">
        <Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
        </Wrapper>
        <Wrapper canvas id='random-id-406' is='div' className="flex flex-col w-full flex justify-center my-3">
            <Wrapper canvas style={{ display: "inline-block", fontSize:'36px', fontWeight:'700', fontFamily:'Poppins', justifyContent:'center'}} className=" text-center items-center text-[#2479FD]" id="random-id-5" is="div">
            <Text text="0$" />
            </Wrapper>
            <Wrapper canvas style={{ display: "inline-block", fontFamily:'Poppins', justifyContent:'center' }} className="items-center text-[16px] font-normal text-center text-[#100103]" id="random-id-6" is="div">
            <Text text="per month" />
            </Wrapper>
        </Wrapper>
        </Wrapper>
        <Wrapper canvas id='random-id-200' is='div' className='w-[100%] justify-center flex' style={{borderRadius:'14px'}}>
            <Button  classn='block' class='py-[12px] max-w-[280px] min-w-[240px] h-[60px] bg-[#FFC92E] hover:bg-[#FFF5D7] border-1 border-[#FFC92E] hover:border-[#FFC92E] flex justify-center cursor-pointer flex justify-center flex mb-4' style={{}}>
                <Wrapper canvas style={{display:'inline-block', fontSize:'22px', fontWeight:'bold', fontFamily:'Poppins', color:'#01060E', borderRadius:'14px'}} id='random-id-7' is='div'>
                    <Text text='Get Started'/>
                </Wrapper>
                {/* <div className='mx-2'>
                    <ArrowCircleRightOutlinedIcon sx={{color:'#01060E', fontSize:'28px'}}/>
                </div> */}
            </Button>
        </Wrapper>
    </Wrapper>
    <Wrapper canvas id='random-id-407' is='div' className=" mx-2 flex p-7 self-align-bottom flex-col min-w-[300px] max-w-[358px] h-full hover:scale-105 duration-300 " style={{background:'#2479FD', borderRadius:'30px', flex:'28%'}}>
        <Wrapper canvas id='random-id-408' is='div' className='flex flex-col'>
            <Wrapper canvas style={{display:'inline-block', fontWeight:'700',fontFamily:'Poppins'}} className={`w-[full] h-[50px] my-4 text-center text-[36px] text-[#FFFFFF]`} id='random-id-8' is='div'>
                <Text text='Premium'/>
            </Wrapper>
        <Wrapper canvas style={{ display: "inline-block", fontSize:'16px', fontWeight:'400', fontFamily:'Poppins' }} className="content  mb-10 font-normal text-center text-[#FFFFFF]"  id="random-id-9" is="div">
        <Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
        </Wrapper>
        <Wrapper canvas id='random-id-409' is='div' className="flex flex-col w-full  flex justify-center my-3 ">
            <Wrapper canvas style={{display:'inline-block', fontSize:'36px', fontWeight:'700', fontFamily:'Poppins', justifyContent:'center'}} className=" items-center text-[#FFFFFF] text-center"  id='random-id-10' is='div'>
                <Text text='12$'/>
            </Wrapper>
            <Wrapper canvas style={{ display: "inline-block", fontFamily:'Poppins', justifyContent:'center' }} className="items-center text-[16px] font-normal text-center text-[#FFFFFF]"  id="random-id-11" is="div">
            <Text text="per month" />
            </Wrapper>
        </Wrapper>
        </Wrapper>
        <Wrapper canvas id='random-id-201' is='div' className='w-[100%] justify-center flex' style={{borderRadius:'14px'}}>
            <Button  classn='block' class='py-[12px] max-w-[280px] min-w-[240px] h-[60px] bg-[#FFC92E] hover:bg-[#FFF5D7] border-1 border-[#FFC92E] hover:border-[#FFC92E] flex justify-center cursor-pointer flex justify-center flex mb-4' style={{}}>
                <Wrapper canvas style={{display:'inline-block', fontSize:'22px', fontWeight:'bold', fontFamily:'Poppins', color:'#01060E', borderRadius:'14px'}} id='random-id-12' is='div'>
                    <Text text='Get Started'/>
                </Wrapper>
                {/* <div className='mx-2'>
                    <ArrowCircleRightOutlinedIcon sx={{color:'#01060E', fontSize:'28px'}}/>
                </div> */}
            </Button>
        </Wrapper>
    </Wrapper>
    <Wrapper canvas id='random-id-410' is='div' className=" mx-4 items-center flex my-4 flex px-7 flex-col min-w-[300px] max-w-[358px] h-full hover:scale-105 duration-300" style={{borderRadius:'30px', border:'1px solid #FFC92E', flex:'28%'}}>
        <Wrapper canvas id='random-id-411' is='div' className='flex flex-col'>
        <Wrapper canvas style={{ display: "inline-block" }} className={`w-[full] h-[50px] my-4 text-center text-[36px] text-[#000000]`}  id="random-id-13" is="div">
        <Text text="Basic" />
        </Wrapper>
        <Wrapper canvas style={{ display: "inline-block", fontSize:'16px', fontWeight:'400', fontFamily:'Poppins' }} className="content  mb-10 font-normal text-center text-[#000000]" id="random-id-14" is="div">
        <Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
        </Wrapper>
        <Wrapper canvas id='random-id-412' is='div' className="flex flex-col flex justify-center w-full  my-3 ">
            <Wrapper canvas style={{ display: "inline-block", fontSize:'36px', fontWeight:'700', fontFamily:'Poppins', justifyContent:'center' }} className=" items-center text-center text-[#2479FD]"  id="random-id-15" is="div">
            <Text text="8$" />
            </Wrapper>
            <Wrapper canvas style={{ display: "inline-block", fontFamily:'Poppins', justifyContent:'center'}} className="items-center text-[16px] font-normal text-center text-[#100103]"  id="random-id-16" is="div">
            <Text text="per month" />
            </Wrapper>
        </Wrapper>
        </Wrapper>
        <Wrapper canvas id='random-id-202' is='div' className='w-[100%] justify-center flex' style={{borderRadius:'14px'}}>
            <Button  classn='block' class='py-[12px] max-w-[280px] min-w-[240px] h-[60px] bg-[#FFC92E] hover:bg-[#FFF5D7] border-1 border-[#FFC92E] hover:border-[#FFC92E] flex justify-center cursor-pointer flex justify-center flex mb-4' style={{}}>
                <Wrapper canvas style={{display:'inline-block', fontSize:'22px', fontWeight:'bold', fontFamily:'Poppins', color:'#01060E', borderRadius:'14px'}} id='random-id-17' is='div'>
                    <Text text='Get Started'/>
                </Wrapper>
                {/* <div className='mx-2'>
                    <ArrowCircleRightOutlinedIcon sx={{color:'#01060E', fontSize:'28px'}}/>
                </div> */}
            </Button>
        </Wrapper>
    </Wrapper>
</Wrapper>
</Wrapper>
</Wrapper> </Element>
  </Element>
    ,
    
<Element canvas is={Parent} id='parentId300' className="w-[100%] h-auto" >
  <Element canvas is={Parent} id='parentId400' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-413' is='parent' className='w-[100%] h-full py-10'  style={{background:'#FFFFFF'}}>
<Wrapper canvas id='random-id-414' is='container' className="w-[100%]">
<Wrapper canvas id='random-id-415' is='div' className="text-center pt-10  flex flex-col text-center" >        
<Wrapper canvas style={{ display: "inline-block", fontFamily:'Prompt' }} className={`text-[46px] font-bold mb-2 text-[#000000]`} id="random-id-18" is="div">
<Text text="Pricing Plan" />
</Wrapper>
<Wrapper canvas style={{ display: "inline-block", fontFamily:'Prompt', fontWeight:'400' }} className={`px-[2%] flex justify-center text-[24px] items-center flex my-4  text-[#000000]`}  id="random-id-19" is="div">
<Text text="Sed sed risus pretium quam vulputate dignissim. Eget nunc lobortis mattis aliquam faucibus purus in. Potenti nullam ac tortor vitae purus faucibus ornare suspendisse." />
</Wrapper>
</Wrapper>

<Wrapper canvas id='random-id-416' is='wrapper' className="w-[100%] flex flex-row flex-wrap align-content-center flex justify-center py-4 justify-content-center">

    <Wrapper canvas id='random-id-417' is='div' className=" mx-4 items-center flex my-4 flex px-7 self-align-bottom flex-col min-w-[300px] max-w-[358px] h-full hover:scale-105 duration-300" style={{borderRadius:'30px', border:'1px solid #FFC92E', flex:'28%'}}>
        <Wrapper canvas id='random-id-418' is='div' className='flex flex-col'>
        <Wrapper canvas style={{ display: "inline-block", fontWeight:'700', fontFamily:'Prompt'}} className={`w-[full] h-[50px] my-4 text-center text-[36px] text-[#000000]`} id="random-id-20" is="div">
        <Text text="Free" />
        </Wrapper>
        <Wrapper canvas style={{ display: "inline-block", fontSize:'16px', fontWeight:'400' }} className="content mb-10 font-normal text-center text-[#100103]" id="random-id-21" is="div">
        <Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
        </Wrapper>
        <Wrapper canvas id='random-id-419' is='div' className="flex flex-col w-full flex justify-center my-3 ">
            <Wrapper canvas style={{ display: "inline-block", fontSize:'36px', fontWeight:'700', fontFamily:'Prompt', justifyContent:'center'}} className=" text-center items-center text-[#FF0060]" id="random-id-22" is="div">
            <Text text="$0" />
            </Wrapper>
            <Wrapper canvas style={{ display: "inline-block", fontFamily:'Prompt', justifyContent:'center'}} className="items-center text-[16px] font-normal text-center text-[#100103]" id="random-id-23" is="div">
            <Text text="per month" />
            </Wrapper>
        </Wrapper>
        </Wrapper>
        <Wrapper canvas id='random-id-203' is='div' className='w-[100%] justify-center flex' style={{borderRadius:'30px'}}>
            <Button  classn='block' class='py-[12px] max-w-[280px] min-w-[240px] h-[60px] bg-[#FF0060] hover:bg-[#C20049] text-[#FFFFFF] flex justify-center cursor-pointer flex justify-center flex mb-4' style={{}}>
                <Wrapper canvas style={{display:'inline-block', fontSize:'22px', fontWeight:'bold', fontFamily:'Prompt', borderRadius:'30px'}} id='random-id-24' is='div'>
                    <Text text='Get Started'/>
                </Wrapper>
                {/* <div className='mx-2'>
                    <ArrowCircleRightOutlinedIcon sx={{color:'#FFFFFF', fontSize:'28px'}}/>
                </div> */}
            </Button>
        </Wrapper>
    </Wrapper>
    <Wrapper canvas id='random-id-420' is='div' className=" mx-2 flex p-7 self-align-bottom flex-col min-w-[300px] max-w-[358px] h-full hover:scale-105 duration-300 " style={{background:'#FF0060', borderRadius:'30px', flex:'28%'}}>
        <Wrapper canvas id='random-id-421' is='div' className='flex flex-col'>
        <Wrapper canvas style={{display:'inline-block', fontWeight:'700',fontFamily:'Prompt'}} className={`w-[full] h-[50px] my-4  text-center text-[36px] text-[#FFFFFF]`} id='random-id-25' is='div'>
                <Text text='Premium'/>
            </Wrapper>
        <Wrapper canvas style={{ display: "inline-block", fontSize:'16px', fontWeight:'400', fontFamily:'Prompt' }} className="content  mb-10 font-normal text-center text-[#FFFFFF]" id="random-id-26" is="div">
        <Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
        </Wrapper>
        <Wrapper canvas id='random-id-422' is='div' className="flex flex-col w-full  flex justify-center my-3 ">
            <Wrapper canvas style={{display:'inline-block', fontSize:'36px', fontWeight:'700', fontFamily:'Prompt', justifyContent:'center'}} className=" items-center text-[#FFFFFF] text-center" id='random-id-27' is='div'>
                <Text text='$12'/>
            </Wrapper>
            <Wrapper canvas style={{ display: "inline-block", fontFamily:'Prompt', justifyContent:'center' }} className="items-center text-[16px] font-normal text-center text-[#FFFFFF]"  id="random-id-28" is="div">
            <Text text="per month" />
            </Wrapper>
        </Wrapper>
        </Wrapper>
        <Wrapper canvas id='random-id-204' is='div' className='w-[100%] justify-center flex' style={{borderRadius:'30px'}}>
            <Button  classn='block' class='py-[12px] max-w-[280px] min-w-[240px] h-[60px] bg-[#F6FA70] hover:bg-[#F6FD00] text-[#000] flex justify-center cursor-pointer flex justify-center flex mb-4' style={{ borderRadius:'30px'}}>
                <Wrapper canvas style={{display:'inline-block', fontSize:'22px', fontWeight:'bold', fontFamily:'Prompt', color:'#FFFFFF',}} id='random-id-29' is='div'>
                    <Text text='Get Started'/>
                </Wrapper>
                {/* <div className='mx-2'>
                    <ArrowCircleRightOutlinedIcon sx={{color:'#FFFFFF', fontSize:'28px'}}/>
                </div> */}
            </Button>
        </Wrapper>
    </Wrapper>
    <Wrapper canvas id='random-id-423' is='div' className=" mx-4 items-center flex my-4 flex px-7 flex-col min-w-[300px] max-w-[358px] h-full hover:scale-105 duration-300" style={{borderRadius:'30px', border:'1px solid #F6FA70', flex:'28%'}}>
        <Wrapper canvas id='random-id-424' is='div' className='flex flex-col'>
        <Wrapper canvas style={{ display: "inline-block", fontWeight:'700', fontFamily:'Prompt'}} className={`w-[full] h-[50px] my-4 text-center  text-[36px] text-[#000000]`} id="random-id-30" is="div">
        <Text text="Basic" />
        </Wrapper>
        <Wrapper canvas style={{ display: "inline-block", fontSize:'16px', fontWeight:'400', fontFamily:'Prompt'}} className="content  mb-10 font-normal text-center text-[#000000]" id="random-id-31" is="div">
        <Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
        </Wrapper>
        <Wrapper canvas id='random-id-425' is='div' className="flex flex-col flex justify-center w-full  my-3 ">
            <Wrapper canvas style={{ display: "inline-block", fontSize:'36px', fontWeight:'700', fontFamily:'Prompt', justifyContent:'center'}} className=" items-center text-center text-[#FF0060]" id="random-id-32" is="div">
            <Text text="$8" />
            </Wrapper>
            <Wrapper canvas style={{ display: "inline-block", fontFamily:'Prompt', justifyContent:'center'}} className="items-center text-[16px] font-normal text-center text-[#100103]" id="random-id-33" is="div">
            <Text text="per month" />
            </Wrapper>
        </Wrapper>
        </Wrapper>
        <Wrapper canvas id='random-id-205' is='div' className='w-[100%] justify-center flex' style={{borderRadius:'30px'}}>
            <Button  classn='block' class='py-[12px] max-w-[280px] min-w-[240px] h-[60px] bg-[#FF0060] hover:bg-[#C20049] flex justify-center cursor-pointer flex justify-center flex mb-4' style={{ borderRadius:'30px'}}>
                <Wrapper canvas style={{display:'inline-block', fontSize:'22px', fontWeight:'bold', fontFamily:'Prompt', color:'#FFFFFF',}} id='random-id-34' is='div'>
                    <Text text='Get Started'/>
                </Wrapper>
                {/* <div className='mx-2'>
                    <ArrowCircleRightOutlinedIcon sx={{color:'#FFFFFF', fontSize:'28px'}}/>
                </div> */}
            </Button>
        </Wrapper>
    </Wrapper>
</Wrapper>
</Wrapper>
</Wrapper> </Element>
  </Element>,


<Element canvas is={Parent} id='parentId500' className="w-[100%] h-auto" >
  <Element canvas is={Parent} id='parentId600' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-426' is='parent' className='w-[100%] h-full py-10'  style={{background:'#FFFFFF'}}>
<Wrapper canvas id='random-id-427' is='container' className="w-[100%]">
<Wrapper canvas id='random-id-428' is='div' className="text-center pt-10  flex flex-col text-center" >        
<Wrapper canvas style={{ display: "inline-block", fontFamily:'Almarai'}} className={`text-[46px] font-bold mb-2 text-[#000000]`} id="random-id-35" is="div">
<Text text="Pricing Plan" />
</Wrapper>
<Wrapper canvas style={{ display: "inline-block", fontFamily:'Almarai', fontWeight:'400'}} className={`px-[2%] flex justify-center text-[24px] items-center flex my-4  text-[#000000]`} id="random-id-36" is="div">
<Text text="Sed sed risus pretium quam vulputate dignissim. Eget nunc lobortis mattis aliquam faucibus purus in. Potenti nullam ac tortor vitae purus faucibus ornare suspendisse." />
</Wrapper>
</Wrapper>

<Wrapper canvas id='random-id-429' is='wrapper' className="w-[100%] w-[100%] flex flex-row flex-wrap align-content-center flex justify-center py-4 justify-content-center">

    <Wrapper canvas id='random-id-430' is='div' className=" mx-4 items-center flex my-4 flex px-7 self-align-bottom flex-col min-w-[300px] max-w-[358px] h-full hover:scale-105 duration-300" style={{borderRadius:'20px 8px 8px 20px', border:'1px solid #FFB7B7', flex:'28%'}}>
        <Wrapper canvas id='random-id-431' is='div' className='flex flex-col'>
        <Wrapper canvas style={{ display: "inline-block", fontWeight:'700', fontFamily:'Almarai'}} className={`w-[full] h-[50px] my-4 text-center text-[36px] text-[#000000]`}  id="random-id-37" is="div">
        <Text text="Free" />
        </Wrapper>
        <Wrapper canvas style={{ display: "inline-block", fontSize:'16px', fontWeight:'400'}} className="content mb-10 font-normal text-center text-[#100103]" id="random-id-38" is="div">
        <Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
        </Wrapper>
        <Wrapper canvas id='random-id-432' is='div' className="flex flex-col w-full flex justify-center my-3 ">
            <Wrapper canvas style={{ display: "inline-block", fontSize:'36px', fontWeight:'700', fontFamily:'Almarai', justifyContent:'center'}} className=" text-center items-center text-[#FF0060]"  id="random-id-39" is="div">
            <Text text="$0" />
            </Wrapper>
            <Wrapper canvas style={{ display: "inline-block", fontFamily:'Almarai', justifyContent:'center'}} className="items-center text-[16px] font-normal text-center text-[#100103]" id="random-id-40" is="div">
            <Text text="per month" />
            </Wrapper>
        </Wrapper>
        </Wrapper>
        <Wrapper canvas id='random-id-206' is='div' className='w-[100%] justify-center flex' style={{borderRadius:'14px'}}>
            <Button  classn='block' class='py-[12px] max-w-[280px] min-w-[240px] h-[60px] bg-[#F31559] hover:bg-[#FF5B8D] text-[#FFFFFF] hover:text-[#000] flex justify-center cursor-pointer flex justify-center flex mb-4' style={{ borderRadius:'14px'}}>
                <Wrapper canvas style={{display:'inline-block', fontSize:'22px', fontWeight:'bold', fontFamily:'Almarai',}} id='random-id-41' is='div'>
                    <Text text='Get Started'/>
                </Wrapper>
                {/* <div className='mx-2'>
                    <ArrowCircleRightOutlinedIcon sx={{color:'#FFFFFF', fontSize:'28px'}}/>
                </div> */}
            </Button>
        </Wrapper>
    </Wrapper>
    <Wrapper canvas id='random-id-433' is='div' className=" mx-2 flex p-7 self-align-bottom flex-col min-w-[300px] max-w-[358px] h-full hover:scale-105 duration-300 " style={{borderRadius:'8px 20px 8px 20px', background:'#FF0060', flex:'28%'}}>
        <Wrapper canvas id='random-id-434' is='div' className='flex flex-col'>
            <Wrapper canvas style={{display:'inline-block', fontWeight:'700',fontFamily:'Almarai' }} className={`w-[full] h-[50px] my-4  text-center text-[36px] text-[#FFFFFF]`} id='random-id-42' is='div'>
                <Text text='Premium'/>
            </Wrapper>
        <Wrapper canvas style={{ display: "inline-block", fontSize:'16px', fontWeight:'400', fontFamily:'Almarai' }} className="content  mb-10 font-normal text-center text-[#FFFFFF]" id="random-id-43" is="div">
        <Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
        </Wrapper>
        <Wrapper canvas id='random-id-435' is='div' className="flex flex-col w-full  flex justify-center my-3 ">
            <Wrapper canvas style={{display:'inline-block', fontSize:'36px', fontWeight:'700', fontFamily:'Almarai', justifyContent:'center'}} className=" items-center text-[#FFFFFF] text-center" id='random-id-44' is='div'>
                <Text text='$12'/>
            </Wrapper>
            <Wrapper canvas style={{ display: "inline-block", fontFamily:'Almarai', justifyContent:'center' }} className="items-center text-[16px] font-normal text-center text-[#FFFFFF]" id="random-id-45" is="div">
            <Text text="per month" />
            </Wrapper>
        </Wrapper>
        </Wrapper>
        <Wrapper canvas id='random-id-207' is='div' className='w-[100%] justify-center flex' style={{borderRadius:'14px'}}>
            <Button  classn='block' class='py-[12px] max-w-[280px] min-w-[240px] h-[60px] bg-[#FFB7B7] hover:bg-[#FF5B8D] text-[#000] flex justify-center cursor-pointer flex justify-center flex mb-4' style={{borderRadius:'14px'}}>
                <Wrapper canvas style={{display:'inline-block', fontSize:'22px', fontWeight:'bold', fontFamily:'Almarai', }} id='random-id-46' is='div'>
                    <Text text='Get Started'/>
                </Wrapper>
                {/* <div className='mx-2'>
                    <ArrowCircleRightOutlinedIcon sx={{color:'#FFFFFF', fontSize:'28px'}}/>
                </div> */}
            </Button>
        </Wrapper>
    </Wrapper>
    <Wrapper canvas id='random-id-436' is='div' className=" mx-4 items-center flex my-4 flex px-7 flex-col min-w-[300px] max-w-[358px] h-full hover:scale-105 duration-300" style={{borderRadius:'8px 20px 20px 8px', border:'1px solid #FFB7B7', flex:'28%'}}>
        <Wrapper canvas id='random-id-437' is='div' className='flex flex-col'>
        <Wrapper canvas style={{ display: "inline-block", fontWeight:'700', fontFamily:'Almarai' }} className={`w-[full] h-[50px] my-4 text-center  text-[36px] text-[#000000]`} id="random-id-47" is="div">
        <Text text="Basic" />
        </Wrapper>
        <Wrapper canvas style={{ display: "inline-block", fontSize:'16px', fontWeight:'400', fontFamily:'Almarai' }} className="content  mb-10 font-normal text-center text-[#000000]" id="random-id-48" is="div">
        <Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
        </Wrapper>
        <Wrapper canvas id='random-id-438' is='div' className="flex flex-col flex justify-center w-full  my-3 ">
            <Wrapper canvas style={{ display: "inline-block", fontSize:'36px', fontWeight:'700', fontFamily:'Almarai', justifyContent:'center'}} className=" items-center text-center text-[#FF0060]" id="random-id-49" is="div">
            <Text text="$8" />
            </Wrapper>
            <Wrapper canvas style={{ display: "inline-block", fontFamily:'Almarai', justifyContent:'center'}} className="items-center text-[16px] font-normal text-center text-[#100103]" id="random-id-50" is="div">
            <Text text="per month" />
            </Wrapper>
        </Wrapper>
        </Wrapper>
        <Wrapper canvas id='random-id-208' is='div' className='w-[100%] justify-center flex' style={{borderRadius:'14px'}}>
            <Button  classn='block' class='py-[12px] max-w-[280px] min-w-[240px] h-[60px] bg-[#F31559] hover:bg-[#FF5B8D] text-[#FFFFFF] hover:text-[#000] flex justify-center cursor-pointer flex justify-center flex mb-4' style={{  borderRadius:'14px'}}>
                <Wrapper canvas style={{display:'inline-block', fontSize:'22px', fontWeight:'bold', fontFamily:'Almarai',}} id='random-id-51' is='div'>
                    <Text text='Get Started'/>
                </Wrapper>
                {/* <div className='mx-2'>
                    <ArrowCircleRightOutlinedIcon sx={{color:'#FFFFFF', fontSize:'28px'}}/>
                </div> */}
            </Button>
        </Wrapper>
    </Wrapper>
</Wrapper>
</Wrapper>
</Wrapper> </Element>
  </Element>,


<Element canvas is={Parent} id='parentId700' className="w-[100%] h-auto" >
  <Element canvas is={Parent} id='parentId800' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-439' is='parent' className='w-[100%] h-full py-10'  style={{background:'#FFFFFF'}}>
<Wrapper canvas id='random-id-440' is='container' className="w-[100%]">
<Wrapper canvas id='random-id-441' is='div' className="text-center pt-10  flex flex-col text-center" >        
<Wrapper canvas style={{ display: "inline-block", fontFamily:'Chivo', fontWeight:'400' }} className={`text-[46px] mb-2 text-[#000000]`} id="random-id-52" is="div">
<Text text="Pricing Plan" />
</Wrapper>
<Wrapper canvas style={{ display: "inline-block", fontFamily:'Chivo', fontWeight:'400'}} className={`px-[2%] flex justify-center text-[24px] items-center flex my-4 text-[#000000]`} id="random-id-53" is="div">
<Text text="Sed sed risus pretium quam vulputate dignissim. Eget nunc lobortis mattis aliquam faucibus purus in. Potenti nullam ac tortor vitae purus faucibus ornare suspendisse." />
</Wrapper>
</Wrapper>
<Wrapper canvas id='random-id-442' is='wraper' className="w-[100%] flex flex-row flex-wrap align-content-center flex justify-center py-4 justify-content-center">
    <Wrapper canvas id='random-id-443' is='div' className=" mx-4 items-center flex my-4 flex px-7 self-align-bottom flex-col min-w-[300px] max-w-[358px] h-full hover:scale-105 duration-300" style={{borderRadius:'8px', border:'1px solid #D4ADFC', flex:'28%'}}>
        <Wrapper canvas id='random-id-444' is='div' className='flex flex-col'>
        <Wrapper canvas style={{ display: "inline-block", fontWeight:'700', fontFamily:'Chivo'}} className={`w-[full] h-[50px] my-4 text-center text-[36px] text-[#000000]`} id="random-id-54" is="div">
        <Text text="Free" />
        </Wrapper>
        <Wrapper canvas style={{ display: "inline-block", fontSize:'16px', fontWeight:'400' }} className="content mb-10 font-normal text-center text-[#100103]" id="random-id-55" is="div">
        <Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
        </Wrapper>
        <Wrapper canvas id='random-id-445' is='div' className="flex flex-col w-full flex justify-center my-3 ">
            <Wrapper canvas style={{ display: "inline-block", fontSize:'36px', fontWeight:'700', fontFamily:'Chivo', justifyContent:'center'}} className="text-center items-center text-[#0C134F]" id="random-id-56" is="div">
            <Text text="0$" />
            </Wrapper>
            <Wrapper canvas style={{ display: "inline-block", fontFamily:'Chivo', justifyContent:'center'}} className="items-center text-[16px] font-normal text-center text-[#100103]" id="random-id-57" is="div">
            <Text text="per month" />
            </Wrapper>
        </Wrapper>
        </Wrapper>
        <Wrapper canvas id='random-id-209' is='div' className='w-[100%] justify-center flex' style={{borderRadius:'8px'}}>
            <Button  classn='block' class='py-[12px] max-w-[280px] min-w-[240px] h-[60px] bg-[#D4ADFC] hover:bg-[#C68EFF] flex justify-center cursor-pointer flex justify-center flex mb-4' style={{ borderRadius:'8px'}}>
                <Wrapper canvas style={{display:'inline-block', fontSize:'22px', fontWeight:'400', fontFamily:'Chivo', color:'#0C134F',}} id='random-id-58' is='div'>
                    <Text text='Get Started'/>
                </Wrapper>
                {/* <div className='mx-2'>
                    <ArrowCircleRightOutlinedIcon sx={{color:'#0C134F', fontSize:'28px'}}/>
                </div> */}
            </Button>
        </Wrapper>
    </Wrapper>
    <Wrapper canvas id='random-id-446' is='div' className=" mx-2 flex p-7 self-align-bottom flex-col min-w-[300px] max-w-[358px] h-full hover:scale-105 duration-300 " style={{background:'#0C134F', borderRadius:'8px', flex:'28%'}}>
        <Wrapper canvas id='random-id-447' is='div' className='flex flex-col'>
            <Wrapper canvas style={{display:'inline-block', fontWeight:'700',fontFamily:'Chivo' }} className={`w-[full] h-[50px] my-4 text-center text-[36px] text-[#FFFFFF]`} id='random-id-59' is='div'>
                <Text text='Premium'/>
            </Wrapper>
        <Wrapper canvas style={{ display: "inline-block", fontSize:'16px', fontWeight:'400', fontFamily:'Chivo' }} className="content  mb-10 font-normal text-center text-[#FFFFFF]" id="random-id-60" is="div">
        <Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
        </Wrapper>
        <Wrapper canvas id='random-id-448' is='div' className="flex flex-col w-full  flex justify-center my-3 ">
            <Wrapper canvas style={{display:'inline-block', fontSize:'36px', fontWeight:'700', fontFamily:'Chivo', justifyContent:'center'}} className=" items-center text-[#FFFFFF] text-center" id='random-id-61' is='div'>
                <Text text='12$'/>
            </Wrapper>
            <Wrapper canvas style={{ display: "inline-block", fontFamily:'Chivo', justifyContent:'center'}} className="items-center text-[16px] font-normal text-center text-[#FFFFFF]" id="random-id-62" is="div">
            <Text text="per month" />
            </Wrapper>
        </Wrapper>
        </Wrapper>
        <Wrapper canvas id='random-id-210' is='div' className='w-[100%] justify-center flex' style={{borderRadius:'8px'}}>
            <Button  classn='block' class='py-[12px] max-w-[280px] min-w-[240px] h-[60px] bg-[#D4ADFC] hover:bg-[#C68EFF] flex justify-center cursor-pointer flex justify-center flex mb-4' style={{ borderRadius:'8px'}}>
                <Wrapper canvas style={{display:'inline-block', fontSize:'22px', fontWeight:'400', fontFamily:'Chivo', color:'#0C134F',}} id='random-id-63' is='div'>
                    <Text text='Get Started'/>
                </Wrapper>
                {/* <div className='mx-2'>
                    <ArrowCircleRightOutlinedIcon sx={{color:'#0C134F', fontSize:'28px'}}/>
                </div> */}
            </Button>
        </Wrapper>
    </Wrapper>
    <Wrapper canvas id='random-id-449' is='div' className=" mx-4 items-center flex my-4 flex px-7 flex-col min-w-[300px] max-w-[358px] h-full hover:scale-105 duration-300" style={{borderRadius:'8px', border:'1px solid #D4ADFC', flex:'28%'}}>
        <Wrapper canvas id='random-id-450' is='div'>
        <Wrapper canvas style={{ display: "inline-block", fontWeight:'700', fontFamily:'Chivo' }} className={`w-[full] h-[50px] my-4 text-center text-[36px] text-[#000000]`} id="random-id-64" is="div">
        <Text text="Basic" />
        </Wrapper>
        <Wrapper canvas style={{ display: "inline-block", fontSize:'16px', fontWeight:'400', fontFamily:'Chivo'}} className="content  mb-10 font-normal text-center text-[#000000]" id="random-id-65" is="div">
        <Text text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!" />
        </Wrapper>
        <Wrapper canvas id='random-id-451' is='div' className="flex flex-col flex justify-center w-full  my-3 ">
            <Wrapper canvas style={{ display: "inline-block", fontSize:'36px', fontWeight:'700', fontFamily:'Chivo', justifyContent:'center'}} className="items-center text-center text-[#0C134F]" id="random-id-66" is="div">
            <Text text="8$" />
            </Wrapper>
            <Wrapper canvas style={{ display: "inline-block", fontFamily:'Chivo', justifyContent:'center' }} className="items-center text-[16px] font-normal text-center text-[#100103]" id="random-id-67" is="div">
            <Text text="per month" />
            </Wrapper>
        </Wrapper>
        </Wrapper>
        <Wrapper canvas id='random-id-211' is='div' className='w-[100%] justify-center flex' style={{borderRadius:'8px'}}>
            <Button  classn='block' class='py-[12px] max-w-[280px] min-w-[240px] h-[60px] bg-[#D4ADFC] hover:bg-[#C68EFF] flex justify-center cursor-pointer flex justify-center flex mb-4' style={{borderRadius:'8px'}}>
                <Wrapper canvas style={{display:'inline-block', fontSize:'22px', fontWeight:'400', fontFamily:'Chivo', color:'#0C134F', }} id='random-id-68' is='div'>
                    <Text text='Get Started'/>
                </Wrapper>
                {/* <div className='mx-2'>
                    <ArrowCircleRightOutlinedIcon sx={{color:'#0C134F', fontSize:'28px'}}/>
                </div> */}
            </Button>
        </Wrapper>
    </Wrapper>
</Wrapper>
</Wrapper>
</Wrapper> </Element>
  </Element>
]


const [sel, setSel] = useState(selected)

function makeOdd(number) {
  setProp((prop) => {
    setSel(100);
  }, 1000);

  setTimeout(() => {
    setProp((prop) => {
      setSel(number)
    }, 1000);
  }, 100);


  
}

useEffect(() => {
if(selected || selected >= 0) {
  makeOdd(selected);
}
},[selected])
  
  return ( <div style={{ padding:enabled? "10px" : "0px", display:"flex", justifyContent:"center", alignItems:"center", width:'100%', height:'100%'}} ref={connect}>
  {sel == 100 ? (
          <div style={{height:"85vh", display:"flex", justifyContent:"center", alignItems:"center"}}>
          <BounceLoader
                  size={100}
                  color={"white"}
                  loading={true}
                />
          </div>
         ) : (
          styles[sel]
         )} 
</div>
  );
};

Price_2.craft = {
    displayName: "Price 2",
    props: {
       selected: 1, // here is the default value of the selected style
       length:3,
isBlock: true
      },
      rules: {
        canDrag: () => true,
        canDrop: () => true,
      },
      related: {
        toolbar: NewPriceSettings,
      },
  };