import { useNode, Element, useEditor } from '@craftjs/core';
import React, { useEffect, useState } from 'react';
import { NewWhyUsSettings } from './newWhyusSetting';
import { Text } from '../../basic/Text';
import { ImageC } from '../../basic/Image';
import { Parent } from '../../Parent/index.js';
import { Wrapper } from '../../wrapper/index.js';
import { BounceLoader } from 'react-spinners';


export const WhyUs_2 = ({selected}) => {
    
  const { 
    actions: { setProp },
      connectors: {
        connect
      } 
  } = useNode();

  
  const {
    enabled,
  } = useEditor((state, query) => ({
    enabled: state.options.enabled,
 
  }));

    const styles = [
      <Element canvas is={Parent} id='parentId100' className="w-[100%] h-auto" >
      <Element canvas is={Parent} id='parentId200' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-400' is='parent' className='w-[100%] h-full bg-[#FFFFFF]'>
        <Wrapper canvas id='random-id-401' is='container' className='container'>
          <Wrapper canvas id='random-id-402' is='wrapper' className='w-full h-full flex flex-row flex-wrap justify-center '>
            <Wrapper canvas id='random-id-403' is='div' className=' bg-[#FFFFFF] flex justify-center justify-center h-full flex flex-col p-4' style={{flex:'48%', margin:'0 auto'}} >

              <Wrapper canvas id='random-id-404' is='div' className='w-[100%] h-auto min-h-[300px] flex flex-col bg-[#2479FD] px-10 py-4 flex justify-center' style={{borderRadius:'30px', marginBottom:'20px'}}>
                  <Wrapper canvas style={{display:'inline-block', fontSize:'46px', fontWeight:'bold', fontFamily:'Poppins', color:'#FFFFFF'}} className='mb-4' id='random-id-5' is='div'>
                    <Text text='Why Us'/>
                  </Wrapper>
                  <Wrapper  canvas style={{display:'inline-block', fontSize:'22px', fontWeight:'400px', fontFamily:'Poppins', color:'#FFFFFF'}} id='random-id-6' is='div'>
                    <Text text='Sed sed risus pretium quam vulputate dignissim. Eget nunc lobortis mattis aliquam faucibus purus in. Potenti nullam ac tortor vitae purus faucibus ornare suspendisse. Commodo ullamcorper a lacus vestibulum sed arcu non odio.'/>
                  </Wrapper>
              </Wrapper>

              <Wrapper canvas id='random-id-405' is='div' className='w-[100%] h-[300px]' style={{borderRadius:'30px', marginBottom:'20px'}}>
                  <ImageC className='w-[100%] h-full object-cover' style={{borderRadius:'30px', marginBottom:'20px'}} src='https://i.ibb.co/JRYPKC6/why1-1.jpg'/>
              </Wrapper> 
            </Wrapper>

            <Wrapper canvas id='random-id-406' is='div' className=' bg-[#FFFFFF] flex justify-center justify-center h-[100%] flex flex-col p-4' style={{flex:'48%', margin:'0 auto'}}>
            <Wrapper  canvas id='random-id-407' is='div' className='w-[100%] h-[300px]' style={{borderRadius:'30px', marginBottom:'20px'}}>
                <ImageC className='w-[100%] h-full object-cover' style={{borderRadius:'30px', marginBottom:'20px'}} src='https://i.ibb.co/k1pHH2D/why1-2.jpg'/>
              </Wrapper>

              <Wrapper canvas id='random-id-408' is='div' className='w-[100%] h-auto min-h-[300px] bg-[#F7F7F9] px-10 py-4 ' style={{borderRadius:'30px', marginBottom:'20px'}}>
                    <Wrapper canvas style={{display:'inline-block', fontSize:'46px', fontWeight:'bold', fontFamily:'Poppins', color:'#000000'}} className='mb-4' id='random-id-7' is='div'>
                      <Text text='Why Us'/>
                    </Wrapper>
                    <Wrapper  canvas style={{display:'inline-block', fontSize:'22px', fontWeight:'400px', fontFamily:'Poppins', color:'#000000'}} id='random-id-8' is='div'>
                      <Text text='Sed sed risus pretium quam vulputate dignissim. Eget nunc lobortis mattis aliquam faucibus purus in. Potenti nullam ac tortor vitae purus faucibus ornare suspendisse. Commodo ullamcorper a lacus vestibulum sed arcu non odio.'/>
                    </Wrapper>
              </Wrapper>
            </Wrapper>
          </Wrapper>
        </Wrapper>
      </Wrapper></Element>
      </Element>
      ,
      <Element canvas is={Parent} id='parentId300' className="w-[100%] h-auto" >
      <Element canvas is={Parent} id='parentId400' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-409' is='parent' className='w-[100%] h-full bg-[#FFFFFF]'>
        <Wrapper canvas id='random-id-410' is='container' className='container'>
          <Wrapper canvas id='random-id-411' is='wrapper' className='w-full h-full flex flex-row flex-wrap justify-center'>
            <Wrapper canvas id='random-id-412' is='div' className=' bg-[#FFFFFF] flex justify-center justify-center h-full flex flex-col p-4' style={{flex:'48%', margin:'0 auto'}} >

              <Wrapper canvas id='random-id-413' is='div' className='w-[100%] h-auto min-h-[300px] flex flex-col bg-[#FF0060] px-10 py-4 flex justify-center' style={{borderRadius:'14px', marginBottom:'20px'}}>
                  <Wrapper canvas style={{display:'inline-block', fontSize:'46px', fontWeight:'bold', fontFamily:'Prompt', color:'#FFFFFF'}} className='mb-4' id='random-id-1' is='div'>
                    <Text text='Why Us'/>
                  </Wrapper>
                  <Wrapper  canvas style={{display:'inline-block', fontSize:'22px', fontWeight:'400px', fontFamily:'Prompt', color:'#FFFFFF'}} id='random-id-2' is='div'>
                    <Text text='Sed sed risus pretium quam vulputate dignissim. Eget nunc lobortis mattis aliquam faucibus purus in. Potenti nullam ac tortor vitae purus faucibus ornare suspendisse. Commodo ullamcorper a lacus vestibulum sed arcu non odio.'/>
                  </Wrapper>
              </Wrapper>

              <Wrapper canvas id='random-id-414' is='div' className='w-[100%] h-[300px]' style={{borderRadius:'14px', marginBottom:'20px'}}>
                  <ImageC className='w-[100%] h-full object-cover' style={{borderRadius:'14px', marginBottom:'20px'}} src='https://i.ibb.co/5vdKmrt/why2-2.jpg'/>
              </Wrapper>
            </Wrapper>

            <Wrapper canvas id='random-id-415' is='div' className=' bg-[#FFFFFF] flex justify-center justify-center h-[100%] flex flex-col p-4' style={{flex:'48%', margin:'0 auto'}}>
              <Wrapper  canvas id='random-id-416' is='div' className='w-[100%] h-[300px]' style={{borderRadius:'14px', marginBottom:'20px'}}>
                <ImageC className='w-[100%] h-full object-cover' style={{borderRadius:'14px', marginBottom:'20px'}} src='https://i.ibb.co/d7xKqXf/why2-1.jpg'/>
              </Wrapper>

              <Wrapper canvas id='random-id-417' is='div' className='w-[100%] h-auto min-h-[300px] bg-[#FDFDF3] px-10 py-4' style={{borderRadius:'14px', marginBottom:'20px'}}>
                    <Wrapper canvas style={{display:'inline-block', fontSize:'46px', fontWeight:'bold', fontFamily:'Prompt', color:'#000000'}} className='mb-4' id='random-id-3' is='div'>
                      <Text text='Why Us'/>
                    </Wrapper>
                    <Wrapper  canvas style={{display:'inline-block', fontSize:'22px', fontWeight:'400px', fontFamily:'Prompt', color:'#000000'}} id='random-id-4' is='div'>
                      <Text text='Sed sed risus pretium quam vulputate dignissim. Eget nunc lobortis mattis aliquam faucibus purus in. Potenti nullam ac tortor vitae purus faucibus ornare suspendisse. Commodo ullamcorper a lacus vestibulum sed arcu non odio.'/>
                    </Wrapper>
              </Wrapper>
            </Wrapper>
          </Wrapper>
        </Wrapper>
      </Wrapper></Element>
      </Element>
      ,
      <Element canvas is={Parent} id='parentId500' className="w-[100%] h-auto" >
      <Element canvas is={Parent} id='parentId600' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-418' is='parent' className='w-[100%] h-full bg-[#FFFFFF]'>
      <Wrapper canvas id='random-id-419' is='container' className='container'>
        <Wrapper canvas id='random-id-420' is='wrapper' className='w-full h-full flex flex-row flex-wrap justify-center'>
          <Wrapper canvas id='random-id-421' is='div' className=' bg-[#FFFFFF] flex justify-center justify-center h-full flex flex-col p-4' style={{flex:'48%', margin:'0 auto'}} >

            <Wrapper canvas id='random-id-422' is='div' className='w-[100%] h-auto min-h-[300px] flex flex-col bg-[#F31559] px-10 py-4 flex justify-center' style={{borderRadius:'20px 20px 0px 20px', marginBottom:'20px'}}>
                <Wrapper canvas style={{display:'inline-block', fontSize:'46px', fontWeight:'bold', fontFamily:'Almarai', color:'#FFFFFF'}} className='mb-4' id='random-id-5' is='div'>
                  <Text text='Why Us'/>
                </Wrapper>
                <Wrapper  canvas style={{display:'inline-block', fontSize:'22px', fontWeight:'400px', fontFamily:'Almarai', color:'#FFFFFF'}} id='random-id-6' is='div'>
                  <Text text='Sed sed risus pretium quam vulputate dignissim. Eget nunc lobortis mattis aliquam faucibus purus in. Potenti nullam ac tortor vitae purus faucibus ornare suspendisse. Commodo ullamcorper a lacus vestibulum sed arcu non odio.'/>
                </Wrapper>
            </Wrapper>

              <Wrapper  canvas  className='w-[100%] h-[300px]' style={{borderRadius:'20px 0px 20px 20px', marginBottom:'20px'}} id='random-id-104' is='div'>
                <ImageC className='w-[100%] h-full object-cover' style={{borderRadius:'20px 0px 20px 20px', marginBottom:'20px'}} src='https://i.ibb.co/NW7gRWJ/why3-1.jpg'/>
              </Wrapper>

          </Wrapper>

          <Wrapper canvas id='random-id-423' is='div' className=' bg-[#FFFFFF] flex justify-center justify-center h-[100%] flex flex-col p-4' style={{flex:'48%', margin:'0 auto'}}>

            <Wrapper  canvas className='w-[100%] h-[300px]' style={{borderRadius:'20px 20px 20px 0px', marginBottom:'20px'}} id='random-id-105' is='div'>
              <ImageC className='w-[100%] h-full object-cover' style={{borderRadius:'20px 20px 20px 0px', marginBottom:'20px'}} src='https://i.ibb.co/m8ddRr2/why3-2.jpg'/>
            </Wrapper>

            <Wrapper canvas id='random-id-424' is='div' className='w-[100%] h-auto min-h-[300px] bg-[#FDF4F4] px-10 py-4' style={{borderRadius:'0px 20px 20px 20px', marginBottom:'20px'}}>
                  <Wrapper canvas style={{display:'inline-block', fontSize:'46px', fontWeight:'bold', fontFamily:'Almarai', color:'#000000'}} className='mb-4' id='random-id-7' is='div'>
                    <Text text='Why Us'/>
                  </Wrapper>
                  <Wrapper  canvas style={{display:'inline-block', fontSize:'22px', fontWeight:'400px', fontFamily:'Almarai', color:'#000000'}} id='random-id-8' is='div'>
                    <Text text='Sed sed risus pretium quam vulputate dignissim. Eget nunc lobortis mattis aliquam faucibus purus in. Potenti nullam ac tortor vitae purus faucibus ornare suspendisse. Commodo ullamcorper a lacus vestibulum sed arcu non odio.'/>
                  </Wrapper>
            </Wrapper>
          </Wrapper>
        </Wrapper>
      </Wrapper>
    </Wrapper></Element>
      </Element>
    ,
    <Element canvas is={Parent} id='parentId700' className="w-[100%] h-auto" >
      <Element canvas is={Parent} id='parentId800' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-425' is='parent' className='w-[100%] h-full bg-[#FFFFFF]'>
    <Wrapper canvas id='random-id-426' is='container' className='container'>
      <Wrapper canvas id='random-id-427' is='wrapper' className='w-full h-full flex flex-row flex-wrap justify-center'>
        <Wrapper canvas id='random-id-428' is='div' className=' bg-[#FFFFFF] flex justify-center justify-center h-full flex flex-col p-4' style={{flex:'48%', margin:'0 auto'}} >

          <Wrapper canvas id='random-id-429' is='div' className='w-[100%] h-auto min-h-[300px] flex flex-col bg-[#0C134F] px-10 py-4 flex justify-center' style={{borderRadius:'14px', marginBottom:'20px'}}>
              <Wrapper canvas style={{display:'inline-block', fontSize:'46px', fontWeight:'bold', fontFamily:'Chivo', color:'#FFFFFF'}} className='mb-4' id='random-id-9' is='div'>
                <Text text='Why Us'/>
              </Wrapper>
              <Wrapper  canvas style={{display:'inline-block', fontSize:'22px', fontWeight:'400px', fontFamily:'Chivo', color:'#FFFFFF'}} id='random-id-10' is='div'>
                <Text text='Sed sed risus pretium quam vulputate dignissim. Eget nunc lobortis mattis aliquam faucibus purus in. Potenti nullam ac tortor vitae purus faucibus ornare suspendisse. Commodo ullamcorper a lacus vestibulum sed arcu non odio.'/>
              </Wrapper>
          </Wrapper>

            <Wrapper  canvas className='w-[100%] h-[300px]' style={{borderRadius:'14px', marginBottom:'20px'}} id='random-id-106' is='div'>
              <ImageC className='w-[100%] h-full object-cover' style={{borderRadius:'14px', marginBottom:'20px'}} src='https://i.ibb.co/wpD9Tk4/why4-2.jpg'/>
            </Wrapper>
        </Wrapper>

        <Wrapper canvas id='random-id-430' is='div' className=' bg-[#FFFFFF] flex justify-center justify-center h-[100%] flex flex-col p-4' style={{flex:'48%', margin:'0 auto'}}>
          <Wrapper  canvas className='w-[100%] h-[300px]' style={{borderRadius:'14px', marginBottom:'20px'}} id='random-id-106' is='div'>
            <ImageC className='w-[100%] h-full object-cover' style={{borderRadius:'14px', marginBottom:'20px'}} src='https://i.ibb.co/T2FbfL4/why4-1.jpgs'/>
          </Wrapper>

          <Wrapper canvas id='random-id-431' is='div' className='w-[100%] h-auto min-h-[300px] bg-[#D4ADFC] px-10 py-4 ' style={{borderRadius:'14px', marginBottom:'20px'}}>
                <Wrapper canvas style={{display:'inline-block', fontSize:'46px', fontWeight:'bold', fontFamily:'Chivo', color:'#000000'}} className='mb-4' id='random-id-11' is='div'>
                  <Text text='Why Us'/>
                </Wrapper>
                <Wrapper  canvas style={{display:'inline-block', fontSize:'22px', fontWeight:'400px', fontFamily:'Chivo', color:'#000000'}} id='random-id-12' is='div'>
                  <Text text='Sed sed risus pretium quam vulputate dignissim. Eget nunc lobortis mattis aliquam faucibus purus in. Potenti nullam ac tortor vitae purus faucibus ornare suspendisse. Commodo ullamcorper a lacus vestibulum sed arcu non odio.'/>
                </Wrapper>
          </Wrapper>
        </Wrapper>
      </Wrapper>
    </Wrapper>
  </Wrapper></Element>
      </Element>
  ,
    
    ]

      
    const [sel, setSel] = useState(selected)

function makeOdd(number) {
  setProp((prop) => {
    setSel(100);
  }, 1000);

  setTimeout(() => {
    setProp((prop) => {
      setSel(number)
    }, 1000);
  }, 100);


  
}

useEffect(() => {
if(selected || selected >= 0) {
  makeOdd(selected);
}
},[selected])

    return (
      <div style={{ padding:enabled? "10px" : "0px", display:"flex", justifyContent:"center", alignItems:"center", width:'100%', height:'100%'}} ref={connect}>
       {sel == 100 ? (
          <div style={{height:"85vh", display:"flex", justifyContent:"center", alignItems:"center"}}>
          <BounceLoader
                  size={100}
                  color={"white"}
                  loading={true}
                />
          </div>
         ) : (
          styles[sel]
         )} 
  </div>
    )
}

WhyUs_2.craft = {
    displayName: "WhyUs 2",
    props: {
       selected: 1, // here is the default value of the selected style
       length:3,
isBlock: true
      },
      rules: {
        canDrag: () => true,
        canDrop: () => true,
      },
      related: {
        toolbar: NewWhyUsSettings,
      },
  };
