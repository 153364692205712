/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR falseS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, { useMemo } from 'react';
import { useNode, useEditor } from '@craftjs/core';
import { DividerSettings } from './DividerSettings';
import { useActiveIdContext } from '../../../ActiveIdContext';

export const BasicDivider = ({
  style = {},
  background = '#0867FC30', 
  width = '80%',
  color,
  paddingTop,
  paddingRight,
  paddingBottom,
  paddingLeft,
  marginTop,
  marginRight,
  marginBottom,
  marginLeft,
  height,
  minWidth,
  maxWidth,
  borderColor,
  borderWidth,
  borderRadius = '10px',
  shadowX,
  shadowY,
  shadowBlur,
  shadowColor,
  className,

}) => {
  const {
    connectors: { connect },
    actions: {setProp},
  } = useNode();

  const { enabled } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));

  
  const { state, dispatch: setActiveId } = useActiveIdContext();
  const { activeId: activeId } = state;
   
  const handleProperty = (value, propertyName, split) => {
    if (value !== null && !split) {
      return { [propertyName]: value?.split(" ").join("") }
    }

    if (value !== null && split === 'splitBg') {
      return { [propertyName]:  value !== null ? value : '' }
    } else {
      return;
    }
  };


    const styleProps = useMemo(() => ({
      ...(color !== null && {
        color: color?.startsWith('#') ? color : 'transparent',
        backgroundImage: !color?.startsWith('#') ? color : undefined,
        WebkitBackgroundClip: !color?.startsWith('#') ? 'text' : undefined,
        backgroundClip: !color?.startsWith('#') ? 'text' : undefined,
      }),

        boxShadow: `${(shadowX?.split(" ").join(""))} ${(shadowY?.split(" ").join(""))} ${(shadowBlur?.split(" ").join(""))} ${shadowColor || `rgba(255, 255, 255)`}`
    ,
    
    ...handleProperty(width, 'width'),
    ...handleProperty(minWidth, 'minWidth'),
    ...handleProperty(maxWidth, 'maxWidth'),

    ...handleProperty(height, 'height'),

    ...handleProperty(background, 'background', 'splitBg'),

    ...handleProperty(marginTop, 'marginTop'),
    ...handleProperty(marginRight, 'marginRight'),
    ...handleProperty(marginBottom, 'marginBottom'),
    ...handleProperty(marginLeft, 'marginLeft'),


    ...handleProperty(paddingTop, 'paddingTop'),
    ...handleProperty(paddingRight, 'paddingRight'),
    ...handleProperty(paddingBottom, 'paddingBottom'),
    ...handleProperty(paddingLeft, 'paddingLeft'),

    ...handleProperty(borderRadius, 'borderRadius'),
    ...handleProperty(borderColor, 'borderColor', 'splitBg'),
    ...handleProperty(borderWidth, 'borderWidth'),


    }), [
      color,  width, height,
      shadowX,
      shadowY,
      shadowColor,
      shadowBlur,  background, borderRadius, borderColor, borderWidth,paddingTop, paddingRight, paddingBottom, paddingLeft, marginTop, marginRight, marginBottom, marginLeft]);



  return (
    <div
    ref={connect}
    className={className}
    style={{...styleProps, ...style, padding:'3px 0', margin:'5px auto', justifyContent:'center', alignItems:'center', display:'flex'}}
    >
    </div>
  );
};

BasicDivider.craft = {
  displayName: 'Divider',
  props: {
    color:null,
    background: '#0867FC30',
    shadowX: null,
    shadowY: null,
    shadowColor:null,
    shadowBlur: null,
    borderRadius: '10px',
    borderColor:null,
    borderWidth:null,
    paddingTop: null,
    paddingRight: null,
    paddingBottom: null,
    paddingLeft: null,
    marginTop: null,
    marginRight: null,
    marginBottom: null,
    marginLeft: null,
    width:'80%',
    height:null,
    minWidth: null,
    maxWidth: null,
    isDivider:true,
  },
  related: {
    toolbar: DividerSettings,
  },
};