/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, { useEffect, useState } from 'react';
import { useNode, useEditor, Element } from '@craftjs/core';
import { Text } from '../../basic/Text';
import{ NewFeaturesSettings} from './newFeaturesSetting'
import { TextField, Typography } from '@mui/material';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import { ImageC } from '../../basic/Image';
import { Parent } from '../../Parent/index.js';
import { BounceLoader } from 'react-spinners';
import { Wrapper } from '../../wrapper/index.js';
import { SvgIcon } from '../../basic/Svg';


export const Features_1 = ({selected}) => {
 
    const { 
        actions: { setProp },
          connectors: {
            connect
          } 
      } = useNode();
    
      
      const {
        enabled,
      } = useEditor((state, query) => ({
        enabled: state.options.enabled,
     
      }));

    const styles = [
        
        <Element canvas is={Parent} id='parentId100' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId200' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-400' is='parent' className='w-[100%] h-full bg-[#FFFDFB] py-20'>
            <Wrapper canvas id='random-id-401' is='container' className='container w-full h-full'>
            <Wrapper canvas id='random-id-402' is='wrapper' className='w-full h-full flex flex-row flex-wrap  justify-center'>
                <Wrapper canvas id='random-id-403' is='parent' className=' m-8 min-w-[300px] min-h-[152px] max-h-[auto] flex flex-row flex-wrap' style={{border: '1px solid #DBD9D9', flex:'40%'}}>
                    <Wrapper canvas id='random-id-404' is='parent' className='w-[30%] h-auto justify-center flex my-auto mx-auto'>
                    <SvgIcon fill='#B20312' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
                    </Wrapper>
                    <Wrapper canvas id='random-id-405' is='parent' className='w-[70%] min-w-[300px] h-auto flex flex-col py-4 px-4 mx-auto items-center text-center '>
                            <Wrapper className='flex my-auto' style={{display:'inline-block', fontSize:'22px', fontWeight:'bold', fontFamily:'Rubik', color:'#B20312'}} id='random-id-49' is='div'>
                                <Text text='Sed do eiusmod'/>
                            </Wrapper>
                            <Wrapper className='flex my-auto' style={{display:'inline-block', fontSize:'18px', fontWeight:'400px', fontFamily:'Rubik', color:'#000000'}} id='random-id-50' is='div'>
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                        </Wrapper>
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-406' is='parent' className=' m-8 min-w-[300px] min-h-[152px] max-h-[auto] flex flex-row flex-wrap' style={{border: '1px solid #DBD9D9', flex:'40%'}}>
                    <Wrapper canvas id='random-id-407' is='parent' className='w-[30%] h-auto justify-center flex my-auto mx-auto'>
                    <SvgIcon fill='#B20312' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
                    </Wrapper>
                    <Wrapper canvas id='random-id-408' is='parent' className='w-[70%] min-w-[300px] h-auto flex flex-col py-4 px-4 mx-auto items-center text-center '>
                            <Wrapper className='flex my-auto' style={{display:'inline-block', fontSize:'22px', fontWeight:'bold', fontFamily:'Rubik', color:'#B20312'}} id='random-id-51' is='div'>
                                <Text text='Sed do eiusmod'/>
                            </Wrapper>
                            <Wrapper className='flex my-auto' style={{display:'inline-block', fontSize:'18px', fontWeight:'400px', fontFamily:'Rubik', color:'#000000'}} id='random-id-52' is='div'>
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                        </Wrapper>
                    </Wrapper>
                </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-409' is='parent' className='w-full h-full flex flex-row flex-wrap  justify-center'>
                <Wrapper canvas id='random-id-410' is='parent' className=' m-8 min-w-[300px] min-h-[152px] max-h-[auto] flex flex-row flex-wrap' style={{border: '1px solid #DBD9D9', flex:'40%'}}>
                    <Wrapper canvas id='random-id-411' is='parent' className='w-[30%] h-auto justify-center flex my-auto mx-auto'>
                    <SvgIcon fill='#B20312' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
                    </Wrapper>
                    <Wrapper canvas id='random-id-412' is='parent' className='w-[70%] min-w-[300px] h-auto flex flex-col py-4 px-4 mx-auto items-center text-center '>
                        
                            <Wrapper className='flex my-auto' style={{display:'inline-block', fontSize:'22px', fontWeight:'bold', fontFamily:'Rubik', color:'#B20312'}} id='random-id-53' is='div'>
                                <Text text='Sed do eiusmod'/>
                            </Wrapper>
                        
                        
                        <Wrapper style={{display:'inline-block', fontSize:'18px', fontWeight:'400px', fontFamily:'Rubik', color:'#000000'}} className='flex my-auto' id='random-id-54' is='div'>
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                        </Wrapper>
                        
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-413' is='parent' className=' m-8 min-w-[300px] min-h-[152px] max-h-[auto] flex flex-row flex-wrap' style={{border: '1px solid #DBD9D9', flex:'40%'}}>
                    <Wrapper canvas id='random-id-414' is='parent' className='w-[30%] h-auto justify-center flex my-auto mx-auto'>
                    <SvgIcon fill='#B20312' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
                    </Wrapper>
                    <Wrapper canvas id='random-id-415' is='parent' className='w-[70%] min-w-[300px] h-auto flex flex-col py-4 px-4 mx-auto items-center text-center'>
                        
                            <Wrapper style={{display:'inline-block', fontSize:'22px', fontWeight:'bold', fontFamily:'Rubik', color:'#B20312'}} className='flex my-auto' id='random-id-55' is='div'>
                                <Text text='Sed do eiusmod'/>
                            </Wrapper>
                        
                        <Wrapper style={{display:'inline-block', fontSize:'18px', fontWeight:'400px', fontFamily:'Rubik', color:'#000000'}} className='flex my-auto'  id='random-id-60' is='div'>
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                        </Wrapper>
                        
                    </Wrapper>
                </Wrapper>
            </Wrapper>
            </Wrapper>

        </Wrapper></Element>
        </Element>,


        <Element canvas is={Parent} id='parentId300' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId400' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-416' is='parent' className='w-[100%] h-full bg-[#121826] py-20'>
            <Wrapper canvas id='random-id-417' is='container' className='container w-full h-full'>
            <Wrapper canvas id='random-id-418' is='wrapper' className='w-full h-full flex flex-row flex-wrap  justify-center'>
                <Wrapper canvas id='random-id-419' is='div' className=' m-8 min-w-[300px] min-h-[152px] max-h-[auto] flex flex-row flex-wrap' style={{border: '1px solid #FFFFFF1A', boxShadow: '0px 0px 4px 0px #FFFFFF1A', flex:'40%'}}>
                    <Wrapper canvas id='random-id-420' is='div' className='w-[30%] h-auto justify-center flex my-auto mx-auto'>
                    <SvgIcon fill='#4BDBA2' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
                    </Wrapper>
                    <Wrapper canvas id='random-id-421' is='div' className='w-[70%] min-w-[300px] h-auto flex flex-col py-4 px-4 mx-auto items-center text-center '>
                       
                            <Wrapper className='flex my-auto' style={{display:'inline-block', fontSize:'22px', fontWeight:'bold', fontFamily:'Rubik', color:'#4BDBA2'}} id='random-id-1' is='div'>
                                <Text text='Sed do eiusmod'/>
                            </Wrapper>
                  
                    
                            <Wrapper className='flex my-auto' style={{display:'inline-block', fontSize:'18px', fontWeight:'400px', fontFamily:'Rubik', color:'#FFF'}} id='random-id-2' is='div'>
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                        </Wrapper>
                  
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-422' is='div' className=' m-8 min-w-[300px] min-h-[152px] max-h-[auto] flex flex-row flex-wrap' style={{border: '1px solid #FFFFFF1A', boxShadow: '0px 0px 4px 0px #FFFFFF1A', flex:'40%'}}>
                    <Wrapper canvas id='random-id-423' is='div' className='w-[30%] h-auto justify-center flex my-auto mx-auto'>
                    <SvgIcon fill='#4BDBA2' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
                    </Wrapper>
                    <Wrapper canvas id='random-id-424' is='div' className='w-[70%] min-w-[300px] h-auto flex flex-col py-4 px-4 mx-auto items-center text-center '>
                            <Wrapper className='flex my-auto' style={{display:'inline-block', fontSize:'22px', fontWeight:'bold', fontFamily:'Rubik', color:'#4BDBA2'}} id='random-id-3' is='div'>
                                <Text text='Sed do eiusmod'/>
                            </Wrapper>
                  
                    
                            <Wrapper className='flex my-auto' style={{display:'inline-block', fontSize:'18px', fontWeight:'400px', fontFamily:'Rubik', color:'#FFF'}} id='random-id-4' is='div'>
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                        </Wrapper>
                  
                    </Wrapper>
                </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-425' is='div' className='w-full h-full flex flex-row flex-wrap  justify-center'>
                <Wrapper canvas id='random-id-426' is='div' className=' m-8 min-w-[300px] min-h-[152px] max-h-[auto] flex flex-row flex-wrap' style={{border: '1px solid #FFFFFF1A', boxShadow: '0px 0px 4px 0px #FFFFFF1A', flex:'40%'}}>
                    <Wrapper canvas id='random-id-427' is='div' className='w-[30%] h-auto justify-center flex my-auto mx-auto'>
                    <SvgIcon fill='#4BDBA2' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
                    </Wrapper>
                    <Wrapper canvas id='random-id-428' is='div' className='w-[70%] min-w-[300px] h-auto flex flex-col py-4 px-4 mx-auto items-center text-center '>
                            <Wrapper style={{display:'inline-block', fontSize:'22px', fontWeight:'bold', fontFamily:'Rubik', color:'#4BDBA2'}} className='flex my-auto' id='random-id-5' is='div'>
                                <Text text='Sed do eiusmod'/>
                            </Wrapper>
                        <Wrapper style={{display:'inline-block', fontSize:'18px', fontWeight:'400px', fontFamily:'Rubik', color:'#FFF'}} className='flex my-auto' id='random-id-6' is='div'>
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                        </Wrapper>
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-429' is='div' className=' m-8 min-w-[300px] min-h-[152px] max-h-[auto] flex flex-row flex-wrap' style={{border: '1px solid #FFFFFF1A', boxShadow: '0px 0px 4px 0px #FFFFFF1A', flex:'40%'}}>
                    <Wrapper canvas id='random-id-430' is='div' className='w-[30%] h-auto justify-center flex my-auto mx-auto'>
                    <SvgIcon fill='#4BDBA2' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
                    </Wrapper>
                    <Wrapper canvas id='random-id-431' is='div' className='w-[70%] min-w-[300px] h-auto flex flex-col py-4 px-4 mx-auto items-center text-center'>
                            <Wrapper style={{display:'inline-block', fontSize:'22px', fontWeight:'bold', fontFamily:'Rubik', color:'#4BDBA2'}} className='flex my-auto' id='random-id-7' is='div'>
                                <Text text='Sed do eiusmod'/>
                            </Wrapper>
                        <Wrapper style={{display:'inline-block', fontSize:'18px', fontWeight:'400px', fontFamily:'Rubik', color:'#FFF'}} className='flex my-auto' id='random-id-8' is='div'>
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                        </Wrapper>
                    </Wrapper>
                </Wrapper>
            </Wrapper>
            </Wrapper>

        </Wrapper></Element>
        </Element>,

        <Element canvas is={Parent} id='parentId500' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId600' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-432' is='parent' className='w-[100%] h-full bg-[#213156] py-20'>
            <Wrapper canvas id='random-id-433' is='container' className='container w-full h-full'>
            <Wrapper canvas id='random-id-434' is='wrapper' className='w-full h-full flex flex-row flex-wrap  justify-center'>
                <Wrapper canvas id='random-id-435' is='div' className=' m-8 min-w-[300px] min-h-[152px] max-h-[auto] flex flex-row flex-wrap' style={{border: '1px solid #EA6EF40A', borderRadius:'10px', boxShadow: '0px 0px 4px 0px #FFFFFF1A', flex:'40%'}}>
                    <Wrapper canvas id='random-id-436' is='div' className='w-[30%] h-auto justify-center flex my-auto mx-auto'>
                    <SvgIcon fill='#EA6EF4' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
                    </Wrapper>
                    <Wrapper canvas id='random-id-437' is='div' className='w-[70%] min-w-[300px] h-auto flex flex-col py-4 px-4 mx-auto items-center text-center '>
                      
                            <Wrapper className='flex my-auto' style={{display:'inline-block', fontSize:'22px', fontWeight:'bold', fontFamily:'Open Sans', color:'#EA6EF4'}} id='random-id-9' is='div'>
                                <Text text='Sed do eiusmod'/>
                            </Wrapper>
                      
                            <Wrapper className='flex my-auto' style={{display:'inline-block', fontSize:'18px', fontWeight:'400px', fontFamily:'Open Sans', color:'#FFF'}} id='random-id-10' is='div'>
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                        </Wrapper>
                      
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-438' is='div' className=' m-8 min-w-[300px] min-h-[152px] max-h-[auto] flex flex-row flex-wrap' style={{border: '1px solid #EA6EF40A', borderRadius:'10px', boxShadow: '0px 0px 4px 0px #FFFFFF1A', flex:'40%'}}>
                    <Wrapper canvas id='random-id-439' is='div' className='w-[30%] h-auto justify-center flex my-auto mx-auto'>
                    <SvgIcon fill='#EA6EF4' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
                    </Wrapper>
                    <Wrapper canvas id='random-id-440' is='div' className='w-[70%] min-w-[300px] h-auto flex flex-col py-4 px-4 mx-auto items-center text-center '>
                      
                            <Wrapper className='flex my-auto' style={{display:'inline-block', fontSize:'22px', fontWeight:'bold', fontFamily:'Open Sans', color:'#EA6EF4'}} id='random-id-11' is='div'>
                                <Text text='Sed do eiusmod'/>
                            </Wrapper>
                      
                            <Wrapper className='flex my-auto' style={{display:'inline-block', fontSize:'18px', fontWeight:'400px', fontFamily:'Open Sans', color:'#FFF'}} id='random-id-12' is='div'>
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                        </Wrapper>
                      
                    </Wrapper>
                </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-441' is='div' className='w-full h-full flex flex-row flex-wrap  justify-center'>
                <Wrapper canvas id='random-id-442' is='div' className=' m-8 min-w-[300px] min-h-[152px] max-h-[auto] flex flex-row flex-wrap' style={{border: '1px solid #EA6EF40A', borderRadius:'10px', boxShadow: '0px 0px 4px 0px #FFFFFF1A', flex:'40%'}}>
                    <Wrapper canvas id='random-id-443' is='div' className='w-[30%] h-auto justify-center flex my-auto mx-auto'>
                    <SvgIcon fill='#EA6EF4' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
                    </Wrapper>
                    <Wrapper canvas id='random-id-444' is='div' className='w-[70%] min-w-[300px] h-auto flex flex-col py-4 px-4 mx-auto items-center text-center '>
                            <Wrapper className='flex my-auto' style={{display:'inline-block', fontSize:'22px', fontWeight:'bold', fontFamily:'Open Sans', color:'#EA6EF4'}} id='random-id-13' is='div'>
                                <Text text='Sed do eiusmod'/>
                            </Wrapper>
                        <Wrapper className='flex my-auto' style={{display:'inline-block', fontSize:'18px', fontWeight:'400px', fontFamily:'Open Sans', color:'#FFF'}} id='random-id-14' is='div'>
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                        </Wrapper>
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-445' is='div' className=' m-8 min-w-[300px] min-h-[152px] max-h-[auto] flex flex-row flex-wrap' style={{border: '1px solid #EA6EF40A', borderRadius:'10px', boxShadow: '0px 0px 4px 0px #FFFFFF1A', flex:'40%'}}>
                    <Wrapper canvas id='random-id-446' is='div' className='w-[30%] h-auto justify-center flex my-auto mx-auto'>
                    <SvgIcon fill='#EA6EF4' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
                    </Wrapper>
                    <Wrapper canvas id='random-id-447' is='div' className='w-[70%] min-w-[300px] h-auto flex flex-col py-4 px-4 mx-auto items-center text-center'>
                            <Wrapper style={{display:'inline-block', fontSize:'22px', fontWeight:'bold', fontFamily:'Open Sans', color:'#EA6EF4'}} className='flex my-auto' id='random-id-15' is='div'>
                                <Text text='Sed do eiusmod'/>
                            </Wrapper>
                        <Wrapper style={{display:'inline-block', fontSize:'18px', fontWeight:'400px', fontFamily:'Open Sans', color:'#FFF'}} className='flex my-auto' id='random-id-16' is='div'>
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                        </Wrapper>
                    </Wrapper>
                </Wrapper>
            </Wrapper>
            </Wrapper>
        </Wrapper></Element>
        </Element>,

        
        <Element canvas is={Parent} id='parentId700' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId800' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-448' is='parent' className='w-[100%] h-full bg-[#DFFFF9] py-20'>
            <Wrapper canvas id='random-id-449' is='container' className='container w-full h-full'>
            <Wrapper canvas id='random-id-450' is='wrapper' className='w-full h-full flex flex-row flex-wrap  justify-center'>
                <Wrapper canvas id='random-id-451' is='div' className=' m-8 min-w-[300px] min-h-[152px] max-h-[auto] flex flex-row flex-wrap' style={{border: '1px solid #31A9931A', borderRadius:'30px', boxShadow: '0px 0px 4px 0px #0825201A', flex:'40%'}}>
                    <Wrapper canvas id='random-id-452' is='div' className='w-[30%] h-auto justify-center flex my-auto mx-auto'>
                    <SvgIcon fill='#31A993' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
                    </Wrapper>
                    <Wrapper canvas id='random-id-453' is='div' className='w-[70%] min-w-[300px] h-auto flex flex-col py-4 px-4 mx-auto items-center text-center '>
                            <Wrapper style={{display:'inline-block', fontSize:'22px', fontWeight:'bold', fontFamily:'Open Sans', color:'#31A993'}} className='flex my-auto' id='random-id-17' is='div'>
                                <Text text='Sed do eiusmod'/>
                            </Wrapper>
                            <Wrapper style={{display:'inline-block', fontSize:'18px', fontWeight:'400px', fontFamily:'Open Sans', color:'#31A993'}} className='flex my-auto' id='random-id-18' is='div'>
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                        </Wrapper>
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-454' is='div' className=' m-8 min-w-[300px] min-h-[152px] max-h-[auto] flex flex-row flex-wrap' style={{border: '1px solid #31A9931A', borderRadius:'30px', boxShadow: '0px 0px 4px 0px #0825201A', flex:'40%'}}>
                    <Wrapper canvas id='random-id-455' is='div' className='w-[30%] h-auto justify-center flex my-auto mx-auto'>
                    <SvgIcon fill='#31A993' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
                    </Wrapper>
                    <Wrapper canvas id='random-id-456' is='div' className='w-[70%] min-w-[300px] h-auto flex flex-col py-4 px-4 mx-auto items-center text-center '>
                            <Wrapper style={{display:'inline-block', fontSize:'22px', fontWeight:'bold', fontFamily:'Open Sans', color:'#31A993'}} className='flex my-auto' id='random-id-19' is='div'>
                                <Text text='Sed do eiusmod'/>
                            </Wrapper>
                            <Wrapper style={{display:'inline-block', fontSize:'18px', fontWeight:'400px', fontFamily:'Open Sans', color:'#31A993'}} className='flex my-auto' id='random-id-20' is='div'>
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                        </Wrapper>
                    </Wrapper>
                </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-457' is='div' className='w-full h-full flex flex-row flex-wrap  justify-center'>
                <Wrapper canvas id='random-id-458' is='div' className=' m-8 min-w-[300px] min-h-[152px] max-h-[auto] flex flex-row flex-wrap' style={{border: '1px solid #31A9931A', borderRadius:'30px', boxShadow: '0px 0px 4px 0px #0825201A', flex:'40%'}}>
                    <Wrapper canvas id='random-id-459' is='div' className='w-[30%] h-auto justify-center flex my-auto mx-auto'>
                    <SvgIcon fill='#31A993' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
                    </Wrapper>
                    <Wrapper canvas id='random-id-460' is='div' className='w-[70%] min-w-[300px] h-auto flex flex-col py-4 px-4 mx-auto items-center text-center '>
                            <Wrapper style={{display:'inline-block', fontSize:'22px', fontWeight:'bold', fontFamily:'Open Sans', color:'#31A993'}} className='flex my-auto' id='random-id-21' is='div'>
                                <Text text='Sed do eiusmod'/>
                            </Wrapper>
                        <Wrapper style={{display:'inline-block', fontSize:'18px', fontWeight:'400px', fontFamily:'Open Sans', color:'#31A993'}} className='flex my-auto' id='random-id-22' is='div'>
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                        </Wrapper>
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-461' is='div' className=' m-8 min-w-[300px] min-h-[152px] max-h-[auto] flex flex-row flex-wrap' style={{border: '1px solid #31A9931A', borderRadius:'30px', boxShadow: '0px 0px 4px 0px #0825201A', flex:'40%'}}>
                    <Wrapper canvas id='random-id-462' is='div' className='w-[30%] h-auto justify-center flex my-auto mx-auto'>
                    <SvgIcon fill='#31A993' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
                    </Wrapper>
                    <Wrapper canvas id='random-id-463' is='div' className='w-[70%] min-w-[300px] h-auto flex flex-col py-4 px-4 mx-auto items-center text-center'>
                            <Wrapper style={{display:'inline-block', fontSize:'22px', fontWeight:'bold', fontFamily:'Open Sans', color:'#31A993'}} className='flex my-auto' id='random-id-23' is='div'>
                                <Text text='Sed do eiusmod'/>
                            </Wrapper>
                        <Wrapper style={{display:'inline-block', fontSize:'18px', fontWeight:'400px', fontFamily:'Open Sans', color:'#31A993'}} className='flex my-auto'  id='random-id-24' is='div'>
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                        </Wrapper>
                    </Wrapper>
                </Wrapper>
            </Wrapper>
            </Wrapper>
        </Wrapper></Element>
        </Element>,

        
        <Element canvas is={Parent} id='parentId900' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId1000' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-464' is='parent' className='w-[100%] h-full bg-[#FFFFFF] py-20'>
            <Wrapper canvas id='random-id-465' is='div' className='container w-full h-full'>
            <Wrapper canvas id='random-id-466' is='div' className='w-full h-full flex flex-row flex-wrap  justify-center'>
                <Wrapper canvas id='random-id-467' is='div' className=' m-8 min-w-[300px] min-h-[152px] max-h-[auto] flex flex-row flex-wrap' style={{border: '1px solid #DBD9D9', borderRadius:'4px', boxShadow: '0px 0px 4px 0px #FFFFFF1A', flex:'40%'}}>
                    <Wrapper canvas id='random-id-468' is='div' className='w-[30%] h-auto justify-center flex my-auto mx-auto'>
                    <SvgIcon fill='#0078D3' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
                    </Wrapper>
                    <Wrapper canvas id='random-id-469' is='div' className='w-[70%] min-w-[300px] h-auto flex flex-col py-4 px-4 mx-auto items-center text-center '>
                     
                            <Wrapper className='flex my-auto' style={{display:'inline-block', fontSize:'22px', fontWeight:'bold', fontFamily:'Inter', color:'#0078D3'}} id='random-id-25' is='div'>
                                <Text text='Sed do eiusmod'/>
                            </Wrapper>
                            <Wrapper className='flex my-auto' style={{display:'inline-block', fontSize:'18px', fontWeight:'400px', fontFamily:'Inter', color:'#100103'}} id='random-id-26' is='div'>
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                        </Wrapper>
                       
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-470' is='div' className=' m-8 min-w-[300px] min-h-[152px] max-h-[auto] flex flex-row flex-wrap' style={{border: '1px solid #DBD9D9', borderRadius:'4px', boxShadow: '0px 0px 4px 0px #FFFFFF1A', flex:'40%'}}>
                    <Wrapper canvas id='random-id-471' is='div' className='w-[30%] h-auto justify-center flex my-auto mx-auto'>
                    <SvgIcon fill='#0078D3' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
                    </Wrapper>
                    <Wrapper canvas id='random-id-472' is='div' className='w-[70%] min-w-[300px] h-auto flex flex-col py-4 px-4 mx-auto items-center text-center '>
                     
                            <Wrapper className='flex my-auto' style={{display:'inline-block', fontSize:'22px', fontWeight:'bold', fontFamily:'Inter', color:'#0078D3'}} id='random-id-27' is='div'>
                                <Text text='Sed do eiusmod'/>
                            </Wrapper>
                            <Wrapper className='flex my-auto' style={{display:'inline-block', fontSize:'18px', fontWeight:'400px', fontFamily:'Inter', color:'#100103'}} id='random-id-28' is='div'>
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                        </Wrapper>
                       
                    </Wrapper>
                </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-473' is='div' className='w-full h-full flex flex-row flex-wrap  justify-center'>
                <Wrapper canvas id='random-id-474' is='div' className=' m-8 min-w-[300px] min-h-[152px] max-h-[auto] flex flex-row flex-wrap' style={{border: '1px solid #DBD9D9', borderRadius:'4px', boxShadow: '0px 0px 4px 0px #FFFFFF1A', flex:'40%'}}>
                    <Wrapper canvas id='random-id-475' is='div' className='w-[30%] h-auto justify-center flex my-auto mx-auto'>
                    <SvgIcon fill='#0078D3' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
                    </Wrapper>
                    <Wrapper canvas id='random-id-476' is='div' className='w-[70%] min-w-[300px] h-auto flex flex-col py-4 px-4 mx-auto items-center text-center '>
                            <Wrapper style={{display:'inline-block', fontSize:'22px', fontWeight:'bold', fontFamily:'Inter', color:'#0078D3'}} className='flex my-auto' id='random-id-29' is='div'>
                                <Text text='Sed do eiusmod'/>
                            </Wrapper>
                        <Wrapper style={{display:'inline-block', fontSize:'18px', fontWeight:'400px', fontFamily:'Inter', color:'#100103'}} className='flex my-auto' id='random-id-30' is='div'>
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                        </Wrapper>
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-477' is='div' className=' m-8 min-w-[300px] min-h-[152px] max-h-[auto] flex flex-row flex-wrap' style={{border: '1px solid #DBD9D9', borderRadius:'4px', boxShadow: '0px 0px 4px 0px #FFFFFF1A', flex:'40%'}}>
                    <Wrapper canvas id='random-id-478' is='div' className='w-[30%] h-auto justify-center flex my-auto mx-auto'>
                    <SvgIcon fill='#0078D3' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
                    </Wrapper>
                    <Wrapper canvas id='random-id-479' is='div' className='w-[70%] min-w-[300px] h-auto flex flex-col py-4 px-4 mx-auto items-center text-center'>
                            <Wrapper style={{display:'inline-block', fontSize:'22px', fontWeight:'bold', fontFamily:'Inter', color:'#0078D3'}} className='flex my-auto' id='random-id-31' is='div'>
                                <Text text='Sed do eiusmod'/>
                            </Wrapper>
                        <Wrapper style={{display:'inline-block', fontSize:'18px', fontWeight:'400px', fontFamily:'Inter', color:'#100103'}} className='flex my-auto' id='random-id-32' is='div'>
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                        </Wrapper>
                    </Wrapper>
                </Wrapper>
            </Wrapper>
            </Wrapper>

        </Wrapper></Element>
        </Element>,
        
        
        <Element canvas is={Parent} id='parentId1100' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId1200' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-480' is='parent' className='w-[100%] h-full bg-[#FFFFFF] py-20'>
            <Wrapper canvas id='random-id-481' is='container' className='container w-full h-full'>
            <Wrapper canvas id='random-id-482' is='wrapper' className='w-full h-full flex flex-row flex-wrap  justify-center'>
                <Wrapper canvas id='random-id-483' is='div' className=' m-8 min-w-[300px] min-h-[152px] max-h-[auto] flex flex-row flex-wrap' style={{border: '1px solid #02021233', background:'#EBEBEC', boxShadow: '0px 0px 4px 0px #FFFFFF1A', flex:'40%'}}>
                    <Wrapper canvas id='random-id-484' is='div' className='w-[30%] h-auto justify-center flex my-auto mx-auto'>
                    <SvgIcon fill='#020212' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
                    </Wrapper>
                    <Wrapper canvas id='random-id-485' is='div' className='w-[70%] min-w-[300px] h-auto flex flex-col py-4 px-4 mx-auto items-center text-center '>
                    
                            <Wrapper className='flex my-auto' style={{display:'inline-block', fontSize:'22px', fontWeight:'bold', fontFamily:'Voces', color:'#020212'}} id='random-id-33' is='div'>
                                <Text text='Sed do eiusmod'/>
                            </Wrapper>
                            <Wrapper className='flex my-auto' style={{display:'inline-block', fontSize:'18px', fontWeight:'400px', fontFamily:'Voces', color:'#020212'}} id='random-id-34' is='div'>
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                        </Wrapper>
           
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-486' is='div' className=' m-8 min-w-[300px] min-h-[152px] max-h-[auto] flex flex-row flex-wrap' style={{border: '1px solid #02021233', background:'#EBEBEC', boxShadow: '0px 0px 4px 0px #FFFFFF1A', flex:'40%'}}>
                    <Wrapper canvas id='random-id-487' is='div' className='w-[30%] h-auto justify-center flex my-auto mx-auto'>
                    <SvgIcon fill='#020212' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
                    </Wrapper>
                    <Wrapper canvas id='random-id-488' is='div' className='w-[70%] min-w-[300px] h-auto flex flex-col py-4 px-4 mx-auto items-center text-center '>
                    
                            <Wrapper className='flex my-auto' style={{display:'inline-block', fontSize:'22px', fontWeight:'bold', fontFamily:'Voces', color:'#020212'}} id='random-id-35' is='div'>
                                <Text text='Sed do eiusmod'/>
                            </Wrapper>
                            <Wrapper className='flex my-auto' style={{display:'inline-block', fontSize:'18px', fontWeight:'400px', fontFamily:'Voces', color:'#020212'}} id='random-id-36' is='div'>
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                        </Wrapper>
           
                    </Wrapper>
                </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-489' is='div' className='w-full h-full flex flex-row flex-wrap  justify-center'>
                <Wrapper canvas id='random-id-490' is='div' className=' m-8 min-w-[300px] min-h-[152px] max-h-[auto] flex flex-row flex-wrap' style={{border: '1px solid #02021233', background:'#EBEBEC', boxShadow: '0px 0px 4px 0px #FFFFFF1A', flex:'40%'}}>
                    <Wrapper canvas id='random-id-491' is='div' className='w-[30%] h-auto justify-center flex my-auto mx-auto'>
                    <SvgIcon fill='#020212' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
                    </Wrapper>
                    <Wrapper canvas id='random-id-492' is='div' className='w-[70%] min-w-[300px] h-auto flex flex-col py-4 px-4 mx-auto items-center text-center '>
                            <Wrapper style={{display:'inline-block', fontSize:'22px', fontWeight:'bold', fontFamily:'Voces', color:'#020212'}} className='flex my-auto' id='random-id-37' is='div'>
                                <Text text='Sed do eiusmod'/>
                            </Wrapper>
                        <Wrapper style={{display:'inline-block', fontSize:'18px', fontWeight:'400px', fontFamily:'Voces', color:'#020212'}} className='flex my-auto' id='random-id-38' is='div'>
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                        </Wrapper>
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-493' is='div' className=' m-8 min-w-[300px] min-h-[152px] max-h-[auto] flex flex-row flex-wrap' style={{border: '1px solid #02021233', background:'#EBEBEC', boxShadow: '0px 0px 4px 0px #FFFFFF1A', flex:'40%'}}>
                    <Wrapper canvas id='random-id-494' is='div' className='w-[30%] h-auto justify-center flex my-auto mx-auto'>
                    <SvgIcon fill='#020212' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
                    </Wrapper>
                    <Wrapper canvas id='random-id-495' is='div' className='w-[70%] min-w-[300px] h-auto flex flex-col py-4 px-4 mx-auto items-center text-center'>
                            <Wrapper style={{display:'inline-block', fontSize:'22px', fontWeight:'bold', fontFamily:'Voces', color:'#020212'}} className='flex my-auto' id='random-id-39' is='div'>
                                <Text text='Sed do eiusmod'/>
                            </Wrapper>
                        <Wrapper style={{display:'inline-block',fontSize:'18px', fontWeight:'400px', fontFamily:'Voces', color:'#020212'}} className='flex my-auto' id='random-id-40' is='div'>
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                        </Wrapper>
                    </Wrapper>
                </Wrapper>
            </Wrapper>
            </Wrapper>

        </Wrapper></Element>
        </Element>,

        
        
        
        <Element canvas is={Parent} id='parentId1300' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId1400' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-496' is='parent' className='w-[100%] h-full bg-[#FBFAFF] py-20'>
            <Wrapper canvas id='random-id-497' is='container' className='container w-full h-full'>
            <Wrapper canvas id='random-id-498' is='wrapper' className='w-full h-full flex flex-row flex-wrap  justify-center'>
                <Wrapper canvas id='random-id-499' is='div' className=' m-8 min-w-[300px] min-h-[152px] max-h-[auto] flex flex-row flex-wrap' style={{border: '1px solid #D0BCFF66',background:'#FFFFFF', borderRadius:'30px', boxShadow: '0px 0px 4px 0px #FFFFFF1A', flex:'40%'}}>
                    <Wrapper canvas id='random-id-500' is='div' className='w-[30%] h-auto justify-center flex my-auto mx-auto'>
                    <SvgIcon fill='#6750A4' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
                    </Wrapper>
                    <Wrapper canvas id='random-id-501' is='div' className='w-[70%] min-w-[300px] h-auto flex flex-col py-4 px-4 mx-auto items-center text-center '>
                            <Wrapper className='flex my-auto' style={{display:'inline-block', fontSize:'22px', fontWeight:'bold', fontFamily:'Roboto', color:'#6750A4'}} id='random-id-41' is='div'>
                                <Text text='Sed do eiusmod'/>
                            </Wrapper>
                            <Wrapper className='flex my-auto' style={{display:'inline-block', fontSize:'18px', fontWeight:'400px', fontFamily:'Roboto', color:'#020212'}} id='random-id-42' is='div'>
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                        </Wrapper>
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-502' is='div' className=' m-8 min-w-[300px] min-h-[152px] max-h-[auto] flex flex-row flex-wrap' style={{border: '1px solid #D0BCFF66',background:'#FFFFFF', borderRadius:'30px', boxShadow: '0px 0px 4px 0px #FFFFFF1A', flex:'40%'}}>
                    <Wrapper canvas id='random-id-503' is='div' className='w-[30%] h-auto justify-center flex my-auto mx-auto'>
                    <SvgIcon fill='#6750A4' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
                    </Wrapper>
                    <Wrapper canvas id='random-id-504' is='div' className='w-[70%] min-w-[300px] h-auto flex flex-col py-4 px-4 mx-auto items-center text-center '>
                            <Wrapper className='flex my-auto' style={{display:'inline-block', fontSize:'22px', fontWeight:'bold', fontFamily:'Roboto', color:'#6750A4'}} id='random-id-43' is='div'>
                                <Text text='Sed do eiusmod'/>
                            </Wrapper>
                            <Wrapper className='flex my-auto' style={{display:'inline-block', fontSize:'18px', fontWeight:'400px', fontFamily:'Roboto', color:'#020212'}} id='random-id-44' is='div'>
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                        </Wrapper>
                    </Wrapper>
                </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-505' is='div' className='w-full h-full flex flex-row flex-wrap  justify-center'>
                <Wrapper canvas id='random-id-506' is='div' className=' m-8 min-w-[300px] min-h-[152px] max-h-[auto] flex flex-row flex-wrap' style={{border: '1px solid #D0BCFF66',background:'#FFFFFF', borderRadius:'30px', boxShadow: '0px 0px 4px 0px #FFFFFF1A', flex:'40%'}}>
                    <Wrapper canvas id='random-id-507' is='div' className='w-[30%] h-auto justify-center flex my-auto mx-auto'>
                    <SvgIcon fill='#6750A4' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
                    </Wrapper>
                    <Wrapper canvas id='random-id-508' is='div' className='w-[70%] min-w-[300px] h-auto flex flex-col py-45 px-4 mx-auto items-center text-center '>
                      
                            <Wrapper style={{display:'inline-block', fontSize:'22px', fontWeight:'bold', fontFamily:'Roboto', color:'#6750A4'}} className='flex my-auto' id='random-id-5' is='div'>
                                <Text text='Sed do eiusmod'/>
                            </Wrapper>
                        <Wrapper style={{display:'inline-block', fontSize:'18px', fontWeight:'400px', fontFamily:'Roboto', color:'#020212    '}} className='flex my-auto' id='random-id-46' is='div'>
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                        </Wrapper>
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-509' is='div' className=' m-8 min-w-[300px] min-h-[152px] max-h-[auto] flex flex-row flex-wrap' style={{border: '1px solid #D0BCFF66',background:'#FFFFFF', borderRadius:'30px', boxShadow: '0px 0px 4px 0px #FFFFFF1A', flex:'40%'}}>
                    <Wrapper canvas id='random-id-510' is='div' className='w-[30%] h-auto justify-center flex my-auto mx-auto'>
                    <SvgIcon fill='#6750A4' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
                    </Wrapper>
                    <Wrapper canvas id='random-id-511' is='div' className='w-[70%] min-w-[300px] h-auto flex flex-col py-4 px-4 mx-auto items-center text-center'>
                            <Wrapper style={{display:'inline-block', fontSize:'22px', fontWeight:'bold', fontFamily:'Roboto', color:'#6750A4'}} className='flex my-auto' id='random-id-47' is='div'>
                                <Text text='Sed do eiusmod'/>
                            </Wrapper>
                      
                        <Wrapper style={{display:'inline-block', fontSize:'18px', fontWeight:'400px', fontFamily:'Roboto', color:'#020212'}} className='flex my-auto' id='random-id-48' is='div'>
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                        </Wrapper>
                    </Wrapper>
                </Wrapper>
            </Wrapper>
            </Wrapper>

        </Wrapper></Element>
        </Element>,

        <Element canvas is={Parent} id='parentId1500' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId1600' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-512' is='parent' className='w-[100%] h-full bg-[#2A2A2A] py-20'>
            <Wrapper canvas id='random-id-513' is='container' className='container w-full h-full'>
            <Wrapper canvas id='random-id-514' is='wrapper' className='w-full h-full flex flex-row flex-wrap  justify-center'>
                <Wrapper canvas id='random-id-515' is='div' className=' m-8 min-w-[300px] min-h-[152px] max-h-[auto] flex flex-row flex-wrap' style={{border: '1px solid #FFD6001A', borderRadius:'10px', boxShadow: '0px 0px 10px 0px #FFFFFF0F', flex:'40%'}}>
                    <Wrapper canvas id='random-id-516' is='div' className='w-[30%] h-auto justify-center flex my-auto mx-auto'>
                    <SvgIcon fill='#FFD600' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
                    </Wrapper>
                    <Wrapper canvas id='random-id-517' is='div' className='w-[70%] min-w-[300px] h-auto flex flex-col py-4 px-4 mx-auto items-center text-center '>
                            <Wrapper className='flex my-auto' style={{display:'inline-block', fontSize:'22px', fontWeight:'bold', fontFamily:'Rubik', color:'#FFD600'}} id='random-id-49' is='div'>
                                <Text text='Sed do eiusmod'/>
                            </Wrapper>
                            <Wrapper className='flex my-auto' style={{display:'inline-block', fontSize:'18px', fontWeight:'400px', fontFamily:'Rubik', color:'#FFFFFF'}} id='random-id-50' is='div'>
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                        </Wrapper>
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-518' is='div' className=' m-8 min-w-[300px] min-h-[152px] max-h-[auto] flex flex-row flex-wrap' style={{border: '1px solid #FFD6001A', borderRadius:'10px', boxShadow: '0px 0px 10px 0px #FFFFFF0F', flex:'40%'}}>
                    <Wrapper canvas id='random-id-519' is='div' className='w-[30%] h-auto justify-center flex my-auto mx-auto'>
                    <SvgIcon fill='#FFD600' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
                    </Wrapper>
                    <Wrapper canvas id='random-id-520' is='div' className='w-[70%] min-w-[300px] h-auto flex flex-col py-4 px-4 mx-auto items-center text-center '>
                            <Wrapper className='flex my-auto' style={{display:'inline-block', fontSize:'22px', fontWeight:'bold', fontFamily:'Rubik', color:'#FFD600'}} id='random-id-51' is='div'>
                                <Text text='Sed do eiusmod'/>
                            </Wrapper>
                            <Wrapper className='flex my-auto' style={{display:'inline-block', fontSize:'18px', fontWeight:'400px', fontFamily:'Rubik', color:'#FFFFFF'}} id='random-id-52' is='div'>
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                        </Wrapper>
                       
                    </Wrapper>
                </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-521' is='div' className='w-full h-full flex flex-row flex-wrap  justify-center'>
                <Wrapper canvas id='random-id-522' is='div' className=' m-8 min-w-[300px] min-h-[152px] max-h-[auto] flex flex-row flex-wrap' style={{border: '1px solid #FFD6001A', borderRadius:'10px', boxShadow: '0px 0px 10px 0px #FFFFFF0F', flex:'40%'}}>
                    <Wrapper canvas id='random-id-523' is='div' className='w-[30%] h-auto justify-center flex my-auto mx-auto'>
                    <SvgIcon fill='#FFD600' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
                    </Wrapper>
                    <Wrapper canvas id='random-id-524' is='div' className='w-[70%] min-w-[300px] h-auto flex flex-col py-4 px-4 mx-auto items-center text-center '>
                            <Wrapper style={{display:'inline-block', fontSize:'22px', fontWeight:'bold', fontFamily:'Rubik', color:'#FFD600'}} className='flex my-auto' id='random-id-53' is='div'>
                                <Text text='Sed do eiusmod'/>
                            </Wrapper>
                        <Wrapper style={{display:'inline-block', fontSize:'18px', fontWeight:'400px', fontFamily:'Rubik', color:'#FFFFFF'}} className='flex my-auto' id='random-id-54' is='div'>
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                        </Wrapper>
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-525' is='div' className=' m-8 min-w-[300px] min-h-[152px] max-h-[auto] flex flex-row flex-wrap' style={{border: '1px solid #FFD6001A', borderRadius:'10px', boxShadow: '0px 0px 10px 0px #FFFFFF0F', flex:'40%'}}>
                    <Wrapper canvas id='random-id-526' is='div' className='w-[30%] h-auto justify-center flex my-auto mx-auto'>
                    <SvgIcon fill='#FFD600' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
                    </Wrapper>
                    <Wrapper canvas id='random-id-527' is='div' className='w-[70%] min-w-[300px] h-auto flex flex-col py-4 px-4 mx-auto items-center text-center'>
                            <Wrapper style={{display:'inline-block', fontSize:'22px', fontWeight:'bold', fontFamily:'Rubik', color:'#FFD600'}} className='flex my-auto' id='random-id-55' is='div'>
                                <Text text='Sed do eiusmod'/>
                            </Wrapper>
                        <Wrapper style={{display:'inline-block', fontSize:'18px', fontWeight:'400px', fontFamily:'Rubik', color:'#FFFFFF'}} className='flex my-auto' id='random-id-56' is='div'>
                                <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                        </Wrapper>
                    </Wrapper>
                </Wrapper>
            </Wrapper>
            </Wrapper>

        </Wrapper></Element>
        </Element>,

<Element canvas is={Parent} id='parentId1700' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId1800' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-400' is='parent' className='w-[100%] h-full bg-[#F7FBFE] py-20'>
<Wrapper canvas id='random-id-401' is='container' className='container w-full h-full'>
<Wrapper canvas id='random-id-402' is='wrapper' className='w-full h-full flex flex-row flex-wrap  justify-center'>
    <Wrapper canvas id='random-id-403' is='parent' className=' m-8 min-w-[300px] min-h-[152px] max-h-[auto] flex flex-row flex-wrap' style={{border: '1px solid rgba(96, 100, 210, 0.20)', borderRadius:'30px', background:'white', flex:'40%'}}>
        <Wrapper canvas id='random-id-404' is='parent' className='w-[30%] h-auto justify-center flex my-auto mx-auto'>
        <SvgIcon fill='#B20312' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
        </Wrapper>
        <Wrapper canvas id='random-id-405' is='parent' className='w-[70%] min-w-[300px] h-auto flex flex-col py-4 px-4 mx-auto items-center text-center '>
                <Wrapper className='flex my-auto' style={{display:'inline-block', fontSize:'22px', fontWeight:'bold', fontFamily:'Rubik', color:'#6064D2'}} id='random-id-49' is='div'>
                    <Text text='Sed do eiusmod'/>
                </Wrapper>
                <Wrapper className='flex my-auto' style={{display:'inline-block', fontSize:'18px', fontWeight:'400px', fontFamily:'Rubik', color:'#000000'}} id='random-id-50' is='div'>
                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
            </Wrapper>
        </Wrapper>
    </Wrapper>
    <Wrapper canvas id='random-id-406' is='parent' className=' m-8 min-w-[300px] min-h-[152px] max-h-[auto] flex flex-row flex-wrap' style={{border: '1px solid rgba(96, 100, 210, 0.20)', borderRadius:'30px', background:'white', flex:'40%'}}>
        <Wrapper canvas id='random-id-407' is='parent' className='w-[30%] h-auto justify-center flex my-auto mx-auto'>
        <SvgIcon fill='#B20312' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
        </Wrapper>
        <Wrapper canvas id='random-id-408' is='parent' className='w-[70%] min-w-[300px] h-auto flex flex-col py-4 px-4 mx-auto items-center text-center '>
                <Wrapper className='flex my-auto' style={{display:'inline-block', fontSize:'22px', fontWeight:'bold', fontFamily:'Rubik', color:'#6064D2'}} id='random-id-51' is='div'>
                    <Text text='Sed do eiusmod'/>
                </Wrapper>
                <Wrapper className='flex my-auto' style={{display:'inline-block', fontSize:'18px', fontWeight:'400px', fontFamily:'Rubik', color:'#000000'}} id='random-id-52' is='div'>
                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
            </Wrapper>
        </Wrapper>
    </Wrapper>
</Wrapper>
<Wrapper canvas id='random-id-409' is='parent' className='w-full h-full flex flex-row flex-wrap  justify-center'>
    <Wrapper canvas id='random-id-410' is='parent' className=' m-8 min-w-[300px] min-h-[152px] max-h-[auto] flex flex-row flex-wrap' style={{border: '1px solid rgba(96, 100, 210, 0.20)', borderRadius:'30px', background:'white', flex:'40%'}}>
        <Wrapper canvas id='random-id-411' is='parent' className='w-[30%] h-auto justify-center flex my-auto mx-auto'>
        <SvgIcon fill='#B20312' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
        </Wrapper>
        <Wrapper canvas id='random-id-412' is='parent' className='w-[70%] min-w-[300px] h-auto flex flex-col py-4 px-4 mx-auto items-center text-center '>
            
                <Wrapper className='flex my-auto' style={{display:'inline-block', fontSize:'22px', fontWeight:'bold', fontFamily:'Rubik', color:'#6064D2'}} id='random-id-53' is='div'>
                    <Text text='Sed do eiusmod'/>
                </Wrapper>
            
            
            <Wrapper style={{display:'inline-block', fontSize:'18px', fontWeight:'400px', fontFamily:'Rubik', color:'#000000'}} className='flex my-auto' id='random-id-54' is='div'>
                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
            </Wrapper>
            
        </Wrapper>
    </Wrapper>
    <Wrapper canvas id='random-id-413' is='parent' className=' m-8 min-w-[300px] min-h-[152px] max-h-[auto] flex flex-row flex-wrap' style={{border: '1px solid rgba(96, 100, 210, 0.20)', borderRadius:'30px', background:'white', flex:'40%'}}>
        <Wrapper canvas id='random-id-414' is='parent' className='w-[30%] h-auto justify-center flex my-auto mx-auto'>
        <SvgIcon fill='#B20312' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
        </Wrapper>
        <Wrapper canvas id='random-id-415' is='parent' className='w-[70%] min-w-[300px] h-auto flex flex-col py-4 px-4 mx-auto items-center text-center'>
            
                <Wrapper style={{display:'inline-block', fontSize:'22px', fontWeight:'bold', fontFamily:'Rubik', color:'#6064D2'}} className='flex my-auto' id='random-id-55' is='div'>
                    <Text text='Sed do eiusmod'/>
                </Wrapper>
            
            <Wrapper style={{display:'inline-block', fontSize:'18px', fontWeight:'400px', fontFamily:'Rubik', color:'#000000'}} className='flex my-auto'  id='random-id-60' is='div'>
                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
            </Wrapper>
            
        </Wrapper>
    </Wrapper>
</Wrapper>
</Wrapper>

</Wrapper></Element>
        </Element>,


<Element canvas is={Parent} id='parentId1900' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId2000' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-900' is='parent' className='w-[100%] h-full bg-[#FEFBF7] py-20'>
<Wrapper canvas id='random-id-901' is='container' className='container w-full h-full'>
<Wrapper canvas id='random-id-902' is='wrapper' className='w-full h-full flex flex-row flex-wrap  justify-center'>
    <Wrapper canvas id='random-id-903' is='parent' className=' m-8 min-w-[300px] min-h-[152px] max-h-[auto] flex flex-row flex-wrap' style={{border: '1px solid rgba(162, 87, 56, 0.20)', borderRadius:'30px', background:'white', flex:'40%'}}>
        <Wrapper canvas id='random-id-904' is='parent' className='w-[30%] h-auto justify-center flex my-auto mx-auto'>
        <SvgIcon fill='#A25738' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
        </Wrapper>
        <Wrapper canvas id='random-id-905' is='parent' className='w-[70%] min-w-[300px] h-auto flex flex-col py-4 px-4 mx-auto items-center text-center '>
                <Wrapper className='flex my-auto' style={{display:'inline-block', fontSize:'22px', fontWeight:'bold', fontFamily:'Rubik', color:'#A25738'}} id='random-id-49' is='div'>
                    <Text text='Sed do eiusmod'/>
                </Wrapper>
                <Wrapper className='flex my-auto' style={{display:'inline-block', fontSize:'18px', fontWeight:'400px', fontFamily:'Rubik', color:'#000000'}} id='random-id-50' is='div'>
                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
            </Wrapper>
        </Wrapper>
    </Wrapper>
    <Wrapper canvas id='random-id-906' is='parent' className=' m-8 min-w-[300px] min-h-[152px] max-h-[auto] flex flex-row flex-wrap' style={{border: '1px solid rgba(162, 87, 56, 0.20)', borderRadius:'30px', background:'white', flex:'40%'}}>
        <Wrapper canvas id='random-id-907' is='parent' className='w-[30%] h-auto justify-center flex my-auto mx-auto'>
        <SvgIcon fill='#A25738' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
        </Wrapper>
        <Wrapper canvas id='random-id-908' is='parent' className='w-[70%] min-w-[300px] h-auto flex flex-col py-4 px-4 mx-auto items-center text-center '>
                <Wrapper className='flex my-auto' style={{display:'inline-block', fontSize:'22px', fontWeight:'bold', fontFamily:'Rubik', color:'#A25738'}} id='random-id-51' is='div'>
                    <Text text='Sed do eiusmod'/>
                </Wrapper>
                <Wrapper className='flex my-auto' style={{display:'inline-block', fontSize:'18px', fontWeight:'400px', fontFamily:'Rubik', color:'#000000'}} id='random-id-52' is='div'>
                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
            </Wrapper>
        </Wrapper>
    </Wrapper>
</Wrapper>
<Wrapper canvas id='random-id-909' is='parent' className='w-full h-full flex flex-row flex-wrap  justify-center'>
    <Wrapper canvas id='random-id-910' is='parent' className=' m-8 min-w-[300px] min-h-[152px] max-h-[auto] flex flex-row flex-wrap' style={{border: '1px solid rgba(162, 87, 56, 0.20)', borderRadius:'30px', background:'white', flex:'40%'}}>
        <Wrapper canvas id='random-id-911' is='parent' className='w-[30%] h-auto justify-center flex my-auto mx-auto'>
        <SvgIcon fill='#A25738' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
        </Wrapper>
        <Wrapper canvas id='random-id-912' is='parent' className='w-[70%] min-w-[300px] h-auto flex flex-col py-4 px-4 mx-auto items-center text-center '>
            
                <Wrapper className='flex my-auto' style={{display:'inline-block', fontSize:'22px', fontWeight:'bold', fontFamily:'Rubik', color:'#A25738'}} id='random-id-53' is='div'>
                    <Text text='Sed do eiusmod'/>
                </Wrapper>
            
            
            <Wrapper style={{display:'inline-block', fontSize:'18px', fontWeight:'400px', fontFamily:'Rubik', color:'#000000'}} className='flex my-auto' id='random-id-54' is='div'>
                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
            </Wrapper>
            
        </Wrapper>
    </Wrapper>
    <Wrapper canvas id='random-id-913' is='parent' className=' m-8 min-w-[300px] min-h-[152px] max-h-[auto] flex flex-row flex-wrap' style={{border: '1px solid rgba(162, 87, 56, 0.20)', borderRadius:'30px', background:'white', flex:'40%'}}>
        <Wrapper canvas id='random-id-914' is='parent' className='w-[30%] h-auto justify-center flex my-auto mx-auto'>
        <SvgIcon fill='#A25738' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
        </Wrapper>
        <Wrapper canvas id='random-id-915' is='parent' className='w-[70%] min-w-[300px] h-auto flex flex-col py-4 px-4 mx-auto items-center text-center'>
            
                <Wrapper style={{display:'inline-block', fontSize:'22px', fontWeight:'bold', fontFamily:'Rubik', color:'#A25738'}} className='flex my-auto' id='random-id-55' is='div'>
                    <Text text='Sed do eiusmod'/>
                </Wrapper>
            
            <Wrapper style={{display:'inline-block', fontSize:'18px', fontWeight:'400px', fontFamily:'Rubik', color:'#000000'}} className='flex my-auto'  id='random-id-60' is='div'>
                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
            </Wrapper>
            
        </Wrapper>
    </Wrapper>
</Wrapper>
</Wrapper>

</Wrapper></Element>
        </Element>,

    ]

    
const [sel, setSel] = useState(selected)

function makeOdd(number) {
  setProp((prop) => {
    setSel(100);
  }, 1000);

  setTimeout(() => {
    setProp((prop) => {
      setSel(number)
    }, 1000);
  }, 100);


  
}

useEffect(() => {
if(selected || selected >= 0) {
  makeOdd(selected);
}
},[selected])
    

    return (
        <div style={{ padding: enabled? "10px" : "0px", display:"flex", justifyContent:"center", alignItems:"center", width:'100%', height:'100%'}} ref={connect}>
         {sel == 100 ? (
          <div style={{height:"85vh", display:"flex", justifyContent:"center", alignItems:"center"}}>
          <BounceLoader
                  size={100}
                  color={"white"}
                  loading={true}
                />
          </div>
         ) : (
          styles[sel]
         )} 
    </div>
    );
}

    Features_1.craft = {
        displayName: "Features 1",
        props: {
        selected: 1, // here is the default value of the selected style
        length:9,
isBlock: true
        },
        rules: {
            canDrag: () => true,
            canDrop: () => true,
        },
        related: {
            toolbar: NewFeaturesSettings,
        },
    }