/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, { useEffect, useState } from 'react';
import { Element, useEditor, useNode } from '@craftjs/core';
import { Text } from '../../basic/Text';
import {NewStatisticsSettings} from './newStatisticsSetting'
import './Stats.css'
import { BounceLoader } from 'react-spinners';
import { Parent } from '../../Parent';
import { Wrapper } from '../../wrapper/index';


export const Statistics_4 = ({selected}) => {
 
    const { 
        actions: { setProp },
          connectors: {
            connect
          } 
      } = useNode();
    
      
      const {
        enabled,
      } = useEditor((state, query) => ({
        enabled: state.options.enabled,
     
      }));
 

    const styles = [
         <Element canvas is={Parent} id='parentId100' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId200' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-400' is='parent' className='w-[100%] h-full bg-[#FFFFFF]'>
        <Wrapper canvas id='random-id-401' is='container' className='container'>
            <Wrapper canvas id='random-id-402' is='div' className='w-full h-full flex flex-col'>
                    <Wrapper canvas className='my-4 px-10' style={{display:'inline-block', fontSize:'46px', fontWeight:'bold', fontFamily:'Poppins', color:'#000000'}} id='random-id-1' is='div'>
                        <Text text='Our work in numbers'/>
                    </Wrapper>
                    <Wrapper canvas className='my-4 px-10' style={{display:'inline-block', fontSize:'24px', fontWeight:'500px', fontFamily:'Poppins', color:'#000000'}} id='random-id-2' is='div'>
                        <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.'/>
                    </Wrapper>
                <Wrapper canvas id='random-id-403' is='wrapper' className='w-full flex flex-row flex-wrap justify-center'>
                    <Wrapper canvas id='random-id-404' is='div' className='w-[40%] min-w-[358px] mx-auto flex p-10'>
                        <Wrapper canvas id='random-id-405' is='div' className='w-full h-full flex p-20 bg-[#FDFDF3]' style={{borderRadius:'30px'}}>
                           <Wrapper canvas id='random-id-406' is='div' className='dayraS1 my-auto mx-auto rotate-45 border-none'>
                                <Wrapper  id='random-id-407' is='div' className='absolute left-[100px] -rotate-90 top-[-2px]'>
                                    <Wrapper  id='random-id-408' is='div' className='dayra1' style={{background:'#FFFFFF'}}></Wrapper>
                                </Wrapper>
                                <Wrapper  id='random-id-409' is='div' className='absolute left-[0px] rotate-180 top-[-1px]'>
                                    <Wrapper  id='random-id-410' is='div' className='dayra2' style={{background:'#FFC92E'}}></Wrapper>
                                </Wrapper>
                                <Wrapper  id='random-id-411' is='div' className='absolute right-[123px] rotate-90 top-[29px]'>
                                    <Wrapper  id='random-id-412' is='div' className='dayra3 absolute left-[20px] rotate-45 top-[0px]' style={{background:'#FFFFFF'}}></Wrapper>
                                </Wrapper>
                                <Wrapper  id='random-id-413' is='div' className='w-[170px] h-[30px] -rotate-45 absolute top-[170px] left-[-70px] bg-[#FFFFFF] flex flex-row' style={{borderRadius:'30px'}}>
                                    <Wrapper  className='pl-4 my-auto' style={{display:'inline-block', fontSize:'16px', fontWeight:'bold', fontFamily:'Poppins', color:'#2479FD'}} id='random-id-3' is='div'>
                                        <Text text='22,4%'/>
                                    </Wrapper>
                                        <Wrapper  className='pl-2 my-auto' style={{display:'inline-block', fontSize:'16px', fontWeight:'400px', fontFamily:'Poppins', color:'#000000'}} id='random-id-4' is='div'>
                                            <Text text='Lorem Ipum'/>
                                        </Wrapper>
                                </Wrapper>
                                <Wrapper  id='random-id-414' is='div' className='w-[170px] h-[30px] -rotate-45 absolute top-[100px] left-[120px] bg-[#FFFFFF] flex flex-row' style={{borderRadius:'30px'}}>
                                    <Wrapper  className='pl-4 my-auto' style={{display:'inline-block', fontSize:'16px', fontWeight:'bold', fontFamily:'Poppins', color:'#FFC92E'}} id='random-id-5' is='div'>
                                        <Text text='22,4%'/>
                                    </Wrapper>
                                        <Wrapper  className='pl-2 my-auto' style={{display:'inline-block', fontSize:'16px', fontWeight:'400px', fontFamily:'Poppins', color:'#000000'}} id='random-id-6' is='div'>
                                            <Text text='Lorem Ipum'/>
                                        </Wrapper>
                                </Wrapper>
                                <Wrapper  id='random-id-415' is='div' className='w-[170px] h-[30px] -rotate-45 absolute top-[-20px] left-[90px] bg-[#FFFFFF] flex flex-row' style={{borderRadius:'30px'}}>
                                    <Wrapper  className='pl-4 my-auto' style={{display:'inline-block', fontSize:'16px', fontWeight:'bold', fontFamily:'Poppins', color:'#2479FD'}} id='random-id-7' is='div'>
                                        <Text text='22,4%'/>
                                    </Wrapper>
                                        <Wrapper  className='pl-2 my-auto' style={{display:'inline-block', fontSize:'16px', fontWeight:'400px', fontFamily:'Poppins', color:'#000000'}} id='random-id-8' is='div'>
                                            <Text text='Lorem Ipum'/>
                                        </Wrapper>
                                </Wrapper>
                                <Wrapper  id='random-id-416' is='div' className='w-[170px] h-[30px] -rotate-45 absolute top-[70px] left-[-60px] bg-[#FFFFFF] flex flex-row' style={{borderRadius:'30px'}}>
                                    <Wrapper  className='pl-4 my-auto' style={{display:'inline-block', fontSize:'16px', fontWeight:'bold', fontFamily:'Poppins', color:'#FFC92E'}} id='random-id-9' is='div'>
                                        <Text text='22,4%'/>
                                    </Wrapper>
                                        <Wrapper  className='pl-2 my-auto' style={{display:'inline-block', fontSize:'16px', fontWeight:'400px', fontFamily:'Poppins', color:'#000000'}} id='random-id-10' is='div'>
                                            <Text text='Lorem Ipum'/>
                                        </Wrapper>
                                </Wrapper>
                           </Wrapper>
                        </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-417' is='div' className='w-[60%] min-w-[358px] mx-auto h-full px-4'>
                        <Wrapper canvas id='random-id-418' is='div' className='w-full h-full flex flex-row my-4'>
                                <Wrapper canvas className='w-auto' style={{display:'inline-block', fontSize:'46px', fontWeight:'bold', fontFamily:'Poppins', color:'#2479FD'}} id='random-id-11' is='div'>
                                    <Text text='22,4%'/>
                                </Wrapper>
                                <Wrapper canvas className='px-4 my-auto'  style={{display:'inline-block', fontSize:'18px', fontWeight:'400px', fontFamily:'Poppins'}} id='random-id-12' is='div'>
                                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                                </Wrapper>
                        </Wrapper>
                        <Wrapper canvas id='random-id-419' is='div' className='w-full h-full flex flex-row my-4'>
                                <Wrapper canvas className='w-auto' style={{display:'inline-block', fontSize:'46px', fontWeight:'bold', fontFamily:'Poppins', color:'#FFC92E'}} id='random-id-13' is='div'>
                                    <Text text='22,4%'/>
                                </Wrapper>
                                <Wrapper canvas className='px-4 my-auto'  style={{display:'inline-block', fontSize:'18px', fontWeight:'400px', fontFamily:'Poppins'}} id='random-id-14' is='div'>
                                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                                </Wrapper>
                        </Wrapper>
                        <Wrapper canvas id='random-id-420' is='div' className='w-full h-full flex flex-row my-4'>
                                <Wrapper canvas className='w-auto' style={{display:'inline-block', fontSize:'46px', fontWeight:'bold', fontFamily:'Poppins', color:'#2479FD'}} id='random-id-15' is='div'>
                                    <Text text='22,4%'/>
                                </Wrapper>
                                <Wrapper canvas className='px-4 my-auto'  style={{display:'inline-block', fontSize:'18px', fontWeight:'400px', fontFamily:'Poppins'}} id='random-id-16' is='div'>
                                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                                </Wrapper>
                        </Wrapper>
                        <Wrapper canvas id='random-id-421' is='div' className='w-full h-full flex flex-row my-4'>
                                <Wrapper canvas className='w-auto' style={{display:'inline-block', fontSize:'46px', fontWeight:'bold', fontFamily:'Poppins', color:'#FFC92E'}} id='random-id-17' is='div'>
                                    <Text text='22,4%'/>
                                </Wrapper>
                                <Wrapper canvas className='px-4 my-auto'  style={{display:'inline-block', fontSize:'18px', fontWeight:'400px', fontFamily:'Poppins'}} id='random-id-18' is='div'>
                                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                                </Wrapper>
                        </Wrapper>
                    </Wrapper>
                </Wrapper>
            </Wrapper>
        </Wrapper>
    </Wrapper></Element>
        </Element>
    ,
         <Element canvas is={Parent} id='parentId300' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId400' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-422' is='parent' className='w-[100%] h-full bg-[#FFFFFF]'>
        <Wrapper canvas id='random-id-423' is='container' className='container'>
            <Wrapper canvas id='random-id-424' is='div' className='w-full h-full flex flex-col'>
                    <Wrapper canvas className='my-4 px-10' style={{display:'inline-block', fontSize:'46px', fontWeight:'bold', fontFamily:'Prompt', color:'#000000'}} id='random-id-19' is='div'>
                        <Text text='Our work in numbers'/>
                    </Wrapper>
                    <Wrapper canvas className='my-4 px-10' style={{display:'inline-block', fontSize:'24px', fontWeight:'500px', fontFamily:'Prompt', color:'#000000'}} id='random-id-20' is='div'>
                        <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.'/>
                    </Wrapper>
                <Wrapper canvas id='random-id-425' is='wrapper' className='w-full flex flex-row flex-wrap justify-center'>
                    <Wrapper canvas id='random-id-426' is='div' className='w-[40%] min-w-[358px] mx-auto flex p-10'>
                        <Wrapper canvas id='random-id-427' is='div' className='w-full h-full flex p-20 bg-[#FDFDF3]' style={{borderRadius:'30px'}}>
                           <Wrapper canvas id='random-id-428' is='div' className='dayraS2 my-auto mx-auto rotate-45 border-none'>
                                <Wrapper  id='random-id-429' is='div' className='absolute left-[100px] -rotate-90 top-[-2px]'>
                                    <Wrapper  id='random-id-430' is='div' className='dayra1' style={{background:'#AB0242'}}></Wrapper>
                                </Wrapper>
                                <Wrapper  id='random-id-431' is='div' className='absolute left-[0px] rotate-180 top-[-1px]'>
                                    <Wrapper  id='random-id-432' is='div' className='dayra2' style={{background:'#F6FA70'}}></Wrapper>
                                </Wrapper>
                                <Wrapper  id='random-id-433' is='div' className='absolute right-[123px] rotate-90 top-[29px]'>
                                    <Wrapper  id='random-id-434' is='div' className='dayra3 absolute left-[20px] rotate-45 top-[0px]' style={{background:'#FFFFFF'}}></Wrapper>
                                </Wrapper>
                                <Wrapper  id='random-id-435' is='div' className='w-[170px] h-[30px] -rotate-45 absolute top-[170px] left-[-70px] bg-[#FFFFFF] flex flex-row' style={{borderRadius:'30px'}}>
                                   
                                    <Wrapper  className='pl-4 my-auto' style={{display:'inline-block', fontSize:'16px', fontWeight:'bold', fontFamily:'Prompt', color:'#FF0060'}} id='random-id-21' is='div'>
                                        <Text text='22,4%'/>
                                    </Wrapper>
                                        <Wrapper  className='pl-2 my-auto' style={{display:'inline-block', fontSize:'16px', fontWeight:'400px', fontFamily:'Prompt', color:'#000000'}} id='random-id-22' is='div'>
                                            <Text text='Lorem Ipum'/>
                                        </Wrapper>
                                </Wrapper>
                                <Wrapper  id='random-id-436' is='div' className='w-[170px] h-[30px] -rotate-45 absolute top-[100px] left-[120px] bg-[#FFFFFF] flex flex-row' style={{borderRadius:'30px'}}>
                                   
                                    <Wrapper  className='pl-4 my-auto' style={{display:'inline-block', fontSize:'16px', fontWeight:'bold', fontFamily:'Prompt', color:'#FF0060'}} id='random-id-23' is='div'>
                                        <Text text='22,4%'/>
                                    </Wrapper>
                                        <Wrapper  className='pl-2 my-auto' style={{display:'inline-block', fontSize:'16px', fontWeight:'400px', fontFamily:'Prompt', color:'#000000'}} id='random-id-24' is='div'>
                                            <Text text='Lorem Ipum'/>
                                        </Wrapper>
                                </Wrapper>
                                <Wrapper  id='random-id-437' is='div' className='w-[170px] h-[30px] -rotate-45 absolute top-[-20px] left-[90px] bg-[#FFFFFF] flex flex-row' style={{borderRadius:'30px'}}>
                                   
                                    <Wrapper  className='pl-4 my-auto' style={{display:'inline-block', fontSize:'16px', fontWeight:'bold', fontFamily:'Prompt', color:'#FF0060'}} id='random-id-25' is='div'>
                                        <Text text='22,4%'/>
                                    </Wrapper>
                                        <Wrapper  className='pl-2 my-auto' style={{display:'inline-block', fontSize:'16px', fontWeight:'400px', fontFamily:'Prompt', color:'#000000'}} id='random-id-26' is='div'>
                                            <Text text='Lorem Ipum'/>
                                        </Wrapper>
                                </Wrapper>
                                <Wrapper  id='random-id-438' is='div' className='w-[170px] h-[30px] -rotate-45 absolute top-[70px] left-[-60px] bg-[#FFFFFF] flex flex-row' style={{borderRadius:'30px'}}>
                                   
                                    <Wrapper  className='pl-4 my-auto' style={{display:'inline-block', fontSize:'16px', fontWeight:'bold', fontFamily:'Prompt', color:'#FF0060'}} id='random-id-27' is='div'>
                                        <Text text='22,4%'/>
                                    </Wrapper>
                                        <Wrapper  className='pl-2 my-auto' style={{display:'inline-block', fontSize:'16px', fontWeight:'400px', fontFamily:'Prompt', color:'#000000'}} id='random-id-28' is='div'>
                                            <Text text='Lorem Ipum'/>
                                        </Wrapper>
                                </Wrapper>
                           </Wrapper>
                        </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-439' is='div' className='w-[60%] min-w-[358px] mx-auto h-full px-4'>
                        <Wrapper canvas id='random-id-440' is='div' className='w-full h-full flex flex-row my-4'>
                                <Wrapper canvas className='w-auto' style={{display:'inline-block', fontSize:'46px', fontWeight:'bold', fontFamily:'Prompt', color:'#FF0060'}} id='random-id-29' is='div'>
                                    <Text text='22,4%'/>
                                </Wrapper>
                                <Wrapper canvas className='px-4 my-auto' style={{display:'inline-block', fontSize:'18px', fontWeight:'400px', fontFamily:'Prompt'}} id='random-id-30' is='div'>
                                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                                </Wrapper>
                        </Wrapper>
                        <Wrapper canvas id='random-id-441' is='div' className='w-full h-full flex flex-row my-4'>
                                <Wrapper canvas className='w-auto' style={{display:'inline-block', fontSize:'46px', fontWeight:'bold', fontFamily:'Prompt', color:'#FF0060'}} id='random-id-31' is='div'>
                                    <Text text='22,4%'/>
                                </Wrapper>
                                <Wrapper canvas className='px-4 my-auto' style={{display:'inline-block', fontSize:'18px', fontWeight:'400px', fontFamily:'Prompt'}} id='random-id-32' is='div'>
                                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                                </Wrapper>
                        </Wrapper>
                        <Wrapper canvas id='random-id-442' is='div' className='w-full h-full flex flex-row my-4'>
                                <Wrapper canvas className='w-auto' style={{display:'inline-block', fontSize:'46px', fontWeight:'bold', fontFamily:'Prompt', color:'#FF0060'}} id='random-id-33' is='div'>
                                    <Text text='22,4%'/>
                                </Wrapper>
                                <Wrapper canvas className='px-4 my-auto' style={{display:'inline-block', fontSize:'18px', fontWeight:'400px', fontFamily:'Prompt'}} id='random-id-34' is='div'>
                                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                                </Wrapper>
                        </Wrapper>
                        <Wrapper canvas id='random-id-443' is='div' className='w-full h-full flex flex-row my-4'>
                                <Wrapper canvas className='w-auto' style={{display:'inline-block', fontSize:'46px', fontWeight:'bold', fontFamily:'Prompt', color:'#FF0060'}} id='random-id-35' is='div'>
                                    <Text text='22,4%'/>
                                </Wrapper>
                                <Wrapper canvas className='px-4 my-auto' style={{display:'inline-block', fontSize:'18px', fontWeight:'400px', fontFamily:'Prompt'}} id='random-id-36' is='div'>
                                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                                </Wrapper>
                        </Wrapper>
                    </Wrapper>
                </Wrapper>
            </Wrapper>
        </Wrapper>
    </Wrapper></Element>
        </Element>
    ,

     <Element canvas is={Parent} id='parentId500' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId600' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-444' is='parent' className='w-[100%] h-full bg-[#FFFFFF]'>
        <Wrapper canvas id='random-id-445' is='container' className='container'>
            <Wrapper canvas id='random-id-446' is='div' className='w-full h-full flex flex-col'>
                    <Wrapper canvas className='my-4 px-10' style={{display:'inline-block', fontSize:'46px', fontWeight:'bold', fontFamily:'Almarai', color:'#000000'}} id='random-id-37' is='div'>
                        <Text text='Our work in numbers'/>
                    </Wrapper>
                    <Wrapper canvas className='my-4 px-10' style={{display:'inline-block', fontSize:'24px', fontWeight:'500px', fontFamily:'Almarai', color:'#000000'}} id='random-id-38' is='div'>
                        <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.'/>
                    </Wrapper>
                <Wrapper canvas id='random-id-447' is='wrapper' className='w-full flex flex-row flex-wrap justify-center'>
                    <Wrapper canvas id='random-id-448' is='div' className='w-[40%] min-w-[358px] mx-auto flex p-10'>
                        <Wrapper canvas id='random-id-449' is='div' className='w-full h-full flex p-20 bg-[#FDF4F4]' style={{borderRadius:'30px'}}>
                           <Wrapper canvas id='random-id-450' is='div' className='dayraS3 my-auto mx-auto rotate-45 border-none'>
                                <Wrapper  id='random-id-451' is='div' className='absolute left-[100px] -rotate-90 top-[-2px]'>
                                    <Wrapper  id='random-id-452' is='div' className='dayra1' style={{background:'#FFFFFF'}}></Wrapper>
                                </Wrapper>
                                <Wrapper  id='random-id-453' is='div' className='absolute left-[0px] rotate-180 top-[-1px]'>
                                    <Wrapper  id='random-id-454' is='div' className='dayra2' style={{background:'#F31559'}}></Wrapper>
                                </Wrapper>
                                <Wrapper  id='random-id-455' is='div' className='absolute right-[123px] rotate-90 top-[29px]'>
                                    <Wrapper  id='random-id-456' is='div' className='dayra3 absolute left-[20px] rotate-45 top-[0px]' style={{background:'#FFFFFF'}}></Wrapper>
                                </Wrapper>
                                <Wrapper  id='random-id-457' is='div' className='w-[170px] h-[30px] -rotate-45 absolute top-[170px] left-[-70px] bg-[#FFFFFF] flex flex-row' style={{borderRadius:'30px'}}>
                                    <Wrapper  className='pl-4 my-auto' style={{display:'inline-block', fontSize:'16px', fontWeight:'bold', fontFamily:'Almarai', color:'#F31559'}} id='random-id-39' is='div'>
                                        <Text text='22,4%'/>
                                    </Wrapper>
                                        <Wrapper  className='pl-2 my-auto' style={{display:'inline-block', fontSize:'16px', fontWeight:'400px', fontFamily:'Almarai', color:'#000000'}} id='random-id-40' is='div'>
                                            <Text text='Lorem Ipum'/>
                                        </Wrapper>
                                </Wrapper>
                                <Wrapper  id='random-id-458' is='div' className='w-[170px] h-[30px] -rotate-45 absolute top-[100px] left-[120px] bg-[#FFFFFF] flex flex-row' style={{borderRadius:'30px'}}>
                                    <Wrapper  className='pl-4 my-auto' style={{display:'inline-block', fontSize:'16px', fontWeight:'bold', fontFamily:'Almarai', color:'#FFB7B7'}} id='random-id-41' is='div'>
                                        <Text text='22,4%'/>
                                    </Wrapper>
                                        <Wrapper  className='pl-2 my-auto' style={{display:'inline-block', fontSize:'16px', fontWeight:'400px', fontFamily:'Almarai', color:'#000000'}} id='random-id-42' is='div'>
                                            <Text text='Lorem Ipum'/>
                                        </Wrapper>
                                </Wrapper>
                                <Wrapper  id='random-id-459' is='div' className='w-[170px] h-[30px] -rotate-45 absolute top-[-20px] left-[90px] bg-[#FFFFFF] flex flex-row' style={{borderRadius:'30px'}}>
                                    <Wrapper  className='pl-4 my-auto' style={{display:'inline-block', fontSize:'16px', fontWeight:'bold', fontFamily:'Almarai', color:'#F31559'}} id='random-id-43' is='div'>
                                        <Text text='22,4%'/>
                                    </Wrapper>
                                        <Wrapper  className='pl-2 my-auto' style={{display:'inline-block', fontSize:'16px', fontWeight:'400px', fontFamily:'Almarai', color:'#000000'}} id='random-id-44' is='div'>
                                            <Text text='Lorem Ipum'/>
                                        </Wrapper>
                                </Wrapper>
                                <Wrapper  id='random-id-460' is='div' className='w-[170px] h-[30px] -rotate-45 absolute top-[70px] left-[-60px] bg-[#FFFFFF] flex flex-row' style={{borderRadius:'30px'}}>
                                    <Wrapper  className='pl-4 my-auto' style={{display:'inline-block', fontSize:'16px', fontWeight:'bold', fontFamily:'Almarai', color:'#FFB7B7'}} id='random-id-45' is='div'>
                                        <Text text='22,4%'/>
                                    </Wrapper>
                                        <Wrapper  className='pl-2 my-auto' style={{display:'inline-block', fontSize:'16px', fontWeight:'400px', fontFamily:'Almarai', color:'#000000'}} id='random-id-46' is='div'>
                                            <Text text='Lorem Ipum'/>
                                        </Wrapper>
                                </Wrapper>
                           </Wrapper>
                        </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-461' is='div' className='w-[60%] min-w-[358px] mx-auto h-full px-4'>
                        <Wrapper canvas id='random-id-462' is='div' className='w-full h-full flex flex-row my-4'>
                                <Wrapper canvas className='w-auto' style={{display:'inline-block', fontSize:'46px', fontWeight:'bold', fontFamily:'Almarai', color:'#F31559'}} id='random-id-47' is='div'>
                                    <Text text='22,4%'/>
                                </Wrapper>
                                <Wrapper canvas className='px-4 my-auto' style={{display:'inline-block', fontSize:'18px', fontWeight:'400px', fontFamily:'Almarai'}} id='random-id-48' is='div'>
                                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                                </Wrapper>
                        </Wrapper>
                        <Wrapper canvas id='random-id-463' is='div' className='w-full h-full flex flex-row my-4'>
                                <Wrapper canvas className='w-auto' style={{display:'inline-block', fontSize:'46px', fontWeight:'bold', fontFamily:'Almarai', color:'#FFB7B7'}} id='random-id-49' is='div'>
                                    <Text text='22,4%'/>
                                </Wrapper>
                                <Wrapper canvas className='px-4 my-auto' style={{display:'inline-block', fontSize:'18px', fontWeight:'400px', fontFamily:'Almarai'}} id='random-id-50' is='div'>
                                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                                </Wrapper>
                        </Wrapper>
                        <Wrapper canvas id='random-id-464' is='div' className='w-full h-full flex flex-row my-4'>
                                <Wrapper canvas className='w-auto' style={{display:'inline-block', fontSize:'46px', fontWeight:'bold', fontFamily:'Almarai', color:'#F31559'}} id='random-id-51' is='div'>
                                    <Text text='22,4%'/>
                                </Wrapper>
                                <Wrapper canvas className='px-4 my-auto' style={{display:'inline-block', fontSize:'18px', fontWeight:'400px', fontFamily:'Almarai'}} id='random-id-52' is='div'>
                                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                                </Wrapper>
                        </Wrapper>
                        <Wrapper canvas id='random-id-465' is='div' className='w-full h-full flex flex-row my-4'>
                                <Wrapper canvas className='w-auto' style={{display:'inline-block', fontSize:'46px', fontWeight:'bold', fontFamily:'Almarai', color:'#FFB7B7'}} id='random-id-53' is='div'>
                                    <Text text='22,4%'/>
                                </Wrapper>
                                <Wrapper canvas className='px-4 my-auto' style={{display:'inline-block', fontSize:'18px', fontWeight:'400px', fontFamily:'Almarai'}} id='random-id-54' is='div'>
                                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                                </Wrapper>
                        </Wrapper>
                    </Wrapper>
                </Wrapper>
            </Wrapper>
        </Wrapper>
    </Wrapper></Element>
        </Element>
    ,
      <Element canvas is={Parent} id='parentId700' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId800' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-466' is='parent' className='w-[100%] h-full bg-[#FFFFFF]'>
     <Wrapper canvas id='random-id-467' is='container' className='container'>
         <Wrapper canvas id='random-id-468' is='div' className='w-full h-full flex flex-col'>
                 <Wrapper canvas className='my-4 px-10' style={{display:'inline-block', fontSize:'46px', fontWeight:'bold', fontFamily:'Chivo', color:'#000000'}} id='random-id-55' is='div'>
                     <Text text='Our work in numbers'/>
                 </Wrapper>
                 <Wrapper canvas className='my-4 px-10' style={{display:'inline-block', fontSize:'24px', fontWeight:'500px', fontFamily:'Chivo', color:'#000000'}} id='random-id-56' is='div'>
                     <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.'/>
                 </Wrapper>
             <Wrapper canvas id='random-id-469' is='wrapper' className='w-full flex flex-row flex-wrap justify-center'>
                 <Wrapper canvas id='random-id-470' is='div' className='w-[40%] min-w-[358px] mx-auto flex p-10'>
                     <Wrapper canvas id='random-id-471' is='div' className='w-full h-full flex p-20 bg-[#F6F2FA]' style={{borderRadius:'30px'}}>
                        <Wrapper canvas id='random-id-472' is='div' className='dayraS4 my-auto mx-auto rotate-45 border-none'>
                             <Wrapper  id='random-id-473' is='div' className='absolute left-[100px] -rotate-90 top-[-2px]'>
                                 <Wrapper  id='random-id-474' is='div' className='dayra1' style={{background:'#FFFFFF'}}></Wrapper>
                             </Wrapper>
                             <Wrapper  id='random-id-475' is='div' className='absolute left-[0px] rotate-180 top-[-1px]'>
                                 <Wrapper  id='random-id-476' is='div' className='dayra2' style={{background:'#D4ADFC'}}></Wrapper>
                             </Wrapper>
                             <Wrapper  id='random-id-477' is='div' className='absolute right-[123px] rotate-90 top-[29px]'>
                                 <Wrapper  id='random-id-478' is='div' className='dayra3 absolute left-[20px] rotate-45 top-[0px]' style={{background:'#FFFFFF'}}></Wrapper>
                             </Wrapper>
                             <Wrapper  id='random-id-479' is='div' className='w-[170px] h-[30px] -rotate-45 absolute top-[170px] left-[-70px] bg-[#FFFFFF] flex flex-row' style={{borderRadius:'30px'}}>
                                 <Wrapper  className='pl-4 my-auto' style={{display:'inline-block', fontSize:'16px', fontWeight:'bold', fontFamily:'Chivo', color:'#0C134F'}} id='random-id-57' is='div'>
                                     <Text text='22,4%'/>
                                 </Wrapper>
                                     <Wrapper  className='pl-2 my-auto' style={{display:'inline-block', fontSize:'16px', fontWeight:'400px', fontFamily:'Chivo', color:'#000000'}} id='random-id-58' is='div'>
                                         <Text text='Lorem Ipum'/>
                                     </Wrapper>
                             </Wrapper>
                             <Wrapper  id='random-id-480' is='div' className='w-[170px] h-[30px] -rotate-45 absolute top-[100px] left-[120px] bg-[#FFFFFF] flex flex-row' style={{borderRadius:'30px'}}>
                                 <Wrapper  className='pl-4 my-auto' style={{display:'inline-block', fontSize:'16px', fontWeight:'bold', fontFamily:'Chivo', color:'#D4ADFC'}} id='random-id-59' is='div'>
                                     <Text text='22,4%'/>
                                 </Wrapper>
                                     <Wrapper  className='pl-2 my-auto' style={{display:'inline-block', fontSize:'16px', fontWeight:'400px', fontFamily:'Chivo', color:'#000000'}} id='random-id-60' is='div'>
                                         <Text text='Lorem Ipum'/>
                                     </Wrapper>
                             </Wrapper>
                             <Wrapper  id='random-id-481' is='div' className='w-[170px] h-[30px] -rotate-45 absolute top-[-20px] left-[90px] bg-[#FFFFFF] flex flex-row' style={{borderRadius:'30px'}}>
                                 <Wrapper  className='pl-4 my-auto' style={{display:'inline-block', fontSize:'16px', fontWeight:'bold', fontFamily:'Chivo', color:'#0C134F'}} id='random-id-61' is='div'>
                                     <Text text='22,4%'/>
                                 </Wrapper>
                                     <Wrapper  className='pl-2 my-auto' style={{display:'inline-block', fontSize:'16px', fontWeight:'400px', fontFamily:'Chivo', color:'#000000'}} id='random-id-62' is='div'>
                                         <Text text='Lorem Ipum'/>
                                     </Wrapper>
                             </Wrapper>
                             <Wrapper  id='random-id-482' is='div' className='w-[170px] h-[30px] -rotate-45 absolute top-[70px] left-[-60px] bg-[#FFFFFF] flex flex-row' style={{borderRadius:'30px'}}>
                                 <Wrapper  className='pl-4 my-auto' style={{display:'inline-block', fontSize:'16px', fontWeight:'bold', fontFamily:'Chivo', color:'#D4ADFC'}} id='random-id-63' is='div'>
                                     <Text text='22,4%'/>
                                 </Wrapper>
                                     <Wrapper  className='pl-2 my-auto' style={{display:'inline-block', fontSize:'16px', fontWeight:'400px', fontFamily:'Chivo', color:'#000000'}} id='random-id-64' is='div'>
                                         <Text text='Lorem Ipum'/>
                                     </Wrapper>
                             </Wrapper>
                        </Wrapper>
                     </Wrapper>
                 </Wrapper>
                 <Wrapper canvas id='random-id-483' is='div' className='w-[60%] min-w-[358px] mx-auto h-full px-4'>
                     <Wrapper canvas id='random-id-484' is='div' className='w-full h-full flex flex-row my-4'>
                             <Wrapper canvas className='w-auto' style={{display:'inline-block', fontSize:'46px', fontWeight:'bold', fontFamily:'Chivo', color:'#0C134F'}} id='random-id-65' is='div'>
                                 <Text text='22,4%'/>
                             </Wrapper>
                             <Wrapper canvas className='px-4 my-auto' style={{display:'inline-block', fontSize:'18px', fontWeight:'400px', fontFamily:'Chivo'}} id='random-id-66' is='div'>
                                 <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                             </Wrapper>
                     </Wrapper>
                     <Wrapper canvas id='random-id-485' is='div' className='w-full h-full flex flex-row my-4'>
                             <Wrapper canvas className='w-auto' style={{display:'inline-block', fontSize:'46px', fontWeight:'bold', fontFamily:'Chivo', color:'#D4ADFC'}} id='random-id-67' is='div'>
                                 <Text text='22,4%'/>
                             </Wrapper>
                             <Wrapper canvas className='px-4 my-auto' style={{display:'inline-block', fontSize:'18px', fontWeight:'400px', fontFamily:'Chivo'}} id='random-id-68' is='div'>
                                 <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                             </Wrapper>
                     </Wrapper>
                     <Wrapper canvas id='random-id-486' is='div' className='w-full h-full flex flex-row my-4'>
                             <Wrapper canvas className='w-auto' style={{display:'inline-block', fontSize:'46px', fontWeight:'bold', fontFamily:'Chivo', color:'#0C134F'}} id='random-id-69' is='div'>
                                 <Text text='22,4%'/>
                             </Wrapper>
                             <Wrapper canvas className='px-4 my-auto' style={{display:'inline-block', fontSize:'18px', fontWeight:'400px', fontFamily:'Chivo'}} id='random-id-70' is='div'>
                                 <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                             </Wrapper>
                     </Wrapper>
                     <Wrapper canvas id='random-id-487' is='div' className='w-full h-full flex flex-row my-4'>
                             <Wrapper canvas className='w-auto' style={{display:'inline-block', fontSize:'46px', fontWeight:'bold', fontFamily:'Chivo', color:'#D4ADFC'}} id='random-id-71' is='div'>
                                 <Text text='22,4%'/>
                             </Wrapper>
                             <Wrapper canvas className='px-4 my-auto' style={{display:'inline-block', fontSize:'18px', fontWeight:'400px', fontFamily:'Chivo'}} id='random-id-72' is='div'>
                                 <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.'/>
                             </Wrapper>
                     </Wrapper>
                 </Wrapper>
             </Wrapper>
         </Wrapper>
     </Wrapper>
 </Wrapper></Element>
        </Element>
 ,
    ]
    
    const [sel, setSel] = useState(selected)

function makeOdd(number) {
  setProp((prop) => {
    setSel(100);
  }, 1000);

  setTimeout(() => {
    setProp((prop) => {
      setSel(number)
    }, 1000);
  }, 100);


  
}

useEffect(() => {
if(selected || selected >= 0) {
  makeOdd(selected);
}
},[selected])

    return (
        <div style={{ padding: enabled? "10px" : "0px", display:"flex", justifyContent:"center", alignItems:"center", width:'100%', height:'100%'}} ref={connect}>
        {sel == 100 ? (
          <div style={{height:"85vh", display:"flex", justifyContent:"center", alignItems:"center"}}>
          <BounceLoader
                  size={100}
                  color={"white"}
                  loading={true}
                />
          </div>
         ) : (
          styles[sel]
         )} 
    </div>
    );
}

    Statistics_4.craft = {
        displayName: "Statistics 4",
        props: {
        selected: 1, // here is the default value of the selected style
        length:3,
isBlock: true
        },
        rules: {
            canDrag: () => true,
            canDrop: () => true,
        },
        related: {
            toolbar: NewStatisticsSettings,
        },
    }