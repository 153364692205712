import React, { useEffect, useRef, useState } from 'react';
import { useNode } from '@craftjs/core';
import { WrapperSettings } from '../wrapper/WrapperSettings';
import { ResizableArea } from 'react-resizable-area';

const defaultProps = {
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  fillSpace: 'no',
  background: { r: 255, g: 255, b: 255, a: 1 },
  color: { r: 0, g: 0, b: 0, a: 1 },
  shadow: 0,
  radius: 0,
  width: '100%',
  height: '100px',
};

export const ResizableWrapper = (props) => {
  const {
    connectors: { connect, drag }
  } = useNode(node => ({
    selected: node.events.selected
  }));

  props = {
    ...defaultProps,
    ...props,
  };
  
  const {
    flexDirection,
    alignItems,
    justifyContent,
    fillSpace,
    background,
    color,
    padding,
    shadow,
    radius,
    children,
  } = props;

  const [marginRight, setMarginRight] = useState(0);
  const [marginLeft, setMarginLeft] = useState(0);
  const [marginTop, setMarginTop] = useState(0);
  const [marginBottom, setMarginBottom] = useState(0);

  const [paddingRight, setPaddingRight] = useState(0);
  const [paddingLeft, setPaddingLeft] = useState(0);
  const [paddingTop, setPaddingTop] = useState(0);
  const [paddingBottom, setPaddingBottom] = useState(0);

  const [activeBackground, setActiveBackground] = useState(false)
  const resizableRef = useRef(null);

  // Resize handlers for each margin side
  const handleResizeMargin = (side, e) => {
    const rect = resizableRef.current.getBoundingClientRect();
    let newValue;
    
    switch (side) {
      case 'right':
        newValue = e.clientX - rect.right;
        setMarginRight(Math.max(newValue, 0));
        break;
      case 'left':
        newValue = rect.left - e.clientX;
        setMarginLeft(Math.max(newValue, 0));
        break;
      case 'top':
        newValue = rect.top - e.clientY;
        setMarginTop(Math.max(newValue, 0));
        break;
      case 'bottom':
        newValue = e.clientY - rect.bottom;
        setMarginBottom(Math.max(newValue, 0));
        break;
      default:
        break;
    }
  };

  // Resize handlers for each padding side
  const handleResizePadding = (side, e) => {
    const rect = resizableRef.current.getBoundingClientRect();
    let newValue;

    switch (side) {
      case 'right':
        newValue = e.clientX - rect.right;
        setPaddingRight(Math.max(newValue, 0));
        break;
      case 'left':
        newValue = rect.left - e.clientX;
        setPaddingLeft(Math.max(newValue, 0));
        break;
      case 'top':
        newValue = rect.top - e.clientY;
        setPaddingTop(Math.max(newValue, 0));
        break;
      case 'bottom':
        newValue = e.clientY - rect.bottom;
        setPaddingBottom(Math.max(newValue, 0));
        break;
      default:
        break;
    }
  };

  const startResize = (side, type, e) => {
    e.preventDefault();
    setActiveBackground(true)
    const handleMouseMove = (event) =>
      type === 'margin'
        ? handleResizeMargin(side, event)
        : handleResizePadding(side, event);
    
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', () => {
      setActiveBackground(false)
      document.removeEventListener('mousemove', handleMouseMove);
    });
  };

  return (
    <div style={{
      // background:"rgb(235 174 112/.6)",
      marginTop: `${marginTop}px`,
      marginRight: `${marginRight}px`,
      marginBottom: `${marginBottom}px`,
      marginLeft: `${marginLeft}px`,
      width:"100%",
      height:"100%"
      }}>
    <ResizableArea
      className="helloworldsParent"
      minimumHeight={{ px: 0, percent: 10 }}
      minimumWidth={{ px: 0, percent: 10 }}
      initHeight={{ px: 0, percent: 100 }}
      initWidth={{ px: 0, percent: 100 }}
      defaultHeight={{ px: 0, percent: 100 }}
      defaultWidth={{ px: 0, percent: 80 }}
      maximumHeight={{ px: 0, percent: 100 }}
      maximumWidth={{ px: 0, percent: 100 }} 
      parentContainer={document.querySelector('.resizable-container')}
    >
      {/* Resize handles */}
     


      <div
        className='resize-handle-right marginClass'
        onMouseDown={(e) => startResize('right', 'margin', e)}
        style={{
          width: '10px',
          height: '100%', 
          cursor: 'ew-resize',
          position: 'absolute',
          right: `-${40 + marginRight}px`, 
          zIndex: 1,
        }}
      />
      <div
        className='resize-handle-left marginClass'
        onMouseDown={(e) => startResize('left', 'margin', e)}
        style={{
          width: '10px',
          height: '100%',
          cursor: 'ew-resize',
          position: 'absolute',
          left: `-${40 + marginLeft}px`, 
          zIndex: 1,
        }}
      />
      <div
        className='resize-handle-top marginClass'
        onMouseDown={(e) => startResize('top', 'margin', e)}
        style={{
          width: '100%',
          height: '10px',
          cursor: 'ns-resize',
          position: 'absolute',
          top: `-${40 + marginTop}px`,
          zIndex: 1,
        }}
      />
      <div
        className='resize-handle-bottom marginClass'
        onMouseDown={(e) => startResize('bottom', 'margin', e)}
        style={{
          width: '100%',
          height: '10px',
          cursor: 'ns-resize',
          position: 'absolute',
          bottom: `-${40 + marginBottom}px`,
          zIndex: 1,
        }}
      />

      {/* Resize handles for padding */}
      <div
        className='resize-handle-right paddingClass'
        onMouseDown={(e) => startResize('right', 'padding', e)}
        style={{
          width: '10px',
          height: '100%', 
          cursor: 'ew-resize',
          position: 'absolute',
          right: `${20 + paddingRight}px`,
          zIndex: 2,
        }}
      />
      <div
        className='resize-handle-left paddingClass'
        onMouseDown={(e) => startResize('left', 'padding', e)}
        style={{
          width: '10px',
          height: '100%', 
          cursor: 'ew-resize',
          position: 'absolute',
          left: `${20 + paddingLeft}px`,
          zIndex: 2,
        }}
      />
      <div
        className='resize-handle-top paddingClass'
        onMouseDown={(e) => startResize('top', 'padding', e)}
        style={{
          width: '100%',
          height: '10px',
          cursor: 'ns-resize',
          position: 'absolute',
          top: `${20 + paddingTop}px`,
          zIndex: 2,
        }}
      />
      <div
        className='resize-handle-bottom paddingClass'
        onMouseDown={(e) => startResize('bottom', 'padding', e)}
        style={{
          width: '100%',
          height: '10px',
          cursor: 'ns-resize',
          position: 'absolute',
          bottom: `${20 + paddingBottom}px`,
          zIndex: 2,
        }}
      />

      {/* Margins and paddings visualization */}
      {/* Visual for margins */}
      <div
        style={{
          position: 'absolute',
          right: `-${marginRight}px`,
          top: 0,
          width: `${marginRight}px`,
          height: '100%',
          borderRight: `${marginRight}px solid ${activeBackground ? "rgb(235 174 112/.6)" : "transparent"}`,
        }}
      />
      <div
        style={{
          position: 'absolute',
          left: `-${marginLeft}px`,
          top: 0,
          width: `${marginLeft}px`,
          height: '100%',
          borderLeft: `${marginLeft}px solid ${activeBackground ? "rgb(235 174 112/.6)" : "transparent"}`,
        }}
      />
      <div
        style={{
          position: 'absolute',
          left: 0,
          top: `-${marginTop}px`,
          width: '100%',
          height: `${marginTop}px`,
          borderTop: `${marginTop}px solid ${activeBackground ? "rgb(235 174 112/.6)" : "transparent"}`,
        }}
      />
      <div
        style={{
          position: 'absolute',
          left: 0,
          bottom: `-${marginBottom}px`,
          width: '100%',
          height: `${marginBottom}px`,
          borderBottom: `${marginBottom}px solid ${activeBackground ? "rgb(235 174 112/.6)" : "transparent"}`,
        }}
      />

      {/* Visual for paddings */}
      {/* <div
        style={{
          position: 'absolute',
          right: `${paddingRight}px`,
          top: 0,
          width: `${paddingRight}px`,
          height: '100%',
          borderRight: `${paddingRight}px solid rgb(150 255 200 /.6)`,
        }}
      />
      <div
        style={{
          position: 'absolute',
          left: `${paddingLeft}px`,
          top: 0,
          width: `${paddingLeft}px`,
          height: '100%',
          borderLeft: `${paddingLeft}px solid rgb(150 255 200 /.6)`,
        }}
      />
      <div
        style={{
          position: 'absolute',
          left: 0,
          top: `${paddingTop}px`,
          width: '100%',
          height: `${paddingTop}px`,
          borderTop: `${paddingTop}px solid rgb(150 255 200 /.6)`,
        }}
      />
      <div
        style={{
          position: 'absolute',
          left: 0,
          bottom: `${paddingBottom}px`,
          width: '100%',
          height: `${paddingBottom}px`,
          borderBottom: `${paddingBottom}px solid rgb(150 255 200 /.6)`,
        }}
      />
       */}

      <div ref={resizableRef} className="helloworlds" 
        style={{ 
          background: activeBackground ? "rgb(150 255 200 /.6)" : "transparent",
          paddingTop: `${paddingTop}px`,
          paddingRight: `${paddingRight}px`,
          paddingBottom: `${paddingBottom}px`,
          paddingLeft: `${paddingLeft}px`,
          width: '100%', height: '100%'
        }}>
        {children}
      </div>
    </ResizableArea>
    </div>
  );
};

ResizableWrapper.craft = {
  displayName: 'Resizable',
  props: defaultProps,
  rules: {
    canDrag: () => true,
    canDrop: () => true
  },
  related: {
    toolbar: WrapperSettings,
  },
};
