/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, {useState, useEffect, useRef} from 'react';
import { Element, useEditor, useNode } from '@craftjs/core';
import {NewEcommerceSettings} from './newEcommerceSetting'
import { Text } from '../../basic/Text';
import { ImageC } from '../../basic/Image';
import { Button } from '../../basic/Button';
import { Parent } from '../../Parent/index.js';
import { Wrapper } from '../../wrapper/index.js';
import { BounceLoader } from 'react-spinners';


export const Ecommerce_1 = ({selected}) => {

 
    const { 
        actions: { setProp },
          connectors: {
            connect
          } 
      } = useNode();
    
      
      const {
        enabled,
      } = useEditor((state, query) => ({
        enabled: state.options.enabled,
     
      }));
    

    const styles = [
        <Element canvas is={Parent} id='parentId100' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId200' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-400' is='parent' className='w-[100%] h-full bg-[white] py-10' >
        <Wrapper canvas id='random-id-401' is='container' className='container'>
                <Wrapper canvas style={{display:'inline-block', fontWeight:'700', fontFamily:'Rubik'}} className={`text-[#100103] my-4 text-[46px] pl-6`} id='random-id-1' is='div'>
                        <Text text='Our best products'/>
                </Wrapper>
            <Wrapper canvas id='random-id-402' is='wrapper' className='w-full h-full flex flex-row flex-wrap justify-center'>
            <Wrapper canvas id='random-id-403' is='div' className=' h-full flex flex-col text-center px-4 mx-auto my-2 ' style={{border: '1px solid #DBD9D9',margin:'0 10px', flex:'28%', maxWidth:'380px'}}>
                <Wrapper canvas id='random-id-404' is='div' className='w-full h-[207px] my-4 flex mx-auto justify-center'>
                    <Wrapper  canvas style={{display:'inline-block', width:'100%', height:'100%'}} id='random-id-100' is='div'>
                        <ImageC className='w-[100%] h-[100%] object-cover' src='https://i.ibb.co/fx9FXrV/Ecommerce.png'/>
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-405' is='div' className='flex flex-col text-[#100103] items-center '>
                        <Wrapper canvas style={{display:'inline-block', fontSize:'24px', fontWeight:'700', fontFamily:'Rubik', letterSpacing:'0.5px'}} className='my-4' id='random-id-3' is='div'>
                        <Text text='Product name'/>
                </Wrapper>
                    <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', textAlignLast:'center'}} id='random-id-4' is='div'>
                        <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...'/>
                    </Wrapper>
                        <Wrapper canvas style={{display:'inline-block', color:'#B20312', fontSize:'34px', fontWeight:'700'}} className='my-4 ' id='random-id-5' is='div'>
                        <Text text='$299'/>
                    </Wrapper>
                    <Wrapper canvas id='random-id-200' is='div' className='mx-auto mb-4'>
                        <Button  classn='block' class='flex w-[260px] h-[70px] cursor-pointer mb-4 bg-[#B20312] hover:bg-[#87000C] uppercase text-[#FFFFFF]' style={{ border: '1px solid #FFFFFF1A', boxShadow: '0px 4px 10px 0px #FFFFFF0D'}}>
                            <Wrapper canvas style={{display:'inline-block', fontSize:'24px', fontWeight:'600', fontFamily:'Rubik',}} className='mx-auto my-auto' id='random-id-6' is='div'>
                        <Text text='Learn more'/>
                    </Wrapper>
                        </Button>
                    </Wrapper>
                </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-406' is='div' className='h-full flex flex-col text-center px-4 mx-auto my-2' style={{border: '1px solid #DBD9D9',margin:'0 10px', flex:'28%', maxWidth:'380px'}}>
                <Wrapper canvas id='random-id-407' is='div' className='w-full h-[207px] my-4 flex mx-auto justify-center'>
                    <Wrapper  canvas style={{display:'inline-block', width:'100%', height:'100%'}} id='random-id-101' is='div'>
                        <ImageC className='w-[100%] h-[100%] object-cover' src='https://i.ibb.co/fx9FXrV/Ecommerce.png'/>
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-408' is='div' className='flex flex-col text-[#100103] items-center '>
                        <Wrapper canvas style={{display:'inline-block', fontSize:'24px', fontWeight:'700', fontFamily:'Rubik', letterSpacing:'0.5px'}} className='my-4' id='random-id-7' is='div'>
                        <Text text='Product name'/>
                </Wrapper>
                        <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', textAlignLast:'center'}} id='random-id-8' is='div'>
                        <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...'/>
                    </Wrapper>
                        <Wrapper canvas style={{display:'inline-block', color:'#B20312', fontSize:'34px', fontWeight:'700'}} className='my-4  ' id='random-id-9' is='div'>
                        <Text text='$299'/>
                    </Wrapper>
                    <Wrapper canvas id='random-id-201' is='div' className='mx-auto mb-4'>
                        <Button  classn='block' class='flex w-[260px] h-[70px] cursor-pointer mb-4 bg-[#B20312] hover:bg-[#87000C] uppercase text-[#FFFFFF]' style={{ border: '1px solid #FFFFFF1A', boxShadow: '0px 4px 10px 0px #FFFFFF0D'}}>
                            <Wrapper canvas style={{display:'inline-block', fontSize:'24px', fontWeight:'600', fontFamily:'Rubik',}} className='mx-auto my-auto' id='random-id-10' is='div'>
                        <Text text='Learn more'/>
                    </Wrapper>
                        </Button>
                    </Wrapper>
                </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-409' is='div' className='h-full flex flex-col text-center px-4 mx-auto my-2' style={{border: '1px solid #DBD9D9',margin:'0 10px', flex:'28%', maxWidth:'380px'}}>
                <Wrapper canvas id='random-id-410' is='div' className='w-full h-[207px] my-4 flex mx-auto justify-center'>
                    <Wrapper canvas style={{display:'inline-block', width:'100%', height:'100%'}} id='random-id-102' is='div'>
                        <ImageC className='w-[100%] h-[100%] object-cover' src='https://i.ibb.co/fx9FXrV/Ecommerce.png'/>
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-411' is='div' className='flex flex-col text-[#100103] items-center '>
                        <Wrapper canvas style={{display:'inline-block', fontSize:'24px', fontWeight:'700', fontFamily:'Rubik', letterSpacing:'0.5px'}} className='my-4'  id='random-id-11' is='div'>
                        <Text text='Product name'/>
                </Wrapper>
                        <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', textAlignLast:'center'}} id='random-id-12' is='div'>
                        <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...'/>
                    </Wrapper>
                        <Wrapper canvas style={{display:'inline-block', color:'#B20312', fontSize:'34px', fontWeight:'700'}} className='my-4  '  id='random-id-13' is='div'>
                        <Text text='$299'/>
                    </Wrapper>
                    <Wrapper canvas id='random-id-202' is='div' className='mx-auto mb-4'>
                        <Button  classn='block' class='flex w-[260px] h-[70px] cursor-pointer mb-4 bg-[#B20312] hover:bg-[#87000C] uppercase text-[#FFFFFF]' style={{ border: '1px solid #FFFFFF1A', boxShadow: '0px 4px 10px 0px #FFFFFF0D'}}>
                            <Wrapper canvas style={{display:'inline-block', fontSize:'24px', fontWeight:'600', fontFamily:'Rubik',}} className='mx-auto my-auto' id='random-id-14' is='div'>
                        <Text text='Learn more'/>
                    </Wrapper>
                        </Button>
                    </Wrapper>
                </Wrapper>
            </Wrapper>
        </Wrapper> 
    </Wrapper>
</Wrapper></Element>
        </Element>
    ,

    <Element canvas is={Parent} id='parentId100' className="w-[100%] h-auto" >
    <Element canvas is={Parent} id='parentId200' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-400' is='parent' className='w-[100%] h-full bg-[#121826] py-10' >
    <Wrapper canvas id='random-id-401' is='container' className='container'>
            <Wrapper canvas style={{display:'inline-block', fontWeight:'700', fontFamily:'Rubik'}} className={`text-[#3265E3] my-4 text-[46px] pl-6`} id='random-id-1' is='div'>
                    <Text text='Our best products'/>
            </Wrapper>
        <Wrapper canvas id='random-id-402' is='wrapper' className='w-full h-full flex flex-row flex-wrap justify-center'>
        <Wrapper canvas id='random-id-403' is='div' className=' h-full flex flex-col text-center px-4 mx-auto my-2 ' style={{border: '1px solid #ffffff43',margin:'0 10px', flex:'28%', maxWidth:'380px',}}>
            <Wrapper canvas id='random-id-404' is='div' className='w-full h-[207px] my-4 flex mx-auto justify-center'>
                <Wrapper  canvas style={{display:'inline-block', width:'100%', height:'100%'}} id='random-id-100' is='div'>
                    <ImageC className='w-[100%] h-[100%] object-cover' src='https://i.ibb.co/fx9FXrV/Ecommerce.png'/>
                </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-405' is='div' className='flex flex-col text-[#FFFFFF] items-center '>
                    <Wrapper canvas style={{display:'inline-block', fontSize:'24px', fontWeight:'700', fontFamily:'Rubik', letterSpacing:'0.5px'}} className='my-4' id='random-id-3' is='div'>
                    <Text text='Product name'/>
            </Wrapper>
                <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', textAlignLast:'center'}} id='random-id-4' is='div'>
                    <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...'/>
                </Wrapper>
                    <Wrapper canvas style={{display:'inline-block', color:'#4BDBA2', fontSize:'34px', fontWeight:'700'}} className='my-4 ' id='random-id-5' is='div'>
                    <Text text='$299'/>
                </Wrapper>
                <Wrapper canvas id='random-id-200' is='div' className='mx-auto mb-4'>
                    <Button  classn='block' class='flex w-[260px] h-[70px] cursor-pointer mb-4 bg-[#3265E3] hover:bg-[#0049FF] uppercase text-[#FFFFFF]' style={{ border: '1px solid #FFFFFF1A', boxShadow: '0px 4px 10px 0px #FFFFFF0D'}}>
                        <Wrapper canvas style={{display:'inline-block', fontSize:'24px', fontWeight:'600', fontFamily:'Rubik',}} className='mx-auto my-auto' id='random-id-6' is='div'>
                    <Text text='Learn more'/>
                </Wrapper>
                    </Button>
                </Wrapper>
            </Wrapper>
        </Wrapper>
        <Wrapper canvas id='random-id-406' is='div' className='h-full flex flex-col text-center px-4 mx-auto my-2' style={{border: '1px solid #ffffff43',margin:'0 10px', flex:'28%', maxWidth:'380px',}}>
            <Wrapper canvas id='random-id-407' is='div' className='w-full h-[207px] my-4 flex mx-auto justify-center'>
                <Wrapper  canvas style={{display:'inline-block', width:'100%', height:'100%'}} id='random-id-101' is='div'>
                    <ImageC className='w-[100%] h-[100%] object-cover' src='https://i.ibb.co/fx9FXrV/Ecommerce.png'/>
                </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-408' is='div' className='flex flex-col text-[#FFFFFF] items-center '>
                    <Wrapper canvas style={{display:'inline-block', fontSize:'24px', fontWeight:'700', fontFamily:'Rubik', letterSpacing:'0.5px'}} className='my-4' id='random-id-7' is='div'>
                    <Text text='Product name'/>
            </Wrapper>
                    <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', textAlignLast:'center'}} id='random-id-8' is='div'>
                    <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...'/>
                </Wrapper>
                    <Wrapper canvas style={{display:'inline-block', color:'#4BDBA2', fontSize:'34px', fontWeight:'700'}} className='my-4  ' id='random-id-9' is='div'>
                    <Text text='$299'/>
                </Wrapper>
                <Wrapper canvas id='random-id-201' is='div' className='mx-auto mb-4'>
                    <Button  classn='block' class='flex w-[260px] h-[70px] cursor-pointer mb-4 bg-[#3265E3] hover:bg-[#0049FF] uppercase text-[#FFFFFF]' style={{ border: '1px solid #FFFFFF1A', boxShadow: '0px 4px 10px 0px #FFFFFF0D'}}>
                        <Wrapper canvas style={{display:'inline-block', fontSize:'24px', fontWeight:'600', fontFamily:'Rubik',}} className='mx-auto my-auto' id='random-id-10' is='div'>
                    <Text text='Learn more'/>
                </Wrapper>
                    </Button>
                </Wrapper>
            </Wrapper>
        </Wrapper>
        <Wrapper canvas id='random-id-409' is='div' className='h-full flex flex-col text-center px-4 mx-auto my-2' style={{border: '1px solid #ffffff43',margin:'0 10px', flex:'28%', maxWidth:'380px',}}>
            <Wrapper canvas id='random-id-410' is='div' className='w-full h-[207px] my-4 flex mx-auto justify-center'>
                <Wrapper canvas style={{display:'inline-block', width:'100%', height:'100%'}} id='random-id-102' is='div'>
                    <ImageC className='w-[100%] h-[100%] object-cover' src='https://i.ibb.co/fx9FXrV/Ecommerce.png'/>
                </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-411' is='div' className='flex flex-col text-[#FFFFFF] items-center '>
                    <Wrapper canvas style={{display:'inline-block', fontSize:'24px', fontWeight:'700', fontFamily:'Rubik', letterSpacing:'0.5px'}} className='my-4'  id='random-id-11' is='div'>
                    <Text text='Product name'/>
            </Wrapper>
                    <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', textAlignLast:'center'}} id='random-id-12' is='div'>
                    <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...'/>
                </Wrapper>
                    <Wrapper canvas style={{display:'inline-block', color:'#4BDBA2', fontSize:'34px', fontWeight:'700'}} className='my-4  '  id='random-id-13' is='div'>
                    <Text text='$299'/>
                </Wrapper>
                <Wrapper canvas id='random-id-202' is='div' className='mx-auto mb-4'>
                    <Button  classn='block' class='flex w-[260px] h-[70px] cursor-pointer mb-4 bg-[#3265E3] hover:bg-[#0049FF] uppercase text-[#FFFFFF]' style={{ border: '1px solid #FFFFFF1A', boxShadow: '0px 4px 10px 0px #FFFFFF0D'}}>
                        <Wrapper canvas style={{display:'inline-block', fontSize:'24px', fontWeight:'600', fontFamily:'Rubik',}} className='mx-auto my-auto' id='random-id-14' is='div'>
                    <Text text='Learn more'/>
                </Wrapper>
                    </Button>
                </Wrapper>
            </Wrapper>
        </Wrapper>
    </Wrapper> 
</Wrapper>
</Wrapper></Element>
    </Element>
,
    <Element canvas is={Parent} id='parentId300' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId400' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-412' is='parent' className='w-[100%] h-full bg-[#213156] py-10' >
    <Wrapper canvas id='random-id-413' is='container' className='container '>
        <Wrapper canvas style={{display:'inline-block', fontWeight:'700', fontFamily:'Open Sans'}} className={`text-[#EA6EF4]  my-4 text-[46px] pl-6`} id='random-id-28' is='div'>
                    <Text text='Our best products'/>
                </Wrapper>
        <Wrapper canvas id='random-id-414' is='wrapper' className='w-full h-full flex flex-row flex-wrap justify-center'>
        <Wrapper canvas id='random-id-415' is='div' className=' h-full flex flex-col text-center px-4 my-2 mx-auto ' style={{border: '1px solid #EA6EF41A' , boxShadow: '0px 0px 4px 0px #FFFFFF1A', borderRadius:'10px',margin:'0 10px', flex:'28%', maxWidth:'380px'}}>
            <Wrapper canvas id='random-id-416' is='div' className='w-full h-[207px] my-4 flex mx-auto justify-center'>
                <Wrapper canvas style={{display:'inline-block', width:'100%', height:'100%'}} id='random-id-106' is='div'>
                    <ImageC className='w-[100%] h-[100%] object-cover' src='https://i.ibb.co/fx9FXrV/Ecommerce.png'/>
                </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-417' is='div' className='flex flex-col text-white items-center '>
                    <Wrapper canvas style={{display:'inline-block', fontSize:'24px', fontWeight:'700', fontFamily:'Open Sans', letterSpacing:'0.5px'}} className='my-4' id='random-id-29' is='div'>
                    <Text text='Product name'/>
            </Wrapper>
                    <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Open Sans', textAlignLast:'center'}} id='random-id-30' is='div'>
                    <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...'/>
                </Wrapper>
                    <Wrapper canvas style={{display:'inline-block', color:'#EA6EF4', fontSize:'34px', fontWeight:'700', fontFamily:'Times New Roman'}} className='my-4  ' id='random-id-31' is='div'>
                    <Text text='$299'/>
                </Wrapper>
                <Wrapper id='random-id-206' is='div' className='mx-auto mb-4'>
                    <Button  classn='block' class='flex w-[260px] h-[70px] cursor-pointer mb-4 bg-[#EA6EF4] hover:bg-[#E111F2] text-[#FFF] uppercase' style={{ borderRadius:'40px'}}>
                        <Wrapper canvas style={{display:'inline-block', fontSize:'24px', fontWeight:'600', fontFamily:'Open Sans',}} className='mx-auto my-auto' id='random-id-32' is='div'>
                    <Text text='Learn more'/>
                </Wrapper>
                    </Button>
                </Wrapper>
            </Wrapper>
        </Wrapper>
        <Wrapper canvas id='random-id-418' is='div' className='  h-full flex flex-col text-center px-4 my-2 mx-auto' style={{border: '1px solid #EA6EF41A' , boxShadow: '0px 0px 4px 0px #FFFFFF1A', borderRadius:'10px',margin:'0 10px', flex:'28%', maxWidth:'380px'}}>
            <Wrapper canvas id='random-id-419' is='div' className='w-full h-[207px] my-4 flex mx-auto justify-center'>
                <Wrapper canvas style={{display:'inline-block', width:'100%', height:'100%'}} id='random-id-107' is='div'>
                    <ImageC className='w-[100%] h-[100%] object-cover' src='https://i.ibb.co/fx9FXrV/Ecommerce.png'/>
                </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-420' is='div' className='flex flex-col text-white items-center '>
                    <Wrapper canvas style={{display:'inline-block', fontSize:'24px', fontWeight:'700', fontFamily:'Open Sans', letterSpacing:'0.5px'}} className='my-4'  id='random-id-33' is='div'>
                    <Text text='Product name'/>
            </Wrapper>
                    <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Open Sans', textAlignLast:'center'}} id='random-id-34' is='div'>
                    <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...'/>
                </Wrapper>
                    <Wrapper canvas style={{display:'inline-block', color:'#EA6EF4', fontSize:'34px', fontWeight:'700', fontFamily:'Times New Roman'}} className='my-4  '  id='random-id-35' is='div'>
                    <Text text='$299'/>
                </Wrapper>
                <Wrapper id='random-id-207' is='div' className='mx-auto mb-4'>
                    <Button  classn='block' class='flex w-[260px] h-[70px] cursor-pointer mb-4 bg-[#EA6EF4] hover:bg-[#E111F2] text-[#FFF] uppercase' style={{ borderRadius:'40px'}}>
                        <Wrapper canvas style={{display:'inline-block', fontSize:'24px', fontWeight:'600', fontFamily:'Open Sans',}} className='mx-auto my-auto' id='random-id-36' is='div'>
                    <Text text='Learn more'/>
                </Wrapper>
                    </Button>
                </Wrapper>
            </Wrapper>
        </Wrapper>
        <Wrapper canvas id='random-id-421' is='div' className='  h-full flex flex-col text-center px-4 my-2 mx-auto' style={{border: '1px solid #EA6EF41A' , boxShadow: '0px 0px 4px 0px #FFFFFF1A', borderRadius:'10px',margin:'0 10px', flex:'28%', maxWidth:'380px'}}>
            <Wrapper canvas id='random-id-422' is='div' className='w-full h-[207px] my-4 flex mx-auto justify-center'>
                <Wrapper canvas style={{display:'inline-block', width:'100%', height:'100%'}} id='random-id-108' is='div'>
                    <ImageC className='w-[100%] h-[100%] object-cover' src='https://i.ibb.co/fx9FXrV/Ecommerce.png'/>
                </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-423' is='div' className='flex flex-col text-white items-center '>
                    <Wrapper canvas style={{display:'inline-block', fontSize:'24px', fontWeight:'700', fontFamily:'Open Sans', letterSpacing:'0.5px'}} className='my-4' id='random-id-37' is='div'>
                    <Text text='Product name'/>
            </Wrapper>
                    <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Open Sans', textAlignLast:'center'}} id='random-id-38' is='div'>
                    <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...'/>
                </Wrapper>
                    <Wrapper canvas style={{display:'inline-block', color:'#EA6EF4', fontSize:'34px', fontWeight:'700', fontFamily:'Times New Roman'}} className='my-4  '  id='random-id-39' is='div'>
                    <Text text='$299'/>
                </Wrapper>
                <Wrapper id='random-id-208' is='div' className='mx-auto mb-4'>
                    <Button  classn='block' class='flex w-[260px] h-[70px] cursor-pointer mb-4 bg-[#EA6EF4] hover:bg-[#E111F2] text-[#FFF] uppercase' style={{ borderRadius:'40px'}}>
                        <Wrapper canvas style={{display:'inline-block', fontSize:'24px', fontWeight:'600', fontFamily:'Open Sans',}} className='mx-auto my-auto' id='random-id-40' is='div'>
                    <Text text='Learn more'/>
                </Wrapper>
                    </Button>
                </Wrapper>
            </Wrapper>
        </Wrapper>
    </Wrapper>
    </Wrapper>
</Wrapper></Element>
        </Element>, 

<Element canvas is={Parent} id='parentId500' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId600' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-424' is='parent' className='w-[100%] h-full bg-[#DFFFF9] py-10' >
            <Wrapper canvas id='random-id-425' is='container' className='container '>
                <Wrapper canvas style={{display:'inline-block', fontWeight:'700', fontFamily:'Zing Script Rust SemiBold Demo', fontStyle:'italic'}} className={`text-[#31A993]  my-4 text-[46px] pl-6`} id='random-id-41' is='div'>
                            <Text text='Our best products'/>
                        </Wrapper>
                <Wrapper canvas id='random-id-426' is='wrapper' className='w-full h-full flex flex-row flex-wrap justify-center'>
                <Wrapper canvas id='random-id-427' is='div' className=' h-full flex flex-col text-center px-4 my-2 mx-auto ' style={{border: '1px solid #31A9931A' , boxShadow: '0px 0px 4px 0px #0825201A', borderRadius:'30px',margin:'0 10px', flex:'28%', maxWidth:'380px'}}>
                    <Wrapper canvas id='random-id-428' is='div' className='w-full h-[207px] my-4 flex mx-auto justify-center'>
                        <Wrapper canvas style={{display:'inline-block', width:'100%', height:'100%'}} id='random-id-109' is='div'>
                            <ImageC className='w-[100%] h-[100%] object-cover' src='https://i.ibb.co/fx9FXrV/Ecommerce.png'/>
                        </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-429' is='div' className='flex flex-col text-[#031815] items-center '>
                            <Wrapper canvas style={{display:'inline-block', fontSize:'24px', fontWeight:'700', fontFamily:'Montserrat', letterSpacing:'0.5px'}} className='my-4'  id='random-id-42' is='div'>
                            <Text text='Product name'/>
                    </Wrapper>
                            <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Montserrat', textAlignLast:'center'}} id='random-id-43' is='div'>
                            <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...'/>
                        </Wrapper>
                            <Wrapper canvas style={{display:'inline-block', color:'#31A993', fontSize:'34px', fontWeight:'700', fontFamily:'Zing Script Rust SemiBold Demo', fontStyle:'italic' }} className='my-4  ' id='random-id-44' is='div'>
                            <Text text='$299'/>
                        </Wrapper>
                        <Wrapper canvas id='random-id-209' is='div' className='mx-auto mb-4'>
                            <Button  classn='block' class='flex w-[260px] h-[70px] cursor-pointer mb-4 bg-[#31a993] hover:bg-[#11816C] text-[#FFF] uppercase' style={{ borderRadius:'40px'}}>
                                <Wrapper canvas style={{display:'inline-block', fontSize:'24px', fontWeight:'600', fontFamily:'Open Sans',}} className='mx-auto my-auto' id='random-id-45' is='div'>
                            <Text text='Learn more'/>
                        </Wrapper>
                            </Button>
                        </Wrapper>
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-430' is='div' className='  h-full flex flex-col text-center px-4 my-2 mx-auto' style={{border: '1px solid #31A9931A' , boxShadow: '0px 0px 4px 0px #0825201A', borderRadius:'30px',margin:'0 10px', flex:'28%', maxWidth:'380px'}}>
                    <Wrapper canvas id='random-id-431' is='div' className='w-full h-[207px] my-4 flex mx-auto justify-center'>
                        <Wrapper canvas style={{display:'inline-block', width:'100%', height:'100%'}} id='random-id-110' is='div'>
                            <ImageC className='w-[100%] h-[100%] object-cover' src='https://i.ibb.co/fx9FXrV/Ecommerce.png'/>
                        </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-432' is='div' className='flex flex-col text-[#031815] items-center '>
                            <Wrapper canvas className='my-4' style={{display:'inline-block', fontSize:'24px', fontWeight:'700', fontFamily:'Montserrat', letterSpacing:'0.5px'}} id='random-id-46' is='div'>
                            <Text text='Product name'/>
                    </Wrapper>
                            <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Montserrat', textAlignLast:'center'}} id='random-id-47' is='div'>
                            <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...'/>
                        </Wrapper>
                            <Wrapper canvas style={{display:'inline-block', color:'#31A993', fontSize:'34px', fontWeight:'700', fontFamily:'Zing Script Rust SemiBold Demo', fontStyle:'italic'}} className='my-4  '  id='random-id-48' is='div'>
                            <Text text='$299'/>
                        </Wrapper>
                        <Wrapper canvas id='random-id-210' is='div' className='mx-auto mb-4'>
                            <Button  classn='block' class='flex w-[260px] h-[70px] cursor-pointer mb-4 bg-[#31a993] hover:bg-[#11816C] text-[#FFF] uppercase' style={{ borderRadius:'40px'}}>
                                <Wrapper canvas style={{display:'inline-block', fontSize:'24px', fontWeight:'600', fontFamily:'Open Sans',}} className='mx-auto my-auto' id='random-id-50' is='div'>
                            <Text text='Learn more'/>
                        </Wrapper>
                            </Button>
                        </Wrapper>
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-433' is='div' className='  h-full flex flex-col text-center px-4 my-2 mx-auto' style={{border: '1px solid #31A9931A' , boxShadow: '0px 0px 4px 0px #0825201A', borderRadius:'30px',margin:'0 10px', flex:'28%', maxWidth:'380px'}}>
                    <Wrapper canvas id='random-id-434' is='div' className='w-full h-[207px] my-4 flex mx-auto justify-center'>
                        <Wrapper canvas style={{display:'inline-block', width:'100%', height:'100%'}} id='random-id-111' is='div'>
                            <ImageC className='w-[100%] h-[100%] object-cover' src='https://i.ibb.co/fx9FXrV/Ecommerce.png'/>
                        </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-435' is='div' className='flex flex-col text-[#031815] items-center '>
                            <Wrapper canvas style={{display:'inline-block', fontSize:'24px', fontWeight:'700', fontFamily:'Montserrat', letterSpacing:'0.5px'}} className='my-4'  id='random-id-50' is='div'>
                            <Text text='Product name'/>
                    </Wrapper>
                            <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Montserrat', textAlignLast:'center'}} id='random-id-51' is='div'>
                            <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...'/>
                        </Wrapper>
                            <Wrapper canvas style={{display:'inline-block', color:'#31A993', fontSize:'34px', fontWeight:'700', fontFamily:'Zing Script Rust SemiBold Demo', fontStyle:'italic'}} className='my-4  '  id='random-id-52' is='div'>
                            <Text text='$299'/>
                        </Wrapper>
                        <Wrapper canvas id='random-id-211' is='div' className='mx-auto mb-4'>
                            <Button  classn='block' class='flex w-[260px] h-[70px] cursor-pointer mb-4 bg-[#31a993] hover:bg-[#11816C] text-[#FFF] uppercase' style={{ borderRadius:'40px'}}>
                                <Wrapper canvas style={{display:'inline-block', fontSize:'24px', fontWeight:'600', fontFamily:'Open Sans',}} className='mx-auto my-auto' id='random-id-53' is='div'>
                            <Text text='Learn more'/>
                        </Wrapper>
                            </Button>
                        </Wrapper>
                    </Wrapper>
                </Wrapper>
            </Wrapper>
            </Wrapper>
        </Wrapper></Element>
        </Element>,

<Element canvas is={Parent} id='parentId700' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId800' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-436' is='parent' className='w-[100%] h-full bg-[white] py-10' >
<Wrapper canvas id='random-id-437' is='container' className='container '>
    <Wrapper canvas style={{display:'inline-block', fontWeight:'700', fontFamily:'Inter'}} className={`text-[#0078D3]  my-4 text-[46px] pl-6`} id='random-id-54' is='div'>
                <Text text='Our best products.'/>
            </Wrapper>
    <Wrapper canvas id='random-id-438' is='wrapper' className='w-full h-full flex flex-row flex-wrap justify-center'>
    <Wrapper canvas id='random-id-439' is='div' className=' h-full flex flex-col text-center px-4 my-2 mx-auto ' style={{border: '1px solid #DBD9D9', borderRadius:'4px',margin:'0 10px', flex:'28%', maxWidth:'380px'}}>
        <Wrapper canvas id='random-id-440' is='div' className='w-full h-[207px] my-4 flex mx-auto justify-center'>
            <Wrapper canvas style={{display:'inline-block', width:'100%', height:'100%'}} id='random-id-112' is='div'>
                <ImageC className='w-[100%] h-[100%] object-cover' src='https://i.ibb.co/fx9FXrV/Ecommerce.png'/>
            </Wrapper>
        </Wrapper>
        <Wrapper canvas id='random-id-441' is='div' className='flex flex-col text-[#100103] items-center '>
                <Wrapper canvas className='my-4' style={{display:'inline-block', fontSize:'24px', fontWeight:'700', fontFamily:'Inter', letterSpacing:'0.5px'}} id='random-id-56' is='div'>
                <Text text='Product name'/>
        </Wrapper>
                <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Inter', textAlignLast:'center'}} id='random-id-57' is='div'>
                <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...'/>
            </Wrapper>
                <Wrapper canvas style={{display:'inline-block', color:'#0078D3', fontSize:'34px', fontWeight:'700'}} className='my-4  '  id='random-id-58' is='div'>
                <Text text='$299'/>
            </Wrapper>
            <Wrapper canvas id='random-id-211' is='div' className='mx-auto mb-4'>
                <Button  classn='block' class='flex w-[260px] h-[70px] cursor-pointer mb-4 bg-[#0078d3] hover:bg-[#11578B] text-[#FFF] uppercase' style={{ boxShadow: '0px 4px 10px 0px #0000001A'}}>
                    <Wrapper canvas style={{display:'inline-block', fontSize:'24px', fontWeight:'600', fontFamily:'Inter',}} className='mx-auto my-auto' id='random-id-59' is='div'>
                <Text text='Learn more'/>
            </Wrapper>
                </Button>
            </Wrapper>
        </Wrapper>
    </Wrapper>
    <Wrapper canvas id='random-id-442' is='div' className='  h-full flex flex-col text-center px-4 my-2 mx-auto' style={{border: '1px solid #DBD9D9', borderRadius:'4px',margin:'0 10px', flex:'28%', maxWidth:'380px'}}>
        <Wrapper canvas id='random-id-443' is='div' className='w-full h-[207px] my-4 flex mx-auto justify-center'>
            <Wrapper canvas style={{display:'inline-block', width:'100%', height:'100%'}} id='random-id-113' is='div'>
                <ImageC className='w-[100%] h-[100%] object-cover' src='https://i.ibb.co/fx9FXrV/Ecommerce.png'/>
            </Wrapper>
        </Wrapper>
        <Wrapper canvas id='random-id-444' is='div' className='flex flex-col text-[#100103] items-center '>
                <Wrapper canvas style={{display:'inline-block', fontSize:'24px', fontWeight:'700', fontFamily:'Inter', letterSpacing:'0.5px'}} className='my-4' id='random-id-60' is='div'>
                <Text text='Product name'/>
        </Wrapper>
                <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Inter', textAlignLast:'center'}} id='random-id-61' is='div'>
                <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...'/>
            </Wrapper>
                <Wrapper canvas style={{display:'inline-block', color:'#0078D3', fontSize:'34px', fontWeight:'700'}} className='my-4  ' id='random-id-62' is='div'>
                <Text text='$299'/>
            </Wrapper>
            <Wrapper canvas id='random-id-212' is='div' className='mx-auto mb-4'>
                <Button  classn='block' class='flex w-[260px] h-[70px] cursor-pointer mb-4 bg-[#0078d3] hover:bg-[#11578B] text-[#FFF] uppercase' style={{ boxShadow: '0px 4px 10px 0px #0000001A'}}>
                    <Wrapper canvas style={{display:'inline-block', fontSize:'24px', fontWeight:'600', fontFamily:'Inter',}} className='mx-auto my-auto' id='random-id-63' is='div'>
                <Text text='Learn more'/>
            </Wrapper>
                </Button>
            </Wrapper>
        </Wrapper>
    </Wrapper>
    <Wrapper canvas id='random-id-445' is='div' className='  h-full flex flex-col text-center px-4 my-2 mx-auto' style={{border: '1px solid #DBD9D9', borderRadius:'4px',margin:'0 10px', flex:'28%', maxWidth:'380px'}}>
        <Wrapper canvas id='random-id-446' is='div' className='w-full h-[207px] my-4 flex mx-auto justify-center'>
            <Wrapper canvas style={{display:'inline-block', width:'100%', height:'100%'}} id='random-id-114' is='div'>
                <ImageC className='w-[100%] h-[100%] object-cover' src='https://i.ibb.co/fx9FXrV/Ecommerce.png'/>
            </Wrapper>
        </Wrapper>
        <Wrapper canvas id='random-id-447' is='div' className='flex flex-col text-[#100103] items-center '>
                <Wrapper canvas style={{display:'inline-block', fontSize:'24px', fontWeight:'700', fontFamily:'Inter', letterSpacing:'0.5px'}} className='my-4'  id='random-id-64' is='div'>
                <Text text='Product name'/>
        </Wrapper>
                <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Inter', textAlignLast:'center'}} id='random-id-65' is='div'>
                <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...'/>
            </Wrapper>
                <Wrapper canvas style={{display:'inline-block', color:'#0078D3', fontSize:'34px', fontWeight:'700'}} className='my-4  '  id='random-id-66' is='div'>
                <Text text='$299'/>
            </Wrapper>
            <Wrapper canvas id='random-id-213' is='div' className='mx-auto mb-4'>
                <Button  classn='block' class='flex w-[260px] h-[70px] cursor-pointer mb-4 bg-[#0078d3] hover:bg-[#11578B] text-[#FFF] uppercase' style={{ boxShadow: '0px 4px 10px 0px #0000001A'}}>
                    <Wrapper canvas style={{display:'inline-block', fontSize:'24px', fontWeight:'600', fontFamily:'Inter',}} className='mx-auto my-auto' id='random-id-67' is='div'>
                <Text text='Learn more'/>
            </Wrapper>
                </Button>
            </Wrapper>
        </Wrapper>
    </Wrapper>
</Wrapper>
</Wrapper>
</Wrapper></Element>
        </Element>,

<Element canvas is={Parent} id='parentId900' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId1000' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-448' is='parent' className='w-[100%] h-full bg-[white] py-10' >
            <Wrapper canvas id='random-id-449' is='container' className='container '>
                <Wrapper canvas style={{display:'inline-block', fontWeight:'700', fontFamily:'Voces'}} className={`text-[#020212]  my-4 text-[46px] pl-6`} id='random-id-68' is='div'>
                    <Text text='Our best products'/>
                </Wrapper>
                <Wrapper canvas id='random-id-450' is='wrapper' className='w-full h-full flex flex-row flex-wrap justify-center'>
                <Wrapper canvas id='random-id-451' is='div' className=' h-full flex flex-col text-center px-4 my-2 mx-auto ' style={{border: '1px solid #0202120A', background:'#F8F8F8',margin:'0 10px', flex:'28%', maxWidth:'380px'}}>
                    <Wrapper canvas id='random-id-452' is='div' className='w-full h-[207px] my-4 flex mx-auto justify-center'>
                        <Wrapper canvas style={{display:'inline-block', width:'100%', height:'100%'}} id='random-id-115' is='div'>
                            <ImageC className='w-[100%] h-[100%] object-cover' src='https://i.ibb.co/fx9FXrV/Ecommerce.png'/>
                        </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-453' is='div' className='flex flex-col text-[#020212] items-center '>
                            <Wrapper canvas style={{display:'inline-block', fontSize:'24px', fontWeight:'700', fontFamily:'Voces', letterSpacing:'0.5px'}} className='my-4' id='random-id-69' is='div'>
                            <Text text='Product name'/>
                    </Wrapper>
                            <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Voces', textAlignLast:'center'}} id='random-id-70' is='div'>
                            <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...'/>
                        </Wrapper>
                            <Wrapper canvas style={{display:'inline-block', color:'#020212', fontSize:'32px', fontWeight:'700'}} className='my-4  '  id='random-id-71' is='div'>
                            <Text text='$299'/>
                        </Wrapper>
                        <Wrapper canvas id='random-id-214' is='div' className='mx-auto mb-4'>
                            <Button  classn='block' class='flex w-[260px] h-[70px] cursor-pointer mb-4 bg-[#FFFFFFA1] hover:bg-[#020212] text-[#000000] hover:text-[#FFFFFF] uppercase ' style={{ border: '2px solid #020212', borderRadius:'10px'}}>
                                <Wrapper canvas style={{display:'inline-block', fontSize:'22px', fontWeight:'600', fontFamily:'Voces',}} className='mx-auto my-auto' id='random-id-72' is='div'>
                            <Text text='Learn more'/>
                        </Wrapper>
                            </Button>
                        </Wrapper>
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-454' is='div' className='  h-full flex flex-col text-center px-4 my-2 mx-auto' style={{border: '1px solid #0202120A', background:'#F8F8F8',margin:'0 10px', flex:'28%', maxWidth:'380px'}}>
                    <Wrapper canvas id='random-id-455' is='div' className='w-full h-[207px] my-4 flex mx-auto justify-center'>
                        <Wrapper canvas style={{display:'inline-block', width:'100%', height:'100%'}} id='random-id-116' is='div'>
                            <ImageC className='w-[100%] h-[100%] object-cover' src='https://i.ibb.co/fx9FXrV/Ecommerce.png'/>
                        </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-456' is='div' className='flex flex-col text-[#020212] items-center '>
                            <Wrapper canvas style={{display:'inline-block', fontSize:'24px', fontWeight:'700', fontFamily:'Voces', letterSpacing:'0.5px'}} className='my-4' id='random-id-73' is='div'>
                                <Text text='Product name'/>
                            </Wrapper>
                            <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Voces', textAlignLast:'center'}} id='random-id-74' is='div'>
                            <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...'/>
                        </Wrapper>
                            <Wrapper canvas style={{display:'inline-block', color:'#020212', fontSize:'32px', fontWeight:'700'}} className='my-4  '  id='random-id-75' is='div'>
                            <Text text='$299'/>
                        </Wrapper>
                        <Wrapper canvas id='random-id-215' is='div' className='mx-auto mb-4'>
                            <Button  classn='block' class='flex w-[260px] h-[70px] cursor-pointer mb-4 bg-[#FFFFFFA1] hover:bg-[#020212] text-[#000000] hover:text-[#FFFFFF] uppercase ' style={{ border: '2px solid #020212', borderRadius:'10px'}}>
                                <Wrapper canvas style={{display:'inline-block', fontSize:'22px', fontWeight:'600', fontFamily:'Voces',}} className='mx-auto my-auto' id='random-id-76' is='div'>
                            <Text text='Learn more'/>
                        </Wrapper>
                            </Button>
                        </Wrapper>
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-457' is='div' className='  h-full flex flex-col text-center px-4 my-2 mx-auto' style={{border: '1px solid #0202120A', background:'#F8F8F8',margin:'0 10px', flex:'28%', maxWidth:'380px'}}>
                    <Wrapper canvas id='random-id-458' is='div' className='w-full h-[207px] my-4 flex mx-auto justify-center'>
                        <Wrapper canvas style={{display:'inline-block', width:'100%', height:'100%'}} id='random-id-117' is='div'>
                            <ImageC className='w-[100%] h-[100%] object-cover' src='https://i.ibb.co/fx9FXrV/Ecommerce.png'/>
                        </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-459' is='div' className='flex flex-col text-[#020212] items-center '>
                            <Wrapper canvas style={{display:'inline-block', fontSize:'24px', fontWeight:'700', fontFamily:'Voces', letterSpacing:'0.5px'}} className='my-4' id='random-id-77' is='div'>
                            <Text text='Product name'/>
                    </Wrapper>
                            <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Voces', textAlignLast:'center'}} id='random-id-78' is='div'>
                            <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...'/>
                        </Wrapper>
                            <Wrapper canvas style={{display:'inline-block', color:'#020212', fontSize:'32px', fontWeight:'700'}} className='my-4  ' id='random-id-79' is='div'>
                            <Text text='$299'/>
                        </Wrapper>
                        <Wrapper canvas id='random-id-216' is='div' className='mx-auto mb-4'>
                            <Button  classn='block' class='flex w-[260px] h-[70px] cursor-pointer mb-4 bg-[#FFFFFFA1] hover:bg-[#020212] text-[#000000] hover:text-[#FFFFFF] uppercase ' style={{ border: '2px solid #020212', borderRadius:'10px'}}>
                                <Wrapper canvas style={{display:'inline-block', fontSize:'22px', fontWeight:'600', fontFamily:'Voces',}} className='mx-auto my-auto' id='random-id-80' is='div'>
                            <Text text='Learn more'/>
                        </Wrapper>
                            </Button>
                        </Wrapper>
                    </Wrapper>
                </Wrapper>
            </Wrapper>
            </Wrapper>
            
        </Wrapper></Element>
        </Element>
        ,
        

        <Element canvas is={Parent} id='parentId1100' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId1200' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-460' is='parent' className='w-[100%] h-full bg-[#FBFAFF] py-10' >
            <Wrapper canvas id='random-id-461' is='container' className='container '>
                <Wrapper canvas style={{display:'inline-block', fontWeight:'700', fontFamily:'Roboto'}} className={`text-[#6750A4]  my-4 text-[46px] pl-6`} id='random-id-81' is='div'>
                            <Text text='Our best products'/>
                        </Wrapper>
                <Wrapper canvas id='random-id-462' is='wrapper' className='w-full h-full flex flex-row flex-wrap justify-center'>
                <Wrapper canvas id='random-id-463' is='div' className=' h-full flex flex-col text-center px-4 my-2 mx-auto ' style={{border: '1px solid #D0BCFF66', borderRadius:'30px', background:'#FFFFFF',margin:'0 10px', flex:'28%', maxWidth:'380px'}}>
                    <Wrapper canvas id='random-id-464' is='div' className='w-full h-[207px] my-4 flex mx-auto justify-center'>
                        <Wrapper canvas style={{display:'inline-block', width:'100%', height:'100%'}} id='random-id-118' is='div'>
                            <ImageC className='w-[100%] h-[100%] object-cover' src='https://i.ibb.co/fx9FXrV/Ecommerce.png'/>
                        </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-465' is='div' className='flex flex-col text-[#020009] items-center '>
                            <Wrapper canvas className='my-4' style={{display:'inline-block', fontSize:'24px', fontWeight:'700', fontFamily:'Roboto', letterSpacing:'0.5px', color:'#6750A4'}} id='random-id-82' is='div'>
                            <Text text='Product name'/>
                    </Wrapper>
                            <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Roboto', textAlignLast:'center'}} id='random-id-83' is='div'>
                            <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...'/>
                        </Wrapper>
                            <Wrapper canvas style={{display:'inline-block', color:'#6750A4', fontSize:'32px', fontWeight:'700'}} className='my-4  '  id='random-id-84' is='div'>
                            <Text text='$299'/>
                        </Wrapper>
                        <Wrapper canvas id='random-id-217' is='div' className='mx-auto mb-4'>
                            <Button  classn='block' class='flex w-[220px] h-[40px] cursor-pointer mb-4 bg-[#D0BCFF] border-1 hover:border-[#381E72] uppercase text-[#6750A4]' style={{ borderRadius:'100px'}}>
                                <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'500px', fontFamily:'Roboto',}} className='mx-auto my-auto' id='random-id-85' is='div'>
                            <Text text='Learn more'/>
                        </Wrapper>
                            </Button>
                        </Wrapper>
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-466' is='div' className='  h-full flex flex-col text-center px-4 my-2 mx-auto' style={{border: '1px solid #D0BCFF66', borderRadius:'30px', background:'#FFFFFF',margin:'0 10px', flex:'28%', maxWidth:'380px'}}>
                    <Wrapper canvas id='random-id-467' is='div' className='w-full h-[207px] my-4 flex mx-auto justify-center'>
                        <Wrapper canvas style={{display:'inline-block', width:'100%', height:'100%'}} id='random-id-119' is='div'>
                            <ImageC className='w-[100%] h-[100%] object-cover' src='https://i.ibb.co/fx9FXrV/Ecommerce.png'/>
                        </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-468' is='div' className='flex flex-col text-[#020009] items-center '>
                            <Wrapper canvas style={{display:'inline-block', fontSize:'24px', fontWeight:'700', fontFamily:'Roboto', letterSpacing:'0.5px', color:'#6750A4'}} className='my-4' id='random-id-86' is='div'>
                            <Text text='Product name'/>
                    </Wrapper>
                            <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Roboto', textAlignLast:'center'}} id='random-id-87' is='div'>
                            <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...'/>
                        </Wrapper>
                            <Wrapper canvas className='my-4  ' style={{display:'inline-block', color:'#6750A4', fontSize:'32px', fontWeight:'700'}} id='random-id-88' is='div'>
                            <Text text='$299'/>
                        </Wrapper>
                        <Wrapper canvas id='random-id-218' is='div' className='mx-auto mb-4'>
                            <Button  classn='block' class='flex w-[220px] h-[40px] cursor-pointer mb-4 bg-[#D0BCFF] border-1 hover:border-[#381E72] uppercase text-[#6750A4]' style={{ borderRadius:'100px'}}>
                                <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'500px', fontFamily:'Roboto',}} className='mx-auto my-auto' id='random-id-89' is='div'>
                            <Text text='Learn more'/>
                        </Wrapper>
                            </Button>
                        </Wrapper>
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-469' is='div' className='  h-full flex flex-col text-center px-4 my-2 mx-auto' style={{border: '1px solid #D0BCFF66', borderRadius:'30px', background:'#FFFFFF',margin:'0 10px', flex:'28%', maxWidth:'380px'}}>
                    <Wrapper canvas id='random-id-470' is='div' className='w-full h-[207px] my-4 flex mx-auto justify-center'>
                        <Wrapper canvas style={{display:'inline-block', width:'100%', height:'100%'}} id='random-id-120' is='div'>
                            <ImageC className='w-[100%] h-[100%] object-cover' src='https://i.ibb.co/fx9FXrV/Ecommerce.png'/>
                        </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-471' is='div' className='flex flex-col text-[#020009] items-center '>
                            <Wrapper canvas className='my-4' style={{display:'inline-block', fontSize:'24px', fontWeight:'700', fontFamily:'Roboto', letterSpacing:'0.5px', color:'#6750A4'}} id='random-id-90' is='div'>
                            <Text text='Product name'/>
                    </Wrapper>
                            <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Roboto', textAlignLast:'center'}} id='random-id-91' is='div'>
                            <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...'/>
                        </Wrapper>
                            <Wrapper canvas className='my-4  '  style={{display:'inline-block', color:'#6750A4', fontSize:'32px', fontWeight:'700'}} id='random-id-92' is='div'>
                            <Text text='$299'/>
                        </Wrapper>
                        <Wrapper canvas id='random-id-219' is='div' className='mx-auto mb-4'>
                            <Button  classn='block' class='flex w-[220px] h-[40px] cursor-pointer mb-4 bg-[#D0BCFF] border-1 hover:border-[#381E72] uppercase text-[#6750A4]' style={{ borderRadius:'100px'}}>
                                <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'500px', fontFamily:'Roboto',}} className='mx-auto my-auto' id='random-id-93' is='div'>
                            <Text text='Learn more'/>
                        </Wrapper>
                            </Button>
                        </Wrapper>
                    </Wrapper>
                </Wrapper>
            </Wrapper>
            </Wrapper>
        </Wrapper></Element>
        </Element>
        ,

        <Element canvas is={Parent} id='parentId1300' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId1400' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-472' is='parent' className='w-[100%] h-full bg-[#2A2A2A] py-10' >
            <Wrapper canvas id='random-id-473' is='container' className='container '>
                <Wrapper canvas style={{display:'inline-block', fontWeight:'700', fontFamily:'Inter'}} className={`text-[#FFD600]  my-4 text-[46px] pl-6 `} id='random-id-94' is='div'>
                            <Text text='Our best products.'/>
                        </Wrapper>
                <Wrapper canvas id='random-id-474' is='wrapper' className='w-full h-full flex flex-row flex-wrap justify-center'>
                <Wrapper canvas id='random-id-475' is='div' className=' h-full flex flex-col text-center px-4 my-2 mx-auto ' style={{border: '1px solid #FFD6001A', boxShadow: '0px 0px 10px 0px #FFFFFF0F', borderRadius:'4px',margin:'0 10px', flex:'28%', maxWidth:'380px'}}>
                    <Wrapper canvas id='random-id-476' is='div' className='w-full h-[207px] my-4 flex mx-auto justify-center'>
                        <Wrapper canvas style={{display:'inline-block', width:'100%', height:'100%'}} id='random-id-121' is='div'>
                            <ImageC className='w-[100%] h-[100%] object-cover' src='https://i.ibb.co/fx9FXrV/Ecommerce.png'/>
                        </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-477' is='div' className='flex flex-col text-[#FFFFFF] items-center '>
                            <Wrapper canvas style={{display:'inline-block', fontSize:'24px', fontWeight:'700', fontFamily:'Rubik', letterSpacing:'0.5px'}} className='my-4' id='random-id-95' is='div'>
                            <Text text='Product name'/>
                    </Wrapper>
                            <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', textAlignLast:'center'}} id='random-id-96' is='div'>
                            <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...'/>
                        </Wrapper>
                            <Wrapper canvas className='my-4  ' style={{display:'inline-block', color:'#FFD600', fontSize:'34px', fontWeight:'700'}} id='random-id-97' is='div'>
                            <Text text='$299'/>
                        </Wrapper>
                        <Wrapper canvas id='random-id-220' is='div' className='mx-auto mb-4'>
                            <Button  classn='block' class='flex w-[260px] h-[70px] cursor-pointer mb-4 bg-[#FFD600] hover:bg-[#FFD600A1] text-[#2A2A2A] ' style={{borderRadius:'10px'}}>
                                <Wrapper canvas style={{display:'inline-block', fontSize:'24px', fontWeight:'700', fontFamily:'Inter', }} className='mx-auto my-auto' id='random-id-98' is='div'>
                            <Text text='Learn more'/>
                        </Wrapper>
                            </Button>
                        </Wrapper>
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-478' is='div' className='  h-full flex flex-col text-center px-4 my-2 mx-auto' style={{border: '1px solid #FFD6001A', boxShadow: '0px 0px 10px 0px #FFFFFF0F', borderRadius:'4px',margin:'0 10px', flex:'28%', maxWidth:'380px'}}>
                    <Wrapper canvas id='random-id-479' is='div' className='w-full h-[207px] my-4 flex mx-auto justify-center'>
                        <Wrapper canvas style={{display:'inline-block', width:'100%', height:'100%'}} id='random-id-122' is='div'>
                            <ImageC className='w-[100%] h-[100%] object-cover' src='https://i.ibb.co/fx9FXrV/Ecommerce.png'/>
                        </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-480' is='div' className='flex flex-col text-[#FFFFFF] items-center '>
                            <Wrapper canvas className='my-4'  style={{display:'inline-block', fontSize:'24px', fontWeight:'700', fontFamily:'Rubik', letterSpacing:'0.5px'}} id='random-id-99' is='div'>
                            <Text text='Product name'/>
                    </Wrapper>
                            <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', textAlignLast:'center'}} id='random-id-1-100' is='div'>
                            <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...'/>
                        </Wrapper>
                            <Wrapper canvas className='my-4  '  style={{display:'inline-block', color:'#FFD600', fontSize:'34px', fontWeight:'700'}} id='random-id-1-101' is='div'>
                            <Text text='$299'/>
                        </Wrapper>
                        <Wrapper canvas id='random-id-221' is='div' className='mx-auto mb-4'>
                            <Button  classn='block' class='flex w-[260px] h-[70px] cursor-pointer mb-4 bg-[#FFD600] hover:bg-[#FFD600A1] text-[#2A2A2A] ' style={{borderRadius:'10px'}}>
                                <Wrapper canvas style={{display:'inline-block', fontSize:'24px', fontWeight:'700', fontFamily:'Inter', }} className='mx-auto my-auto' id='random-id-102' is='div'>
                            <Text text='Learn more'/>
                        </Wrapper>
                            </Button>
                        </Wrapper>
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-481' is='div' className='h-full flex flex-col text-center px-4 my-2 mx-auto' style={{border: '1px solid #FFD6001A', boxShadow: '0px 0px 10px 0px #FFFFFF0F', borderRadius:'4px',margin:'0 10px', flex:'28%', maxWidth:'380px'}}>
                    <Wrapper canvas id='random-id-482' is='div' className='w-full h-[207px] my-4 flex mx-auto justify-center'>
                        <Wrapper canvas style={{display:'inline-block', width:'100%', height:'100%'}} id='random-id-123' is='div'>
                            <ImageC className='w-[100%] h-[100%] object-cover' src='https://i.ibb.co/fx9FXrV/Ecommerce.png'/>
                        </Wrapper>
                    </Wrapper>
                    <Wrapper canvas id='random-id-483' is='div' className='flex flex-col text-[#FFFFFF] items-center '>
                            <Wrapper canvas className='my-4'  style={{display:'inline-block', fontSize:'24px', fontWeight:'700', fontFamily:'Rubik', letterSpacing:'0.5px'}} id='random-id-1-103' is='div'>
                            <Text text='Product name'/>
                    </Wrapper>
                            <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', textAlignLast:'center'}} id='random-id-1-104' is='div'>
                            <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...'/>
                        </Wrapper>
                            <Wrapper canvas className='my-4  '  style={{display:'inline-block', color:'#FFD600', fontSize:'34px', fontWeight:'700'}} id='random-id-1-105' is='div'>
                            <Text text='$299'/>
                        </Wrapper>
                        <Wrapper canvas id='random-id-222' is='div' className='mx-auto mb-4'>
                            <Button  classn='block' class='flex w-[260px] h-[70px] cursor-pointer mb-4 bg-[#FFD600] hover:bg-[#FFD600A1] text-[#2A2A2A] ' style={{borderRadius:'10px'}}>
                                <Wrapper canvas style={{display:'inline-block', fontSize:'24px', fontWeight:'700', fontFamily:'Inter', }} className='mx-auto my-auto' id='random-id-1-106' is='div'>
                            <Text text='Learn more'/>
                        </Wrapper>
                            </Button>
                        </Wrapper>
                    </Wrapper>
                </Wrapper>
            </Wrapper>
            </Wrapper>
            
        </Wrapper></Element>
        </Element>,

<Element canvas is={Parent} id='parentId1500' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId1600' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-484' is='parent' className='w-[100%] h-full bg-[#F7FBFE] py-10' >
<Wrapper canvas id='random-id-485' is='container' className='container'>
        <Wrapper canvas style={{display:'inline-block', fontWeight:'700', fontFamily:'Rubik'}} className={`text-[#6750A4] my-4 text-[46px] pl-6`} id='random-id-1' is='div'>
                <Text text='Our best products'/>
        </Wrapper>
    <Wrapper canvas id='random-id-486' is='wrapper' className='w-full h-full flex flex-row flex-wrap justify-center'>
    <Wrapper canvas id='random-id-487' is='div' className=' h-full flex flex-col text-center px-4 mx-auto my-2 ' style={{border: '1px solid rgba(96, 100, 210, 0.10)', borderRadius:'10px', background:'#fff',margin:'0 10px', flex:'28%', maxWidth:'380px'}}>
        <Wrapper canvas id='random-id-488' is='div' className='w-full h-[207px] my-4 flex mx-auto justify-center'>
            <Wrapper  canvas style={{display:'inline-block', width:'100%', height:'100%'}} id='random-id-100' is='div'>
                <ImageC className='w-[100%] h-[100%] object-cover' src='https://i.ibb.co/fx9FXrV/Ecommerce.png'/>
            </Wrapper>
        </Wrapper>
        <Wrapper canvas id='random-id-489' is='div' className='flex flex-col text-[#100103] items-center '>
                <Wrapper canvas style={{display:'inline-block', fontSize:'24px', fontWeight:'700', fontFamily:'Rubik', letterSpacing:'0.5px'}} className='my-4' id='random-id-3' is='div'>
                <Text text='Product name'/>
        </Wrapper>
            <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', textAlignLast:'center'}} id='random-id-4' is='div'>
                <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...'/>
            </Wrapper>
                <Wrapper canvas style={{display:'inline-block', color:'#6064D2', fontSize:'32px', fontWeight:'700'}} className='my-4 ' id='random-id-5' is='div'>
                <Text text='$299'/>
            </Wrapper>
            <Wrapper canvas id='random-id-200' is='div' className='mx-auto mb-4'>
                <Button  classn='block' class='flex w-[320px] h-[50px] cursor-pointer mb-4 bg-[#B20312] hover:bg-[#87000C] uppercase text-[#FFFFFF]' style={{ border: '1px solid #FFFFFF1A'}}>
                    <Wrapper canvas style={{display:'inline-block', fontSize:'24px', borderRadius:'10px', fontWeight:'600', fontFamily:'Rubik',}} className='mx-auto my-auto' id='random-id-6' is='div'>
                <Text text='Learn more'/>
            </Wrapper>
                </Button>
            </Wrapper>
        </Wrapper>
    </Wrapper>
    <Wrapper canvas id='random-id-490' is='div' className='h-full flex flex-col text-center px-4 mx-auto my-2' style={{border: '1px solid rgba(96, 100, 210, 0.10)', borderRadius:'10px', background:'#fff',margin:'0 10px', flex:'28%', maxWidth:'380px'}}>
        <Wrapper canvas id='random-id-491' is='div' className='w-full h-[207px] my-4 flex mx-auto justify-center'>
            <Wrapper  canvas style={{display:'inline-block', width:'100%', height:'100%'}} id='random-id-101' is='div'>
                <ImageC className='w-[100%] h-[100%] object-cover' src='https://i.ibb.co/fx9FXrV/Ecommerce.png'/>
            </Wrapper>
        </Wrapper>
        <Wrapper canvas id='random-id-492' is='div' className='flex flex-col text-[#100103] items-center '>
                <Wrapper canvas style={{display:'inline-block', fontSize:'24px', fontWeight:'700', fontFamily:'Rubik', letterSpacing:'0.5px'}} className='my-4' id='random-id-7' is='div'>
                <Text text='Product name'/>
        </Wrapper>
                <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', textAlignLast:'center'}} id='random-id-8' is='div'>
                <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...'/>
            </Wrapper>
                <Wrapper canvas style={{display:'inline-block', color:'#6064D2', fontSize:'32px', fontWeight:'700'}} className='my-4  ' id='random-id-9' is='div'>
                <Text text='$299'/>
            </Wrapper>
            <Wrapper canvas id='random-id-201' is='div' className='mx-auto mb-4'>
                <Button  classn='block' class='flex w-[320px] h-[50px] cursor-pointer mb-4 bg-[#B20312] hover:bg-[#87000C] uppercase text-[#FFFFFF]' style={{ border: '1px solid #FFFFFF1A'}}>
                    <Wrapper canvas style={{display:'inline-block', fontSize:'24px', borderRadius:'10px', fontWeight:'600', fontFamily:'Rubik',}} className='mx-auto my-auto' id='random-id-10' is='div'>
                <Text text='Learn more'/>
            </Wrapper>
                </Button>
            </Wrapper>
        </Wrapper>
    </Wrapper>
    <Wrapper canvas id='random-id-493' is='div' className='h-full flex flex-col text-center px-4 mx-auto my-2' style={{border: '1px solid rgba(96, 100, 210, 0.10)', borderRadius:'10px', background:'#fff',margin:'0 10px', flex:'28%', maxWidth:'380px'}}>
        <Wrapper canvas id='random-id-494' is='div' className='w-full h-[207px] my-4 flex mx-auto justify-center'>
            <Wrapper canvas style={{display:'inline-block', width:'100%', height:'100%'}} id='random-id-102' is='div'>
                <ImageC className='w-[100%] h-[100%] object-cover' src='https://i.ibb.co/fx9FXrV/Ecommerce.png'/>
            </Wrapper>
        </Wrapper>
        <Wrapper canvas id='random-id-495' is='div' className='flex flex-col text-[#100103] items-center '>
                <Wrapper canvas style={{display:'inline-block', fontSize:'24px', fontWeight:'700', fontFamily:'Rubik', letterSpacing:'0.5px'}} className='my-4'  id='random-id-11' is='div'>
                <Text text='Product name'/>
        </Wrapper>
                <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', textAlignLast:'center'}} id='random-id-12' is='div'>
                <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...'/>
            </Wrapper>
                <Wrapper canvas style={{display:'inline-block', color:'#6064D2', fontSize:'32px', fontWeight:'700'}} className='my-4  '  id='random-id-13' is='div'>
                <Text text='$299'/>
            </Wrapper>
            <Wrapper canvas id='random-id-202' is='div' className='mx-auto mb-4'>
                <Button  classn='block' class='flex w-[320px] h-[50px] cursor-pointer mb-4 bg-[#B20312] hover:bg-[#87000C] uppercase text-[#FFFFFF]' style={{ border: '1px solid #FFFFFF1A'}}>
                    <Wrapper canvas style={{display:'inline-block', fontSize:'24px', borderRadius:'10px', fontWeight:'600', fontFamily:'Rubik',}} className='mx-auto my-auto' id='random-id-14' is='div'>
                <Text text='Learn more'/>
            </Wrapper>
                </Button>
            </Wrapper>
        </Wrapper>
    </Wrapper>
</Wrapper> 
</Wrapper>
</Wrapper></Element>
        </Element>
,


<Element canvas is={Parent} id='parentId1700' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId1800' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-496' is='parent' className='w-[100%] h-full bg-[#FEFBF7] py-10' >
<Wrapper canvas id='random-id-497' is='container' className='container'>
        <Wrapper canvas style={{display:'inline-block', fontWeight:'700', fontFamily:'Rubik'}} className={`text-[#A25738] my-4 text-[46px] pl-6`} id='random-id-1' is='div'>
                <Text text='Our best products'/>
        </Wrapper>
    <Wrapper canvas id='random-id-498' is='wrapper' className='w-full h-full flex flex-row flex-wrap justify-center'>
    <Wrapper canvas id='random-id-499' is='div' className=' h-full flex flex-col text-center px-4 mx-auto my-2 ' style={{border: '1px solid rgba(162, 87, 56, 0.10)', borderRadius:'10px', background:'#fff',margin:'0 10px', flex:'28%', maxWidth:'380px'}}>
        <Wrapper canvas id='random-id-500' is='div' className='w-full h-[207px] my-4 flex mx-auto justify-center'>
            <Wrapper  canvas style={{display:'inline-block', width:'100%', height:'100%'}} id='random-id-100' is='div'>
                <ImageC className='w-[100%] h-[100%] object-cover' src='https://i.ibb.co/fx9FXrV/Ecommerce.png'/>
            </Wrapper>
        </Wrapper>
        <Wrapper canvas id='random-id-501' is='div' className='flex flex-col text-[#100103] items-center '>
                <Wrapper canvas style={{display:'inline-block', fontSize:'24px', fontWeight:'700', fontFamily:'Rubik', letterSpacing:'0.5px'}} className='my-4' id='random-id-3' is='div'>
                <Text text='Product name'/>
        </Wrapper>
            <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', textAlignLast:'center'}} id='random-id-4' is='div'>
                <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...'/>
            </Wrapper>
                <Wrapper canvas style={{display:'inline-block', color:'#A25738', fontSize:'32px', fontWeight:'700'}} className='my-4 ' id='random-id-5' is='div'>
                <Text text='$299'/>
            </Wrapper>
            <Wrapper canvas id='random-id-200' is='div' className='mx-auto mb-4'>
                <Button  classn='block' class='flex w-[320px] h-[50px] cursor-pointer mb-4 bg-[#A25738] uppercase text-[#FFFFFF]'>
                    <Wrapper canvas style={{display:'inline-block', fontSize:'24px', borderRadius:'10px', fontWeight:'600', fontFamily:'Rubik'}} className='mx-auto my-auto' id='random-id-6' is='div'>
                <Text text='Learn more'/>
            </Wrapper>
                </Button>
            </Wrapper>
        </Wrapper>
    </Wrapper>
    <Wrapper canvas id='random-id-502' is='div' className='h-full flex flex-col text-center px-4 mx-auto my-2' style={{border: '1px solid rgba(162, 87, 56, 0.10)', borderRadius:'10px', background:'#fff',margin:'0 10px', flex:'28%', maxWidth:'380px'}}>
        <Wrapper canvas id='random-id-503' is='div' className='w-full h-[207px] my-4 flex mx-auto justify-center'>
            <Wrapper  canvas style={{display:'inline-block', width:'100%', height:'100%'}} id='random-id-101' is='div'>
                <ImageC className='w-[100%] h-[100%] object-cover' src='https://i.ibb.co/fx9FXrV/Ecommerce.png'/>
            </Wrapper>
        </Wrapper>
        <Wrapper canvas id='random-id-504' is='div' className='flex flex-col text-[#100103] items-center '>
                <Wrapper canvas style={{display:'inline-block', fontSize:'24px', fontWeight:'700', fontFamily:'Rubik', letterSpacing:'0.5px'}} className='my-4' id='random-id-7' is='div'>
                <Text text='Product name'/>
        </Wrapper>
                <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', textAlignLast:'center'}} id='random-id-8' is='div'>
                <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...'/>
            </Wrapper>
                <Wrapper canvas style={{display:'inline-block', color:'#A25738', fontSize:'32px', fontWeight:'700'}} className='my-4  ' id='random-id-9' is='div'>
                <Text text='$299'/>
            </Wrapper>
            <Wrapper canvas id='random-id-201' is='div' className='mx-auto mb-4'>
                <Button  classn='block' class='flex w-[320px] h-[50px] cursor-pointer mb-4 bg-[#A25738] uppercase text-[#FFFFFF]'>
                    <Wrapper canvas style={{display:'inline-block', fontSize:'24px', borderRadius:'10px', fontWeight:'600', fontFamily:'Rubik'}} className='mx-auto my-auto' id='random-id-10' is='div'>
                <Text text='Learn more'/>
            </Wrapper>
                </Button>
            </Wrapper>
        </Wrapper>
    </Wrapper>
    <Wrapper canvas id='random-id-505' is='div' className='h-full flex flex-col text-center px-4 mx-auto my-2' style={{border: '1px solid rgba(162, 87, 56, 0.10)', borderRadius:'10px', background:'#fff',margin:'0 10px', flex:'28%', maxWidth:'380px'}}>
        <Wrapper canvas id='random-id-506' is='div' className='w-full h-[207px] my-4 flex mx-auto justify-center'>
            <Wrapper canvas style={{display:'inline-block', width:'100%', height:'100%'}} id='random-id-102' is='div'>
                <ImageC className='w-[100%] h-[100%] object-cover' src='https://i.ibb.co/fx9FXrV/Ecommerce.png'/>
            </Wrapper>
        </Wrapper>
        <Wrapper canvas id='random-id-507' is='div' className='flex flex-col text-[#100103] items-center '>
                <Wrapper canvas style={{display:'inline-block', fontSize:'24px', fontWeight:'700', fontFamily:'Rubik', letterSpacing:'0.5px'}} className='my-4'  id='random-id-11' is='div'>
                <Text text='Product name'/>
        </Wrapper>
                <Wrapper canvas style={{display:'inline-block', fontSize:'18px', fontWeight:'400', fontFamily:'Rubik', textAlignLast:'center'}} id='random-id-12' is='div'>
                <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...'/>
            </Wrapper>
                <Wrapper canvas style={{display:'inline-block', color:'#A25738', fontSize:'32px', fontWeight:'700'}} className='my-4  '  id='random-id-13' is='div'>
                <Text text='$299'/>
            </Wrapper>
            <Wrapper canvas id='random-id-202' is='div' className='mx-auto mb-4'>
                <Button  classn='block' class='flex w-[320px] h-[50px] cursor-pointer mb-4 bg-[#A25738] uppercase text-[#FFFFFF]'>
                    <Wrapper canvas style={{display:'inline-block', fontSize:'24px', borderRadius:'10px', fontWeight:'600', fontFamily:'Rubik'}} className='mx-auto my-auto' id='random-id-14' is='div'>
                <Text text='Learn more'/>
            </Wrapper>
                </Button>
            </Wrapper>
        </Wrapper>
    </Wrapper>
</Wrapper> 
</Wrapper>
</Wrapper></Element>
        </Element>
,
    ]

    
const [sel, setSel] = useState(selected)

function makeOdd(number) {
  setProp((prop) => {
    setSel(100);
  }, 1000);

  setTimeout(() => {
    setProp((prop) => {
      setSel(number)
    }, 1000);
  }, 100);


  
}

useEffect(() => {
if(selected || selected >= 0) {
  makeOdd(selected);
}
},[selected])



return (
    <div style={{ padding: enabled? "10px" : "0px", display:"flex", justifyContent:"center", alignItems:"center", width:'100%', height:'100%'}} ref={connect}>
        {sel == 100 ? (
          <div style={{height:"85vh", display:"flex", justifyContent:"center", alignItems:"center"}}>
          <BounceLoader
                  size={100}
                  color={"white"}
                  loading={true}
                />
          </div>
         ) : (
          styles[sel]
         )} 
    </div>
);
}

Ecommerce_1.craft = {
    displayName: "Ecommerce 1",
    props: {
    selected: 1, // here is the default value of the selected style
    length:9,
isBlock: true
    },
    rules: {
        canDrag: () => true,
        canDrop: () => true,
    },
    related: {
        toolbar: NewEcommerceSettings,
    },
};
