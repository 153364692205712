const ToolbarSelectDrop = ({
    isDarkMode,
    props,
    noinput,
    optionsDrop,
    value,
    onChange,
    setProp,
    node,
    propKey,
}) => {
    return (
        <div className={`${isDarkMode ? 'text-[#fff]' : 'text-[#000]'} flex justify-between items-center`} style={{ padding: noinput ? '' : '1vmin 0' }}>
            <div className="text-left w-[fit-content] flex-1" style={{ fontSize: '2vmin', fontWeight: '500', fontFamily: 'Inter', paddingBottom: '.5Vmin' }}>{props.label}</div>
            {props.label === 'Transition' ? <div className='text-[#989191] text-[2vmin] px-[.25Vmin]'>You can add a transition</div> : props.label === 'Display' ? <div className='text-[#989191] text-[2vmin] px-[.25Vmin]'>Flexbox layout mode</div> : props.label === 'Direction' ? <div className='text-[#989191] text-[2vmin] px-[.25Vmin]'>Set the layout direction</div> : null}
            <select
              style={{
                color: isDarkMode ? '#fff' : '#000',
                fontFamily: 'Inter',
                fontWeight: '500',
                padding: '.5Vmin 2Vmin',
                width:'50%',
                flex:1,
                fontSize: '2vmin',
                border: isDarkMode ? '.25vmin solid #e9e9e930' : '.25Vmin solid #E9E9EA',
                borderRadius: '2.5Vmin',
                backgroundColor: 'transparent',
                margin: '.5vmin 0',
                lineHeight: '1.2em',
                transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
              }}

              value={value || ''}
              onChange={(e) => {
                const selectedValue = e.target.value;
                onChange ? onChange(selectedValue) : 
                setProp(node.id, (props) => {
                  props[propKey] = onChange ? onChange(selectedValue) : selectedValue;
                });


              }}
            >
              <option value="">default</option>
              {optionsDrop.map((option) => (
                <option
                  style={{
                    fontFamily: 'Inter',
                    fontWeight: '400',
                    fontSize: '2.2Vmin',
                    color: 'black',
                    lineHeight: 'auto'
                  }}
                  key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
    )
}

export default ToolbarSelectDrop;